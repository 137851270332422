import { Tooltip } from 'reactstrap';
import { ProgressBar } from 'react-bootstrap';
import React, { useState } from 'react';
import { useModalState } from '../../../Molecules/Dialogs';
import { FileSize } from '../../../Atoms/FileSize';

export default function ({ item, index }) {
  const { show, toggle } = useModalState(false);
  const [id] = useState(`process-${index}`);

  return (
    <>
      <ProgressBar isChild id={id} style={{ backgroundColor: item.color }} now={item.procent} key={index} />
      <Tooltip placement="top" isOpen={show} target={id} toggle={toggle}>
        {item.label} <FileSize fileSize={item.value} />
      </Tooltip>
    </>
  );
}
