// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Space, Checkbox, Text } from '@mantine/core';
import { useContext } from 'react';
import RequestAnswer from '../../../Types/Enums/RequestAnswer';
import BookingStateItem from '../BookingStateItem';
import { EventsContext } from '../../../Service/Context/EventsContext';

const AvailabilitySelection = () => {
  const { filter, updateFilter } = useContext(EventsContext);
  const { answersOfInterest, showAllEvents } = filter;

  function toggleAvailability(availability: any) {
    let newAvailability = Object.assign([], answersOfInterest);

    if (newAvailability.includes(availability)) {
      newAvailability = newAvailability.filter((s: any) => s !== availability);
      updateFilter({ answersOfInterest: newAvailability });
    } else {
      newAvailability.push(availability);
      updateFilter({ answersOfInterest: newAvailability });
    }
  }

  return (
    <>
      <Space h="xl" />
      <Text pl={10}>Verfügbarkeiten</Text>
      <Space h="xs" />
      <BookingStateItem
        label="Verfügbar"
        onClick={() => toggleAvailability(RequestAnswer.AVAILABLE)}
        active={answersOfInterest.includes(RequestAnswer.AVAILABLE)}
      />
      <BookingStateItem
        label="Nicht verfügbar"
        onClick={() => toggleAvailability(RequestAnswer.UNAVAILABLE)}
        active={answersOfInterest.includes(RequestAnswer.UNAVAILABLE)}
      />
      <BookingStateItem
        label="Geblockt"
        onClick={() => toggleAvailability(RequestAnswer.BLOCKED)}
        active={answersOfInterest.includes(RequestAnswer.BLOCKED)}
      />
      <BookingStateItem
        label="Unpassend"
        onClick={() => toggleAvailability(RequestAnswer.UNFIT)}
        active={answersOfInterest.includes(RequestAnswer.UNFIT)}
      />
      <BookingStateItem
        label="Unbekannt"
        onClick={() => toggleAvailability(RequestAnswer.UNKNOWN)}
        active={answersOfInterest.includes(RequestAnswer.UNKNOWN)}
      />
      <Space h="xl" />
      <Checkbox
        ml="xs"
        label="Events ohne Vermittlungen anzeigen"
        checked={showAllEvents}
        onChange={() => updateFilter({ showAllEvents: !showAllEvents })}
      />
    </>
  );
};

export default AvailabilitySelection;
