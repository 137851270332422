import { FC, useContext } from 'react';
import { useLingui } from '@lingui/react';
import { ModalFlowStepItem } from '../../../Atoms/Stepper/MenuItem';
import { ModalFlow } from '../../../Molecules/Dialogs';
import ContactDeleteFlow1 from './ContactDeleteFlow-1';
import ContactDeleteFlow0 from './ContactDeleteFlow-0';
import ContactDeleteFlow2 from './ContactDeleteFlow-2';
import ContactDeleteFlow4 from './ContactDeleteFlow-4';
import ContactDeleteFlow3 from './ContactDeleteFlow-3';
import { ContactDeleteFlowContext } from './ContactDeleteFlowContext';

const ContactModalSteps: FC = () => {
  const {
    documents,
    contracts,
    mails,
    eventParticipations,
    recommendations,
    error,
    isLoading,
    canBeFinished,
    finish,
    deleteDocumentsValidator,
    deleteEventParticipantsValidator,
    deleteMailsValidator,
    deleteRecommendationsValidator,
    deleteContractsValidator,
  } = useContext(ContactDeleteFlowContext);

  const { i18n } = useLingui();

  const getCount = (list?: any[]) => (list ? ` (${list.length})` : '');

  const stepBodyComponents = [];

  const menuItems: ModalFlowStepItem[] = [];

  if (contracts) {
    menuItems.push({
      icon: 'FILE_DESIGN',
      label: 'Verträge',
      description: i18n._('contact.delete.contracts.head') + getCount(contracts),
      validator: deleteContractsValidator,
    });
    stepBodyComponents.push(<ContactDeleteFlow0 key={0} />);
  }

  if (documents) {
    menuItems.push({
      icon: 'FILE',
      label: 'Dokumente',
      description: i18n._('contact.delete.documents.head') + getCount(documents),
      validator: deleteDocumentsValidator,
    });
    stepBodyComponents.push(<ContactDeleteFlow1 key={1} />);
  }

  if (mails) {
    menuItems.push({
      icon: 'EMAIL',
      label: 'E-Mails',
      description: i18n._('contact.delete.emails.head') + getCount(mails),
      validator: deleteMailsValidator,
    });
    stepBodyComponents.push(<ContactDeleteFlow2 key={2} />);
  }

  if (recommendations) {
    menuItems.push({
      icon: 'RECOMMENDED',
      label: 'Vermittlungen',
      description: i18n._('contact.delete.recommendations.head') + getCount(recommendations),
      validator: deleteRecommendationsValidator,
    });
    stepBodyComponents.push(<ContactDeleteFlow3 key={3} />);
  }

  if (eventParticipations) {
    menuItems.push({
      icon: 'STAR_FILLED',
      label: 'Events',
      description: i18n._('contact.delete.events.head') + getCount(eventParticipations),
      validator: deleteEventParticipantsValidator,
    });
    stepBodyComponents.push(<ContactDeleteFlow4 key={4} />);
  }

  return (
    <ModalFlow
      menuItems={menuItems}
      stepBodyComponents={stepBodyComponents}
      error={error}
      isLoading={isLoading}
      canBeFinished={canBeFinished()}
      finish={finish}
      finishButtonText="contact.delete"
    />
  );
};

export default ContactModalSteps;
