// eslint-disable-next-line no-redeclare
import { Button, Group, Stack, Text } from '@mantine/core';
import { useContext } from 'react';
import { Trans } from '@lingui/react';
import { useElementSize } from '@mantine/hooks';
import PositionListWithDocumentContext from '../../../../Molecules/Document/PositionList';
import { EditableDocumentTemplateProperty } from '../../../../Atoms/EditableProperties/EditableDocumentTemplateProperty';
import { HtmlPlaceholderFormatter } from '../../../../Atoms/Formatter/HtmlPlaceholderFormatter';
import { EditableDivInput } from '../../../../Atoms/inputs/EditableDivInput';
import { DocumentContext } from '../../../../Service/Context/DocumentContext';
import PositionHeader from '../../../../Molecules/Document/Position/PositionHeader';
import DocumentSum from '../../../../Molecules/Document/DocumentSum';
import { ProfileContext } from '../../../../Service/Context/ProfileContext';
import BookitupSegmentedControl from '../../../../Atoms/BookitupSegmentedControl';
import { DataS } from '../../../../Service/DataS';

const Skeleton = ({ preChange }) => {
  const { profile } = useContext(ProfileContext);
  const { document, handleChange, addPosition, savePatch } = useContext(DocumentContext);
  const { documentType, positions, taxType } = document;
  const isPartialInvoice = documentType === 'PARTIAL_INVOICE';
  const { ref, width } = useElementSize();

  if (document !== undefined) {
    return (
      <div ref={ref}>
        <Stack mt={50}>
          <Group position="apart">
            <Text size="lg" weight="bolder" mt="md">
              <Trans id={`document.${document.documentType.toLowerCase()}`} />
            </Text>
            {!profile.smallScaleBusiness && (
              <BookitupSegmentedControl
                value={taxType}
                color="blue"
                data={DataS.netGrossOptions}
                radius="md"
                onChange={(val) => savePatch({ taxType: val })}
              />
            )}
          </Group>

          <EditableDocumentTemplateProperty
            dataTour="document-template-headRow"
            patchField="headRow"
            preChange={preChange}
            name="headRow"
            changeHandler={handleChange}
            entity={document}
            editFormatter={EditableDivInput}
            labelFormatter={HtmlPlaceholderFormatter}
            settings
          />

          <PositionHeader documentVersion desktopVersion={width > 805} showDiscount={!isPartialInvoice} />
          <PositionListWithDocumentContext preChange={preChange} settings />

          {!(isPartialInvoice && positions.length > 0) && (
            <Button
              variant="outline"
              mt="xs"
              sx={(theme) => ({ maxWidth: 150, backgroundColor: theme.colors.lightBlue[0], border: 'none' })}
              onClick={() => addPosition(document.length)}
            >
              Neue Position
            </Button>
          )}
          <DocumentSum positions={document.positions} />
          <Text size="lg" weight="bolder">
            Fußtext
          </Text>
          <EditableDocumentTemplateProperty
            patchField="endRow"
            preChange={preChange}
            name="endRow"
            changeHandler={handleChange}
            entity={document}
            big
            editFormatter={EditableDivInput}
            labelFormatter={HtmlPlaceholderFormatter}
            settings
          />
        </Stack>
      </div>
    );
  }
  return null;
};

export default Skeleton;
