import React from 'react';
import PropTypes from 'prop-types';

/**
 * TimeFormatter is a formatter which simply passes strings trough and adds ' Uhr', but
 * returns a dash for empty or null values.
 */
export const TimeFormatter = ({ value, testId }) => (
  <span
    className="form-control"
    data-testid={testId}
    style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
  >
    {value === '' || value === null || value === undefined ? '-' : `${value} Uhr`}
  </span>
);

TimeFormatter.propTypes = {
  value: PropTypes.string,
};
