import { Box, Navbar, ScrollArea } from '@mantine/core';
import { FC, ReactNode } from 'react';
import PrimaryNavbar from './PrimaryNavbar';

const DoubleNavbar: FC<{ children: ReactNode }> = ({ children }) => (
  <Navbar width={{ sm: 300 }}>
    <ScrollArea scrollbarSize={0} className="non-relative">
      <Navbar.Section grow sx={{ display: 'flex', gap: 5 }}>
        <Box sx={{ boxShadow: '2px 0px 10px #0000001A' }}>
          <PrimaryNavbar />
        </Box>
        <Box sx={{ flex: 1, minHeight: '100vh' }}>{children}</Box>
      </Navbar.Section>
    </ScrollArea>
  </Navbar>
);

export default DoubleNavbar;
