/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Group, Text, ThemeIcon } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { IconArrowLeft, IconBallpen } from '@tabler/icons';
import { FC, ReactNode, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import PDFViewer from '../../Atoms/PDFViewer';
import { useModalState } from '../Dialogs';
import EditFinishedDocumentWarning from '../Event/Documents/EditFinishedDocumentWarning';
import PanePaper from '../PanePaper';
import { TestS } from '../../TestS';
import { ToastS } from '../../Service/ToastS';
import DocumentPreviewActions from './DocumentPreviewActions';

const DocumentTemplatePreview: FC<{
  url: string;
  rightSection?: ReactNode;
  draft?: boolean;
  entityType: string;
  entity: any;
  patchEntity?: (patch: any, onSuccess?: () => void) => void;
  isPrintable?: boolean;
  portalSupported?: boolean;
}> = ({ url, rightSection, draft, entityType, entity, portalSupported, patchEntity, isPrintable }) => {
  const { id: eventId } = useParams();
  const navigate: NavigateFunction = useNavigate();
  const { pathname } = useLocation();
  const { show, toggle } = useModalState(false);
  const { ref, width } = useElementSize();
  const [blob, setBlob] = useState<Blob>();
  const [lastModified, setLastModified] = useState<Date>();
  const { locked, hasPayments } = entity;
  const showDropdownVariant = width < 750;

  const editDocument = () => {
    if (draft || entityType === 'questionnaires') {
      navigate(pathname.replace('/preview', ''));
    } else if (locked) {
      ToastS.warn(
        'document-locked',
        'Das Dokument darf nicht mehr bearbeitet werden, da es bereits festgeschrieben wurde.',
      );
    } else if (hasPayments) {
      ToastS.warn(
        'document-has-payments',
        'Das Dokument darf nicht bearbeitet werden. Löschen Sie zunächst alle Zahlungen.',
      );
    } else {
      toggle();
    }
  };

  return (
    <div ref={ref} style={{ display: 'flex' }}>
      <PanePaper withShadow withoutBorder rightSection={rightSection}>
        <Group position="right" sx={{ position: 'relative' }}>
          {!draft && (
            <div style={{ display: 'flex', position: 'absolute', left: 0, alignItems: 'center', gap: 10 }}>
              <IconArrowLeft
                color="gray"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(eventId ? `/events/${eventId}/documents` : '/documents')}
              />
              <Text size="lg" weight="bolder">
                Vorschau
              </Text>
            </div>
          )}
          <div>
            {width < 500 ? (
              <ThemeIcon onClick={editDocument} data-testid={TestS.Navigate.Documents.TO_EDIT}>
                <IconBallpen size={20} />
              </ThemeIcon>
            ) : (
              <Button
                leftIcon={<IconBallpen size={20} />}
                onClick={editDocument}
                data-testid={TestS.Navigate.Documents.TO_EDIT}
              >
                Bearbeiten
              </Button>
            )}
          </div>
          {showDropdownVariant && (
            <DocumentPreviewActions
              dropDownVesion
              portalSupported={portalSupported}
              entityType={entityType}
              entity={entity}
              documentFile={blob}
              setLastModified={setLastModified}
              patchEntity={patchEntity}
              isPrintable={isPrintable}
            />
          )}
        </Group>
        <PDFViewer
          setFile={setBlob}
          url={url}
          containerWidth={width - (showDropdownVariant ? 0 : 330)}
          lastModified={lastModified}
          isDraft={draft}
        />
      </PanePaper>
      {!showDropdownVariant && (
        <DocumentPreviewActions
          portalSupported={portalSupported}
          entityType={entityType}
          entity={entity}
          documentFile={blob}
          setLastModified={setLastModified}
          patchEntity={patchEntity}
          isPrintable={isPrintable}
        />
      )}
      {!draft && (
        <EditFinishedDocumentWarning
          eventId={entity.eventId}
          documentType={entity.documentType}
          entityType={entityType}
          entityId={entity.id}
          show={show}
          toggle={toggle}
          patchEntity={patchEntity}
        />
      )}
    </div>
  );
};

export default DocumentTemplatePreview;
