// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { BookitupNotification } from '../../../../Types/LogT';
import { RoutingS } from '../../../../Service/RoutingS';

const OfferOverdue: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { personDescription, eventId, entityId } = notification;

  return (
    <Text>
      Das
      <Text
        span
        weight="bolder"
        variant="link"
        color="blue"
        sx={{ cursor: 'pointer' }}
        onClick={() => RoutingS.openInNewTab(`/events/${eventId}/documents/${entityId}`)}
      >
        {'  '}Angebot{'  '}
      </Text>
      für
      <Text span weight="bolder">
        {` ${personDescription} `}
      </Text>
      ist abgelaufen.
    </Text>
  );
};

export default OfferOverdue;
