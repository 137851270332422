// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Stack, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { FC, useContext } from 'react';
import { IconInfoCircle, IconStack2, IconTimeline } from '@tabler/icons';
import NavigationLink from '../../Atoms/Navigation/NavigationLink';
import { useNavigationItem } from '../Navigation/NavigationItemHook';
import { WindowContext } from '../../Service/Context/WindowContext';
import { ResourceDetailsContext } from '../../Service/Context/ResourceDetailsContext';
import { ResourceType } from '../../Types/ResourceT';

const ResourceNavBar: FC<{ closeNavBar: () => void }> = ({ closeNavBar }) => {
  const navigate = useNavigate();
  const { sm } = useContext(WindowContext);
  const { resourceId, resource } = useContext(ResourceDetailsContext);
  const { name } = resource ?? {};

  const onClickHandler = (path: string) => {
    navigate(path);
    closeNavBar();
  };

  return (
    <>
      {!sm && (
        <Stack spacing={3} p="md">
          <Text>Ressource</Text>
          <Text size="xl" weight="bolder">
            {name}
          </Text>
        </Stack>
      )}
      <NavigationLink
        variant="filled"
        label="Details"
        onClick={() => onClickHandler(`/resources/${resourceId}/details`)}
        active={useNavigationItem(`/resources/${resourceId}/details`)}
        icon={sm && <IconInfoCircle size={16} />}
      />
      {resource?.type !== ResourceType.KIT && (
        <StockIntervalNavItem resourceId={resourceId} sm={sm} onClickHandler={onClickHandler} />
      )}
      <NavigationLink
        variant="filled"
        label="Planung"
        onClick={() => onClickHandler(`/resources/${resourceId}/planning`)}
        active={useNavigationItem(`/resources/${resourceId}/planning`)}
        icon={sm && <IconTimeline size={16} />}
      />
      {/* {resource?.type !== ResourceType.KIT && (
        <ExemplarsNavItem resourceId={resourceId} sm={sm} onClickHandler={onClickHandler} />
      )}
      <NavigationLink
        variant="filled"
        label="Historie"
        onClick={() => onClickHandler(`/resources/${resourceId}/history`)}
        active={useNavigationItem(`/resources/${resourceId}/history`)}
        icon={sm && <IconInfoCircle size={16} />}
      /> */}
    </>
  );
};

const StockIntervalNavItem: FC<{ resourceId?: string; onClickHandler: (path: string) => void; sm?: boolean }> = ({
  resourceId,
  onClickHandler,
  sm,
}) => (
  <NavigationLink
    variant="filled"
    label="Bestand"
    onClick={() => onClickHandler(`/resources/${resourceId}/stock-intervals`)}
    active={useNavigationItem(`/resources/${resourceId}/stock-intervals`)}
    icon={sm && <IconStack2 size={16} />}
  />
);

const ExemplarsNavItem: FC<{ resourceId?: string; onClickHandler: (path: string) => void; sm?: boolean }> = ({
  resourceId,
  onClickHandler,
  sm,
}) => (
  <NavigationLink
    variant="filled"
    label="Exemplare"
    onClick={() => onClickHandler(`/resources/${resourceId}/exemplar`)}
    active={useNavigationItem(`/resources/${resourceId}/exemplar`)}
    icon={sm && <IconInfoCircle size={16} />}
  />
);

export default ResourceNavBar;
