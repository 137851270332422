import {useContext, useEffect} from "react";
import {WindowContext} from "../../Service/Context/WindowContext";

const ProductFruitsChecklist = () => {
  const { md, lg } = useContext(WindowContext);

  useEffect(() => {
    // to show checklist, after initial page loading, after productFruits is initialized
    window.productFruitsReady = () => {
      try {
        window.productFruits.api.checklists.injectToElement(4661, document.getElementById("onboardingChecklist"), { checkEligibility: true });
        setTimeout(() => {
          const elem = document.getElementById("onboardingChecklist");
          if (
            elem &&
            elem.children[0] &&
            elem.children[0].shadowRoot &&
            elem.children[0].shadowRoot.children[0] &&
            elem.children[0].shadowRoot.children[0].children[0] &&
            elem.children[0].shadowRoot.children[0].children[0].style
          ) {
            elem.children[0].shadowRoot.children[0].children[0].style.zIndex = 1
          }

        }, 1000)
      } catch (e) {
        console.error("not able to inject onboardingChecklist", e);
      }
    }

    // to show checklist, after navigating through the app
    if (window.productFruits && window.productFruits.api) {
      try {
        window.productFruits.api.checklists.injectToElement(4661, document.getElementById("onboardingChecklist"), { checkEligibility: true });
      } catch (e) {
        console.error("not able to inject onboardingChecklist", e);
      }
    }

  }, []);

  if (md || lg) {
    return (<div style={{marginTop: 10}} id={"onboardingChecklist"} />);
  }

  return null;
}

export default ProductFruitsChecklist;



