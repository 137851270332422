import React, { useEffect, useState } from 'react';

export const FileSize = ({ fileSize: fileSizeInBytes }) => {
  const [fileSize, setFileSize] = useState(undefined);
  const [unit, setUnit] = useState(undefined);

  useEffect(() => {
    if (fileSizeInBytes >= 1000000000) {
      setFileSize(Math.floor(fileSizeInBytes / 1000000000));
      setUnit('GB');
    } else if (fileSizeInBytes >= 1000000) {
      setFileSize(Math.floor(fileSizeInBytes / 1000000));
      setUnit('MB');
    } else if (fileSizeInBytes >= 1000) {
      setFileSize(Math.floor(fileSizeInBytes / 1000));
      setUnit('KB');
    } else {
      setUnit('Bytes');
      setFileSize(fileSizeInBytes);
    }
  }, [fileSizeInBytes]);

  if (fileSizeInBytes === undefined) return null;

  const styles = {
    fileSize: {
      fontSize: 12,
    },
  };

  return (
    <div style={styles.fileSize}>
      {fileSize} {unit}
    </div>
  );
};
