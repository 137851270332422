import { FC } from 'react';
import { useElementSize } from '@mantine/hooks';
import BookitupTable from '../../../../Atoms/BookitupTable';
import { Planning } from '../../../../Types/ResourceT';
import PlanningTableRow from './PlanningTableRow';

interface Props {
  plannings: Planning[];
  refresh: () => void;
}

const ResourcePlanningTable: FC<Props> = ({ plannings, refresh }) => {
  const { ref, width } = useElementSize();
  const showColumns = {
    showCategory: width > 550,
    showType: width > 450,
    showCounter: width > 400,
  };

  return (
    <BookitupTable>
      <thead>
        <tr ref={ref}>
          <th style={{ width: 30 }}> </th>
          <th style={{ width: 30 }}> </th>
          <th>Name</th>
          {showColumns.showCategory && <th style={{ width: 100 }}>Kategorie</th>}
          {showColumns.showType && <th style={{ width: 70 }}> Typ </th>}
          {showColumns.showCounter && <th style={{ width: 100 }}> Planung</th>}
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {plannings.map((planning) => (
          <PlanningTableRow key={planning.id} planning={planning} refresh={refresh} {...showColumns} />
        ))}
      </tbody>
    </BookitupTable>
  );
};

export default ResourcePlanningTable;
