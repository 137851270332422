// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Text } from '@mantine/core';
import { IconCheckbox } from '@tabler/icons';
import { FC } from 'react';

const FutureSubscriptionAlert: FC<{ startDate: string }> = ({ startDate }) => (
  <Alert
    icon={<IconCheckbox size={16} />}
    title="Abonnement migriert"
    sx={(theme) => ({
      backgroundColor: theme.colors.lightBlue[0],
    })}
  >
    <Text mb="md">
      Vielen Dank für deine Hilfe! Das Abo wird am{' '}
      <Text span weight="bolder">
        {startDate}
      </Text>{' '}
      verlängert.

    </Text>
    <Text mb="md">
      Info: Falls Du bisher PayPal als Zahlungsmethode hinterlegt hattest, hast du nun wahrscheinlich eine E-Mail von PayPal erhalten, dass Dein Abo beendet wurde. Das ist so korrekt, da sonst 2 Abos zeitgleich existieren würden.
    </Text>
    <Text mb="md">
      Wenn du schon jetzt zu einem höheren Plan wechseln möchtest, melde Dich bitte über den Support-Chat bei uns.
    </Text>
  </Alert>
);

export default FutureSubscriptionAlert;
