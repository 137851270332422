import { FC } from 'react';
import { Text } from '@mantine/core';
import { ProcedureReportProps, ShareEntityConfigDTO } from '../../../../Types/LogT';

const ShareEntityReport: FC<ProcedureReportProps> = ({ report, config }) => {
  const cfg = config as ShareEntityConfigDTO;
  const { entityReference } = cfg;
  const { label } = entityReference;

  return <Text>{label} wurde geteilt.</Text>;
};

export default ShareEntityReport;
