import React, { useContext, useEffect, useState } from 'react';
import { withI18n } from '@lingui/react';
import moment from 'moment';
import { LoadingAnimation } from '../../Service/ResourceAdapter';
import { fetchDocumentsByEvent } from '../../Service/restapi/documentService';
import { RadioBox } from '../../Atoms/inputs/RadioBox';
import { fetchContractsByEvent } from '../../Service/restapi/contractService';
import { fetchQuestionnairesByEvent } from '../../Service/restapi/questionnaireService';

export const DocumentSelector = withI18n()(({ i18n, eventId, selectedId, onSelect, entityType, documentType }) => {
  const [documents, setDocuments] = useState(undefined);

  useEffect(() => {
    let functionCall;
    if (entityType === 'documents') {
      functionCall = fetchDocumentsByEvent;
    } else if (entityType === 'contracts') {
      functionCall = fetchContractsByEvent;
    } else if (entityType === 'questionnaires') {
      functionCall = fetchQuestionnairesByEvent;
    }

    functionCall(eventId).then((resp) => {
      if (resp.ok) {
        resp.json().then((docs) => {
          setDocuments(docs);
        });
      }
    });
  }, [eventId, entityType]);

  if (!documents) {
    return <LoadingAnimation />;
  }

  const options = [];
  documents
    .filter((d) => (documentType ? d.documentType === documentType : true))
    .forEach((document) => {
      let label = null;
      if (entityType === 'documents') {
        label = document.label ? document.label : i18n._(`document.${document.documentType.toLowerCase()}`);
      } else if (entityType === 'questionnaires') {
        label = document.label ? document.label : 'Fragebogen';
      } else {
        label = document.label ? document.label : 'Vertag';
      }
      if (entityType === 'documents') {
        label += ` - ${document.sum} EUR`;
      }
      const lastModified = moment(document.lastModified);
      lastModified.locale('de');
      label += ` - zuletzt bearbeitet: ${lastModified.fromNow()}`;
      options.push({
        label,
        checked: selectedId === document.id,
        onDeselect: () => onSelect(undefined),
        onClick: () => onSelect(document),
      });
    });

  return (
    <>
      {documents.length === 0 && <div>Erstelle zuerst ein Dokument und kehre dann hierher zurück.</div>}
      <RadioBox options={options} />
    </>
  );
});
