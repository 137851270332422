import { Input, ButtonGroup } from 'reactstrap';
import React from 'react';
import { Trans } from '@lingui/react';

export const RadioBox = ({ options }) => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
  };

  const items = [];

  options.map((val, index) => {
    items.push(
      <label key={index} className="radiobox-radiobox" data-tour={val.dataTour} style={{ display: 'flex' }}>
        <Input
          type="radio"
          className="radiobox-radioboxInput"
          disabled={val.disabled}
          onClick={(option) => {
            if (val.checked) {
              if (typeof val.onDeselect === 'function') {
                val.onDeselect(option);
              }
            } else {
              val.onClick(option);
            }
          }}
          name={val.name}
          onChange={() => {}}
          checked={val.checked}
        />
        <span
          className={`ua-radiobox-radioboxLabel radiobox-radioboxLabel ${val.disabled ? 'disabled' : ''}`}
          style={{ flex: 1 }}
        >
          <Trans id={val.label} />
        </span>
      </label>,
    );
  });

  return <ButtonGroup style={styles.container}>{items}</ButtonGroup>;
};
