import { Group, Tooltip } from '@mantine/core';
import { IconX } from '@tabler/icons';
import PdfPreview from '../../Molecules/Document/PdfPreviewNew';
import { FileS } from '../../Service/FileS';
import { useEffect, useState } from 'react';

const EmailFileAttachment = ({ attachment, eventId, index, deleteAtIndex }) => {
  const [fileExists, setFileExists] = useState(true);

  useEffect(() => {
    if (attachment.entityType === 'profiles') {
      FileS.isFileExists(attachment.entityType, attachment.entityId).then(setFileExists);
    }
  }, [attachment]);

  return (
    <Tooltip withArrow color="red" disabled={fileExists} label="Datei nicht gefunden" position="right" transition="slide-right">
      <Group
        p="xs"
        sx={(theme) => ({
          borderRadius: theme.radius.sm,
          backgroundColor: fileExists ? theme.colors.gray[0] : '#FFEFEF',
        })}
      >
        <PdfPreview eventId={eventId} file={attachment} fileExists={fileExists} />
        {deleteAtIndex && (
          <div>
            <IconX size={16} onClick={() => deleteAtIndex(index)} style={{ cursor: 'pointer' }} />
          </div>
        )}
      </Group>
    </Tooltip>
  );
};

export default EmailFileAttachment;
