import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';
import { MentionList } from './MentionList';
import { fetchCustomPlaceholders, getPredefinedPlaceholders } from '../../../Service/Placeholder/PlaceholderLabels';
import { i18n } from '@lingui/core';


const getFilteredMentions = async (query) => {
  const placeholderGroups = [...getPredefinedPlaceholders(), ...(await fetchCustomPlaceholders())];
  return placeholderGroups
    .flatMap((group) => group.items)
    .filter((item) => i18n._(item.text).toLowerCase().includes(query.toLowerCase()))
    .map((item) => ({ id: item.placeholder, label: i18n._(item.text) }))
};

export default {
  char: '#',
  allowedPrefixes: null,
  items: ({ query }) => getFilteredMentions(query),
  render: () => {
    let reactRenderer;
    let popup;

    return {
      onStart: (props) => {
        reactRenderer = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        reactRenderer.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return reactRenderer.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        reactRenderer.destroy();
      },
    };
  },
};
