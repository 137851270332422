import React, { useContext, useEffect, useState } from 'react';
import { fetchRecurringTasks } from '../../../Service/restapi/taskService';
import { LoadingAnimation } from '../../../Service/ResourceAdapter';
import RecurringTask from './RecurringTask';

export default function ({ lastModified }) {
  const [recurringTasks, setRecurringTasks] = useState(undefined);

  useEffect(() => {
    loadTasks();
  }, [lastModified]);

  function loadTasks() {
    fetchRecurringTasks().then((resp) => {
      if (resp.ok) {
        resp.json().then((tasks) => {
          setRecurringTasks(tasks);
        });
      }
    });
  }

  function onChange(patch) {
    const oldRTasks = Object.assign([], recurringTasks);
    const oldTask = oldRTasks.find((a) => a.id === patch.id);
    const indexOf = oldRTasks.indexOf(oldTask);
    oldRTasks.splice(indexOf, 1, patch);
    setRecurringTasks(oldRTasks);
  }

  if (!recurringTasks) {
    return <LoadingAnimation />;
  }

  return (
    <div>
      {recurringTasks.map((task, index) => (
        <RecurringTask key={index} index={index} task={task} onChange={onChange} reload={loadTasks} />
      ))}
    </div>
  );
}
