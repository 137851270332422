// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Checkbox, Group, NumberInput, Select, Stack, Text } from '@mantine/core';
import { FC, useState } from 'react';
import { IconAffiliate, IconFileDots } from '@tabler/icons';
import { i18n } from '@lingui/core';
import { ChronoUnit, ShareEntityConfigDTO, WorkflowConfigProps } from '../../../../../Types/LogT';
import { WorkflowS } from '../../../../../Service/WorkflowS';
import { DataS } from '../../../../../Service/DataS';

const ShareEntityConfig: FC<WorkflowConfigProps> = ({ config, handleChange, availableEntities }) => {
  const {
    entityReference,
    portalReference,
    sharedForAmount,
    sharedForUnit,
    editableForAmount,
    editableForUnit,
    useExistingPortal,
  } = config as ShareEntityConfigDTO;
  const availablePortals = WorkflowS.filterEntities(availableEntities, ['portal']);
  const availableDocuments = WorkflowS.filterEntities(availableEntities, ['documents', 'contracts', 'questionnaires']);

  const [withEditableDate, setEditableDate] = useState(editableForAmount > 0);
  const [withSharedDate, setSharedDate] = useState(sharedForAmount > 0);

  return (
    <Stack spacing="xs">
      <Select
        required
        defaultValue={entityReference ? entityReference.id : null}
        icon={<IconFileDots size={20} />}
        label="Dokument"
        data={DataS.getEntityReferencesOptions(availableDocuments)}
        onChange={(val) => val && handleChange({ entityReferenceId: val })}
      />
      <Checkbox
        mt="xs"
        defaultChecked={useExistingPortal}
        label="Vorhandenes Portal nutzen"
        onChange={(e) => {
          const val = e.currentTarget.checked;
          handleChange({ useExistingPortal: val });
        }}
      />
      <Text color="dimmed" size="xs" mt={-5}>
        Wählen Sie aus, ob das Portal bereits manuell oder mithilfe eines anderen Workflows erstellt wurde.
      </Text>
      {!useExistingPortal && (
        <>
          <Select
            required={!useExistingPortal}
            defaultValue={portalReference ? portalReference.id : null}
            label="Portal"
            data={DataS.getEntityReferencesOptions(availablePortals)}
            icon={<IconAffiliate size={20} />}
            onChange={(val) => val && handleChange({ portalReferenceId: val })}
          />
          <Checkbox
            mt="xs"
            defaultChecked={withEditableDate}
            label="Satz bis zum Datum editierbar"
            onChange={(e) => {
              const val = e.currentTarget.checked;
              setEditableDate(val);
              if (!val && editableForAmount) {
                handleChange({ editableForAmount: null });
              }
            }}
          />
          {withEditableDate && (
            <Group position="apart">
              <Text>Bearbeitbar für: </Text>
              <Group spacing={5}>
                <NumberInput
                  hideControls
                  defaultValue={editableForAmount}
                  min={0}
                  sx={{ width: 50 }}
                  onBlur={(e) => handleChange({ editableForAmount: Number(e.currentTarget.value) })}
                />
                <Select
                  defaultValue={editableForUnit}
                  data={DataS.getChronoUnitsData(i18n)}
                  sx={{ width: 100 }}
                  onChange={(val: ChronoUnit) => val && handleChange({ editableForUnit: val })}
                />
              </Group>
            </Group>
          )}
          <Checkbox
            defaultChecked={withSharedDate}
            label="Satz bis Datum geteilt"
            onChange={(e) => {
              const val = e.currentTarget.checked;
              setSharedDate(val);
              if (!val && sharedForAmount) {
                handleChange({ sharedForAmount: null });
              }
            }}
          />
          {withSharedDate && (
            <Group position="apart">
              <Text>Geteilt für: </Text>
              <Group spacing={5}>
                <NumberInput
                  hideControls
                  defaultValue={sharedForAmount}
                  min={0}
                  sx={{ width: 50 }}
                  onBlur={(e) => handleChange({ sharedForAmount: Number(e.currentTarget.value) })}
                />
                <Select
                  defaultValue={sharedForUnit}
                  data={DataS.getChronoUnitsData(i18n)}
                  sx={{ width: 100 }}
                  onChange={(val: ChronoUnit) => val && handleChange({ sharedForUnit: val })}
                />
              </Group>
            </Group>
          )}
        </>
      )}
    </Stack>
  );
};

export default ShareEntityConfig;
