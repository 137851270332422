import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { NumberInput, Space, Stack, Text } from '@mantine/core';
import { openModal } from '@mantine/modals';
import { useModalState } from '../../../../Molecules/Dialogs';
import { ProfileContext } from '../../../../Service/Context/ProfileContext';
import SelectDocumentTypeAndNameModal from '../../../../Organismns/Document/SelectDocumentTypeAndNameModal';
import { createTemplateByDocumentTypeAndName } from '../../../../Service/restapi/templateService';
import PanePaper from '../../../../Molecules/PanePaper';
import BookitupAddIcon from '../../../../Atoms/BookitupAddIcon';
import { ViolationContext } from '../../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../../Service/LicenceS';
import { RoutingS } from '../../../../Service/RoutingS';
import DocumentTemplateByDocumentType from './DocumentTemplateByDocumentType';
import { MixpanelS } from '../../../../Service/MixpanelS';
import { ConstantS } from '../../../../Service/ConstantS';
import { i18n } from '@lingui/core';

const DocumentTemplateList = () => {
  const { profile, patchProfile } = useContext(ProfileContext);
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const { show, toggle } = useModalState();
  const navigate = useNavigate();
  const { offerValidUntil, invoiceValidUntil } = profile;

  useEffect(() => {
    RoutingS.changeTitle('Dokument-Vorlagen');
  }, []);

  const openTemplateModal = () => {
    openModal({
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Dokument-Typ auswählen
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: <SelectDocumentTypeAndNameModal show={show} toggle={toggle} finish={create} />,
    });
  };

  function create(type, name) {
    createTemplateByDocumentTypeAndName(type, name)
      .then((resp) => {
        if (resp.ok) {
          MixpanelS.track(ConstantS.TrackingEvents.DocumentTemplateCreated);
          navigate(`/settings/template/document/${type.toLowerCase()}/${name}`);
        } else {
          console.error(`could not create template by documentType ${type} and name ${name}`);
        }
      })
      .catch((error) => {
        console.error(`could not create template by documentType ${type} and name ${name}`);
        console.error(error.message);
      });
  }

  return (
    <Stack sx={{ maxWidth: 500 }}>
      <PanePaper
        title="document.templates"
        tooltip="help.settings.document.templates.pane"
        rightSection={
          <BookitupAddIcon
            onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.MULTIPLE_DOCUMENT_TEMPLATE, openTemplateModal)}
          />
        }
      >
        <Space h="lg" />
        <Stack>
          <DocumentTemplateByDocumentType documentType="OFFER" />
          <DocumentTemplateByDocumentType documentType="CONFIRMATION" />
          <DocumentTemplateByDocumentType documentType="INVOICE" />
          <DocumentTemplateByDocumentType documentType="PARTIAL_INVOICE" />
          <DocumentTemplateByDocumentType documentType="CANCEL" />
        </Stack>
      </PanePaper>
      <PanePaper title="document.deadlines">
        <NumberInput
          hideControls
          onBlur={(e) => patchProfile({ offerValidUntil: e.currentTarget.value })}
          label={i18n._('applicationUser.validUntil')}
          defaultValue={offerValidUntil}
          min={1}
          mb="sm"
          onKeyPress={(event) => {
            if (!/[0-9,\.]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
        <NumberInput
          hideControls
          onBlur={(e) => patchProfile({ invoiceValidUntil: e.currentTarget.value })}
          label={i18n._('applicationUser.invoiceValidUntil')}
          defaultValue={invoiceValidUntil}
          min={1}
          onKeyPress={(event) => {
            if (!/[0-9,\.]/.test(event.key)) {
              event.preventDefault();
            }
          }}
        />
      </PanePaper>
    </Stack>
  );
};
export default DocumentTemplateList;
