/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { TextInput, Loader, Text, TextInputProps } from '@mantine/core';
import { FC, useState } from 'react';
import { formatTimeValue } from '../../Utils/utils';

interface TimeProps extends TextInputProps {
  patchCallback: (val: string | null) => Promise<boolean>;
  initialValue: string | null | undefined;
}

const BookitupTimeInput: FC<TimeProps> = ({ patchCallback, label, initialValue, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<string>(initialValue ?? '');
  const [error, setError] = useState(false);

  const formatTime = async () => {
    const formattedTime = formatTimeValue(value);
    if (formattedTime || value == '') {
      setLoading(true);
      const ok = await patchCallback(formattedTime);
      setLoading(false);
      if (ok) {
        setValue(formattedTime);
        setError(false);
      } else {
        setError(true);
      }
    } else {
      setError(true);
    }
  };

  return (
    <TextInput
      placeholder="-- : --"
      value={value}
      sx={{ width: 90 }}
      onBlur={formatTime}
      onChange={(e) => setValue(e.target.value)}
      label={label && <Text size="xs">{label}</Text>}
      rightSection={
        loading ? (
          <Loader size="xs" />
        ) : (
          <Text size="xs" weight="bolder">
            Uhr
          </Text>
        )
      }
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          formatTime();
        }
      }}
      error={error && 'Gib z.B. 15:00 ein.'}
      {...rest}
    />
  );
};

export default BookitupTimeInput;
