import { Popover } from 'react-text-selection-popover';
import { forwardRef, useState } from 'react';
import { Trans } from '@lingui/react';
import { TransButtonDefault, TransButtonDefaultWithIcon, TransButtonPrimary } from '../Buttons';
import { ModalDialog, useModalState } from '../../Molecules/Dialogs';
import { CheckBox, TransTextInputField } from '../InputFields';

const TextSelectionPopover = forwardRef((props, ref) => {
  const { show, toggle } = useModalState(false);
  const [insertAsButton, setInsertAsButton] = useState(false);
  const [link, setLink] = useState('https://');
  const [text, setText] = useState('Klick hier');
  const [selection, setSelection] = useState(undefined);

  function toggleModal() {
    setSelection(document.getSelection().focusNode);
    toggle();
  }

  function exec(commandId) {
    document.execCommand(commandId, false, null);
    console.log({ ref });
    props.finish(ref.innerHTML);
  }

  function linkText() {
    const text = document.getSelection();
    const linkURL = prompt('Gib den Link ein:', 'https://');
    document.execCommand(
      'insertHTML',
      false,
      `<a title="${linkURL}" href="${linkURL}" target="_blank">${text}</a><br/><br/>`,
    );
    finish(ref.innerHTML);
  }

  function addLink() {
    const linkURL = prompt('Gib den Link ein:', 'https://');
    const sel = window.getSelection();
    sel.removeAllRanges();
    const range = document.createRange();

    range.selectNodeContents(selection);
    sel.addRange(range);
    if (insertAsButton) {
      document.execCommand(
        'insertHTML',
        false,
        `<a style="min-height: 40px;  padding: 6px 12px; border-radius: 3px; text-align: center; font-weight: 700; user-select: none; background-color: rgb(111, 130, 151); color: rgb(255, 255, 255);" href="${link}" target="_blank">${text}</a>`,
      );
    } else {
      document.execCommand('insertHTML', false, `<a href="${link}" target="_blank">${text}</a>`);
    }
    toggle();
  }

  const styles = {
    container: {
      backgroundColor: '#ffffff',
      boxShadow: '0 4px 14px 0 rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.05)',
      borderRadius: 4,
    },
    icon: {
      padding: 5,
      marginLeft: 5,
      marginRight: 5,
      border: 'none',
      backgroundColor: '#ffffff',
    },
    buttonStyle: {
      width: '100%',
      minHeight: 40,
      padding: '6px 12px',
      boxSizing: 'border-box',
      borderRadius: 3,
      textAlign: 'center',
      fontWeight: 700,
      pointerEvents: 'auto',
      cursor: 'pointer',
      userSelect: 'none',
      marginBottom: 0,
      backgroundColor: 'rgb(111, 130, 151)',
      color: 'rgb(255, 255, 255)',
    },
  };

  return (
    <>
      <Popover
        target={ref}
        render={({ clientRect, isCollapsed }) => {
          if (!clientRect || clientRect === null || isCollapsed) return null;

          const style = {
            position: 'absolute',
            left: clientRect.left + clientRect.width / 2,
            top: clientRect.top - 40,
            minWidth: 125,
            textAlign: 'center',
          };

          return (
            <div style={{ ...styles.container, ...style }} className="text-formatter-option-wrapper">
              <TransButtonDefaultWithIcon
                icon="BOLD"
                style={styles.icon}
                className="text-formatter-option-cell"
                onClickHandler={() => exec('bold')}
              />
              <TransButtonDefaultWithIcon
                icon="ITALIC"
                style={styles.icon}
                className="text-formatter-option-cell"
                onClickHandler={() => exec('italic')}
              />
              <TransButtonDefaultWithIcon
                icon="UNDERLINE"
                style={styles.icon}
                className="text-formatter-option-cell"
                onClickHandler={() => exec('underline')}
              />
              <TransButtonDefaultWithIcon
                icon="LINK"
                style={styles.icon}
                className="text-formatter-option-cell"
                onClickHandler={linkText}
              />
            </div>
          );
        }}
      />
      <ModalDialog
        header={<Trans id="Link/Button hinzufügen" />}
        help="Gib einen Link und eine Beschreibung ein."
        body={
          <>
            <TransTextInputField
              value={link}
              saveValue={(value, name) => {}}
              onChange={(e) => {
                setLink(e.target.value);
              }}
              placeholder="Link"
            />
            <TransTextInputField
              value={text}
              saveValue={(value, name) => {}}
              onChange={(e) => {
                setText(e.target.value);
              }}
              placeholder="angezeigter Text"
            />

            <CheckBox
              checked={() => setInsertAsButton(!insertAsButton)}
              initialValue={insertAsButton}
              text="als Button (CTA) einfügen"
            />
          </>
        }
        footer={
          <div>
            <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />{' '}
            <TransButtonPrimary onClickHandler={addLink} text="actions.ok" />
          </div>
        }
        show={show}
        toggle={toggle}
      />
    </>
  );
});

export default TextSelectionPopover;
