import { Trans } from '@lingui/react';
import React, { useContext, useState } from 'react';
import { TransButtonDefault, TransButtonPrimaryWithSpinner } from '../Atoms/Buttons';
import { ModalDialog } from '../Molecules/Dialogs';
import { saveContact, validateContact } from '../Service/restapi/contactService';
import { ToastS } from '../Service/ToastS';
import ContactEdit from './ContactEdit';

export default function ({
  contactId,
  changeCustomer,
  setLastModified,
  eventRole,
  onFinish,
  close,
  toastManager,
  contactType,
}) {
  const [contact, setContact] = useState(undefined);
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function save() {
    setSubmitted(true);
    if (validateContact(contact)) {
      setIsLoading(true);
      saveContact(contact)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((contact) => {
              console.log('got changed contact', { contact });

              {
                /* TODO: edit directly on customer context */
              }
              if (eventRole && eventRole === 'MAIN_CUSTOMER') {
                console.log('change main customer', { contact });
                changeCustomer(contact);
              }

              onFinish({ contactId: contact.id });
              close();
              ToastS.success('save.contact', 'Kontakt erfolgreich gespeichert');
              if (setLastModified) {
                setLastModified(new Date());
              }
            });
          } else if (resp.status === 409)
            resp.json().then((contact) => {
              console.log(contact);
              ToastS.warn('save.contact', `Kontakt mit dieser Email-Adresse existiert bereits.\nBetroffener Kontakt: ${contact.firstname} ${contact.lastname}`);
            });
          else {
            ToastS.error('save.contact', 'Kontakt konnte nicht gespeichert werden');
          }
        })
        .catch((resp) => {
          ToastS.error('save.contact', 'Kontakt konnte nicht gespeichert werden');
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }

  return (
    <ModalDialog
      header={contactType ? 'Ansprechpartner hinzufügen' : <Trans id="actions.contact.edit" />}
      body={<ContactEdit contactType={contactType} contactId={contactId} setContact={setContact} />}
      footer={
        <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
          <div />
          <div>
            <TransButtonPrimaryWithSpinner isLoading={isLoading} onClickHandler={save} text="actions.save" />{' '}
            <TransButtonDefault onClickHandler={close} text="actions.cancel" />
          </div>
        </div>
      }
      alert={
        submitted && !contact.firstName && !contact.lastName && !contact.companyName && !contact.email
          ? 'validation.event.customer.minimal'
          : undefined
      }
      show
      toggle={close}
    />
  );
}
