import { useState, useEffect, useContext } from 'react';
import { Trans } from '@lingui/react';
import { ContextModalProps } from '@mantine/modals';
import { Box, Center, Checkbox, Indicator, SegmentedControl, Textarea, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { isToday } from 'date-fns';
import { TransButtonPrimaryWithSpinner } from '../../../Atoms/Buttons';
import { createTask } from '../../../Service/restapi/taskService';
import AgendaType from '../../../Types/Enums/AgendaType';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import EventSearchAutocomplete from '../../../Organismns/Event/EventSearchAutocomplete';
import { ToastS } from '../../../Service/ToastS';
import TaskGroupSelection from './TaskGroupSelection';
import { MixpanelS } from '../../../Service/MixpanelS';
import { ConstantS } from '../../../Service/ConstantS';

type Props = {
  entityId?: string;
  entityType?: string;
  showEntityTypeSelection?: boolean;
  finish: () => void;
  profile: any;
};

const TaskCreateModal = ({ context, id, innerProps }: ContextModalProps<Props>) => {
  const { entityId, entityType, finish, showEntityTypeSelection, profile } = innerProps;

  const [name, setName] = useState('');
  const [agendaType, setAgendaType] = useState<AgendaType>(AgendaType.STRING);
  const [dueDate, setDueDate] = useState<Date | null>(null);
  const [groupName, setGroupName] = useState<string>();

  const [priority, setPriority] = useState(false);
  const [notes, setNotes] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const [selectedEventId, setSelectedEventId] = useState(entityId ?? null);

  const [selectedEntityType, setSelectedEntityType] = useState(entityType);

  useEffect(() => {
    setName('');
    setGroupName('Standard');
    setDueDate(null);
    setNotes(undefined);
    setPriority(false);
  }, [profile.id]);

  const create = () => {
    if (selectedEntityType === 'events' && (!name || !groupName || !selectedEventId)) {
      ToastS.error(
        'create.task',
        'Es konnte keine neue Aufgabe erstellt werden. Bitte Name, Gruppe und Veranstaltung auswählen.',
      );
    } else if (selectedEntityType === 'profiles' && (!name || !groupName)) {
      ToastS.error('create.task', 'Es konnte keine neue Aufgabe erstellt werden: Bitte Name und Gruppe auswählen.');
    } else {
      createAttr();
    }
  };

  function createAttr() {
    setLoading(true);
    createTask({
      name,
      agendaType,
      entityId: selectedEntityType === 'events' ? selectedEventId : profile.id,
      entityType: selectedEntityType,
      groupName,
      dueDate: dueDate ?? undefined,
      notes,
      priority,
    })
      .then((resp) => {
        if (resp.ok) {
          finish();
          MixpanelS.track(ConstantS.TrackingEvents.TaskCreated);
          ToastS.success('create.task', 'Neue Aufgabe erstellt');
        } else {
          console.error(`could not create new task ${name} of agendaType ${agendaType}`);
          ToastS.error('create.task', 'Es konnte keine neue Aufgabe erstellt werden');
        }
      })
      .catch(() => {
        console.error(`could not create new custom task ${name} of agendaType ${agendaType}`);
        ToastS.error('create.task', 'Es konnte keine neue Aufgabe erstellt werden');
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <>
      <div>
        {showEntityTypeSelection && (
          <Center>
            {' '}
            <SegmentedControl
              value={selectedEntityType}
              onChange={setSelectedEntityType}
              data={[
                { label: 'Event', value: 'events' },
                { label: 'Allgemein', value: 'profiles' },
              ]}
              sx={{ marginBottom: 20 }}
            />
          </Center>
        )}

        {!entityId && selectedEntityType === 'events' && (
          <div style={{ marginBottom: 10 }}>
            <EventSearchAutocomplete
              clearable
              selectEvent={(e) => {
                // setSearchString(e.customer ? e.customer.personDescription : e.dates[0].date.toDateString());

                setSelectedEventId(e.id.toString());
              }}
            />
          </div>
        )}

        <TextInput
          onChange={(e: any) => {
            setName(e.target.value);
          }}
          value={name}
          description="Titel der Aufgabe"
          sx={{ marginBottom: 10 }}
        />

        <DatePicker
          renderDay={(date) => {
            // eslint-disable-next-line no-underscore-dangle
            const _isToday = isToday(date);
            return (
              <Box sx={{ color: _isToday ? 'blue' : 'inherit', borderBottom: _isToday ? '3px solid blue' : 'none' }}>
                {date.getDate()}
              </Box>
            );
          }}
          onChange={setDueDate}
          value={dueDate}
          description="Erledigen bis"
          sx={{ marginBottom: 10 }}
        />

        <Textarea
          value={notes}
          onChange={(e: any) => {
            setNotes(e.target.value);
          }}
          description="Beschreibung der Aufgabe"
          sx={{ marginBottom: 10 }}
          autosize
          minRows={5}
          maxRows={10}
        />
      </div>
      <div style={{ marginBottom: 10 }}>
        <TaskGroupSelection required clearable entityType={selectedEntityType} onGroupNameChanged={setGroupName} />
      </div>
      <div style={{ flex: 1, justifySelf: 'center', marginBottom: 20 }}>
        <Checkbox
          label="Als wichtige Aufgabe markieren"
          checked={priority}
          onChange={(e: any) => setPriority(e.currentTarget.checked)}
        />
      </div>

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <TransButtonPrimaryWithSpinner
          isLoading={loading}
          onClickHandler={create}
          enabled={name}
          text="Aufgabe erstellen"
        />
      </div>
    </>
  );
};

export default TaskCreateModal;
