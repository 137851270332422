// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { BookitupNotification } from '../../Types/LogT';
import EventStateChanged from './Type/Logbook/EventStateChanged';
import TaskCompleted from './Type/Logbook/TaskCompleted';
import OfferConfirmed from './Type/Logbook/OfferConfirmed';
import ContractSigned from './Type/Logbook/ContractSigned';
import QuestionnaireFilled from './Type/Logbook/QuestionnaireFilled';
import RecommendationAvailabilityChanged from './Type/Logbook/RecommendationAvailabilityChanged';
import RecommendationStateChanged from './Type/Logbook/RecommendationStateChanged';
import OfferOverdue from './Type/General/OfferOverdue';
import InvoiceNotPaid from './Type/General/InvoiceNotPaid';
import MailOpened from './Type/Logbook/MailOpened';
import MailReceived from './Type/Logbook/MailReceived';
import RequestReceived from './Type/Logbook/RequestReceived';
import DocumentCreated from './Type/Logbook/DocumentCreated';
import ContractCreated from './Type/Logbook/ContractCreated';
import PortalCreated from './Type/Logbook/PortalCreated';
import EntityShared from './Type/Logbook/EntityShared';
import TaskCreated from './Type/Logbook/TaskCreated';
import WorkflowCompleted from './Type/Logbook/WorkflowCompleted';
import ConditionNotFulfilled from './Type/Logbook/ConditionNotFulfilled';
import ProcedureFailed from './Type/Logbook/ProcedureFailed';
import MailSent from './Type/Logbook/MailSent';
import TaskDeleted from './Type/Logbook/TaskDeleted';
import TaskOverdue from './Type/General/TaskOverdue';
import QuestionnaireCreated from './Type/Logbook/QuestionnaireCreated';
import TasksDeleted from './Type/Logbook/TasksDeleted';

const LogbookNotificationContent: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { occurrenceType } = notification;

  return (
    <>
      {occurrenceType === 'TASK_OVERDUE' && <TaskOverdue notification={notification} />}
      {occurrenceType === 'TASK_DELETED' && <TaskDeleted notification={notification} />}
      {occurrenceType === 'TASKS_DELETED' && <TasksDeleted notification={notification} />}
      {occurrenceType === 'MAIL_SENT' && <MailSent notification={notification} />}
      {occurrenceType === 'PROCEDURE_FAILED' && <ProcedureFailed notification={notification} />}
      {occurrenceType === 'CONDITION_NOT_FULFILLED' && <ConditionNotFulfilled notification={notification} />}
      {occurrenceType === 'WORKFLOW_COMPLETED' && <WorkflowCompleted notification={notification} />}
      {occurrenceType === 'TASK_CREATED' && <TaskCreated notification={notification} />}
      {occurrenceType === 'ENTITY_SHARED' && <EntityShared notification={notification} />}
      {occurrenceType === 'PORTAL_CREATED' && <PortalCreated notification={notification} />}
      {occurrenceType === 'CONTRACT_CREATED' && <ContractCreated notification={notification} />}
      {occurrenceType === 'DOCUMENT_CREATED' && <DocumentCreated notification={notification} />}
      {occurrenceType === 'MAIL_OPENED' && <MailOpened notification={notification} />}
      {occurrenceType === 'MAIL_RECEIVED' && <MailReceived notification={notification} />}
      {occurrenceType === 'EVENT_CREATED' && <Text>Event erstellt</Text>}
      {occurrenceType === 'EVENT_STATE_CHANGED' && <EventStateChanged notification={notification} />}
      {occurrenceType === 'TASK_COMPLETED' && <TaskCompleted notification={notification} />}
      {occurrenceType === 'OFFER_CONFIRMED' && <OfferConfirmed notification={notification} />}
      {occurrenceType === 'CONTRACT_SIGNED' && <ContractSigned notification={notification} />}
      {occurrenceType === 'QUESTIONNAIRE_FILLED' && <QuestionnaireFilled notification={notification} />}
      {occurrenceType === 'QUESTIONNAIRE_CREATED' && <QuestionnaireCreated notification={notification} />}
      {occurrenceType === 'REQUEST_RECEIVED' && <RequestReceived notification={notification} />}
      {occurrenceType === 'RECOMMENDATION_STATE_CHANGED' && <RecommendationStateChanged notification={notification} />}
      {occurrenceType === 'RECOMMENDATION_AVAILABILITY_CHANGED' && (
        <RecommendationAvailabilityChanged notification={notification} />
      )}
      {occurrenceType === 'OFFER_OVERDUE' && <OfferOverdue notification={notification} />}
      {occurrenceType === 'INVOICE_NOT_PAID' && <InvoiceNotPaid notification={notification} />}
    </>
  );
};

export default LogbookNotificationContent;
