import { useState } from 'react';
import { IconCopy, IconDotsVertical, IconTrash } from '@tabler/icons';
import { Menu } from '@mantine/core';
import { ConfirmDialog } from '../../Dialogs';
import { copyDocumentTemplate, deleteDocumentTemplate } from '../../../Service/restapi/documentService';
import { ToastS } from '../../../Service/ToastS';

// eslint-disable-next-line import/prefer-default-export
export const DocumentTemplateListActions = ({ templateName, documentType, reloadDocuments }) => {
  const [modalDelete, setModalDelete] = useState(false);

  function deleteHandler() {
    deleteDocumentTemplate(documentType, templateName).then((resp) => {
      if (resp.ok) {
        ToastS.success('delete.document.template', `Vorlage ${templateName} gelöscht`);
        reloadDocuments();
      } else if (resp.status === 423) {
        ToastS.warn('delete.document.template', 'Es muss mindestens eine Vorlage pro Typ existieren.');
      }
    });
  }

  function copy(templateName) {
    copyDocumentTemplate(documentType, templateName)
      .then((resp) => {
        if (resp.ok) {
          reloadDocuments();
        } else {
          console.error(`could not copy template ${templateName}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not copy template ${templateName}`);
      });
  }

  return (
    <div>
      <Menu withArrow shadow="md" width={200} transition="scale-y" position="bottom-end">
        <Menu.Target>
          <div>
            <IconDotsVertical size={20} color="gray" style={{ cursor: 'pointer' }} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<IconCopy size={16} />} onClick={() => copy(templateName)}>
            Duplizieren
          </Menu.Item>
          <Menu.Item icon={<IconTrash size={16} />} onClick={() => setModalDelete(!modalDelete)}>
            Löschen
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      <ConfirmDialog
        text="common.delete.document.confirm"
        subject="common.delete.document.confirm.head"
        open={modalDelete}
        toggle={() => setModalDelete(!modalDelete)}
        handler={deleteHandler}
      />
    </div>
  );
};
