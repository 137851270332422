import { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Center, Skeleton, Text } from '@mantine/core';
import { fetchEmailIds } from '../../Service/restapi/messageService';
import { fetchFileListOfMessage } from '../../Service/restapi/fileService';
import { EventParticipationsContext } from '../../Service/Context/EventParticipationContext';
import PanePaper from '../PanePaper';
import BookitupTable from '../../Atoms/BookitupTable';
import { EventContext } from '../../Service/Context/EventContext';
import { FileModalDialog } from '../Dialogs/FileModalDialog';
import { useModalState } from '../Dialogs';
import EventFileActions from './EventFileActions';

const MessageFilesPane = () => {
  const { participations } = useContext(EventParticipationsContext);
  const { event } = useContext(EventContext);
  const [emailFiles, setEmailFiles] = useState([]);
  const [file, setFile] = useState();
  const [loadingEmailFiles, setLoadingEmailFiles] = useState(true);
  const [lastUpdate, setLastUpdate] = useState();
  const reload = () => setLastUpdate(new Date());
  const { show, toggle } = useModalState(false);

  const selectFile = (bookitupFile) => {
    setFile(bookitupFile);
    toggle();
  };

  useEffect(() => {
    const fetchMailFiles = (emailIds) => {
      let tmpFiles = [];
      let count = 0;
      emailIds.forEach((emailId) => {
        fetchFileListOfMessage(emailId).then((resp) => {
          if (resp.ok) {
            resp.json().then((filesOfEmail) => {
              const decorateFiles = [];
              filesOfEmail.forEach((f) => {
                decorateFiles.push({
                  ...f,
                  entityType: 'messages',
                  entityId: emailId,
                });
              });

              tmpFiles = tmpFiles.concat(decorateFiles);
              count += 1;
              if (count === emailIds.length) {
                const emailItems = [];

                console.log('=> ', { emailItems });
                setEmailFiles(tmpFiles);
                setLoadingEmailFiles(false);
              }
            });
          }
        });
      });
    };

    setLoadingEmailFiles(true);
    const ids = [];
    participations.forEach((participation) => {
      ids.push(participation.contactId);
    });

    fetchEmailIds(ids)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((emailIds) => {
            if (emailIds.length === 0) {
              setEmailFiles([]);
              setLoadingEmailFiles(false);
            } else {
              fetchMailFiles(emailIds);
            }
          });
        } else {
          console.error('could not fetch email-ids by contact-ids');
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not fetch email-ids by contact-ids');
      });
  }, [lastUpdate]);

  return (
    <PanePaper title="E-Mail Anhänge" tooltip="help.event.files.pane">
      {loadingEmailFiles ? (
        <div>
          {Array.from(Array(3)).map(() => (
            <Skeleton key={Math.random()} radius="md" height={25} width="100%" mb={10} />
          ))}
        </div>
      ) : (
        <div>
          {emailFiles.length === 0 ? (
            <Text italic color="dimmed" size="xs" weight="lighter">
              Noch nichts hinzugefügt.
            </Text>
          ) : (
            <BookitupTable>
              <thead>
                <tr>
                  <th>Bezeichnung</th>
                  <th style={{ width: 50 }}>Datum</th>
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {emailFiles.map((file) => {
                  const { entityId, fileName, lastModified } = file;
                  const creationDate = new Date(lastModified);
                  return (
                    <tr key={fileName}>
                      <td style={{ cursor: 'pointer' }} onClick={() => selectFile(file)}>
                        {fileName}
                      </td>
                      <td onClick={() => selectFile(file)}>
                        <Moment date={creationDate} format="DD.MM" />
                      </td>
                      <td style={{ position: 'relative' }}>
                        <Center>
                          <div style={{ position: 'absolute', right: 0 }}>
                            <EventFileActions
                              entityId={entityId}
                              entityType="messages"
                              fileName={fileName}
                              reload={reload}
                            />
                          </div>
                        </Center>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </BookitupTable>
          )}
        </div>
      )}
      <FileModalDialog
        show={show}
        toggle={toggle}
        allowDownload
        allowEmailAttachment={false}
        allowEditing={false}
        file={file}
        eventId={undefined}
      />
    </PanePaper>
  );
};

export default MessageFilesPane;
