import React from 'react';
import TooltipButton from '../../Atoms/buttons/TooltipButton';
import { DeleteAction } from '../../Molecules/DynamicFieldWrapper';

export default function ({ index, addQuestionBundle, bundlesLength, setBundleInDelete, moveQuestionBundle, bundleId }) {
  const styles = {
    component: {
      display: 'flex',
      backgroundColor: 'rgb(251, 251, 251)',
      border: '1px solid rgba(0, 0, 0, .125)',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    menuComponent: {
      width: 30,
      borderLeft: 'none',
      height: 102,
      marginLeft: -1,
      borderRadius: '0px 4px 4px 0px',
    },
    addQuestion: {
      marginTop: 10,
      marginLeft: 15,
    },
    trashComponent: {
      textAlign: 'center',
    },
  };

  return (
    <div style={{ ...styles.component, ...styles.menuComponent }}>
      <div style={styles.addQuestion}>
        <TooltipButton
          isLoading={false}
          id={`plusBundle${index}`}
          text="actions.add"
          onClick={() => addQuestionBundle()}
          icon="ADD"
        />
      </div>
      <div style={styles.trashComponent}>
        {index !== 0 && (
          <TooltipButton
            isLoading={false}
            id={`up${index}`}
            text="actions.up"
            onClick={() => {
              moveQuestionBundle(bundleId, index - 1);
            }}
            icon="UP"
          />
        )}
      </div>
      <div style={styles.trashComponent}>
        <DeleteAction
          deletePosition={() => {
            setBundleInDelete(bundleId);
          }}
          index={index}
          count={bundlesLength}
        />
      </div>
      {/* <div style={styles.trashComponent}>
                                    <TooltipButton isLoading={false} id={"duplicate" + index} text="actions.duplicate"
                                                   onClick={() => {
                                                       alert("Funktion noch nicht verfügbar.")
                                                   }} icon="DUPLICATE"/>
                                </div> */}
      <div style={styles.trashComponent}>
        {index !== bundlesLength - 1 && (
          <TooltipButton
            isLoading={false}
            id={`down${index}`}
            text="actions.down"
            onClick={() => {
              moveQuestionBundle(bundleId, index + 1);
            }}
            icon="DOWN"
          />
        )}
      </div>
    </div>
  );
}
