import { AppShell, Aside, Group, Navbar, ScrollArea } from '@mantine/core';
import { useContext, useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { IconChevronsLeft, IconChevronsRight } from '@tabler/icons';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import { WindowContext } from '../../Service/Context/WindowContext';
import PageHeader from '../../Templates/Navigation/PageHeader';
import { ResourceDetailsContextProvider } from '../../Service/Context/ResourceDetailsContext';
import ResourceNavBar from './ResourceNavBar';
import ResourceAsideBar from './aside/ResourceAsideBar';


const ResourcePage = () => {
  const { sm, md } = useContext(WindowContext);
  const [navBarOpen, setNavBarOpen] = useState(false);
  const [asideBarOpen, setAsideBarOpen] = useState(false);
  const [asideBarCollapsed, setAsideBarCollapsed] = useState(md);

  useEffect(() => {
    document.title = 'Ressource';
  }, []);

  useEffect(() => {
    if (navBarOpen && asideBarOpen) {
      setAsideBarOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navBarOpen]);

  return (
    <ResourceDetailsContextProvider>
      <AppShell
        header={
          <PageHeader
            title="Ressource"
            navBarOpen={navBarOpen}
            setNavBarOpen={setNavBarOpen}
            rightSection={
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <div>
                {asideBarOpen ? (
                  <IconChevronsRight onClick={() => setAsideBarOpen(false)} />
                ) : (
                  <IconChevronsLeft onClick={() => setAsideBarOpen(true)} />
                )}
              </div>
            }
          />
        }
        navbar={
          <div>
            {sm ? (
              <Navbar hiddenBreakpoint="sm" hidden={!navBarOpen} width={{ sm: 220, md: 250 }}>
                <ScrollArea>
                  <ResourceNavBar closeNavBar={() => setNavBarOpen(false)} />
                </ScrollArea>
              </Navbar>
            ) : (
              <DoubleNavbar>
                <ResourceNavBar closeNavBar={() => setNavBarOpen(false)} />
              </DoubleNavbar>
            )}
          </div>
        }
        aside={
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {(!sm || asideBarOpen) && (
              <Aside
                p="md"
                hiddenBreakpoint="md"
                width={{ sm: asideBarCollapsed ? 60 : 275, lg: asideBarCollapsed ? 60 : 350 }}
                sx={{ overflow: 'auto' }}
              >
                {!sm && (
                  <div style={{ cursor: 'pointer' }}>
                    {asideBarCollapsed ? (
                      <IconChevronsLeft onClick={() => setAsideBarCollapsed(false)} style={{ cursor: 'pointer' }} />
                    ) : (
                      <Group position="right" mb={10}>
                        <IconChevronsRight onClick={() => setAsideBarCollapsed(true)} style={{ cursor: 'pointer' }} />
                      </Group>
                    )}
                  </div>
                )}
                {(!asideBarCollapsed || asideBarOpen) && <ResourceAsideBar />}
              </Aside>
            )}
          </>
        }
      >
        <Outlet />
      </AppShell>
    </ResourceDetailsContextProvider>
  );
};

export default ResourcePage;
