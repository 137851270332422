import { showNotification, cleanNotifications } from '@mantine/notifications';
import { ViolationS } from './ViolationS';

const success = (id: string, message: string, title?: string) => {
  showNotification({
    id,
    title,
    message,
    color: 'teal',
  });
};

const info = (id: string, message: string, title?: string) => {
  showNotification({
    id,
    title,
    message,
  });
};

const warn = (id: string, message: string, title?: string) => {
  showNotification({
    id,
    title,
    message,
    color: 'orange',
  });
};

const error = (id: string, message: string, title?: string) => {
  showNotification({
    id,
    title,
    message,
    color: 'red',
  });
};

const cleanAll = () => cleanNotifications();

const generalError = () =>
  ToastS.error('general-err', 'Upps etwas ist schief gelaufen 🙁 Bitte versuchen Sie es später');

const interceptError = (httpResponse: Response) => {
  httpResponse.json().then((resJSON) => {
    if (resJSON && resJSON.violation) {
      const violation = ViolationS.getViolation(resJSON.violation);
      const { description } = violation;
      if (description) {
        error('err-violation', description);
      }
    } else {
      generalError();
    }
  });
};

// eslint-disable-next-line import/prefer-default-export
export const ToastS = {
  success,
  info,
  warn,
  error,
  cleanAll,
  generalError,
  interceptError,
};
