import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IconSearch } from '@tabler/icons';
import SearchTextInputField from '../SearchTextInputField';
import { searchEmails, searchEmailsCancelable } from '../../Service/restapi/messageService';
import EmailSearchListItem from './EmailSearchListItem';

const EmailSearchTextInputField = () => {
  const [searchString, setSearchString] = useState('');
  const navigate = useNavigate();

  function onSelect(selectedSuggestion) {
    if (selectedSuggestion.email) {
      // second call has only label and no email -> rm second call if possible
      navigate(`/postbox/search?query=${searchString}&emailId=${selectedSuggestion.email.id}`);
    }
  }

  function search(query, signal) {
    return searchEmailsCancelable(query, signal).then((resp) => {
      if (resp.status === 200) {
        return resp.data.map((e) => ({
          label: e.subject,
          value: `${e.subject} ${e.message}`,
          email: e,
        }));
      }
      console.error(`could not search emails by search-string ${searchString}`);
      return null;
    });
  }

  return (
    <SearchTextInputField
      icon={<IconSearch size={16} />}
      search={search}
      searchString={searchString}
      onChange={setSearchString}
      onSelect={onSelect}
      placeholder="E-Mails durchsuchen..."
      autocompleteItem={EmailSearchListItem}
      clearable
      inlinePlaceholder
      sx={{
        '.mantine-Autocomplete-dropdown': {
          width: '300px !important',
          left: '0 !important',
        },
      }}
    />
  );
};

export default EmailSearchTextInputField;
