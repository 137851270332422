// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Group, ThemeIcon, Switch, Text, LoadingOverlay, Button } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { IconCheck, IconInfoCircle, IconTrash } from '@tabler/icons';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LexOfficeConfig, LexOfficeS } from '../../../../Service/LexOfficeS';

interface Props {
  config: LexOfficeConfig;
  updateConfig: (patch: Partial<LexOfficeConfig>) => void;
  refresh: () => void;
}

const LexOfficeOAuthPane: FC<Props> = ({ config, updateConfig, refresh }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { connected, useBookitupPDF, userEmailOAuth, remainingDays } = config;

  const revokeOAuth = () => {
    setLoading(true);
    LexOfficeS.revokeOAuth()
      .then((revoked) => revoked && refresh())
      .finally(() => setLoading(false));
  };

  const confirmRevokeOAuth = () => {
    openConfirmModal({
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          lexoffice Verbindung trennen
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      labels: { confirm: 'Ja', cancel: 'Abbrechen' },
      children: <Text>Möchtest du wirklich die lexoffice Verbindung trennen?</Text>,
      onConfirm: revokeOAuth,
    });
  };

  return (
    <Box
      p="md"
      sx={{
        position: 'relative',
        borderRadius: 10,
        backgroundColor: '#F3F3F3',
        maxWidth: 380,
        minHeight: 293,
        border: connected && useBookitupPDF ? '2px solid #1D5191' : 'none',
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'lg' }} />
      <Group position="apart">
        <Group spacing={5}>
          <Text weight="bolder">lexoffice Anmeldung</Text>
          {(connected && useBookitupPDF) && <ThemeIcon radius="xl" size="xs">
            <IconCheck/>
          </ThemeIcon>}
        </Group>
        <Switch
          disabled={!connected}
          checked={connected && useBookitupPDF}
          onChange={(event) => updateConfig({ useBookitupPDF: event.currentTarget.checked })}
        />
      </Group>
      <Group spacing={5} ml={-2}>
        <div>
          <IconInfoCircle size={16} color="green" />
        </div>
        <Text size="xs" mt={5}>
          Funktioniert mit allen lexoffice Paketen.
        </Text>
      </Group>
      {connected ? (
        <>
          <Text weight="bolder" mt="md">
            Dein Account
            <Text span color="blue">
              {` ${userEmailOAuth} `}
            </Text>
            ist verbunden.
            {remainingDays < 30 && (
              <Text>
                Die Verbindung muss in
                <Text span color="blue">
                  {` ${remainingDays} `}
                </Text>
                Tagen erneuert werden.
              </Text>
            )}
          </Text>
          <Text variant="link" mb="xl" mt="xs" color="dimmed" sx={{ cursor: 'pointer' }} onClick={confirmRevokeOAuth}>
            <IconTrash size={16} color="gray" style={{ marginTop: -2 }} />
            <Text span color="dimmed" size="xs" ml={5}>
              Verbindung löschen
            </Text>
          </Text>
        </>
      ) : (
        <Button mt="md" mb="md" onClick={() => LexOfficeS.startOAuthProcess()}>
          Account verbinden
        </Button>
      )}
      <Text size="xs" weight="bolder" mb="sm">
        bookitup Rechnungen
      </Text>
      <Text size="xs">
        Die Rechnungen werden von bookitup erstellt und an LexOffice gesendet. Das Layout kannst Du direkt in bookitup
        unter{' '}
        <Text span variant="link" onClick={() => navigate('/settings/documents')} sx={{ cursor: 'pointer' }}>
          Einstellungen &gt; Dokumente &gt; Dokument-Layout
        </Text>{' '}
        bearbeiten. Fortlaufende Dokument-Nummern werden von bookitup verwendet.
      </Text>
    </Box>
  );
};

export default LexOfficeOAuthPane;
