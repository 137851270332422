import { Menu } from '@mantine/core';
import { IconDotsVertical, IconSettings, IconTrash } from '@tabler/icons';
import { FC, useContext } from 'react';
import { TestS } from '../../../../TestS';
import { GoogleCalendarDto } from '../../../../Types/GoogleT';
import { ViolationContext } from '../../../../Service/Context/ViolationContext';
import { openDeleteGoogleCalendarModal } from './DeleteGoogleCalendarModal';
import { openGoogleCalendarConfigModal } from './GoogleCalendarConfigModal';

interface Props {
  googleCalendar: GoogleCalendarDto;
  removeHandler: () => void;
  updateHandler: (patch: Partial<GoogleCalendarDto>) => void;
}

const GoogleCalendarActions: FC<Props> = ({ googleCalendar, removeHandler, updateHandler }) => {
  const { executeWithLicenceCheck } = useContext(ViolationContext);

  return (
    <Menu withArrow shadow="md" width={175} transition="scale-y" position="bottom-end">
      <Menu.Target>
        <div>
          <IconDotsVertical
            color="gray"
            style={{ cursor: 'pointer' }}
            data-testid={TestS.Documents.List.Dropdown.OPEN}
          />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          icon={<IconSettings size={16} />}
          onClick={() => openGoogleCalendarConfigModal(googleCalendar, updateHandler, executeWithLicenceCheck)}
        >
          Einstellungen
        </Menu.Item>
        <Menu.Item
          color="red"
          icon={<IconTrash size={16} />}
          onClick={() => openDeleteGoogleCalendarModal(googleCalendar, removeHandler)}
        >
          Löschen
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default GoogleCalendarActions;
