// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Skeleton, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { openModal } from '@mantine/modals';
import { i18n } from '@lingui/core';
import { useElementSize } from '@mantine/hooks';
import { RecruitmentCampaign, RecruitmentS } from '../../../Service/restapi/recruitmentService';
import { ChangeListS } from '../../../Service/helper/ChangeListS';
import PanePaper from '../../../Molecules/PanePaper';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import BookitupTable from '../../../Atoms/BookitupTable';
import RecruitmentCampaignListItem from './RecruitmentCampaignListItem';
import CreateCampaignModal from './CreateCampaignModal';

const RecruitmentCampaignList = () => {
  const { width, ref } = useElementSize();
  const [recruitmentCampaigns, setRecruitmentCampaigns] = useState<RecruitmentCampaign[]>([]);
  const [loading, setLoading] = useState(false);
  const { length } = recruitmentCampaigns;
  const showRegistrations = width > 470;
  const showSales = width > 370;

  const createCampaign = () => {
    openModal({
      title: (
        <Text mt="md" weight="bolder" size="lg" align="center" sx={{ lineHeight: 1.2 }}>
          {i18n._('recruitment-campaign.create.subject')}
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: (
        <CreateCampaignModal addCampaign={(campaign) => setRecruitmentCampaigns((prev) => [...prev, campaign])} />
      ),
    });
  };

  useEffect(() => {
    setLoading(true);
    RecruitmentS.getRecruitmentCampaigns()
      .then((campaigns) => campaigns && setRecruitmentCampaigns(campaigns))
      .finally(() => setLoading(false));
  }, []);

  const handleChange = (patch: RecruitmentCampaign) =>
    ChangeListS.changeItemInList(recruitmentCampaigns, setRecruitmentCampaigns, patch);

  return (
    <PanePaper
      title="recruitment-campaign.links"
      rightSection={<BookitupAddIcon onClick={createCampaign} />}
      sx={{ marginBottom: 75 }}
    >
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <div ref={ref}>
          {length === 0 ? (
            <Text italic color="dimmed" size="xs" weight="lighter">
              Noch nichts hinzugefügt.
            </Text>
          ) : (
            <BookitupTable>
              <thead>
                <tr>
                  <th style={{ width: 200 }}>Name </th>
                  {showRegistrations && <th style={{ width: 100 }}>Registrierungen</th>}
                  {showSales && <th>Erhalten</th>}
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {recruitmentCampaigns.map((campaign) => (
                  // eslint-disable-next-line react/jsx-key
                  <RecruitmentCampaignListItem
                    campaign={campaign}
                    showRegistrations={showRegistrations}
                    showSales={showSales}
                    handleChange={handleChange}
                  />
                ))}
              </tbody>
            </BookitupTable>
          )}
        </div>
      )}
    </PanePaper>
  );
};

const LoadingSkeleton = () => (
  <>
    {Array.from(Array(3)).map(() => (
      <Skeleton key={Math.random()} radius="md" height={30} width="100%" mt="sm" />
    ))}
  </>
);

export default RecruitmentCampaignList;
