import React, { useContext, useState } from 'react';
import { Trans, useLingui } from '@lingui/react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mantine/core';
import { TransButtonDefault, TransButtonPrimaryWithSpinner } from '../../Atoms/Buttons';
import { ModalDialog } from '../Dialogs';
import { SupportVideo } from '../Support/SupportVideo';
import { deleteEvents } from '../../Service/restapi/eventService';
import { CheckBox } from '../../Atoms/InputFields';
import { InputHelp } from '../../Atoms/inputs/InputHelp';
import { ToastS } from '../../Service/ToastS';
import { EventContext } from '../../Service/Context/EventContext';
import { TestS } from '../../TestS';

const EventHeadActions = () => {
  const { i18n } = useLingui();
  const { event } = useContext(EventContext);
  const { id } = event;
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [error, setError] = useState(undefined);
  const navigate = useNavigate();

  function deleteHandler(id) {
    if (!confirm) {
      setError('Bestätige erst die Löschung der E-Mails und Dokumente');
    } else {
      setLoading(true);
      deleteEvents([id])
        .then((resp) => {
          if (resp.ok) {
            setModalOpen(!modalOpen);
            navigate('/');
            ToastS.success('event.delete', i18n._('actions.event.delete.success'));
          } else {
            console.error(`could not delete event ${id}`);
            ToastS.error(`event.delete.${resp.status}.title`, i18n._(`errors.${resp.status}.boxed.title`));
            ToastS.error(`event.delete.${resp.status}.description`, i18n._(`errors.${resp.status}.boxed.description`));
          }
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not delete event ${id}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  return (
    <>
      {id !== '' && (
        <Button
          color="red"
          variant="subtle"
          sx={{ backgroundColor: '#FFEFEF', '&:hover': { color: 'white' } }}
          onClick={() => setModalOpen(true)}
          data-testid={TestS.Events.Delete.DELETE}
        >
          Event löschen
        </Button>
      )}
      <ModalDialog
        header={<Trans id="common.delete.event.confirm.head" />}
        body={
          <>
            <Trans id="common.delete.event.confirm" />

            <div style={{ marginTop: 10, marginBottom: 10 }}>
              Beim Löschen des Events, werden die im Event angezeigten E-Mails und Dokumente ebenfalls gelöscht.
            </div>
            <CheckBox
              checked={() => setConfirm(!confirm)}
              initialValue={confirm}
              text="Bestätige die Löschung von Dokumenten und E-Mails"
              testId={TestS.Events.Delete.CHECKBOX_LOSE_DOCUMENTS_EMAILS}
            />

            {error ? <InputHelp errorDescription={error} /> : null}
          </>
        }
        footer={
          <div>
            <TransButtonPrimaryWithSpinner
              isLoading={loading}
              id="deleteEvent"
              onClickHandler={() => {
                deleteHandler(id);
              }}
              text="actions.delete"
              testId={TestS.Events.Delete.CONFIRM}
            />
            <TransButtonDefault onClickHandler={() => setModalOpen(!modalOpen)} text="actions.cancel" />
          </div>
        }
        show={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
      />
      <SupportVideo entityId={3} fileName="Event-Details" />
    </>
  );
};

export default EventHeadActions;
