import { ActionIcon, Group, Menu, Tooltip } from '@mantine/core';
import {
  IconArrowBackUp,
  IconBallpen,
  IconCalendarPlus,
  IconDeviceFloppy,
  IconDots,
  IconEyeCheck,
  IconEyeOff,
  IconRefresh,
  IconTrash,
} from '@tabler/icons';
import { FC, useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { EmailBoxContext } from '../../Service/Context/EmailBoxContext';
import { MessageS } from '../../Service/MessageS';
import { getCustomerId } from '../../Service/restapi/contactService';
import { saveEventParticipation } from '../../Service/restapi/eventService';
import { useDisclosure } from '@mantine/hooks';
import { ModalDialog } from '../Dialogs';
import EventSearchAutocomplete from '../../Organismns/Event/EventSearchAutocomplete';
import { TransButtonDefault, TransButtonPrimaryWithSpinner } from '../../Atoms/Buttons';
import { openCreateTemplateModal, openUpdateTemplateModal } from '../../Organismns/Message/MessageModals';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import { LicenceS } from '../../Service/LicenceS';
import { PostboxCountersContext } from '../../Service/Context/PostboxCountersContext';

const MessageActions: FC<{ phoneVersion: boolean }> = ({ phoneVersion }) => {
  const { selectedMessage, changeEmail, prepareMessage, refresh } = useContext(EmailBoxContext);
  const { refreshCount } = useContext(PostboxCountersContext);
  const { box } = useParams();
  const [opened, { toggle }] = useDisclosure(false);
  const [selectedEventId, setSelectedEventId] = useState(undefined);
  const { executeWithLicenceCheck } = useContext(ViolationContext);

  if (!selectedMessage) {
    return null;
  }
  const { id, readTimestamp, localDateTime } = selectedMessage;

  const deleteHandler = async () => {
    const deleted = await MessageS.deleteEmail(id);
    if (deleted) {
      refresh();
      refreshCount('trash');
    }
  };

  const trashHandler = async (trash: boolean) => {
    const email = await MessageS.patchEmail(id, { trash });
    if (email) {
      refresh();
      refreshCount(box ?? '');
      refreshCount('trash');
    }
  };

  const markAsReadHandler = async (read: boolean) => {
    const patchedEmail = await MessageS.patchEmail(id, { read });
    if (patchedEmail) {
      changeEmail(patchedEmail);
    }
  };

  const assignContactToEvent = async () => {
    const patchedEmail = await MessageS.patchEmail(id, { eventId: selectedEventId });
    if (patchedEmail) {
      changeEmail(patchedEmail);
      toggle();
    }

    let participation: any = {
      eventId: selectedEventId,
      eventRole: 'CUSTOMER',
    };
    const customerId = getCustomerId(selectedMessage);

    if (customerId) {
      participation = {
        ...participation,
        contactId: customerId,
      };
    }
    saveEventParticipation(participation);
  };

  const createTemplate = () =>
    executeWithLicenceCheck(LicenceS.Restrictions.MULTIPLE_EMAIL_TEMPLATE, () =>
      openCreateTemplateModal({ messageId: id }),
    );

  const showReply = box === 'inbox';
  const showEdit = ['scheduled', 'failed', 'drafts'].includes(box ?? '') && !localDateTime;
  const showMarkAsRead = !readTimestamp && showReply;
  const showMarkAsUnread = readTimestamp && showReply;

  return (
    <Menu radius="sm" shadow="md" transition="scale-y" position="bottom-end">
      <Group>
        {!phoneVersion && (
          <>
            {showReply && (
              <Tooltip openDelay={300} withArrow transition="slide-down" label="Antwort" position="bottom">
                <ActionIcon onClick={() => prepareMessage('REPLY')} color="blue" variant="filled">
                  <IconArrowBackUp size={20} />
                </ActionIcon>
              </Tooltip>
            )}
            {showMarkAsRead && (
              <Tooltip
                openDelay={300}
                withArrow
                transition="slide-down"
                label="als gelesen markieren"
                position="bottom"
              >
                <ActionIcon onClick={() => markAsReadHandler(true)}>
                  <IconEyeCheck size={20} />
                </ActionIcon>
              </Tooltip>
            )}
            {showMarkAsUnread && (
              <Tooltip
                openDelay={300}
                withArrow
                transition="slide-down"
                label="als unerledigt Markieren"
                position="bottom"
              >
                <ActionIcon onClick={() => markAsReadHandler(false)}>
                  <IconEyeOff size={20} />
                </ActionIcon>
              </Tooltip>
            )}
            {showEdit && (
              <Tooltip openDelay={300} withArrow transition="slide-down" label="Bearbeiten" position="bottom">
                <ActionIcon onClick={() => prepareMessage('EDIT')} color="blue" variant="filled">
                  <IconBallpen size={20} />
                </ActionIcon>
              </Tooltip>
            )}
          </>
        )}
        <Menu.Target>
          <ActionIcon>
            <IconDots color="gray" style={{ cursor: 'pointer' }} />
          </ActionIcon>
        </Menu.Target>
      </Group>
      <Menu.Dropdown>
        {showReply && (
          <Menu.Item icon={<IconArrowBackUp size={16} />} onClick={() => prepareMessage('REPLY')}>
            Antwort
          </Menu.Item>
        )}
        {showMarkAsRead && (
          <Menu.Item icon={<IconEyeCheck size={16} />} onClick={() => markAsReadHandler(true)}>
            als gelesen markieren
          </Menu.Item>
        )}
        {showEdit && (
          <Menu.Item icon={<IconBallpen size={16} />} onClick={() => prepareMessage('EDIT')}>
            Bearbeiten
          </Menu.Item>
        )}
        {!selectedMessage.eventId && (
          <Menu.Item icon={<IconCalendarPlus size={16} />} onClick={toggle}>
            Event zuweisen
          </Menu.Item>
        )}
        {showMarkAsUnread && (
          <Menu.Item icon={<IconEyeOff size={16} />} onClick={() => markAsReadHandler(false)}>
            als unerledigt Markieren
          </Menu.Item>
        )}
        {!localDateTime && (
          <>
            <Menu.Item
              icon={<IconRefresh size={16} />}
              onClick={() => openUpdateTemplateModal({ email: selectedMessage })}
            >
              Vorlage aktualisieren
            </Menu.Item>
            <Menu.Item icon={<IconDeviceFloppy size={16} />} onClick={createTemplate}>
              Als Vorlage speichern
            </Menu.Item>
          </>
        )}
        {box === 'trash' && (
          <>
            <Menu.Item onClick={() => trashHandler(false)}>Wiederherstellen</Menu.Item>
            <Menu.Item icon={<IconTrash size={16} />} color="red" onClick={deleteHandler}>
              Endgültig löschen
            </Menu.Item>
          </>
        )}
        {box !== 'trash' && (
          <Menu.Item icon={<IconTrash size={16} />} color="red" onClick={() => trashHandler(true)}>
            Löschen
          </Menu.Item>
        )}
      </Menu.Dropdown>
      <ModalDialog
        header="Event zuweisen"
        body={<EventSearchAutocomplete selectEvent={(bookingEvent: any) => setSelectedEventId(bookingEvent.id)} />}
        footer={
          <div>
            <TransButtonPrimaryWithSpinner text="actions.save" icon="SAVE" onClickHandler={assignContactToEvent} />{' '}
            <TransButtonDefault onClickHandler={toggle} text="actions.close" />
          </div>
        }
        show={opened}
        toggle={toggle}
      />
    </Menu>
  );
};

export default MessageActions;
