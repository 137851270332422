import { useContext } from 'react';
import { Skeleton, Stack, Text } from '@mantine/core';
import { CustomAttributesContext } from '../../../Service/Context/CustomAttributeContext';
import CustomAttributeGroup from './CustomAttributeGroup';

const EventCustomAttributeList = ({ customAttributeFormatter }) => {
  const { attributeGroups, refreshGroups, refreshValue, entity, entityType } = useContext(CustomAttributesContext);

  if (!attributeGroups) {
    return <Skeleton width="100%" height={50} />;
  }

  return (
    <div>
      {attributeGroups.length === 0 ? (
        <Text italic color="dimmed" size="xs" weight="lighter">
          Noch nichts hinzugefügt.
        </Text>
      ) : (
        <Stack spacing={5}>
          {attributeGroups.map((group) => (
            <CustomAttributeGroup
              key={group.id}
              group={group}
              entityType={entityType}
              entityId={entity.id}
              customAttributeFormatter={customAttributeFormatter}
              attributeGroups={attributeGroups}
              refreshGroups={refreshGroups}
              refreshValue={refreshValue}
            />
          ))}
        </Stack>
      )}
    </div>
  );
};

export default EventCustomAttributeList;
