import { useEffect } from 'react';
import ReactGA from 'react-ga4';

// eslint-disable-next-line import/prefer-default-export
export const useGoogleAnalytics = () => {
  const gaId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

  useEffect(() => {
    if (gaId) {
      ReactGA.initialize(gaId);
    } else {
      // eslint-disable-next-line no-console
      console.warn('Google analytics is disabled for this env');
    }
  }, [gaId]);
};
