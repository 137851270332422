import { CookiesProvider, withCookies } from 'react-cookie';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { WindowContextProvider } from './Service/Context/WindowContext';
import { FocusedNodeContextProvider } from './Service/Context/FocusedNode';
import { NavigationContextProvider } from './Service/Context/NavigationContext';
import ApplicationRoutes, { ModalRoutes } from './AppRoutes';
import './app.scss';
import { AgencyModeContextProvider } from './Service/Context/AgencyModeContext';
import { AuthContextProvider } from './Service/Context/AuthContext';
import CustomMantineTheme from './Theme/MantineTheme';
import { useRocketLog } from './Service/hooks/useRocketLog';
import useHubspot from './Service/hooks/useHubspot';
import { useGoogleTagManager } from './Service/hooks/useGoogleTagManager';
import { useFacebookPixel } from './Service/hooks/useFacebookPixel';
import { useGoogleAnalytics } from './Service/hooks/useGoogleAnalytics';
import { useMixpanel } from './Service/hooks/useMixpanel';

export const App = () => {
  const WithMaCookies = withCookies((props) => {
    let locale = props.cookies.get('locale');
    if (locale === undefined) {
      locale = 'de';
    }
    return (
      <MantineProvider theme={CustomMantineTheme}>
        <AuthContextProvider>
          <RoutesProxy />
        </AuthContextProvider>
      </MantineProvider>
    );
  });

  return (
    <DndProvider backend={HTML5Backend}>
      <CookiesProvider>
        <WithMaCookies />
      </CookiesProvider>
    </DndProvider>
  );
};

const AppRoutes = (props) => {
  useRocketLog();
  useHubspot();
  useGoogleTagManager();
  useGoogleAnalytics();
  useFacebookPixel();
  useMixpanel();

  if (props.cookies.get('locale') === undefined) {
    props.cookies.set('locale', 'de');
  }

  return (
    <WindowContextProvider>
      <FocusedNodeContextProvider>
        <AgencyModeContextProvider>
          <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
            <ModalsProvider
              modals={ModalRoutes}
              modalProps={{ closeOnEscape: true, closeOnClickOutside: false, transition: 'slide-down' }}
            >
              <NavigationContextProvider>
                <DndProvider backend={HTML5Backend}>
                  <ApplicationRoutes />
                </DndProvider>
              </NavigationContextProvider>
            </ModalsProvider>
          </GoogleOAuthProvider>
        </AgencyModeContextProvider>
      </FocusedNodeContextProvider>
    </WindowContextProvider>
  );
};

export const RoutesProxy = withCookies(AppRoutes);
