import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EditableProperty } from './EditableProperty';

export const EditableQuestionnaireProperty = ({
  entity,
  preChange,
  changeHandler,
  patchField,
  placeholder,
  editFormatter,
  labelFormatter,
  name,
  ...rest
}) => {
  let params = '';
  if (typeof entity.id.includes === 'function') {
    if (entity.id.includes('-')) {
      params += 'templates/';
    }
  }
  params += entity.id;

  return (
    <EditableProperty
      dto={entity}
      changeHandler={changeHandler}
      preChange={preChange}
      patchField={patchField}
      placeholder={placeholder}
      targetUrl={`${process.env.REACT_APP_QUESTIONNAIRE_SERVICE_URL}/questionnaires/${params}`}
      editFormatter={editFormatter}
      labelFormatter={labelFormatter}
      name={name}
      {...rest}
    />
  );
};

EditableQuestionnaireProperty.propTypes = {
  patchField: PropTypes.string.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lastModified: PropTypes.string.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  preChange: PropTypes.func,
  changeHandler: PropTypes.func,
};
