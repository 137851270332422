// eslint-disable-next-line no-redeclare
import { Box, Flex, Group, Space, Stack, Text } from '@mantine/core';
import { Trans } from '@lingui/react';
import { useContext, useEffect, useState } from 'react';
import { IconTrashX } from '@tabler/icons';
import { useElementSize } from '@mantine/hooks';
import { TransButtonDefault, TransButtonPrimaryWithSpinner } from '../../../../Atoms/Buttons';
import { ModalDialog, useConfirmModalState } from '../../../../Molecules/Dialogs';
import {
  deleteFavoritePosition,
  fetchFavoritePositions,
  putFavoritePosition,
} from '../../../../Service/restapi/positionService';
import { Position } from '../../../../Molecules/Document/Position/Position';
import PanePaper from '../../../../Molecules/PanePaper';
import BookitupAddIcon from '../../../../Atoms/BookitupAddIcon';
import PositionHeader from '../../../../Molecules/Document/Position/PositionHeader';
import { ProductPositionPreview } from '../../../../Molecules/Document/Position/PositionPreview';
import SkeletonItems from '../../../../Atoms/SkeletonItems';
import { ProductPositionPreviewActions } from '../../../../Molecules/Document/Position/PositionActions';
import { ViolationContext } from '../../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../../Service/LicenceS';
import { RoutingS } from '../../../../Service/RoutingS';
import FavoritePosition from "./FavoritePosition";

const PositionsTable = () => {
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const { ref, width } = useElementSize();
  const previewVersion = width < 900;

  useEffect(() => {
    RoutingS.changeTitle('Vordefinierte Produkte');
    setLoading(true);
    fetchFavoritePositions().then((resp) => {
      resp
        .json()
        .then((resJSON) => {
          setPositions(resJSON);
        })
        .finally(() => setLoading(false));
    });
  }, []);

  const updatePosition = (idx, position) => {
    let newPositions = [...positions];
    newPositions[idx] = position;
    setPositions(newPositions);
  };

  function openDialog(id) {
    setSelectedPosition(id);
    toggleConfirm();
  }

  function addFavoritePosition() {
    putFavoritePosition({}).then((resp) => {
      if (resp.ok) {
        resp.json().then((pos) => {
          const tmpPositions = Object.assign([], positions);
          tmpPositions.push(pos);
          setPositions(tmpPositions);
        });
      }
    });
  }

  function unfavorite() {
    setLoading(true);
    deleteFavoritePosition(selectedPosition)
      .then((resp) => {
        if (resp.ok) {
          fetchFavoritePositions().then((resp) => {
            resp.json().then((positions) => {
              setPositions(positions);
              setLoading(false);
              toggleConfirm();
            });
          });
        } else {
          console.error(`could not delete position ${selectedPosition}`);
        }
      })
      .catch(() => {});
  }

  const mapPositionUI = (position, index) => {
      return <FavoritePosition position={position} index={index} openDialog={openDialog} updatePosition={updatePosition} width={width} />
  };

  const { executeWithLicenceCheck } = useContext(ViolationContext);

  return (
    <div ref={ref}>
      <PanePaper
        title="positions.favourites"
        tooltip="licence.position.favourite.pane"
        rightSection={
          <BookitupAddIcon
            onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.PRODUCT_USAGE, addFavoritePosition)}
          />
        }
      >
        <Text color="dimmed" mb="md" size="sm">
          Hinterlege hier vordefinierte Produkte, um diese z.B. Dein Angebot schnell per Autocomplete einzufügen.
        </Text>
        {isLoading ? (
          <SkeletonItems />
        ) : (
          <div>
            {positions.length > 0 ? (
              <>
                <PositionHeader desktopVersion={!previewVersion} />
                <Space h="md" />
                <Stack> {positions.map(mapPositionUI)} </Stack>
              </>
            ) : (
              <Stack>
                <Box
                  sx={(theme) => ({
                    width: '100%',
                    height: 50,
                    backgroundColor: theme.colors.gray[0],
                    borderRadius: 10,
                  })}
                />
                <Box
                  sx={(theme) => ({
                    width: '100%',
                    height: 100,
                    backgroundColor: theme.colors.gray[0],
                    borderRadius: 10,
                  })}
                />
                <Text sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
                  Sie haben keine Lieblingspositionen. Klicke auf
                  <Space w={5} />
                  <BookitupAddIcon
                    onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.PRODUCT_USAGE, addFavoritePosition)}
                  />
                  <Space w={2} />, um zu beginnen.
                </Text>
              </Stack>
            )}
          </div>
        )}

        <ModalDialog
          header={<Trans id="position.delete.confirm.head" />}
          body={<Trans id="position.delete.confirm" />}
          footer={
            <div>
              <TransButtonPrimaryWithSpinner isLoading={isLoading} onClickHandler={unfavorite} text="actions.yes" />{' '}
              <TransButtonDefault onClickHandler={toggleConfirm} text="actions.cancel" />
            </div>
          }
          show={showConfirm}
          toggle={toggleConfirm}
        />
      </PanePaper>
    </div>
  );
};

export default PositionsTable;
