import {
  Anchor,
  Breadcrumbs,
  Center,
  Container,
  Divider,
  Group,
  Loader,
  Tabs,
} from '@mantine/core';
import { IconCode, IconEye, IconForms, IconSettings } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { BookitupFormContext } from '../../../Service/Context/BookitupFormContext';
import ScheduleMeetingAlert from './ScheduledMettingAlert';
import FormSettings from './tabs/FormSettings';
import FormBuilder from './tabs/FormBuilder';
import ContactFormActions from './ContactFormActions';
import FormCode from './tabs/FormCode';
import FormPreview from './tabs/FormPreview';
import { enrichFormElementsWithCustomAttributes } from './utils/FormElementsStore';

const ContactForm = () => {
  const { bookitupForm, builderKey, loading } = useContext(BookitupFormContext);
  const navigate = useNavigate();
  const [toolbarItems, setToolbarItems] = useState<unknown>();

  useEffect(() => {
    enrichFormElementsWithCustomAttributes().then(setToolbarItems);
  }, []);

  if (loading || !bookitupForm) {
    return (
      <Center>
        <Loader size="xl" pos="absolute" top="40%" />
      </Center>
    );
  }
  const { name } = bookitupForm;

  return (
    <Container p={0}>
      <Group position="apart" sx={{ alignItems: 'center' }}>
        <Breadcrumbs>
          <Anchor onClick={() => navigate('/settings/contact-form')}>Kontaktformulare</Anchor>
          <Anchor sx={{ pointerEvents: 'none' }}>
            <b>{name}</b>
          </Anchor>
        </Breadcrumbs>
        <ContactFormActions showTutorialIcon form={bookitupForm} reload={() => {}} />
      </Group>
      <Divider my="sm" />
      <ScheduleMeetingAlert />
      <Tabs
        mt="sm"
        keepMounted={false}
        defaultValue="Einstellungen"
        styles={{
          tab: {
            '&[data-active]': {
              fontWeight: 'bolder',
            },
          },
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="Einstellungen" icon={<IconSettings size={14} />}>
            Einstellungen
          </Tabs.Tab>
          <Tabs.Tab value="Formular" icon={<IconForms size={14} />}>
            Formular
          </Tabs.Tab>
          <Tabs.Tab value="Vorschau" icon={<IconEye size={14} />}>
            Webseitenansicht
          </Tabs.Tab>
          <Tabs.Tab value="Code" icon={<IconCode size={14} />}>
            Code
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="Einstellungen" pt="xs">
          <FormSettings />
        </Tabs.Panel>
        <Tabs.Panel key={builderKey} value="Formular" pt="xs">
          <FormBuilder toolbarItems={toolbarItems}/>
        </Tabs.Panel>
        <Tabs.Panel value="Vorschau" pt="xs">
          <FormPreview />
        </Tabs.Panel>
        <Tabs.Panel value="Code" pt="xs">
          <FormCode />
        </Tabs.Panel>
      </Tabs>
    </Container>
  );
};

export default ContactForm;
