import Moment from 'react-moment';
import { Box, Divider, Group, Stack, Text } from '@mantine/core';
import { IconPaperclip } from '@tabler/icons';
import { MessageS } from '../../Service/MessageS';
import Dot from '../../Atoms/Dot';
import MessagePlaceholderText from '../../Atoms/MessagePlaceholderText';
import { useParams } from 'react-router-dom';

const EmailHistoryListItem = ({ email, onClick, active }) => {
  const { id: eventId } = useParams();

  const unread = email.localDateTime && !email.readTimestamp && !email.sendByUser;

  let contact = email.sendByUser ? (email.to && email.to.length > 0 ? email.to[0] : {}) : email.from;
  if (!contact) contact = {};

  return (
    <>
      <Stack
        p="sm"
        spacing={5}
        className="clickable"
        onClick={onClick}
        sx={{
          height: 95,
          borderLeft: active ? '5px solid #4f4f4f' : 'none',
          backgroundColor: unread ? '#E8EEF4' : 'inherit',
        }}
      >
        <Box pos="relative">
          <Group spacing="xs">
            {unread && <Dot withoutShadow size={8} colour="#1D5191" />}
            <Text className="overflow-ellipsis" size="md" weight="bolder" sx={{ width: '75%' }}>
              {contact.personDescription ? contact.personDescription : contact.email}
            </Text>
          </Group>
          <Box pos="absolute" right={0} top={0}>
            {!email.scheduledTime && (
              <Text size="xs" align="right">
                {email.localDateTime && <Moment calendar={MessageS.calendarStrings}>{email.localDateTime}</Moment>}
                {!email.localDateTime && <Moment calendar={MessageS.calendarStrings}>{email.lastModified}</Moment>}
              </Text>
            )}
            {email.scheduledTime && (
              <Text size="xs" align="right">
                <Moment fromNow calendar={MessageS.calendarStrings}>
                  {email.scheduledTime}
                </Moment>
              </Text>
            )}
          </Box>
        </Box>
        <Box pos="relative">
          {email.subject && (
            <Text weight="bolder" className="overflow-ellipsis" sx={{ width: '90%' }}>
              <MessagePlaceholderText settings={eventId === undefined} email={email} value={email.subject} />
            </Text>
          )}
          {!email.subject && (
            <Text italic color="dimmed" weight="bolder">
              Kein Betreff
            </Text>
          )}
          {((email.attachments && email.attachments.length > 0) || email.countOfAttachments > 0) && (
            <Box pos="absolute" right={0} top={-5}>
              <IconPaperclip size={16} color="gray" />
            </Box>
          )}
        </Box>
        <Text color="dimmed" size="xs" className="overflow-ellipsis">
          <MessagePlaceholderText settings={eventId === undefined} email={email} value={email.message} />
        </Text>
      </Stack>
      <Divider />
    </>
  );
};

export default EmailHistoryListItem;
