import React, { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { Trans } from '@lingui/react';
import { useDrag, useDrop } from 'react-dnd-latest';
import { IconGripVertical } from '@tabler/icons';
import { ConfirmDialog, ModalDialog, useConfirmModalState, useModalState } from '../../Molecules/Dialogs';
import {
  deleteFormSubmission,
  deleteResource,
  fetchFormSubmissionOfResource,
} from '../../Service/restapi/shareService';
import { file } from '../../Service/FileActions';
import { TransButtonLink, TransButtonPrimary } from '../../Atoms/Buttons';
import { EventPortalContext } from '../../Service/Context/EventPortalContext';
import { ShareS } from '../../Service/ShareS';
import ResourceValues from './ResourceValues';
import FormSubmissionFiles from './FormSubmissionFiles';
import FormSubmissionModal, { openFormSubmissionModal } from './FormSubmissionModal';

const ResourceItem = ({ resource, index, setResources }) => {
  const { reload } = useContext(EventPortalContext);
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);
  const [deleteCustomerData, setDeleteCustomerData] = useState(false);
  const [formSubmission, setFormSubmission] = useState(undefined);
  const { show, toggle } = useModalState(false);
  useEffect(() => {
    fetchSubmission();
  }, [resource]);

  function delResource() {
    deleteResource(resource.id)
      .then((resp) => {
        if (resp.ok) {
          reload();
        } else {
          console.error(`could not delete resource ${resource.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not delete resource ${resource.id}`);
      });
  }

  function fetchSubmission() {
    fetchFormSubmissionOfResource(resource.id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((formSubmission) => {
            setFormSubmission(formSubmission);
          });
        } else if (resp.status === 404) {
          setFormSubmission(undefined);
          console.info('not submitted yet');
        } else {
          console.error(`could not fetch formSubmission of resource ${resource.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not fetch formSubmission of resource ${resource.id}`);
      });
  }

  function delFormSubmit() {
    deleteFormSubmission(formSubmission.id).then((resp) => {
      if (resp.ok) {
        fetchSubmission();
      }
    });
  }

  function download() {
    file
      .download(`${process.env.REACT_APP_SHARE_SERVICE_URL}/formSubmissions/${formSubmission.id}/pdf`)
      .then(() => {})
      .catch((response) => {});
  }

  const styles = {
    container: {
      flex: 1,
      display: 'flex',
      borderBottom: '1px solid #ebebeb',
      padding: '10px 20px',
      paddingLeft: 5,
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    },
    customerValues: {
      marginRight: 10,
    },
    statePoint: {
      width: 8,
      height: 8,
      backgroundColor: formSubmission && formSubmission.lastSubmitted ? '#4cd964' : '#ffb100',
      borderRadius: '50%',
    },
  };

  const [, drop] = useDrop(
    () => ({
      accept: 'resource',
      collect(monitor) {
        return { handlerId: monitor.getHandlerId() };
      },
      drop(item) {
        ShareS.changeResourceIndex(resource.shareId, item.index, index).then((resouces) => {
          if (resouces) {
            setResources(resouces);
          }
        });
      },
    }),
    [],
  );

  const [, drag] = useDrag(
    () => ({
      type: 'resource',
      item: { resource, index },
    }),
    [],
  );

  return (
    <div style={styles.container} ref={drop}>
      <div style={{ display: 'flex', alignItems: 'center', minWidth: 260 }}>
        <div style={{ marginRight: 20 }} ref={drag}>
          <IconGripVertical color="gray" size={16} style={{ cursor: 'move' }} />
        </div>
        <div style={styles.statePoint} />

        <div style={{ marginRight: 4, marginLeft: 10 }}>
          <Trans id={resource.label ? resource.label : `resource.entityType.${resource.entityType}`} />
        </div>

        {formSubmission && formSubmission.lastSubmitted && (
          <div>
            <div className="description" style={{ marginRight: 10, textAlign: 'center' }}>
              bestätigt <Moment fromNow>{formSubmission.lastSubmitted}</Moment>
            </div>
            <ModalDialog
              size="xl"
              header={<Trans id="Kundeneingaben verarbeiten" />}
              body={<FormSubmissionModal formSubmission={formSubmission} closeModal={toggle} />}
              show={show}
              toggle={toggle}
            />
          </div>
        )}
      </div>
      <div style={{ minWidth: 260, textAlign: 'right' }}>
        {formSubmission && formSubmission.lastSubmitted && (
          <TransButtonLink
            className="link-style-blue"
            style={styles.customerValues}
            onClick={toggle}
            text="Kundeneingaben ansehen"
          />
        )}

        {formSubmission && (
          <TransButtonLink
            className="link-style-danger"
            onClick={() => {
              setDeleteCustomerData(!deleteCustomerData);
            }}
            text="Kundeneingaben löschen"
          />
        )}
      </div>
      {!formSubmission && (
        <TransButtonLink className="link-style-danger" onClick={toggleConfirm} text="Freigabe entfernen" />
      )}
      <ConfirmDialog
        text="formSubmission.delete.confirm"
        subject="formSubmission.delete.confirm.head"
        open={deleteCustomerData}
        toggle={() => {
          setDeleteCustomerData(!deleteCustomerData);
        }}
        handler={delFormSubmit}
      />
      <ConfirmDialog
        text="share.resource.delete.confirm"
        subject="share.resource.delete.confirm.head"
        open={showConfirm}
        toggle={toggleConfirm}
        handler={delResource}
      />
    </div>
  );
};

export default ResourceItem;
