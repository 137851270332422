import { Group, Skeleton, Stack, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { FormSubmissionDto } from '../../Types/ShareT';
import { QuestionnaireDto } from '../../Types/QuestionnaireT';
import { QuestionnaireS } from '../../Service/QuestionnaireS';
import FileDetails, { FileDTO } from '../../Molecules/FileDetails';
import { FileModalDialog } from '../../Molecules/Dialogs/FileModalDialog';
import { useModalState } from '../../Molecules/Dialogs';
import { fetchFileListOfSubmission } from '../../Service/restapi/fileService';
import PanePaper from '../../Molecules/PanePaper';
import QuestionnaireSubmission from './QuestionnaireSubmission';

interface Props {
  formSubmission: FormSubmissionDto;
  closeModal: () => void; // close modal;
}

const FormSubmissionModal: FC<Props> = ({ formSubmission, closeModal }) => {
  const { id, entityType, entityId } = formSubmission;
  const { show, toggle } = useModalState(false);
  const [questionnaire, setQuestionnaire] = useState<QuestionnaireDto>();
  const [loading, setLoading] = useState(false);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [submissionFiles, setSubmissionFiles] = useState<FileDTO[]>([]);
  const [file, setFile] = useState<FileDTO>();

  useEffect(() => {
    fetchSubmissionFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (file && !show) {
      toggle();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const fetchSubmissionFiles = () => {
    setLoadingFiles(true);
    fetchFileListOfSubmission(formSubmission.id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then(setSubmissionFiles);
        }
      })
      .finally(() => {
        setLoadingFiles(false);
      });
  };

  useEffect(() => {
    if (entityType === 'questionnaires') {
      setLoading(true);
      QuestionnaireS.fetchQuestionnaire(entityId)
        .then((resJSON) => {
          if (resJSON) {
            setQuestionnaire(resJSON);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [entityId, entityType]);

  return (
    <Stack>
      <PanePaper title="Eingereichte Dateien">
        {loadingFiles && <Skeleton height={100} width="100%" />}
        {!loadingFiles && (
          <Group spacing="xs">
            {submissionFiles.map((f) => (
              // eslint-disable-next-line react/jsx-key
              <FileDetails
                file={f}
                entityId={id}
                entityType="formSubmissions"
                preview={() => setFile(f)}
                reload={fetchSubmissionFiles}
              />
            ))}
          </Group>
        )}
        {!loading && submissionFiles.length === 0 && (
          <Text italic color="dimmed" size="xs" weight="lighter">
            Noch nichts hinzugefügt.
          </Text>
        )}
      </PanePaper>
      {loading && <Skeleton height={300} />}
      {!loading && questionnaire && (
        <QuestionnaireSubmission
          submissionFiles={submissionFiles}
          closeModal={closeModal}
          formSubmission={formSubmission}
          questions={questionnaire.questionBundles.map((qb) => qb.questions).flat()}
          setFile={setFile}
          reload={fetchSubmissionFiles}
        />
      )}
      <FileModalDialog
        show={show}
        toggle={() => {
          setFile(undefined);
          toggle();
        }}
        allowDownload
        allowEmailAttachment={false}
        allowEditing={false}
        file={file ? { ...file, entityType: 'formSubmissions', entityId: id } : undefined}
        eventId={undefined}
      />
    </Stack>
  );
};

export default FormSubmissionModal;
