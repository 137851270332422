/* eslint-disable no-nested-ternary */
import { Stack, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';

const PasswordChecker: React.FC<{
  password: string;
  showErrors: boolean;
  setValidPassword: (valid: boolean) => void;
}> = ({ password, showErrors, setValidPassword }) => {
  const [longPassword, setLongPassword] = useState<boolean>(false);
  const [lowerCasePresent, setLowerCasePresent] = useState<boolean>(false);
  const [upperCasePresent, setUpperCasePresent] = useState<boolean>(false);
  const [upperAndLowerCase, setUpperAndLowerCase] = useState<boolean>(false);
  const [digitPresent, setDigitPresent] = useState<boolean>(false);

  useEffect(() => {
    if (password) {
      setLongPassword(password.length >= 8);
      setLowerCasePresent(password.toUpperCase() !== password);
      setUpperCasePresent(password.toLowerCase() !== password);
      setDigitPresent(/\d/.test(password));
    }
  }, [password]);

  useEffect(() => {
    setUpperAndLowerCase(lowerCasePresent && upperCasePresent);
  }, [lowerCasePresent, upperCasePresent]);

  useEffect(() => {
    setValidPassword(longPassword && upperAndLowerCase && digitPresent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [longPassword, upperAndLowerCase, digitPresent]);

  return (
    <>
      <Text size="xs" color="dimmed">
        Dein Passwort muss folgende Kriterien erfüllen:
      </Text>
      <Stack spacing={0} pl={10}>
        <Text size="xs" color={longPassword ? 'green' : showErrors ? 'red' : 'dimmed'}>
          - mind. 8 Zeichen
        </Text>
        <Text size="xs" color={upperAndLowerCase ? 'green' : showErrors ? 'red' : 'dimmed'}>
          - mind. einen Groß- und einen Kleinbuchstaben
        </Text>
        <Text size="xs" color={digitPresent ? 'green' : showErrors ? 'red' : 'dimmed'}>
          - mind. eine Zahl
        </Text>
      </Stack>
    </>
  );
};

export default PasswordChecker;
