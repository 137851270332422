import { Menu, Box } from '@mantine/core';
import {IconDotsVertical, IconTrash, IconFileDescription, IconCheck, IconX} from '@tabler/icons';
import { FC, useContext } from 'react';
import { i18n } from '@lingui/core';
import { Planning } from '../../../../Types/ResourceT';
import ResourcePlanningS from '../../../../Service/restapi/resourcePlanningS';
import { WindowContext } from '../../../../Service/Context/WindowContext';
import { openPlanningDrawerModal } from '../../PlanningDrawerModal';

interface Props {
  planning: Planning;
  refresh: () => unknown;
  removePlanning: () => void;
}

const PlanningActions: FC<Props> = ({ planning, refresh, removePlanning }) => {
  const { id: planningId, warehousePlanning } = planning;
  const { xs } = useContext(WindowContext);

  const reserve = async () => {
    const resJSON = await ResourcePlanningS.confirmResourcePlanning(planningId);
    if (resJSON) {
      refresh();
    }
  };

  const cancelReservation = async () => {
    if (warehousePlanning) {
      const cancelled = await ResourcePlanningS.deleteWarehousePlanning(warehousePlanning.id);
      if (cancelled) {
        refresh();
      }
    }
  };

  return (
    <Menu shadow="md" width={150} position="bottom-end" transition="scale-y">
      <Menu.Target>
        <Box sx={{ cursor: 'pointer' }}>
          <IconDotsVertical color="gray" />
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        {xs && (
          <Menu.Item
            icon={<IconFileDescription size={16} />}
            onClick={() => openPlanningDrawerModal({ planning, refresh, defaultPlannings: false })}
          >
            Einzelheiten
          </Menu.Item>
        )}
        {!warehousePlanning && (
          <Menu.Item icon={<IconCheck size={16} />} onClick={reserve}>
            Bestätigen
          </Menu.Item>
        )}
        {warehousePlanning && (
          <Menu.Item icon={<IconX size={16} />} onClick={cancelReservation}>
            Entfernen
          </Menu.Item>
        )}
        <Menu.Item color="red" icon={<IconTrash size={16} />} onClick={removePlanning}>
          {i18n._('actions.delete')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default PlanningActions;
