import { ActionIcon, ActionIconProps } from '@mantine/core';
import { FC } from 'react';

type ActionIconPropsColorless = Omit<ActionIconProps, 'color'>;

interface IconProps extends ActionIconPropsColorless, React.ButtonHTMLAttributes<HTMLButtonElement> {}

const ActionIconWithoutFocus: FC<IconProps> = ({ ...props }) => {
  return <ActionIcon {...props} onMouseDown={(e: any) => e.preventDefault()} />;
};

export default ActionIconWithoutFocus;
