import React, { useEffect, useState } from 'react';
import { withI18n } from '@lingui/react';
import { Pane } from '../../Molecules/Pane';
import TooltipButton from '../../Atoms/buttons/TooltipButton';
import TooltipTextButton from '../../Atoms/buttons/TooltipTextButton';
import { RoutingS } from '../../Service/RoutingS';

export default withI18n()(({ i18n }) => {
  const styles = {
    heading: {
      fontSize: 16,
    },
    container: {
      padding: 5,
    },
    description: {
      color: '#666666',
      marginBottom: 20,
      fontSize: 12,
    },
  };

  useEffect(() => {
    RoutingS.changeTitle('Portal-Branding');
  }, []);

  return (
    <>
      <Pane
        title="portal.branding"
        icon="SHARE"
        headAddition={
          <div style={{ display: 'flex' }}>
            <TooltipTextButton
              buttonText="INTERACTIVE"
              id="licence-portal-branding-pane"
              text="licence.portal.branding.pane"
              style={{ float: 'right' }}
            />

            <TooltipButton
              icon="QUESTION"
              id="help-portal-branding-pane"
              text="help.portal.branding.pane"
              style={{ float: 'right' }}
            />
          </div>
        }
        component={<div style={styles.container} />}
      />
    </>
  );
});
