/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Button, Center, Checkbox, Loader, Text, Textarea } from '@mantine/core';
import { FC, useState } from 'react';
import { i18n } from '@lingui/core';
import { closeAllModals } from '@mantine/modals';
import { useForm } from '@mantine/form';
import { IconMessage } from '@tabler/icons';
import { RecommendationS } from '../../../../Service/RecommendationS';
import { BookingState, RecommendationDto } from '../../../../Types/RecommendationT';

interface Props {
  recommendationId: string;
  newBookingState: BookingState;
  patchRecommendation: (recommendation: RecommendationDto) => void;
  contactEmail: string | undefined | null;
}

const RecommendationBookingStateModal: FC<Props> = ({
  recommendationId,
  newBookingState,
  patchRecommendation,
  contactEmail,
}) => {
  const [loading, setLoading] = useState(false);

  const form = useForm({
    initialValues: {
      sendMail: false,
      message: '',
    },
  });

  const { sendMail } = form.values;

  const onSubmit = () => {
    setLoading(true);
    const { message } = form.values;
    const patchFn = () =>
      sendMail
        ? RecommendationS.editAndInform(recommendationId, newBookingState, message)
        : RecommendationS.edit(recommendationId, { bookingState: newBookingState });
    patchFn()
      .then((recommendation) => {
        recommendation && patchRecommendation(recommendation);
      })
      .finally(() => {
        setLoading(false);
        closeAllModals();
      });
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Text>
        Du kannst entweder nur den Status ändern oder auch zusätzlich den Dienstleister über die Statusänderung per
        E-Mail informieren.
      </Text>
      <Checkbox
        size="xs"
        mt="md"
        label="Ja, Dienstleister per E-Mail benachrichtigen"
        disabled={loading || !contactEmail}
        {...form.getInputProps('sendMail', { type: 'checkbox' })}
      />
      {sendMail && (
        <Textarea
          icon={<IconMessage size={16} />}
          disabled={loading}
          maxRows={10}
          variant="filled"
          mt="xs"
          placeholder="Optionale Nachricht an den Dienstleister"
          {...form.getInputProps('message')}
        />
      )}
      <Center>
        <Box sx={{ width: '60%' }}>
          <Button fullWidth mt="xl" mb="xs" disabled={loading} type="submit" leftIcon={loading && <Loader size="xs" />}>
            {i18n._('recommendation.bookingState.changed')}
          </Button>
          <Button disabled={loading} fullWidth color="gray" variant="subtle" onClick={() => closeAllModals()}>
            {i18n._('actions.cancel')}
          </Button>
        </Box>
      </Center>
    </form>
  );
};

export default RecommendationBookingStateModal;
