// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { ActionIcon, CopyButton, Group, Text, Tooltip } from '@mantine/core';
import { openModal } from '@mantine/modals';
import { IconBallpen, IconCheck, IconCopy, IconShare } from '@tabler/icons';
import { FC, useContext } from 'react';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import { FormatS } from '../../../Service/FormatS';
import { RecruitmentCampaign } from '../../../Service/restapi/recruitmentService';
import EditCampaignModal from './EditCampaignModal';
import ShareCampaignModal from './ShareCampaignModal';

interface Props {
  campaign: RecruitmentCampaign;
  showRegistrations: boolean;
  showSales: boolean;
  handleChange: (patch: RecruitmentCampaign) => void;
}

const RecruitmentCampaignListItem: FC<Props> = ({ campaign, showRegistrations, showSales, handleChange }) => {
  const { id, name, registrations, earnedCredits } = campaign;
  const { profile } = useContext(ProfileContext);
  const campaignLink = `${process.env.REACT_APP_FRONTEND_URL}/register?campaign=${id}`;

  const shareCampaign = () => {
    openModal({
      title: (
        <Text mt="md" weight="bolder" size="lg" align="center" sx={{ lineHeight: 1.2 }}>
          Einen Link teilen
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: <ShareCampaignModal campaignId={id} userName={profile?.alias ?? ''} />,
    });
  };

  const editCampaign = () => {
    openModal({
      title: (
        <Text mt="md" weight="bolder" size="lg" align="center" sx={{ lineHeight: 1.2 }}>
          Kampagne bearbeiten
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: <EditCampaignModal campaign={campaign} handleChange={handleChange} />,
    });
  };

  return (
    <tr>
      <td>{name}</td>
      {showRegistrations && <td>{registrations}</td>}
      {showSales && <td>{FormatS.formatNumberCurrencyWithUnitSign(earnedCredits)}</td>}
      <td>
        <Group spacing="xs" position="right">
          <IconBallpen size={20} color="gray" style={{ cursor: 'pointer' }} onClick={editCampaign} />
          <CopyButton value={campaignLink} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip label={copied ? 'Kopiert' : 'Link kopieren'} withArrow position="left" transition="slide-left">
                <ActionIcon onClick={copy} size={20}>
                  {copied ? <IconCheck color="teal" /> : <IconCopy color="gray" />}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
          <IconShare size={20} color="gray" style={{ cursor: 'pointer' }} onClick={shareCampaign} />
        </Group>
      </td>
    </tr>
  );
};

export default RecruitmentCampaignListItem;
