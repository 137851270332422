import { ColorPicker, ColorPickerProps, ColorSwatch, Group, Popover, Text, TextInput } from '@mantine/core';
import { FC, useEffect, useState } from 'react';

interface Props extends ColorPickerProps {
  color: string;
  label: string;
  onChange(color: string): void;
}

const MantineColorPicker: FC<Props> = ({ color, label, onChange, ...rest }) => {
  const [localColor, setLocalColor] = useState(color);

  useEffect(() => {
    if (color !== localColor) {
      setLocalColor(color);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color]);

  return (
    <Group>
      <Popover>
        <Popover.Target>
          <ColorSwatch radius="sm" color={color} sx={{ cursor: 'pointer' }} />
        </Popover.Target>
        <Popover.Dropdown>
          <ColorPicker
            format="rgba"
            value={color}
            swatches={[
              '#25262b',
              '#868e96',
              '#fa5252',
              '#e64980',
              '#be4bdb',
              '#7950f2',
              '#4c6ef5',
              '#228be6',
              '#15aabf',
              '#12b886',
              '#40c057',
              '#82c91e',
              '#fab005',
              '#fd7e14',
            ]}
            onChangeEnd={onChange}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
          />
          <TextInput
            mt="md"
            icon={<ColorSwatch size={16} radius="sm" color={color} />}
            value={localColor}
            onChange={(e) => setLocalColor(e.currentTarget.value)}
            onBlur={(e) => onChange(e.currentTarget.value)}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onChange(e.currentTarget.value);
              }
            }}
          />
        </Popover.Dropdown>
      </Popover>

      <Text size="xs">{label}</Text>
    </Group>
  );
};

export default MantineColorPicker;
