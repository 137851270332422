import { FC } from 'react';
import { Badge, Stack } from '@mantine/core';
import { AddonDTO } from '../../../Types/Billing';
import PanePaper from '../../../Molecules/PanePaper';
import FieldValueRow from '../../../Atoms/FieldValueRow';
import SubscriptionS from '../../../Service/restapi/subscriptionService';

const SubscriptionAddonsPane: FC<{ addons: AddonDTO[] }> = ({ addons }) => {
  const mapAddonUI = (addon: AddonDTO) => {
    const { name, billingInterval, quantity } = addon;
    const tier = SubscriptionS.getAddonTier(quantity);

    return (
      <PanePaper
        title={name}
        rightSection={
          <Badge mb="md" radius="sm" sx={{ color: 'white' }} variant="gradient">
            ADDON
          </Badge>
        }
      >
        <Stack mt="xs">
          <FieldValueRow name="Frequenz" value={billingInterval} />
          {tier && <FieldValueRow name="Menge" value={`${tier.from} - ${tier.to}`} />}
        </Stack>
      </PanePaper>
    );
  };
  return (
    <Stack mt="md" mb="md">
      {addons.map(mapAddonUI)}
    </Stack>
  );
};

export default SubscriptionAddonsPane;
