/* eslint-disable react/jsx-props-no-spreading */
import { Select, Skeleton } from '@mantine/core';
import { IconChevronDown, IconTemplate } from '@tabler/icons';
import { FC, useEffect, useState } from 'react';
import { fetchFormTemplates } from '../../../Service/restapi/semanticFormService';

interface Props {
  initialValue?: string;
}

const ContactFormTemplateSelect: FC<Props> = ({ initialValue, ...rest }) => {
  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(true);
  const [availableTemplates, setAvailableTemplates] = useState<string[]>([]);

  useEffect(() => {
    fetchFormTemplates()
      .then((resp) => {
        if (resp.ok) {
          resp
            .json()
            .then((templates) => setAvailableTemplates(templates.map((t: any) => ({ value: t.id, label: t.name }))));
        }
      })
      .finally(() => setLoadingTemplates(false));
  }, []);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loadingTemplates ? (
        <Skeleton radius="sm" height={35} width="100%" />
      ) : (
        <Select
          clearable
          icon={<IconTemplate size={16} />}
          data={availableTemplates}
          label="Vorlage"
          defaultValue={initialValue}
          styles={{ rightSection: { pointerEvents: 'none' } }}
          rightSection={<IconChevronDown size={14} />}
          rightSectionWidth={25}
          {...rest}
        />
      )}
    </>
  );
};

export default ContactFormTemplateSelect;
