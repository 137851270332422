/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Center, Group, Switch, Text } from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { FC, useState } from 'react';
import { i18n } from '@lingui/core';
import { useForm } from '@mantine/form';
import { GoogleCalendarDto } from '../../../../Types/GoogleT';
import Dot from '../../../../Atoms/Dot';
import ConfigOption from '../../Google/ConfigOption';
import PanePaper from '../../../../Molecules/PanePaper';
import { GoogleS } from '../../../../Service/GoogleS';
import { LicenceRestriction, LicenceS } from '../../../../Service/LicenceS';
import { MixpanelS } from '../../../../Service/MixpanelS';
import { ConstantS } from '../../../../Service/ConstantS';

// eslint-disable-next-line import/prefer-default-export
export const openGoogleCalendarConfigModal = (
  googleCalendar: GoogleCalendarDto,
  updateHandler: (patch: Partial<GoogleCalendarDto>) => void,
  checkLicence: (licenceRestriction: LicenceRestriction, featureHandler: () => unknown) => void,
) => {
  openModal({
    size: 'lg',
    title: (
      <Text weight="bolder" size="xl">
        🗓️ Kalenderoptionen
      </Text>
    ),
    closeOnEscape: true,
    transition: 'slide-down',
    children: (
      <GoogleCalendarConfigModal
        googleCalendar={googleCalendar}
        updateHandler={updateHandler}
        checkLicence={checkLicence}
      />
    ),
  });
};

interface Props {
  googleCalendar: GoogleCalendarDto;
  updateHandler: (patch: Partial<GoogleCalendarDto>) => void;
  checkLicence: (licenceRestriction: LicenceRestriction, featureHandler: () => unknown) => void;
}

const GoogleCalendarConfigModal: FC<Props> = ({ googleCalendar, updateHandler, checkLicence }) => {
  const {
    id,
    backgroundColor,
    calendarName,
    read,
    write,
    recommendation,
    insertOnlySharedRecommendations,
    insertAsFullTimeEvents,
    useCustomStateLabels,
  } = googleCalendar;
  const form = useForm({
    initialValues: {
      read,
      write,
      recommendation,
      insertOnlySharedRecommendations,
      insertAsFullTimeEvents,
      useCustomStateLabels,
    },
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    if (form.isDirty('write') && form.values.write) {
      MixpanelS.track(ConstantS.TrackingEvents.GoogleCalendarActivated);
    }
    setLoading(true);
    GoogleS.editCalendarConfig(id, form.values)
      .then((calendar) => {
        if (calendar) {
          updateHandler(calendar);
          closeAllModals();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Group>
        <Text>Kalender:</Text>
        <Group mb="md" mt="md">
          <Dot withoutShadow colour={backgroundColor} />
          <Text weight="bolder">{calendarName}</Text>
        </Group>
      </Group>
      <PanePaper withoutBorder sx={{ backgroundColor: '#F3F3F3' }}>
        <ConfigOption
          title=" bookitup Events eintragen"
          description="Es werden automatisch bookitup Termine in Deinen Kalender eingetragen, welche im Status Angefragt, Option oder Bestätigt sind."
          rightSection={<Switch {...form.getInputProps('write', { type: 'checkbox' })} />}
        />
        <ConfigOption
          title="auf Verfügbarkeit prüfen"
          description="bookitup prüft diesen Kalander auf private Termine und zeigt Dir diese an."
          rightSection={
            <Switch
              checked={form.values.read}
              onChange={(e) =>
                checkLicence(LicenceS.Restrictions.PRIVATE_CALENDAR, () =>
                  form.setFieldValue('read', e.currentTarget.checked),
                )
              }
            />
          }
        />
        <ConfigOption
          title="Vermittlungen eintragen"
          description="Es werden automatisch alle Vermittlungen eingetragen, bei denen Du einen anderen Dienstleister vermittelt hast."
          rightSection={<Switch {...form.getInputProps('recommendation', { type: 'checkbox' })} />}
        />
        <ConfigOption
          title="Vermittlungen nur eintragen, wenn Auftragsdaten geteilt wurden"
          description="Ist diese Option deaktiviert, werden alle Vermittlungen eingetragen - egal ob die Auftragsdaten geteilt wurden oder nicht."
          rightSection={
            <Switch
              disabled={!form.values.recommendation}
              {...form.getInputProps('insertOnlySharedRecommendations', { type: 'checkbox' })}
            />
          }
        />
        <ConfigOption
          title="als Ganztags-Event eintragen"
          description="Die Kalender-Einträge werden ganztags eingetragen."
          rightSection={<Switch {...form.getInputProps('insertAsFullTimeEvents', { type: 'checkbox' })} />}
        />
        <ConfigOption
          title="Eigenen Buchungsstatus verwenden"
          description="Die Kalender-Einträge enthalten deinen individuell erstellen Buchungsstatus."
          rightSection={<Switch {...form.getInputProps('useCustomStateLabels', { type: 'checkbox' })} />}
        />
      </PanePaper>
      <Center mt="xl">
        <Button sx={{ minWidth: 200 }} type="submit" disabled={loading || !form.isTouched()}>
          {i18n._('actions.save')}
        </Button>
      </Center>
    </form>
  );
};
