import { ReactNode, useEffect, useState } from 'react';
import { useLingui } from '@lingui/react';
import { ContextModalProps, useModals } from '@mantine/modals';
import { deleteCustomAttribute, fetchCustomAttributeValues } from '../../../../Service/restapi/customAttributeService';
import { ModalFlow } from '../../../../Molecules/Dialogs';
import { ModalFlowStepItem } from '../../../../Atoms/Stepper/MenuItem';
import { ToastS } from '../../../../Service/ToastS';
import CustomAttributeDeleteFlow1 from './CustomAttributeDeleteFlow-1';

type Props = {
  attributeId: string;
  reload?: () => void;
};

const CustomAttributeDeleteModalFlow = ({ context, id, innerProps }: ContextModalProps<Props>) => {
  const { attributeId, reload } = innerProps;

  const [error, setError] = useState<string | undefined>(undefined);

  const [lastModified, setLastModified] = useState(new Date());

  const { closeAll } = useModals();

  const { i18n } = useLingui();

  // load email template
  const [values, setValues] = useState<any[] | undefined>(undefined);

  const [isLoading, setLoading] = useState(false);
  const [canBeFinished, setCanBeFinished] = useState(true);

  const doReload = () => {
    setLastModified(new Date());
    reload?.();
  };

  // reset state if attributeId changed
  useEffect(() => {
    const fetchValues = () => {
      setError(undefined);
      fetchCustomAttributeValues(attributeId).then((resp) => {
        resp.json().then((v) => {
          setValues(v);
        });
      });
    };
    fetchValues();
  }, [attributeId, lastModified]);

  const finish = () => {
    if (values && values.length === 0) {
      setLoading(true);
      deleteCustomAttribute(attributeId)
        .then((resp) => {
          if (resp.ok) {
            ToastS.success('delete.custom.attr', 'Feld erfolgreich gelöscht');
            closeAll();
            reload?.();
          } else {
            console.error(`could not delete customAttribute ${attributeId}`);
          }
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not delete customAttribute ${attributeId}`);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError('Bitte lösche zunächst alle Werte');
    }
  };

  const stepBodyComponents: ReactNode[] = [];

  const getCount = (list?: any[]) => (list ? ` (${list.length})` : '');

  const menuItems: ModalFlowStepItem[] = [];

  if (values) {
    menuItems.push({
      icon: 'TABLE',
      label: 'Werte löschen',
      description: i18n._('customField.delete.values.head') + getCount(values),
      validator: () => {
        const notValid = !values || values.length > 0;
        if (notValid) {
          setError('Bitte lösche zunächst alle Werte');
        } else {
          setError(undefined);
        }
        return !notValid;
      },
    });
    stepBodyComponents.push(
      <CustomAttributeDeleteFlow1
        key={0}
        values={values}
        attributeId={attributeId}
        setCanBeFinished={setCanBeFinished}
        reload={doReload}
      />,
    );
  }

  return (
    <ModalFlow
      menuItems={menuItems}
      stepBodyComponents={stepBodyComponents}
      error={error}
      isLoading={isLoading}
      canBeFinished={canBeFinished}
      finish={finish}
      finishButtonText="customField.delete"
    />
  );
};

export default CustomAttributeDeleteModalFlow;
