import { FC } from 'react';
import { ThemeIcon } from '@mantine/core';
import { IconCircleDashed, IconExclamationMark, IconUserCheck } from '@tabler/icons';
import { WorkflowStepDTO } from '../../Types/LogT';

const StepExecutionStatusIcon: FC<{ step: WorkflowStepDTO; currentStep: boolean }> = ({ step, currentStep }) => {
  const { executionReport, procedureType } = step;
  const { executionStatus } = executionReport ?? {};
  const failed = executionStatus === 'FAILED' || executionStatus === 'PRECONDITION_FAILED';

  if (procedureType === 'WAIT_UNTIL' && currentStep) {
    return null;
  }

  if ((failed && currentStep) || executionStatus === 'ABORTED') {
    return (
      <ThemeIcon color="red" size="xs" radius="xl">
        <IconExclamationMark size={12} />
      </ThemeIcon>
    );
  }

  if (executionStatus === 'APPROVAL_REQUIRED') {
    return (
      <ThemeIcon color="orange" size="xs" radius="xl">
        <IconUserCheck size={12} />
      </ThemeIcon>
    );
  }

  if (executionStatus === 'PAUSED') {
    return (
      <ThemeIcon color="gray" size="xs" radius="xl">
        <IconCircleDashed size={12} />
      </ThemeIcon>
    );
  }

  if ((currentStep && executionStatus !== 'COMPLETED') || executionStatus === 'SCHEDULED') {
    return (
      <ThemeIcon size="xs" radius="xl">
        <IconCircleDashed size={12} />
      </ThemeIcon>
    );
  }
  return null;
};

export default StepExecutionStatusIcon;
