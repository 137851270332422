import React, { useContext, useEffect, useState } from 'react';
import { TransSelectField } from '../../Atoms/InputFields';
import ResourceAdapter from '../../Service/ResourceAdapter';
import { fetchContactById } from '../../Service/restapi/contactService';

const ContactSelect = ({ contacts, selectedContactId, contactSelected, placeholder }) => {
  const [personDescription, setPersonDescription] = useState(undefined);

  useEffect(() => {
    if (selectedContactId && selectedContactId !== 'NEW') {
      fetchContactById(selectedContactId).then((contact) => {
        setPersonDescription(contact.personDescription ? contact.personDescription : contact.email);
      });
    } else {
      setPersonDescription(undefined);
    }
  }, [selectedContactId]);

  const styles = {
    selectField: {
      minWidth: 200,
    },
  };

  return (
    <TransSelectField
      id="contact-select"
      style={styles.selectField}
      value={{ value: selectedContactId, label: personDescription }}
      options={contacts}
      onChangeHandler={(contactId) => {
        contactSelected(contactId);
      }}
      name="contacts"
      placeholder={placeholder || 'contact.select'}
    />
  );
};

export default function (outerProps) {
  return (
    <ResourceAdapter
      serviceUrl={`${process.env.REACT_APP_AUTH_SERVICE_URL}/contacts`}
      component={(props) => {
        const contacts = [];
        props.data.forEach((c) => {
          contacts.push({ value: c.id, label: c.personDescription ? c.personDescription : c.email });
        });
        return <ContactSelect {...outerProps} contacts={contacts} />;
      }}
    />
  );
}
