// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Text } from '@mantine/core';

const ChangesDetectedAlert = () => (
  <Alert sx={(theme) => ({ backgroundColor: theme.colors.orange[3] })} mb="xs">
    <Text color="white">Änderungen entdeckt. Gehe zur Webseitenansicht, um Deine Änderungen zu veröffentlichen.</Text>
  </Alert>
);

export default ChangesDetectedAlert;
