import React, {FC, useContext, useEffect, useState} from 'react';
import {fetchMailOutboundConfig, patchMailOutboundConfig, sendTestMail} from '../../../Service/restapi/mailService';
import {LoadingAnimation} from '../../../Service/ResourceAdapter';
import {ProfileContext} from '../../../Service/Context/ProfileContext';
import {ToastS} from '../../../Service/ToastS';
import {MixpanelS} from '../../../Service/MixpanelS';
import {ConstantS} from '../../../Service/ConstantS';
import {DataS} from "../../../Service/DataS";
import {i18n} from "@lingui/core";
import {
  Alert,
  Button,
  Center,
  Checkbox,
  Group,
  Select,
  Space,
  Stack,
  Text,
  TextInput
} from "@mantine/core";
import {RoutingS} from "../../../Service/RoutingS";
import ScheduleMeetingAlert from "../ContactFormBuilder/ScheduledMettingAlert";


type MailOutBoundConfig = {
  id: string;
  provider: string;
  description: string;
  email: string;
  replyTo: boolean;
  replyToEmail: string;
  smptHost: string;
  smptPort: string;
  login: string;
  password: string;
  auth: boolean;
  startTls: boolean;
  bcc: string;
  defaultAccount: boolean;
};

export const MailOutBoundConfig: FC<{id: number}> = ({ id }) => {
  const { profile } = useContext(ProfileContext);
  const [success, setSuccess] = useState(false);

  const [mailOutBoundConfig, setMailOutBoundConfig] = useState<MailOutBoundConfig>();
  const [sendingTestMail, setSendingTestMail] = useState(false);
  const [error, setError] = useState<boolean>(false);

  function handleChange(patch : any) {
    patchMailOutboundConfig(id, patch).then((resp) => {
      if (resp.ok) {
        resp.json().then((mailOutBoundConfig) => {
          setMailOutBoundConfig(mailOutBoundConfig);
        });
      }
    });
  }

  function sendMail() {
    setSendingTestMail(true);
    if (mailOutBoundConfig) {
      sendTestMail(mailOutBoundConfig.id)
        .then((resp) => {
          if (resp.ok) {
            ToastS.success('send.mail', 'Test-E-Mail erfolgreich versendet');
            MixpanelS.track(ConstantS.TrackingEvents.MailAccountConnected);
            setSuccess(true);
            setError(false);
          } else {
            ToastS.error('send.mail', 'Überprüfe Deine E-Mail-Konfiguration');
            console.error('could not send test mail');
            setSuccess(false);
            setError(true);
          }
        })
        .catch((error) => {
          ToastS.error('send.mail', 'Überprüfe Deine E-Mail-Konfiguration');
          console.error('could not send test mail');
          console.error({ error });
          setSuccess(false);
          setError(true);
        })
        .finally(() => {
          setSendingTestMail(false);
        });
    }

  }

  useEffect(() => {
    fetchMailOutboundConfig(id).then((resp) => {
      if (resp.ok) {
        resp.json().then((mailOutBoundConfig) => {
          setMailOutBoundConfig(mailOutBoundConfig);
        });
      }
    });
  }, [id]);

  const hints = [
    {
      provider: "gmail",
      appSpecificPasswordLink: "https://myaccount.google.com/u/1/apppasswords",
      twoStepVerificationLink: "https://myaccount.google.com/u/1/signinoptions/two-step-verification"
    },
    {
      provider: "gmx",
      text: "Zudem musst Du in den E-Mail-Einstellungen von GMX unter \"POP3/IMAP Abruf\" die Checkbox \"POP3 und IMAP Zugriff erlauben\" aktivieren. Um die E-Mail-Einstellungen zu öffnen, gehe im GMX Webmailer auf Deine E-Mails und klicke unten rechts auf das Zahnrad.",
      appSpecificPasswordLink: "https://hilfe.gmx.net/sicherheit/2fa/anwendungsspezifisches-passwort.html",
      twoStepVerificationLink: null
    },
    {
      provider: "web-de",
      text: "Für diesen Anbieter musst Du in den E-Mail-Einstellungen von web.de unter \"POP3/IMAP Abruf\" die Checkbox \"POP3 und IMAP Zugriff erlauben\" aktivieren. Um die E-Mail-Einstellungen zu öffnen, gehe im web.de Webmailer auf Deine E-Mails und klicke unten rechts auf das Zahnrad.",
      appSpecificPasswordLink: undefined, // necessary?
      twoStepVerificationLink: null
    },
    {
      provider: "t-online",
      appSpecificPasswordLink: "https://email.t-online.de/pr?a=passwords.emailpassword",
      twoStepVerificationLink: null
    },
    {
      provider: "1blu",
      text: "Damit Deine 1blu Mails funktionieren muss Dein Benutzername dein (vollständiger) Postfachname sein - dies ist NICHT Deine E-Mail-Adresse. Den Postfachnamen findest du z.B. im Kundencenter von 1blu in den E-Mail-Einstellungen. In unserem Test-1blu-Konto ist dieser beispielsweise a333102_0-info",
      appSpecificPasswordLink: null,
      twoStepVerificationLink: null
    },
    {
      provider: "yahoo",
      text: "Yahoo unterstützt keine Antwort-an-E-Mail-Adresse. Antworten Deiner Kunden landen damit nicht automatisch in bookitup. Daher empfehlen wir hier ein anderes E-Mail-Konto zu verwenden.",
      appSpecificPasswordLink: "https://login.yahoo.com/account/security/app-passwords/list",
      twoStepVerificationLink: null
    },
    {
      provider: "office365",
      text: "Damit Du Dein Office365 Konto verbinden kannst, ist es notwendig, dass du das in den Office365-Einstellungen erlaubst. Kontaktiere hierzu bei Problemen gerne unseren Support.",
      appSpecificPasswordLink: null,
      twoStepVerificationLink: null
    }
]

  if (!mailOutBoundConfig) {
    return <LoadingAnimation style={{}} />;
  }

  let providerHint = hints.filter(h => h.provider == mailOutBoundConfig.provider).length == 1 ? hints.filter(h => h.provider == mailOutBoundConfig.provider)[0] : undefined;


  const updateConfig = (patch: any) => {
    setMailOutBoundConfig((prevState) => ({
      ...prevState,
      ...patch,
    }));
  };

  return (
    <>
      <Select
        required
        label="E-Mail-Anbieter"
        searchable
        value={mailOutBoundConfig.provider}
        onChange={(val: string) => {
          handleChange(
            {
              provider: val,
              defaultAccount: false
            }
          );
          setSuccess(false);
          }
        }
        data={DataS.getMailProviderOptions(i18n)}
      />

      {providerHint &&
        <>
          <Space h={"xl"} />
          <Alert
            key={'gmail'}
            p="md"
            radius="md"
            title={
              <Text weight="bolder" size="md">
                Wichtiger Hinweis
              </Text>
            }
            sx={(theme) => ({
              backgroundColor: theme.colors.lightBlue[0],
            })}

          >
            {providerHint.appSpecificPasswordLink && <>
              <>
                <Text sx={{lineHeight: 1.6}} color={'black'}>Für diesen E-Mail-Anbieter ist es notwendig, dass Du für
                  bookitup ein
                  App-spezifisches Passwort erstellst und dieses hier verwendest</Text>
                <Space h="md"/>
              </>
              <Button onClick={() => {
                if (providerHint && providerHint.appSpecificPasswordLink) {
                  RoutingS.openInNewTab(providerHint.appSpecificPasswordLink)
                }
              }}> {"App-spezifisches Passwort erstellen"}
              </Button>
              {providerHint.twoStepVerificationLink && <Text italic size="xs" weight="lighter" mt="md">
                Solltest Du kein App-spezifisches Passwort erstellen können, könnte es sein, dass Du die {' '}
                <Text
                  span
                  component="a"
                  color="blue"
                  href={providerHint.twoStepVerificationLink}
                  target="_blank"
                  sx={{cursor: 'pointer'}}
                >
                  Zwei-Faktor-Verifikation
                </Text>{' '}
                noch aktivieren musst.
              </Text>}
              <Space h="md"/>
            </>}

            {providerHint.text && <Text sx={{lineHeight: 1.6}} color={'black'}>{providerHint.text}</Text>}
          </Alert>
        </>
      }
      <Space h={"xl"} />
      <Stack>
        <TextInput
          label="Beschreibung (nur für Dich)"
          defaultValue={mailOutBoundConfig.description}
          onBlur={(e) => handleChange({description: e.currentTarget.value})}
        />
        <TextInput
          required
          label="E-Mail-Adresse"
          defaultValue={mailOutBoundConfig.email}
          onBlur={(e) => handleChange({email: e.currentTarget.value})}
        />
        <TextInput
          required
          label="Benutzername"
          defaultValue={mailOutBoundConfig.login}
          onBlur={(e) => handleChange({login: e.currentTarget.value})}
        />
        <TextInput
          required
          label="Passwort"
          type={"password"}
          defaultValue={mailOutBoundConfig.password}
          onBlur={(e) => handleChange({password: e.currentTarget.value})}
        />
        <TextInput
          required
          label="SMTP-Host"
          value={mailOutBoundConfig.smptHost}
          onChange={e => updateConfig({smptHost: e.currentTarget.value})}
          onBlur={(e) => handleChange({smptHost: e.currentTarget.value})}
        />
        <Select
          required
          label="SMTP-Port"
          clearable
          value={mailOutBoundConfig.smptPort ? mailOutBoundConfig.smptPort.toString() : ''}
          onChange={(val: string) => {
            handleChange(
              {
                smptPort: Number(val),
              }
            );
          }
          }
          data={DataS.getMailPortOptions()}
        />
        <Group>
          <Checkbox
            size="xs"
            checked={mailOutBoundConfig.startTls}
            mt="xs"
            label="StartTLS"
            onChange={(e) => handleChange({startTls: e.currentTarget.checked})}
          />
          <Checkbox
            size="xs"
            checked={mailOutBoundConfig.auth}
            mt="xs"
            label="SSL-Verschlüsselt"
            onChange={(e) => handleChange({auth: e.currentTarget.checked})}
          />
        </Group>
        <TextInput
          label="Blind-Kopie-E-Mail (BCC)"
          value={mailOutBoundConfig.bcc}
          onChange={e => updateConfig({bcc: e.currentTarget.value})}
          onBlur={(e) => handleChange({bcc: e.currentTarget.value})}
        />
        {profile && <TextInput
          disabled
          label="Antwort-E-Mail-Adresse"
          defaultValue={`profile-${profile.id}@bookitup.de`}
          style={{color: "#000000"}}
        />}
        {error && <ScheduleMeetingAlert/>}
        <Center mt="xl">
          <Button mt="xl" sx={{minWidth: 200}} onClick={sendMail}>
            Test-E-Mail verschicken
          </Button>
        </Center>

      </Stack>
    </>
  );
}
