import moment from 'moment';
import Placeholder from '../Organismns/Placeholder/Placeholder';

export const translate = {
  toLocale,
  toEnglish,
  insertPlainValues,
  insertCustomAttributePlainValues,
  removeUnnecessary,
  getPlaceholderValue,
  getCustomAttributePlaceholderValue,
};

function removeUnnecessary(htmlText) {
  htmlText = htmlText.replace('<p></p>', '<div style="height: 30px;"></div>');
  htmlText = htmlText.replace('<p><br></p>', '<div style="height: 30px;"></div>');
  htmlText = htmlText.replace('<p><b><u><br></u></b></p>', '<div style="height: 30px;"></div>');
  htmlText = htmlText.replace('<b><u><i><br></i></u></b>', '<div style="height: 30px;"></div>');
  htmlText = htmlText.replace('<b><u><br></u></b>', '<div style="height: 30px;"></div>');
  htmlText = htmlText.replace('<p><b><u><i><br></i></u></b></p>', '<div style="height: 30px;"></div>');
  // htmlText = htmlText.replace('<p><br></p>', '<br/>');
  htmlText = htmlText.replace('<b style="font-size: 0.875rem;">', '<b>');
  // htmlText = htmlText.replace('<span style="">', '');
  // htmlText = htmlText.replace('</span>', '');
  htmlText = htmlText.replace('<li .*?>', '');
  htmlText = htmlText.replace('</li>', '');
  htmlText = htmlText.replace('<ul .*?>', '');
  htmlText = htmlText.replace('</ul>', '');

  htmlText = htmlText.replace('<b><br></b>', '');
  htmlText = htmlText.replace('<i><br></i>', '');

  // remove last <br>, but only if it's the only <br>
  if (
    htmlText.substring(htmlText.length - 4, htmlText.length) === '<br>' &&
    htmlText.substring(htmlText.length - 8, htmlText.length - 4) !== '<br>'
  ) {
    console.log('remove break at end of input');
    htmlText = htmlText.substring(0, htmlText.length - 4);
  }

  // replace all html tags except <br/> <placeholder> and <img> (logo in signature)
  // <b> <u> <i> are also not removed
  htmlText = htmlText.replace(/(<([^br/ placeholder img][^>]+)>)/gi, '');

  return htmlText.replace('font-size: 0.875rem;', '');
}

function getLanguageKey(locale) {
  let localeKey = 1;
  if (locale === 'en') {
    localeKey = 0;
  }

  return localeKey;
}

function toLocale(text, editable, removable, settings, i18n, locale, finish, ...rest) {
  return replaceWithLocaleVersion(text, editable, removable, settings, i18n, getLanguageKey(locale), finish, ...rest);
}

/// placeholderId is expected to start and end with currly brackets e.g {placeholder_id}
function getPlaceholderValue(placeholderId, entityName, entity, i18n) {
  const PREFIX = `${entityName}_`;
  if (entity && placeholderId.indexOf(PREFIX) > 0) {
    const fieldName = placeholderId.substring(1 + PREFIX.length, placeholderId.length - 1);
    if (fieldName.includes('date') || fieldName.toLowerCase().includes('until')) {
      const format = i18n._('format.date.short');
      return moment(entity[fieldName]).format(format);
    }
    return entity[fieldName];
  }
}

function getCustomAttributePlaceholderValue(placeholderId, customAttributeValues, i18n) {
  placeholderId = placeholderId.substr(1, placeholderId.length - 2); // rm curly brackets
  const value = customAttributeValues.find((v) => v.placeholderId === placeholderId);

  if (value) {
    if (value.attributeType === 'DATE') {
      const format = i18n._('format.date.short');
      return moment(value.value).format(format);
    }
    if (value.attributeType === 'BOOLEAN') {
      if (value.value === 'true') {
        return 'Ja';
      }
      return 'Nein';
    }
    return value.value;
  }
}

function insertPlainValues(text, entityName, entity, i18n) {
  console.log('change placeholders in text ', text);
  if (text === null) {
    return null;
  }
  let placeholders = text.match(/{[\w:-]+}/g);
  if (placeholders !== null) {
    placeholders.forEach((htmlElement) => {
      const value = getPlaceholderValue(htmlElement, entityName, entity, i18n);
      console.log(`got value ${value} for placeholder ${htmlElement}`);

      if (value) {
        if (value === 'null') {
          alert('Fehler beim Ersetzen der Platzhalter. Bitte beim Support melden.');
          throw new Error(`missing value for placeholder ${htmlElement}`);
        }
        text = text.replace(htmlElement, value);
      } else {
        console.error(`missing value for placeholder ${htmlElement}`);
      }
    });
  }
  placeholders = text.match(/<\s*placeholder[^>]*(.*?)<\/placeholder>/g);
  if (placeholders !== null) {
    placeholders.map((placeholder, index) => {
      const div = document.createElement('div');
      div.innerHTML = placeholder.trim();
      const frag = div.firstChild;
      const value = frag.getAttribute('data-placeholder-value');
      if (!value || value === 'null') {
        throw new Error(`value for placeholder ${placeholder} is null`);
      } else {
        console.log('insert ', value);
        text = text.replace(placeholder, value);
      }
    });
  }

  return text;
}

function insertCustomAttributePlainValues(text, customAttributes, i18n) {
  console.log('change cA placeholders in text ', text);
  if (text === null) {
    return null;
  }
  let placeholders = text.match(/{[\w:-]+}/g);
  if (placeholders !== null) {
    placeholders.forEach((htmlElement) => {
      const value = getCustomAttributePlaceholderValue(htmlElement, customAttributes, i18n);
      console.log(`got value ${value} for placeholder ${htmlElement}`);

      if (value) {
        text = text.replace(htmlElement, value);
      } else {
        console.error(`missing value for placeholder ${htmlElement}`);
      }
    });
  }
  placeholders = text.match(/<\s*placeholder[^>]*(.*?)<\/placeholder>/g);
  if (placeholders !== null) {
    placeholders.map((placeholder, index) => {
      const div = document.createElement('div');
      div.innerHTML = placeholder.trim();
      const frag = div.firstChild;
      const value = frag.getAttribute('data-placeholder-value');
      text = text.replace(placeholder, value);
    });
  }

  return text;
}

function removeDivByIndex(text, index) {
  if (!text) {
    return text;
  }

  const htmlString = '';
  if (typeof text === 'string') {
    const re = new RegExp(`<s*placeholder index="${index}"[^>]*(.*?)</placeholder>`, 'g');

    const htmlElements = text.match(re);

    if (htmlElements !== null) {
      htmlElements.forEach((htmlElement) => {
        if (typeof htmlElement === 'string') {
          text = text.replace(htmlElement, '');
        }
      });
    }

    return text;
  }

  throw Error(`type of text in PlaceholderTranslationService is ${typeof text}`);
}

String.prototype.regexIndexOf = function (regex, startpos) {
  const indexOf = this.substring(startpos || 0).search(regex);
  return indexOf >= 0 ? indexOf + (startpos || 0) : indexOf;
};

function toEnglish(text, i18n) {
  if (!text || typeof text !== 'string') {
    return text;
  }

  text = text.replace(/<p>/g, '');
  text = text.replace(/<\/p>/g, '');
  text = text.replace(/&nbsp;/g, ' ');
  text = text.replace(/&amp;/g, '&');

  let htmlString = '';
  if (typeof text === 'string') {
    const htmlElements = text.match(/<\s*placeholder[^>]*(.*?)<\/placeholder>/g);

    if (htmlElements) {
      htmlElements.map((htmlElement, index) => {
        const div = document.createElement('div');
        div.innerHTML = htmlElement.trim();
        const frag = div.firstChild;
        const placeholder = frag.getAttribute('data-placeholder-id');
        text = text.replace(htmlElement, placeholder);
      });
    }

    htmlString = text;

    return htmlString;
  }
  if (typeof text === 'object') {
    for (const htmlElement of text) {
      if (typeof htmlElement === 'object') {
        const placeholder = htmlElement.props.id;
        htmlString += placeholder;
      } else {
        htmlString += htmlElement;
      }
    }
    return htmlString;
  }
  console.log('parameter text: ', text);
  return text;

  throw Error(`type of text in PlaceholderTranslationService is ${typeof text}`);
}

function splitHtmlByImages(htmlString) {
  const items = [];
  const imageList = htmlString.match(/<img.*?>/g);

  if (!imageList) {
    return [htmlString];
  }
  imageList.map((imgItem, index) => {
    const indexOfNext = htmlString.indexOf(imgItem);
    if (indexOfNext > 0) {
      items.push(htmlString.substring(0, indexOfNext));
    }
    const div = document.createElement('div');
    div.innerHTML = imgItem.trim();

    // Change this to div.childNodes to support multiple top-level nodes
    const frag = div.firstChild;
    items.push(<img title={frag.title} src={frag.src} />);

    // take rest of string
    htmlString = htmlString.substr(indexOfNext + imgItem.length);

    if (imageList.length - 1 === index) {
      // rest of string
      if (htmlString.length > 0) {
        items.push(htmlString);
      }
    }
  });

  return items;
}

function splitHtmlToArray(htmlString) {
  if (htmlString === null) {
    return [];
  }
  if (!htmlString) {
    return [];
  }
  if (typeof htmlString === 'object') {
    return [];
  }

  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  return [div];
}

function getHtmlElement(htmlString, el) {
  const output = [];
  const indexOfEl = htmlString.indexOf(el);
  if (indexOfEl > 0) {
    output.push(htmlString.substring(0, indexOfEl));
  }
  const innerValue = el.substring(3, el.length - 4);
  output.push(<b> + {innerValue} + </b>);
  output.push(htmlString.substring(indexOfEl + el.length));
  return output;
}

function b(node, editable, removable, settings, i18n, localeKey, finish, ...rest) {
  const out = [];
  let value;
  if (node.innerHTML) {
    value = replacePlaceholder(node.innerHTML, editable, removable, settings, i18n, localeKey, finish, ...rest);
  }

  switch (node.nodeName) {
    case 'B':
      out.push(<b>{value}</b>);
      break;
    case 'I':
      out.push(<i>{value}</i>);
      break;
    case 'BR':
      out.push(<br />);
      break;
    case '#text':
      out.push(value);
      break;
    case 'IMG':
      out.push(splitHtmlByImages(node.outerHTML));
      break;
    default:
      out.push(value);
      break;
  }
  return out;
}

function a(node, editable, removable, settings, i18n, localeKey, finish, ...rest) {
  const out = [];

  for (let i = 0; i < node.length; i++) {
    if (node[i].nodeName === '#text') {
      out.push(replacePlaceholder(node[i].nodeValue, editable, removable, settings, i18n, localeKey, finish, ...rest));
    } else if (node[i].childNodes.length > 0) {
      switch (node[i].nodeName) {
        case 'SPAN':
          const tmpSpan = a(node[i].childNodes, editable, removable, settings, i18n, localeKey, finish, ...rest);
          const { style } = node[i].attributes;
          const styleValue = style ? style.value : undefined;
          let color;
          if (styleValue) {
            if (styleValue.includes('color: #')) {
              color = styleValue.substring(styleValue.indexOf('color: #') + 7, styleValue.indexOf('color: #') + 8 + 6);
            } else if (styleValue.includes('color: rgb(')) {
              color = styleValue.substring(styleValue.indexOf('color: rgb(') + 7, styleValue.indexOf(');') + 1);
            }
          }

          out.push(<span style={{ color }}>{tmpSpan}</span>);
          break;
        case 'B':
          const tmp = a(node[i].childNodes, editable, removable, settings, i18n, localeKey, finish, ...rest);
          out.push(<b>{tmp}</b>);
          break;
        case 'I':
          out.push(<i>{a(node[i].childNodes, editable, removable, settings, i18n, localeKey, finish, ...rest)}</i>);
          break;
        case 'U':
          out.push(<u>{a(node[i].childNodes, editable, removable, settings, i18n, localeKey, finish, ...rest)}</u>);
          break;
        case 'A':
          const styles = {};
          if (node[i].style.forEach) {
            node[i].style.forEach((s) => {
              if (node[i].style[s]) {
                styles[s] = node[i].style[s];
              }
            });
          }
          out.push(
            <a title={node[i].title} style={styles} href={node[i].href} target={node[i].target}>
              {a(node[i].childNodes, editable, removable, settings, i18n, localeKey, finish, ...rest)}
            </a>,
          );
          break;
        default:
          out.push(a(node[i].childNodes, editable, removable, settings, i18n, localeKey, finish, ...rest));
      }
    } else {
      out.push(b(node[i], editable, removable, settings, i18n, localeKey, finish, finish, ...rest));
    }
  }

  return out;
}

function splitHtmlByBreak(htmlString, editable, removable, settings, i18n, localeKey, finish, ...rest) {
  // htmlString = "<b>Fett</b>, Normal&nbsp;{event_date}, <br/><i>Kursiv</i>, Normal, <b><i>Fett &amp; Kursiv</i></b>, Normal";
  if (htmlString === null) {
    return [];
  }
  if (!htmlString) {
    return [];
  }
  if (typeof htmlString === 'object') {
    return [];
  }

  htmlString = htmlString.replace(/<div style="height: 30px;">/g, '<br>');
  htmlString = htmlString.replace(/<span style="font-size: 0.875rem;">/g, '');
  htmlString = htmlString.replace(/<\/span>/g, '');
  htmlString = htmlString.replace(/&nbsp;/g, ' ');
  htmlString = htmlString.replace(/&amp;/g, '&');
  htmlString = htmlString.replace(/<p>/g, '');
  htmlString = htmlString.replace(/<\/p>/g, '<br>');
  htmlString = htmlString.replace(/<div.*?>/g, ' ');
  htmlString = htmlString.replace(/<\/div>/g, ' ');
  htmlString = htmlString.replace(/<p\/>/g, ''); // no clue by whom it was generated

  // remove &#8203; (zero-width spaces, added by placeholders)
  htmlString = htmlString.replace(/\u200B/g, '');

  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();
  div.innerHTML = div.innerHTML.replace(/&nbsp;/g, ' ');

  const nodeLength = div.children.length;
  let output;
  if (nodeLength > 0) {
    output = a(div.childNodes, editable, removable, settings, i18n, localeKey, finish, ...rest);
  } else {
    output = [replacePlaceholder(div.innerHTML, editable, removable, settings, i18n, localeKey, finish, ...rest)];
  }

  return output;
}

function replacePlaceholder(string, editable, removable, settings, i18n, localeKey, finish, ...rest) {
  const items = [];
  if (string === null) {
    return [];
  }
  string = string.replace('&amp;', '&');

  if (typeof string === 'object') {
    items.push(string);
  } else {
    const placeholderList = string.match(/{[\w:-]+}/g);
    if (placeholderList !== null && placeholderList.length > 0) {
      placeholderList.map((placeholder, index) => {
        const indexOfNext = string.indexOf(placeholder);
        if (indexOfNext > 0) {
          items.push(string.substring(0, indexOfNext));
        }
        const placeholderWithoutCurlyBrackets = placeholder.substring(1, placeholder.indexOf('}'));
        const userReadablePlaceholder = i18n._(placeholderWithoutCurlyBrackets, { 0: localeKey });
        items.push(
          <Placeholder
            key={userReadablePlaceholder}
            index={index}
            id={placeholder}
            editable={editable}
            removable={removable}
            settings={settings}
            value={userReadablePlaceholder}
            finish={finish}
            getCustomAttributePlaceholderValue={getCustomAttributePlaceholderValue}
            getPlaceholderValue={getPlaceholderValue}
            {...rest}
          />,
        );

        // take rest of string
        string = string.substr(indexOfNext + placeholder.length);

        if (placeholderList.length - 1 === index) {
          // rest of string
          items.push(string);
        }
      });
    } else {
      items.push(string);
    }
  }

  return items;
}

function replaceWithLocaleVersion(text, editable, removable, settings, i18n, localeKey, finish, ...rest) {
  const items = splitHtmlByBreak(text, editable, removable, settings, i18n, localeKey, finish, ...rest);

  const allHtmlTags = [];
  items.forEach((item) => {
    if (typeof item === 'string') {
      const subItems = splitHtmlByImages(item);
      subItems.forEach((sub) => {
        allHtmlTags.push(sub);
      });
    } else {
      allHtmlTags.push(item);
    }
  });
  return allHtmlTags;
}
