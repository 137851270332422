import { TokenResponse } from '@react-oauth/google';
import { showNotification, updateNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons';
import { GoogleAccountDTO, GoogleCalendarDto } from '../Types/GoogleT';
import { ajaxActions } from './AjaxActions';
import { ToastS } from './ToastS';

/* eslint-disable camelcase */
const OPTIONS = {
  fields: ['address_components', 'name', 'formatted_phone_number', 'website'],
  types: ['establishment'], //  instructs the Place Autocomplete service to return only business results.
};

const SCOPES =
  'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/contacts https://www.googleapis.com/auth/tasks';

const parseAndTransformPlaceResult = (placeResult: any) => {
  const { address_components, formatted_phone_number, name, website } = placeResult;
  const addressInformation = address_components.reduce(
    // eslint-disable-next-line no-return-assign, no-param-reassign, no-sequences
    (seed: any, { long_name, types }: any) => (types.forEach((t: any) => (seed[t] = long_name)), seed),
    {},
  );

  return {
    alias: name,
    companyName: name,
    phoneNumber: formatted_phone_number,
    city: addressInformation.locality || addressInformation.sublocality_level_1,
    addressStreetAndNo: `${addressInformation.route} ${addressInformation.street_number}`,
    zipCode: addressInformation.postal_code,
    addressOfWebsite: website,
  };
};

const BASE_URL = process.env.REACT_APP_GOOGLE_SERVICE_URL;

const completeOAuthProcess = async (tokenResponse: Partial<TokenResponse>, migration = false): Promise<boolean> => {
  showNotification({
    id: 'complete-oauth',
    loading: true,
    title: 'Kontosynchronisierung',
    message: 'Einen Moment, wir bereiten alles für Dich vor',
    autoClose: false,
    disallowClose: true,
  });
  const res = await ajaxActions.put(`${BASE_URL}/google/accounts?migration=${migration}`, tokenResponse);
  if (res.ok) {
    updateNotification({
      id: 'complete-oauth',
      loading: false,
      message: 'Neues Google-Konto wurde erfolgreich hinzugefügt',
      color: 'teal',
      icon: <IconCheck size={16} />,
      autoClose: 2000,
    });
    return true;
  }
  updateNotification({
    id: 'complete-oauth',
    loading: false,
    message: 'Die Kontoeinrichtung konnte nicht abgeschlossen werden. Bitte versuchen Sie es noch einmal.',
    color: 'red',
    icon: <IconX size={16} />,
    autoClose: 2000,
  });
  return false;
};

const fetchAccounts = async (): Promise<GoogleAccountDTO[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/google/accounts`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const editAccountConfig = async (id: number, patch: Partial<GoogleAccountDTO>): Promise<GoogleAccountDTO | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/google/accounts/${id}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const removeAccount = async (id: number): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/google/accounts/${id}`);
  if (res.ok) {
    ToastS.info('google-acc-removed', 'Verknüpfung zu Google-Konto entfernt');
    return true;
  }
  ToastS.generalError();
  return false;
};

const exportAllContacts = async (googleAccountId: number) => {
  const notificationId = 'export-contacts';
  showNotification({
    id: notificationId,
    loading: true,
    title: 'Kontakte exportieren',
    message: 'Kontakte werden zu Google exportiert. Dies kann bis zu 30 Minuten dauern. Bitte warten...',
    autoClose: false,
    disallowClose: true,
  });
  const res = await ajaxActions.put(`${BASE_URL}/google/accounts/${googleAccountId}/contacts/export`, {});

  updateNotification({
    id: notificationId,
    color: res.ok ? 'teal' : 'red',
    message: res.ok ? 'Erfolgreich abgeschlossen' : 'Upps etwas ist schief gelaufen 🙁 Bitte versuche es später erneut',
    icon: res.ok ? <IconCheck size={16} /> : <IconX size={16} color="red" />,
    autoClose: 2000,
  });
};

const exportAllTasks = async (googleAccountId: number) => {
  showNotification({
    id: 'export-tasks',
    loading: true,
    title: 'Aufgaben exportieren',
    message: 'Aufgaben werden zu Google exportiert. Dies kann bis zu 30 Minuten dauern. Bitte warten...',
    autoClose: false,
    disallowClose: true,
  });
  const res = await ajaxActions.put(`${BASE_URL}/google/accounts/${googleAccountId}/tasks/export`, {});

  updateNotification({
    id: 'export-tasks',
    color: res.ok ? 'teal' : 'red',
    message: res.ok ? 'Erfolgreich abgeschlossen' : 'Upps etwas ist schief gelaufen 🙁 Bitte versuche es später erneut',
    icon: res.ok ? <IconCheck size={16} /> : <IconX size={16} />,
    autoClose: 2000,
  });
};

const fetchCalendars = async (): Promise<GoogleCalendarDto[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/google/calendars`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const editCalendarConfig = async (id: number, patch: Partial<GoogleCalendarDto>): Promise<GoogleCalendarDto | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/google/calendars/${id}`, patch);
  if (res.ok) {
    ToastS.info('cgf-update', 'Kalenderkonfiguration aktualisiert');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const removeCalendar = async (id: number): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/google/calendars/${id}`);
  if (res.ok) {
    ToastS.info('cal-removed', 'Kalender entfernt');
    return true;
  }
  ToastS.generalError();
  return false;
};

const refreshCalendars = async () => {
  showNotification({
    id: 'cals-refresh',
    loading: true,
    title: 'Kalender werden aktualisiert',
    message: 'Google-Kalender werden geladen',
    autoClose: false,
    disallowClose: true,
  });
  const res = await ajaxActions.put(`${BASE_URL}/google/calendars/refresh`);
  if (res.ok) {
    updateNotification({
      id: 'cals-refresh',
      loading: false,
      message: 'Kalender erfolgreich aktualisiert',
      color: 'teal',
      icon: <IconCheck />,
      autoClose: 2000,
    });
    return true;
  }
  updateNotification({
    id: 'cals-refresh',
    loading: false,
    message: 'Der Kalender kann derzeit nicht synchronisiert werden',
    autoClose: 2000,
    color: 'red',
  });
  return false;
};

const publishEvents = async (): Promise<boolean> => {
  showNotification({
    id: 'export-events',
    loading: true,
    title: 'Kalender-Einträge werden erstellt',
    message: 'Deine bookitup Events werden in deinen Google-Kalender eingetragen.',
    autoClose: false,
    disallowClose: true,
  });
  const res = await ajaxActions.get(`${BASE_URL}/events/publish`);
  if (res.ok) {
    updateNotification({
      id: 'export-events',
      loading: false,
      message: 'bookitup Event erfolgreich eingetragen',
      color: 'teal',
      icon: <IconCheck />,
      autoClose: 2000,
    });
    return true;
  }
  updateNotification({
    id: 'export-events',
    loading: false,
    message: ' Es ist ein Problem aufgetreten. Bitte wende Dich an unseren Support',
    autoClose: 2000,
    color: 'red',
  });
  return false;
};

// eslint-disable-next-line import/prefer-default-export
export const GoogleS = {
  OPTIONS,
  SCOPES,
  parseAndTransformPlaceResult,
  completeOAuthProcess,
  fetchAccounts,
  fetchCalendars,
  editAccountConfig,
  editCalendarConfig,
  removeAccount,
  removeCalendar,
  exportAllContacts,
  exportAllTasks,
  refreshCalendars,
  publishEvents,
};
