import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingOverlay } from '@mantine/core';
import DocumentTemplate from '../../../../Molecules/Settings/DocumentTemplates/DocumentTemplate';
import {
  addTemplatePositionAtIndex,
  deleteTemplatePositionAtIndex,
  moveTemplatePosition,
  savePatch,
} from '../../../../Service/restapi/templateService';
import { patchPosition } from '../../../../Service/restapi/positionService';
import { DocumentContext } from '../../../../Service/Context/DocumentContext';
import { DocumentS } from '../../../../Templates/Documents/DocumentS';
import { MixpanelS } from '../../../../Service/MixpanelS';
import { ConstantS } from '../../../../Service/ConstantS';

const DocumentProvider = () => {
  const { id, type } = useParams();
  const [document, setDocument] = useState();
  const [loading, setLoading] = useState(false);

  const fetchTemplate = () => {
    setLoading(true);
    DocumentS.fetchTemplate(type, id)
      .then((resJSON) => {
        if (resJSON) {
          setDocument(resJSON);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    MixpanelS.track(ConstantS.TrackingEvents.DocumentTemplateOpened);
  }, []);

  useEffect(() => {
    if (id && type) {
      fetchTemplate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type]);

  if (loading) {
    <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />;
  }

  if (!document) {
    return null;
  }

  return (
    <DocumentContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        document,
        handleChange: (patch) => {
          // Fetch whole document on position change so the sum is updated accordingly
          if (patch && Object.keys(patch).length === 1 && patch.positions) {
            fetchTemplate();
          } else {
            setDocument((prevState) => ({
              ...prevState,
              ...patch,
            }));
          }
        },
        savePatch: (patch) => {
          savePatch(document.id, patch)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((newDocument) => {
                  setDocument(newDocument);
                });
              } else {
                console.error(`could not save patch of template ${document.id}`);
              }
            })
            .catch(() => {
              console.error(`could not save patch of template ${document.id}`);
            });
        },
        savePositionPatch: (positionId, index, patch) => {
          patchPosition(positionId, patch)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((newPosition) => {
                  const tmpPositions = Object.assign([], document.positions);
                  tmpPositions[index] = {
                    ...tmpPositions[index],
                    ...newPosition,
                  };

                  setDocument((prevState) => ({
                    ...prevState,
                    positions: tmpPositions,
                  }));
                });
              } else {
                console.error(`could not save patch of template ${document.id}`);
              }
            })
            .catch(() => {
              console.error(`could not save patch of template ${document.id}`);
            });
        },
        deletePosition: (index) =>
          deleteTemplatePositionAtIndex(document.id, index)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((newDocument) => {
                  setDocument(newDocument);
                });
              } else {
                console.error(`could not delete position ${index} of document ${document.id}`);
              }
            })
            .catch((error) => {
              console.error({ error });
              console.error(`could not delete position ${index} of document ${document.id}`);
            }),
        movePosition: (fromIndex, newIndex) => {
          moveTemplatePosition(document.id, fromIndex, newIndex)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((newDocument) => {
                  setDocument(newDocument);
                });
              } else {
                console.error(
                  `could not move position for document ${document.id} from index ${fromIndex} to index ${newIndex}`,
                );
              }
            })
            .catch(() => {
              console.error(
                `could not move position for document ${document.id} from index ${fromIndex} to index ${newIndex}`,
              );
            });
        },
        addPosition: (index) => {
          if (index === undefined) {
            index = document.positions.length;
          }
          addTemplatePositionAtIndex(document.id, index)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((newDocument) => {
                  setDocument(newDocument);
                });
              } else {
                console.error(`could not add position for document ${document.id} at index ${index}`);
              }
            })
            .catch(() => {
              console.error(`could not add position for document ${document.id} at index ${index}`);
            });
        },
      }}
    >
      <DocumentTemplate />
    </DocumentContext.Provider>
  );
};

export default DocumentProvider;
