function onConversationsAPIReady() {
  console.log('HubSpot Conversations API:', window.HubSpotConversations);
  window.HubSpotConversations.widget.load();
}

function isConversationApiReady(email, token) {
  console.log('email and token', { email }, { token });

  window.hsConversationsSettings = {
    identificationEmail: email,
    identificationToken: token,
  };
  console.log('hsConversationSettings: ', window.hsConversationsSettings);

  if (window.HubSpotConversations) {
    onConversationsAPIReady();
  } else {
    /*
      Otherwise, callbacks can be added to the hsConversationsOnReady on the window object.
      These callbacks will be called once the external API has been initialized.
    */
    window.hsConversationsOnReady = [onConversationsAPIReady];
  }
}

export const HubSpotS = {
  isConversationApiReady,
};
