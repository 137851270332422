import React, { useContext, useState } from 'react';
import { Trans } from '@lingui/react';
import { TransButtonDefault, TransButtonDefaultWithIcon } from '../../../Atoms/Buttons';
import PDFViewerProxy from '../../../Atoms/PDFViewer';
import { ModalDialog } from '../../Dialogs';
import { WindowContext } from '../../../Service/Context/WindowContext';
import { Button, ThemeIcon } from '@mantine/core';
import { i18n } from '@lingui/core';
import { IconEye } from '@tabler/icons';

const TemplatePreview = ({ documentType, name, style }) => {
  const { xs } = useContext(WindowContext);

  const [previewModal, setPreviewModal] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(undefined);

  function togglePreviewModal() {
    setPreviewModal(!previewModal);
  }

  function preview() {
    let url;
    if (documentType === 'QUESTIONNAIRE') {
      // name is here the id
      url = `${process.env.REACT_APP_QUESTIONNAIRE_SERVICE_URL}/questionnaires/templates/${name}/pdf`;
    } else {
      url = `${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/generate?documenttype=${documentType}&templatename=${name}`;
    }
    setPreviewUrl(url);
    setPreviewModal(true);
  }

  return (
    <div style={style}>
      {xs ? (
        <ThemeIcon>
          <IconEye size={20} onClick={() => preview()} />
        </ThemeIcon>
      ) : (
        <Button leftIcon={<IconEye size={20} />} onClick={() => preview()}>
          {i18n._('actions.preview')}
        </Button>
      )}

      {previewModal && (
        <ModalDialog
          size="xl"
          header={<Trans id="common.document.preview-title" />}
          body={
            <div>
              <PDFViewerProxy url={previewUrl} />
            </div>
          }
          footer={
            <div>
              <TransButtonDefault onClickHandler={togglePreviewModal} text="actions.cancel" />
            </div>
          }
          show={previewModal}
          toggle={togglePreviewModal}
        />
      )}
    </div>
  );
};

export default TemplatePreview;
