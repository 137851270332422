import React, { useContext } from 'react';
import { withI18n } from '@lingui/react';
import { withCookies } from 'react-cookie';
import ResourceAdapter from '../../Service/ResourceAdapter';
import { TransSelectField } from '../../Atoms/InputFields';
import { DocumentContext } from '../../Service/Context/DocumentContext';

const SelectDocument = withCookies(
  withI18n()(({ templates, value, onChange, style }) => {
    const temps = [];
    templates.forEach((templateName) => {
      temps.push({ label: templateName, value: templateName });
    });

    function templateSelected(value, name) {
      const tmp = temps.find((tmp) => tmp.value === value);
      onChange(tmp);
    }

    const option = { label: value, value };

    return (
      <TransSelectField
        style={style}
        value={option}
        options={temps}
        onChangeHandler={templateSelected}
        name="templates"
        noSelectionPlaceholder="document.template.select"
      />
    );
  }),
);

export default function (rest) {
  const { document } = useContext(DocumentContext);
  if (document.documentType === undefined) {
    return null;
  }
  return (
    <ResourceAdapter
      serviceUrl={`${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/report/template/${document.documentType}/names`}
      component={(props) => <SelectDocument templates={props.data} {...rest} />}
    />
  );
}
