// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Skeleton, Text } from '@mantine/core';
import { FC } from 'react';
import { ProcedureType } from '../../../../../Types/LogT';
import CreateQuestionnaireProcedure from './CreateQuestionnaire';
import CreateShare from './CreateShare';
import SendEmail from './SendEmail';
import ShareEntity from './ShareEntity';
import CreateTask from './CreateTask';
import CreateDocument from './CreateDocument';
import ChangeEventStatus from './ChangeEventStatus';
import CreateContract from './CreateContract';
import DeleteTask from './DeleteTask';
import CompleteTask from './CompleteTask';
import DeleteTasks from './DeleteTasksConfig';

interface Props {
  procedureType: ProcedureType;
  config: unknown;
  loading: boolean;
}

const ProcedurePreview: FC<Props> = ({ procedureType, config, loading }) => {
  if (loading) {
    return <Skeleton width="100%" height={32} />;
  }
  if (!config) {
    return (
      <Text italic color="red">
        Konfiguration fehlt
      </Text>
    );
  }

  return (
    <>
      {procedureType === 'DELETE_TASK' && <DeleteTask config={config} />}
      {procedureType === 'COMPLETE_TASK' && <CompleteTask config={config} />}
      {procedureType === 'CREATE_CONTRACT' && <CreateContract config={config} />}
      {procedureType === 'CHANGE_EVENT_STATUS' && <ChangeEventStatus config={config} />}
      {procedureType === 'CREATE_DOCUMENT' && <CreateDocument config={config} />}
      {procedureType === 'CREATE_QUESTIONNAIRE' && <CreateQuestionnaireProcedure config={config} />}
      {procedureType === 'CREATE_SHARE' && <CreateShare config={config} />}
      {procedureType === 'SEND_MAIL' && <SendEmail config={config} />}
      {procedureType === 'SHARE_ENTITY' && <ShareEntity config={config} />}
      {procedureType === 'CREATE_TASK' && <CreateTask config={config} />}
      {procedureType === 'DELETE_TASKS' && <DeleteTasks config={config} />}
    </>
  );
};

export default ProcedurePreview;
