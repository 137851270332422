/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Loader, Text } from '@mantine/core';
import { DatePickerProps, DatePicker } from '@mantine/dates';
import { isSameDay } from 'date-fns';
import { FC, useState } from 'react';
import { toUTC } from '../../Utils/dateUtils';

interface Props extends DatePickerProps {
  patchCallback: (val: Date | null) => Promise<unknown>;
  value: Date;
}

const BookitupDateInput: FC<Props> = ({ patchCallback, label, value, ...rest }) => {
  const [loading, setLoading] = useState(false);

  const onChange = async (val: Date | null) => {
    if (val && isSameDay(val, value)) {
      // Prevent update for the same day
      return;
    }
    // Send time in UTC for now :(
    const finalValue = val ? toUTC(val) : null;
    setLoading(true);
    await patchCallback(finalValue);
    setLoading(false);
  };

  return (
    <DatePicker
      clearable={false}
      rightSection={loading && <Loader size="xs" />}
      onChange={onChange}
      label={label && <Text size="xs">{label}</Text>}
      value={value}
      sx={{ width: 110 }}
      {...rest}
    />
  );
};

export default BookitupDateInput;
