import React from 'react';
import { Icon } from '../Icons';

export const RemovableTextFormatter = ({ value, patchField, remove }) => {
  const styles = {
    container: {
      border: '1px solid #d3d3d3',
      borderRadius: 4,
      padding: 5,
      width: 'fit-content',
      display: 'flex',
      margin: 2,
    },
    value: {
      width: 'fit-content',
    },
    icon: {
      padding: 2,
    },
  };

  if (!value) {
    return null;
  }

  return (
    <div style={styles.container}>
      <div>
        <div style={styles.value}>{value[patchField]}</div>
      </div>
      {typeof remove === 'function' ? (
        <div className="clickable-icon" style={styles.icon}>
          <Icon onClick={() => remove(value)} value="TIMES" />
        </div>
      ) : null}
    </div>
  );
};
