import {FC} from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';

import DocumentEventStatus from "../../../Molecules/Event/Documents/DocumentEventStatus";
import {WorkflowDTO} from "../../../Types/LogT";


const EventWorkflowListItem: FC<{workflow : WorkflowDTO}> = ({workflow}) => {
  const navigate: NavigateFunction = useNavigate();

  const _onClick = () => {
    navigate(`/events/${workflow.eventId}/details`);
  };

  return (
    <tr>
      <td onClick={_onClick} style={{ cursor: 'pointer' }}>
        {workflow.name}
      </td>

      <td onClick={_onClick}>
        {workflow.eventId && (
          <DocumentEventStatus shortVersion={false} eventId={workflow.eventId} hideDate={false} />
        )}
      </td>

    </tr>
  );
};

export default EventWorkflowListItem;
