import { FC, useContext } from 'react';
import { openConfirmModal } from '@mantine/modals';
import { useLingui } from '@lingui/react';
import { Text } from '@mantine/core';
import { TransButtonLink } from '../../../Atoms/Buttons';
import { Icon } from '../../../Atoms/Icons';
import { ContactDeleteFlowContext } from './ContactDeleteFlowContext';

const ContactDeleteFlow3: FC = () => {
  const { recommendations, isLoading, removeRecomendations } = useContext(ContactDeleteFlowContext);

  const { i18n } = useLingui();

  const confirmDeleteRecomendations = () => {
    openConfirmModal({
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          {i18n._('common.delete.recommendations.confirm.head')}
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      labels: { confirm: 'Ja', cancel: 'Abbrechen' },
      children: <Text>{i18n._('common.delete.recommendations.confirm')}</Text>,
      onConfirm: removeRecomendations,
    });
  };

  if (!recommendations || recommendations?.length === 0) {
    return (
      <div>
        <Icon value="CHECK" /> Keine verknüpften Vermittlungen zu diesem Kontakt.
      </div>
    );
  }

  return (
    <div>
      <div>
        Um diesen Kontakt zu löschen, musst Du vorher die Löschung der Vermittlungen mit diesem Kontakt
        bestätigen. Es werden dabei <b>keine E-Mail-Benachrichtigungen</b> an den Kontakt versendet.
      </div>

      <TransButtonLink
        isLoading={isLoading}
        style={{ color: '#9499a7' }}
        icon="TRASH"
        className="primary-link"
        onClick={confirmDeleteRecomendations}
        text={`${recommendations?.length ?? '0'} Vermittlungen löschen`}
      />
    </div>
  );
};

export default ContactDeleteFlow3;
