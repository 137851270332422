/* https://gomakethings.com/how-to-get-the-closest-parent-element-with-a-matching-selector-using-vanilla-javascript/ */
import moment from 'moment';
import { translate } from '../Service/PlaceholderTranslationService';
import { replaceTemplatePlaceholdersWithValues } from '../Service/Placeholder/PlaceholderResover';

const umlautMap = {
  '\u00dc': 'UE',
  '\u00c4': 'AE',
  '\u00d6': 'OE',
  '\u00fc': 'ue',
  '\u00e4': 'ae',
  '\u00f6': 'oe',
  '\u00df': 'ss',
};

export const replaceUmlaute = (str) =>
  str
    .replace(/[\u00dc|\u00c4|\u00d6][a-z]/g, (a) => {
      const big = umlautMap[a.slice(0, 1)];
      return big.charAt(0) + big.charAt(1).toLowerCase() + a.slice(1);
    })
    .replace(new RegExp(`[${Object.keys(umlautMap).join('|')}]`, 'g'), (a) => umlautMap[a]);

export const getClosest = function (elem, selector) {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function (s) {
        const matches = (this.document || this.ownerDocument).querySelectorAll(s);
        let i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }

  // Get the closest matching element
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

export const join = (array, separator) => {
  const validNames = [];
  array.forEach((name) => {
    if (name !== null && name !== '' && name !== 0 && name !== ' ') {
      validNames.push(name);
    }
  });
  if (validNames.length > 0) {
    return validNames.join(`${separator} `);
  }
  return '';
};

// Regular expression to match substrings in the format "(n)"
export const notificationCountPattern = /\(\d+\)/;

const awsCharsRegEx = /[\\/?%#&:;|+=()<>@*"'`]/g;

export const containsAwsReservedChar = (str) => awsCharsRegEx.test(str);

const specialCharsRegEx = /[~`´!#$%\^&§@€»«¢łĸ®ŧ←¼¨øþåſðđ©ª„“µ·*+=\-\[\]\\';.,/(){}|\\"°:<>\?]/g;

export const hasSpecialChar = (str) => specialCharsRegEx.test(str);

export const replaceSpecialChar = (str) => str.replaceAll(specialCharsRegEx, '');

export const findByTestAttr = (component, attr) => {
  const wrapper = component.find(`[data-testid='${attr}']`);
  return wrapper;
};

export const findFirstEmailInString = (searchString) => {
  const emailRegex =
    /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

  let match;
  if ((match = emailRegex.exec(searchString))) {
    return match[0];
  }
};

export const filterContactInfosFromString = (string) => {
  const contact = {};
  string = string.replace(/(<([^>]+)>)/gi, '');

  // rm unwanted whitespaces
  string = string.trim();
  string = string.replace(/&nbsp;/g, '');

  // extract email if present
  const optionalEmail = findFirstEmailInString(string);
  if (optionalEmail) {
    contact.email = optionalEmail;
    string = string.replace(optionalEmail, '');
    string = string.trim();
  }

  // rm multiple whitespaces
  string = string.replace(/ +(?= )/g, '');

  if (string !== '') {
    const firstAndLastName = string.split(' ');

    if (firstAndLastName.length === 1) {
      contact.firstName = firstAndLastName[0];
    } else {
      // set last string as lastName
      contact.lastName = firstAndLastName[firstAndLastName.length - 1];
      string = string.replace(contact.lastName, '');

      // rm whitespace between last- and firstName
      string = string.trim();

      contact.firstName = string;
    }
  }

  return contact;
};

export const preChange = (patch, i18n) => {
  console.log('preChange', { patch });
  const result = {};
  let obj;
  for (obj in patch) {
    if (typeof patch[obj] === 'string') {
      let changedValue = translate.toEnglish(patch[obj], i18n);
      if (isNaN(changedValue)) {
        // remove <div>'s
        changedValue = changedValue.replace(/^<div[^>]*>|<\/div>$/g, '');
      }
      result[obj] = changedValue;
    }
  }

  return result;
};

export const getForSend = (message, patch, i18n, customer, event, location, customAttributeValues) => {
  const m = copy(message, patch, event ? event.id : undefined);

  console.log(message, patch);

  m.message = replaceTemplatePlaceholdersWithValues(i18n, message.message, {
    event,
    customer,
    eventLocation: location,
    customAttributeValues,
  });

  m.subject = replaceTemplatePlaceholdersWithValues(i18n, message.subject, {
    event,
    customer,
    eventLocation: location,
    customAttributeValues,
  });

  console.log('getForSend', { m });
  return m;
};

export const getForDraft = (message, patch, eventId, i18n) => {
  const m = copy(message, patch, eventId);

  m.message = translate.toEnglish(m.message, i18n);
  m.subject = translate.toEnglish(m.subject, i18n);

  console.log('getForDraft', { m });
  return m;
};

export const isEmpty = (str) => !str || str.length === 0;

const copy = (message, patch, eventId) => {
  let m = {
    id: message.id,
    message: message.message,
    subject: message.subject,
    to: message.to,
    cc: message.cc,
    bcc: message.bcc,
    attachments: message.attachments,
    eventId,
  };

  if (patch) {
    console.log('override message', { message });
    console.log('by patch', { patch });
    m = {
      ...m,
      ...patch,
    };
  }

  // fix: remove eventually break at the end in subject
  m.subject = m.subject ? m.subject.replace(/<br>/g, '') : null;

  m.message = m.message ? m.message.replace(/\n/g, '<br/>') : null;

  // remove &nbsp;'s
  m.subject = m.subject ? m.subject.replace(/&nbsp;/g, ' ') : null;
  m.message = m.message ? m.message.replace(/&nbsp;/g, ' ') : null;

  // remove <span>'s (with style)
  if (m.subject) {
    m.subject = m.subject.replace(/<span[^>]*>|<\/span>/g, '');
  }

  if (m.message) {
    m.message = m.message.replace(/<span[^>]*>|<\/span>/g, '');
  }

  if (m.cc && m.cc.indexOf(',') >= 0) {
    m.cc.replace(',', ' ');
  }
  if (m.bcc && m.bcc.indexOf(',') >= 0) {
    m.bcc.replace(',', ' ');
  }

  return m;
};

function setCaretPosition(elemId, caretPos) {
  const el = document.getElementById(elemId);

  el.value = el.value;
  // ^ this is used to not only get "focus", but
  // to make sure we don't have it everything -selected-
  // (it causes an issue in chrome, and having it doesn't hurt any other browser)

  if (el !== null) {
    if (el.createTextRange) {
      const range = el.createTextRange();
      range.move('character', caretPos);
      range.select();
      return true;
    }
    // (el.selectionStart === 0 added for Firefox bug)
    if (el.selectionStart || el.selectionStart === 0) {
      el.focus();
      el.setSelectionRange(caretPos, caretPos);
      return true;
    }
    // fail city, fortunately this never happens (as far as I've tested) :)
    el.focus();
    return false;
  }
}

function replaceSelectedText(replacementText, nodeName) {
  let sel;
  let range;
  if (window.getSelection) {
    sel = window.getSelection();
    if (sel.rangeCount) {
      range = sel.getRangeAt(0);
      range.deleteContents();

      const b = document.createElement(nodeName);
      console.log({ replacementText });
      b.innerHTML = replacementText;
      // range.insertNode(b);
    }
  } else if (document.selection && document.selection.createRange) {
    range = document.selection.createRange();
    range.text = replacementText;
  }
}

function getHTMLOfSelection(nodeName) {
  let range;
  if (document.selection && document.selection.createRange) {
    range = document.selection.createRange();
    range.deleteContents();
    let b = document.createElement(nodeName);
    b.innerHTML = range.htmlText;
    range.insertNode(b);
    console.log('REPLACED');
    return range.htmlText;
  }
  if (window.getSelection) {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      range = selection.getRangeAt(0);

      console.log({ selection });

      const clonedSelection = range.cloneContents();
      const div = document.createElement('div');
      div.appendChild(clonedSelection);

      range.deleteContents();

      if (div.innerHTML !== '') {
        let b = document.createElement(nodeName);
        b.innerHTML = div.innerHTML;
        range.insertNode(b);
      } else {
        console.log('empty string');
      }

      return div.innerHTML;
    }
    return '';
  }
  return '';
}

export const randomInteger = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const formatDuration = (distance) => {
  const { travelTimeInSeconds } = distance;
  if (travelTimeInSeconds) {
    if (travelTimeInSeconds < 60 * 60) {
      return `${moment.duration(travelTimeInSeconds, 'seconds').format('m')} min`;
    }
    return `${moment.duration(travelTimeInSeconds, 'seconds').format('h:mm')} h`;
  }
  return '';
};

export const camelize = (str) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (index === 0 ? word.toLowerCase() : word.toUpperCase()))
    .replace(/\s+/g, '');

export const stringToNumber = (value) => {
  if (!isNaN(value)) {
    return value;
  }

  value = value.replace(',', '.');

  const numberValue = parseFloat(value);

  if (!isNaN(numberValue)) {
    return numberValue;
  }
  return null;
};

export const formatTimeValue = (value) => {
  let tempValue = value || '';

  if (tempValue.length > 0) {
    if (tempValue.length === 1) {
      if (!isNaN(Number(tempValue))) {
        tempValue = `0${tempValue}:00`;
      } else {
        return null;
      }
    } else if (tempValue.length === 2) {
      if (!isNaN(Number(tempValue))) {
        if (Number(tempValue) <= 24) {
          tempValue = `${value}:00`;
        } else {
          return null;
        }
      } else if (tempValue.indexOf(':') === 1) {
        // e.g.: 4:
        tempValue = `0${tempValue}00`;
      } else if (value.indexOf(',') === 1) {
        // e.g.: 4,
        tempValue = `0${value.substring(0, 1)}:00`;
      } else {
        return null;
      }
    } else if (tempValue.length <= 5) {
      if (tempValue.includes(':')) {
        if (tempValue.indexOf(':') === 1) {
          if (tempValue.length === 3) {
            // e.g.: 4:0
            tempValue = `0${tempValue}0`;
          }
          if (tempValue.length === 4) {
            // e.g.: 6:00
            tempValue = `0${tempValue}`;
          }
        } else if (tempValue.indexOf(':') === 2) {
          if (tempValue.length === 3) {
            // e.g.: 14:
            tempValue += '00';
          }
          if (tempValue.length === 4) {
            // e.g.: 10:0
            tempValue += '0';
          }
        } else if (tempValue.indexOf(':') === 1) {
          if (tempValue.length === 3) {
            tempValue = `0${tempValue}`;
          } else {
            // hinter dem Doppelpunkt stehen mehr als 2 Zeichen => nicht erlaubt
            return null;
          }
        }
      } else if (value.indexOf(',') !== -1) {
        if (value.indexOf(',') === 1) {
          // e.g.: 4,
          tempValue = `0${value.substring(0, 1)}:00`;
        } else if (value.indexOf(',') === 2) {
          // e.g.: 04, oder 99,
          if (!isNaN(Number(value.substring(0, 2))) && Number(value.substring(0, 2)) < 24) {
            if (value.length > 3) {
              if (value.length > 4) {
                if (Number(value.substring(3, 5)) < 60) {
                  tempValue = `${value.substring(0, 2)}:${value.substring(3, 5)}`;
                }
              } else if (Number(value.substring(3, 4)) < 6) {
                tempValue = `${value.substring(0, 2)}:${value.substring(3, 4)}0`;
              }
            } else {
              tempValue = `${value.substring(0, 2)}:00`;
            }
          }
        }
      } else if (!isNaN(Number(tempValue))) {
        if (tempValue.length === 3) {
          tempValue = `0${tempValue.substring(0, 1)}:${tempValue.substring(1, 3)}`;
        } else if (tempValue.length === 4) {
          tempValue = `${tempValue.substring(0, 2)}:${tempValue.substring(2, 4)}`;
        } else {
          // e.g.: 04:000
          return null;
        }
      } else {
        return null;
      }
    } else {
      // value.length > 5
    }

    if (tempValue === '24:00') {
      tempValue = '00:00';
    }
    const found = tempValue.match(/([01]?[0-9]|2[0-3]):[0-5][0-9]/g);
    if (found) {
      return found[0];
    }
    return null;
  }

  return null;
};

export const nullValuesToUndefined = (patch) => {
  for (obj in patch) {
    if (patch[obj] === null) {
      patch[obj] = undefined;
    }
  }
}
