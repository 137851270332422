// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import {Accordion, Box, Group, Text} from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { i18n } from '@lingui/core';
import { IconChevronRight, IconRefresh } from '@tabler/icons';
import PanePaper from '../../PanePaper';
import { WorkflowDTO } from '../../../Types/LogT';
import { WorkflowS } from '../../../Service/WorkflowS';
import SkeletonItems from '../../../Atoms/SkeletonItems';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import Workflow from './Workflow';
import { openAddWorkflowModal } from './WorkflowModals';
import {ErrorBoundary} from "react-error-boundary";
import BookitupThemeIcon from '../../../Atoms/BookitupThemeIcon';

const WorkflowsPane = () => {
  const { id: eventId } = useParams();
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const [workflows, setWorkflows] = useState<WorkflowDTO[]>([]);
  const [openedWorkflows, setOpenedWorkflows] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const isEmpty = workflows.length === 0;
  const navigate = useNavigate();

  useEffect(() => {
    getWorkflows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const getWorkflows = () => {
    if (eventId) {
      setLoading(true);
      WorkflowS.fetchWorkflows(eventId)
        .then(setWorkflows)
        .finally(() => setLoading(false));
    }
  };

  if (!eventId) {
    return null;
  }

  const removeWorkflow = (workflow: WorkflowDTO) => setWorkflows(workflows.filter((w) => w.id !== workflow.id));

  const addWorkflow = () => {
    executeWithLicenceCheck(LicenceS.Restrictions.AUTOMATIONS, () => openAddWorkflowModal(getWorkflows, eventId));
  };

  const toggleAccordionItem = (itemId: string, show: boolean) => {
    if (show) {
      setOpenedWorkflows([...openedWorkflows, itemId]);
    } else {
      setOpenedWorkflows(openedWorkflows.filter((owId) => owId !== itemId));
    }
  };

  return (
    <PanePaper
      title={i18n._('event.details.workflows.pane.title')}
      rightSection={
        <Group spacing="xs">
          {!loading && (
            <BookitupThemeIcon onClick={getWorkflows}>
              <IconRefresh />
            </BookitupThemeIcon>
          )}
          <BookitupAddIcon onClick={addWorkflow} />
        </Group>
      }
    >
      {loading && <SkeletonItems height={60} />}
      {!loading && isEmpty && (
        <Text italic color="dimmed" size="xs" weight="lighter">
          Noch nichts hinzugefügt.
        </Text>
      )}
      {!loading && !isEmpty && (
        <Accordion
          multiple
          value={openedWorkflows}
          chevronPosition="left"
          chevron={<IconChevronRight size="1rem" />}
          styles={{
            chevron: {
              '&[data-rotate]': {
                transform: 'rotate(90deg)',
              },
            },
          }}
        >
          {workflows.map((workflow) => (
            <ErrorBoundary fallback={
              <Box sx={{ marginTop: 10, marginBottom: 10, marginLeft: 15 }}>
                <Text size="sm" >
                  ⚠️ Oh no! Im Workflow
                {' '}
                <Text
                  weight={"bolder"}
                  span
                  component="a"
                  color="blue"
                  onClick={() => navigate(`/settings/workflow/templates/${workflow.templateId}`)}
                  sx={{ cursor: 'pointer' }}
                >
                  {workflow.name}
                </Text>{' '}
                  liegt ein Fehler vor.
                </Text>
                <Text size="xs" color="dimmed">
                  Bitte kontaktiere den Support, um das Problem zu beheben.
                </Text>
              </Box>
            }>
              <Workflow
                key={workflow.id}
                previewOpened={openedWorkflows.includes(workflow.id)}
                setShowPreview={(show: boolean) => toggleAccordionItem(workflow.id, show)}
                workflow={workflow}
                onDelete={() => removeWorkflow(workflow)}
              />
            </ErrorBoundary>

          ))}
        </Accordion>
      )}
    </PanePaper>
  );
};

export default WorkflowsPane;
