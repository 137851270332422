import { Trans } from '@lingui/react';
import React, { useContext, useState } from 'react';
import { TransButtonDefault, TransButtonPrimaryWithSpinner } from '../../Atoms/Buttons';
import { ModalDialog, useModalState } from '../../Molecules/Dialogs';
import {
  insertPositionsAfterTextBlock,
  insertPositionsAfterTextBlockInTemplate,
} from '../../Service/restapi/textBlockService';
import { DocumentSelector } from './DocumentSelector';

export default function ({ eventId, handleChange, contractId, contractTemplateName, textBlockId }) {
  const { show, toggle } = useModalState(false);
  const [selectedDocument, setSelectedDocument] = useState(undefined);

  function insertPositions() {
    insertPositionsAfterTextBlock(textBlockId, contractId, selectedDocument.id).then((resp) => {
      if (resp.ok) {
        resp.json().then((contract) => {
          handleChange(contract);
          toggle();
        });
      }
    });
  }

  function insertPositionsInTemplate() {
    insertPositionsAfterTextBlockInTemplate(textBlockId, contractTemplateName)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((newContract) => {
            handleChange(newContract);
          });
        } else {
          console.error(
            `could not put positions after textBlock ${textBlockId} of contractTemplate ${contractTemplateName}`,
          );
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(
          `could not put positions after textBlock ${textBlockId} of contractTemplate ${contractTemplateName}`,
        );
      })
      .finally(() => {});
  }

  function selectDocument() {
    if (eventId) {
      toggle();
    } else {
      insertPositionsInTemplate();
    }
  }

  return (
    <>
      <TransButtonDefault
        style={{ margin: 5 }}
        text="textBlocks.button.add.positions"
        onClickHandler={selectDocument}
      />
      <ModalDialog
        header={<Trans id="Dokument auswählen" />}
        body={
          <>
            <Trans id="Wähle das Dokument aus, von dem die Positionen eingefügt werden sollen:" />
            <br />
            <DocumentSelector
              eventId={eventId}
              entityType="documents"
              selectedId={selectedDocument ? selectedDocument.id : undefined}
              onSelect={setSelectedDocument}
            />
          </>
        }
        footer={
          <div>
            <TransButtonPrimaryWithSpinner
              onClickHandler={() => {
                insertPositions();
              }}
              text="common.apply"
            />
            <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />
          </div>
        }
        show={show}
        toggle={toggle}
      />
    </>
  );
}
