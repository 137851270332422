import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EventContext } from '../../Service/Context/EventContext';
import { EditableProperty } from './EditableProperty';

/*
using:
<EditableEventProperty patchField="phoneNumber"/>
 */
export const EditableEventDateProperty = ({ patchField, preChange, placeholder, editFormatter, labelFormatter, testId }) => {
  const { event, handleChange } = useContext(EventContext);

  function changeEventDate(patch) {
    handleChange({
      dates: [
        {
          ...event.dates[0],
          ...patch,
        },
      ],
    });
  }

  return (
    <EditableProperty
      dto={event.dates[0]}
      preChange={preChange}
      changeHandler={changeEventDate}
      patchField={patchField}
      placeholder={placeholder}
      targetUrl={`${process.env.REACT_APP_EVENT_SERVICE_URL}/events/${event.id}/dates`}
      editFormatter={editFormatter}
      labelFormatter={labelFormatter}
      testId={testId}
    />
  );
};

EditableEventDateProperty.propTypes = {
  patchField: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
};
