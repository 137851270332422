import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withI18n } from '@lingui/react';
import Autocomplete from '../Autocomplete';
import { InputHelp } from './InputHelp';

export const EditableAutocomplete = withI18n()(
  ({ finishCallback, value: initialValue, placeholder, patchField, insertPlaceholderUrl, index, overflowHidden, ...rest }) => {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    function finish(patch) {
      setLoading(true);
      setValue(patch[patchField]);
      finishCallback(patch)
        .catch((error) => setError(error.message))
        .then(() => setLoading(false));
    }

    const styles = {
      error: {
        borderColor: 'red',
      },
      input: {
        marginBottom: 0,
        overflow: overflowHidden ? 'hidden' : 'initial',
        // height: '100%',
        height: 34,
      },
      label: {
        color: '#8ca3b5',
        fontWeight: 800,
      },
      emailAddress: {
        height: '100%',
        minHeight: 34,
      },
    };

    return (
      <>
        <Autocomplete
          index={index}
          autoFocus
          className={loading ? 'loading' : ''}
          insertPlaceholderUrl={insertPlaceholderUrl}
          style={styles.emailAddress}
          saveValue={finish}
          patchField={patchField}
          {...rest}
          value={value}
          onChange={(patch) => setValue(patch[patchField])}
          placeholder={placeholder}
        />
        {error ? <InputHelp errorDescription={error} /> : null}
      </>
    );
  },
);

EditableAutocomplete.propTypes = {
  value: PropTypes.string,
  finishCallback: PropTypes.func,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
};

EditableAutocomplete.defaultProps = {
  autoFocus: true,
};
