/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Indicator, Popover, PopoverProps, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconBellRinging } from '@tabler/icons';
import { useContext, useState } from 'react';
import { WindowContext } from '../../Service/Context/WindowContext';
import { NotificationsContext } from '../../Service/Context/NotificationsContext';
import BookitupNotifications from './GeneralBookitupNotifications';

const phoneConfig: Partial<PopoverProps> = {
  position: 'bottom-end',
  transition: 'slide-down',
  offset: 10,
  arrowOffset: 15,
};

const desktopConfig: Partial<PopoverProps> = {
  position: 'right-start',
  offset: 15,
  transition: 'slide-right',
};

const NotificationNavIcon = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { xs, sm, windowWidth, windowHeight } = useContext(WindowContext);
  const { markAsSeen, unseenCount } = useContext(NotificationsContext);
  const opts = sm ? phoneConfig : desktopConfig;
  const popoverWidth = xs ? windowWidth - 10 : 450;
  const popoverHeight = xs ? windowHeight - 130 : 600;

  const onClose = () => {
    close();
    markAsSeen();
  };

  return (
    <Tooltip disabled={opened} label="Benachrichtigungen" position="right" withArrow transition="slide-right">
      <Box
        sx={(theme) => ({
          backgroundColor: opened && theme.colors.lightBlue ? theme.colors.lightBlue[0] : 'transparent',
          width: 40,
          height: 40,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 10,
          '&:hover': {
            backgroundColor: theme.colors.gray[0],
          },
        })}
      >
        <Popover withArrow radius="md" opened={opened} onClose={onClose} width={popoverWidth} {...opts}>
          <Popover.Target>
            <div>
              <Indicator
                inline
                disabled={unseenCount < 1}
                radius="xl"
                size={20}
                color="red"
                label={
                  <Text color="white" size={10}>
                    {unseenCount}
                  </Text>
                }
              >
                <IconBellRinging
                  style={{ color: opened ? '#1D5191' : '#212121', cursor: 'pointer' }}
                  size={24}
                  onClick={() => (opened ? close() : open())}
                />
              </Indicator>
            </div>
          </Popover.Target>
          <Popover.Dropdown
            id="scrollableDiv"
            sx={{
              height: popoverHeight,
              overflow: 'auto',
              boxShadow: '0px 0px 8px #00000039',
              borderRadius: 10,
            }}
          >
            <BookitupNotifications />
          </Popover.Dropdown>
        </Popover>
      </Box>
    </Tooltip>
  );
};

export default NotificationNavIcon;
