import { FC, useContext, useEffect, useState } from 'react';
import { closeAllModals, openModal } from '@mantine/modals';
import { ActionIcon, Menu, Text } from '@mantine/core';
import { IconDotsVertical, IconPencil, IconTrash } from '@tabler/icons';
import { useLingui } from '@lingui/react';
import PanePaper from '../../../Molecules/PanePaper';
import { CreateResourceCategory, ResourceCategory } from '../../../Types/ResourceT';
import ResourceCategoryS from '../../../Service/restapi/resourceCategoryService';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { ResourceAddonContext } from '../../../Service/Context/ResourceAddonsContext';
import AddEditResourceCategoryModal from './AddEditResourceCategoryModal';
import DeleteResourceModal from './DeleteResourceCategoryModal';

const ResourceCategoriesList: FC = () => {
  const { executeWithCheck } = useContext(ResourceAddonContext);
  const [categories, setCategories] = useState<ResourceCategory[]>([]);
  const [lastModified, setLastModified] = useState(new Date());
  const existingNames = categories.map((category) => category.name);

  useEffect(() => {
    ResourceCategoryS.getCategories().then(setCategories);
  }, [lastModified]);

  const refresh = () => setLastModified(new Date());

  const openAddCategoryModal = () => {
    const onCreate = (c: Partial<ResourceCategory>) => {
      ResourceCategoryS.createCategory(c as CreateResourceCategory)
        .then((category) => {
          if (category) {
            closeAllModals();
          }
        })
        .finally(() => setLastModified(new Date()));
    };

    openModal({
      title: (
        <Text weight="bolder" size="xl" sx={{ lineHeight: 1.2 }}>
          Kategorien hinzufügen
        </Text>
      ),
      children: <AddEditResourceCategoryModal onCreate={onCreate} existingNames={existingNames} />,
    });
  };

  return (
    <PanePaper
      title="Kategorien"
      rightSection={<BookitupAddIcon onClick={() => executeWithCheck(openAddCategoryModal)} />}
    >
      {categories.map((c) => (
        <CategoryTile
          key={c.id}
          refresh={refresh}
          existingNames={existingNames}
          lastModified={lastModified}
          category={c}
        />
      ))}
    </PanePaper>
  );
};

const CategoryTile: FC<{
  category: ResourceCategory;
  refresh: () => void;
  existingNames: string[];
  lastModified?: Date;
}> = ({ category, refresh, existingNames, lastModified }) => {
  const { children: categories } = category;
  const { i18n } = useLingui();

  const openEditCategoryModal = (initialCategory: ResourceCategory) => {
    const onCreate = (c: Partial<ResourceCategory>) => {
      ResourceCategoryS.updateCategory(initialCategory.id, c)
        .then((ctg) => {
          if (ctg) {
            closeAllModals();
          }
        })
        .finally(refresh);
    };

    openModal({
      title: (
        <Text weight="bolder" size="xl" sx={{ lineHeight: 1.2 }}>
          Kategorien hinzufügen
        </Text>
      ),
      children: (
        <AddEditResourceCategoryModal
          initialCategory={initialCategory}
          onCreate={onCreate}
          existingNames={existingNames.filter((name) => initialCategory.name !== name)}
        />
      ),
    });
  };

  const openDeleteModal = (categoryId: string) =>
    openModal({
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Kategory löschen
        </Text>
      ),
      children: <DeleteResourceModal categoryId={categoryId} removeCategory={refresh} />,
    });
  const styles = {
    container: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 10,
      padding: 8,
      backgroundColor: 'rgb(251, 251, 251)',
      border: '1px solid rgba(0, 0, 0, .125)',
      borderRadius: 4,
    },

    child: {
      marginLeft: 25,
      paddingLeft: 10,
    },
  };

  return (
    <>
      <div style={styles.container}>
        <div style={{ alignSelf: 'center' }}>{category.name}</div>
        <div style={{ alignSelf: 'end' }}>
          <Menu shadow="md" width={200}>
            <Menu.Target>
              <ActionIcon>
                <IconDotsVertical />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Item onClick={() => openEditCategoryModal(category)} icon={<IconPencil size={14} />}>
                {i18n._('actions.edit')}
              </Menu.Item>
              {categories.length === 0 && (
                <Menu.Item onClick={() => openDeleteModal(category.id)} icon={<IconTrash size={14} />}>
                  {i18n._('actions.delete')}
                </Menu.Item>
              )}
            </Menu.Dropdown>
          </Menu>
        </div>
      </div>

      {categories.map((c) => (
        <div key={c.id} style={styles.child}>
          <CategoryTile refresh={refresh} lastModified={lastModified} category={c} existingNames={existingNames} />
        </div>
      ))}
    </>
  );
};

export default ResourceCategoriesList;
