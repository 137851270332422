import React from 'react';
import TextBlock from './TextBlock';

const TextBlockAndButtons = ({
  textBlock,
  index,
  contract,
  handleChange,
  event,
  customer,
  eventLocation,
  _document,
  customAttributeValues,
  settings,
}) => {
  const styles = {
    container: {
      display: 'flex',
      marginBottom: 10,
    },
    detailComponent: {
      flex: 1,
    },
    menuComponent: {
      width: 40,
      textAlign: 'center',
      alignSelf: 'center',
    },
    dropdownComponent: {
      marginBottom: 10,
    },
  };

  function deleteTextBlock(textBlockId) {
    if (contract.aboveTextBlocks) {
      if (contract.aboveTextBlocks.find((tB) => tB.id === textBlockId)) {
        let textBlocks = contract.aboveTextBlocks.filter((tB) => tB.id !== textBlockId);
        handleChange({ aboveTextBlocks: textBlocks });
      } else if (contract.belowTextBlocks.find((tB) => tB.id === textBlockId)) {
        let textBlocks = contract.belowTextBlocks.filter((tB) => tB.id !== textBlockId);
        handleChange({ belowTextBlocks: textBlocks });
      }
    }
  }

  return (
    <div>
      <div style={styles.container}>
        <div style={styles.detailComponent}>
          <TextBlock
            key={`aboveTextBlock${index}`}
            textBlockId={textBlock.id}
            deleteTextBlock={deleteTextBlock}
            customer={customer}
            contract={contract}
            _document={_document}
            event={event}
            eventLocation={eventLocation}
            customAttributeValues={customAttributeValues}
            settings={settings}
          />
        </div>
        <div style={styles.menuComponent}>
          <div style={styles.dropdownComponent}>
            {/* <div style={styles.trashComponent}>
                            {index !== 0 && <TooltipButton isLoading={false} id={"up" + index} text="actions.up"
                                                           onClick={() => {

                                                           }} icon="UP"/>}
                            {lastTextBlock.id !== textBlock.id &&
                            <TooltipButton isLoading={false} id={"down" + index} text="actions.down"
                                           onClick={() => {

                                           }} icon="DOWN"/>}
                        </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextBlockAndButtons;
