import React, { useContext } from 'react';
import { withI18n } from '@lingui/react';
import { DndProvider } from 'react-dnd-latest';
import { HTML5Backend } from 'react-dnd-html5-backend-latest';
import { EditableDivInput } from '../../Atoms/inputs/EditableDivInput';
import { HtmlPlaceholderFormatter } from '../../Atoms/Formatter/HtmlPlaceholderFormatter';
import { QuestionnaireContext } from '../../Service/Context/QuestionnaireContext';
import { EditableQuestionnaireProperty } from '../../Atoms/EditableProperties/EditableQuestionnaireProperty';
import { preChange } from '../../Utils/utils';
import QuestionBundleList from './QuestionBundleList';

export default withI18n()(({ i18n, event, eventLocation, customer, customAttributeValues, settings }) => {
  const { questionnaire, setQuestionnaire } = useContext(QuestionnaireContext);

  function handleChange(patch) {
    setQuestionnaire(patch);
  }

  return (
    <>
      <div>
        <div className="section-header">Kopfzeile</div>
        <EditableQuestionnaireProperty
          name="headRow"
          style={{ borderRadius: '0px 0px 4px 4px' }}
          preChange={(p) => preChange(p, i18n)}
          patchField="headRow"
          changeHandler={handleChange}
          entity={questionnaire}
          editFormatter={EditableDivInput}
          labelFormatter={HtmlPlaceholderFormatter}
          settings={settings}
          event={event}
          eventLocation={eventLocation}
          customer={customer}
          customAttributeValues={customAttributeValues}
        />
      </div>
      <DndProvider backend={HTML5Backend}>
        <QuestionBundleList
          settings={settings}
          event={event}
          eventLocation={eventLocation}
          customer={customer}
          customAttributeValues={customAttributeValues}
        />
      </DndProvider>
      <div style={{ marginTop: 15, marginBottom: 10 }}>
        <div className="section-header">Fußzeile</div>
        <EditableQuestionnaireProperty
          name="endRow"
          style={{ borderRadius: '0px 0px 4px 4px' }}
          patchField="endRow"
          preChange={(p) => preChange(p, i18n)}
          changeHandler={handleChange}
          entity={questionnaire}
          editFormatter={EditableDivInput}
          labelFormatter={HtmlPlaceholderFormatter}
          settings={settings}
          event={event}
          eventLocation={eventLocation}
          customer={customer}
          customAttributeValues={customAttributeValues}
        />
      </div>
    </>
  );
});
