import React, { useContext, useState } from 'react';
import { useModals } from '@mantine/modals';
import { Trans } from '@lingui/react';
import { EventContext } from '../../../Service/Context/EventContext';
import { CustomModalHeader } from '../../Dialogs';
import PanePaper from '../../PanePaper';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import TaskList from './TaskList';

export default React.memo(() => {
  const { event } = useContext(EventContext);
  const { profile } = useContext(ProfileContext);
  const [lastModified, setLastModified] = useState(new Date());
  const { openContextModal, closeAll } = useModals();
  const { executeWithLicenceCheck } = useContext(ViolationContext);

  const openTaskCreateModal = () =>
    openContextModal('taskCreateModal', {
      title: (
        <CustomModalHeader>
          <Trans id="task.create" />
        </CustomModalHeader>
      ),
      innerProps: {
        profile,
        entityId: event.id,
        entityType: 'events',
        finish: () => {
          closeAll();
          setLastModified(new Date());
        },
      },
      centered: true,
    });

  return (
    <PanePaper
      withShadow
      withoutBorder
      title="Aufgaben"
      tooltip="help.event.tasks.pane"
      rightSection={
        <BookitupAddIcon
          onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.SAVE_TASK, openTaskCreateModal)}
        />
      }
    >
      <TaskList entityId={event.id} entityType="events" lastModified={lastModified} />
    </PanePaper>
  );
});
