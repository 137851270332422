import React, { useContext } from 'react';
import { CustomerContext } from '../../Service/Context/CustomerContext';

export const CustomerContextInjection = ({ children }) => {
  const { customer, changeCustomer } = useContext(CustomerContext);

  return React.cloneElement(children, {
    entity: customer,
    changeHandler: changeCustomer,
  });
};
