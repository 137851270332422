import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { withI18n } from '@lingui/react';
import { TransButtonDefault } from '../Buttons';
import { ModalDialog } from '../../Molecules/Dialogs';
import { dateFormatService } from '../../Service/DateFormatService';
import { getPrivateAndBusinessEvents } from '../../Service/restapi/calendarAccountService';
import Calendar from './Calendar';

export default withI18n()(({ show, toggle, i18n, dates, eventId }) => {
  const [calendarEvents, setCalendarEvents] = useState(undefined);

  useEffect(() => {
    if (show) {
      fetchEvents();
    }
  }, [show]);

  function fetchEvents() {
    getPrivateAndBusinessEvents(dates, eventId).then((resp) => {
      if (resp.ok) {
        resp.json().then((events) => {
          setCalendarEvents(events);
        });
      }
    });
  }

  const format = i18n._('format.date.long');

  return (
    <ModalDialog
      header={`Dein privater Kalender am ${moment(dateFormatService.getDate(dates[0].date)).format(format)}`}
      body={<Calendar eventDates={dates} calendarEvents={calendarEvents} toolbar={false} view="day" />}
      footer={<TransButtonDefault onClickHandler={toggle} text="actions.ok" />}
      show={show}
      toggle={toggle}
    />
  );
});
