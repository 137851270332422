import React from 'react';
import { FileModalDialog } from '../Dialogs/FileModalDialog';
import { useModalState } from '../Dialogs';
import { TransButtonDefaultWithIcon } from '../../Atoms/Buttons';

export const SupportVideo = ({ entityId, fileName }) => {
  const { show, toggle } = useModalState(false);

  return (
    <>
      {/* <TransButtonDefaultWithIcon
                style={{display: "flex", justifyContent: "center", alignItems: "center", marginLeft: 5}}
                id={"showVideo-" + entityId} onClickHandler={toggle} icon={"VIDEO"}/> */}
      <FileModalDialog toggle={toggle} show={show} file={{ entityType: 'videos', entityId, fileName }} />
    </>
  );
};
