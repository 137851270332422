import { useContext, useEffect, useState } from 'react';
import { useLingui } from '@lingui/react';
import { ContextModalProps, useModals } from '@mantine/modals';
import { ModalFlow } from '../../../Molecules/Dialogs';
import { newShare, share } from '../../../Service/restapi/shareService';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import { ModalFlowStepItem } from '../../../Atoms/Stepper/MenuItem';
import { ShareFlow1 } from './ShareFlow-1';
import ShareFlowCombined1 from './ShareFlow-2-4';
import ShareFlowCombined2 from './ShareFlow-5-6';

type Props = {
  shareId?: string;
  eventId: string;
  reload?: () => void;
  profile: any;
};

const ShareModalFlow = ({ context, id, innerProps }: ContextModalProps<Props>) => {
  const { shareId, eventId, reload, profile } = innerProps;

  const { i18n } = useLingui();

  const { closeModal } = useModals();

  const [error, setError] = useState<string | undefined>(undefined);

  const [isLoading, setLoading] = useState(false);
  const [canBeFinished, setCanBeFinished] = useState(false);

  const [name, setName] = useState(undefined);
  const [password, setPassword] = useState(undefined);
  const [sharedUntil, setSharedUntil] = useState(undefined);

  const [shares, setShares] = useState<any[]>([]);

  useEffect(() => {
    setCanBeFinished(false);
    setPassword(undefined);
    setSharedUntil(undefined);

    setLoading(false);
    setShares([]);
    setError(undefined);
  }, [shareId]);

  const finish = () => {
    if (shares.length > 0) {
      setLoading(true);
      if (!shareId) {
        newShare(name, password, sharedUntil, eventId).then((resp) => {
          if (resp.ok) {
            resp.json().then((s) => {
              handleShare(s.id);
            });
          }
        });
      } else {
        handleShare(shareId);
      }
    }
  };

  const handleShare = (shareIdParam: string) => {
    share(shareIdParam, { entityTypeAndId: shares, eventId })
      .then((resp) => {
        if (resp.ok) {
          closeModal('shareModalFlow');
          reload?.();
        } else {
          console.error('could not share some document/questionnaire');
        }
      })
      .catch((error) => {
        console.error('could not share some document/questionnaire');
        console.error({ error });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSelected = (entityType: string) => shares.filter((r) => r.entityType === entityType);

  const onSelect = (entityType: string, entityId: string, label?: string) => {
    shares.push({
      entityType,
      entityId,
      label: label || i18n._(`resource.entityType.${entityType.toString().toLowerCase()}`),
    });
    setCanBeFinished(true);
  };

  const onDeselect = (entityType: string, entityId: string, label?: string) => {
    const tempShares: any[] = [];
    shares.forEach((share) => {
      if (share.entityType !== entityType && share.entityId !== entityId && share.label !== label) {
        tempShares.push(share);
      }
    });
    setShares(tempShares);
  };

  const stepBodyComponents = [
    <ShareFlow1
      key={0}
      name={name}
      setName={setName}
      password={password}
      setPassword={setPassword}
      sharedUntil={sharedUntil}
      setSharedUntil={setSharedUntil}
    />,

    <ShareFlowCombined1
      key={1}
      eventId={eventId}
      getSelected={getSelected}
      onSelectWithType={onSelect}
      onDeselectWithType={onDeselect}
    />,

    <ShareFlowCombined2
      key={2}
      eventId={eventId}
      profileId={profile.id}
      getSelected={getSelected}
      onSelectWithType={onSelect}
      onDeselectWithType={onDeselect}
    />,
  ];

  const menuItems: ModalFlowStepItem[] = [
    {
      icon: 'KEY',
      label: 'Zugang',
      validator: () => {
        const notValid = !password;
        if (notValid) {
          setError('Bitte wähle ein Password für den Zugang.');
        } else {
          setError(undefined);
        }
        return !notValid;
      },
    },
    {
      icon: 'CONTRACT',
      label: 'Dokumente auswählen',
    },
    {
      icon: 'FILE',
      label: 'Dateien auswählen',
    },
  ];

  return (
    <ModalFlow
      initialStep={shareId ? 1 : 0}
      menuItems={menuItems}
      stepBodyComponents={stepBodyComponents}
      error={error}
      isLoading={isLoading}
      canBeFinished={canBeFinished}
      finish={finish}
      finishButtonText={shareId ? 'common.share-update' : 'common.share-create'}
    />
  );
};

export default ShareModalFlow;
