// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Loader, MultiSelect, Text } from '@mantine/core';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CustomAttributeDTO } from '../../../Types/CustomAttributeT';
import { CustomAttributeS } from '../../../Service/Context/CustomAttributeS';
import LabelWithDescription from './LabelWithDescription';

interface Props {
  attribute: CustomAttributeDTO;
  initialValue?: string;
}

const MultipleChoiceInput: FC<Props> = ({ attribute, initialValue }) => {
  const { suggestions } = attribute;
  const initialParsedValue = CustomAttributeS.parseMultipleChoiceValue(initialValue);
  const { id: eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<string[]>(initialParsedValue.concat(suggestions));
  const [value, setValue] = useState<string[]>(initialParsedValue);

  const onChange = async (values: string[]) => {
    if (eventId) {
      setLoading(true);
      const valueDto = await CustomAttributeS.patchAttributeValue(attribute.id, eventId, values.join(';'));
      setLoading(false);
      if (valueDto) {
        setValue(CustomAttributeS.parseMultipleChoiceValue(valueDto.value));
      }
    }
  };

  return (
    <MultiSelect
      creatable
      searchable
      value={value}
      onChange={onChange}
      label={<LabelWithDescription attribute={attribute} />}
      data={data}
      icon={loading && <Loader size="xs" />}
      onCreate={(query) => {
        setData([...data, query]);
        return query;
      }}
      getCreateLabel={(query) => (
        <Text color="blue">
          {' '}
          + Hinzufügen{' '}
          <Text span color="black" ml={10} weight="bolder">
            {query}
          </Text>
        </Text>
      )}
    />
  );
};

export default MultipleChoiceInput;
