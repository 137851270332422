import { FC, useState } from 'react';
import { Box, Button, Center, Loader, Textarea } from '@mantine/core';
import { i18n } from '@lingui/core';
import { closeAllModals } from '@mantine/modals';
import { StockIntervalDto } from '../../../Types/ResourceT';
import ResourceS from '../../../Service/ResourceS';

interface Props {
  stockInterval: StockIntervalDto;
  onFinish: (stockInterval: StockIntervalDto) => void;
}

const EditStockIntervalModal: FC<Props> = ({ stockInterval, onFinish }) => {
  const { id: intervalId, description: initialDescription } = stockInterval;
  const [description, setDescription] = useState(initialDescription);
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    ResourceS.patchStockInterval(intervalId, { description })
      .then((_stockInterval) => {
        if (_stockInterval) {
          onFinish(_stockInterval);
          closeAllModals();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Textarea
        variant="filled"
        label="Beschreibung"
        disabled={loading}
        minRows={2}
        value={description}
        onChange={(e) => setDescription(e.currentTarget.value)}
      />
      <Center>
        <Box sx={{ width: '70%' }}>
          <Button
            fullWidth
            mt="xl"
            mb="xs"
            onClick={onSubmit}
            disabled={loading}
            leftIcon={loading && <Loader size="xs" />}
          >
            {i18n._('actions.save')}
          </Button>
          <Button fullWidth disabled={loading} color="gray" variant="subtle" onClick={() => closeAllModals()}>
            {i18n._('actions.cancel')}
          </Button>
        </Box>
      </Center>
    </>
  );
};

export default EditStockIntervalModal;
