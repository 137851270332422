import { Anchor, Breadcrumbs } from '@mantine/core';
import {
  IconBorderAll,
  IconChevronRight,
  IconCircles,
  IconCreditCard,
  IconFileDescription,
  IconFolder,
  IconGift,
  IconKey,
  IconLink,
  IconMail,
  IconRotateRectangle,
  IconShieldHalfFilled,
  IconUser,
  IconUsers,
} from '@tabler/icons';
import { FC, useContext } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import NavigationLink from '../../Atoms/Navigation/NavigationLink';
import { useNavigationItem } from '../../Organismns/Navigation/NavigationItemHook';
import { WindowContext } from '../../Service/Context/WindowContext';
import { TestS } from '../../TestS';
import { ConstantS } from '../../Service/ConstantS';
import { MixpanelS } from '../../Service/MixpanelS';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { LicenceS } from '../../Service/LicenceS';

const SettingsNavbar: FC<{ closeNavBar: () => void }> = ({ closeNavBar }) => {
  const { sm } = useContext(WindowContext);
  const navigate: NavigateFunction = useNavigate();
  const { profile } = useContext<any>(ProfileContext);
  const resourceItemActive = useNavigationItem('/settings/resources');

  // eslint-disable-next-line no-underscore-dangle
  const _onClick = (path: string, section: string) => {
    navigate(path);
    closeNavBar();
    MixpanelS.track(ConstantS.TrackingEvents.ViewContent, { page: 'Settings', section });
  };

  return (
    <>
      {!sm && (
        <Breadcrumbs separator={<IconChevronRight size={12} />} mt={5} mb={10}>
          <Anchor size="xl" weight="bolder" variant="text" p="md">
            Einstellungen
          </Anchor>
        </Breadcrumbs>
      )}
      <NavigationLink
        variant="filled"
        label="Profil"
        onClick={() => _onClick('/settings/userdata', 'Profile')}
        active={useNavigationItem('/settings/userdata')}
        icon={sm && sm && <IconUser size={16} />}
        color="lightBlue"
        testId={TestS.Navigate.SETTINGS_TO_USER_DATA}
      />
      <NavigationLink
        variant="filled"
        label="Freunde werben"
        onClick={() => _onClick('/settings/recruitment', 'Recruitment')}
        active={useNavigationItem('/settings/recruitment')}
        icon={sm && <IconGift size={16} />}
        color="lightBlue"
      />
      <NavigationLink
        label="Paket &amp; Abrechnung"
        icon={sm && <IconCreditCard size={16} />}
        childrenOffset={28}
        color="lightBlue"
      >
        <NavigationLink
          label="Paket"
          active={useNavigationItem('/settings/plans')}
          onClick={() => _onClick('/settings/plans', 'Billing plans')}
          variant="filled"
          color="lightBlue"
        />
      </NavigationLink>

      <NavigationLink
        label="Eigene Felder, Status & Art"
        icon={sm && <IconBorderAll size={16} />}
        childrenOffset={28}
        variant="filled"
        color="lightBlue"
      >
        <NavigationLink
          label="Eigene Felder"
          active={useNavigationItem('/settings/customfields')}
          onClick={() => _onClick('/settings/customfields', 'Custom fields')}
          variant="filled"
          color="lightBlue"
        />
        <NavigationLink
          label="Eigene Status"
          active={useNavigationItem('/settings/eventstates')}
          onClick={() => _onClick('/settings/eventstates', 'Event states')}
          variant="filled"
          color="lightBlue"
        />
        <NavigationLink
          label="Eigene Auftragsarten"
          active={useNavigationItem('/settings/eventkinds')}
          onClick={() => _onClick('/settings/eventkinds', 'Event kinds')}
          variant="filled"
          color="lightBlue"
        />
      </NavigationLink>

      <NavigationLink
        label="Integrationen"
        icon={sm && <IconLink size={16} />}
        childrenOffset={28}
        variant="filled"
        color="lightBlue"
      >
        <NavigationLink
          label="Google-Sync"
          active={useNavigationItem('/settings/google')}
          onClick={() => _onClick('/settings/google', 'Google panel')}
          variant="filled"
          color="lightBlue"
        />
        <NavigationLink
          label="Kalender-Sync"
          active={useNavigationItem('/settings/calendar')}
          onClick={() => _onClick('/settings/calendar', 'External calendar')}
          variant="filled"
          color="lightBlue"
        />
        {(profile && profile.kindOfWebsite) &&
          <NavigationLink
            label="Webseite verbinden"
            active={location.pathname.includes("connectwebsite")}
            onClick={() => _onClick('/settings/connectwebsite', 'Connect website')}
            variant="filled"
            color="lightBlue"
          />
        }
        <NavigationLink
          label="Kontaktformulare"
          active={useNavigationItem('/settings/contact-form')}
          onClick={() => _onClick('/settings/contact-form', 'Contact form')}
          variant="filled"
          color="lightBlue"
        />
        <NavigationLink
          label="Buchhaltung"
          active={useNavigationItem('/settings/bookkeeping')}
          onClick={() => _onClick('/settings/bookkeeping', 'Bookkeeping')}
          variant="filled"
          color="lightBlue"
        />
      </NavigationLink>

      <NavigationLink
        label="Dienstleister-Netzwerk"
        icon={sm && <IconUsers size={16} />}
        active={useNavigationItem('/settings/team')}
        onClick={() => _onClick('/settings/team', 'Team')}
        variant="filled"
        color="lightBlue"
      />
      {profile && LicenceS.hasAccessToResourceModule(profile.id) && (
        <NavigationLink
          label="Ressourcen"
          icon={sm && <IconCircles size={16} />}
          active={resourceItemActive}
          onClick={() => _onClick('/settings/resources', 'Resources')}
          variant="filled"
          color="lightBlue"
        />
      )}
      <NavigationLink
        label="Workflow"
        icon={sm && <IconRotateRectangle size={16} />}
        active={useNavigationItem('/settings/workflow')}
        onClick={() => _onClick('/settings/workflow', 'Workflow')}
        variant="filled"
        color="lightBlue"
      />

      <NavigationLink label="E-Mail" icon={sm && <IconMail size={16} />} childrenOffset={28} variant="filled">
        <NavigationLink
          label="E-Mail-Vorlagen"
          active={useNavigationItem('/settings/messages')}
          onClick={() => _onClick('/settings/messages', 'Messages')}
          variant="filled"
          color="lightBlue"
        />
        <NavigationLink
          label="Ausgangsserver"
          active={useNavigationItem('/settings/mail-account')}
          onClick={() => _onClick('/settings/mail-account', 'Mail-account')}
          variant="filled"
          color="lightBlue"
        />
        <NavigationLink
          label="Signatur"
          active={useNavigationItem('/settings/signature')}
          onClick={() => _onClick('/settings/signature', 'Signature')}
          variant="filled"
          color="lightBlue"
        />
      </NavigationLink>

      <NavigationLink
        label="Dokumente"
        icon={sm && <IconFileDescription size={16} />}
        childrenOffset={28}
        variant="filled"
        color="lightBlue"
        testId={TestS.Navigate.SETTINGS_TO_DOCUMENTS}
      >
        <NavigationLink
          label="Dokument-Vorlagen"
          active={useNavigationItem('/settings/templates')}
          onClick={() => _onClick('/settings/templates', 'Document templates')}
          variant="filled"
          color="lightBlue"
        />
        <NavigationLink
          label="Produkte"
          active={useNavigationItem('/settings/positions')}
          onClick={() => _onClick('/settings/positions', 'Products')}
          variant="filled"
          color="lightBlue"
        />
        <NavigationLink
          label="Fragebogen-Vorlagen"
          active={useNavigationItem('/settings/questionnaires')}
          onClick={() => _onClick('/settings/questionnaires', 'Questionnaires templates')}
          variant="filled"
          color="lightBlue"
        />
        <NavigationLink
          label="Vertrags-Vorlagen"
          active={useNavigationItem('/settings/contracts')}
          onClick={() => _onClick('/settings/contracts', 'Contracts templates')}
          variant="filled"
          color="lightBlue"
        />
        <NavigationLink
          label="Dokument-Layout"
          active={useNavigationItem('/settings/documents')}
          onClick={() => _onClick('/settings/documents', 'Dokument Layout')}
          variant="filled"
          color="lightBlue"
          testId={TestS.Navigate.SETTINGS_TO_DOCUMENT_LAYOUT}
        />
        <NavigationLink
          label="Mehrwertsteuer"
          active={useNavigationItem('/settings/taxes')}
          onClick={() => _onClick('/settings/taxes', 'Taxes')}
          variant="filled"
          color="lightBlue"
          testId={TestS.Navigate.SETTINGS_TO_TAXES}
        />
        <NavigationLink
          label="Nummernkreise"
          active={useNavigationItem('/settings/sequentialnumbers')}
          onClick={() => _onClick('/settings/sequentialnumbers', 'Sequential numbers')}
          variant="filled"
          color="lightBlue"
        />
      </NavigationLink>

      <NavigationLink
        label="Dateien"
        icon={sm && <IconFolder size={16} />}
        active={useNavigationItem('/settings/files')}
        onClick={() => _onClick('/settings/files', 'Files')}
        variant="filled"
        color="lightBlue"
      />
      <NavigationLink
        label="Datenschutz"
        icon={sm && <IconShieldHalfFilled size={16} />}
        active={useNavigationItem('/settings/gdpr')}
        onClick={() => _onClick('/settings/gdpr', 'GDPR')}
        variant="filled"
        color="lightBlue"
      />
      <NavigationLink
        label="Passwort &amp; Account"
        icon={sm && <IconKey size={16} />}
        active={useNavigationItem('/settings/account')}
        onClick={() => _onClick('/settings/account', 'Account details')}
        variant="filled"
        color="lightBlue"
      />
    </>
  );
};

export default SettingsNavbar;
