import React from 'react';
import { withI18n } from '@lingui/react';
import { EditableContractProperty } from '../../Atoms/EditableProperties/EditableContractProperty';
import { EditableDivInput } from '../../Atoms/inputs/EditableDivInput';
import { HtmlPlaceholderFormatter } from '../../Atoms/Formatter/HtmlPlaceholderFormatter';
import { EditableContractTemplateProperty } from '../../Atoms/EditableProperties/EditableContractTemplateProperty';

const ContactSubject =  withI18n()(
  ({
    i18n,
    contract,
    handleChange,
    preChange,
    event,
    customer,
    eventLocation,
    _document,
    customAttributeValues,
    settings,
  }) => {
    let Component = EditableContractProperty;

    if (settings) {
      Component = EditableContractTemplateProperty;
    }

    return (
      <div style={{ marginBottom: 30, marginRight: 5, marginLeft: 5 }}>
        <div style={{ color: '#9ca6af' }}>Überschrift:</div>
        <Component
          patchField="subject"
          preChange={(p) => {
            if (p.subject === '<br>') {
              p.subject = '';
            }
            return preChange(p, i18n);
          }}
          changeHandler={handleChange}
          entity={contract}
          editFormatter={EditableDivInput}
          labelFormatter={HtmlPlaceholderFormatter}
          _document={_document}
          contract={contract}
          event={event}
          eventLocation={eventLocation}
          customer={customer}
          customAttributeValues={customAttributeValues}
          settings={settings}
        />
      </div>
    );
  },
);

export default ContactSubject;
