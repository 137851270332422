/* eslint-disable react/jsx-props-no-spreading */
import { Button, Center, Loader, Text, TextInput } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { closeAllModals, openModal } from '@mantine/modals';
import { IconSearch } from '@tabler/icons';
import ResourceS from '../../Service/ResourceS';
import { Resource } from '../../Types/ResourceT';
import SelectionTable from './table/general/SelectionTable';

type Props = {
  partOfResourceId: string;
  refresh?: () => void;
};

export const openAddPartsModal = (id: string, refresh?: () => void) =>
  openModal({
    size: 'xl',
    title: (
      <Text weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
        Ressourcen hinzufügen
      </Text>
    ),
    closeOnEscape: true,
    transition: 'slide-down',
    children: <AddResourceContentModal partOfResourceId={id} refresh={refresh} />,
  });

const AddResourceContentModal: FC<Props> = ({ partOfResourceId, refresh }) => {
  const [loading, setLoading] = useState(false);
  const [allPosisibleResources, setAllPossibleResources] = useState<Resource[]>([]);
  const [selectedResources, setResources] = useState<string[]>([]);
  const [query, setQuery] = useState<string>('');

  useEffect(() => {
    const loadAllResources = async () => {
      const fetchedResources = await ResourceS.fetchPossiblePartResources(partOfResourceId);

      if (fetchedResources) {
        setAllPossibleResources(fetchedResources);
      }
    };

    loadAllResources();
  }, [partOfResourceId]);

  const createResourceParts = async () => {
    setLoading(true);
    await ResourceS.createParts(
      partOfResourceId,
      selectedResources.map((resourceId) => ({ resourceId, quantity: 1 })),
    );
    setLoading(false);
    refresh?.();
    closeAllModals();
  };

  const toggle = (resourceId: string) => {
    if (selectedResources.includes(resourceId)) {
      setResources(selectedResources.filter((id) => id !== resourceId));
    } else {
      setResources([...selectedResources, resourceId]);
    }
  };

  return (
    <>
      <Center>
        <TextInput
          defaultValue={query}
          variant="filled"
          placeholder="Ressourcen durchsuchen"
          icon={<IconSearch size={16} />}
          onChange={(e) => setQuery(e.currentTarget.value)}
          sx={{ width: 250 }}
          mb="xl"
        />
      </Center>
      <SelectionTable
        resources={allPosisibleResources.filter((r) => r.name.toLowerCase().includes(query.toLowerCase()))}
        toggle={toggle}
        selectedResources={selectedResources}
      />
      <Center>
        <Button
          onClick={createResourceParts}
          mb="md"
          mt="xl"
          type="submit"
          disabled={loading || selectedResources.length === 0}
          leftIcon={loading && <Loader size="xs" />}
        >
          Speichern
        </Button>
      </Center>
    </>
  );
};

export default AddResourceContentModal;
