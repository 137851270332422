import React, { useContext, useEffect, useState } from 'react';
import { ContactCreateForm } from '../Molecules/ContactCreateForm';
import { fetchContactById } from '../Service/restapi/contactService';

export default function ({ contactId, setContact, contactType }) {
  const [contact, updateContact] = useState(undefined);

  useEffect(() => {
    if (!contactId) {
      setContact({});
      updateContact({ contactType: 'PRIVATE' });
    } else {
      fetchContactById(contactId).then((contact) => {
        setContact(contact);
        updateContact(contact);
      });
    }
  }, [contactId]);

  function changeHandler(e) {
    const { name, value } = e.target;
    updateContact((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setContact((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  if (!contact) {
    return <div>Laden ...</div>;
  }

  return <ContactCreateForm contact={contact} changeHandler={changeHandler} contactType={contactType} />;
}
