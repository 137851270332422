import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { translate } from '../../Service/PlaceholderTranslationService';
import { EmailTemplateEditor } from '../../Molecules/Settings/EmailTemplateEditor';
import { EditableEmailTemplateProperty } from '../../Atoms/EditableProperties/EditableEmailTemplateProperty';
import { EditableCheckbox } from '../../Atoms/inputs/EditableCheckbox';
import { hasSuitableLicence } from '../../Service/helper/LicenceService';
import { DurationMinutesFormatter } from '../../Atoms/Formatter/DurationMinutesFormatter';
import { EditableDurationInMinutes } from '../../Atoms/inputs/EditableDurationInMinutes';
import { AuthContext } from '../../Service/Context/AuthContext';
import { MixpanelS } from '../../Service/MixpanelS';
import { ConstantS } from '../../Service/ConstantS';
import { Anchor, Breadcrumbs, Container, Divider, Group, Space } from '@mantine/core';
import EmailTemplateTooltips from './EmailTemplateTooltips';
import { i18n } from '@lingui/core';
import EmailTemplateActions from './EmailTemplateActions';
import { MessageContext } from '../../Service/Context/MessageContextV2';

export const EmailTemplateEditorSkeleton = () => {
  const { type, name } = useParams();
  const navigate = useNavigate();
  const { message, changeMessage } = useContext(MessageContext);
  const { jwtClaims } = useContext(AuthContext);
  const messageType = type.toUpperCase();

  useEffect(() => {
    MixpanelS.track(ConstantS.TrackingEvents.EmailTemplateOpened);
  }, []);

  function preChange(patch) {
    console.log('preChange', patch);
    for (const obj in patch) {
      const changedValue = translate.toEnglish(patch[obj], i18n);
      patch[obj] = changedValue;
    }
    return patch;
  }

  function preChangeDuration(patch) {
    console.log('preChange', patch);
    if (patch.delay) {
      patch.delay *= 60;
    } else {
      patch.delay = 0;
    }
    return patch;
  }

  return (
    <Container p={0}>
      <Group position="apart">
      <Breadcrumbs sx={{ overflow: 'hidden'}}>
        <Anchor onClick={() => navigate('/settings/messages')}>E-Mail-Vorlagen</Anchor>
        <Anchor sx={{ pointerEvents: 'none' }} className="overflow-ellipsis">
          <b>{name || i18n._(`message.type.${messageType.toLowerCase()}`)}</b>
        </Anchor>
      </Breadcrumbs>
      {message && messageType === 'CUSTOM' && <EmailTemplateActions template={message} />}
      </Group>
      <Divider my="sm" />
      <EmailTemplateTooltips />
      <Space h="sm" />
      <EmailTemplateEditor messageType={messageType} preChange={preChange} value={message} templateName={name} />
      {(messageType === 'AVAILABLE' || messageType === 'UNAVAILABLE') && message.sendAutomatically && (
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          {!hasSuitableLicence('FLOW', jwtClaims.licence) && <div>Diese Funktion benötigt bookitup Flow.</div>}
          <div style={{ display: 'flex', alignItems: 'center', minHeight: 60 }}>
            <div style={{ marginRight: 20 }}>
              <EditableEmailTemplateProperty
                disabled={!hasSuitableLicence('FLOW', jwtClaims.licence)}
                patchField="sendAutomatically"
                messageType={messageType}
                templateName={name}
                preChange={preChange}
                changeHandler={changeMessage}
                email={message}
                placeholder="message.sendAutomatically"
                name="sendAutomatically"
                editFormatter={EditableCheckbox}
                editOnly
              />
            </div>

            {message.sendAutomatically && (
              <EditableEmailTemplateProperty
                patchField="delay"
                messageType={messageType}
                templateName={name}
                preChange={preChangeDuration}
                changeHandler={changeMessage}
                email={message}
                placeholder="message.delay"
                name="delay"
                editFormatter={EditableDurationInMinutes}
                labelFormatter={DurationMinutesFormatter}
              />
            )}
          </div>
        </div>
      )}
      <Space h={50} />
    </Container>
  );
};
