import React, {useContext, useEffect} from 'react';
import {Alert} from 'reactstrap';
import {ProfileContext} from '../../../Service/Context/ProfileContext';
import {EditableProfileProperty} from '../../../Atoms/EditableProperties/EditableProfileProperty';
import {EditableCheckbox} from '../../../Atoms/inputs/EditableCheckbox';
import {PercentageFormatter} from '../../../Atoms/Formatter/PercentageFormatter';
import {HtmlFormatter} from '../../../Atoms/Formatter/HtmlFormatter';
import {SimpleEditableText} from '../../../Atoms/inputs/SimpleEditableText';
import {Icon} from '../../../Atoms/Icons';
import {EditableDivInput} from '../../../Atoms/inputs/EditableDivInput';
import PanePaper from '../../../Molecules/PanePaper';
import {TestS} from "../../../TestS";
import { RoutingS } from '../../../Service/RoutingS';

export const Taxes = () => {
  const { profile } = useContext(ProfileContext);

  useEffect(() => {
    RoutingS.changeTitle('Mehrwertsteuer');
  }, []);

  const styles = {
    smallScaleBusinessCheckbox: {
      marginTop: 20,
      marginBottom: 10,
      color: '#000000 !important',
    },
    vat: {
      maxWidth: 100,
      cursor: profile.smallScaleBusiness ? 'not-allowed' : 'unset',
      pointerEvents: profile.smallScaleBusiness ? 'inherit' : 'unset',
      backgroundColor: profile.smallScaleBusiness ? '#f9f9f9' : 'unset',
    },
    description: {
      color: '#9499a7',
    },
    attention: {
      marginBottom: 20,
    },
  };

  return (
    <div style={{ maxWidth: 500 }}>
      <PanePaper title="settings.taxes" tooltip="help.settings.taxes.pane">
        <div style={styles.container}>
          <div style={styles.attention}>
            <Alert color="warning">
              <Icon value="ATTENTION" /> Wenn du den allgemeinen Mehrwertsteuersatz änderst, bekommen alle Positionen in
              den Dokumentvorlagen den neuen Satz, die vorher den alten allgemeinen Mehrwertsteuersatz hatten. Wir
              empfehlen dir daher, alle Preise in den{' '}
              <a style={{ textDecoration: 'underline' }} href="/settings/templates">
                Dokumentvorlagen
              </a>{' '}
              zu überprüfen und gegebenenfalls anzupassen.
            </Alert>
          </div>

          <EditableProfileProperty
            style={styles.vat}
            disabled={profile.smallScaleBusiness}
            patchField="vat"
            profile={profile}
            placeholder="MwSt.-Satz:"
            editFormatter={SimpleEditableText}
            labelFormatter={PercentageFormatter}
          />
          <div style={styles.description}>
            Hinweis: Du kannst den MwSt.-Satz auch individuell für jede Position im Dokument einstellen.
          </div>

          <EditableProfileProperty
            style={styles.smallScaleBusinessCheckbox}
            labelStyle={{ color: '#000000' }}
            patchField="smallScaleBusiness"
            profile={profile}
            placeholder="profile.smallScaleBusiness"
            editFormatter={EditableCheckbox}
            editOnly
            testId={TestS.Taxes.TOGGLE_SMALL_SCALE_BUSINESS}
          />

          {profile.smallScaleBusiness && (
            <EditableProfileProperty
              patchField="vatStatement"
              profile={profile}
              placeholder="profile.vatStatement"
              editFormatter={EditableDivInput}
              labelFormatter={HtmlFormatter}
            />
          )}
        </div>
      </PanePaper>
    </div>
  );
};
