enum SemanticFormStyle {
  bookitup = 'bookitup',
  cosmos = 'cosmos',
  lily = 'lily',
  minimal = 'minimal',
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export default SemanticFormStyle;
