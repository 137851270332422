/* eslint-disable react/jsx-props-no-spreading */
import { ThemeIcon, ThemeIconProps } from '@mantine/core';
import { FC, ReactNode } from 'react';

interface Props extends ThemeIconProps {
  children: ReactNode;
}

const BookitupThemeIcon: FC<Props> = ({ children, ...rest }) => (
  <ThemeIcon
    size={20}
    variant="gradient"
    gradient={{ from: '#1D5191', to: '#6185B2', deg: 135 }}
    sx={{ cursor: 'pointer' }}
    {...rest}
  >
    {children}
  </ThemeIcon>
);

export default BookitupThemeIcon;
