import { ListGroup } from 'reactstrap';
import WebForm from './WebForm';

const WebForms = ({ forms, reloadCallback }) => {
  const indents = [];

  Object.keys(forms).forEach((value) => {
    indents.push(<WebForm key={value} form={forms[value]} reloadCallback={reloadCallback} />);
  });

  return <ListGroup>{indents}</ListGroup>;
};

export default WebForms;
