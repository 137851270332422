// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { GeneralTaskConfigDTO, ProcedureReportProps } from '../../../../Types/LogT';

const DeleteTaskReport: FC<ProcedureReportProps> = ({ report, config }) => {
  const { taskReference } = config as GeneralTaskConfigDTO;
  const { label } = taskReference ?? {};

  return (
    <Text>
      Aufgabe{' '}
      <Text span weight="bolder">
        {label}
      </Text>{' '}
      wurde gelöscht.
    </Text>
  );
};

export default DeleteTaskReport;
