import { Menu } from '@mantine/core';
import { IconBellOff, IconDotsVertical, IconTrashX } from '@tabler/icons';
import { FC, useContext } from 'react';
import { BookitupNotification } from '../../Types/LogT';
import { LogS } from '../../Service/LogS';
import { NotificationsContext } from '../../Service/Context/NotificationsContext';

interface Props {
  notification: BookitupNotification;
  onDelete: () => void;
}

const NotificationOptions: FC<Props> = ({ notification, onDelete }) => {
  const { id, occurrenceType } = notification;
  const { updateConfig } = useContext(NotificationsContext);
  const isConfigurable = LogS.isConfigurable(notification);

  const deleteHandler = async () => {
    const deleted = await LogS.deleteNotification(id);
    if (deleted) {
      onDelete();
    }
  };

  const hideNotificationType = async () => {
    const patch = occurrenceType === 'OFFER_OVERDUE' ? { hideOfferOverdue: true } : { hideInvoiceNotPaid: true };
    const cfg = await LogS.patchNotificationConfig(patch);
    if (cfg) {
      updateConfig(cfg);
    }
  };

  return (
    <div>
      <Menu radius="md" shadow="md" width={210} transition="slide-down" position="bottom-end">
        <Menu.Target>
          <div>
            <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          {isConfigurable && (
            <Menu.Item icon={<IconBellOff size={16} />} onClick={hideNotificationType}>
              Diesen Typ ausblenden
            </Menu.Item>
          )}
          <Menu.Item color="red" icon={<IconTrashX size={16} />} onClick={deleteHandler}>
            Löschen
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
};

export default NotificationOptions;
