import { i18n } from '@lingui/core';
import { ThemeIcon, Tooltip } from '@mantine/core';
import { IconQuestionMark } from '@tabler/icons';

const EntityInputTooltipIcon = () => (
  <Tooltip
    multiline
    withArrow
    position="bottom"
    label={i18n._('entity.ref.input.tooltip-message')}
    transition="scale-y"
  >
    <ThemeIcon size={14} sx={{ borderRadius: '50%', backgroundColor: '#D9D9D9' }}>
      <IconQuestionMark size={12} />
    </ThemeIcon>
  </Tooltip>
);

export default EntityInputTooltipIcon;
