// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Stack, Switch, Text, ThemeIcon } from '@mantine/core';
import { useContext, useState } from 'react';
import { i18n } from '@lingui/core';
import { IconCheck } from '@tabler/icons';
import ConfigOption from '../../../Atoms/ConfigOption';
import PanePaper from '../../../Molecules/PanePaper';
import { NotificationsContext } from '../../../Service/Context/NotificationsContext';
import { NotificationConfig } from '../../../Types/LogT';
import { LogS } from '../../../Service/LogS';
import { ToastS } from '../../../Service/ToastS';
import { BrowserS } from '../../../Service/BrowserS';

const BookitupNotificationsConfigPane = () => {
  const { config, updateConfig } = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);
  const { hideInvoiceNotPaid, hideOfferOverdue } = config;
  const { supported, granted } = BrowserS.getNotificationStatus();
  const [browserNotificationAllowed, setBrowserNotificationAllowed] = useState(granted);

  const askForNotificationPermission = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        setBrowserNotificationAllowed(true);
        ToastS.success('foo', '🎉 Browserbenachrichtigungen aktiviert.');
      } else {
        ToastS.error('foo', 'Bitte erlauben Sie zunächst Benachrichtigungen in Ihrem Browser.');
      }
    });
  };

  const editConfig = async (patch: Partial<NotificationConfig>) => {
    setLoading(true);
    const cfg = await LogS.patchNotificationConfig(patch);
    if (cfg) {
      updateConfig(cfg);
    }
    setLoading(false);
  };

  return (
    <PanePaper title={i18n._('notifications.config.title')}>
      <Text>{i18n._('notifications.config.description')}</Text>
      <Stack mt="sm" p="md" sx={{ backgroundColor: '#f2f2f2', borderRadius: 10 }}>
        <ConfigOption
          title={i18n._('notifications.config.invoice-not-paid')}
          description={i18n._('notifications.config.invoice-not-paid.description')}
          rightSection={
            <Switch
              disabled={loading}
              checked={!hideInvoiceNotPaid}
              onChange={() => editConfig({ hideInvoiceNotPaid: !hideInvoiceNotPaid })}
            />
          }
        />
        <ConfigOption
          title={i18n._('notifications.config.offer-expired')}
          description={i18n._('notifications.config.offer-expired.description')}
          rightSection={
            <Switch
              disabled={loading}
              checked={!hideOfferOverdue}
              onChange={() => editConfig({ hideOfferOverdue: !hideOfferOverdue })}
            />
          }
        />
        <ConfigOption
          title={i18n._('notifications.config.browser-notification')}
          description={i18n._(
            supported ? 'notifications.config.browser-notification.description' : 'browser.notifications.not-supported',
          )}
          rightSection={
            <>
              {browserNotificationAllowed && (
                <ThemeIcon radius="xl" size="sm" mr="xs">
                  <IconCheck size={14} />
                </ThemeIcon>
              )}
              {!browserNotificationAllowed && (
                <Switch
                  disabled={loading || !supported}
                  checked={browserNotificationAllowed}
                  onChange={askForNotificationPermission}
                />
              )}
            </>
          }
        />
      </Stack>
    </PanePaper>
  );
};

export default BookitupNotificationsConfigPane;
