import { Menu, Text } from '@mantine/core';
import { IconDotsVertical, IconTrashX, IconBallpen } from '@tabler/icons';
import { i18n } from '@lingui/core';
import { FC } from 'react';
import { openModal } from '@mantine/modals';
import { ResourceCalendar } from '../../../Types/CalDavT';
import DeleteCalendarModal from './DeleteCalendarModal';
import EditCalendarModal from './EditCalendarModal';

interface Props {
  calendar: ResourceCalendar;
  removeCalendar: () => void;
  editCalendar: (calendar: ResourceCalendar) => void;
}

const ResourceCalendarActions: FC<Props> = ({ calendar, editCalendar, removeCalendar }) => {
  const { id: calendarId } = calendar;

  const openEditModal = () =>
    openModal({
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Kalender bearbeiten
        </Text>
      ),
      children: <EditCalendarModal calendar={calendar} editCalendar={editCalendar} />,
    });

  const openDeleteModal = () =>
    openModal({
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          {i18n._('externalSubscription.delete.confirm.head')}
        </Text>
      ),
      children: <DeleteCalendarModal calendarId={calendarId} removeCalendar={removeCalendar} />,
    });

  return (
    <Menu withArrow shadow="md" width={140} transition="scale-y" position="bottom-end">
      <Menu.Target>
        <div>
          <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item icon={<IconBallpen size={16} />} onClick={openEditModal}>
          {i18n._('actions.edit')}
        </Menu.Item>
        <Menu.Item color="red" icon={<IconTrashX size={16} />} onClick={openDeleteModal}>
          {i18n._('actions.delete')}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default ResourceCalendarActions;
