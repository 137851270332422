/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { Checkbox, Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import BookitupTable from '../../../../Atoms/BookitupTable';
import { Resource } from '../../../../Types/ResourceT';
import NumberCounter from '../../../../Atoms/inputs/NumberCounter';
import ResourceTableRow from './ResourceTableRow';
import ResourceAvailabilityDot from "../../../Settings/Documents/Positions/ResourceAvailabilityDot";

interface Props {
  resources: Resource[];
  selectedResources: string[];
  toggle: (id: string) => void;
  changeQuantity?: (resourceId: string, newQuantity: number) => void;
  quantities?: Record<string, number>;
  eventId?: number;
}

const SelectionTable: FC<Props> = ({ resources, toggle, selectedResources, changeQuantity, quantities, eventId }) => {
  const { ref, width } = useElementSize();

  const showColumns = {
    showDescription: width > 550,
    showCategory: width > 450,
  };

  const mapRowUI = (resource: Resource) => {
    const { id } = resource;
    return (
      <ResourceTableRow
        key={id}
        resource={resource}
        eventId={eventId}
        quantity={quantities ? quantities[id] ?? 1 : 1}
        onClick={(res) => toggle(res.id)}
        getSelectionColumn={(resourceId: string) => (
          <Checkbox checked={selectedResources.includes(resourceId)} onChange={() => toggle(resourceId)} />
        )}
        counter={
          changeQuantity &&
          quantities && (
            <NumberCounter deleteDisabled count={quantities[id] ?? 1} onChange={(count) => changeQuantity(id, count)} />
          )
        }
        {...showColumns}
      />
    );
  };

  return (
    <div ref={ref}>
      {resources.length === 0 && (
        <Text italic color="dimmed">
          Keine Resourcen vorhanden.
        </Text>
      )}
      {resources.length > 0 && (
        <BookitupTable>
          <thead>
            <tr>
              <th> </th>
              <th> </th>
              {eventId && <th> </th>}
              <th> Typ </th>
              <th>Name</th>
              {showColumns.showCategory && <th>Kategorie</th>}
              {showColumns.showDescription && <th>Bezeichnung</th>}
              {changeQuantity && <th> </th>}
            </tr>
          </thead>
          <tbody>{resources.map(mapRowUI)}</tbody>
        </BookitupTable>
      )}
    </div>
  );
};

export default SelectionTable;
