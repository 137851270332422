/* eslint-disable react/jsx-no-useless-fragment */
import { FC } from 'react';
import Moment from 'react-moment';
import { i18n } from '@lingui/core';
import { WorkflowStepDTO } from '../../../Types/LogT';

const WorklowStepScheduleInfo: FC<{ workflowStep: WorkflowStepDTO }> = ({ workflowStep }) => {
  const { procedureType, executionReport, executionConfig } = workflowStep;
  const { executionStatus, executedAt } = executionReport ?? {};
  const { thresholdDate, manualApprovalRequired } = executionConfig;

  if (executionStatus === 'COMPLETED') {
    return (
      <>
        Ausgeführt <Moment fromNow date={executedAt} />
      </>
    );
  }
  if (manualApprovalRequired) {
    return <>Manuelle Genehmigung erforderlich</>;
  }
  if (thresholdDate) {
    if (procedureType !== 'WAIT_UNTIL') {
      return (
        <>
          Geplant <Moment calendar date={thresholdDate} />
        </>
      );
    }
    return (
      <>
        Warten bis <Moment fromNow date={thresholdDate} />
      </>
    );
  }
  const { relativeAmount, relativeUnit } = executionConfig;

  if (procedureType === 'WAIT_UNTIL') {
    return (
      <>
        Warten {relativeAmount} {i18n._(`chronoUnit.${relativeUnit}`)}
      </>
    );
  }
  return (
    <>
      {relativeAmount > 0
        ? `Nach ${relativeAmount} ${i18n._(`chronoUnit.${relativeUnit}`)} ausführen`
        : 'Sofort ausführen'}
    </>
  );
};

export default WorklowStepScheduleInfo;
