import { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import FileUpload from '../../../Organismns/FileUpload';
import { FileSize } from '../../../Atoms/FileSize';
import { fetchFileSizeOfProfile } from '../../../Service/restapi/fileSizeService';
import { LoadingAnimation } from '../../../Service/ResourceAdapter';
import { AuthContext } from '../../../Service/Context/AuthContext';
import PanePaper from '../../../Molecules/PanePaper';
import { RoutingS } from '../../../Service/RoutingS';
import FolderSizes from './FolderSizes';
import AttachmentTable from './AttachmentTable';

export default function () {
  const [lastUpdate, setLastUpdate] = useState(new Date().getMilliseconds());
  const [usedFileSize, setUsedFileSize] = useState(undefined);
  const { jwtClaims } = useContext(AuthContext);

  useEffect(() => {
    RoutingS.changeTitle('Dateien');
  }, []);

  useEffect(() => {
    fetchFileSizeOfProfile().then((resp) => {
      if (resp.ok) {
        resp.json().then((fileDto) => {
          setUsedFileSize(fileDto.fileSize);
        });
      }
    });
  }, [lastUpdate]);

  function reloadData() {
    setLastUpdate(new Date().getMilliseconds());
  }

  let diskSpaceOfLicence = 50; // FREE OR TEST-VERSION
  if (jwtClaims.licence === 'PRO') {
    diskSpaceOfLicence = 100;
  }
  if (jwtClaims.licence === 'FLOW') {
    diskSpaceOfLicence = 250;
  }
  if (jwtClaims.licence === 'INTERACTIVE') {
    diskSpaceOfLicence = 1000;
  }

  return (
    <PanePaper title="Dateien & Speicherplatz" tooltip="help.settings.files.pane">
      <div>
        <div style={{ marginBottom: 30, marginTop: 10 }}>
          <div style={{ fontSize: 15, fontWeight: 800, marginBottom: 10 }}>Speicherplatz</div>
          <FolderSizes lastModified={lastUpdate} />
          {usedFileSize !== undefined && (
            <div
              className="description"
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 5 }}
            >
              <FileSize fileSize={usedFileSize} />
              <div style={{ marginLeft: 2, marginRight: 2 }}>von</div>
              <FileSize fileSize={diskSpaceOfLicence * 1000 * 1000} />
              <div style={{ marginLeft: 2 }}>benutzt</div>
            </div>
          )}
          {usedFileSize === undefined && <LoadingAnimation />}
        </div>
        <div style={{ marginBottom: 30, marginTop: 10 }}>
          <div style={{ fontSize: 15, fontWeight: 800, marginBottom: 10 }}>Profil-Daten:</div>
          <Row>
            <Col xs="12">
              <FileUpload kind="stuff" reloadCallback={reloadData} />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <AttachmentTable lastUpdate={lastUpdate} reloadCallback={reloadData} />
            </Col>
          </Row>
        </div>
      </div>
    </PanePaper>
  );
}
