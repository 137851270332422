// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Button, Center, Checkbox, List, Loader, Skeleton, Text, Textarea } from '@mantine/core';
import { i18n } from '@lingui/core';
import { closeAllModals } from '@mantine/modals';
import { FC, useCallback, useEffect, useState } from 'react';
import { IconMessage, IconSend } from '@tabler/icons';
import { RecommendationS } from '../../../../Service/RecommendationS';
import { RecommendationDto } from '../../../../Types/RecommendationT';
import { Contact, ContactS } from '../../../../Service/ContactS';

interface Props {
  recommendations: RecommendationDto[];
  reload: () => void;
}

const CheckAvailabilityModal: FC<Props> = ({ recommendations, reload }) => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedRecommendations, setRecommendations] = useState(recommendations.map((r) => r.id));
  const { length } = selectedRecommendations;

  const checkAvailability = async () => {
    setLoading(true);
    await RecommendationS.checkAvailabilities(selectedRecommendations, message);
    setLoading(false);
    closeAllModals();
    reload();
  };

  const toggle = useCallback(
    (recommendationId: string) => {
      const remove = (id: string) => setRecommendations(selectedRecommendations.filter((_id) => _id !== id));
      const append = (id: string) => setRecommendations([...selectedRecommendations, id]);
      if (selectedRecommendations.includes(recommendationId)) {
        remove(recommendationId);
      } else {
        append(recommendationId);
      }
    },
    [selectedRecommendations],
  );

  const mapContactItemUI = (recommendation: RecommendationDto) => {
    const { id, recommendedContactId } = recommendation;
    return (
      <ContactListItem
        key={recommendation.id}
        selected={selectedRecommendations.includes(id)}
        toggle={() => toggle(id)}
        contactId={recommendedContactId}
      />
    );
  };

  return (
    <>
      <Text>So funktionierts:</Text>
      <List mt="md" mb="xl" type="ordered" size="xs" spacing={5}>
        <List.Item>Es wird eine E-Mail an den Dienstleister gesendet.</List.Item>
        <List.Item>Du wirst benachrichtigt, sobald der Dienstleister geantwortet hat.</List.Item>
        <List.Item>Ist er an dem Tag verfügbar, kannst Du ihm die Event-Infos übermitteln.</List.Item>
      </List>
      <Text mb="sm">Folgende Dienstleister werden per E-Mail informiert:</Text>
      <Box ml="sm">{recommendations.map(mapContactItemUI)}</Box>
      <Textarea
        icon={<IconMessage size={16} />}
        maxRows={10}
        variant="filled"
        mt="xs"
        placeholder="Optionale Nachricht an den Dienstleister"
        disabled={loading}
        value={message}
        onChange={(e) => setMessage(e.currentTarget.value)}
      />
      <Center>
        <Box sx={{ width: '70%' }}>
          <Button
            fullWidth
            mt="xl"
            mb="xs"
            onClick={checkAvailability}
            disabled={loading || length === 0}
            leftIcon={!loading ? <IconSend size={16} /> : <Loader size="xs" />}
          >
            {i18n._('recommendation.check.availability')}
          </Button>
          <Button fullWidth disabled={loading} color="gray" variant="subtle" onClick={() => closeAllModals()}>
            {i18n._('actions.cancel')}
          </Button>
        </Box>
      </Center>
    </>
  );
};

interface ListItemProps {
  contactId: number;
  selected: boolean;
  toggle: () => void;
}

const ContactListItem: FC<ListItemProps> = ({ contactId, selected, toggle }) => {
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState<Contact>();

  useEffect(() => {
    setLoading(true);
    ContactS.fetchById(contactId)
      .then((_contact) => _contact && setContact(_contact))
      .finally(() => setLoading(false));
  }, [contactId]);

  if (loading) {
    return <Skeleton mb="sm" height={30} width={250} />;
  }
  if (!contact) {
    return null;
  }
  const { personDescription, email } = contact;

  return <Checkbox size="xs" label={personDescription ?? email} checked={selected} onChange={toggle} />;
};

export default CheckAvailabilityModal;
