import { forwardRef } from 'react';
import EmailHistoryListItem from '../../Organismns/Message/EmailHistoryListItem';
import { EventCustomerLoadingWrapper } from '../../Organismns/Message/EventCustomerLoadingWrapper';

const EmailSearchListItem = forwardRef<HTMLDivElement, any>((props: any, ref) => (
  <div ref={ref} {...props}>
    <EventCustomerLoadingWrapper message={props.email} key={props.email}>
      <EmailHistoryListItem active={false} onClick={() => {}} email={props.email} />
    </EventCustomerLoadingWrapper>
  </div>
));

export default EmailSearchListItem;
