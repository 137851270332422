import { useContext } from 'react';
import { EventContext } from '../EventContext';
import { EventLocationContextProvider } from '../EventLocationContext';

const EventLocationContextProviderProxy = () => {
  const { event } = useContext(EventContext);

  return <EventLocationContextProvider event={event} />;
};

export default EventLocationContextProviderProxy;
