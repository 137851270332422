import { ScheduleMailDto } from '../Types/MessageT';
import { ajaxActions } from './AjaxActions';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_SCHEDULE_SERVICE_URL;

const fetchByMessageId = async (messageId: number): Promise<ScheduleMailDto | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/schedule/chatMessage/${messageId}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const scheduleMail = async (emailId: number, scheduledTime: Date, id?: string): Promise<ScheduleMailDto | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/schedule`, { id, emailId, scheduledTime });
  if (res.ok) {
    ToastS.success('foo', 'E-Mail geplant');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const deleteScheduledMail = async (chatMessageId: number): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/schedule?chatMessageId=${chatMessageId}`);
  if (res.ok) {
    return true;
  }
  ToastS.generalError();
  return false;
};

// eslint-disable-next-line import/prefer-default-export
export const ScheduledMailS = {
  fetchByMessageId,
  scheduleMail,
  deleteScheduledMail,
};
