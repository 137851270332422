import React, { useContext } from 'react';
import ResourceAdapter from '../../Service/ResourceAdapter';
import { EditableEventProperty } from '../../Atoms/EditableProperties/EditableEventProperty';
import { EditableAutocomplete } from '../../Atoms/inputs/EditableAutocomplete';
import { StringFormatter } from '../../Atoms/Formatter/StringFormatter';
import { AutocompleteListTopComponent } from '../../Atoms/AutocompleteListTopComponent';
import { HtmlFormatter } from '../../Atoms/Formatter/HtmlFormatter';

const LeadOriginSelect = ({ leadOrigins }) => {
  const suggestions = [];
  leadOrigins.forEach((k) => {
    if (k) {
      suggestions.push({ leadOrigin: k, id: k });
    }
  });

  return (
    <EditableEventProperty
      patchField="leadOrigin"
      placeholder="Aufmerksam geworden durch"
      listTopComponent={<AutocompleteListTopComponent text="Aufmerksam geworden durch" />}
      suggestions={suggestions}
      editFormatter={EditableAutocomplete}
      disableTextSelectionPopover
      labelFormatter={HtmlFormatter}
    />
  );
};

export const LeadOriginSelectProxy = (outerProps) => (
  <ResourceAdapter
    serviceUrl={`${process.env.REACT_APP_EVENT_SERVICE_URL}/leadOrigins`}
    component={(props) => <LeadOriginSelect leadOrigins={props.data} {...outerProps} />}
  />
);
