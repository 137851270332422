// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Skeleton, Stack, Text } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { openModal } from '@mantine/modals';
import PanePaper from '../../../Molecules/PanePaper';
import { ResourceCalendar } from '../../../Types/CalDavT';
import { CalDavS } from '../../../Service/CalDavS';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { ResourceDetailsContext } from '../../../Service/Context/ResourceDetailsContext';
import { ResourceType } from '../../../Types/ResourceT';
import CreateCalendarModal from './CreateCalendarModal';
import ResourceCalendarActions from './ResourceCalendarActions';

const ResourceCalendarsPane = () => {
  const { resourceId, resource } = useContext(ResourceDetailsContext);
  const { type } = resource ?? {};
  const [loading, setLoading] = useState(false);
  const [calendars, setCalendars] = useState<ResourceCalendar[]>([]);

  useEffect(() => {
    if (resourceId) {
      setLoading(true);
      CalDavS.getCalendars(resourceId)
        .then(setCalendars)
        .finally(() => setLoading(false));
    }
  }, [resourceId]);

  if (type !== ResourceType.HUMAN) {
    return null;
  }

  const removeCalendar = (calendarId: string) => setCalendars(calendars.filter((c) => c.id !== calendarId));
  const appendCalendar = (calendar: ResourceCalendar) => setCalendars([...calendars, calendar]);
  const editCalendar = (calendar: ResourceCalendar) =>
    setCalendars(calendars.map((c) => (c.id === calendar.id ? calendar : c)));

  const openAddCalendarModal = () => {
    if (resourceId) {
      openModal({
        title: (
          <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
            Kalender hinzufügen
          </Text>
        ),
        children: <CreateCalendarModal resourceId={resourceId} onCreate={appendCalendar} />,
      });
    }
  };

  const mapCalendarItemUI = (calendar: ResourceCalendar) => {
    const { id, label } = calendar;
    return (
      <Group position="apart">
        <Text>{label}</Text>
        <ResourceCalendarActions
          calendar={calendar}
          editCalendar={editCalendar}
          removeCalendar={() => removeCalendar(id)}
        />
      </Group>
    );
  };

  return (
    <PanePaper
      withShadow
      withoutBorder
      title="Kalender"
      sx={{ maxWidth: 350 }}
      rightSection={<BookitupAddIcon onClick={openAddCalendarModal} />}
    >
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <div>
          {calendars.length === 0 ? (
            <Text italic color="dimmed" size="xs" weight="lighter">
              Noch nichts hinzugefügt.
            </Text>
          ) : (
            <Stack mt="md" spacing="xs">
              {calendars.map(mapCalendarItemUI)}
            </Stack>
          )}
        </div>
      )}
    </PanePaper>
  );
};

const LoadingSkeleton = () => (
  <>
    {Array.from(Array(3)).map(() => (
      <Skeleton key={Math.random()} radius="md" height={25} width="100%" mb={10} />
    ))}
  </>
);

export default ResourceCalendarsPane;
