import { i18n } from '@lingui/core';
import { Button, Text } from '@mantine/core';
import Joyride from 'react-joyride';
import { ConstantS } from '../../Service/ConstantS';
import { openCreateEventModal } from '../../Templates/Event/CreateEventModal';

const IntroductionTour = () => (
  <Joyride
    continuous
    disableCloseOnEsc
    disableOverlayClose
    hideCloseButton
    spotlightClicks
    steps={[
      {
        title: (
          <Text weight="bolder" size="lg">
            Erstelle jetzt Dein erstes Event
          </Text>
        ),
        content: (
          <Text>
            Tipp: Hast du eine weitere E-Mail-Adresse? Gib diese im nächsten Schritt als Kunden-Mail an und schick ihm
            ein Beispiel-Angebot zu.
          </Text>
        ),
        locale: {
          skip: <Button variant="outline">{i18n._('actions.skip')}</Button>,
          last: (
            <Text onClick={openCreateEventModal} size="sm">
              Jetzt erstellen
            </Text>
          ),
        },
        target: `#${ConstantS.Pages.Events.AddEvent}`,
        disableBeacon: true,
      },
    ]}
    styles={{
      options: {
        zIndex: 10000,
        primaryColor: '#1D5191',
      },
    }}
  />
);

export default IntroductionTour;
