import React, { useContext, useState } from 'react';
import { ConfirmDialog } from '../Dialogs';
import FileSelectDialog from '../Dialogs/FileSelectDialog';
import { ContractContext } from '../../Service/Context/ContractContext';
import EmailFileAttachment from '../../Organismns/Message/EmailFileAttachment';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { CheckLicence } from '../../Organismns/Upgrade/CheckLicence';

export default function ({ patchField, show, toggle, patch }) {
  const { contract } = useContext(ContractContext);
  const { profile } = useContext(ProfileContext);
  const [deleteModal, setDeleteModal] = useState(false);

  return (
    <div>
      {!contract[patchField] && (
        <div
          style={{ width: 'fit-content', padding: 5, border: '1px solid rgba(0, 0, 0, 0.125)', borderRadius: '4px' }}
        >
          <CheckLicence
            buttonText="PDF-Datei auswählen"
            onClick={toggle}
            licence="PRO"
            upgradeTitle="Cover & Backcover"
            upgradeText={
              'Lade PDF-Dokumente hoch, welche automatisch vor bzw. hinter das erstellte Dokument eingefügt werden.\n' +
              'Die Seitenzahl wird dabei automatisch berechnet und eingefügt.\n'
            }
          />
        </div>
      )}
      {contract[patchField] && (
        <EmailFileAttachment
          deleteAtIndex={() => setDeleteModal(!deleteModal)}
          attachment={{
            fileName: contract[patchField],
            entityType: 'profiles',
            entityId: profile.id,
          }}
        />
      )}

      <ConfirmDialog
        text="document.cover.file.delete.confirm"
        subject="document.cover.file.delete.confirm.head"
        open={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        handler={() => patch({ [patchField]: null })}
      />

      <FileSelectDialog
        show={show}
        toggle={toggle}
        onFileSelect={(entityType, entityId, fileName) => patch({ [patchField]: fileName })}
      />
    </div>
  );
}
