import React from 'react';
import { useLingui } from '@lingui/react';
import { TransToolTip } from './ToolTip';

const TooltipTextButton = ({ disabled, className, id, onClick, buttonText, style, text, placement, isLoading }) => {
  const { i18n, values } = useLingui();

  const styles = {
    button: {
      cursor: 'pointer',
      padding: '5px !important',
    },
  };

  return (
    <span style={styles.button}>
      <span
        id={`Tooltip-${id}`}
        className={`${className} btn btn-icon`}
        color="icon"
        disabled={disabled}
        onClick={onClick ? () => onClick() : null}
        style={{ ...style }}
      >
        {i18n._(buttonText)}
      </span>

      <TransToolTip text={i18n._(text, values)} placement={placement} target={`Tooltip-${id}`} />
    </span>
  );
};

export default TooltipTextButton;
