import React from 'react';
import { file } from '../FileActions';
import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_FILE_SERVICE_URL;

export const downloadFile = (messageId, fileName) =>
  file.download(`${BASE_URL}/files/messages/${messageId}/${fileName}`, fileName).then((resp) => resp);

export const deleteFile = (entityType, entityId, fileName) =>
  ajaxActions.del(`${BASE_URL}/files/${entityType}/${entityId || 'default'}/${fileName}`).then((resp) => resp);

export const fetchFileListOfSubmission = (formSubmissionId) =>
  ajaxActions.get(`${BASE_URL}/files/formSubmissions/${formSubmissionId}`).then((resp) => resp);

export const fetchFileListOfEvent = (eventId) =>
  ajaxActions.get(`${BASE_URL}/files/events/${eventId}`).then((resp) => resp);

export const fetchFileListOfProfile = (profileId) =>
  ajaxActions.get(`${BASE_URL}/files/profiles/${profileId}`).then((resp) => resp);

export const fetchPublicFileListOfProfile = (profileId) =>
  ajaxActions.get(`${BASE_URL}/files/public/${profileId}`).then((resp) => resp);

export const fetchFileListOfMessage = (messageId) =>
  ajaxActions.get(`${BASE_URL}/files/messages/${messageId}`).then((resp) => resp);

export const fetchFileListOfDocument = (documentId) =>
  ajaxActions.get(`${BASE_URL}/files/documents/${documentId}`).then((resp) => resp);

export const fetchFileListOfBills = () => ajaxActions.get(`${BASE_URL}/files/bills/default`).then((resp) => resp);

export const fetchFile = (entityType, entityId, fileName) =>
  ajaxActions.get(`${BASE_URL}/files/${entityType}/${entityId}/${fileName}`).then((resp) => resp);
