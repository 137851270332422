import { Trans, withI18n } from '@lingui/react';
import { withCookies } from 'react-cookie';
import React, { useContext, useEffect, useState } from 'react';
import { translate } from '../../Service/PlaceholderTranslationService';
import { SimpleEditableText } from '../../Atoms/inputs/SimpleEditableText';
import { EditableSequentialNumberProperty } from '../../Atoms/EditableProperties/EditableSequentialNumberProperty';
import { EditableDivInput } from '../../Atoms/inputs/EditableDivInput';
import { NumberFormatter } from '../../Atoms/Formatter/NumberFormatter';
import { HtmlPlaceholderFormatter } from '../../Atoms/Formatter/HtmlPlaceholderFormatter';
import { getNextSequentialNumber, getSequentialNumber } from '../../Service/restapi/documentService';
import { LoadingAnimation } from '../../Service/ResourceAdapter';
import { Pane } from '../../Molecules/Pane';
import { EditableSelectField } from '../../Atoms/inputs/EditableSelectField';
import PanePaper from '../../Molecules/PanePaper';

export default withCookies(
  withI18n()(({ i18n, sequentialNumberType }) => {
    const [nextNumber, setNextNumber] = useState('');
    const [sequentialNumberConfig, setSequentialNumberConfig] = useState(undefined);

    useEffect(() => {
      getSequentialNumber(sequentialNumberType)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((sequentialNumberConfig) => {
              setSequentialNumberConfig(sequentialNumberConfig);
            });
          } else {
            console.error(`could not get sequentialNumber of type ${sequentialNumberType}`);
          }
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not get sequentialNumber of type ${sequentialNumberType}`);
        });
    }, []);

    useEffect(() => {
      if (sequentialNumberConfig) {
        getNextSequentialNumber(sequentialNumberType).then((resp) => {
          if (resp.ok) {
            resp.json().then((numbers) => setNextNumber(numbers.nextNumber));
          }
        });
      }
    }, [sequentialNumberConfig]);

    if (!sequentialNumberConfig) {
      return <LoadingAnimation />;
    }

    function changeSequentialNumber(patch) {
      setSequentialNumberConfig((prevState) => ({
        ...prevState,
        ...patch,
      }));
    }

    function preChange(patch) {
      let val = patch.pattern;
      val = val.replace(/<br>/g, '');
      val = val.replace(/<span contenteditable="true">/g, '');
      val = val.replace(/<\/span>/g, '');
      let changedValue = translate.toEnglish(val, i18n);
      // remove <div>'s
      changedValue = changedValue.replace(/^<div[^>]*>|<\/div>$/g, '');
      console.log(`process and change pattern ${val} to ${changedValue}`);
      return { pattern: changedValue };
    }

    const styles = {
      fieldContainer: {
        marginTop: 10,
        justifyContent: 'space-between',
      },
      field: {
        marginLeft: 5,
        marginTop: 20,
        display: 'flex',
        textAlign: 'right',
        flexDirection: 'column',
      },
      pattern: {
        marginRight: 5,
      },
      seqNo: {
        marginRight: 5,
        flex: 1,
      },
      nextInvoiceNumber: {
        fontWeight: 800,
        textAlign: 'right',
        fontSize: 17,
      },
    };

    const nextSequentialNumbertype = sequentialNumberType.split('_')[0].toLowerCase();

    const minimumLengthOptions = [
      { label: i18n._('sequential-number.minimum-length.undefined'), value: 1 },
      { label: i18n._('sequential-number.minimum-length.2'), value: 2 },
      { label: i18n._('sequential-number.minimum-length.3'), value: 3 },
      { label: i18n._('sequential-number.minimum-length.4'), value: 4 },
      { label: i18n._('sequential-number.minimum-length.5'), value: 5 },
      { label: i18n._('sequential-number.minimum-length.6'), value: 6 },
    ];

    return (
      <PanePaper title={`sequential-number.${sequentialNumberType.toLowerCase()}`}>
        <>
          <div style={styles.pattern}>
            <EditableSequentialNumberProperty
              sequentialNumberType={sequentialNumberType}
              entity={sequentialNumberConfig}
              handleChange={changeSequentialNumber}
              preChange={preChange}
              name="pattern"
              patchField="pattern"
              placeholder="sequential-number.pattern"
              editFormatter={EditableDivInput}
              labelFormatter={HtmlPlaceholderFormatter}
              settings
            />
          </div>
          <div style={styles.fieldContainer}>
            <div style={{ display: 'flex' }}>
              <div style={styles.seqNo}>
                <EditableSequentialNumberProperty
                  sequentialNumberType={sequentialNumberType}
                  entity={sequentialNumberConfig}
                  handleChange={changeSequentialNumber}
                  patchField="sequentialNumber"
                  placeholder="sequential-number"
                  editFormatter={SimpleEditableText}
                  labelFormatter={NumberFormatter}
                />
              </div>
              <div style={styles.seqNo}>
                <EditableSequentialNumberProperty
                  sequentialNumberType={sequentialNumberType}
                  patchField="minimumLength"
                  changeHandler={changeSequentialNumber}
                  options={minimumLengthOptions}
                  entity={sequentialNumberConfig}
                  placeholder="sequential-number.minimum-length"
                  value={sequentialNumberConfig.minimumLength}
                  editFormatter={EditableSelectField}
                  editOnly
                />
              </div>
            </div>
            <div style={styles.field}>
              <Trans id={`document.number.next.${nextSequentialNumbertype}`} />:
              <span style={styles.nextInvoiceNumber}>{nextNumber}</span>
            </div>
          </div>
        </>
      </PanePaper>
    );
  }),
);
