import React, { useContext, useEffect, useState } from 'react';
import { fetchFileSizeOfFolder } from '../../../Service/restapi/fileSizeService';
import ProgressMultiBar from './ProgressMultiBar';

export default function ({ lastModified }) {
  const [values, setValues] = useState([]);

  const folder = ['profiles', 'formSubmissions', 'documentLayouts', 'contracts', 'events', 'messages', 'documents'];

  useEffect(() => {
    Promise.all(
      folder.map((folderName, index) =>
        fetchFileSizeOfFolder(folderName).then((resp) => {
          if (resp.ok) {
            return resp.json().then((fileDto) => ({
              value: fileDto.fileSize,
              label: getFolderName(fileDto.fileName),
              color: getFolderColor(fileDto.fileName),
            }));
          }
        }),
      ),
    ).then((results) => {
      const a = results.filter((r) => r.value !== 0);
      setValues(a);
    });
  }, [lastModified]);

  return (
    <div>
      <ProgressMultiBar values={values} sum={100000 * 1000} />
    </div>
  );
}

function getFolderName(folderName) {
  if (folderName === 'profiles') {
    return 'Profil-Daten';
  }
  if (folderName === 'messages') {
    return 'E-Mails';
  }
  if (folderName === 'events') {
    return 'Event-Daten';
  }
  if (folderName === 'formSubmissions') {
    return 'E-Sign-Dokumente';
  }
  if (folderName === 'documentLayouts') {
    return 'Layout-Dateien';
  }
  if (folderName === 'contracts') {
    return 'Verträge';
  }
  if (folderName === 'documents') {
    return 'Dokumente';
  }
  return 'Sonstiges';
}

function getFolderColor(folderName) {
  if (folderName === 'profiles') {
    return '#000000';
  }
  if (folderName === 'messages') {
    return '#79dd39';
  }
  if (folderName === 'events') {
    return '#1e44cf';
  }
  if (folderName === 'formSubmissions') {
    return '#bd11d0';
  }
  if (folderName === 'documentLayouts') {
    return '#912b5c';
  }
  if (folderName === 'contracts') {
    return '#ff8400';
  }
  if (folderName === 'documents') {
    return '#07ffd8';
  }
  return '#f6c40b';
}
