import { Content, EditorProvider, Extensions } from '@tiptap/react';
import useRteStyles from '../../Service/hooks/useRteStyles';
import { Box, Group, Text } from '@mantine/core';
import { FC, ReactNode, useState } from 'react';
import {
  getPlaceholderValue,
  replacePlaceholdersHtmlWithPlaceholdersId,
  replacePlaceholdersWithHtmlSettings,
} from '../../Service/Placeholder/PlaceholderResover';
import { i18n } from '@lingui/core';
import { TiptapS } from '../../Service/TiptapS';
import TooltipButton from '../../Atoms/buttons/TooltipButton';
import Mention from '@tiptap/extension-mention';
import suggestion from './mentions/Suggestions';
import { useEventData } from '../../Service/Context/CustomerContext';
import { getPlaceholderLabelById } from '../../Service/Placeholder/PlaceholderLabels';

interface Props {
  value: string;
  label?: string;
  description?: string;
  small?: boolean;
  patchField: (value: string) => Promise<boolean>;
  toolbar?: ReactNode;
}

export const EventBaseInput: FC<Props> = ({ ...rest }) => {
  const { value, patchField } = rest;
  const content = replacePlaceholdersWithHtmlSettings(i18n, value);
  const { event, customer, location: eventLocation, customAttributeValues } = useEventData();

  const onChange = async (html: string) => {
    const val = TiptapS.removeParagraphWrapper(replacePlaceholdersHtmlWithPlaceholdersId(html));
    return await patchField(val);
  };

  return (
    <TiptapInput
      small
      extensions={[
        ...TiptapS.Extentions.MINIMAL,
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion,
          renderHTML: ({ node }) => {
            const resolved = getPlaceholderValue(node.attrs.id, i18n, {
              event,
              customer,
              eventLocation,
              customAttributeValues,
            });
            const value = resolved ?? getPlaceholderLabelById(i18n, node.attrs.id);
            if (node.attrs.id === 'event_portalLink' && resolved) {
              return ['a', { title: 'Kunden-Portal öffnen', href: value }, "Kunden-Portal öffnen"];
            }
            return ['span', { class: resolved ? 'mention' : 'unresolved', ['data-id']: node.attrs.id }, `${value}`];
          },
        }),
      ]}
      content={content}
      onChange={onChange}
      {...rest}
    />
  );
};

export const BaseInput: FC<Props> = ({ ...rest }) => {
  const { value, patchField } = rest;
  const content = replacePlaceholdersWithHtmlSettings(i18n, value);

  const onChange = async (html: string) => {
    const val = TiptapS.removeParagraphWrapper(replacePlaceholdersHtmlWithPlaceholdersId(html));
    return await patchField(val);
  };

  return (
    <TiptapInput
      small
      extensions={[
        ...TiptapS.Extentions.MINIMAL,
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion,
          renderHTML: ({ node }) => {
            return ['span', { class: 'mention', ['data-id']: node.attrs.id }, `${node.attrs.label}`];
          },
        }),
      ]}
      content={content}
      onChange={onChange}
      {...rest}
    />
  );
};

export const RichInputWithoutMentions: FC<Props> = ({ ...rest }) => {
  const { value, patchField } = rest;

  return <TiptapInput extensions={TiptapS.Extentions.EXTENDED} content={value} onChange={patchField} {...rest} />;
};

export const EventRichInput: FC<Props> = ({ ...rest }) => {
  const { value, patchField } = rest;
  const content = replacePlaceholdersWithHtmlSettings(i18n, value);
  const { event, customer, location: eventLocation, customAttributeValues } = useEventData();

  const onChange = async (html: string) => {
    const val = replacePlaceholdersHtmlWithPlaceholdersId(html);
    return await patchField(val);
  };

  return (
    <TiptapInput
      extensions={[
        ...TiptapS.Extentions.EXTENDED,
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion,
          renderHTML: ({ node }) => {
            const resolved = getPlaceholderValue(node.attrs.id, i18n, {
              event,
              customer,
              eventLocation,
              customAttributeValues,
            });
            const value = resolved ?? getPlaceholderLabelById(i18n, node.attrs.id);
            if (node.attrs.id === 'event_portalLink') {
              return ['a', { title: 'Kunden-Portal öffnen', href: value, class: resolved ? undefined : 'unresolved' }, "Kunden-Portal öffnen"];
            }
            return ['span', { class: resolved ? 'mention' : 'unresolved', ['data-id']: node.attrs.id }, `${value}`];
          },
        }),
      ]}
      content={content}
      onChange={onChange}
      {...rest}
    />
  );
};

export const RichInput: FC<Props> = ({ ...rest }) => {
  const { value, patchField } = rest;
  const content = replacePlaceholdersWithHtmlSettings(i18n, value);

  const onChange = async (html: string) => {
    const val = replacePlaceholdersHtmlWithPlaceholdersId(html);
    return await patchField(val);
  };

  return (
    <TiptapInput
      extensions={[
        ...TiptapS.Extentions.EXTENDED,
        Mention.configure({
          HTMLAttributes: {
            class: 'mention',
          },
          suggestion,
          renderHTML: ({ node }) => {
            return ['span', { class: 'mention', ['data-id']: node.attrs.id }, `${node.attrs.label}`];
          },
        }),
      ]}
      content={content}
      onChange={onChange}
      {...rest}
    />
  );
};

interface TiptapProps {
  extensions: Extensions;
  content: Content;
  onChange: (html: string) => Promise<boolean>;
  toolbar?: ReactNode;
  small?: boolean;
  label?: string;
  description?: string;
}

const TiptapInput: FC<TiptapProps> = ({
  extensions,
  content,
  small = false,
  onChange,
  label,
  description,
  toolbar,
}) => {
  const [focused, setFocused] = useState(false);
  const { classes, cx } = useRteStyles({ small }, { name: 'TiptapEditor' });

  return (
    <>
      {label && (
        <Group spacing={5}>
          <Text mb={3} size={12} color={focused ? 'blue' : 'dimmed'} weight={focused ? 'bold' : 'normal'}>
            {label}
          </Text>
          {description && (
            <TooltipButton style={{ marginBottom: 3 }} icon="QUESTION" id={'s'} text={i18n._(description)} />
          )}
        </Group>
      )}
      <Box className={cx(classes.root)}>
        <EditorProvider
          injectCSS={false}
          extensions={extensions}
          content={content}
          slotAfter={toolbar}
          onFocus={() => setFocused(true)}
          onBlur={({ editor }) => {
            onChange(editor.getHTML());
            setFocused(false);
          }}
        >
          {' '}
        </EditorProvider>
      </Box>
    </>
  );
};
