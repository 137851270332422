// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Text } from '@mantine/core';
import { FC } from 'react';
import { IconTrashX } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { Resource } from '../../../Types/ResourceT';

interface Props {
  resource: Resource;
  quantity?: number | string;
  onDelete?: () => void;
  onClick? : () => void;
}

const ResourceWidgetItem: FC<Props> = ({ resource, quantity, onDelete, onClick }) => {
  const { id, name } = resource;

  const navigate = useNavigate();

  return (
    <Group position="apart">
      <Text
        size="xs"
        weight="bolder"
        onClick={onClick ? onClick :() => navigate(`/resources/${id}/details`)}
        sx={{ cursor: 'pointer' }}
        variant="link"
      >
        {name}
        {quantity !== undefined && <Text span weight="lighter">{` (${quantity})`}</Text>}
      </Text>
      <div>{onDelete && <IconTrashX size={16} cursor="pointer" onClick={onDelete} />}</div>
    </Group>
  );
};

export default ResourceWidgetItem;
