import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { AddonDTO } from '../../Types/ResourceT';
import ResourceS from '../ResourceS';
import { RoutingS } from '../RoutingS';
import { openActivateAddonTrialModal, openAddonMissingModal } from '../../Organismns/Resources/ResourceModals';

interface ResourceAddonInterface {
  isActive: boolean;
  executeWithCheck: (executionHandler: () => unknown) => unknown;
  trialAvailable: boolean;
  openTrialModal: () => unknown;
  refresh: () => unknown;
}

export const ResourceAddonContext = createContext<ResourceAddonInterface>({} as ResourceAddonInterface);

export const AddonContextProvider = () => {
  const [addon, setAddon] = useState<AddonDTO | null>(null);
  const { pathname } = useLocation();
  const isActive = addon !== null && addon.active;
  const trialAvailable = addon !== null && !addon.active && !addon.trialActivated;

  useEffect(() => {
    getAddon();
  }, []);

  useEffect(() => {
    if (trialAvailable && RoutingS.isResourcePage(pathname)) {
      openActivateAddonTrialModal(getAddon);
    }
  }, [pathname, trialAvailable]);

  const getAddon = () => ResourceS.fetchAddon().then(setAddon);

  const openTrialModal = () => openActivateAddonTrialModal(getAddon);

  const executeWithCheck = useCallback(
    (executionHandler: () => unknown) => {
      if (addon) {
        const { active } = addon;
        if (active) {
          executionHandler();
        } else {
          openAddonMissingModal();
        }
      }
    },
    [addon],
  );

  const value = useMemo(
    () => ({
      isActive,
      executeWithCheck,
      trialAvailable,
      openTrialModal,
      refresh: getAddon,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addon, executeWithCheck],
  );

  return (
    <ResourceAddonContext.Provider value={value}>
      <Outlet />
    </ResourceAddonContext.Provider>
  );
};
