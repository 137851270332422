import React, { useContext, useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { Table } from 'reactstrap';
import Moment from 'react-moment';
import { ButtonLinkWithIcon } from '../Atoms/Buttons';
import { ConfirmDialog, useModalState } from '../Molecules/Dialogs';
import { FileModalDialog } from '../Molecules/Dialogs/FileModalDialog';

export default function ({
  deletable,
  deleteUrl,
  data: initialValue,
  id,
  display,
  i18nPrefix,
  edit,
  deleteHandler,
  entityType,
  entityId,
  reloadCallback,
}) {
  const [data, setData] = useState(initialValue);
  const [deleteModal, setDeleteModal] = useState(false);
  const [itemInDelete, setItemInDelete] = useState(undefined);
  const { show, toggle } = useModalState();

  useEffect(() => {
    setData(initialValue);
  }, [initialValue]);

  const [file, setFile] = useState(undefined);

  function deleteItem() {
    if (!deleteUrl) {
      deleteUrl = `${process.env.REACT_APP_FILE_SERVICE_URL}/files/${entityType}/${entityId || 'default'}`;
    }
    console.log('asdf');
    fetch(`${deleteUrl}/${data[itemInDelete][id]}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: { Accept: 'application/json' },
    })
      .then((response) => {
        if (response.ok) {
          setData(data.slice(0, itemInDelete).concat(data.slice(itemInDelete + 1)));
          setDeleteModal(false);
        }
      })
      .finally(() => {
        console.log('jlajlks');
        reloadCallback();
      });
  }

  const styles = {
    row: {
      borderBottom: '1px dashed rgba(0, 0, 0, 0.125)',
    },
    head: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
    },
  };

  const tableHead = [];

  let index = 0;
  if (i18nPrefix) {
    for (index in display) {
      tableHead.push(
        <th key={index}>
          <Trans id={`${i18nPrefix}.${display[index]}`} />
        </th>,
      );
    }

    tableHead.push(
      <th key={index + 1}>
        <Trans id={`${i18nPrefix}.actions`} />
      </th>,
    );
  }

  const rows = [];

  let obj;
  data.map((value, index) => {
    const rowContent = [];
    for (obj in display) {
      if (data[index]) {
        if (display[obj] === 'lastModified') {
          if (data[index][display[obj]]) {
            rowContent.push(
              <td key={index + obj}>
                <Moment fromNow>{data[index][display[obj]]}</Moment>
              </td>,
            );
          } else {
            rowContent.push(<td key={index + obj} />);
          }
        } else {
          rowContent.push(<td key={index + obj}>{data[index][display[obj]]}</td>);
        }
      } else {
        rowContent.push(<td key={index + obj} />);
      }
    }
    rowContent.push(
      <td key={`${index}ACTIONS`}>
        {edit && (
          <ButtonLinkWithIcon
            onClick={() => {
              edit(data[index]);
            }}
            icon="EDIT"
          />
        )}

        {entityId && (
          <ButtonLinkWithIcon
            onClick={() => {
              setFile({
                ...data[index],
                entityType,
                entityId,
              });
              toggle();
            }}
            icon="EYE"
          />
        )}

        {deletable && deleteHandler && (
          <ButtonLinkWithIcon
            onClick={() => {
              deleteHandler(data[index]);
            }}
            icon="TRASH"
          />
        )}

        {deletable && !deleteHandler && (
          <ButtonLinkWithIcon
            onClick={() => {
              setItemInDelete(index);
              setDeleteModal(true);
            }}
            icon="TRASH"
          />
        )}
      </td>,
    );

    rows.push(
      <tr key={index} style={styles.row}>
        {rowContent}
      </tr>,
    );
  });

  return (
    <div>
      <Table>
        <thead style={styles.head}>
          <tr>{tableHead}</tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>

      <ConfirmDialog
        text={`${i18nPrefix}.delete.confirm`}
        subject={`${i18nPrefix}.delete.confirm.head`}
        open={deleteModal}
        toggle={() => setDeleteModal(!deleteModal)}
        handler={deleteItem}
      />

      <FileModalDialog show={show} toggle={toggle} allowDownload allowEmailAttachment allowEditing file={file} />
    </div>
  );
}
