import React, { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import {
  deleteLexOfficeInvoice,
  fetchLexOfficeConfig,
  fetchLexOfficeInvoice,
} from '../../Service/restapi/lexOfficeService';
import { fetchDocumentById, transmitToBookkeepingTool } from '../../Service/restapi/documentService';
import { Icon } from '../../Atoms/Icons';
import { TransButtonPrimaryWithSpinner } from '../../Atoms/Buttons';

export default function ({ history, children, entityType, entityId, onSuccess }) {
  const [lexOfficeConfig, setLexOfficeConfig] = useState(undefined);
  const [document, setDocument] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [lexOfficeInvoice, setLexOfficeInvoice] = useState(undefined);

  useEffect(() => {
    if (entityType === 'documents') {
      loadDocument();
    }
    if (lexOfficeConfig) {
      loadLexOfficeInvoiceInfo();
    }
  }, [lexOfficeConfig]);

  function loadLexOfficeInvoiceInfo() {
    fetchLexOfficeInvoice(entityId).then((resp) => {
      if (resp.ok) {
        resp.json().then(setLexOfficeInvoice);
      }
    });
  }

  function loadDocument() {
    fetchDocumentById(entityId).then((resp) => {
      if (resp.ok) {
        resp.json().then(setDocument);
      }
    });
  }

  useEffect(() => {
    fetchLexOfficeConfig().then((resp) => {
      if (resp.ok) {
        resp.json().then(setLexOfficeConfig);
      }
    });
  }, []);

  function syncToBookkeepingTool() {
    setLoading(true);

    if (lexOfficeInvoice) {
      deleteLexOfficeInvoice(entityId).then((resp) => {
        if (resp.ok) {
          transmit();
        }
      });
    } else {
      transmit();
    }
  }

  function transmit() {
    transmitToBookkeepingTool(entityId)
      .then((resp) => {
        if (resp.ok) {
          loadLexOfficeInvoiceInfo();
          onSuccess();
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (document && document.documentType === 'INVOICE' && lexOfficeConfig && lexOfficeConfig.enabled) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: 10 }}>
        <TransButtonPrimaryWithSpinner
          style={{ alignSelf: 'center', marginBottom: 10 }}
          text={lexOfficeInvoice ? 'erneut zu Lexoffice übertragen' : 'zu Lexoffice übertragen'}
          isLoading={loading}
          icon="FILE_IMPORT"
          onClick={syncToBookkeepingTool}
          onClickHandler={syncToBookkeepingTool}
        />
        {lexOfficeInvoice && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ marginBottom: 20, textAlign: 'center' }}>
              <Icon value="CHECK" /> Rechnung übertragen <Moment fromNow>{lexOfficeInvoice.updatedAt}</Moment>
            </div>
            {children}
          </div>
        )}
      </div>
    );
  }

  if (children == null) {
    return null;
  }
  return children;
}
