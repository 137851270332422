// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProcedureReportProps } from '../../../../Types/LogT';

const CreateQuestionnaireReport: FC<ProcedureReportProps> = ({ report }) => {
  const { entityId, executionStatus } = report ?? {};
  const { id: eventId } = useParams();
  const navigate = useNavigate();
  const questionnaireCreated = executionStatus === 'COMPLETED';

  const onClick = () => {
    if (eventId && questionnaireCreated) {
      navigate(`/events/${eventId}/questionnaires/${entityId}`);
    }
  };

  return (
    <Text>
      <Text
        span
        weight="bolder"
        onClick={onClick}
        variant={questionnaireCreated ? 'link' : 'text'}
        color={questionnaireCreated ? 'blue' : 'inherit'}
        sx={{ cursor: questionnaireCreated ? 'pointer' : 'default' }}
      >
        Fragebogen
      </Text>{' '}
      erfolgreich erstellt.
    </Text>
  );
};

export default CreateQuestionnaireReport;
