import { ThemeIcon } from '@mantine/core';
import { FC, ReactNode } from 'react';

interface Props {
  accessGranted: boolean;
  innerIcon: ReactNode;
}

const GoogleScopeIcon: FC<Props> = ({ accessGranted, innerIcon }) => (
  <ThemeIcon radius="xl" size="sm" color={accessGranted ? 'blue' : 'red'}>
    {innerIcon}
  </ThemeIcon>
);

export default GoogleScopeIcon;
