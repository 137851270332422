import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EditableProperty } from './EditableProperty';

export const EditableEmailProperty = ({
  email,
  changeHandler,
  preChange,
  patchField,
  placeholder,
  editFormatter,
  labelFormatter,
  ...rest
}) => (
  <EditableProperty
    dto={email}
    preChange={preChange}
    changeHandler={changeHandler}
    patchField={patchField}
    placeholder={placeholder}
    targetUrl={`${process.env.REACT_APP_MESSAGE_SERVICE_URL}/emails/draft${email.id ? `?id=${email.id}` : ''}`}
    editFormatter={editFormatter}
    labelFormatter={labelFormatter}
    {...rest}
  />
);

EditableEmailProperty.propTypes = {
  patchField: PropTypes.string.isRequired,
  email: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lastModified: PropTypes.string.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
};
