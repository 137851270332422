import React from 'react';
import { useLingui } from '@lingui/react';
import { translate } from '../../../Service/PlaceholderTranslationService';
import { useCookies } from 'react-cookie';

const PositionSearchListItem = ({ suggestion }) => {
  const { label, optional, price, priceUnit, subtext, unit, amount, lastModified } = suggestion;

  const { i18n } = useLingui();
  const [locale, setLocale, removeLocale] = useCookies(['locale']);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      width: 500,
    },
    label: {
      display: 'flex',
      flexDirection: 'row',
      minWidth: 25,
    },
    subtext: {},
    price: {
      fontSize: 11,
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.label} className="overflow-ellipsis">
        {translate.toLocale(label, true, true, true, i18n, locale)}
      </div>
      <div>
        <div className="overflow-ellipsis description" style={styles.subtext}>
          {translate.toLocale(subtext, true, true, true, i18n, locale)}
        </div>
        <div style={styles.price}>
          {amount || 1} x {priceUnit} EUR = {price} EUR
        </div>
      </div>
    </div>
  );
};

export default PositionSearchListItem;
