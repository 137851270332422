// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Stack, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { WorkflowDTO, WorkflowStepDTO } from '../../../Types/LogT';
import StepExecutionStatusIcon from '../../../Atoms/workflows/StepExecutionStatusIcon';
import StepReport from './executionReport/StepReport';
import WorklowStepScheduleInfo from './WorkflowStepScheduleInfo';

interface Props {
  index: number;
  workflowStep: WorkflowStepDTO;
  currentStep: boolean;
  workflow: WorkflowDTO;
  reload: () => void;
}

const WorkflowStep: FC<Props> = ({ index, workflowStep, currentStep, workflow, reload }) => {
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();
  const { startedAt, completedAt } = workflow;
  const { procedureType, executionReport, executionConfig } = workflowStep;
  const { executionStrategy, thresholdDate } = executionConfig;
  const { executionStatus } = executionReport ?? {};
  const scheduledImmediatelly = executionStrategy === 'AFTER_PREVIOUS_STEP' && !thresholdDate;

  useEffect(() => {
    if (startedAt && currentStep && scheduledImmediatelly) {
      setTimeoutId(setTimeout(reload, 2500));
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [executionStrategy, startedAt, thresholdDate, executionStatus, currentStep]);

  return (
    <Stack
      ml="xs"
      spacing={5}
      sx={{ opacity: (executionStatus && executionStatus !== 'SKIPPED') || currentStep ? 1 : 0.5 }}
    >
      <Text weight="bolder">{`${index + 1}. ${i18n._(`actionType.${procedureType}`)}`}</Text>
      <StepReport workflowStep={workflowStep} />
      <Text color="blue" size="xs">
        <WorklowStepScheduleInfo workflowStep={workflowStep} />
      </Text>
      {startedAt && !completedAt && (
        <Box sx={{ position: 'absolute', right: 0, top: 0 }}>
          <StepExecutionStatusIcon step={workflowStep} currentStep={currentStep} />
        </Box>
      )}
    </Stack>
  );
};

export default WorkflowStep;
