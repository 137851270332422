// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Skeleton, Stack, Text } from '@mantine/core';
import { openModal } from '@mantine/modals';
import { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import PanePaper from '../../../Molecules/PanePaper';
import ResourceS from '../../../Service/ResourceS';
import { CreateResourceAlternative, ResourceAlternative } from '../../../Types/ResourceT';
import SelectResourcesModal from '../SelectResourcesModal';
import { ResourceAddonContext } from '../../../Service/Context/ResourceAddonsContext';
import ResourceWidgetItem from './ResourceWidgetItem';

const ResourceAlternativesPane = () => {
  const { id: resourceId } = useParams();
  const { executeWithCheck } = useContext(ResourceAddonContext);
  const [loading, setLoading] = useState(false);
  const [lastModified, setLastModified] = useState(new Date());
  const [alternatives, setAlternatives] = useState<ResourceAlternative[] | null>();

  const length = alternatives?.length ?? -1;

  useEffect(() => {
    if (resourceId) {
      setLoading(true);
      ResourceS.fetchAlternatives(resourceId)
        .then(setAlternatives)
        .finally(() => setLoading(false));
    }
  }, [resourceId, lastModified]);

  const openAddModal = () => {
    if (resourceId) {
      openModal({
        size: 'xl',
        title: (
          <Text weight="bolder" size="xl">
            Alternativen hinzufügen
          </Text>
        ),
        children: (
          <SelectResourcesModal
            fetchResources={(filter, signal) => ResourceS.fetchPossibleAlternatives(resourceId, filter, signal)}
            callbackAlternative={addAlternatives}
            enableEditPairQuantity={false}
          />
        ),
      });
    }
  };

  const addAlternatives = async (createResourcePairs: CreateResourceAlternative[]) => {
    if (resourceId) {
      const newAlternatives = await ResourceS.addAlternatives(resourceId, createResourcePairs);
      if (newAlternatives) {
        setAlternatives(newAlternatives);
        return true;
      }
    }
    return false;
  };

  const removeAlternative = (ownerId: string, alternativeId: string) => {
    if (resourceId) {
      ResourceS.removeAlternative(ownerId, alternativeId).then((deleted) => {
        if (deleted) {
          setLastModified(new Date());
        }
      });
    }
  };

  return (
    <PanePaper
      withShadow
      withoutBorder
      title="Alternativen"
      rightSection={<BookitupAddIcon onClick={() => executeWithCheck(openAddModal)} />}
    >
      {loading ? (
        Array.from(Array(3)).map(() => <Skeleton key={Math.random()} radius="md" height={20} width="100%" mb="xs" />)
      ) : (
        <div>
          {length > 0 ? (
            <Box
              p="md"
              sx={{
                borderRadius: 10,
                backgroundColor: '#F4F2F2',
              }}
            >
              <Stack spacing="xs">
                {alternatives?.map((alternative) => {
                  const isSwapped = alternative.ownerId === alternative.resource.id;

                  return (
                    // eslint-disable-next-line react/jsx-key
                    <ResourceWidgetItem
                      resource={alternative.resource}
                      onDelete={() => {
                        removeAlternative(alternative.ownerId, isSwapped ? resourceId! : alternative.resource.id);
                      }}
                    />
                  );
                })}
              </Stack>
            </Box>
          ) : (
            <Text italic color="dimmed" size="xs" weight="lighter">
              Noch nichts hinzugefügt.
            </Text>
          )}
        </div>
      )}
    </PanePaper>
  );
};

export default ResourceAlternativesPane;
