/* eslint-disable no-console */
/* eslint-disable no-undef */
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { ajaxActions } from './AjaxActions';
import { ToastS } from './ToastS';
import { ConstantS } from './ConstantS';
import { MixpanelS } from './MixpanelS';
import {CookiesS} from "./CookiesS";

const cbInstance = Chargebee.init({
  site: process.env.REACT_APP_CHARGEBEE_SITE,
  enableGTMTracking: true,
});

const BASE_URL = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/chargebee`;

if (cbInstance) {
  cbInstance.setPortalSession(() =>
    ajaxActions.get(`${BASE_URL}/portal-session`).then((res) => {
      if (res.ok) {
        return res.json();
      }
      console.error('Unable to create portal session');
      return null;
    }),
  );
} else {
  console.error('Unable to initialize chargebee');
}

const verifyCheckout = (hostedPageId, onSuccess, billingPlan) =>
  ajaxActions.get(`${BASE_URL}/verify-checkout?hostedPageId=${hostedPageId}`).then((resp) => {
    if (resp.ok) {
      onSuccess();
      const { price, name } = billingPlan;
      ReactGA.event({ category: 'Billing', action: ConstantS.TrackingEvents.Purchase, label: name, value: price });
      ReactPixel.track(ConstantS.TrackingEvents.Purchase, { content_name: name, currency: 'EUR', value: price });
      MixpanelS.track(ConstantS.TrackingEvents.Purchase, { content_name: name, currency: 'EUR', value: price });
    } else {
      console.warn(`Unable to verify checkout for hostedPage: ${hostedPageId}`);
    }
  });

const openHostedCheckout = (planId, refreshToken, billingPlan) => {
  const { price, name } = billingPlan;
  ReactGA.event({ category: 'Billing', action: ConstantS.TrackingEvents.InitiateCheckout, label: name, value: price });
  ReactPixel.track(ConstantS.TrackingEvents.InitiateCheckout, { currency: 'EUR', value: price });
  MixpanelS.track(ConstantS.TrackingEvents.InitiateCheckout, { currency: 'EUR', value: price });
  if (cbInstance) {
    cbInstance.openCheckout({
      hostedPage: () => {
        let targetUrl = `${BASE_URL}/hosted-checkout?planId=${planId}`;
        let firstPromoterTrackingId = CookiesS.getCookiePayload("_fprom_tid");
        if (firstPromoterTrackingId) {
          console.log("adding trackingId " + firstPromoterTrackingId);
          targetUrl += `&trackingId=${firstPromoterTrackingId}`;
        }
        return ajaxActions.get(targetUrl).then((resp) => {
          if (resp.ok) {
            return resp.json();
          }
          console.error('Unable to open hosted checkout');
          ToastS.generalError();
          return null;
        });
      },
      success: (hostedPageId) => verifyCheckout(hostedPageId, refreshToken, billingPlan),
    });
  }
};

const migrateExistingSubscription = (onSuccess) => {
  if (cbInstance) {
    cbInstance.openCheckout({
      hostedPage: () => {
        const targetUrl = `${process.env.REACT_APP_PAYMENT_SERVICE_URL}/subscriptions/migrate`;
        return ajaxActions.get(targetUrl).then((resp) => {
          if (resp.ok) {
            return resp.json();
          }
          console.error('Unable to open hosted checkout');
          ToastS.generalError();
          return null;
        });
      },
      success: (hostedPageId) => verifyCheckout(hostedPageId, onSuccess),
    });
  }
};

const openPortal = (onClose) => {
  if (cbInstance) {
    const cbPortal = cbInstance.createChargebeePortal();
    if (cbPortal) {
      cbPortal.open({
        close: onClose,
      });
    } else {
      ToastS.generalError();
      console.error('Unable to create portal');
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export const ChargebeeS = {
  openHostedCheckout,
  openPortal,
  migrateExistingSubscription,
};
