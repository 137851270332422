import React, { useState, useContext, FC, ReactNode, useMemo, useEffect } from 'react';
import { MixpanelS } from '../MixpanelS';
import { ConstantS } from '../ConstantS';

type AgencyModeConfig = {
  agencyModeActive: boolean;
};

type Props = {
  children: ReactNode[];
};

export interface AgencyModeContextInterface {
  modeConfig: AgencyModeConfig;
  handleModeConfigChange: (patch: Partial<AgencyModeConfig>) => void;
}

const defaultState: AgencyModeContextInterface = {
  modeConfig: { agencyModeActive: false },
  handleModeConfigChange: () => null,
};

export const AgencyModeContext = React.createContext<AgencyModeContextInterface>({} as AgencyModeContextInterface);

export const AgencyModeContextProvider: FC<Props> = ({ children }) => {
  const [modeConfig, setModeConfig] = useState(defaultState.modeConfig);
  const { agencyModeActive } = modeConfig;

  useEffect(() => {
    if (agencyModeActive) {
      MixpanelS.track(ConstantS.TrackingEvents.AgencyModeActivated);
    }
  }, [agencyModeActive]);

  const value = useMemo(
    () => ({
      modeConfig,
      handleModeConfigChange: (patch: Partial<AgencyModeConfig>) => {
        setModeConfig((prevState) => ({
          ...prevState,
          ...patch,
        }));
      },
    }),
    [modeConfig],
  );

  return <AgencyModeContext.Provider value={value}>{children}</AgencyModeContext.Provider>;
};

export const useAgencyModeConfig = () => {
  const { modeConfig, handleModeConfigChange } = useContext(AgencyModeContext);

  return [modeConfig, handleModeConfigChange];
};
