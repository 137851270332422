import PropTypes from 'prop-types';
import { DocumentService } from '../../Service/helper/DocumentService';

/**
 * NetGrossFormatter is a formatter which simply passes strings trough and adds ' EUR', but
 * returns a dash for empty or null values.
 */
export const NetGrossFormatter = ({ value, disabled, taxType, tax, withoutCurrency, style }) => {
  const styles = {
    span: {
      height: '100%',
      ...style
    },
  };

  const getValue = () => {
    if (!value || isNaN(value)) {
      return '-';
    }
    const unitPrice = DocumentService.calculateNetGrossPrice(taxType, value, tax);
    return unitPrice.toString().replace('.', ',');
  }

  return (
    <span className={`form-control ${disabled ? 'disabled' : ''}`} style={styles.span}>
      {`${getValue()} `}
      {!withoutCurrency && 'EUR'}
    </span>
  );
};

NetGrossFormatter.propTypes = {
  value: PropTypes.number,
};
