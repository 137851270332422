import { useLingui } from '@lingui/react';
import { Menu } from '@mantine/core';
import { IconBallpen, IconDotsVertical, IconDownload, IconTrash } from '@tabler/icons';
import { FC, useState } from 'react';
import { downloadDocumentContractQuestionnaire } from '../../../Service/DocumentService';
import { QuestionnaireS } from '../../../Service/QuestionnaireS';
import { ConfirmDialog, useModalState } from '../../Dialogs';
import ChangeLabel from '../../Dialogs/ChangeLabel';

const QuestionnaireItemActions: FC<{
  entityId: number;
  initialLabel: string;
  reload: () => void;
  handleChange: (patch: any) => void;
}> = ({ entityId, initialLabel, reload, handleChange }) => {
  const { i18n } = useLingui();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const { show, toggle } = useModalState(false);

  const deleteHandler = () => {
    setLoading(true);
    QuestionnaireS.deleteQuestionnaire(entityId).then((ok) => ok && reload());
    setLoading(false);
  };

  return (
    <div>
      <Menu withArrow shadow="md" width={200} transition="scale-y" position="bottom-end">
        <Menu.Target>
          <div>
            <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item
            icon={<IconDownload size={16} />}
            onClick={() => downloadDocumentContractQuestionnaire('questionnaires', entityId, i18n)}
          >
            Herunterladen
          </Menu.Item>
          <Menu.Item icon={<IconBallpen size={16} />} onClick={toggle}>
            Umbenennen
          </Menu.Item>
          <Menu.Item icon={<IconTrash size={16} />} onClick={() => setModalDelete(!modalDelete)}>
            Löschen
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      {show && (
        <ChangeLabel
          entityType="questionnaire"
          toggle={toggle}
          initialLabel={initialLabel}
          entityId={entityId}
          handleChange={handleChange}
        />
      )}
      <ConfirmDialog
        isLoading={isLoading}
        text="common.delete.questionnaire.confirm"
        subject="common.delete.questionnaire.confirm.head"
        open={modalDelete}
        toggle={() => setModalDelete(!modalDelete)}
        handler={deleteHandler}
        // eslint-disable-next-line react/no-children-prop
        children={[]}
        confirmText=""
        disableConfirm={false}
      />
    </div>
  );
};

export default QuestionnaireItemActions;
