import React, { useContext, useEffect, useState } from 'react';
import { useLingui } from '@lingui/react';
import { useModalState } from '../Dialogs';
import { FileModalDialog } from '../Dialogs/FileModalDialog';
import { WindowContext } from '../../Service/Context/WindowContext';
import { getUnresolvablePlaceholders } from '../../Service/restapi/documentService';
import { ToastS } from '../../Service/ToastS';

const PdfPreview = ({ file, eventId, button, id, lastModified, breakpointWidth }) => {
  const { i18n } = useLingui();
  const { show, toggle } = useModalState();
  const { windowWidth } = useContext(WindowContext);
  const [isLoading, setLoading] = useState(false);
  const [unresolvablePlaceholders, setUnresolvablePlaceholders] = useState(undefined);

  useEffect(() => {
    fetchUnresolvablePlaceholders();
  }, [file, lastModified]);

  function fetchUnresolvablePlaceholders(notification) {
    if (file.entityType === 'documents' || file.entityType === 'contracts' || file.entityType === 'questionnaires') {
      setLoading(true);
      getUnresolvablePlaceholders(file.entityType, file.entityId)
        .then((unresolvablePlaceholders) => {
          setUnresolvablePlaceholders(unresolvablePlaceholders);
          if (notification && unresolvablePlaceholders.length > 0) {
            ToastS.info('placeholder.unsatisfied', i18n._('placeholder.unsatisfied'));
            unresolvablePlaceholders.forEach((placeholder) => {
              const translation = i18n._(placeholder, { 0: 1 });
              ToastS.info(`unresolvable.placeholder-${translation}`, i18n._(`${translation} nicht gesetzt`));
            });
          }
        })
        .catch(() => {
          console.error('could not load, if document has unresolvable placeholders');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  function preview() {
    if (
      (file.entityType === 'documents' || file.entityType === 'contracts' || file.entityType === 'questionnaires') &&
      (!unresolvablePlaceholders || unresolvablePlaceholders.length > 0)
    ) {
      fetchUnresolvablePlaceholders(true);
    } else {
      toggle();
    }
  }

  const buttonBreakpoint = breakpointWidth || 800;

  const styles = {
    buttons: {
      marginBottom: 5,
    },
    emailButton: {
      marginRight: 5,
    },
    downloadButton: {},
    button: {
      minWidth: windowWidth > buttonBreakpoint ? 105 : 'unset',
      alignSelf: 'center',
      marginRight: 5,
      marginLeft: 5,
    },
  };
  const Button = button;

  return (
    <>
      <Button
        isLoading={isLoading}
        disabled={isLoading}
        style={styles.button}
        id={id || 'showPdf'}
        onClickHandler={preview}
        onClick={preview}
        text={windowWidth > buttonBreakpoint ? i18n._('actions.preview') : undefined}
        icon={unresolvablePlaceholders && unresolvablePlaceholders.length > 0 ? 'REFRESH' : 'EYE'}
      />
      <FileModalDialog
        file={file}
        eventId={eventId || (file ? file.eventId : undefined)}
        allowDownload
        allowEmailAttachment
        allowEditing
        toggle={toggle}
        show={show}
      />
    </>
  );
};

export default PdfPreview;
