import { Checkbox, Group, NumberInput, Select, Stack, Text, TextInput } from '@mantine/core';
import { FC, useState } from 'react';
import { i18n } from '@lingui/core';
import { ChronoUnit, CreateShareConfigDTO, WorkflowConfigProps } from '../../../../../Types/LogT';
import { DataS } from '../../../../../Service/DataS';
import { ValidationS } from '../../../../../Service/ValidationS';

const CreateShareConfig: FC<WorkflowConfigProps> = ({ config, handleChange }) => {
  const { name, password, randomPassword, sharedForAmount, sharedForUnit } = config as CreateShareConfigDTO;
  const [withDueDate, setWithDueDate] = useState(sharedForAmount !== null);

  const checkDueDate = (checked: boolean) => {
    if (!checked && sharedForAmount) {
      handleChange({ sharedForAmount: null });
    }
    setWithDueDate(checked);
  };

  return (
    <Stack spacing="xs" mt="sm">
      <TextInput
        required
        defaultValue={name ?? ''}
        label="Name des Zugangs"
        onBlur={(e) => handleChange({ name: e.currentTarget.value })}
      />
      <Checkbox
        size="xs"
        defaultChecked={randomPassword}
        label="Verwenden Sie ein zufälliges Passwort"
        onChange={(e) => handleChange({ randomPassword: e.currentTarget.checked })}
      />
      {!randomPassword && (
        <TextInput
          required
          label="Passwort (zur Weitergabe an Deinen Kunden)"
          defaultValue={password ?? ''}
          onBlur={(e) => handleChange({ password: e.currentTarget.value })}
          error={
            !ValidationS.validatePortalPassword(password ?? '') &&
            i18n._('error.input-field.invalid-portal-password-char')
          }
        />
      )}
      <Checkbox
        size="xs"
        defaultChecked={withDueDate}
        label="Fälligkeitsdatum der Einrichtung"
        onChange={(e) => checkDueDate(e.currentTarget.checked)}
      />
      {withDueDate && (
        <Group position="apart">
          <Text>Portal geöffnet für: </Text>
          <Group spacing={5}>
            <NumberInput
              hideControls
              defaultValue={sharedForAmount}
              min={0}
              sx={{ width: 50 }}
              onBlur={(e) => handleChange({ sharedForAmount: Number(e.currentTarget.value) })}
            />
            <Select
              defaultValue={sharedForUnit}
              data={DataS.getChronoUnitsData(i18n)}
              sx={{ width: 100 }}
              onChange={(val: ChronoUnit) => val && handleChange({ sharedForUnit: val })}
            />
          </Group>
        </Group>
      )}
    </Stack>
  );
};

export default CreateShareConfig;
