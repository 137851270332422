import { useContext, useEffect, useState } from 'react';
import { TransButtonLink } from '../../Atoms/Buttons';
import { useModalState } from '../Dialogs';
import { FocusedNodeContext } from '../../Service/Context/FocusedNode';
import {
  fetchCustomPlaceholders,
  sequentialNumbersGroups,
  placeholderGroupsWithDocNum,
  placeholderGroups,
} from '../../Service/Placeholder/PlaceholderLabels';

const PlaceholderCollection = ({ finish, _document, contract, style }) => {
  const { show, toggle } = useModalState();
  const { focusedNode } = useContext(FocusedNodeContext);
  const [customerEventLocation, setCustomerEventLocation] = useState(
    contract ? placeholderGroupsWithDocNum : placeholderGroups,
  );

  useEffect(() => {
    fetchCustomPlaceholders().then((customerPlaceholders) =>
      setCustomerEventLocation([
        ...(contract ? placeholderGroupsWithDocNum : placeholderGroups),
        ...customerPlaceholders,
      ]),
    );
  }, [_document, contract]);

  const onClickHandler = (placeholder) => {
    toggle();
    const selObj = window.getSelection();
    const selRange = selObj.getRangeAt(0);
    const newElement = window.document.createTextNode(`{${placeholder}}`);
    selRange.insertNode(newElement);
    selObj.removeAllRanges();
    finish(focusedNode.innerHTML);
  };

  const openMenu = () => {
    toggle();
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      if (e) {
        e.preventDefault();
      }
    }
  };

  const onMouseDown = (e) => {
    if (e) {
      e.preventDefault();
    }
    return false;
  };

  const isFF = !!navigator.userAgent.match(/firefox/i);
  const styles = {
    placeholderContainer: {
      marginLeft: 5,
      border: '1px solid rgba(0, 0, 0, .125)',
      borderRadius: 4,
      // position: "absolute",
      backgroundColor: '#ffffff',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 200,
      overflowY: 'scroll',
      opacity: 1,
      ...style,
    },
    iconAndText: {
      paddingRight: 5,
    },
    groupName: {
      padding: 5,
      fontWeight: 800,
      color: '#96969A',
    },
    item: {
      textAlign: 'left',
      padding: 5,
      height: '100%',
      minHeight: isFF ? 'unset' : 30,
    },
  };

  let placeholder = customerEventLocation;
  if (window.location.pathname.includes('sequentialnumbers')) {
    placeholder = sequentialNumbersGroups;
  }

  const items = [];
  placeholder.map((groups, groupIndex) => {
    items.push(
      <span key={groupIndex + 1} style={styles.groupName}>
        {groups.groupName}
      </span>,
    );
    groups.items.map((value, index) => {
      items.push(
        <TransButtonLink
          key={`${groupIndex}.${index}`}
          className="primary-link"
          style={styles.item}
          onKeyUp={onKeyUp}
          onMouseDown={onMouseDown}
          onClick={() => {
            onClickHandler(value.placeholder);
          }}
          text={value.text}
        />,
      );
    });
  });

  return (
    <div style={{ width: 200 }}>
      <div key={0} tabIndex={0} className="placeholder show-placeholder-menu" style={styles.placeholderContainer}>
        {!show && (
          <TransButtonLink
            key={100}
            className="primary-link open-placeholder-menu"
            style={{ fontWeight: 800, marginTop: -1 }}
            text="placeholder.insertButton"
            onClick={openMenu}
          />
        )}
        {show && items}
        {show && _document && (
          <TransButtonLink
            key={0}
            className="primary-link"
            onKeyUp={onKeyUp}
            onMouseDown={onMouseDown}
            style={styles.item}
            onClick={() => {
              onClickHandler('document_number');
            }}
            text="Dokumentnummer"
          />
        )}
        {show && contract && (
          <TransButtonLink
            key={0}
            className="primary-link"
            onKeyUp={onKeyUp}
            onMouseDown={onMouseDown}
            style={styles.item}
            onClick={() => {
              onClickHandler('contract_number');
            }}
            text="Vertragsnummer"
          />
        )}
      </div>
    </div>
  );
};

export default PlaceholderCollection;
