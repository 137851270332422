import React from 'react';
import TooltipButton from '../../Atoms/buttons/TooltipButton';
import { UpgradePanel } from '../Upgrade/UpgradePanel';
import { Pane } from '../../Molecules/Pane';

export const Contracts = () => (
  <Pane
    title="common.contracts"
    headAddition={<TooltipButton icon="QUESTION" id="help-event-contracts-pane" text="help.event.contracts.pane" />}
    component={
      <UpgradePanel
        licence="INTERACTIVE"
        component={<div />}
        upgradeTitle={
          <div>
            Erstelle Deinen Kunden Verträge und arbeite jetzt
            <br />
            noch komfortabler und professioneller.
          </div>
        }
        upgradeBody={
          <div>
            <div>
              Mit (Online)-Verträgen kannst Du schnell und einfach einen Engagement-Vertrag für Deinen Kunden erstellen
              und diesen per E-Mail verschicken, online freigeben und per elektronischer Unterschrift (eSign) bestätigen
              zu lassen oder als PDF downloaden.
            </div>
            <div>Erleichtere jetzt die Kummunikation mit bookitup Interactive.</div>
          </div>
        }
      />
    }
  />
);
