import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import ResourceAdapter from '../../ResourceAdapter';
import { EventContext } from '../EventContext';
import { EventParticipationsContextProvider } from '../EventParticipationContext';
import CustomerContextProviderProxy from './CustomerContextProvider';

const EventParticipationsContextProviderProxy = () => {
  const { event } = useContext(EventContext);

  const { id } = useParams();
  if (id === 'new') {
    return (
      <EventParticipationsContextProvider event={event} participations={[]}>
        <CustomerContextProviderProxy />
      </EventParticipationsContextProvider>
    );
  }
  return (
    <ResourceAdapter
      serviceUrl={`${process.env.REACT_APP_EVENT_SERVICE_URL}/eventParticipations/${id}`}
      component={(participationsProps) => (
        <EventParticipationsContextProvider event={event} participations={participationsProps.data}>
          <CustomerContextProviderProxy />
        </EventParticipationsContextProvider>
      )}
    />
  );
};

export default EventParticipationsContextProviderProxy;
