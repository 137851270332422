import React, { useState, useContext, useEffect } from 'react';
import { withI18n } from '@lingui/react';
import { loadMessageTemplate } from '../../Service/restapi/messageService';
import { EventContext } from '../../Service/Context/EventContext';
import MessageTemplateSelectDocuments from './MessageTemplateSelectDocuments';

export default withI18n()(({ kind, templateName, setDisableApply, onDocumentRequestSelected, i18n }) => {
  const { event } = useContext(EventContext);
  const [documentBlueprints, setDocumentBlueprints] = useState([]);
  const [documentRequests, setDocumentRequests] = useState(undefined);
  useEffect(() => {
    setDocumentBlueprints(undefined);
    if (kind) {
      loadMessageTemplate(setDocumentRequests, setDocumentBlueprints, kind, templateName, event.id);
    }
  }, [kind]);

  const styles = {
    selectDocumentsContainer: {},
    text: {
      marginBottom: 10,
    },
    documentBlueprint: {
      border: '1px solid rgba(0, 0, 0, .125)',
      padding: 5,
      marginTop: 5,
      borderRadius: 4,
    },
  };

  function stringifyBlueprints(documentBlueprints) {
    const dBArray = [];
    documentBlueprints.forEach((dB) => {
      if (dB.documentType === 'OFFER') {
        dBArray.push('ein Angebot');
      } else if (dB.documentType === 'CONFIRMATION') {
        dBArray.push('eine Auftragbestätigung');
      } else if (dB.documentType === 'PARTIAL_INVOICE') {
        dBArray.push('ein Abschlagsrechnung');
      } else if (dB.documentType === 'INVOICE') {
        dBArray.push('eine Rechnung');
      } else if (dB.documentType === 'CANCEL') {
        dBArray.push('eine Rechnungskorrektur');
      } else if (dB.documentType === 'CONTRACT') {
        dBArray.push('ein Vertrag');
      }
    });
    let output = dBArray[0];
    if (dBArray.length > 2) {
      output = dBArray.slice(0, dBArray.length - 1).join(', ');
      output += ` und ${dBArray[dBArray.length - 1]}`;
    } else if (dBArray.length > 1) {
      output = `${dBArray[0]} und ${dBArray[1]}`;
    }

    return output;
  }

  return (
    <div>
      {kind && !documentBlueprints && (
        <div>
          <br />
          Anhänge laden...
        </div>
      )}

      {documentBlueprints && documentBlueprints.length > 0 && (
        <div style={styles.selectDocumentsContainer}>
          <div style={styles.text}>
            In{' '}
            <a target="_blank" href={`/settings/template/message/${kind}`}>
              dieser E-Mail-Vorlage
            </a>{' '}
            {` ist ${stringifyBlueprints(documentBlueprints)} vorgesehen.`}{' '}
          </div>

          {documentBlueprints.map((documentBlueprint, index) => (
            <div key={index} style={styles.documentBlueprint}>
              {/* <div style={styles.text}>{i18n._("document." + documentBlueprint.documentType.toLowerCase())}</div> */}
              <MessageTemplateSelectDocuments
                setDisableApply={setDisableApply}
                documentBlueprint={documentBlueprint}
                documentRequests={documentRequests}
                onDocumentRequestSelected={(documentRequest) => onDocumentRequestSelected(documentRequest, index)}
              />
            </div>
          ))}
        </div>
      )}
      {documentBlueprints && documentBlueprints.length === 0 && (
        <div>
          <div style={styles.text}>
            In{' '}
            <a target="_blank" href={`/settings/template/message/${kind}`}>
              dieser E-Mail-Vorlage
            </a>{' '}
            {' sind keine Anhänge vorgesehen.'}{' '}
          </div>
        </div>
      )}
    </div>
  );
});
