import Moment from 'react-moment';
import { FC } from 'react';
import { useLingui } from '@lingui/react';
import { useNavigate } from 'react-router-dom';
import { Group, Stack, Text } from '@mantine/core';
import { MessageTemplateDto } from '../../../Types/MessageT';
import EmailTemplateActions from '../../../Organismns/Settings/EmailTemplateActions';

interface Props {
  template: MessageTemplateDto;
  onUpdate: (template: MessageTemplateDto) => void;
  onDelete: () => void;
  existingNames: string[];
}

const MessageTemplateListItem: FC<Props> = ({ template, onUpdate, onDelete, existingNames }) => {
  const { messageType, name, lastModified } = template;
  const navigate = useNavigate();
  const { i18n } = useLingui();
  const isCustom = messageType === 'CUSTOM';
  const label = isCustom ? name : i18n._(`message.type.${messageType.toLowerCase()}`);

  return (
    <Group position="apart">
      <Stack spacing={0}>
        <Text
          size="md"
          sx={{ cursor: 'pointer', color: 'black' }}
          variant="link"
          onClick={() =>
            navigate(`/settings/template/message/${messageType.toLowerCase()}${isCustom ? `/${name}` : ''}`)
          }
        >
          {label}
        </Text>
        <Text size="xs" color="dimmed">
          Bearbeitet{'  '}
          <Moment fromNow date={lastModified} />
        </Text>
      </Stack>
      <div>
        <EmailTemplateActions
          template={template}
          onUpdate={onUpdate}
          onDelete={onDelete}
          existingNames={existingNames}
        />
      </div>
    </Group>
  );
};

export default MessageTemplateListItem;
