import { useEffect, useState } from 'react';
import { useLingui } from '@lingui/react';
import moment from 'moment';
import { LoadingAnimation } from '../../Service/ResourceAdapter';
import { fetchDocumentsByEvent } from '../../Service/restapi/documentService';
import { fetchContractsByEvent } from '../../Service/restapi/contractService';
import { fetchQuestionnairesByEvent } from '../../Service/restapi/questionnaireService';
import { CheckboxList } from '../../Atoms/inputs/CheckboxList';
import { fetchFileListOfEvent, fetchFileListOfProfile } from '../../Service/restapi/fileService';

const DocumentCheckboxSelector = ({ entityId, selected, onSelect, onDeselect, entityType, documentType }) => {
  const { i18n } = useLingui();
  const [documents, setDocuments] = useState(undefined);

  const getDocumentLabel = (doc) => {
    if (doc && doc.label) {
      return doc.label;
    }

    if (doc && doc.documentType) {
      return i18n._(`document.${doc.documentType.toString().toLowerCase()}`);
    }
    return 'Angebot';
  };

  useEffect(() => {
    let functionCall;
    if (entityType === 'documents') {
      functionCall = fetchDocumentsByEvent;
    } else if (entityType === 'contracts') {
      functionCall = fetchContractsByEvent;
    } else if (entityType === 'questionnaires') {
      functionCall = fetchQuestionnairesByEvent;
    } else if (entityType === 'events') {
      functionCall = fetchFileListOfEvent;
    } else if (entityType === 'profiles') {
      functionCall = fetchFileListOfProfile;
    }

    functionCall(entityId).then((resp) => {
      if (resp.ok) {
        resp.json().then((docs) => {
          setDocuments(docs);
        });
      }
    });
  }, [entityId, entityType]);

  if (!documents) {
    return <LoadingAnimation />;
  }

  const options = [];
  documents
    .filter((d) => (documentType ? d.documentType === documentType : true))
    .filter((f) => (f.fileName ? f.fileName.toLowerCase().includes('.pdf') : true))
    .forEach((document) => {
      let label = null;
      if (entityType === 'documents') {
        label = getDocumentLabel(document);
      } else if (entityType === 'questionnaires') {
        label = document.label ? document.label : 'Fragebogen';
      } else if (entityType === 'contracts') {
        label = document.label ? document.label : 'Vertrag';
      } else {
        label = document.fileName;
      }
      if (entityType === 'documents') {
        label += ` - ${document.sum} EUR`;
      }
      const lastModified = moment(document.lastModified);
      lastModified.locale('de');
      if (document.fileName) {
        label += ` - hinzugefügt: ${lastModified.fromNow()}`;
      } else {
        label += ` - zuletzt bearbeitet: ${lastModified.fromNow()}`;
      }
      options.push({
        label,
        checked: selected ? selected.filter((r) => r.entityId === document.id).length === 1 : false,
        onDeselect: () => onDeselect(document.id ? document.id : document.fileName),
        onClick: () => onSelect(document.id ? document.id : document.fileName),
      });
    });

  return (
    <>
      {documents.length === 0 && <div>Erstelle zuerst ein Dokument und kehre dann hierher zurück.</div>}
      <CheckboxList options={options} />
    </>
  );
};

export default DocumentCheckboxSelector;
