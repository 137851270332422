import React, { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { TransButtonLink } from '../../../../Atoms/Buttons';
import { useModalState } from '../../../../Molecules/Dialogs';
import { LoadingAnimation } from '../../../../Service/ResourceAdapter';
import { Pane } from '../../../../Molecules/Pane';
import { ProfileContext } from '../../../../Service/Context/ProfileContext';
import TooltipButton from '../../../../Atoms/buttons/TooltipButton';
import { EditableProfileProperty } from '../../../../Atoms/EditableProperties/EditableProfileProperty';
import { SimpleEditableText } from '../../../../Atoms/inputs/SimpleEditableText';
import { NumberFormatter } from '../../../../Atoms/Formatter/NumberFormatter';
import ContractTemplateNameModal from '../../../../Organismns/Settings/Documents/ContractTemplates/ContractTemplateNameModal';
import { createContractTemplateByName } from '../../../../Service/restapi/contractTemplateService';
import { MediaUpload } from '../MediaUpload';
import { ContractTemplateListActions } from '../../../../Molecules/Settings/ContractTemplates/ContractTemplateListActions';
import { fetchContractTemplates } from '../../../../Service/restapi/contractService';
import StandardLayoutContent from './StandardLayoutContent';
import ContractTemplateWorkflow from './Template-DJ/ContractTemplateWorkflow';
import { Stack } from '@mantine/core';
import PanePaper from '../../../../Molecules/PanePaper';
import BookitupAddIcon from '../../../../Atoms/BookitupAddIcon';
import { ViolationContext } from '../../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../../Service/LicenceS';
import { RoutingS } from '../../../../Service/RoutingS';
import { MixpanelS } from '../../../../Service/MixpanelS';
import { ConstantS } from '../../../../Service/ConstantS';

export const ContractTemplates = () => {
  const { profile } = useContext(ProfileContext);
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const { show, toggle } = useModalState();
  const [contracts, setContracts] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    RoutingS.changeTitle('Vertrags-Vorlagen');
    loadContracts();
  }, []);

  function loadContracts() {
    fetchContractTemplates()
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((newContracts) => {
            setContracts(newContracts);
          });
        } else {
          console.error('could not load contracts');
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not load contracts');
      });
  }

  function create(name) {
    createContractTemplateByName(name)
      .then((resp) => {
        if (resp.ok) {
          MixpanelS.track(ConstantS.TrackingEvents.ContractTemplateCreated, { default: false });
          navigate(`/settings/template/contract/${name}`);
        } else {
          console(`could not create contract template ${name}`);
        }
      })
      .catch((error) => {
        console.error(`could not create contract template ${name}`);
        console.error(error.message);
      });
  }

  const styles = {
    actions: {
      marginBottom: 5,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    list: {
      backgroundColor: 'white',
      borderRadius: 5,
      paddingTop: 5,
      paddingBottom: 5,
    },
    itemContainer: {
      marginBottom: 5,
      display: 'flex',
    },
  };

  const items = [];
  if (!contracts) {
    return <LoadingAnimation />;
  }

  contracts.map((value, index) => {
    items.push(
      <div style={styles.itemContainer}>
        <div key={index} style={{ flex: 1, maxWidth: '100%' }}>
          <div style={{ display: 'flex' }}>
            <TransButtonLink
              className="overflow-ellipsis"
              style={{ fontSize: '1rem', color: '#000000', height: 25 }}
              onClick={() => navigate(`/settings/template/contract/${value.name}`)}
              text={value.name}
            />
            <ContractTemplateListActions templateName={value.name} reloadDocuments={loadContracts} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {value.lastModified && (
              <span style={{ fontSize: '0.75rem' }}>
                Bearbeitet <Moment fromNow>{value.lastModified}</Moment>
              </span>
            )}
          </div>
        </div>
      </div>,
    );
  });
  return (
    <Stack sx={{ maxWidth: 650 }}>
      <PanePaper
        title="contract.templates"
        tooltip="help.settings.contract.templates.pane"
        rightSection={
          <BookitupAddIcon onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.CONTRACT_USAGE, toggle)} />
        }
      >
        <div style={styles.list}>
          {items}
          <ContractTemplateWorkflow reloadContracts={loadContracts} />
        </div>

        <ContractTemplateNameModal show={show} toggle={toggle} finish={create} />
      </PanePaper>

      <StandardLayoutContent />

      <MediaUpload
        showPreview
        kind="signature"
        title="Unterschrift"
        text="Hier kannst Du ein Bild Deiner Unterschrift hineinziehen"
        setLastModified={() => {}}
      />

      <PanePaper title="document.deadlines">
        <EditableProfileProperty
          patchField="fixEngagementUntil"
          profile={profile}
          placeholder="applicationUser.fixEngagementUntil"
          editFormatter={SimpleEditableText}
          labelFormatter={NumberFormatter}
        />
      </PanePaper>
    </Stack>
  );
};
