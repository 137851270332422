import React, { FC, useContext, useEffect, useState } from 'react';
import {Group, Skeleton, Text} from '@mantine/core';
import { getResourcePosition, getResourcePositionFavorite } from '../../../../Service/restapi/documentService';
import { Resource } from '../../../../Types/ResourceT';
import ResourceS from '../../../../Service/ResourceS';
import { ViolationContext } from '../../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../../Service/LicenceS';
import { openResourcePositionModal } from './ResourcePositionModal';
import ResourceWidgetItem from "../../../Resources/aside/ResourceWidgetItem";
import ResourceAvailabilityDot from "./ResourceAvailabilityDot";
import {ProfileContext} from "../../../../Service/Context/ProfileContext";
import {Position} from "../../../../Service/PositionS";

export type ResourcePosition = {
  id: string;
  positionId: number;
  resourceId: string;
  exemplarId?: string;
  factorGroupId: string;
  planningGroupTemplateId: string;
};

type Props = {
  position: Position;
  preview: boolean;
  favoritePosition: boolean;
  event?: any;
  amount?: string
};

const ResourcePositionAssignment: FC<Props> = ({ position, preview, favoritePosition, event, amount }) => {
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const [resourcePosition, setResourcePosition] = useState<ResourcePosition | null>(null);
  const [resource, setResource] = useState<Resource | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { profile } = useContext<any>(ProfileContext);

  useEffect(() => {
    if (favoritePosition) {
      getResourcePositionFavorite(position.id)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((e) => {
              setResourcePosition(e);
            });
          } else {
            console.error(`could not load resource of position ${position.id}`);
          }
        })
        .catch(() => {
          console.error(`could not load resource of position ${position.id}`);
        }).finally(() => setLoading(false));
    } else {
      getResourcePosition(position.id)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((e) => {
              setResourcePosition(e);
            });
          } else {
            console.error(`could not load resource of position ${position.id}`);
          }
        })
        .catch(() => {
          console.error(`could not load resource of position ${position.id}`);
        }).finally(() => setLoading(false));
    }
  }, [position, favoritePosition]);

  useEffect(() => {
    if (resourcePosition) {
      ResourceS.fetchById(resourcePosition?.resourceId)
        .then(setResource)
        .catch(() => {
          console.error(`could not load resource ${resourcePosition?.resourceId}`);
        });
    } else {
      setResource(null);
    }
  }, [resourcePosition]);

  if (!LicenceS.hasAccessToResourceModule(profile.id)) {
    return null;
  }

  const onClick = () => {
    executeWithLicenceCheck(LicenceS.Restrictions.PRODUCT_RESOURCE, () =>
      openResourcePositionModal({ resourcePosition, setResourcePosition, positionId: position.id, resource, favoritePosition }),
    );
  };

  if (loading || (resourcePosition != null && resource == null)) {
    return <Skeleton mt="sm" mb="sm" height={20} width={150} />;
  }

  return (
      <Group position="apart">
        {(resource && resourcePosition) ?
            <>
              {(event && amount) && <ResourceAvailabilityDot eventId={event.id} resourceId={resource.id} amount={amount}/>}
              <ResourceWidgetItem
                  resource={resource}
                  onClick={onClick}
              />

            </> :
            <Text
                variant="link"
                size="xs"
                color="blue"
                sx={{cursor: 'pointer'}}
                onClick={onClick}
            >
              Ressource verknüpfen
            </Text>}
      </Group>
  );
};

export default ResourcePositionAssignment;
