import { useContext, useEffect, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'; // dist/entry.webpack';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import { Trans, useLingui } from '@lingui/react';
import { Alert } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { Center, Loader, LoadingOverlay, Text } from '@mantine/core';
import { ProfileContext } from '../Service/Context/ProfileContext';
import { ToastS } from '../Service/ToastS';
import { ViolationContext } from '../Service/Context/ViolationContext';
import { DocumentContext } from '../Service/Context/DocumentContext';
import { Icon } from './Icons';
import { TransButtonLink } from './Buttons';

const PDFViewer = (props) => {
  const { profile } = useContext(ProfileContext);
  const { interceptViolation } = useContext(ViolationContext);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState(undefined);
  const [unfilledProfile, setUnfilledProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { i18n } = useLingui();
  const documentContext = useContext(DocumentContext);
  const { isDraft } = props;
  const transmittingDocument = documentContext && documentContext.transmittingDocument;
  const [pdfError, setPdfError] = useState(false);

  // eslint-disable-next-line no-underscore-dangle
  const _setFile = (blob) => {
    setFile(blob);
    if (props.setFile) {
      props.setFile(blob);
    }
  };

  function onDocumentLoadSuccess(numPages) {
    setNumPages(numPages._pdfInfo.numPages);
  }

  function updateCurrentPage(element) {
    setPageNumber(element + 1);
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(fetchPDF, isDraft ? 500 : 0);
  }, [props.documentLayout, props.lastModified]);

  const fetchPDF = () => {
    fetch(props.url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (!response.ok) {
          setPdfError(true);
        }
        if (response.ok) {
          response
            .blob()
            .then((blob) => {
              _setFile(blob);
            })
            .catch(() => {
              console.error('no blob file');
              // no blob
            });
        } else if (response.status === 412) {
          response
            .json()
            .then((json) => {
              ToastS.error('placeholder.unsatisfied', i18n._('placeholder.unsatisfied'));
              json.forEach((placeholder) => {
                const translation = i18n._(placeholder, { 0: 1 });
                ToastS.error(`placeholder-unsatisfied-${translation}`, i18n._(`${translation} nicht gesetzt`));
              });

              props.closeCallback();
            })
            .catch(() => {
              console.error('json error');
            });
        } else if (response.status === 400) {
          interceptViolation(response);
        } else {
          ToastS.error('document.generate.error', i18n._('document.generate.error'));
        }
      })
      .catch(() => {
        console.error('Error while getting any response from server. Maybe no internet connection or server down.');
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (props.kind && props.kind !== 'FILESYSTEM') {
      fetch(`${process.env.REACT_APP_AUTH_SERVICE_URL}/profiles/${profile.id}/filled`, {
        method: 'GET',
      }).then((response) => {
        if (response.ok) {
          response.text().then((text) => {
            if (text < 50) {
              setUnfilledProfile(true);
            }
          });
        }
      });
    }
  }, []);

  return (
    <div className="view-file">
      {unfilledProfile && (
        <div>
          <Alert color="info">
            <Icon value="BULB" /> <Trans id="profile.unfilled" />
            <TransButtonLink
              style={{ color: '#4f6a83' }}
              onClick={() => {
                navigate('/settings/userdata/');
              }}
              text="actions.route.settings"
              title="actions.route.settings"
            />
          </Alert>
          <br />
        </div>
      )}
      <br />
      {pdfError && (
        <Text align="center" mt="xl" size="md">
          Dokument konnte nicht erstellt werden. Bitte versuche es erneut. Sollte der Fehler weiterhin auftreten,
          kontaktiere bitte unseren Support über den Chat unten rechts.
        </Text>
      )}
      {loading && (
        <Center sx={{ width: props.containerWidth, maxWidth: '85vw', height: '50vh' }}>
          <Loader size="xl" />
        </Center>
      )}
      {file && (
        <div id="previewPDF" style={{ position: 'relative' }}>
          <LoadingOverlay visible={transmittingDocument} overlayBlur={2} loaderProps={{ size: 'xl' }} />

          <Document
            file={file}
            loading={
              <Center sx={{ width: props.containerWidth, maxWidth: '85vw', height: '50vh' }}>
                <Loader size="xl" />
              </Center>
            }
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(e) => {
              console.error(e);
            }}
            error={<Trans id="document.generate.error" />}
          >
            {Array.from(Array(numPages).keys()).map((el) => (
              <div key={el} onMouseOver={() => updateCurrentPage(el)} onFocus={() => updateCurrentPage(el)}>
                <Page pageNumber={el + 1} width={1000} />
              </div>
            ))}
          </Document>
        </div>
      )}
    </div>
  );
};

export default PDFViewer;
