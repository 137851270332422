import React, { useContext } from 'react';
import { downloadFile } from '../../../Service/FileActions';

export default function ({ index, fileName }) {
  const styles = {
    alternateBackColor: {
      backgroundColor: index % 2 ? '#fbfbfb' : '#ffffff',
      padding: 20,
    },
  };

  function downloadBill(fileName) {
    downloadFile('bills', 'default', fileName);
  }

  return (
    <div
      className="clickable"
      style={styles.alternateBackColor}
      key={index}
      onClick={() => {
        downloadBill(fileName);
      }}
    >
      {fileName}
    </div>
  );
}
