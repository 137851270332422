import { Center, Skeleton, ThemeIcon } from '@mantine/core';
import { IconBallpen, IconCheck, IconCurrencyEuro, IconLock } from '@tabler/icons';
import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { fetchContactById } from '../../../Service/restapi/contactService';
import { ToastS } from '../../../Service/ToastS';
import { DocumentS } from '../../../Templates/Documents/DocumentS';
import BookitupDocument from '../../../Types/BookitupDocument';
import Contact from '../../../Types/Contact';
import DocumentPortalStatus from '../DocumentPortalStatus';
import DocumentEventStatus from './DocumentEventStatus';
import DocumentItemActions from './DocumentItemActions';
import DocumentExpirationBadge from './DocumentExpirationBadge';
import { ViolationContext } from '../../../Service/Context/ViolationContext';

interface DocumentItemProps {
  bookitupDocument: BookitupDocument;
  reload: () => void;
  showNumber?: boolean;
  showDatum?: boolean;
  showName?: boolean;
  showDueDate?: boolean;
  showPortal?: boolean;
  withEventStatus?: boolean;
  narrowEventStatus?: boolean;
  narrowPortalStatus: boolean;
  showSum?: boolean;
  hideEventDate?: boolean;
}

const DocumentListItem: FC<DocumentItemProps> = ({
  bookitupDocument: initialDocument,
  reload,
  showDatum,
  showDueDate,
  showName,
  showPortal,
  showNumber,
  withEventStatus,
  narrowEventStatus,
  narrowPortalStatus,
  showSum,
  hideEventDate,
}) => {
  const navigate: NavigateFunction = useNavigate();
  const [customer, setCustomer] = useState<Contact>();
  const [bookitupDocument, setBookitupDocument] = useState(initialDocument);
  const { interceptViolation } = useContext(ViolationContext);
  const [loading, setLoading] = useState<boolean>();
  const { id, eventId, customerId, date, finalSum, number, draft, locked, hasPayments, paid, subject, acceptedAt } =
    bookitupDocument;
  const creationDate = new Date(date);

  const refresh = () => {
    DocumentS.fetchById(id).then((doc) => {
      if (doc) {
        setBookitupDocument(doc);
      }
    });
  };

  const patchDocument = (patch: Partial<BookitupDocument>, onSuccess?: () => void) => {
    DocumentS.patchDocument(id, patch, interceptViolation).then((patchedDocument) => {
      if (patchedDocument) {
        setBookitupDocument(patchedDocument);
        if (onSuccess) onSuccess();
      }
    });
  };

  const setDraft = (value: boolean) => {
    setBookitupDocument((prevState) => ({
      ...prevState,
      draft: value,
    }));
  };

  const getStatusIcon = (): ReactNode => {
    if (draft) {
      return (
        <ThemeIcon radius="xl" size="sm" color="gray">
          <IconBallpen size={14} />
        </ThemeIcon>
      );
    }
    if (!hasPayments) {
      return (
        <ThemeIcon color={acceptedAt ? 'green' : 'blue'} radius="xl" size="sm">
          <IconCheck size={14} />
        </ThemeIcon>
      );
    }
    return (
      <ThemeIcon radius="xl" size="sm" color={paid ? 'green' : 'yellow'}>
        <IconCurrencyEuro size={14} />
      </ThemeIcon>
    );
  };

  // eslint-disable-next-line no-underscore-dangle
  const _onClick = () => {
    const targetPath = eventId
      ? `/events/${eventId}/documents/${id}${draft ? '' : '/preview'}`
      : `${id}${draft ? '' : '/preview'}`;
    navigate(targetPath);
  };

  useEffect(() => {
    if (customerId) {
      fetchContactById(customerId.toString())
        .then((contact: any) => setCustomer(contact))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  return (
    <tr>
      <td>{getStatusIcon()}</td>
      {showNumber && (
        <td onClick={_onClick} style={{ cursor: 'pointer' }} className="overflow-ellipsis">
          {locked && <IconLock size={16} style={{ marginBottom: 3 }} />} {number}
        </td>
      )}
      {showName && (
        <td onClick={_onClick} style={{ cursor: 'pointer' }}>
          {loading ? <Skeleton width="100%" height={30} /> : customer?.personDescription}
        </td>
      )}
      {showDatum && (
        <td onClick={_onClick} style={{ cursor: 'pointer' }}>
          <Moment date={creationDate} format="DD.MM" />
        </td>
      )}
      {showSum && <td onClick={_onClick} style={{ cursor: 'pointer' }}>{`${finalSum} €`}</td>}

      {showDueDate && (
        <td onClick={_onClick} style={{ cursor: 'pointer' }}>
          <DocumentExpirationBadge bookitupDocument={bookitupDocument} />
        </td>
      )}
      {withEventStatus && (
        <td onClick={_onClick}>
          {eventId && (
            <DocumentEventStatus shortVersion={narrowEventStatus} eventId={eventId} hideDate={hideEventDate} />
          )}
        </td>
      )}
      {showPortal && (
        <td>
          {eventId && (
            <DocumentPortalStatus
              setDraft={setDraft}
              shortVersion={narrowPortalStatus}
              entityType="documents"
              entityId={id.toString()}
              label={`${subject} ${number}`}
            />
          )}
        </td>
      )}
      <td style={{ position: 'relative' }}>
        <Center>
          <div style={{ position: 'absolute', right: 0 }}>
            <DocumentItemActions
              bookitupDocument={bookitupDocument}
              reload={reload}
              refresh={refresh}
              handleChange={setBookitupDocument}
              setDraft={setDraft}
              patchDocument={patchDocument}
            />
          </div>
        </Center>
      </td>
    </tr>
  );
};

export default DocumentListItem;
