import React, { useEffect, useState } from 'react';
import { Icon } from '../../../Atoms/Icons';

export const PasswordConstraintCheck = ({ password, passwordRepeat, setValidPassword }) => {
  const [passwordsEqual, setPasswordEqual] = useState(password === passwordRepeat);
  const [validPasswordLength, setValidPasswordLength] = useState(false);
  const [hasSmallLetter, setHasSmallLetter] = useState(false);
  const [hasCapitalLetter, setHasCapitalLetter] = useState(false);
  const [hasSpacialChar, setHasSpacialChar] = useState(false);
  const [hasNumber, setHasNumber] = useState(false);

  const smallLetterPattern = /[a-z]/g;
  const capitalLetterPattern = /[A-Z]/g;
  const specialCharPattern = /[!@#\$%\^\&*\)\(+=._-]/g;
  const numberPattern = /[0-9]/g;

  useEffect(() => {
    setPasswordEqual(password === passwordRepeat);

    if (password) {
      setValidPasswordLength(password.length >= 8);
      setHasSmallLetter(password.match(smallLetterPattern));
      setHasCapitalLetter(password.match(capitalLetterPattern));
      setHasSpacialChar(password.match(specialCharPattern));
      setHasNumber(password.match(numberPattern));
    } else {
      setValidPasswordLength(false);
      setHasSmallLetter(false);
      setHasCapitalLetter(false);
      setHasSpacialChar(false);
      setHasNumber(false);
    }
  }, [password, passwordRepeat]);

  useEffect(() => {
    setValidPassword(
      passwordsEqual && validPasswordLength && hasSmallLetter && hasCapitalLetter && hasSpacialChar && hasNumber,
    );
  }, [passwordsEqual, validPasswordLength, hasSmallLetter, hasCapitalLetter, hasSpacialChar, hasNumber]);

  const styles = {
    valid: {
      color: 'green',
    },
    invalid: {
      color: 'red',
    },
  };

  return (
    <>
      {validPasswordLength && (
        <div style={styles.valid}>
          <Icon value="CHECK" /> Passwörter mit mindestens 8 Zeichen
        </div>
      )}
      {!validPasswordLength && (
        <div style={styles.invalid}>
          <Icon value="TIMES" /> Passwortlänge mit mindestens 8 Zeichen
        </div>
      )}

      {hasSmallLetter && (
        <div style={styles.valid}>
          <Icon value="CHECK" /> Mindestens einen Kleinbuchstaben
        </div>
      )}
      {!hasSmallLetter && (
        <div style={styles.invalid}>
          <Icon value="TIMES" /> Mindestens einen Kleinbuchstaben
        </div>
      )}

      {hasCapitalLetter && (
        <div style={styles.valid}>
          <Icon value="CHECK" /> Mindestens einen Großbuchstaben
        </div>
      )}
      {!hasCapitalLetter && (
        <div style={styles.invalid}>
          <Icon value="TIMES" /> Mindestens einen Großbuchstaben
        </div>
      )}

      {hasSpacialChar && (
        <div style={styles.valid}>
          <Icon value="CHECK" /> Mindestens ein Sonderzeichen
        </div>
      )}
      {!hasSpacialChar && (
        <div style={styles.invalid}>
          <Icon value="TIMES" /> Mindestens ein Sonderzeichen
        </div>
      )}

      {hasNumber && (
        <div style={styles.valid}>
          <Icon value="CHECK" /> Mindestens eine Zahl
        </div>
      )}
      {!hasNumber && (
        <div style={styles.invalid}>
          <Icon value="TIMES" /> Mindestens eine Zahl
        </div>
      )}

      {passwordsEqual && (
        <div style={styles.valid}>
          <Icon value="CHECK" /> Passwörter stimmen überein
        </div>
      )}
      {!passwordsEqual && (
        <div style={styles.invalid}>
          <Icon value="TIMES" /> Passwörter sind nicht gleich
        </div>
      )}
    </>
  );
};
