// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Badge, Button, Center, Group, Stack, Text } from '@mantine/core';
import { FC, useContext } from 'react';
import PanePaper from '../../../Molecules/PanePaper';
import { DataS } from '../../../Service/DataS';
import { BillingInterval } from '../../../Types/Billing';
import FieldValueRow from '../../../Atoms/FieldValueRow';
import { ResourceAddonContext } from '../../../Service/Context/ResourceAddonsContext';
import { LicenceS } from '../../../Service/LicenceS';
import { useJwtClaims } from '../../../Service/Context/AuthContext';

const AvailableAddons: FC<{ billingInterval: BillingInterval }> = ({ billingInterval }) => {
  const { trialAvailable, openTrialModal } = useContext(ResourceAddonContext);
  const { profileId } = useJwtClaims();

  if (!LicenceS.hasAccessToResourceModule(profileId)) {
    return null;
  }

  return (
    <>
      {DataS.billingAddons.map((addon) => {
        const { name, description, price } = addon;

        return (
          <PanePaper
            key={addon.name}
            withShadow
            withoutBorder
            rightSection={
              <Badge mb="md" radius="sm" sx={{ color: 'white' }} variant="gradient">
                Addon
              </Badge>
            }
          >
            <Text size="xl" weight="bolder">
              {name}
            </Text>
            <Text mt="md" mb="sm">
              {description}
            </Text>
            <Text weight="bolder" mt="md" mb={5}>
              Anzahl der Ressourcen
            </Text>
            <Stack spacing={5} sx={{ borderTop: `2px solid #e2e2e2` }} pt={5}>
              {price[billingInterval].map((tier) => {
                const { from, to, price: amount } = tier;
                return (
                  <Group key={amount} position="apart" pl={5} pr={5}>
                    <Text italic>{`${from} - ${to}`}</Text>
                    <Text weight="bolder">{amount} € / Monat</Text>
                  </Group>
                );
              })}
            </Stack>
            {trialAvailable && (
              <Center mt="md">
                <Button onClick={openTrialModal}>Jetzt kostenlos testen</Button>
              </Center>
            )}
            <Text italic mt="md" size="xs" color="dimmed">
              Bei einer größeren Anzahl an Ressourcen wende Dich bitte an unseren Support unter{' '}
              <a href="mailto:support@bookitup.de?subject=Angebot für mehr Ressourcen">
                support@bookitup.de
              </a>. Preise zzgl. gesetzl. MwSt.
            </Text>
          </PanePaper>
        );
      })}
    </>
  );
};

export default AvailableAddons;
