// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Group, Image, LoadingOverlay, Stack, Text } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import PanePaper from '../../../../Molecules/PanePaper';
import { WindowContext } from '../../../../Service/Context/WindowContext';
import { LexOfficeConfig as LexOfficeConfigDTO, LexOfficeS } from '../../../../Service/LexOfficeS';
import LexOfficeApiPanel from './LexOfficeApiPanel';
import LexOfficeOAuthPane from './LexOfficeOAuthPane';
import LexOfficeImportPane from './LexOfficeImportPane';

const LexOfficeConfig = () => {
  const { xs } = useContext(WindowContext);
  const [lexOfficeConfig, setLexOfficeConfig] = useState<LexOfficeConfigDTO>();
  const [loading, setLoading] = useState(false);

  useEffect(() => fetchConfig(), []);

  const fetchConfig = () => {
    setLoading(true);
    LexOfficeS.fetchLexOfficeConfig()
      .then((config) => {
        if (config) {
          setLexOfficeConfig(config);
        }
      })
      .finally(() => setLoading(false));
  };

  const updateConfig = (patch: Partial<LexOfficeConfigDTO>) =>
    LexOfficeS.patchConfig(patch).then((config) => config && setLexOfficeConfig(config));

  if (loading) {
    return (
      <Box sx={{ position: 'relative', width: '100%', height: 500 }}>
        <LoadingOverlay visible overlayBlur={2} loaderProps={{ size: 'xl' }} />
      </Box>
    );
  }

  if (!lexOfficeConfig) {
    return null;
  }
  const { connected, apiKey } = lexOfficeConfig;

  return (
    <Stack>
      <PanePaper
        title="Verbindung zu lexoffice"
        rightSection={<Image width={xs ? 32 : 64} height={xs ? 21 : 42} src="/img/lexoffice-badge.png" />}
        sx={{ maxWidth: 850 }}
      >
        <Text mt="md" weight="bolder">
          Bitte wähle eine Art der Verbindung
        </Text>
        <Group mt="md" mb="xl">
          <LexOfficeOAuthPane config={lexOfficeConfig} updateConfig={updateConfig} refresh={fetchConfig} />
          <LexOfficeApiPanel config={lexOfficeConfig} updateConfig={updateConfig} />
        </Group>
        {(connected || apiKey) && <LexOfficeImportPane />}
      </PanePaper>
    </Stack>
  );
};

export default LexOfficeConfig;
