import { FC, ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import { ResourceFilter, Resource } from '../../Types/ResourceT';
import ResourceS from '../ResourceS';
import { PageNavigation } from '../../Types/Page';

interface ResourcesContextInterface {
  resources: Resource[];
  loading: boolean;
  filter: ResourceFilter;
  navigation: PageNavigation;
  empty: boolean;
  updateFilter: (patch: Partial<ResourceFilter>) => void;
  reload: () => void;
}

export const ResourcesContext = createContext<ResourcesContextInterface>({} as ResourcesContextInterface);

export const ResourcesContextProvider: FC<{ children: ReactNode; initialFilter?: Partial<ResourceFilter> }> = ({
  children,
  initialFilter,
}) => {
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState<Resource[]>([]);
  const [empty, setEmpty] = useState(true);
  const [navigation, setNavigation] = useState<PageNavigation>({ totalCount: 0, totalPages: 0 });
  const [filter, setFilter] = useState<ResourceFilter>({
    pageNumber: 0,
    pageSize: 10,
    showArchived: false,
    withContent: false,
    categoriesOfInterest: [],
    typesOfInterest: [],
    ...initialFilter,
  });

  useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();
    ResourceS.fetchAll(filter, abortController.signal)
      .then((page) => {
        if (page) {
          const { content, totalElements, totalPages, totalCount } = page;
          setNavigation({ totalCount: totalElements, totalPages });
          setResources(content);
          setEmpty(totalCount === 0);
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });
    return () => abortController.abort();
  }, [filter]);

  const updateFilter = (patch: Partial<ResourceFilter>) => {
    // Reset page number on filter change
    if (!patch.pageNumber) {
      // eslint-disable-next-line no-param-reassign
      patch.pageNumber = 0;
    }
    setFilter((prev) => ({ ...prev, ...patch }));
  };

  const reload = () => updateFilter({ trigger: new Date() });

  const value = useMemo(
    () => ({
      loading,
      resources,
      navigation,
      filter,
      empty,
      updateFilter,
      reload,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, resources, filter, navigation, empty],
  );

  return <ResourcesContext.Provider value={value}> {children}</ResourcesContext.Provider>;
};
