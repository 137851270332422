import { FC, useContext } from 'react';
import { useElementSize } from '@mantine/hooks';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import { ResourceDetailsContext } from '../../Service/Context/ResourceDetailsContext';
import PanePaper from '../../Molecules/PanePaper';
import { ResourceAddonContext } from '../../Service/Context/ResourceAddonsContext';
import ResourcePartsTable from './table/content/ResourcePartsTable';
import { openAddPartsModal } from './AddResourceContentModal';

const ResouceContentTable: FC = () => {
  const { resource, resourceId, refresh } = useContext(ResourceDetailsContext);
  const { executeWithCheck } = useContext(ResourceAddonContext);
  const { ref, width } = useElementSize();
  if (!resource || resource.type === 'ITEM') {
    return null;
  }
  return (
    <PanePaper
      title="Inhalt"
      rightSection={
        <BookitupAddIcon onClick={() => resourceId && executeWithCheck(() => openAddPartsModal(resourceId, refresh))} />
      }
    >
      <div ref={ref}>
        <ResourcePartsTable parts={resource.content} width={width} />
      </div>
    </PanePaper>
  );
};

export default ResouceContentTable;
