// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Divider, Image, Space, Stack, Text, Title } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { useWistiaChannel } from '../../Service/hooks/useWistiaChannel';
import { WindowContext } from '../../Service/Context/WindowContext';
import BookitupSelect from '../../Atoms/BookitupSelect';
import { WistiaS } from '../../Service/WistiaS';

const ChannelNavbar = () => {
  const { channel, currentEpisode } = useWistiaChannel();
  const navigate = useNavigate();
  const { md } = useContext(WindowContext);
  if (!channel || !currentEpisode) return null;
  const { episodes } = channel;

  return (
    <Box sx={{ maxWidth: md ? 'none' : 350, borderRadius: 5 }}>
      {!md && <Image radius="sm" src={channel.img} />}
      {md && (
        <>
          <Title order={5}>{channel.name}</Title>
          <Divider my="xs" />
        </>
      )}
      <BookitupSelect
        mt="md"
        value={channel.path}
        data={WistiaS.CHANNELS.map((ch) => ({ label: ch.dropdownLabel ?? ch.name, value: ch.path }))}
        onChange={(val) => val && navigate(`/academy/channels/${val}`)}
      />
      <Stack spacing="xs" mt="md" mb="xl" ml="sm">
        {episodes.map((episode, index) => {
          const isCurrent = currentEpisode === episode;
          return (
            <Text
              key={episode.id}
              weight={isCurrent ? 'bolder' : 'normal'}
              color={isCurrent ? 'blue' : 'dimmed'}
              onClick={() => navigate(`/academy/channels/${channel.path}?episodeId=${episode.id}`)}
              sx={{ cursor: isCurrent ? 'auto' : 'pointer' }}
              className={isCurrent ? undefined : 'hooverable-text'}
            >
              {`${index === 0 ? '' : `${index}.`} ${episode.title}`}
            </Text>
          );
        })}
      </Stack>
      {md && <Space h={50} />}
    </Box>
  );
};

export default ChannelNavbar;
