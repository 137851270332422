import React from 'react';
import MessageTemplateAttachmentOverview from '../../Molecules/Message/MessageTemplateAttachmentOverview';

export const EmailTemplateFlow2 = ({ mailTemplate, templateName, selectedDocuments, setDisableApply }) => {
  function onDocumentRequestSelected(documentRequest, index) {
    if (documentRequest === undefined) {
      selectedDocuments.delete(index);
    } else {
      selectedDocuments.set(index, documentRequest);
    }
  }

  return (
    <MessageTemplateAttachmentOverview
      templateName={templateName}
      kind={mailTemplate}
      setDisableApply={setDisableApply}
      onDocumentRequestSelected={onDocumentRequestSelected}
    />
  );
};
