import { useEffect, useState } from 'react';
import { TextInput } from '@mantine/core';
import { i18n } from '@lingui/core';
import { IconTemplate } from '@tabler/icons';
import { getContractTemplateNames } from '../../Service/restapi/contractTemplateService';
import { InputHelp } from '../../Atoms/inputs/InputHelp';
import { getDocumentTemplateNames } from '../../Service/restapi/templateService';
import { getQuestionnaireTemplateNames } from '../../Service/restapi/questionnaireService';
import { fetchMessageTemplateNames } from '../../Service/restapi/messageService';

const GenericTemplateName = ({ name, newName, setNewName, disableSave, documentType }) => {
  const [takenNames, setTakenNames] = useState();
  const [containsInvalidChar, setContainsInvalidChar] = useState(false);

  useEffect(() => {
    let funcCall = getDocumentTemplateNames;
    if (documentType === 'CONTRACT') {
      funcCall = getContractTemplateNames;
    } else if (documentType === 'QUESTIONNAIRE') {
      funcCall = getQuestionnaireTemplateNames;
    } else if (documentType === 'CUSTOM') {
      funcCall = fetchMessageTemplateNames;
    }
    funcCall(documentType).then((resp) => {
      if (resp.ok) {
        resp.json().then((names) => {
          names = names.filter((n) => n !== name);
          names = names.map((n) => n.toLowerCase());
          setTakenNames(names);
        });
      } else {
        console.error('could not load contract template names');
      }
    });
  }, []);

  const nameAlreadyExists = () => takenNames && takenNames.includes(newName.toLowerCase());

  useEffect(() => {
    const validName = nameAlreadyExists() || newName !== '';
    if (validName && !containsInvalidChar) {
      disableSave(false);
    } else {
      disableSave(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newName, containsInvalidChar, disableSave]);

  useEffect(() => {
    setContainsInvalidChar(/[/?#:%&+]/g.test(newName));
  }, [newName]);

  return (
    <>
      <TextInput
        label={i18n._('template.name')}
        value={newName}
        onChange={(e) => setNewName(e.currentTarget.value)}
        icon={<IconTemplate size={16} />}
      />
      {nameAlreadyExists() ? <InputHelp errorDescription="Dieser Name ist bereits vergeben." /> : null}
      {containsInvalidChar && <InputHelp errorDescription="Der Name enthält unerlaubte Sonderzeichen: %/?#:&+" />}
    </>
  );
};

export default GenericTemplateName;
