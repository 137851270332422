import React, { useContext, useState } from 'react';
import ResourceAdapter from '../../../Service/ResourceAdapter';
import { RadioBox } from '../../../Atoms/inputs/RadioBox';

const SelectContract = ({ templates, onChange, style }) => {
  const [value, setValue] = useState(undefined);

  const options = [];
  templates.forEach((templateName) => {
    options.push({
      label: templateName,
      checked: templateName === value,
      onClick: () => {
        setValue(templateName);
        onChange(templateName);
      },
      onDeselect: () => {
        setValue(undefined);
        onChange(undefined);
      },
    });
  });

  return <RadioBox options={options} />;
};

export default function (outerProps) {
  return (
    <ResourceAdapter
      serviceUrl={`${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/contracts/templates/names`}
      component={(props) => <SelectContract templates={props.data} {...outerProps} />}
    />
  );
}
