import React from 'react';
import { Icon } from '../../Atoms/Icons';
import RecommendationPipeline from './EventRecommendations/RecommendationPipeline';
import RecommendationPersonDescription from './EventRecommendations/RecommendationPersonDescription';

const RecommendationChild = ({ recommendation, small }) => {
  const { answer, bookingState, lastContactShare } = recommendation;

  const recommendationNotPossible = answer === 'UNAVAILABLE' || bookingState === 'CANCELED';
  const recommendationFinished = answer === 'AVAILABLE' && bookingState === 'BOOKED' && lastContactShare;

  return (
    <div>
      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', color: '#5E5E5E' }}>
        <RecommendationPersonDescription
          contactId={recommendation.recommendedContactId}
          style={{
            textDecoration: recommendationNotPossible ? 'line-through' : 'none',
            color: recommendationNotPossible ? '#B4B4B4' : '#5E5E5E',
          }}
        />
        <div style={{ display: 'flex' }}>
          {!recommendationNotPossible && !recommendationFinished && (
            <RecommendationPipeline answer={answer} bookingState={bookingState} lastContactShare={lastContactShare} />
          )}
          {recommendationNotPossible && (
            <div style={{ fontSize: 8, display: 'flex' }}>
              <div className="pipeline recommendation stopped failed">
                <div style={{ border: '1px solid #ffffff', borderRadius: 5, width: 5 }} />
              </div>{' '}
              <div style={{ marginLeft: 5 }}>Vermittlung nicht möglich</div>
            </div>
          )}
          {recommendationFinished && (
            <div style={{ fontSize: 8, display: 'flex' }}>
              <div className="pipeline recommendation stopped success">
                {' '}
                <Icon value="CHECK" />
              </div>{' '}
              <div style={{ marginLeft: 5 }}>Vermittlung abgeschlossen</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecommendationChild;
