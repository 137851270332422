enum AgendaType {
  STRING = 'STRING',
  DATE = 'DATE',
  RECURRING = 'RECURRING',
  ANSWER_REQUEST = 'ANSWER_REQUEST',
  MAKE_OFFER = 'MAKE_OFFER',
  FOLLOW_UP = 'FOLLOW_UP',
  PAYMENT = 'PAYMENT',
  REPLY = 'REPLY',
}

export default AgendaType;
