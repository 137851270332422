// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Stack, Text } from '@mantine/core';
import { FC, useContext } from 'react';
import { EventContext } from '../../Service/Context/EventContext';

const EventBreadCrumbs: FC = () => {
  const { event } = useContext<any>(EventContext);

  return (
    <Stack spacing={3}>
      <Text>Event am</Text>
      <Text size="xl" weight="bolder">{`${event.dates[0].weekday}, ${new Intl.DateTimeFormat('de').format(
        new Date(event.dates[0].date),
      )}`}</Text>
    </Stack>
  );
};

export default EventBreadCrumbs;
