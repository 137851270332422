const AlertType = {
  REGISTRATION_COMPLETED: 'verificationsuccessfull',
  PASSWORD_CHANGED: 'passwordchanged',
  LOGOUT_SUCCESS: 'logoutsuccessful',
  LINK_EXPIRED: 'linkexpired',
  WRONG_CRENDENTIALS: 'wrongcredentials',
  WRONG_EMAIL: 'wrongemail',
  EMAIL_TAKEN: 'useralreadyexists',
  UNEXPECTED_ERROR: 'unexpectederror',
  SERVER_UNAVAILABLE: 'serverunavailable',
};

export type AlertDetails = {
  color: string;
  title?: string;
};

interface AlertInterface {
  [name: string]: AlertDetails;
}

const Alerts: AlertInterface = {
  verificationsuccessfull: {
    color: 'green',
    title: 'Registrierung vervollständigt',
  },
  passwordchanged: {
    color: 'green',
    title: 'Passwort geändert',
  },
  logoutsuccessful: {
    color: 'green',
    title: 'Abmeldung erfolgreich',
  },
  linkexpired: {
    color: 'red',
    title: 'Link abgelaufen',
  },
  wrongcredentials: {
    color: 'red',
    title: 'Ungültige Anmeldeinformationen',
  },
  wrongemail: {
    color: 'orange',
    title: 'Ungültige E-Mail',
  },
  useralreadyexists: {
    color: 'orange',
    title: 'E-Mail wird übernommen',
  },
  unexpectederror: {
    color: 'red',
    title: 'Unerwarteter Fehler',
  },
  serverunavailable: {
    color: 'red',
    title: 'Server nicht verfügbar',
  },
};

// eslint-disable-next-line import/prefer-default-export
export const AlertS = {
  Alerts,
  AlertType,
};
