// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from '@lingui/core';
import { BookitupNotification } from '../../../../Types/LogT';

const DocumentCreated: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { entityId, eventId, value } = notification;
  const navigate = useNavigate();

  return (
    <Text>
      <Text
        span
        weight="bolder"
        variant="link"
        color="blue"
        sx={{ cursor: 'pointer' }}
        onClick={() => navigate(`/events/${eventId}/documents/${entityId}/preview`)}
      >
        {i18n._(`document.${value?.toLowerCase()}`)}
      </Text>
      {'  '}
      wurde erstellt.
    </Text>
  );
};

export default DocumentCreated;
