// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text, SelectItemProps } from '@mantine/core';
import { forwardRef } from 'react';

interface CustomAttributeItemProps extends SelectItemProps {
  groupName: string;
  name: string;
}
const AutocompleteCustomAttributeItem = forwardRef<HTMLDivElement, CustomAttributeItemProps>(
  ({ groupName, name, ...rest }: CustomAttributeItemProps, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div ref={ref} {...rest}>
      <Text size="xs" color="dimmed">
        {groupName}
      </Text>
      <Text>{name}</Text>
    </div>
  ),
);

export default AutocompleteCustomAttributeItem;
