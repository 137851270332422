import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { LoadingOverlay } from '@mantine/core';
import { getContactById } from '../restapi/contactService';
import { ajaxActions } from '../AjaxActions';
import { ProfileContext } from './ProfileContext';

export const ContactContext = React.createContext(undefined);

export const ContactContextProvider = () => {
  const { profile } = useContext(ProfileContext);
  const [contact, setContact] = useState(undefined);

  useEffect(() => {
    if (profile) {
      const { contactId } = profile;
      if (contactId) {
        loadContact();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  const { personDescription } = contact ?? {};

  useEffect(() => {
    if (personDescription && mixpanel.people) {
      mixpanel.people.set({ $name: personDescription });
    }
  }, [personDescription]);

  if (!contact) {
    return <LoadingOverlay visible overlayBlur={2} loaderProps={{ size: 'xl' }} />;
  }

  function loadContact() {
    getContactById(profile.contactId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((contact) => {
            setContact(contact);
          });
        } else {
          console.error(`could not load own contact ${profile.contactId} of profile`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not load own contact ${profile.contactId} of profile`);
      });
  }

  return (
    <ContactContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        contact,
        handleContactChange: (patch) => {
          setContact((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
        saveContactChange: (patch) => {
          ajaxActions.patch(`${process.env.REACT_APP_AUTH_SERVICE_URL}/contacts/${contact.id}`, patch);
          setContact((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
      }}
    >
      <Outlet />
    </ContactContext.Provider>
  );
};
