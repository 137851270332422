import { useState } from 'react';
import { Button, Select, Space, Text } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import { i18n } from '@lingui/core';
import { IconChevronDown, IconFileDescription } from '@tabler/icons';
import GenericTemplateName from '../../Molecules/Settings/GenericTemplateName';
import { DataS } from '../../Service/DataS';

const SelectDocumentTypeAndNameModal = ({ finish }) => {
  const [documentType, setDocumentType] = useState('OFFER');
  const [disableSave, setDisableSave] = useState(false);
  const [newName, setNewName] = useState('');

  const onClickHandler = () => {
    finish(documentType, newName.trim());
    closeAllModals();
  };

  return (
    <>
      <Space h="xl" />
      <Select
        icon={<IconFileDescription size={16} />}
        label="Dokumentenart"
        data={DataS.documentTypeOptions}
        styles={{ rightSection: { pointerEvents: 'none' } }}
        rightSection={<IconChevronDown size={14} />}
        rightSectionWidth={25}
        value={documentType}
        onChange={setDocumentType}
        mb="sm"
      />
      <GenericTemplateName
        disableSave={setDisableSave}
        documentType={documentType}
        newName={newName}
        setNewName={setNewName}
      />
      <Space h="md" />
      <Button fullWidth mt="xl" mb="md" onClick={onClickHandler} disabled={disableSave}>
        {i18n._('common.apply')}
      </Button>
      <Text color="dimmed" align="center" sx={{ cursor: 'pointer' }} onClick={() => closeAllModals()}>
        {i18n._('actions.cancel')}
      </Text>
    </>
  );
};

export default SelectDocumentTypeAndNameModal;
