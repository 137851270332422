import { Group, Popover, Text, Box } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import ReactGPicker from 'react-gcolor-picker';
import { useDebouncedCallback } from 'use-debounce';

interface Props {
  initialGradient: string;
  label: string;
  onChange: (val: string) => unknown;
}

const GradientPicker: FC<Props> = ({ initialGradient, label, onChange }) => {
  const [gradient, setGradient] = useState(initialGradient);

  useEffect(() => {
    if (gradient !== initialGradient) {
      setGradient(initialGradient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialGradient]);

  useEffect(() => {
    if (gradient !== initialGradient) {
      debouncedOnChange();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gradient]);

  const debouncedOnChange = useDebouncedCallback(() => onChange(gradient), 1000);

  return (
    <Group>
      <Popover>
        <Popover.Target>
          <Box
            sx={{
              background: gradient,
              width: 25,
              height: 25,
              borderRadius: 4,
              boxShadow: 'rgba(0, 0, 0, .1) 0px 0px 0px 1px inset,rgb(0, 0, 0, .15) 0px 0px 4px inset',
              cursor: 'pointer',
            }}
          />
        </Popover.Target>
        <Popover.Dropdown>
          <ReactGPicker gradient value={gradient} onChange={setGradient} />
        </Popover.Dropdown>
      </Popover>
      <Text size="xs">{label}</Text>
    </Group>
  );
};

export default GradientPicker;
