/* eslint-disable camelcase */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Skeleton, Text, Button, Card, Image, ThemeIcon, Badge } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons';
import { FC, useContext, useState } from 'react';
import { AnnoucementsContext } from '../../Service/Context/AnnouncementsContext';

const AnnouncementsCard: FC = () => {
  const { stories, loading } = useContext(AnnoucementsContext);
  const [index, setIndex] = useState<number>(0);
  const filteredStories = stories.filter((story) => story.content.post_type !== 'MAINTENANCE');

  if (!loading && (!filteredStories || filteredStories.length === 0)) {
    return null;
  }
  const { length } = filteredStories;
  const canShowPrevious = index > 0;
  const canShowNext = index + 1 < length;
  const showPrevious = () => canShowPrevious && setIndex(index - 1);
  const showNext = () => canShowNext && setIndex(index + 1);

  if (!filteredStories[index]) {
    return null;
  }

  const { content } = filteredStories[index];
  const { title, post_type, image, call_to_action_btn, buttonTitle, description } = content;
  const imageUrl = image?.filename;
  const actionLink = call_to_action_btn?.url;

  return (
    <Skeleton visible={loading} width={450}>
      <Card p="lg" radius="md">
        {imageUrl && (
          <Card.Section>
            <Image src={imageUrl} height={160} />
          </Card.Section>
        )}
        <Group position="apart" mt="xl" mb="xs">
          <Text weight="bolder" size="md">
            {title}
          </Text>
          <Badge radius="sm" sx={{ color: 'white', backgroundColor: post_type === 'MAINTENANCE' ? 'red' : 'black' }}>
            {post_type === 'MAINTENANCE' ? 'Wartung' : 'Update'}
          </Badge>
        </Group>
        <Text size="sm" mt="xl" sx={{ height: 100 }}>
          {description}
        </Text>
        <Group position="apart" mt="xl" mb="xs" sx={{ position: 'relative' }}>
          {actionLink && (
            <Button
              variant="outline"
              radius="sm"
              sx={(theme) => ({ backgroundColor: theme.colors.secondaryBlue[3], border: 'none' })}
            >
              <a href={actionLink} target="_blank" style={{ color: '#1D5191' }}>
                {buttonTitle}
              </a>
            </Button>
          )}
          {length > 1 && (
            <Group spacing={5} sx={{ position: 'absolute', right: 0 }}>
              <ThemeIcon
                onClick={showPrevious}
                size="lg"
                sx={{ opacity: canShowPrevious ? 1 : 0.6, cursor: canShowPrevious ? 'pointer' : 'initial' }}
              >
                <IconChevronLeft />
              </ThemeIcon>
              <ThemeIcon
                onClick={showNext}
                size="lg"
                sx={{ opacity: canShowNext ? 1 : 0.6, cursor: canShowNext ? 'pointer' : 'initial' }}
              >
                <IconChevronRight />
              </ThemeIcon>
            </Group>
          )}
        </Group>
      </Card>
    </Skeleton>
  );
};

export default AnnouncementsCard;
