import React, { useContext, useState } from 'react';
import ResourceAdapter from '../../../Service/ResourceAdapter';
import DisplayTable from '../../../Organismns/DisplayTable';
import { ProfileContext } from '../../../Service/Context/ProfileContext';

const Table = ({ profileId, attachments: initialValue, reloadCallback }) => {
  const [attachments] = useState(initialValue);

  return (
    <div>
      <DisplayTable
        data={attachments}
        show
        id="fileName"
        display={['fileName']}
        i18nPrefix="promomaterial"
        entityType="profiles"
        entityId={profileId}
        deletable
        reloadCallback={reloadCallback}
      />
    </div>
  );
};

export default function (outerProps) {
  const { profile } = useContext(ProfileContext);

  return (
    <ResourceAdapter
      serviceUrl={`${process.env.REACT_APP_FILE_SERVICE_URL}/files/profiles/${profile.id}`}
      lastUpdate={outerProps.lastUpdate}
      component={(props) => (
        <Table attachments={props.data} profileId={profile.id} reloadCallback={outerProps.reloadCallback} />
      )}
    />
  );
}
