// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Space, Stack, Text } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd-latest';
import { HTML5Backend } from 'react-dnd-html5-backend-latest';
import { i18n } from '@lingui/core';
import PanePaper from '../../../Molecules/PanePaper';
import { CustomAttributeCreateModal } from '../../../Molecules/Event/CustomAttributes/CustomAttributeCreateModal';
import { useModalState } from '../../../Molecules/Dialogs';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import { CustomAttributeGroupDTO } from '../../../Types/CustomAttributeT';
import { CustomAttributeS } from '../../../Service/Context/CustomAttributeS';
import AttributesGroup from '../../../Molecules/Event/CustomAttributes/AttributesGroup';
import SkeletonItems from '../../../Atoms/SkeletonItems';
import { RoutingS } from '../../../Service/RoutingS';

const CustomFields = () => {
  const [loading, setLoading] = useState(false);
  const [attributeGroups, setAttributeGroups] = useState<CustomAttributeGroupDTO[]>([]);
  const { show, toggle } = useModalState();
  const { executeWithLicenceCheck } = useContext(ViolationContext);

  useEffect(() => {
    RoutingS.changeTitle(i18n._('customFields'));
    fetchGroups();
  }, []);

  const fetchGroups = () => {
    setLoading(true);
    CustomAttributeS.fetchGroups('event')
      .then(setAttributeGroups)
      .finally(() => setLoading(false));
  };

  const addCustomAttribute = () => executeWithLicenceCheck(LicenceS.Restrictions.CUSTOM_ATTRIBUTE, toggle);

  const removeGroup = (deletedGroup: CustomAttributeGroupDTO) => {
    CustomAttributeS.deleteAttributesGroup(deletedGroup.id).then((deleted) => {
      if (deleted) {
        setAttributeGroups(attributeGroups.filter((group) => group !== deletedGroup));
      }
    });
  };

  return (
    <Stack mb={70} sx={{ maxWidth: 600 }}>
      <PanePaper
        title="customFields"
        tooltip="help.event.customFields.pane"
        rightSection={<BookitupAddIcon onClick={addCustomAttribute} />}
      >
        <Text>{i18n._('custom-fields-description')}</Text>
        {attributeGroups.length > 0 && (
          <Text size="xs" color="dimmed" mt="xs">
            {i18n._('custom-fields-tip')}
          </Text>
        )}
        {loading ? (
          <>
            <Space h="md" />
            <SkeletonItems height={50} mb={5} />
          </>
        ) : (
          <div>
            {attributeGroups.length === 0 ? (
              <Text italic color="dimmed" size="xs" weight="lighter" mt="md">
                {i18n._('custom-fields-empty')}
              </Text>
            ) : (
              <DndProvider backend={HTML5Backend}>
                <Group
                  mt="md"
                  spacing="xs"
                  p={6}
                  sx={{ backgroundColor: '#4A4A4A', borderRadius: 6, color: 'white', fontSize: 12 }}
                >
                  <Text ml="xl">Gruppen</Text>
                </Group>
                <Stack spacing={5} mt={5}>
                  {attributeGroups.map((group, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <AttributesGroup
                      entityType="event"
                      group={group}
                      index={index}
                      deleteGroup={() => removeGroup(group)}
                      attributeGroups={attributeGroups}
                      setAttributeGroups={setAttributeGroups}
                      reload={fetchGroups}
                    />
                  ))}
                </Stack>
              </DndProvider>
            )}
          </div>
        )}
        <CustomAttributeCreateModal entityType="event" toggle={toggle} show={show} finish={fetchGroups} />
      </PanePaper>
    </Stack>
  );
};

export default CustomFields;
