import React from 'react';
import PropTypes from 'prop-types';

/**
 * NumberFormatter is a formatter which simply passes a number trough, but
 * returns a dash for empty or null values.
 */
export const NumberFormatter = ({ value, style }) => {
  const styles = {
    span: {
      height: '100%',
      ...style,
    },
  };

  return (
    <span className="form-control" style={styles.span}>
      {value === '' || value === null || value === undefined ? '-' : value}
    </span>
  );
};

NumberFormatter.propTypes = {
  value: PropTypes.number,
};
