// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Group, Text } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { closeModal } from '@mantine/modals';
import { FC, useState } from 'react';
import { deleteCustomAttributeValues } from '../../../../Service/restapi/customAttributeService';
import { ToastS } from '../../../../Service/ToastS';

const ConfirmDeleteValuesModal: FC<{ attributeId: string }> = ({ attributeId }) => {
  const { i18n } = useLingui();
  const [loading, setLoading] = useState(false);

  const close = () => closeModal('confirmDeleteValues');

  const deleteValues = () => {
    setLoading(true);
    deleteCustomAttributeValues(attributeId)
      .then((resp) => {
        close();
        if (!resp.ok) {
          ToastS.generalError();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Text>{i18n._('customField.delete.values.confirm')}</Text>
      <Group position="right" mt="md">
        <Button variant="default" onClick={close} disabled={loading}>
          Abbrechen
        </Button>
        <Button onClick={deleteValues} disabled={loading}>
          Ja
        </Button>
      </Group>
    </>
  );
};

export default ConfirmDeleteValuesModal;
