// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Button, Text } from '@mantine/core';
import { IconReceipt } from '@tabler/icons';
import { FC } from 'react';
import { ChargebeeS } from '../../../Service/ChargebeeS';

const MigrateSubscriptionAlert: FC<{ refreshSubscription: () => void }> = ({ refreshSubscription }) => (
  <Alert
    icon={<IconReceipt size={16} />}
    title="Achtung: Du hast Deine Abrechnung noch nicht umgestellt!"
    sx={(theme) => ({ backgroundColor: theme.colors.red[3], color: "black" })}
  >
    <Text mb="md">
      Stelle jetzt unbedingt Deine Abrechnung auf unser neues Abrechnungssystem um.
    </Text>
    <Text mb="md">
      Hinterlege dazu einfach noch einmal Deine Zahlungsdaten, die bei deiner nächsten Abrechnung verwendet werden
      sollen. Jetzt wird also keine Zahlung fällig.
    </Text>
    <Text>Natürlich kannst du Dein Abo weiterhin hier wie gewohnt kündigen.</Text>
    <Button mt="md" onClick={() => ChargebeeS.migrateExistingSubscription(refreshSubscription)}>
      Abrechnung umstellen
    </Button>
  </Alert>
);

export default MigrateSubscriptionAlert;
