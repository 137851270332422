import { FC, useEffect, useState } from 'react';
import { Loader, Select } from '@mantine/core';
import { ChangeEventStatusConfigDTO, WorkflowConfigProps } from '../../../../../Types/LogT';
import { DataS } from '../../../../../Service/DataS';
import AvailabilityIcon from '../../../../../Atoms/AvailabilityIcon';
import BookingState from '../../../../../Types/Enums/BookingState';
import { CustomEventState } from '../../../../../Types/EventT';
import { EventS } from '../../../../../Service/EventS';
import { useJwtClaims } from '../../../../../Service/Context/AuthContext';
import { LicenceS } from '../../../../../Service/LicenceS';

const ChangeEventStatusConfig: FC<WorkflowConfigProps> = ({ config, handleChange }) => {
  const { licence } = useJwtClaims();
  const { bookingState, customStateId } = config as ChangeEventStatusConfigDTO;
  const [eventStates, setEventStates] = useState<CustomEventState[]>([]);
  const [loading, setLoading] = useState(true);
  const customStatesAllowed = LicenceS.hasSuitableLicence('INTERACTIVE', licence);

  const onChange = (val: string | null) => {
    if (val) {
      const customState = eventStates.find((s) => s.id === val);
      // Custom state found ---> ID selected
      if (customState) {
        const { id, originState } = customState;
        handleChange({ customStateId: id, bookingState: originState });
      } else {
        handleChange({ bookingState: val });
      }
    }
  };

  useEffect(() => {
    if (customStatesAllowed) {
      setLoading(true);
      EventS.fetchEventStates()
        .then(setEventStates)
        .finally(() => setLoading(false));
    }
  }, [customStatesAllowed]);

  return (
    <>
      {customStatesAllowed && (
        <Select
          rightSection={loading && <Loader size="xs" />}
          disabled={loading}
          label="Neuer Status"
          defaultValue={customStateId ?? bookingState}
          data={DataS.getEventStateOptionsV2(eventStates)}
          onChange={onChange}
          icon={bookingState && <AvailabilityIcon bookingState={bookingState as BookingState} />}
        />
      )}
      {!customStatesAllowed && (
        <Select
          label="Neuer Status"
          defaultValue={bookingState}
          data={DataS.getBookingStateOptions()}
          onChange={(state) => handleChange({ bookingState: state as BookingState })}
          icon={bookingState && <AvailabilityIcon bookingState={bookingState as BookingState} />}
        />
      )}
    </>
  );
};

export default ChangeEventStatusConfig;
