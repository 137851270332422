// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Loader, Switch, Text } from '@mantine/core';
import { FC, useContext, useState } from 'react';
import { IconUsers } from '@tabler/icons';
import PanePaper from '../../../Molecules/PanePaper';
import { GoogleAccountDTO } from '../../../Types/GoogleT';
import { GoogleS } from '../../../Service/GoogleS';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import { MixpanelS } from '../../../Service/MixpanelS';
import { ConstantS } from '../../../Service/ConstantS';
import ConfigOption from './ConfigOption';
import GoogleScopeIcon from './GoogleScopeIcon';
import MissingScope from './MissingScope';

interface Props {
  googleAccount: GoogleAccountDTO;
  handleChange: (patch: GoogleAccountDTO) => void;
}

const GooglePeoplePane: FC<Props> = ({ googleAccount, handleChange }) => {
  const { id, hasContactScopes, syncContactsAutomatically } = googleAccount;
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const [loading, setLoading] = useState(false);

  const exportAll = () => {
    setLoading(true);
    GoogleS.exportAllContacts(id).finally(() => setLoading(false));
  };

  const toggleAutoSync = () => {
    if (!syncContactsAutomatically) {
      MixpanelS.track(ConstantS.TrackingEvents.GoogleContactsSyncActivated);
    }
    const patch = { syncContactsAutomatically: !syncContactsAutomatically };
    GoogleS.editAccountConfig(id, patch).then((_googleAccount) => {
      if (_googleAccount) {
        handleChange(_googleAccount);
      }
    });
  };

  return (
    <PanePaper
      withoutBorder
      sx={{ backgroundColor: '#F3F3F3' }}
      rightSection={<GoogleScopeIcon accessGranted={hasContactScopes} innerIcon={<IconUsers size={14} />} />}
    >
      <Text size="lg" mb="xl">
        Kontakte
      </Text>
      {!hasContactScopes && <MissingScope />}
      {hasContactScopes && (
        <>
          <ConfigOption
            title="Automatisch synchronisieren"
            description="Synchronisiere alle Kontaktänderungen automatisch mit Google"
            rightSection={
              <Switch
                checked={syncContactsAutomatically}
                onChange={() => executeWithLicenceCheck(LicenceS.Restrictions.GOOGLE_CONTACTS, toggleAutoSync)}
              />
            }
          />
          <ConfigOption
            title="Alles exportieren"
            description="Exportiere alle bookitup-Kontakte in Google"
            rightSection={
              <div>
                {loading ? (
                  <Loader variant="dots" />
                ) : (
                  <Text
                    variant="link"
                    color="blue"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.GOOGLE_CONTACTS, exportAll)}
                  >
                    Export
                  </Text>
                )}
              </div>
            }
          />
        </>
      )}
    </PanePaper>
  );
};

export default GooglePeoplePane;
