import React, {FC, useEffect, useState} from 'react';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from 'chart.js';
import {Bar} from 'react-chartjs-2';
import {fetchEventDocumentsStatistic} from "../../Service/restapi/statisticService";
import {Skeleton, Space, Text} from "@mantine/core";
import PanePaper from "../../Molecules/PanePaper";

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip
);

export const options = {
    plugins: {
        grid: {
            display: false
        },
        legend: {
            display: true,
            position: 'right' as const,
        },
    },
    responsive: true,
};

interface Props {
  year: string | null,
  leadOrigin: string | null,
  eventKind: string | null,
  hasProperLicence: boolean
}

interface StatisticData {
    labels: string[],
    datasets: StatisticDataset[]
}

interface StatisticDataset {
    label: string,
    data: ChartData<'bar'>
}

const ExpectedRevenueStatistic: FC<Props> = ({year, leadOrigin, eventKind, hasProperLicence}) => {
    const [statisticData, setStatisticData] = useState<StatisticData>();

    useEffect(() => {
      if (hasProperLicence) {
        setStatisticData(undefined);
        const abortController = new AbortController();

        fetchEventDocumentsStatistic(year, leadOrigin, eventKind, abortController.signal)
          .then((resp) => {
            if (resp.ok) {
              resp.json().then(setStatisticData);
            } else {
              console.error(`could not load expected revenue statistics`);
            }
          })
          .catch((error) => {
            console.error({ error });
            console.error(`could not load expected revenue statistics`);
          });
        return () => {
          abortController.abort();
        };
      }

    }, [year, leadOrigin, eventKind, hasProperLicence]);



    return (
      <PanePaper
        title="Erwarteter Umsatz"
      >
        <Text>{"Der erwartete Umsatz wird anhand Deiner bestätigen Angebote, sowie Auftragsbestätigungen berechnet."}</Text>
        <Space h="xl" />

        {(!statisticData && hasProperLicence) && <Skeleton radius="sm" height={350} width="100%" />}
        {(statisticData) && <Bar options={options} data={statisticData} />}
      </PanePaper>

    );
}
export default ExpectedRevenueStatistic;
