// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Center, Divider, Loader, Select, Stack, Text, TextInput, Textarea } from '@mantine/core';
import { IconWorldWww, IconPalette, IconWebhook } from '@tabler/icons';
import validator from 'validator';
import { useContext } from 'react';
import { DataS } from '../../../../Service/DataS';
import { ToastS } from '../../../../Service/ToastS';
import SemanticFormStyle from '../../../../Types/Enums/SemanticFormStyle';
import ContactFormStyleOptions from '../ContactFormStyleOptions';
import { BookitupFormContext } from '../../../../Service/Context/BookitupFormContext';
import ChangesDetectedAlert from '../ChangesDetectedAlert';
import {LicenceS} from "../../../../Service/LicenceS";
import {ViolationContext} from "../../../../Service/Context/ViolationContext";

const FormSettings = () => {
  const { bookitupForm, loading, updateForm } = useContext(BookitupFormContext);
  const { executeWithLicenceCheck } = useContext(ViolationContext);

  if (loading || !bookitupForm) {
    return (
      <Center>
        <Loader size="xl" pos="absolute" top="40%" />
      </Center>
    );
  }
  const { name, domain, acknowledgementText, actionButtonText, recaptchaSecret, formStyle, outOfSync, webhookUrl } =
    bookitupForm;

  return (
    <>
      {outOfSync && <ChangesDetectedAlert />}
      <Text weight="bolder" size="lg" mb="md" mt="md">
        Allgemein
      </Text>
      <Stack sx={{ maxWidth: 600 }}>
        <TextInput
          required
          label="Bezeichnung"
          defaultValue={name}
          onBlur={(e) => updateForm({ name: e.currentTarget.value })}
          error={validator.isEmpty(name) && 'Bitte geben Sie einen gültigen Formularnamen an.'}
        />
        <TextInput
          required
          label="Domain"
          defaultValue={domain}
          icon={<IconWorldWww size={20} />}
          placeholder="www.example.com"
          onBlur={(e) => {
            const val = e.currentTarget.value;
            if (validator.isFQDN(val) || val === '') {
              updateForm({ domain: val });
            } else {
              ToastS.error('invalid-domain', 'Bitte trage eine gültige Domain ein.');
            }
          }}
        />
        <TextInput
          label="reCAPTCHA Secret Key (Achtung: nicht DSGVO-konform!)"
          defaultValue={recaptchaSecret}
          onBlur={(e) => updateForm({ recaptchaSecret: e.currentTarget.value })}
        />
        <div>
          <TextInput
            label="Webhook-URL"
            defaultValue={webhookUrl ?? ''}
            icon={<IconWebhook size={20} />}
            onBlur={(e) => {
              const val = e.currentTarget.value;
              if (validator.isURL(val)) {
                executeWithLicenceCheck(LicenceS.Restrictions.CONTACT_FORM_ZAPIER, () => updateForm({ webhookUrl: val }));
              }
              if (val === '') {
                updateForm({ webhookUrl: val })
              } else {
                ToastS.error('invalid-domain', 'Bitte trage eine gültige URL ein.');
              }
            }}
          />
          <Text mt={5} size="xs" color="dimmed">
            z.B. für Zapier-Webhook
          </Text>
        </div>
      </Stack>
      <Divider my={30} />
      <Text weight="bolder" size="lg" mb="md" mt="xl">
        Kundenkontakt
      </Text>
      <Stack sx={{ maxWidth: 600 }}>
        <div>
          <Textarea
            label="Dankestext /-seite"
            defaultValue={acknowledgementText}
            placeholder="Vielen Dank für Ihre Anfrage!"
            onBlur={(e) => updateForm({ acknowledgementText: e.currentTarget.value })}
            minRows={3}
            maxLength={500}
          />
          <Text mt={5} size="xs" color="dimmed">
            In dieses Textfeld kannst Du entweder einen Text einfügen, der nach dem Absenden angezeigt werden soll, oder
            einen Link zu einer Dankesseite, auf die Du Deine Kund:innen weiterleiten möchtest.
          </Text>
        </div>
        <TextInput
          label="Schaltflächentext"
          placeholder="Anfrage senden"
          defaultValue={actionButtonText}
          onBlur={(e) => updateForm({ actionButtonText: e.currentTarget.value })}
        />
      </Stack>
      <Divider my={30} />
      <Text weight="bolder" size="lg" mb="md" mt="xl">
        Stile
      </Text>
      <Select
        mb="xl"
        label="Theme"
        value={formStyle}
        icon={<IconPalette size={20} />}
        placeholder="Formularthema"
        data={DataS.bookitupFormStyleOptions}
        onChange={(val: SemanticFormStyle) => updateForm({ formStyle: val })}
        sx={{ maxWidth: 600 }}
      />
      <ContactFormStyleOptions />
    </>
  );
};

export default FormSettings;
