import { Stack, Text } from '@mantine/core';
import { FC, useContext, useEffect, useState } from 'react';
import { LoadingAnimation } from '../../../Service/ResourceAdapter';
import { fetchTaskGroups } from '../../../Service/restapi/taskService';
import AgendaGroup from '../../../Types/AgendaGroup';
import { EventContext } from '../../../Service/Context/EventContext';
import { TaskGroup } from './TaskGroup';

type Props = {
  entityId?: number;
  entityType: string;
  lastModified: Date;
};

const TaskList: FC<Props> = ({ entityId, entityType, lastModified }) => {
  const [taskGroups, setTaskGroups] = useState<AgendaGroup[]>([]);
  const { taskReloadTrigger } = useContext<any>(EventContext);

  useEffect(() => {
    fetchTaskGroups(entityType)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((tg) => {
            console.log({ taskGroups: tg });
            setTaskGroups(tg);
          });
        } else {
          console.error(`could not load taskLists for entityType ${entityType}`);
        }
      })
      .catch(() => {
        console.error(`could not load taskLists for entityType ${entityType}`);
      });
  }, [entityType, lastModified, taskReloadTrigger]);

  if (!taskGroups) {
    return <LoadingAnimation style={{}} />;
  }

  return (
    <div>
      {taskGroups.length === 0 ? (
        <Text italic color="dimmed" size="xs" weight="lighter">
          Noch nichts hinzugefügt.
        </Text>
      ) : (
        <Stack spacing={5}>
          {taskGroups.map((group) => (
            <TaskGroup key={group.id} group={group} entityId={entityId} lastModified={lastModified} />
          ))}
        </Stack>
      )}
    </div>
  );
};

export default TaskList;
