export const dateFormatService = {
  formatDate,
  getDateOfDeLocale,
  getYearsOfDate,
  getMonthsOfDate,
  getDaysOfDate,
  getHoursOfTime,
  getMinutesOfTime,
  getSecondsOfTime,
  getDateTime,
  getDate,
};

function getDate(dateString) {
  return new Date(
    dateFormatService.getYearsOfDate(dateString),
    dateFormatService.getMonthsOfDate(dateString) - 1,
    dateFormatService.getDaysOfDate(dateString),
  );
}

function getDateTime(dateString, timeString) {
  return new Date(
    dateFormatService.getYearsOfDate(dateString),
    dateFormatService.getMonthsOfDate(dateString) - 1,
    dateFormatService.getDaysOfDate(dateString),
    dateFormatService.getHoursOfTime(timeString),
    dateFormatService.getMinutesOfTime(timeString),
    dateFormatService.getSecondsOfTime(timeString),
  );
}

function getYearsOfDate(dateString) {
  const number = dateString.substr(0, 4);
  return parseInt(number);
}

function getMonthsOfDate(dateString) {
  const number = dateString.substr(5, 2);
  return parseInt(number);
}

function getDaysOfDate(dateString) {
  const number = dateString.substr(8, 2);
  return parseInt(number);
}

function getHoursOfTime(timeString) {
  const hours = timeString.substr(0, 2);
  return parseInt(hours);
}

function getMinutesOfTime(timeString) {
  const minutes = timeString.substr(3, 2);
  return parseInt(minutes);
}

function getSecondsOfTime(timeString) {
  const seconds = timeString.substr(6, 2);
  return parseInt(seconds);
}

function getDateOfDeLocale(germanDateString) {
  const day = germanDateString.substr(0, 2);
  const month = germanDateString.substr(3, 2);
  const year = germanDateString.substr(6, 4);
  return new Date(year, month - 1, day, 2); // set hours to 2
}

function formatDate(date, format, splitChar) {
  if (!splitChar) {
    splitChar = '/';
  }

  const dayPlacement = format.toLowerCase().indexOf('dd');
  const monthPlacement = format.toLowerCase().indexOf('mm');
  const yearPlacement = format.toLowerCase().indexOf('yy');

  const month = date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;

  const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;

  let year = date.getFullYear();
  if (!format.includes('yyyy')) {
    year = year.toString().substring(2, 4);
  }

  let compound;

  if (dayPlacement < monthPlacement) {
    if (monthPlacement < yearPlacement) {
      compound = day + splitChar + month + splitChar + year;
    } else if (dayPlacement < yearPlacement) {
      compound = day + splitChar + year + splitChar + month;
    } else {
      compound = year + splitChar + month + splitChar + day;
    }
  } else if (yearPlacement < monthPlacement) {
    compound = year + splitChar + month + splitChar + day;
  } else {
    compound = month + splitChar + year + splitChar + day;
  }

  return compound;
}
