import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { Box, Group, Text } from '@mantine/core';
import { DocumentTemplateListActions } from '../../../../Molecules/Settings/DocumentTemplates/DocumentTemplateListActions';

const DocumentTemplateItem = ({ template, documentType, reloadCallback }) => {
  const navigate = useNavigate();

  return (
    <Box ml="xs" mt="sm">
      <Group position="apart">
        <Text
          className="overflow-ellipsis"
          sx={{ cursor: 'pointer', color: 'black' }}
          onClick={() => navigate(`/settings/template/document/${documentType.toLowerCase()}/${template.name}`)}
          variant="link"
          size="md"
        >
          {template.name}
        </Text>
        <DocumentTemplateListActions
          templateName={template.name}
          documentType={documentType}
          reloadDocuments={reloadCallback}
        />
      </Group>

      {template.lastModified && (
        <Text size="xs" color="dimmed">
          Bearbeitet <Moment fromNow>{template.lastModified}</Moment>
        </Text>
      )}
    </Box>
  );
};

export default DocumentTemplateItem;
