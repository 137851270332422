/* eslint-disable react/jsx-props-no-spreading */
import { FC, useEffect, useState } from 'react';
import {
  // eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
  Text,
  Paper,
  TextInput,
  PasswordInput,
  Button,
  Space,
  Checkbox,
  SimpleGrid,
  LoadingOverlay,
  Divider,
  Center,
} from '@mantine/core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IconAt, IconLock } from '@tabler/icons';
import validator from 'validator';
import { useMediaQuery } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { i18n } from '@lingui/core';
import ReactPixel from 'react-facebook-pixel';
import { AuthS } from '../../Service/restapi/authService';
import { RoutingS } from '../../Service/RoutingS';
import { AlertS } from '../../Service/AlertS';
import { RecruitmentS } from '../../Service/restapi/recruitmentService';
import { ValidationS } from '../../Service/ValidationS';
import { ConstantS } from '../../Service/ConstantS';
import PasswordChecker from './PasswordChecker';
import AlertBox from './AlertBox';
import GoogleAuthButton from './GoogleAuthButton';
import {MixpanelS} from "../../Service/MixpanelS";
import ReactGA from 'react-ga4';
import {CookiesS} from "../../Service/CookiesS";

const RegisterPage: FC = () => {
  const isPhoneDevice = useMediaQuery('(max-width: 480px)');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [validPassword, setValidPassword] = useState<boolean>(false);
  const [recruitmentCampaignId, setRecruitmentCampaignId] = useState<string>();
  const [recruiterAlias, setRecruiterAlias] = useState<string>();
  const [campaignId, setCampaignId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>();

  const form = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      repeatedPassword: '',
      termsAccepted: false,
    },
    validate: {
      firstName: (value) => {
        if (ValidationS.validAwsObjectKeyName(value)) {
          return value.length > 0 ? null : 'Bitte geben Sie den Vornamen ein';
        }
        return i18n._('error.input-field.invalid-aws-char');
      },
      lastName: (value) => {
        if (ValidationS.validAwsObjectKeyName(value)) {
          return value.length > 0 ? null : 'Bitte geben Sie den Nachnamen ein';
        }
        return i18n._('error.input-field.invalid-aws-char');
      },
      email: (value) => (validator.isEmail(value) ? null : 'Bitte gib eine valide E-Mail-Adresse ein'),
      password: () => (validPassword ? null : 'Bitte geben Sie ein gültiges Passwort ein'),
      repeatedPassword: (value, values) => (value === values.password ? null : 'Passwörter stimmen nicht überein'),
      termsAccepted: (value: boolean) => (value === true ? null : 'Dieses Feld ist erforderlich'),
    },
  });

  useEffect(() => {
    document.title = 'Registrieren';
    const emailParam = searchParams.get('email');
    const campaignParam = searchParams.get('campaign');
    const campaignIdParam = searchParams.get('hsCtaTracking');
    if (emailParam) {
      form.setFieldValue('email', emailParam);
    }
    if (campaignIdParam) {
      setCampaignId(campaignIdParam);
    }
    if (campaignParam) {
      setRecruitmentCampaignId(campaignParam);
    }
    // Execute with timeout to ensure that pixel is initialized
    setTimeout(() => ReactGA.event({ category: 'User', action: ConstantS.TrackingEvents.RegisterPageOpened }), 2000);
    setTimeout(() => MixpanelS.track(ConstantS.TrackingEvents.RegisterPageOpened), 2000);
    setTimeout(() => ReactPixel.track(ConstantS.TrackingEvents.ViewContent), 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (recruitmentCampaignId) {
      RecruitmentS.getRecruitmentCampaignProfileAlias(recruitmentCampaignId).then(
        (alias) => alias && setRecruiterAlias(alias),
      );
    }
  }, [recruitmentCampaignId]);

  const signUp = () => {
    setLoading(true);
    const { firstName, lastName, email, password } = form.values;
    const loxLaunch = searchParams.get('loxLaunch') !== null;
    let firstPromoterTrackingId = CookiesS.getCookiePayload("_fprom_tid");
    AuthS.signup({ firstName, lastName, email, password, recruitmentCampaignId, campaignId, expandedTrial: loxLaunch, promoterTrackingId: firstPromoterTrackingId})
      .then((res) => {
        if (res.ok) {
          navigate(RoutingS.ROUTES.REGISTRATION_COMPLETED);
        } else if (res.status === 409) {
          setAlert(AlertS.AlertType.EMAIL_TAKEN);
        } else {
          setAlert(AlertS.AlertType.UNEXPECTED_ERROR);
        }
      })
      .catch(() => setAlert(AlertS.AlertType.SERVER_UNAVAILABLE))
      .finally(() => setLoading(false));
  };

  return (
    <Paper
      shadow="xs"
      p={40}
      sx={{
        maxWidth: 480,
        borderRadius: 10,
        position: 'relative',
        backgroundClip: 'padding-box, border-box',
        backgroundImage: 'linear-gradient(#fff,#fff),linear-gradient(88deg,#ffba5e,#ff6b69)',
        borderTop: '10px double transparent',
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Space h="md" />
      <Text size={isPhoneDevice ? 22 : 28}>YEAH! Gute Entscheidung.</Text>
      <Space h="xs" />
      <Text color="dimmed" size={isPhoneDevice ? 'sm' : 'md'}>
        Es wird Zeit, Deinen Verwaltungsaufwand zu halbieren.
      </Text>
      {recruiterAlias && <Text mt="xs">{recruiterAlias} lädt Dich dazu ein bookitup zu testen!</Text>}
      <Space h="md" />
      {alert && <AlertBox alert={alert} />}
      <form onSubmit={form.onSubmit(signUp)}>
        <SimpleGrid cols={2}>
          <TextInput label={<Text size="xs">Vorname</Text>} {...form.getInputProps('firstName')} />
          <TextInput label={<Text size="xs">Nachname</Text>} {...form.getInputProps('lastName')} />
        </SimpleGrid>
        <Space h="xs" />
        <TextInput label={<Text size="xs">E-Mail</Text>} icon={<IconAt size={14} />} {...form.getInputProps('email')} />
        <Space h="xs" />
        <PasswordInput
          label={<Text size="xs">Passwort</Text>}
          icon={<IconLock size={16} />}
          {...form.getInputProps('password')}
        />
        <Space h="xs" />
        <PasswordChecker
          password={form.values.password}
          showErrors={Object.keys(form.errors).length > 0}
          setValidPassword={setValidPassword}
        />
        <Space h="xs" />
        <PasswordInput
          label={<Text size="xs">Passwort wiederholen</Text>}
          icon={<IconLock size={16} />}
          {...form.getInputProps('repeatedPassword')}
        />
        <Space h="md" />
        <Checkbox
          label={
            <Text size="xs">
              Ich habe die{' '}
              <a target="_blank" href="https://bookitup.de/wp-content/uploads/2023/05/bookitup-agb-1.pdf">
                allgemeinen Geschäftsbedingungen
              </a>{' '}
              von bookitup gelesen und akzeptiere diese. Es gelten die Datenschutzbestimmungen von bookitup.
            </Text>
          }
          {...form.getInputProps('termsAccepted', { type: 'checkbox' })}
        />
        {form.errors.termsAccepted && (
          <Text color="red" size="xs">
            {' '}
            Dieses Feld ist erforderlich{' '}
          </Text>
        )}
        <Button fullWidth mt={40} type="submit">
          kostenlos registrieren
        </Button>
      </form>
      <Space h="md" />
      <Text color="blue" align="center" sx={{ cursor: 'pointer' }} onClick={() => navigate(RoutingS.ROUTES.LOGIN)}>
        Anmelden
      </Text>
      <Space h="md" />
      <Divider my="xs" label="weitere Möglichkeiten" labelPosition="center" />
      <Center>
        <GoogleAuthButton
          text="signup_with"
          setAlert={setAlert}
          setLoading={setLoading}
          recruitmentCampaignId={recruitmentCampaignId}
          campaignId={campaignId}
        />
      </Center>
      <Space h="xl" />
    </Paper>
  );
};

export default RegisterPage;
