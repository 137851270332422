import { FC } from 'react';
import { Text } from '@mantine/core';
import { i18n } from '@lingui/core';
import { ShareEntityConfigDTO } from '../../../../../Types/LogT';

const ShareEntity: FC<{ config: unknown }> = ({ config }) => {
  const { entityReference, portalReference, useExistingPortal } = config as ShareEntityConfigDTO;

  if (!entityReference || (!portalReference && !useExistingPortal)) {
    return <Text color="red">Ungültige Konfiguration</Text>;
  }
  return (
    <Text>
      <Text span weight="bolder">
        {' '}
        {i18n._(`resource.entityType.${entityReference.entityType}`)}
      </Text>{' '}
      über Online-Portal teilen.
    </Text>
  );
};

export default ShareEntity;
