// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { openModal } from '@mantine/modals';
import {FC, useContext} from 'react';
import {ResourceAddonContext} from "../../../Service/Context/ResourceAddonsContext";
import {ViolationContext} from "../../../Service/Context/ViolationContext";
import BookitupAddIcon from "../../../Atoms/BookitupAddIcon";
import CreateResourcePositionModal from "./CreateResoucePositionModal";
import {useParams} from "react-router-dom";
import {ProfileContext} from "../../../Service/Context/ProfileContext";
import {LicenceS} from "../../../Service/LicenceS";

const AddPositionResourceItem: FC<{ onSave: () => void }> = ({ onSave }) => {
  const { id: resourceId } = useParams();
  const { executeWithCheck } = useContext(ResourceAddonContext);
  const { interceptViolation } = useContext(ViolationContext);
  const { profile } = useContext<any>(ProfileContext);
  const { executeWithLicenceCheck } = useContext(ViolationContext);

  if (!resourceId) {
      return null;
  }

  const addResource = () => {
    executeWithCheck(() =>
      openModal({
        size: 'sm',
        title: (
          <Text weight="bolder" size="xl">
            Produkt erstellen
          </Text>
        ),
        closeOnEscape: true,
        transition: 'slide-down',
        children: <CreateResourcePositionModal interceptViolation={interceptViolation} resourceId={resourceId} onSave={onSave} isSmallScaleBusiness={profile.isSmallScaleBusiness} />,
      }),
    );
  };

  return  <BookitupAddIcon onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.PRODUCT_RESOURCE, addResource)} />;
};

export default AddPositionResourceItem;
