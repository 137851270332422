import {Anchor, Box, Breadcrumbs, Select, Space} from '@mantine/core';
import React, {FC, useContext, useEffect, useState} from 'react';
import {WindowContext} from '../../Service/Context/WindowContext';
import {EventS} from "../../Service/EventS";
import {EventKind} from "../../Types/EventT";

const StatisticsNavBar: FC<{ year: string | null, setYear: (year: string | null) => void , eventKind: string | null, setEventKind: (eventKind: string | null) => void, leadOrigin: string | null, setLeadOrigin: (leadOrigin: string | null) => void }> = ({ year, setYear, eventKind, setEventKind, leadOrigin, setLeadOrigin}) => {
  const { sm } = useContext(WindowContext);
  const [eventKinds, setEventKinds] = useState<EventKind[]>();
  const existingKinds = eventKinds?.map((k) => k.name);
  const [leadOrigins, setLeadOrigins] = useState<string[]>();

  useEffect(() => {
    EventS.fetchEventKinds()
      .then(setEventKinds)
      .catch((error) => {
        console.error({ error });
        console.error(`could not load event kinds`);
      });

    EventS.fetchLeadOrigins()
      .then(setLeadOrigins)
      .catch((error) => {
        console.error({ error });
        console.error(`could not load lead origins`);
      });  }, []);


  return (
    <>
      {!sm && (
        <Breadcrumbs mt={5} mb={10}>
          <Anchor size="xl" variant="text" p="md" weight="bolder">
            Statistiken
          </Anchor>
        </Breadcrumbs>
      )}
      <Box p="xs">
        <Select
          variant="filled"
          placeholder={"Jahr"}
          clearable={false}
          value={year}
          onChange={(val) => setYear(val)}
          mb="md"
          data={getYears()}
        />

        {existingKinds && <Select
          variant="filled"
          value={eventKind}
          placeholder={"Auftragsart"}
          clearable={true}
          onChange={(val) => setEventKind(val)}
          mb="md"
          data={existingKinds}
        />}

        {leadOrigins && <Select
          variant="filled"
          value={leadOrigin}
          placeholder={"Aufmerksam geworden"}
          clearable={true}
          onChange={(val) => setLeadOrigin(val)}
          mb="md"
          data={leadOrigins}
        />}
      </Box>
      <Space h="md" />
    </>
  );
};

const getYears = () => [
  { value: '2025', label: '2025' },
  { value: '2024', label: '2024' },
  { value: '2023', label: '2023' },
  { value: '2022', label: '2022' },
  { value: '2021', label: '2021' },
  { value: '2020', label: '2020' },
];

export default StatisticsNavBar;
