// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { FC, useContext } from 'react';
import DocumentTemplatePreview from '../../Molecules/Document/DocumentTemplatePreview';
import { QuestionnaireContext } from '../../Service/Context/QuestionnaireContext';

const QuestionnairePreview: FC = () => {
  const { questionnaire } = useContext<any>(QuestionnaireContext);
  const { id } = questionnaire;

  return (
    <DocumentTemplatePreview
      portalSupported
      entityType="questionnaires"
      entity={questionnaire}
      url={`${process.env.REACT_APP_QUESTIONNAIRE_SERVICE_URL}/questionnaires/${id}/pdf`}
    />
  );
};

export default QuestionnairePreview;
