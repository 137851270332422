/* eslint-disable react/jsx-props-no-spreading */
import {
  Badge,
  Button,
  Center,
  Checkbox,
  Group,
  Loader,
  LoadingOverlay,
  Paper,
  Radio,
  Select,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { i18n } from '@lingui/core';
import { IconCalendarEvent, IconCalendarTime, IconChevronDown, IconClock, IconTemplate } from '@tabler/icons';
import { DatePicker, TimeInput } from '@mantine/dates';
import { startOfTomorrow, addDays, addHours } from 'date-fns/esm';
import { useParams } from 'react-router-dom';
import { DocumentRequestDTO, MessageDTO, MessageTemplateDto, ScheduleMailDto } from '../../Types/MessageT';
import { ScheduledMailS } from '../../Service/ScheduleMailS';
import { MessageS } from '../../Service/MessageS';
import { DataS } from '../../Service/DataS';
import { ToastS } from '../../Service/ToastS';
import { DocumentS } from '../../Templates/Documents/DocumentS';
import SkeletonItems from '../../Atoms/SkeletonItems';
import MessageTemplateSelectDocuments from '../../Molecules/Message/MessageTemplateSelectDocuments';
import { MessageContext } from '../../Service/Context/MessageContextV2';
import { EventParticipationsContext } from '../../Service/Context/EventParticipationContext';
import moment from 'moment';

export const LoadTemplateModal: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { id: eventId } = useParams();
  const [templates, setTemplates] = useState<MessageTemplateDto[]>([]);
  const { mainCustomerId } = useContext<any>(EventParticipationsContext);
  const { message, initMessage, changeMessage } = useContext(MessageContext);
  const [selectedTemplate, selectTemplate] = useState<MessageTemplateDto>();
  const [documentRequests, setDocumentRequests] = useState<DocumentRequestDTO[]>([]);
  const [selectedDocuments, setSelectedDocuments] = useState(new Map());
  const [disableApply, setDisableApply] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const [finishing, setFinishing] = useState(false);
  const [overrideSubject, setOverrideSubject] = useState(true);
  const [overrideRecipient, setOverrideRecipient] = useState(true);
  const documentBlueprints = selectedTemplate?.documentBlueprints ?? [];

  useEffect(() => {
    setLoading(true);
    MessageS.fetchTemplates()
      .then(setTemplates)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (selectedTemplate && eventId) {
      if (documentBlueprints.length > 0) {
        setLoadingDocuments(true);
        DocumentS.exchangeExistingDocuments(eventId, documentBlueprints)
          .then(setDocumentRequests)
          .finally(() => setLoadingDocuments(false));
      }
    }
  }, [selectedTemplate, eventId]);

  const setTemplate = (id: string) => selectTemplate(templates.find((t) => t.id === Number(id)));

  const onDocumentRequestSelected = (documentRequest: DocumentRequestDTO, index: number) => {
    if (documentRequest === undefined) {
      selectedDocuments.delete(index);
    } else {
      selectedDocuments.set(index, documentRequest);
    }
  };

  const finish = async () => {
    if (selectedTemplate) {
      setFinishing(true);
      const { messageType, name } = selectedTemplate;
      const messageTemplate: Partial<MessageDTO> | null = await MessageS.createMessageFromTemplate(
        messageType,
        name,
        mainCustomerId,
        selectedDocuments as any,
      );
      if (messageTemplate) {
        if (message) {
          // Preserve fields based on the configuration
          if (!overrideSubject && message.subject) {
            messageTemplate.subject = message.subject;
          }
          if (!overrideRecipient && message.to) {
            messageTemplate.to = message.to;
          }
          await MessageS.deleteDraft(message.id);
          changeMessage(null);
          setFinishing(false);
        }
        initMessage(messageTemplate);
        onClose();
      }
    }
  };

  return (
    <>
      <Badge mb="md" radius="sm" sx={{ backgroundColor: '#585858', color: 'white' }}>
        <Text weight="lighter">Vorlage</Text>
      </Badge>
      <Select
        required
        searchable
        icon={<IconTemplate size={20} />}
        rightSection={loading ? <Loader size="xs" /> : <IconChevronDown size={14} />}
        disabled={loading}
        data={DataS.getMessageTemplateOptions(templates, i18n)}
        onChange={setTemplate}
        value={selectedTemplate ? selectedTemplate.id.toString() : null}
        styles={{ rightSection: { pointerEvents: 'none' } }}
        rightSectionWidth={25}
        maxDropdownHeight={400}
        limit={100}
      />

      {selectedTemplate && (
        <>
          {message && (
            <Group mt="sm">
              <Checkbox
                label="Betreff überschreiben"
                checked={overrideSubject}
                onChange={() => setOverrideSubject(!overrideSubject)}
              />
              <Checkbox
                label="Empfänger überschreiben"
                checked={overrideRecipient}
                onChange={() => setOverrideRecipient(!overrideRecipient)}
              />
            </Group>
          )}
          <Badge mb="md" mt="xl" radius="sm" sx={{ backgroundColor: '#585858', color: 'white' }}>
            <Text weight="lighter"> {i18n._('email.template.select.attachments.head')}</Text>
          </Badge>
          {loadingDocuments && <SkeletonItems height={50} count={3} />}
          {!loadingDocuments && (
            <>
              {documentBlueprints.length === 0 && (
                <Text italic color="dimmed">
                  In dieser E-Mail-Vorlage sind keine Anhänge vorgesehen.
                </Text>
              )}
              {documentBlueprints.length > 0 && (
                <>
                  <Text mb="sm">Vorgesehende Anhänge</Text>
                  <Stack spacing="xs">
                    {documentBlueprints.map((documentBlueprint, idx) => (
                      <Paper p="xs" shadow="sm">
                        <MessageTemplateSelectDocuments
                          documentBlueprint={documentBlueprint}
                          documentRequests={documentRequests}
                          onDocumentRequestSelected={(documentRequest: DocumentRequestDTO) =>
                            onDocumentRequestSelected(documentRequest, idx)
                          }
                          setDisableApply={setDisableApply}
                        />
                      </Paper>
                    ))}
                  </Stack>
                </>
              )}
            </>
          )}
        </>
      )}

      <Center mt={30}>
        <Button
          disabled={!selectedTemplate || disableApply || finishing}
          sx={{ width: 200 }}
          onClick={finish}
          leftIcon={finishing && <Loader size="xs" />}
        >
          {i18n._('actions.finish')}
        </Button>
      </Center>
    </>
  );
};

interface UpdateTemplateProps {
  email: MessageDTO;
}

export const openUpdateTemplateModal = (props: UpdateTemplateProps) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Vorlagenaktualisierung
      </Text>
    ),
    children: <UpdateTemplateModal {...props} />,
  });
};

const UpdateTemplateModal: FC<UpdateTemplateProps> = ({ email }) => {
  const [templateId, setTemplateId] = useState<number>();
  const [templates, setTemplates] = useState<MessageTemplateDto[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    MessageS.fetchTemplates()
      .then(setTemplates)
      .finally(() => setLoading(false));
  }, []);

  const update = async () => {
    if (templateId) {
      const selectedTemplate = templates.find((t) => t.id === templateId);
      if (selectedTemplate) {
        const { messageType, name } = selectedTemplate;
        const { subject, message } = email;
        setLoading(true);
        const template = await MessageS.updateTemplate(messageType, { subject, message }, name);
        setLoading(false);
        if (template) {
          ToastS.success('template-updated', 'Vorlage erfolgreich aktualisiert');
          closeAllModals();
        }
      }
    }
  };

  return (
    <>
      <Select
        icon={<IconTemplate size={20} />}
        label="Vorlage"
        data={DataS.getMessageTemplateOptions(templates, i18n)}
        onChange={(id) => id && setTemplateId(Number(id))}
      />
      <Center mt="xl">
        <Button
          onClick={update}
          leftIcon={loading && <Loader size="xs" />}
          disabled={loading || !templateId}
          sx={{ width: 200 }}
        >
          {i18n._('actions.save')}
        </Button>
      </Center>
    </>
  );
};

interface CreateTemplateProps {
  messageId: number;
}

export const openCreateTemplateModal = (props: CreateTemplateProps) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Als Vorlage speichern unter
      </Text>
    ),
    children: <CreateTemplateModal {...props} />,
  });
};

const CreateTemplateModal: FC<CreateTemplateProps> = ({ messageId }) => {
  const [templateName, setTemplateName] = useState('');
  const [templates, setTemplates] = useState<MessageTemplateDto[]>([]);
  const [loading, setLoading] = useState(false);
  const existingNames = useMemo(
    () => templates.filter((t) => t.messageType === 'CUSTOM').map((t) => t.name),
    [templates],
  );
  const conflict = existingNames.includes(templateName);

  useEffect(() => {
    setLoading(true);
    MessageS.fetchTemplates()
      .then(setTemplates)
      .finally(() => setLoading(false));
  }, []);

  const create = async () => {
    setLoading(true);
    const template = await MessageS.createTemplateFromMessage(templateName, messageId);
    setLoading(false);
    if (template) {
      closeAllModals();
    }
  };

  return (
    <>
      <TextInput
        icon={<IconTemplate size={20} />}
        label="Name der Vorlage"
        value={templateName}
        onChange={(e) => setTemplateName(e.currentTarget.value)}
        error={conflict && 'Name ist bereits vergeben'}
      />
      <Center mt="xl">
        <Button
          onClick={create}
          leftIcon={loading && <Loader size="xs" />}
          disabled={templateName === '' || loading || conflict}
          sx={{ width: 200 }}
        >
          {i18n._('actions.apply')}
        </Button>
      </Center>
    </>
  );
};

interface ScheduleMessageProps {
  message: MessageDTO;
  onCreate: (mail: ScheduleMailDto) => unknown;
  onDelete: () => void;
}

export const openScheduleMessageModal = (props: ScheduleMessageProps) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl" align="center">
        E-Mail planen
      </Text>
    ),
    children: <ScheduleMailModal {...props} />,
  });
};

const ScheduleMailModal: FC<ScheduleMessageProps> = ({ message, onCreate, onDelete }) => {
  const { id: chatMessageId } = message;
  const [dateTime, setDateTime] = useState<Date>(startOfTomorrow());
  const [loading, setLoading] = useState(false);
  const [scheduledMail, setScheduledMail] = useState<ScheduleMailDto | null>(null);
  const [value, setValue] = useState<string>();
  const { id: scheduledMailId } = scheduledMail ?? {};

  useEffect(() => {
    setLoading(true);
    ScheduledMailS.fetchByMessageId(chatMessageId)
      .then(setScheduledMail)
      .finally(() => setLoading(false));
  }, [chatMessageId]);

  useEffect(() => {
    if (scheduledMail) {
      const { scheduledTime } = scheduledMail;
      setDateTime(new Date(scheduledTime));
    }
  }, [scheduledMail]);

  const updateDateFrom = (val: Date) => {
    setDateTime(new Date(val.getFullYear(), val.getMonth(), val.getDate(), dateTime.getHours(), dateTime.getMinutes()));
  };

  const updateTimeFrom = (val: Date) => {
    setDateTime(
      new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate(), val.getHours(), val.getMinutes()),
    );
  };

  const getScheduledTime = () => {
    const now = new Date();
    if (value === 'HOUR') {
      return addHours(now, 1);
    }
    if (value === 'TWO_HOUR') {
      return addHours(now, 2);
    }
    if (value === 'TOMORROW_MORNING') {
      const tomorrow = addDays(now, 1);
      tomorrow.setHours(8);
      tomorrow.setMinutes(0);
      return tomorrow;
    }
    if (value === 'TOMORROW_AFTERNOON') {
      const tomorrow = addDays(now, 1);
      tomorrow.setHours(13);
      tomorrow.setMinutes(0);
      return tomorrow;
    }
    return dateTime;
  };

  const deleteHandler = async () => {
    setLoading(true);
    const deleted = await ScheduledMailS.deleteScheduledMail(chatMessageId);
    if (deleted) {
      onDelete();
      setLoading(false);
      closeAllModals();
    }
  };

  const scheduleMail = async () => {
    setLoading(true);
    const resJSON = await ScheduledMailS.scheduleMail(chatMessageId, getScheduledTime(), scheduledMailId);
    setLoading(false);
    if (resJSON) {
      onCreate(resJSON);
      closeAllModals();
    }
  };

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      {scheduledMail && (
        <>
          <Text size="md" weight="bold">
            {`Senden geplant für ${moment(scheduledMail.scheduledTime).fromNow()}`}
          </Text>
          <Text mt={5} mb="xl" color="red" variant="link" sx={{ cursor: 'pointer' }} onClick={deleteHandler}>
            - Sendevorgang abbrechen
          </Text>
        </>
      )}
      <Radio.Group
        withAsterisk
        value={value}
        onChange={setValue}
        spacing={5}
        size="sm"
        name="scheduledTime"
        orientation="vertical"
        label="Später senden"
      >
        {DataS.scheduleMailOptions.map((opt) => (
          // eslint-disable-next-line react/jsx-key
          <Radio value={opt.value} label={opt.label} />
        ))}
      </Radio.Group>
      <Group mt="xs" spacing={5} position="center">
        <DatePicker
          disabled={value !== 'CUSTOM'}
          icon={<IconCalendarEvent size={16} />}
          clearable={false}
          value={dateTime}
          onChange={(val) => val && updateDateFrom(val)}
          variant="filled"
          minDate={new Date()}
        />
        <TimeInput
          disabled={value !== 'CUSTOM'}
          icon={<IconClock size={16} />}
          onChange={updateTimeFrom}
          value={dateTime}
          variant="filled"
        />
      </Group>
      <Center mt="xl">
        <Button
          leftIcon={<IconCalendarTime size={16} />}
          disabled={loading || !value}
          onClick={scheduleMail}
          sx={{ width: 170 }}
        >
          {i18n._('actions.schedule')}
        </Button>
      </Center>
    </>
  );
};

interface DeleteDraftModalProps {
  draftId: number;
  onDelete: () => unknown;
}

export const openDeleteDraftModal = (props: DeleteDraftModalProps) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        {i18n._('common.discard.email.confirm.head')}
      </Text>
    ),
    children: <DeleteDraftModal {...props} />,
  });
};

const DeleteDraftModal: FC<DeleteDraftModalProps> = ({ draftId, onDelete }) => {
  const [deleting, setDeleting] = useState(false);

  const deleteDraft = async () => {
    setDeleting(true);
    const deleted = await MessageS.deleteDraft(draftId);
    setDeleting(false);
    if (deleted) {
      onDelete();
      closeAllModals();
    }
  };
  return (
    <>
      <Text>{i18n._('common.discard.email.confirm')}</Text>
      <Group position="right" spacing="xs" mt="xl">
        <Button color="gray" variant="subtle" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          disabled={deleting}
          onClick={deleteDraft}
          leftIcon={deleting && <Loader size="xs" />}
          color="red"
          variant="subtle"
          sx={{ backgroundColor: '#FFEFEF' }}
        >
          {i18n._('actions.delete')}
        </Button>
      </Group>
    </>
  );
};

interface DeleteEmailsModalProps {
  ids: number[];
  onDelete: () => unknown;
}

export const openDeleteEmailsModal = (props: DeleteEmailsModalProps) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        E-Mails entfernen
      </Text>
    ),
    children: <DeleteEmailsModal {...props} />,
  });
};

const DeleteEmailsModal: FC<DeleteEmailsModalProps> = ({ ids, onDelete }) => {
  const [deleting, setDeleting] = useState(false);

  const deleteEmails = async () => {
    const fn: () => Promise<boolean> = window.location.pathname.endsWith('trash')
      ? () => MessageS.deleteEmails(ids)
      : () => MessageS.moveToTrash(ids);

    setDeleting(true);
    const deleted = await fn();
    setDeleting(false);
    if (deleted) {
      onDelete();
      closeAllModals();
    }
  };
  return (
    <>
      <Text>
        Möchtest Du wirklich{' '}
        <Text span weight="bolder">
          {' '}
          {ids.length}{' '}
        </Text>
        E-Mails löschen?
      </Text>
      <Group position="right" spacing="xs" mt="xl">
        <Button color="gray" variant="subtle" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          disabled={deleting}
          onClick={deleteEmails}
          leftIcon={deleting && <Loader size="xs" />}
          color="red"
          variant="subtle"
          sx={{ backgroundColor: '#FFEFEF' }}
        >
          {i18n._('actions.delete')}
        </Button>
      </Group>
    </>
  );
};

interface EditTemplateModalProps {
  template: MessageTemplateDto;
  onFinish: (template: MessageTemplateDto) => void;
  existingNames: string[] | null;
}

export const openEditTemplateModal = (props: EditTemplateModalProps) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Vorlage bearbeiten
      </Text>
    ),
    children: <EditTemplateModal {...props} />,
  });
};

const EditTemplateModal: FC<EditTemplateModalProps> = ({ template, onFinish, existingNames: initialExistingNames }) => {
  const { name: initialName } = template;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(initialName);
  const [existingNames, setExistingNames] = useState<string[] | null>(initialExistingNames);
  const isNameTaken = initialName !== name && existingNames !== null && existingNames.includes(name);

  useEffect(() => {
    if (existingNames === null) {
      MessageS.fetchTemplatesNames().then(setExistingNames);
    }
  }, [existingNames]);

  const updateTemplate = async () => {
    setLoading(true);
    const message = await MessageS.updateTemplate('CUSTOM', { name }, initialName);
    setLoading(true);
    if (message) {
      onFinish({ ...template, name });
      closeAllModals();
    } else {
      ToastS.generalError();
    }
  };

  return (
    <>
      <TextInput
        value={name}
        label="Name"
        onChange={(e) => setName(e.currentTarget.value)}
        icon={<IconTemplate size={20} />}
        disabled={loading}
        rightSection={loading && <Loader size="xs" />}
        error={isNameTaken && 'Name ist bereits vergeben'}
      />
      <Center mt="xl">
        <Button
          disabled={name === '' || name === initialName || isNameTaken || loading}
          onClick={updateTemplate}
          rightIcon={loading && <Loader size="xs" />}
          sx={{ minWidth: 250 }}
        >
          {i18n._('actions.edit')}
        </Button>
      </Center>
    </>
  );
};
