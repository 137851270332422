import { useEffect, useState } from 'react';
import { EditableTaskProperty } from '../../../Atoms/EditableProperties/EditableTaskProperty';
import EditableTaskText from '../../../Atoms/inputs/EditableTaskText';

export const TaskField = ({ task, entityId }) => {
  const { name, completed, lastModified } = task;
  const [value, setValue] = useState(completed);

  useEffect(() => {
    setValue(task.completed);
  }, [task]);

  return (
    <EditableTaskProperty
      entityId={entityId}
      handleChange={(val) => {
        setValue(val.completed);
      }}
      value={{ completed: value, lastModified }}
      task={task}
      placeholder={name}
      editFormatter={EditableTaskText}
      editOnly
    />
  );
};
