import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EventContext } from '../../Service/Context/EventContext';
import { EditableProperty } from './EditableProperty';

/*
using:
<EditableEventProperty patchField="phoneNumber"/>
 */
export const EditableEventProperty = ({ patchField, placeholder, editFormatter, labelFormatter, ...rest }) => {
  const { event, handleChange } = useContext(EventContext);

  return (
    <EditableProperty
      dto={event}
      changeHandler={handleChange}
      patchField={patchField}
      placeholder={placeholder}
      targetUrl={`${process.env.REACT_APP_EVENT_SERVICE_URL}/events/${event.id}`}
      editFormatter={editFormatter}
      labelFormatter={labelFormatter}
      {...rest}
    />
  );
};

EditableEventProperty.propTypes = {
  patchField: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
};
