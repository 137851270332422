// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Flex, Group, Stack, Text, Timeline, Tooltip } from '@mantine/core';
import { useContext } from 'react';
import moment from 'moment';
import { IconSettingsAutomation } from '@tabler/icons';
import PanePaper from '../../Molecules/PanePaper';
import { BookitupNotification } from '../../Types/LogT';
import LogbookNotification from '../../Molecules/Notifications/LogbookNotificationContent';
import { NotificationSkeletonItems } from '../../Atoms/SkeletonItems';
import NotificationOptions from '../../Molecules/Notifications/NotificationOptions';
import BookitupNotificationIcon from '../../Atoms/BookitupNotificationIcon';
import { EventContext } from '../../Service/Context/EventContext';
import EmptyText from '../../Atoms/EmptyText';

const EventLogbook = () => {
  const { loadingNotifications, notifications, removeNotification } = useContext<any>(EventContext);
  const empty = !loadingNotifications && notifications.length === 0;

  return (
    <PanePaper title="Logbuch" tooltip="help.event.log.pane">
      {loadingNotifications && <NotificationSkeletonItems />}
      {empty && <EmptyText />}
      {!empty && (
        <Timeline mt="md" bulletSize={32} lineWidth={3}>
          {notifications.map((notification: BookitupNotification) => (
            <Timeline.Item key={notification.id} bullet={<BookitupNotificationIcon notification={notification} />}>
              <Flex ml="sm">
                <Stack spacing={0} sx={{ flexGrow: 1 }}>
                  <LogbookNotification notification={notification} />
                  <Group spacing={5}>
                    <Text size="xs" color="blue">
                      {moment(notification.createdOn).calendar()}
                    </Text>
                    {notification.automated && (
                      <Tooltip withArrow position="right" label="Erstellt durch Automation." transition="slide-right">
                        <div>
                          <IconSettingsAutomation size={20} />
                        </div>
                      </Tooltip>
                    )}
                  </Group>
                </Stack>
                <NotificationOptions notification={notification} onDelete={() => removeNotification(notification)} />
              </Flex>
            </Timeline.Item>
          ))}
        </Timeline>
      )}
    </PanePaper>
  );
};

export default EventLogbook;
