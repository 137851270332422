// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Center, List, Space, Text, ThemeIcon } from '@mantine/core';
import { IconCheck, IconCircleCheck, IconFlame } from '@tabler/icons';
import { FC, useContext } from 'react';
import PanePaper from '../../../Molecules/PanePaper';
import { ChargebeeS } from '../../../Service/ChargebeeS';
import { AuthContext, Licence } from '../../../Service/Context/AuthContext';
import { BillingInterval, BillingPlan } from '../../../Types/Billing';
import { ResourceAddonContext } from '../../../Service/Context/ResourceAddonsContext';

interface Props {
  billingPlan: BillingPlan;
  billingInterval: BillingInterval;
  currentLicence: Licence;
}

const Plan: FC<Props> = ({ billingPlan, billingInterval, currentLicence }) => {
  const { refreshToken } = useContext(AuthContext);
  const { refresh: refreshAddon } = useContext(ResourceAddonContext);
  const { name, internalName, free, price, topFeaturesText, features, licence, popular, topFeaturesComponent } =
    billingPlan;

  const subscribe = () => {
    const planId = `${internalName}-EUR-${billingInterval}`;
    const onSuccess = () => {
      refreshToken();
      refreshAddon();
    };
    ChargebeeS.openHostedCheckout(planId, onSuccess, billingPlan);
  };

  return (
    <PanePaper
      withShadow
      withoutBorder
      sx={{
        maxWidth: 250,
      }}
    >
      <Space h="md" />
      <Text align="center" weight="bold" size={28}>
        {popular && (
          <ThemeIcon variant="gradient">
            <IconFlame />
          </ThemeIcon>
        )}
        <Text span ml={popular ? 'sm' : 0}>
          {name}
        </Text>
      </Text>
      <Space h="xl" />
      {free ? (
        <Text align="center" size="lg" mb="xl">
          Kostenlos
        </Text>
      ) : (
        <>
          <Text align="center" size="lg">{`${price[billingInterval]} € / Monat`}</Text>
          <Text align="center" size="xs" color="dimmed">
            zzgl. gesetzl. MwSt.
          </Text>
        </>
      )}
      <Center mt="xl" mb="xl">
        {currentLicence === licence && (
          <Button
            sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0], pointerEvents: 'none' })}
            variant="light"
            rightIcon={<IconCircleCheck size={20} />}
          >
            Plan aktiv
          </Button>
        )}
        {currentLicence !== licence && licence !== 'BASIC' && <Button onClick={subscribe}>Plan Buchen</Button>}
      </Center>
      <Text size="sm" weight="bolder">
        {topFeaturesText}
      </Text>
      <Space h="sm" />
      <List
        spacing={5}
        size="xs"
        icon={
          <ThemeIcon radius="xl" size="xs">
            <IconCheck size={10} />
          </ThemeIcon>
        }
      >
        {topFeaturesComponent && topFeaturesComponent}
        {features.map((feature) => (
          <List.Item key={feature}>{feature}</List.Item>
        ))}
      </List>
    </PanePaper>
  );
};

export default Plan;
