// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { AppShell, Navbar } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { WindowContext } from '../../Service/Context/WindowContext';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import PageHeader from '../Navigation/PageHeader';
import { RoutingS } from '../../Service/RoutingS';
import EmailInboxNavLinks from './EmailInboxNavLinks';
import { PostboxCountersProvider } from '../../Service/Context/PostboxCountersContext';

const EmailInboxPage = () => {
  const { sm } = useContext(WindowContext);
  const [navBarOpen, setNavBarOpen] = useState<boolean>(false);

  useEffect(() => {
    RoutingS.changeTitle('Postfach');
  }, []);

  return (
    <PostboxCountersProvider>
      <AppShell
        header={<PageHeader title="Postfach" navBarOpen={navBarOpen} setNavBarOpen={setNavBarOpen} />}
        navbar={
          <div>
            {sm ? (
              <Navbar hiddenBreakpoint="sm" hidden={!navBarOpen} width={{ sm: 220, md: 250 }}>
                <EmailInboxNavLinks closeNavBar={() => setNavBarOpen(false)} />
              </Navbar>
            ) : (
              <DoubleNavbar>
                <EmailInboxNavLinks closeNavBar={() => setNavBarOpen(false)} />
              </DoubleNavbar>
            )}
          </div>
        }
      >
        <Outlet />
      </AppShell>
    </PostboxCountersProvider>
  );
};

export default EmailInboxPage;
