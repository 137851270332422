/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Flex, FlexProps, Space, Text } from '@mantine/core';
import { FC, ReactNode } from 'react';

interface Props extends FlexProps {
  title: string;
  description?: string;
  rightSection: ReactNode;
}

const ConfigOption: FC<Props> = ({ title, description, rightSection, ...rest }) => (
  <Flex align="center" {...rest}>
    <div style={{ flexGrow: 1 }}>
      <Text weight="bolder">{title}</Text>
      {description && (
        <Text size="xs" color="dimmed">
          {description}
        </Text>
      )}
    </div>
    <Space w="xl" />
    {rightSection}
  </Flex>
);

export default ConfigOption;
