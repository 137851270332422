import { createContext, useEffect, useState } from 'react';
import { fetchCustomAttributeGroups, fetchFlattenedCustomAttributeValues } from '../restapi/customAttributeService';

export const CustomAttributesContext = createContext(undefined);

export const CustomAttributesContextProvider = ({ entity, entityType, children }) => {
  const [customAttributeValues, setCustomAttributeValues] = useState();

  const [attributeGroups, setAttributeGroups] = useState();

  const [valueLastModified, setValueLastModified] = useState(new Date());
  const [groupsLastModified, setGroupsLastModified] = useState(new Date());

  const refreshValue = () => setValueLastModified(new Date());

  const refreshGroups = () => setGroupsLastModified(new Date());

  useEffect(() => {
    fetchFlattenedCustomAttributeValues(entityType, entity.id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((flattenedValues) => {
            setCustomAttributeValues(flattenedValues);
          });
        } else {
          console.error(`could not load flattened custom attributes of ${entityType} ${entity.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not load flattened custom attributes of ${entityType} ${entity.id}`);
      });
  }, [entityType, entity.id, valueLastModified]);

  useEffect(() => {
    fetchCustomAttributeGroups(entityType)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((attrs) => {
            setAttributeGroups(attrs);
          });
        } else {
          console.error(`could not load custom attributes for entityType ${entityType}`);
        }
      })
      .catch(() => {
        console.error(`could not load custom attributes for entityType ${entityType}`);
      });
  }, [entityType, groupsLastModified]);

  return (
    <CustomAttributesContext.Provider
      value={{
        entity,
        entityType,
        customAttributeValues,
        attributeGroups,
        valueLastModified,
        groupsLastModified,
        refreshValue,
        refreshGroups,
      }}
    >
      {children}
    </CustomAttributesContext.Provider>
  );
};
