import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EventLocationContext } from '../../Service/Context/EventLocationContext';
import { EventContext } from '../../Service/Context/EventContext';
import { EditableProperty } from './EditableProperty';

export const EditableEventLocationProperty = ({
  patchField,
  placeholder,
  preChange,
  editFormatter,
  labelFormatter,
  ...rest
}) => {
  const { event } = useContext(EventContext);
  const { location, handleLocationChange } = useContext(EventLocationContext);

  let locationId = '';
  if (event.locationId) {
    locationId = `/${event.locationId}`;
  }
  if (!locationId && location && location.id) {
    locationId = `/${location.id}`;
  }
  return (
    <EditableProperty
      dto={location || { lastModified: new Date().toString() }}
      preChange={preChange}
      changeHandler={handleLocationChange}
      patchField={patchField}
      placeholder={placeholder}
      targetUrl={`${process.env.REACT_APP_LOCATION_SERVICE_URL}/locations?eventId=${event.id}`}
      editFormatter={editFormatter}
      labelFormatter={labelFormatter}
      {...rest}
    />
  );
};

EditableEventLocationProperty.propTypes = {
  patchField: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
};
