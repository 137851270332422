import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { Part, Resource, ResourceUpdate } from '../../Types/ResourceT';
import ResourceS from '../ResourceS';

type Props = {
  children: ReactNode;
};

export interface ResourceDetailsContextInterface {
  resourceId?: string;
  loading: boolean;
  resource?: Resource;
  refresh: () => void;
  setLoading: (b: boolean) => void;
  updateResource: (patch: ResourceUpdate) => void;
  patchResource: (patch: ResourceUpdate) => void;
  updateResourcePart: (parentResourceId: string, partId: string, patch: Partial<Pick<Part, 'quantity'>>) => void;
  removePart: (partId: string) => void;
}

export const ResourceDetailsContext = React.createContext<ResourceDetailsContextInterface>(
  {} as ResourceDetailsContextInterface,
);

export const ResourceDetailsContextProvider: FC<Props> = ({ children }) => {
  const { id: resourceId } = useParams();
  const [loading, setLoading] = useState(false);

  const [lastModified, setLastModified] = useState(new Date());

  const [resource, setResource] = useState<Resource | undefined>();

  const refresh = () => setLastModified(new Date());

  useEffect(() => {
    const loadResource = async () => {
      const fetchedResource = await ResourceS.fetchById(resourceId!);

      if (fetchedResource) {
        setResource(fetchedResource);
      }
    };
    loadResource();
  }, [resourceId, lastModified]);

  const updateResourcePart = useDebouncedCallback(
    (parentResourceId: string, partId: string, patch: Partial<Pick<Part, 'quantity'>>) => {
      const updatePartInContent = (id: string, part: Part) => {
        const index = resource?.content.findIndex((c) => c.id === id) ?? -1;

        if (index !== -1) {
          const contentCopy = [...resource!.content];

          contentCopy[index] = part;

          setResource((prev) => (prev ? { ...prev, content: contentCopy } : undefined));
        }
      };

      const updatePart = () => {
        if (parentResourceId) {
          ResourceS.updatePart(parentResourceId, partId, patch).then(
            (resJSON) => resJSON && updatePartInContent(partId, resJSON),
          );
        }
      };

      if (patch) {
        updatePart();
      }
    },
    300,
  );
  const patchResource = (patch: ResourceUpdate) => {
    if (resourceId) {
      ResourceS.update(resourceId, patch).then((resJSON) => resJSON && setResource(resJSON));
    }
  };

  const removePart = async (partId: string) => {
    if (resourceId) {
      await ResourceS.removePart(resourceId, partId);
      refresh();
    }
  };

  const value = useMemo(() => {
    const updateResource = (patch: ResourceUpdate) => {
      console.log({ patch });

      setResource((prev) => ({ ...prev, ...(patch as Resource) }));
      patchResource(patch);
    };
    return {
      resourceId,
      loading,
      resource,
      updateResource,
      patchResource,
      refresh,
      setLoading,
      updateResourcePart,
      removePart,
    };
  }, [resourceId, loading, resource, updateResourcePart, patchResource]);

  return <ResourceDetailsContext.Provider value={value}>{children}</ResourceDetailsContext.Provider>;
};
