/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, Center, Loader, LoadingOverlay, TextInput } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { closeAllModals } from '@mantine/modals';
import {
  BaseResourceFilter,
  CreateResourceAccessory,
  CreateResourceAlternative,
  Resource,
} from '../../Types/ResourceT';
import { PageableResponse } from '../../Types/PageableResponse';
import { PageNavigation } from '../../Types/Page';
import PaginationNav from '../../Molecules/Navigation/PaginationNav';
import SelectionTable from './table/general/SelectionTable';
import {AddResourceFilters} from "../../Templates/Resource/AddResourceFilters";

interface Props {
  fetchResources: (filter: BaseResourceFilter, signal: AbortSignal) => Promise<PageableResponse<Resource> | null>;
  enableEditPairQuantity?: boolean;
  callbackAlternative?: (createResourceAlternatives: CreateResourceAlternative[]) => Promise<boolean>;
  callbackAccessory?: (createResourceAccessories: CreateResourceAccessory[]) => Promise<boolean>;
}

const SelectResourcesModal: FC<Props> = ({
  enableEditPairQuantity,
  fetchResources,
  callbackAlternative,
  callbackAccessory,
}) => {
  const [loadingResources, setLoadingResources] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState<Resource[]>([]);
  const [selectedResources, setSelectedResources] = useState<string[]>([]);
  const [quantities, setQuantities] = useState<Record<string, number>>({});

  const [navigation, setNavigation] = useState<PageNavigation>({ totalCount: 0, totalPages: 0 });
  const [filter, setFilter] = useState<BaseResourceFilter>({
    categoriesOfInterest: [],
    query: undefined,
    typesOfInterest: [],
    pageNumber: 0, pageSize: 10 });

  const { totalCount, totalPages } = navigation;
  const { pageNumber, pageSize, query, categoriesOfInterest, typesOfInterest } = filter;

  const updateFilter = (patch: Partial<BaseResourceFilter>) => setFilter((prev) => ({ ...prev, ...patch }));

  useEffect(() => {
    setLoadingResources(true);
    const abortController = new AbortController();
    fetchResources(filter, abortController.signal)
      .then((page) => {
        if (page) {
          const { content, totalElements, totalPages } = page;
          setNavigation({ totalCount: totalElements, totalPages });
          setResources(content);
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoadingResources(false);
        }
      });
    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const onSubmit = () => {
    setLoading(true);
    if (callbackAccessory) {
      callbackAccessory(selectedResources.map((id) => ({ resourceId: id, quantity: quantities[id] ?? 1 })))
        .then((success) => {
          if (success) {
            closeAllModals();
          }
        })
        .finally(() => setLoading(false));
    } else if (callbackAlternative) {
      callbackAlternative(selectedResources.map((id) => ({ resourceId: id })))
        .then((success) => {
          if (success) {
            closeAllModals();
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const changeQuantity = (resourceId: string, newQuantity: number) =>
    setQuantities((prev) => ({ ...prev, [resourceId]: newQuantity }));

  const toggle = (resourceId: string) => {
    if (selectedResources.includes(resourceId)) {
      setSelectedResources(selectedResources.filter((id) => id !== resourceId));
    } else {
      setSelectedResources([...selectedResources, resourceId]);
    }
  };

  return (
    <>
      <Center>
        <AddResourceFilters updateFilter={updateFilter} query={query} categoriesOfInterest={categoriesOfInterest} typesOfInterest={typesOfInterest} />
      </Center>
      <Box pos="relative" sx={{ minHeight: 100 }}>
        <LoadingOverlay visible={loadingResources} overlayBlur={2} loaderProps={{ size: 'xl' }} />
        <SelectionTable
          changeQuantity={enableEditPairQuantity ? changeQuantity : undefined}
          quantities={quantities}
          resources={resources}
          selectedResources={selectedResources}
          toggle={toggle}
        />
        <PaginationNav
          pageSize={pageSize}
          pageNumber={pageNumber}
          totalCount={totalCount}
          totalPages={totalPages}
          entityName="Ressourcen"
          onChange={(newPageNumber) => updateFilter({ pageNumber: newPageNumber - 1 })}
        />
      </Box>
      <Center>
        <Button
          onClick={onSubmit}
          mb="md"
          mt={50}
          type="submit"
          disabled={loading || selectedResources.length === 0}
          leftIcon={loading && <Loader size="xs" />}
          sx={{ width: 200 }}
        >
          Speichern
        </Button>
      </Center>
    </>
  );
};

export default SelectResourcesModal;
