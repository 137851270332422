import { Center, Pagination, Text } from '@mantine/core';
import { FC } from 'react';

interface Props {
  pageSize: number;
  pageNumber: number;
  totalCount: number;
  totalPages: number;
  entityName: string;
  onChange: (pageNumber: number) => void;
}

const PaginationNav: FC<Props> = ({ pageNumber, pageSize, totalCount, totalPages, entityName, onChange }) => (
  <>
    <Center mt="xl" mb="sm">
      <Pagination noWrap boundaries={1} siblings={0} total={totalPages} page={pageNumber + 1} onChange={onChange} />
    </Center>
    {totalCount > 0 && (
      <Center>
        <Text weight="lighter">{`${pageSize * pageNumber + 1} - ${
          pageSize * (pageNumber + 1) < totalCount ? pageSize * (pageNumber + 1) : totalCount
        } von ${totalCount} ${entityName}`}</Text>
      </Center>
    )}
  </>
);

export default PaginationNav;
