// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Skeleton, Stack, Text } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { openModal } from '@mantine/modals';
import { useParams } from 'react-router-dom';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import PanePaper from '../../../Molecules/PanePaper';
import ResourceS from '../../../Service/ResourceS';
import SelectResourcesModal from '../SelectResourcesModal';
import { CreateResourceAccessory, ResourceAccessory } from '../../../Types/ResourceT';
import { ResourceAddonContext } from '../../../Service/Context/ResourceAddonsContext';
import ResourceWidgetItem from './ResourceWidgetItem';

const ResourceAccessoriesPane = () => {
  const { id: resourceId } = useParams();
  const [loading, setLoading] = useState(false);
  const { executeWithCheck } = useContext(ResourceAddonContext);
  const [lastModified, setLastModified] = useState(new Date());
  const [accessories, setAccessories] = useState<ResourceAccessory[] | null>();
  const length = accessories?.length ?? -1;

  useEffect(() => {
    if (resourceId) {
      setLoading(true);
      ResourceS.fetchAccessories(resourceId)
        .then(setAccessories)
        .finally(() => setLoading(false));
    }
  }, [resourceId, lastModified]);

  const openAddModal = () => {
    if (resourceId) {
      openModal({
        size: 'xl',
        title: (
          <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
            Zubehör hinzufügen
          </Text>
        ),
        children: (
          <SelectResourcesModal
            enableEditPairQuantity
            fetchResources={(filter, signal) => ResourceS.fetchPossibleAccessories(resourceId, filter, signal)}
            callbackAccessory={addAccessories}
          />
        ),
      });
    }
  };

  const addAccessories = async (createResourcePairs: CreateResourceAccessory[]) => {
    if (resourceId) {
      const newAccessories = await ResourceS.addAccessories(resourceId, createResourcePairs);
      if (newAccessories) {
        setAccessories(newAccessories);
        return true;
      }
    }
    return false;
  };

  const removeAccessory = (ownerId: string, accessoryId: string) => {
    if (resourceId) {
      ResourceS.removeAccessory(ownerId, accessoryId).then((deleted) => {
        if (deleted) {
          setLastModified(new Date());
        }
      });
    }
  };

  return (
    <PanePaper
      withShadow
      withoutBorder
      title="Zubehör"
      rightSection={<BookitupAddIcon onClick={() => executeWithCheck(openAddModal)} />}
    >
      {loading ? (
        Array.from(Array(3)).map(() => <Skeleton key={Math.random()} radius="md" height={20} width="100%" mb="xs" />)
      ) : (
        <div>
          {length > 0 ? (
            <Box
              p="md"
              sx={{
                borderRadius: 10,
                backgroundColor: '#F4F2F2',
              }}
            >
              <Stack spacing="xs">
                {accessories?.map((accessory) => {
                  const isSwapped = accessory.ownerId === accessory.resource.id;

                  return (
                    // eslint-disable-next-line react/jsx-key
                    <ResourceWidgetItem
                      resource={accessory.resource}
                      quantity={isSwapped && accessory.quantity !== 1 ? `1:${accessory.quantity}` : accessory.quantity}
                      onDelete={() => {
                        removeAccessory(accessory.ownerId, isSwapped ? resourceId! : accessory.resource.id);
                      }}
                    />
                  );
                })}
              </Stack>
            </Box>
          ) : (
            <Text italic color="dimmed" size="xs" weight="lighter">
              Noch nichts hinzugefügt.
            </Text>
          )}
        </div>
      )}
    </PanePaper>
  );
};

export default ResourceAccessoriesPane;
