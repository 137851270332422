import React from 'react';
import { withI18n } from '@lingui/react';
import { Group } from '@mantine/core';
import { useDrag, useDrop } from 'react-dnd-latest';
import { EditableAnswerProperty } from '../../Atoms/EditableProperties/EditableAnswerProperty';
import { EditableRadiobox } from '../../Atoms/inputs/EditableRadiobox';
import { EditableCheckbox } from '../../Atoms/inputs/EditableCheckbox';
import { EditableDivInput } from '../../Atoms/inputs/EditableDivInput';
import { HtmlPlaceholderFormatter } from '../../Atoms/Formatter/HtmlPlaceholderFormatter';
import TooltipButton from '../../Atoms/buttons/TooltipButton';
import { preChange } from '../../Utils/utils';
import DragButton from '../../Atoms/DragDrop/DragButton';

export default withI18n()(
  ({
    index,
    i18n,
    attributeType,
    answer,
    moveAnswer,
    delAnswerOption,
    handleChange,
    options,
    additionalAnswer,
    event,
    eventLocation,
    customer,
    withoutDrag,
  }) => {
    const [, drop] = useDrop(
      () => ({
        accept: 'answerPosition',
        collect(monitor) {
          return { handlerId: monitor.getHandlerId() };
        },
        drop(item, monitor) {
          // console.log(`move position ${item.position.label} to index ${index}`);
          moveAnswer(item.index, index);
        },
      }),
      [answer.id],
    );

    const [, drag] = useDrag(
      () => ({
        type: 'answerPosition',
        item: { answer, index },
      }),
      [answer.id],
    );

    return (
      <Group spacing="xs" ref={drop}>
        {!withoutDrag && <DragButton dragRef={drag} />}
        {withoutDrag && <div style={{ width: 20 }}/>}
        {attributeType === 'SINGLE_CHOICE' && (
          <div style={{ marginTop: -18 }}>
            <EditableAnswerProperty
              placeholder=""
              name="enabled"
              patchField="enabled"
              preChange={(p) => preChange(p, i18n)}
              changeHandler={handleChange}
              entity={answer}
              editFormatter={EditableRadiobox}
              disabled
              options={options}
              editOnly
              event={event}
              eventLocation={eventLocation}
              customer={customer}
            />
          </div>
        )}
        {attributeType === 'MULTIPLE_CHOICE' && (
          <div style={{ marginTop: -18 }}>
            <EditableAnswerProperty
              placeholder=""
              name="enabled"
              patchField="enabled"
              preChange={(p) => preChange(p, i18n)}
              changeHandler={handleChange}
              entity={answer}
              editFormatter={EditableCheckbox}
              disabled
              editOnly
              event={event}
              eventLocation={eventLocation}
              customer={customer}
            />
          </div>
        )}
        <div style={{ flex: 1 }}>
          <EditableAnswerProperty
            name="value"
            patchField="value"
            changeHandler={handleChange}
            disabled={additionalAnswer}
            entity={answer}
            editFormatter={EditableDivInput}
            labelFormatter={HtmlPlaceholderFormatter}
          />
        </div>

        <TooltipButton
          isLoading={false}
          id={`removeAnswer${answer.id}`}
          text="actions.remove"
          onClick={() => {
            delAnswerOption(answer.id);
          }}
          icon="TIMES"
        />
      </Group>
    );
  },
);
