import React, { useState } from 'react';
import { BlockPicker, SketchPicker } from 'react-color';

const ColorPicker = ({
  value,
  testId,
  onChange,
  onChangeRgba,
  pickerStyles,
  colors,
  useSketchPicker,
  open,
  setOpen,
}) => {
  const [color, setColor] = useState(value);

  const Picker = useSketchPicker ? SketchPicker : BlockPicker;

  return (
    <div>
      <div
        style={{
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        }}
        onClick={() => setOpen(true)}
      >
        <div
          style={{
            width: '36px',
            height: '14px',
            borderRadius: '2px',
            background: color,
          }}
        />
      </div>
      {open ? (
        <div
          style={
            pickerStyles ?? {
              position: 'absolute',
              zIndex: '2',
            }
          }
        >
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={() => setOpen(false)}
          />
          <Picker
            color={color}
            colors={colors}
            onChangeComplete={(color, event) => {
              setColor(color);
              onChange?.(color.hex);
              onChangeRgba?.(color.rgb);
            }}
            data-testid={testId}
          />
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;
