import { Skeleton, Stack } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { useJwtClaims } from '../../../Service/Context/AuthContext';
import { LicenceS } from '../../../Service/helper/LicenceService';
import { useUpdateBillingPlans } from '../../../Service/hooks/useUpdateBillingPlans';
import SubscriptionS from '../../../Service/restapi/subscriptionService';
import { SubscriptionDTO } from '../../../Types/Billing';
import { RoutingS } from '../../../Service/RoutingS';
import { ResourceAddonContext } from '../../../Service/Context/ResourceAddonsContext';
import AvailablePlans from './AvailablePlans';
import SubscriptionPane from './SubscriptionPane';
import ThankYou from './ThankYou';
import AvailableAddons from './AvailableAddons';

const PackageSection = () => {
  const [subscription, setSubscription] = useState<SubscriptionDTO>();
  const { trialAvailable } = useContext(ResourceAddonContext);
  const [loading, setLoading] = useState<boolean>(false);
  const { licence } = useJwtClaims();
  const freeLicence = LicenceS.isFreeLicence(licence);

  useUpdateBillingPlans(freeLicence);

  useEffect(() => {
    fetchSubscriptionDetails();
  }, [freeLicence]);

  const fetchSubscriptionDetails = () => {
    setLoading(true);
    SubscriptionS.getSubscription()
      .then((resp) => {
        if (resp.ok) {
          resp.json().then(setSubscription);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    RoutingS.changeTitle('Paket');
  }, []);

  if (freeLicence && !subscription) {
    return <AvailablePlans />;
  }
  if (loading) {
    return (
      <Stack sx={{ maxWidth: 550 }}>
        <Skeleton radius="md" height={250} />
        <Skeleton radius="md" height={250} />
      </Stack>
    );
  }
  if (subscription) {
    return (
      <Stack sx={{ maxWidth: 550 }}>
        <SubscriptionPane subscription={subscription} refreshSubscription={fetchSubscriptionDetails} />
        <AvailableAddons billingInterval={"Yearly"} />
        <ThankYou />
      </Stack>
    );
  }
  return <AvailablePlans />;
};

export default PackageSection;
