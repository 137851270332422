import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Skeleton, Stack, Text } from '@mantine/core';
import PanePaper from '../../../Molecules/PanePaper';
import { ResourceType, UsageDTO } from '../../../Types/ResourceT';
import ResourceS from '../../../Service/ResourceS';
import ResourceWidgetItem from './ResourceWidgetItem';

const ResourceUsagesPane: FC<{ resourceType: ResourceType; title: string }> = ({ resourceType, title }) => {
  const { id: resourceId } = useParams();
  const [loading, setLoading] = useState(false);
  const [usages, setUsages] = useState<UsageDTO[]>([]);
  const { length } = usages;

  useEffect(() => {
    if (resourceId) {
      setLoading(true);
      ResourceS.fetchUsages(resourceId, resourceType)
        .then(setUsages)
        .finally(() => setLoading(false));
    }
  }, [resourceType, resourceId]);

  return (
    <PanePaper withShadow withoutBorder title={title}>
      {loading ? (
        Array.from(Array(3)).map(() => <Skeleton key={Math.random()} radius="md" height={20} width="100%" mb="xs" />)
      ) : (
        <div>
          {length > 0 ? (
            <Box
              p="md"
              sx={{
                borderRadius: 10,
                backgroundColor: '#F4F2F2',
              }}
            >
              <Stack spacing="xs">
                {usages.map((usage) => {
                  const { owner, quantity } = usage;
                  // eslint-disable-next-line react/jsx-key
                  return <ResourceWidgetItem resource={owner} quantity={quantity} />;
                })}
              </Stack>
            </Box>
          ) : (
            <Text italic color="dimmed" size="xs" weight="lighter">
              Noch nichts hinzugefügt.
            </Text>
          )}
        </div>
      )}
    </PanePaper>
  );
};

export default ResourceUsagesPane;
