import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_RECOMMENDATION_SERVICE_URL;

export const patchRecommendationAnswer = (recommendationId, answer) =>
  ajaxActions.patch(`${BASE_URL}/answer/recommendations/${recommendationId}`, { answer }).then((resp) => resp);

export const patchRecommendationState = (recommendationId, state, optionalMessage) =>
  ajaxActions
    .patch(`${BASE_URL}/bookingState/recommendations/${recommendationId}`, {
      bookingState: state,
      message: optionalMessage,
    })
    .then((resp) => resp);

export const putRecommendation = (recommendation) =>
  ajaxActions.put(`${BASE_URL}/recommendations`, recommendation).then((resp) => resp);

export const fetchRecommendation = (recommendationId) =>
  ajaxActions.get(`${BASE_URL}/recommendations/${recommendationId}`).then((resp) => resp);

export const deleteRecommendation = (recommendationId, sendMail, message) =>
  ajaxActions
    .del(`${BASE_URL}/recommendations/${recommendationId}?sendMail=${sendMail}`, { message })
    .then((resp) => resp);

export const deleteRecommendations = (recommendationIds, sendMail) =>
  ajaxActions.del(`${BASE_URL}/recommendations?sendMail=${sendMail}`, recommendationIds).then((resp) => resp);

export const fetchRecommendationsByEvent = (eventId) =>
  ajaxActions.get(`${BASE_URL}/recommendations/events/${eventId}`).then((resp) => resp);

export const fetchRecommendationsByContact = (contactId) =>
  ajaxActions.get(`${BASE_URL}/recommendations/contacts/${contactId}`).then((resp) => resp);

export const patchRecommendation = (recommendationId, patch) =>
  ajaxActions.patch(`${BASE_URL}/recommendations/${recommendationId}`, patch).then((resp) => resp);

export const checkAvailabilityForRecommendation = (recommendationId, optionalMessage) =>
  ajaxActions
    .put(`${BASE_URL}/recommendations/${recommendationId}/checkAvailability`, { message: optionalMessage })
    .then((resp) => resp);

export const sendInfoMailForRecommendation = (recommendationId, optionalMessage) =>
  ajaxActions
    .put(`${BASE_URL}/recommendations/${recommendationId}/sendInfoMail`, { message: optionalMessage })
    .then((resp) => resp);

export const lookUpAvailability = (contactId, eventId) =>
  ajaxActions.get(`${BASE_URL}/availabilityCache?contactId=${contactId}&eventId=${eventId}`).then((resp) => resp);
