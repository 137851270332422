import { CSSProperties, FC, useState } from 'react';
import ColorPicker from './ColorPicker';

type RgbaColor = {
  r: string;
  g: string;
  b: string;
  a: string;
};

type Props = {
  value: string;
  onChange?: (c: string) => void;
  onChangeRgba?: (c: string) => void;
  pickerStyles?: CSSProperties;
  testId?: string;
  useVibrantColors?: boolean;
  useSketchPicker?: boolean;
  open?: boolean;
  setOpen?: (b: boolean) => void;
};

const ColorPickerWithOptions: FC<Props> = ({
  value,
  testId,
  onChange,
  onChangeRgba,
  pickerStyles,
  useVibrantColors,
  useSketchPicker,
  open,
  setOpen,
}) => {
  const [isOpenUncontrolled, setIsOpenUncontrolled] = useState(false);

  const monochromeColors = [
    '#FFFFFF',
    '#f7f7f7',
    '#d3d3d3',
    '#c5c5c5',
    '#a8a8a8',
    '#878787',
    '#555555',
    '#404040',
    '#252525',
    '#000000',
  ];

  const vibrantColors = [
    value,
    '#FFFFFF',
    '#e2e2e2',
    '#88fb8f',
    '#efe674',
    '#00219f',
    '#8670e7',
    '#ffd9ff',
    '#ff89f5',
    '#ffa9ce',
    '#310f21',
    '#7c7578',
    '#000000',
  ];

  const handleRgbaChange = (c: RgbaColor) => onChangeRgba?.(`rgba(${c.r},${c.g},${c.b},${c.a})`);

  return (
    <ColorPicker
      value={value}
      colors={useVibrantColors ? vibrantColors : monochromeColors}
      onChange={onChange}
      onChangeRgba={handleRgbaChange}
      pickerStyles={pickerStyles}
      useSketchPicker={useSketchPicker}
      testId={testId}
      open={open ?? isOpenUncontrolled}
      setOpen={setOpen ?? setIsOpenUncontrolled}
    />
  );
};

export default ColorPickerWithOptions;
