// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Container, Space, Text } from '@mantine/core';
import {useContext, useEffect} from 'react';
import AnnoucementsBox from '../../Molecules/Announcements/AnnouncementsBox';
import { ContactContext } from '../../Service/Context/ContactContext';
import Onboarding from '../Onboarding/Onboarding';
import { EventsContext } from '../../Service/Context/EventsContext';
import {openModal} from "@mantine/modals";
import {ProfileContext} from "../../Service/Context/ProfileContext";

const WelcomeHeader = () => {
  const {
    contact: { firstName },
  } = useContext<any>(ContactContext);
  const {profile} = useContext(ProfileContext);

  useEffect(() => {
    if (profile && profile.id && profile.id == 55714) {
      openModal({
        size: 800,
        children: (
          <div>
            <Text mt="md" weight="bolder" size={24} align="center" sx={{ lineHeight: 1.2 }}>
              ❌ {` Es gibt unbezahlte Rechnungen`}
            </Text>
            <Space h="md" />
            <Text mb="xl" align="left">
              Der Zugang wurde vorrübergehend gesperrt, da eine offene Forderung von <b>310,20 EUR</b> vorliegt.
            </Text>
            <Text mb="xl" align="left">
              Zur schnellstmöglichen Entsperrung, überweise den Betrag auf unten angegebenes Konto und schicke
              uns den <b>Überweisungsbeleg per E-Mail an support@bookitup.de</b>.
            </Text>
            <Space h={"xs"}/>

            <Text weight={"bolder"} mb="xl" align="left">
              bookitup GmbH <br/>
              IBAN: DE81 1001 7997 1149 8382 90<br/>
              BIC: HOLVDEB1
            </Text>

            <Text mb="xl" align="left">
              Vielen Dank!<br/>
              Dein bookitup Team
            </Text>

          </div>
        ),
        closeOnEscape: false,
        withCloseButton: false,
      })
    }

  }, [profile]);

  const { view } = useContext(EventsContext);

  return (
    <Container px={0} size={800}>
      <Onboarding />
      {view === 'list' && (
        <>
          <Space h="xs" />
          <Text size="xl" weight="bolder">
            {`Moin ${firstName}! `}&#128075;
          </Text>
          <AnnoucementsBox />
          <Space h="sm" />
        </>
      )}
    </Container>
  );
};

export default WelcomeHeader;
