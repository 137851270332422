import React, { useContext, useState } from 'react';
import { InputGroup } from 'reactstrap';
import ClipboardButton from '../../../../Atoms/buttons/ClipboardButton';

export const Subscription = ({ calendarSubscriptionId, entityType }) => {
  const [url] = useState(
    `${process.env.REACT_APP_CALDAV_SERVICE_URL}/subscriptions/${entityType}/${calendarSubscriptionId}.ics`,
  );

  const styles = {
    codeField: {
      height: 34,
      borderRadius: 5,
      marginRight: 4,
    },
  };

  return (
    <div style={{ marginTop: 10, marginBottom: 15 }}>
      <InputGroup style={{ maxWidth: 600 }}>
        <input readOnly className="code form-control" style={styles.codeField} value={url} />
        <ClipboardButton text={url} />
      </InputGroup>
    </div>
  );
};
