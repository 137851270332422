import { useContext, useState } from 'react';
import Attachments from '../../Organismns/Attachments';
import { Space } from '@mantine/core';
import { MessageS } from '../../Service/MessageS';
import { BaseInput, RichInput } from '../../Organismns/tiptap/TiptapEditorInput';
import { useParams } from 'react-router-dom';
import TemplateMessageToolbar from '../../Organismns/tiptap/toolbars/TemplateMessageToolbar';
import AttachmentList from '../Message/AttachmentList';
import { Trans } from '@lingui/react';
import { TransButtonDefault } from '../../Atoms/Buttons';
import { ModalDialog } from '../Dialogs';
import { MessageContext } from '../../Service/Context/MessageContextV2';
import BaseToolbar from '../../Organismns/tiptap/toolbars/BaseToolbar';

export const EmailTemplateEditor = () => {
  const { type, name: templateName } = useParams();
  const [open, setOpen] = useState(false);
  const { message, changeMessage, saveMessage } = useContext(MessageContext);
  const messageType = type.toUpperCase();

  function deleteFileAttachment(index) {
    const tmpFiles = message.attachments;
    tmpFiles.splice(index, 1);
    saveMessage({ attachments: tmpFiles });
  }

  function deleteDocumentAttachment(index) {
    const tmpDocs = message.documentBlueprints;
    tmpDocs.splice(index, 1);
    saveMessage({ documentBlueprints: tmpDocs });
  }

  if (!message) {
    return null;
  }

  const updateMessage = (key, val) => {
    MessageS.updateTemplate(messageType, { [key]: val }, templateName).then((resJSON) => {
      if (resJSON) {
        changeMessage(resJSON);
        return true;
      }
      return false;
    });
  };

  return (
    <>
      <BaseInput
        small
        value={message.subject}
        label="Betreff"
        description="help.message.email.pane"
        patchField={(value) => updateMessage('subject', value)}
        toolbar={<BaseToolbar />}
      />
      <Space h="sm" />
      <Attachments
        attachments={message.attachments}
        documentBlueprints={message.documentBlueprints}
        deleteFileAttachment={deleteFileAttachment}
        deleteDocumentAttachment={deleteDocumentAttachment}
      />
      <Space h="sm" />
      <RichInput
        value={message.message}
        label="Nachricht"
        patchField={(value) => updateMessage('message', value)}
        toolbar={<TemplateMessageToolbar addAttachment={() => setOpen(true)} />}
      />
      <ModalDialog
        help="help.email.attachments.available.pane"
        header={<Trans id="message.attach.file" />}
        body={<AttachmentList eventId={undefined} emailTemplate={messageType} templateName={templateName} />}
        footer={
          <TransButtonDefault
            onClickHandler={() => {
              setOpen(false);
            }}
            text="actions.close"
          />
        }
        show={open}
        toggle={() => {
          setOpen(false);
        }}
      />
    </>
  );
};
