import { Text } from '@mantine/core';
import { FC } from 'react';
import { CreateTaskConfigDTO, ProcedureReportProps } from '../../../../Types/LogT';

const CreateTaskReport: FC<ProcedureReportProps> = ({ report, config }) => {
  const { name } = config as CreateTaskConfigDTO;

  return (
    <Text>
      Aufgabe{' '}
      <Text span weight="bolder">
        {name}
      </Text>{' '}
      wurde erstellt.
    </Text>
  );
};

export default CreateTaskReport;
