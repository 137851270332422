import { useContext, useEffect, useState } from 'react';
import {Alert, Space, Text} from '@mantine/core';
import QuestionnaireSkeleton from '../../Organismns/Questionnaire/QuestionnaireSkeleton';
import { EventContext } from '../../Service/Context/EventContext';
import { EventLocationContext } from '../../Service/Context/EventLocationContext';
import { CustomerContext } from '../../Service/Context/CustomerContext';
import { fetchFlattenedCustomAttributeValues } from '../../Service/restapi/customAttributeService';
import PanePaper from '../../Molecules/PanePaper';
import DocumentHeadTemplate from '../DocumentHeadTemplate';
import { QuestionnaireContext } from '../../Service/Context/QuestionnaireContext';
import QuestionnaireHeadActions from './QuestionnaireHeadActions';
import {IconInfoCircle} from "@tabler/icons";
import {i18n} from "@lingui/core";

const QuestionnaireForm = () => {
  const { event } = useContext(EventContext);
  const { location } = useContext(EventLocationContext);
  const { customer } = useContext(CustomerContext);
  const [customAttributeValues, setCustomAttributeValues] = useState(undefined);
  const { questionnaire } = useContext(QuestionnaireContext);

  useEffect(() => {
    fetchFlattenedCustomAttributeValues('event', event.id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((flattenedValues) => {
            setCustomAttributeValues(flattenedValues);
          });
        } else {
          console.error(`could not load flattened custom attributes of event ${event.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not load flattened custom attributes of event ${event.id}`);
      });
  }, [event.id]);

  return (
    <PanePaper>
      <DocumentHeadTemplate
        entityType="questionnaires"
        entityId={questionnaire.id}
        title="Fragebögen erstellen"
        previewUrl={`/events/${event.id}/questionnaires/${questionnaire.id}/preview`}
        documentActions={<QuestionnaireHeadActions />}
      />
      <Space h="xl" />
      <Alert
        mb="sm"
        icon={<IconInfoCircle />}
        title="Sind im PDF große Bereiche weiß? Dieser Tipp hilft Dir!"
        sx={(theme) => ({ maxWidth: 800, backgroundColor: theme.colors.lightBlue[0] })}
      >
        <Text>{i18n._('questionnaire.tip.helper')}</Text>
      </Alert>
      <QuestionnaireSkeleton
        event={event}
        eventLocation={location}
        customer={customer}
        customAttributeValues={customAttributeValues}
      />
    </PanePaper>
  );
};

export default QuestionnaireForm;
