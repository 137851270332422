// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';

const MissingScope = () => (
  <Text italic color="red">
    Der Zugriff für diesen Google-Dienst fehlt.
  </Text>
);

export default MissingScope;
