import { Group, Skeleton, Text } from '@mantine/core';
import { IconMapPin } from '@tabler/icons';
import React, { useContext, useEffect, useState } from 'react';
import { WindowContext } from '../../Service/Context/WindowContext';
import { fetchLocationByEventId } from '../../Service/restapi/locationService';

// TODO: Move type declaration somewhere else later
type EventLocation = {
  id: number;
  eventId: number;
  profileId: number;
  lastModified: Date;
  name?: string;
  streetAndNo?: string;
  zipCode?: string;
  city?: string;
  contactId?: number;
  phoneNumber?: string;
  placeId?: string;
  address?: string;
};

const LocationDescription: React.FC<{ eventId: number }> = ({ eventId }) => {
  const { xs, windowWidth } = useContext(WindowContext);
  const [description, setDescription] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetchLocationByEventId(eventId)
      .then((resp: any) => {
        if (resp.ok) {
          resp.json().then((location: EventLocation) => {
            if (location) {
              const { name, streetAndNo, zipCode, city } = location;
              if (name && name !== '') {
                setDescription(name);
              } else if (streetAndNo) {
                if (city) {
                  setDescription(`${streetAndNo}, ${zipCode ? `${zipCode} ` : ''}${city}`);
                } else {
                  setDescription(streetAndNo);
                }
              } else if (city) {
                setDescription(`${zipCode ? `${zipCode} ` : ''}${city}`);
              }
            }
          });
        }
      })
      .finally(() => setLoading(false));
  }, [eventId]);

  if (loading) {
    return <Skeleton height={20} width={100} mt={5} />;
  }

  if (!description) {
    return null;
  }

  return (
    <Group spacing={5}>
      <IconMapPin size={16} color="grey" />
      <Text
        color="dimmed"
        size="xs"
        weight="lighter"
        className="overflow-ellipsis"
        sx={{ width: xs ? windowWidth - 200 : 'initial' }}
      >
        {description}
      </Text>
    </Group>
  );
};

export default LocationDescription;
