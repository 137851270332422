import { useContext, useRef, useState } from 'react';
import { Trans } from '@lingui/react';
import { ModalDialog } from '../../Molecules/Dialogs';
import { patchDraft } from '../../Service/restapi/messageService';
import { FocusedNodeContext } from '../../Service/Context/FocusedNode';
import EmailReceiver from './EmailReceiver';
import { Box, LoadingOverlay, Space, Text, TextInput } from '@mantine/core';
import { MessageS } from '../../Service/MessageS';
import Attachments from '../Attachments';
import { EventBaseInput, EventRichInput, RichInputWithoutMentions } from '../tiptap/TiptapEditorInput';
import MessageToolbar from '../tiptap/toolbars/MessageToolbar';
import AttachmentList from '../../Molecules/Message/AttachmentList';
import { TransButtonDefault } from '../../Atoms/Buttons';
import { IconX } from '@tabler/icons';
import { MessageContext } from '../../Service/Context/MessageContextV2';
import { useParams } from 'react-router';
import { i18n } from '@lingui/core';
import BaseToolbar from '../tiptap/toolbars/BaseToolbar';
import { EmailBoxContext } from '../../Service/Context/EmailBoxContext';

const NewEmail = () => {
  const { id: eventId } = useParams();
  const { message, changeMessage, action, loading } = useContext(MessageContext);
  const { flushMessage, changeEmail } = useContext(EmailBoxContext);
  const { focusedNode, setFocusedNode } = useContext(FocusedNodeContext);
  const focusedNodeRef = useRef(focusedNode);
  focusedNodeRef.current = focusedNode;

  function deleteFileAttachment(index) {
    const tmpFiles = message.attachments;
    tmpFiles.splice(index, 1);
    patchDraft(message.id, { attachments: tmpFiles })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then(changeMessage);
        } else {
          console.error('could not delete attachment');
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not delete attachment');
      });
  }

  const updateMessage = (key, val) => {
    MessageS.patchDraftMessage({ [key]: val }, message.id).then((resJSON) => {
      if (resJSON) {
        changeMessage(resJSON);
        changeEmail(resJSON);
        return true;
      }
      return false;
    });
  };

  const [open, setOpen] = useState(false);

  if (loading) {
    return <LoadingOverlay visible loaderProps={{ size: 'xl' }} overlayBlur={2} />;
  }

  if (!message || !action) {
    return null;
  }

  return (
    <>
      <Text
        p="sm"
        size="sm"
        weight="bolder"
        color="blue"
        sx={(theme) => ({
          backgroundColor: theme.colors.lightBlue[0],
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
        })}
        pos="relative"
      >
        {i18n._(`messaging.action.${action}`)}
        <IconX size={16} style={{ position: 'absolute', right: 10, cursor: 'pointer' }} onClick={flushMessage} />
      </Text>
      <Box p="sm">
        <EmailReceiver />
        <Space h="md" />

        {eventId && (
          <EventBaseInput
            label="Betreff"
            value={message.subject}
            patchField={(value) => updateMessage('subject', value)}
            toolbar={<BaseToolbar />}
          />
        )}
        {!eventId && (
          <TextInput
            placeholder="Betreff"
            defaultValue={message.subject}
            onBlur={(e) => updateMessage('subject', e.currentTarget.value)}
          />
        )}
        <Space h="sm" />
        <Attachments attachments={message.attachments} deleteFileAttachment={deleteFileAttachment} eventId={eventId} />
        {message.attachments && message.attachments.length > 0 && <Space h="sm" />}
        {eventId && (
          <EventRichInput
            label="Nachricht"
            value={message.message}
            patchField={(value) => updateMessage('message', value)}
            toolbar={<MessageToolbar addAttachment={() => setOpen(true)} />}
          />
        )}
        {!eventId && (
          <RichInputWithoutMentions
            label="Nachricht"
            value={message.message}
            patchField={(value) => updateMessage('message', value)}
            toolbar={<MessageToolbar addAttachment={() => setOpen(true)} />}
          />
        )}

        <ModalDialog
          help="help.email.attachments.available.pane"
          header={<Trans id="message.attach.file" />}
          body={<AttachmentList eventId={eventId} />}
          footer={
            <TransButtonDefault
              onClickHandler={() => {
                setOpen(false);
              }}
              text="actions.close"
            />
          }
          show={open}
          toggle={() => {
            setOpen(false);
          }}
        />
      </Box>
    </>
  );
};

export default NewEmail;
