import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_DOCUMENT_SERVICE_URL;

export const favorite = (positionId, favorite) =>
  ajaxActions.exchange('PUT', `${BASE_URL}/positions/${positionId}?favorite=${favorite}`).then((resp) => resp);

export const putPosition = (position) =>
  ajaxActions.exchange('PUT', `${BASE_URL}/positions`, position).then((resp) => resp);

export const putFavoritePosition = (position) =>
  ajaxActions.exchange('PUT', `${BASE_URL}/positionFavorites`, position).then((resp) => resp);

export const fetchPositions = () => ajaxActions.get(`${BASE_URL}/positions`).then((resp) => resp);

export const fetchFavoritePositions = () => ajaxActions.get(`${BASE_URL}/positionFavorites`).then((resp) => resp);

export const fetchPosition = (positionId) =>
    ajaxActions.get(`${BASE_URL}/positions/${positionId}`).then((resp) => resp);

export const fetchFavoritePosition = (favoritePositionId) =>
  ajaxActions.get(`${BASE_URL}/positionFavorites/${favoritePositionId}`).then((resp) => resp);

export const deleteFavoritePosition = (favoritePositionId) =>
  ajaxActions.del(`${BASE_URL}/positionFavorites/${favoritePositionId}`, undefined).then((resp) => resp);

export const patchPosition = (positionId, patch) =>
  ajaxActions.patch(`${BASE_URL}/positions/${positionId}`, patch).then((resp) => resp);

export const patchFavoritePosition = (positionId, patch) =>
  ajaxActions.patch(`${BASE_URL}/positionFavorites/${positionId}`, patch).then((resp) => resp);
