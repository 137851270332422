import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TransButtonLink } from '../../Atoms/Buttons';
import TooltipButton from '../../Atoms/buttons/TooltipButton';
import { patchContract, patchContractTemplate } from '../../Service/restapi/contractService';

export default function ({ contract, handleChange }) {
  const navigate = useNavigate();

  if (!contract.documentId && !contract.positions) {
    return null;
  }

  function deleteDocumentFromContract() {
    patchContract(contract.id, { documentId: null }).then((resp) => {
      if (resp.ok) {
        resp.json().then((contract) => {
          handleChange(contract);
        });
      }
    });
  }

  function deletePositionsFromContract() {
    patchContractTemplate(contract.id, { positions: null })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((newContract) => {
            handleChange(newContract);
          });
        } else {
          console.error(`could not remove positions vom contract template ${contract.name}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not remove positions vom contract template ${contract.name}`);
      });
  }

  function deletePositions() {
    if (contract.documentId) {
      deleteDocumentFromContract();
    } else {
      deletePositionsFromContract();
    }
  }

  const styles = {
    documentPositions: {
      marginTop: 10,
      marginBottom: 10,
      padding: 3,
      paddingLeft: 5,
      border: '1px solid rgba(0, 0, 0, 0.125)',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={styles.documentPositions}>
        <div>
          Positionen werden hier aus
          {contract.documentId && (
            <TransButtonLink
              className="primary-link"
              style={{ padding: 5 }}
              onClick={() => {
                navigate(`/events/${contract.eventId}/documents/${contract.documentId}`);
              }}
              text="Dokument"
            />
          )}
          {!contract.documentId && <> einem ausgewählten Dokument </>}
          eingefügt
        </div>
        <div style={{ marginLeft: 10, marginRight: 10 }}>
          <TooltipButton
            icon="TIMES"
            id="contract-document-delete"
            text="Positionen löschen"
            onClick={deletePositions}
          />
        </div>
      </div>
    </div>
  );
}
