// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Checkbox, Divider, Group, Skeleton, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IconCheck, IconQuestionCircle } from '@tabler/icons';
import { QuestionDto } from '../../Types/QuestionnaireT';
import { useCustomAttribute } from '../../Service/hooks/useCustomAttribute';
import { CustomAttributeWithGroupNameDTO } from '../../Types/CustomAttributeT';
import { DataS } from '../../Service/DataS';
import AutocompleteCustomAttributeItem from '../../Atoms/Autocomplete/AutocompleteCustomAttributeItem';
import BookitupSelect from '../../Atoms/BookitupSelect';
import { QuestionnaireS } from '../../Service/QuestionnaireS';
import FileDetails, { FileDTO } from '../../Molecules/FileDetails';

interface Props {
  question: QuestionDto;
  isSelected: boolean;
  toggle: () => void;
  customerAnswer: string | null;
  changeAttributeId: (id: string | null) => void;
  customAttributes: CustomAttributeWithGroupNameDTO[];
  formId: string;
  file?: FileDTO;
  previewFile?: () => void;
  reload: () => void;
}

const QuestionSubmission: FC<Props> = ({
  question,
  isSelected,
  toggle,
  customerAnswer,
  changeAttributeId,
  customAttributes,
  formId,
  file,
  previewFile,
  reload,
}) => {
  const { id: eventId } = useParams();
  const { question: questionText, attributeType, targetAttributeId } = question;
  const { loading, attribute, alternatives, attributeValue } = useCustomAttribute(
    targetAttributeId,
    eventId,
    customAttributes,
    attributeType,
  );
  const [addingAttribute, setAddingAttribute] = useState(false);
  const [selectedAttributeName, selectAttributeName] = useState<string | null>(attribute ? attribute.name : null);
  const attributeTypeSupported = attributeType !== 'FILE_UPLOAD';
  const formattedCustomerAnswer = QuestionnaireS.formatAnswer(attributeType, customerAnswer);
  const formattedAttributeValue = QuestionnaireS.formatAnswer(attributeType, `${attributeValue}`);
  const attributeValueNotEmpty = formattedAttributeValue !== 'Nicht beantwortet';
  const attributeValueSynced = formattedCustomerAnswer === formattedAttributeValue;
  const hideCheckbox = attributeValueSynced || !attributeTypeSupported || !targetAttributeId;

  useEffect(() => {
    if (!targetAttributeId) {
      if (addingAttribute) {
        setAddingAttribute(false);
      }
      if (selectedAttributeName) {
        selectAttributeName(null);
      }
      if (isSelected) {
        toggle();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetAttributeId]);

  useEffect(() => {
    if (selectedAttributeName) {
      const selectedAttribute = customAttributes.find((a) => a.name === selectedAttributeName);
      if (selectedAttribute) {
        changeAttributeId(selectedAttribute.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAttributeName]);

  return (
    <>
      <Group sx={{ width: '100%' }}>
        <Box pl="md" sx={{ width: 50 }}>
          {loading && <Skeleton circle height={20} />}
          {!loading && !hideCheckbox && <Checkbox checked={isSelected} onChange={toggle} />}
        </Box>
        <Group position="apart" sx={{ flexGrow: 1 }}>
          <Stack spacing="xs">
            <Text weight="bolder" dangerouslySetInnerHTML={{ __html: questionText }} />
            {attributeTypeSupported && <Text>{QuestionnaireS.formatAnswer(attributeType, customerAnswer)}</Text>}
            {file && (
              <FileDetails
                file={file}
                entityType="formSubmissions"
                entityId={formId}
                reload={reload}
                preview={previewFile}
              />
            )}
            {!attributeTypeSupported && !file && (
              <Text italic color="dimmed" size="xs" weight="lighter">
                Noch nichts hinzugefügt.
              </Text>
            )}
            {!loading && !attributeValueSynced && attributeValueNotEmpty && (
              <Text italic size="xs" color="red" weight="bolder">
                Überschreibt bestehende Eingabe:{' '}
                <Text weight="normal" span>
                  {QuestionnaireS.formatAnswer(attributeType, attributeValue as string)}
                </Text>
              </Text>
            )}
            {attributeValueSynced && (
              <Text italic size="xs" color="dimmed">
                Antwort ist im eigenem Feld eingetragen
              </Text>
            )}
          </Stack>
          {!targetAttributeId && !addingAttribute && attributeTypeSupported && (
            <Stack spacing={0} align="center">
              <Text size="xs">Kein Eigenes Feld verknüpft.</Text>
              <Text
                size="xs"
                color="blue"
                variant="link"
                sx={{ cursor: 'pointer' }}
                onClick={() => setAddingAttribute(true)}
              >
                Feld verknüpfen
              </Text>
            </Stack>
          )}
          {(targetAttributeId || addingAttribute) && attributeTypeSupported && (
            <Stack spacing={0}>
              <BookitupSelect
                size="xs"
                data={DataS.getCustomAttributeOptions(alternatives)}
                value={selectedAttributeName}
                onChange={(val) => val && selectAttributeName(val)}
                itemComponent={AutocompleteCustomAttributeItem}
                sx={{ maxWidth: 150 }}
              />
              <Text
                size="xs"
                variant="link"
                color="red"
                ml="xs"
                mt={5}
                onClick={() => changeAttributeId(null)}
                sx={{ cursor: 'pointer' }}
              >
                - Verknüpfung entfernen
              </Text>
            </Stack>
          )}
        </Group>
      </Group>
      <Divider />
    </>
  );
};

export default QuestionSubmission;
