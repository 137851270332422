/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Button, Center, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconTag } from '@tabler/icons';
import { FC } from 'react';
import { i18n } from '@lingui/core';
import { closeAllModals, closeModal } from '@mantine/modals';
import { ResourceCategory } from '../../../Types/ResourceT';
import CategoryInputField from '../../../Organismns/Resources/CategoryInputField';
import ResourceS from '../../../Service/ResourceS';

interface Props {
  modalId?: string;
  initialCategoryName?: string;
  initialCategory?: ResourceCategory;
  onCreate: (category: Partial<ResourceCategory>) => void;
  existingNames?: string[];
}

const AddEditResourceCategoryModal: FC<Props> = ({
  initialCategoryName,
  modalId,
  initialCategory,
  onCreate,
  existingNames = [],
}) => {
  const validateName = (value: string) => {
    if (value.length === 0) {
      return 'Bitte einen Name eingeben';
    }
    if (existingNames.includes(value)) {
      return 'Name ist bereits vergeben';
    }
    return null;
  };

  const form = useForm({
    initialValues: {
      name: initialCategoryName ?? initialCategory?.name ?? '',
    },
    validate: {
      name: validateName,
    },
  });

  return (
    <form onSubmit={form.onSubmit(onCreate)}>
      <TextInput mt="xl" mb="sm" label="Name" icon={<IconTag size={16} />} {...form.getInputProps('name')} />
      <CategoryInputField
        initialName={initialCategory?.parentName}
        placeholder="Innerhalb Kategorie"
        onSelected={(val) => form.setFieldValue('parentName', val)}
        forbiddenNames={
          initialCategory ? [initialCategory.name, ...ResourceS.getChildrenNames(initialCategory)] : undefined
        }
      />
      <Center>
        <Box sx={{ width: '70%' }}>
          <Button fullWidth mt="xl" mb="xs" type="submit">
            {i18n._('actions.save')}
          </Button>
          <Button
            fullWidth
            color="gray"
            variant="subtle"
            onClick={() => (modalId ? closeModal(modalId) : closeAllModals())}
          >
            {i18n._('actions.cancel')}
          </Button>
        </Box>
      </Center>
    </form>
  );
};

export default AddEditResourceCategoryModal;
