import React, { useState } from 'react';
import { Trans } from '@lingui/react';
import { ModalDialog } from '../../Dialogs';
import { TransButtonDefault, TransButtonPrimaryWithSpinner } from '../../../Atoms/Buttons';
import SelectQuestionnaireTemplate from '../../Event/Questionnaires/SelectQuestionnaireTemplate';

export default function ({ open, toggle, isLoading, finish }) {
  const [template, setTemplate] = useState(undefined);

  const styles = {
    templateSubject: {
      margin: '15px 0px 5px 0px',
    },
  };

  return (
    <ModalDialog
      header={<Trans id="template.select.dialog-title" />}
      help="help.questionnaire.template.select"
      body={
        <div>
          <div style={styles.templateSubject}>Vorlage auswählen:</div>
          <SelectQuestionnaireTemplate template={template} onChange={setTemplate} />
        </div>
      }
      footer={
        <div>
          <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />{' '}
          <TransButtonPrimaryWithSpinner
            isLoading={isLoading}
            onClickHandler={() => {
              finish(template);
            }}
            text="common.apply"
          />
        </div>
      }
      show={open}
      toggle={toggle}
    />
  );
}
