import {useContext} from 'react';
import {useJwtClaims} from "./AuthContext";
import {ProductFruits} from "react-product-fruits";
import {ContactContext} from "./ContactContext";

export const ProductFruitsInit = () => {
  const { profileId, email, supportLogin, webSessions, licence, trialEndsAt } = useJwtClaims();
  const { contact } = useContext<any>(ContactContext);

  const userInfo = {
    username: profileId + '',
    email,
    firstname: contact.firstName,
    lastname: contact.lastName,
    props: {
      profileId,
      licence,
      webSessions: webSessions ?? 0,
      trialEndsAt: trialEndsAt + ''
    }
  };

  if (supportLogin) {
    return null;
  }

  return (
    <ProductFruits workspaceCode="MbePjtR7kybgTx07" language="en" user={userInfo} />
  )
};
