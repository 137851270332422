// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Flex, Skeleton, Space, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import LocationDistance from '../LocationDistance';
import { openEditContactModal } from '../../../Organismns/Contact/EditContactModal';
import { Contact, ContactS } from '../../../Service/ContactS';
import { RecommendationDto } from '../../../Types/RecommendationT';
import RecommendationActions from './RecommendationActions';
import RecommendationAnswerStatus from './RecommendationAnswerStatus';
import RecommendationStateStatus from './RecommendationState';
import ExportEventInfoStatus from './ExportEventInfoStatus';

const RecommendationRequest: FC<{ recommendation: RecommendationDto }> = ({ recommendation }) => {
  const [contact, setContact] = useState<Contact>();
  const [loadingContact, setLoadingContact] = useState(false);

  const { recommendedContactId, answer } = recommendation;

  useEffect(() => {
    setLoadingContact(true);
    ContactS.fetchById(recommendedContactId)
      .then((_contact) => _contact && setContact(_contact))
      .finally(() => setLoadingContact(false));
  }, [recommendedContactId]);

  if (loadingContact) {
    return <Skeleton width="100%" height={100} />;
  }
  if (!contact) {
    return null;
  }

  const { personDescription, email } = contact;
  const showLocationInfo = answer === 'UNKNOWN' || answer === 'AVAILABLE';
  const showExportInfo = email && answer === 'AVAILABLE';

  return (
    <Box p="sm" sx={{ borderRadius: 10, backgroundColor: '#F1F1F1' }}>
      <Flex>
        <Text
          weight="bolder"
          onClick={() => openEditContactModal(contact, undefined, setContact)}
          sx={{ cursor: 'pointer', flexGrow: 1 }}
          className="overflow-ellipsis"
        >
          {personDescription ?? email}
        </Text>
        <RecommendationActions recommendation={recommendation} contactEmail={email} />
      </Flex>
      {showLocationInfo && <LocationDistance contactId={recommendedContactId} />}
      <Space h="sm" />
      <RecommendationAnswerStatus recommendation={recommendation} />
      {showExportInfo && (
        <>
          <Box ml={18} sx={{ width: 5, height: 5, backgroundColor: 'white' }} />
          <ExportEventInfoStatus recommendation={recommendation} />
        </>
      )}
      <Box ml={18} sx={{ width: 5, height: 5, backgroundColor: 'white' }} />
      <RecommendationStateStatus recommendation={recommendation} contactEmail={email} />
    </Box>
  );
};

export default RecommendationRequest;
