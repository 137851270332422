import React, { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { LoadingAnimation } from '../../Service/ResourceAdapter';
import { patchRecommendationAnswer } from '../../Service/restapi/recommendationService';
import { useQuery } from '../../Helpers/UrlQuery';

export default function ({ id, savedAnswer, lastAnswered, sourceProfileAlias, reloadRecommendation }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);
  const query = useQuery();
  const answer = query.get('answer');

  if (!answer) {
    return (
      <>
        {lastAnswered && (
          <div>
            <div className="healine">
              Du bist{' '}
              {savedAnswer === 'AVAILABLE'
                ? 'verfügbar'
                : savedAnswer === 'UNAVAILABLE'
                ? 'nicht verfügbar'
                : 'geblockt'}
            </div>
            {lastAnswered && (
              <div className="description">
                Deine Antwort vom <Moment format="DD.MM.YYYY">{lastAnswered}</Moment>
              </div>
            )}
          </div>
        )}
        {!lastAnswered && (
          <div>
            <div className="healine">
              Deine Verfügbarkeit wurde von {sourceProfileAlias} auf&nbsp;
              {savedAnswer === 'AVAILABLE'
                ? 'verfügbar'
                : savedAnswer === 'UNAVAILABLE'
                ? 'nicht verfügbar'
                : 'geblockt'}{' '}
              gesetzt.
            </div>
          </div>
        )}
      </>
    );
  }

  useEffect(() => {
    if (id && answer) {
      setLoading(true);
      patchRecommendationAnswer(id, answer)
        .then((resp) => {
          if (resp.ok) {
            reloadRecommendation();
            setError(false);
          } else {
            console.error(`could not patch answer ${answer} of request ${id}`);
            setError(true);
          }
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not patch answer ${answer} of request ${id}`);
          setError(true);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <div>
      {loading && <LoadingAnimation />}
      {!loading && !error && (
        <div>
          <h5>Antwort übermittelt</h5>
          <div>Vielen Dank - Deine Antwort wurde erfolgreich übermittelt.</div>
        </div>
      )}
      {!loading && error && (
        <p>
          Es ist ein Fehler aufgetreten - Deine Antwort konnte nicht übermittelt werden.
          <br />
          <br />
          Bitte versuche es in einigen Sekunden noch einmal.
        </p>
      )}
    </div>
  );
}
