// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Accordion, Box, Stack, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { Planning, PlanningGroup } from '../../Types/ResourceT';
import ResourcePlanningS from '../../Service/restapi/resourcePlanningS';
import ResourcePlanningTable from '../../Organismns/Resources/table/planning/ResourcePlanningTable';
import PlanningGroupActions from './PlanningGroupActions';

interface Props {
  group: PlanningGroup;
  previewOpened: boolean;
  setShowPreview: (show: boolean) => unknown;
}

const EventPlanningGroup: FC<Props> = ({ group, previewOpened, setShowPreview }) => {
  const { id, description, begin, end } = group;
  const [loading, setLoading] = useState(false);
  const [plannings, setPlannings] = useState<Planning[]>([]);

  const fetchPlannings = () => {
    setLoading(true);
    ResourcePlanningS.fetchPlannings(id)
      .then(setPlannings)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPlannings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Accordion.Item value={id}>
      <Box sx={{ display: 'flex' }}>
        <Accordion.Control onClick={() => setShowPreview(!previewOpened)}>
          <Stack spacing={0}>
            <Text mb={3}>{description}</Text>
            <Text color="dimmed" size="xs" weight="lighter">{`${moment(begin).format('DD.MM.YYYY HH:mm')} - ${moment(
              end,
            ).format('DD.MM.YYYY HH:mm')}`}</Text>
          </Stack>
        </Accordion.Control>
        <PlanningGroupActions group={group} refresh={fetchPlannings} closeAccordionItem={() => setShowPreview(false)} />
      </Box>
      <Accordion.Panel>
        {!loading && plannings.length === 0 && (
          <Text italic color="dimmed" size="xs" weight="lighter">
            Noch nichts hinzugefügt.
          </Text>
        )}
        {!loading && plannings.length > 0 && <ResourcePlanningTable plannings={plannings} refresh={fetchPlannings} />}
      </Accordion.Panel>
    </Accordion.Item>
  );
};

export default EventPlanningGroup;
