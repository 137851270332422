import { useEffect } from 'react';
import mixpanel from 'mixpanel-browser';
import { useJwtClaims } from '../Context/AuthContext';

// eslint-disable-next-line import/prefer-default-export
export const useMixpanel = () => {
  const token = process.env.REACT_APP_MIXPANEL_TOKEN;
  const jwtClaims = useJwtClaims();
  const { profileId } = jwtClaims;

  useEffect(() => {
    if (token) {
      // Route data to Mixpanel's EU servers
      mixpanel.init(token, { api_host: 'https://api-eu.mixpanel.com' });
      window.mixpanel = mixpanel;
    } else {
      // eslint-disable-next-line no-console
      console.warn('Mixpanel is disabled for this env');
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      if (profileId) {
        mixpanel.identify(profileId.toString());
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { email, ...rest } = jwtClaims;
        mixpanel.people.set({ ...rest, $email: email });
      } else {
        mixpanel.reset();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId, token]);
};
