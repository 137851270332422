import React, { useContext, useState } from 'react';
import { useParams, Outlet } from 'react-router';
import ResourceAdapter from '../ResourceAdapter';
import { DocumentContext } from './DocumentContext';

export const DocumentContactContext = React.createContext(undefined);

const DocumentContactContextProvider = (props) => {
  const [documentContact, setDocumentContact] = useState(props.documentContact);
  const { id } = useParams();

  return (
    <DocumentContactContext.Provider
      value={{
        documentContact,
        changeDocumentContact: (patch) => {
          setDocumentContact((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
      }}
    >
      <Outlet />
    </DocumentContactContext.Provider>
  );
};

export const DocumentContactContextProviderProxy = () => {
  const { document } = useContext(DocumentContext);

  return (
    <ResourceAdapter
      serviceUrl={`${process.env.REACT_APP_AUTH_SERVICE_URL}/contacts/${document.contactId}`}
      component={(props) => {
        const contact = props.data;
        return <DocumentContactContextProvider documentContact={contact} />;
      }}
    />
  );
};
