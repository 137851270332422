/* eslint-disable react/jsx-props-no-spreading */
import { FC, useEffect, useState } from 'react';
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text, Paper, TextInput, PasswordInput, Button, Space, LoadingOverlay, Divider } from '@mantine/core';
import { IconAt, IconLock } from '@tabler/icons';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import validator from 'validator';
import { useMediaQuery } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { userActions } from '../../Service/UserAccountActions';
import { RoutingS } from '../../Service/RoutingS';
import { AlertS } from '../../Service/AlertS';
import { TestS } from '../../TestS';
import { ConstantS } from '../../Service/ConstantS';
import { MixpanelS } from '../../Service/MixpanelS';
import AlertBox from './AlertBox';
import GoogleAuthButton from './GoogleAuthButton';

const LoginPage: FC = () => {
  const isPhoneDevice = useMediaQuery('(max-width: 480px)');
  const navigate: NavigateFunction = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string | null>(searchParams.get('alert'));

  const form = useForm({
    initialValues: {
      email: '',
      password: '',
    },
    validate: {
      email: (value) => (validator.isEmail(value) ? null : 'Bitte gib eine valide E-Mail-Adresse ein'),
      password: (value) => (value.length > 0 ? null : 'Bitte Passwort eingeben'),
    },
  });

  const signIn = () => {
    const { email, password } = form.values;
    setLoading(true);
    userActions
      .login(email, password)
      .then((resp) => {
        if (resp.ok) {
          ReactPixel.trackCustom(ConstantS.TrackingEvents.LoginSuccessful);
          ReactGA.event({ category: 'User', action: ConstantS.TrackingEvents.LoginSuccessful });
          MixpanelS.track(ConstantS.TrackingEvents.LoginSuccessful);
        }
        if (!resp.ok) {
          if (resp.status === 403) {
            setAlert(AlertS.AlertType.WRONG_CRENDENTIALS);
          } else {
            setAlert(AlertS.AlertType.UNEXPECTED_ERROR);
          }
          setLoading(false);
        }
      })
      .catch(() => setAlert(AlertS.AlertType.SERVER_UNAVAILABLE));
  };

  useEffect(() => {
    document.title = 'Anmelden';
  }, []);

  return (
    <Paper
      shadow="xs"
      p={40}
      sx={{
        maxWidth: 480,
        borderRadius: 10,
        position: 'relative',
        backgroundClip: 'padding-box, border-box',
        backgroundImage: 'linear-gradient(#fff,#fff),linear-gradient(88deg,#ffba5e,#ff6b69)',
        borderTop: '10px double transparent',
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Space h="md" />
      <Text size={isPhoneDevice ? 22 : 28}>Herzlich Willkommen!</Text>
      <Space h="xs" />
      <Text color="dimmed" size={isPhoneDevice ? 'sm' : 'md'}>
        Schön, dass Du heute da bist. Bitte melde Dich an.
      </Text>
      <Space h="xl" />
      {alert && <AlertBox alert={alert} />}
      <form onSubmit={form.onSubmit(signIn)}>
        <TextInput
          data-testid={TestS.Login.INPUT_FIELD_USER_MAIL}
          label="E-Mail"
          icon={<IconAt size={14} />}
          {...form.getInputProps('email')}
        />
        <Space h="xs" />
        <PasswordInput
          data-testid={TestS.Login.INPUT_FIELD_USER_PASSWORD}
          label="Passwort"
          icon={<IconLock size={16} />}
          {...form.getInputProps('password')}
        />
        <Text
          align="right"
          color="blue"
          mt={10}
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(RoutingS.ROUTES.PASSWORD_FORGOTTEN)}
        >
          Passwort vergessen?
        </Text>
        <Space h="xl" />
        <Button data-testid={TestS.Login.BUTTON_SUBMIT_LOGIN} fullWidth type="submit">
          Anmelden
        </Button>
      </form>
      <Space h="md" />
      <Divider my="xs" label="weitere Möglichkeiten" labelPosition="center" />
      <GoogleAuthButton text="signin_with" setLoading={setLoading} setAlert={setAlert} />
      <Space h="xs" />
      <Text color="dimmed" align="center">
        Du hast noch keinen Account?
      </Text>
      <Text color="blue" align="center" sx={{ cursor: 'pointer' }} onClick={() => navigate(RoutingS.ROUTES.REGISTER)}>
        Kostenlos registrieren
      </Text>
    </Paper>
  );
};

export default LoginPage;
