/* eslint-disable react/jsx-props-no-spreading */
import { Autocomplete, AutocompleteProps, Loader } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { IconX } from '@tabler/icons';
import ResourceCategoryS from '../../Service/restapi/resourceCategoryService';

interface Props extends Partial<AutocompleteProps> {
  initialName?: string;
  onSelected: (category: string | null) => void;
  forbiddenNames?: string[];
}

const CategoryInputField: FC<Props> = ({ initialName, onSelected, forbiddenNames = [], ...rest }) => {
  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<string>(initialName ?? '');
  const [error, setError] = useState(false);

  useEffect(() => {
    setLoading(true);
    ResourceCategoryS.fetchAllOptions()
      .then((resJSON) => {
        setOptions(resJSON.filter((name) => !forbiddenNames.includes(name)));
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (forbiddenNames.includes(value)) {
      setError(true);
    } else if (error) {
      setError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onBlur = () => {
    if (error) {
      return;
    }
    if (value === null || value === '') {
      onSelected(null);
    } else {
      onSelected(value);
    }
  };

  const onClear = () => {
    setValue('');
    onSelected(null);
  };

  return (
    <Autocomplete
      error={error && 'Name kann nicht verwendet werden'}
      label="Kategorie"
      onBlur={onBlur}
      onChange={setValue}
      value={value}
      data={options}
      icon={loading && <Loader size="xs" />}
      rightSection={value && <IconX size={16} onClick={onClear} />}
      sx={{ cursor: 'pointer' }}
      {...rest}
    />
  );
};

export default CategoryInputField;
