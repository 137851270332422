import { FC, useEffect, useState } from 'react';
import { Loader, MultiSelect, Select, Text } from '@mantine/core';
import { IconPaperclip, IconTemplate } from '@tabler/icons';
import { i18n } from '@lingui/core';
import { SendMailConfigDTO, WorkflowConfigProps } from '../../../../../Types/LogT';
import { DataS } from '../../../../../Service/DataS';
import { MessageS } from '../../../../../Service/MessageS';
import { MessageTemplateDto } from '../../../../../Types/MessageT';
import { WorkflowS } from '../../../../../Service/WorkflowS';

const SendMailConfig: FC<WorkflowConfigProps> = ({ config, handleChange, availableEntities }) => {
  const { templateId, attachments } = config as SendMailConfigDTO;
  const [templates, setTemplates] = useState<MessageTemplateDto[]>([]);
  const [loading, setLoading] = useState(false);

  const availableAttachments = WorkflowS.filterEntities(availableEntities, [
    'documents',
    'questionnaires',
    'contracts',
  ]);
  const initialAttachments = attachments ? attachments.map((a) => a.id) : [];

  useEffect(() => {
    setLoading(true);
    MessageS.fetchTemplates()
      .then(setTemplates)
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Select
        required
        icon={<IconTemplate size={20} />}
        label="Mail-Vorlage"
        rightSection={loading && <Loader size="xs" />}
        disabled={loading}
        data={DataS.getMessageTemplateOptions(templates, i18n)}
        onChange={(id) => handleChange({ templateId: id })}
        value={templateId ? templateId.toString() : null}
      />
      <Text mt={5} size="xs" color="dimmed">
        Die E-Mail wird nur an den Hauptkunden gesendet.
      </Text>
      <MultiSelect
        mt="sm"
        defaultValue={initialAttachments}
        onChange={(entities) => handleChange({ attachments: entities })}
        icon={<IconPaperclip size={20} />}
        label="Anhang"
        data={DataS.getEntityReferencesOptions(availableAttachments)}
      />
      <Text mt={5} size="xs" color="dimmed">
        Hänge zuvor erstellte Dokumente an diese E-Mail an.
      </Text>
    </>
  );
};

export default SendMailConfig;
