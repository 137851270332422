import React, { useContext } from 'react';
import RecommendationChild from '../RecommendationChild';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import EventBookingState from '../../Event/EventBookingState';
import RecommendationPersonDescription from './RecommendationPersonDescription';
import { Paper, Text } from '@mantine/core';

const EventRecommendationListWrapper = ({ recommendations, profileBookingState }) => {
  const { profile } = useContext(ProfileContext);

  if (!recommendations) {
    return null;
  }

  const styles = {
    listWrapper: {
      background: '#FAFAFA 0% 0% no-repeat padding-box',
      border: '1px solid #E5E5E5',
      padding: 10,
      cursor: 'pointer',
    },
    recommendationsListWrapper: {
      border: '1px solid #E5E5E5',
      borderRadius: 5,
    },
    recommendation: {
      padding: 10,
    },
    recommendationBorder: {
      borderBottom: '1px solid #E5E5E5',
    },
  };

  return (
    <Paper shadow="xs" p="md" sx={{ borderRadius: 10 }}>
      <div
        style={{
          ...styles.recommendationsListWrapper,
          ...styles.recommendation,
          // backgroundColor: '#9BACBB',
          marginBottom: 10,
          display: 'flex',
        }}
      >
        <RecommendationPersonDescription contactId={profile.contactId} alias={profile.alias} />
        <EventBookingState state={profileBookingState} />
      </div>

      {recommendations.length > 0 ? (
        <div style={styles.recommendationsListWrapper}>
          {recommendations.map((recommendation, index) => {
            let style = styles.recommendation;
            if (recommendations.length !== index + 1) {
              style = {
                ...style,
                ...styles.recommendationBorder,
              };
            }
            return (
              <div style={style} key={index}>
                <RecommendationChild recommendation={recommendation} />
              </div>
            );
          })}
          {}
        </div>
      ) : (
        <Text italic color="dimmed" size="xs" weight="lighter">
          Bisher wurden keine Vermittlungen hinzugefügt.
        </Text>
      )}
    </Paper>
  );
};

export default EventRecommendationListWrapper;
