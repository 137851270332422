import { useState } from 'react';
import { withI18n } from '@lingui/react';
import { withCookies } from 'react-cookie';
import { Outlet, useParams } from 'react-router-dom';
import ResourceAdapter from '../../../../Service/ResourceAdapter';
import { patchContractTemplate } from '../../../../Service/restapi/contractTemplateService';
import { ContractContext } from '../../../../Service/Context/ContractContext';

const ContractTemplateProvider = (props) => {
  const [contract, setContract] = useState(props.contractTemplate);
  return (
    <ContractContext.Provider
      value={{
        contract,
        handleChange: (patch) => {
          setContract((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
        savePatch: (patch) => {
          patchContractTemplate(contract.id, patch)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((newContract) => {
                  setContract(newContract);
                });
              } else {
                console.error(`could not save patch of contract ${contract.name}`);
              }
            })
            .catch((error) => {
              console.error(`could not save patch of contract ${contract.name}`);
              console.error({ error });
            });
        },
      }}
    >
      <Outlet />
    </ContractContext.Provider>
  );
};

export default withCookies(
  withI18n()((outerProps) => {
    const { templateName } = useParams();

    return (
      <ResourceAdapter
        serviceUrl={`${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/contracts/templates/${templateName}`}
        component={(props) => {
          const tmp = props.data;
          return <ContractTemplateProvider contractTemplate={tmp} {...outerProps} />;
        }}
      />
    );
  }),
);
