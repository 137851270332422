// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { useContext } from 'react';
import { useModals } from '@mantine/modals';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import CreateDocumentModal from '../../Molecules/Event/Documents/CreateDocumentModal';
import { EventParticipationsContext } from '../../Service/Context/EventParticipationContext';
import { TestS } from '../../TestS';
import { DocumentsContext } from '../../Service/Context/DocumentsContext';
import { ViolationContext } from '../../Service/Context/ViolationContext';

const DocumentHeadActions = () => {
  const { openModal } = useModals();
  const { openLicenceModal } = useContext(ViolationContext);
  const eventParticipantsContext = useContext(EventParticipationsContext);
  const documentsContext = useContext(DocumentsContext);
  const refresh = documentsContext ? documentsContext.reload : undefined;

  const openCreateDocumentModal = () => {
    openModal({
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Vorlage wählen
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: (
        <CreateDocumentModal
          openLicenceModal={openLicenceModal}
          eventParticipationContext={eventParticipantsContext}
          refresh={refresh}
        />
      ),
    });
  };

  return <BookitupAddIcon onClick={openCreateDocumentModal} testId={TestS.Documents.CREATE_DOCUMENT} />;
};

export default DocumentHeadActions;
