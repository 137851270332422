import { i18n } from '@lingui/core';
import { Button, Group, Loader, TextInput } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import { FC, useState } from 'react';
import { RecruitmentCampaign, RecruitmentS } from '../../../Service/restapi/recruitmentService';

interface Props {
  campaign: RecruitmentCampaign;
  handleChange: (patch: RecruitmentCampaign) => void;
}

const EditCampaignModal: FC<Props> = ({ campaign, handleChange }) => {
  const { id, name: initialName } = campaign;
  const [campaignName, setCampaignName] = useState(initialName);
  const [loading, setLoading] = useState(false);

  const patchCampaign = () => {
    setLoading(true);
    RecruitmentS.updateRecruitmentCampaign(id, { name: campaignName })
      .then((patch) => patch && handleChange(patch))
      .finally(() => {
        closeAllModals();
        setLoading(false);
      });
  };

  return (
    <>
      <TextInput
        value={campaignName}
        onChange={(e) => setCampaignName(e.target.value)}
        label={i18n._('recruitment-campaign.name')}
      />
      <Group spacing="xs" mt="xl" position="right">
        <Button variant="default" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          disabled={loading || campaignName.length === 0}
          onClick={patchCampaign}
          leftIcon={loading && <Loader size="xs" />}
        >
          {i18n._('actions.save')}
        </Button>
      </Group>
    </>
  );
};

export default EditCampaignModal;
