import React, { useState } from 'react';
import { Trans, useLingui, withI18n } from '@lingui/react';
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Popover,
  PopoverBody,
  PopoverHeader,
} from 'reactstrap';

import { TransButtonDefault, TransButtonPrimaryWithSpinner, TransDropdownItemWithIcon } from './Buttons';
import { Icon } from './Icons';

export const Dropdown = ({
  options,
  caret,
  value,
  left,
  icon,
  style,
  buttonContainer,
  dropdownMenu,
  dropdownItem,
  buttonDropdownStyle,
  iconStyle,
  textStyle,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen(!isOpen);
  }

  const dropdownItems = [];
  let obj;
  let i = 0;
  for (obj in options) {
    if (dropdownItems.length > 0 && options[obj].length > 0) {
      dropdownItems.push(<DropdownItem key={`devider_${i}`} divider />);
    }
    {
      options[obj].map((item, index) => {
        dropdownItems.push(
          <TransDropdownItemWithIcon
            disabled={item.disabled}
            icon={item.icon}
            key={`${i}_${index}`}
            text={item.text}
            values={item.values}
            onClick={item.onClick}
            style={dropdownItem}
          />,
        );
      });
    }
    i++;
  }

  const onOffState = {};
  if (dropdownItems.length === 0) {
    onOffState.disabled = true;
  }

  const styles = {
    button: {
      minWidth: 18,
      display: 'flex',
      alignItems: 'center',
    },
    dropdown: {
      ...dropdownMenu,
    },
    icon: {
      marginRight: 3,
      marginLeft: 3,
      maxWidth: 32,
      ...iconStyle,
    },
    text: {
      marginRight: caret ? 5 : 'unset',
      marginLeft: 5,
      ...textStyle,
    },
    buttonContainer: {
      display: 'flex',
      padding: 3,
      ...buttonContainer,
    },
  };

  return (
    <ButtonDropdown isOpen={isOpen} toggle={toggle} style={buttonDropdownStyle}>
      <DropdownToggle
        caret={caret !== undefined ? caret : true}
        color=""
        className="clickable"
        style={{ ...styles.button, ...style }}
        size="sm"
      >
        <div style={styles.buttonContainer}>
          {icon && <Icon style={styles.icon} value={icon} />}
          {(value !== undefined || placeholder !== undefined) && (
            <div style={styles.text}>
              {value !== undefined ? <Trans id={value} /> : <Trans id={placeholder || 'actions.select'} />}
            </div>
          )}
        </div>
      </DropdownToggle>

      <DropdownMenu style={styles.dropdown} className={`animate slideIn ${left ? 'left' : ''}`}>
        {dropdownItems}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export const DropdownBody = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen(!isOpen);
  }

  const dropdownItems = [];
  let obj;
  let i = 0;
  for (obj in props.options) {
    if (dropdownItems.length > 0 && props.options[obj].length > 0) {
      dropdownItems.push(<DropdownItem key={`devider_${i}`} divider />);
    }
    {
      props.options[obj].map((item, index) => {
        dropdownItems.push(
          <TransDropdownItemWithIcon
            icon={item.icon}
            key={`${i}_${index}`}
            text={item.text}
            values={item.values}
            onClick={item.onClick}
          />,
        );
      });
    }
    i++;
  }

  const onOffState = {};
  if (dropdownItems.length === 0) {
    onOffState.disabled = true;
  }

  return (
    <ButtonDropdown isOpen={isOpen} toggle={toggle}>
      <DropdownToggle caret={false} style={props.style} size="sm">
        {props.body}
      </DropdownToggle>
      <DropdownMenu>{dropdownItems}</DropdownMenu>
    </ButtonDropdown>
  );
};

export const DropdownWithMain = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  function toggle() {
    setIsOpen(!isOpen);
  }

  const dropdownItems = [];
  let obj;
  let i = 0;
  for (obj in props.options) {
    if (dropdownItems.length > 0 && props.options[obj].length > 0) {
      dropdownItems.push(<DropdownItem key={`devider_${i}`} divider />);
    }
    {
      props.options[obj].map((item, index) => {
        dropdownItems.push(
          <TransDropdownItemWithIcon
            icon={item.icon}
            key={`${i}_${index}`}
            text={item.text}
            values={item.values}
            onClick={item.onClick}
          />,
        );
      });
    }
    i++;
  }

  const onOffState = {};
  if (dropdownItems.length === 0) {
    onOffState.disabled = true;
  }

  if (dropdownItems.length === 0) {
    return (
      <TransButtonPrimaryWithSpinner
        isLoading={props.isLoading}
        disabled={props.isLoading}
        onClickHandler={props.main}
        text={props.text}
        icon={props.icon}
      />
    );
  }

  const styles = {
    dropdownButton: {
      borderLeft: '1px solid #eee',
      padding: 5,
    },
    dropdown: {
      transform: 'translate3d(0px, -18px, 10px)',
    },
  };

  return (
    <ButtonDropdown isOpen={isOpen} toggle={toggle}>
      <TransButtonPrimaryWithSpinner
        isLoading={props.isLoading}
        disabled={props.isLoading}
        onClickHandler={props.main}
        text={props.text}
        icon={props.icon}
      />
      <DropdownToggle color="primary" caret style={{ ...styles.dropdownButton, ...props.style }} />
      <DropdownMenu style={styles.dropdown}>{dropdownItems}</DropdownMenu>
    </ButtonDropdown>
  );
};

export const GenericPopover = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { i18n } = useLingui();

  function toggle() {
    setIsOpen(!isOpen);
  }

  const styles = {
    item: {
      padding: 5,
    },
  };

  const dropdownItems = [];
  let obj;
  let i = 0;
  for (obj in props.options) {
    if (dropdownItems.length > 0 && props.options[obj].length > 0) {
      dropdownItems.push(<DropdownItem key={`devider_${i}`} divider />);
    }
    {
      props.options[obj].map((item, index) => {
        dropdownItems.push(
          <div
            style={styles.item}
            className="clickable overflow-ellipsis"
            key={`${i}_${index}`}
            onClick={() => {
              toggle();
              item.onClick();
            }}
          >
            {i18n._(item.text)}
          </div>,
        );
      });
    }
    i++;
  }

  const onOffState = {};
  if (dropdownItems.length === 0) {
    onOffState.disabled = true;
  }

  return (
    <div>
      <div onClick={toggle} id={props.id}>
        {props.children}
      </div>

      <Popover placement="bottom" isOpen={isOpen} target={props.id} toggle={toggle}>
        <PopoverHeader>{props.title}</PopoverHeader>
        <PopoverBody>{dropdownItems}</PopoverBody>
      </Popover>
    </div>
  );
};

export const DocumentTypeDropdown = (props) => {
  const dropdownItems = [];

  if (props.documentType === 'CONTRACT') {
    dropdownItems.push({ text: 'document.contract', onClick: () => props.onClick('CONTRACT') });
  } else {
    dropdownItems.push({ text: 'document.offer', onClick: () => props.onClick('OFFER') });
    dropdownItems.push({ text: 'document.confirmation', onClick: () => props.onClick('CONFIRMATION') });
    dropdownItems.push({ text: 'document.invoice', onClick: () => props.onClick('INVOICE') });
  }

  return (
    <GenericPopover
      id={`document-type-select${props.documentId}`}
      title="Dokument-Typ auswählen"
      value={props.documentType ? `document.${props.documentType.toLowerCase()}` : undefined}
      options={{ dropdownItems }}
      {...props}
    >
      <TransButtonDefault text={props.placeholder} />
    </GenericPopover>
  );
};

// export const AttachmentDropdown = (props) => {
//     let items = {docs: [], files: []};

//     props.attachments.map((docsOrFiles, index) => {
//         docsOrFiles.map((value, i) => {
//             if (value.kind === "GENERATE") {
//                 items.docs.push({text: "document.template." + value.documentType.toLowerCase(), onClick: () => {props.add(Object.assign({}, value))}});
//             }

//             if (value.kind === "EXISTING") {
//                 items.docs.push({text: "document." + value.documentType.toLowerCase() + ".id", values: {0: value.documentId}, onClick: () => props.add(value)})
//             }

//             if (value.kind === "FILESYSTEM") {
//                 items.files.push({text: value.fileName, onClick: () => props.add(value)})
//             }

//         });
//     });

//     return (
//         <ToolTipPopover value={props.text} icon={props.icon} id={props.id} title={props.title} options={items}/>
//     );
// }
