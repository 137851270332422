import axios from 'axios';
import moment from 'moment';
import { ajaxActions } from '../AjaxActions';
import BookingState from '../../Types/Enums/BookingState';
import BookingEvent from '../../Types/Event';

const BASE_URL = process.env.REACT_APP_EVENT_SERVICE_URL;

export const fetchEventById = (eventId: string) =>
  ajaxActions.get(`${BASE_URL}/events/${eventId}`).then((resp) => resp);

export const fetchEventInfosById = (eventId: string) =>
  ajaxActions.get(`${BASE_URL}/events/${eventId}/forRecommendation`).then((resp) => resp);

export const fetchEventByRecommendationId = (recommendationId: string) =>
  ajaxActions.get(`${BASE_URL}/events/recommendation/${recommendationId}`).then((resp) => resp);

export const fetchBriefestEventParticipationByContactId = (contactId: string, briefest?: boolean) =>
  ajaxActions
    .get(`${BASE_URL}/eventParticipations/contact/${contactId}${briefest ? '?briefest=true' : ''}`)
    .then((resp) => resp);

/**
 *
 * @param apiContext well-known
 * @param filter {
 *    from: date e.g. today or null for all events
 *    to: date or null
 *    bookingStates: [OPEN | OFFERED | BOOKED | UNAVAILABLE | CANCELED]
 *    sortBy: chronological | createdAt
 *    profiles: [profileId, ...]
 *  }
 * @returns {Promise<{
 *     profiles: {
 *         profileId : events
 *     }
 * }>}
 */
export const fetchEvents = (from: Date, to: Date, bookingStates: BookingState[], profiles: string[]) =>
  ajaxActions
    .get(
      `${BASE_URL}/events/own/filter?from=${from.toISOString()}&to=${
        to ? to.toISOString() : ''
      }&bookingStates=${bookingStates}&profiles=${profiles}`,
    )
    .then((resp) => resp);

/**
 *
 * @param apiContext well-known
 * @param filter {
 *    from: date e.g. today or null for all events
 *    to: date or null
 *    requestAnswers: [AVAILABLE | UNAVAILABLE | UNFIT | BLOCKED] (only if events is recommendations or all)
 *    recommendationContacts: [contactId, ...]
 *    bookingStates: [OPEN | OFFERED | BOOKED | UNAVAILABLE | CANCELED]
 *    sortBy: chronological | createdAt
 *    profiles: [profileId, ...]
 *  }
 * @returns {Promise<{
 *     profiles: {
 *         profileId : events
 *     }
 * }>}
 */
export const fetchRecommendations = (
  from: Date,
  to: Date | null,
  bookingStates: BookingState[],
  profiles: string[],
  availabilities: boolean,
  recommendationContacts: string[],
  filterCustomerContactShared: string[],
  showEventsWithoutRecommendations: boolean,
  customerContactSharedAfter?: Date,
) =>
  ajaxActions
    .get(
      `${BASE_URL}/events/recommendations/filter?from=${from.toISOString()}&to=${
        to ? to.toISOString() : ''
      }&bookingStates=${bookingStates}&profiles=${profiles}&availabilities=${availabilities}&recommendationContacts=${recommendationContacts}&filterCustomerContactShared=${filterCustomerContactShared}${
        customerContactSharedAfter ? `&customerContactSharedAfter=${customerContactSharedAfter.toISOString()}` : ''
      }&showAllEvents=${showEventsWithoutRecommendations}`,
    )
    .then((resp) => resp);

export const fetchEventsChronological = (
  pastEvents: string[],
  onlyRecommendations: boolean,
  recommendedContactId: string,
  answer: boolean,
  lastContactShare: Date | null,
  bookingStates: BookingState[],
) =>
  ajaxActions
    .get(
      `${BASE_URL}/events/calendar?pastEvents=${pastEvents}&onlyRecommendations=${onlyRecommendations}${
        recommendedContactId ? `&recommendedContactId=${recommendedContactId}` : ''
      }${answer ? `&answer=${answer}` : ''}${
        lastContactShare ? `&lastContactShare=${lastContactShare}` : ''
      }&bookingStates=${bookingStates}`,
    )
    .then((resp) => resp);

export const fetchEventsSorted = (sorting: string) =>
  ajaxActions.get(`${BASE_URL}/events/sorted?sorting=${sorting}`).then((resp) => resp);

export const saveEventParticipation = (eventParticipation: any) =>
  ajaxActions.post(`${BASE_URL}/eventParticipations`, eventParticipation).then((resp) => resp);

export const fetchEventParticipations = (eventId: string) =>
  ajaxActions.get(`${BASE_URL}/eventParticipations/${eventId}`).then((resp) => resp);

export const removeContactFromAllEvents = (contactId: string) =>
  ajaxActions.del(`${BASE_URL}/eventParticipations/contact/${contactId}`).then((resp) => resp);

export const saveEvent = (eventPatch: Partial<BookingEvent>) =>
  ajaxActions.post(`${BASE_URL}/events`, eventPatch).then((resp) => resp);

export const getContactIdsWithoutEventParticipation = () =>
  ajaxActions.get(`${BASE_URL}/contacts/events/none`).then((resp) => resp);

export const patchEvent = (eventId: string, eventPatch: Partial<BookingEvent>) =>
  ajaxActions.patch(`${BASE_URL}/events/${eventId}`, eventPatch).then((resp) => resp);

export const deleteEvents = (eventIds: string[]) =>
  ajaxActions.del(`${BASE_URL}/events`, eventIds).then((resp) => resp);

export const searchEvents = async (searchText: string, signal: any) =>
  axios.get(`${BASE_URL}/search?text=${searchText}`, {
    cancelToken: signal.token,
    withCredentials: true,
  });

export const getDescription = (event: Partial<BookingEvent>, i18n: any) => {
  if (!event) return undefined;
  let description;
  if (event.customer) {
    if (event.customer.personDescription) {
      description = event.customer.personDescription;
    } else if (event.customer.email) {
      description = event.customer.email;
    }
  }
  const format = i18n._('format.date.long');

  if (event.dates !== null) {
    description += ` - ${moment(event.dates?.[0].date).format(format)}`;
  }

  return description;
};

export const shareEvent = (eventId: string, recommendedContactId: string, recommendationId: string) =>
  ajaxActions
    .put(
      `${BASE_URL}/export/events/${eventId}?recommendedContactId=${recommendedContactId}&recommendationId=${recommendationId}`,
    )
    .then((resp) => resp);

export const getBookingStateColour = (bookingState: BookingState): string => {
  switch (bookingState) {
    case BookingState.OPEN:
      return '#1D5191';
    case BookingState.OFFERED:
      return '#FDA632';
    case BookingState.BOOKED:
      return '#4EBF46';
    case BookingState.UNAVAILABLE:
      return '#757575';
    case BookingState.CANCELED:
      return '#f44336';
    default:
      return '#616161';
  }
};
