/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, Center, Loader, LoadingOverlay, Select, Text, TextInput } from '@mantine/core';
import { FC, useContext, useState} from 'react';
import { closeAllModals, openModal } from '@mantine/modals';
import { CreateDefaultPlanning, CreatePlanning, PlanningGroup } from '../../Types/ResourceT';
import ResourcePlanningS from '../../Service/restapi/resourcePlanningS';
import SelectionTable from '../../Organismns/Resources/table/general/SelectionTable';
import { ResourcesContext, ResourcesContextProvider } from '../../Service/Context/ResourcesContext';
import PaginationNav from '../../Molecules/Navigation/PaginationNav';
import { stringToNumber } from '../../Utils/utils';
import { AddResourceFilters } from "./AddResourceFilters";

type Props = {
  refresh?: () => void;
  group?: PlanningGroup;
};

export const openAddResourcePlanningModal = (props: Props) =>
  openModal({
    size: 'xl',
    title: (
      <Text weight="bolder" size="xl">
        Ressourcen einplanen
      </Text>
    ),
    closeOnEscape: true,
    transition: 'slide-down',
    children: (
      <ResourcesContextProvider initialFilter={{ showArchived: false, withContent: true }}>
        <AddResourcePlanningModal {...props} />
      </ResourcesContextProvider>
    ),
  });

export const openAddDefaultPlanningModal = (refresh?: () => void) =>
  openModal({
    size: 'xl',
    title: (
      <Text weight="bolder" size="xl">
        Standard-Elemente einplanen
      </Text>
    ),
    closeOnEscape: true,
    transition: 'slide-down',
    children: (
      <ResourcesContextProvider initialFilter={{ showArchived: false, withContent: true }}>
        <AddResourcePlanningModal refresh={refresh} />
      </ResourcesContextProvider>
    ),
  });

const AddResourcePlanningModal: FC<Props> = ({ refresh, group }) => {
  const [loading, setLoading] = useState(false);
  const [selectedResources, setResources] = useState<string[]>([]);
  const { resources, navigation, filter, updateFilter, loading: resourcesLoading } = useContext(ResourcesContext);
  const { totalCount, totalPages } = navigation;
  const { pageNumber, pageSize } = filter;
  const [quantities, setQuantities] = useState<Record<string, number>>({});

  const createResourcePlannings = async () => {
    if (group) {
      setLoading(true);
      const { id: groupId, entityId, entityType, begin, end, usageBegin, usageEnd } = group;

      const plannings: CreatePlanning[] = selectedResources.map((id) => ({
        resourceId: id.length === 36 ? id : id.substring(37),
        plannedQuantity: quantities[id] ?? 1,
        plannedWarehouseQuantity: 0,
        entityType,
        entityId,
        begin,
        end,
        usageBegin,
        usageEnd,
        planningGroupId: groupId,
      }));

      await ResourcePlanningS.createPlannings(plannings);

      setLoading(false);
      refresh?.();
      closeAllModals();
    } else {
      setLoading(true);

      const defaultPlannings: CreateDefaultPlanning[] = selectedResources.map((id) => ({
        resourceId: id.length === 36 ? id : id.substring(37),
        plannedQuantity: 1,
      }));
      await ResourcePlanningS.createDefaultPlannings(defaultPlannings);
      setLoading(false);
      refresh?.();
      closeAllModals();
    }
  };

  const toggle = (resourceId: string) => {
    if (selectedResources.includes(resourceId)) {
      setResources(selectedResources.filter((id) => id !== resourceId));
    } else {
      setResources([...selectedResources, resourceId]);
    }
  };

  const changeQuantity = (resourceId: string, newQuantity: number) =>
    setQuantities((prev) => ({ ...prev, [resourceId]: newQuantity }));

  return (
    <>
      <Center>
        <AddResourceFilters updateFilter={updateFilter} query={filter.query} categoriesOfInterest={filter.categoriesOfInterest} typesOfInterest={filter.typesOfInterest} />
      </Center>
      <Box pos="relative" sx={{ minHeight: 100 }}>
        <LoadingOverlay visible={resourcesLoading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
        <SelectionTable
          resources={resources}
          toggle={toggle}
          selectedResources={selectedResources}
          eventId={stringToNumber(group?.entityId)}
          changeQuantity={changeQuantity}
          quantities={quantities}
        />
        <PaginationNav
          pageSize={pageSize}
          pageNumber={pageNumber}
          totalCount={totalCount}
          totalPages={totalPages}
          entityName="Ressourcen"
          onChange={(newPageNumber) => updateFilter({ pageNumber: newPageNumber - 1 })}
        />
      </Box>
      <Center>
        <Button
          onClick={createResourcePlannings}
          mb="md"
          mt={50}
          type="submit"
          disabled={loading || selectedResources.length === 0}
          leftIcon={loading && <Loader size="xs" />}
          sx={{ width: 200 }}
        >
          Speichern
        </Button>
      </Center>
    </>
  );
};

export default AddResourcePlanningModal;
