import React, { useContext, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import ResourceAdapter from '../ResourceAdapter';
import { EventContext } from '../Context/EventContext';
import {
  deleteQuestion,
  deleteQuestionBundle,
  moveQuestionBundleToIndex,
  moveQuestionToIndex,
  putQuestion,
  putQuestionBundle,
} from '../restapi/questionnaireService';

export const QuestionnaireContext = React.createContext(undefined);

const QuestionnaireContextProvider = ({ questionnaire: initialQuestionnaire }) => {
  const { event } = useContext(EventContext);
  const navigate = useNavigate();
  const [questionnaire, setQuestionnaire] = useState(initialQuestionnaire);
  const [saving, setSaving] = useState(false);
  return (
    <QuestionnaireContext.Provider
      value={{
        questionnaire,
        handleQuestionnaireChange: (index, patch, name) => {
          const newQuestionnaire = { ...questionnaire };
          newQuestionnaire.questions[index] = patch;
          setQuestionnaire((prevState) => newQuestionnaire);
        },
        setQuestionnaire: (questionnaire) => {
          const newQuestionnaire = { ...questionnaire };
          setQuestionnaire(newQuestionnaire);
        },
        addQuestion: (bundleId, index) => {
          const id = uuidv4();
          putQuestion(bundleId, id, index).then((resp) => {
            if (resp.ok) {
              resp.json().then((questionBundle) => {
                const oldQuestionBundle = questionnaire.questionBundles.find((b) => b.id === bundleId);
                const indexOf = questionnaire.questionBundles.indexOf(oldQuestionBundle);
                const newQuestionnaire = { ...questionnaire };
                newQuestionnaire.questionBundles.splice(indexOf, 1, questionBundle);
                setQuestionnaire((prevState) => newQuestionnaire);
              });
            }
          });
        },
        addQuestionBundle: (index) => {
          const id = uuidv4();
          putQuestionBundle(id, questionnaire.id, index).then((resp) => {
            if (resp.ok) {
              resp.json().then((questionnaire) => {
                setQuestionnaire((prevState) => questionnaire);
              });
            }
          });
        },
        delQuestion: (id, bundleId) => {
          deleteQuestion(bundleId, id).then((resp) => {
            if (resp.ok) {
              resp.json().then((questionBundle) => {
                const oldQuestionBundle = questionnaire.questionBundles.find((b) => b.id === bundleId);
                const indexOf = questionnaire.questionBundles.indexOf(oldQuestionBundle);
                const newQuestionnaire = { ...questionnaire };
                newQuestionnaire.questionBundles.splice(indexOf, 1, questionBundle);
                setQuestionnaire((prevState) => newQuestionnaire);
              });
            }
          });
        },
        setQuestionBundle: (questionBundle) => {
          const oldQuestionBundle = questionnaire.questionBundles.find((b) => b.id === questionBundle.id);
          const indexOf = questionnaire.questionBundles.indexOf(oldQuestionBundle);
          const newQuestionnaire = { ...questionnaire };
          newQuestionnaire.questionBundles.splice(indexOf, 1, questionBundle);
          setQuestionnaire((prevState) => newQuestionnaire);
        },
        moveQuestion: (bundleId, questionId, index, originBundleId) => {
          moveQuestionToIndex(bundleId, questionId, index, originBundleId).then((resp) => {
            if (resp.ok) {
              resp.json().then(setQuestionnaire);
            }
          });
        },
        moveQuestionBundle: (bundleId, index) => {
          moveQuestionBundleToIndex(questionnaire.id, bundleId, index).then((resp) => {
            if (resp.ok) {
              resp.json().then((questionnaire) => {
                setQuestionnaire((prevState) => questionnaire);
              });
            }
          });
        },
        delQuestionBundle: (bundleId) => {
          deleteQuestionBundle(questionnaire.id, bundleId).then((resp) => {
            if (resp.ok) {
              resp.json().then((questionnaire) => {
                setQuestionnaire((prevState) => questionnaire);
              });
            }
          });
        },
        handleQuestionnaireRefresh: (questions) => {
          const newQuestionnaire = Object.assign([], questionnaire);
          newQuestionnaire.questions = questions;
          setQuestionnaire((prevState) => newQuestionnaire);
        },
        saveQuestionnaire: () => {
          setSaving(true);
          const url = `${process.env.REACT_APP_QUESTIONNAIRE_SERVICE_URL}/questionnaires/`;
          questionnaire.eventId = event.id;
          fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(questionnaire),
          })
            .then((response) => {
              if (response.ok) {
                setSaving(false);
                return response.json();
              }
            })
            .then((json) => {
              if (questionnaire.id === undefined) {
                navigate(`/events/${json.eventId}/questionnaires/${json.id}`);
              } else {
                setQuestionnaire(json);
              }
            })
            .catch(() => {
              setSaving(false);
            });
        },
        isSaving: saving,
      }}
    >
      <Outlet />
    </QuestionnaireContext.Provider>
  );
};

export const QuestionnaireContextProviderProxy = () => {
  const { qid } = useParams();

  return (
    <ResourceAdapter
      serviceUrl={`${process.env.REACT_APP_QUESTIONNAIRE_SERVICE_URL}/questionnaires/${qid}`}
      component={(props) => {
        const q = props.data;
        return <QuestionnaireContextProvider questionnaire={q} />;
      }}
    />
  );
};
