import { FC } from 'react';
import { Text } from '@mantine/core';
import { BookitupNotification } from '../../../../Types/LogT';

const TasksDeleted: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { value } = notification;
  return <Text>Alle {value ? 'unerledigten' : ''} Aufgaben wurden gelöscht.</Text>;
};

export default TasksDeleted;
