import { useEffect, useState } from 'react';
import Moment from 'react-moment';
import { fetchDocumentById } from '../../Service/restapi/documentService';
import { Checkbox } from '@mantine/core';

export default function ({ onClick, item, selected, disabled }) {
  const [documentSum, setDocumentSum] = useState(undefined);
  const [documentLastModified, setDocumentLastModified] = useState(undefined);

  useEffect(() => {
    if (item.entityId && item.entityType === 'documents') {
      fetchDocumentById(item.entityId).then((resp) => {
        resp.json().then((document) => {
          if (document.summable) {
            setDocumentSum(document.sum);
          }
          setDocumentLastModified(document.lastModified);
        });
      });
    }
  }, [item]);

  return (
    <>
      <Checkbox
        color="blue"
        className="overflow-ellipsis"
        disabled={disabled}
        onChange={() => onClick(item)}
        checked={selected}
        label={item.fileName}
      />

      <div>{documentLastModified && <Moment fromNow>{documentLastModified}</Moment>}</div>
    </>
  );
}
