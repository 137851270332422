/* eslint-disable react/jsx-props-no-spreading */
import { Button, Center, Loader, Select, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { closeAllModals } from '@mantine/modals';
import { ResourceType, ResourceUsage } from '../../Types/ResourceT';
import { DataS } from '../../Service/DataS';
import ResourceS from '../../Service/ResourceS';
import CategoryInputField from './CategoryInputField';
import BookitupSelect from "../../Atoms/BookitupSelect";
import AutocompleteResourceTypeItem from "./table/AutocompleteResourceTypeItem";

interface Props {
  interceptViolation: (httpResponse: Response) => void;
}

const CreateResourceModal: FC<Props> = ({ interceptViolation }) => {
  const form = useForm({
    initialValues: {
      name: '',
      description: '',
      type: ResourceType.ITEM,
      usage: ResourceUsage.RENTAL,
    },
    validate: {
      name: (value) => (value.length > 0 ? null : 'Bitte geben Sie den Bezeichnung ein'),
      // description: (value) => (value.length > 0 ? null : 'Bitte geben Sie den Anmerkungen ein'),
    },
  });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const createResource = () => {
    setLoading(true);
    ResourceS.create({ ...form.values }, interceptViolation)
      .then((resJSON) => {
        if (resJSON) {
          closeAllModals();
          const { id } = resJSON;
          navigate(`/resources/${id}/details`);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form onSubmit={form.onSubmit(createResource)}>
      <Stack spacing="xs">
        <TextInput label="Bezeichnung" {...form.getInputProps('name')} />
        <BookitupSelect
            mt="md"
            label="Typ"
            sx={{marginTop: "0px !important"}}
            variant={"default"}
            data={DataS.resourceTypeOptions}
            {...form.getInputProps('type')}
            itemComponent={AutocompleteResourceTypeItem}
        />
        <CategoryInputField onSelected={(category) => form.setFieldValue('categoryName', category)} />
        {/* <Select label="Verbrauchsart" data={DataS.resourceUsageOptions} {...form.getInputProps('usage')} /> */}
        <Text color="dimmed" size="xs" mt={-5}>
          Die Kategorie wird automatisch erstellt, wenn sie nicht vorhanden ist.
        </Text>
        <Center mb="md" mt="md">
          <Button
            type="submit"
            disabled={loading || !form.isValid()}
            leftIcon={loading && <Loader size="xs" />}
            sx={{ width: 200 }}
          >
            Speichern
          </Button>
        </Center>
      </Stack>
    </form>
  );
};

export default CreateResourceModal;
