// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Skeleton, Stack, Text } from '@mantine/core';
import { useContext } from 'react';
import RecommendationRequest from './RecommendationRequest';
import { RecommendationContext } from './RecommendationsPane';
import RecommendationsActions from './RecommendationsActions';

const RecommendationRequests = () => {
  const { loading, recommendations } = useContext(RecommendationContext);
  const { length } = recommendations;

  if (loading) {
    return <Skeleton height={100} />;
  }

  return (
    <Stack spacing="xs">
      {length === 0 ? (
        <Text italic color="dimmed" size="xs" weight="lighter">
          Noch nichts hinzugefügt.
        </Text>
      ) : (
        <>
          <RecommendationsActions />
          {recommendations.map((recommendation) => (
            <RecommendationRequest key={recommendation.id} recommendation={recommendation} />
          ))}
        </>
      )}
    </Stack>
  );
};

export default RecommendationRequests;
