import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { ModalDialog, useModalState } from '../../Molecules/Dialogs';
import { TransButtonDefault, TransButtonPrimary } from '../../Atoms/Buttons';
import ContactEditModal from '../ContactEditModal';
import ContactSelect from './ContactSelect';

export const CreateOrSelectContactModal = ({
  onContactIdSelected,
  show: showModal,
  toggle: toggleModal,
  contactType,
}) => {
  const [contactId, setContactId] = useState(undefined);
  const { show, toggle } = useModalState();

  useEffect(() => {
    setContactId(undefined);
  }, [showModal]);

  const styles = {
    container: {
      // minHeight: "100%",
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      // alignItems: "stretch",
      // height: "74vh",
      minWidth: 0,
      minHeight: 0,
    },
    editor: {
      flexShrink: 0,
      position: 'sticky',
      left: 0,
      right: 0,
      bottom: 0,
      height: '100%',
    },
    selectContact: {
      marginBottom: 10,
      display: 'flex',
    },
    contactDescription: {
      margin: 10,
    },
    contactSubject: {
      fontWeight: 800,
    },
    description: {
      color: '#9499a7',
      fontSize: 13,
      marginBottom: 3,
    },
  };

  return (
    <ModalDialog
      header={<Trans id="contact.add" />}
      body={
        <div style={styles.selectContact}>
          <div style={{ marginBottom: 20, flex: 1, alignSelf: 'flex-end' }}>
            <div style={styles.description}>Kontakt erstellen</div>
            <TransButtonDefault style={{ height: 36 }} onClickHandler={toggle} text="contact.create" />
          </div>

          <div style={{ flex: 1 }}>
            <div style={styles.description}>Kontakt suchen</div>
            <ContactSelect
              placeholder={' '}
              selectedContactId={contactId}
              contactSelected={(contactId) => {
                contactId === 'NEW' ? toggle() : setContactId(contactId);
              }}
            />
          </div>
          {show && (
            <ContactEditModal
              contactType={contactType}
              contact={{}}
              onFinish={(contactPatch) => {
                setContactId(contactPatch.contactId);
              }}
              close={toggle}
            />
          )}
        </div>
      }
      footer={
        <div>
          <TransButtonPrimary
            onClickHandler={() => {
              onContactIdSelected(contactId);
              toggleModal();
            }}
            text="actions.add"
          />{' '}
          <TransButtonDefault onClickHandler={toggleModal} text="actions.cancel" />
        </div>
      }
      show={showModal}
      toggle={toggleModal}
    />
  );
};
