import React from 'react';
import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_DOCUMENT_SERVICE_URL;

export const createContract = (customerId, template, eventId, documentId) => {
  let url = `${BASE_URL}/contracts?customerId=${customerId}`;
  if (template) {
    url += `&template=${template}`;
  }
  if (eventId) {
    url += `&eventId=${eventId}`;
  }
  if (documentId) {
    url += `&documentId=${documentId}`;
  }
  return ajaxActions.put(url).then((resp) => resp);
};

export const deleteContractTemplate = (templateName) =>
  ajaxActions.del(`${BASE_URL}/contracts/templates/${templateName}`).then((resp) => resp);

export const patchContract = (contractId, patch) =>
  ajaxActions.patch(`${BASE_URL}/contracts/${contractId}`, patch).then((resp) => resp);

export const patchContractTemplate = (templateId, patch) =>
  ajaxActions.patch(`${BASE_URL}/contracts/templates/${templateId}`, patch).then((resp) => resp);

export const fetchContractsByEvent = (eventId) => ajaxActions.get(urlContractsByEvent(eventId)).then((resp) => resp);

export const fetchContractsByCustomerContactId = (contactId) =>
  ajaxActions.get(`${BASE_URL}/contracts?customerContactId=${contactId}`).then((resp) => resp);

export const deleteContract = (contractId) =>
  ajaxActions.del(`${BASE_URL}/contracts/${contractId}`).then((resp) => resp);

export const deleteContracts = (contractIds) =>
  ajaxActions.del(`${BASE_URL}/contracts`, contractIds).then((resp) => resp);

export const fetchContractById = (contractId) =>
  ajaxActions.get(`${BASE_URL}/contracts/${contractId}`).then((resp) => resp);

export const fetchContractTemplate = (templateId) =>
  ajaxActions.get(`${BASE_URL}/contracts/templates/id/${templateId}`).then((resp) => {
    if (resp.ok) {
      return resp.json();
    }
    return null;
  });

export const fetchContractTemplates = () => ajaxActions.get(`${BASE_URL}/contracts/templates`).then((resp) => resp);

export const urlContractsByEvent = (eventId) => `${BASE_URL}/contracts?eventId=${eventId}`;

export const addAboveTextBlockOnContract = (contractId, textBlockId) =>
  ajaxActions
    .put(`${BASE_URL}/contracts/${contractId}/aboveTextBlocks${textBlockId ? `?afterTextBlock=${textBlockId}` : ''}`)
    .then((resp) => resp);

export const addBelowTextBlockOnContract = (contractId, textBlockId) =>
  ajaxActions
    .put(`${BASE_URL}/contracts/${contractId}/belowTextBlocks${textBlockId ? `?afterTextBlock=${textBlockId}` : ''}`)
    .then((resp) => resp);

export const createContractTemplateFromContract = (contractId, templateName) =>
  ajaxActions.put(`${BASE_URL}/contracts/${contractId}?templateName=${templateName}`).then((resp) => resp);
