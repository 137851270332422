import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import makeTrashable from 'trashable';
import { Alert } from 'reactstrap';
import { Trans } from '@lingui/react';
import { LoadingOverlay } from '@mantine/core';

/**
 * ResourceAdapter is a stateful component, which is used to load data from a URL.
 *
 * This component also supports injection of pre-loaded data.
 */
class ResourceAdapter extends React.Component {
  constructor(props) {
    super(props);

    const state = {
      data: null,
      loading: true,
      errorCode: null,

      total: null,
      totalPage: null,
    };

    if (typeof props.preloadedData !== 'undefined') {
      state.data = props.preloadedData;
    }

    this.state = state;

    this.fetchData.bind(this);
    this.reloadData = this.reloadData.bind(this);
  }

  componentDidMount() {
    // Only fetch if preloaded state is not defined
    if (typeof this.props.preloadedData === 'undefined') {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // Compare, but ignore jwt changes
    if (JSON.stringify({ ...prevProps, jwt: undefined }) !== JSON.stringify({ ...this.props, jwt: undefined })) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    if (this.request != null) {
      this.request.trash();
    }
  }

  reloadData() {
    if (this.props.reloadCallback) {
      this.props.reloadCallback();
    } else {
      this.fetchData();
    }
  }

  fetchData() {
    const { serviceUrl, limit, offset } = this.props;
    this.setState({ loading: true });

    const splitServiceUrl = queryString.parseUrl(serviceUrl);

    const urlArgsString = queryString.stringify({
      ...splitServiceUrl.query,
      limit,
      offset: parseInt(offset) !== 0 ? offset : undefined,
    });
    const url = splitServiceUrl.url + (urlArgsString !== '' ? `?${urlArgsString}` : '');

    // If we have a previous request, cancel it first
    if (typeof this.request !== 'undefined') {
      this.request.trash();
    }

    this.request = makeTrashable(
      fetch(url, {
        method: 'GET',
        credentials: 'include'
      }),
    );

    this.request
      .then((resp) => {
        if (resp.ok) {
          resp
            .json()
            .then((value) =>
              this.setState({
                loading: false,
                data: value,
                errorCode: null,
                total: parseInt(resp.headers.get('X-Total-Count')),
                totalPage: parseInt(resp.headers.get('X-Count')),
              }),
            )
            .catch(() =>
              this.setState({
                loading: false,
                data: null,
                errorCode: null,
                total: null,
                totalPage: null,
              }),
            );
        } else {
          resp.json().then((value) => console.log(value));
          this.setState({
            loading: false,
            data: null,
            errorCode: resp.status,
            total: null,
            totalPage: null,
          });
        }
      })
      .catch((reason) => {
        this.setState({
          loading: false,
          data: null,
          errorCode: 503,
          total: null,
          totalPage: null,
        });
        console.error(`Failed to load data at ${serviceUrl} because of a network error`);
        console.error(reason.message);
      });
  }

  render() {
    const { data, loading, errorCode, total } = this.state;
    const { component, loadingComponent, errorComponent } = this.props;

    if (loading) {
      return <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
    }
    if (data !== null) {
      return component({ data, total, ...{ reloadCallback: this.reloadData } });
    }
    return errorComponent(errorCode, this.props.serviceUrl);
  }
}

ResourceAdapter.propTypes = {
  serviceUrl: PropTypes.string.isRequired,

  component: PropTypes.func.isRequired,
  loadingComponent: PropTypes.func.isRequired,
  errorComponent: PropTypes.func.isRequired,

  preloadedData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),

  limit: PropTypes.number,
  offset: PropTypes.number,
};

ResourceAdapter.defaultProps = {
  loadingComponent: () => <LoadingAnimation />,
  errorComponent: (code, url) => <ErrorBox code={code} url={url} />,
};

export default ResourceAdapter;

export const ErrorBox = (props) => {
  let errorColor = 'danger';
  let errorCode = 'unknown';

  switch (props.code) {
    case 500:
    case 503:
    case 403:
    case 404:
    case 400:
    case 410:
      errorCode = props.code;

      if (errorCode === 404) {
        errorColor = 'warning';
      }

      break;
    default:
      errorCode = 'unknown';
  }

  console.error(`Error retrieving from url: ${props.url}`);

  return (
    <Alert color={errorColor}>
      <Trans key="title" id={`errors.${errorCode}.boxed.title`} render="h4" className="alert-heading" />
      <hr />
      <Trans key="description" id={`errors.${errorCode}.boxed.description`} render="p" className="mb-0" />
    </Alert>
  );
};

export const LoadingAnimation = ({ style }) => (
  <img
    style={{ width: 50, marginLeft: 'auto', marginRight: 'auto', display: 'block', ...style }}
    src="/img/generic-loading-animation.gif"
  />
);
