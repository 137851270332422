import { withI18n } from '@lingui/react';
import { useEffect, useState } from 'react';
import { Skeleton } from '@mantine/core';
import { createMailOutboundConfig, fetchMailOutboundConfigs } from '../../../Service/restapi/mailService';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import PanePaper from '../../../Molecules/PanePaper';
import { MailOutBoundConfig } from './MailOutBoundConfig';
import { RoutingS } from '../../../Service/RoutingS';

export const MailAccount = withI18n()(({ i18n }) => {
  const [mailOutboundConfigs, setMailInboundConfigs] = useState(undefined);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    RoutingS.changeTitle('Ausgangsserver');
    fetchConfigs();
  }, []);

  function fetchConfigs() {
    fetchMailOutboundConfigs().then((resp) => {
      if (resp.ok) {
        resp.json().then((mailOutBoundConfigs) => {
          setMailInboundConfigs(mailOutBoundConfigs);
        });
      }
    });
  }

  function createNewOutBoundConfig() {
    // fetching config without id, creates a new config
    createMailOutboundConfig().then((resp) => {
      if (resp.ok) {
        // ether get the returned config, or just reload configs
        fetchConfigs();
      }
    });
  }

  const styles = {
    container: {},
    description: {
      color: '#666666',
      marginBottom: 20,
      fontSize: 12,
    },
  };

  return (
    <PanePaper
      sx={{maxWidth: 800}}
      title="settings.mail.account.outbound"
      tooltip="help.settings.mail.account.outbound.pane"
      rightSection={
        mailOutboundConfigs && mailOutboundConfigs.length === 0 && <BookitupAddIcon onClick={createNewOutBoundConfig} />
      }
    >
      {!mailOutboundConfigs ? (
        <Skeleton height={50} width={500} />
      ) : (
        <div>
          <div style={styles.description}>
            Hier kannst Du Deinen E-Mail-Account hinzufügen, damit E-Mails über Deinen E-Mail-Anbieter verschickt
            werden. Dadurch verringerst Du das Risiko, dass Deine E-Mails im Spam-Ordner Deiner Kunden landen.
          </div>

          {mailOutboundConfigs.map((config, index) => (
            <MailOutBoundConfig key={index} id={config.id} />
          ))}
        </div>
      )}
    </PanePaper>
  );
});
