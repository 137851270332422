import BookitupTutorialIcon from '../../Atoms/BookitupTutorialIcon';
import EventKindSelect from '../../Molecules/Event/EventKindSelect';
import { LeadOriginSelectProxy } from '../../Molecules/Event/LeadOriginSelect';
import PanePaper from '../../Molecules/PanePaper';
import EventDates from './EventDates';

const EventDatesPane = () => (
  <PanePaper
    title="common.dateandkind"
    tooltip="help.event.dates.pane"
    rightSection={
      <BookitupTutorialIcon videoUrl={'https://www.youtube.com/watch?v=4WdMhJZo-vo'} name={'EventDetails'} />
    }
  >
    <EventDates />
    <EventKindSelect />
    <LeadOriginSelectProxy />
  </PanePaper>
);

export default EventDatesPane;
