import React, { useState } from 'react';
import { ButtonGroup } from 'reactstrap';
import {
  TransButtonDefault,
  TransButtonDefaultWithIcon,
  TransButtonPrimary,
  TransButtonPrimaryWithIcon,
} from './Buttons';

export const InboxButtonGroup = (props) => {
  const elements = [];
  elements.push({ text: 'inbox' });
  elements.push({ text: 'outbound' });

  return <GenericButtonGroup elements={elements} {...props} />;
};

export const HtmlEditButtonGroup = (props) => {
  const elements = [];
  elements.push({ text: 'HTML_Editor' });
  elements.push({ text: 'Text_Editor' });

  return <GenericButtonGroup elements={elements} {...props} />;
};

export const SizeButtonGroup = (props) => {
  const elements = [];
  elements.push({ text: 'EXTRA_SMALL' });
  elements.push({ text: 'SMALL' });
  elements.push({ text: 'MEDIUM' });
  elements.push({ text: 'BIG' });
  elements.push({ text: 'FULLSIZE' });

  return <GenericButtonGroup elements={elements} {...props} />;
};

export const HorizontalAlignButtonGroup = (props) => {
  const elements = [];
  elements.push({ icon: 'LEFT' });
  elements.push({ icon: 'CENTER' });
  elements.push({ icon: 'RIGHT' });
  elements.push({ icon: 'JUSTIFIED' });

  return <GenericIconButtonGroup elements={elements} {...props} />;
};

export const ContactTypeButtonGroup = (props) => {
  const elements = [];
  elements.push({ text: 'PRIVATE' });
  // elements.push({text: "BRIDE"});
  // elements.push({text: "GROOM"});
  elements.push({ text: 'COMPANY' });

  return <GenericButtonGroup elements={elements} {...props} />;
};

export const GenericButtonGroup = ({ value, elements, style, onChange }) => {
  const [selected, setSelected] = useState(value);

  function changeHandler(value) {
    setSelected(value.text);
    onChange(value.text);
  }

  const indents = [];

  elements.map((value, index) => {
    if (selected === value.text) {
      indents.push(
        <TransButtonPrimary
          key={index}
          onClickHandler={() => changeHandler(value, index)}
          className="active"
          text={`common.${value.text.toLowerCase()}`}
        />,
      );
    } else {
      indents.push(
        <TransButtonDefault
          key={index}
          onClickHandler={() => changeHandler(value, index)}
          text={`common.${value.text.toLowerCase()}`}
        />,
      );
    }
  });

  return <ButtonGroup style={style}>{indents}</ButtonGroup>;
};

export const GenericIconButtonGroup = (props) => {
  const [selected, setSelected] = useState(props.value);

  function onChange(value) {
    setSelected(value.icon);
    props.onChange(value.icon);
  }

  const indents = [];

  props.elements.map((value, index) => {
    if (selected === value.icon) {
      indents.push(
        <TransButtonPrimaryWithIcon key={index} icon={value.icon} onClickHandler={() => onChange(value, index)} />,
      );
    } else {
      indents.push(
        <TransButtonDefaultWithIcon key={index} icon={value.icon} onClickHandler={() => onChange(value, index)} />,
      );
    }
  });

  return <ButtonGroup style={props.style}>{indents}</ButtonGroup>;
};
