import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_LOG_SERVICE_URL;

export const getLogs = (logType, entityType, entityId) => {
  const params = [];
  if (logType) {
    params.push(`logType=${logType}`);
  }
  if (entityType) {
    params.push(`entityType=${entityType}`);
  }
  if (entityId) {
    params.push(`entityId=${entityId}`);
  }
  return ajaxActions.get(`${BASE_URL}/logs?${params.join('&')}`).then((resp) => resp);
};

export const createLog = (id, logType, entityType, entityId) => {
  const params = [];
  if (logType) {
    params.push(`logType=${logType}`);
  }
  if (entityType) {
    params.push(`entityType=${entityType}`);
  }
  if (entityId) {
    params.push(`entityId=${entityId}`);
  }
  return ajaxActions.post(`${BASE_URL}/logs/${id}?${params.join('&')}`).then((resp) => resp);
};

export const deleteLog = (id) => ajaxActions.del(`${BASE_URL}/logs/${id}`).then((resp) => resp);
