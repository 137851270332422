import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_LOCATION_SERVICE_URL;

export const patchLocation = (eventId, locationId, locationPatch) =>
  ajaxActions.patch(`${BASE_URL}/locations?eventId=${eventId}`, locationPatch).then((resp) => resp);

export const fetchLocationByEventId = (eventId) =>
  ajaxActions.get(`${BASE_URL}/locations?eventId=${eventId}`).then((resp) => resp);

export const fetchLocationById = (eventId, locationId) =>
  ajaxActions.get(`${BASE_URL}/locations/${locationId}`).then((resp) => resp);

export const calcLocationDistanceAndDuration = (contactId, locationId) =>
  ajaxActions.get(`${BASE_URL}/distances/calc?contactId=${contactId}&locationId=${locationId}`).then((resp) => resp);
