import React, { useContext, useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import validator from 'validator';
import { admitUserOnProfile, getUsersOfProfile, isOwnProfile } from '../../../Service/restapi/authService';
import { LoadingAnimation } from '../../../Service/ResourceAdapter';
import { TransButtonDefault, TransButtonLink, TransButtonPrimaryWithSpinner } from '../../../Atoms/Buttons';
import TooltipButton from '../../../Atoms/buttons/TooltipButton';
import { Pane } from '../../../Molecules/Pane';
import { CheckLicence } from '../../../Organismns/Upgrade/CheckLicence';
import { ModalDialog, useModalState } from '../../../Molecules/Dialogs';
import { TransTextInputField } from '../../../Atoms/InputFields';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import TooltipTextButton from '../../../Atoms/buttons/TooltipTextButton';
import { ProfileShareActions } from './ProfileShareActions';
import PanePaper from '../../../Molecules/PanePaper';
import { IconPlus } from '@tabler/icons';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';

export const ShareProfile = () => {
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const [sharedUser, setSharedUser] = useState(undefined);
  const { show, toggle } = useModalState(false);
  const [emailNewShare, setEmailNewShare] = useState('');
  const [loadingShareProfile, setLoadingShareProfile] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);

  const [isOwner, setOwner] = useState(undefined);
  const { profile } = useContext(ProfileContext);

  useEffect(() => {
    isOwnProfile(profile.id).then((resp) => {
      if (resp.ok) {
        resp.text().then((isOwner) => {
          setOwner(isOwner === 'true');
        });
      }
    });
  }, []);

  useEffect(() => {
    setEmailNewShare('');
    setLoadingShareProfile(false);
  }, [show]);

  useEffect(() => {
    loadProfileShares();
  }, []);

  function loadProfileShares() {
    getUsersOfProfile().then((resp) => {
      if (resp.ok) {
        resp.json().then((userEmails) => {
          setSharedUser(userEmails);
        });
      }
    });
  }

  function shareProfile() {
    if (!validator.isEmail(emailNewShare)) {
      setInvalidEmail(true);
    } else {
      setLoadingShareProfile(true);
      admitUserOnProfile(emailNewShare)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((newSharedUsers) => {
              setSharedUser(newSharedUsers);
            });
          } else {
            console.error(`could not share profile with ${emailNewShare}`);
          }
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not share profile with ${emailNewShare}`);
        })
        .finally(() => {
          setLoadingShareProfile(false);
          toggle();
        });
    }
  }

  if (!sharedUser) {
    return <LoadingAnimation />;
  }

  const styles = {
    actions: {
      marginBottom: 5,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    list: {
      backgroundColor: 'white',
      borderRadius: 5,
      paddingTop: 5,
      paddingBottom: 5,
    },
    itemContainer: {
      marginBottom: 5,
      display: 'flex',
    },
    description: {
      color: '#666666',
      marginBottom: 20,
      fontSize: 12,
    },
  };

  const users = [];
  sharedUser.map((email, index) => {
    users.push(
      <div style={styles.itemContainer}>
        <div key={index} style={{ flex: 1, maxWidth: '100%' }}>
          <div style={{ display: 'flex' }}>
            <TransButtonLink
              className="overflow-ellipsis"
              style={{ fontSize: '1rem', color: '#000000', height: 25 }}
              onClick={() => {}}
              text={email}
            />
            <ProfileShareActions email={email} reloadProfileShares={loadProfileShares} />
          </div>
        </div>
      </div>,
    );
  });

  return (
    <PanePaper
      title="profile.shares"
      tooltip="help.settings.profile.shares.pane"
      rightSection={
        <BookitupAddIcon onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.SHARE_PROFILE, toggle)} />
      }
    >
      <div style={{ marginBottom: 10 }} className="description">
        Wenn du Mitarbeiter hast, kannst Du für sie hier einen Zugang erstellen.
        <br />
        Sie erhalten dann vollen Zugriff, sowie alle E-Mail-Benachrichtigungen, wie der Haupt-Account.
      </div>
      {isOwner && (
        <>
          {sharedUser.length === 0 && <div className="description">Dieses Profil ist mit niemandem geteilt.</div>}
          {sharedUser.length > 0 && (
            <>
              <div style={{ marginBottom: 10 }} className="description">
                Folgenden Nutzern ist der Zugriff auf dieses Profil freigegeben:
              </div>
              {users}
            </>
          )}
          <ModalDialog
            header={<Trans id="Profil teilen" />}
            body={
              <>
                <div style={styles.description}>
                  Gib die E-Mail-Adresse der Person ein, die auf dieses Profil Zugriff erhalten soll. Falls unter dieser
                  E-Mail-Adresse noch kein bookitup Konto existiert, wird automatisch ein Zugang erstellt und der Person
                  per E-Mail zugeschickt.
                </div>
                <TransTextInputField
                  onChange={(e) => {
                    setEmailNewShare(e.target.value);
                  }}
                  name="email"
                  value={emailNewShare}
                  placeholder="contact.email"
                />

                {invalidEmail && <div style={{ color: 'red' }}>Gib eine gültige E-Mail-Adresse ein.</div>}

                <div style={styles.description}>
                  Die Freigabe kannst Du jederzeit wieder auflösen. Nur Du, als Admin des Profils, kannst weitere
                  Freigaben einrichten.
                </div>
              </>
            }
            footer={
              <>
                <TransButtonPrimaryWithSpinner
                  isLoading={loadingShareProfile}
                  onClickHandler={shareProfile}
                  text="Profil freigeben"
                />
                &nbsp;
                <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />
              </>
            }
            show={show}
            toggle={toggle}
          />
        </>
      )}
      {!isOwner && <div>Profile freigeben, kann nur der Administrator von dem Profil.</div>}
    </PanePaper>
  );
};
