/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { useElementSize } from '@mantine/hooks';
import {FC, useEffect, useState} from 'react';
import PanePaper from "../../../Molecules/PanePaper";
import BookitupTable from "../../../Atoms/BookitupTable";
import {WorkflowDTO} from "../../../Types/LogT";
import {WorkflowS} from "../../../Service/WorkflowS";
import EventWorkflowListItem from "./EventWorkflowListItem";


const EventWorkflowList: FC<{workflowTemplateId: string}> = ({workflowTemplateId}) => {
  const { ref, width } = useElementSize();
  const [workflows, setWorkflows] = useState<WorkflowDTO[]>([]);

  useEffect(() => {
    WorkflowS.fetchWorkflowsByTemplate(workflowTemplateId)
      .then(setWorkflows);
  }, [workflowTemplateId]);

  return (
    <div ref={ref}>
      <PanePaper>
        <BookitupTable>
          <thead>
          <tr>
            <th>Workflow-Name im Event</th>
            <th style={{width: 250}}>Event</th>
          </tr>
          </thead>
          <tbody>
            {workflows.map((workflow: WorkflowDTO) => (
              <EventWorkflowListItem workflow={workflow} key={workflow.eventId} />
            ))}
          </tbody>
        </BookitupTable>
      </PanePaper>
    </div>
  );
};

export default EventWorkflowList;
