import React, { useContext } from 'react';
import { preChange } from '../../Utils/utils';
import { CustomerContext } from '../../Service/Context/CustomerContext';
import ContractAddPositions from './ContractAddPositions';
import ContractSubject from './ContractSubject';
import TextBlockContainer from './TextBlockContainer';
import { TransButtonDefault } from '../../Atoms/Buttons';

export default function ({
  addAboveTextBlock,
  addBelowTextBlock,
  contract,
  handleChange,
  style,
  event,
  eventLocation,
  _document,
  customAttributeValues,
}) {
  const { customer } = useContext(CustomerContext);

  if (!contract) {
    return null;
  }

  return (
    <div style={style}>
      <ContractSubject
        contract={contract}
        handleChange={handleChange}
        preChange={preChange}
        event={event}
        customer={customer}
        eventLocation={eventLocation}
        _document={_document}
        customAttributeValues={customAttributeValues}
      />
      <TextBlockContainer
        contract={contract}
        handleChange={handleChange}
        textBlocks={contract.aboveTextBlocks}
        addTextBlock={addAboveTextBlock}
        event={event}
        customer={customer}
        eventLocation={eventLocation}
        _document={_document}
        customAttributeValues={customAttributeValues}
      />
      {(!contract.aboveTextBlocks || contract.aboveTextBlocks.length < 1) && (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
          <TransButtonDefault
            style={{ margin: 5 }}
            text="textBlocks.button.add"
            id={'addTextBlock'}
            onClickHandler={() => {
              addAboveTextBlock(null);
            }}
          />
        </div>
      )}
      <ContractAddPositions contract={contract} handleChange={handleChange} />

      <TextBlockContainer
        contract={contract}
        handleChange={handleChange}
        textBlocks={contract.belowTextBlocks}
        addTextBlock={addBelowTextBlock}
        event={event}
        customer={customer}
        eventLocation={eventLocation}
        _document={_document}
        customAttributeValues={customAttributeValues}
      />

      {
        // if a document is connected and
        // no below text blocks are present (thus no addTextBlock button is shown in above component)
        contract.documentId && (!contract.belowTextBlocks || contract.belowTextBlocks.length < 1) && (
          <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
            <TransButtonDefault
              style={{ margin: 5 }}
              text="textBlocks.button.add"
              id={'addTextBlock'}
              onClickHandler={() => {
                addBelowTextBlock(null);
              }}
            />
          </div>
        )
      }
    </div>
  );
}
