import { Text } from '@mantine/core';
import { useModals } from '@mantine/modals';

const useTaskConfirmDeleteModal = () => {
  const { openConfirmModal, closeAll } = useModals();

  const openConfirmDeleteModal = (onConfirm: () => void, onCancel?: () => void) =>
    openConfirmModal({
      title: 'Bist Du sicher?',
      centered: true,
      children: <Text size="sm">Wenn Du diese Aufgabe löscht, kann sie später nicht wiederhergestellt werden.</Text>,
      labels: { confirm: 'Aufgabe löschen', cancel: 'Abbrechen' },
      confirmProps: { color: 'red' },
      onCancel,
      onConfirm,
    });
  return { openConfirmDeleteModal };
};

export default useTaskConfirmDeleteModal;
