import { FC } from 'react';

interface Props {
  colour: string;
  withoutShadow?: boolean;
  size?: number;
}

const Dot: FC<Props> = ({ colour, withoutShadow, size = 8 }) => (
  <div
    style={{
      width: size,
      height: size,
      borderRadius: '50%',
      backgroundColor: colour,
      boxShadow: withoutShadow ? 'none' : `0px 0px 8px ${colour}`,
    }}
  />
);

export const SpanDot: FC<Props> = ({ colour, withoutShadow, size = 8 }) => (
  <span
    style={{
      width: size,
      height: size,
      marginRight: 5,
      borderRadius: '50%',
      backgroundColor: colour,
      boxShadow: withoutShadow ? 'none' : `0px 0px 8px ${colour}`,
      display: 'inline-block',
    }}
  />
);

export default Dot;
