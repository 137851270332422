/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare
import { Skeleton, Space, Text } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { useLingui } from '@lingui/react';
import { useElementSize } from '@mantine/hooks';
import { useModals } from '@mantine/modals';
import { fetchDocumentsByEvent } from '../../../Service/restapi/documentService';
import { EventParticipationsContext } from '../../../Service/Context/EventParticipationContext';
import { EventContext } from '../../../Service/Context/EventContext';
import PanePaper from '../../PanePaper';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import BookitupTable from '../../../Atoms/BookitupTable';
import DocumentListItem from './DocumentListItem';
import { TestS } from '../../../TestS';
import CreateDocumentModal from './CreateDocumentModal';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import BookitupTutorialIcon from "../../../Atoms/BookitupTutorialIcon";

const DocumentPane = () => {
  const { i18n } = useLingui();
  const { event } = useContext(EventContext);
  const { openLicenceModal } = useContext(ViolationContext);
  const eventParticipantsContext = useContext(EventParticipationsContext);
  const { mainCustomerId } = eventParticipantsContext;
  const [documentContactId, setDocumentContactId] = useState(mainCustomerId);
  const [isLoading, setLoading] = useState(false);
  const [documents, setDocuments] = useState();
  const [sortedDocuments, setSortedDocuments] = useState();
  const { ref, width } = useElementSize();
  const { openModal } = useModals();

  const openCreateDocumentModal = () => {
    openModal({
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Vorlage wählen
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: (
        <CreateDocumentModal
          initialCustomerId={documentContactId}
          eventId={event.id}
          eventParticipationContext={eventParticipantsContext}
          openLicenceModal={openLicenceModal}
        />
      ),
    });
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  useEffect(() => {
    if (documents) {
      const tmpSortedDocuments = {};
      ['OFFER', 'CONFIRMATION', 'PARTIAL_INVOICE', 'INVOICE', 'CANCEL'].forEach((docType) => {
        const filteredDocs = documents.filter((d) => d.documentType === docType);
        if (filteredDocs.length > 0) {
          tmpSortedDocuments[docType] = filteredDocs;
        }
      });
      setSortedDocuments(tmpSortedDocuments);
    }
  }, [documents]);

  function fetchDocuments() {
    setLoading(true);
    setDocuments(undefined);
    fetchDocumentsByEvent(event.id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((newDocuments) => {
            setDocuments(newDocuments);
          });
        } else {
          console.error(`could not load documents of event ${event.id}`);
        }
      })
      .catch((error) => {
        console.error(`could not load documents of event ${event.id}`);
        console.error({ error });
      })
      .finally(() => setLoading(false));
  }

  const showColumns = {
    showNumber: width > 490,
    showDatum: width > 540,
    showName: width > 700,
    showDueDate: width > 400,
    showPortal: width >= 240,
    narrowPortalStatus: width < 290,
    showSum: true,
  };

  return (
    <PanePaper
      title="common.documents"
      tooltip="help.event.documents.pane"
      rightSection={<>
        <BookitupTutorialIcon videoUrl={"https://www.youtube.com/watch?v=rWjLWBbnw5g"} name={"CreateDocument"} />
        <BookitupAddIcon onClick={openCreateDocumentModal} testId={TestS.Documents.CREATE_DOCUMENT} />
      </>}
    >
      <div ref={ref}>
        {isLoading ? (
          <div>
            {Array.from(Array(3)).map(() => (
              <Skeleton key={Math.random()} radius="md" height={25} width="100%" mb={10} />
            ))}
          </div>
        ) : (
          <div>
            {!documents || !sortedDocuments || documents.length === 0 ? (
              <Text italic color="dimmed" size="xs" weight="lighter">
                Noch nichts hinzugefügt.
              </Text>
            ) : (
              <BookitupTable>
                <thead>
                  <tr>
                    <th style={{ width: 30 }}>Status</th>
                    {showColumns.showNumber && <th style={{ width: 50 }}>Nummer</th>}
                    {showColumns.showName && <th>Name </th>}
                    {showColumns.showDatum && <th style={{ width: 50 }}>Datum</th>}
                    <th style={{ width: 100 }}>Betrag</th>
                    {showColumns.showDueDate && <th style={{ width: 100 }}>Frist</th>}
                    {showColumns.showPortal && (
                      <th style={{ width: showColumns.narrowPortalStatus ? 50 : 120 }}>Portal</th>
                    )}
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(sortedDocuments).map((key) => {
                    const docs = sortedDocuments[key];
                    return (
                      <>
                        <tr className="notHover">
                          <td colSpan={6}>
                            <Text weight="bolder">
                              <b> {i18n._(`document.${key.toLowerCase()}`)}</b>
                            </Text>
                          </td>
                        </tr>
                        {docs.map((doc) => (
                          <DocumentListItem
                            key={doc.id}
                            bookitupDocument={doc}
                            reload={() => fetchDocuments()}
                            {...showColumns}
                          />
                        ))}
                        <Space h="md" />
                      </>
                    );
                  })}
                </tbody>
              </BookitupTable>
            )}
          </div>
        )}
      </div>
    </PanePaper>
  );
};

export default DocumentPane;
