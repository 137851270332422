import { ajaxActions } from '../AjaxActions';
import { ConstantS } from '../ConstantS';
import { MixpanelS } from '../MixpanelS';
import { patchContract } from './contractService';
import { patchDocument } from './documentService';
import { patchQuestionnaire } from './questionnaireService';

const BASE_URL = process.env.REACT_APP_SHARE_SERVICE_URL;

export const fetchShares = (entityType, entityId) => {
  const requestParams = [];
  if (entityType) {
    requestParams.push(`entityType=${entityType}`);
  }
  if (entityId) {
    requestParams.push(`entityId=${entityId}`);
  }
  return ajaxActions.get(`${BASE_URL}/shares?${requestParams.join('&')}`).then((resp) => resp);
};

export const share = (shareId, shares) =>
  ajaxActions.put(`${BASE_URL}/shares/${shareId}`, shares).then((resp) => {
    if (resp.ok) {
      const patch = { draft: false };
      shares.entityTypeAndId.forEach((_share) => {
        const { entityType, entityId } = _share;
        if (entityType === 'documents') {
          patchDocument(entityId, patch);
        }
        if (entityType === 'contracts') {
          patchContract(entityId, patch);
        }
        if (entityType === 'questionnaires') {
          patchQuestionnaire(entityId, patch);
        }
      });
    }
    return resp;
  });

export const resetLogin = (shareId, shares) =>
  ajaxActions.patch(`${BASE_URL}/shares/${shareId}`, shares).then((resp) => resp);

export const fetchResources = (shareId) =>
  ajaxActions.get(`${BASE_URL}/resources?shareId=${shareId}`).then((resp) => resp);

export const fetchResourceByEntityTypeAndEntityId = (entityType, entityId) =>
  ajaxActions.get(`${BASE_URL}/resources?entityType=${entityType}&entityId=${entityId}`).then((resp) => resp);

export const fetchFormSubmissionOfResource = (resourceId) =>
  ajaxActions.get(`${BASE_URL}/resources/${resourceId}/formSubmissions`).then((resp) => resp);

export const fetchSubmittedFormSubmission = (entityType, entityId) =>
  ajaxActions.get(`${BASE_URL}/formSubmissions?entityType=${entityType}&entityId=${entityId}`).then((resp) => resp);

export const deleteFormSubmission = (formSubmissionId) =>
  ajaxActions.del(`${BASE_URL}/formSubmissions/${formSubmissionId}`).then((resp) => resp);

export const deleteShare = (shareId) => ajaxActions.del(`${BASE_URL}/shares/${shareId}`).then((resp) => resp);

export const deleteResource = (resourceId) =>
  ajaxActions.del(`${BASE_URL}/resources/${resourceId}`).then((resp) => resp);

export const newShare = (name, password, sharedUntil, eventId) => {
  const newShare = {
    name,
    password,
    sharedUntil,
    eventId,
  };
  return ajaxActions.put(`${BASE_URL}/shares`, newShare).then((resp) => {
    if (resp.ok) {
      MixpanelS.track(ConstantS.TrackingEvents.PortalCreated);
    }
    return resp;
  });
};

export const checkSubmissionsAndDelete = (entityType, entityId, deleteFunction, onSuccess, onFinally) => {
  fetchSubmittedFormSubmission(entityType, entityId).then((resp) => {
    if (resp.ok) {
      resp.json().then((submittedFormSubmissions) => {
        if (submittedFormSubmissions.length === 0) {
          deleteFunction(entityId)
            .then((resp) => {
              if (resp.ok) {
                onSuccess();
              }
            })
            .finally(() => {
              onFinally();
            });
        } else {
          alert('Dokument kann nicht gelöscht werden, da es im Online-Portal freigegeben wurde.');
          onFinally();
        }
      });
    } else {
      onFinally();
    }
  });
};
