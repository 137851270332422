import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/react';
import { Table } from 'reactstrap';
import { FormatS } from '../Service/FormatS';
import TooltipButton from '../Atoms/buttons/TooltipButton';
import { Pane } from '../Molecules/Pane';
import { EditableEventProperty } from '../Atoms/EditableProperties/EditableEventProperty';
import { SimpleEditableText } from '../Atoms/inputs/SimpleEditableText';
import { EuroFormatter } from '../Atoms/Formatter/EuroFormatter';
import { countDocumentsOfEvent, sumDocumentsOfEvents } from '../Service/restapi/documentService';
import { EventContext } from '../Service/Context/EventContext';
import { ProfileContext } from '../Service/Context/ProfileContext';
import PanePaper from '../Molecules/PanePaper';

export default function () {
  const { event } = useContext(EventContext);
  const { profile } = useContext(ProfileContext);

  const [countDocuments, setCountDocuments] = useState(0);
  const [sumConfirmed, setSumConfirmed] = useState(undefined);
  const [sumOpen, setSumOpen] = useState(0);
  const [fee, setFee] = useState(isNaN(event.fee) ? 0 : event.fee);
  const [tip, setTip] = useState(isNaN(event.tip) ? 0 : event.tip);
  const [provision, setProvision] = useState(isNaN(event.provision) ? 0 : event.provision);
  const [hotel, setHotel] = useState(isNaN(event.hotel) ? 0 : event.hotel);
  const [incomeSum, setIncomeSum] = useState(0);
  const [sumExpences, setSumExpences] = useState(0);
  const [total, setTotal] = useState(0);
  const [net, setNet] = useState(0);

  useEffect(() => {
    if (event.id !== '') {
      countDocumentsOfEvent(event.id)
        .then((resp) => resp.headers)
        .then((headers) => {
          setCountDocuments(parseInt(headers.get('X-Total-Count')));
        });
      sumDocumentsOfEvents([event.id])
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((json) => {
              setSumConfirmed(json.events[0].sumConfirmed);
              setSumOpen(json.events[0].sumOpen);
            });
          } else {
            console.error('could not post');
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    }
  }, []);

  useEffect(() => {
    setFee(isNaN(event.fee) ? 0 : event.fee);
    setTip(isNaN(event.tip) ? 0 : event.tip);
    setHotel(isNaN(event.hotel) ? 0 : event.hotel);
    setProvision(isNaN(event.provision) ? 0 : event.provision);

    if (countDocuments === 0) {
      setIncomeSum(Number(fee + tip).toFixed(2));
    }

    if (countDocuments > 0) {
      setIncomeSum(Number(sumConfirmed + tip).toFixed(2));
    }

    setSumExpences(Number(hotel + provision).toFixed(2));
    setTotal(Number(incomeSum - sumExpences).toFixed(2));
    setNet(Number((total - tip) / 1.19 + tip).toFixed(2));
  });

  const indents = [];
  if (event.offers !== undefined) {
    event.offers.map((o, index) => {
      indents.push(
        <li key={index}>
          <Link to={`/events/${event.id}/documents/${o.id}`}>
            {' '}
            <Trans id="Bestätigt" />: {o.sum} €
          </Link>
        </li>,
      );
    });
  }

  const styles = {
    table: {
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: 600,
    },
    input: {
      textAlign: 'center',
      // maxWidth: 200,
    },
  };

  return (
    <PanePaper title="common.profit" tooltip="help.event.profit.pane">
      <Table borderless className="details" style={styles.table}>
        <thead>
          <tr>
            <th>
              <Trans id="Umsatz" render="h6" />
            </th>
            <th>
              <Trans id="Ausgaben" render="h6" />
            </th>
            <th>
              <Trans id="Verdienst" render="h6" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {(!countDocuments || countDocuments === 0) && (
                <EditableEventProperty
                  patchField="fee"
                  placeholder="event.fee"
                  editFormatter={SimpleEditableText}
                  labelFormatter={EuroFormatter}
                />
              )}

              {countDocuments > 0 && (
                <h6 style={{ textAlign: 'center' }}>
                  {' '}
                  {FormatS.formatCurrency(sumConfirmed)}{' '}
                  <span style={{ textAlign: 'center', fontSize: '0.75rem', display: 'block' }} className="details">
                    <TooltipButton id="sumOfBookedOffers" placement="bottom" text="event.turnover.sum" icon="INFO" />
                  </span>
                </h6>
              )}
            </td>

            <td>
              <EditableEventProperty
                patchField="provision"
                placeholder="event.provision"
                editFormatter={SimpleEditableText}
                labelFormatter={EuroFormatter}
              />
            </td>

            <td />
          </tr>
          <tr>
            <td>
              <EditableEventProperty
                patchField="tip"
                placeholder="event.tip"
                editFormatter={SimpleEditableText}
                labelFormatter={EuroFormatter}
              />
              <TooltipButton id="tip" placement="bottom" text="event.turnover.tip" icon="INFO" />
            </td>

            <td>
              <EditableEventProperty
                patchField="hotel"
                placeholder="event.hotel"
                editFormatter={SimpleEditableText}
                labelFormatter={EuroFormatter}
              />
            </td>

            <td />
          </tr>
          <tr>
            <td>
              <h6 style={{ textAlign: 'center', color: '#6BA581' }} className="details">
                {' '}
                {FormatS.formatCurrency(incomeSum.toString().replace('.', ','))}{' '}
              </h6>

              {countDocuments > 0 && (
                <span style={{ textAlign: 'center', fontSize: '0.75rem' }}>
                  <Trans id="event.openoffers" className="details" />:{` ${FormatS.formatCurrency(sumOpen)}`}
                </span>
              )}
            </td>

            <td>
              <h6 style={{ textAlign: 'center', color: 'rgb(152, 35, 35)' }}>
                {' '}
                {FormatS.formatCurrency(sumExpences.toString().replace('.', ','))}{' '}
              </h6>
            </td>

            <td>
              <h6 style={{ textAlign: 'center' }}> {FormatS.formatCurrency(total.toString().replace('.', ','))} </h6>
              {!profile.smallScaleBusiness && (
                <span style={{ textAlign: 'center', fontSize: '0.75rem' }} className="details">
                  netto: {` ${FormatS.formatCurrency(net.toString().replace('.', ','))}`}{' '}
                </span>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
    </PanePaper>
  );
}
