import { useContext, useEffect, useState } from 'react';
import { useLingui } from '@lingui/react';
import { SimpleEditableText } from '../../../Atoms/inputs/SimpleEditableText';
import {
  fetchCustomAttributeValueByEntityId,
  fetchCustomAttributeValues,
} from '../../../Service/restapi/customAttributeService';
import { NumberFormatter } from '../../../Atoms/Formatter/NumberFormatter';
import EditableDateInput from '../../../Atoms/inputs/EditableDateInput';
import { DateFormatter } from '../../../Atoms/Formatter/DateFormatter';
import { EditableDecimalNumber } from '../../../Atoms/inputs/EditableDecimalNumber';
import { DecimalNumberFormatter } from '../../../Atoms/Formatter/DecimalNumberFormatter';
import { EditableTimeInput } from '../../../Atoms/inputs/EditableTimeInput';
import { TimeFormatter } from '../../../Atoms/Formatter/TimeFormatter';
import { HtmlFormatter } from '../../../Atoms/Formatter/HtmlFormatter';
import { hasSuitableLicence } from '../../../Service/helper/LicenceService';
import { EditableSelectField } from '../../../Atoms/inputs/EditableSelectField';
import { EditableCustomAttributeValueProperty } from '../../../Atoms/EditableProperties/EditableCustomAttributeValueProperty';
import { EditableAutocomplete } from '../../../Atoms/inputs/EditableAutocomplete';
import { EditableDivInput } from '../../../Atoms/inputs/EditableDivInput';
import { AuthContext } from '../../../Service/Context/AuthContext';
import MultipleChoiceInput from './MutipleChoiceInput';

const CustomAttributeField = ({ attribute, entityId, refreshGroups, refreshValue }) => {
  const { i18n } = useLingui();

  const { jwtClaims } = useContext(AuthContext);
  const userLicence = jwtClaims.licence;
  const [values, setValues] = useState(undefined);

  const [value, setValue] = useState();
  const { id, name, description, attributeType, selectable, suggestions } = attribute;

  const handleChange = (v) => {
    setValue(v);
    refreshValue();
  };

  useEffect(() => {
    if (selectable && id) {
      fetchCustomAttributeValues(id)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((values) => {
              const tmpValues = [];
              values.forEach((v) => {
                if (!tmpValues.find((item) => item.value === v.value)) {
                  tmpValues.push(v);
                }
              });
              suggestions.forEach((v) => {
                if (!tmpValues.find((item) => item.value === v)) {
                  tmpValues.push({label: v, value: v});
                }
              });
              setValues(tmpValues);
            });
          } else {
            console.error(`could not fetch values of attributeId ${id}`);
          }
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not fetch values of attributeId ${id}`);
        });
    }
  }, [id, attributeType]);

  useEffect(() => {
    if (entityId) {
      fetchCustomAttributeValueByEntityId(id, entityId)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((value) => {
              setValue(value);
            });
          } else if (resp.status === 404 || resp.status === 500) {
            const placeholderDate = '2020-04-21';
            setValue({ value: '', lastModified: placeholderDate });
          } else {
            console.error(`could not load value of attribute ${name}`);
          }
        })
        .catch(() => {
          console.error(`could not load value of attribute ${name}`);
        });
    }
  }, [id]);

  if (!value) {
    return null;
  }

  const opts = {};
  if (!hasSuitableLicence('PRO', userLicence)) {
    opts.disabled = true;
  }

  const booleanOptions = [
    { label: i18n._('common.yes'), value: true },
    { label: i18n._('common.no'), value: false },
    { label: i18n._('common.no-selection'), value: null },
  ];

  switch (attributeType) {
    case 'STRING':
      return (
        <EditableCustomAttributeValueProperty
          entityId={entityId}
          handleChange={handleChange}
          value={value}
          attribute={attribute}
          placeholder={name}
          {...opts}
          suggestions={values}
          editFormatter={selectable ? EditableAutocomplete : SimpleEditableText}
          labelFormatter={HtmlFormatter}
          description={description}
        />
      );
    case 'TEXT':
      return (
        <EditableCustomAttributeValueProperty
          entityId={entityId}
          handleChange={handleChange}
          value={value}
          attribute={attribute}
          placeholder={name}
          {...opts}
          suggestions={values}
          editFormatter={selectable ? EditableAutocomplete : EditableDivInput}
          labelFormatter={HtmlFormatter}
          description={description}
        />
      );
    case 'NUMBER':
      return (
        <EditableCustomAttributeValueProperty
          entityId={entityId}
          handleChange={handleChange}
          value={value}
          attribute={attribute}
          placeholder={name}
          {...opts}
          editFormatter={SimpleEditableText}
          labelFormatter={NumberFormatter}
          description={description}
        />
      );
    case 'DATE':
      return (
        <EditableCustomAttributeValueProperty
          entityId={entityId}
          handleChange={handleChange}
          value={value}
          attribute={attribute}
          placeholder={name}
          {...opts}
          editFormatter={EditableDateInput}
          labelFormatter={DateFormatter}
          description={description}
        />
      );
    case 'TIME':
      return (
        <EditableCustomAttributeValueProperty
          entityId={entityId}
          handleChange={handleChange}
          value={value}
          attribute={attribute}
          placeholder={name}
          {...opts}
          editFormatter={EditableTimeInput}
          labelFormatter={TimeFormatter}
          description={description}
        />
      );
    case 'BOOLEAN':
      return (
        <EditableCustomAttributeValueProperty
          entityId={entityId}
          options={booleanOptions}
          handleChange={handleChange}
          value={value}
          attribute={attribute}
          placeholder={name}
          {...opts}
          editFormatter={EditableSelectField}
          editOnly
          description={description}
        />
      );
    case 'DECIMAL':
      return (
        <EditableCustomAttributeValueProperty
          entityId={entityId}
          handleChange={handleChange}
          value={value}
          attribute={attribute}
          placeholder={name}
          {...opts}
          editFormatter={EditableDecimalNumber}
          labelFormatter={DecimalNumberFormatter}
          description={description}
        />
      );
    case 'MULTIPLE_CHOICE':
      return <MultipleChoiceInput attribute={attribute} initialValue={value.value} />;
    default:
      return null;
  }
};

export default CustomAttributeField;
