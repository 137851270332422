import ChatMessage from '../../Molecules/Message/ChatMessage';
import { useContext } from 'react';
import { EmailBoxContext } from '../../Service/Context/EmailBoxContext';
import { MessageContext } from '../../Service/Context/MessageContextV2';
import { Text } from '@mantine/core';

const ChatMessagePreview = () => {
  const { selectedMessage } = useContext(EmailBoxContext);
  const { action } = useContext(MessageContext);

  if (!selectedMessage || action) {
    return null;
  }

  return (
    <div>
      <Text
        p="sm"
        size="sm"
        weight="bolder"
        color="blue"
        sx={(theme) => ({
          backgroundColor: theme.colors.lightBlue[0],
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
        })}
        pos="relative"
      >
        Vorschau der Nachricht
      </Text>
      <ChatMessage phoneVersion={false} />
    </div>
  );
};

export default ChatMessagePreview;
