import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_MAIL_SERVICE_URL;

export const fetchMailOutboundConfigs = () => ajaxActions.get(`${BASE_URL}/mailOutboundConfigs`).then((resp) => resp);

export const fetchMailOutboundConfig = (id) =>
  ajaxActions.get(`${BASE_URL}/mailOutboundConfigs/${id}`).then((resp) => resp);

export const createMailOutboundConfig = () => ajaxActions.put(`${BASE_URL}/mailOutboundConfigs`).then((resp) => resp);

export const patchMailOutboundConfig = (id, patch) =>
  ajaxActions.patch(`${BASE_URL}/mailOutboundConfigs/${id}`, patch).then((resp) => resp);

export const sendTestMail = (id) =>
  ajaxActions.get(`${BASE_URL}/mailOutboundConfigs/${id}/testMail`).then((resp) => resp);
