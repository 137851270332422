import React, { useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { DocumentS } from '../../Templates/Documents/DocumentS';
import ResourceAdapter from '../ResourceAdapter';
import { ToastS } from '../ToastS';

export const ContractContext = React.createContext(undefined);

const ContractContextProvider = (props) => {
  const [contract, setContract] = useState(props.contract);
  const { id } = contract;

  return (
    <ContractContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        contract,
        patch: (patch) => {
          DocumentS.patchContract(id, patch).then((patchedContract) => {
            if (patchedContract) {
              setContract(patchedContract);
            } else {
              ToastS.generalError();
            }
          });
        },
        handleChange: (patch) => {
          setContract((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
      }}
    >
      <Outlet />
    </ContractContext.Provider>
  );
};

export const ContractContextProviderProxy = () => {
  const { contractId } = useParams();

  return (
    <ResourceAdapter
      serviceUrl={`${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/contracts/${contractId}`}
      component={(props) => {
        const contract = props.data;
        return <ContractContextProvider contract={contract} />;
      }}
    />
  );
};
