import { useParams } from 'react-router-dom';
import { Alert } from '@mantine/core';
import { IconBulb } from '@tabler/icons';

const EmailTemplateTooltips = () => {
  const { type } = useParams();
  const messageType = type?.toUpperCase();

  return (
    <>
      {(messageType === 'AVAILABLE' || messageType === 'UNAVAILABLE') && (
        <Alert title="Tipp" icon={<IconBulb />} sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })}>
          Verwende hier nur Platzhalter von Daten, die Du zum Zeitpunkt der Anfrage auch schon hast wie bswp. das
          Event-Datum und ggf. den Namen Deines Kunden. Wenn Du das Kontaktformular Deiner Webseite mit bookitup
          verknüpft hast, solltest Du nur von Daten der Pflichtfelder ausgehen. Andernfalls müsstest Du den Text bei
          einer Anfrage manuell anpassen.
        </Alert>
      )}
      {messageType === 'OFFER' && (
        <Alert title="Tipp" icon={<IconBulb />} sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })}>
          Wähle unter "Anhänge voreinstellen" -&gt; "Mögliche Dokument-Anhänge" -&gt; "Angebot" aus. Damit wird Dir beim
          Laden dieser Vorlage direkt vorgeschlagen das betreffende Angebot beizufügen.
        </Alert>
      )}
      {messageType === 'FOLLOW_UP' && (
        <Alert title="Tipp" icon={<IconBulb />} sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })}>
          Füge das betreffende Angebot noch einmal für Deinen Kunden an diese E-Mail an. Wähle dafür unter "Anhänge
          voreinstellen" -&gt; "Mögliche Dokument-Anhänge" -&gt; "Angebot" aus.
          <br />
          Wenn Du dann, in einem konkreten Event, diese Vorlage lädst, kannst Du direkt das betreffende Angebot
          auswählen.
        </Alert>
      )}
    </>
  );
};

export default EmailTemplateTooltips;
