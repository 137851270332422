import { FC, useEffect, useState } from 'react';
import ResourceCategoryS from "../../Service/restapi/resourceCategoryService";
import { Select, TextInput } from "@mantine/core";
import { IconSearch } from "@tabler/icons";
import { DataS } from "../../Service/DataS";
import { useDebouncedCallback } from "use-debounce";
import { ResourceFilter } from "../../Types/ResourceT";

export const AddResourceFilters:FC<{
  updateFilter: (patch: Partial<ResourceFilter>) => void;
  query?: string;
  categoriesOfInterest: string[];
  typesOfInterest: string[];
}> = ({ updateFilter, query, categoriesOfInterest, typesOfInterest }) => {
  const [categories, setCategories] = useState<string[]>([]);

  useEffect(() => {
    ResourceCategoryS.fetchAllOptions()
      .then(setCategories);
  }, []);

  const updateQuery = useDebouncedCallback((val: string | undefined, type : string | null | undefined, category : string | null | undefined) => {
    let newValue = val ? val : query;
    let newCategoriesOfInterest = category === undefined ? categoriesOfInterest : (category === null ? [] : [category]);
    let newTypesOfInterest = type === undefined ? typesOfInterest : (type === null ? [] : [type]);
    updateFilter({ query: newValue, typesOfInterest: newTypesOfInterest, categoriesOfInterest: newCategoriesOfInterest});
  }, 500);

  return (
    <>
      <TextInput
        defaultValue={query}
        variant="filled"
        placeholder="Ressourcen durchsuchen"
        icon={<IconSearch size={16} />}
        onChange={(e) => updateQuery(e.currentTarget.value, undefined, undefined)}
        sx={{ width: 250 }}
        mb="xl"
      />
      <Select
        variant="filled"
        mt="md"
        clearable
        placeholder="Typ"
        sx={{marginTop: "0px !important", marginLeft: 5, marginBottom: 24, width: 110}}
        data={DataS.resourceTypeOptions}
        value={(typesOfInterest && typesOfInterest.length > 0) ? typesOfInterest[0] : null}
        onChange={(newType) => updateQuery(undefined, newType, undefined)}
      />
      <Select
        mt="md"
        clearable
        searchable
        placeholder="Kategorie"
        sx={{marginTop: "0px !important", marginLeft: 5, marginBottom: 24}}
        variant={"filled"}
        data={categories}
        value={categoriesOfInterest.length > 0 ? categoriesOfInterest[0] : null}
        onChange={(category) => updateQuery(undefined, undefined, category)}

      />
    </>
  );
}
