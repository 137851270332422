// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { i18n } from '@lingui/core';
import { FC } from 'react';
import { WorkflowStepTemplateDTO } from '../../../Types/LogT';

const ExecutionConfigPreview: FC<{ workflowStep: WorkflowStepTemplateDTO }> = ({ workflowStep }) => {
  const { executionConfig } = workflowStep;
  const { executionStrategy, relativeAmount, relativeUnit, manualApprovalRequired } = executionConfig ?? {};

  if (executionStrategy === 'WAIT_UNTIL') {
    const { entityOfInterest, typeOfInterest } = executionConfig;
    if (!entityOfInterest) {
      return <Text color="red">Ungültige Konfiguration</Text>;
    }
    return (
      <>
        <Text>{i18n._(`waitUntil.${entityOfInterest ? entityOfInterest.entityType : null}.${typeOfInterest}`)}</Text>
        <Text size="xs" color="blue">
          Auf Ereignis warten
        </Text>
      </>
    );
  }
  if (manualApprovalRequired) {
    return (
      <Text size="xs" color="blue">
        Manuelle Genehmigung erforderlich
      </Text>
    );
  }
  const relativeStr = `${relativeAmount} ${i18n._(`chronoUnit.${relativeUnit}`)}`;

  if (executionStrategy === 'AFTER_PREVIOUS_STEP') {
    return (
      <Text size="xs" color="blue">
        {relativeAmount > 0 ? `Nach ${relativeStr} ausführen` : 'Sofort ausführen'}
      </Text>
    );
  }
  const strategyTranslation = `${i18n._(`execution.${executionStrategy}`)}`;
  return (
    <Text size="xs" color="blue">
      {relativeAmount > 0 ? `${relativeStr} ${strategyTranslation}` : strategyTranslation}
    </Text>
  );
};

export default ExecutionConfigPreview;
