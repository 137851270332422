import React, { useContext, useEffect, useState } from 'react';
import { Trans, withI18n } from '@lingui/react';
import { HorizontalAlignButtonGroup } from '../../Atoms/ButtonGroup';
import { LoadingAnimation } from '../../Service/ResourceAdapter';
import { fetchBlockStyleById, patchBlockStyleById } from '../../Service/restapi/blockStyleService';
import { TransButtonDefaultWithIcon, TransButtonPrimary } from '../../Atoms/Buttons';
import { ModalDialog, useModalState } from '../../Molecules/Dialogs';
import { EditableBlockStyleProperty } from '../../Atoms/EditableProperties/EditableBlockStyleProperty';
import { NumberFormatter } from '../../Atoms/Formatter/NumberFormatter';
import { SimpleEditableText } from '../../Atoms/inputs/SimpleEditableText';

export default withI18n()(({ blockStyleId, reload }) => {
  const [blockStyle, setBlockStyle] = useState(undefined);
  const { show, toggle } = useModalState(false);
  const { showConfirm, toggleConfirm } = useModalState(false);

  useEffect(() => {
    fetchBlockStyleById(blockStyleId).then((resp) => {
      if (resp.ok) {
        resp.json().then((newTextBlock) => {
          setBlockStyle(newTextBlock);
        });
      }
    });
  }, [blockStyleId]);

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 2,
    },
    trash: {},
    description: {
      marginLeft: 5,
      color: '#9499a7',
      fontSize: 13,
    },
  };

  function patchTextBlock(alignment) {
    patchBlockStyleById(blockStyleId, { horizontalAlignment: alignment }).then((resp) => {
      if (resp.ok) {
        resp.json().then((newTextBlock) => {
          setBlockStyle(newTextBlock);
          reload();
        });
      } else {
      }
    });
  }

  if (!blockStyle) {
    return <LoadingAnimation />;
  }

  return (
    <div style={styles.container}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <HorizontalAlignButtonGroup value={blockStyle.horizontalAlignment} onChange={patchTextBlock} />
        <div style={{ marginLeft: 5, marginRight: 5 }}>
          <TransButtonDefaultWithIcon icon="PALETTE" onClickHandler={toggle} />
        </div>
      </div>

      <ModalDialog
        className="modal-xl"
        header={<Trans id="Darstellung" />}
        body={
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1, marginRight: 5 }}>
              <EditableBlockStyleProperty
                patchField="fontColor"
                entity={blockStyle}
                placeholder="Schrift-Farbe"
                changeHandler={setBlockStyle}
                labelFormatter={NumberFormatter}
                editFormatter={SimpleEditableText}
              />
              <EditableBlockStyleProperty
                patchField="backgroundColor"
                entity={blockStyle}
                placeholder="Hintergrund-Farbe"
                changeHandler={setBlockStyle}
                labelFormatter={NumberFormatter}
                editFormatter={SimpleEditableText}
              />
              <EditableBlockStyleProperty
                patchField="borderColor"
                entity={blockStyle}
                placeholder="Rahmen-Farbe"
                changeHandler={setBlockStyle}
                labelFormatter={NumberFormatter}
                editFormatter={SimpleEditableText}
              />
              <EditableBlockStyleProperty
                patchField="borderRadius"
                entity={blockStyle}
                placeholder="Rahmen-Rundung"
                changeHandler={setBlockStyle}
                labelFormatter={NumberFormatter}
                editFormatter={SimpleEditableText}
              />
              <EditableBlockStyleProperty
                patchField="width"
                entity={blockStyle}
                placeholder="Breite der Spalte"
                changeHandler={setBlockStyle}
                labelFormatter={NumberFormatter}
                editFormatter={SimpleEditableText}
              />
            </div>
            <div style={{ flex: 1, marginLeft: 5 }}>
              <EditableBlockStyleProperty
                patchField="paddingTop"
                entity={blockStyle}
                placeholder="Abstand (Oben)"
                changeHandler={setBlockStyle}
                labelFormatter={NumberFormatter}
                editFormatter={SimpleEditableText}
              />
              <EditableBlockStyleProperty
                patchField="paddingBottom"
                entity={blockStyle}
                placeholder="Abstand (Unten)"
                changeHandler={setBlockStyle}
                labelFormatter={NumberFormatter}
                editFormatter={SimpleEditableText}
              />
              <EditableBlockStyleProperty
                patchField="paddingLeft"
                entity={blockStyle}
                placeholder="Abstand (Links)"
                changeHandler={setBlockStyle}
                labelFormatter={NumberFormatter}
                editFormatter={SimpleEditableText}
              />
              <EditableBlockStyleProperty
                patchField="paddingRight"
                entity={blockStyle}
                placeholder="Abstand (Rechts)"
                changeHandler={setBlockStyle}
                labelFormatter={NumberFormatter}
                editFormatter={SimpleEditableText}
              />
            </div>
          </div>
        }
        footer={
          <div>
            <TransButtonPrimary onClickHandler={toggle} text="actions.ok" />{' '}
          </div>
        }
        show={show}
        toggle={toggle}
      />
    </div>
  );
});
