import React from 'react';
import { Trans } from '@lingui/react';

const RecommendationPipeline = ({ answer, bookingState, lastContactShare }) => (
  <>
    <div className={`pipeline recommendation start ${answer ? answer.toLowerCase() : 'unknown'}`}>
      <div className="text overflow-ellipsis">
        <Trans id={`recommendation-pipeline.requestAnswer.${answer ? answer.toLowerCase() : 'unknown'}`} />
      </div>
    </div>
    <div className={`pipeline recommendation middle ${bookingState ? bookingState.toLowerCase() : 'open'}`}>
      <div className="text overflow-ellipsis">
        <Trans id={`recommendation-pipeline.bookingState.${bookingState ? bookingState.toLowerCase() : 'open'}`} />
      </div>
    </div>
    <div className={`pipeline recommendation end ${lastContactShare ? 'data-transmitted' : 'data-not-transmitted'}`}>
      <div className="text overflow-ellipsis">
        <Trans id={`recommendation-pipeline.contactShared.${lastContactShare ? 'true' : 'false'}`} />
      </div>
    </div>
  </>
);

export default RecommendationPipeline;
