import React, { useContext, useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { ModalDialog } from '../../Molecules/Dialogs';
import { TransButtonDefault, TransButtonPrimaryWithSpinner } from '../../Atoms/Buttons';
import SelectContractTemplate from '../../Molecules/Event/Contracts/SelectContractTemplate';
import { DocumentSelector } from '../../Templates/Documents/DocumentSelector';
import { getContractTemplateByName } from '../../Service/restapi/contractTemplateService';

export default function ({ open, toggle, finish, isLoading, eventId }) {
  const [template, setTemplate] = useState(undefined);
  const [templateName, setTemplateName] = useState(undefined);
  const [bookingDocument, setDocument] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const styles = {
    templateSubject: {
      margin: '15px 0px 5px 0px',
    },
    selectDocument: {
      marginTop: 20,
    },
  };

  useEffect(() => {
    setLoading(true);
    if (templateName) {
      getContractTemplateByName(templateName)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((contractTemplate) => {
              setTemplate(contractTemplate);
            });
          } else {
            console.error(`could not load contract template ${templateName}`);
          }
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not load contract template ${templateName}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [templateName]);

  console.log(template)

  return (
    <ModalDialog
      header={<Trans id="contract.template.select.dialog-title" />}
      help="help.contract.template.select"
      body={
        <div>
          <div style={styles.templateSubject}>Vorlage auswählen:</div>
          <SelectContractTemplate contractTemplate={templateName} onChange={(t) => setTemplateName(t)} />
          {template && template.positions && (
            <div style={styles.selectDocument}>
              <div>
                In diesen Vertrag werden Positionen eingefügt. Wähle das Dokument, aus welchem diese übernommen werden
                sollen:
              </div>
              <DocumentSelector
                entityType="documents"
                eventId={eventId}
                selectedId={bookingDocument ? bookingDocument.id : undefined}
                onSelect={setDocument}
              />
            </div>
          )}
        </div>
      }
      footer={
        <div>
          <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />{' '}
          <TransButtonPrimaryWithSpinner
            disabled={loading}
            isLoading={isLoading}
            onClickHandler={() => {
              finish(templateName, bookingDocument);
            }}
            text="common.apply"
          />
        </div>
      }
      show={open}
      toggle={toggle}
    />
  );
}
