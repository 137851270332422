import { addYears } from 'date-fns';
import React, { useState } from 'react';

export const NavigationContext = React.createContext(undefined);

export const NavigationContextProvider = (props) => {
  const [searchParameter, setSearchParameter] = useState({
    from: new Date(),
    to: addYears(new Date(), 5),
    searchText: '',
    searchState: ['OPEN', 'OFFERED', 'BOOKED'],
    availability: ['AVAILABLE'],
    searchStateRequest: ['OPEN', 'OFFERED', 'BOOKED'],
    pastEvents: 'no',
    sortingBy: 'chronological',
    filterCustomerContactShared: false,
  });

  return (
    <NavigationContext.Provider
      value={{
        searchParameter,
        handleSearchParameterChange: (patch) => {
          setSearchParameter((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
      }}
    >
      {props.children}
    </NavigationContext.Provider>
  );
};
