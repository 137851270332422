import moment from 'moment';
import Contact from '../../Types/Contact';
import BookingEvent from '../../Types/Event';
import { getPlaceholderLabelById } from './PlaceholderLabels';
import { isEmpty } from '../../Utils/utils';

export type PlaceholderDataSource = {
  event?: BookingEvent;
  customer?: Contact;
  _document?: any;
  contract?: any;
  eventLocation?: any;
  customAttributeValues?: any;
};

const getPlaceholderValueForId = (id: string, entityName: string, entity: any, i18n: any) => {
  const PREFIX = `${entityName}_`;
  if (entity && id.indexOf(PREFIX) !== -1) {
    const fieldName = id.substring(PREFIX.length);
    if (fieldName.includes('date') || fieldName.toLowerCase().includes('until')) {
      const format = i18n._('format.date.short');
      return moment(entity[fieldName]).format(format);
    }
    return entity[fieldName];
  }
};

const getCustomAttributePlaceholderValueForId = (id: string, customAttributeValues: any, i18n: any) => {
  const value = customAttributeValues.find((v: any) => v.placeholderId === id);

  if (value) {
    if (value.attributeType === 'DATE') {
      const format = i18n._('format.date.short');
      return moment(value.value).format(format);
    }
    if (value.attributeType === 'BOOLEAN') {
      if (value.value === 'true') {
        return 'Ja';
      }
      return 'Nein';
    }
    if (value.attributeType === 'MULTIPLE_CHOICE') {
      return value.value?.replace(';', ', ');
    }

    return value.value;
  }
};

export const getPlaceholderValue = (id: string, i18n: any, rest: PlaceholderDataSource) => {
  const replaceData = (entityName: string, entity: any) => {
    if (!entity) {
      return null;
    }
    const value = getPlaceholderValueForId(id, entityName, entity, i18n);
    return value !== undefined ? value : null;
  };

  const { event, contract, customAttributeValues, customer, eventLocation, _document } = rest;
  const { partner } = (customer as any) ?? {};

  if (event) {
    const flatEvent = {
      ...event,
      ...event.dates[0], // TODO
    };
    if (id.includes('event_')) {
      return replaceData('event', flatEvent);
    }
  }

  if (customer && (id === 'customer_and_partner_names' || id === 'customer_and_partner_first_names')) {
    const { firstName, lastName } = customer;
    const { firstName: partnerFirstName, lastName: partnerLastName } = partner ?? {};

    if (id === 'customer_and_partner_names') {
      if (firstName && lastName && partnerFirstName && partnerLastName) {
        return `${firstName} ${lastName} und ${partnerFirstName} ${partnerLastName}`;
      }
      if (firstName && lastName) {
        return `${firstName} ${lastName}`;
      }
    } else {
      if (firstName && partnerFirstName) {
        return `${firstName} und ${partnerFirstName}`;
      }
      if (firstName) {
        return firstName;
      }
    }
  }

  if (customer && id.includes('customer_') && !id.includes('partner')) {
    return replaceData('customer', customer);
  }
  if (partner && id.includes('customer_partner_')) {
    return replaceData('customer_partner', partner);
  }
  if (id.includes('document_')) {
    return replaceData('document', _document);
  }

  if (id.includes('contract_')) {
    return replaceData('contract', contract);
  }

  if (id.includes('location_')) {
    return replaceData('location', eventLocation);
  }

  if (customAttributeValues && id.includes(':')) {
    const value = getCustomAttributePlaceholderValueForId(id, customAttributeValues, i18n);
    return value !== undefined ? value : null;
  }

  return null;
};

export const replacePlaceholdersWithHtmlSettings = (i18n: any, value: string) => {
  if (!value) {
    return '';
  }
  let result = value;

  parsePlaceholders(value).forEach((matchedId) => {
    const id = matchedId.substring(1, matchedId.length - 1);

    const label = getPlaceholderLabelById(i18n, id);

    result = result.replaceAll(
      matchedId,
      `<span data-type="mention" data-label="${label}" data-id="${id}">${label}</span>`,
    );
  });
  return result;
};

export const replacePlaceholdersHtmlWithPlaceholdersId = (htmlString: string) => {
  // Create a temporary div element
  const tempDiv = document.createElement('div');
  // Set the HTML content of the div
  tempDiv.innerHTML = htmlString;
  // Find all span elements with the specified class
  const spanElements = tempDiv.querySelectorAll('.mention[data-id], .unresolved[data-id]');
  // Iterate over each span element and replace it with the data-id attribute value
  spanElements.forEach((spanElement) => {
    const dataIdValue = spanElement.getAttribute('data-id') ?? '';
    const textContent = document.createTextNode(`{${dataIdValue}}`);
    spanElement.parentNode?.replaceChild(textContent, spanElement);
  });
  // Return the modified HTML content
  return tempDiv.innerHTML;
};

export const parsePlaceholders = (value: string) => value.match(/{[\w:-]+}/g) ?? [];

export const replaceTemplatePlaceholdersWithHtml = (i18n: any, value: string, rest: PlaceholderDataSource) => {
  let result = value;

  parsePlaceholders(value).forEach((matchedId) => {
    const id = matchedId.substring(1, matchedId.length - 1);

    const resolved = getPlaceholderValue(id, i18n, rest);
    result = result.replaceAll(matchedId, createHtmlRtePlaceholder(i18n, id, resolved));
  });
  return result;
};

const createHtmlRtePlaceholder = (i18n: any, id: string, resolved?: string) => {
  const isResolved = resolved && resolved !== null && resolved.length > 0;

  const isCustom = id.includes(':');

  // eslint-disable-next-line no-nested-ternary
  const value = isResolved ? resolved : isCustom ? id : getPlaceholderLabelById(i18n, id);

  if (id === 'event_portalLink' && isResolved) {
    return previewUrl('Kunden-Portal öffnen', value);
  }
  return `<span data-type="mention" data-resolved="${isResolved}" data-label="${value}" data-id="${id}">${value}</span>`;
};

const previewUrl = (label: string, href: string) => `<a href="${href}">${label}</a>`;

export const replaceTemplatePlaceholdersWithHTMLValues = (i18n: any, value: string, rest: PlaceholderDataSource) => {
  let result = value;

  parsePlaceholders(value).forEach((id) => {
    const resolved = getPlaceholderValue(id.substring(1, id.length - 1), i18n, rest);
    const finalValue = resolved ?? getPlaceholderLabelById(i18n, id.substring(1, id.length - 1));

    if (id === '{event_portalLink}') {
      result = result.replaceAll(
        id,
        `<a class="${
          resolved !== null ? undefined : 'unresolved'
        }" title="Kunden-Portal öffnen" href="${finalValue}"> Kunden-Portal öffnen </a>`,
      );
    } else {
      result = result.replaceAll(
        id,
        `<span class="${resolved !== null ? 'mention' : 'unresolved'}">${finalValue} </span>`,
      );
    }
  });
  return result;
};

export const replacePlaceholdersWithHtml = (i18n: any, value: string) => {
  if (!value) {
    return '';
  }
  let result = value;

  parsePlaceholders(value).forEach((matchedId) => {
    const id = matchedId.substring(1, matchedId.length - 1);

    const label = getPlaceholderLabelById(i18n, id);

    result = result.replaceAll(matchedId, `<span class="mention">${label}</span>`);
  });
  return result;
};

export const replaceTemplatePlaceholdersWithValues = (i18n: any, value: string, rest: PlaceholderDataSource) => {
  let result = value;

  parsePlaceholders(value).forEach((id) => {
    const resolved = getPlaceholderValue(id.substring(1, id.length - 1), i18n, rest);

    if (!isEmpty(resolved)) {
      result = result.replaceAll(id, resolved);
    }
  });
  return result;
};
