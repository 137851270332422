// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { openModal } from '@mantine/modals';
import { useContext } from 'react';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import { ResourceAddonContext } from '../../Service/Context/ResourceAddonsContext';
import CreateResourceModal from './CreateResouceModal';

const AddResourceIcon = () => {
  const { executeWithCheck } = useContext(ResourceAddonContext);
  const { interceptViolation } = useContext(ViolationContext);
  const addResouce = () => {
    executeWithCheck(() =>
      openModal({
        size: 'sm',
        title: (
          <Text weight="bolder" size="xl">
            Ressource erstellen
          </Text>
        ),
        closeOnEscape: true,
        transition: 'slide-down',
        children: <CreateResourceModal interceptViolation={interceptViolation} />,
      }),
    );
  };

  return <BookitupAddIcon onClick={addResouce} />;
};

export default AddResourceIcon;
