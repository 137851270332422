/* eslint-disable no-lonely-if */
import { FC, ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { MessageAction, MessageDTO } from '../../Types/MessageT';
import { useParams } from 'react-router';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { MessageS } from '../MessageS';
import { MessageContext } from './MessageContextV2';
import { useElementSize } from '@mantine/hooks';

interface EmailBoxContextProps {
  messages: MessageDTO[];
  loading: boolean;
  selectedMessage: MessageDTO | undefined;
  selectMessage: (message: MessageDTO | undefined) => unknown;
  changeEmail: (message: MessageDTO) => unknown;
  setPageNumber: (page: number) => unknown;
  prepareMessage: (action: MessageAction) => unknown;
  pageNumber: number;
  pageCount: number;
  totalCount: number;
  refresh: () => void;
  flushMessage: () => void;
}

export const EmailBoxContext = createContext<EmailBoxContextProps>({} as EmailBoxContextProps);

export const EmailBoxContextProvider: FC<{ contactIds?: number[]; children: ReactNode }> = ({
  contactIds,
  children,
}) => {
  const { box, filter, id: eventId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { changeByAction, changeMessage, message, action } = useContext(MessageContext);
  const [messages, setMessages] = useState<MessageDTO[]>([]);
  const [selectedMessage, selectMessage] = useState<MessageDTO>();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [trigger, setTrigger] = useState(Date.now());
  const [totalCount, setTotalCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const query = searchParams.get('query');
  const emailId = searchParams.get('emailId');
  const entityId = searchParams.get('entityId');
  const { ref, width } = useElementSize();

  useEffect(() => {
    const abortController = new AbortController();
    fetchMessages(abortController);
    return () => abortController.abort();
  }, [box, filter, pageNumber, query, trigger]);

  useEffect(() => {
    if (message && action !== 'EDIT') {
      changeMessage(null);
    }
  }, [selectedMessage]);

  useEffect(() => {
    if (selectedMessage) {
      selectMessage(undefined);
    }
    if (pageNumber !== 0) {
      setPageNumber(0);
    }
  }, [box]);

  useEffect(() => {
    if (emailId) {
      const optionalEmail = messages.find((e) => e.id === parseInt(emailId));
      if (optionalEmail) {
        selectMessage(optionalEmail);
      }
    } else if (width > 700 && !entityId) {
      selectMessage(messages[0]);
    }
  }, [emailId, messages]);

  const fetchMessages = async (abortController: AbortController) => {
    try {
      setLoading(true);
      let _messages: MessageDTO[] = [];
      if (query) {
        _messages = await MessageS.searchMessages(query);
      } else {
        const page = await MessageS.fetchEmails(
          box ?? '',
          filter ?? '',
          pageNumber,
          contactIds,
          abortController.signal,
        );
        _messages = page.messages;
        setTotalCount(page.count);
        setPageCount(page.pages);
      }
      setMessages(_messages);
    } finally {
      if (!abortController.signal.aborted) {
        setLoading(false);
      }
    }
  };

  const changeEmail = (message: MessageDTO) => {
    setMessages(messages.map((m) => (m.id === message.id ? message : m)));
  };

  const prepareMessage = async (action: MessageAction) => {
    if (selectedMessage) {
      // General postbox with assigned event --> Redirect to event scope
      if (!eventId && selectedMessage.eventId) {
        navigate(`/events/${selectedMessage.eventId}/emails/${box}?action=${action}&messageId=${selectedMessage.id}`);
      } else {
        // We are already in event scope or message doesn't have an event assigned yet
        changeByAction(selectedMessage.id, action);
      }
    }
  };

  const flushMessage = () => {
    if (selectedMessage) {
      changeEmail(selectedMessage);
    }
    changeMessage(null);
  };

  const refresh = () => setTrigger(Date.now());

  const value = useMemo(
    () => ({
      messages,
      loading,
      selectedMessage,
      pageNumber,
      totalCount,
      pageCount,
      prepareMessage,
      changeEmail,
      selectMessage,
      setPageNumber,
      refresh,
      flushMessage,
    }),
    [messages, loading, selectedMessage, pageNumber, totalCount, pageCount],
  );

  return (
    <EmailBoxContext.Provider value={value}>
      <div ref={ref}>{children}</div>
    </EmailBoxContext.Provider>
  );
};
