import { useEffect } from 'react';
import { Space, Stack, Text } from '@mantine/core';
import PanePaper from '../../../Molecules/PanePaper';
import ResourceCategoriesList from './ResourceCategoriesList';
import ResourceStandardElements from './ResourceStandardElements';

const ResourcesPane = () => {
  useEffect(() => {
    document.title = 'Ressourcen';
  }, []);

  return (
    <PanePaper title="Ressourcen">
      <Stack>
        <Text>Individualisere die Art, wie Du Deine Ressourcen in bookitup verwaltest.</Text>
        {/* <ResourceStandardElements /> */}
        <ResourceCategoriesList />
      </Stack>
    </PanePaper>
  );
};

export default ResourcesPane;
