/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, LoadingOverlay, Paper, Space, Text, TextInput } from '@mantine/core';
import { IconAt } from '@tabler/icons';
import { FC, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import validator from 'validator';
import { useMediaQuery } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { AlertS } from '../../Service/AlertS';
import { AuthS } from '../../Service/restapi/authService';
import { RoutingS } from '../../Service/RoutingS';
import AlertBox from './AlertBox';
import {MixpanelS} from "../../Service/MixpanelS";
import {ConstantS} from "../../Service/ConstantS";

const PasswordForgotten: FC = () => {
  const isPhoneDevice = useMediaQuery('(max-width: 480px)');
  const navigate: NavigateFunction = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<string>();
  const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (validator.isEmail(value) ? null : 'Bitte gib eine valide E-Mail-Adresse ein'),
    },
  });

  const requestNewPassword = () => {
    const { email } = form.values;
    if (alert) {
      setAlert(undefined);
    }
    setLoading(true);
    AuthS.resetPassword(null, { email })
      .then((resp) => {
        if (resp.ok) {
          setRequestSubmitted(true);
          MixpanelS.track(ConstantS.TrackingEvents.PasswordForgotten, {email});
        } else if (resp.status === 404) {
          setAlert(AlertS.AlertType.WRONG_EMAIL);
        } else {
          setAlert(AlertS.AlertType.UNEXPECTED_ERROR);
        }
      })
      .catch(() => setAlert(AlertS.AlertType.SERVER_UNAVAILABLE))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    document.title = 'Passwort vergessen?';
  }, []);

  return (
    <Paper
      shadow="xs"
      p={40}
      sx={{
        maxWidth: 480,
        borderRadius: 10,
        position: 'relative',
        backgroundClip: 'padding-box, border-box',
        backgroundImage: 'linear-gradient(#fff,#fff),linear-gradient(88deg,#ffba5e,#ff6b69)',
        borderTop: '10px double transparent',
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Space h="md" />
      <Text size={isPhoneDevice ? 22 : 28}>
        {requestSubmitted ? 'Überprüfe Deine E-Mails.' : 'Das kann passieren!'}
      </Text>
      <Text color="dimmed" mt={10}>
        {requestSubmitted
          ? 'Wir haben Dir eine E-Mail mit den nächsten Schritten geschickt. Siehe ggf. auch in Deinem Spam-Ordner nach.'
          : 'Mit welcher E-Mail hast Du Dich bei bookitup registriert?'}
      </Text>
      <Space h="md" />
      {alert && <AlertBox alert={alert} />}
      {!requestSubmitted && (
        <form onSubmit={form.onSubmit(requestNewPassword)}>
          <TextInput
            label={<Text size="xs">E-Mail</Text>}
            icon={<IconAt size={14} />}
            {...form.getInputProps('email')}
          />
          <Space h="xl" />
          <Button fullWidth type="submit">
            {requestSubmitted ? 'Jetzt anmelden' : 'Passwort zurücksetzen'}
          </Button>
        </form>
      )}
      {requestSubmitted && (
        <>
          <Space h="md" />
          <Button fullWidth onClick={() => navigate(RoutingS.ROUTES.LOGIN)}>
            Jetzt anmelden
          </Button>
        </>
      )}
      {!requestSubmitted && (
        <>
          <Space h="md" />
          <Text color="blue" align="center" sx={{ cursor: 'pointer' }} onClick={() => navigate(RoutingS.ROUTES.LOGIN)}>
            zurück zur Anmeldung
          </Text>
        </>
      )}
    </Paper>
  );
};

export default PasswordForgotten;
