import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_REQUEST_SERVICE_URL;

export const fetchWebForms = () => ajaxActions.get(`${BASE_URL}/webforms`).then((resp) => resp);

export const fetchLatestRequestOfWebForm = (webFormId: string) =>
  ajaxActions.get(`${BASE_URL}/webforms/${webFormId}/latest`).then((resp) => resp);

export const postWebForm = (webFormId: string, mapping: Record<string, string>) =>
  ajaxActions.post(`${BASE_URL}/webforms/${webFormId}`, mapping).then((resp) => resp);

export const patchCustomAttributeRequestField = (webFormId: string, websiteField: string, attributeId: string) =>
  ajaxActions
    .patch(`${BASE_URL}/webforms/${webFormId}/customAttributes`, { [websiteField]: attributeId })
    .then((resp) => resp);

export const patchFormAttributeRequestField = (webFormId: string, websiteField: string, attributeId: string) =>
  ajaxActions
    .patch(`${BASE_URL}/webforms/${webFormId}/formAttributes`, { [websiteField]: attributeId })
    .then((resp) => resp);

export const deleteWebForm = (webFormId: string) => ajaxActions.del(`${BASE_URL}/webforms/${webFormId}`).then((resp) => resp);
