import { ResourceCalendar } from '../Types/CalDavT';
import { ajaxActions } from './AjaxActions';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_CALDAV_SERVICE_URL;

const getCalendars = async (resouceId: string): Promise<ResourceCalendar[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/calendars?resourceId=${resouceId}`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const createCalendar = async (
  resourceId: string,
  label: string,
  calendarUrl: string,
): Promise<ResourceCalendar | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/calendars`, { resourceId, label, calendarUrl });
  if (res.ok) {
    return res.json();
  }
  if (res.status === 409) {
    ToastS.warn('cal-confict', 'Dieser Kalender wurde bereits hinzugefügt.');
  } else if (res.status === 412) {
    ToastS.error('cal-invalid', 'Der Kalender existiert wahrscheinlich nicht oder die angegebene URL ist falsch.');
  } else {
    ToastS.generalError();
  }
  return null;
};

const editCalendar = async (calendarId: string, patch: Partial<ResourceCalendar>): Promise<ResourceCalendar | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/calendars/${calendarId}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const removeCalendar = async (calendarId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/calendars/${calendarId}`);
  if (res.status === 204) {
    ToastS.info('cal-deleted', 'Kalender wurde gelöscht');
    return true;
  }
  ToastS.generalError();
  return false;
};

// eslint-disable-next-line import/prefer-default-export
export const CalDavS = {
  getCalendars,
  createCalendar,
  editCalendar,
  removeCalendar,
};
