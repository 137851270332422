import { FC } from 'react';
import Moment from 'react-moment';
import { IconBallpen, IconChevronsDown, IconChevronsUp, IconDotsVertical, IconTrashX } from '@tabler/icons';
import { Menu } from '@mantine/core';
import { StockIntervalDto } from '../../../Types/ResourceT';

interface Props {
  stockInterval: StockIntervalDto;
  deleteInterval: () => void;
  editInterval: () => void;
}

const StockIntervalItem: FC<Props> = ({ stockInterval, deleteInterval, editInterval }) => {
  const { quantityChange, begin, description } = stockInterval;

  return (
    <tr>
      <td>
        {quantityChange > 0 ? (
          <IconChevronsUp size={16} color="#4EBF46" />
        ) : (
          <IconChevronsDown size={16} color="#f44336" />
        )}
      </td>
      <td> {quantityChange}</td>
      <td>
        <Moment date={begin} format="DD.MM.YYYY HH:mm" />
      </td>
      <td> {description}</td>
      <td>
        <Menu withArrow shadow="md" width={150} transition="scale-y" position="bottom-end">
          <Menu.Target>
            <div>
              <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<IconBallpen size={16} />} onClick={editInterval}>
              Bearbeiten
            </Menu.Item>
            <Menu.Item icon={<IconTrashX size={16} />} color="red" onClick={deleteInterval}>
              Löschen
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </td>
    </tr>
  );
};

export default StockIntervalItem;
