import { closeAllModals } from '@mantine/modals';
import React, { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { fetchEventById } from '../../../../Service/restapi/eventService';

export default function ({ history, index, eventId, value }) {
  const [event, setEvent] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (eventId) {
      fetchEventById(eventId).then((resp) => {
        if (resp.ok) {
          resp.json().then(setEvent);
        }
      });
    }
  }, [eventId]);

  if (!event) {
    return null;
  }

  const onClick = () => {
    closeAllModals();
    navigate(`/events/${event.id}/details`);
  }

  const getFinalValue = () => {
    if (typeof value === 'boolean') {
      return value ? 'Ja' : 'Nein';
    }
    return value;
  };

  const styles = {
    container: {
      display: 'flex',
      padding: 10,
      backgroundColor: index % 2 ? '#fbfbfb' : 'unset',
    },
  };

  return (
    <div style={styles.container} className="clickable" onClick={onClick}>
      <div>
        Event am <Moment format="DD.MM.YYYY">{event.eventDate.date}</Moment> - {getFinalValue()}
      </div>
    </div>
  );
}
