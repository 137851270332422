import {
  AttributeType,
  CustomAttributeDTO,
  CustomAttributeGroupDTO,
  CustomAttributeValueDTO,
  CustomAttributeWithGroupNameDTO,
} from '../../Types/CustomAttributeT';
import { hasSpecialChar, replaceSpecialChar, replaceUmlaute } from '../../Utils/utils';
import { ajaxActions } from '../AjaxActions';
import { ToastS } from '../ToastS';

const BASE_URL = process.env.REACT_APP_ATTRIBUTE_SERVICE_URL;

const moveAttribute = async (
  groupId: string,
  fromIndex: number,
  newIndex: number,
): Promise<CustomAttributeDTO[] | null> => {
  const targetUrl = `${BASE_URL}/attributegroups/${groupId}/attribute?fromIndex=${fromIndex}&newIndex=${newIndex}`;
  const res = await ajaxActions.patch(targetUrl, {});
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const moveGroup = async (entityType: string, fromIndex: number, newIndex: number) => {
  const targetUrl = `${BASE_URL}/${entityType}/attributegroups?fromIndex=${fromIndex}&newIndex=${newIndex}`;
  const res = await ajaxActions.patch(targetUrl, {});
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const sanitaze = (patch: any) => {
    Object.keys(patch).forEach((key) => {
    if (key === 'name' && hasSpecialChar(patch[key])) {
      ToastS.info('foo', 'Sonderzeichen sind nicht erlaubt und wurden entfernt');
      // eslint-disable-next-line no-param-reassign
      patch[key] = replaceSpecialChar(patch[key]);
    }
    if (key !== 'selectable' && key != 'suggestions') {
      patch[key] = replaceUmlaute(patch[key]);
    }
  });
  return patch;
};

const editAttribute = async (
  attributeId: string,
  patch: Partial<CustomAttributeDTO>,
): Promise<CustomAttributeDTO | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/attributes/${attributeId}`, sanitaze(patch));
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const fetchGroups = async (entityType: string): Promise<CustomAttributeGroupDTO[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/${entityType}/attributegroups`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchGroupAttributes = async (groupId: string): Promise<CustomAttributeDTO[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/attributegroups/${groupId}/attributes`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const deleteAttributesGroup = async (groupId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/attributegroups/${groupId}`);
  if (res.ok) {
    return true;
  }
  ToastS.generalError();
  return false;
};

const editGroup = async (
  groupId: string,
  patch: Partial<CustomAttributeGroupDTO>,
): Promise<CustomAttributeGroupDTO | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/attributegroups/${groupId}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const fetchAttributesByType = async (attributeType: AttributeType): Promise<CustomAttributeWithGroupNameDTO[]> => {
  // Use STRING type for not supported attributes types
  const finalType: AttributeType = isUnsupportedType(attributeType) ? 'STRING' : attributeType;
  const res = await ajaxActions.get(`${BASE_URL}/attributes/type/${finalType}`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return [];
};

const fetchAttribute = async (id: string): Promise<CustomAttributeDTO | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/attributes/${id}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchAllAttributes = async (): Promise<CustomAttributeWithGroupNameDTO[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/attributes`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchAttributeValue = async (attributeId: string, entityId: string): Promise<CustomAttributeValueDTO | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/attributes/${attributeId}/values/${entityId}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const patchAttributeValue = async (
  attributeId: string,
  entityId: string,
  value: unknown,
): Promise<CustomAttributeValueDTO | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/attributes/${attributeId}/values/${entityId}`, { value });
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const filterSuitableAttributesByType = (attributes: CustomAttributeWithGroupNameDTO[], attributeType: AttributeType) =>
  attributes.filter((attr) => {
    if (CustomAttributeS.isUnsupportedType(attributeType)) {
      return attr.attributeType === 'STRING';
    }
    return attr.attributeType === attributeType;
  });

const isUnsupportedType = (attributeType: AttributeType) => attributeType === 'SINGLE_CHOICE';

const parseMultipleChoiceValue = (value?: string): string[] => {
  if (!value) return [];
  return value.split(';').map((s) => s.trim());
};

// eslint-disable-next-line import/prefer-default-export
export const CustomAttributeS = {
  moveAttribute,
  editAttribute,
  moveGroup,
  fetchGroups,
  fetchGroupAttributes,
  deleteAttributesGroup,
  editGroup,
  fetchAttributesByType,
  fetchAttribute,
  fetchAllAttributes,
  fetchAttributeValue,
  isUnsupportedType,
  patchAttributeValue,
  filterSuitableAttributesByType,
  parseMultipleChoiceValue,
};
