import React, { useContext } from 'react';
import { withI18n } from '@lingui/react';
import EditableDateInput from '../../Atoms/inputs/EditableDateInput';
import { DateFormatter } from '../../Atoms/Formatter/DateFormatter';
import { WindowContext } from '../../Service/Context/WindowContext';
import { ContractContext } from '../../Service/Context/ContractContext';
import { EditableContractProperty } from '../../Atoms/EditableProperties/EditableContractProperty';
import SequentialDocumentNumber from '../Document/SequentialDocumentNumber/SequentialDocumentNumber';

export default withI18n()(({ i18n }) => {
  const { windowWidth } = useContext(WindowContext);
  const { contract, handleChange } = useContext(ContractContext);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: windowWidth > 800 ? 'row' : 'column',
    },
    documentInfoContainer: {
      flex: 1,
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
    typeAndNumber: {
      alignSelf: 'flex-end',
      width: 100,
    },
    addressContainer: {
      flex: 1,
      marginTop: 10,
      marginLeft: 10,
    },
    addressSubject: {
      fontWeight: 'bold',
    },
    documentType: {
      flex: 1,
      maxWidth: '200px',
    },
    rightContainer: {
      alignSelf: 'flex-end',
      width: 107,
      display: 'flex',
      flexDirection: 'column',
    },
    documentNo: {
      flex: 1,
      maxWidth: 100,
    },
    documentDate: {
      flex: 1,
      maxWidth: 100,
    },
    documentValidUntil: {
      flex: 1,
      maxWidth: 100,
      marginLeft: 5,
    },
    documentSubject: {
      minWidth: '150px',
      maxWidth: '150px',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.documentInfoContainer} />
      <div style={styles.documentInfoContainer}>
        <div style={styles.typeAndNumber} />
        <div style={styles.rightContainer}>
          <SequentialDocumentNumber
            entityId={contract.id}
            documentType="CONTRACT"
            lastModified={contract.lastModified}
            number={contract.number}
            handleChange={handleChange}
          />

          <div style={styles.documentDate}>
            <EditableContractProperty
              patchField="date"
              left
              changeHandler={handleChange}
              entity={contract}
              placeholder="contract.date"
              editFormatter={EditableDateInput}
              labelFormatter={DateFormatter}
            />
          </div>
        </div>
      </div>
    </div>
  );
});
