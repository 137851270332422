// eslint-disable-next-line no-redeclare
import { Box, Center, Checkbox, Divider, Group, NumberInput, Select, Stack, Text, TextInput } from '@mantine/core';
import { useContext } from 'react';
import { DocumentContext } from '../../Service/Context/DocumentContext';
import { FormatS } from '../../Service/FormatS';
import { DataS } from '../../Service/DataS';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { TestS } from '../../TestS';
import BookitupSegmentedControl from '../../Atoms/BookitupSegmentedControl';

const DocumentSum = ({ positions }) => {
  const { profile } = useContext(ProfileContext);
  const { savePatch, document } = useContext(DocumentContext);
  const { discount, summable, documentType, taxSummary, taxType, finalNet, subtotal, sum } = document;
  const {
    value: discountValue,
    type: discountType,
    description: discountDescription,
    amount: discountAmount,
  } = discount;
  const isPartialInvoice = documentType === 'PARTIAL_INVOICE';
  const negativeSum = subtotal - discountAmount < 0;

  if (positions.length === 0) {
    return <div />;
  }
  return (
    <Group position="right">
      <Stack sx={{ maxWidth: 375, width: '100%' }}>
        {summable ? (
          <Stack
            spacing={0}
            sx={(theme) => ({
              border: `1px solid ${theme.colors.gray[2]}`,
              borderRadius: 10,
            })}
          >
            {documentType !== 'PARTIAL_INVOICE' && (
              <>
                <div>
                  <Group position="apart" m="sm">
                    <Text> Zwischensumme {taxType === 'net' ? 'netto' : ''}</Text>
                    <Text> {FormatS.formatCurrencyWithUnitSign(subtotal)} </Text>
                  </Group>
                  <Divider />
                </div>
                <Group position="apart" m="sm">
                  <Group spacing={0}>
                    <Text mr="xs">Rabatt</Text>
                    <NumberInput
                      value={discountValue}
                      hideControls
                      decimalSeparator=","
                      precision={2}
                      sx={{ width: 65 }}
                      onBlur={(e) => {
                        const intVal = parseFloat(e.target.value.replace(',', '.'));
                        const finalVal = isNaN(intVal) || intVal < 0 ? 0 : intVal;
                        savePatch({ discountValue: finalVal });
                      }}
                      min={0}
                      data-testid={TestS.Documents.Edit.DISCOUNT_VALUE}
                    />
                    <BookitupSegmentedControl
                      value={discountType}
                      data={DataS.discountUnitOptions}
                      onChange={(val) => val && savePatch({ discountType: val })}
                      testId={TestS.Documents.Edit.DISCOUNT_TYPE}
                    />
                  </Group>
                  <Text> {`-${FormatS.formatCurrencyWithUnitSign(discountAmount)}`}</Text>
                </Group>
                <TextInput
                  ml="sm"
                  mr="sm"
                  mb="sm"
                  label="Anmerkung zum Rabatt"
                  defaultValue={discountDescription}
                  placeholder="Rabattbezeichnung"
                  onBlur={(e) => savePatch({ discountDescription: e.target.value })}
                />
                <Divider />
              </>
            )}
            {taxType === 'net' && !profile.isSmallScaleBusiness && taxSummary && (
              <>
                <Stack p="xs">
                  <Group position="apart">
                    <Text>Gesamt netto</Text>
                    <Text>{FormatS.formatCurrencyWithUnitSign(finalNet)}</Text>
                  </Group>
                  {taxSummary.taxEntries.map((taxEntry) =>
                    taxEntry.taxRatePercent === '0' ? null : (
                      <Group position="apart">
                        <Text key={taxEntry} data-testid={TestS.Documents.Edit.TAX}>
                          {`USt. ${taxEntry.taxRatePercent}%`}
                        </Text>
                        <Text>{FormatS.formatCurrencyWithUnitSign(taxEntry.taxAmount)}</Text>
                      </Group>
                    ),
                  )}
                </Stack>
                <Divider />
              </>
            )}
            <Box
              p="xs"
              sx={{
                backgroundColor: negativeSum ? '#FFEFEF' : '#F1F1F1',
                color: negativeSum ? 'red' : 'inherit',
                borderRadius: 10,
                borderTopLeftRadius: isPartialInvoice ? 10 : 0,
                borderTopRightRadius: isPartialInvoice ? 10 : 0,
              }}
            >
              <Group position="apart">
                <Text weight="bolder">Gesamtbetrag</Text>
                <Text weight="bolder" data-testid={TestS.Documents.Edit.SUM}>
                  {FormatS.formatCurrencyWithUnitSign(sum)}
                </Text>
              </Group>
              {taxType === 'gross' && !profile.isSmallScaleBusiness && taxSummary && (
                <>
                  {taxSummary.taxEntries.map((taxEntry) =>
                    taxEntry.taxRatePercent === 0 ? null : (
                      <Text size="xs" key={taxEntry} data-testid={TestS.Documents.Edit.TAX}>
                        {`enthält USt. ${taxEntry.taxRatePercent} % (${FormatS.formatCurrencyWithUnitSign(
                          taxEntry.taxAmount,
                        )})`}
                      </Text>
                    ),
                  )}
                </>
              )}
            </Box>
          </Stack>
        ) : (
          <Center>Summen werden jetzt nicht auf dem Dokument angezeigt</Center>
        )}
        {documentType === 'OFFER' && (
          <Checkbox
            checked={summable}
            label="Gesamtbetrag auf Dokument einblenden"
            onChange={(event) => savePatch({ summable: event.target.checked })}
          />
        )}
      </Stack>
    </Group>
  );
};

export default DocumentSum;
