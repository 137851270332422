// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Menu, Text } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { useModals } from '@mantine/modals';
import { IconBallpen, IconCopy, IconDotsVertical, IconTrash } from '@tabler/icons';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { MessageTemplateDto } from '../../Types/MessageT';
import { MessageS } from '../../Service/MessageS';
import { openEditTemplateModal } from '../Message/MessageModals';
import { openCopyTemplateModal } from './MessageTemplates/MessageTemplateModals';

interface Props {
  template: MessageTemplateDto;
  existingNames?: string[];
  onDelete?: () => void;
  onUpdate?: (template: MessageTemplateDto) => void;
}

const EmailTemplateActions: FC<Props> = ({ template, onUpdate, onDelete, existingNames }) => {
  const { name, messageType } = template;
  const navigate = useNavigate();
  const isCustom = messageType === 'CUSTOM';

  const { openConfirmModal } = useModals();
  const { i18n } = useLingui();

  const deleteTemplateById = async () => {
    const deleted = await MessageS.deleteTemplate(name);
    if (deleted) {
      if (onDelete) {
        onDelete();
      } else {
        navigate('/settings/messages');
      }
    }
  };

  const openDeleteModal = () =>
    openConfirmModal({
      title: (
        <Text weight="bolder" size="xl">
          Wirklich löschen?
        </Text>
      ),
      centered: true,
      closeOnConfirm: true,
      children: (
        <Text size="sm">
          Möchtest Du das Vorlage{' '}
          <Text span weight="bolder">
            {name}
          </Text>{' '}
          wirklich löschen?
        </Text>
      ),
      labels: { confirm: i18n._('actions.delete'), cancel: i18n._('actions.cancel') },
      confirmProps: { color: 'red' },
      groupProps: { spacing: 'xs', mt: 'xl' },
      onConfirm: deleteTemplateById,
    });

  return (
    <Menu radius="sm" shadow="md" width={130} transition="scale-y" position="bottom-end">
      <Menu.Target>
        <div>
          <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        {isCustom && (
          <Menu.Item
            icon={<IconBallpen size={16} />}
            onClick={() =>
              openEditTemplateModal({
                template,
                onFinish:
                  onUpdate ?? ((t: MessageTemplateDto) => navigate(`/settings/template/message/custom/${t.name}`)),
                existingNames: existingNames ?? null,
              })
            }
          >
            {i18n._('actions.edit')}
          </Menu.Item>
        )}
        <Menu.Item
          icon={<IconCopy size={16} />}
          onClick={() => openCopyTemplateModal({ origin: template, existingNames: existingNames ?? null })}
        >
          Duplizieren
        </Menu.Item>
        {isCustom && (
          <Menu.Item color="red" icon={<IconTrash size={16} />} onClick={openDeleteModal}>
            {i18n._('actions.delete')}
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export default EmailTemplateActions;
