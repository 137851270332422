import moment from 'moment';
import _ from 'lodash';
import { DistanceDto, LocationDto } from '../Types/LocationT';
import { ajaxActions } from './AjaxActions';
import { Contact } from './ContactS';
import { isEmpty } from '../Utils/utils';

const BASE_URL = process.env.REACT_APP_LOCATION_SERVICE_URL;

const fetchByEventId = async (eventId: number): Promise<LocationDto | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/locations?eventId=${eventId}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const calculateDistance = async (contactId: number, locationId: number): Promise<DistanceDto | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/distances/calc?contactId=${contactId}&locationId=${locationId}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const formatDuration = (distance: DistanceDto | null | undefined) => {
  if (!distance) {
    return null;
  }
  const { travelTimeInSeconds } = distance;
  return `${moment.duration({ seconds: travelTimeInSeconds }).humanize()}`;
};

const getDistanceMeters = (distance: DistanceDto | null | undefined) => {
  if (!distance || !distance.distanceInMeters) {
    return null;
  }
  return metersToKm(distance.distanceInMeters);
};

const metersToKm = (meters: number) => Math.round((meters / 1000) * 100) / 100;

const sortContactsByDistance = (contacts: Contact[]): Contact[] =>
  _.sortBy(contacts, (contact: Contact) => {
    const { requestAnswer, distance } = contact;
    if (requestAnswer === 'UNAVAILABLE') {
      return Number.MAX_VALUE; // Return max number if unavailable to place contact at the bottom of the list
    }
    return distance?.distanceInMeters;
  });

const isFilled = (location: LocationDto) => location.name !== '';

const isEmptyLocation = (location: LocationDto) => {
  const { city, zipCode, streetAndNo } = location;
  return isEmpty(city) && isEmpty(zipCode) && isEmpty(streetAndNo);
};


// eslint-disable-next-line import/prefer-default-export
export const LocationS = {
  fetchByEventId,
  calculateDistance,
  formatDuration,
  metersToKm,
  getDistanceMeters,
  sortContactsByDistance,
  isFilled,
  isEmptyLocation,
};
