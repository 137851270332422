// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import moment from 'moment';
import { BookitupNotification } from '../../../../Types/LogT';
import { RoutingS } from '../../../../Service/RoutingS';

const WorkflowCompleted: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { value, eventId, eventDate, personDescription } = notification;

  return (
    <Text>
      Workflow{'  '}
      <Text span weight="bolder">
        {value}
      </Text>
      {'  '}abgeschlossen für das
      <Text
        span
        weight="bolder"
        variant="link"
        color="blue"
        onClick={() => RoutingS.openInNewTab(`/events/${eventId}/details`)}
        sx={{ cursor: 'pointer' }}
      >
        {'  '}Event{'  '}
      </Text>
      am
      <Text span weight="bolder">
        {` ${moment(eventDate).format('LL')} `}
      </Text>
      mit{'  '}
      <Text span weight="bolder">
        {personDescription}
      </Text>
      .
    </Text>
  );
};

export default WorkflowCompleted;
