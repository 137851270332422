import { FC } from 'react';
import { Text } from '@mantine/core';
import { BookitupNotification } from '../../../../Types/LogT';

const WorkflowCompleted: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { value } = notification;

  return (
    <Text>
      Workflow{'  '}
      <Text span weight="bolder">
        {value}
      </Text>
      {'  '} wurde abgeschlossen.
    </Text>
  );
};

export default WorkflowCompleted;
