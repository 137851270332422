import { useContext, useEffect, useState, createRef, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLingui } from '@lingui/react';
import { useCookies } from 'react-cookie';
import { translate } from '../../Service/PlaceholderTranslationService';
import { getClosest } from '../../Utils/utils';
import { FocusedNodeContext } from '../../Service/Context/FocusedNode';
import onBlurEditableContent from '../../Utils/FocusBlurHelper';
import TextSelectionPopover from './TextSelectionPopover';

const PlaceholderInputField = ({
  htmlString: initialHtmlString,
  selectedValue,
  autoFocus,
  inlinePlaceholder,
  className,
  style,
  loading,
  big,
  error,
  name,
  finish,
  disabled,
  settings,
  onKeyDown,
  onInputChange,
  onBlur,
  onFocus,
  disableTextSelectionPopover,
  ...rest
}) => {
  const [ref, setRef] = useState(createRef());
  const { setFocusedNode, setListPosition } = useContext(FocusedNodeContext);

  const { i18n } = useLingui();
  const [locale, setLocale, removeLocale] = useCookies(['locale']);

  const getItems = () =>
    translate.toLocale(
      initialHtmlString,
      true,
      true,
      settings,
      i18n,
      locale,
      () => finish(ref.current.innerHTML),
      rest,
    );

  function focusNode(e) {
    const parentNode = getClosest(e.target, '.form-control');
    setFocusedNode(parentNode);
    setListPosition(rest.index);
    if (typeof onFocus === 'function') {
      onFocus(e);
    }
  }

  const styles = {
    error: {
      borderColor: 'red',
    },
    input: {
      marginBottom: 0,
      height: '100%',
      minHeight: big ? 150 : 34,
      MozAppearance: 'textfield',
      WebkitAppearance: 'textfield',
      display: 'inline-block',
      ...style,
    },
    label: {
      color: '#8ca3b5',
      fontWeight: 800,
    },
    inputContainer: {
      marginBottom: 9,
    },
    inputAndPlaceholderContainer: {
      display: 'flex',
    },
  };

  {
    /* fixes a bug in Firefox, that the placeholder cannot be deleted by backspace */
  }

  function onKeydownInFF(e) {
    /* if (e.key === "Backspace") {
            let selection = document.getSelection();

            // if caret is at the begining of the text node (0), remove previous element
            if (selection && selection.anchorOffset === 0) {
                if (selection.anchorNode.previousSibling != null && selection.anchorNode.previousSibling.parentNode != null) {
                    selection.anchorNode.previousSibling.parentNode.removeChild(selection.anchorNode.previousSibling)
                }
            }
        } */
  }

  let classes = loading ? `loading form-control ${className}` : `form-control ${className}`;
  if (disabled) {
    classes += ' disabled';
  }

  const items = getItems();

  return (
    <>
      {!disableTextSelectionPopover && <TextSelectionPopover finish={finish} ref={ref} />}
      <div
        ref={(el) => el && el != null && setRef(el)}
        contentEditable={!disabled}
        suppressContentEditableWarning
        onFocus={focusNode}
        onInput={onInputChange}
        onBlur={(e) => {
          onBlurEditableContent(e, selectedValue, onBlur, finish);
        }}
        placeholder={i18n._(inlinePlaceholder || '')}
        tabIndex={0}
        name={name}
        style={error ? { ...styles.error, ...styles.input } : styles.input}
        className={classes}
        onKeyDown={(e) => {
          // credit: https://stackoverflow.com/a/25397485/104380
          const isFF = !!navigator.userAgent.match(/firefox/i);
          const editableElm = document.querySelector('div');

          // listen to any key press
          if (isFF) editableElm.addEventListener('keydown', onKeydownInFF);

          if (typeof onKeyDown === 'function') {
            onKeyDown(e);
          } else if (e.keyCode === 13 && e.shiftKey) {
          } else if (e.keyCode === 13) {
            // block enter
            e.preventDefault();
            window.document.execCommand('insertHTML', false, '<br><br>');
          }
        }}
      >
        {items}
      </div>
    </>
  );
};

PlaceholderInputField.propTypes = {
  htmlString: PropTypes.string,
};

export default PlaceholderInputField;
