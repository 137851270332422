import { showNotification } from '@mantine/notifications';
import BookitupDocument from '../Types/BookitupDocument';
import { ajaxActions } from './AjaxActions';
import { ToastS } from './ToastS';
import { Violation, ViolationS } from './ViolationS';

const BASE_URL = process.env.REACT_APP_LEXOFFICE_SERVICE_URL;
const LOX_BASE_URL = process.env.REACT_APP_LEXOFFICE_URL;

export type InvoiceType = 'invoices' | 'vouchers';

export type LexOfficeConfig = {
  apiKey?: string;
  enabled: boolean;
  userEmail?: string;
  userEmailOAuth?: string;
  connected: boolean;
  useBookitupPDF: boolean;
  remainingDays: number;
};

export type LexOfficeInvoice = {
  lastModified: Date;
  type: InvoiceType;
};

const getTypeTranslation = (type: InvoiceType): string => {
  // eslint-disable-next-line default-case
  switch (type) {
    case 'invoices':
      return 'Rechnung';
    case 'vouchers':
      return 'Einnahme';
    default:
      return '';
  }
};

const fetchLexOfficeConfig = async (): Promise<LexOfficeConfig | null> => {
  const resp = await ajaxActions.get(`${BASE_URL}/lexOffice/config`);
  if (resp.ok) {
    return resp.json();
  }
  ToastS.generalError();
  return null;
};

const patchConfig = async (patch: Partial<LexOfficeConfig>): Promise<LexOfficeConfig | null> => {
  const resp = await ajaxActions.patch(`${BASE_URL}/lexOffice/config`, patch);
  if (resp.ok) {
    if (patch.apiKey && patch.apiKey !== '') {
      ToastS.success('lo-setup', '🎉 lexoffice erfolgreich eingerichtet');
    }
    return resp.json();
  }
  if (resp.status === 400) {
    ToastS.interceptError(resp);
  } else {
    ToastS.generalError();
  }
  return null;
};

const fetchLexOfficeInvoice = (documentId: number) =>
  ajaxActions.get(`${BASE_URL}/invoices/${documentId}`).then((resp) => resp);

const deleteLexOfficeInvoice = (documentId: number) =>
  ajaxActions.del(`${BASE_URL}/invoices/${documentId}`).then((resp) => resp);

const startOAuthProcess = () => {
  ajaxActions.get(`${BASE_URL}/lexOffice/config/connect`).then((resp) => {
    if (resp.ok) {
      resp.text().then((connectUrl) => {
        if (connectUrl) {
          window.location.href = connectUrl;
        }
      });
    }
  });
};

const revokeOAuth = async (): Promise<boolean> => {
  const resp = await ajaxActions.get(`${BASE_URL}/lexOffice/config/oauth/revoke`);
  if (resp.ok) {
    ToastS.success('oath-revoked', 'Erfolgreich widerrufen');
    return true;
  }
  ToastS.generalError();
  return false;
};

const completeOAuthProcess = (authCode: string) =>
  ajaxActions.get(`${BASE_URL}/lexOffice/config/oauth?authCode=${authCode}`).then((resp) => resp);

const publishInvoice = async (
  id: number,
  interceptViolation?: (httpResponse: Response) => void,
): Promise<LexOfficeInvoice | null> => {
  const resp = await ajaxActions.post(`${BASE_URL}/invoices/${id}`);
  if (resp.ok) {
    ToastS.success('transmit-completed', 'Erfolgreich in lexoffice übertragen');
    return resp.json();
  }
  if (resp.status === 400 && interceptViolation) {
    interceptViolation(resp);
  } else {
    ToastS.generalError();
  }
  return null;
};

const publishInvoicePrecheck = (
  document: BookitupDocument,
  loxConfig: LexOfficeConfig,
  openErrorModal: (ex: Violation) => void,
): boolean => {
  const { apiKey, useBookitupPDF, connected } = loxConfig;
  const loxConnectedAndEnabled = (useBookitupPDF && connected) || (apiKey && !useBookitupPDF);
  if (!loxConnectedAndEnabled) {
    openErrorModal(ViolationS.Violations.LO_NOT_CONNECTED);
    return false;
  }

  const { partialInvoices, documentType } = document;
  if (
    !(useBookitupPDF && connected) &&
    (documentType === 'PARTIAL_INVOICE' || (partialInvoices && partialInvoices.length > 0))
  ) {
    ToastS.warn(
      'lox-import',
      'lexoffice unterstützt dies leider nicht. Um Abschlags- und Schlussrechnungen zu lexoffice zu übertragen, stelle auf bookitup Rechnungen in den Einstellungen um.',
    );
    return false;
  }

  const { positions } = document;
  const positionWithAbsoluteDiscountPresent =
    positions.find((p) => p.discount.type === 'ABSOLUTE') && apiKey && useBookitupPDF;
  if (positionWithAbsoluteDiscountPresent) {
    ToastS.warn(
      'lox-import',
      'Diese Rechnung kann nicht zu lexoffice übertragen werden, da sie Positionen enthält, die einen Rabatt in EUR enthalten. Dies wird von lexoffice nicht unterstützt.',
      'Rechnung kann nicht übertragen werden',
    );
    return false;
  }
  return true;
};

const importContacts = async (csvFile: File) => {
  ToastS.info(
    'importing-contacts',
    'Abhängig von der Anzahl Ihrer Kontakte kann es einen Moment dauern.',
    'Kontakte importieren',
  );
  const resp = await ajaxActions.uploadFile(`${BASE_URL}/import/contacts`, csvFile);
  if (resp.ok) {
    const importedProductsCount = await resp.text();
    ToastS.success(
      'contacts-imported',
      `${importedProductsCount} Kontakte erfolgreich importiert`,
      'Kontakte importiert',
    );
    return;
  }
  ToastS.generalError();
};

// eslint-disable-next-line import/prefer-default-export
export const LexOfficeS = {
  fetchLexOfficeConfig,
  patchConfig,
  fetchLexOfficeInvoice,
  deleteLexOfficeInvoice,
  completeOAuthProcess,
  startOAuthProcess,
  revokeOAuth,
  publishInvoice,
  publishInvoicePrecheck,
  getTypeTranslation,
  LOX_BASE_URL,
  importContacts,
};
