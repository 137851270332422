/* eslint-disable no-plusplus */
import { isToday, isYesterday } from 'date-fns';
import { i18n } from '@lingui/core';
import moment from 'moment';
import { BookitupNotification, NotificationConfig, OccurrenceType } from '../Types/LogT';
import { PageableResponse } from '../Types/PageableResponse';
import { ajaxActions } from './AjaxActions';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_LOG_SERVICE_URL;

const fetchNotifications = async (
  page: number,
  includeAll: boolean,
): Promise<PageableResponse<BookitupNotification> | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/occurrences?pageNumber=${page}&includeAll=${includeAll}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchLogbookNotifications = async (eventId: string): Promise<BookitupNotification[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/occurrences/logbook/${eventId}`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchUnseenNotificationsCount = async (): Promise<number | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/occurrences/unseen/count`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const markAsSeen = async (ids: string[]): Promise<boolean> => {
  const res = await ajaxActions.patch(`${BASE_URL}/occurrences/seen`, ids);
  return res.ok;
};

const markAllAsSeen = async (): Promise<boolean> => {
  const res = await ajaxActions.patch(`${BASE_URL}/occurrences/all/seen`, null);
  if (res.ok) {
    return true;
  }
  ToastS.generalError();
  return false;
};

const markAsBrowserReported = async (ids: string[]): Promise<boolean> => {
  const res = await ajaxActions.patch(`${BASE_URL}/occurrences/browser-reported`, ids);
  return res.ok;
};

const deleteNotification = async (id: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/occurrences/${id}`);
  if (res.status === 204) {
    ToastS.info('foo', 'Benachrichtigung gelöscht');
    return true;
  }
  ToastS.generalError();
  return false;
};

const deleteAllNotifications = async (): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/occurrences/all`);
  if (res.status === 204) {
    ToastS.info('foo', 'Benachrichtigungen gelöscht');
    return true;
  }
  ToastS.generalError();
  return false;
};

const insertTimeIndents = (notifications: BookitupNotification[]) => {
  const { length } = notifications;
  for (let i = 0; i < length; i++) {
    const { createdOn } = notifications[i];
    if (isToday(new Date(createdOn))) {
      // eslint-disable-next-line no-param-reassign
      notifications[i].timeLabel = 'Heute';
      break;
    }
  }
  for (let i = 0; i < length; i++) {
    const { createdOn } = notifications[i];
    if (isYesterday(new Date(createdOn))) {
      // eslint-disable-next-line no-param-reassign
      notifications[i].timeLabel = 'Frühere';
      break;
    }
  }
};

const fetchNotificationConfig = async (): Promise<NotificationConfig | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/occurrences-config`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const patchNotificationConfig = async (patch: Partial<NotificationConfig>): Promise<NotificationConfig | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/occurrences-config`, patch);
  if (res.ok) {
    ToastS.info('patch-cfg', 'Benachrichtigungs konfiguration aktualisiert');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const isConfigurable = (notification: BookitupNotification): boolean => {
  const { occurrenceType } = notification;
  return occurrenceType === 'OFFER_OVERDUE' || occurrenceType === 'INVOICE_NOT_PAID';
};

const getBrowserNotificationMessage = (notification: BookitupNotification): string => {
  const { occurrenceType, eventDate } = notification;
  // eslint-disable-next-line no-param-reassign
  notification.eventDateFormatted = moment(eventDate).format('LL');
  if (occurrenceType !== 'CONDITION_NOT_FULFILLED') {
    return i18n._(`browser.notification.${occurrenceType}`, notification);
  }
  const { value, personDescription } = notification;
  const type = value as OccurrenceType;
  let message = `Workflow abgebrochen: Kunde ${personDescription} hat`;

  if (type === 'CONTRACT_SIGNED') {
    message += ` den Vertrag für das Event am ${eventDate} nicht unterzeichnet.`;
  }
  if (type === 'OFFER_CONFIRMED') {
    message += ` den Angebot für das Event am ${eventDate} nicht angenommen.`;
  }
  if (type === 'QUESTIONNAIRE_FILLED') {
    message += ` den Fragebogen für das Event am ${eventDate} nicht ausgefüllt.`;
  }
  return message;
};

// eslint-disable-next-line import/prefer-default-export
export const LogS = {
  fetchNotifications,
  fetchLogbookNotifications,
  fetchUnseenNotificationsCount,
  deleteNotification,
  deleteAllNotifications,
  markAsSeen,
  markAllAsSeen,
  markAsBrowserReported,
  insertTimeIndents,
  fetchNotificationConfig,
  patchNotificationConfig,
  isConfigurable,
  getBrowserNotificationMessage,
};
