import { FC, useContext } from 'react';
import { CustomEventState } from '../../Types/EventT';
import { i18n } from '@lingui/core';
import { Group, Text } from '@mantine/core';
import { IconBallpen, IconTrashX } from '@tabler/icons';
import Dot from '../../Atoms/Dot';
import { openCreateEventStatusModal, openDeleteEventStateModal, openEditEventStatusModal } from './EventStatusesModals';
import { useDrag, useDrop } from 'react-dnd-latest';
import DragButton from '../../Atoms/DragDrop/DragButton';
import { EventS } from '../../Service/EventS';
import { ToastS } from '../../Service/ToastS';
import { WindowContext } from '../../Service/Context/WindowContext';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import { LicenceS } from '../../Service/LicenceS';

interface Props {
  state: CustomEventState;
  refresh: () => void;
  states: CustomEventState[];
}

const EventStateItem: FC<Props> = ({ state, refresh, states }) => {
  const { id, idx, label, color, originState, primary, emoji } = state;
  const { xs } = useContext(WindowContext);
  const { executeWithLicenceCheck } = useContext(ViolationContext);

  const [, drag] = useDrag(
    () => ({
      type: 'eventState',
      item: state,
    }),
    [idx],
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'eventState',
      collect(monitor) {
        return { handlerId: monitor.getHandlerId() };
      },
      drop(item: any) {
        if (item.originState === originState && idx !== null) {
          EventS.moveCustomState(originState, item.idx, idx).then((ok) => {
            if (ok) {
              refresh();
            }
          });
        }
        if (item.originState !== originState) {
          ToastS.warn('drop-not-supported', 'Der Status kann nicht zu einem anderen Basisstatus verschoben werden.');
        }
      },
    }),
    [idx],
  );

  const edit = () => {
    const onEdit = () => {
      if (id) {
        openEditEventStatusModal(refresh, state, []);
      } else {
        openCreateEventStatusModal(refresh, [], state);
      }
    };
    executeWithLicenceCheck(LicenceS.Restrictions.CUSTOM_EVENT_STATE, onEdit);
  };

  return (
    <tr>
      <td>
        {idx !== null && (
          <div ref={drop}>
            <DragButton dragRef={drag} />
          </div>
        )}
      </td>
      <td>
        <div>{color && <Dot withoutShadow colour={color} />}</div>
      </td>
      <td>
        <Text>{`${emoji ? `${emoji} ` : ''}${label}`}</Text>
      </td>
      {!xs && (
        <td>
          <Text>{i18n._(`event.state.${originState.toLocaleLowerCase()}`)}</Text>
        </td>
      )}
      <td>
        <Group spacing="xs" position="right">
          <IconBallpen size={20} color="gray" style={{ cursor: 'pointer' }} onClick={edit} />
          {id && !primary && (
            <IconTrashX
              size={20}
              color="gray"
              style={{ cursor: 'pointer' }}
              onClick={() => openDeleteEventStateModal(state, refresh, states)}
            />
          )}
        </Group>
      </td>
    </tr>
  );
};

export default EventStateItem;
