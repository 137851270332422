import { FC } from 'react';
import { useEventData } from '../Service/Context/CustomerContext';
import { useMessageEntities } from '../Service/hooks/useMessageEntities';
import { MessageDTO } from '../Types/MessageT';
import { useParams } from 'react-router-dom';
import {
  replacePlaceholdersWithHtml,
  replaceTemplatePlaceholdersWithHTMLValues,
} from '../Service/Placeholder/PlaceholderResover';
import { i18n } from '@lingui/core';
import { Text } from '@mantine/core';

interface Props {
  email: MessageDTO;
  value: string | null;
  settings?: boolean;
}

const MessagePlaceholderText: FC<Props> = ({ email, value, settings = false }) => {
  const { id: eventId } = useParams();

  if (value === null) {
    return null;
  }
  // We are in an event
  if (eventId && Number(eventId) === email.eventId) {
    return <EventPlaceholderText value={value} />;
  }
  // We are in an event, but we're loading an email (cause a participant in on those two events), which is related to another event
  if (eventId && Number(eventId) !== email.eventId) {
    return <EventPlaceholderText value={value} />;
  }
  // We are in general postbox master details
  if (settings) {
    return <PlaceholderSettingsText value={value} />;
  }
  // General postbox master details
  return <PlaceholderText email={email} value={value} settings={settings} />;
};

const EventPlaceholderText: FC<{ value: string }> = ({ value }) => {
  const { event, customer, location, customAttributeValues } = useEventData();

  const htmlValue = replaceTemplatePlaceholdersWithHTMLValues(i18n, value, {
    event,
    customer,
    eventLocation: location,
    customAttributeValues,
  });

  return <Text dangerouslySetInnerHTML={{ __html: htmlValue }} />;
};



const PlaceholderSettingsText: FC<{ value: string }> = ({ value }) => {
  const htmlValue = replacePlaceholdersWithHtml(i18n, value ?? '');
  return <Text dangerouslySetInnerHTML={{ __html: htmlValue }} />;
};

const PlaceholderText: FC<Props> = ({ email, value, settings }) => {
  const { event, customer, eventLocation, customAttributeValues } = useMessageEntities(email);

  const htmlValue = settings
    ? replacePlaceholdersWithHtml(i18n, value ?? '')
    : replaceTemplatePlaceholdersWithHTMLValues(i18n, value ?? '', {
        event: event ?? undefined,
        customer,
        eventLocation,
        customAttributeValues,
      });

  return <Text dangerouslySetInnerHTML={{ __html: htmlValue }} />;
};

export default MessagePlaceholderText;
