import DeLang from './entries/de-de';
import EnLang from './entries/en-us';

export const AppLanguages = [
  {
    languageId: 'deutsch',
    locale: 'de',
    name: 'Deutsch',
    icon: 'de',
  },
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us',
  },
];

const FormBuilderLocale = {
  en: EnLang,
  de: DeLang,
};

export default FormBuilderLocale;
