import { Popover, Group, Tooltip } from '@mantine/core';
import {
  IconBold,
  IconClearFormatting,
  IconHash,
  IconItalic,
  IconLink,
  IconList,
  IconListNumbers,
  IconMoodWink2,
  IconPaperclip,
  IconUnderline,
} from '@tabler/icons';
import { useCurrentEditor } from '@tiptap/react';
import EmojiPicker, { IEmojiData } from 'emoji-picker-react';
import { FC, useCallback, useContext, useState } from 'react';
import { WindowContext } from '../../../Service/Context/WindowContext';
import ActionIconWithoutFocus from '../../../Atoms/ActionIconWithoutFocus';

interface Props {
  includePlaceholders?: boolean;
  addAttachment: () => unknown;
}

const TemplateMessageToolbar: FC<Props> = ({ includePlaceholders = true, addAttachment }) => {
  const { editor } = useCurrentEditor();
  const [opened, setOpened] = useState(false);
  const { xs, md } = useContext(WindowContext);

  const setLink = useCallback(() => {
    if (editor) {
      const previousUrl = editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);
      // cancelled
      if (url === null) {
        return;
      }
      // empty
      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }
      // update link
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    }
  }, [editor]);

  const onEmojiClick = (event: React.MouseEvent, data: IEmojiData) => {
    if (editor) {
      editor.commands.insertContent(data.emoji);
      setOpened(false);
    }
  };

  const onHashClicked = () => {
    if (editor) {
      editor.commands.insertContent('#');
    }
  };

  if (!editor) {
    return null;
  }

  return (
    <Group spacing={0} p="xs">
      <Tooltip openDelay={300} withArrow transition="slide-up" label="Fett">
        <div>
          <ActionIconWithoutFocus
            variant={editor.isActive('bold') ? 'filled' : 'subtle'}
            onClick={() => editor.chain().focus().toggleBold().run()}
            disabled={!editor.can().chain().focus().toggleBold().run()}
          >
            <IconBold size={20} />
          </ActionIconWithoutFocus>
        </div>
      </Tooltip>
      <Tooltip openDelay={300} withArrow transition="slide-up" label="Kursiv">
        <div>
          <ActionIconWithoutFocus
            variant={editor.isActive('italic') ? 'filled' : 'subtle'}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
          >
            <IconItalic size={20} />
          </ActionIconWithoutFocus>
        </div>
      </Tooltip>
      <Tooltip openDelay={300} withArrow transition="slide-up" label="Unterstrichen">
        <div>
          <ActionIconWithoutFocus
            variant={editor.isActive('underline') ? 'filled' : 'subtle'}
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            disabled={!editor.can().chain().focus().toggleUnderline().run()}
          >
            <IconUnderline size={20} />
          </ActionIconWithoutFocus>
        </div>
      </Tooltip>
      <Tooltip openDelay={300} withArrow transition="slide-up" label="Formatierung löschen">
        <div>
          <ActionIconWithoutFocus
            variant="subtle"
            onClick={() => editor.chain().focus().clearNodes().unsetAllMarks().run()}
            disabled={!editor.can().chain().focus().clearNodes().unsetAllMarks().run()}
          >
            <IconClearFormatting size={20} />
          </ActionIconWithoutFocus>
        </div>
      </Tooltip>
      <Tooltip openDelay={300} withArrow transition="slide-up" label="Liste">
        <div>
          <ActionIconWithoutFocus
            variant={editor.isActive('bulletList') ? 'filled' : 'subtle'}
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            disabled={!editor.can().chain().focus().toggleBulletList().run()}
          >
            <IconList size={20} />
          </ActionIconWithoutFocus>
        </div>
      </Tooltip>

      <Tooltip openDelay={300} withArrow transition="slide-up" label="Nummerierte Liste">
        <div>
          <ActionIconWithoutFocus
            variant={editor.isActive('orderedList') ? 'filled' : 'subtle'}
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            disabled={!editor.can().chain().focus().toggleOrderedList().run()}
          >
            <IconListNumbers size={20} />
          </ActionIconWithoutFocus>
        </div>
      </Tooltip>
      <Tooltip openDelay={300} withArrow transition="slide-up" label="Verlinken">
        <div>
          <ActionIconWithoutFocus variant={editor.isActive('link') ? 'filled' : 'subtle'} onClick={setLink}>
            <IconLink size={20} />
          </ActionIconWithoutFocus>
        </div>
      </Tooltip>
      <Tooltip openDelay={300} withArrow transition="slide-up" label="Anhang hinzufügen">
        <div>
          <ActionIconWithoutFocus onClick={addAttachment}>
            <IconPaperclip size={20} />
          </ActionIconWithoutFocus>
        </div>
      </Tooltip>
      {!md && (
        <Popover opened={opened} onChange={setOpened} position="top" returnFocus={true}>
          <Popover.Target>
            <Tooltip openDelay={300} withArrow transition="slide-up" label="Emoji">
              <div>
                <ActionIconWithoutFocus variant="subtle" onClick={() => setOpened((o) => !o)}>
                  <IconMoodWink2 size={20} />
                </ActionIconWithoutFocus>
              </div>
            </Tooltip>
          </Popover.Target>
          <Popover.Dropdown>
            <EmojiPicker onEmojiClick={onEmojiClick} />
          </Popover.Dropdown>
        </Popover>
      )}
      {!xs && includePlaceholders && (
        <Tooltip openDelay={300} withArrow transition="slide-up" label="Platzhalter einfügen ">
          <div>
            <ActionIconWithoutFocus onClick={onHashClicked}>
              <IconHash size={20} />
            </ActionIconWithoutFocus>
          </div>
        </Tooltip>
      )}
    </Group>
  );
};

export default TemplateMessageToolbar;
