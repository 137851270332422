// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Autocomplete, Button, Center, Loader, Select, Text, Title } from '@mantine/core';
import { Calendar, isSameDate } from '@mantine/dates';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { closeAllModals, openModal } from '@mantine/modals';
import { i18n } from '@lingui/core';
import { IconUser } from '@tabler/icons';
import moment from 'moment';
import { TestS } from '../../TestS';
import ContactAutocomplete from '../../Organismns/Contact/ContactAutocomplete';
import { ToastS } from '../../Service/ToastS';
import { filterContactInfosFromString } from '../../Utils/utils';
import { EventS } from '../../Service/EventS';
import { saveContact } from '../../Service/restapi/contactService';
import PanePaper from '../../Molecules/PanePaper';
import { toUTC } from '../../Utils/dateUtils';
import { ValidationS } from '../../Service/ValidationS';
import { EventKind } from '../../Types/EventT';
import { ConstantS } from '../../Service/ConstantS';
import { MixpanelS } from '../../Service/MixpanelS';

export const openCreateEventModal = () =>
  openModal({
    size: 'sm',
    closeOnEscape: true,
    transition: 'slide-down',
    children: <CreateEventModal />,
  });

const CreateEventModal = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState<any>();
  const [eventDate, setEventDate] = useState<Date>();
  const [errorMessage, setError] = useState<string>();
  const [eventKind, setEventKind] = useState<string>();
  const [loadingKinds, setLoadingKinds] = useState(true);
  const [kinds, setKinds] = useState<EventKind[]>([]);
  const kindNames = kinds.map((_kind) => _kind.name);

  useEffect(() => {
    setLoadingKinds(true);
    EventS.fetchEventKinds()
      .then(setKinds)
      .finally(() => setLoadingKinds(false));
  }, []);

  const save = async () => {
    if (!eventDate) {
      ToastS.error('event-date-missing', 'Gib ein Eventdatum an.');
      return;
    }
    if (!contact) {
      ToastS.error('contact-missing', 'Lege einen Kontakt an oder wähle einen aus.');
      return;
    }
    let tmpContact: any = contact;
    let customerId = tmpContact.id;

    if (contact && !contact.id) {
      tmpContact = filterContactInfosFromString(contact.personDescription);
      tmpContact = {
        ...tmpContact,
        contactType: 'PRIVATE',
      };
      if (!ValidationS.validateAwsContactProps(tmpContact)) {
        setError(i18n._('error.input-field.invalid-aws-char'));
        return;
      }
      if (errorMessage) {
        setError(undefined);
      }
      setLoading(true);
      const resp = await saveContact(tmpContact);
      if (resp.ok) {
        const newContact = await resp.json();
        customerId = newContact.id;
      }
    }
    const newEvent = await EventS.createEvent({ customerId, dates: [{ date: eventDate.toISOString() }], kind: eventKind });
    if (newEvent) {
      MixpanelS.track(ConstantS.TrackingEvents.EventCreated);
      const { id: eventId } = newEvent;
      closeAllModals();
      navigate(`/events/${eventId}/details`);
    }
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Title order={4}>{`Neues Event ${
        eventDate ? `am ${moment(eventDate).format('DD.MM.YYYY')}` : 'erstellen'
      }`}</Title>
      <Center mb="md" mt="sm">
        <PanePaper withShadow withoutBorder>
          <Calendar
            value={eventDate}
            onChange={(val) => val && setEventDate(toUTC(val))}
            renderDay={(date) => {
              const day = date.getDate();
              const selected = eventDate && isSameDate(eventDate, date);
              const today = isSameDate(date, new Date());
              // eslint-disable-next-line no-nested-ternary
              return <Text color={selected ? 'white' : today ? 'blue' : 'grey'}>{day}</Text>;
            }}
          />
        </PanePaper>
      </Center>
      <ContactAutocomplete
        icon={<IconUser size={16} />}
        setContact={setContact}
        testId={TestS.Events.Create.CONTACT_SELECT}
        label={<b>Kunde neu anlegen oder vorhandene auswählen:</b>}
        description="Max Mustermann max@musterfirma.de"
      />
      {errorMessage && (
        <Text size="xs" color="red">
          {errorMessage}
        </Text>
      )}
      <Autocomplete
        mt="sm"
        label="Art des Events"
        data={kindNames}
        defaultValue={eventKind}
        rightSection={loadingKinds && <Loader size="xs" />}
        onChange={(val) => val && setEventKind(val)}
        sx={{ textAlign: 'left' }}
      />
      <Button fullWidth mt="xl" mb="sm" onClick={save} disabled={loading} leftIcon={loading && <Loader size="xs" />}>
        {i18n._('actions.save')}
      </Button>
      <Text color="dimmed" align="center" sx={{ cursor: 'pointer' }} onClick={() => closeAllModals()}>
        {i18n._('actions.cancel')}
      </Text>
    </div>
  );
};

export default CreateEventModal;
