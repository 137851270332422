import { Text } from '@mantine/core';
import { FC } from 'react';
import { BookitupNotification, OccurrenceType } from '../../../../Types/LogT';

const ConditionNotFulfilled: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { value } = notification;
  const type = value as OccurrenceType;

  if (type === 'CONTRACT_SIGNED') {
    return (
      <Text>
        <Text span weight="bolder">
          Vertrag
        </Text>
        {'  '}wurde nicht unterzeichnet.
      </Text>
    );
  }
  if (type === 'OFFER_CONFIRMED') {
    return (
      <Text>
        <Text span weight="bolder">
          Angebot
        </Text>
        {'  '}wurde nicht angenommen.
      </Text>
    );
  }
  if (type === 'QUESTIONNAIRE_FILLED') {
    return (
      <Text>
        <Text span weight="bolder">
          Fragebogen
        </Text>
        {'  '}wurde nicht ausgefüllt.
      </Text>
    );
  }

  return null;
};

export default ConditionNotFulfilled;
