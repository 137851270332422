import React, { useContext, useEffect, useState } from 'react';
import { withI18n } from '@lingui/react';
import { Select, SelectWrapper } from '../../Atoms/InputFields';
import { fetchCustomAttributeGroups, fetchCustomAttributesOfGroup } from '../../Service/restapi/customAttributeService';

export default withI18n()(
  ({ style, i18nPreLabel, i18n, exampleValue, customAttributesOnly, targetFieldId, field, onChange }) => {
    const [fieldOptions, setFieldOptions] = useState(
      customAttributesOnly
        ? [
            {
              label: i18n._(`${i18nPreLabel}undefined`),
              value: null,
            },
          ]
        : [
            { label: i18n._(`${i18nPreLabel}undefined`), value: 'undefined' },
            { label: i18n._(`${i18nPreLabel}date`), value: 'date' },
            { label: i18n._(`${i18nPreLabel}time.from`), value: 'time.from' },
            { label: i18n._(`${i18nPreLabel}time.to`), value: 'time.to' },
            { label: i18n._(`${i18nPreLabel}customer.emailAddress`), value: 'customer.emailAddress' },
            { label: i18n._(`${i18nPreLabel}customer.companyName`), value: 'customer.companyName' },
            { label: i18n._(`${i18nPreLabel}customer.name`), value: 'customer.name' },
            { label: i18n._(`${i18nPreLabel}customer.salutation`), value: 'customer.salutation' },
            { label: i18n._(`${i18nPreLabel}customer.firstname`, { 0: 1 }), value: 'customer.firstname' },
            { label: i18n._(`${i18nPreLabel}customer.lastname`), value: 'customer.lastname' },
            { label: i18n._(`${i18nPreLabel}customer.address`), value: 'customer.address' },
            { label: i18n._(`${i18nPreLabel}customer.addressStreetAndNo`), value: 'customer.addressStreetAndNo' },
            { label: i18n._(`${i18nPreLabel}customer.addressStreet`), value: 'customer.addressStreet' },
            { label: i18n._(`${i18nPreLabel}customer.addressNo`), value: 'customer.addressNo' },
            { label: i18n._(`${i18nPreLabel}customer.postalCode`), value: 'customer.postalCode' },
            { label: i18n._(`${i18nPreLabel}customer.city`), value: 'customer.city' },
            { label: i18n._(`${i18nPreLabel}leadOrigin`), value: 'leadOrigin' },
            { label: i18n._(`${i18nPreLabel}kind`), value: 'kind' },
            { label: i18n._(`${i18nPreLabel}phone`), value: 'phone' },
            { label: i18n._(`${i18nPreLabel}requestSubject`), value: 'requestSubject' },
            { label: i18n._(`${i18nPreLabel}requestText`), value: 'requestText' },
            { label: i18n._(`${i18nPreLabel}location.name`), value: 'location.name' },
            { label: i18n._(`${i18nPreLabel}location.address`), value: 'location.address' },
            { label: i18n._(`${i18nPreLabel}location.phone`), value: 'location.phone' },
          ],
    );

    useEffect(() => {
      console.log('try fetching custom attributes of entityType event');
      fetchCustomAttributeGroups('event')
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((groups) => {
              groups.forEach((group) => {
                fetchCustomAttributesOfGroup(group.id)
                  .then((resp) => {
                    if (resp.ok) {
                      resp
                        .json()
                        .then((attributes) => {
                          group.attributes = attributes;
                        })
                        .catch(() => {})
                        .then(() => {
                          const tmp = [];
                          tmp.push(...getLabelAndValue(group));
                          setFieldOptions((prevState) => [...prevState, ...tmp]);
                          console.log('successfully fetched custom attributes of entityType event');
                        });
                    } else {
                      console.error(`could not load attributes of group ${group.id}`);
                    }
                  })
                  .catch((error) => {
                    console.error(`could not load attributes of group ${group.id}`);
                    console.error({ error });
                  });
              });
            });
          } else {
            console.error('could not load groups');
          }
        })
        .catch((error) => {
          console.error('could not load groups');
          console.error({ error });
        });
    }, []);

    function getLabelAndValue(group) {
      const labelAndValues = [];
      const { attributes } = group;
      const groupName = group.name;
      if (attributes) {
        attributes.forEach((attr) => {
          labelAndValues.push({ label: `${groupName} -> ${attr.name}`, value: attr.id, attribute: attr });
        });
      }
      return labelAndValues;
    }

    const styles = {
      container: {
        display: 'flex',
        marginBottom: 10,
        ...style,
      },
      exampleValue: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
      },
      fieldValue: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        color: '#afaeae',
      },
      fieldAssignment: {
        flex: 1,
        maxWidth: 250,
        marginRight: 5,
        // alignSelf: "center"
      },
    };

    function getAttributeNameById(attributeId) {
      if (attributeId) {
        const optional = fieldOptions.filter((option) => option.value === attributeId);
        if (optional && optional[0]) {
          return optional[0].label;
        }
      }

      return null;
    }

    let label;
    let value = null;
    if (targetFieldId) {
      label = getAttributeNameById(targetFieldId);
      value = targetFieldId;
    } else {
      label = i18n._(`${i18nPreLabel}undefined`);
    }

    const val = { label, value };

    if (field === 'pathId' || field === 'hostname') {
      return null;
    }

    return (
      <div style={styles.container}>
        <div style={{ flex: 1, marginLeft: 5 }}>
          {exampleValue && <span style={styles.exampleValue}>{exampleValue.value}</span>}
          <span style={styles.fieldValue}>{field}</span>
        </div>
        <SelectWrapper
          style={styles.fieldAssignment}
          options={fieldOptions}
          value={val}
          onChangeHandler={onChange}
          name="fields"
          noSelectionPlaceholder="document.template.select"
        />
      </div>
    );
  },
);
