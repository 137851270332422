// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Loader, Button, Text, Group } from '@mantine/core';
import { i18n } from '@lingui/core';
import { closeAllModals } from '@mantine/modals';
import { FC, useState } from 'react';
import ResourceS from '../../../Service/ResourceS';
import { StockIntervalDto } from '../../../Types/ResourceT';

interface Props {
  stockInterval: StockIntervalDto;
  deleteInterval: () => void;
}

const DeleteStockIntervalModal: FC<Props> = ({ stockInterval, deleteInterval }) => {
  const { id: intervalId } = stockInterval;
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    ResourceS.deleteStockInterval(intervalId)
      .then((success) => {
        if (success) {
          deleteInterval();
          closeAllModals();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Text>Möchten Sie das Lagerintervall wirklich entfernen?</Text>
      <Group position="right" mt="xl">
        <Button disabled={loading} color="gray" variant="subtle" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          color="red"
          variant="light"
          onClick={onSubmit}
          disabled={loading}
          leftIcon={loading && <Loader size="xs" />}
        >
          {i18n._('actions.delete')}
        </Button>
      </Group>
    </>
  );
};

export default DeleteStockIntervalModal;
