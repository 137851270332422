import { Menu } from '@mantine/core';
import { IconDotsVertical, IconDownload, IconTrash } from '@tabler/icons';
import { FC, useState } from 'react';
import { downloadFile } from '../../Service/FileActions';
import { deleteFile } from '../../Service/restapi/fileService';
import { ConfirmDialog } from '../Dialogs';

interface Props {
  entityId: number;
  entityType?: string;
  fileName: string;
  reload: () => void;
}

const EventFileActions: FC<Props> = ({ entityId, entityType = 'events', fileName, reload }) => {
  const [modalDelete, setModalDelete] = useState<boolean>(false);

  const deleteHandler = () => {
    deleteFile(entityType, entityId, fileName).then((resp) => {
      if (resp.ok) {
        reload();
        setModalDelete(false);
      }
    });
  };

  return (
    <div>
      <Menu withArrow shadow="md" width={200} transition="scale-y" position="bottom-end">
        <Menu.Target>
          <div>
            <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<IconDownload size={16} />} onClick={() => downloadFile(entityType, entityId, fileName)}>
            Herunterladen
          </Menu.Item>
          {entityType !== 'messages' && (
            <Menu.Item icon={<IconTrash size={16} />} onClick={() => setModalDelete(!modalDelete)}>
              Löschen
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      <ConfirmDialog
        text="common.delete.document.confirm"
        subject="common.delete.document.confirm.head"
        open={modalDelete}
        toggle={() => setModalDelete(!modalDelete)}
        handler={deleteHandler}
        // eslint-disable-next-line react/no-children-prop
        children={[]}
        isLoading={false}
        confirmText=""
        disableConfirm={false}
      />
    </div>
  );
};

export default EventFileActions;
