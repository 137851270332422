// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { i18n } from '@lingui/core';
import { FC } from 'react';
import { GeneralTaskConfigDTO } from '../../../../../Types/LogT';

const DeleteTask: FC<{ config: unknown }> = ({ config }) => {
  const { taskReference } = config as GeneralTaskConfigDTO;

  if (!taskReference) {
    return (
      <Text italic color="red">
        {i18n._('step.config.general-error')}
      </Text>
    );
  }
  const { label } = taskReference ?? {};

  return (
    <Text>
      Aufgabe{' '}
      <Text span weight="bolder">
        {label}
      </Text>{' '}
      entfernen.
    </Text>
  );
};

export default DeleteTask;