import { FC, useState } from 'react';
import Moment from 'react-moment';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { BookitupQuestionnaire } from '../../../Types/BookitupDocument';
import DocumentPortalStatus from '../DocumentPortalStatus';
import QuestionnaireItemActions from './QuestionnaireItemActions';

interface QuestionnaireItemProps {
  questionnaire: BookitupQuestionnaire;
  showDatum: boolean;
  showPortal: boolean;
  reload: () => void;
  narrowPortalStatus: boolean;
}

const QuestionnaireItem: FC<QuestionnaireItemProps> = ({
  questionnaire: initialQuestionnaire,
  reload,
  showDatum,
  showPortal,
  narrowPortalStatus,
}) => {
  const navigate: NavigateFunction = useNavigate();
  const [questionnaire, setQuestionnaire] = useState<BookitupQuestionnaire>(initialQuestionnaire);
  const { id: eventIdParam } = useParams();
  const { id, label, subject, date } = questionnaire;
  const creationDate = new Date(date);
  const eventId = eventIdParam ?? questionnaire.eventId;

  return (
    <tr key={id}>
      <td onClick={() => navigate(`/events/${eventId}/questionnaires/${id}`)} style={{ cursor: 'pointer' }}>
        {label || subject}
      </td>
      {showDatum && (
        <td onClick={() => navigate(`/events/${eventId}/questionnaires/${id}`)} style={{ cursor: 'pointer' }}>
          <Moment date={creationDate} format="DD.MM" />
        </td>
      )}
      {showPortal && (
        <td>
          <DocumentPortalStatus
            entityType="questionnaires"
            entityId={id.toString()}
            shortVersion={narrowPortalStatus}
            setDraft={() => null}
          />
        </td>
      )}
      <td style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', right: 0, bottom: 5 }}>
          <QuestionnaireItemActions
            entityId={id}
            initialLabel={label || ''}
            handleChange={setQuestionnaire}
            reload={reload}
          />
        </div>
      </td>
    </tr>
  );
};

export default QuestionnaireItem;
