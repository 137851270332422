import React, { useContext } from 'react';
import { SimpleEditableText } from '../../Atoms/inputs/SimpleEditableText';
import { StringFormatter } from '../../Atoms/Formatter/StringFormatter';
import { EditableBankAccountProperty } from '../../Atoms/EditableProperties/EditableBankAccountProperty';
import { BankAccountContext } from '../../Service/Context/BankAccountContext';
import PanePaper from '../PanePaper';

export const BankAccountSettings = () => {
  const { bankAccount } = useContext(BankAccountContext);

  const styles = {
    websiteContainer: {
      maxWidth: 300,
      minWidth: 250,
      margin: 5,
    },
    bankAccountContainer: {
      maxWidth: 300,
      margin: 5,
    },
    bankAccountField: {
      flex: 1,
      maxWidth: 300,
      margin: 5,
    },
  };

  return (
    <PanePaper
      title="common.settings.bankaccount">



        <div>
          <div style={styles.bankAccountField}>
            <EditableBankAccountProperty
              patchField="nameOfBank"
              bankAccount={bankAccount}
              placeholder="applicationuser.bankaccount.name"
              editFormatter={SimpleEditableText}
              labelFormatter={StringFormatter}
            />
            <EditableBankAccountProperty
              patchField="iban"
              bankAccount={bankAccount}
              placeholder="applicationuser.bankaccount.iban"
              editFormatter={SimpleEditableText}
              labelFormatter={StringFormatter}
            />
            <EditableBankAccountProperty
              patchField="bic"
              bankAccount={bankAccount}
              placeholder="applicationuser.bankaccount.bic"
              editFormatter={SimpleEditableText}
              labelFormatter={StringFormatter}
            />
          </div>
        </div>
      
    </PanePaper>
  );
};
