import { CSSProperties, FC, useContext } from 'react';
import { ActionIconVariant, DefaultMantineColor, MantineNumberSize } from '@mantine/core';
import { useModalState } from '../../Molecules/Dialogs';
import { hasSuitableLicence } from '../../Service/helper/LicenceService';
import { ApplicationUserContext } from '../../Service/Context/ApplicationUserContext';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import { AuthContext } from '../../Service/Context/AuthContext';
import { UpgradeModal } from './UpgradeModal';

type Props = {
  licence: string;
  alternativeCondition?: boolean;
  purchasedOnly?: boolean;
  icon: string;
  id?: string;
  isLoading?: boolean;
  onClick?: () => void;
  upgradeTitle?: string;
  upgradeText?: string;
  className?: string;
  style?: CSSProperties;
  size?: MantineNumberSize;
  radius?: MantineNumberSize;
  variant?: ActionIconVariant;
  color?: DefaultMantineColor;
};

const CheckLicenceActionIcon: FC<Props> = ({
  licence,
  alternativeCondition,
  purchasedOnly,
  icon,
  id,
  onClick,
  upgradeText,
  upgradeTitle,
  isLoading,
  className,
  style,
  size,
  radius,
  variant,
  color,
}) => {
  const { jwtClaims } = useContext(AuthContext);
  const { licence: userLicence } = jwtClaims;
  const { show, toggle } = useModalState(false);

  return (
    <>
      <BookitupAddIcon
        onClick={() => {
          if (hasSuitableLicence(licence, userLicence, purchasedOnly) || alternativeCondition) {
            if (onClick) {
              onClick();
            }
          } else {
            toggle();
          }
        }}
      />
      <UpgradeModal show={show} toggle={toggle} title={upgradeTitle} text={upgradeText} licence={licence} />
    </>
  );
};

export default CheckLicenceActionIcon;
