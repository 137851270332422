import { Box, Flex, Space } from '@mantine/core';
import ResourceImagePane from './ResourceImagePane';
import ResourceInfoPane from './ResourceInfoPane';
import ResourceContentTable from './ResourceContentTable';

const ResourceDetails = () => (
  <Box pb={50}>
    <Flex wrap="wrap" gap="xl">
      <ResourceImagePane />
      <ResourceInfoPane />
    </Flex>
    <Space h={20} />
    <ResourceContentTable />
  </Box>
);

export default ResourceDetails;
