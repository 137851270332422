import React, { useContext, useEffect, useState } from 'react';
import { useLingui, withI18n } from '@lingui/react';
import { preChange } from '../../Utils/utils';
import { EditableDivInput } from '../../Atoms/inputs/EditableDivInput';
import { HtmlPlaceholderFormatter } from '../../Atoms/Formatter/HtmlPlaceholderFormatter';
import { EditableContractTextBlockProperty } from '../../Atoms/EditableProperties/EditableContractTextBlockProperty';
import { fetchTextBlockById, patchTextBlockById } from '../../Service/restapi/textBlockService';
import { LoadingAnimation } from '../../Service/ResourceAdapter';
import { TransButtonDefaultWithIcon } from '../../Atoms/Buttons';
import { ConfirmDialog } from '../../Molecules/Dialogs';
import TextBlockSettings from './TextBlockSettings';
import TextBlockImage from './TextBlockImage';

const TextBlock = ({
    textBlockId,
    deleteTextBlock,
    _document,
    contract,
    event,
    eventLocation,
    customer,
    customAttributeValues,
    settings,
  }) => {
    const [textBlock, setTextBlock] = useState(undefined);
    const [deleteCol, setDeleteCol] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const { i18n } = useLingui();

    useEffect(() => {
      fetchTextBlock();
    }, [textBlockId]);

    function fetchTextBlock() {
      fetchTextBlockById(textBlockId)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((newTextBlock) => {
              setTextBlock(newTextBlock);
            });
          } else {
            console.error(`could not load textBlock${textBlockId}`);
          }
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not load textBlock${textBlockId}`);
        });
    }

    function patchBlock(patch) {
      patchTextBlockById(textBlockId, patch).then((resp) => {
        if (resp.ok) {
          resp.json().then((newTextBlock) => {
            setTextBlock(newTextBlock);
          });
        } else {
        }
      });
    }

    function deleteBlock() {
      setLoading(true);
      patchTextBlockById(textBlockId, { [deleteCol]: null })
        .then((resp) => {
          if (resp.ok) {
            if (resp.body) {
              resp
                .json()
                .then((newTextBlock) => {
                  setTextBlock(newTextBlock);
                })
                .catch(() => {
                  deleteTextBlock(textBlockId);
                });
            }
          } else {
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (!textBlock) {
      return <LoadingAnimation />;
    }

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <div style={{ flex: 1, margin: 5 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
            <div style={{ display: 'flex' }}>
              {textBlock.styleFirstColumn && (
                <TextBlockSettings blockStyleId={textBlock.styleFirstColumn.id} reload={fetchTextBlock} />
              )}
              <TextBlockImage patchBlock={patchBlock} textBlock={textBlock} column="first" />
            </div>
            <div style={{ display: 'flex' }}>
              {textBlock.contentSecondColumn == null && (
                <div style={{ marginRight: 5 }}>
                  <TransButtonDefaultWithIcon
                    icon="COLUMNS"
                    onClickHandler={() => {
                      patchBlock({ contentSecondColumn: '' });
                    }}
                  />
                </div>
              )}
              <TransButtonDefaultWithIcon
                icon="TRASH"
                onClickHandler={() => {
                  setDeleteCol('contentFirstColumn');
                }}
              />
            </div>
          </div>

          <EditableContractTextBlockProperty
            patchField="headerFirstColumn"
            style={{
              textAlign: textBlock.styleFirstColumn ? textBlock.styleFirstColumn.horizontalAlignment : undefined,
            }}
            settings={settings}
            inlinePlaceholder="Kopfzeile"
            preChange={(p) => preChange(p, i18n)}
            changeHandler={(patch) => setTextBlock(patch)}
            entity={textBlock}
            editFormatter={EditableDivInput}
            labelFormatter={HtmlPlaceholderFormatter}
            event={event}
            _document={_document}
            contract={contract}
            eventLocation={eventLocation}
            customer={customer}
            customAttributeValues={customAttributeValues}
          />
          <div style={{ marginTop: 5 }}>
            <EditableContractTextBlockProperty
              patchField="contentFirstColumn"
              style={{
                textAlign: textBlock.styleFirstColumn ? textBlock.styleFirstColumn.horizontalAlignment : undefined,
              }}
              settings={settings}
              inlinePlaceholder="Inhalt"
              preChange={(p) => preChange(p, i18n)}
              changeHandler={(patch) => setTextBlock(patch)}
              entity={textBlock}
              editFormatter={EditableDivInput}
              labelFormatter={HtmlPlaceholderFormatter}
              event={event}
              _document={_document}
              contract={contract}
              eventLocation={eventLocation}
              customer={customer}
              customAttributeValues={customAttributeValues}
            />
          </div>
        </div>
        {textBlock.contentSecondColumn != null && (
          <div style={{ flex: 1, margin: 5 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
              <div style={{ display: 'flex' }}>
                {textBlock.styleSecondColumn && (
                  <TextBlockSettings blockStyleId={textBlock.styleSecondColumn.id} reload={fetchTextBlock} />
                )}
                <TextBlockImage patchBlock={patchBlock} textBlock={textBlock} column="second" />
              </div>
              <TransButtonDefaultWithIcon
                icon="TRASH"
                onClickHandler={() => {
                  setDeleteCol('contentSecondColumn');
                }}
              />
            </div>
            {textBlock.styleSecondColumn && (
              <EditableContractTextBlockProperty
                patchField="headerSecondColumn"
                style={{ textAlign: textBlock.styleSecondColumn.horizontalAlignment }}
                settings={settings}
                inlinePlaceholder="Kopfzeile"
                preChange={(p) => preChange(p, i18n)}
                changeHandler={(patch) => setTextBlock(patch)}
                entity={textBlock}
                editFormatter={EditableDivInput}
                labelFormatter={HtmlPlaceholderFormatter}
                event={event}
                _document={_document}
                contract={contract}
                eventLocation={eventLocation}
                customer={customer}
                customAttributeValues={customAttributeValues}
              />
            )}
            <div style={{ marginTop: 5 }}>
              {textBlock.styleSecondColumn && (
                <EditableContractTextBlockProperty
                  patchField="contentSecondColumn"
                  style={{ textAlign: textBlock.styleSecondColumn.horizontalAlignment }}
                  settings={settings}
                  inlinePlaceholder="Inhalt"
                  preChange={(p) => preChange(p, i18n)}
                  changeHandler={(patch) => setTextBlock(patch)}
                  entity={textBlock}
                  editFormatter={EditableDivInput}
                  labelFormatter={HtmlPlaceholderFormatter}
                  event={event}
                  _document={_document}
                  contract={contract}
                  eventLocation={eventLocation}
                  customer={customer}
                  customAttributeValues={customAttributeValues}
                />
              )}
            </div>
          </div>
        )}
        <ConfirmDialog
          isLoading={loading}
          text="textBlocks.delete.confirm"
          subject="textBlocks.delete.confirm.head"
          open={deleteCol}
          toggle={() => setDeleteCol(undefined)}
          handler={deleteBlock}
        />
      </div>
    );
  };

export default TextBlock;
