import { useEffect, useState } from 'react';
import { LocationS } from '../LocationS';
import { LocationDto } from '../../Types/LocationT';
import { Contact } from '../ContactS';

// eslint-disable-next-line import/prefer-default-export
export const useServiceProdiderDistances = (contacts: Contact[], location?: LocationDto) => {
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDistances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  const fetchDistances = async () => {
    const { length } = contacts;
    setLoading(true);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      // eslint-disable-next-line no-await-in-loop
      await fetchDistance(contacts[i]);
    }
    setLoading(false);
  };

  const fetchDistance = async (contact: Contact) => {
    if (location) {
      const { id: locationId } = location;
      const { id: contactId } = contact;
      const distance = await LocationS.calculateDistance(contactId, locationId);
      // eslint-disable-next-line no-param-reassign
      contact.distance = distance;
      setLastUpdated(new Date());
    }
  };
  return { loading, lastUpdated };
};
