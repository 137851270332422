import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_AUTH_SERVICE_URL;

export const fetchContactGroups = (recommendable) =>
  ajaxActions
    .get(`${BASE_URL}/contactGroups${recommendable ? `?recommendable=${recommendable}` : ''}`)
    .then((resp) => resp);

export const fetchContactGroup = (groupId) =>
  ajaxActions.get(`${BASE_URL}/contactGroups/${groupId}`).then((resp) => resp);

export const deleteContactGroup = (groupId) =>
  ajaxActions.del(`${BASE_URL}/contactGroups/${groupId}`).then((resp) => resp);

export const fetchRecommendableContacts = () =>
  ajaxActions.get(`${BASE_URL}/contactGroups/recommendable/contacts`).then((resp) => resp);

export const fetchContactsOfGroup = (groupId) =>
  ajaxActions.get(`${BASE_URL}/contactGroups/${groupId}/contacts`).then((resp) => resp);

export const putContactInGroup = (groupId, contactId) =>
  ajaxActions.put(`${BASE_URL}/contactGroups/${groupId}/contacts/${contactId}`).then((resp) => resp);

export const patchContactGroup = (contactGroupId, patch) =>
  ajaxActions.patch(`${BASE_URL}/contactGroups/${contactGroupId}`, patch).then((resp) => resp);
