import { useLingui } from '@lingui/react';
import { Menu } from '@mantine/core';
import { IconBallpen, IconCheck, IconDotsVertical, IconDownload, IconTrash } from '@tabler/icons';
import { FC, useState } from 'react';
import { downloadDocumentContractQuestionnaire } from '../../../Service/DocumentService';
import { DocumentS } from '../../../Templates/Documents/DocumentS';
import { BookitupContract } from '../../../Types/BookitupDocument';
import { ConfirmDialog, useConfirmModalState } from '../../Dialogs';
import ChangeLabel from '../../Dialogs/ChangeLabel';

const ContractItemActions: FC<{
  contract: BookitupContract;
  initialLabel: string;
  handleChange: (val: any) => void;
  reload: () => void;
  patchContract: (patch: Partial<BookitupContract>) => void;
}> = ({ contract, initialLabel, handleChange, reload, patchContract }) => {
  const { i18n } = useLingui();
  const { id, draft } = contract;
  const [modalDelete, setModalDelete] = useState(false);
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);

  const deleteHandler = () => DocumentS.deleteContract(id).then((ok) => ok && reload());

  return (
    <div>
      <Menu withArrow shadow="md" width={200} transition="scale-y" position="bottom-end">
        <Menu.Target>
          <div>
            <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          {draft && (
            <Menu.Item icon={<IconCheck size={16} />} onClick={() => patchContract({ draft: false })}>
              Fertigstellen
            </Menu.Item>
          )}
          <Menu.Item
            icon={<IconDownload size={16} />}
            onClick={() => downloadDocumentContractQuestionnaire('contracts', id, i18n)}
          >
            Herunterladen
          </Menu.Item>
          <Menu.Item icon={<IconBallpen size={16} />} onClick={toggleConfirm}>
            Umbenennen
          </Menu.Item>
          <Menu.Item icon={<IconTrash size={16} />} onClick={() => setModalDelete(!modalDelete)}>
            Löschen
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      {showConfirm && (
        <ChangeLabel
          entityType="contracts"
          toggle={toggleConfirm}
          initialLabel={initialLabel}
          entityId={id}
          handleChange={handleChange}
        />
      )}
      <ConfirmDialog
        text="common.delete.contract.confirm"
        subject="common.delete.contract.confirm.head"
        open={modalDelete}
        toggle={() => setModalDelete(!modalDelete)}
        handler={deleteHandler}
        // eslint-disable-next-line react/no-children-prop
        children={[]}
        isLoading={false}
        confirmText=""
        disableConfirm={false}
      />
    </div>
  );
};

export default ContractItemActions;
