import { FC } from 'react';
import { getBookingStateColour } from '../Service/restapi/eventService';
import BookingState from '../Types/Enums/BookingState';

const AvailabilityIcon: FC<{ bookingState: BookingState, center?: boolean }> = ({ bookingState, center }) => (
  <div
    style={{
      width: 10,
      height: 10,
      borderRadius: '50%',
      backgroundColor: getBookingStateColour(bookingState),
      margin: center ? 'auto' : 'initial',
    }}
  />
);

export default AvailabilityIcon;
