// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { BookitupNotification } from '../../../../Types/LogT';

const ScheduledMail: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { occurrenceType, mailSubject, recipient } = notification;
  const success = occurrenceType === 'SCHEDULED_MAIL_SUCCESSFUL';

  return (
    <Text>
      Geplante E-Mail{' '}
      <Text span weight="bolder">
        {mailSubject}
      </Text>
      {success ? ' wurde erfolgreich' : ' kann nicht'}
      {' an'}
      <Text span weight="bolder">
        {` ${recipient} `}
      </Text>
      {!success && 'gesendet'} werden.
    </Text>
  );
};

export default ScheduledMail;
