// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Skeleton, Text } from '@mantine/core';
import { FC } from 'react';
import { IconCar } from '@tabler/icons';
import { DistanceDto } from '../../../Types/LocationT';
import { LocationS } from '../../../Service/LocationS';
import { formatDuration } from '../../../Utils/utils';

interface Props {
  distance?: DistanceDto | null;
  fullWidth?: boolean;
}

const Distance: FC<Props> = ({ distance, fullWidth }) => {
  if (distance === undefined) {
    return <Skeleton width={130} height={20} />;
  }
  if (!distance) {
    return null;
  }
  const { distanceInMeters } = distance;

  return (
    <Text color="blue" size="xs" mb={7} sx={{ maxWidth: fullWidth ? 'none' : 140 }} className="overflow-ellipsis">
      <IconCar size={16} style={{ marginBottom: 3 }} />
      <Text span weight="bolder">
        {'  '}
        {formatDuration(distance)}
      </Text>
      {' für '}
      {distanceInMeters && (
        <Text span weight="bolder">
          {`${LocationS.metersToKm(distanceInMeters)} km`}
        </Text>
      )}
    </Text>
  );
};

export default Distance;
