import { useContext, useEffect, useState } from 'react';
import { MessageDTO } from '../../Types/MessageT';
import { EventS } from '../EventS';
import BookingEvent from '../../Types/Event';
import { fetchContactById, getCustomerId } from '../restapi/contactService';
import { fetchBriefestEventParticipationByContactId } from '../restapi/eventService';
import { LocationS } from '../LocationS';
import { LocationDto } from '../../Types/LocationT';
import { ContactContext } from '../Context/ContactContext';
import { fetchFlattenedCustomAttributeValues } from '../restapi/customAttributeService';
import { fetchShares } from '../restapi/shareService';
import { ShareS } from '../ShareS';

export const useMessageEntities = (message: MessageDTO) => {
  const [event, setEvent] = useState<BookingEvent | null>(null);
  const [customer, setCustomer] = useState();
  const [location, setLocation] = useState<LocationDto>();
  const [customAttributeValues, setCustomAttributeValues] = useState();
  const [portalLink, setPortalLink] = useState<string>();
  const { eventId } = message;

  const { contact } = useContext<any>(ContactContext);
  const { id: contactId } = contact;

  useEffect(() => {
    if (eventId) {
      EventS.fetchById(eventId).then(setEvent);
    } else {
      const customerId = getCustomerId(message);
      if (customerId) {
        fetchEventParticipation(customerId);
      }
    }
  }, [eventId]);

  const fetchEventParticipation = (customerId: number) => {
    // ask event service for participating in next or last event
    fetchBriefestEventParticipationByContactId(customerId.toString(), true).then((resp) => {
      if (resp.ok) {
        resp.json().then((participations) => {
          if (participations.length > 0) {
            console.log(`got ${participations.length} participations for contact ${customerId}`);
            const { eventId } = participations[0];
            EventS.fetchById(eventId).then((resJSON) => {
              if (resJSON) {
                setEvent(resJSON);
              }
            });
          }
        });
      }
    });
  };

  useEffect(() => {
    if (eventId) {
      LocationS.fetchByEventId(eventId).then((loc) => {
        if (loc) {
          const { id: locationId } = loc;
          if (LocationS.isEmptyLocation(loc)) {
            setLocation(loc);
          } else {
            LocationS.calculateDistance(contactId, locationId).then((_distance) => {
              setLocation({ ...loc, distance: _distance ? Math.round(_distance.distanceInMeters / 1000) : null });
            });
          }
        }
      });
    }
  }, [eventId]);

  useEffect(() => {
    if (event) {
      fetchContactById(event.customerId.toString()).then((mainCustomer) => {
        setCustomer(mainCustomer as any);
      });
      fetchShares('events', event.id).then((resp) => {
        if (resp.ok) {
          resp.json().then((shares) => {
            if (shares.length > 0) {
              setPortalLink(ShareS.getPortalLink(shares[0]));
            }
          });
        }
      });
    }
  }, [event]);

  useEffect(() => {
    if (eventId) {
      fetchFlattenedCustomAttributeValues('event', eventId).then((resp) => {
        if (resp.ok) {
          resp.json().then((flattenedValues) => {
            setCustomAttributeValues(flattenedValues);
          });
        }
      });
    }
  }, [eventId]);

  return { event: event ? {...event, portalLink } : undefined, customer, eventLocation: location, customAttributeValues };
};
