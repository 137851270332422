import { forwardRef } from 'react';
import { Text, SelectItemProps } from '@mantine/core';

interface ContactItemProps extends SelectItemProps {
  personDescription?: string;
  email?: string;
}
const AutoCompleteContactItem = forwardRef<HTMLDivElement, ContactItemProps>(
  ({ personDescription, email, ...rest }: ContactItemProps, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div ref={ref} {...rest}>
      <Text>{personDescription}</Text>
      <Text size="xs" color="dimmed">
        {email}
      </Text>
    </div>
  ),
);

export default AutoCompleteContactItem;
