import { FC, ReactNode, createContext, useMemo, useState } from 'react';
import { usePostboxCounter } from '../hooks/usePostboxCounter';

interface Props {
  unreadCount?: number | null;
  unassignedCount?: number | null;
  draftsCount?: number | null;
  scheduledCount?: number | null;
  failedCount?: number | null;
  trashCount?: number | null;
  inboxCount?: number | null;
  refreshCount: (box: string) => void;
}

export const PostboxCountersContext = createContext<Props>({} as Props);

export const PostboxCountersProvider: FC<{ children: ReactNode; contactsIds?: number[] }> = ({
  children,
  contactsIds,
}) => {
  const [inboxLastUpdated, setInboxCount] = useState<number>(Date.now());
  const [draftsLastUpdated, setDraftsCount] = useState<number>(Date.now());
  const [scheduledLastUpdated, setScheduledCount] = useState<number>(Date.now());
  const [failedLastUpdated, setFailedCount] = useState<number>(Date.now());
  const [trashLastUpdated, setTrashCount] = useState<number>(Date.now());

  const inboxCount = usePostboxCounter('inbox', inboxLastUpdated, undefined, contactsIds);
  const unreadCount = usePostboxCounter('inbox', inboxLastUpdated, 'unread', contactsIds);
  const unassignedCount = usePostboxCounter('inbox', inboxLastUpdated, 'unassigned', contactsIds);
  const draftsCount = usePostboxCounter('drafts', draftsLastUpdated, undefined, contactsIds);
  const scheduledCount = usePostboxCounter('scheduled', scheduledLastUpdated, undefined, contactsIds);
  const failedCount = usePostboxCounter('failed', failedLastUpdated, undefined, contactsIds);
  const trashCount = usePostboxCounter('trash', trashLastUpdated, undefined, contactsIds);

  const refreshCount = (box: string) => {
    if (box === 'inbox') {
      setInboxCount(Date.now());
    }
    if (box === 'drafts') {
      setDraftsCount(Date.now());
    }
    if (box === 'scheduled') {
      setScheduledCount(Date.now());
    }
    if (box === 'failed') {
      setFailedCount(Date.now());
    }
    if (box === 'trash') {
      setTrashCount(Date.now());
    }
  };

  const value = useMemo(
    () => ({
      unreadCount,
      inboxCount,
      unassignedCount,
      draftsCount,
      scheduledCount,
      failedCount,
      trashCount,
      refreshCount,
    }),
    [unreadCount, unassignedCount, draftsCount, scheduledCount, failedCount, trashCount],
  );

  return <PostboxCountersContext.Provider value={value}>{children}</PostboxCountersContext.Provider>;
};
