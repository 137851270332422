import { Group, Popover, Tooltip } from '@mantine/core';
import { useCurrentEditor } from '@tiptap/react';
import { FC, useContext, useState } from 'react';
import { WindowContext } from '../../../Service/Context/WindowContext';
import EmojiPicker, { IEmojiData } from 'emoji-picker-react';
import ActionIconWithoutFocus from '../../../Atoms/ActionIconWithoutFocus';
import { IconHash, IconMoodWink2 } from '@tabler/icons';

const BaseToolbar: FC<{ includePlaceholders?: boolean }> = ({ includePlaceholders = true }) => {
  const { editor } = useCurrentEditor();
  const [opened, setOpened] = useState(false);
  const { md } = useContext(WindowContext);

  const onEmojiClick = (event: React.MouseEvent, data: IEmojiData) => {
    if (editor) {
      editor.commands.insertContent(data.emoji);
      setOpened(false);
    }
  };

  const onHashClicked = () => {
    if (editor) {
      editor.commands.insertContent('#');
    }
  };

  if (!editor) {
    return null;
  }

  return (
    <Group spacing={0} p="xs">
      {!md && (
        <Popover opened={opened} onChange={setOpened} position="top" returnFocus={true}>
          <Popover.Target>
            <Tooltip openDelay={300} withArrow transition="slide-up" label="Emoji">
              <div>
                <ActionIconWithoutFocus variant="subtle" onClick={() => setOpened((o) => !o)}>
                  <IconMoodWink2 size={20} />
                </ActionIconWithoutFocus>
              </div>
            </Tooltip>
          </Popover.Target>
          <Popover.Dropdown>
            <EmojiPicker onEmojiClick={onEmojiClick} />
          </Popover.Dropdown>
        </Popover>
      )}
      {includePlaceholders && (
        <Tooltip openDelay={300} withArrow transition="slide-up" label="Platzhalter einfügen">
          <div>
            <ActionIconWithoutFocus onClick={onHashClicked}>
              <IconHash size={20} />
            </ActionIconWithoutFocus>
          </div>
        </Tooltip>
      )}
    </Group>
  );
};

export default BaseToolbar;
