// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { i18n } from '@lingui/core';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateDocumentConfigDTO, ProcedureReportProps } from '../../../../Types/LogT';

const CreateDocumentReport: FC<ProcedureReportProps> = ({ report, config }) => {
  const { entityId, executionStatus } = report ?? {};
  const { documentType, templateName, originDocument } = config as CreateDocumentConfigDTO;
  const navigate = useNavigate();
  const { id: eventId } = useParams();
  const documentCreated = executionStatus === 'COMPLETED';

  const onClick = () => {
    if (eventId && documentCreated) {
      navigate(`/events/${eventId}/documents/${entityId}`);
    }
  };

  const documentNode = () => (
    <Text
      span
      weight="bolder"
      onClick={onClick}
      variant={documentCreated ? 'link' : 'text'}
      color={documentCreated ? 'blue' : 'inherit'}
      sx={{ cursor: documentCreated ? 'pointer' : 'default' }}
    >
      {i18n._(`document.${documentType.toLowerCase()}`)}
    </Text>
  );

  if (originDocument) {
    const { label } = originDocument;
    return (
      <Text>
        {documentNode()}
        {'  '}wurde aus Dokument{'  '}
        <Text span weight="bolder">
          {label}
        </Text>
        .
      </Text>
    );
  }

  if (templateName) {
    return (
      <Text>
        {documentNode()}
        {'  '}wurde aus Vorlage{'  '}
        <Text
          span
          weight="bolder"
          color="blue"
          variant="link"
          onClick={() => navigate(`/settings/template/document/${documentType.toLowerCase()}/${templateName}`)}
          sx={{ cursor: 'pointer' }}
        >
          {templateName}
        </Text>
        {'  '}erstellt.
      </Text>
    );
  }

  return null;
};

export default CreateDocumentReport;
