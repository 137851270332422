import React, { useContext, useState } from 'react';
import { createContractTemplate } from '../../../../../Service/restapi/contractTemplateService';
import { TransButtonDefaultWithIcon } from '../../../../../Atoms/Buttons';
import { CheckLicence } from '../../../../../Organismns/Upgrade/CheckLicence';
import ContractTemplateNameModal from '../../../../../Organismns/Settings/Documents/ContractTemplates/ContractTemplateNameModal';
import { useModalState } from '../../../../../Molecules/Dialogs';
import { ViolationContext } from '../../../../../Service/Context/ViolationContext';
import { Button, Loader } from '@mantine/core';
import { IconSignature } from '@tabler/icons';
import { LicenceS } from '../../../../../Service/LicenceS';
import { ToastS } from '../../../../../Service/ToastS';
import { ConstantS } from '../../../../../Service/ConstantS';
import { MixpanelS } from '../../../../../Service/MixpanelS';

const ContractTemplateWorkflow = ({ reloadContracts }) => {
  const [loading, setLoading] = useState(false);
  const { show, toggle } = useModalState(false);
  const { executeWithLicenceCheck } = useContext(ViolationContext);

  const generate = (name) => {
    setLoading(true);
    const template = {
      name,
      subject: null,
      positions: true,
      contractHead: 'PARTIES',
      contractFooter: 'BLOCKS',
      contractSign: 'BOTH',
      customerSignType: 'BASE64',
      aboveTextBlocks: [
        {
          headerFirstColumn: '<b>1.) Vertragsgegenstand, Leistungen, Preise</b>',
          contentFirstColumn:
            'Ich begleite als DJ die folgende Veranstaltung musikalisch.<br/>' +
            '<b>Veranstaltungsart:</b> {event_kind}<br/>' +
            '<b>Veranstaltungsdatum:</b> {event_date}<br/>' +
            '<b>Location:</b> {location_name}, {location_address}<br/>' +
            '<b>Musikbeginn:</b> {event_timeFrom}<br/>' +
            '<b>Musikende:</b> {event_timeTo}<br/>',
        },
      ],
      belowTextBlocks: [
        {
          headerFirstColumn: null,
          contentFirstColumn:
            'Eingeschlossen in diese Preise ist die Musik bis um {event_timeTo} Uhr. Danach fallen pro Stunde zusätzlich\n' +
            '€ ________ inkl. Umsatzsteuer an (abgerechnet im Takt von 15 Minuten).',
        },
        {
          headerFirstColumn: '<b>2.) Zahlung</b>',
          contentFirstColumn:
            '(1) Mit Unterzeichnung des Vertrags ist eine Anzahlung in Höhe von ________ % der Gesamtsumme (brutto) zu ' +
            'leisten (Hinweis 1: Spanne von 0 – 30 % möglich). Diese ist innerhalb von ________ Tagen nach Unterzeichnung dieses Vertrags zu zahlen. ' +
            '<b>(Hinweis 2: 7 – 14 Tage üblich.)</b> ' +
            'Wir werden Ihnen hierüber eine entsprechende Rechnung stellen.',
        },
        {
          headerFirstColumn: null,
          contentFirstColumn:
            '(2) Der Restbetrag in Höhe von ________ % der Gesamtsumme (brutto) <b>(Hinweis 1: entsprechend zur gewählten Höhe der Anzahlung Spanne von 100 - 70 % möglich)</b> ist (i) in bar vor der Veranstaltung zu zahlen*, (ii) in bar nach der Veranstaltung zu zahlen*, oder (iii) innerhalb von ________ Tagen nach Durchführung der Veranstaltung zu zahlen. ' +
            '<b>(Hinweis 2: 7 - 14 Tage üblich)</b>' +
            'Wir werden Ihnen auch hierüber eine entsprechende Rechnung stellen. ' +
            '<b>* (Bitte Unzutreffendes streichen.)</b>',
        },
        {
          headerFirstColumn: null,
          contentFirstColumn:
            '(3) Sollte keine Barzahlung vereinbart worden sein, sind diese Beträge auf das folgende Konto zu überweisen:<br/>' +
            'Kontoinhaber: <br/>' +
            'Bank: <br/>' +
            'IBAN: <br/>' +
            'BIC: ',
        },
        {
          headerFirstColumn: null,
          contentFirstColumn:
            '(4) 30 Tage nach Zugang meiner Rechnung kommen Sie automatisch in Verzug. Das heißt, dass nach 30 Tagen z.B. Verzugszinsen und Mahngebühren aufgeschlagen werden können. Einer Mahnung bedarf es hierfür nicht. Ich werde Sie hierauf in meiner Rechnung noch einmal ausdrücklich hinweisen. <br/>' +
            '<b>(Hinweis: Sollten Sie die Klausel nicht aufnehmen wollen, streichen Sie diese bitte; ansonsten müssen Sie in der Rechnung hierauf ausdrücklich hinweisen.)</b>',
        },
        {
          headerFirstColumn: null,
          contentFirstColumn:
            '(5) Bei Zahlungsverzug gelten die folgenden Regelungen:<br/>' +
            '<b>Für Verbraucher:</b> Im Fall von Zahlungsverzug richten sich meine Ansprüche nach § 288 BGB, insbesondere kann ich Zinsen in Höhe von 5 % über dem jeweils geltenden Basiszinssatz erheben.<br/>' +
            '<b>(Hinweis: Spanne von 1 - 5 % möglich)</b><br/>' +
            '<b>Für Unternehmer:</b> Im Fall von Zahlungsverzug richten sich meine Ansprüche nach § 288 BGB, insbesondere kann ich Zinsen in Höhe von 9 % über dem jeweils geltenden Basiszinssatz ' +
            '<b>(Hinweis 1: Spanne von 1 - 9 % möglich)</b> ' +
            'und eine Pauschale in Höhe von 40 EURO erheben <b>(Hinweis 2: Spanne von 10 - 40 EURO möglich)</b>. ' +
            'Diese Pauschale ist auf einen Schadensersatz anzurechnen, der im Rahmen der Rechtsverfolgung entstanden ist. ' +
            '<b>(Hinweis 3: Sollten Sie die Klausel nicht aufnehmen wollen, streichen Sie diese bitte.)</b>',
        },
        {
          headerFirstColumn: '<b>3.) Entschädigungspauschalen</b>',
          contentFirstColumn:
            '(1) Der Grund für die nachfolgende Klausel liegt darin, dass DJs sehr langfristig für Veranstaltungen wie z.B. Hochzeiten gebucht werden. Sollte die Veranstaltung kurzfristig abgesagt werden, ist es dem DJ daher nicht mehr möglich, den frei gewordenen Termin anderweitig zu vergeben. Entsprechend würde dies zu einem 100 % Ausfall seiner Einnahmen führen. Um dies abzusichern und gleichzeitig den Bedürfnissen der Kunden Rechnung zu tragen, orientiert sich die nachfolgende Staffel der Entschädigungspauschale an (i) den zu erwartenden Einnahmen durch anderweitige Aufträge des DJs, (ii) die zu erwartende Ersparnis des DJs und (iii) wie lange vor dem Veranstaltungstermin die Absage erfolgt.',
        },
        {
          headerFirstColumn: null,
          contentFirstColumn:
            '(2) Der Kunde kann jederzeit vom Vertrag zurücktreten. In diesem Fall erlischt mein Vergütungsanspruch. Ich kann jedoch eine angemessene Entschädigung gemäß der nachfolgenden Entschädigungspauschalen verlangen: <br/> <br/>' +
            '(a) Rücktrittserklärung bis zum 10. Monat vor dem Datum der Veranstaltung: keine Entschädigung <br/>' +
            '(b) Rücktrittserklärung ab dem 9. bis einschließlich 5. Monat vor dem Datum der Veranstaltung: 30 % der Gesamtsumme (netto) <br/>' +
            '(c) Rücktrittserklärung ab dem 4. Monat vor dem Datum der Veranstaltung: 40 % der Gesamtsumme (netto)<br/>' +
            '(d) Rücktrittserklärung ab dem 3. Monat vor dem Datum der Veranstaltung: 50 % der Gesamtsumme (netto)<br/>' +
            '(e) Rücktrittserklärung ab dem 2. Monat vor dem Datum der Veranstaltung: 65 % der Gesamtsumme (netto)<br/>' +
            '(f) Rücktrittserklärung ab 30 Tage vor dem Datum der Veranstaltung: 80 % der Gesamtsumme (netto)<br/>' +
            '(g) Rücktrittserklärung ab 14 Tage vor dem Datum der Veranstaltung: 90 % der Gesamtsumme (netto)<br/><br/>' +
            'Dem Kunden steht es frei, nachzuweisen, dass kein Anspruch bzw. ein Anspruch nur in geringere Höhe entstanden\n' +
            'ist.<br/>' +
            '<b>(Hinweis: Bei kurzfristigen Buchungen, also Buchungen innerhalb von 1-3 Monaten vor der Veranstaltung, können Sie eine andere Vereinbarung treffen; Sie können z.B. diese Regelung streichen oder unter Ziffer 12 auf eine Entschädigung verzichten bzw. eine andere Quote vereinbaren)</b>',
        },
        {
          headerFirstColumn: null,
          contentFirstColumn:
            '(3) Entscheidend für die vorbezeichneten Entschädigungspauschalen ist der Zugang der Rücktrittserklärung beim DJ. Der DJ ist berechtigt, die Entschädigungspauschalen dem Kunden in Rechnung zu stellen und von der Anzahlung einzubehalten bzw. mit dieser zu verrechnen.<br/>',
        },
        {
          headerFirstColumn: null,
          contentFirstColumn:
            '(4) Sollten wir einen Ersatztermin vereinbaren oder sollten Sie meine Leistungen für eine andere Veranstaltung buchen, können wir selbstverständlich andere Beträge vereinbaren, die Entschädigungspauschale ganz oder teilweise verrechnen (ausgenommen ________ % der Gesamtsumme (netto) als Entschädigung) * oder diese Vereinbarung aufheben.<br/>' +
            '<b>*(Hinweis: Ausnahme ist optional, sollten Sie dies nicht wollen, streichen Sie diesen Punkt bitte; Spanne: 10 - 30%)</b>',
        },
        {
          headerFirstColumn: '<b>4.) Absage Veranstaltung</b>',
          contentFirstColumn:
            '<b>(1) Gründe des Kunden</b><br/>' +
            'Sofern aufgrund von (i) seiner Krankheit, (ii) der Krankheit einer ihm nahe stehenden Person (nahestehende Personen sind Personen seiner Familie bis einschließlich des 1. Verwandtschaftsgrades, also einschl. Brüdern oder Schwestern; darunter fällt bei Hochzeiten oder Verlobungsfeiern selbstverständlich auch die oder der Lebenspartner/-in), (iii) einer Trennung (bei Hochzeiten oder Verlobungsfeiern) oder (iv) einem Trauerfall der Kunde die Veranstaltung absagt (und dies nachweist*), werden die Parteien sich nach besten Kräften bemühen, einen Ersatztermin zu vereinbaren. Sollte ihnen dies nicht möglich oder zumutbar sein, oder lehnt eine der Parteien dies aus triftigem Grund ab, ist der Kunde berechtigt, von diesem Vertrag zurückzutreten. Die Rücktrittserklärung muss per Textform (E-Mail ausreichend) erfolgen. In diesem Fall wird der DJ die erhaltene Anzahlung (gegebenenfalls unter Abzug der Vorbereitungskosten (brutto)) * erstatten. Ziffer 3 gilt unverändert fort, soweit der Rücktritt des Kunden nicht aufgrund der Krankheit, der Trennung oder des Trauerfalls erfolgt. *(Hinweise: optional - gegebenenfalls bitte streichen)' +
            '<b>(Hinweis: Diese Regelung ist für Ihre Kunden SEHR kulant. Sie müssen sich vergegenwärtigen, dass der Kunde hierdurch in der Regel keine Entschädigung zahlen wird. Es bleiben kaum Situationen, die nicht in dieser Klausel abgedeckt werden. Meines Erachtens ist es deshalb empfehlenswert, diese Klausel vollständig zu streichen; alternativ können Sie einzelne der unter (i) - (iv) beschriebenen Situationen streichen.)</b>',
        },
        {
          headerFirstColumn: null,
          contentFirstColumn:
            '<b>(2) Gründe des DJs</b><br/>' +
            'Sofern aufgrund von seiner Krankheit, der Krankheit einer ihm nahe stehenden Person (nahe stehende Personen sind Personen seiner Familie bis einschließlich des 1. Verwandtschaftsgrades, also einschl. Brüdern oder Schwestern und die Ehefrau und der Ehemann bzw. der/die Lebenspartner/-in) oder einem Trauerfall der DJ seine Leistungen nicht erbringen kann, werden die Parteien sich nach besten Kräften bemühen, einen Ersatztermin zu vereinbaren. Sollte ihnen dies nicht möglich oder zumutbar sein, oder lehnt eine der Parteien dies aus triftigem Grund ab, ist der DJ berechtigt, einen anderen DJ vorzuschlagen. Sollte der Kunde diesen jedoch ablehnen, ist der DJ berechtigt, von diesem Vertrag zurückzutreten. Die Rücktrittserklärung muss per Textform (E-Mail ausreichend) erfolgen. In diesem Fall wird der DJ die erhaltene Anzahlung erstatten.',
        },
        {
          headerFirstColumn: null,
          contentFirstColumn:
            '<b>(3) Coronabedingte Umstände oder höhere Gewalt</b><br/>' +
            'Sofern aufgrund von Gesetzen, Verordnungen, Allgemeinverfügungen oder Verwaltungsakten zur Be-kämpfung oder Verhinderung der Ausbreitung des Coronavirus oder ähnlich schwerwiegender Gründe von höherer Gewalt (also Umstände, die außerhalb der Einflusssphäre des Kunden oder des DJ liegen wie z.B. Naturkatastrophen) die Veranstaltung nicht stattfinden kann, liegt keine vom DJ oder dem Kunden zu ver-tretende Pflichtverletzung vor. Dies gilt unabhängig vom Zeitpunkt der Verkündung des Gesetzes oder Er-lasses der Verordnung, der Allgemeinverfügung oder des Verwaltungsaktes. Die Parteien werden sich nach besten Kräften bemühen, einen Ersatztermin zu vereinbaren. Sollte ihnen dies nicht möglich oder zumutbar sein, oder lehnt eine der Parteien dies aus triftigem Grund ab, sind beide Parteien berechtigt, von diesem Vertrag entschädigungsfrei zurückzutreten. Die Rücktrittserklärung muss per Textform (E-Mail ausrei-chend) erfolgen. In diesem Fall wird der DJ die erhaltene Anzahlung (gegebenenfalls unter Abzug der Vorbereitungskosten (brutto))* erstatten. Ziffer 3 gilt unverändert fort, soweit der Rücktritt des Kunden nicht aufgrund eines coronabedingten Umstandes oder aufgrund höherer Gewalt erfolgt.<br/>' +
            '<b>*(Hinweis: optional - gegebenenfalls bitte streichen)</b>',
        },
        {
          headerFirstColumn: '<b>5.) GEMA-Gebühren</b>',
          contentFirstColumn:
            'Bei privaten Veranstaltungen für geladene Gäste, wie z.B. Partys oder Hochzeitsfeiern, können Gebühren\n' +
            'der „Gesellschaft für musikalische Aufführungs- und mechanische Vervielfältigungsrechte“ (nachfolgend\n' +
            '„GEMA“) anfallen. Ausgenommen sind lediglich rein private Feiern bis zu einer gewissen Größe, wie z.B.\n' +
            'eine Geburtstagsfeier mit bis zu 30 Gästen, eine Weihnachtsfeier in kleinem Kreis oder eine Hochzeit mit\n' +
            'bis zu 80 – 100 Gästen. Die Gebühren für Ihre Veranstaltung können Sie unter der folgenden Adresse\n' +
            'gema.de/portal/app/tarifrechner/preisrechner berechnen lassen. Sie sind dafür verantwortlich, Ihre\n' +
            'Veranstaltung ordnungsgemäß anzumelden und die Gebühren an die GEMA abzuführen.<br/>' +
            '<b>(Hinweise: Die Anmeldung muss mindestens 3 Tage vorher erfolgen. Bis sechs Wochen nach der Ver-anstaltung ist eine Setliste einzureichen, also eine Liste mit den gespielten Liedern. Wenn dies überse-hen wird, erfolgt eine Strafe in Höhe von 10% der Gebühren.  Falls Sie die Veranstaltung doch absagen müssen, schreiben Sie bitte eine E-Mail an kontakt@gema.de)</b>',
        },
        {
          headerFirstColumn: null,
          contentFirstColumn:
            'Sollte mich – ausnahmsweise – die GEMA wegen Ihrer Gebühren in Anspruch nehmen, werden Sie mich von allen Ansprüchen, Kosten und Aufwendungen der GEMA (einschließlich angemessener Kosten einer Rechtsver-folgung und Bußgeldforderungen) freistellen, vorausgesetzt, dass<br/>' +
            '(i) ich Sie per E-Mail über die Stellung solcher Ansprüche informiert habe,<br/>' +
            '(ii)  die Anerkennung, die Abwehr der Ansprüche der GEMA oder ein Vergleich nur nach entsprechender Zustimmung (E-Mail) Ihrerseits erfolgt und <br/>' +
            '(iii) auf Rechtsmittel nur nach Ihrer Zustimmung (E-Mail) verzichtet werden kann bzw. diese nur nach Ihrer Zustimmung zurückgenommen werden können.<br/>' +
            'Dies gilt entsprechend für die Abgabe einer Unterlassungs-, Unterwerfungs- oder Abschlusserklärung. Zudem werden wir uns gegenseitig über neue Schreiben, Schriftsätze, E-Mails etc. umfassend und zeitnah informieren. Erfolgt die vorgenannte Abstimmung mit Ihnen nicht innerhalb der vorliegenden Fristen und verbleiben mir keine weiteren Möglichkeiten der Rechtsverfolgung, bin ich in der Wahl der Verteidigung und des Rechtsmittels frei.',
        },
        {
          headerFirstColumn: '<b>6.) Verantwortlichkeit des Kunden bei Schäden durch seine Gäste etc.</b>',
          contentFirstColumn:
            'Der Kunde haftet für alle Schäden, die durch seine Gäste, Veranstaltungsteilnehmer oder -besucher, Mitarbeiter oder sonstige Dritte, die für ihn tätig werden, verursacht werden. Für Unternehmer: Sofern Sie als Kunde Unternehmer sind, haften Sie unabhängig vom Verschuldensnachweis durch den DJ. Für Verbraucher: Sofern Sie als Kunde Verbraucher sind, haften Sie nur im Verschuldensfall. (Option 1)* Im Fall der Beschädigung des Equipements des DJs oder sonstiger ihm gehörender Gegenstände wird der Kunde dem DJ den Namen und die notwendigen weiteren Informationen wie Anschrift und Telefonnummer bezüglich seines Gastes oder seiner Gäste, Veranstaltungsteilnehmers oder -besuchers, Mitarbeiters oder sonstiger Dritter, die für ihn tätig geworden sind, mitteilen. (Option 2)*<br/>' +
            '<b>*(Hinweis: Bitte eine Option wählen und die andere Option streichen.)</b>',
        },
        {
          headerFirstColumn: '<b>7.) Haftung</b>',
          contentFirstColumn:
            'Ich hafte (gleich aus welchem Rechtsgrund) auf Schadensersatz oder Ersatz vergeblicher Aufwendungen (zusammen „Schadensersatz“) nach Maßgabe der folgenden Bestimmungen unter den Buchstaben (a), (b) und (c).<br/>' +
            '(a) Ich hafte bei vorsätzlicher und grob fahrlässiger Pflichtverletzung unbeschränkt. Dies gilt auch, wenn ich einen Mangel arglistig verschweige oder eine Garantie für die Beschaffenheit der Ware übernommen habe, für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und im Fall einer Haftung nach dem Produkthaftungsgesetz.<br/>' +
            '(b) Bei einfacher Fahrlässigkeit hafte ich auf Schadensersatz nur aus der Verletzung einer wesentlichen Vertragspflicht (eine Verpflichtung, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Vertragspartner regelmäßig vertraut oder vertrauen darf); in diesem Fall ist die Haftung auf den vorhersehbaren Schaden beschränkt, mit dessen Entstehung typischerweise gerechnet werden muss. <br/>' +
            'Im Übrigen sind Ansprüche auf Schadenersatz ausgeschlossen.<br/>' +
            '(c) Soweit meine Haftung ausgeschlossen oder beschränkt ist, gilt dies auch für die persönliche Haftung der gesetzlichen Vertreter und Erfüllungsgehilfen.',
        },
        {
          headerFirstColumn: '<b>8.) Betriebshaftpflichtversicherung</b>',
          contentFirstColumn:
            'Ich habe eine Betriebshaftpflichtversicherung abgeschlossen.<br/>' +
            '<b>* (Hinweis: Wenn Sie diese Angabe nicht machen möchten, bitte streichen und Nummerierungen anpassen)</b>',
        },
        {
          headerFirstColumn: '<b>9.) Sonstiges</b>',
          contentFirstColumn:
            '(1) Der Kunde wird dem DJ während der Veranstaltung Getränke und Verpflegung in üblicher Menge zur Verfügung stellen. Zudem wird der Kunde sich gegebenenfalls mit den Mitarbeitern der Location verständi-gen, dass dem DJ der Zutritt sowie die An- und Abfahrt (insbesondere Be- und Entladen des Equipments) ermöglicht wird. Schließlich wird der Kunde den DJ gegebenenfalls auch bei der Suche nach einem Park- oder Stellplatz unterstützen.<br/>' +
            '(2) Ferner werden die Parteien sich gemeinsam mit den Mitarbeitern der Location dahingehend verständigen, dass der DJ die Location mit einem „3G“-Status betreten kann. Dies ist deshalb zu gewähren, da es sich für den DJ um den Zugang zu „seinem Arbeitsplatz“ handelt.' +
            '(3) Werbung mit den Ton-, Bild- oder Videoaufnahmen Ihrer Veranstaltung: Hiermit gestatten Sie als Kunden mir ausdrücklich, dass ich Ton-, Bild- oder Videoaufnahmen Ihrer Veranstaltung nutzen kann, um für mein Unternehmen oder meine Dienstleistungen in den Printmedien oder online werben zu können (selbstverständlich verwende ich nur solche Aufnahmen, bei denen keiner Ihrer Gäste zu erkennen ist).</b>',
        },
        {
          headerFirstColumn: '<b>10.) Gerichtsstand</b>',
          contentFirstColumn:
            'Wenn Sie Kaufmann sind, ist ausschließlicher Gerichtsstand  ______________________________________.<br/>' +
            '<b>(Hinweis: Bitte hier die nächstgrößere Stadt mit einem Gericht eintragen.)</b>',
        },
        {
          headerFirstColumn: '<b>11.) Anwendbares Recht</b>',
          contentFirstColumn:
            'Es gilt das Recht der Bundesrepublik Deutschland. Diese Rechtswahl gilt aber nur insoweit, als nicht der gewährte Schutz durch zwingende Bestimmungen des Rechts des Staates, in dem der Verbraucher seinen gewöhnlichen Aufenthalt hat, entzogen wird.',
        },
      ],
    };
    createContractTemplate(template)
      .then((resp) => {
        if (resp.ok) {
          console.log('contract successfully created');
          ToastS.success('foo', `Vertrag ${name}  erfolgreich angelegt`);
          MixpanelS.track(ConstantS.TrackingEvents.ContractTemplateCreated, { default: true });
          resp.json().then((contract) => {
            console.log({ contract });
          });
        } else {
          console.error('could not create contract template');
        }
      })
      .catch((error) => {
        console.error('could not create contract template', { error });
      })
      .finally(() => {
        setLoading(false);
        reloadContracts();
      });
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Button
        disabled={loading}
        rightIcon={loading && <Loader size="xs" />}
        leftIcon={<IconSignature size={16} />}
        onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.DJ_CONTRACT_TEMPLATE, toggle)}
      >
        DJ-Mustervertrag erstellen
      </Button>
      <ContractTemplateNameModal show={show} toggle={toggle} finish={generate} />
    </div>
  );
};

export default ContractTemplateWorkflow;
