// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookitupNotification } from '../../../../Types/LogT';

const ContractCreated: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { entityId, eventId } = notification;
  const navigate = useNavigate();

  return (
    <Text>
      <Text
        span
        weight="bolder"
        variant="link"
        color="blue"
        sx={{ cursor: 'pointer' }}
        onClick={() => navigate(`/events/${eventId}/contracts/${entityId}/preview`)}
      >
        Vertrag
      </Text>
      {'  '}
      wurde erstellt.
    </Text>
  );
};

export default ContractCreated;
