import { ajaxActions } from './AjaxActions';

export type DocumentPayment = {
  id: string;
  payDate: Date;
  value: number;
  cancelled: boolean;
  cancellationCause: string | null;
};

type PaymentCancellation = {
  cancellationDate: Date;
  cancellationCause: string;
};

const BASE_URL = `${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/payments`;

const addPayment = (documentId: number, body: Partial<DocumentPayment>): Promise<DocumentPayment | null> =>
  ajaxActions.post(`${BASE_URL}?documentId=${documentId}`, body).then((res) => {
    if (res.status === 201) {
      return res.json();
    }
    return null;
  });

const getPayments = (documentId: number): Promise<DocumentPayment[] | null> =>
  ajaxActions.get(`${BASE_URL}?documentId=${documentId}`).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return null;
  });

const cancelPayment = (paymentId: string, body: Partial<PaymentCancellation>): Promise<Response> =>
  ajaxActions.del(`${BASE_URL}/${paymentId}`, body);

// eslint-disable-next-line import/prefer-default-export
export const DocumentPaymentS = {
  getPayments,
  addPayment,
  cancelPayment,
};
