import { useContext, useEffect, useMemo, useState } from 'react';
import { createMessageTemplate } from '../../../Service/restapi/messageService';
import MessageTemplateListItem from './MessageTemplateListItem';
import PanePaper from '../../../Molecules/PanePaper';
import { useModalState } from '../../../Molecules/Dialogs';
import { useNavigate } from 'react-router-dom';
import MessageTemplateNameModal from '../../../Organismns/Settings/MessageTemplates/MessageTemplateNameModal';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import { RoutingS } from '../../../Service/RoutingS';
import { MixpanelS } from '../../../Service/MixpanelS';
import { ConstantS } from '../../../Service/ConstantS';
import { Stack } from '@mantine/core';
import SkeletonItems from '../../../Atoms/SkeletonItems';
import { MessageTemplateDto } from '../../../Types/MessageT';
import { MessageS } from '../../../Service/MessageS';
import { i18n } from '@lingui/core';
import _ from 'lodash';

const MessageTemplateList = () => {
  const [messageTemplates, setMessageTemplates] = useState<MessageTemplateDto[]>([]);
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const { show, toggle } = useModalState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const existingNames = useMemo(
    () => messageTemplates.filter((m) => m.messageType === 'CUSTOM').map((m) => m.name),
    [messageTemplates],
  );

  const create = (templateName: string) => {
    createMessageTemplate('CUSTOM', templateName).then((resp) => {
      if (resp.ok) {
        MixpanelS.track(ConstantS.TrackingEvents.EmailTemplateCreated);
        navigate(`/settings/template/message/custom/${templateName}`);
      }
    });
  };

  const removeTemplate = (id: number) => setMessageTemplates(messageTemplates.filter((t) => t.id !== id));

  const onUpdate = (template: MessageTemplateDto) => {
    const tmp = messageTemplates.map((t) => (t.id === template.id ? template : t));
    setMessageTemplates(_.orderBy(tmp, ['name']));
  };

  useEffect(() => {
    RoutingS.changeTitle('Email-Vorlagen');
    setLoading(true);
    MessageS.fetchTemplates()
      .then((templates) => {
        MessageS.fillTemplateNames(templates, i18n);
        setMessageTemplates(_.orderBy(templates, ['name']));
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <PanePaper
      title="message.templates"
      tooltip="help.settings.message.templates.pane"
      rightSection={
        <BookitupAddIcon
          onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.MULTIPLE_EMAIL_TEMPLATE, toggle)}
        />
      }
      sx={{ maxWidth: 500 }}
    >
      <Stack mt="md">
        {loading && <SkeletonItems count={8} height={80} />}
        {!loading &&
          messageTemplates.map((template) => (
            <MessageTemplateListItem
              key={template.id}
              template={template}
              onDelete={() => removeTemplate(template.id)}
              onUpdate={onUpdate}
              existingNames={existingNames}
            />
          ))}
      </Stack>
      <MessageTemplateNameModal show={show} toggle={toggle} finish={create} />
    </PanePaper>
  );
};

export default MessageTemplateList;
