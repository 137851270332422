import React from 'react';

export const AutocompleteListTopComponent = ({ text }) => {
  const styles = {
    container: {
      padding: 5,
      fontSize: 12,
      fontStyle: 'italic',
    },
  };

  return <div style={styles.container}>{text}</div>;
};
