import { Space, Table } from '@mantine/core';
import { FC, ReactNode } from 'react';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  thead {
    tr {
      th {
        color: black;
        font-weight: normal;
        border-bottom: 2px solid #e2e2e2;
        padding-bottom: 15px;
      }
    }
  }
  tbody {
    margin-top: 100px;
    tr:nth-child(2n) {
      background-color: #F1F1F1;
    }
    tr {
      &:hover {
        background-color: #E8EEF4;
      }
      height: 40px;
      td {
        font-weight: 300;
        border: none;
      }
    }
  }
`;

const BookitupTable: FC<{ children: ReactNode }> = ({ children }) => (
  <StyledTable>
    <Space h="sm" />
    {children}
  </StyledTable>
);

export default BookitupTable;
