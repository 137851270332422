// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Loader, Switch, Text } from '@mantine/core';
import { IconListCheck } from '@tabler/icons';
import { FC, useContext, useState } from 'react';
import PanePaper from '../../../Molecules/PanePaper';
import { GoogleAccountDTO } from '../../../Types/GoogleT';
import { GoogleS } from '../../../Service/GoogleS';
import ConfigOption from './ConfigOption';
import GoogleScopeIcon from './GoogleScopeIcon';
import MissingScope from './MissingScope';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import { MixpanelS } from '../../../Service/MixpanelS';
import { ConstantS } from '../../../Service/ConstantS';

interface Props {
  googleAccount: GoogleAccountDTO;
  handleChange: (patch: GoogleAccountDTO) => void;
}

const GoogleTasksPane: FC<Props> = ({ googleAccount, handleChange }) => {
  const [loading, setLoading] = useState(false);
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const { id, hasTasksScopes, syncTasksAutomatically } = googleAccount;

  const toggleAutoSync = () => {
    if (!syncTasksAutomatically) {
      MixpanelS.track(ConstantS.TrackingEvents.GoogleTaskSyncActivated);
    }
    const patch = { syncTasksAutomatically: !syncTasksAutomatically };
    GoogleS.editAccountConfig(id, patch).then((_googleAccount) => {
      if (_googleAccount) {
        handleChange(_googleAccount);
      }
    });
  };

  const exportAll = () => {
    setLoading(true);
    GoogleS.exportAllTasks(id).finally(() => setLoading(false));
  };

  return (
    <PanePaper
      withoutBorder
      sx={{ backgroundColor: '#F3F3F3' }}
      rightSection={<GoogleScopeIcon accessGranted={hasTasksScopes} innerIcon={<IconListCheck size={14} />} />}
    >
      <Text size="lg" mb="xl">
        Aufgaben
      </Text>
      {!hasTasksScopes && <MissingScope />}
      {hasTasksScopes && (
        <>
          <ConfigOption
            title="Automatisch synchronisieren"
            description={`Alle Aufgabenänderungen automatisch mit Google synchronisieren. Alle bookitup Aufgaben werden in der Google-Aufgabenliste "bookitup" gespeichert.`}
            rightSection={
              <Switch
                checked={syncTasksAutomatically}
                onChange={() => executeWithLicenceCheck(LicenceS.Restrictions.GOOGLE_TASKS, toggleAutoSync)}
              />
            }
          />
          <ConfigOption
            title="Alles exportieren"
            description="Exportiere alle bookitup-Aufgaben in Google"
            rightSection={
              <div>
                {loading ? (
                  <Loader variant="dots" />
                ) : (
                  <Text
                    variant="link"
                    color="blue"
                    sx={{ cursor: 'pointer' }}
                    onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.GOOGLE_TASKS, exportAll)}
                  >
                    Export
                  </Text>
                )}
              </div>
            }
          />
        </>
      )}
    </PanePaper>
  );
};

export default GoogleTasksPane;
