// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { openModal } from '@mantine/modals';
import PanePaper from '../../PanePaper';
import { EventContext } from '../../../Service/Context/EventContext';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { RecommendationDto } from '../../../Types/RecommendationT';
import { RecommendationS } from '../../../Service/RecommendationS';
import { EventLocationContext } from '../../../Service/Context/EventLocationContext';
import { useAppUser } from '../../../Service/Context/ApplicationUserContext';
import RecommendationSource from './RecommendationSource';
import RecommendationRequests from './RecommendationRequests';
import AddRecommendationModal from './Modals/AddRecommendationModal';

interface RecommenationsContextInterface {
  recommendations: RecommendationDto[];
  loading: boolean;
  reload: () => void;
  removeRecommendation: (recommendationId: string) => void;
  removeAll: () => void;
  patchRecommendation: (recommendation: RecommendationDto) => void;
}

export const RecommendationContext = createContext<RecommenationsContextInterface>(
  {} as RecommenationsContextInterface,
);

const RecommendationPane = () => {
  const [recommendations, setRecommendations] = useState<RecommendationDto[]>([]);
  const { redGreenWeakness } = useAppUser();
  const [loading, setLoading] = useState(false);
  const { event } = useContext<any>(EventContext);
  const { location } = useContext<any>(EventLocationContext);
  const { id: eventId, recommendationId } = event;

  const fetchRecommendations = useCallback(() => {
    setLoading(true);
    RecommendationS.fetchByEventId(eventId)
      .then(setRecommendations)
      .finally(() => {
        setLoading(false);
      });
  }, [eventId]);

  useEffect(() => {
    fetchRecommendations();
  }, [eventId, fetchRecommendations]);

  const removeRecommendation = (id: string) =>
    setRecommendations(recommendations.filter((_recommendation) => _recommendation.id !== id));

  const removeAll = () => setRecommendations([]);

  const patchRecommendation = (recommendation: RecommendationDto) => {
    const { id } = recommendation;
    setRecommendations(
      recommendations.map((_recommendation) => (_recommendation.id === id ? recommendation : _recommendation)),
    );
  };

  const appendRecommendations = (_recommendations: RecommendationDto[]) => {
    setRecommendations([...recommendations, ..._recommendations]);
  };

  const value = useMemo(
    () => ({
      recommendations,
      loading,
      reload: fetchRecommendations,
      removeRecommendation,
      removeAll,
      patchRecommendation,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [recommendations, loading],
  );

  const openAddRecommendationModal = () =>
    openModal({
      size: 'md',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Dienstleister auswählen
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: (
        <AddRecommendationModal
          eventId={eventId}
          eventLocation={location}
          appendRecommendations={appendRecommendations}
          recommendations={recommendations}
          redGreenWeakness={redGreenWeakness}
        />
      ),
    });

  return (
    <PanePaper
      withShadow
      withoutBorder
      title="Vermittlungen"
      tooltip="help.event.recommendations.pane"
      rightSection={<BookitupAddIcon onClick={openAddRecommendationModal} />}
    >
      <RecommendationSource recommendationId={recommendationId} />
      <RecommendationContext.Provider value={value}>
        <RecommendationRequests />
      </RecommendationContext.Provider>
    </PanePaper>
  );
};

export default RecommendationPane;
