import { FC } from 'react';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import { TestS } from '../../TestS';
import { openCreateEventModal } from '../../Templates/Event/CreateEventModal';
import { ConstantS } from '../../Service/ConstantS';

const CreateEventIcon: FC<{ onClickCallback?: () => void }> = ({ onClickCallback }) => (
  <BookitupAddIcon
    id={ConstantS.Pages.Events.AddEvent}
    testId={TestS.Navigate.Add.EVENT}
    onClick={() => {
      if (onClickCallback) {
        onClickCallback();
      }
      openCreateEventModal();
    }}
  />
);

export default CreateEventIcon;
