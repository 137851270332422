import React, { useContext } from 'react';
import { TransButtonPrimary, TransButtonLinkWithIcon } from '../../Atoms/Buttons';
import { useModalState } from '../../Molecules/Dialogs';
import { hasSuitableLicence } from '../../Service/helper/LicenceService';
import { ApplicationUserContext } from '../../Service/Context/ApplicationUserContext';
import { AuthContext } from '../../Service/Context/AuthContext';
import { UpgradeModal } from './UpgradeModal';

export const CheckLicence = ({
  licence,
  purchasedOnly,
  id,
  buttonText,
  buttonComponent,
  onClick,
  isLoading,
  upgradeTitle,
  upgradeText,
  className,
  icon,
  style,
}) => {
  const { jwtClaims } = useContext(AuthContext);

  const { show, toggle } = useModalState(false);

  if (!hasSuitableLicence(licence, jwtClaims.licence, purchasedOnly)) {
    onClick = toggle;
  }
  let Button = TransButtonLinkWithIcon;
  if (buttonComponent) {
    Button = buttonComponent;
  }
  return (
    <>
      <Button
        icon={icon}
        className={`btn-link ${className}`}
        style={{ color: '#7d7d7d', ...style }}
        text={buttonText}
        id={id}
        onClick={onClick}
        isLoading={isLoading}
        onClickHandler={onClick}
      />
      <UpgradeModal show={show} toggle={toggle} title={upgradeTitle} text={upgradeText} licence={licence} />
    </>
  );
};
