/* eslint-disable no-param-reassign */
import { FC, ReactNode, createContext, useEffect, useMemo, useState } from 'react';
import { ContactsFilter } from '../../Types/Contact';
import { ContactS, Contact } from '../ContactS';
import { ContactGroup, ContactGroupOperation } from '../../Types/AuthT';
import { ContactGroupS } from '../ContactGroupS';

interface ContactsContextInterface {
  contacts: Contact[];
  contactGroups: ContactGroup[];
  count: number;
  totalPages: number;
  loading: boolean;
  loadingGroups: boolean;
  filter: ContactsFilter;
  reload: () => void;
  updateContact: (contact: Contact) => void;
  updateGroups: (group: ContactGroup, operation: ContactGroupOperation) => void;
  updateFilter: (patch: Partial<ContactsFilter>) => void;
}

export const ContactsContext = createContext<ContactsContextInterface>({} as ContactsContextInterface);

export const ContactsContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [loadingGroups, setLoadingGroups] = useState(false);
  const [contactGroups, setGroups] = useState<ContactGroup[]>([]);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState<ContactsFilter>({ pageNumber: 0 });

  useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();
    ContactS.fetchAllWithFilter(filter, abortController.signal)
      .then((page) => {
        if (page) {
          const { content, totalElements, totalPages: numberOfPages } = page;
          setCount(totalElements);
          setTotalPages(numberOfPages);
          setContacts(content);
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });

    return () => abortController.abort();
  }, [filter]);

  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = () => {
    setLoadingGroups(true);
    ContactGroupS.fetchAll()
      .then(setGroups)
      .finally(() => setLoadingGroups(false));
  };

  const updateFilter = (patch: Partial<ContactsFilter>) => {
    // Reset page number on filter change
    if (!patch.pageNumber) {
      patch.pageNumber = 0;
    }
    setFilter((prev) => ({ ...prev, ...patch }));
  };

  const reload = () => updateFilter({ trigger: new Date() });

  const updateGroups = (group: ContactGroup, operation: ContactGroupOperation) => {
    if (operation === 'ADD') {
      setGroups([...contactGroups, group]);
    } else if (operation === 'EDIT') {
      setGroups(contactGroups.map((g) => (g.id === group.id ? group : g)));
      reload();
    } else {
      setGroups(contactGroups.filter((g) => g.id !== group.id));
      reload();
    }
  };

  const updateContact = (updatedContact: Contact) =>
    setContacts(contacts.map((contact) => (contact.id === updatedContact.id ? updatedContact : contact)));

  const value = useMemo(
    () => ({
      contacts,
      contactGroups,
      count,
      totalPages,
      loading,
      loadingGroups,
      filter,
      updateFilter,
      updateContact,
      updateGroups,
      reload,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contacts, loading, contactGroups, loadingGroups, count, filter, totalPages],
  );

  return <ContactsContext.Provider value={value}>{children}</ContactsContext.Provider>;
};
