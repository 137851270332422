// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Group, Loader, Text } from '@mantine/core';
import { i18n } from '@lingui/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { FC, useState } from 'react';
import { GoogleS } from '../../../Service/GoogleS';

// eslint-disable-next-line import/prefer-default-export
export const openDeleteGoogleAccountModal = (id: number, removeHandler: () => void) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Verbindung entfernen
      </Text>
    ),
    closeOnEscape: true,
    transition: 'slide-down',
    children: <DeleteGoogleAccountModal id={id} removeHandler={removeHandler} />,
  });
};

const DeleteGoogleAccountModal: FC<{ id: number; removeHandler: () => void }> = ({ id, removeHandler }) => {
  const [loading, setLoading] = useState(false);

  const onConfirm = async () => {
    setLoading(true);
    const ok = await GoogleS.removeAccount(id);
    if (ok) {
      removeHandler();
      closeAllModals();
    }
    setLoading(false);
  };

  return (
    <>
      <Text>
        Möchten Sie die Verbindung zu diesem Google-Konto wirklich entfernen? Eine Synchronisierung ist nicht mehr
        möglich.
      </Text>
      <Group position="right" spacing="xs" mt="xl">
        <Button disabled={loading} color="gray" variant="subtle" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          color="red"
          variant="subtle"
          sx={{ backgroundColor: '#FFEFEF' }}
          onClick={onConfirm}
          disabled={loading}
          leftIcon={loading && <Loader size="xs" />}
        >
          {i18n._('actions.delete')}
        </Button>
      </Group>
    </>
  );
};
