import { useEffect } from 'react';
import ReactPixel, { AdvancedMatching, Options } from 'react-facebook-pixel';
import { useJwtClaims } from '../Context/AuthContext';

// eslint-disable-next-line import/prefer-default-export
export const useFacebookPixel = () => {
  const pixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID; // Defined only for the prod
  const { email, licence } = useJwtClaims();

  useEffect(() => {
    if (pixelId) {
      const options: Options = {
        autoConfig: true, // set pixel's autoConfig
        debug: false, // enable logs
      };
      if (!licence || (licence === "INTERACTIVE_TRIAL_30DAYS" || licence === "BASIC")) {
        // only track visitors (without account) or basic/trial users
        ReactPixel.init(pixelId, (email ? { em: email } : {}) as AdvancedMatching, options);
      }
    }
  }, [pixelId, email, licence]);
};
