// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { useContext } from 'react';
import BookitupTable from '../../Atoms/BookitupTable';
import QuestionnaireItem from '../../Molecules/Event/Questionnaires/QuestionnaireItem';
import PanePaper from '../../Molecules/PanePaper';
import { DocumentsContext } from '../../Service/Context/DocumentsContext';
import SortDirectionIcon from '../../Atoms/SortDirectionIcon';

const QuestionnaireList = () => {
  const { ref, width } = useElementSize();
  const { questionnaires, reload, filter, changeFilter } = useContext(DocumentsContext);
  const { direction } = filter;

  const showColumns = {
    showDatum: width > 350,
    showPortal: width >= 230,
    narrowPortalStatus: width < 280,
  };

  return (
    <PanePaper>
      <div ref={ref}>
        <div>
          {questionnaires.length === 0 ? (
            <Text italic color="dimmed" size="xs" weight="lighter">
              Noch nichts hinzugefügt.
            </Text>
          ) : (
            <BookitupTable>
              <thead>
                <tr>
                  <th>Bezeichnung</th>
                  {showColumns.showDatum && (
                    <th
                      style={{ width: 100, fontWeight: 'bolder', cursor: 'pointer' }}
                      onClick={() => changeFilter({ direction: direction === 'ASC' ? 'DESC' : 'ASC' })}
                    >
                      Datum{'  '}
                      <SortDirectionIcon direction={direction} />
                    </th>
                  )}
                  {showColumns.showPortal && (
                    <th style={{ width: showColumns.narrowPortalStatus ? 50 : 120 }}>Portal</th>
                  )}
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {questionnaires.map((q) => (
                  <QuestionnaireItem key={q.id} questionnaire={q} reload={reload} {...showColumns} />
                ))}
              </tbody>
            </BookitupTable>
          )}
        </div>
      </div>
    </PanePaper>
  );
};

export default QuestionnaireList;
