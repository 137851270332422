// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Space, Text } from '@mantine/core';
import PanePaper from '../../../Molecules/PanePaper';

const ThankYou = () => (
  <PanePaper title="Danke, dass Du Dich für bookitup entschieden hast.">
    <Text>
      Mit Deinem Upgrade auf einen bezahlten Plan hast Du Dir Deine Büroarbeit als Eventdienstleister in so vielen
      Bereichen deutlich vereinfacht. Durch Dein Upgrade unterstützt Du zudem unsere Mission, das beste
      Eventverwaltungstool am Markt zu entwickeln.
    </Text>
    <Space h="md" />
    <Text>
      Mein Team und ich wünschen Dir allzeit gute Geschäfte und erfüllende Aufträge, egal ob vor, hinter oder auf der
      Bühne!
    </Text>
    <Space h="md" />
    <div style={{ fontFamily: 'SafiarSignature, Courier', fontSize: 30, textAlign: 'right' }}>Sebastian Mahlke</div>
    <Text mr="xl" color="dimmed" align="right">
      Geschäftsführer
    </Text>
  </PanePaper>
);

export default ThankYou;
