import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Paragraph from '@tiptap/extension-paragraph';
import StarterKit from '@tiptap/starter-kit';
import Document from '@tiptap/extension-document';
import Text from '@tiptap/extension-text';

const Extentions = {
  MINIMAL: [
    Document.extend({
      content: 'block',
    }),
    Text,
    Paragraph,
  ],
  EXTENDED: [
    StarterKit.configure({
      code: false,
      codeBlock: false,
      dropcursor: false,
      horizontalRule: false,
      heading: false,
      paragraph: false,
    }),
    Link.configure({
      openOnClick: false,
      autolink: true,
    }),
    Underline,
    Paragraph.configure({
      HTMLAttributes: {
        style: 'margin-bottom: 1.5em;'
      }
    })
  ],
};

const removeParagraphWrapper = (str: string) => str.replace(/<\/?p>/g, '').replaceAll('&nbsp;', ' ');

export const TiptapS = {
  Extentions,
  removeParagraphWrapper,
};
