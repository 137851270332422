// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { useContext, useEffect } from 'react';
import { useJwtClaims } from '../../../../Service/Context/AuthContext';
import { SemanticFormS } from '../../../../Service/restapi/semanticFormService';
import { BookitupFormContext } from '../../../../Service/Context/BookitupFormContext';
import CodeWithCopy from '../../../../Atoms/CodeWithCopy';

const FormCode = () => {
  const { profileId } = useJwtClaims();
  const { bookitupForm, handleChange } = useContext(BookitupFormContext);
  const { id: formId, outOfSync } = bookitupForm ?? {};

  useEffect(() => {
    if (outOfSync && formId) {
      SemanticFormS.syncFormFiles(formId).then((synced) => {
        if (synced) {
          handleChange({ outOfSync: false });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outOfSync, formId]);

  if (!formId) {
    return null;
  }

  return (
    <>
      <Text weight="bolder" size="lg" mb="md" mt="md">
        iFrame
      </Text>
      <CodeWithCopy content={SemanticFormS.generateIframeCode(formId, profileId)} />
      <Text weight="bolder" size="lg" mb="md" mt="md">
        Script
      </Text>
      <CodeWithCopy content={SemanticFormS.generateJsCode(formId, profileId)} />
    </>
  );
};

export default FormCode;
