// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Skeleton, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { RecommendationDto } from '../../../Types/RecommendationT';
import { RecommendationS } from '../../../Service/RecommendationS';

const RecommendationSource: FC<{ recommendationId: string | null }> = ({ recommendationId }) => {
  const [recommendation, setRecommendation] = useState<RecommendationDto>();

  useEffect(() => {
    if (recommendationId) {
      RecommendationS.fetchById(recommendationId).then(
        (_recommendation) => _recommendation && setRecommendation(_recommendation),
      );
    }
  }, [recommendationId]);

  if (!recommendationId) {
    return null;
  }
  if (!recommendation) {
    return <Skeleton height={50} />;
  }
  const { sourceProfileAlias, lastContactShare, message } = recommendation;

  return (
    <Text size="xs">
      Anfrage am <Moment format="L">{lastContactShare}</Moment>{' '}
      {sourceProfileAlias ? ` von ${sourceProfileAlias} ` : ''} erhalten
      {message && message !== '' && (
        <details style={{ marginTop: 5 }}>
          <summary>Nachricht von {sourceProfileAlias}</summary>
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </details>
      )}
      <hr />
    </Text>
  );
};

export default RecommendationSource;
