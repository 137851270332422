// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Badge, Box, Skeleton, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { EntityReferenceDTO, WorkflowStepTemplateDTO } from '../../../../Types/LogT';
import { WorkflowS } from '../../../../Service/WorkflowS';
import CreateShareConfig from './forms/CreateShareConfig';
import CreateQuestionnaireConfig from './forms/CreateQuestionnaireConfig';
import ShareEntityConfig from './forms/ShareEntityConfig';
import SendMailConfig from './forms/SendMailConfig';
import CreateTaskConfig from './forms/CreateTaskConfig';
import CreateContractConfig from './forms/CreateContractConfig';
import CreateDocumentConfig from './forms/CreateDocumentConfig';
import ChangeEventStatusConfig from './forms/ChangeEventStatusConfig';
import GeneralTaskConfig from './forms/GeneralTaskConfig';
import DeleteTasksConfig from './forms/DeleteTasksConfig';

interface Props {
  step: WorkflowStepTemplateDTO;
  availableEntities: EntityReferenceDTO[];
  handleConfigChange?: (cfg: any) => unknown;
}

const ProcedureConfig: FC<Props> = ({ step, availableEntities, handleConfigChange }) => {
  const { configId, procedureType } = step;
  const [config, setConfig] = useState<unknown>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (configId && procedureType) {
      fetchConfig();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configId, procedureType]);

  const fetchConfig = async () => {
    setLoading(true);
    const resJSON = await WorkflowS.fetchProcedureConfig(procedureType, configId);
    if (resJSON) {
      setConfig(resJSON);
    }
    setLoading(false);
  };

  const updateConfig = async (patch: unknown) => {
    const resJSON = await WorkflowS.patchProcedureConfig(procedureType, configId, patch);
    if (resJSON) {
      setConfig(resJSON);
      if (handleConfigChange) {
        handleConfigChange(resJSON);
      }
    }
  };

  if (loading) {
    return <Skeleton mt="xl" height={150} />;
  }

  if (!config) {
    return null;
  }

  return (
    <>
      <Badge mt="xl" radius="sm" sx={{ backgroundColor: '#585858', color: 'white' }}>
        <Text weight="lighter"> Grundeinstellungen</Text>
      </Badge>
      <Box mt="sm">
        {(procedureType === 'DELETE_TASK' || procedureType === 'COMPLETE_TASK') && (
          <GeneralTaskConfig config={config} handleChange={updateConfig} availableEntities={availableEntities} />
        )}
        {procedureType === 'DELETE_TASKS' && <DeleteTasksConfig config={config} handleChange={updateConfig} />}
        {procedureType === 'CHANGE_EVENT_STATUS' && (
          <ChangeEventStatusConfig config={config} handleChange={updateConfig} />
        )}
        {procedureType === 'SEND_MAIL' && (
          <SendMailConfig config={config} handleChange={updateConfig} availableEntities={availableEntities} />
        )}
        {procedureType === 'CREATE_DOCUMENT' && (
          <CreateDocumentConfig config={config} handleChange={updateConfig} availableEntities={availableEntities} />
        )}
        {procedureType === 'CREATE_TASK' && <CreateTaskConfig config={config} handleChange={updateConfig} />}
        {procedureType === 'CREATE_CONTRACT' && (
          <CreateContractConfig config={config} handleChange={updateConfig} availableEntities={availableEntities} />
        )}
        {procedureType === 'CREATE_SHARE' && (
          <CreateShareConfig config={config} handleChange={updateConfig} availableEntities={availableEntities} />
        )}
        {procedureType === 'CREATE_QUESTIONNAIRE' && (
          <CreateQuestionnaireConfig config={config} handleChange={updateConfig} />
        )}
        {procedureType === 'SHARE_ENTITY' && (
          <ShareEntityConfig config={config} handleChange={updateConfig} availableEntities={availableEntities} />
        )}
      </Box>
    </>
  );
};

export default ProcedureConfig;
