/* eslint-disable react/jsx-no-useless-fragment */
import { AppShell, Header, Navbar } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { useContext } from 'react';
import PrimaryNavbar from '../../Molecules/PrimaryNavbar';
import { WindowContext } from '../../Service/Context/WindowContext';
import PrimaryHeader from '../../Templates/Navigation/PrimaryHeader';
import AcademyHeader from './AcademyHeader';

const AcademyPage = () => {
  const { sm } = useContext(WindowContext);
  return (
    <AppShell
      padding="md"
      navbar={
        <>
          {!sm && (
            <Navbar width={{ base: 60 }} sx={{ boxShadow: '2px 0px 10px #0000001A', border: 'none' }}>
              <PrimaryNavbar />
            </Navbar>
          )}
        </>
      }
      header={
        <>
          <Header height={sm ? 90 : 40}>
            {sm && <PrimaryHeader />}
            <AcademyHeader />
          </Header>
        </>
      }
      styles={(theme) => ({
        main: { backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0] },
      })}
    >
      <Outlet />
    </AppShell>
  );
};

export default AcademyPage;
