// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';

interface Props {
  active: boolean;
  toggle: () => void;
  text: string;
}

const ToggleText: FC<Props> = ({ active, toggle, text }) => (
  <Text
    color={active ? 'blue' : 'inherit'}
    onClick={toggle}
    weight={active ? 'bolder' : 'normal'}
    p="md"
    pt={5}
    pb={5}
    sx={(theme) => ({
      cursor: 'pointer',
      borderRadius: 15,
      backgroundColor: active ? theme.colors.lightBlue[0] : 'inherit',
    })}
  >
    {text}
  </Text>
);

export default ToggleText;
