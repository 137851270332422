import React, { useContext } from 'react';
import { Pane } from '../Pane';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { SimpleEditableText } from '../../Atoms/inputs/SimpleEditableText';
import { StringFormatter } from '../../Atoms/Formatter/StringFormatter';
import { EditableProfileProperty } from '../../Atoms/EditableProperties/EditableProfileProperty';
import PanePaper from '../PanePaper';

export const WebPresentationSettings = () => {
  const { profile } = useContext(ProfileContext);

  const styles = {
    websiteContainer: {
      maxWidth: 300,
      minWidth: 250,
      margin: 5,
    },
    website: {
      flex: 1,
      margin: 5,
    },
  };

  return (
    <PanePaper title="common.settings.websites">
      <div>
        <div style={styles.website}>
          <EditableProfileProperty
            patchField="addressOfWebsite"
            profile={profile}
            placeholder="applicationuser.website"
            editFormatter={SimpleEditableText}
            labelFormatter={StringFormatter}
            style={{ overflow: 'hidden' }}
          />
        </div>
        <div style={styles.website}>
          <EditableProfileProperty
            patchField="addressOfFacebook"
            profile={profile}
            placeholder="applicationuser.facebook"
            editFormatter={SimpleEditableText}
            labelFormatter={StringFormatter}
          />
        </div>
        <div style={styles.website}>
          <EditableProfileProperty
            patchField="addressOfInstagram"
            profile={profile}
            placeholder="applicationuser.instagram"
            editFormatter={SimpleEditableText}
            labelFormatter={StringFormatter}
          />
        </div>
      </div>
    </PanePaper>
  );
};
