import { useEffect } from 'react';
import LogRocket from 'logrocket';
import { useJwtClaims } from '../Context/AuthContext';
import { LicenceS } from '../LicenceS';

// eslint-disable-next-line import/prefer-default-export
export const useRocketLog = () => {
  const appId = process.env.REACT_APP_LOG_ROCKET_APP_ID; // Defined only for the prod
  const { email, licence, profileId } = useJwtClaims();
  const trialLicence = LicenceS.inTrialPeriod(licence);

  useEffect(() => {
    if (appId && trialLicence) {
      LogRocket.init(appId);
    }
  }, [appId, trialLicence]);

  useEffect(() => {
    if (appId && trialLicence) {
      LogRocket.identify(profileId.toString(), { email, licence });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appId, trialLicence, profileId, email]);
};
