/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, LoadingOverlay, Paper, PasswordInput, Space, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { useSearchParams, useNavigate, NavigateFunction } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { IconLock } from '@tabler/icons';
import { useForm } from '@mantine/form';
import { AuthS } from '../../Service/restapi/authService';
import { AlertS } from '../../Service/AlertS';
import { RoutingS } from '../../Service/RoutingS';
import AlertBox from './AlertBox';
import PasswordChecker from './PasswordChecker';

const ConfirmInvitationPage: FC = () => {
  const isPhoneDevice = useMediaQuery('(max-width: 480px)');
  const navigate: NavigateFunction = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [validPassword, setValidPassword] = useState<boolean>(false);
  const [alert, setAlert] = useState<string | null>(searchParams.get('alert'));
  const token: string | null = searchParams.get('token');

  const form = useForm({
    initialValues: {
      password: '',
      repeatedPassword: '',
    },
    validate: {
      password: () => (validPassword ? null : 'Bitte geben Sie ein gültiges Passwort ein'),
      repeatedPassword: (value, values) => (value === values.password ? null : 'Passwörter stimmen nicht überein'),
    },
  });

  useEffect(() => {
    document.title = 'Anmelden';
    if (token) {
      AuthS.verifyToken(AuthS.TOKEN_TYPE.SHARE_PROFILE, token)
        .then((response) => {
          if (!response.ok) {
            setAlert(AlertS.AlertType.LINK_EXPIRED);
          }
        })
        .catch(() => {
          setAlert(AlertS.AlertType.SERVER_UNAVAILABLE);
        })
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    const { password } = form.values;
    AuthS.confirmInvitation(token, password)
      .then((resp) => {
        if (resp.ok) {
          navigate(`${RoutingS.ROUTES.LOGIN}?alert=${AlertS.AlertType.REGISTRATION_COMPLETED}`);
        }
      })
      .catch(() => setAlert(AlertS.AlertType.SERVER_UNAVAILABLE))
      .finally(() => setLoading(false));
  };

  return (
    <Paper
      shadow="xs"
      p={40}
      sx={{
        maxWidth: 480,
        borderRadius: 10,
        position: 'relative',
        backgroundClip: 'padding-box, border-box',
        backgroundImage: 'linear-gradient(#fff,#fff),linear-gradient(88deg,#ffba5e,#ff6b69)',
        borderTop: '10px double transparent',
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Space h="md" />
      <Text size={isPhoneDevice ? 22 : 28}>Willkommen!</Text>
      <Space h="xs" />
      <Text color="dimmed" size={isPhoneDevice ? 'sm' : 'md'}>
        Erstell Dein kostenfreies bookitup Konto um loszulegen.
      </Text>
      <Space h="md" />
      {alert && <AlertBox alert={alert} />}
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <PasswordInput
          label={<Text size="xs">Passwort</Text>}
          icon={<IconLock size={16} />}
          {...form.getInputProps('password')}
        />
        <Space h="xs" />
        <PasswordChecker
          password={form.values.password}
          showErrors={Object.keys(form.errors).length > 0}
          setValidPassword={setValidPassword}
        />
        <Space h="xs" />
        <PasswordInput
          label={<Text size="xs">Passwort wiederholen</Text>}
          icon={<IconLock size={16} />}
          {...form.getInputProps('repeatedPassword')}
        />
        <Button fullWidth mt={40} type="submit">
          kostenlos registrieren
        </Button>
      </form>
    </Paper>
  );
};

export default ConfirmInvitationPage;
