import React, { useContext, useEffect, useState } from 'react';
import { TransTextInputField } from '../Atoms/InputFields';
import { WindowContext } from '../Service/Context/WindowContext';
import { ContactTypeButtonGroup } from '../Atoms/ButtonGroup';
import { TransButtonLink } from '../Atoms/Buttons';
import Autocomplete from '../Atoms/Autocomplete';
import { ContactRelationList } from './Contact/ContactRelationList';
import ContactGroupSelection from './Contact/ContactGroupSelection';

export const ContactCreateForm = ({ hideEmail, hideAddress, contact, changeHandler, onBlur, contactType }) => {
  const { windowWidth } = useContext(WindowContext);
  const [showPhoneAlt, setShowPhoneAlt] = useState(contact.phoneNumberAlt);

  useEffect(() => {
    setShowPhoneAlt(contact.phoneNumberAlt);
  }, [contact]);

  if (!contact) {
    contact = {};
  }

  const styles = {
    group: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 10,
    },
    salutation: {
      marginRight: windowWidth > 445 ? 5 : 'unset',
      flex: 1,
      minWidth: 100,
      maxWidth: 125,
    },
    firstName: {
      marginRight: windowWidth > 445 ? 5 : 'unset',
      flex: 1,
      minWidth: windowWidth < 800 ? 200 : 'unset',
    },
    lastName: {
      flex: 1,
      minWidth: windowWidth < 800 ? 200 : 'unset',
    },
    zipCode: {
      marginRight: windowWidth > 349 ? 5 : 'unset',
      flex: 1,
      minWidth: windowWidth < 800 ? 100 : 'unset',
      maxWidth: windowWidth > 354 ? 100 : 'unset',
    },
    city: {
      flex: 1,
      minWidth: windowWidth < 800 ? 200 : 'unset',
    },
    phoneNumber: {
      marginRight: windowWidth > 445 ? 5 : 'unset',
      flex: 1,
      minWidth: windowWidth < 800 ? 200 : 'unset',
    },
    email: {
      flex: 1,
      minWidth: windowWidth < 800 ? 200 : 'unset',
    },
    contactType: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: 10,
    },
    mainContact: {
      marginTop: 20,
      marginBottom: 10,
      color: '#9499a7',
    },
  };

  const salutations = [
    { label: 'Herr', salutation: 'Herr' },
    { label: 'Frau', salutation: 'Frau' },
  ];

  return (
    <div>
      {!contactType && (
        <div style={styles.contactType}>
          <ContactTypeButtonGroup
            value={contact.contactType}
            onChange={(type) =>
              changeHandler({
                target: {
                  name: 'contactType',
                  value: type,
                },
              })
            }
          />
        </div>
      )}

      {contact.contactType === 'COMPANY' && (
        <div>
          <TransTextInputField
            onChange={changeHandler}
            onBlur={onBlur}
            name="companyName"
            value={contact.companyName}
            placeholder="Firma"
          />
        </div>
      )}

      {contact.contactType !== 'COMPANY' && (
        <div style={styles.group}>
          <div style={styles.salutation}>
            <Autocomplete
              suggestions={salutations}
              placeholder="contact.salutation"
              patchField="salutation"
              value={contact.salutation}
              onChange={(patch) => {
                changeHandler({ target: { name: 'salutation', value: patch.salutation } });
              }}
              saveValue={(patch) =>
                changeHandler({
                  target: {
                    name: 'salutation',
                    value: patch.salutation,
                  },
                })
              }
            />
          </div>
          <div style={styles.firstName}>
            <TransTextInputField
              onChange={changeHandler}
              onBlur={onBlur}
              name="firstName"
              value={contact.firstName}
              placeholder="contact.firstName"
            />
          </div>
          <div style={styles.lastName}>
            <TransTextInputField
              onChange={changeHandler}
              onBlur={onBlur}
              name="lastName"
              value={contact.lastName}
              placeholder="contact.lastName"
            />
          </div>
        </div>
      )}
      {!hideAddress && !contactType && (
        <div>
          <TransTextInputField
            onChange={changeHandler}
            onBlur={onBlur}
            name="addressStreetAndNo"
            value={contact.addressStreetAndNo}
            placeholder="contact.streetAndNo"
          />
        </div>
      )}

      <div style={styles.group}>
        {!hideAddress && !contactType && (
          <div style={styles.zipCode}>
            <TransTextInputField
              onChange={changeHandler}
              onBlur={onBlur}
              name="zipCode"
              value={contact.zipCode}
              placeholder="contact.zipCode"
            />
          </div>
        )}

        {!hideAddress && !contactType && (
          <div style={styles.city}>
            <TransTextInputField
              onChange={changeHandler}
              onBlur={onBlur}
              name="city"
              value={contact.city}
              placeholder="contact.city"
            />
          </div>
        )}
      </div>

      {contact.contactType === 'COMPANY' && <div style={styles.mainContact}>Haupt-Ansprechpartner:</div>}
      {contact.contactType === 'COMPANY' && (
        <div style={styles.group}>
          <div style={styles.firstName}>
            <TransTextInputField
              onChange={changeHandler}
              onBlur={onBlur}
              name="firstName"
              value={contact.firstName}
              placeholder="contact.firstName"
            />
          </div>
          <div style={styles.lastName}>
            <TransTextInputField
              onChange={changeHandler}
              onBlur={onBlur}
              name="lastName"
              value={contact.lastName}
              placeholder="contact.lastName"
            />
          </div>
        </div>
      )}

      <div style={styles.group}>
        <div style={styles.phoneNumber}>
          <TransTextInputField
            onChange={changeHandler}
            onBlur={onBlur}
            name="phoneNumber"
            value={contact.phoneNumber}
            placeholder="contact.phoneNumber"
          />
        </div>
        {!hideEmail && (
          <div style={styles.email}>
            <TransTextInputField
              onChange={changeHandler}
              onBlur={onBlur}
              name="email"
              value={contact.email}
              placeholder="contact.email"
            />
          </div>
        )}
      </div>
      <div style={styles.group}>
        {showPhoneAlt && (
          <div style={styles.phoneNumber}>
            <TransTextInputField
              onChange={changeHandler}
              onBlur={onBlur}
              name="phoneNumberAlt"
              value={contact.phoneNumberAlt}
              placeholder="contact.phoneNumberAlt"
            />
          </div>
        )}
        {!showPhoneAlt && <TransButtonLink onClick={() => setShowPhoneAlt(true)} text="+ Telefonnummer hinzufügen" />}
        <div style={styles.phoneNumber} />
      </div>

      <ContactGroupSelection
        selectedGroups={contact.groups}
        saveValue={(p) => {
          changeHandler({ target: { name: 'groups', value: p } });
        }}
      />

      {contact.id && contact.contactType === 'COMPANY' && <hr />}
      {contact.id && contact.contactType === 'COMPANY' && (
        <div style={{ marginBottom: 10 }}>
          <b>Ansprechpartner</b>
        </div>
      )}
      {contact.id && contact.contactType === 'COMPANY' && <ContactRelationList contactId={contact.id} />}
      {/* <div>
                <Autocomplete suggestions={[]} patchField="group" value={contact.group}
                              onChange={(patch) => {
                                  changeHandler({group: patch["group"]})
                              }}
                              saveValue={(patch) => {
                                  changeHandler({group: patch["group"]})
                              }} placeholder={"Kontakt-Gruppe"}
                              labelStyle={{color: "#9ca6af", fontWeight: "unset"}}
                />
            </div> */}

      {/* contact.id &&
                <>
                    <CustomAttributeList entityId={contact.id} entityType={"contact"} lastModified={lastModified}/>
                    <CustomAttributeCreateModal entityType={"contact"} toggle={toggle} show={show} finish={() => {
                        setLastModified(new Date())
                    }}/>

                    <CheckLicence buttonText={"custom-attribute.add"} onClick={toggle} id={"newCustomAttribute"}
                                  licence={"PRO"} upgradeTitle={"Lege eigene Felder an die für Dich wichtig sind."}
                                  upgradeText={
                                      "Mit erweiterbaren Feldern hast Du schnell im Blick, welche Informationen Dir fehlen.\n" +
                                      "Zusätzlich kannst Du ein Feld z.B. nur für Hochzeitsbuchungen hinterlegen \n" +
                                      "oder sie mit Deinem Formular auf Deiner Webseite verknüpfen."
                                  }/>
                </>
            */}
    </div>
  );
};
