// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Skeleton, Text } from '@mantine/core';
import { FC } from 'react';

const EmailBoxCounter: FC<{ count: number | null | undefined }> = ({ count }) => {
  if (count === undefined) {
    return <Skeleton height={20} circle />;
  }
  return (
    <Text mr={5} size="xs" color="dimmed" align="center">
      {count ?? 0}
    </Text>
  );
};

export const EmailBoxCounterWithUnread: FC<{
  count: number | null | undefined;
  unreadCount: number | null | undefined;
}> = ({ count, unreadCount }) => {
  if (count === undefined || unreadCount === undefined) {
    return <Skeleton height={20} circle />;
  }
  return (
    <Text mr={5} size="xs" color="dimmed" align="center">
      {(unreadCount ?? 0) > 0 && (
        <Text span color="blue" weight="bold" sx={{marginRight: 4}}>
          {`(${unreadCount})`}
        </Text>
      )}
      {count ?? 0}
    </Text>
  );
};

export default EmailBoxCounter;
