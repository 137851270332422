// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Center, Text, Button } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { FC } from 'react';
import SubscriptionS from '../../../Service/restapi/subscriptionService';
import { ToastS } from '../../../Service/ToastS';

const CancelSubscriptionButton: FC<{ onCancel: () => void }> = ({ onCancel }) => {
  const confirmCancellation = () => {
    openConfirmModal({
      size: 'md',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Plan stornieren
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      labels: { confirm: 'Ja', cancel: 'Abbrechen' },
      children: (
        <>
          <Text>
            Dein Abonnement wird sofort gekündigt. Du verlierst damit den Zugriff auf alle wichtigen Premium Funktionen,
            sobald der aktuelle Abrechnungszeitraum beendet ist. Nach Deinem Downgrade auf den Starter Plan behälst Du den
            Zugriff auf Deine Daten.
          </Text>
          <Text mt="md">Möchten Sie Ihren Plan wirklich kündigen?</Text>
        </>
      ),
      onConfirm: () => cancelSubscription(),
    });
  };

  const cancelSubscription = () => {
    SubscriptionS.cancelSubscription(null).then((success) => {
      if (success) {
        onCancel();
      } else {
        ToastS.generalError();
      }
    });
  };

  return (
    <Center mt="xl">
      <Button color="red" variant="subtle" sx={{ backgroundColor: '#FFEFEF' }} onClick={confirmCancellation}>
        Jetzt kündigen
      </Button>
    </Center>
  );
};

export default CancelSubscriptionButton;
