import React, { useContext, useEffect, useState } from 'react';
import { fetchRelatedContacts } from '../../Service/restapi/contactRelationService';
import ContactEditModal from '../ContactEditModal';
import ContactListItemDescription from './ContactListItemDescription';

export default function ({ contactId, lastModified }) {
  const [relationContacts, setRelationContacts] = useState(undefined);
  const [editingContact, setEditingContact] = useState(false);
  const [contactLastModified, setContactLastModified] = useState(undefined);

  useEffect(() => {
    if (contactId) {
      fetchRelatedContacts(contactId)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((newRelatedContacts) => {
              setRelationContacts(newRelatedContacts);
            });
          } else {
            console.error(`could not load relatedContacts of contact ${contactId}`);
          }
        })
        .catch((error) => {
          console.error(`could not load relatedContacts of contact ${contactId}`);
          console.error({ error });
        });
    }
  }, [contactId, lastModified]);

  if (!relationContacts || relationContacts.length === 0) {
    return null;
  }

  return (
    <div style={{ marginTop: 10, marginBottom: 10 }}>
      {relationContacts.map((relationContact, index) => (
        <>
          <ContactListItemDescription
            key={index}
            onEdit={() => {
              setEditingContact(true);
            }}
            lastModified={contactLastModified}
            contactId={relationContact.relatedContactId}
            description={relationContact.description}
            addressField={false}
          />

          {editingContact && (
            <ContactEditModal
              setLastModified={setContactLastModified}
              contactId={relationContact.relatedContactId}
              onFinish={() => {
                setEditingContact(false);
              }}
              close={() => {
                setEditingContact(false);
              }}
            />
          )}
        </>
      ))}
    </div>
  );
}
