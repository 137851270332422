import React, { useContext } from 'react';
import { ProfileContext } from '../Service/Context/ProfileContext';
import { EditableProfileProperty } from '../Atoms/EditableProperties/EditableProfileProperty';
import { SimpleEditableText } from '../Atoms/inputs/SimpleEditableText';
import { StringFormatter } from '../Atoms/Formatter/StringFormatter';
import { EuroFormatter } from '../Atoms/Formatter/EuroFormatter';
import { Pane } from './Pane';
import PanePaper from './PanePaper';

export const FinancialForm = () => {
  const { profile } = useContext(ProfileContext);

  const styles = {
    container: {
      maxWidth: 300,
      minWidth: 250,
      margin: 5,
    },
    field: {
      flex: 1,
      maxWidth: 300,
    },
  };

  return (
    <PanePaper
      title="common.settings.finance">
        <div>
          <div style={styles.field}>
            <EditableProfileProperty
              patchField="monthlyTurnover"
              profile={profile}
              placeholder="applicationuser.monthlyturnover"
              editFormatter={SimpleEditableText}
              labelFormatter={EuroFormatter}
            />
          </div>
          <div style={styles.field}>
            <EditableProfileProperty
              patchField="taxOffice"
              profile={profile}
              placeholder="applicationuser.taxoffice"
              editFormatter={SimpleEditableText}
              labelFormatter={StringFormatter}
            />
          </div>
          <div style={styles.field}>
            <EditableProfileProperty
              patchField="taxNumber"
              profile={profile}
              placeholder="applicationuser.taxnumber"
              editFormatter={SimpleEditableText}
              labelFormatter={StringFormatter}
            />
          </div>
          <div style={styles.field}>
            <EditableProfileProperty
              patchField="vatId"
              profile={profile}
              placeholder="applicationuser.vatid"
              editFormatter={SimpleEditableText}
              labelFormatter={StringFormatter}
            />
          </div>
        </div>
    </PanePaper>
  );
};
