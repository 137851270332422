import React, { FC, useContext, useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { ContextModalProps, useModals } from '@mantine/modals';
import { ModalFlow } from '../../../Molecules/Dialogs';
import { createRecurringTask } from '../../../Service/restapi/taskService';
import { ModalFlowStepItem } from '../../../Atoms/Stepper/MenuItem';
import TimeState from '../../../Types/Enums/TimeState';
import RecurringTaskFlow1 from './RecurringTaskFlow-1';
import RecurringTaskFlow2 from './RecurringTaskFlow-2';

const RecurringTaskModalFlow = ({ context, id, innerProps }: ContextModalProps) => {
  const [error, setError] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState(undefined);
  const [notes, setNotes] = useState(undefined);
  const [days, setDays] = useState(undefined);
  const [timeState, setTimeState] = useState(TimeState.PAST_OPEN);
  const [taskGroupName, setTaskGroupName] = useState('Standard');
  const [priority, setPriority] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [canBeFinished, setCanBeFinished] = useState(true);
  const { closeAll } = useModals();

  const finish = () => {
    if (!days || !timeState) {
      setError('Gib an, bis wann die Aufgabe zu erledigen ist.');
    } else {
      setLoading(true);
      createRecurringTask({ taskGroupName, description, notes, priority, days, timeState })
        .then((resp) => {
          if (resp.ok) {
            closeAll();
          } else {
            console.error('could not create recurringTask');
          }
        })
        .catch((error) => {
          console.error({ error });
          console.error('could not create recurringTask');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const stepBodyComponents = [
    <RecurringTaskFlow1
      key={0}
      description={description}
      setDescription={setDescription}
      notes={notes}
      setNotes={setNotes}
      groupName={taskGroupName}
      setGroupName={setTaskGroupName}
      priority={priority}
      setPriority={setPriority}
    />,
    <RecurringTaskFlow2 key={1} days={days} setDays={setDays} timeState={timeState} setTimeState={setTimeState} />,
  ];

  const menuItems: ModalFlowStepItem[] = [
    {
      icon: 'EDIT',
      label: 'Beschreibung',
      description: 'Beschreibe was zu tun ist',
      validator: () => {
        const notValid = !taskGroupName || !description;
        if (notValid) {
          setError('Gruppenname und Beschreibung erforderlich');
        } else {
          setError(undefined);
        }
        return !notValid;
      },
    },
    {
      icon: 'CALENDAR',
      label: 'Fälligkeit',
      description: 'Wann steht diese Aufgabe an?',
    },
  ];

  return (
    <ModalFlow
      menuItems={menuItems}
      stepBodyComponents={stepBodyComponents}
      error={error}
      isLoading={isLoading}
      canBeFinished={canBeFinished}
      finish={finish}
      finishButtonText="actions.save"
    />
  );
};

export default RecurringTaskModalFlow;
