import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EditableProperty } from './EditableProperty';

export const EditableQuestionBundleProperty = ({
  entity,
  preChange,
  changeHandler,
  patchField,
  placeholder,
  editFormatter,
  labelFormatter,
  name,
  ...rest
}) => (
  <EditableProperty
    dto={entity}
    preChange={preChange}
    changeHandler={changeHandler}
    patchField={patchField}
    placeholder={placeholder}
    targetUrl={`${process.env.REACT_APP_QUESTIONNAIRE_SERVICE_URL}/questionBundles/${entity.id}`}
    editFormatter={editFormatter}
    labelFormatter={labelFormatter}
    name={name}
    {...rest}
  />
);

EditableQuestionBundleProperty.propTypes = {
  patchField: PropTypes.string.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    lastModified: PropTypes.string.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  preChange: PropTypes.func,
  changeHandler: PropTypes.func,
};
