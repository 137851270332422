/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import {Alert, Button, Center, Checkbox, Loader, Skeleton, Space, Text} from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { closeAllModals, openModal } from '@mantine/modals';
import { i18n } from '@lingui/core';
import { CustomAttributeDTO, CustomAttributeWithGroupNameDTO } from '../../Types/CustomAttributeT';
import { CustomAttributeS } from '../../Service/Context/CustomAttributeS';
import BookitupSelect from '../../Atoms/BookitupSelect';
import { DataS } from '../../Service/DataS';
import { QuestionnaireS } from '../../Service/QuestionnaireS';
import { QuestionDto } from '../../Types/QuestionnaireT';
import AutocompleteCustomAttributeItem from '../../Atoms/Autocomplete/AutocompleteCustomAttributeItem';
import { MixpanelS } from '../../Service/MixpanelS';
import { ConstantS } from '../../Service/ConstantS';
import {IconInfoCircle} from "@tabler/icons";

interface Props {
  currentAttribute: CustomAttributeDTO | null;
  handleChange: (patch: Partial<CustomAttributeDTO>) => void;
  question: QuestionDto;
}

// eslint-disable-next-line import/prefer-default-export
export const openQuestionAttributeModal = (props: Props) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Eigenes Feld verknüpfen
      </Text>
    ),
    closeOnEscape: true,
    transition: 'slide-down',
    children: <QuestionCustomAttributeModal {...props} />,
  });

const QuestionCustomAttributeModal: FC<Props> = ({ currentAttribute, handleChange, question }) => {
  const { id: questionId, attributeType, prefillAttributeValueAsAnswer } = question;
  const [loading, setLoading] = useState(false);
  const [loadingAlternatives, setLoadingAlternatives] = useState(false);
  const [prefillAttributeValue, setPrefillAttributeValue] = useState(prefillAttributeValueAsAnswer);
  const [suitableAttributes, setSuitableAttributes] = useState<CustomAttributeWithGroupNameDTO[]>([]);
  const initialAttributeName = currentAttribute ? currentAttribute.name : null;
  const [selectedAttributeName, selectAttributeName] = useState<string | null>(initialAttributeName);
  const prefillSupported = attributeType !== 'SINGLE_CHOICE';

  useEffect(() => {
    setLoadingAlternatives(true);
    CustomAttributeS.fetchAttributesByType(attributeType)
      .then(setSuitableAttributes)
      .finally(() => setLoadingAlternatives(false));
  }, [attributeType]);

  const onSave = () => {
    const getSelectedAttributeId = () => {
      const attribute = suitableAttributes.find((att) => att.name === selectedAttributeName);
      return attribute ? attribute.id : null;
    };
    setLoading(true);
    QuestionnaireS.editQuestion(questionId, {
      targetAttributeId: getSelectedAttributeId(),
      prefillAttributeValueAsAnswer: prefillAttributeValue,
    })
      .then((_question) => {
        if (_question) {
          if (_question.targetAttributeId) {
            MixpanelS.track(ConstantS.TrackingEvents.AnswerConnectedWithAttribute);
          }
          handleChange(_question);
          closeAllModals();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Text>Automatisieren Sie Ihren Fragebogen und ordnen Sie die Antworten des Kunden Ihren eigenen Feldern zu.</Text>
      <Space h="sm" />
      <Alert
        mb="sm"
        icon={<IconInfoCircle />}
        title="Findest Du ein Feld nicht?"
        sx={(theme) => ({ maxWidth: 800, backgroundColor: theme.colors.lightBlue[0] })}
      >
        <Text>Dann liegt es daran, dass der Typ vom eigenen Feld (z.B. Ganze-Zahl) nicht mit dem Typ der Frage übereinstimmt.</Text>
      </Alert>
      {loadingAlternatives ? (
        <Skeleton mt="md" height={40} width="100%" />
      ) : (
        <>
          <BookitupSelect
            mt="md"
            label="Eigenes Feld"
            data={DataS.getCustomAttributeOptions(suitableAttributes)}
            value={selectedAttributeName}
            onChange={(val) => val && selectAttributeName(val)}
            itemComponent={AutocompleteCustomAttributeItem}
          />
          {selectedAttributeName && (
            <Text
              size="xs"
              variant="link"
              color="red"
              ml="xs"
              mt={5}
              onClick={() => selectAttributeName(null)}
              sx={{ cursor: 'pointer' }}
            >
              - Verknüpfung entfernen
            </Text>
          )}
        </>
      )}
      {selectedAttributeName && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {prefillSupported ? (
            <Checkbox
              mt="md"
              label="Fragebogen-Antwort mit Wert aus eigenem Feld vorbefüllen."
              checked={prefillAttributeValue}
              onChange={() => setPrefillAttributeValue(!prefillAttributeValue)}
            />
          ) : (
            <Text mt="md" color="dimmed" size="xs">
              Hinweis: Das Vorbefüllen der Antwort ist für diesen Fragetyp derzeit nicht möglich. Bitte kontaktieren
              unseren Support, falls Du diese Funktion benötigst.
            </Text>
          )}
        </>
      )}
      <Center mt="xl">
        <Button
          disabled={
            loading ||
            (initialAttributeName === selectedAttributeName && prefillAttributeValueAsAnswer === prefillAttributeValue)
          }
          sx={{ minWidth: 200 }}
          onClick={onSave}
          rightIcon={loading && <Loader size="xs" />}
        >
          {i18n._('actions.save')}
        </Button>
      </Center>
      <Text mt="xs" align="center" size="xs" color="dimmed">
        Die Kundenantwort wird nicht automatisch zugeordnet
      </Text>
    </>
  );
};
