// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addDays } from 'date-fns';
import PanePaper from '../../../Molecules/PanePaper';
import ResourcePlanningS from '../../../Service/restapi/resourcePlanningS';
import BookitupTable from '../../../Atoms/BookitupTable';
import { ResourcePlanningDTO } from '../../../Types/ResourceT';
import ResourcePlanningItem from './ResoucePlanningItem';

const ResoucePlannings = () => {
  const { id: resouceId } = useParams();
  const [plannings, setPlannings] = useState<ResourcePlanningDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [begin, setBegin] = useState(new Date());
  const [end, setEnd] = useState(addDays(begin, 180));
  const isEmpty = plannings.length === 0;

  useEffect(() => {
    fetchPlannings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resouceId, begin, end]);

  const fetchPlannings = async () => {
    if (resouceId) {
      setLoading(true);
      const resJSON = await ResourcePlanningS.getBookings(resouceId, null, null);
      if (resJSON) {
        setPlannings(resJSON);
      }
      setLoading(false);
    }
  };

  return (
    <Stack>
      <PanePaper title="Planung">
        {!loading && isEmpty && (
          <Text italic color="dimmed" size="xs" weight="lighter">
            Noch nichts hinzugefügt.
          </Text>
        )}
        {!isEmpty && (
          <BookitupTable>
            <thead>
              <tr>
                <th style={{ width: 30 }}> </th>
                <th style={{ width: 290 }}>Zeitraum </th>
                <th style={{ width: 60 }}>Menge</th>
                <th>Ereignis</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {plannings.map((planning) => (
                <ResourcePlanningItem key={planning.id} resoucePlanning={planning} refresh={fetchPlannings} />
              ))}
            </tbody>
          </BookitupTable>
        )}
      </PanePaper>
    </Stack>
  );
};

export default ResoucePlannings;
