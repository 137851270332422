// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Button, Center, Checkbox, Stack, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { closeAllModals, openModal } from '@mantine/modals';
import { IconPlayerPlay, IconTemplate } from '@tabler/icons';
import { WorkflowS } from '../../../Service/WorkflowS';
import { WorkflowTemplateDTO } from '../../../Types/LogT';
import SkeletonItems from '../../../Atoms/SkeletonItems';

export const openAddWorkflowModal = (refresh: () => void, eventId: string) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Workflow hinzufügen
      </Text>
    ),
    children: <AddWorkflowModal eventId={eventId} />,
    onClose: refresh,
  });
};

const AddWorkflowModal: FC<{ eventId: string }> = ({ eventId }) => {
  const [templates, setTemplates] = useState<WorkflowTemplateDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingTemplate, setLoadingTemplates] = useState(false);
  const [selectedTemplates, setSelectedTemplates] = useState<string[]>([]);
  const [showAvailabilityOption, setShowAvailabilityOption] = useState(false);
  const [available, setAvailable] = useState(true);

  useEffect(() => {
    const included = isAlternativeSequenceIncluded();
    setShowAvailabilityOption(included);
    if (!included && !available) {
      setAvailable(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplates]);

  useEffect(() => {
    setLoadingTemplates(true);
    WorkflowS.fetchRunnableTemplates(eventId)
      .then(setTemplates)
      .finally(() => setLoadingTemplates(false));
  }, [eventId]);

  const isAlternativeSequenceIncluded = () => {
    if (selectedTemplates.length > 0 && templates.length > 0) {
      return (
        templates.find((template) => selectedTemplates.includes(template.id) && template.alternativeSteps !== null) !==
        undefined
      );
    }
    return false;
  };

  const toggle = (id: string) => {
    if (selectedTemplates.includes(id)) {
      setSelectedTemplates(selectedTemplates.filter((_id) => _id !== id));
    } else {
      setSelectedTemplates([...selectedTemplates, id]);
    }
  };

  const onSubmit = () => {
    if (eventId) {
      setLoading(true);
      WorkflowS.createWorkflow(eventId, selectedTemplates, available)
        .then((success) => {
          if (success) {
            closeAllModals();
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const mapItemUI = (template: WorkflowTemplateDTO) => {
    const { id, name } = template;
    return (
      <Checkbox key={id} defaultChecked={selectedTemplates.includes(id)} onChange={() => toggle(id)} label={name} />
    );
  };

  return (
    <>
      <Alert
        mb="sm"
        icon={<IconTemplate />}
        title="Workflow-Vorlage"
        sx={(theme) => ({
          backgroundColor: theme.colors.lightBlue[0],
        })}
      >
        <Text>
          Eine bestimmte Workflow-Vorlage kann nur einmal pro Event gleichzeitig werden. Wenn du einen Workflow noch
          einmal neu starten möchtest, entferne zunächst den alten Workflow aus dem Event.
        </Text>
      </Alert>
      <Text>Wähle jetzt den passenden Workflow aus und führe ihn aus. Verfügbare Workflows: </Text>
      {loadingTemplate && <SkeletonItems mt="md" />}
      {!loadingTemplate && templates.length === 0 && (
        <Text italic size="xs" color="dimmed" mt="md">
          Es gibt keine passenden Workflows.
        </Text>
      )}
      {!loadingTemplate && templates.length > 0 && <Stack spacing="xs" mt="md">{templates.map(mapItemUI)}</Stack>}
      {showAvailabilityOption && (
        <>
          <Text mt="md">
            Der ausgewählte Workflow hat 2 Szenarien: "Verfügbar" und "Ausgebucht". Gib in der folgenden Checkbox an,
            welche Variante Du hinzufügen möchtest.
          </Text>
          <Checkbox
            mt="sm"
            checked={available}
            onChange={(e) => setAvailable(e.target.checked)}
            label="Ja, ich bin verfügbar"
          />
        </>
      )}
      <Center mt={40}>
        <Button
          disabled={loading || selectedTemplates.length === 0}
          leftIcon={<IconPlayerPlay size={16} />}
          onClick={onSubmit}
          sx={{ minWidth: 200 }}
        >
          Jetzt ausführen
        </Button>
      </Center>
      <Text mt={5} size="xs" align="center" color="dimmed">
        Ausgewählte Workflows werden sofort gestartet
      </Text>
    </>
  );
};

export default AddWorkflowModal;
