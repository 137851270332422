import { SelectItemProps, Checkbox } from '@mantine/core';
import { forwardRef } from 'react';

export interface AgendaGroupItemProps extends SelectItemProps {
  checked: boolean;
  label: string;
}

export const AgendaGroupMultiSelectItem = forwardRef<HTMLDivElement, AgendaGroupItemProps>(
  ({ value, checked, label, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Checkbox id={value} readOnly label={label} checked={checked} />
    </div>
  ),
);
