import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { EditableProperty } from './EditableProperty';

export const EditableProfileProperty = ({
  profile,
  preChange,
  patchField,
  placeholder,
  editFormatter,
  labelFormatter,
  ...rest
}) => {
  const { handleProfileChange } = useContext(ProfileContext);

  return (
    <EditableProperty
      dto={profile}
      preChange={preChange}
      changeHandler={handleProfileChange}
      patchField={patchField}
      placeholder={placeholder}
      targetUrl={`${process.env.REACT_APP_AUTH_SERVICE_URL}/profiles/${profile.id}`}
      editFormatter={editFormatter}
      labelFormatter={labelFormatter}
      {...rest}
    />
  );
};

EditableProfileProperty.propTypes = {
  patchField: PropTypes.string.isRequired,
  profile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lastModified: PropTypes.string.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]),
};
