import { useContext, useEffect } from 'react';
import { SimpleGrid, Space } from '@mantine/core';
import { ProfileContext } from '../Service/Context/ProfileContext';
import { EditableProfileProperty } from '../Atoms/EditableProperties/EditableProfileProperty';
import { SimpleEditableText } from '../Atoms/inputs/SimpleEditableText';
import { StringFormatter } from '../Atoms/Formatter/StringFormatter';
import { ShareProfile } from '../Templates/Settings/Share/ShareProfile';
import { ContactContext } from '../Service/Context/ContactContext';
import { EditableContactProperty } from '../Atoms/EditableProperties/EditableContactProperty';
import { WebPresentationSettings } from './Settings/WebPresentationSettings';
import { BankAccountSettings } from './Settings/BankAccountSettings';
import { ContactForm } from './ContactForm';
import { FinancialForm } from './FinancialForm';
import PanePaper from './PanePaper';
import EventKindsPane from './Settings/EventKindsPane';

const ApplicationUserData = () => {
  const { profile } = useContext(ProfileContext);
  const { contact, handleContactChange } = useContext(ContactContext);

  return (
    <>
      <SimpleGrid
        cols={5}
        breakpoints={[
          { maxWidth: 'xl', cols: 3 },
          { maxWidth: 'md', cols: 2 },
          { maxWidth: 'sm', cols: 2 },
          { maxWidth: 'xs', cols: 1 },
        ]}
      >
        <PanePaper title="common.settings.profile">
          <div>
            <EditableContactProperty
              patchField="companyName"
              entity={contact}
              changeHandler={handleContactChange}
              placeholder="contact.companyName"
              editFormatter={SimpleEditableText}
              labelFormatter={StringFormatter}
            />
            <EditableProfileProperty
              patchField="alias"
              profile={profile}
              placeholder="applicationuser.alias"
              editFormatter={SimpleEditableText}
              labelFormatter={StringFormatter}
            />
            <EditableContactProperty
              patchField="firstName"
              entity={contact}
              changeHandler={handleContactChange}
              placeholder="contact.firstName"
              editFormatter={SimpleEditableText}
              labelFormatter={StringFormatter}
            />
            <EditableContactProperty
              patchField="lastName"
              entity={contact}
              changeHandler={handleContactChange}
              placeholder="contact.lastName"
              editFormatter={SimpleEditableText}
              labelFormatter={StringFormatter}
            />
            <EditableContactProperty
              patchField="email"
              entity={contact}
              changeHandler={handleContactChange}
              placeholder="contact.email"
              editFormatter={SimpleEditableText}
              labelFormatter={StringFormatter}
            />
          </div>
        </PanePaper>

        <ContactForm changeHandler={handleContactChange} contact={contact} hideEmail />

        <WebPresentationSettings />

        <FinancialForm />

        <BankAccountSettings />
      </SimpleGrid>
      <Space h="md" />
      <div style={{ maxWidth: 500 }}>
        <ShareProfile />
      </div>
    </>
  );
};

export default ApplicationUserData;
