import { Group } from '@mantine/core';
import { useEffect } from 'react';
import SequentialNumber from '../../../Organismns/Settings/SequentialNumber';
import { RoutingS } from '../../../Service/RoutingS';

export const SequentialNumbers = () => {
  useEffect(() => {
    RoutingS.changeTitle('Buchhaltung');
  }, []);

  return (
    <Group>
      <SequentialNumber sequentialNumberType="CONTRACT_NUMBER" />
      <SequentialNumber sequentialNumberType="OFFER_NUMBER" />
      <SequentialNumber sequentialNumberType="CONFIRMATION_NUMBER" />
      <SequentialNumber sequentialNumberType="INVOICE_NUMBER" />
      <SequentialNumber sequentialNumberType="CANCEL_NUMBER" />
    </Group>
  );
};
