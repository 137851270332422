import React, { useContext } from 'react';
import { TransSelectField } from '../../../Atoms/InputFields';
import ResourceAdapter from '../../../Service/ResourceAdapter';

const SelectDocument = ({ templates, template, onChange, style }) => {
  const temps = [];
  templates.forEach((templateName) => {
    temps.push({ label: templateName, value: templateName });
  });

  const tmp = temps.find((tmp) => tmp.value === template);

  return (
    <TransSelectField
      style={style}
      value={tmp}
      options={temps}
      onChangeHandler={onChange}
      name="templates"
      noSelectionPlaceholder="document.template.select"
    />
  );
};

export default function ({ template, onChange, style }) {
  return (
    <ResourceAdapter
      serviceUrl={`${process.env.REACT_APP_QUESTIONNAIRE_SERVICE_URL}/questionnaires/templates/names`}
      component={(props) => (
        <SelectDocument templates={props.data} template={template} onChange={onChange} style={style} />
      )}
    />
  );
}
