import React, { useContext, useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/react';
import { FocusedNodeContext } from '../../Service/Context/FocusedNode';
import { InputHelp } from './InputHelp';

export const EditableDurationInMinutes = ({
  finishCallback,
  value: initialValue,
  placeholder,
  patchField,
  style,
  labelStyle,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue ? initialValue / 60 : 0);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const { setListPosition } = useContext(FocusedNodeContext);

  useEffect(() => {
    setValue(initialValue ? initialValue / 60 : 0);
  }, [initialValue]);

  function focusNode(e) {
    setListPosition(rest.index);
  }

  const styles = {
    error: {
      borderColor: 'red',
    },
    input: {
      marginBottom: 0,
    },
    label: {
      color: '#8ca3b5',
      fontWeight: 800,
      ...labelStyle,
    },
    inputContainer: {
      marginBottom: 9,
      ...style,
    },
  };

  return (
    <div style={styles.inputContainer}>
      {placeholder && (
        <Label sm={12} style={styles.label}>
          <Trans id={placeholder} />
        </Label>
      )}
      <Input
        onBlur={() => {
          setLoading(true);
          finishCallback({ [patchField]: value })
            .catch((error) => setError(error.message))
            .finally(() => {
              setLoading(false);
            });
        }}
        className={loading ? 'loading' : ''}
        style={error ? { ...styles.error, ...styles.input } : styles.input}
        onFocus={focusNode}
        onChange={(e) => setValue(e.target.value)}
        onKeyUp={(e) => {
          switch (e.key) {
            case 'Enter':
              finishCallback({ [patchField]: value })
                .catch((error) => setError(error.message))
                .then(() => setLoading(false));
              break;
            case 'Escape':
              finishCallback({ [patchField]: initialValue })
                .catch((error) => setError(error.message))
                .then(() => setLoading(false));
          }
        }}
        value={value != null ? value : ''}
        {...rest}
      />
      {error ? <InputHelp errorDescription={error} /> : null}
    </div>
  );
};

EditableDurationInMinutes.propTypes = {
  value: PropTypes.string,
  finishCallback: PropTypes.func,
  autoFocus: PropTypes.bool,
};

EditableDurationInMinutes.defaultProps = {
  autoFocus: true,
};
