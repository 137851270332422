import { Box, Center } from '@mantine/core';
import { IconCash, IconClipboard, IconFileDescription, IconInbox, IconInfoCircle, IconShare } from '@tabler/icons';
import { FC, useContext } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import NavigationLink from '../../Atoms/Navigation/NavigationLink';
import EventHeadActions from '../../Molecules/Event/EventHeadActions';
import { useNavigationItem } from '../../Organismns/Navigation/NavigationItemHook';
import { EventParticipationsContext } from '../../Service/Context/EventParticipationContext';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { WindowContext } from '../../Service/Context/WindowContext';
import EventBreadCrumbs from './EventBreadCrumbs';
import { TestS } from '../../TestS';
import {LicenceS} from "../../Service/LicenceS";
import { PostboxCountersContext } from '../../Service/Context/PostboxCountersContext';
import EmailBoxCounter, { EmailBoxCounterWithUnread } from '../Emails/EmailBoxCounter';

const EventFormNavbar: FC<{ closeNavBar: () => void }> = ({ closeNavBar }) => {
  const { id: eventId } = useParams();
  const navigate: NavigateFunction = useNavigate();
  const resouceItemActive = useNavigationItem(`/events/${eventId}/resources`);
  const { participations } = useContext<any>(EventParticipationsContext);
  const { profile } = useContext<any>(ProfileContext);
  const { sm } = useContext(WindowContext);

  const { inboxCount, unreadCount, draftsCount, scheduledCount, failedCount, trashCount } = useContext(PostboxCountersContext);

  const contactIds: number[] = [];
  participations.forEach((participation: any) => {
    contactIds.push(participation.contactId);
  });

  // eslint-disable-next-line no-underscore-dangle
  const _onClick = (path: string) => {
    navigate(path);
    closeNavBar();
  };

  return (
    <>
      {!sm && (
        <Box p="md">
          <EventBreadCrumbs />
        </Box>
      )}
      <NavigationLink
        variant="filled"
        label="Details"
        onClick={() => _onClick(`/events/${eventId}/details`)}
        active={useNavigationItem(`/events/${eventId}/details`)}
        icon={sm && <IconInfoCircle size={16} />}
        color="lightBlue"
      />

      {profile && LicenceS.hasAccessToResourceModule(profile.id) && <NavigationLink
        variant="filled"
        label="Ressourcen"
        onClick={() => _onClick(`/events/${eventId}/resources`)}
        active={resouceItemActive}
        icon={sm && <IconClipboard size={16}/>}
        color="lightBlue"
      />}

      <NavigationLink
        variant="filled"
        label="Dateien"
        onClick={() => _onClick(`/events/${eventId}/documents`)}
        active={useNavigationItem(`/events/${eventId}/documents`)}
        icon={sm && <IconFileDescription size={16} />}
        color="lightBlue"
        testId={TestS.Events.Navigate.TO_DOCUMENTS}
      />
      <NavigationLink
        defaultOpened
        label="E-Mails"
        icon={sm && <IconInbox size={16} />}
        childrenOffset={28}
        color="lightBlue"
      >
        <NavigationLink
          label="Posteingang"
          active={useNavigationItem(`/events/${eventId}/emails/inbox`)}
          onClick={() => _onClick(`/events/${eventId}/emails/inbox`)}
          variant="filled"
          rightSection={<EmailBoxCounterWithUnread count={inboxCount} unreadCount={unreadCount}/>}
          color="lightBlue"
        />
        <NavigationLink
          label="Entwürfe"
          active={useNavigationItem(`/events/${eventId}/emails/drafts`)}
          onClick={() => _onClick(`/events/${eventId}/emails/drafts`)}
          variant="filled"
          rightSection={<EmailBoxCounter count={draftsCount} />}
          color="lightBlue"
        />
        <NavigationLink
          label="Geplant"
          active={useNavigationItem(`/events/${eventId}/emails/scheduled`)}
          onClick={() => _onClick(`/events/${eventId}/emails/scheduled`)}
          variant="filled"
          rightSection={<EmailBoxCounter count={scheduledCount} />}
          color="lightBlue"
        />
        <NavigationLink
          label="Fehlgeschlagen"
          active={useNavigationItem(`/events/${eventId}/emails/failed`)}
          onClick={() => _onClick(`/events/${eventId}/emails/failed`)}
          variant="filled"
          rightSection={<EmailBoxCounter count={failedCount} />}
          color="lightBlue"
        />
        <NavigationLink
          label="Gesendet"
          active={useNavigationItem(`/events/${eventId}/emails/outbound`)}
          onClick={() => _onClick(`/events/${eventId}/emails/outbound`)}
          variant="filled"
          color="lightBlue"
        />
        <NavigationLink
          label="Papierkorb"
          active={useNavigationItem(`/events/${eventId}/emails/trash`)}
          onClick={() => _onClick(`/events/${eventId}/emails/trash`)}
          variant="filled"
          rightSection={<EmailBoxCounter count={trashCount} />}
          color="lightBlue"
        />
      </NavigationLink>
      <NavigationLink
        label="Freigaben"
        icon={sm && <IconShare size={16} />}
        active={useNavigationItem(`/events/${eventId}/shares`)}
        onClick={() => _onClick(`/events/${eventId}/shares`)}
        variant="filled"
        color="lightBlue"
      />
      <NavigationLink
        label="Verdienst"
        icon={sm && <IconCash size={16} />}
        active={useNavigationItem(`/events/${eventId}/finance`)}
        onClick={() => _onClick(`/events/${eventId}/finance`)}
        variant="filled"
        color="lightBlue"
      />
      <Center>
        <Box sx={{ position: 'absolute', bottom: 20 }}>
          <EventHeadActions />
        </Box>
      </Center>
    </>
  );
};

export default EventFormNavbar;
