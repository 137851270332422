import { Text } from '@mantine/core';
import { i18n } from '@lingui/core';
import { FC } from 'react';
import { CreateTaskConfigDTO } from '../../../../../Types/LogT';

const CreateTask: FC<{ config: unknown }> = ({ config }) => {
  const { name } = config as CreateTaskConfigDTO;

  if (!name) {
    return (
      <Text italic color="red">
        {i18n._('step.config.general-error')}
      </Text>
    );
  }
  return (
    <Text>
      Aufgabe{' '}
      <Text span weight="bolder">
        {name}
      </Text>{' '}
      erstellen.
    </Text>
  );
};

export default CreateTask;
