import {Alert, Button, Group, Space, Text, Title} from '@mantine/core';
import { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { ReactFormBuilder } from 'bookitup-form-builder';
import { IntlProvider } from 'react-intl';
import { BookitupFormContext } from '../../../../Service/Context/BookitupFormContext';
import FormBuilderLocale from '../lang/FormBuilderLocale';
import '../style/application.scss';
import ChangesDetectedAlert from '../ChangesDetectedAlert';
import { i18n } from '@lingui/core';
import RequiredFieldsAlert from '../RequiredFieldsAlert';
import {IconInfoCircle} from "@tabler/icons";
import {useNavigate} from "react-router-dom";

const FormBuilder = ({ toolbarItems }) => {
  const { bookitupForm, updateForm, addCustomItem } = useContext(BookitupFormContext);
  const [locale] = useCookies(['locale']);
  const navigate = useNavigate();

  if (!bookitupForm) {
    return null;
  }

  const { task_data, outOfSync } = bookitupForm;
  const usableLocale = locale.locale === 'en' ? 'en' : 'de';
  const currentAppLocale = FormBuilderLocale[usableLocale];

  return (
    <IntlProvider defaultLocale="en" locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <div>
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v6.2.1/css/all.css" />
        <link
          rel="stylesheet"
          href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"
          integrity="sha384-Vkoo8x4CGsO3+Hhxv8T/Q5PaXtkKtu6ug5TOeNV6gBiFeWPGFN9MuhOf23Q9Ifjh"
          crossOrigin="anonymous"
        />
      </div>

      <Alert
        title="Tipp: So fügst Du individuelle Felder hinzu"
        sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })}
        icon={<IconInfoCircle />}
      >
        <Text mb="sm">
          Wenn du weitere individuelle Felder hinzufügen möchtest, kannst Du Dir einfach unter {' '}
          <Text
            span
            weight="bolder"
            color="blue"
            variant="link"
            sx={{ cursor: 'pointer' }}
            onClick={() => navigate('/settings/customfields')}
          >
            eigene Felder
          </Text>{' '}
          individuelle Felder erstellen. Anschließend kannst Du diese unter "Verfügbare Felder" einfach dem Kontaktformular hinzufügen.
        </Text>
        <Text>
          Damit lassen sich z.B. auch Felder mit Checkboxen oder Dropdowns hinzufügen.
        </Text>
      </Alert>
      <Space h="xs" />

      {outOfSync && <ChangesDetectedAlert />}
      <RequiredFieldsAlert form={bookitupForm} />
      <Title order={3} mb="xs" mt="xs">
        {' '}
        Vorgefertigte Layouts
      </Title>
      <Group p="sm" mb={45} sx={{ border: '1px dashed #ddd' }}>
        {[
          'DATUM_START_END',
          'DATE_AND_EVENT_KIND',
          'START_END',
          'FIRST_AND_LASTNAME',
          'SALUTATION_FIRST_AND_LAST_NAME',
          'PHONE_AND_EMAIL',
          'ZIP_AND_CITY',
          'PARTNER_FIRST_AND_LASTNAME',
          'PARTNER_SALUTATION_FIRST_AND_LAST_NAME',
          'PARTNER_PHONE_AND_EMAIL',
        ].map((customItem) => (
          <Button
            onClick={() => addCustomItem(customItem)}
            size="xs"
            variant="outline"
            sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0], border: 'none' })}
          >
            {i18n._(`form.custom.field.${customItem}`)}
          </Button>
        ))}
      </Group>
      {toolbarItems && (
        <ReactFormBuilder
          saveAlways
          toolbarItems={toolbarItems}
          data={task_data}
          onPost={(data) => updateForm({ task_data: data.task_data })}
          appLocaleOverride={FormBuilderLocale}
          locale={usableLocale}
        />
      )}
    </IntlProvider>
  );
};

export default FormBuilder;
