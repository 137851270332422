import { Skeleton, ThemeIcon, Tooltip } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { IconCheck, IconQuestionMark, IconX } from '@tabler/icons';
import { ResourceType, Shortage } from '../../../../Types/ResourceT';
import ResourcePlanningS from '../../../../Service/restapi/resourcePlanningS';
import { stringToNumber } from '../../../../Utils/utils';
import { ToastS } from '../../../../Service/ToastS';

interface Props {
  resourceId: string;
  amount: string;
  eventId?: number;
  from?: Date;
  to?: Date;
}

const ResourceAvailabilityDot: FC<Props> = ({ eventId, resourceId, amount, from, to }) => {
  const DOT_SIZE = 20;
  const [shortages, setShortages] = useState<Shortage[] | undefined>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();
    ResourcePlanningS.getVirtualPlanningShortages(
      resourceId,
      stringToNumber(amount),
      eventId,
      from,
      to,
      abortController.signal,
    )
      .then(setShortages)
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, resourceId, amount, from, to]);

  if (stringToNumber(amount) === null) {
    return (
      <Tooltip
        label="Verfügbarkeit kann nicht geprüft werden. Bitte ersetze den Mengen-Platzhalter durch eine Zahl."
        position="bottom-start"
        transition="slide-down"
      >
        <div>
          <ThemeIcon radius="xl" size="sm" color="#FFD600">
            <IconQuestionMark color="#113157" size={14} />
          </ThemeIcon>
        </div>
      </Tooltip>
    );
  }

  if (!Number.isInteger(Number(amount))) {
    ToastS.warn(
      `round-up-${resourceId}`,
      'Achtung: Die Menge einer verknüpften Ressource sollte nicht ungerade sein. Es wird aufgerundet!',
    );
  }

  if (!shortages || loading) {
    return <Skeleton circle height={DOT_SIZE} />;
  }
  const warehouseShortage = shortages.reduce(
    (accumulator, currentShortage) =>
      accumulator ||
      (currentShortage.warehouseShortage < 0 && currentShortage.resourceType !== ResourceType.KIT) ||
      currentShortage.showWarehousePlanningShortage,
    false,
  );

  if (warehouseShortage) {
    return (
      <Tooltip
        label={warehouseShortage ? 'Resource ist bereits überbucht.' : 'Resource ist ausreichend verfügbar'}
        position="bottom-start"
        transition="slide-down"
      >
        <div>
          <ThemeIcon radius="xl" size="sm" color="#FF5C5A">
            <IconX size={14} />
          </ThemeIcon>
        </div>
      </Tooltip>
    );
  }

  const resourcePlanningShortage = shortages.reduce(
    (accumulator, currentShortage) =>
      accumulator ||
      (currentShortage.shortage < 0 && currentShortage.resourceType !== ResourceType.KIT) ||
      currentShortage.showResourcePlanningShortage,
    false,
  );

  return (
    <Tooltip
      label={
        resourcePlanningShortage ? 'Die Planungen übersteigen den Bestand.' : 'Resource ist ausreichend verfügbar.'
      }
      position="bottom-start"
      transition="slide-down"
    >
      <ThemeIcon radius="xl" size="sm" color={resourcePlanningShortage ? '#FFD600' : 'gray'}>
        {resourcePlanningShortage && <IconX color="#4b525b" size={14} />}
        {!resourcePlanningShortage && <IconCheck color="black" size={14} />}
      </ThemeIcon>
    </Tooltip>
  );
};

export default ResourceAvailabilityDot;
