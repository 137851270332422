import { Box, LoadingOverlay, Space, Stack, Text } from '@mantine/core';
import { FC, useContext } from 'react';
import ResourcesTable from '../../Organismns/Resources/table/general/ResourcesTable';
import { ResourcesContext } from '../../Service/Context/ResourcesContext';
import PaginationNav from '../../Molecules/Navigation/PaginationNav';
import AddResourceIcon from '../../Organismns/Resources/AddResourceIcon';

const AllResources: FC = () => {
  const { loading, resources, navigation, filter, empty, updateFilter } = useContext(ResourcesContext);
  const { totalCount, totalPages } = navigation;
  const { pageNumber, pageSize } = filter;

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      {resources.length === 0 && <ResourcesNotFound empty={empty} />}
      {resources.length > 0 && <ResourcesTable resources={resources} />}
      <PaginationNav
        pageSize={pageSize}
        pageNumber={pageNumber}
        totalCount={totalCount}
        totalPages={totalPages}
        entityName="Ressourcen"
        onChange={(newPageNumber) => updateFilter({ pageNumber: newPageNumber - 1 })}
      />
    </div>
  );
};

export default AllResources;

const ResourcesNotFound: FC<{ empty: boolean }> = ({ empty }) => (
  <Stack>
    <Box sx={(theme) => ({ width: '100%', height: 50, backgroundColor: theme.colors.gray[0], borderRadius: 10 })} />
    <Box sx={(theme) => ({ width: '100%', height: 100, backgroundColor: theme.colors.gray[0], borderRadius: 10 })} />
    {empty ? (
      <Text sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
        Du hast noch kein Ressource erstellt. Klicke auf
        <Space w={5} />
        <AddResourceIcon />
        <Space w={2} />, um zu beginnen.
      </Text>
    ) : (
      <Text align="center">
        Die Kombination der ausgewählten <b>Filter</b> erzielt keine Ergebnisse.
      </Text>
    )}
  </Stack>
);
