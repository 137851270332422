import { useDrag, useDrop } from 'react-dnd-latest';
import React from 'react';
import DragButton from '../../Atoms/DragDrop/DragButton';
import QuestionMenuComponent from './QuestionMenuComponent';
import QuestionDetails from './QuestionDetails';

export const Question = ({
  handleChange,
  moveQuestion,
  bundleId,
  setQuestionInDelete,
  questionsLength,
  question,
  index,
  event,
  eventLocation,
  customer,
  customAttributeValues,
  settings,
  setQuestions
}) => {
  const [, drop] = useDrop(
    () => ({
      accept: 'questionItem',
      collect(monitor) {
        return { handlerId: monitor.getHandlerId() };
      },
      drop(item, monitor) {
        console.log(`move question ${item.question.question} to index ${index}`);
        moveQuestion(bundleId, item.question.id, index, item.bundleId !== bundleId ? item.bundleId : null);
      },
    }),
    [question.id],
  );

  const [collected, drag] = useDrag(
    () => ({
      type: 'questionItem',
      item: { question, index, bundleId },
    }),
    [question.id, bundleId],
  );

  const opacity = collected.isDragging ? 0.6 : 1;

  const styles = {
    questionContainer: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 5,
      padding: '0px 5px',
      backgroundColor: 'rgb(251, 251, 251)',
      border: '1px solid rgba(0, 0, 0, .125)',
      borderRadius: '4px 0px 0px 4px',
    },
    moveHandle: {
      cursor: 'move',
      alignSelf: 'center',
      paddingRight: '10px',
    },
  };

  return (
    <div ref={drop}>
      <div key={index} style={{ display: 'flex', opacity }}>
        <div style={styles.questionContainer}>
          <DragButton dragRef={drag} entity="question-bundle" />
          <QuestionDetails
            handleChange={handleChange}
            val={question}
            index={index}
            event={event}
            eventLocation={eventLocation}
            customer={customer}
            customAttributeValues={customAttributeValues}
            settings={settings}
          />
        </div>
        <QuestionMenuComponent
          index={index}
          bundleId={bundleId}
          questionId={question.id}
          moveQuestion={moveQuestion}
          questionsLength={questionsLength}
          setQuestionInDelete={setQuestionInDelete}
          setQuestions={setQuestions}
        />
      </div>
    </div>
  );
};
