import { Center, SegmentedControl } from '@mantine/core';
import { FC, useState } from 'react';
import DocumentCheckboxSelector from '../../../Templates/Documents/DocumentCheckboxSelector';

type Props = {
  eventId: string;
  getSelected: (entityType: string) => any;
  onSelectWithType: (entityType: string, entityId: string) => void;
  onDeselectWithType: (entityType: string, entityId: string) => void;
};

const ShareFlowCombined1: FC<Props> = ({ eventId, getSelected, onSelectWithType, onDeselectWithType }) => {
  const [selectedEntityType, setSelectedEntityType] = useState('contracts');

  return (
    <>
      <Center>
        {' '}
        <SegmentedControl
          value={selectedEntityType}
          onChange={setSelectedEntityType}
          data={[
            { label: 'Vertrag', value: 'contracts' },
            { label: 'Dokumente', value: 'documents' },
            { label: 'Fragebogen', value: 'questionnaires' },
          ]}
          sx={{ marginBottom: 20 }}
        />
      </Center>
      <DocumentCheckboxSelector
        entityId={eventId}
        entityType={selectedEntityType}
        selected={getSelected(selectedEntityType)}
        onSelect={(entityId: string) => onSelectWithType(selectedEntityType, entityId)}
        onDeselect={(entityId: string) => onDeselectWithType(selectedEntityType, entityId)}
        documentType={undefined}
      />
    </>
  );
};

export default ShareFlowCombined1;
