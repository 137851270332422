import React, { useContext } from 'react';
import moment from 'moment';
import { withI18n } from '@lingui/react';
import { EditableLogProperty } from '../../Atoms/EditableProperties/EditableLogProperty';
import { EditableDivInput } from '../../Atoms/inputs/EditableDivInput';
import { HtmlFormatter } from '../../Atoms/Formatter/HtmlFormatter';
import EditableDateInput from '../../Atoms/inputs/EditableDateInput';
import { SimpleEditableText } from '../../Atoms/inputs/SimpleEditableText';
import { NumberFormatter } from '../../Atoms/Formatter/NumberFormatter';
import { TransButtonLinkWithIcon } from '../../Atoms/Buttons';
import { deleteLog } from '../../Service/restapi/logService';
import { ConfirmDialog, useConfirmModalState, useModalState } from '../Dialogs';
import { StringFormatter } from '../../Atoms/Formatter/StringFormatter';
import { DateTimeFormatter } from '../../Atoms/Formatter/DateTimeFormatter';
import { EditableCheckbox } from '../../Atoms/inputs/EditableCheckbox';
import TooltipButton from '../../Atoms/buttons/TooltipButton';

export default withI18n()(({ note, handleChange, deleteNote, i18n }) => {
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);
  const { show, toggle } = useModalState(false);

  function delNote() {
    deleteLog(note.id).then((resp) => {
      if (resp.ok) {
        deleteNote(note.id);
      }
    });
  }

  const format = i18n._('format.dateTime.short');
  return (
    <div className="" style={{ marginBottom: 25 }}>
      <div className="clickable">
        <EditableLogProperty
          className=""
          entity={note}
          handleChange={handleChange}
          patchField="value"
          editFormatter={EditableDivInput}
          style={{ minHeight: 100, backgroundColor: 'rgb(251, 251, 251)' }}
          labelFormatter={HtmlFormatter}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          {!show && (
            <div style={{ color: '#9ca6af' }}>
              {!note.description && (
                <TransButtonLinkWithIcon
                  className="btn-link"
                  style={{ marginRight: 10, fontStyle: 'italic' }}
                  text="Beschreibung hinzufügen"
                  onClick={toggle}
                />
              )}
              {note.description && (
                <TransButtonLinkWithIcon
                  className="btn-link"
                  style={{ marginRight: 10 }}
                  text={note.description}
                  onClick={toggle}
                />
              )}
              {!note.dateTime && (
                <TransButtonLinkWithIcon
                  icon="CLOCK"
                  className="btn-link"
                  style={{ marginRight: 10, fontStyle: 'italic' }}
                  text="Uhrzeit/Dauer bearbeiten"
                  onClick={toggle}
                />
              )}
              {note.dateTime && (
                <TransButtonLinkWithIcon
                  icon="CLOCK"
                  className="btn-link"
                  style={{ marginRight: 10 }}
                  text={moment(note.dateTime).format(format)}
                  onClick={toggle}
                />
              )}
              {note.durationInMinutes && (
                <TransButtonLinkWithIcon
                  className="btn-link"
                  style={{ marginRight: 10 }}
                  text={`${note.durationInMinutes} Minuten`}
                  onClick={toggle}
                />
              )}
              <TransButtonLinkWithIcon
                className="btn-link"
                style={{ marginRight: 10 }}
                text={`in Kalender übertragen: ${note.calendarNote ? 'Aktiv' : 'Deaktiviert'}`}
                onClick={toggle}
              />
            </div>
          )}
          {show && (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ marginRight: 5 }}>
                <EditableLogProperty
                  placeholder="Beschreibung"
                  entity={note}
                  handleChange={handleChange}
                  patchField="description"
                  editFormatter={SimpleEditableText}
                  labelFormatter={StringFormatter}
                />
              </div>
              <div style={{ marginRight: 5, width: 155 }}>
                <EditableLogProperty
                  placeholder="Datum / Uhrzeit"
                  entity={note}
                  handleChange={handleChange}
                  patchField="dateTime"
                  showTimeSelect
                  editFormatter={EditableDateInput}
                  labelFormatter={DateTimeFormatter}
                />
              </div>
              <div style={{ marginRight: 10, width: 110 }}>
                <EditableLogProperty
                  style={{ height: 'unset' }}
                  placeholder="Dauer (Minuten)"
                  entity={note}
                  handleChange={handleChange}
                  patchField="durationInMinutes"
                  editFormatter={SimpleEditableText}
                  labelFormatter={NumberFormatter}
                />
              </div>
              <EditableLogProperty
                placeholder="in Kalender übertragen"
                name="calendarNote"
                patchField="calendarNote"
                changeHandler={handleChange}
                entity={note}
                editFormatter={EditableCheckbox}
                editOnly
                style={{ marginTop: 15, alignSelf: 'center', marginBottom: 0 }}
              />
            </div>
          )}
        </div>

        <TooltipButton
          icon="TRASH"
          isLoading={false}
          id={`delete-note-${note.id}`}
          style={{ marginRight: 10 }}
          text="Notiz löschen"
          onClick={toggleConfirm}
        />
      </div>
      <ConfirmDialog
        text="event.notes.delete.confirm"
        subject="event.notes.delete.confirm.head"
        open={showConfirm}
        toggle={toggleConfirm}
        handler={delNote}
      />
    </div>
  );
});
