// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
// Output: 100,00 EUR
const CurrencyFormatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
  currencyDisplay: 'code',
});

// Output: 100,00 €
const CurrencyFormatterWithEuroSign = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
});

const formatCurrency = (price: string): string => CurrencyFormatter.format(parseFloat(price));

const formatNumberCurrencyWithUnitSign = (value: number): string => formatCurrencyWithUnitSign(value.toString());

const formatCurrencyWithUnitSign = (price: string): string => {
  if (Number.isNaN(parseFloat(price))) {
    return ' - €';
  }
  return CurrencyFormatterWithEuroSign.format(parseFloat(price));
};

const numberToCurrency = (num: number | null | undefined) => {
  if (!num || Number.isNaN(num)) {
    return ' - €';
  }
  return formatCurrencyWithUnitSign(num.toString());
};

// eslint-disable-next-line import/prefer-default-export
export const FormatS = {
  formatCurrency,
  formatCurrencyWithUnitSign,
  numberToCurrency,
  formatNumberCurrencyWithUnitSign,
};
