import { useParams } from 'react-router-dom';
import RecommendationRequest from './RecommendationRequest';

const RecommendationRequestLoggedIn = () => {
  const { id } = useParams();

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
      {id && <RecommendationRequest isAppUser />}
    </div>
  );
};

export default RecommendationRequestLoggedIn;
