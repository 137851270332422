// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Group, Menu, Text } from '@mantine/core';
import { i18n } from '@lingui/core';
import { IconChevronDown } from '@tabler/icons';
import { FC, useContext } from 'react';
import { openModal } from '@mantine/modals';
import moment from 'moment';
import Dot from '../../../Atoms/Dot';
import { RecommendationS } from '../../../Service/RecommendationS';
import { BookingState, RecommendationDto } from '../../../Types/RecommendationT';
import { RecommendationContext } from './RecommendationsPane';
import RecommendationBookingStateModal from './Modals/RecommendationChangeBookingStateModal';

interface Props {
  recommendation: RecommendationDto;
  contactEmail: string | undefined | null;
}

const RecommendationStateStatus: FC<Props> = ({ recommendation, contactEmail }) => {
  const { id: recommendationId, bookingState, lastModifiedBookingState } = recommendation;
  const { patchRecommendation } = useContext(RecommendationContext);

  const changeBookingState = (_bookingState: BookingState) => {
    const currentState = i18n._(`recommendation.bookingState.${bookingState.toLowerCase()}`);
    const newState = i18n._(`recommendation.bookingState.${_bookingState.toLowerCase()}`);

    openModal({
      title: (
        <Text mt="md" size="xl" sx={{ lineHeight: 1.2 }}>
          Statusänderung von{' '}
          <Text span weight="bolder">
            {currentState}
          </Text>{' '}
          auf{' '}
          <Text span weight="bolder">
            {newState}
          </Text>
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: (
        <RecommendationBookingStateModal
          recommendationId={recommendationId}
          newBookingState={_bookingState}
          patchRecommendation={patchRecommendation}
          contactEmail={contactEmail}
        />
      ),
    });
  };

  return (
    <Box
      p={5}
      pr="xs"
      pl="md"
      sx={(theme) => ({ borderRadius: theme.spacing.xs, backgroundColor: 'white', fontSize: theme.fontSizes.xs })}
    >
      <Group position="apart">
        <Group>
          <Dot colour={RecommendationS.getColourByBookingState(bookingState)} />
          <Text weight="bolder">{i18n._(`bookingState.${bookingState}`)}</Text>
        </Group>

        <Menu radius="md" shadow="md" width={130} position="bottom-end" transition="scale-y">
          <Menu.Target>
            <div>
              <IconChevronDown size={16} style={{ cursor: 'pointer' }} />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            {['OPEN', 'OFFERED', 'BOOKED', 'CANCELED'].map((_bookingState) => (
              <Menu.Item
                key={_bookingState}
                icon={<Dot colour={RecommendationS.getColourByBookingState(_bookingState as BookingState)} />}
                onClick={() => changeBookingState(_bookingState as BookingState)}
              >
                {i18n._(`bookingState.${_bookingState}`)}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      </Group>
      {lastModifiedBookingState && (
        <Text italic color="dimmed">{`seit ${moment(lastModifiedBookingState).format('DD.MM.YY HH:mm')}`}</Text>
      )}
    </Box>
  );
};

export default RecommendationStateStatus;
