import React, { useContext, useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { fetchFileListOfEvent, fetchFileListOfProfile } from '../../Service/restapi/fileService';
import { TransButtonDefault } from '../../Atoms/Buttons';
import { ModalDialog } from '../Dialogs';
import { FileUploadModalDialog } from './FileUploadModalDialog';

/**
 * Select a file.
 *
 * Attachment {
 *     fileName: String
 *     eventId: Number
 * }
 */

export default function ({ eventId, show, toggle, onFileSelect }) {
  const { profile } = useContext(ProfileContext);
  const [eventFiles, setEventFiles] = useState(undefined);
  const [profileFiles, setProfileFiles] = useState(undefined);

  function fetchFilesOfEvent() {
    fetchFileListOfEvent(eventId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((fileList) => {
            fileList.forEach((file) => {
              file.entityId = eventId;
              file.entityType = 'events';
            });
            setEventFiles(fileList);
          });
        } else {
          console.error('could not fetch event files');
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not fetch event files');
      });
  }

  function fetchFilesOfProfile() {
    fetchFileListOfProfile(profile.id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((fileList) => {
            fileList.forEach((file) => {
              file.entityId = profile.id;
              file.entityType = 'profiles';
            });
            setProfileFiles(fileList);
          });
        } else {
          console.error(`could not load files of profile ${profile.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not load files of profile ${profile.id}`);
      });
  }

  useEffect(() => {
    fetchFilesOfProfile();
  }, [show]);

  useEffect(() => {
    if (eventId) {
      fetchFilesOfEvent();
    }
  }, [eventId, show]);

  const styles = {
    tableHead: {
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 800,
      color: '#8ca3b5',
    },
    group: {
      marginBottom: 10,
    },
    listAndUploadContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  };

  return (
    <ModalDialog
      help="help.common.file.select.pdf.pane"
      header={<Trans id="common.file.select.pdf" />}
      body={
        <>
          <div style={styles.group}>
            <div style={styles.tableHead}>
              <div>Dateien aus Deinem Profil</div>
              <div />
            </div>
            <div style={styles.listAndUploadContainer}>
              <div>
                {!profileFiles && <div>Laden...</div>}
                {profileFiles
                  ? profileFiles.map((item, index) => (
                      <div
                        className="clickable"
                        onClick={() => {
                          onFileSelect('profiles', profile.id, item.fileName);
                          toggle();
                        }}
                        key={index}
                      >
                        {item.fileName}
                      </div>
                    ))
                  : null}
              </div>
              <FileUploadModalDialog
                text="Hier kannst Du allgemeine Dateien hineinziehen"
                serverUrl={`${process.env.REACT_APP_FILE_SERVICE_URL}/files/profiles/${profile.id}`}
                reloadCallback={(fileName) => {
                  onFileSelect('profiles', profile.id, fileName);
                  toggle();
                }}
              />
            </div>
          </div>

          {eventId && (
            <div style={styles.group}>
              <div style={styles.tableHead}>
                <div>Dateien aus diesem Event</div>
                <div />
              </div>
              <div style={styles.listAndUploadContainer}>
                <div>
                  {!eventFiles && <div>Laden...</div>}
                  {eventFiles
                    ? eventFiles.map((item, index) => (
                        <div
                          className="clickable"
                          onClick={() => {
                            onFileSelect('events', eventId, item.fileName);
                            toggle();
                          }}
                          key={index}
                        >
                          {item.fileName}
                        </div>
                      ))
                    : null}
                </div>
                <FileUploadModalDialog
                  text="Hier kannst Du Dateien zum Event hineinziehen"
                  serverUrl={`${process.env.REACT_APP_FILE_SERVICE_URL}/files/events/${eventId}`}
                  reloadCallback={(fileName) => {
                    onFileSelect('events', eventId, fileName);
                    toggle();
                  }}
                />
              </div>
            </div>
          )}
        </>
      }
      footer={
        <div style={styles.buttons}>
          <TransButtonDefault onClickHandler={toggle} text="actions.close" />
        </div>
      }
      show={show}
      toggle={toggle}
    />
  );
}
