import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EditableProperty } from './EditableProperty';

export const EditableStandardLayoutContentProperty = ({
  entity,
  changeHandler,
  patchField,
  placeholder,
  editFormatter,
  labelFormatter,
  ...rest
}) => (
  <EditableProperty
    dto={entity}
    changeHandler={changeHandler}
    patchField={patchField}
    placeholder={placeholder}
    targetUrl={`${process.env.REACT_APP_PDF_SERVICE_URL}/standardLayoutContent/${entity.id}`}
    editFormatter={editFormatter}
    labelFormatter={labelFormatter}
    {...rest}
  />
);

EditableStandardLayoutContentProperty.propTypes = {
  patchField: PropTypes.string.isRequired,
  entity: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lastModified: PropTypes.string.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  changeHandler: PropTypes.func,
};
