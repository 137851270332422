import { Stack } from '@mantine/core';
import PanePaper from '../../../Molecules/PanePaper';
import CalendarShares from './CalendarShares';
import CalendarAccess from './CalendarAccess';
import { useEffect } from 'react';
import { RoutingS } from '../../../Service/RoutingS';

export default function () {
  useEffect(() => {
    RoutingS.changeTitle('Dienstleister-Netzwerk');
  }, []);
  return (
    <PanePaper title="settings.team" tooltip="help.settings.team.pane">
      <div style={{ marginTop: 10 }}>
        <div className="" style={{ marginBottom: 20 }}>
          In jedem Event unter <i>Event-Details</i> steht Dir der Bereich <i>Dienstleister-Netzwerk</i> zur Verfügung.
          Wenn du dort Kollegen hinzufügst, kannst Du Aufträge schnell und einfach an Deine Kollegen übermitteln - egal
          ob sie ebenso bookitup nutzen oder nicht.
          <br />
          <br />
          Wenn Dein Kollege aber auch bookitup nutzt, kannst Du sein bookitup Konto (unter{' '}
          <i>Verfügbarkeit Deines Teams</i>) verknüpfen. Das System prüft dann automatisiert, ob der Kollege für das
          Event noch Zeit hätte (anhand seiner hinterlegten Events und privaten Kalender). Falls ja, kannst Du ihm die
          Anfrage weiterleiten. Diese wird dann automatisch in seinem bookitup Konto importiert.
        </div>
        <Stack>
          <CalendarAccess />
          <CalendarShares />
        </Stack>
      </div>
    </PanePaper>
  );
}
