import { i18n } from '@lingui/core';
import {Menu, Box, ThemeIcon, Tooltip} from '@mantine/core';
import {IconDotsVertical, IconTrashX, IconArchive, IconArchiveOff, IconX, IconCheck} from '@tabler/icons';
import { FC, useContext } from 'react';
import { Resource } from '../../../../Types/ResourceT';
import { ResourcesContext } from '../../../../Service/Context/ResourcesContext';
import { openResouceArchiveModal, openResouceDeleteModal } from '../../ResourceModals';
import ResourceS from '../../../../Service/ResourceS';
import { ToastS } from '../../../../Service/ToastS';

const GeneralResourceActions: FC<{ resource: Resource }> = ({ resource }) => {
  const { id, archive } = resource;
  const { reload } = useContext(ResourcesContext);

  const activate = async () => {
    const resJSON = await ResourceS.update(id, { archive: false });
    if (resJSON) {
      ToastS.success('foo', 'Ressource wurde aktiviert');
      reload();
    }
  };

  return (
    <Menu shadow="md" width={150} position="bottom-end" transition="scale-y">
      <Menu.Target>
        <Box sx={{ cursor: 'pointer' }}>
          <IconDotsVertical color="gray" />
        </Box>
      </Menu.Target>
      <Menu.Dropdown>
        {!archive && (
            <>
                <Menu.Item icon={<IconArchive size={16} />}
                    onClick={() => openResouceArchiveModal({ resource, onFinish: reload })}>
                    {i18n._('actions.archive')}
                </Menu.Item>
                <Menu.Item
                    color="red"
                    icon={<IconTrashX size={16} />}
                    disabled>
                    Löschen
                </Menu.Item>
            </>
        )}
        {archive && (
          <>
            <Menu.Item icon={<IconArchiveOff size={16} />} onClick={activate}>
              Aktivieren
            </Menu.Item>
            <Menu.Item
              color="red"
              icon={<IconTrashX size={16} />}
              onClick={() => openResouceDeleteModal({ resource, onFinish: reload })}
            >
              Löschen
            </Menu.Item>
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export default GeneralResourceActions;
