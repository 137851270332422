import { FC } from 'react';
import { Text } from '@mantine/core';
import { DeleteTasksConfigDTO } from '../../../../../Types/LogT';

const DeleteTasks: FC<{ config: unknown }> = ({ config }) => {
  const { removeOnlyUnfinished } = config as DeleteTasksConfigDTO;
  return <Text>Entfernen Sie alle {removeOnlyUnfinished ? 'nicht erledigten' : ''} Aufgaben.</Text>;
};

export default DeleteTasks;
