// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Button, Group, Loader, Space, Stack, Text } from '@mantine/core';
import { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { QuestionDto } from '../../Types/QuestionnaireT';
import { FormSubmissionDto } from '../../Types/ShareT';
import { CustomAttributeValueDTO, CustomAttributeWithGroupNameDTO } from '../../Types/CustomAttributeT';
import { CustomAttributeS } from '../../Service/Context/CustomAttributeS';
import SkeletonItems from '../../Atoms/SkeletonItems';
import { QuestionnaireS } from '../../Service/QuestionnaireS';
import PanePaper from '../../Molecules/PanePaper';
import { ToastS } from '../../Service/ToastS';
import { FileDTO } from '../../Molecules/FileDetails';
import { WindowContext } from '../../Service/Context/WindowContext';
import { MixpanelS } from '../../Service/MixpanelS';
import { ConstantS } from '../../Service/ConstantS';
import QuestionSubmission from './QuestionSubmission';

interface QuestionBundleProps {
  submissionFiles: FileDTO[];
  formSubmission: FormSubmissionDto;
  questions: QuestionDto[];
  closeModal: () => void;
  setFile: (file: FileDTO) => void;
  reload: () => void;
}

const QuestionnaireSubmission: FC<QuestionBundleProps> = ({
  formSubmission,
  submissionFiles,
  questions: initialQuestions,
  closeModal,
  setFile,
  reload,
}) => {
  const { xs } = useContext(WindowContext);
  const [loading, setLoading] = useState(false);
  const [transmittingValues, setTransmittingValues] = useState(false);
  const { id: eventId } = useParams();
  const [customAttributes, setAttributes] = useState<CustomAttributeWithGroupNameDTO[]>([]);
  const [selectedQuestions, selectQuestions] = useState<string[]>([]);
  const [questions, setQuestions] = useState(initialQuestions);
  const { shareName } = formSubmission;

  const toggle = (questionId: string) => {
    if (selectedQuestions.includes(questionId)) {
      selectQuestions(selectedQuestions.filter((id) => id !== questionId));
    } else {
      selectQuestions([...selectedQuestions, questionId]);
    }
  };

  const changeAttributeId = (questionId: string, attributeId: string | null) => {
    QuestionnaireS.editQuestion(questionId, { targetAttributeId: attributeId }).then((question) => {
      if (question) {
        setQuestions(questions.map((q) => (q.id === questionId ? question : q)));
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    CustomAttributeS.fetchAllAttributes()
      .then(setAttributes)
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return <SkeletonItems />;
  }

  const mapQuestionUI = (question: QuestionDto) => {
    const { id } = question;
    const answer = QuestionnaireS.resolveQuestionAnswer(formSubmission, question);
    const answerFile = submissionFiles.find((f) => f.fileName === answer);
    const previewFn = answerFile ? () => setFile(answerFile) : undefined;

    return (
      <QuestionSubmission
        question={question}
        isSelected={selectedQuestions.includes(id)}
        toggle={() => toggle(id)}
        customerAnswer={answer}
        customAttributes={customAttributes}
        changeAttributeId={(attributeId: string | null) => changeAttributeId(id, attributeId)}
        formId={formSubmission.id}
        file={answerFile}
        previewFile={previewFn}
        reload={reload}
      />
    );
  };

  const selectAll = () => {
    const selectableQuestions = questions
      .filter((q) => {
        const { attributeType, targetAttributeId } = q;
        return attributeType !== 'FILE_UPLOAD' && targetAttributeId !== null;
      })
      .map((q) => q.id);
    selectQuestions(selectableQuestions);
  };

  const transmitValues = () => {
    setTransmittingValues(true);
    const requests: Promise<CustomAttributeValueDTO | null>[] = [];
    selectedQuestions.forEach((questionId) => {
      const question = questions.find((q) => q.id === questionId);
      if (question) {
        const { targetAttributeId } = question;
        if (targetAttributeId && eventId) {
          const customerAnswer = QuestionnaireS.resolveQuestionAnswer(formSubmission, question);
          const fn = CustomAttributeS.patchAttributeValue(targetAttributeId, eventId, customerAnswer);
          requests.push(fn);
        }
      }
    });
    Promise.all(requests)
      .then(() => {
        ToastS.success('foo', 'Werte erfolgreich übermittelt');
        MixpanelS.track(ConstantS.TrackingEvents.AnswersTransmittedToAttributes);
        closeModal();
      })
      .catch(() => ToastS.generalError())
      .finally(() => setTransmittingValues(false));
  };

  return (
    <PanePaper title={`Einsendungen aus dem Fragebogen: ${shareName}`}>
      {!xs && (
        <>
          <Space h="xl" />
          <Group sx={{ width: '100%' }}>
            <Text pl="md" color="blue" variant="link" sx={{ width: 50, cursor: 'pointer' }} onClick={selectAll}>
              Alle
            </Text>
            <Group position="apart" sx={{ flexGrow: 1 }}>
              <Text weight="bolder">Einsendung</Text>
              <Text weight="bolder" pr={40}>
                Eigenes Feld
              </Text>
            </Group>
          </Group>
        </>
      )}
      <Box mt="sm" mb="xl" sx={{ borderBottom: '2px solid #e2e2e2' }} />
      <Stack spacing="xl">{questions.map(mapQuestionUI)}</Stack>
      <Group mt="xl" position="right">
        <Button
          disabled={selectedQuestions.length === 0 || transmittingValues}
          onClick={transmitValues}
          rightIcon={transmittingValues && <Loader size="xs" />}
        >
          Auswahl übertragen
        </Button>
      </Group>
    </PanePaper>
  );
};

export default QuestionnaireSubmission;
