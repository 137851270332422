import { useModals } from '@mantine/modals';
import { Accordion, ActionIcon, Box, Checkbox, Flex, Group, Select, TextInput } from '@mantine/core';
import { useDrag, useDrop } from 'react-dnd-latest';
import { IconChevronRight, IconTrashX } from '@tabler/icons';
import { i18n } from '@lingui/core';
import { FC, useEffect, useState } from 'react';
import { CustomModalHeader } from '../../../Molecules/Dialogs';
import DragButton from '../../../Atoms/DragDrop/DragButton';
import { DataS, LabelValuePair } from '../../../Service/DataS';
import { CustomAttributeS } from '../../../Service/Context/CustomAttributeS';
import PanePaper from '../../../Molecules/PanePaper';
import { CustomAttributeDTO, AttributeType } from '../../../Types/CustomAttributeT';
import CreatableMultiSelect from '../../../Atoms/inputs/CreatableMultiSelect';

interface Props {
  index: number;
  attribute: CustomAttributeDTO;
  edit: (patch: Partial<CustomAttributeDTO>) => void;
  remove: () => void;
  setAttributes: (attributes: CustomAttributeDTO[]) => void;
  groupsSelectOptions: LabelValuePair[];
}

const CustomAttributeFieldSettings: FC<Props> = ({
  index,
  attribute,
  edit,
  remove,
  setAttributes,
  groupsSelectOptions,
}) => {
  const { openContextModal } = useModals();

  const openCustomAttributeDeleteModalFlow = () =>
    openContextModal('customAttributeDeleteModalFlow', {
      title: <CustomModalHeader>Werte löschen</CustomModalHeader>,
      innerProps: {
        attributeId: attribute.id,
        reload: remove,
      },
      centered: true,
      size: 'md',
      closeOnEscape: true,
    });

  const {
    name: initialName,
    attributeType: initialCustomAttribute,
    description: initialDescription,
    groupId,
    selectable,
    suggestions,
  } = attribute;

  const [, drag] = useDrag(
    () => ({
      type: 'attribute',
      item: { attribute, index },
    }),
    [attribute.id],
  );

  const [, drop] = useDrop(
    () => ({
      accept: 'attribute',
      collect(monitor) {
        return { handlerId: monitor.getHandlerId() };
      },
      drop(item: any, monitor) {
        if (item.attribute.groupId === groupId && item.index !== index) {
          CustomAttributeS.moveAttribute(groupId, item.index, index).then((attributes) => {
            if (attributes) {
              setAttributes(attributes);
            }
          });
        }
      },
    }),
    [attribute.id],
  );

  useEffect(() => {
    setName(initialName);
  }, [initialName]);

  useEffect(() => {
    setDescription(initialDescription);
  }, [initialDescription]);

  useEffect(() => {
    setAttributeType(initialCustomAttribute);
  }, [initialCustomAttribute]);

  const [name, setName] = useState(initialName);
  const [description, setDescription] = useState(initialDescription);
  const [attributeType, setAttributeType] = useState(initialCustomAttribute);

  return (
    <Flex ref={drop} align="center">
      <div style={{ width: 30 }}>
        <DragButton dragRef={drag} />
      </div>
      <Accordion
        chevronPosition="left"
        chevron={<IconChevronRight size="1rem" />}
        styles={{
          chevron: {
            '&[data-rotate]': {
              transform: 'rotate(90deg)',
            },
          },
        }}
        variant="separated"
        defaultValue=""
        sx={{ flexGrow: 1 }}
      >
        <Accordion.Item value="foo">
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Accordion.Control>{name}</Accordion.Control>
            <ActionIcon size="lg">
              <IconTrashX size="1rem" color="black" onClick={openCustomAttributeDeleteModalFlow} />
            </ActionIcon>
          </Box>
          <Accordion.Panel>
            <PanePaper withShadow withoutBorder>
              <Group grow>
                <TextInput
                  size="xs"
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                  onBlur={(e) => edit({ name: e.currentTarget.value })}
                />
                <Select
                  size="xs"
                  data={DataS.getAttributeTypeOptions(i18n)}
                  label="Typ"
                  value={attributeType}
                  onChange={(val: AttributeType) => edit({ attributeType: val })}
                />
              </Group>
              <Group grow mb="sm" mt={5}>
                <TextInput
                  size="xs"
                  label="Beschreibung"
                  value={description}
                  onChange={(e) => setDescription(e.currentTarget.value)}
                  onBlur={(e) => edit({ description: e.currentTarget.value })}
                />
                <Select
                  size="xs"
                  data={groupsSelectOptions}
                  label="Gruppe"
                  value={groupId}
                  onChange={(val) => val && edit({ groupId: val })}
                />
              </Group>
              {attributeType === 'STRING' && (
                <Checkbox
                  mb={5}
                  size="xs"
                  label="Dropdown"
                  disabled={attributeType !== 'STRING'}
                  checked={selectable}
                  onChange={() => edit({ selectable: !selectable })}
                />
              )}
              {((attributeType === 'STRING' && selectable) || attributeType === 'MULTIPLE_CHOICE') && (
                <CreatableMultiSelect
                  creatable
                  searchable
                  size="xs"
                  label="Vorschläge"
                  data={suggestions}
                  value={suggestions}
                  onChange={(val) => edit({ suggestions: val })}
                />
              )}
            </PanePaper>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Flex>
  );
};

export default CustomAttributeFieldSettings;
