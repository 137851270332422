// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Text } from '@mantine/core';
import { FC, useContext } from 'react';
import { ProfileContext } from '../../../../Service/Context/ProfileContext';

const PartialInvoiceHeader: FC<{ desktopVersion: boolean }> = ({ desktopVersion }) => {
  const { profile } = useContext(ProfileContext);

  return (
    <Group spacing="xs" p={6} sx={{ backgroundColor: '#4A4A4A', borderRadius: 6, color: 'white', fontSize: 12 }}>
      {desktopVersion ? (
        <>
          <Text ml="md" sx={{ flexGrow: 1 }}>
            Abzüglich erhaltener Abschlagsrechnungen
          </Text>
          <Text sx={{ width: 100 }}>Netto</Text>
          {profile && !profile.smallScaleBusiness && <Text sx={{ width: 100 }}>USt</Text>}
          <Text sx={{ width: 100 }}>Brutto</Text>
          <div style={{ width: 75 }} />
        </>
      ) : (
        <Text ml="xs">Abschlagsrechnungen</Text>
      )}
    </Group>
  );
};

export default PartialInvoiceHeader;
