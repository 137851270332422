/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Button, Center, Group, Loader, LoadingOverlay, Space, Text, TextInput } from '@mantine/core';
import { i18n } from '@lingui/core';
import { closeAllModals, openConfirmModal, openModal } from '@mantine/modals';
import { FC, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import { DatePicker, TimeInput } from '@mantine/dates';
import { IconSearch } from '@tabler/icons';
import { addDays, startOfDay } from 'date-fns';
import ResourceS from '../../Service/ResourceS';
import { ToastS } from '../../Service/ToastS';
import ResourcePlanningS from '../../Service/restapi/resourcePlanningS';
import { PlanningCountReportDTO, Resource } from '../../Types/ResourceT';
import { ConstantS } from '../../Service/ConstantS';
import { MixpanelS } from '../../Service/MixpanelS';
import { ResourcesContext, ResourcesContextProvider } from '../../Service/Context/ResourcesContext';
import PaginationNav from '../../Molecules/Navigation/PaginationNav';
import AvailabilityTable from './table/availability/AvailabilityTable';

interface ModalProps {
  resource: Resource;
  onFinish: () => void;
}

export const openResouceArchiveModal = (props: ModalProps) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl" align="center">
        Ressource archiv
      </Text>
    ),
    children: <ArchiveModal {...props} />,
  });
};

const ArchiveModal: FC<ModalProps> = ({ resource, onFinish }) => {
  const { id: resourceId, name } = resource;
  const [archiving, setArchiving] = useState(false);
  const [loadingPlannings, setLoadingPlannings] = useState(false);
  const [countReport, setReport] = useState<PlanningCountReportDTO | null>(null);

  useEffect(() => {
    setLoadingPlannings(true);
    ResourcePlanningS.getPlanningsCountReport(resourceId)
      .then(setReport)
      .finally(() => setLoadingPlannings(false));
  }, [resourceId]);

  const archive = async () => {
    setArchiving(true);
    const resJSON = await ResourceS.update(resourceId, { archive: true });
    setArchiving(false);
    if (resJSON) {
      onFinish();
      closeAllModals();
      ToastS.success('resource-archived', 'Ressource wurde erfolgreich archiviert');
      MixpanelS.track(ConstantS.TrackingEvents.ResourceArchived);
    }
  };

  return (
    <>
      {loadingPlannings && (
        <Center sx={{ height: 120 }}>
          <Loader size="lg" />
        </Center>
      )}
      {!loadingPlannings && countReport && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {countReport.futurePlanningsCount === 0 && (
            <Text>
              Möchten Sie die Ressource{' '}
              <Text span weight="bolder">
                {name}
              </Text>{' '}
              wirklich archivieren? Eine zukünftige Planung ist danach nicht mehr möglich.
            </Text>
          )}
          {countReport.futurePlanningsCount > 0 && (
            <Text>
              Die Ressource ist in{' '}
              <Text span weight="bolder">
                {countReport.futurePlanningsCount}
              </Text>{' '}
              zukünftigen Planungen enthalten. Eine Archivierung ist erst möglich, wenn alle zukünftigen Planungen
              abgesagt werden.
            </Text>
          )}
        </>
      )}
      <Group position="right" spacing="xs" mt="xl">
        <Button color="gray" variant="subtle" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          disabled={archiving || (countReport !== null && countReport.futurePlanningsCount > 0)}
          onClick={archive}
          leftIcon={archiving && <Loader size="xs" />}
        >
          {i18n._('actions.archive')}
        </Button>
      </Group>
    </>
  );
};

export const openResouceDeleteModal = (props: ModalProps) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl" align="center">
        Ressource löschen
      </Text>
    ),
    children: <DeleteModal {...props} />,
  });
};

const DeleteModal: FC<ModalProps> = ({ resource, onFinish }) => {
  const { id: resourceId, name } = resource;
  const [deleting, setDeleting] = useState(false);
  const [loadingPlannings, setLoadingPlannings] = useState(false);
  const [countReport, setReport] = useState<PlanningCountReportDTO | null>(null);

  useEffect(() => {
    setLoadingPlannings(true);
    ResourcePlanningS.getPlanningsCountReport(resourceId)
      .then(setReport)
      .finally(() => setLoadingPlannings(false));
  }, [resourceId]);

  const archive = async () => {
    setDeleting(true);
    const deleted = await ResourceS.remove(resourceId);
    setDeleting(false);
    if (deleted) {
      onFinish();
      closeAllModals();
      ToastS.info('resource-deleted', `Ressource ${name} wurde gelöscht.`);
      MixpanelS.track(ConstantS.TrackingEvents.ResourceDeleted);
    }
  };

  return (
    <>
      {loadingPlannings && (
        <Center sx={{ height: 120 }}>
          <Loader size="lg" />
        </Center>
      )}
      {!loadingPlannings && countReport && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
          {countReport.pastPlanningsCount === 0 && (
            <Text>
              Möchten Sie die Ressource{' '}
              <Text span weight="bolder">
                {name}
              </Text>{' '}
              wirklich entfernen?
            </Text>
          )}
          {countReport.pastPlanningsCount > 0 && (
            <Text>
              Die Ressource{' '}
              <Text span weight="bolder">
                {name}
              </Text>{' '}
              kann nicht gelöscht werden, da {countReport.pastPlanningsCount} Planungen dafür vorhanden sind.
            </Text>
          )}
        </>
      )}
      <Group position="right" spacing="xs" mt="xl">
        <Button color="gray" variant="subtle" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          disabled={deleting || (countReport !== null && countReport.pastPlanningsCount > 0)}
          onClick={archive}
          leftIcon={deleting && <Loader size="xs" />}
          color="red"
          variant="subtle"
          sx={{ backgroundColor: '#FFEFEF' }}
        >
          {i18n._('actions.delete')}
        </Button>
      </Group>
    </>
  );
};

export const openChangeResourceTypeModal = (onConfirm: () => void) =>
  openConfirmModal({
    title: (
      <Text weight="bolder" size="xl" align="center">
        ⚠️ Verknüpfungen gehen verloren
      </Text>
    ),
    children: (
      <Text size="sm">
        In Deinem Case/Set sind Ressourcen als Inhalt hinzugefügt. Durch den Wechsel auf den Typ Item werden bestehende
        Verknüpfungen zu diesen Inhalten gelöscht. Möchtest Du fortfahren?
      </Text>
    ),
    labels: { confirm: 'Fortfahren', cancel: i18n._('actions.cancel') },
    onConfirm,
  });

export const openActivateAddonTrialModal = (onSuccess: () => unknown) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Test-Phase aktivieren
      </Text>
    ),
    children: <ActivateAddonTrialModal onSuccess={onSuccess} />,
  });

const ActivateAddonTrialModal: FC<{ onSuccess: () => unknown }> = ({ onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();

  const activate = async () => {
    setLoading(true);
    const activated = await ResourceS.activateAddonTrial();
    if (activated) {
      MixpanelS.track(ConstantS.TrackingEvents.AddonResourcePlanningTrialActivated, { pathname });
      onSuccess();
      closeAllModals();
    }
    setLoading(false);
  };

  return (
    <>
      <Text>
        Teste jetzt unser Ressourcen-Modul und verwalte deinen Vermietbestand - egal ob Deko-Artikel, Fotoboxen, Sound-
        & Licht-Equipment oder etwas ganz anderes.
      </Text>
      <Center mt="xl">
        <Button disabled={loading} onClick={activate} rightIcon={loading && <Loader size="xs" />}>
          Testphase starten
        </Button>
      </Center>
    </>
  );
};

export const openAddonMissingModal = () =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Dein Addon ist abgelaufen
      </Text>
    ),
    children: <AddonMissingModal />,
  });

const AddonMissingModal = () => {
  const navigate = useNavigate();

  const onClick = () => {
    closeAllModals();
    MixpanelS.track(ConstantS.TrackingEvents.AddonMissingModalClicked);
    navigate('/settings/plans');
  };

  return (
    <>
      <Text>
        Deine Testphase oder Dein Addon-Paket zum Ressourcen-Modul ist abgelaufen. Gehe jetzt in die Einstellungen, um
        das Addon wieder zu aktivieren.
      </Text>
      <Center mt="xl">
        <Button onClick={onClick}>Jetzt buchen</Button>
      </Center>
    </>
  );
};

export const openAvailabilityModal = () =>
  openModal({
    size: 'xl',
    title: (
      <Text weight="bolder" size="xl">
        Verfügbarkeit von Ressourcen
      </Text>
    ),
    children: (
      <ResourcesContextProvider initialFilter={{ showArchived: false, withContent: true }}>
        <CheckAvailabilityModal />
      </ResourcesContextProvider>
    ),
  });

const CheckAvailabilityModal = () => {
  const { resources, navigation, filter, updateFilter, loading } = useContext(ResourcesContext);
  const { totalCount, totalPages } = navigation;
  const { query, pageNumber, pageSize } = filter;

  const [from, setFrom] = useState(startOfDay(new Date()));
  const [to, setTo] = useState(startOfDay(addDays(new Date(), 1)));

  const updateQuery = useDebouncedCallback((val: string) => {
    updateFilter({ query: val });
  }, 500);

  const updateDateFrom = (val: Date) => {
    setFrom(new Date(val.getFullYear(), val.getMonth(), val.getDate(), from.getHours(), from.getMinutes()));
  };

  const updateTimeFrom = (val: Date) => {
    setFrom(new Date(from.getFullYear(), from.getMonth(), from.getDate(), val.getHours(), val.getMinutes()));
  };

  const updateDateTo = (val: Date) => {
    setTo(new Date(val.getFullYear(), val.getMonth(), val.getDate(), to.getHours(), to.getMinutes()));
  };

  const updateTimeTo = (val: Date) => {
    setTo(new Date(to.getFullYear(), to.getMonth(), to.getDate(), val.getHours(), val.getMinutes()));
  };

  return (
    <>
      <Center>
        <Box sx={{ maxWidth: 500 }}>
          <Group position="apart">
            <Group spacing={5}>
              <DatePicker
                value={from}
                onChange={(val) => val && updateDateFrom(val)}
                label="Von"
                variant="filled"
                sx={{ width: 120 }}
              />
              <TimeInput onChange={updateTimeFrom} value={from} label=" " variant="filled" />
            </Group>
            <Group spacing={5}>
              <DatePicker
                value={to}
                onChange={(val) => val && updateDateTo(val)}
                label="Bis"
                variant="filled"
                sx={{ width: 120 }}
              />
              <TimeInput onChange={updateTimeTo} value={to} label=" " variant="filled" />
            </Group>
          </Group>
          <TextInput
            mt="sm"
            defaultValue={query}
            variant="filled"
            placeholder="Ressourcen durchsuchen"
            icon={<IconSearch size={16} />}
            onChange={(e) => updateQuery(e.currentTarget.value)}
          />
        </Box>
      </Center>

      <Box pos="relative" sx={{ minHeight: 100 }}>
        <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
        <Space h="xl" />
        <AvailabilityTable resources={resources} from={from} to={to} />
        <PaginationNav
          pageSize={pageSize}
          pageNumber={pageNumber}
          totalCount={totalCount}
          totalPages={totalPages}
          entityName="Ressourcen"
          onChange={(newPageNumber) => updateFilter({ pageNumber: newPageNumber - 1 })}
        />
      </Box>
    </>
  );
};
