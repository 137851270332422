import React, { useContext } from 'react';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useLingui } from '@lingui/react';
import { useModalState } from '../../../Molecules/Dialogs';
import { patchDocument } from '../../../Service/restapi/documentService';
import { patchContract } from '../../../Service/restapi/contractService';
import { ToastS } from '../../../Service/ToastS';

const DraftStateChanger = ({ style, draft, entityType, entityId, handleChange, refreshEvent, disabled }) => {
  const { show, toggle } = useModalState(false);
  const { i18n } = useLingui();

  const styles = {
    statePoint: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      marginRight: 5,
    },
  };

  function patch(draft) {
    let functionCall = patchDocument;
    if (entityType === 'contracts') {
      functionCall = patchContract;
    }
    functionCall(entityId, { draft })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((document) => {
            handleChange(document);
          });
        } else if (resp.status === 412) {
          ToastS.error('patchFail', 'Dokument konnte nicht als Fertig markiert werden');
          ToastS.error('placeholder.unsatisfied', i18n._('placeholder.unsatisfied'));
        }
      })
      .catch(() => {
        ToastS.error('patchFail', 'Dokument konnte nicht als Fertig markiert werden');
      })
      .finally(() => {
        refreshEvent();
      });
  }

  return (
    <div
      style={{
        // width: 90,
        marginLeft: 10,
        display: 'flex',
        backgroundColor: '#ffffff',
        border: '1px solid #e5e5e5',
        borderRadius: 4,
        height: 34,
        padding: 0,
        ...style,
      }}
      className="draft-state-changer-container"
    >
      <ButtonDropdown isOpen={show} toggle={toggle} style={{ width: '100%' }}>
        <DropdownToggle
          disabled={!draft}
          caret={draft}
          color=""
          className="draft-state-changer-button"
          style={{ display: 'flex', alignItems: 'center', opacity: 1, cursor: 'cursor' }}
          size="sm"
        >
          {draft && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ ...styles.statePoint, backgroundColor: '#ffb100' }} />
              <div style={{ paddingRight: 10 }}>Entwurf</div>
            </div>
          )}

          {!draft && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ ...styles.statePoint, backgroundColor: '#4cd964' }} />
              <div style={{ paddingRight: 10 }}>Fertig</div>
            </div>
          )}
        </DropdownToggle>

        <DropdownMenu className="animate slideIn" style={{ minWidth: 100 }}>
          <DropdownItem
            onClick={() => {
              patch(true);
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ ...styles.statePoint, backgroundColor: '#ffb100' }} />
              <div>Entwurf</div>
            </div>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              patch(false);
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ ...styles.statePoint, backgroundColor: '#4cd964' }} />
              <div>Fertig</div>
            </div>
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

export default DraftStateChanger;
