// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Text, Tooltip } from '@mantine/core';
import { i18n } from '@lingui/core';
import { openModal } from '@mantine/modals';
import { IconMailFast, IconBackspace } from '@tabler/icons';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { RecommendationContext } from './RecommendationsPane';
import CheckAvailabilityModal from './Modals/CheckAvailabilityModal';
import RecommendationDeleteModal from './Modals/RecommendationDeleteModal';

const RecommendationsActions = () => {
  const { recommendations, reload, removeRecommendation, removeAll } = useContext(RecommendationContext);
  const { id: eventId } = useParams();
  // Use only recommendations, which weren't requested yet
  const filteredRecommendations = recommendations.filter((recommendation) => !recommendation.lastRequested);
  const { length } = filteredRecommendations;
  const isEmpty = length === 0;

  const openQueryAvailabilityModal = () =>
    openModal({
      size: 'md',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          {i18n._('recommendation.request.submit.head')}
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: <CheckAvailabilityModal recommendations={filteredRecommendations} reload={reload} />,
    });

  const openDeleteModal = () => {
    if (eventId) {
      openModal({
        title: (
          <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
            {i18n._('event.recommendations.delete.confirm.head')}
          </Text>
        ),
        closeOnEscape: true,
        transition: 'slide-down',
        children: (
          <RecommendationDeleteModal
            eventId={Number(eventId)}
            removeRecommendation={removeRecommendation}
            removeAllRecommendations={removeAll}
            contactEmail="foo"
          />
        ),
      });
    }
  };

  return (
    <Group
      p={5}
      position="center"
      sx={(theme) => ({
        backgroundColor: '#D2EBFC',
        color: theme.colors.blue[3],
        borderRadius: theme.radius.md,
        fontSize: theme.fontSizes.xs,
      })}
    >
      <Tooltip
        withArrow
        multiline
        disabled={!isEmpty}
        transition="slide-down"
        position="bottom"
        width={235}
        label=" Alle Dienstleister wurden bereits nach ihrer verfügbarkeit gefragt."
      >
        <Text
          sx={{ cursor: isEmpty ? 'default' : 'pointer', opacity: isEmpty ? 0.5 : 1 }}
          onClick={openQueryAvailabilityModal}
        >
          <IconMailFast size={16} /> Alle anfragen
        </Text>
      </Tooltip>
      <Text sx={{ cursor: 'pointer' }} onClick={openDeleteModal}>
        <IconBackspace size={16} />
        {'  '}
        Alle entfernen
      </Text>
    </Group>
  );
};

export default RecommendationsActions;
