/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Group, NumberInput, Stack, Text } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useModals } from '@mantine/modals';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import BookitupSegmentedControl from '../../../Atoms/BookitupSegmentedControl';
import { EventCustomerSelect, GeneralCustomerSelect } from '../../../Atoms/inputs/CustomerSelect';
import DocumentTemplateSelect from '../../../Atoms/inputs/DocumentTemplateSelect';
import { DataS } from '../../../Service/DataS';
import { ToastS } from '../../../Service/ToastS';
import { DocumentS } from '../../../Templates/Documents/DocumentS';
import { TestS } from '../../../TestS';

const CreateDownPaymentModal: FC<{
  documentId: number;
  initialCustomerId: number;
  eventParticipationContext: unknown;
}> = ({ documentId, initialCustomerId, eventParticipationContext }) => {
  const navigate = useNavigate();
  const { closeAll } = useModals();
  const form = useForm({
    initialValues: {
      amount: 30,
      amountUnit: 'PERCENTAGE',
      templateName: '',
      customerId: initialCustomerId,
    },
  });
  const onSubmit = () => {
    const { templateName, customerId, amount, amountUnit } = form.values;
    DocumentS.createPartialInvoice(documentId, customerId, { amount, amountUnit }, templateName).then((newDocument) => {
      if (newDocument) {
        const { eventId, id } = newDocument;
        closeAll();
        navigate(`${eventId ? `/events/${eventId}` : ''}/documents/${id}`);
      } else {
        ToastS.error('err', 'Dokument kann derzeit nicht erstellt werden. Bitte versuch es später.');
      }
    });
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Stack mt="xl">
        <Group spacing={0}>
          <Text mr="sm">Prozentsatz der Anzahlung</Text>
          <NumberInput hideControls {...form.getInputProps('amount')} sx={{ width: 55 }} />
          <BookitupSegmentedControl data={DataS.paymentUnitOptions} {...form.getInputProps('amountUnit')} />
        </Group>
        <DocumentTemplateSelect documentType="PARTIAL_INVOICE" {...form.getInputProps('templateName')} />
        {eventParticipationContext ? (
          <EventCustomerSelect
            initialValue={initialCustomerId.toString()}
            eventParticipationContext={eventParticipationContext}
            {...form.getInputProps('customerId')}
          />
        ) : (
          <GeneralCustomerSelect initialValue={initialCustomerId.toString()} {...form.getInputProps('customerId')} />
        )}
        <Button mb="xl" mt="md" type="submit" data-testid={TestS.Documents.Create.SAVE}>
          Rechnung erstellen
        </Button>
      </Stack>
    </form>
  );
};

export default CreateDownPaymentModal;
