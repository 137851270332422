import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_CALENDAR_SERVICE_URL;

export const putCalendarAccount = (type, id, token) =>
  ajaxActions
    .put(`${BASE_URL}/calendars/accounts`, {
      calendarId: id,
      token,
      type,
    })
    .then((resp) => resp);

export const checkForeignCalendarAvailability = (contactId, eventId) =>
  ajaxActions
    .get(`${BASE_URL}/calendar/checkForeignAvailability?contactId=${contactId}&eventId=${eventId}`)
    .then((resp) => resp);

export const getPrivateAndBusinessEvents = (dates, eventId) =>
  ajaxActions
    .post(`${BASE_URL}/calendar/clashes`, {
      dates,
      eventId,
    })
    .then((resp) => resp);

export const getCalendarShares = () => ajaxActions.get(`${BASE_URL}/calendars/shares`).then((resp) => resp);

export const getOwnCalendarAccesses = (calendarShareId) =>
  ajaxActions.get(`${BASE_URL}/calendars/access/own?calendarShareId=${calendarShareId}`).then((resp) => resp);

export const newCalendarShare = () => ajaxActions.put(`${BASE_URL}/calendars/shares`).then((resp) => resp);

export const deleteCalendarShare = (calendarShareId) =>
  ajaxActions.del(`${BASE_URL}/calendars/shares/${calendarShareId}`).then((resp) => resp);

export const getCalendarAccesses = () => ajaxActions.get(`${BASE_URL}/calendars/access`).then((resp) => resp);

export const saveCalendarAccess = (contactId) =>
  ajaxActions.put(`${BASE_URL}/calendars/access?contactId=${contactId}`).then((resp) => resp);

export const patchCalendarAccess = (calendarAccessId, patch) =>
  ajaxActions.put(`${BASE_URL}/calendars/access/${calendarAccessId}`, patch).then((resp) => resp);

export const deleteCalendarAccess = (calendarAccessId) =>
  ajaxActions.del(`${BASE_URL}/calendars/access/${calendarAccessId}`).then((resp) => resp);
