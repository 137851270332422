import { FC, useEffect, useState } from 'react';
import { EditableTaskProperty } from '../../../Atoms/EditableProperties/EditableTaskProperty';
import EditableTaskListItem from '../../../Atoms/inputs/EditableTaskListItem';
import { AgendaItem } from '../../../Types/AgendaItem';
import AgendaType from '../../../Types/Enums/AgendaType';
import { MixpanelS } from '../../../Service/MixpanelS';
import { ConstantS } from '../../../Service/ConstantS';

type Props = {
  task: AgendaItem;
  entityId?: number;
};

const TaskListItem: FC<Props> = ({ task, entityId }) => {
  const { name, completed, agendaType, lastModified } = task;

  const [value, setValue] = useState(completed);

  const handleChange = (val: any) => {
    setValue(val.completed);
    MixpanelS.track(ConstantS.TrackingEvents.TaskCompleted);
  };

  useEffect(() => {
    setValue(task.completed);
  }, [task]);

  switch (agendaType) {
    case AgendaType.ANSWER_REQUEST:
      return (
        <EditableTaskProperty
          entityId={entityId}
          handleChange={handleChange}
          value={{ completed: value, lastModified }}
          task={task}
          placeholder={name}
          editFormatter={EditableTaskListItem}
          editOnly
          labelFormatter={undefined}
        />
      );
    case AgendaType.FOLLOW_UP:
      return (
        <EditableTaskProperty
          entityId={entityId}
          handleChange={handleChange}
          value={{ completed: value, lastModified }}
          task={task}
          placeholder={name}
          editFormatter={EditableTaskListItem}
          editOnly
          labelFormatter={undefined}
        />
      );
    case AgendaType.STRING:
      return (
        <EditableTaskProperty
          entityId={entityId}
          handleChange={handleChange}
          value={{ completed: value, lastModified }}
          task={task}
          placeholder={name}
          editFormatter={EditableTaskListItem}
          editOnly
          labelFormatter={undefined}
        />
      );
    default:
      return (
        <EditableTaskProperty
          entityId={entityId}
          handleChange={handleChange}
          value={{ completed: value, lastModified }}
          task={task}
          placeholder={name}
          editFormatter={EditableTaskListItem}
          editOnly
          labelFormatter={undefined}
        />
      );
  }
};
export default TaskListItem;
