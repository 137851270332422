// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import {Box, Group, LoadingOverlay, Switch, Text, TextInput, ThemeIcon} from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import {IconInfoCircle, IconCircleKey, IconTrash, IconCheck} from '@tabler/icons';
import { FC, useState } from 'react';
import { LexOfficeConfig, LexOfficeS } from '../../../../Service/LexOfficeS';
import { RoutingS } from '../../../../Service/RoutingS';

interface Props {
  config: LexOfficeConfig;
  updateConfig: (patch: Partial<LexOfficeConfig>) => void;
}

const LexOfficeApiPanel: FC<Props> = ({ config, updateConfig }) => {
  const [loading, setLoading] = useState(false);
  const { apiKey, useBookitupPDF, userEmail } = config;
  const generateApiKey = () => RoutingS.openInNewTab(`${LexOfficeS.LOX_BASE_URL}/addons/public-api`);

  const revokeApiKey = async () => {
    setLoading(true);
    updateConfig({ apiKey: '' });
    setLoading(false);
  };

  const confirmRevokeApiKey = () => {
    openConfirmModal({
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          lexoffice Verbindung trennen
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      labels: { confirm: 'Ja', cancel: 'Abbrechen' },
      children: <Text>Möchtest du wirklich die lexoffice Verbindung trennen?</Text>,
      onConfirm: revokeApiKey,
    });
  };

  return (
    <Box
      p="md"
      sx={{
        position: 'relative',
        borderRadius: 10,
        backgroundColor: '#F3F3F3',
        maxWidth: 380,
        border: apiKey && !useBookitupPDF ? '2px solid #1D5191' : 'none',
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'lg' }} />
      <Group position="apart">
        <Group spacing={5}>
          <Text weight="bolder">lexoffice API-Schlüssel</Text>
          {(apiKey && !useBookitupPDF) && <ThemeIcon radius="xl" size="xs">
            <IconCheck/>
          </ThemeIcon>}
        </Group>
        <Switch
          disabled={!apiKey || apiKey === ''}
          checked={apiKey !== '' && !useBookitupPDF}
          onChange={(event) => updateConfig({ useBookitupPDF: !event.currentTarget.checked })}
        />
      </Group>
      <Group spacing={5} ml={-2}>
        <div>
          <IconInfoCircle size={16} color="red" />
        </div>
        <Text size="xs" mt={5}>
          Funktioniert nur mit dem Paket lexoffice XL.
        </Text>
      </Group>
      {!apiKey ? (
        <>
          <Text variant="link" mt="sm" mb="sm" onClick={generateApiKey} sx={{ cursor: 'pointer' }}>
            Neuen API-Schlüssel generieren
          </Text>
          <TextInput
            mt="md"
            mb="md"
            icon={<IconCircleKey size={16} color={apiKey ? '#1D5191' : 'gray'} />}
            defaultValue={apiKey}
            onBlur={(e) => updateConfig({ apiKey: e.currentTarget.value })}
          />
        </>
      ) : (
        <>
          <Text weight="bolder" mt="md">
            Dein Account
            <Text span color="blue">
              {` ${userEmail} `}
            </Text>
            ist verbunden.
          </Text>

          <Text variant="link" mb="xl" mt="xs" color="dimmed" sx={{ cursor: 'pointer' }} onClick={confirmRevokeApiKey}>
            <IconTrash size={16} color="gray" style={{ marginTop: -2 }} />
            <Text span color="dimmed" size="xs" ml={5}>
              Verbindung löschen
            </Text>
          </Text>
        </>
      )}
      <Text size="xs" weight="bolder" mb="sm">
        lexoffice Rechnungen
      </Text>
      <Text size="xs">
        Du erstellst Deine Rechnungen in bookitup. Wenn Du sie an lexoffice sendest, generiert lexoffice eine neue
        Rechnung mit dem dort eingestellten Layout. Diese Rechnungen werden als PDF zurück an bookitup gesendet.
        Fortlaufende Dokument-Nummern werden von lexoffice verwendet.
      </Text>
    </Box>
  );
};

export default LexOfficeApiPanel;
