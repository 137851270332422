import { Skeleton } from '@mantine/core';
import { FC } from 'react';
import Dot from '../../../../Atoms/Dot';
import { RecommendationS } from '../../../../Service/RecommendationS';
import { RequestAnswer } from '../../../../Types/RecommendationT';

const ContactAvailabilityDot: FC<{ requestAnswer?: RequestAnswer }> = ({ requestAnswer }) => {
  const DOT_SIZE = 8;

  if (!requestAnswer) {
    return <Skeleton circle height={DOT_SIZE} />;
  }
  return <Dot withoutShadow size={DOT_SIZE} colour={RecommendationS.getColourByAnswer(requestAnswer)} />;
};

export default ContactAvailabilityDot;
