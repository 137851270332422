import React, { useContext } from 'react';
import { TransButtonPrimary } from '../../Atoms/Buttons';
import { Pane } from '../../Molecules/Pane';
import { AuthContext } from '../../Service/Context/AuthContext';

export const UpgradePanel = ({ component, licence, upgradeTitle, upgradeBody, title, headAddition, style }) => {
  const { jwtClaims } = useContext(AuthContext);

  if (jwtClaims.licence !== licence) {
    const styles = {
      container: {
        textAlign: 'center',
        lineHeight: 1.5,
        maxWidth: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      subject: {
        fontWeight: 800,
        lineHeight: 1.2,
        marginTop: 10,
        marginBottom: 10,
      },
      upgradeButton: {
        margin: 20,
      },
    };

    return (
      <Pane
        style={style}
        title={title}
        headAddition={headAddition}
        component={
          <div style={styles.container}>
            <h5 style={styles.subject}>{upgradeTitle}</h5>
            {upgradeBody}

            {licence === 'INTERACTIVE' && (
              <div>
                <br />
                <b>Dieses Modul befindet sich aktuell in Entwicklung.</b> <br />
                Schreib uns gerne, wenn Du beim Start informiert werden möchtest.
              </div>
            )}
            <TransButtonPrimary
              style={styles.upgradeButton}
              onClickHandler={() => {
                window.open('https://bookitup.de/paket-buchen');
              }}
              text="actions.learn-more"
            />
          </div>
        }
      />
    );
  }

  return <div>{component}</div>;
};
