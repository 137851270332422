// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Skeleton, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateQuestionnaireConfigDTO } from '../../../../../Types/LogT';
import { QuestionnaireS } from '../../../../../Service/QuestionnaireS';
import { QuestionnaireTemplateDto } from '../../../../../Types/QuestionnaireT';

const CreateQuestionnaireProcedure: FC<{ config: unknown }> = ({ config }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState<QuestionnaireTemplateDto>();
  const { templateId } = config as CreateQuestionnaireConfigDTO;

  useEffect(() => {
    if (templateId) {
      setLoading(true);
      QuestionnaireS.fetchQuestionnaireTemplate(templateId)
        .then((resJSON) => {
          if (resJSON) {
            setTemplate(resJSON);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [templateId]);

  if (templateId) {
    return (
      <Text>
        Fragebogen aus Vorlage{' '}
        {loading || !template ? (
          <Skeleton mb={-3} height={16} width={100} sx={{ display: 'inline-block' }} />
        ) : (
          <Text
            span
            variant="link"
            color="blue"
            sx={{ cursor: 'pointer' }}
            weight="bolder"
            onClick={() => navigate(`/settings/template/questionnaire/${template.name}`)}
          >
            {template.name}
          </Text>
        )}
        {'  '}erstellen.
      </Text>
    );
  }
  return <Text color="red">Die Fragebogenvorlage wurde nicht ausgewählt</Text>;
};

export default CreateQuestionnaireProcedure;
