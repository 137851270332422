/* eslint-disable react/jsx-props-no-spreading */
import {Button, Center, Loader, NumberInput, Select, Skeleton, Stack, Text, TextInput} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, {FC, useEffect, useState} from 'react';
import {putFavoritePosition} from "../../../Service/restapi/positionService";
import {ToastS} from "../../../Service/ToastS";
import {createResourcePositionFavorite} from "../../../Service/restapi/documentService";
import {MixpanelS} from "../../../Service/MixpanelS";
import {ConstantS} from "../../../Service/ConstantS";
import {closeAllModals} from "@mantine/modals";
import {DataS} from "../../../Service/DataS";
import {IconChevronDown} from "@tabler/icons";
import {Resource} from "../../../Types/ResourceT";
import ResourceS from "../../../Service/ResourceS";

interface Props {
  interceptViolation: (httpResponse: Response) => void;
  resourceId: string;
  onSave: () => void;
  isSmallScaleBusiness: boolean;
}

const CreateResourcePositionModal: FC<Props> = ({ interceptViolation, resourceId, onSave, isSmallScaleBusiness }) => {
    const [resource, setResource] = useState<Resource | null>();

    const form = useForm({
        initialValues: {
            label: resource?.name ? resource?.name : '',
            subtext: '',
            netPriceUnit: 0,
            tax: 19,
        },
        validate: {
            label: (value) => (value.length > 0 ? null : 'Bitte gib eine Beschreibung ein'),
        },
    });

    useEffect(() => {
        ResourceS.fetchById(resourceId).then(resource => {
            setResource(resource);
            form.values.label = resource?.name ? resource?.name : '';
        });
    }, [resourceId]);

  const [loading, setLoading] = useState(false);

  const createProduct = () => {
    putFavoritePosition({...form.values, priceUnit: form.values.netPriceUnit * (1 + (form.values.tax / 100))})
        .then((resp) => {
          if (resp.ok) {
            ToastS.success('save-favourite', 'Position als Favorit gespeichert.');
            resp.json().then(position => {
              createResourcePositionFavorite({positionId: position.id, resourceId })
                  .then((resp) => {
                    if (resp.ok) {
                      MixpanelS.track(ConstantS.TrackingEvents.ResourceAssignedToProduct);
                    } else {
                      console.error(`could not save resource ${resourceId} to position ${position.id}`);
                    }
                  })
                  .finally(() => {
                    closeAllModals();
                    onSave();
                  });
            })

          } else {
            ToastS.error('save-favourite', 'Position konnte nicht speichert werden');
          }
        })
        .catch(() => ToastS.error('save-favourite', 'Position konnte nicht speichert werden'));

  };

  return (
    <form onSubmit={form.onSubmit(createProduct)}>
      <Stack spacing="xs">
        <TextInput label="Beschreibung" {...form.getInputProps('label')} />
        <NumberInput
            label="Einzelpreis (netto)"
            placeholder="Do not enter more that 2"
            hideControls
            decimalSeparator=","
            precision={2}
            defaultValue={2.2}
            {...form.getInputProps('netPriceUnit')}
        />
          {!isSmallScaleBusiness && (
              <Select
                  label={
                      <Text size="xs">
                          MwSt
                      </Text>
                  }
                  {...form.getInputProps('tax')}
                  data={DataS.taxRateOptions}
                  sx={{ width: 60 }}
                  styles={{ rightSection: { pointerEvents: 'none' } }}
                  rightSection={<IconChevronDown size={14} />}
                  rightSectionWidth={25}
              />
          )}
        <Center mb="md" mt="md">
          <Button
            type="submit"
            disabled={loading || !form.isValid()}
            leftIcon={loading && <Loader size="xs" />}
            sx={{ width: 200 }}
          >
            Speichern
          </Button>
        </Center>
      </Stack>
    </form>
  );
};

export default CreateResourcePositionModal;
