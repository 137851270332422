import { AgendaItem, CreateAgendaItem } from '../../Types/AgendaItem';
import RecurringTask from '../../Types/RecurringTask';
import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_TASK_SERVICE_URL;

export const fetchTaskGroups = (entityType?: string): Promise<Response> =>
  ajaxActions.get(`${BASE_URL}${entityType ? `/${entityType}` : ``}/agendaGroups`).then((resp) => resp);

export const fetchTasksForGroup = (groupId: string, entityId?: string) =>
  ajaxActions
    .get(`${BASE_URL}/agendaGroups/${groupId}/agendaItems${entityId ? `?entityId=${entityId}` : ''}`)
    .then((resp) => resp);

export const fetchTasks = (page: number, start: Date, end: Date, groupIds?: string[], includeCompleted?: boolean) => {
  const dateUrlFragment = `&start=${start.toISOString()}&end=${end.toISOString()}`;
  const groupUrlFragment = groupIds ? groupIds.map((gid) => `&groupIds=${gid}`).reduce((a, b) => `${a}&${b}`) : '';
  const includeCompleteFragment = includeCompleted !== undefined ? `&includeCompleted=${includeCompleted}` : '';

  return ajaxActions
    .get(`${BASE_URL}/agendaItems?page=${page}${dateUrlFragment}${groupUrlFragment}${includeCompleteFragment}`)
    .then((resp) => resp);
};

export const createTask = (payload: Partial<CreateAgendaItem>) =>
  ajaxActions.put(`${BASE_URL}/agendaItems`, payload).then((resp) => resp);

export const patchTask = (taskId: string, payload: Partial<AgendaItem>) =>
  ajaxActions.patch(`${BASE_URL}/agendaItems/${taskId}`, payload).then((resp) => resp);

export const createTaskGroup = (entityType: string, name: string) =>
  ajaxActions.put(`${BASE_URL}/${entityType}/agendaGroups`, { name }).then((resp) => resp);

export const deleteTask = (taskId: string) => ajaxActions.del(`${BASE_URL}/agendaItems/${taskId}`).then((resp) => resp);

export const createRecurringTask = (payload: Partial<RecurringTask>) =>
  ajaxActions
    .post(`${BASE_URL}/recurringTasks`, {
      ...payload,
      enabled: true,
    })
    .then((resp) => resp);

export const createRecurringEventTasks = (taskId: string) =>
  ajaxActions.put(`${BASE_URL}/recurringTasks/${taskId}/createTasks`).then((resp) => resp);

export const deleteRecurringEventTasks = (taskId: string) =>
  ajaxActions.del(`${BASE_URL}/recurringTasks/${taskId}/deleteTasks`).then((resp) => resp);

export const fetchRecurringTasks = () => ajaxActions.get(`${BASE_URL}/recurringTasks`).then((resp) => resp);

export const deleteRecurringTask = (taskId: string) =>
  ajaxActions.del(`${BASE_URL}/recurringTasks/${taskId}`).then((resp) => resp);
