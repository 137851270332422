import { useEffect, useState } from 'react';
import SelectFieldList from '../../../Organismns/Settings/SelectFieldList';
import { Pane } from '../../../Molecules/Pane';
import { TransButtonLinkWithIcon } from '../../../Atoms/Buttons';
import { ConfirmDialog, useConfirmModalState, useModalState } from '../../../Molecules/Dialogs';
import { deleteWebForm } from '../../../Service/restapi/webformService';
import TooltipButton from '../../../Atoms/buttons/TooltipButton';
import { EditableCheckbox } from '../../../Atoms/inputs/EditableCheckbox';
import { EditableWebFormProperty } from '../../../Atoms/EditableProperties/EditableWebFormProperty';

const WebForm = ({ form: initialForm, reloadCallback }) => {
  const { show, toggle } = useModalState();
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);
  const [form, setForm] = useState(initialForm);

  useEffect(() => {
    setForm(initialForm);
  }, [initialForm]);

  function deleteForm() {
    deleteWebForm(form.id)
      .then((resp) => {
        if (resp.ok) {
          console.log(`successfully deleted webForm ${form.id}`);
        } else {
          console.error(`could not delete webForm ${form.id}`);
        }
        reloadCallback();
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not delete webForm ${form.id}`);
      });
  }

  return (
    <>
      <Pane
        head={
          <div onClick={toggleConfirm} style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex' }}>
              <div>{`Verbundenes Formular auf Seite: ${form.hostname ? form.hostname : ''}${form.path}`}</div>
              {show && (
                <TooltipButton
                  icon="CARET_UP"
                  isLoading={false}
                  id={`showHide-webForm-${form.id}`}
                  style={{ marginRight: 10 }}
                  text="ein/ausblenden"
                  onClick={toggleConfirm}
                />
              )}
              {!show && (
                <TooltipButton
                  icon="CARET_DOWN"
                  isLoading={false}
                  id={`showHide-webForm-${form.id}`}
                  style={{ marginRight: 10 }}
                  text="ein/ausblenden"
                  onClick={toggleConfirm}
                />
              )}
            </div>
            <div style={{ display: 'flex' }}>
              <EditableWebFormProperty
                patchField="active"
                changeHandler={setForm}
                entity={form}
                placeholder="Aktiv"
                editFormatter={EditableCheckbox}
                editOnly
              />
              <TransButtonLinkWithIcon
                icon="TRASH"
                style={{ float: 'right' }}
                onClick={toggle}
                text="Löschen"
                title="webForm.delete"
              />
            </div>
          </div>
        }
        component={<>{showConfirm && <SelectFieldList webForm={form} />}</>}
      />

      <ConfirmDialog
        text="webForm.delete.confirm"
        subject="webForm.delete.confirm.head"
        open={show}
        toggle={toggle}
        handler={deleteForm}
      />
    </>
  );
};

export default WebForm;
