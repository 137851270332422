import { FC, useEffect, useState } from 'react';
import { Autocomplete, Checkbox, Group, Loader, NumberInput, Select, Text, TextInput } from '@mantine/core';
import { i18n } from '@lingui/core';
import { ChronoUnit, CreateTaskConfigDTO, ExecutionStrategy, WorkflowConfigProps } from '../../../../../Types/LogT';
import AgendaGroup from '../../../../../Types/AgendaGroup';
import { fetchTaskGroups } from '../../../../../Service/restapi/taskService';
import { DataS } from '../../../../../Service/DataS';

const CreateTaskConfig: FC<WorkflowConfigProps> = ({ config, handleChange }) => {
  const { name, groupName, priority, saveUnderEvent, notes, relativeAmount, relativeUnit, dueDateStrategy } =
    config as CreateTaskConfigDTO;
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState<AgendaGroup[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchTaskGroups(saveUnderEvent ? 'events' : 'profiles')
      .then((res) => {
        if (res.ok) {
          res.json().then(setGroups);
        }
      })
      .finally(() => setLoading(false));
  }, [saveUnderEvent]);

  return (
    <>
      <Group grow>
        <TextInput
          required
          label="Name"
          defaultValue={name}
          onBlur={(e) => handleChange({ name: e.currentTarget.value })}
        />
        <Autocomplete
          required
          label="Gruppe"
          data={groups.map((group) => group.name)}
          defaultValue={groupName ?? ''}
          rightSection={loading && <Loader size="xs" />}
          onBlur={(e) => handleChange({ groupName: e.currentTarget.value })}
        />
      </Group>
      <TextInput
        mt="xs"
        label="Beschreibung"
        defaultValue={notes}
        onBlur={(e) => handleChange({ notes: e.currentTarget.value })}
      />
      <Text mt="xs" sx={(theme) => ({ color: theme.colors.gray[9] })}>
        Erledigen bis
      </Text>
      <Group spacing={5}>
        <NumberInput
          hideControls
          defaultValue={relativeAmount}
          min={0}
          sx={{ width: 50 }}
          onBlur={(e) => handleChange({ relativeAmount: Number(e.currentTarget.value) })}
        />
        <Select
          defaultValue={relativeUnit}
          data={DataS.getChronoUnitsData(i18n)}
          sx={{ width: 100 }}
          onChange={(val: ChronoUnit) => val && handleChange({ relativeUnit: val })}
        />
        <Select
          sx={{ flexGrow: 1 }}
          defaultValue={dueDateStrategy}
          data={DataS.getExecutionStrategyOptions(i18n)}
          onChange={(val: ExecutionStrategy) => val && handleChange({ dueDateStrategy: val })}
        />
      </Group>
      <Checkbox
        size="xs"
        mt="sm"
        defaultChecked={priority}
        label="Als wichtige Aufgabe markieren"
        onChange={(e) => handleChange({ priority: e.currentTarget.checked })}
      />
      <Checkbox
        size="xs"
        defaultChecked={saveUnderEvent}
        mt="xs"
        label="Aufgabe in Event speichern"
        onChange={(e) => handleChange({ saveUnderEvent: e.currentTarget.checked, groupName: null })}
      />
    </>
  );
};

export default CreateTaskConfig;
