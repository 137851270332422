import EmailsSection from '../../Organismns/Message/EmailsSection';
import { EmailBoxContextProvider } from '../../Service/Context/EmailBoxContext';

const MessagingWrapper = () => (
  <EmailBoxContextProvider>
    <EmailsSection />
  </EmailBoxContextProvider>
);

export default MessagingWrapper;
