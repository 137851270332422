import { FC, useEffect, useState } from 'react';
import { Code, Group, Skeleton, Text } from '@mantine/core';
import { i18n } from '@lingui/core';
import { useNavigate } from 'react-router-dom';
import { IconPaperclip } from '@tabler/icons';
import { SendMailConfigDTO } from '../../../../../Types/LogT';
import { MessageS } from '../../../../../Service/MessageS';
import { MessageTemplateDto } from '../../../../../Types/MessageT';

const SendEmail: FC<{ config: unknown }> = ({ config }) => {
  const { templateId, attachments } = config as SendMailConfigDTO;
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState<MessageTemplateDto>();
  const navigate = useNavigate();

  useEffect(() => {
    if (templateId) {
      setLoading(true);
      MessageS.fetchTemplate(templateId)
        .then((resJSON) => {
          if (resJSON) {
            setTemplate(resJSON);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [templateId]);

  if (!templateId) {
    return <Text color="red">Ungültige Konfiguration</Text>;
  }

  const onClick = () => {
    if (template) {
      const { messageType, name } = template;
      if (messageType === 'CUSTOM') {
        navigate(`/settings/template/message/custom/${name}`);
      } else {
        navigate(`/settings/template/message/${messageType.toLocaleLowerCase()}`);
      }
    }
  };

  return (
    <>
      <Text>
        E-Mail{' '}
        {loading || !template ? (
          <Skeleton mb={-3} height={16} width={100} sx={{ display: 'inline-block' }} />
        ) : (
          <Text span weight="bolder" variant="link" color="blue" sx={{ cursor: 'pointer' }} onClick={onClick}>
            {` ${template.name || i18n._(`message.type.${template.messageType.toLowerCase()}`)}`}
          </Text>
        )}
        {'  '}an den Hauptkunden senden.
      </Text>
      {attachments.length > 0 && (
        <Group spacing={5}>
          {attachments.map((attachment) => (
            <Code key={attachment.id}>
              <IconPaperclip size={16} /> {attachment.label}
            </Code>
          ))}
        </Group>
      )}
    </>
  );
};

export default SendEmail;
