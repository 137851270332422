const BASE_URL = process.env.REACT_APP_AUTH_SERVICE_URL;

export const userActions = {
  login,
  logout,
  register,
  activateAccount,
  passwordConstraints,
  update,
};

function passwordConstraints(password, passwordRepeat) {
  const flag = true;
  if (password === undefined || password == null || password === '') return false;
  if (password !== passwordRepeat) return false;

  return flag;
}

function activateAccount(url, username, password) {
  const user = { username, password };
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(url, requestOptions)
    .then((response) => response)
    .catch((response) => {
      throw response;
    });
}

function login(username, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify({ username, password }),
  };

  return fetch(`${BASE_URL}/login`, requestOptions)
    .then((response) => {
      if (!response.ok) {
        return response;
      }

      return response;
    })
    .catch((response) => {
      throw response;
    });
}

function logout() {
  localStorage.removeItem('jwt');
}

function register(url, user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(url, requestOptions)
    .then((response) => {
      if (!response.ok) {
        return response;
      }

      return response;
    })
    .catch((response) => response);
}

function update(url, user, toastManager) {
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(user),
  })
    .then((response) => {
      if (response.ok) {
        toastManager.add('Erfolgreich gespeichert', { autoDismiss: true, appearance: 'success' });
      }
      return response;
    })
    .catch(() => {
      toastManager.add('Es ist ein Fehler aufgetreten', { autoDismiss: true, appearance: 'error' });
    });
}
