import { ajaxActions } from '../AjaxActions';
import { ToastS } from '../ToastS';

const BASE_URL = process.env.REACT_APP_PAYMENT_SERVICE_URL;

export type RecruitmentCampaign = {
  id: string;
  name: string;
  registrations: number;
  earnedCredits: number;
};

/**
 * Get all recruitment campaigns of that user.
 * @returns {Promise<unknown>}
 */
const getRecruitmentCampaigns = async (): Promise<RecruitmentCampaign[] | null> => {
  const resp = await ajaxActions.get(`${BASE_URL}/recruitmentCampaigns`);
  if (resp.ok) {
    return resp.json();
  }
  ToastS.generalError();
  return null;
};

/**
 * Create a new recruitment campaign.
 * @param name name of the campaign.
 * @returns {Promise<unknown>}
 */
const createRecruitmentCampaign = async (name: string): Promise<RecruitmentCampaign | null> => {
  const resp = await ajaxActions.put(`${BASE_URL}/recruitmentCampaigns`, { name });
  if (resp.ok) {
    return resp.json();
  }
  ToastS.generalError();
  return null;
};

/**
 * Update properties of the recruitment campaign.
 * @param id id of the recruitment campaign.
 * @param patch
 * @returns {Promise<unknown>}
 */
const updateRecruitmentCampaign = async (id: string, patch: Partial<RecruitmentCampaign>) => {
  const resp = await ajaxActions.patch(`${BASE_URL}/recruitmentCampaigns/${id}`, patch);
  if (resp.ok) {
    return resp.json();
  }
  ToastS.generalError();
  return null;
};

/**
 * Get all recruitments of a campaign.
 * @param id id of the recruitment campaign.
 * @returns {Promise<unknown>}
 */
const getRecruitments = (id: string) =>
  ajaxActions.get(`${BASE_URL}/recruitmentCampaigns/${id}/recruitments`).then((resp) => resp);

/**
 * Get profile alias of the owner of the campaign.
 * @param id id of the recruitment campaign.
 * @returns {Promise<*>}
 */
const getRecruitmentCampaignProfileAlias = async (id: string): Promise<string | null> => {
  const resp = await ajaxActions.get(`${BASE_URL}/recruitmentCampaigns/${id}/profileAlias`);
  if (resp.ok) {
    return resp.text();
  }
  return null;
};

// eslint-disable-next-line import/prefer-default-export
export const RecruitmentS = {
  getRecruitmentCampaigns,
  createRecruitmentCampaign,
  updateRecruitmentCampaign,
  getRecruitmentCampaignProfileAlias,
  getRecruitments,
};
