import { FC } from 'react';
import ReactPlayer from 'react-player';
import { useWistiaChannel } from '../../Service/hooks/useWistiaChannel';
import { MixpanelS } from '../../Service/MixpanelS';
import { ConstantS } from '../../Service/ConstantS';
import { useJwtClaims } from '../../Service/Context/AuthContext';
import { useVideoMonitoring } from '../../Service/hooks/useVideoMonitoring';

interface Props {
  mediaId: string;
}

const WistiaVideoPlayer: FC<Props> = ({ mediaId }) => {
  const { channel, currentEpisode } = useWistiaChannel();
  const { profileId } = useJwtClaims();

  const trackProps = {
    channel: channel?.name,
    episode: currentEpisode?.title,
  };
  const config = {
    wistia: {
      options: {
        email: profileId,
      },
    },
  };
  const { onProgress, onSeek } = useVideoMonitoring(mediaId, trackProps);
  const track = (event: string) => MixpanelS.track(event, trackProps);

  return (
    <div className="player-wrapper">
      <ReactPlayer
        controls
        className="react-player"
        width="100%"
        height="100%"
        url={`https://bookitup.wistia.com/medias/${mediaId}`}
        onStart={() => track(ConstantS.TrackingEvents.AcademyVideoStarted)}
        onPause={() => track(ConstantS.TrackingEvents.AcademyVideoPaused)}
        onEnded={() => track(ConstantS.TrackingEvents.AcademyVideoEnded)}
        progressInterval={5000} // The time between onProgress callbacks, in milliseconds
        onProgress={onProgress}
        onSeek={onSeek}
        config={config}
      />
    </div>
  );
};

export default WistiaVideoPlayer;
