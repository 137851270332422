import { FC, useContext } from 'react';
import { useLingui } from '@lingui/react';
import { Text } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import MultipleItemSelector from '../../../Molecules/Document/MultipleItemSelector';
import { TransButtonLink } from '../../../Atoms/Buttons';
import { InputField } from '../../../Atoms/InputFields';
import { Icon } from '../../../Atoms/Icons';
import { ContactDeleteFlowContext } from './ContactDeleteFlowContext';

const ContactDeleteFlow1: FC = () => {
  const { documents, documentIds, isLoading, deleteSelectedDocs, toggleDocument, selectAllDocuments } =
    useContext(ContactDeleteFlowContext);

  const { i18n } = useLingui();

  if (documents?.length === 0) {
    return (
      <div>
        <Icon value="CHECK" /> Keine verknüpften Dokumente zu diesem Kontakt.
      </div>
    );
  }

  const confirmDeleteDocuments = () => {
    openConfirmModal({
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          {i18n._('common.delete.documents.confirm')}
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      labels: { confirm: 'Ja', cancel: 'Abbrechen' },
      children: <Text>{i18n._('common.delete.documents.confirm')}</Text>,
      onConfirm: () => setTimeout(deleteSelectedDocs, 500),
    });
  };

  return (
    <div>
      <div>
        Um diesen Kontakt zu löschen, musst Du vorher {documents?.length} an ihn addressierten Dokumente löschen:
      </div>

      <MultipleItemSelector items={documents ?? []} selectedItems={documentIds} toggleItem={toggleDocument} />
      {documents && documents?.length > 1 && (
        <div style={{ marginTop: 5, marginBottom: 5 }}>
          <InputField
            checked={documents?.length === documentIds.length}
            toggle={selectAllDocuments}
            text={i18n._('common.select.all')}
          />
        </div>
      )}
      <TransButtonLink
        isLoading={isLoading}
        disabled={documentIds.length === 0}
        style={{ color: '#9499a7' }}
        icon="TRASH"
        className="primary-link"
        onClick={confirmDeleteDocuments}
        text="Dokumente löschen"
      />
    </div>
  );
};

export default ContactDeleteFlow1;
