import React, {FC, useEffect, useState} from 'react';
import {ArcElement, Chart as ChartJS, Legend, Tooltip} from 'chart.js';
import {Pie} from 'react-chartjs-2';
import {Skeleton, Text} from "@mantine/core";
import {fetchEventKindStatistic} from "../../Service/restapi/statisticService";
import PanePaper from "../../Molecules/PanePaper";


ChartJS.register(ArcElement, Tooltip, Legend);

interface StatisticData {
  labels: string[],
  datasets: StatisticDataset[]
}

interface StatisticDataset {
  label: string,
  data: number[],
  backgroundColor: string[],
  borderColor: string[],
  borderWidth: number
}

export const options = {
  plugins: {
    legend: {
      display: true,
      position: 'right' as const,
    },
  },
  responsive: true,
};

interface Props {
  year: string | null,
  leadOrigin: string | null,
  hasProperLicence: boolean}

const EventKindStatistic: FC<Props> = ({year, leadOrigin, hasProperLicence}) => {

  const [statisticData, setStatisticData] = useState<StatisticData>();

  useEffect(() => {
    if (hasProperLicence) {
      setStatisticData(undefined);
      const abortController = new AbortController();
      fetchEventKindStatistic(year, leadOrigin, abortController.signal)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then(setStatisticData);
          } else {
            console.error(`could not load event kind statistics`);
          }
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not load event kind statistics`);
        });
      return () => {
        abortController.abort();
      };
    }

  }, [year, leadOrigin, hasProperLicence]);

  return (

      <PanePaper
        title="Auftragsarten"
      >
        <Text italic color="dimmed" size="xs" weight="lighter">
          Hinweis: Du kannst den Auftragsarten in den Einstellungen eine Farbe zuordnen.
        </Text>
        {(!statisticData && hasProperLicence) && <Skeleton radius="sm" height={35} width="100%" />}
        {statisticData && <Pie data={statisticData} options={options} />}

      </PanePaper>
  );

}

export default EventKindStatistic;
