import { Anchor, Box, Breadcrumbs, Divider, Flex } from '@mantine/core';
import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useElementSize } from '@mantine/hooks';
import { useWistiaChannel } from '../../Service/hooks/useWistiaChannel';
import { WindowContext } from '../../Service/Context/WindowContext';
import { MixpanelS } from '../../Service/MixpanelS';
import { ConstantS } from '../../Service/ConstantS';
import ChannelNavbar from './ChannelNavbar';
import WistiaVideoWrapper from './WistiaVideoWrapper';

const AcademyChannel = () => {
  const { channel, currentEpisode } = useWistiaChannel();
  const navigate = useNavigate();
  const { md } = useContext(WindowContext);
  const { ref, width } = useElementSize();

  useEffect(() => {
    if (currentEpisode && channel) {
      MixpanelS.track(ConstantS.TrackingEvents.AcademyVideoOpened, {
        modul: channel.name,
        episode: currentEpisode.title,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentEpisode]);

  if (!channel || !currentEpisode) return null;

  return (
    <Flex ref={ref} gap="xl" direction={md ? 'column-reverse' : 'row'} sx={{ minHeight: md ? 'none' : '90vh' }}>
      <ChannelNavbar />
      <Divider orientation="vertical" />
      <Box>
        <Breadcrumbs mb="xs">
          <Anchor size="md" onClick={() => navigate('/academy/welcome')}>
            Akademie
          </Anchor>
          <Anchor size="md" sx={{ pointerEvents: 'none' }} className="text overflow-ellipsis">
            <b>{channel.name}</b>
          </Anchor>
        </Breadcrumbs>
        <Divider mb="sm" />
        <Box sx={{ width: md ? width : width * 0.6 }}>
          <WistiaVideoWrapper mediaId={currentEpisode.id} />
        </Box>
      </Box>
    </Flex>
  );
};

export default AcademyChannel;
