import React, { useContext } from 'react';
import PdfPreview from '../../../Molecules/Document/PdfPreview';
import { TransButtonDefault, TransButtonDefaultWithSpinner } from '../../../Atoms/Buttons';
import { ConfirmDialog, useConfirmModalState } from '../../../Molecules/Dialogs';
import { deleteCandisInvoice } from '../../../Service/restapi/candisService';

export default function ({ invoice, index, refreshInvoices }) {
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);

  const styles = {
    container: {
      backgroundColor: index % 2 ? '#fbfbfb' : '#ffffff',
      padding: 10,
      display: 'flex',
      alignItems: 'center',
    },
  };

  function deleteHandler() {
    deleteCandisInvoice(invoice.id)
      .then((resp) => {
        if (resp.ok) {
          toggleConfirm();
          refreshInvoices();
        } else {
          console.error(`could not delete candis invoice ${invoice.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not delete candis invoice ${invoice.id}`);
      });
  }

  return (
    <div style={styles.container}>
      <div style={{ marginRight: 10 }}>{invoice.fileName}</div>
      <PdfPreview
        eventId={undefined}
        file={{ entityType: 'documents', entityId: invoice.documentId }}
        lastModified={undefined}
        button={TransButtonDefaultWithSpinner}
      />
      <TransButtonDefault style={{ marginLeft: 10 }} onClickHandler={toggleConfirm} text="Übertragung zurücksetzen" />
      <ConfirmDialog
        text="Achtung: Bitte vergiss nicht die übertragene Rechnung aus Deinem Buchhaltungstool zu löschen."
        subject="Übertragung wirklich zurücksetzen?"
        open={showConfirm}
        toggle={toggleConfirm}
        handler={deleteHandler}
      />
    </div>
  );
}
