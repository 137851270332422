// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Group, Loader, Menu, Modal, Stack, Text } from '@mantine/core';
import { IconCloudDownload, IconDotsVertical, IconFile, IconTrash } from '@tabler/icons';
import { FC, useState } from 'react';
import Moment from 'react-moment';
import { useDisclosure } from '@mantine/hooks';
import { i18n } from '@lingui/core';
import { downloadFile } from '../Service/FileActions';
import { deleteFile } from '../Service/restapi/fileService';
import PanePaper from './PanePaper';
import { CustomModalHeader } from './Dialogs';

export interface FileDTO {
  fileName: string;
  fileSize: number;
  lastModified: string;
};

export interface EntityFile extends FileDTO {
  entityId: string;
  entityType: string;
}

interface FileDetailsProps {
  file: FileDTO;
  entityId: string;
  entityType: string;
  preview?: () => void;
  reload?: () => void;
}

const FileDetails: FC<FileDetailsProps> = ({ file, entityId, entityType, preview, reload }) => {
  const { fileName, lastModified } = file;
  const [opened, { open, close }] = useDisclosure(false);
  const [loading, setLoading] = useState(false);

  const donwloadHandler = () => downloadFile(entityType, entityId, fileName);

  const onDelete = () => {
    setLoading(true);
    deleteFile(entityType, entityId, fileName).then((resp) => {
      if (resp.ok) {
        if (reload) reload();
        setLoading(false);
        close();
      }
    });
  };

  return (
    <PanePaper withShadow withoutBorder sx={{ width: 'fit-content', maxWidth: 370 }}>
      <Group>
        <div>
          <IconFile />
        </div>
        <Stack spacing={0}>
          <Text
            className="overflow-ellipsis"
            variant="link"
            size="md"
            sx={{ cursor: 'pointer', width: 150 }}
            onClick={() => preview && preview()}
          >
            {fileName}
          </Text>
          <Text color="dimmed" size="xs">
            Hochgeladen <Moment fromNow>{lastModified}</Moment>
          </Text>
        </Stack>
        <Menu withArrow shadow="md" width={170} transition="scale-y" position="bottom-end">
          <Menu.Target>
            <div>
              <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<IconCloudDownload size={16} />} onClick={donwloadHandler}>
              Herunterladen
            </Menu.Item>
            <Menu.Item color="red" icon={<IconTrash size={16} />} onClick={open}>
              Löschen
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      <Modal
        opened={opened}
        onClose={close}
        title={<CustomModalHeader onClose={close}> Wirklich löschen? </CustomModalHeader>}
      >
        <>
          <Text>Möchtest Du dieses Dokument wirklich löschen?</Text>
          <Group mt="xl" spacing="xs" position="right">
            <Button variant="default" onClick={close}>
              {i18n._('actions.cancel')}
            </Button>
            <Button
              variant="subtle"
              color="red"
              disabled={loading}
              rightIcon={loading && <Loader size="xs" />}
              onClick={onDelete}
            >
              {i18n._('actions.delete')}
            </Button>
          </Group>
        </>
      </Modal>
    </PanePaper>
  );
};

export default FileDetails;
