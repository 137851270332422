import React, { useContext, useEffect, useState } from 'react';
import { withI18n } from '@lingui/react';
import { Icon } from '../../../Atoms/Icons';
import { EditableCheckbox } from '../../../Atoms/inputs/EditableCheckbox';
import { EditableReminderConfigProperty } from '../../../Atoms/EditableProperties/EditableReminderConfigProperty';
import { fetchReminderConfig } from '../../../Service/restapi/reminderService';
import { LoadingAnimation } from '../../../Service/ResourceAdapter';

export default withI18n()(() => {
  const [reminderConfig, setReminderConfig] = useState(undefined);

  useEffect(() => {
    fetchReminderConfig()
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((newReminderConfig) => {
            setReminderConfig(newReminderConfig);
          });
        } else {
          console.error('could not fetch reminder config');
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not fetch reminder config');
      });
  }, []);

  if (!reminderConfig) {
    return <LoadingAnimation />;
  }

  const styles = {
    heading: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 5,
    },
  };

  return (
    <div>
      <div style={styles.heading}>Standard-Aufgaben</div>
      Du hast eine neue Anfrage erhalten und sie einen Tag nicht beantwortet.
      <EditableReminderConfigProperty
        patchField="unansweredRequests"
        config={reminderConfig}
        handleChange={setReminderConfig}
        placeholder="aktivieren"
        editFormatter={EditableCheckbox}
        editOnly
      />
      <br />
      Ein Kunde hat sich zwei Tage nicht auf Deine E-Mail/Angebot zurückgemeldet
      <br />
      (für Events im Buchungsstatus <Icon value="OPEN" /> Anfrage oder <Icon value="OFFERED" /> Option).
      <EditableReminderConfigProperty
        patchField="followUp"
        config={reminderConfig}
        handleChange={setReminderConfig}
        placeholder="aktivieren"
        editFormatter={EditableCheckbox}
        editOnly
      />
      <br />
    </div>
  );
});
