import { ThemeIcon, Tooltip } from '@mantine/core';
import { IconVideo} from '@tabler/icons';
import React, { FC } from 'react';
import {RoutingS} from "../Service/RoutingS";
import {MixpanelS} from "../Service/MixpanelS";
import {ConstantS} from "../Service/ConstantS";

const BookitupTutorialIcon: FC<{
  id?: string;
  testId?: string;
  videoUrl: string;
  name: string;
}> = ({ id, testId, videoUrl, name }) => {

  const onClick = () => {
    MixpanelS.track(ConstantS.TrackingEvents.OpenVideoTutorial, {name});
    RoutingS.openInNewTab(videoUrl);
  }

  return (
    <Tooltip label={"Video-Tutorial"}>
      <ThemeIcon
        id={id}
        size={20}
        style={{ cursor: 'pointer' }}
        variant="gradient"
        gradient={{ from: '#647c91', to: '#a2a7b0', deg: 135 }}
        sx={{marginRight: 3}}
      >
        <IconVideo data-testid={testId} color="white" onClick={onClick} />
      </ThemeIcon>
    </Tooltip>
  );
};

export default BookitupTutorialIcon;
