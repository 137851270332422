import React, { useContext, useEffect, useState } from 'react';
import { fetchFileListOfBills } from '../../../Service/restapi/fileService';
import BillItem from './BillItem';
import PanePaper from '../../../Molecules/PanePaper';
import { Skeleton } from '@mantine/core';
import { RoutingS } from '../../../Service/RoutingS';

export default function () {
  const [bills, setBills] = useState();

  useEffect(() => {
    RoutingS.changeTitle('Rechnungen');
    fetchFileListOfBills().then((resp) => {
      if (resp.ok) {
        resp.json().then(setBills);
      }
    });
  }, []);

  return (
    <PanePaper title="Rechnungen">
      {!bills ? (
        <Skeleton height={50} width="100%" />
      ) : (
        <>
          {bills.length === 0 && <div>Es liegen keine Rechnungen von bookitup vor.</div>}
          {bills.map((bill, index) => (
            <BillItem index={index} key={index} fileName={bill.fileName} />
          ))}
        </>
      )}
    </PanePaper>
  );
}
