import React from 'react';
import moment from 'moment';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { dateFormatService } from '../../Service/DateFormatService';
import { LoadingAnimation } from '../../Service/ResourceAdapter';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment); // or globalizeLocalizer
moment.locale('de-DE');

export default function ({ calendarEvents, eventDates, ...rest }) {
  if (!calendarEvents) {
    return <LoadingAnimation />;
  }

  const events = [];

  calendarEvents.privateEvents.map((event) => {
    const startDate = dateFormatService.getDateTime(event.date, event.timeFrom);
    const endDate = dateFormatService.getDateTime(event.date, event.timeTo);
    events.push({
      title: event.description,
      start: startDate,
      end: endDate,
    });
  });

  return (
    <div style={{ height: 500 }}>
      <Calendar
        localizer={localizer}
        events={events}
        date={dateFormatService.getDate(eventDates[0].date)}
        startAccessor="start"
        endAccessor="end"
        {...rest}
      />
    </div>
  );
}
