import React from 'react';
import { TransSelectField } from '../../../Atoms/InputFields';
import { DataS } from '../../../Service/DataS';
import { i18n } from '@lingui/core';

export const CustomAttributeType = ({ attributeType, onSelect }) => {
  const attributeTypes = DataS.getAttributeTypeOptions(i18n);

  const attributeTypeValue = attributeTypes.find((type) => type.value === attributeType);

  return (
    <TransSelectField
      value={attributeTypeValue}
      placeholder="Typ des Feldes auswählen:"
      options={attributeTypes}
      onChangeHandler={(val, name) => onSelect(val)}
    />
  );
};
