import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_QUESTIONNAIRE_SERVICE_URL;

export const newQuestionnaire = (eventId, customerContactId, templateName) =>
  ajaxActions
    .put(
      `${BASE_URL}/questionnaires?eventId=${eventId}&customerContactId=${customerContactId}${
        templateName ? `&template=${templateName}` : ''
      }`,
    )
    .then((resp) => resp);

export const getQuestionnaireTemplateNames = () =>
  ajaxActions.get(`${BASE_URL}/questionnaires/templates/names`).then((resp) => resp);

export const fetchQuestionnaireTemplates = () =>
  ajaxActions.get(`${BASE_URL}/questionnaires/templates`).then((resp) => resp);

export const createQuestionnaireTemplateByName = (name, optionalQuestionnaireId) =>
  ajaxActions
    .put(
      `${BASE_URL}/questionnaires/templates/${name}${
        optionalQuestionnaireId ? `?questionnaireId=${optionalQuestionnaireId}` : ''
      }`,
    )
    .then((resp) => resp);

export const patchQuestionnaireTemplate = (questionnaireTemplateId, patch) =>
  ajaxActions.patch(`${BASE_URL}/questionnaires/templates/${questionnaireTemplateId}`, patch).then((resp) => resp);

export const patchQuestionnaire = (questionnaireId, patch) =>
  ajaxActions.patch(`${BASE_URL}/questionnaires/${questionnaireId}`, patch).then((resp) => resp);

export const moveQuestionToIndexInTemplate = (questionnaireId, questionId, index) =>
  ajaxActions
    .put(`${BASE_URL}/questionnaires/templates/${questionnaireId}/question/${questionId}/moveTo/${index}`)
    .then((resp) => resp);

export const putQuestion = (bundleId, questionId, index) => {
  const params = [];
  if (index) {
    params.push(`index=${index}`);
  }
  return ajaxActions
    .put(`${BASE_URL}/questionBundles/${bundleId}/${questionId}?${params.join('&')}`)
    .then((resp) => resp);
};

export const putQuestionBundle = (bundleId, questionnaireId, index) =>
  ajaxActions
    .put(`${BASE_URL}/questionnaires/${questionnaireId}/questionBundles/${bundleId}${index ? `?index=${index}` : ''}`)
    .then((resp) => resp);

export const putQuestionBundleInTemplate = (bundleId, questionnaireId, index) =>
  ajaxActions
    .put(
      `${BASE_URL}/questionnaires/templates/${questionnaireId}/questionBundles/${bundleId}${
        index ? `?index=${index}` : ''
      }`,
    )
    .then((resp) => resp);

export const patchQuestion = (questionId, patch) =>
  ajaxActions.patch(`${BASE_URL}/questions/${questionId}`, patch).then((resp) => resp);

export const moveQuestionToIndex = (bundleId, questionId, index, originBundleId) =>
  ajaxActions
    .put(
      `${BASE_URL}/questionBundles/${bundleId}/question/${questionId}/moveTo/${index}${
        originBundleId ? `?originBundleId=${originBundleId}` : ''
      }`,
    )
    .then((resp) => resp);

export const moveQuestionBundleToIndex = (questionnaireId, bundleId, index) =>
  ajaxActions
    .put(`${BASE_URL}/questionnaires/${questionnaireId}/questionBundles/${bundleId}/moveTo/${index}`)
    .then((resp) => resp);

export const moveQuestionBundleToIndexInTemplate = (questionnaireId, bundleId, index) =>
  ajaxActions
    .put(`${BASE_URL}/questionnaires/templates/${questionnaireId}/questionBundles/${bundleId}/moveTo/${index}`)
    .then((resp) => resp);

export const deleteQuestionBundle = (questionnaireId, bundleId) =>
  ajaxActions.del(`${BASE_URL}/questionnaires/${questionnaireId}/questionBundles/${bundleId}`).then((resp) => resp);

export const deleteQuestionBundleInTemplate = (questionnaireId, bundleId) =>
  ajaxActions
    .del(`${BASE_URL}/questionnaires/templates/${questionnaireId}/questionBundles/${bundleId}`)
    .then((resp) => resp);

export const deleteQuestion = (bundleId, questionId) =>
  ajaxActions.del(`${BASE_URL}/questionBundles/${bundleId}/${questionId}`).then((resp) => resp);

export const deleteQuestionnaire = (questionnaireId) =>
  ajaxActions.del(`${BASE_URL}/questionnaires/${questionnaireId}`).then((resp) => resp);

export const getQuestionnaires = () => ajaxActions.get(`${BASE_URL}/questionnaires`).then((resp) => resp);

export const fetchQuestionnaireById = (questionnaireId) =>
  ajaxActions.get(`${BASE_URL}/questionnaires/${questionnaireId}`).then((resp) => resp);

export const fetchAnswers = (questionId) =>
  ajaxActions.get(`${BASE_URL}/answers?questionId=${questionId}`).then((resp) => resp);

export const fetchAdditionalAnswer = (questionId) =>
  ajaxActions.get(`${BASE_URL}/answers/additionalAnswer?questionId=${questionId}`).then((resp) => resp);

export const addAnswer = (answerId, questionId, additionalAnswer) =>
  ajaxActions
    .put(
      `${BASE_URL}/answers/${answerId}/${questionId}${additionalAnswer ? `?additionalAnswer=${additionalAnswer}` : ''}`,
    )
    .then((resp) => resp);

export const delAnswer = (answerId, questionId) =>
  ajaxActions.del(`${BASE_URL}/answers/${answerId}/${questionId}`).then((resp) => resp);

export const fetchQuestionnairesByEvent = (eventId) =>
  ajaxActions.get(`${BASE_URL}/questionnaires?eventId=${eventId}`).then((resp) => resp);

export const copyQuestionnaireTemplate = (templateName) =>
  ajaxActions.put(`${BASE_URL}/questionnaires/templates/${templateName}/copy`).then((resp) => resp);

export const deleteQuestionnaireTemplate = (templateId) =>
  ajaxActions.del(`${BASE_URL}/questionnaires/templates/${templateId}`).then((resp) => resp);
