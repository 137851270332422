import { CreateResourceCategory, ResourceCategory } from '../../Types/ResourceT';
import { ajaxActions } from '../AjaxActions';
import { ConstantS } from '../ConstantS';
import { MixpanelS } from '../MixpanelS';
import { ToastS } from '../ToastS';

const BASE_URL = process.env.REACT_APP_RESOURCE_SERVICE_URL;

/**
 * Create a new category for a resource
 * @param create
 * @returns {Promise<CreateResourceCategory>}
 */
const createCategory = async (create: CreateResourceCategory): Promise<ResourceCategory> => {
  const res = await ajaxActions.put(`${BASE_URL}/categories`, create);
  if (res.ok) {
    MixpanelS.track(ConstantS.TrackingEvents.ResourceCategoryCreated);
  }
  return processResponse(res);
};

/**
 * Get an individual category
 * @param categoryId id of the resource category
 * @returns {Promise<ResourceCategory | null>}
 */
const getCategory = async (categoryId: string): Promise<ResourceCategory | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/categories/${categoryId}`);
  return processResponse(res);
};

const searchCategories = async (query: string): Promise<ResourceCategory[] | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/categories/search?query=${query}`);
  return processResponse(res);
};

const getCategories = async (): Promise<ResourceCategory[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/categories`);
  return processResponse(res);
};

const fetchAll = async (): Promise<ResourceCategory[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/categories/all`);
  return processResponse(res);
};

const fetchAllOptions = async (): Promise<string[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/categories/options`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

/**
 * Deletes an individual category
 * @param categoryId id of the category
 * @returns {Promise<boolean>}
 */
const deleteCategory = async (categoryId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/categories/${categoryId}`);
  return res.ok;
};

const updateCategory = async (
  categoryId: string,
  patch: Partial<ResourceCategory>,
): Promise<ResourceCategory | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/categories/${categoryId}`, patch);
  return processResponse(res);
};

const processResponse = (res: Response) => {
  if (res.ok) {
    return res.json();
  }
  ToastS.interceptError(res);
  return null;
};

const getAllSubCategories = (category: ResourceCategory): string[] => {
  const getSubCategories = (_category: ResourceCategory, subCategories: string[]) => {
    const { name, children } = _category;
    subCategories.push(name);
    children.forEach((c) => getSubCategories(c, subCategories));
  };
  const { children } = category;
  if (children.length === 0) {
    return [];
  }
  const subCategories: [] = [];
  children.forEach((c) => getSubCategories(c, subCategories));
  return subCategories;
};

const ResourceCategoryS = {
  createCategory,
  getCategory,
  getCategories,
  fetchAllOptions,
  getAllSubCategories,
  searchCategories,
  deleteCategory,
  updateCategory,
};

export default ResourceCategoryS;
