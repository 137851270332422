import React, { useContext } from 'react';
import ProfileFiles from './Attachments/ProfileFiles';
import EventFiles from './Attachments/EventFiles';
import EventDocuments from './Attachments/EventDocuments';
import DocumentBlueprints from './Attachments/DocumentBlueprints';
import { MessageContext } from '../../Service/Context/MessageContextV2';

/**
 * Shows all possible attachments for this E-Mail.
 *
 * Attachment {
 *     fileName: String
 *     eventId: Number
 * }
 */

const AttachmentList = ({ eventId, emailTemplate, templateName }) => {
  const { message, changeMessage, saveMessage } = useContext(MessageContext);

  function addAttachment(attachment) {
    const newAttachments = Object.assign([], message.attachments);

    if (itemIsSelected(attachment)) {
      const index = message.attachments.map((e) => e.fileName).indexOf(attachment.fileName);
      newAttachments.splice(index, 1);
    } else {
      newAttachments.push(attachment);
    }
    return { attachments: newAttachments };
  }

  function itemIsSelected(item) {
    let found = message.attachments
      ? message.attachments.find(
          (a) => a.entityType === item.entityType && a.entityId === item.entityId && a.fileName === item.fileName,
        )
      : undefined;
    if (found) {
      return true;
    }
    found = message.documentBlueprints
      ? message.documentBlueprints.find((a) => a.documentType === item.documentType)
      : undefined;
    if (found) {
      return true;
    }
    return false;
  }

  const styles = {
    tableHead: {
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 800,
      color: '#8ca3b5',
    },
    group: {
      marginBottom: 10,
    },
    listAndUploadContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  };

  function getEditableItem(item) {
    let foundItem;

    if (message && message.attachments) {
      foundItem = message.attachments.find(
        (a) => a.entityType === item.entityType && a.entityId === item.entityId && a.fileName === item.fileName,
      );
    }
    return {
      id: message?.id,
      selected: !!foundItem,
      lastModified: item.lastModified,
    };
  }

  return (
    <div>
      <ProfileFiles
        styles={styles}
        emailTemplate={emailTemplate}
        templateName={templateName}
        addAttachment={addAttachment}
        changeMessage={changeMessage}
        getEditableItem={getEditableItem}
        saveMessage={saveMessage}
      />

      <EventFiles
        styles={styles}
        eventId={eventId}
        addAttachment={addAttachment}
        changeMessage={changeMessage}
        getEditableItem={getEditableItem}
        saveMessage={saveMessage}
      />
      <EventDocuments
        styles={styles}
        eventId={eventId}
        addAttachment={addAttachment}
        changeMessage={changeMessage}
        getEditableItem={getEditableItem}
      />

      <DocumentBlueprints
        styles={styles}
        message={message}
        itemIsSelected={itemIsSelected}
        emailTemplate={emailTemplate}
        templateName={templateName}
        changeMessage={changeMessage}
      />
    </div>
  );
};

export default AttachmentList;
