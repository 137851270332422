// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Center, LoadingOverlay, Pagination, Text } from '@mantine/core';
import { useContext } from 'react';
import { DocumentsContext } from '../../Service/Context/DocumentsContext';
import ContractsList from './ContractsList';
import DocumentsList from './DocumentsList';
import DocumentsNotFound from './DocumentsNotFound';
import QuestionnaireList from './QuestionnaireList';

const BaseTable = () => {
  const PAGE_SIZE = 30;
  const { filter, changeFilter, totalPages, count, loading, missingEntries } = useContext(DocumentsContext);
  const { documentType, pageNumber } = filter;

  return (
    <div style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      {missingEntries || count === 0 ? (
        <DocumentsNotFound documentType={documentType} emptyDocuments={missingEntries} />
      ) : (
        <>
          {documentType === 'CONTRACT' && <ContractsList />}
          {documentType === 'QUESTIONNAIRE' && <QuestionnaireList />}
          {documentType !== 'CONTRACT' && documentType !== 'QUESTIONNAIRE' && <DocumentsList />}
        </>
      )}
      <Center mt="xl" mb="sm">
        <Pagination
          noWrap
          siblings={1}
          total={totalPages}
          page={pageNumber + 1}
          onChange={(newPageNumber) => changeFilter({ pageNumber: newPageNumber - 1 })}
        />
      </Center>
      {count > 0 && (
        <Center>
          <Text weight="lighter">{`${PAGE_SIZE * pageNumber + 1} - ${
            PAGE_SIZE * (pageNumber + 1) < count ? PAGE_SIZE * (pageNumber + 1) : count
          } von ${count} Dokumente`}</Text>
        </Center>
      )}
    </div>
  );
};

export default BaseTable;
