// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import moment from 'moment';
import { BookitupNotification } from '../../../../Types/LogT';
import { RoutingS } from '../../../../Service/RoutingS';

const OfferConfirmed: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { eventId, eventDate, personDescription } = notification;

  return (
    <Text>
      Angebot zum{'  '}
      <Text
        span
        weight="bolder"
        variant="link"
        color="blue"
        onClick={() => RoutingS.openInNewTab(`/events/${eventId}/details`)}
        sx={{ cursor: 'pointer' }}
      >
        Event
      </Text>
      {'  '}von
      <Text span weight="bolder">
        {` ${personDescription} `}
      </Text>
      am
      <Text span weight="bolder">
        {` ${moment(eventDate).format('LL')} `}
      </Text>
      wurde angenommen.
    </Text>
  );
};

export default OfferConfirmed;
