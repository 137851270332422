import React, { useContext, useEffect, useState } from 'react';
import { EditableEmailTemplateProperty } from '../../../Atoms/EditableProperties/EditableEmailTemplateProperty';
import { EditableCheckbox } from '../../../Atoms/inputs/EditableCheckbox';
import { EditableEmailProperty } from '../../../Atoms/EditableProperties/EditableEmailProperty';
import { FileUploadModalDialog } from '../../Dialogs/FileUploadModalDialog';
import { fetchFileListOfProfile } from '../../../Service/restapi/fileService';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import { useModalState } from '../../Dialogs';
import { Text } from '@mantine/core';

const ProfileFiles = ({
  styles,
  emailTemplate,
  templateName,
  addAttachment,
  changeMessage,
  getEditableItem,
  saveMessage,
}) => {
  const { profile } = useContext(ProfileContext);
  const [profileFiles, setProfileFiles] = useState(undefined);
  const { show, toggle } = useModalState(false);

  useEffect(() => {
    fetchFilesOfProfile();
  }, []);

  function fetchFilesOfProfile() {
    fetchFileListOfProfile(profile.id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((fileList) => {
            fileList.forEach((file) => {
              file.entityId = profile.id;
              file.entityType = 'profiles';
            });
            setProfileFiles(fileList);
          });
        } else {
          console.error(`could not load files of profile ${profile.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not load files of profile ${profile.id}`);
      });
  }

  return (
    <div style={styles.group}>
      <div style={styles.tableHead}>
        <div>Dateien aus Deinem Profil</div>
        <div />
      </div>
      <div style={styles.listAndUploadContainer}>
        <div>
          {!profileFiles && <div>Laden...</div>}
          {profileFiles
            ? profileFiles.map((item, index) => {
                if (emailTemplate) {
                  return (
                    <EditableEmailTemplateProperty
                      messageType={emailTemplate}
                      templateName={templateName}
                      key={item}
                      patchField="selected"
                      preChange={(p) => addAttachment(item)}
                      changeHandler={changeMessage}
                      email={getEditableItem(item)}
                      placeholder={item.fileName}
                      editOnly
                      editFormatter={EditableCheckbox}
                    />
                  );
                }
                return (
                  <EditableEmailProperty
                    key={item}
                    patchField="selected"
                    preChange={(p) => addAttachment(item, 'attachments')}
                    changeHandler={changeMessage}
                    email={getEditableItem(item)}
                    placeholder={item.fileName}
                    editOnly
                    editFormatter={EditableCheckbox}
                  />
                );
              })
            : null}
        </div>
        <FileUploadModalDialog
          show={show}
          toggle={toggle}
          text="Hier kannst Du allgemeine Dateien hineinziehen"
          serverUrl={`${process.env.REACT_APP_FILE_SERVICE_URL}/files/profiles/${profile.id}`}
          reloadCallback={(fileName) => {
            saveMessage(
              addAttachment({
                entityType: 'profiles',
                entityId: profile.id,
                fileName,
              }),
            );
            fetchFilesOfProfile();
          }}
        />
      </div>
      <Text mt="sm" onClick={toggle} color="blue" variant="link" sx={{ cursor: 'pointer' }}>
        + Datei hochladen
      </Text>
    </div>
  );
};

export default ProfileFiles;
