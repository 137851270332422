/* eslint-disable no-param-reassign */
import { createContext, FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { DocumentS } from '../../Templates/Documents/DocumentS';
import BookitupDocument, {
  BookitupContract,
  BookitupQuestionnaire,
  DocumentFilter,
} from '../../Types/BookitupDocument';
import { PageableResponse } from '../../Types/PageableResponse';
import { QuestionnaireS } from '../QuestionnaireS';

interface DocumentsContextInterface {
  documents: BookitupDocument[];
  contracts: BookitupContract[];
  questionnaires: BookitupQuestionnaire[];
  count: number;
  missingEntries: boolean;
  totalPages: number;
  loading: boolean;
  filter: DocumentFilter;
  changeFilter: (patch: Partial<DocumentFilter>) => void;
  reload: () => void;
}

type BookitupEntity = BookitupContract | BookitupDocument | BookitupQuestionnaire;

export const DocumentsContext = createContext<DocumentsContextInterface>({} as DocumentsContextInterface);

export const DocumentsContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [lastModified, setLastModified] = useState(new Date());
  const [documents, setDocuments] = useState<BookitupDocument[]>([]);
  const [contracts, setContracts] = useState<BookitupContract[]>([]);
  const [questionnaires, setQuestionnaires] = useState<BookitupQuestionnaire[]>([]);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [missingEntries, setEntriesMissing] = useState(false);
  const [filter, setFilter] = useState<DocumentFilter>({
    pageNumber: 0,
    dateRange: 'CURRENT_MONTH',
    documentType: 'OFFER',
    sortBy: 'date',
    direction: 'DESC',
  });
  const { documentType, sortBy } = filter;

  const changeFilter = useCallback(
    (patch: Partial<DocumentFilter>) => {
      // Reset page number on filter change
      if (!patch.pageNumber) {
        patch.pageNumber = 0;
      }
      // Remove payments option when documentType is not an invoice
      if (patch.documentType && !DocumentS.isInvoice(patch.documentType) && filter.withPayments !== undefined) {
        patch.withPayments = undefined;
      }
      // Change sortBy to date since questionnaire doesn't have number property
      if (patch.documentType && patch.documentType === 'QUESTIONNAIRE' && sortBy !== 'date') {
        patch.sortBy = 'date';
      }
      setFilter((prev) => ({ ...prev, ...patch }));
    },
    [filter],
  );

  const fetchQuestionnaires = (abortController: AbortController) => {
    QuestionnaireS.fetchQuestionnaires(filter, setEntriesMissing, abortController.signal)
      .then((resJSON) => {
        if (resJSON) {
          const { content } = resJSON;
          updateQueryMetaData(resJSON);
          setQuestionnaires(content);
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });
  };

  const fetchContracts = (abortController: AbortController) => {
    DocumentS.fetchContracts(filter, setEntriesMissing, abortController.signal)
      .then((resJSON) => {
        if (resJSON) {
          const { content } = resJSON;
          updateQueryMetaData(resJSON);
          setContracts(content);
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });
  };

  const fetchDocuments = (abortController: AbortController) => {
    DocumentS.fetchDocuments(filter, setEntriesMissing, abortController.signal)
      .then((resJSON) => {
        if (resJSON) {
          const { content } = resJSON;
          updateQueryMetaData(resJSON);
          setDocuments(content);
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });
  };

  const updateQueryMetaData = (data: PageableResponse<BookitupEntity>) => {
    const { totalElements, totalPages: numberOfPages } = data;
    setCount(totalElements);
    setTotalPages(numberOfPages);
  };

  const reload = () => setLastModified(new Date());

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    if (documentType === 'CONTRACT') {
      fetchContracts(abortController);
    } else if (documentType === 'QUESTIONNAIRE') {
      fetchQuestionnaires(abortController);
    } else {
      fetchDocuments(abortController);
    }
    return () => {
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, lastModified]);

  const value = useMemo(
    () => ({
      loading,
      documents,
      contracts,
      questionnaires,
      filter,
      count,
      missingEntries,
      totalPages,
      changeFilter,
      reload,
    }),
    [loading, documents, contracts, questionnaires, filter, count, missingEntries, totalPages, changeFilter],
  );

  return <DocumentsContext.Provider value={value}>{children}</DocumentsContext.Provider>;
};
