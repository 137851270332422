import { BookitupPageableResponse, PageableResponse } from '../Types/PageableResponse';
import {
  AddonDTO,
  BaseResourceFilter,
  CalendarEventsDto,
  CreatePart,
  CreateResourceAccessory,
  CreateResourceAlternative,
  CreateStockIntervalDto,
  EventDateDto,
  EventPlanningGroups,
  EventTimelineItem,
  Part,
  Resource,
  ResourceAccessory,
  ResourceAlternative,
  ResourceCategory,
  ResourceFilter,
  ResourceRequest,
  ResourceType,
  ResourceUpdate,
  StockIntervalDto,
  TimelineFilter,
  UsageDTO,
} from '../Types/ResourceT';
import { ajaxActions } from './AjaxActions';
import { ConstantS } from './ConstantS';
import { MixpanelS } from './MixpanelS';
import { ToastS } from './ToastS';

const BASE_URL = `${process.env.REACT_APP_RESOURCE_SERVICE_URL}/resources`;

/**
 * List of all resources
 * @param  well-known
 * @returns {Promise<* | void>}
 */
const fetchAll = async (
  filter: ResourceFilter,
  abortSignal?: AbortSignal,
): Promise<BookitupPageableResponse<Resource>> => {
  const { pageNumber, pageSize, withContent, query, categoriesOfInterest, typesOfInterest, showArchived } = filter;
  let targetUrl = `${BASE_URL}?pageIndex=${pageNumber}&pageSize=${pageSize}&withContent=${withContent}`;

  if (query) {
    targetUrl += `&query=${query}`;
  }
  if (categoriesOfInterest !== undefined && categoriesOfInterest.length > 0) {
    targetUrl += `&categoriesOfInterest=${categoriesOfInterest}`;
  }
  if (typesOfInterest !== undefined && typesOfInterest.length > 0) {
    targetUrl += `&typesOfInterest=${typesOfInterest}`;
  }
  if (showArchived !== undefined) {
    targetUrl += `&showArchived=${showArchived}`;
  }
  const res = await ajaxActions.get(targetUrl, {
    signal: abortSignal,
  });
  return processResponse(res);
};

const fetchAllWithoutPagination = async (): Promise<Resource[]> => {
  let targetUrl = `${BASE_URL}-all`; // TODO find better path name
  const res = await ajaxActions.get(targetUrl);
  return processResponse(res);
};

/**
 * Get an existing resource
 * @param  well-known
 * @param resourceId if of the resource
 * @returns {Promise<*>}
 */
const fetchById = async (resourceId: string): Promise<Resource | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/${resourceId}`);
  return processResponse(res);
};

/**
 * Create a new resource
 * @param  well-known
 * @param resource TODO
 * @returns {Promise<*>}
 */
const create = async (
  resource: ResourceRequest,
  interceptViolation?: (httpResponse: Response) => void,
): Promise<Resource | null> => {
  const res = await ajaxActions.put(`${BASE_URL}`, resource);
  if (res.ok) {
    MixpanelS.track(ConstantS.TrackingEvents.ResourceCreated, { type: resource.type });
  }
  return processResponse(res, interceptViolation);
};

/**
 * Update an existing resource
 * @param  well-known
 * @param resourceId id of the resource
 * @param patch subset of resource entity
 * @returns {Promise<*>}
 */
const update = async (resourceId: string, patch: ResourceUpdate): Promise<Resource | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/${resourceId}`, patch);
  return processResponse(res);
};

/**
 * Deletes an existing resource
 * @param resourceId if of the resource
 * @returns {Promise<* | void>}
 */
const remove = async (resourceId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/${resourceId}`);
  return res.ok;
};

const fetchParts = async (resourceId: string): Promise<Part[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/${resourceId}/parts`);
  return processResponse(res);
};

const fetchPossiblePartResources = async (resourceId: string): Promise<Resource[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/${resourceId}/possibleParts`);
  return processResponse(res);
};

const createPart = async (resourceId: string, part: Partial<CreatePart>): Promise<Part | null> => {
  const res = await ajaxActions.put(`${BASE_URL}/${resourceId}/part`, part);
  return processResponse(res);
};

const createParts = async (resourceId: string, parts: Partial<CreatePart>[]): Promise<Part | null> => {
  const res = await ajaxActions.put(`${BASE_URL}/${resourceId}/parts`, parts);
  return processResponse(res);
};

const updatePart = async (resourceId: string, partId: string, patch: Partial<Part>): Promise<Part | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/${resourceId}/parts/${partId}`, patch);
  return processResponse(res);
};

const removeAllParts = async (resourceId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/${resourceId}/parts`);
  return res.ok;
};

const removePart = async (resourceId: string, partId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/${resourceId}/parts/${partId}`);
  return res.ok;
};

const processResponse = (res: Response, interceptViolation?: (httpResponse: Response) => void) => {
  if (res.ok) {
    return res.json();
  }
  if (interceptViolation) {
    interceptViolation(res);
  } else {
    ToastS.interceptError(res);
  }
  return null;
};

const getClashEvents = async (dates: EventDateDto, eventId?: number): Promise<CalendarEventsDto | null> => {
  const res = await ajaxActions.post(`${process.env.REACT_APP_RESOURCE_SERVICE_URL}/clashes`, {
    dates,
    eventId,
  });
  if (res.ok) {
    return res.json();
  }
  return null;
};

const STOCK_INTERVAL_URL = `${process.env.REACT_APP_RESOURCE_SERVICE_URL}/stockIntervals`;

const getStockIntervals = async (resourceId: string): Promise<StockIntervalDto[]> => {
  const res = await ajaxActions.get(`${STOCK_INTERVAL_URL}?resourceId=${resourceId}`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const getStockInterval = async (intervalId: string): Promise<StockIntervalDto | null> => {
  const res = await ajaxActions.get(`${STOCK_INTERVAL_URL}/${intervalId}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const patchStockInterval = async (
  intervalId: string,
  patch: Partial<StockIntervalDto>,
): Promise<StockIntervalDto | null> => {
  const res = await ajaxActions.patch(`${STOCK_INTERVAL_URL}/${intervalId}`, patch);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const createStockInterval = async (stockInterval: CreateStockIntervalDto): Promise<StockIntervalDto | null> => {
  const res = await ajaxActions.post(STOCK_INTERVAL_URL, stockInterval);
  if (res.ok) {
    ToastS.success('interval-created', 'Lagerintervalle erstellt');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const deleteStockInterval = async (intervalId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${STOCK_INTERVAL_URL}/${intervalId}`);
  if (res.status === 204) {
    return true;
  }
  ToastS.generalError();
  return false;
};

const addAccessories = async (
  resourceId: string,
  accessories: CreateResourceAccessory[],
): Promise<ResourceAccessory[] | null> => {
  const res = await ajaxActions.put(`${BASE_URL}/${resourceId}/accessories`, accessories);
  if (res.ok) {
    ToastS.success('acc-added', 'Zubehör hinzugefügt');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const fetchPossibleAccessories = async (
  resourceId: string,
  filter: BaseResourceFilter,
  abortSignal?: AbortSignal,
): Promise<PageableResponse<Resource> | null> => {
  const { pageNumber, pageSize, query, categoriesOfInterest, typesOfInterest } = filter;

  let targetUrl = `${BASE_URL}/${resourceId}/possible-accessories?pageIndex=${pageNumber}&pageSize=${pageSize}`;

  if (query) {
    targetUrl += `&query=${query}`;
  }
  if (categoriesOfInterest !== undefined && categoriesOfInterest.length > 0) {
    targetUrl += `&categoriesOfInterest=${categoriesOfInterest}`;
  }
  if (typesOfInterest !== undefined && typesOfInterest.length > 0) {
    targetUrl += `&typesOfInterest=${typesOfInterest}`;
  }

  const res = await ajaxActions.get(targetUrl, { signal: abortSignal });
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchAccessories = async (resourceId: string): Promise<ResourceAccessory[] | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/${resourceId}/accessories`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const removeAccessory = async (resourceId: string, accessoryId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/${resourceId}/accessories/${accessoryId}`);
  if (res.ok) {
    ToastS.info('accessory-deleted', 'Zubehör entfernt');
    return true;
  }
  ToastS.generalError();
  return false;
};

const addAlternatives = async (
  resourceId: string,
  alternatives: CreateResourceAlternative[],
): Promise<ResourceAlternative[] | null> => {
  const res = await ajaxActions.put(`${BASE_URL}/${resourceId}/alternatives`, alternatives);
  if (res.ok) {
    ToastS.success('acc-added', 'Alternative hinzugefügt');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const fetchPossibleAlternatives = async (
  resourceId: string,
  filter: BaseResourceFilter,
  abortSignal?: AbortSignal,
): Promise<PageableResponse<Resource> | null> => {
  const { pageNumber, pageSize, query } = filter;
  const res = await ajaxActions.get(
    `${BASE_URL}/${resourceId}/possible-alternatives?pageIndex=${pageNumber}&pageSize=${pageSize}${
      query ? `&query=${query}` : ''
    }`,
    { signal: abortSignal },
  );
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchAlternatives = async (resourceId: string): Promise<ResourceAlternative[] | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/${resourceId}/alternatives`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const removeAlternative = async (resourceId: string, alternativeId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/${resourceId}/alternatives/${alternativeId}`);
  if (res.ok) {
    ToastS.info('alternative-deleted', 'Alternative entfernt');
    return true;
  }
  ToastS.generalError();
  return false;
};

const fetchUsages = async (resourceId: string, resourceType: ResourceType): Promise<UsageDTO[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/${resourceId}/usages?ownerType=${resourceType}`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchResourcePlannings = async (resourceId: string, begin: Date | null, end: Date | null) => {
  const res = await ajaxActions.get(`${BASE_URL}/${resourceId}/eventsPlannings`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchTimelineEvents = async (
  filter: TimelineFilter,
  setTotalCount: (count: number) => unknown,
  abortSignal?: AbortSignal,
): Promise<EventTimelineItem[]> => {
  const { from, to, statesOfInterest } = filter;
  const res = await ajaxActions.get(
    `${
      process.env.REACT_APP_RESOURCE_SERVICE_URL
    }/events?from=${from.toISOString()}&to=${to.toISOString()}&bookingStates=${statesOfInterest}`,
    { signal: abortSignal },
  );
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchTimelinePlannings = async (
  eventIds: number[],
  abortSignal?: AbortSignal,
): Promise<EventPlanningGroups | null> => {
  const res = await ajaxActions.get(
    `${process.env.REACT_APP_RESOURCE_SERVICE_URL}/planningGroups/timeline?eventIds=${eventIds}`,
    { signal: abortSignal },
  );
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchAddon = async (): Promise<AddonDTO | null> => {
  const res = await ajaxActions.get(`${process.env.REACT_APP_RESOURCE_SERVICE_URL}/addon`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const activateAddonTrial = async (): Promise<boolean> => {
  const res = await ajaxActions.get(`${process.env.REACT_APP_RESOURCE_SERVICE_URL}/addon/activate-trial`);
  if (res.ok) {
    ToastS.success('foo', 'Yay! Jetzt kannst du loslegen!', '🎉 Das Addon wurde aktiviert');
    return true;
  }
  ToastS.error('foo', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', ':( Addon trial cannot be activated');
  return false;
};

const getChildrenNames = (category: ResourceCategory) => {
  const appendNames = (c: ResourceCategory, list: string[]) => {
    list.push(c.name);
    if (c.children.length > 0) {
      c.children.forEach((children) => appendNames(children, list));
    }
  };
  const names: string[] = [];
  appendNames(category, names);
  return names;
};

const ResourceS = {
  fetchAll,
  fetchAllWithoutPagination,
  fetchById,
  create,
  update,
  remove,
  fetchParts,
  fetchPossiblePartResources,
  createPart,
  createParts,
  updatePart,
  removeAllParts,
  removePart,
  getClashEvents,
  getStockInterval,
  getStockIntervals,
  patchStockInterval,
  createStockInterval,
  deleteStockInterval,
  fetchAccessories,
  addAccessories,
  fetchPossibleAccessories,
  removeAccessory,
  fetchAlternatives,
  addAlternatives,
  fetchPossibleAlternatives,
  removeAlternative,
  fetchUsages,
  fetchResourcePlannings,
  fetchTimelineEvents,
  fetchTimelinePlannings,
  fetchAddon,
  activateAddonTrial,
  getChildrenNames,
};

export default ResourceS;
