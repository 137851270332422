import React, { useContext } from 'react';
import Moment from 'react-moment';
import { Trans } from '@lingui/react';
import { EditableCheckbox } from '../../../Atoms/inputs/EditableCheckbox';
import { EditableRecurringTaskProperty } from '../../../Atoms/EditableProperties/EditableRecurringTaskProperty';
import TooltipButton from '../../../Atoms/buttons/TooltipButton';
import { deleteRecurringTask } from '../../../Service/restapi/taskService';
import { ConfirmDialog, useConfirmModalState } from '../../../Molecules/Dialogs';
import { WindowContext } from '../../../Service/Context/WindowContext';
import CreateRecurringEventTasks from './CreateRecurringEventTasks';
import DeleteRecurringEventTasks from './DeleteRecurringEventTasks';

export default function ({ task, onChange, index, reload }) {
  const { windowWidth } = useContext(WindowContext);

  const { showConfirm, toggleConfirm } = useConfirmModalState(false);

  const styles = {
    container: {
      display: 'flex',
      height: 50,
      backgroundColor: index % 2 ? 'rgb(251, 251, 251)' : 'unset',
    },
    description: {
      flex: 1,
      alignSelf: 'center',
    },
    lastModified: {
      textAlign: 'right',
      alignSelf: 'center',
      width: 150,
    },
    trash: {
      marginLeft: 10,
      alignSelf: 'center',
    },
  };

  function delRecurringTask() {
    deleteRecurringTask(task.id)
      .then((resp) => {
        if (resp.ok) {
          reload();
        } else {
          console.error(`could not delete recurringTask ${task.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not delete recurringTask ${task.id}`);
      });
  }

  return (
    <>
      {index === 0 && (
        <div style={{ ...styles.container, fontWeight: 600, backgroundColor: 'rgb(251, 251, 251)' }}>
          <div style={{ alignSelf: 'center', marginLeft: 10, marginRight: 5, width: 50 }}>Aktiv</div>
          <div style={styles.description}>Beschreibung</div>
          {windowWidth > 1000 && <div style={styles.description}>Gruppe</div>}
          {windowWidth > 600 && <div style={{ textAlign: 'left', alignSelf: 'center', width: 300 }}>Fällig</div>}
          {windowWidth > 1200 && <div style={styles.description} />}
          {windowWidth > 1600 && <div style={styles.lastModified}>Zuletzt bearbeitet</div>}
          <div style={styles.trash} />
        </div>
      )}
      <div style={styles.container}>
        <div style={{ alignSelf: 'center', marginRight: 5, width: 50 }}>
          <EditableRecurringTaskProperty
            patchField="enabled"
            changeHandler={onChange}
            entity={task}
            editOnly
            editFormatter={EditableCheckbox}
          />
        </div>
        <div style={styles.description}>{task.description}</div>
        {windowWidth > 1000 && <div style={styles.description}>{task.taskGroupName}</div>}
        {windowWidth > 600 && (
          <div style={{ textAlign: 'left', alignSelf: 'center', width: 300, marginTop: 15, marginBottom: 10 }}>
            {task.days} Tage <Trans id={`recurringTasks.timeState.${task.timeState.toLowerCase()}`} />
            {task.timeState === 'BOOKED_PRE_EVENT' && (
              <div style={{ alignSelf: 'center', marginRight: 5, marginTop: 5, width: 250 }}>
                <EditableRecurringTaskProperty
                  patchField="alsoIfUndershot"
                  placeholder="auch, wenn in Vergangenheit"
                  changeHandler={onChange}
                  entity={task}
                  editOnly
                  editFormatter={EditableCheckbox}
                />
              </div>
            )}
          </div>
        )}

        {windowWidth > 1400 && (
          <div style={styles.description}>
            <CreateRecurringEventTasks task={task} />
          </div>
        )}
        {windowWidth > 1600 && (
          <div style={styles.lastModified}>
            <Moment fromNow>{task.lastModified}</Moment>
          </div>
        )}
        <div style={styles.trash}>
          <TooltipButton
            icon="TRASH"
            id={`recurringTask-delete-${index}`}
            onClick={toggleConfirm}
            text="Wiederkehrende Aufgabe löschen"
          />
        </div>
        <ConfirmDialog
          text="recurringTasks.delete.confirm"
          subject="recurringTasks.delete.confirm.head"
          open={showConfirm}
          toggle={toggleConfirm}
          handler={delRecurringTask}
        />
      </div>
    </>
  );
}
