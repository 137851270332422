// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Menu, Text } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { openConfirmModal, useModals } from '@mantine/modals';
import { IconCash, IconCheck, IconCopy, IconDotsVertical, IconDownload, IconPencil, IconTrash, IconX } from '@tabler/icons';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EventParticipationsContext } from '../../../Service/Context/EventParticipationContext';
import { downloadDocumentContractQuestionnaire } from '../../../Service/DocumentService';
import { copyDocument } from '../../../Service/restapi/documentService';
import BookitupDocument, { BookitupDocumentType } from '../../../Types/BookitupDocument';
import { useConfirmModalState } from '../../Dialogs';
import ChangeLabel from '../../Dialogs/ChangeLabel';
import { TestS } from '../../../TestS';
import { ToastS } from '../../../Service/ToastS';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { DocumentS } from '../../../Templates/Documents/DocumentS';
import { LicenceS } from '../../../Service/LicenceS';
import CreateDocumentModal from './CreateDocumentModal';
import CreateDownPaymentModal from './CreateDownPaymentModal';
import PaymentsManagerModal from './payments/PaymentsModal';

const DocumentItemActions: FC<{
  bookitupDocument: BookitupDocument;
  handleChange: (val: any) => void;
  reload: () => void;
  refresh: () => void;
  setDraft: (val: boolean) => void;
  patchDocument: (val: Partial<BookitupDocument>, onSuccess?: () => void) => void;
}> = ({ bookitupDocument, handleChange, reload, refresh, setDraft, patchDocument }) => {
  const { openLicenceModal, executeWithLicenceCheck } = useContext(ViolationContext);
  const eventParticipantsContext = useContext(EventParticipationsContext);
  const { interceptViolation } = useContext(ViolationContext);
  const [, setLoading] = useState(false);
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);
  const navigate = useNavigate();
  const { i18n } = useLingui();
  const { openModal } = useModals();
  const {
    id,
    label,
    number: documentNumber,
    customerId,
    documentType,
    eventId,
    draft,
    finalInvoiceCreatable,
    acceptedAt,
  } = bookitupDocument;

  const downloadDocument = () => {
    downloadDocumentContractQuestionnaire('documents', id, i18n, () => setDraft(false));
  };

  const markAsReady = () => {
    patchDocument({ draft: false }, () =>
      ToastS.success('document-finished', 'Dokument wurde erfolgreich abgeschlossen'),
    );
  };

  const markAsAccepted = () => {
    patchDocument({ accepted: true }, () => ToastS.success('document-accepted', 'Dokument als akzeptiert markiert.'));
  };

  const deleteHandler = () => {
    DocumentS.deleteDocument(bookitupDocument).then((deleted) => {
      if (deleted) {
        reload();
      }
    });
  };

  function copy(documentType: any, templateName: any, customerId: any) {
    setLoading(true);
    copyDocument(id, documentType, templateName, customerId, null)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((newDocument) => {
            navigate(`${newDocument.eventId ? `/events/${newDocument.eventId}` : ''}/documents/${newDocument.id}`);
          });
          reload();
        } else {
          console.error(
            `could not copy document ${id} by applying documentType ${documentType} with template ${templateName}`,
          );
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(
          `could not copy document ${id} by applying documentType ${documentType} with template ${templateName}`,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const createDocument = (initialDocumentType: BookitupDocumentType, finalInvoice?: boolean) => {
    openModal({
      modalId: 'create-document-modal',
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Vorlage wählen
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: (
        <CreateDocumentModal
          copyingDocument
          documentId={id}
          initialDocumentType={initialDocumentType}
          initialCustomerId={customerId}
          eventParticipationContext={eventParticipantsContext}
          eventId={eventId}
          finalInvoice={finalInvoice}
          openLicenceModal={openLicenceModal}
        />
      ),
    });
  };

  const createDownPayment = () => {
    executeWithLicenceCheck(LicenceS.Restrictions.SAVE_PARTIAL_INVOICE, () => {
      openModal({
        size: 'sm',
        title: (
          <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
            Abschlagsrechnung
          </Text>
        ),
        closeOnEscape: true,
        transition: 'slide-down',
        children: (
          <CreateDownPaymentModal
            documentId={id}
            initialCustomerId={customerId}
            eventParticipationContext={eventParticipantsContext}
          />
        ),
      });
    });
  };

  const managePayments = () => {
    executeWithLicenceCheck(LicenceS.Restrictions.DOCUMENT_PAYMENTS, () => {
      const modalTitle = `Zahlung(en) zu ${documentNumber} verwalten`;
      openModal({
        size: 'md',
        title: (
          <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
            {modalTitle}
          </Text>
        ),
        closeOnEscape: true,
        onClose: refresh,
        transition: 'slide-down',
        children: <PaymentsManagerModal bookitupDocument={bookitupDocument} interceptViolation={interceptViolation} />,
      });
    });
  };

  const deleteDocument = () => {
    openConfirmModal({
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          {i18n._('common.delete.document.confirm.head')}
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      labels: { confirm: 'Ja', cancel: 'Abbrechen' },
      children: <Text>{i18n._('common.delete.document.confirm')}</Text>,
      onConfirm: deleteHandler,
    });
  };

  return (
    <div>
      <Menu withArrow shadow="md" width={240} transition="scale-y" position="bottom-end">
        <Menu.Target>
          <div>
            <IconDotsVertical
              color="gray"
              style={{ cursor: 'pointer' }}
              data-testid={TestS.Documents.List.Dropdown.OPEN}
            />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          {draft && (
            <>
              <Menu.Item
                icon={<IconCheck size={16} />}
                onClick={markAsReady}
                data-testid={TestS.Documents.List.Dropdown.NON_DRAFT}
              >
                Fertigstellen
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          {!draft && documentType === 'OFFER' && !acceptedAt && (
            <>
              <Menu.Item
                onClick={markAsAccepted}
                icon={<IconCheck size={16} />}
                data-testid={TestS.Documents.List.Dropdown.ACCEPT}
              >
                Angebot annehmen
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          {acceptedAt && (
            <>
              <Menu.Item
                onClick={() => patchDocument({ accepted: false })}
                icon={<IconX size={16} />}
                data-testid={TestS.Documents.List.Dropdown.ACCEPT}
              >
                Angebot widerrufen
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          {documentType === 'INVOICE' && (
            <>
              <Menu.Item
                onClick={() => createDocument('CANCEL')}
                data-testid={TestS.Documents.List.Dropdown.CREATE_CANCEL}
              >
                Rechnungskorrektur erstellen
              </Menu.Item>
              <Menu.Divider />
            </>
          )}
          {(documentType === 'CONFIRMATION' ||
            documentType === 'OFFER' ||
            (documentType === 'PARTIAL_INVOICE' && finalInvoiceCreatable)) && (
            <>
              {documentType === 'OFFER' && (
                <Menu.Item
                  onClick={() => createDocument('CONFIRMATION')}
                  data-testid={TestS.Documents.List.Dropdown.CREATE_CONFIRMATION}
                >
                  Auftragsbestätigung erstellen
                </Menu.Item>
              )}
              {documentType !== 'PARTIAL_INVOICE' && (
                <>
                  <Menu.Item
                    onClick={createDownPayment}
                    data-testid={TestS.Documents.List.Dropdown.CREATE_PARTIAL_INVOICE}
                  >
                    Abschlagsrechnung erstellen
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => createDocument('INVOICE')}
                    data-testid={TestS.Documents.List.Dropdown.CREATE_INVOICE}
                  >
                    Rechnung erstellen
                  </Menu.Item>
                </>
              )}
              {finalInvoiceCreatable && (
                <Menu.Item
                  onClick={() => createDocument('INVOICE', true)}
                  data-testid={TestS.Documents.List.Dropdown.CREATE_FINAL_INVOICE}
                >
                  Schlussrechnung erstellen
                </Menu.Item>
              )}
              <Menu.Divider />
            </>
          )}

          {(documentType === 'INVOICE' || documentType === 'PARTIAL_INVOICE') && !draft && (
            <Menu.Item
              icon={<IconCash size={16} />}
              onClick={managePayments}
              data-testid={TestS.Documents.List.Dropdown.MANAGE_PAYMENTS}
            >
              Zahlungen verwalten
            </Menu.Item>
          )}
          <Menu.Item
            icon={<IconDownload size={16} />}
            onClick={downloadDocument}
            data-testid={TestS.Documents.List.Dropdown.DOWNLOAD}
          >
            Herunterladen
          </Menu.Item>
          <Menu.Item
            icon={<IconCopy size={16} />}
            onClick={() => copy(undefined, undefined, undefined)}
            data-testid={TestS.Documents.List.Dropdown.DUPLICATE}
          >
            Duplizieren
          </Menu.Item>
          <Menu.Item
            icon={<IconPencil size={16} />}
            onClick={toggleConfirm}
            data-testid={TestS.Documents.List.Dropdown.RENAME}
          >
            Umbenennen
          </Menu.Item>
          <Menu.Item
            icon={<IconTrash size={16} />}
            onClick={deleteDocument}
            data-testid={TestS.Documents.List.Dropdown.DELETE}
          >
            Löschen
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
      {showConfirm && (
        <ChangeLabel
          entityType="documents"
          toggle={toggleConfirm}
          initialLabel={label}
          entityId={id}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

export default DocumentItemActions;
