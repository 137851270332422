import { useEffect, useState } from 'react';

export const useFileSize = (fileSizeInBytes: number) => {
  const [fileSize, setFileSize] = useState<number>();
  const [unit, setUnit] = useState<string>();

  useEffect(() => {
    if (fileSizeInBytes >= 1000000000) {
      setFileSize(Math.floor(fileSizeInBytes / 1000000000));
      setUnit('GB');
    } else if (fileSizeInBytes >= 1000000) {
      setFileSize(Math.floor(fileSizeInBytes / 1000000));
      setUnit('MB');
    } else if (fileSizeInBytes >= 1000) {
      setFileSize(Math.floor(fileSizeInBytes / 1000));
      setUnit('KB');
    } else {
      setUnit('Bytes');
      setFileSize(fileSizeInBytes);
    }
  }, [fileSizeInBytes]);

  return [ fileSize, unit ];
};
