import { file } from './FileActions';
import { patchContract } from './restapi/contractService';
import { patchDocument } from './restapi/documentService';
import { patchQuestionnaire } from './restapi/questionnaireService';
import { ToastS } from './ToastS';

export const downloadDocumentContractQuestionnaire = (entityType, entityId, i18n, onSuccess, interceptViolation) => {
  let toastId;

  ToastS.info('document.generate', 'Dokument wird generiert');

  let patchFn = patchDocument;
  let serverUrl = process.env.REACT_APP_DOCUMENT_SERVICE_URL;

  if (entityType === 'contracts') {
    patchFn = patchContract;
  }
  if (entityType === 'questionnaires') {
    patchFn = patchQuestionnaire;
    serverUrl = process.env.REACT_APP_QUESTIONNAIRE_SERVICE_URL;
  }

  patchFn(entityId, { draft: false })
    .then((resp) => {
      if (resp.ok) {
        file
          .download(`${serverUrl}/${entityType}/${entityId}/pdf`)
          .then(() => {
            ToastS.success('document.generate', 'Dokument heruntergeladen');
            if (onSuccess) {
              onSuccess();
            }
          })
          .catch((error) => {
            console.error({ error });
            ToastS.error('document.generate', i18n._("document.generate.error"));
          })
          .finally(() => {});
      } else if (resp.status === 400 && interceptViolation) {
        interceptViolation(resp);
      } else if (resp.status === 412) {
        ToastS.error('document.generate', i18n._("placeholder.unsatisfied"));
      }
    })
    .catch(() => {
      throw new Error('could not set document as non draft');
    });
};
