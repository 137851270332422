import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { fetchContactById } from '../restapi/contactService';
import { LoadingOverlay } from '@mantine/core';
import { EventContext } from './EventContext';
import { EventLocationContext } from './EventLocationContext';
import { CustomAttributesContext } from './CustomAttributeContext';

export const CustomerContext = React.createContext(undefined);

export const CustomerContextProvider = ({ mainCustomerId }) => {
  const [customer, setCustomer] = useState(undefined);

  useEffect(() => {
    fetchContactById(mainCustomerId).then((contact) => {
      setCustomer(contact);
    });
  }, []);

  if (!customer) {
    return <LoadingOverlay visible overlayBlur={2} loaderProps={{ size: 'xl' }} />;
  }

  return (
    <CustomerContext.Provider
      value={{
        customer,
        changeCustomer: (patch) => {
          setCustomer((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
      }}
    >
      <Outlet />
    </CustomerContext.Provider>
  );
};

export const useEventData = () => {
  const { event } = useContext(EventContext);
  const { customer } = useContext(CustomerContext);
  const { location } = useContext(EventLocationContext);
  const { customAttributeValues } = useContext(CustomAttributesContext);
  return { event, customer, location, customAttributeValues};
}
