import { useContext, useEffect, useState } from 'react';
import { Alert, Center, Loader, Text } from '@mantine/core';
import IframeResizer from 'iframe-resizer-react';
import { useJwtClaims } from '../../../../Service/Context/AuthContext';
import { SemanticFormS } from '../../../../Service/restapi/semanticFormService';
import { BookitupFormContext } from '../../../../Service/Context/BookitupFormContext';
import { v4 as UUIDv4 } from 'uuid';

const FormPreview = () => {
  const { profileId } = useJwtClaims();
  const [loading, setLoading] = useState(false);
  const { bookitupForm, handleChange } = useContext(BookitupFormContext);
  const { id: formId, outOfSync } = bookitupForm ?? {};
  const previewAvailable = SemanticFormS.previewAvailable(bookitupForm);

  useEffect(() => {
    if (formId && outOfSync && previewAvailable) {
      setLoading(true);
      SemanticFormS.syncFormFiles(formId)
        .then((synced) => {
          if (synced) {
            handleChange({ outOfSync: false });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formId, outOfSync, previewAvailable]);

  if (loading) {
    return (
      <Center>
        <Loader size="xl" pos="absolute" top="40%" />
      </Center>
    );
  }
  if (!previewAvailable) {
    return (
      <Alert sx={(theme) => ({ backgroundColor: theme.colors.red[4] })}>
        <Text color="white">
          Formular kann nicht erstellt werden, da im Formular Felder im Spaltenlayout leer sind.
        </Text>
      </Alert>
    );
  }

  if (!formId) {
    return null;
  }

  return (
    <IframeResizer
      title="Vorschau"
      src={`${SemanticFormS.createSemanticFormIframeUrl(profileId, formId)}?cacheBust=` + UUIDv4()}
      style={{ width: '1px', minWidth: '100%', border: 0 }}
    />
  );
};

export default FormPreview;
