import React from 'react';
import PropTypes from 'prop-types';

/**
 * EuroFormatter is a formatter which simply passes strings trough and adds ' EUR', but
 * returns a dash for empty or null values.
 */
export const EuroFormatter = ({ value, disabled }) => {
  const styles = {
    span: {
      height: '100%',
    },
  };

  return (
    <span className={`form-control ${disabled ? 'disabled' : ''}`} style={styles.span}>
      {value === '' || value === null || value === undefined ? '-' : value} EUR
    </span>
  );
};

EuroFormatter.propTypes = {
  value: PropTypes.number,
};
