// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Stack, Button, Center, Group, Text } from '@mantine/core';
import { FC, useContext } from 'react';
import { closeAllModals } from '@mantine/modals';
import PanePaper from '../../../Molecules/PanePaper';
import { SubscriptionDTO } from '../../../Types/Billing';
import { ChargebeeS } from '../../../Service/ChargebeeS';
import { AuthContext } from '../../../Service/Context/AuthContext';
import FieldValueRow from '../../../Atoms/FieldValueRow';
import { ResourceAddonContext } from '../../../Service/Context/ResourceAddonsContext';
import { LicenceS } from '../../../Service/LicenceS';
import MigrateSubscriptionAlert from './MigrateSubscriptonAlert';
import FutureSubscriptionAlert from './FutureSubscriptionAlert';
import CancelSubscriptionButton from './CancelSubscriptionButton';
import SubscriptionStatusBadge from './SubscriptionStatusBadge';
import SubscriptionAddonsPane from './SubscriptionAddonsPane';
import AddonManagementPane from './AddonManagementPane';

interface Props {
  subscription: SubscriptionDTO;
  refreshSubscription: () => void;
}

const SubscriptionPane: FC<Props> = ({ subscription, refreshSubscription }) => {
  const { jwtClaims, refreshToken } = useContext(AuthContext);
  const { refresh: refreshAddon } = useContext(ResourceAddonContext);
  const { licence, profileId } = jwtClaims;
  const { billingInterval, activatedAt, nextBillingDate, cancelledAt, status, withChargebee, startDate, addons } =
    subscription;

  const openPortal = () => {
    const onClose = () => {
      refreshToken();
      refreshSubscription();
      refreshAddon();
    };
    ChargebeeS.openPortal(onClose);
  };

  return (
    <PanePaper title="Abonnement">
      <AddonManagementPane />
      {!withChargebee && <MigrateSubscriptionAlert refreshSubscription={refreshSubscription} />}
      {status === 'FUTURE' && <FutureSubscriptionAlert startDate={startDate} />}
      <Stack mt="md">
        <FieldValueRow name="Derzeitiger Plan" value={licence} />
        <Group position="apart">
          <Text>Status:</Text>
          <SubscriptionStatusBadge status={status} />
        </Group>
        <FieldValueRow name="Frequenz" value={billingInterval} />
        {activatedAt && <FieldValueRow name="Fing an am" value={activatedAt} />}
        {nextBillingDate && <FieldValueRow name="Nächste Zahlung" value={nextBillingDate} />}
        {cancelledAt && <FieldValueRow name="Endet am" value={cancelledAt} />}
        {LicenceS.hasAccessToResourceModule(profileId) && addons.length > 0 && (
          <SubscriptionAddonsPane addons={addons} />
        )}
      </Stack>
      {withChargebee && (
        <>
          <Center mt="xl" mb={5}>
            <Button onClick={openPortal}>Abrechnung verwalten</Button>
          </Center>
          <Text align="center" color="dimmed" size="xs">
            Verwalte hier verfügbare Pläne und Add-ons
          </Text>
        </>
      )}
      {status === 'ACTIVE' && !withChargebee && (
        <CancelSubscriptionButton
          onCancel={() => {
            refreshSubscription();
            closeAllModals();
          }}
        />
      )}
    </PanePaper>
  );
};

export default SubscriptionPane;
