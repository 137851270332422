// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Text } from '@mantine/core';
import { useContext } from 'react';
import { RoutingS } from '../../Service/RoutingS';
import { WindowContext } from '../../Service/Context/WindowContext';

const AcademyHeader = () => {
  const { sm } = useContext(WindowContext);
  const recommendFriend = () => RoutingS.openInNewTab(`${process.env.REACT_APP_FRONTEND_URL}/settings/recruitment`);
  const openFacebookGroup = () => RoutingS.openInNewTab('https://www.facebook.com/groups/bookitup');
  const openInstagram = () => RoutingS.openInNewTab('https://www.instagram.com/bookitup.de/');
  const supportCall = () => RoutingS.openInNewTab('https://hey.bookitup.de/meetings/sebastian-mahlke/technik-support');

  return (
    <Group
      spacing={40}
      position="center"
      sx={(theme) => ({ backgroundColor: theme.colors.blue[4], color: 'white', height: 40 })}
    >
      <Text variant="link" sx={{ cursor: 'pointer', color: 'white' }} onClick={supportCall}>
        Support-Termin
      </Text>
      {!sm && (
        <Text variant="link" sx={{ cursor: 'pointer', color: 'white' }} onClick={recommendFriend}>
          Freunde werben
        </Text>
      )}
      {!sm && (
        <Text variant="link" sx={{ cursor: 'pointer', color: 'white' }} onClick={openFacebookGroup}>
          Facebook-Gruppe
        </Text>
      )}
      <Text variant="link" sx={{ cursor: 'pointer', color: 'white' }} onClick={openInstagram}>
        Instagram
      </Text>
    </Group>
  );
};

export default AcademyHeader;
