import { Center, SegmentedControl } from '@mantine/core';
import { FC, useState } from 'react';
import DocumentCheckboxSelector from '../../../Templates/Documents/DocumentCheckboxSelector';

type Props = {
  eventId: string;
  profileId: string;
  getSelected: (entityType: string) => any;
  onSelectWithType: (entityType: string, entityId: string, label: string) => void;
  onDeselectWithType: (entityType: string, entityId: string, label: string) => void;
};

const ShareFlowCombined2: FC<Props> = ({ eventId, profileId, getSelected, onSelectWithType, onDeselectWithType }) => {
  const [selectedEntityType, setSelectedEntityType] = useState('events');

  const entityId = selectedEntityType === 'events' ? eventId : profileId;

  return (
    <>
      <Center>
        {' '}
        <SegmentedControl
          value={selectedEntityType}
          onChange={setSelectedEntityType}
          data={[
            { label: 'Event-Dateien', value: 'events' },
            { label: 'Profil-Dateien', value: 'profiles' },
          ]}
          sx={{ marginBottom: 20 }}
        />
      </Center>
      <DocumentCheckboxSelector
        entityId={entityId}
        entityType={selectedEntityType}
        selected={getSelected(selectedEntityType)}
        onSelect={(label: string) => onSelectWithType(selectedEntityType, entityId, label)}
        onDeselect={(label: string) => onDeselectWithType(selectedEntityType, entityId, label)}
        documentType={undefined}
      />
    </>
  );
};

export default ShareFlowCombined2;
