import { useEffect, useState } from 'react';
import { Box, Stack, Text } from '@mantine/core';
import { getContactById } from '../../Service/restapi/contactService';
import { join } from '../../Utils/utils';
import ContactListItemRelationContacts from './ContactListItemRelationContacts';
import { ContactS } from '../../Service/ContactS';

const ContactListItemDescription = ({ contactId, addressField, editingContact, lastModified, withoutPartner }) => {
  const [contact, setContact] = useState(undefined);
  const [partner, setPartner] = useState();
  const isDefined = (val) => val && val !== '';

  useEffect(() => {
    if (contact) {
      if (contact.partnerId) {
        ContactS.fetchById(contact.partnerId).then((_partner) => {
          if (_partner) {
            setPartner(_partner);
          }
        });
      } else if (partner) {
        setPartner(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact]);

  useEffect(() => {
    if (contactId) {
      getContactById(contactId)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((newContact) => {
              setContact(newContact);
            });
          } else {
            console.error(`could not load contact ${contactId}`);
          }
        })
        .catch((error) => {
          console.error(`could not load contact ${contactId}`);
          console.error({ error });
        });
    }
  }, [contactId, editingContact, lastModified]);

  if (!contact) {
    return null;
  }

  const {
    salutation,
    firstName,
    lastName,
    companyName,
    addressSupplement,
    addressStreetAndNo,
    zipCode,
    city,
    email,
    phoneNumber,
    phoneNumberAlt,
  } = contact;

  const getAddressInfo = () => {
    let address = '';
    if (isDefined(addressStreetAndNo)) address += addressStreetAndNo;
    if (isDefined(addressStreetAndNo) && (isDefined(zipCode) || isDefined(city))) address += ',';
    if (isDefined(zipCode)) address += ` ${zipCode}`;
    if (isDefined(city)) address += ` ${city}`;
    return address;
  };

  return (
    <Stack spacing={5}>
      {isDefined(companyName) && <Text weight="bolder">{companyName} </Text>}

      {(isDefined(salutation) || isDefined(firstName) || isDefined(lastName)) && (
        <Text mb="sm">{join([salutation, firstName, lastName], '')}</Text>
      )}
      <Text weight="lighter" size="xs">{addressSupplement}</Text>
      <Stack spacing={1}>
        <Text weight="lighter" size="xs">
          {getAddressInfo()}
        </Text>

        {isDefined(email) && (
          <Text weight="lighter" size="xs">
            {email}
          </Text>
        )}

        {!addressField && isDefined(phoneNumber) && (
          <Text weight="lighter" size="xs">
            <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
          </Text>
        )}
        {!addressField && isDefined(phoneNumberAlt) && (
          <Text weight="lighter" variant="a" href={`tel:${phoneNumberAlt}`} size="xs">
            <a href={`tel:${phoneNumberAlt}`}>{phoneNumberAlt}</a>
          </Text>
        )}
        {partner && !withoutPartner && (
          <Box mt="md">
            {(isDefined(partner.salutation) || isDefined(partner.firstName) || isDefined(partner.lastName)) && (
              <Text mb="sm">{join([partner.salutation, partner.firstName, partner.lastName], '')}</Text>
            )}
            {isDefined(partner.email) && (
              <Text weight="lighter" size="xs">
                {partner.email}
              </Text>
            )}
            {isDefined(partner.phoneNumber) && (
              <Text weight="lighter" size="xs">
                <a href={`tel:${partner.phoneNumber}`}>{partner.phoneNumber}</a>
              </Text>
            )}
          </Box>
        )}
      </Stack>
      {!addressField && <ContactListItemRelationContacts contactId={contactId} lastModified={lastModified} />}
    </Stack>
  );
};

export default ContactListItemDescription;
