import React, { useContext, useEffect, useState } from 'react';
import { InputGroup } from 'reactstrap';
import { deleteCalendarShare, getOwnCalendarAccesses } from '../../../Service/restapi/calendarAccountService';
import TooltipButton from '../../../Atoms/buttons/TooltipButton';
import { ConfirmDialog, useConfirmModalState } from '../../../Molecules/Dialogs';
import { EditableCheckbox } from '../../../Atoms/inputs/EditableCheckbox';
import { EditableCalendarShareProperty } from '../../../Atoms/EditableProperties/EditableCalendarShareProperty';
import { LoadingAnimation } from '../../../Service/ResourceAdapter';
import ClipboardButton from '../../../Atoms/buttons/ClipboardButton';

export default function ({ calendarShare: initialCalendarShare, index, reloadCalendarShares }) {
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);
  const [calendarShare, setCalendarShare] = useState(initialCalendarShare);
  const [calendarAccesses, setCalendarAccesses] = useState();

  useEffect(() => {
    setCalendarShare(initialCalendarShare);
    ownCalendarAccesses();
  }, [initialCalendarShare]);

  if (!calendarAccesses) {
    return <LoadingAnimation />;
  }

  const styles = {
    container: {
      display: 'flex',
      padding: 10,
      backgroundColor: index % 2 ? '#fbfbfb' : 'unset',
    },
    codeField: {
      height: 34,
      borderRadius: 5,
      marginRight: 4,
    },
  };

  function ownCalendarAccesses() {
    getOwnCalendarAccesses(calendarShare.id).then((resp) => {
      if (resp.ok) {
        resp.json().then(setCalendarAccesses);
      }
    });
  }

  function delShareItem() {
    deleteCalendarShare(calendarShare.id)
      .then((resp) => {
        if (resp.ok) {
          reloadCalendarShares();
        } else {
          console.error(`could not delete calendarShare ${calendarShare.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not delete calendarShare ${calendarShare.id}`);
      });
  }

  return (
    <div style={styles.container}>
      <div style={{ marginRight: 5, marginTop: 6 }}>
        <EditableCalendarShareProperty
          entity={calendarShare}
          patchField="enabled"
          changeHandler={(p) => {
            setCalendarShare(p);
          }}
          placeholder=""
          editFormatter={EditableCheckbox}
          editOnly
        />
      </div>
      <InputGroup style={{ width: 425 }}>
        <input readOnly className="code form-control" style={styles.codeField} value={calendarShare.id} />
        <ClipboardButton text={calendarShare.id} />
      </InputGroup>
      <TooltipButton
        icon="TRASH"
        isLoading={false}
        id={`delete-calendarShare-${calendarShare.id}`}
        style={{ marginLeft: 10, marginTop: 6 }}
        text="Freigabe entfernen"
        onClick={toggleConfirm}
      />

      <div style={{ marginLeft: 20, marginTop: 6, marginRight: 10 }} className="description">
        <div>(bisher von {calendarAccesses.length} Kollegen verknüpft)</div>
        {/* <details>
                    <summary>{calendarAccesses.length} bookitup Konten verknüpft</summary>
                    {calendarAccesses.map((access, index) => {
                        return <div key={index}>{access.profileId}</div>
                    })}
                </details> */}
      </div>
      <ConfirmDialog
        text="settings.calendarShare.delete.confirm"
        subject="settings.calendarShare.delete.confirm.head"
        open={showConfirm}
        toggle={toggleConfirm}
        handler={delShareItem}
      />
    </div>
  );
}
