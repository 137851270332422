import { Menu } from '@mantine/core';
import { IconDotsVertical, IconTrash } from '@tabler/icons';
import { useContext, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ConfirmDialog, useModalState } from '../../Molecules/Dialogs';
import SaveTemplateName from '../../Molecules/Document/SaveTemplateName';
import { EventContext } from '../../Service/Context/EventContext';
import { QuestionnaireContext } from '../../Service/Context/QuestionnaireContext';
import { deleteQuestionnaire } from '../../Service/restapi/questionnaireService';
import { checkSubmissionsAndDelete } from '../../Service/restapi/shareService';

const QuestionnaireHeadActions = () => {
  const navigate: NavigateFunction = useNavigate();
  const { event } = useContext<any>(EventContext);
  const { questionnaire } = useContext<any>(QuestionnaireContext);
  const [openSaveTemplateNameDialog, setOpenSaveTemplateNameDialog] = useState(false);
  const { show, toggle } = useModalState(false);
  const [deleting, setDeleting] = useState(false);

  function remove() {
    setDeleting(true);
    checkSubmissionsAndDelete(
      'questionnaires',
      questionnaire.id,
      deleteQuestionnaire,
      () => {
        navigate(`/events/${event.id}/documents`);
      },
      () => {
        setDeleting(false);
      },
    );
  }

  return (
    <div>
      <Menu withArrow shadow="md" width={200} transition="scale-y" position="bottom-end">
        <Menu.Target>
          <div>
            <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => setOpenSaveTemplateNameDialog(true)}>Vorlage speichern</Menu.Item>
          <Menu.Item onClick={toggle} icon={<IconTrash size={16} />}>
            Löschen
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <SaveTemplateName
        entityId={questionnaire.id}
        open={openSaveTemplateNameDialog}
        close={() => {
          setOpenSaveTemplateNameDialog(false);
        }}
        documentType={undefined}
      />

      <ConfirmDialog
        text="common.delete.questionnaire.confirm"
        subject="common.delete.questionnaire.confirm.head"
        open={show}
        toggle={toggle}
        handler={() => remove()}
        isLoading={deleting}
        // eslint-disable-next-line react/no-children-prop
        children={[]}
        confirmText=""
        disableConfirm={false}
      />
    </div>
  );
};

export default QuestionnaireHeadActions;
