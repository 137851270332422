/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Center, Loader, Skeleton, Text } from '@mantine/core';
import React, { FC, useEffect, useState } from 'react';
import { closeAllModals, openModal } from '@mantine/modals';
import { i18n } from '@lingui/core';
import { Resource } from '../../../../Types/ResourceT';
import ResourceS from '../../../../Service/ResourceS';
import BookitupSelect from '../../../../Atoms/BookitupSelect';
import { DataS } from '../../../../Service/DataS';
import {
  createResourcePosition,
  createResourcePositionFavorite,
  deleteResourcePosition,
  deleteResourcePositionFavorite,
} from '../../../../Service/restapi/documentService';
import { ToastS } from '../../../../Service/ToastS';
import { MixpanelS } from '../../../../Service/MixpanelS';
import { ConstantS } from '../../../../Service/ConstantS';
import { ResourcePosition } from './ResourcePositionAssignment';

interface Props {
  resourcePosition: ResourcePosition | null;
  setResourcePosition: (resourcePosition: ResourcePosition | null) => void;
  positionId: number;
  resource: Resource | null;
  favoritePosition: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const openResourcePositionModal = (props: Props) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Ressource verknüpfen
      </Text>
    ),
    closeOnEscape: true,
    transition: 'slide-down',
    children: <ResourcePositionModal {...props} />,
  });

const ResourcePositionModal: FC<Props> = ({
  positionId,
  resourcePosition,
  setResourcePosition,
  resource,
  favoritePosition,
}) => {
  const [loading, setLoading] = useState(false);
  const [resources, setResources] = useState<Resource[]>();
  const [selectedResourceId, setSelectedResourceId] = useState<string | null>(
    resourcePosition === null ? null : resourcePosition.resourceId,
  );

  useEffect(() => {
    setLoading(true);
    ResourceS.fetchAllWithoutPagination()
      .then(setResources)
      .finally(() => setLoading(false));
  }, []);

  const onSave = () => {
    if (selectedResourceId) {
      if (favoritePosition) {
        createResourcePositionFavorite({ positionId, resourceId: selectedResourceId })
          .then((resp) => {
            if (resp.ok) {
              MixpanelS.track(ConstantS.TrackingEvents.ResourceAssignedToProduct);
              resp.json().then((e) => {
                setResourcePosition(e);
              });
            } else {
              console.error(`could not save resource ${selectedResourceId} to position ${positionId}`);
            }
          })
          .finally(() => {
            closeAllModals();
          });
      } else {
        createResourcePosition({ positionId, resourceId: selectedResourceId })
          .then((resp) => {
            if (resp.ok) {
              MixpanelS.track(ConstantS.TrackingEvents.ResourceAssignedToTemplatePosition);
              resp.json().then((e) => {
                setResourcePosition(e);
              });
            } else {
              console.error(`could not save resource ${selectedResourceId} to position ${positionId}`);
            }
          })
          .finally(() => {
            closeAllModals();
          });
      }
    } else if (resourcePosition) {
      if (favoritePosition) {
        deleteResourcePositionFavorite(resourcePosition.id).then(() => {
          setResourcePosition(null);
          closeAllModals();
        });
      } else {
        deleteResourcePosition(resourcePosition.id).then(() => {
          setResourcePosition(null);
          closeAllModals();
        });
      }
    } else {
      ToastS.info('foo', 'Wähle zuerst eine Ressource aus.');
    }
  };

  return (
    <>
      <Text>
        Die verknüpfte Ressource wird automatisch dem Event hinzugefügt, sobald das Dokument fertiggestellt wurde.
      </Text>
      <Text mt="xs" size="xs" color="dimmed">
        Tipp: Um mehrere Ressourcen der Position hinzuzufügen, erstelle ein Set mit den gewünschten Ressourcen und
        verknüpfe dieses anschließend hier.
      </Text>
      {loading ? (
        <Skeleton mt="md" height={40} width="100%" />
      ) : (
        <>
          {resources && (
            <BookitupSelect
              searchable
              mt="md"
              label="Ressource"
              data={DataS.getResourceOptions(resources)}
              value={selectedResourceId}
              onChange={(val) => val && setSelectedResourceId(val)}
            />
          )}
          {selectedResourceId && (
            <Text
              size="xs"
              variant="link"
              color="red"
              ml="xs"
              mt={5}
              onClick={() => setSelectedResourceId(null)}
              sx={{ cursor: 'pointer' }}
            >
              - Verknüpfung entfernen
            </Text>
          )}
        </>
      )}
      <Center mt="xl">
        <Button
          disabled={loading || (selectedResourceId === resource?.id)}
          sx={{ minWidth: 200 }}
          onClick={onSave}
          rightIcon={loading && <Loader size="xs" />}
        >
          {i18n._('actions.save')}
        </Button>
      </Center>
      <Text mt="xs" align="center" size="xs" color="dimmed">
        Die verknüpfte Ressource wird Deinem Kunden nicht angezeigt.
      </Text>
    </>
  );
};
