/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Center, Checkbox, Loader, Text, TextInput } from '@mantine/core';
import { closeModal, openConfirmModal, openModal } from '@mantine/modals';
import { FC, useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { ContactGroup } from '../../Types/AuthT';
import { ContactGroupS } from '../../Service/ContactGroupS';

export const openContactGroupDeleteModal = (group: ContactGroup, onDelete: () => void) => {
  const { id, name } = group;

  const onConfirm = async () => {
    const deleted = await ContactGroupS.remove(id);
    if (deleted) {
      onDelete();
    }
  };

  openConfirmModal({
    title: (
      <Text weight="bolder" size="xl">
        Gruppe löschen
      </Text>
    ),
    children: (
      <Text size="sm">
        Möchten Sie die Gruppe{' '}
        <Text span weight="bolder">
          {name}
        </Text>{' '}
        wirklich löschen?
      </Text>
    ),
    groupProps: { spacing: 5 },
    labels: { confirm: i18n._('actions.delete'), cancel: i18n._('actions.cancel') },
    confirmProps: { color: 'red' },
    onConfirm,
  });
};

interface ContactGroupModalProps {
  group?: ContactGroup;
  onFinish: (group: ContactGroup) => void;
  existingNames?: string[];
}

const MODAL_ID = 'groupModal';

// eslint-disable-next-line import/prefer-default-export
export const openContactGroupModal = (props: ContactGroupModalProps) => {
  openModal({
    modalId: MODAL_ID,
    title: (
      <Text weight="bolder" size="xl">
        {props.group ? 'Gruppe bearbeiten' : 'Gruppe erstellen'}
      </Text>
    ),
    children: <ContactGroupModal {...props} />,
  });
};

const ContactGroupModal: FC<ContactGroupModalProps> = ({ group, onFinish, existingNames }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(group ? group.name : '');
  const [recommendable, setRecommendable] = useState(group ? group.recommendable : false);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (existingNames?.includes(name)) {
      setError('Name ist bereits vergeben');
    } else if (error) {
      setError(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const onClick = async () => {
    setLoading(true);
    let resGroup;
    if (group) {
      resGroup = await ContactGroupS.update(group.id, { name, recommendable });
    } else {
      resGroup = await ContactGroupS.create(name, recommendable);
    }
    if (resGroup) {
      onFinish(resGroup);
      closeModal(MODAL_ID);
    }
    setLoading(false);
  };

  return (
    <>
      <TextInput error={error} label="Name" defaultValue={name} onChange={(e) => setName(e.currentTarget.value)} />
      <Checkbox
        mt="sm"
        label="Dienstleister-Netzwerk"
        checked={recommendable}
        onChange={() => setRecommendable(!recommendable)}
      />
      <Text color="dimmed" size="xs">
        Wenn Du diese Gruppe für Dienstleister verwendest, markiere sie für das Dienstleister-Netzwerk, damit Dir diese
        Kontakte für Vermittlungen angezeigt werden.
      </Text>
      <Center>
        <Button
          mt="xl"
          sx={{ width: 200 }}
          onClick={onClick}
          disabled={loading || error !== undefined || name === ''}
          rightIcon={loading && <Loader size="xs" />}
        >
          {group ? i18n._('actions.save') : i18n._('actions.add')}
        </Button>
      </Center>
    </>
  );
};
