// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Button, Text } from '@mantine/core';
import { IconBrandGoogle } from '@tabler/icons';
import { FC } from 'react';

const GoogleMigrationAlert: FC<{ migrate: () => void }> = ({ migrate }) => (
  <Alert
    icon={<IconBrandGoogle size={16} />}
    title="Neue Google Anbindung"
    sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })}
    mb="sm"
  >
    <Text mb="md">
      Mit unserer neuen Google-Anbindung, können (neben Kalender-Einträgen) jetzt auch Kontakte und Aufgaben automatisch
      in dein Google-Konto eingetragen werden.
    </Text>
    <Text mb="md">
      Dafür ist es notwendig, dass du uns deine Zustimmung gibst. An deinem bisherigen Kalender-Sync zu Google ändert
      sich nichts. Nachdem dein Konto neu verbunden ist, kannst du hier in bookitup alles genau einstellen, was du gerne
      synchronisieren möchtest.
    </Text>
    <Text>Um loszulegen, klicke jetzt auf &quot;Google neu verbinden&quot;</Text>
    <Button size="xs" mt="md" onClick={migrate}>
      Google neu verbinden
    </Button>
  </Alert>
);

export default GoogleMigrationAlert;
