import { FC } from 'react';
import { Checkbox, Text } from '@mantine/core';
import { DeleteTasksConfigDTO, WorkflowConfigProps } from '../../../../../Types/LogT';

const DeleteTasksConfig: FC<WorkflowConfigProps> = ({ config, handleChange }) => {
  const { removeOnlyUnfinished } = config as DeleteTasksConfigDTO;
  return (
    <>
      <Checkbox
        mt="md"
        defaultChecked={removeOnlyUnfinished}
        label="Nur unerledigte Aufgaben löschen"
        onChange={() => handleChange({ removeOnlyUnfinished: !removeOnlyUnfinished })}
      />
      <Text size="xs" color="dimmed">
        Deaktivieren Sie das Kontrollkästchen, wenn Sie alle Aufgaben entfernen möchten.
      </Text>
    </>
  );
};

export default DeleteTasksConfig;
