// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { WorkflowStepDTO } from '../../../../Types/LogT';
import { WorkflowS } from '../../../../Service/WorkflowS';
import CreateQuestionnaireReport from './CreateQuestionnaireReport';
import CreateShareReport from './CreateShareReport';
import ShareEntityReport from './ShareEntityReport';
import SendMailReport from './SendMailReport';
import CreateDocumentReport from './CreateDocumentReport';
import CreateContractReport from './CreateContractReport';
import ChangeEventStatusReport from './ChangeEventStatusReport';
import CreateTaskReport from './CreateTaskReport';
import DeleteTaskReport from './DeleteTaskReport';
import CompleteTaskReport from './CompleteTaskReport';
import DeleteTasksReport from './DeleteTasksReport';

const StepReport: FC<{ workflowStep: WorkflowStepDTO }> = ({ workflowStep }) => {
  const { procedureType, executionReport, configId, executionConfig } = workflowStep;
  const [config, setConfig] = useState<unknown>();

  useEffect(() => {
    if (configId && procedureType) {
      WorkflowS.fetchProcedureConfig(procedureType, configId).then(setConfig);
    }
  }, [configId, procedureType]);

  if (procedureType === 'WAIT_UNTIL') {
    const { typeOfInterest, entityOfInterest } = executionConfig;
    if (!entityOfInterest) {
      return <Text color="red">Die Konfiguration ist ungültig.</Text>;
    }
    return <Text>{i18n._(`waitUntil.${entityOfInterest.entityType}.${typeOfInterest}`)}</Text>;
  }

  if (!config) {
    return null;
  }

  if (executionReport && executionReport.workflowError === 'INVALID_PROCEDURE_CONFIG') {
    return <Text color="red">Die Konfiguration ist ungültig.</Text>;
  }

  return (
    <>
      {procedureType === 'DELETE_TASK' && <DeleteTaskReport report={executionReport} config={config} />}
      {procedureType === 'DELETE_TASKS' && <DeleteTasksReport report={executionReport} config={config} />}
      {procedureType === 'COMPLETE_TASK' && <CompleteTaskReport report={executionReport} config={config} />}
      {procedureType === 'CREATE_TASK' && <CreateTaskReport report={executionReport} config={config} />}
      {procedureType === 'CHANGE_EVENT_STATUS' && <ChangeEventStatusReport report={executionReport} config={config} />}
      {procedureType === 'CREATE_CONTRACT' && <CreateContractReport report={executionReport} config={config} />}
      {procedureType === 'CREATE_DOCUMENT' && <CreateDocumentReport report={executionReport} config={config} />}
      {procedureType === 'CREATE_SHARE' && <CreateShareReport report={executionReport} config={config} />}
      {procedureType === 'CREATE_QUESTIONNAIRE' && <CreateQuestionnaireReport report={executionReport} />}
      {procedureType === 'SHARE_ENTITY' && <ShareEntityReport report={executionReport} config={config} />}
      {procedureType === 'SEND_MAIL' && <SendMailReport report={executionReport} config={config} />}
    </>
  );
};

export default StepReport;
