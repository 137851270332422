import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/react';
import PlaceholderCollection from '../../Molecules/Placeholder/PlaceholderCollection';
import { PlaceholderImageCollection } from '../../Molecules/Placeholder/PlaceholderImageCollection';
import PlaceholderInputField from './PlaceholderInputField';
import { InputHelp } from './InputHelp';

export const EditableDivInput = ({
  finishCallback,
  value: initialValue,
  placeholder,
  patchField,
  name,
  big,
  event,
  customer,
  location,
  eventLocation,
  _document,
  contract,
  customAttributeValues,
  settings,
  labelStyle,
  ...rest
}) => {
  const [containerRef] = useState(React.createRef());
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  function finish(newValue) {
    setLoading(true);
    setError(false);
    finishCallback({ [patchField]: newValue })
      .catch((error) => setError(error.message))
      .then(() => setLoading(false));
  }

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const styles = {
    label: {
      color: '#8ca3b5',
      fontWeight: 800,
      ...labelStyle,
    },
    inputContainer: {
      marginBottom: 9,
    },
    inputAndPlaceholderContainer: {
      display: 'flex',
    },
  };

  {
    /* focus on click: sonst führt click innerhalb des Feldes nicht zum focussieren */
  }
  return (
    <div style={styles.inputContainer} ref={containerRef}>
      {placeholder && (
        <Label sm={12} style={styles.label}>
          <Trans id={placeholder} />
        </Label>
      )}
      <div style={styles.inputAndPlaceholderContainer}>
        <PlaceholderInputField
          htmlString={value}
          autoFocus
          name={name}
          loading={loading}
          event={event}
          customer={customer}
          eventLocation={eventLocation}
          _document={_document}
          contract={contract}
          customAttributeValues={customAttributeValues}
          finish={finish}
          settings={settings}
          error={error}
          big={big}
          {...rest}
        />
        {containerRef && settings && !window.location.pathname.includes('settings/signature') && (
          <PlaceholderCollection finish={finish} _document={_document} contract={contract} style={{ left: 400 }} />
        )}
        {containerRef && settings && window.location.pathname.includes('settings/signature') && (
          <PlaceholderImageCollection finish={finish} _document={_document} contract={contract} style={{ left: 400 }} />
        )}
      </div>
      {error ? <InputHelp errorDescription={error} /> : null}
    </div>
  );
};

EditableDivInput.propTypes = {
  value: PropTypes.string,
  finishCallback: PropTypes.func,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  big: PropTypes.bool,
};

EditableDivInput.defaultProps = {
  autoFocus: true,
};
