import { Trans } from '@lingui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TransButtonPrimary } from '../../Atoms/Buttons';
import { ModalDialog } from '../../Molecules/Dialogs';

export const UpgradeModal = ({ title, text, show, toggle, licence }) => {
  const navigate = useNavigate();

  const styles = {
    container: {
      textAlign: 'center',
      lineHeight: 1.5,
      maxWidth: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    subject: {
      fontWeight: 800,
      lineHeight: 1.2,
      marginTop: 10,
      marginBottom: 10,
    },
    upgradeButton: {
      margin: 20,
    },
    text: {
      textAlign: 'center',
    },
    actNow: {
      marginTop: 10,
    },
    upgradeNow: {
      fontWeight: 500,
      marginBottom: 5,
      fontSize: '1.1rem',
    },
  };

  return (
    <ModalDialog
      header={
        <Trans
          id={`Jetzt upgraden zu bookitup ${licence === 'PRO' ? 'Pro' : licence === 'FLOW' ? 'Flow' : 'Interactive'}`}
        />
      }
      body={
        <div style={styles.text}>
          <div style={styles.upgradeNow}>{title}</div>

          {text}

          <div style={styles.actNow}>
            {licence === 'PRO' && <>Profitiere jetzt vom besseren Überblick mit bookitup Pro.</>}
            {licence === 'FLOW' && <>Automatisiere jetzt Deinen Workflow mit bookitup Flow.</>}
            {licence === 'INTERACTIVE' && (
              <>Erstelle Deinen persönlichen Online-Zugang - papierlos mit bookitup Interactive.</>
            )}
          </div>
        </div>
      }
      footer={
        <TransButtonPrimary
          style={styles.upgradeButton}
          onClickHandler={() => {
            navigate('/settings/plans');
          }}
          text="Zu den Paketen"
        />
      }
      show={show}
      toggle={toggle}
    />
  );
};
