import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_AUTH_SERVICE_URL;

export const fetchContactRelationById = (contactRelationId) =>
  ajaxActions.get(`${BASE_URL}/contactRelations/${contactRelationId}`).then((resp) => resp);

export const putContactRelation = (contactId, relatedContactId) =>
  ajaxActions.put(`${BASE_URL}/contactRelations/${contactId}/${relatedContactId}`).then((resp) => resp);

export const deleteContactRelation = (contactRelationId) =>
  ajaxActions.del(`${BASE_URL}/contactRelations/${contactRelationId}`).then((resp) => resp);

export const fetchRelatedContacts = (contactId) =>
  ajaxActions.get(`${BASE_URL}/relatedContacts/${contactId}`).then((resp) => resp);
