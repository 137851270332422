import React from 'react';
import FileSelectDialog from '../../Molecules/Dialogs/FileSelectDialog';
import { TransButtonDefaultWithIcon, TransButtonLink } from '../../Atoms/Buttons';
import { useModalState } from '../../Molecules/Dialogs';

export default function ({ patchBlock, textBlock, column }) {
  const { show, toggle } = useModalState(false);

  function patchImage(entityType, entityId, fileName) {
    if (column === 'first') {
      patchBlock({
        imageEntityTypeFirstColumn: entityType,
        imageEntityIdFirstColumn: entityId,
        imageFileNameFirstColumn: fileName,
      });
    } else if (column === 'second') {
      patchBlock({
        imageEntityTypeSecondColumn: entityType,
        imageEntityIdSecondColumn: entityId,
        imageFileNameSecondColumn: fileName,
      });
    }
  }

  return (
    <>
      {((column === 'first' && !textBlock.imageEntityIdFirstColumn) ||
        (column === 'second' && !textBlock.imageEntityIdSecondColumn)) && (
        <TransButtonDefaultWithIcon icon="IMAGE" onClickHandler={toggle} />
      )}
      {((column === 'first' && textBlock.imageEntityIdFirstColumn) ||
        (column === 'second' && textBlock.imageEntityIdSecondColumn)) && (
        <TransButtonLink
          className="primary-link"
          onClick={() => {
            patchImage(null, null, null);
          }}
          text="Bild entfernen"
        />
      )}
      <FileSelectDialog show={show} toggle={toggle} onFileSelect={patchImage} />
    </>
  );
}
