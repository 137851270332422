import React, { useState } from 'react';
import { Trans } from '@lingui/react';
import { TransButtonDefault } from '../../Atoms/Buttons';
import { ModalDialog } from '../Dialogs';
import FileSelectButton from '../Contract/FileSelectButton';
import TooltipTextButton from '../../Atoms/buttons/TooltipTextButton';

/**
 * Select a file.
 *
 * Attachment {
 *     fileName: String
 *     eventId: Number
 * }
 */

export default function ({ show, toggle, patch }) {
  const [showFrontCoverConfig, setShowFrontCoverConfig] = useState(false);
  const [showBackCoverConfig, setShowBackCoverConfig] = useState(false);

  const styles = {
    tableHead: {
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: 800,
      color: '#8ca3b5',
    },
    group: {
      marginBottom: 10,
    },
    listAndUploadContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    description: {
      color: '#666666',
      marginBottom: 5,
      fontSize: 12,
    },
  };

  return (
    <ModalDialog
      help="Wähle PDF Dateien aus, die vor bzw. nach dem Dokument eingefügt werden sollen."
      header={
        <>
          <Trans id="PDFs beifügen" />
          <div style={{ alignSelf: 'center', marginRight: 10 }}>
            <TooltipTextButton
              buttonText="PRO"
              id="licence-event-tasks-pane"
              text="licence.document.merge.pane"
              style={{ float: 'right' }}
            />
          </div>
        </>
      }
      body={
        <>
          <div style={styles.description}>
            Wähle eine PDF-Datei, die <b>vor</b> dem Dokument eingefügt werden soll:
          </div>
          <FileSelectButton
            patchField="frontCoverFileName"
            patch={patch}
            show={showFrontCoverConfig}
            toggle={() => {
              setShowFrontCoverConfig(!showFrontCoverConfig);
            }}
          />
          <br />
          <div style={styles.description}>
            Wähle eine PDF-Datei, die <b>nach</b> dem Dokument eingefügt werden soll:
          </div>
          <FileSelectButton
            patchField="backCoverFileName"
            patch={patch}
            show={showBackCoverConfig}
            toggle={() => {
              setShowBackCoverConfig(!showBackCoverConfig);
            }}
          />
        </>
      }
      footer={
        <div style={styles.buttons}>
          <TransButtonDefault onClickHandler={toggle} text="actions.finish" />
        </div>
      }
      show={show}
      toggle={toggle}
    />
  );
}
