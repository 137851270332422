import React from 'react';
import { CheckBox } from '../InputFields';

export const CheckboxList = ({ options }) => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
  };

  const items = [];

  options.map((val, index) => {
    items.push(
      <CheckBox
        key={index}
        checked={(checked) => {
          if (checked) {
            val.onClick();
          } else {
            val.onDeselect();
          }
        }}
        initialValue={val.checked}
        text={val.label}
      />,
    );
  });

  return <div style={styles.container}>{items}</div>;
};
