enum AgendaGroupEntityType {
  events = 'events',
  profiles = 'profiles',
}

export const getAgendaGroupDisplayEntityType = (type: AgendaGroupEntityType) => {
  switch (type) {
    case AgendaGroupEntityType.events:
      return 'Eventgruppe';

    case AgendaGroupEntityType.profiles:
      return 'Allgemein';

    default:
      return '';
  }
};

export default AgendaGroupEntityType;
