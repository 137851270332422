import React, { useContext, useEffect, useState } from 'react';
import { LoadingAnimation } from '../../../Service/ResourceAdapter';
import CheckDocumentPlaceholderWrapper from '../../CheckDocumentPlaceholderWrapper';
import { fetchDocumentsAndContractsByEvent } from '../../../Service/restapi/documentService';
import { QuestionnaireS } from '../../../Service/QuestionnaireS';

export default function ({ styles, eventId, addAttachment, changeMessage, getEditableItem }) {
  const [documents, setDocuments] = useState([]);

  const _setDocuments = (newState) => {
    setDocuments((prev) => [...prev, ...newState]);
  };

  useEffect(() => {
    if (eventId) {
      fetchEntities();
    }
  }, [eventId]);

  if (!eventId) {
    return null;
  }

  const fetchEntities = () => {
    fetchDocumentsOfEvent();
    QuestionnaireS.getExistingByEventId(eventId).then(_setDocuments);
  };

  function fetchDocumentsOfEvent() {
    fetchDocumentsAndContractsByEvent(eventId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((existingDocuments) => {
            /*
             *   Long entityId;
             *   String entityType;
             *   String fileName;
             */
            _setDocuments(existingDocuments);
          });
        } else {
          console.error(`could not load documents of event ${eventId}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not load documents of event ${eventId}`);
      });
  }

  return (
    <div style={styles.group}>
      <div style={styles.tableHead}>
        <div>Dokumente aus diesem Event</div>
        {/* <div>Betrag</div>
                        <div>Zuletzt bearbeitet</div> */}
      </div>
      <div style={styles.listAndUploadContainer}>
        <div>
          {!documents && <LoadingAnimation />}
          {documents
            ? documents.map((item, index) => (
                <CheckDocumentPlaceholderWrapper
                  key={index}
                  index={index}
                  eventId={eventId}
                  addAttachment={addAttachment}
                  reload={fetchEntities}
                  changeMessage={changeMessage}
                  attachment={item}
                  email={getEditableItem(item)}
                />
              ))
            : null}
        </div>
      </div>
    </div>
  );
}
