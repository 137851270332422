import { addDays } from 'date-fns';

export const hasSuitableLicence = (licence, userLicence, purchasedOnly) => {
  if (purchasedOnly) {
    return hasPurchasedSuitableLicence(licence, userLicence);
  }

  if (licence === 'BETA') {
    return true;
  }

  if (licence === 'PRO') {
    if (!userLicence.includes('PRO') && !userLicence.includes('FLOW') && !userLicence.includes('INTERACTIVE')) {
      return false;
    }
  }
  if (licence === 'FLOW') {
    if (!userLicence.includes('FLOW') && !userLicence.includes('INTERACTIVE')) {
      return false;
    }
  }
  if (licence === 'INTERACTIVE') {
    if (!userLicence.includes('INTERACTIVE')) {
      return false;
    }
  }

  return true;
};

export const hasPurchasedSuitableLicence = (licence, userLicence) => {
  if (licence === 'PRO') {
    if (userLicence === 'PRO' || userLicence === 'FLOW' || userLicence === 'INTERACTIVE') {
      return true;
    }
  }
  if (licence === 'FLOW') {
    if (userLicence === 'FLOW' || userLicence === 'INTERACTIVE') {
      return true;
    }
  }
  if (licence === 'INTERACTIVE') {
    if (userLicence === 'INTERACTIVE') {
      return true;
    }
  }

  return false;
};

const hasTrialLicence = (licence) => licence ===licence.includes('TRIAL');

const getRemainingTrialDays = (trialEndsAt) => {
  const dateOneUTC = Date.UTC(trialEndsAt.getFullYear(), trialEndsAt.getMonth(), trialEndsAt.getDate());
  const today = new Date();
  const todayUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
  const difference = dateOneUTC - todayUTC;
  return difference / (1000 * 60 * 60 * 24);
};

const isFreeLicence = (licence) => licence === 'BASIC' || LicenceS.hasTrialLicence(licence);

export const LicenceS = {
  getRemainingTrialDays,
  hasTrialLicence,
  isFreeLicence,
};
