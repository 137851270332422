import React from 'react';
import PropTypes from 'prop-types';
import { SimpleLabelWrapper } from '../Formatter/SimpleLabelWrapper';

/*
Editable hält den State, ob gerade editiert wird oder nicht. Je nachdem wird entweder der "label", oder der "edit" angezeigt.
Der "labelWrapper" ist was umschaltet - siehe dazu "SimpleLabelWrapper".
Das kriegt ne "startEditCallback" Methode rein gereicht, die dem Editable signalisiert das umgeschaltet werden soll.
*/

class Editable extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
      error: false,
    };
  }

  render() {
    const {
      label,
      labelWrapper,
      edit,
      value,
      onChange,
      tabIndex,
      placeholder,
      description,
      disabled,
      inputField,
      remove,
      editOnly,
      patchField,
      doNotCheckForSameValue,
      ...rest
    } = this.props;
    const { editing } = this.state;
    const { useGrow } = rest;

    if (this.state.error || editing || editOnly) {
      const innerProps = {
        finishCallback: (newValue) => {
          if (
            doNotCheckForSameValue ||
            (value !== newValue[patchField] &&
              !(value === null && newValue[patchField] === '') &&
              !(value === '' && newValue[patchField] === null))
          ) {
            return onChange(newValue)
              .then(() => {
                this.setState({ editing: false, error: false });
              })
              .catch((error) => {
                this.setState({ editing: true, error: true });
                throw error;
              });
          }
          this.setState({ editing: false });
          return Promise.resolve();
        },
        value,
        disabled,
        placeholder,
        remove,
        patchField,
        ...rest,
      };

      let editInner;
      if (typeof edit === 'object') {
        editInner = React.cloneElement(edit, innerProps, inputField);
      } else {
        const EditTag = edit;
        editInner = <EditTag {...innerProps} />;
      }

      return editInner;
    }
    const innerProps = {
      value,
      patchField,
      disabled,
      ...rest,
    };

    let wrapperInner;
    if (typeof label === 'object') {
      wrapperInner = React.cloneElement(label, innerProps);
    } else {
      const LabelTag = label;
      wrapperInner = <LabelTag {...innerProps} />;
    }

    // ----------------

    const outerProps = {
      placeholder,
      description,
      disabled,
      tabIndex,
      startEditCallback: () => this.setState({ editing: true }),
    };

    let wrapperOuter;
    if (typeof labelWrapper === 'object') {
      wrapperOuter = React.cloneElement(labelWrapper, outerProps, wrapperInner);
    } else {
      const LabelWrapper = labelWrapper;
      wrapperOuter = (
        <LabelWrapper {...outerProps} style={{ flexGrow: useGrow ? 1 : 'unset' }}>
          {wrapperInner}
        </LabelWrapper>
      );
    }

    return wrapperOuter;
  }
}

Editable.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  edit: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelWrapper: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  placeholder: PropTypes.string,
};

Editable.defaultProps = {
  labelWrapper: SimpleLabelWrapper,
  label: ({ value }) => value,
};

export default Editable;
