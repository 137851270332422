import { I18n } from '@lingui/core';
import {
  IdPersonDescriptionEmailDto,
  MessageAction,
  MessageDTO,
  MessageTemplateDto,
  MessageType,
} from '../Types/MessageT';
import { ajaxActions } from './AjaxActions';
import { ConstantS } from './ConstantS';
import { ContactS } from './ContactS';
import { MixpanelS } from './MixpanelS';
import { PlaceholderS } from './PlaceholderS';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_MESSAGE_SERVICE_URL;

const fetchTemplates = async (): Promise<MessageTemplateDto[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/email/template`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchTemplatesNames = async (): Promise<string[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/email/template/all/names`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchTemplate = async (id: number): Promise<MessageTemplateDto | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/email/template/id/${id}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchTemplate2 = async (type: MessageType, name: string | null): Promise<MessageTemplateDto | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/email/template/${type}${name ? `?name=${name}` : ''}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const exportEvent = async (
  eventId: number,
  receiverContactId: number,
  recommendationId: string,
  data: Record<string, unknown>,
): Promise<boolean> => {
  const res = await ajaxActions.put(
    `${BASE_URL}/export/events/${eventId}?receiverContactId=${receiverContactId}&embedCustomerDetails=${true}`,
    {
      id: recommendationId,
      ...data,
    },
  );
  if (res.ok) {
    ToastS.success('send.event.info', 'Event-Daten per Mail verschickt');
    return true;
  }
  ToastS.generalError();
  return false;
};

const updateTemplate = async (
  type: MessageType,
  patch: Partial<MessageTemplateDto>,
  name?: string,
): Promise<MessageDTO | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/email/template/${type}${name ? `?name=${name}` : ''}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const deleteTemplate = async (name: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/email/template/CUSTOM?name=${name}`);
  if (res.ok) {
    ToastS.info('email-template-deleted', 'Vorlage wurde gelöscht.');
    return true;
  }
  ToastS.generalError();
  return false;
};

const patchDraftMessage = async (patch: Partial<MessageDTO>, emailId?: number): Promise<MessageDTO | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/emails/draft${emailId ? `?id=${emailId}` : ''}`, patch);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const patchNewDraft = async (patch: Partial<MessageDTO>, eventId?: string): Promise<MessageDTO | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/emails/draft${eventId ? `?eventId=${eventId}` : ''}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const validateMessage = async (message: MessageDTO): Promise<MessageDTO | null> => {
  const { to, subject } = message;
  if (!subject) {
    ToastS.warn('subject.required', 'Bitte gib einen Betreff an');
    return null;
  }
  if (!to) {
    ToastS.warn('recipient.required', 'Bitte gib einen Empfänger an');
    return null;
  }
  return PlaceholderS.replaceMessage(message);
};

const sendMail = async (message: MessageDTO): Promise<MessageDTO | null> => {
  ToastS.cleanAll();
  const res = await ajaxActions.post(`${BASE_URL}/emails`, message);
  if (res.ok) {
    MixpanelS.track(ConstantS.TrackingEvents.EmailSent);
    ToastS.success('email-sent', 'E-Mail erfolgreich verschickt');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const deleteDraft = async (draftId: number): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/emails/${draftId}`);
  if (res.ok) {
    return true;
  }
  ToastS.generalError();
  return false;
};

const createMessageFromTemplate = async (
  type: MessageType,
  name: string | null,
  customerId: number,
  documents: any[],
) => {
  const template = await fetchTemplate2(type, name);
  if (!template) {
    return null;
  }
  const { subject, message, attachments } = template;

  const attachedDocuments: any = [];
  documents.forEach((d) => {
    attachedDocuments.push({
      fileName: d.fileName,
      entityType: d.documentType === 'CONTRACT' ? 'contracts' : 'documents',
      entityId: d.documentId,
    });
  });

  const primaryCustomer = await ContactS.fetchById(customerId);

  return {
    to: [{ email: primaryCustomer ? primaryCustomer.email : undefined } as any],
    message,
    subject,
    attachments: attachments.concat(attachedDocuments as any),
  };
};

const searchMessages = async (query: string): Promise<MessageDTO[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/search?query=${query}`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return [];
};

export type PageWrapper = {
  pages: number;
  count: number;
  messages: MessageDTO[];
};

const fetchEmails = async (
  postbox: string,
  filter: string,
  page: number,
  contacts: number[] | undefined,
  abortSignal: AbortSignal,
): Promise<PageWrapper> => {
  const res = await ajaxActions.post(
    `${BASE_URL}/emails/postbox/${postbox}?${filter ? `filter=${filter}` : ''}&page=${page}`,
    contacts,
    { signal: abortSignal },
  );
  if (res.ok) {
    const pagesCount = res.headers.get('Total-Count');
    const totalCount = res.headers.get('X-Total-Count');
    const data: [] = await res.json();

    return {
      pages: pagesCount ? Number(pagesCount) : 0,
      count: totalCount ? Number(totalCount) : 0,
      messages: data.reverse(),
    };
  }
  ToastS.generalError();
  return {
    pages: 0,
    count: 0,
    messages: [],
  };
};

const fetchEmail = async (emailId: number): Promise<MessageDTO | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/emails/${emailId}`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const patchEmail = async (emailId: number, patch: Partial<unknown>): Promise<MessageDTO | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/emails/${emailId}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const deleteEmail = async (emailId: number): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/emails/${emailId}`, null);
  if (res.ok) {
    ToastS.info('email-deleted', 'Nachricht gelöscht');
    return true;
  }
  ToastS.generalError();
  return false;
};

const deleteEmails = async (emailsIds: number[]): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/emails/multiple`, emailsIds);
  if (res.ok) {
    ToastS.info('emails-deleted', 'Nachrichten gelöscht');
    return true;
  }
  ToastS.generalError();
  return false;
};

const getEmailByAction = async (emailId: number, action: MessageAction): Promise<MessageDTO | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/emails/${emailId}/${action.toLowerCase()}`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const getEmailDate = (email: MessageDTO) => {
  const { scheduledTime, localDateTime, lastModified } = email;
  if (scheduledTime !== null) {
    return scheduledTime;
  }
  if (localDateTime) {
    return localDateTime;
  }
  return lastModified;
};

const moveToTrash = async (ids: number[]): Promise<boolean> => {
  const res = await ajaxActions.patch(`${BASE_URL}/emails/trash`, ids);
  if (res.ok) {
    ToastS.info('emails-deleted', 'Nachrichten gelöscht');
    return true;
  }
  ToastS.generalError();
  return false;
};

const calendarStrings = {
  lastDay: '[Gestern, ] LT',
  sameDay: 'LT',
  nextDay: '[Morgen, ] LT',
  lastWeek: 'dddd',
  sameElse: 'L',
};

const getCustomerContact = (message: MessageDTO): Partial<IdPersonDescriptionEmailDto> => {
  return message.sendByUser ? (message.to && message.to.length > 0 ? message.to[0] : {}) : message.from;
};

const createTemplateFromMessage = async (name: string, messageId: number): Promise<MessageTemplateDto | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/email/template?messageId=${messageId}&name=${name}`);
  if (res.status === 201) {
    ToastS.success('template-create', `Vorlage ${name} wurde erfolgreich erstellt.`);
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const fillTemplateNames = (templates: MessageTemplateDto[], i18n: I18n) =>
  templates.forEach((template) => {
    const { messageType } = template;
    if (messageType !== 'CUSTOM') {
      template.name = i18n._(`message.type.${messageType.toLowerCase()}`);
    }
  });

const copyTemplate = async (templateId: number, name: string): Promise<MessageTemplateDto | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/email/template/${templateId}/copy?name=${name}`);
  if (res.status === 201) {
    ToastS.info('template-copy-created', `Kopie ${name} erstellt.`);
    return res.json();
  }
  ToastS.generalError();
  return null;
};

// eslint-disable-next-line import/prefer-default-export
export const MessageS = {
  exportEvent,
  fetchTemplate,
  fetchTemplates,
  fetchTemplatesNames,
  updateTemplate,
  deleteTemplate,
  patchDraftMessage,
  patchNewDraft,
  validateMessage,
  sendMail,
  deleteDraft,
  createMessageFromTemplate,
  searchMessages,
  fetchEmail,
  fetchEmails,
  patchEmail,
  deleteEmail,
  deleteEmails,
  getEmailByAction,
  getEmailDate,
  moveToTrash,
  calendarStrings,
  getCustomerContact,
  createTemplateFromMessage,
  fillTemplateNames,
  copyTemplate,
};
