import React from 'react';
import { DropdownItem } from 'reactstrap';
import { Trans } from '@lingui/react';
import { BookingStateModal } from '../Organismns/Event/BookingStateModal';
import { Dropdown } from '../Atoms/Dropdowns';
import { useModalState } from './Dialogs';

export default function ({ bookingState, handleChange, recommendationState }) {
  const { show, toggle } = useModalState();

  const states = [
    { value: 'OPEN', label: 'event.state.open' },
    { value: 'OFFERED', label: 'event.state.offered' },
    { value: 'BOOKED', label: 'event.state.booked' },
    { value: 'CANCELED', label: 'event.state.canceled' },
    { value: 'RECOMMENDED', label: 'event.state.recommended' },
    { value: 'EARMARKED', label: 'event.state.earmarked' },
    { value: 'UNAVAILABLE', label: 'event.state.unavailable' },
  ];

  const stateItems = [];
  states.forEach((state, index) => {
    stateItems.push(
      <DropdownItem
        key={index}
        onClick={() => {
          handleChange(state.value);
        }}
      >
        <Trans id={state.label} />
      </DropdownItem>,
    );
  });

  let stateColor;
  let border = '';
  if (bookingState === 'CANCELED') {
    stateColor = '#FF514F';
    border = '#ff2a21';
  } else if (bookingState === 'EARMARKED' || bookingState === 'UNAVAILABLE') {
    stateColor = '#adadad';
    border = '#838383';
  } else if (bookingState === 'OFFERED') {
    stateColor = '#ffd86f';
    border = '#ffd86f';
  } else if (bookingState === 'OPEN') {
    stateColor = '#97CBFC';
    border = '#5492CC';
  } else if (bookingState === 'BOOKED') {
    stateColor = '#a1e2a6';
    border = '#6BA581';
  } else if (bookingState === 'RECOMMENDED') {
    stateColor = '#c894b2';
    border = '#ad4b80';
  }

  const styles = {
    bookingStateBadge: {
      width: '2rem',
      height: '2rem',
      borderRadius: '100%',
      borderColor: border,
      backgroundColor: stateColor,
      display: 'flex',
      justifyContent: 'center',
      color: '#ffffff',
      alignItems: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 3,
    },
    button: {
      border: 'none',
      background: 'transparent',
      color: '#000000',
    },
    changeState: {},
    dropdownButton: {
      padding: '0px 4px',
      borderRadius: '4px',
      textAlign: 'center',
      color: '#698aa6',
      fontWeight: 800,
      border: '1px solid #e5e5e5',
    },
    dropdownMenu: {
      left: -46,
      minWidth: 140,
    },
    dropdownItem: {
      height: 26,
    },
  };

  const items = [
    {
      icon: 'OPEN',
      text: recommendationState ? 'Kein Kundenkontakt' : 'Anfrage',
      onClick: () => handleChange('OPEN'),
    },
    {
      icon: 'OFFERED',
      text: recommendationState ? 'Mit Kunde in Kontakt' : 'Option',
      onClick: () => handleChange('OFFERED'),
    },
    { icon: 'BOOKED', text: 'Gebucht', onClick: () => handleChange('BOOKED') },
  ];

  if (!recommendationState) {
    items.push({ icon: 'TIMES', text: 'Nicht verfügbar', onClick: () => handleChange('UNAVAILABLE') });
  }

  items.push({
    icon: 'CANCELED',
    text: recommendationState ? 'Absage erhalten' : 'Annulliert',
    onClick: () => handleChange('CANCELED'),
  });

  if (!bookingState) {
    return null;
  }

  return (
    <div>
      <Dropdown
        style={styles.dropdownButton}
        dropdownMenu={styles.dropdownMenu}
        dropdownItem={styles.dropdownItem}
        iconStyle={{ alignSelf: 'center' }}
        caret
        icon={bookingState}
        value={
          recommendationState
            ? `recommendation.bookingState.${bookingState.toLowerCase()}`
            : `event.bookingstate.${bookingState.toLowerCase()}`
        }
        options={{ items }}
      />

      <BookingStateModal
        title="Buchungsstatus ändern"
        bookingState={bookingState}
        show={show}
        toggle={toggle}
        finish={(state) => handleChange(state)}
      />
      {/* <UserAgent mobile tablet>
                <TransSelectField value={value} options={states}
                onChangeHandler={(value, name) => handleChange({bookingState: value})} placeholder="bookingstate" name="bookingState"/>
            </UserAgent> */}
    </div>
  );
}
