// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Checkbox, LoadingOverlay, Text, TextInput, Stack, Space } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { closeModal } from '@mantine/modals';
import { IconCalendarEvent, IconLock, IconNote } from '@tabler/icons';
import { FC, useState } from 'react';
import { DocumentPaymentS } from '../../../../Service/DocumentPaymentS';
import { ToastS } from '../../../../Service/ToastS';

const CancelPaymentModal: FC<{ paymentId: string; interceptViolation: (httpResponse: Response) => void }> = ({
  paymentId,
  interceptViolation,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [editingDate, setEditingDate] = useState<boolean>(false);
  const [cancellationCause, setCancellationCause] = useState<string>('');
  const [cancellationDate, setCancellationDate] = useState<Date>(new Date());

  const cancelPayment = () => {
    setLoading(true);
    DocumentPaymentS.cancelPayment(paymentId, { cancellationCause, cancellationDate })
      .then((res) => {
        if (res.ok) {
          closeModal(paymentId);
          ToastS.info('payment-cancelled', 'Zahlung wurde storniert');
        } else if (res.status === 400) {
          interceptViolation(res);
        } else {
          ToastS.generalError();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'lg' }} />
      <Text>
        Diese Zahlung wurde bereits festgeschrieben. <br /> Sie können sie daher nicht mehr bearbeiten, sondern nur noch
        stornieren und anschließend neu erfassen.
      </Text>
      <Space h="md" />
      <TextInput
        icon={<IconNote size={16} />}
        label="Stornonotiz"
        placeholder="Grund für Storno"
        value={cancellationCause}
        onChange={(event) => event && setCancellationCause(event.currentTarget.value)}
      />
      <Space h="xs" />
      <DatePicker
        label="Stornodatum"
        icon={<IconCalendarEvent size={16} />}
        rightSection={!editingDate && <IconLock size={16} />}
        disabled={!editingDate}
        value={cancellationDate}
        onChange={(val) => val && setCancellationDate(val)}
      />
      <Space h="xs" />
      <Checkbox
        label="Datum editieren"
        checked={editingDate}
        onChange={(event) => setEditingDate(event.currentTarget.checked)}
      />
      <Space h="xl" />
      <Stack>
        <Button onClick={cancelPayment}>Stornieren</Button>
        <Text color="blue" align="center" sx={{ cursor: 'pointer' }} onClick={() => closeModal(paymentId)}>
          Abbrechen
        </Text>
      </Stack>
    </>
  );
};

export default CancelPaymentModal;
