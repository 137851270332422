// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Group, Loader, Text } from '@mantine/core';
import { i18n } from '@lingui/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { FC, useState } from 'react';
import { GoogleS } from '../../../../Service/GoogleS';
import { GoogleCalendarDto } from '../../../../Types/GoogleT';
import Dot from '../../../../Atoms/Dot';

// eslint-disable-next-line import/prefer-default-export
export const openDeleteGoogleCalendarModal = (calendar: GoogleCalendarDto, removeHandler: () => void) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Kalender löschen
      </Text>
    ),
    closeOnEscape: true,
    transition: 'slide-down',
    children: <DeleteGoogleCalendarModal calendar={calendar} removeHandler={removeHandler} />,
  });
};

const DeleteGoogleCalendarModal: FC<{ calendar: GoogleCalendarDto; removeHandler: () => void }> = ({
  calendar,
  removeHandler,
}) => {
  const [loading, setLoading] = useState(false);
  const { id, calendarName, backgroundColor } = calendar;

  const onConfirm = async () => {
    setLoading(true);
    const ok = await GoogleS.removeCalendar(id);
    if (ok) {
      removeHandler();
      closeAllModals();
    }
    setLoading(false);
  };

  return (
    <>
      <Text>Möchten Sie den folgenden Kalender wirklich entfernen?</Text>
      <Group m="md">
        <Dot withoutShadow colour={backgroundColor} />
        <Text weight="bolder">{calendarName}</Text>
      </Group>
      <Group position="right" spacing="xs" mt="xl">
        <Button disabled={loading} color="gray" variant="subtle" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          color="red"
          variant="subtle"
          sx={{ backgroundColor: '#FFEFEF' }}
          onClick={onConfirm}
          disabled={loading}
          leftIcon={loading && <Loader size="xs" />}
        >
          {i18n._('actions.delete')}
        </Button>
      </Group>
    </>
  );
};
