/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Button, Center, Loader, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconCalendar, IconTag } from '@tabler/icons';
import { FC, useState } from 'react';
import validator from 'validator';
import { i18n } from '@lingui/core';
import { closeAllModals } from '@mantine/modals';
import { CalDavS } from '../../../Service/CalDavS';
import { ResourceCalendar } from '../../../Types/CalDavT';

interface Props {
  resourceId: string;
  onCreate: (calendar: ResourceCalendar) => void;
}

const CreateCalendarModal: FC<Props> = ({ resourceId, onCreate }) => {
  const form = useForm({
    initialValues: {
      label: '',
      calendarUrl: '',
    },
    validate: {
      label: (value) => (value.length > 0 ? null : 'Bitte Etikett eingeben'),
      calendarUrl: (value) =>
        value.length > 0 && validator.isURL(value) ? null : 'Bitte geben Sie die gültige URL ein',
    },
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    const { label, calendarUrl } = form.values;
    setLoading(true);
    CalDavS.createCalendar(resourceId, label, calendarUrl)
      .then((calendar) => {
        if (calendar) {
          onCreate(calendar);
          closeAllModals();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <TextInput
        mt="xl"
        mb="sm"
        label="Beschriftung"
        icon={<IconTag size={16} />}
        disabled={loading}
        {...form.getInputProps('label')}
      />
      <TextInput
        mb="xs"
        label="Kalender-URL"
        icon={<IconCalendar size={16} />}
        placeholder="https://some.calendar.ics"
        disabled={loading}
        {...form.getInputProps('calendarUrl')}
      />
      <Text size="xs" color="dimmed" align="center">
        Stellen Sie sicher, dass die Sichtbarkeit Ihres Kalenders auf „Öffentlich“ eingestellt ist. Andernfalls können
        wir Ihren Kalender nicht verwenden.
      </Text>
      <Center>
        <Box sx={{ width: '70%' }}>
          <Button fullWidth mt="xl" mb="xs" type="submit" disabled={loading} leftIcon={loading && <Loader size="xs" />}>
            {i18n._('actions.save')}
          </Button>
          <Button fullWidth disabled={loading} color="gray" variant="subtle" onClick={() => closeAllModals()}>
            {i18n._('actions.cancel')}
          </Button>
        </Box>
      </Center>
    </form>
  );
};

export default CreateCalendarModal;
