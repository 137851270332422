import React, { useContext, useEffect, useState } from 'react';
import { fetchRelatedContacts, putContactRelation } from '../../Service/restapi/contactRelationService';
import { LoadingAnimation } from '../../Service/ResourceAdapter';
import { TransButtonLink } from '../../Atoms/Buttons';
import { CreateOrSelectContactModal } from '../../Organismns/Contact/CreateOrSelectContactModal';
import { useModalState } from '../Dialogs';
import { ContactRelation } from './ContactRelation';

export const ContactRelationList = ({ contactId }) => {
  const [contactRelations, setContactRelations] = useState([]);
  const { show, toggle } = useModalState(false);

  useEffect(() => {
    fetchContactRelations();
  }, []);

  function fetchContactRelations() {
    fetchRelatedContacts(contactId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((newContactRelations) => {
            setContactRelations(newContactRelations);
          });
        } else {
          console.error('could not fetch related contacts');
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not fetch related contacts');
      });
  }

  function addContactRelation(relatedContactId) {
    putContactRelation(contactId, relatedContactId)
      .then((resp) => {
        if (resp.ok) {
          fetchContactRelations();
        } else {
          console.error(
            `could not save contact relation, contactId: ${contactId} relatedContactId: ${relatedContactId}`,
          );
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not save contact relation, contactId: ${contactId} relatedContactId: ${relatedContactId}`);
      });
  }

  if (!contactRelations) {
    return <LoadingAnimation />;
  }

  const styles = {
    container: {
      marginTop: 10,
    },
    description: {
      color: '#9499a7',
      fontSize: 13,
      marginBottom: 5,
    },
  };

  return (
    <>
      <div style={{ display: 'flex' }}>
        {contactRelations.map((contactRelation, index) => (
          <ContactRelation
            key={index}
            contactRelationId={contactRelation.id}
            refreshContactRelations={fetchContactRelations}
          />
        ))}
      </div>
      <TransButtonLink
        style={{ fontSize: '13px', paddingTop: '0px', paddingLeft: '0px' }}
        text="contact-relation.company.button.add"
        id="contactNew"
        onClick={toggle}
      />

      <CreateOrSelectContactModal
        contactType="PRIVATE"
        show={show}
        toggle={toggle}
        onContactIdSelected={addContactRelation}
      />
    </>
  );
};
