import React, { useContext, useEffect, useState } from 'react';
import { getContactById } from '../../Service/restapi/contactService';
import { EditableContactRelationProperty } from '../../Atoms/EditableProperties/EditableContactRelationProperty';
import { SimpleEditableText } from '../../Atoms/inputs/SimpleEditableText';
import { StringFormatter } from '../../Atoms/Formatter/StringFormatter';
import { deleteContactRelation, fetchContactRelationById } from '../../Service/restapi/contactRelationService';
import { TransButtonLinkWithIcon } from '../../Atoms/Buttons';
import ContactEditModal from '../../Organismns/ContactEditModal';
import ContactListItemDescription from '../../Organismns/Contact/ContactListItemDescription';

export const ContactRelation = ({ contactRelationId, refreshContactRelations }) => {
  const [contact, setContact] = useState(undefined);
  const [contactRelation, setContactRelation] = useState(undefined);
  const [editingContact, setEditingContact] = useState(false);
  const [lastModified, setLastModified] = useState(undefined);

  useEffect(() => {
    fetchContactRelationById(contactRelationId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((newContactRelation) => {
            setContactRelation(newContactRelation);
          });
        } else {
          console.error(`could not load contactRelation${contactRelationId}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not load contactRelation${contactRelationId}`);
      });
  }, [contactRelationId]);

  useEffect(() => {
    if (contactRelation) {
      const { relatedContactId } = contactRelation;
      getContactById(relatedContactId)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((newContact) => {
              setContact(newContact);
            });
          } else {
            console.error(`could not load contact ${relatedContactId}`);
          }
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not load contact ${relatedContactId}`);
        });
    }
  }, [contactRelation]);

  function deleteRelation() {
    deleteContactRelation(contactRelationId)
      .then((resp) => {
        if (resp.ok) {
          refreshContactRelations();
        } else {
          console.error(`could not delete contactRelation ${contactRelationId}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not delete contactRelation ${contactRelationId}`);
      });
  }

  const styles = {
    container: {
      display: 'flex',
      marginBottom: 10,
      border: '1px solid rgba(0, 0, 0, .125)',
      borderRadius: 4,
      width: 'max-content',
      padding: 5,
      marginRight: 5,
    },
    description: {
      fontSize: 13,
      marginBottom: 5,
    },
    remove: {
      marginLeft: 10,
      marginRight: 5,
      marginTop: 6,
    },
  };

  if (!contact) {
    return null;
  }

  return (
    <div style={styles.container}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, marginLeft: 5, ...styles.description }} onClick={() => setEditingContact(true)}>
          <ContactListItemDescription
            contactId={contact.id}
            onEdit={() => {
              setEditingContact(true);
            }}
            lastModified={lastModified}
          />
        </div>
        <div style={{ flex: 1 }}>
          <EditableContactRelationProperty
            patchField="description"
            placeholder="Abteilung"
            contactRelation={contactRelation}
            changeHandler={(patch) => {
              setContactRelation((prevState) => ({
                ...prevState,
                ...patch,
              }));
            }}
            editFormatter={SimpleEditableText}
            labelFormatter={StringFormatter}
          />
        </div>
      </div>
      <div style={styles.remove}>
        <TransButtonLinkWithIcon icon="TIMES" title="Ansprechpartner entfernen" onClick={deleteRelation} />
      </div>
      {editingContact && (
        <ContactEditModal
          setLastModified={setLastModified}
          contactId={contact.id}
          onFinish={() => {
            setEditingContact(false);
          }}
          close={() => {
            setEditingContact(false);
          }}
        />
      )}
    </div>
  );
};
