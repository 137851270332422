import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_DOCUMENT_SERVICE_URL;

export const getContractTemplateNames = () =>
  ajaxActions.get(`${BASE_URL}/contracts/templates/names`).then((resp) => resp);

export const getContractTemplateByName = (templateName) =>
  ajaxActions.get(`${BASE_URL}/contracts/templates/${templateName}`).then((resp) => resp);

export const createContractTemplateByName = (templateName) =>
  ajaxActions.put(`${BASE_URL}/contracts/templates/${templateName}`).then((resp) => resp);

export const createContractTemplate = (template) =>
  ajaxActions.post(`${BASE_URL}/contracts/templates`, template).then((resp) => resp);

export const patchContractTemplate = (templateContractId, patch) =>
  ajaxActions.patch(`${BASE_URL}/contracts/templates/${templateContractId}`, patch).then((resp) => resp);

export const copyContractTemplate = (templateName) =>
  ajaxActions.put(`${BASE_URL}/contracts/templates/${templateName}/copy`).then((resp) => resp);

export const addAboveTextBlockOnContractTemplate = (templateContractId, textBlockId) =>
  ajaxActions
    .put(
      `${BASE_URL}/contracts/templates/${templateContractId}/aboveTextBlocks${
        textBlockId ? `?afterTextBlock=${textBlockId}` : ''
      }`,
    )
    .then((resp) => resp);

export const addBelowTextBlockOnContractTemplate = (templateContractId, textBlockId) =>
  ajaxActions
    .put(
      `${BASE_URL}/contracts/templates/${templateContractId}/belowTextBlocks${
        textBlockId ? `?afterTextBlock=${textBlockId}` : ''
      }`,
    )
    .then((resp) => resp);
