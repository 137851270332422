// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Center, Flex, Space, Stack, Text } from '@mantine/core';
import { useContext, useState } from 'react';
import { DataS } from '../../../Service/DataS';
import { useJwtClaims } from '../../../Service/Context/AuthContext';
import { BillingInterval } from '../../../Types/Billing';
import { WindowContext } from '../../../Service/Context/WindowContext';
import { LicenceS } from '../../../Service/LicenceS';
import BookitupSegmentedControl from '../../../Atoms/BookitupSegmentedControl';
import DjTalkRecommendation from './DjTalkRecommendation';
import BuyPlanContact from './BuyPlanContact';
import Plan from './Plan';
import TrialRedeemAlert from './TrialRedeemAlert';
import AvailableAddons from './AvailableAddons';

const AvailablePlans = () => {
  const [billingInterval, setBillingInterval] = useState<BillingInterval>('Yearly');
  const { sm } = useContext(WindowContext);
  const jwtClaims = useJwtClaims();
  const { licence } = jwtClaims;

  return (
    <>
      <Box mt="md" sx={{ textAlign: 'center' }}>
        <Text size={sm ? 22 : 28} weight="bold">
          360° Eventverwaltung mit bookitup
        </Text>
        <Space h="sm" />
        <Text color="dimmed">Du bezahlst nur, was Du brauchst (und wenn du es brauchst)</Text>
      </Box>
      <Space h="sm" />
      <Center>
        <BookitupSegmentedControl
          color="blue"
          value={billingInterval}
          data={DataS.billingIntervals}
          onChange={(val: BillingInterval) => val && setBillingInterval(val)}
        />
      </Center>
      <Center>{LicenceS.trialRedeemable(jwtClaims) && <TrialRedeemAlert />}</Center>
      <Space h="xl" />
      <Flex gap="md" wrap="wrap" justify="center">
        {DataS.billingPlans.map((plan) => (
          // eslint-disable-next-line react/jsx-key
          <Plan billingPlan={plan} billingInterval={billingInterval} currentLicence={licence} />
        ))}
        <Stack sx={{ maxWidth: 400 }}>
          <AvailableAddons billingInterval={billingInterval} />
            <BuyPlanContact />
          <DjTalkRecommendation />
        </Stack>
      </Flex>
      <Space h="md" />
    </>
  );
};

export default AvailablePlans;
