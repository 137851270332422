import { Alert } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/react';
import React, { useContext } from 'react';
import { Icon } from '../../Atoms/Icons';
import { TransButtonLink } from '../../Atoms/Buttons';
import { createDocument } from '../../Service/restapi/documentService';
import { createContract } from '../../Service/restapi/contractService';

export const UnresolvableInfoBox = ({ history, selectedTemplate, documentType, entityId, eventId, customerId }) => {
  const navigate = useNavigate();

  function editDocument() {
    let editUrl = `/events/${eventId}`;
    let createFn;
    if (documentType === 'CONTRACT') {
      editUrl += '/contracts/';
      createFn = createContract;
    } else {
      editUrl += '/documents/';
      createFn = createDocument;
    }

    if (selectedTemplate) {
      // create with selected document type and templateName
      createFn(customerId, selectedTemplate, eventId, documentType)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((newDocument) => {
              navigate(editUrl + newDocument.id);
            });
          } else {
            console.error(`could not create ${documentType} of template ${selectedTemplate}`);
          }
        })
        .catch((error) => {
          console.error(`could not create ${documentType} of template ${selectedTemplate}`);
          console.error(error);
        });
    } else {
      navigate(editUrl + entityId);
    }
  }

  const styles = {
    container: {
      margin: 5,
    },
  };

  const url = `/events/${eventId}/details?${
    selectedTemplate
      ? `document_type=${documentType.toLowerCase()}&template_name=${selectedTemplate}`
      : `entityType=${documentType === 'CONTRACT' ? 'contracts' : 'documents'}&entityId=${entityId}`
  }`;

  return (
    <div style={styles.container}>
      <Alert color="info">
        <Icon value="BULB" /> <Trans id="event.unfilled" />
        <TransButtonLink
          style={{ color: '#4f6a83' }}
          onClick={() => {
            navigate(url);
          }}
          text="edit.event"
          title="edit.event.title"
        />{' '}
        <TransButtonLink
          style={{ color: '#4f6a83' }}
          onClick={editDocument}
          text="edit.document"
          title="edit.document"
        />
      </Alert>
    </div>
  );
};
