import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { EventContext } from '../../Service/Context/EventContext';
import { EventLocationContext } from '../../Service/Context/EventLocationContext';
import { CustomerContext } from '../../Service/Context/CustomerContext';
import { EventParticipationsContext } from '../../Service/Context/EventParticipationContext';
import { CustomAttributesContext } from '../../Service/Context/CustomAttributeContext';
import { EmailBoxContextProvider } from '../../Service/Context/EmailBoxContext';
import EmailsSection from '../../Organismns/Message/EmailsSection';

const Messaging = () => {
  const { box } = useParams();
  const { event, refreshEvent } = useContext(EventContext);
  const { customer } = useContext(CustomerContext);
  const { participations } = useContext(EventParticipationsContext);
  const { location } = useContext(EventLocationContext);
  const { customAttributeValues } = useContext(CustomAttributesContext);

  const contactIds = [];
  participations.forEach((participation) => {
    contactIds.push(participation.contactId);
  });

  return (
    <EmailBoxContextProvider contactIds={contactIds}>
      <EmailsSection />
    </EmailBoxContextProvider>
  );
};

export default Messaging;
