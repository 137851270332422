import { FC, useContext } from 'react';
import { useLingui } from '@lingui/react';
import { openConfirmModal } from '@mantine/modals';
import { Text } from '@mantine/core';
import { TransButtonLink } from '../../../Atoms/Buttons';
import { Icon } from '../../../Atoms/Icons';
import { ContactDeleteFlowContext } from './ContactDeleteFlowContext';

const ContactDeleteFlow4: FC = () => {
  const { i18n } = useLingui();

  const {
    eventParticipations,
    mainCustomerParticipations,
    otherParticipations,
    isDeletingEvents,
    isRemovingParticipants,
    delEvents,
    removeParticipations,
  } = useContext(ContactDeleteFlowContext);

  const confirmDeleteEvents = () => {
    openConfirmModal({
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          {i18n._('common.delete.event.confirm.head')}
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      labels: { confirm: 'Ja', cancel: 'Abbrechen' },
      children: <Text>{i18n._('common.delete.event.confirm')}</Text>,
      onConfirm: delEvents,
    });
  };

  const confirmRemoveParticipants = () => {
    openConfirmModal({
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          {i18n._('contact.delete.eventParticipation.confirm.head')}
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      labels: { confirm: 'Ja', cancel: 'Abbrechen' },
      children: <Text>{i18n._('contact.delete.eventParticipation.confirm')}</Text>,
      onConfirm: removeParticipations,
    });
  };

  if (!eventParticipations || eventParticipations?.length === 0) {
    return (
      <div>
        <Icon value="CHECK" /> Keine Beteiligungen an Events zu diesem Kontakt.
      </div>
    );
  }
  const styles = {
    description: {
      color: '#9499a7',
    },
  };
  return (
    <div>
      {mainCustomerParticipations.length > 0 && (
        <>
          <div>
            Dieser Kontakt ist Hauptkunde von{' '}
            {mainCustomerParticipations.length === 1 ? 'einem Event' : `${mainCustomerParticipations.length} Events`}.
            <div style={styles.description}>
              Ein Event muss einen Kontakt als Hauptkunden haben. Du kannst im Event einen anderen Hauptkunden setzen.
            </div>
            {mainCustomerParticipations.length > 1 && (
              <div style={styles.description}>
                Wenn du die Events nicht mehr brauchst, kannst Du diese direkt hier löschen:
              </div>
            )}
            {mainCustomerParticipations.length === 1 && (
              <div style={styles.description}>
                Wenn du das Event nicht mehr brauchst, kannst Du dieses direkt hier löschen:
              </div>
            )}
          </div>

          <TransButtonLink
            isLoading={isDeletingEvents}
            style={{ color: '#9499a7', marginTop: 10 }}
            icon="TRASH"
            className="primary-link"
            onClick={confirmDeleteEvents}
            text={`${
              mainCustomerParticipations.length === 1 ? '1 Event' : `${mainCustomerParticipations.length} Events`
            } löschen`}
          />
        </>
      )}

      {mainCustomerParticipations.length === 0 && otherParticipations.length > 0 && (
        <>
          <div>
            Dieser Kontakt ist {otherParticipations.length} Events zugeordnet.
            <div style={styles.description}>
              Um diesen Kontakt zu löschen, kannst Du diese Zuordnungen hier entfernen. Die Events selber bleiben
              ansonsten unverändert erhalten.
            </div>
            <br />
          </div>
          <TransButtonLink
            isLoading={isRemovingParticipants}
            style={{ color: '#9499a7', marginTop: 10 }}
            icon="TRASH"
            className="primary-link"
            onClick={confirmRemoveParticipants}
            text={`Kontakt von ${otherParticipations.length} Events entfernen`}
          />
        </>
      )}
    </div>
  );
};

export default ContactDeleteFlow4;
