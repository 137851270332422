import React, { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import ClipboardButton from '../../Atoms/buttons/ClipboardButton';
import { ModalDialog, useConfirmModalState, useModalState } from '../../Molecules/Dialogs';
import TooltipButton from '../../Atoms/buttons/TooltipButton';
import { Pane } from '../../Molecules/Pane';
import { resetLogin } from '../../Service/restapi/shareService';
import { TransButtonDefault, TransButtonDefaultWithIcon } from '../../Atoms/Buttons';
import { EditableShareProperty } from '../../Atoms/EditableProperties/EditableShareProperty';
import { StringFormatter } from '../../Atoms/Formatter/StringFormatter';
import { SimpleEditableText } from '../../Atoms/inputs/SimpleEditableText';
import EditableDateInput from '../../Atoms/inputs/EditableDateInput';
import { DateFormatter } from '../../Atoms/Formatter/DateFormatter';
import {WindowContext} from "../../Service/Context/WindowContext";
import { EventPortalContext } from '../../Service/Context/EventPortalContext';
import SharedResourceList from './SharedResourceList';

export default function ({ share, eventId }) {
  const { reload } = useContext(EventPortalContext);
  const { show, toggle } = useModalState(false);
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);
  const [link, setLink] = useState(undefined);
  const {windowWidth} = useContext(WindowContext);

  useEffect(() => {
    setLink(`${process.env.REACT_APP_PORTAL_SERVICE_URL}/login?shareId=${share.id}&key=${share.password}`);
  }, [share]);

  const handleChange = (patch) => {
    reload();
  }

  const styles = {
    containerBorder: {
      border: '1px solid rgba(0, 0, 0, 0.125)',
      borderRadius: '4px',
      padding: 5,
      marginBottom: 5,
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 5,
      flexDirection: 'column',
    },
    infos: {
      flex: 1,
      borderRadius: 4,
      padding: 5,
      display: 'flex',
      justifyContent: 'space-between',
    },
    dates: {
      display: 'flex',
      color: '#666666',
      fontSize: 13,
    },
    links: {
      display: 'flex',
    },

    description: {
      color: '#666666',
      fontSize: 12,
      alignSelf: 'center',
      display: 'flex',
    },
    codeField: {
      fontSize: 10,
    },
  };

  function resetLastLogin() {
    resetLogin(share.id, { lastLogin: null }).then((resp) => {
      console.log('Resetting last portal login');
      if (resp.ok) {
        console.log('Login reset successful');
        // reload();
      } else console.error('Something went wrong');
    });
  }

  return (
    <Pane
      style={{ marginBottom: 20 }}
      head={
        <>
          <div style={{ display: 'flex' }}>
            {windowWidth > 800 && <div style={{marginRight: 10, alignSelf: "center"}}>Beschreibung des Zugangs:</div>}
            <EditableShareProperty
              patchField="name"
              inlinePlaceholder="Name des Zugangs"
              handleChange={handleChange}
              entity={share}
              labelFormatter={StringFormatter}
              editFormatter={SimpleEditableText}
            />
          </div>
          <div style={{ display: 'flex' }}>
            <TransButtonDefaultWithIcon
              icon="LOGIN"
              style={{...styles.icon, marginRight: 10 }}
              text={windowWidth > 1000 ? "Portal öffnen" : ""}
              onClickHandler={() =>
                window.open(`${link}`, '_blank')
              }
            />

            <ClipboardButton text={link} buttonText={"Link kopieren"}/>
          </div>

          <ModalDialog
            className="modal-xl"
            header="Zugangspasswort ändern"
            body={
              <div>
                <EditableShareProperty
                  patchField="password"
                  placeholder="Kennwort"
                  handleChange={handleChange}
                  entity={share}
                  labelFormatter={StringFormatter}
                  editFormatter={SimpleEditableText}
                />
              </div>
            }
            footer={<TransButtonDefault onClickHandler={toggle} text="actions.ok" />}
            show={show}
            toggle={toggle}
          />

          <ModalDialog
            className="modal-xl"
            header="Freigabedatum ändern"
            body={
              <div>
                <EditableShareProperty
                  patchField="sharedUntil"
                  placeholder="Geteilt bis"
                  handleChange={handleChange}
                  entity={share}
                  labelFormatter={DateFormatter}
                  editFormatter={EditableDateInput}
                />
              </div>
            }
            footer={<TransButtonDefault onClickHandler={toggleConfirm} text="actions.ok" />}
            show={showConfirm}
            toggle={toggleConfirm}
          />
        </>
      }
      component={
        <div style={styles.container}>
          <SharedResourceList shareId={share.id} eventId={eventId} />
        </div>
      }
      footer={
        <div style={styles.dates}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>Letzter Login ins Portal:&nbsp;</div>
            {share.lastLogin && (
              <div>
                <Moment fromNow>{share.lastLogin}</Moment>
              </div>
            )}
            {!share.lastLogin && <div>nie</div>}
            {share.lastLogin && (
              <TooltipButton
                style={{ marginLeft: 5 }}
                icon="HISTORY"
                id="reset-portal-last-login"
                text="Zeitpunkt zurücksetzen"
                onClick={() => resetLastLogin()}
              />
            )}
          </div>
          {share.sharedUntil && (
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 5 }}>
              <div>Geteilt bis:&nbsp;</div>
              <div>
                <Moment fromNow>{share.sharedUntil}</Moment>
              </div>
              <TooltipButton
                style={{ marginLeft: 5 }}
                icon="EDIT"
                id={`shared-until-${share.id}`}
                text="Geteilt bis ändern"
                onClick={toggleConfirm}
              />
            </div>
          )}
          <div style={{ display: 'flex', marginLeft: 10, alignItems: 'center' }}>Kennwort: {share.password}</div>
          <TooltipButton
            style={{ marginLeft: 5 }}
            icon="EDIT"
            id={`share-copy-link-${share.id}`}
            text="Kennwort ändern"
            onClick={toggle}
          />
        </div>
      }
    />
  );
}
