// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text, Stack, Divider, Box, Group, Skeleton, ThemeIcon, Flex, Tooltip } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { IconCurrencyEuro, IconLink } from '@tabler/icons';
import { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { DocumentContext } from '../../../../Service/Context/DocumentContext';
import { FormatS } from '../../../../Service/FormatS';
import { ToastS } from '../../../../Service/ToastS';
import { DocumentS } from '../../../../Templates/Documents/DocumentS';
import BookitupDocument from '../../../../Types/BookitupDocument';

const UnassginedPartialInvoices = () => {
  const { document, refresh } = useContext<any>(DocumentContext);
  const { id: documentId, eventId, partialInvoices: documentPartialInvoices } = document as BookitupDocument;
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [partialInvoices, setPartialInvoices] = useState<BookitupDocument[]>([]);
  const { ref, width } = useElementSize();
  const previewVersion = width < 500;

  useEffect(() => {
    loadInvoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentPartialInvoices]);

  const loadInvoices = () => {
    if (eventId) {
      setLoading(true);
      DocumentS.getUnassignedPartialInvoices(eventId)
        .then((invoices) => {
          if (invoices) {
            setPartialInvoices(invoices);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const assignPartialInvoice = (id: number, paid?: boolean) => {
    if (paid) {
      DocumentS.patchDocument(id, { finalDocument: documentId }).then((ok) => {
        if (ok) {
          refresh();
          loadInvoices();
        }
      });
    } else {
      ToastS.warn('not-paid', 'Diese Abschlagsrechnung wurde noch nicht komplett bezahlt');
    }
  };

  const mapInvoiceUI = (partialInvoice: BookitupDocument) => {
    const { id, number, date, sum, paid } = partialInvoice;
    const creationDate = new Date(date);
    const taxRate = partialInvoice.positions[0].tax;
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {previewVersion ? (
          <>
            <Flex p="sm">
              <Text sx={{ flexGrow: 1 }}>
                <ThemeIcon radius="xl" size="sm" mr="md" color={paid ? 'green' : 'gray'}>
                  <IconCurrencyEuro size={14} />
                </ThemeIcon>
                <Text
                  span
                  color="blue"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/events/${eventId}/documents/${id}`)}
                >
                  {` ${number} `}
                </Text>
                vom{'  '}
                <Moment date={creationDate} format="DD.MM.YYYY" />
              </Text>

              <div style={{ width: 20 }}>
                <IconLink onClick={() => assignPartialInvoice(id, paid)} size={20} color="gray" />
              </div>
            </Flex>
            <Flex p="md" pt={0} direction="row" wrap="wrap" gap="md" rowGap="xs">
              <Text size="xs" weight="bolder">
                Brutto:
                <Text span weight="normal">
                  {` ${FormatS.numberToCurrency(sum)}`}
                </Text>
              </Text>
              <Text size="xs" weight="bolder">
                USt:
                <Text span weight="normal">
                  {` ${FormatS.numberToCurrency(sum)}`}
                </Text>
              </Text>
            </Flex>
          </>
        ) : (
          <Group
            p="sm"
            position="apart"
            sx={(theme) => ({
              borderRadius: 5,
              ':hover': { backgroundColor: theme.colors.lightBlue[0] },
            })}
          >
            <Text>
              <ThemeIcon radius="xl" size="sm" mr="md" color={paid ? 'green' : 'gray'}>
                <IconCurrencyEuro size={14} />
              </ThemeIcon>
              {width > 700 && 'Abschlagsrechnung'}
              <Text
                span
                color="blue"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/events/${eventId}/documents/${id}`)}
              >
                {` ${number} `}
              </Text>
              vom{'  '}
              <Moment date={creationDate} format="DD.MM.YYYY" />
              {`  (USt: ${taxRate}%) `}
            </Text>
            <Text>{FormatS.formatNumberCurrencyWithUnitSign(sum)}</Text>
            <Tooltip
              withArrow
              disabled={paid}
              transition="slide-left"
              position="left"
              label={<Text italic>Diese Abschlagsrechnung wurde noch nicht komplett bezahlt</Text>}
            >
              <Text
                size="xs"
                weight="lighter"
                color="blue"
                sx={{ cursor: paid ? 'pointer' : 'not-allowed' }}
                onClick={() => assignPartialInvoice(id, true)}
              >
                <u>Hinzufügen</u>
              </Text>
            </Tooltip>
          </Group>
        )}
      </>
    );
  };

  if (!loading && partialInvoices.length === 0) {
    return null;
  }

  return (
    <div ref={ref}>
      <Skeleton mt="md" mb="xl" visible={loading}>
        <Stack
          spacing={0}
          sx={(theme) => ({
            border: `1px solid ${theme.colors.gray[2]}`,
            borderRadius: 10,
          })}
        >
          <Box sx={{ backgroundColor: '#F1F1F1', borderTopLeftRadius: 10, borderTopRightRadius: 10 }}>
            <Text m="sm" weight="bolder">
              {`${
                !previewVersion ? 'Es gibt nicht zugeordnete Abschlagsrechnungen zu Ihrem Kunden. ' : ''
              }Jetzt hinzufügen?`}
            </Text>
          </Box>
          <Divider />
          <Box>{partialInvoices.map(mapInvoiceUI)}</Box>
        </Stack>
      </Skeleton>
    </div>
  );
};

export default UnassginedPartialInvoices;
