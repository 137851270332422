import { Center } from '@mantine/core';
import { FC } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import SemanticForm from '../../../Types/SemanticForm';
import ContactFormActions from './ContactFormActions';

type ContactFormItemProps = {
  form: SemanticForm;
  showDatum: boolean;
  showName: boolean;
  showCount: boolean;
  narrowActions: boolean;
  reload: () => unknown;
};

const ContactFormListItem: FC<ContactFormItemProps> = ({
  form,
  showDatum,
  showName,
  showCount,
  narrowActions,
  reload,
}) => {
  const { id: semanticFormId } = form;
  const navigate = useNavigate();
  const openForm = () => navigate(`/settings/contact-form/${semanticFormId}`);

  return (
    <tr>
      {showName && (
        <td onClick={openForm} style={{ cursor: 'pointer' }}>
          {form.name ?? form.id}
        </td>
      )}
      {showDatum && (
        <td onClick={openForm} style={{ cursor: 'pointer' }}>
          <Moment date={form.lastModified} format="DD.MM.YYYY" />
        </td>
      )}
      {showCount && (
        <td onClick={openForm} style={{ cursor: 'pointer' }}>
          {form.submissionsCount ?? 0}
        </td>
      )}
      {narrowActions && (
        <td style={{ position: 'relative' }}>
          <Center>
            <div style={{ position: 'absolute', right: 0 }}>
              <ContactFormActions form={form} reload={reload} />
            </div>
          </Center>
        </td>
      )}
    </tr>
  );
};

export default ContactFormListItem;
