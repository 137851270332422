import React, { useContext, useEffect, useState } from 'react';
import { TransModalDialogWithTextField } from '../Dialogs';
import { getDocumentTemplateNames, saveTemplate } from '../../Service/restapi/templateService';
import {
  createQuestionnaireTemplateByName,
  getQuestionnaireTemplateNames,
} from '../../Service/restapi/questionnaireService';
import { ToastS } from '../../Service/ToastS';
import { useJwtClaims } from '../../Service/Context/AuthContext';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import { LicenceS } from '../../Service/LicenceS';

export default function ({ documentType, entityId, close, open }) {
  const [template, setTemplate] = useState(undefined);
  const [templates, setTemplates] = useState(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (documentType) {
      getDocumentTemplateNames(documentType).then((resp) => {
        if (resp.ok) {
          resp.json().then(setTemplates);
        }
      });
    } else {
      getQuestionnaireTemplateNames().then((resp) => {
        if (resp.ok) {
          resp.json().then(setTemplates);
        }
      });
    }
  }, []);

  if (!templates) {
    return null;
  }

  const { executeWithLicenceCheck } = useContext(ViolationContext);

  function save() {
    const fn = () => {
      let fct = saveTemplate;
      if (!documentType) {
        fct = createQuestionnaireTemplateByName;
      }
      setLoading(true);
      fct(template, entityId)
        .then((resp) => {
          if (resp.ok) {
            ToastS.success('save-result', 'Erfolgreich gespeichert');
          } else {
            ToastS.error('save-result', 'Vorlage konnte nicht gespeichert werden');
          }
          close();
        })
        .catch(() => {
          ToastS.error('save-result', 'Vorlage konnte nicht gespeichert werden');
        })
        .finally(() => {
          setLoading(false);
        });
    };
    executeWithLicenceCheck(LicenceS.Restrictions.MULTIPLE_DOCUMENT_TEMPLATE, fn);
  }

  function changeTemplateInput(e) {
    setTemplate(e.target.value);
  }

  return (
    <TransModalDialogWithTextField
      isLoading={loading}
      subject="document.template.save"
      value={template}
      name="template"
      onChange={changeTemplateInput}
      placeholder="document.template"
      takenIdentifier={templates}
      show={open}
      confirm={save}
      toggle={close}
    />
  );
}
