import { Skeleton, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { ChangeEventStatusConfigDTO, ProcedureReportProps } from '../../../../Types/LogT';
import { CustomEventState } from '../../../../Types/EventT';
import { EventS } from '../../../../Service/EventS';

const ChangeEventStatusReport: FC<ProcedureReportProps> = ({ report, config }) => {
  const { bookingState, customStateId } = config as ChangeEventStatusConfigDTO;
  const [customState, setCustomState] = useState<CustomEventState | null>(null);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    if (customStateId) {
      setLoading(true);
      EventS.fetchCustomState(customStateId)
        .then(setCustomState)
        .finally(() => setLoading(false));
    }
  }, [customStateId]);

  return (
    <Text>
      Eventstatus wurde auf{'  '}
      <Text span weight="bolder">
          {loading ? (
            <Skeleton mr={8} ml={8} mb={-3} width={100} height={16} sx={{ display: 'inline-block' }} />
          ) : (
            <>{customState ? ` ${customState.label} ` : i18n._(`bookingState.${bookingState}`)}</>
          )}
        </Text>
      {'  '}gesetzt.
    </Text>
  );
};

export default ChangeEventStatusReport;
