/* eslint-disable react/jsx-props-no-spreading */
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useElementSize } from '@mantine/hooks';
import { Skeleton, Text } from '@mantine/core';
import { useModalState } from '../../Dialogs';
import SelectContractTemplateView from '../../../Organismns/Contract/SelectContractTemplateView';
import { createContract, fetchContractsByEvent } from '../../../Service/restapi/contractService';
import { EventParticipationsContext } from '../../../Service/Context/EventParticipationContext';
import { EventContext } from '../../../Service/Context/EventContext';
import PanePaper from '../../PanePaper';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import BookitupTable from '../../../Atoms/BookitupTable';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import { ConstantS } from '../../../Service/ConstantS';
import { MixpanelS } from '../../../Service/MixpanelS';
import BookitupTutorialIcon from '../../../Atoms/BookitupTutorialIcon';
import { ToastS } from '../../../Service/ToastS';
import ContractItem from './ContractItem';
import { DocumentS } from '../../../Templates/Documents/DocumentS';

const ContractPane = () => {
  const { show, toggle } = useModalState();
  const { event } = useContext(EventContext);
  const { executeWithLicenceCheck, interceptViolation } = useContext(ViolationContext);
  const { mainCustomerId } = useContext(EventParticipationsContext);
  const [isLoading, setLoading] = useState(false);
  const [contracts, setContracts] = useState();
  const navigate = useNavigate();
  const { ref, width } = useElementSize();

  useEffect(() => {
    fetchContracts();
  }, []);

  function fetchContracts() {
    setLoading(true);
    setContracts(undefined);
    fetchContractsByEvent(event.id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((newDocuments) => {
            setContracts(newDocuments);
          });
        } else {
          console.error(`could not load contracts of event ${event.id}`);
        }
      })
      .catch((error) => {
        console.error(`could not load contracts of event ${event.id}`);
        console.error({ error });
      })
      .finally(() => setLoading(false));
  }

  const create = async (templateName, bookingDocument) => {
    setLoading(true);
    if (bookingDocument && bookingDocument.draft) {
      const resJSON = await DocumentS.patchDocument(bookingDocument.id, { draft: false }, interceptViolation);
      if (!resJSON) {
        setLoading(false);
        return;
      }
    }
    createContract(mainCustomerId, templateName, event.id, bookingDocument ? bookingDocument.id : undefined)
      .then((resp) => {
        toggle();
        if (resp.ok) {
          resp.json().then((newContract) => {
            MixpanelS.track(ConstantS.TrackingEvents.ContractCreated);
            navigate(`/events/${event.id}/contracts/${newContract.id}`);
          });
        } else {
          ToastS.generalError();
        }
      })
      .finally(() => setLoading(false));
  };

  const showColumns = {
    showNumber: width > 300,
    showDatum: width > 350,
    showName: width > 500,
    showLabel: width > 500,
    narrowPortalStatus: width < 200,
    showPortal: width >= 150,
  };

  return (
    <PanePaper
      title="common.contracts"
      tooltip="help.event.contracts-offline.pane"
      rightSection={
        <>
          <BookitupTutorialIcon videoUrl={'https://www.youtube.com/watch?v=i6AmjEn-umg'} name={'CreateContract'} />
          <BookitupAddIcon onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.CONTRACT_USAGE, toggle)} />
        </>
      }
    >
      <div ref={ref}>
        {isLoading ? (
          <div>
            {Array.from(Array(3)).map(() => (
              <Skeleton key={Math.random()} radius="md" height={25} width="100%" mb={10} />
            ))}
          </div>
        ) : (
          <div>
            {!contracts || contracts.length === 0 ? (
              <Text italic color="dimmed" size="xs" weight="lighter">
                Noch nichts hinzugefügt.
              </Text>
            ) : (
              <BookitupTable>
                <thead>
                  <tr>
                    <th style={{ width: 50 }}>Status</th>
                    {showColumns.showNumber && <th style={{ width: 50 }}>Nummer</th>}
                    {showColumns.showLabel && <th style={{ width: 100 }}>Name</th>}
                    {showColumns.showName && <th>Vertragspartner:in</th>}
                    {showColumns.showDatum && <th style={{ width: 50 }}>Datum</th>}
                    {showColumns.showPortal && (
                      <th style={{ width: showColumns.narrowPortalStatus ? 50 : 120 }}>Portal</th>
                    )}
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  {contracts.map((contract) => (
                    <ContractItem
                      key={contract.id}
                      contract={contract}
                      reload={() => fetchContracts()}
                      {...showColumns}
                    />
                  ))}
                </tbody>
              </BookitupTable>
            )}
          </div>
        )}
      </div>

      <SelectContractTemplateView
        open={show}
        toggle={toggle}
        isLoading={isLoading}
        finish={create}
        eventId={event.id}
      />
    </PanePaper>
  );
};

export default ContractPane;
