import { IconGripVertical } from '@tabler/icons';

export default function ({ dragRef }) {
  const styles = {
    moveHandle: {
      cursor: 'move',
      alignSelf: 'center',
      marginRight: 5,
    },
  };

  return (
    <div style={styles.moveHandle} ref={dragRef}>
      <IconGripVertical color="gray" size={16} style={{ cursor: 'move' }} />
    </div>
  );
}
