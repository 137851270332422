import React from 'react';
import { withI18n } from '@lingui/react';
import { EditableQuestionProperty } from '../../Atoms/EditableProperties/EditableQuestionProperty';
import { preChange } from '../../Utils/utils';
import { EditableDivInput } from '../../Atoms/inputs/EditableDivInput';
import { HtmlPlaceholderFormatter } from '../../Atoms/Formatter/HtmlPlaceholderFormatter';
import { EditableSelectField } from '../../Atoms/inputs/EditableSelectField';
import { EditableCheckbox } from '../../Atoms/inputs/EditableCheckbox';
import Answer from './Answer';
import { Group, Text } from '@mantine/core';
import QuestionCustomAttribute from './QuestionCustomAttribute';

export default withI18n()(
  ({ i18n, index, handleChange, val, event, eventLocation, customer, customAttributeValues, settings }) => {
    const attributeTypes = [
      { value: 'STRING', label: 'custom-attribute.string' },
      { value: 'TEXT', label: 'custom-attribute.text' },
      { value: 'SINGLE_CHOICE', label: 'custom-attribute.single_choice' },
      { value: 'MULTIPLE_CHOICE', label: 'custom-attribute.multiple_choice' },
      { value: 'BOOLEAN', label: 'custom-attribute.boolean' },
      { value: 'DATE', label: 'custom-attribute.date' },
      { value: 'TIME', label: 'custom-attribute.time' },
      { value: 'NUMBER', label: 'custom-attribute.number' },
      { value: 'DECIMAL', label: 'custom-attribute.decimal' },
      { value: 'FILE_UPLOAD', label: 'custom-attribute.file_upload' },
    ];

    const styles = {
      detailComponent: {
        flex: 1,
        padding: 5,
      },
      firstLineContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      descriptionComponent: {
        flex: 1,
        marginRight: 10,
        maxWidth: 200,
      },
      subtextComponent: {
        flex: 1,
        minWidth: 400,
      },
      secondLineComponent: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 5,
      },
      questionComponent: {
        flex: 1,
        marginRight: 15,
        minWidth: 400,
      },
      typeComponent: {
        flex: 1,
        maxWidth: 200,
      },
    };

    return (
      <div style={styles.detailComponent}>
        <div style={styles.firstLineContainer}>
          {/* <div style={styles.descriptionComponent}>
                                        <QuestionnaireDescription value={val} index={index} focused={() => {
                                        }} changeHandler={change} name="description" options={fields}/>
                                    </div> */}
          <div style={styles.questionComponent}>
            <EditableQuestionProperty
              index={index}
              name="question"
              patchField="question"
              inlinePlaceholder="Frage"
              preChange={(p) => preChange(p, i18n)}
              changeHandler={handleChange}
              entity={val}
              editFormatter={EditableDivInput}
              labelFormatter={HtmlPlaceholderFormatter}
              settings={settings}
              event={event}
              eventLocation={eventLocation}
              customer={customer}
              customAttributeValues={customAttributeValues}
            />
          </div>
          <div style={styles.typeComponent}>
            <EditableQuestionProperty
              entity={val}
              changeHandler={handleChange}
              patchField="attributeType"
              options={attributeTypes}
              editFormatter={EditableSelectField}
              editOnly
            />
          </div>
        </div>
        <div style={styles.secondLineComponent}>
          {/* <div style={styles.typeComponent}>
                                        <QuestionnaireType index={index} value={val.type} changeHandler={change}
                                                           name="type"/>
                                    </div> */}
          <div style={styles.subtextComponent}>
            <EditableQuestionProperty
              index={index}
              inlinePlaceholder="Ergänzende Angaben / Hilfestellung"
              name="description"
              patchField="description"
              preChange={(p) => preChange(p, i18n)}
              changeHandler={handleChange}
              entity={val}
              editFormatter={EditableDivInput}
              labelFormatter={HtmlPlaceholderFormatter}
              settings={settings}
              event={event}
              eventLocation={eventLocation}
              customer={customer}
              customAttributeValues={customAttributeValues}
            />
          </div>
        </div>
        <Group position="right">
          <QuestionCustomAttribute question={val} handleChange={handleChange} />
          <EditableQuestionProperty
            entity={val}
            changeHandler={handleChange}
            style={{ margin: 10 }}
            patchField="printMinimalQuestion"
            editFormatter={EditableCheckbox}
            placeholder="Frage minimal drucken"
            editOnly
          />
          {(val.attributeType === 'SINGLE_CHOICE' || val.attributeType === 'MULTIPLE_CHOICE') && (
            <EditableQuestionProperty
              entity={val}
              changeHandler={handleChange}
              style={{ margin: 10 }}
              patchField="printMinimalAnswer"
              editFormatter={EditableCheckbox}
              placeholder="Antwort minimal drucken"
              editOnly
            />
          )}
        </Group>
        <Answer
          attributeType={val.attributeType}
          questionId={val.id}
          settings={settings}
          event={event}
          eventLocation={eventLocation}
          customer={customer}
          customAttributeValues={customAttributeValues}
        />
        {/* <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                                    <div className={"description"}>Antwort übertragen in:</div>
                                    <div style={{width: 200,}}>
                                        <AssignField
                                            style={{flex: 1, flexDirection: "column", marginTop: 10,}}
                                            targetFieldId={val.targetAttributeId}
                                            i18nPreLabel={"webform.field."}
                                            field={undefined}
                                            exampleValue={undefined}
                                            customAttributesOnly={true}
                                            onChange={(value) => {
                                                patchTargetAttribute(val.id, {targetAttributeId: value.value})
                                            }}
                                        />
                                    </div>
                                </div> */}
      </div>
    );
  },
);
