import { FC, useEffect, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd-latest';
import { Group, Box, ActionIcon, Accordion, Text, Stack, Space, Checkbox, Flex } from '@mantine/core';
import { IconChevronRight, IconTrashX } from '@tabler/icons';
import { CustomAttributeDTO, CustomAttributeGroupDTO } from '../../../Types/CustomAttributeT';
import { CustomAttributeS } from '../../../Service/Context/CustomAttributeS';
import DragButton from '../../../Atoms/DragDrop/DragButton';
import { ConfirmDialog, useConfirmModalState } from '../../Dialogs';
import CustomAttributeFieldSettings from '../../../Templates/Settings/CustomFields/CustomAttributeFieldSettings';
import { DataS } from '../../../Service/DataS';
import SkeletonItems from '../../../Atoms/SkeletonItems';

interface Props {
  index: number;
  group: CustomAttributeGroupDTO;
  deleteGroup: () => void;
  entityType: string;
  setAttributeGroups: (groups: CustomAttributeGroupDTO[]) => void;
  attributeGroups: CustomAttributeGroupDTO[];
  reload: () => void;
}

const AttributesGroup: FC<Props> = ({
  index,
  group,
  deleteGroup,
  entityType,
  setAttributeGroups,
  attributeGroups,
  reload,
}) => {
  const [loading, setLoading] = useState(false);
  const [attributes, setAttributes] = useState<CustomAttributeDTO[]>([]);
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);
  const { length } = attributes;
  const groupsSelectOptions = DataS.getAttributeGroupsOptions(attributeGroups);
  const { id: groupId, name, openAutomatically } = group;
  const [opened, setOpened] = useState(openAutomatically);

  const [, drag] = useDrag(
    () => ({
      type: 'group',
      item: { index },
    }),
    [groupId],
  );

  useEffect(() => {
    setOpened(openAutomatically);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const [, drop] = useDrop(
    () => ({
      accept: 'group',
      collect(monitor) {
        return { handlerId: monitor.getHandlerId() };
      },
      drop(item: any) {
        if (item.index !== index) {
          CustomAttributeS.moveGroup('event', item.index, index).then((groups) => {
            if (groups) {
              setAttributeGroups(groups);
            }
          });
        }
      },
    }),
    [group.id],
  );

  const toggleOpened = () => {
    CustomAttributeS.editGroup(groupId, { openAutomatically: !opened }).then((_group) => {
      if (_group) {
        setOpened((prev) => !prev);
      }
    });
  };

  const editAttribute = (id: string, patch: Partial<CustomAttributeDTO>) => {
    CustomAttributeS.editAttribute(id, patch).then((_attribute) => {
      if (_attribute) {
        if (patch.groupId) {
          reload();
        } else {
          setAttributes(attributes.map((a) => (a.id === id ? _attribute : a)));
        }
      }
    });
  };

  const removeAttribute = (attribute: CustomAttributeDTO) =>
    setAttributes(attributes.filter((_attribute) => _attribute !== attribute));

  useEffect(() => {
    setLoading(true);
    CustomAttributeS.fetchGroupAttributes(groupId)
      .then(setAttributes)
      .finally(() => setLoading(false));
  }, [groupId]);

  return (
    <Flex align="center">
      <div ref={drop} style={{ width: 30 }}>
        <DragButton dragRef={drag} />
      </div>
      <Accordion
        sx={{ flexGrow: 1 }}
        chevron={<IconChevronRight size="1rem" />}
        styles={{
          chevron: {
            '&[data-rotate]': {
              transform: 'rotate(90deg)',
            },
          },
        }}
        variant="separated"
        defaultValue="foo"
        chevronPosition="left"
      >
        <Accordion.Item value={openAutomatically ? 'foo' : 'closed'}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Accordion.Control>{name}</Accordion.Control>
            <ActionIcon size="lg">
              {length === 0 && <IconTrashX size="1rem" color="black" onClick={toggleConfirm} />}
            </ActionIcon>
          </Box>
          <Accordion.Panel>
            {entityType !== 'contact' && (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {attributes.length === 0 && (
                  <ConfirmDialog
                    text="custom-attribute.group.delete.confirm"
                    subject="custom-attribute.group.delete.confirm.head"
                    open={showConfirm}
                    toggle={toggleConfirm}
                    handler={deleteGroup}
                    // eslint-disable-next-line react/no-children-prop
                    children={[]}
                    isLoading={false}
                    confirmText={''}
                    disableConfirm={false}
                  />
                )}
              </>
            )}
            {loading ? (
              <>
                <Space h="md" />
                <SkeletonItems height={50} mb={5} />
              </>
            ) : (
              <div>
                {length === 0 && (
                  <Text italic color="dimmed" size="xs" weight="lighter">
                    Noch nichts hinzugefügt.
                  </Text>
                )}
                <Stack spacing={5}>
                  {attributes.map((attribute, idx) => (
                    // eslint-disable-next-line react/jsx-key
                    <CustomAttributeFieldSettings
                      index={idx}
                      attribute={attribute}
                      edit={(patch: Partial<CustomAttributeDTO>) => editAttribute(attribute.id, patch)}
                      remove={() => removeAttribute(attribute)}
                      setAttributes={setAttributes}
                      groupsSelectOptions={groupsSelectOptions}
                    />
                  ))}
                </Stack>
              </div>
            )}
            <Checkbox mt="md" size="xs" label="Automatisch öffnen" checked={opened} onChange={toggleOpened} />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    </Flex>
  );
};

export default AttributesGroup;
