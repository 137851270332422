import { Stack } from '@mantine/core';
import ResourceCalendarsPane from '../calendar/ResourceCalendarsPane';
import { ResourceType } from '../../../Types/ResourceT';
import ResourceAlternativesPane from './ResourceAlternativesPane';
import ResourceAccessoriesPane from './ResourceAccessoriesPane';
import ResourceUsagesPane from './ResourceUsagesPane';
import ResourceProductsPane from "./ResourceProductsPane";

const ResourceAsideBar = () => (
  <Stack>
    <ResourceAccessoriesPane />
    <ResourceAlternativesPane />
    <ResourceProductsPane />
    <ResourceUsagesPane title="Verbaut in folgenden Cases" resourceType={ResourceType.CASE} />
    <ResourceUsagesPane title="Bestandteil folgender Sets" resourceType={ResourceType.KIT} />
    <ResourceCalendarsPane />
  </Stack>
);

export default ResourceAsideBar;
