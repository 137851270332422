/* eslint-disable react/jsx-no-useless-fragment */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Autocomplete, Loader, Text } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { IconX } from '@tabler/icons';
import { EventKind } from '../../Types/EventT';
import { EventS } from '../../Service/EventS';
import { EventContext } from '../../Service/Context/EventContext';
import Dot from '../../Atoms/Dot';

const EventKindSelect = () => {
  const { event, handleChange } = useContext<any>(EventContext);
  const [loading, setLoading] = useState(false);
  const [kinds, setKinds] = useState<EventKind[]>([]);
  const existingKinds = kinds.map((k) => k.name);
  const { id: eventId, kind, kindColor } = event;
  const [kindValue, setKindValue] = useState(kind ?? '');

  useEffect(() => {
    setLoading(true);
    EventS.fetchEventKinds()
      .then(setKinds)
      .finally(() => setLoading(false));
  }, []);

  const getKindId = async () => {
    if (kindValue === '') {
      return null;
    }
    const selectedKind = kinds.find((_kind) => _kind.name === kindValue);
    if (selectedKind) {
      // Existing kind selected
      return selectedKind.id;
    }
    // Creating new event kind
    const newKind = await EventS.createEventKind({ name: kindValue });
    if (newKind) {
      setKinds([...kinds, newKind]);
      return newKind.id;
    }
    return undefined;
  };

  const onBlur = async () => {
    if (kindValue !== kind) {
      setLoading(true);
      const kindId = await getKindId();
      if (kindId !== undefined) {
        const patch = await EventS.editEvent(eventId, { eventKindId: kindId });
        if (patch) {
          handleChange(patch);
          setKindValue(patch.kind ?? '');
        }
      }
      setLoading(false);
    }
  };

  const clear = async () => {
    const patch = await EventS.editEvent(eventId, { eventKindId: null });
    if (patch) {
      handleChange(patch);
      setKindValue('');
    }
  };

  return (
    <Autocomplete
      rightSection={
        loading ? (
          <Loader size="xs" />
        ) : (
          <>{kind && <IconX size={16} onClick={clear} style={{ cursor: 'pointer' }} />}</>
        )
      }
      value={kindValue}
      icon={kindColor && <Dot withoutShadow colour={kindColor} />}
      onBlur={onBlur}
      onChange={(val) => setKindValue(val)}
      data={existingKinds}
      label={<Text size="xs">Art des Events</Text>}
    />
  );
};

export default EventKindSelect;
