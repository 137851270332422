// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Menu, Text } from '@mantine/core';
import { openModal } from '@mantine/modals';
import {
  IconArrowDownCircle,
  IconArrowUpCircle,
  IconBallpen,
  IconDeviceFloppy,
  IconDotsVertical,
  IconPlus,
  IconTrash,
  IconTrashX,
} from '@tabler/icons';
import { FC, useContext } from 'react';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import { Position } from '../../../Service/PositionS';
import { putFavoritePosition } from '../../../Service/restapi/positionService';
import { ToastS } from '../../../Service/ToastS';
import PositionModal from './PositionModal';

export const DocumentPositionActions: FC<{
  index: number;
  position: Position;
  editPosition: () => void;
  deletePosition: (index: number) => void;
  addPosition: (index: number) => void;
  movePosition: (oldIndex: number, newIndex: number) => void;
  positionCount: number;
  fetchFavoritePositions: () => void;
  previewVersion?: boolean;
  isPartialInvoice?: boolean;
}> = ({
  index,
  position,
  deletePosition,
  addPosition,
  movePosition,
  positionCount,
  fetchFavoritePositions,
  editPosition,
  previewVersion,
  isPartialInvoice,
}) => {
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const moveUp = () => movePosition(index, index - 1);
  const moveDown = () => movePosition(index, index + 1);
  const insertAt = (_index: number) => addPosition(_index);

  const saveAsProduct = () => {
    putFavoritePosition(position)
      .then((resp) => {
        if (resp.ok) {
          ToastS.success('save-favourite', 'Position als Favorit gespeichert.');
          fetchFavoritePositions();
        } else {
          ToastS.error('save-favourite', 'Position konnte nicht speichert werden');
        }
      })
      .catch(() => ToastS.error('save-favourite', 'Position konnte nicht speichert werden'));
  };

  return (
    <Menu withArrow shadow="md" width={200} transition="scale-y" position="bottom-end">
      <Menu.Target>
        <div>
          <IconDotsVertical color="gray" size={20} style={{ cursor: 'pointer' }} />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        {previewVersion && (
          <Menu.Item icon={<IconBallpen size={16} />} onClick={editPosition}>
            Bearbeiten
          </Menu.Item>
        )}
        <Menu.Item
          icon={<IconDeviceFloppy size={16} />}
          onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.PRODUCT_USAGE, saveAsProduct)}
        >
          als Produkt speichern
        </Menu.Item>
        {!isPartialInvoice && index !== 0 && (
          <Menu.Item icon={<IconArrowUpCircle size={16} />} onClick={moveUp}>
            nach oben schieben
          </Menu.Item>
        )}
        {!isPartialInvoice && index !== positionCount - 1 && (
          <Menu.Item icon={<IconArrowDownCircle size={16} />} onClick={moveDown}>
            nach unten schieben
          </Menu.Item>
        )}
        {!isPartialInvoice && (
          <>
            <Menu.Item icon={<IconPlus size={16} />} onClick={() => insertAt(index)}>
              Oberhalb hinzufügen
            </Menu.Item>
            <Menu.Item icon={<IconPlus size={16} />} onClick={() => insertAt(index + 1)}>
              Unterhalb hinzufügen
            </Menu.Item>
            {previewVersion && (
              <Menu.Item icon={<IconTrash size={16} />} onClick={() => deletePosition(index)}>
                Löschen
              </Menu.Item>
            )}
          </>
        )}
      </Menu.Dropdown>
    </Menu>
  );
};

export const ProductPositionPreviewActions: FC<{
  index: number;
  position: Position;
  deletePosition: () => void;
  onChange: (idx: number, position: Position) => void;
}> = ({ index, position, deletePosition, onChange }) => {
  const { profile } = useContext(ProfileContext);

  const editPosition = () => {
    openModal({
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Position
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: (
        <PositionModal
          settings
          isFavoritePosition
          index={index}
          position={position}
          favorites={[]}
          changePosition={onChange}
          _document={null}
          profile={profile}
          i18n={undefined}
          customer={undefined}
          event={undefined}
          eventLocation={undefined}
          distance={undefined}
          customAttributeValues={undefined}
          onPlaceholderClicked={undefined}
          executeWithLicenceCheck={undefined}
        />
      ),
    });
  };

  return (
    <Menu withArrow shadow="md" width={200} transition="scale-y" position="bottom-end">
      <Menu.Target>
        <div>
          <IconDotsVertical color="gray" size={20} style={{ cursor: 'pointer' }} />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item icon={<IconBallpen size={16} />} onClick={editPosition}>
          Bearbeiten
        </Menu.Item>
        <Menu.Item icon={<IconTrashX size={16} />} onClick={deletePosition}>
          Löschen
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
