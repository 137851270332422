// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { useContext } from 'react';
import { ContactContext } from '../../Service/Context/ContactContext';

const ContactProviderHeader = () => {
  const { contact } = useContext<any>(ContactContext);
  const { companyName, addressStreetAndNo, zipCode, city } = contact;
  const isDefined = (val: string) => val && val !== '';

  return (
    <Text size={10}>
      {isDefined(companyName) && (
        <Text span weight="bolder">
          {`${companyName} `}
        </Text>
      )}
      {isDefined(addressStreetAndNo) && `${addressStreetAndNo} `}
      {isDefined(zipCode) && `${zipCode} `}
      {isDefined(city) && `${city} `}
    </Text>
  );
};

export default ContactProviderHeader;
