import { Col, Row } from 'reactstrap';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useLingui } from '@lingui/react';
import { Button, Switch } from '@mantine/core';
import { TransButtonDefault } from '../../../Atoms/Buttons';
import { ModalDialog, useModalState } from '../../../Molecules/Dialogs';
import ChangePasswordDialog from '../../../Molecules/Settings/ChangePasswordDialog';
import { TransSelectField } from '../../../Atoms/InputFields';
import ChangeEmailDialog from '../../../Molecules/Settings/ChangeEmailDialog';
import { AuthS } from '../../../Service/restapi/authService';
import { ToastS } from '../../../Service/ToastS';
import PanePaper from '../../../Molecules/PanePaper';
import { AuthContext } from '../../../Service/Context/AuthContext';
import { ApplicationUserContext } from '../../../Service/Context/ApplicationUserContext';
import { RoutingS } from '../../../Service/RoutingS';
import { ConstantS } from '../../../Service/ConstantS';
import { MixpanelS } from '../../../Service/MixpanelS';

const AccountSettings = () => {
  const { i18n } = useLingui();
  const [modalOpen, setModalOpen] = useState(false);
  const { show, toggle } = useModalState();
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const { logout, jwtClaims } = useContext(AuthContext);
  const { applicationUser, savePatch } = useContext(ApplicationUserContext);
  const { redGreenWeakness } = applicationUser;

  useEffect(() => {
    RoutingS.changeTitle('Zugangsdaten');
  }, []);

  function deleteAppUser() {
    AuthS.deleteAccount()
      .then((response) => {
        if (response.status === 200) {
          MixpanelS.track(ConstantS.TrackingEvents.AccountDeleted);
          logout();
        } else {
          ToastS.error('statusErrorTitle', i18n._(`errors.${response.status}.boxed.title`));
          ToastS.error('statusErrorDescription', i18n._(`errors.${response.status}.boxed.description`));
        }
      })
      .catch(() => {
        ToastS.error('statusError503', i18n._('errors.503.boxed.title'));
      });
  }

  function toggleEmailDialog() {
    setShowEmailDialog(!showEmailDialog);
  }

  const styles = {
    rowContainer: {
      display: 'flex',
      marginBottom: 20,
    },
    columnContainer: {
      flex: 1,
    },
    subject: {},
    deleteButton: {
      float: 'right',
    },
  };

  const languageOptions = [{ label: i18n._('language.german'), value: 'de' }];

  return (
    <div style={{ maxWidth: 800 }}>
      <PanePaper title="Zugangsdaten">
        <div>
          <div style={styles.rowContainer}>
            <div style={styles.columnContainer}>
              <span style={styles.subject}>{i18n._('applicationuser.email')}</span>
            </div>
            <div style={styles.columnContainer}>
              {jwtClaims.email}{' '}
              <TransButtonDefault
                onClickHandler={() => {
                  setShowEmailDialog(true);
                }}
                text="settings.account.change-email"
              />
            </div>
          </div>

          <div style={styles.rowContainer}>
            <div style={styles.columnContainer}>
              <span>{i18n._('applicationuser.password')}</span>
            </div>
            <div style={styles.columnContainer}>
              <TransButtonDefault
                id="changePassword"
                onClickHandler={() => toggle()}
                text="settings.account.change-password"
              />
            </div>
          </div>

          <div style={styles.rowContainer}>
            <div style={styles.columnContainer}>
              <span>{i18n._('applicationuser.language')}</span>
            </div>
            <div style={styles.columnContainer}>
              <TransSelectField options={languageOptions} value={languageOptions[0]} onChangeHandler={() => {}} />
            </div>
          </div>

          <div style={styles.rowContainer}>
            <div style={styles.columnContainer}>
              <span>{i18n._('applicationuser.red-green-weakness')}</span>
            </div>
            <div style={styles.columnContainer}>
              <Switch
                size="md"
                checked={redGreenWeakness}
                onLabel="AN"
                offLabel="AUS"
                onChange={() => savePatch({ redGreenWeakness: !redGreenWeakness })}
              />
            </div>
          </div>

          <div style={styles.rowContainer}>
            <div style={styles.columnContainer}>
              <span>{i18n._('applicationuser.delete')}</span>
            </div>
            <div style={styles.columnContainer}>
              <Button
                color="red"
                variant="subtle"
                sx={{ backgroundColor: '#FFEFEF', '&:hover': { color: 'white' } }}
                onClick={() => setModalOpen(true)}
              >
                {i18n._('settings.account.delete-account')}
              </Button>
            </div>
          </div>
        </div>
      </PanePaper>
      <Row>
        <Col sm={12}>
          <ModalDialog
            header={<Trans id="common.delete.appuser.confirm.head" />}
            body={<Trans id="common.delete.appuser.confirm" />}
            footer={
              <div>
                <TransButtonDefault
                  color="danger"
                  id="deleteAppUser"
                  onClickHandler={() => {
                    deleteAppUser();
                    setModalOpen(!modalOpen);
                  }}
                  text="actions.delete"
                />{' '}
                <TransButtonDefault onClickHandler={() => setModalOpen(!modalOpen)} text="actions.cancel" />
              </div>
            }
            show={modalOpen}
            toggle={() => setModalOpen(!modalOpen)}
          />

          <ChangePasswordDialog open={show} />
          <ChangeEmailDialog open={showEmailDialog} toggle={toggleEmailDialog} />
        </Col>
      </Row>
    </div>
  );
};

export default AccountSettings;
