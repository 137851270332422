import React, { useState } from 'react';
import { Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/react';
import { InputHelp } from './InputHelp';

export const EditableTimeInput = ({ finishCallback, value: initialValue, placeholder, patchField, ...rest }) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const styles = {
    error: {
      borderColor: 'red',
    },
    input: {
      marginBottom: 0,
    },
    label: {
      color: '#8ca3b5',
      fontWeight: 800,
    },
    inputContainer: {
      marginBottom: 9,
    },
  };

  function formatTime() {
    let tempValue = value || '';
    if (tempValue.includes('Uhr')) {
      tempValue = value.replace('Uhr', '');
    }
    if (tempValue.length > 0) {
      if (tempValue.length === 1) {
        if (!isNaN(tempValue)) {
          tempValue = `0${tempValue}:00`;
        } else {
          finishCallback({ [patchField]: tempValue })
            .catch((error) => setError(error.message))
            .then(() => setLoading(false));
        }
      } else if (tempValue.length === 2) {
        if (!isNaN(tempValue)) {
          if (tempValue <= 24) {
            tempValue = `${value}:00`;
          } else {
            finishCallback({ [patchField]: tempValue })
              .catch((error) => setError(error.message))
              .then(() => setLoading(false));
            console.error('keine gültige Zeitangabe');
          }
        } else if (tempValue.indexOf(':') === 1) {
          // e.g.: 4:
          tempValue = `0${tempValue}00`;
        } else if (value.indexOf(',') === 1) {
          // e.g.: 4,
          tempValue = `0${value.substring(0, 1)}:00`;
        } else {
          finishCallback({ [patchField]: tempValue })
            .catch((error) => setError(error.message))
            .then(() => setLoading(false));
        }
      } else if (tempValue.length <= 5) {
        if (tempValue.includes(':')) {
          if (tempValue.indexOf(':') === 1) {
            if (tempValue.length === 3) {
              // e.g.: 4:0
              tempValue = `0${tempValue}0`;
            }
            if (tempValue.length === 4) {
              // e.g.: 6:00
              tempValue = `0${tempValue}`;
            }
          } else if (tempValue.indexOf(':') === 2) {
            if (tempValue.length === 3) {
              // e.g.: 14:
              tempValue += '00';
            }
            if (tempValue.length === 4) {
              // e.g.: 10:0
              tempValue += '0';
            }
          } else if (tempValue.indexOf(':') === 1) {
            if (tempValue.length === 3) {
              tempValue = `0${tempValue}`;
            } else {
              // hinter dem Doppelpunkt stehen mehr als 2 Zeichen => nicht erlaubt
              finishCallback({ [patchField]: tempValue })
                .catch((error) => setError(error.message))
                .then(() => setLoading(false));
              console.log('keine gültige Zeitangabe');
            }
          } else {
          }
        } else if (value.indexOf(',') !== -1) {
          if (value.indexOf(',') === 1) {
            // e.g.: 4,
            tempValue = `0${value.substring(0, 1)}:00`;
          } else if (value.indexOf(',') === 2) {
            // e.g.: 04, oder 99,
            if (!isNaN(value.substring(0, 2)) && value.substring(0, 2) < 24) {
              if (value.length > 3) {
                if (value.length > 4) {
                  if (value.substring(3, 5) < 60) {
                    tempValue = `${value.substring(0, 2)}:${value.substring(3, 5)}`;
                  }
                } else if (value.substring(3, 4) < 6) {
                  tempValue = `${value.substring(0, 2)}:${value.substring(3, 4)}0`;
                }
              } else {
                tempValue = `${value.substring(0, 2)}:00`;
              }
            }
          }
        } else if (!isNaN(tempValue)) {
          if (tempValue.length === 3) {
            tempValue = `0${tempValue.substring(0, 1)}:${tempValue.substring(1, 3)}`;
          } else if (tempValue.length === 4) {
            tempValue = `${tempValue.substring(0, 2)}:${tempValue.substring(2, 4)}`;
          } else {
            // e.g.: 04:000
            finishCallback({ [patchField]: tempValue })
              .catch((error) => setError(error.message))
              .then(() => setLoading(false));
            console.error('keine gültige Zeitangabe');
          }
        } else {
          finishCallback({ [patchField]: tempValue })
            .catch((error) => setError(error.message))
            .then(() => setLoading(false));
          console.error('keine gültige Zeitangabe');
        }
      } else {
        // value.length > 5
      }

      if (tempValue === '24:00') {
        tempValue = '00:00';
      }

      const found = tempValue.match(/([01]?[0-9]|2[0-3]):[0-5][0-9]/g);
      if (found) {
        finishCallback({ [patchField]: found[0] })
          .catch((error) => setError(error.message))
          .then(() => setLoading(false));
      } else {
        finishCallback({ [patchField]: tempValue })
          .catch((error) => setError(error.message))
          .then(() => setLoading(false));
      }
    } else {
      finishCallback({ [patchField]: '' })
        .catch((error) => setError(error.message))
        .then(() => setLoading(false));
    }
  }

  return (
    <div style={styles.inputContainer}>
      <Label sm={12} style={styles.label}>
        <Trans id={placeholder} />
      </Label>
      <Input
        onBlur={() => {
          setLoading(true);
          formatTime();
        }}
        className={loading ? 'loading' : ''}
        style={error ? { ...styles.error, ...styles.input } : styles.input}
        onChange={(e) => setValue(e.target.value)}
        onKeyUp={(e) => {
          switch (e.key) {
            case 'Enter':
              formatTime();
              break;
            case 'Escape':
              finishCallback({ [patchField]: initialValue })
                .catch((error) => setError(error.message))
                .then(() => setLoading(false));
          }
        }}
        value={value != null ? value : ''}
        {...rest}
      />
      {error ? (
        <>
          <InputHelp errorDescription="Gib z.B. 15:00 ein." />
          <InputHelp errorDescription={error} />
        </>
      ) : null}
    </div>
  );
};

EditableTimeInput.propTypes = {
  value: PropTypes.string,
  finishCallback: PropTypes.func,
  autoFocus: PropTypes.bool,
};

EditableTimeInput.defaultProps = {
  autoFocus: true,
};
