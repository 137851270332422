import { FC } from 'react';
import { Text } from '@mantine/core';
import { i18n } from '@lingui/core';
import { BookitupNotification } from '../../../../Types/LogT';

const EntityShared: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { entityType } = notification;
  return (
    <Text>
      <Text span weight="bolder">
        {' '}
        {i18n._(`resource.entityType.${entityType}`)}
      </Text>{' '}
      über Online-Portal teilen.
    </Text>
  );
};

export default EntityShared;
