import { Stepper } from '@mantine/core';
import React, { FC, ReactNode, useContext, useMemo, useState } from 'react';
import { Icon } from '../Icons';
import { ModalFlowStepItem, ModalFlowStepItemNodePair } from './MenuItem';

interface CustomStepperContextInterface {
  steps: ModalFlowStepItem[];
  listItems: ReactNode[];
  currentStep: number;
  nextStep: () => void;
  prevStep: () => void;
  setAllItemsContent: (items: ModalFlowStepItemNodePair[]) => void;
  onStepClick: (step: number) => void;
}

export const CustomStepperContext = React.createContext<CustomStepperContextInterface>(
  {} as CustomStepperContextInterface,
);

export const CustomStepperContextProvider: FC<{ children: ReactNode[] | ReactNode; initialStep?: number }> = ({
  children,
  initialStep,
}) => {
  const [active, setActive] = useState(initialStep ?? 0);

  const [allItems, setAllItems] = useState<ModalFlowStepItemNodePair[]>([]);

  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const onStepClick = (step: number) => {
    setActive(step);
  };

  const value = useMemo(
    () => ({
      currentStep: active,
      steps: allItems.map((it) => it.step),
      listItems: allItems.map((it) => it.content),
      setAllItemsContent: setAllItems,
      nextStep: () => {
        setActive((current) => {
          const isValid = allItems[current].step.validator?.() ?? true;
          return current < allItems.length - 1 && isValid ? current + 1 : current;
        });
      },
      prevStep,
      onStepClick,
    }),
    [active, allItems],
  );

  return <CustomStepperContext.Provider value={value}>{children}</CustomStepperContext.Provider>;
};

const CustomStepper: FC<{ clickable?: boolean }> = ({ clickable }) => {
  const { currentStep, onStepClick, steps, listItems } = useContext(CustomStepperContext);

  return (
    <Stepper
      active={currentStep}
      onStepClick={clickable ? onStepClick : undefined}
      breakpoint="xs"
      size="xs"
      orientation="horizontal"
    >
      {steps.map((step, idx) => (
        <Stepper.Step
          key={step.label}
          label={step.label}
          description={step.description}
          icon={step.icon !== undefined ? <Icon value={step.icon} /> : undefined}
        >
          {listItems[idx]}
        </Stepper.Step>
      ))}
    </Stepper>
  );
};

export default CustomStepper;
