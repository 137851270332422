import { useContext, useEffect, useState } from 'react';
import { AppShell, Navbar, ScrollArea } from '@mantine/core';
import { WindowContext } from '../../Service/Context/WindowContext';
import CreateEventIcon from '../../Organismns/Eventlist/CreateEventIcon';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import PageHeader from '../Navigation/PageHeader';
import { RoutingS } from '../../Service/RoutingS';
import EventListNavBar from './navbar/EventListNavBar';
import WelcomeHeader from './WelcomeHeader';
import EventsPageContent from './EventsPageContent';
import {useSearchParams} from "react-router-dom";
import {MixpanelS} from "../../Service/MixpanelS";
import {ConstantS} from "../../Service/ConstantS";

const EventsPage = () => {
  const { sm } = useContext(WindowContext);
  const [navBarOpen, setNavBarOpen] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    RoutingS.changeTitle('Events');
    if (searchParams.get('__hstc')) {
      MixpanelS.track(ConstantS.TrackingEvents.OnboardingConfirmed);
    }
  }, []);

  return (
    <AppShell
      header={
        <PageHeader
          title="Events"
          navBarOpen={navBarOpen}
          setNavBarOpen={setNavBarOpen}
          rightSection={<CreateEventIcon />}
        />
      }
      navbar={
        <div>
          {sm ? (
            <Navbar hiddenBreakpoint="sm" hidden={!navBarOpen} width={{ sm: 220, md: 250 }}>
              <ScrollArea scrollbarSize={10}>
                <EventListNavBar />
              </ScrollArea>
            </Navbar>
          ) : (
            <DoubleNavbar>
              <EventListNavBar />
            </DoubleNavbar>
          )}
        </div>
      }
    >
      <WelcomeHeader />
      <EventsPageContent />
    </AppShell>
  );
};

export default EventsPage;
