import { Skeleton } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import { EditableMailNotificationConfigProperty } from '../../../Atoms/EditableProperties/EditableMailNotificationConfigProperty';
import { EditableCheckbox } from '../../../Atoms/inputs/EditableCheckbox';
import { LoadingAnimation } from '../../../Service/ResourceAdapter';
import { fetchMailNotificationConfig } from '../../../Service/restapi/scheduledMailService';

export default function () {
  const [mailNotificationConfig, setMailNotificationConfig] = useState(undefined);

  useEffect(() => {
    fetchMailNotificationConfig()
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((newMailNotificationConfig) => {
            setMailNotificationConfig(newMailNotificationConfig);
          });
        } else {
          console.error('could not fetch mail notification config');
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not fetch mail notification config');
      });
  }, []);

  if (!mailNotificationConfig) {
    return <Skeleton height={50} width="100%" mt={15}/>;
  }

  const styles = {
    heading: {
      fontSize: 16,
      fontWeight: 600,
      marginBottom: 5,
    },
  };

  return (
    <div style={{ marginTop: 20 }}>
      <div style={styles.heading}>E-Mail-Erinnerung</div>
      Möchtest Du per E-Mail über anstehende Aufgaben erinnert werden?
      <EditableMailNotificationConfigProperty
        patchField="taskReminder"
        config={mailNotificationConfig}
        handleChange={setMailNotificationConfig}
        placeholder="reminder.activeMailNotifications"
        editFormatter={EditableCheckbox}
        editOnly
      />
    </div>
  );
}
