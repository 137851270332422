import { Episode, WistiaChannel, WistiaMedia } from '../Types/WistiaT';

const BASE_URL = 'https://api.wistia.com/v1';
const API_TOKEN = process.env.REACT_APP_WISTIA_TOKEN;

const CHANNELS: WistiaChannel[] = [
  {
    img: '/img/academy/Modul 1.png',
    path: 'automatic-responses',
    name: 'Modul 1 - Anfragen automatisch beantworten',
    description: 'Description here',
    episodes: [
      {
        title: 'Intro',
        id: '75kk47lzfg',
      },
      {
        title: 'Kontaktformular erstellen und verbinden',
        id: 'jjj5z1isb0',
      },
      {
        title: 'Google reCAPTCHA in Kontaktformularen',
        id: 's6ar74irbe',
      },
      {
        title: 'Externer Kalender verbinden',
        id: 'faiabsidch',
      },
      {
        title: 'HTML Signatur',
        id: '00qto1iesx',
      },
      {
        title: 'Workflow Tutorial - Anfrage beantworten',
        id: 's7srcy9mc3',
      },
    ],
  },
  {
    img: '/img/academy/Modul 2.png',
    path: 'perfect-overview',
    name: 'Modul 2 - Perfekter Überblick',
    description: 'Description here',
    episodes: [
      {
        title: 'Intro',
        id: 'hf6n4t281g',
      },
      {
        title: 'Event manuell anlegen',
        id: '8j3iejxh9a',
      },
      {
        title: 'Event Details hinterlegen',
        id: '77tp5g4owb',
      },
      {
        title: 'Eigene Felder nutzen',
        id: '3ece8264ch',
      },
      {
        title: 'Kalender Abo in iCloud/Outlook etc. einfügen',
        id: 'mlqjdnri0n',
      },
      {
        title: 'Google Kalender verbinden',
        id: '241v3ogxbu',
      },
      {
        title: 'Individuelle Buchungsstatus',
        id: 'o7oxhbd7q7',
      },
      {
        title: 'Kontakte verwalten',
        id: '9f2rea1oqr',
      },
      {
        title: 'Dokumenten Übersicht',
        id: 'bse8e56alp',
      },
      {
        title: 'Aufgaben verwalten',
        id: 'hhy23bsf9o',
      },
    ],
  },
  {
    img: '/img/academy/Modul 3.png',
    path: 'offer-service',
    name: 'Modul 3 - Leistung anbieten',
    description: 'Description here',
    episodes: [
      {
        title: 'Intro',
        id: 'ix67ywf3wn',
      },
      {
        title: 'Angebotsvorlagen erstellen',
        id: 's0ov3hcol9',
      },
      {
        title: 'Angebot erstellen',
        id: 'tfl5yj1fg2',
      },
      {
        title: 'Verträge erstellen und Online freigeben',
        id: 'ye6osph0pm',
      },
      {
        title: 'Dokumente im Online-Kundenportal freigeben',
        id: 'h84giqebs0',
      },
      {
        title: 'Abschlagsrechnung und Schlussrechnung erstellen',
        id: 'ogngkplr6l',
      },
      {
        title: 'Zahlungen zu einer Rechnung erfassen',
        id: 'jsu4k2hu4u',
      },
      {
        title: 'Workflow: Angebot und Vertrag automatisch erstellen',
        id: 'b8224a6njp',
      },
      {
        title: 'lexoffice verbinden',
        id: '9lmcl6yml7',
      },
    ],
  },
  {
    img: '/img/academy/Modul 4.png',
    path: 'event-details',
    name: 'Modul 4 - Event-Details einholen',
    dropdownLabel: 'Modul 4 - Event-Details einholen',
    description: 'Description here',
    episodes: [
      {
        title: 'Intro',
        id: 'fmecv7mw7j',
      },
      {
        title: 'Fragebogen erstellen und Freigeben',
        id: 'zgkqf33ydb',
      },
      {
        title: 'Workflow: Fragebogen verschicken',
        id: 'uh0f08r5do',
      },
      {
        title: 'Infosheet für Dich oder Deine Mitarbeiter',
        id: 'r5sukmelyz',
      },
      {
        title: 'Workflow: Kundenbewertung und Feedback einholen',
        id: 'qslqzcwk2u',
      },
    ],
  },
  {
    img: '/img/academy/Modul 5.png',
    path: 'agency',
    name: 'Modul 5 - Agentur & Vermittlungen',
    description: 'Description here',
    episodes: [
      {
        title: 'Agentur und Vermittlungen',
        id: 'qki0vq1gua',
      },
    ],
  },
  {
    img: '/img/academy/Modul 6.png',
    path: 'rental',
    name: 'Modul 6 - Vermietung',
    description: 'Description here',
    episodes: [
      {
        title: 'Intro',
        id: 'cqaev92yr4',
      },
      {
        title: 'Ressourcenmodul',
        id: '5ych0299qm',
      },
      {
        title: 'Ressourcenmodul Ressourcen richtig zählen',
        id: 'aywaaa8d6y',
      },
      {
        title: 'So bucht man das Ressourcenmodul',
        id: 'f1sgfsb74y',
      },
    ],
  },
];

const opt = {
  method: 'GET',
  headers: {
    accept: 'application/json',
    authorization: `Bearer ${API_TOKEN}`,
  },
};

const fetchChannels = async () => {
  const res = await fetch(`${BASE_URL}/channels`, opt);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchChannelDetails = async (channelId: string) => {
  const res = await fetch(`${BASE_URL}/channels/${channelId}`, opt);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const fetchChannelEpisodes = async (channelId: string) => {
  const res = await fetch(`${BASE_URL}/channel_episodes?channel_id=${channelId}`, opt);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchMediaDetails = async (mediaId: string): Promise<WistiaMedia | null> => {
  const res = await fetch(`${BASE_URL}/medias/${mediaId}.json`, opt);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const getNavigationProps = (currentChannel: WistiaChannel, currentEpisode: Episode) => {
  const currentChannelIdx = CHANNELS.indexOf(currentChannel);
  const nextChannel = currentChannelIdx === CHANNELS.length - 1 ? null : CHANNELS[currentChannelIdx + 1];
  const previousChannel = currentChannelIdx === 0 ? null : CHANNELS[currentChannelIdx - 1];
  const { episodes } = currentChannel;
  const currentEpisodeIdx = episodes.indexOf(currentEpisode);
  const nextEpisode = episodes.length - 1 === currentEpisodeIdx ? null : episodes[currentEpisodeIdx + 1];
  const previousEpisode = episodes.length === 0 ? null : episodes[currentEpisodeIdx - 1];

  return {
    nextEpisode,
    previousEpisode,
    nextChannel,
    previousChannel,
  };
};

// eslint-disable-next-line import/prefer-default-export
export const WistiaS = {
  CHANNELS,
  fetchChannels,
  fetchChannelDetails,
  fetchChannelEpisodes,
  fetchMediaDetails,
  getNavigationProps,
};
