import React, {useEffect, useState} from "react";
import {InputHelp} from "./InputHelp";
import PropTypes from "prop-types";
import {withI18n} from "@lingui/react";
import AutocompleteWithPlaceholderCollection from "../AutocompleteWithPlaceholderCollection";

export const EditableAutocompleteWithPlaceholderCollection = withI18n()(({finishCallback, value: initialValue, placeholder, patchField, insertPlaceholderUrl, index, ...rest}) => {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    function finish(patch) {
        setLoading(true);
        setValue(patch[patchField]);
        finishCallback(patch).catch((error) => setError(error.message)).then(() => setLoading(false));
    }

    let styles = {
        error: {
            borderColor: "red",
        },
        input: {
            marginBottom: 0,
            height: "100%",
        },
        label: {
            color: "#8ca3b5",
            fontWeight: 800,
        },
        emailAddress: {
            height: "100%",
            minHeight: 34,
        }
    };

    return (
        <>
            <AutocompleteWithPlaceholderCollection index={index} autoFocus={true} className={loading ? "loading" : ""}
                          insertPlaceholderUrl={insertPlaceholderUrl} style={styles.emailAddress} saveValue={finish}
                          patchField={patchField} {...rest} value={value}
                          onChange={(patch) => setValue(patch[patchField])} placeholder={placeholder}/>
            {error ? <InputHelp errorDescription={error}/> : null}

        </>
    )

});

EditableAutocompleteWithPlaceholderCollection.propTypes = {
    value: PropTypes.string,
    finishCallback: PropTypes.func,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string,
};

EditableAutocompleteWithPlaceholderCollection.defaultProps = {
    autoFocus: true,
};