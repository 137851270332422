import React from 'react';
import PropTypes from 'prop-types';

/**
 * HtmlFormatter is a formatter which displays html content, but
 * returns a dash for empty or null values.
 */
export const HtmlFormatter = ({ value, dataTour, name, disabled, ...rest }) => {
  const { big } = rest;

  const styles = {
    input: {
      height: '100%',
      minHeight: big ? 250 : 34,
      backgroundColor: disabled ? '#f1f1f1' : 'white',
      cursor: disabled ? 'not-allowed' : 'initial',
      ...rest.style,
    },
  };

  return (
    <div
      name={name}
      data-tour={dataTour}
      className="form-control"
      style={styles.input}
      dangerouslySetInnerHTML={{ __html: value }}
    />
  );
};

HtmlFormatter.propTypes = {
  value: PropTypes.string,
};
