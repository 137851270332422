import React from 'react';
import TooltipButton from '../../Atoms/buttons/TooltipButton';
import { DeleteAction } from '../../Molecules/DynamicFieldWrapper';
import { QuestionnaireS } from '../../Service/QuestionnaireS';

export default function ({
  index,
  moveQuestion,
  setQuestionInDelete,
  questionsLength,
  bundleId,
  questionId,
  setQuestions,
}) {
  const styles = {
    menuComponent: {
      display: 'flex',
      width: 30,
      backgroundColor: 'rgb(251, 251, 251)',
      border: '1px solid rgba(0, 0, 0, .125)',
      borderLeft: 'none',
      height: 87,
      marginLeft: -1,
      borderRadius: '0px 4px 4px 0px',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    trashComponent: {
      textAlign: 'center',
    },
  };

  return (
    <div style={styles.menuComponent}>
      <div style={styles.trashComponent}>
        {index !== 0 && (
          <TooltipButton
            isLoading={false}
            id={`up${index}`}
            text="actions.up"
            onClick={() => {
              moveQuestion(bundleId, questionId, index - 1);
            }}
            icon="UP"
          />
        )}
      </div>
      <div style={styles.trashComponent}>
        <TooltipButton
          isLoading={false}
          id={`duplicate${index}`}
          text="actions.duplicate"
          onClick={() => {
            QuestionnaireS.duplicateQuestion(bundleId, questionId, index).then((questions) => {
              setQuestions(questions);
            });
          }}
          icon="DUPLICATE"
        />
      </div>
      <div style={styles.trashComponent}>
        <DeleteAction
          deletePosition={() => {
            setQuestionInDelete(questionId);
          }}
          index={index}
          count={questionsLength}
        />
      </div>
      {/* <div style={styles.trashComponent}>
                                <TooltipButton isLoading={false} id={"duplicate" + index} text="actions.duplicate"
                                               onClick={() => {
                                                   alert("Funktion noch nicht verfügbar.")
                                               }} icon="DUPLICATE"/>
                            </div> */}
      <div style={styles.trashComponent}>
        {index !== questionsLength - 1 && (
          <TooltipButton
            isLoading={false}
            id={`down${index}`}
            text="actions.down"
            onClick={() => {
              moveQuestion(bundleId, questionId, index + 1);
            }}
            icon="DOWN"
          />
        )}
      </div>
    </div>
  );
}
