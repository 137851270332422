import { createContext, FC, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Story, StoryBlokS } from '../StoryBlokS';

interface AnnoucementContextInterface {
  loading: boolean;
  stories: Story[];
  markAsSeen: (uuid: string) => void;
}

export const AnnoucementsContext = createContext<AnnoucementContextInterface>({} as AnnoucementContextInterface);

export const AnnoucementsContextProvider: FC<{ showSeenStories?: boolean }> = ({ showSeenStories }) => {
  const [stories, setStories] = useState<Story[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const filterSeenStory = (story: Story) => !StoryBlokS.getSeenNotifications().includes(story.uuid);

  // eslint-disable-next-line no-underscore-dangle
  const _setStories = (allStories: Story[]) => {
    if (showSeenStories) {
      setStories(allStories);
    } else {
      setStories(allStories.filter(filterSeenStory));
    }
  };

  const markAsSeen = (uuid: string) => {
    StoryBlokS.markAsSeen(uuid);
    if (!showSeenStories) {
      setStories(stories.filter(filterSeenStory));
    }
  };

  useEffect(() => {
    StoryBlokS.fetchStories(StoryBlokS.SPACES.NOTIFICATIONS)
      ?.then(_setStories)
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = useMemo(
    () => ({
      loading,
      stories,
      markAsSeen,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, stories],
  );

  return (
    <AnnoucementsContext.Provider value={value}>
      <Outlet />
    </AnnoucementsContext.Provider>
  );
};
