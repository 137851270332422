import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_CANDIS_SERVICE_URL;

export const fetchCandisConfig = () => ajaxActions.get(`${BASE_URL}/candisConfigs`).then((resp) => resp);

export const fetchCandisInvoices = () => ajaxActions.get(`${BASE_URL}/candisInvoices`).then((resp) => resp);

export const deleteCandisInvoice = (candisInvoiceId) =>
  ajaxActions.del(`${BASE_URL}/candisInvoices/${candisInvoiceId}`).then((resp) => resp);
