import React, { useContext, useEffect, useState } from 'react';
import { fetchStandardLayoutContent } from '../../../../Service/restapi/pdfService';
import { EditableStandardLayoutContentProperty } from '../../../../Atoms/EditableProperties/EditableStandardLayoutContentProperty';
import { LoadingAnimation } from '../../../../Service/ResourceAdapter';
import { SimpleEditableText } from '../../../../Atoms/inputs/SimpleEditableText';
import { StringFormatter } from '../../../../Atoms/Formatter/StringFormatter';
import { Pane } from '../../../../Molecules/Pane';
import PanePaper from '../../../../Molecules/PanePaper';

export default function () {
  const [standardLayoutContent, setStandardLayoutContent] = useState(undefined);

  useEffect(() => {
    fetchStandardLayoutContent().then((resp) => {
      if (resp.ok) {
        resp.json().then(setStandardLayoutContent);
      }
    });
  }, []);

  if (!standardLayoutContent) {
    return <LoadingAnimation />;
  }

  return (
    <PanePaper title="Vertragspartner Referenznamen">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="description" style={{ marginBottom: 20 }}>
          Diese Referenznamen werden nur angezeigt, wenn im Vertrag der Kopfbereich auf <i>Vertragspartner</i>{' '}
          eingestellt ist.
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, maxWidth: 400, display: 'flex', flexDirection: 'column' }}>
            <div className="description" style={{ marginBottom: 10 }}>
              Unter den Kontaktdaten Deines Kunden
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginTop: 8, marginRight: 5 }}>Nachfolgend</div>
              <EditableStandardLayoutContentProperty
                entity={standardLayoutContent}
                patchField="contractPartyEmployerDescription"
                changeHandler={(p) => {
                  setStandardLayoutContent(p);
                }}
                placeholder=""
                editFormatter={SimpleEditableText}
                labelFormatter={StringFormatter}
              />
              <div style={{ marginTop: 8, marginLeft: 5 }}>genannt.</div>
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: 10, maxWidth: 400, display: 'flex', flexDirection: 'column' }}>
            <div className="description" style={{ marginBottom: 10 }}>
              Unter den Kontaktdaten von Dir
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ marginTop: 8, marginRight: 5 }}>Nachfolgend</div>
              <EditableStandardLayoutContentProperty
                entity={standardLayoutContent}
                patchField="contractPartyEmployeeDescription"
                changeHandler={(p) => {
                  setStandardLayoutContent(p);
                }}
                placeholder=""
                editFormatter={SimpleEditableText}
                labelFormatter={StringFormatter}
              />
              <div style={{ marginTop: 8, marginLeft: 5 }}>genannt.</div>
            </div>
          </div>
        </div>
      </div>
    </PanePaper>
  );
}
