// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Anchor, Box, Breadcrumbs, Checkbox, Divider, Group, Select, Space, Text } from '@mantine/core';
import { i18n } from '@lingui/core';
import { IconLayoutSidebarLeftCollapse } from '@tabler/icons';
import { FC, useContext } from 'react';
import styled from 'styled-components';
import { DateRangePickerWithSelect } from '../../Atoms/inputs/DateRangePickerWithSelect';
import { AgendaGroupMultiSelectItem } from '../../Atoms/MultiSelect/AgendaGroupMultiSelect';
import AddTaskIcon from '../../Organismns/Tasks/AddTaskIcon';
import { TaskListContext } from '../../Service/Context/TaskListContext';
import { WindowContext } from '../../Service/Context/WindowContext';
import { useDateRangeItem } from '../../Service/hooks/useDateRangeItem';
import { getAgendaGroupDisplayEntityType } from '../../Types/Enums/AgendaGroupEntityType';
import { addDays, lastDateBeforeNextMidnight, lastMidnight } from '../../Utils/dateUtils';
import BookingStateItem from '../EventList/BookingStateItem';

const AgendaGroupMultiSelectPlaceholder = styled(Select)`
  input::placeholder {
    color: black;
  }
`;

const TasksNavFilter: FC<{ closeNavBar: () => void }> = ({ closeNavBar }) => {
  const { taskListFilter, taskListContent, changeFilter } = useContext(TaskListContext);
  const { selectedTaskGroups, selectedDateRange, includeCompleted } = taskListFilter;
  const { taskGroups } = taskListContent;
  const dateRangePickerMinimum = new Date(2020, 1, 1);
  const dateRangePickerMaximum = new Date(new Date().getFullYear() + 10, 1, 1);
  const startOfDay = lastMidnight();
  const { sm } = useContext(WindowContext);

  const onDateChange = (values: [Date | null, Date | null]) => {
    if (values[0] && values[1]) {
      changeFilter({ selectedDateRange: [values[0], values[1]] });
    }
  };

  const displayItems = taskGroups.map((g) => ({
    value: g.id,
    label: `${g.name} (${getAgendaGroupDisplayEntityType(g.entityType)})`,
    checked: selectedTaskGroups.indexOf(g) !== -1,
  }));

  const onIncludeCompletedChange = (checked: boolean) => {
    changeFilter({ includeCompleted: checked });
  };

  const getGroupInputText = () =>
    // eslint-disable-next-line no-nested-ternary
    selectedTaskGroups.length === taskGroups.length
      ? 'Alle Gruppen'
      : selectedTaskGroups.length > 0
      ? `(${selectedTaskGroups.length}) Gruppen`
      : 'Keine Gruppen';

  const onChange = (value: string) => {
    const item = taskGroups.find((g) => g.id === value);

    if (!item) {
      return;
    }

    const mutableGroups = [...selectedTaskGroups];

    const idx = mutableGroups.indexOf(item);

    if (idx !== -1) {
      mutableGroups.splice(idx, 1);
    } else {
      mutableGroups.push(item);
    }

    changeFilter({ selectedTaskGroups: mutableGroups });
  };

  return (
    <>
      {!sm && (
        <Group sx={{ position: 'relative' }} mt={5} mb={5}>
          <Breadcrumbs>
            <Anchor size="xl" variant="text" p="md" weight="bolder">
              {i18n._('common.tasks')}
            </Anchor>
          </Breadcrumbs>
          <Box sx={{ position: 'absolute', right: 15, top: 15 }}>
            <AddTaskIcon />
          </Box>
        </Group>
      )}
      <Space h="md" />
      <Text pl={10}>Datumsbereich</Text>
      <Box p="xs">
        <DateRangePickerWithSelect
          zIndex={100}
          shadow="xl"
          labelSeparator="bis"
          placeholder="Pick dates range"
          value={[selectedDateRange[0], selectedDateRange[1]]}
          onChange={onDateChange}
          clearable={false}
          variant="filled"
          showRanges
        />
      </Box>
      <BookingStateItem
        label="Maximum"
        onClick={() => changeFilter({ selectedDateRange: [dateRangePickerMinimum, dateRangePickerMaximum] })}
        active={useDateRangeItem(dateRangePickerMinimum, dateRangePickerMaximum, selectedDateRange)}
      />
      <BookingStateItem
        label="Heute"
        onClick={() => changeFilter({ selectedDateRange: [dateRangePickerMinimum, lastDateBeforeNextMidnight()] })}
        active={useDateRangeItem(dateRangePickerMinimum, lastDateBeforeNextMidnight(), selectedDateRange)}
      />
      <BookingStateItem
        label="Morgen"
        onClick={() => changeFilter({ selectedDateRange: [dateRangePickerMinimum, addDays(1, startOfDay)] })}
        active={useDateRangeItem(dateRangePickerMinimum, addDays(1, startOfDay), selectedDateRange)}
      />
      <BookingStateItem
        label="In einer Woche"
        onClick={() => changeFilter({ selectedDateRange: [dateRangePickerMinimum, addDays(7, startOfDay)] })}
        active={useDateRangeItem(dateRangePickerMinimum, addDays(7, startOfDay), selectedDateRange)}
      />
      <BookingStateItem
        label="In zwei Woche"
        onClick={() => changeFilter({ selectedDateRange: [dateRangePickerMinimum, addDays(14, startOfDay)] })}
        active={useDateRangeItem(dateRangePickerMinimum, addDays(14, startOfDay), selectedDateRange)}
      />
      <BookingStateItem
        label="In einem Monat"
        onClick={() => changeFilter({ selectedDateRange: [dateRangePickerMinimum, addDays(30, startOfDay)] })}
        active={useDateRangeItem(dateRangePickerMinimum, addDays(30, startOfDay), selectedDateRange)}
      />
      <BookingStateItem
        label="In einem Jahr"
        onClick={() => changeFilter({ selectedDateRange: [dateRangePickerMinimum, addDays(365, startOfDay)] })}
        active={useDateRangeItem(dateRangePickerMinimum, addDays(365, startOfDay), selectedDateRange)}
      />

      <Space h="md" />
      <Text pl={10}>Filtergruppe</Text>
      <Box p="xs">
        <AgendaGroupMultiSelectPlaceholder
          data={displayItems}
          variant="filled"
          placeholder={getGroupInputText()}
          value={null}
          clearButtonLabel="Übersichtliche Auswahl"
          onChange={onChange}
          zIndex={100}
          shadow="xl"
          itemComponent={AgendaGroupMultiSelectItem}
        />
      </Box>
      <Space h="sm" />
      <Checkbox
        ml={15}
        label="fertige Aufgaben"
        checked={includeCompleted}
        onChange={(event) => onIncludeCompletedChange(event.currentTarget.checked)}
      />
    </>
  );
};

export default TasksNavFilter;
