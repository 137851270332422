import React from 'react';
import { ExternalSubscription } from './ExternalSubscription';

export const ExternalSubscriptionsList = ({ subscriptions, deleteSubscription }) => {
  const content = [];

  subscriptions.map((subscription, index) =>
    content.push(
      <ExternalSubscription
        key={index}
        url={subscription.subscriptionUrl}
        id={subscription.id}
        lastModified={subscription.lastModified}
        onClick={deleteSubscription}
        index={index}
      />,
    ),
  );

  return (
    <div className="externalSubscriptionTable">
      {subscriptions.map((subscription, index) => (
        <ExternalSubscription
          key={`external-subscription-${subscription.id}`}
          onClick={deleteSubscription}
          index={index}
          subscription={subscription}
        />
      ))}
    </div>
  );
};
