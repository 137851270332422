import { ajaxActions } from './AjaxActions';

const SEEN_NOTIFICATIONS = 'seenNotifications';

const SPACES = {
  NOTIFICATIONS: 'notifications',
};

type PostType = 'ANNOUNCEMENT' | 'MAINTENANCE';

type ActionLink = {
  url: string;
};

type ImageAsset = {
  filename: string;
};

type StoryContent = {
  title: string;
  description: string;
  post_type: PostType;
  buttonTitle?: string;
  call_to_action_btn?: ActionLink;
  image?: ImageAsset;
};

export type Story = {
  uuid: string;
  content: StoryContent;
};

type Stories = {
  stories: Story[];
};

const BASE_URL = process.env.REACT_APP_STORYBLOK_URL;
const API_TOKEN = process.env.REACT_APP_STORYBLOK_TOKEN;

const fetchStories = (space: string): Promise<Story[]> | null => {
  if (BASE_URL && API_TOKEN) {
    return ajaxActions
      .getResourceAsJson(`${BASE_URL}/cdn/stories?starts_with=${space}/&token=${API_TOKEN}`)
      .then((resJson) => {
        const data = resJson as Stories;
        return data.stories;
      });
  }
  return null;
};

const getSeenNotifications = (): string[] => {
  const seenNotifications = localStorage.getItem(SEEN_NOTIFICATIONS);
  if (seenNotifications) {
    return JSON.parse(seenNotifications);
  }
  return [];
};

const markAsSeen = (uuid: string) => {
  const seenNotifications = getSeenNotifications();
  seenNotifications.push(uuid);
  localStorage.setItem(SEEN_NOTIFICATIONS, JSON.stringify(seenNotifications));
};

// eslint-disable-next-line import/prefer-default-export
export const StoryBlokS = {
  SPACES,
  fetchStories,
  getSeenNotifications,
  markAsSeen,
};
