import { DefaultProps, MultiSelect, MultiSelectProps, Text } from '@mantine/core';
import { FC, useState } from 'react';

const CreatableMultiSelect: FC<MultiSelectProps> = ({ ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<string[]>(rest.value ?? []);
    
  return (
    <MultiSelect
      creatable
      searchable
      // value={value}
      // onChange={onChange}

      // icon={loading && <Loader size="xs" />}
      onCreate={(query) => {
        setData([...data, query]);
        return query;
      }}
      getCreateLabel={(query) => (
        <Text color="blue">
          {' '}
          + Hinzufügen{' '}
          <Text span color="black" ml={10} weight="bolder">
            {query}
          </Text>
        </Text>
      )}
			{...rest}
			data={data}
    />
  );
};

export default CreatableMultiSelect;
