import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Trans } from '@lingui/react';
import { Icon } from '../Icons';
import { ToastS } from '../../Service/ToastS';

export default function ({ text, className, buttonText, button }) {
  function copyToClipboard(e) {
    ToastS.success('copySuccess', 'In Zwischenablage kopiert');
  }

  return (
    <CopyToClipboard text={text} onCopy={copyToClipboard}>
      {button || (
        <button className={className || 'btn btn-primary'} onClick={() => {}}>
          <Icon value="COPY_SOLID" /> <Trans id={buttonText || 'actions.copy'} />
        </button>
      )}
    </CopyToClipboard>
  );
}
