import React, { useContext, useState } from 'react';
import { withI18n } from '@lingui/react';
import { withCookies } from 'react-cookie';
import { v4 as uuidv4 } from 'uuid';
import { Outlet, useParams } from 'react-router-dom';
import { QuestionnaireContext } from '../../../../Service/Context/QuestionnaireContext';
import {
  deleteQuestion,
  deleteQuestionBundleInTemplate,
  moveQuestionBundleToIndexInTemplate,
  moveQuestionToIndex,
  putQuestion,
  putQuestionBundleInTemplate,
} from '../../../../Service/restapi/questionnaireService';
import ResourceAdapter from '../../../../Service/ResourceAdapter';

const QuestionnaireTemplateProvider = (props) => {
  const [questionnaire, setQuestionnaire] = useState(props.template);
  return (
    <QuestionnaireContext.Provider
      value={{
        questionnaire,
        setQuestionnaire: (questionnaire) => {
          const newQuestionnaire = { ...questionnaire };
          setQuestionnaire(newQuestionnaire);
        },
        addQuestion: (bundleId, index) => {
          const id = uuidv4();
          putQuestion(bundleId, id, index).then((resp) => {
            if (resp.ok) {
              resp.json().then((questionBundle) => {
                const oldQuestionBundle = questionnaire.questionBundles.find((b) => b.id === bundleId);
                const indexOf = questionnaire.questionBundles.indexOf(oldQuestionBundle);
                const newQuestionnaire = { ...questionnaire };
                newQuestionnaire.questionBundles.splice(indexOf, 1, questionBundle);
                setQuestionnaire((prevState) => newQuestionnaire);
              });
            }
          });
        },
        addQuestionBundle: (index) => {
          const id = uuidv4();
          putQuestionBundleInTemplate(id, questionnaire.id, index).then((resp) => {
            if (resp.ok) {
              resp.json().then((questionnaire) => {
                setQuestionnaire((prevState) => questionnaire);
              });
            }
          });
        },
        delQuestion: (id, bundleId) => {
          deleteQuestion(bundleId, id).then((resp) => {
            if (resp.ok) {
              resp.json().then((questionBundle) => {
                const oldQuestionBundle = questionnaire.questionBundles.find((b) => b.id === bundleId);
                const indexOf = questionnaire.questionBundles.indexOf(oldQuestionBundle);
                const newQuestionnaire = { ...questionnaire };
                newQuestionnaire.questionBundles.splice(indexOf, 1, questionBundle);
                setQuestionnaire((prevState) => newQuestionnaire);
              });
            }
          });
        },
        setQuestionBundle: (questionBundle) => {
          const oldQuestionBundle = questionnaire.questionBundles.find((b) => b.id === questionBundle.id);
          const indexOf = questionnaire.questionBundles.indexOf(oldQuestionBundle);
          const newQuestionnaire = { ...questionnaire };
          newQuestionnaire.questionBundles.splice(indexOf, 1, questionBundle);
          setQuestionnaire((prevState) => newQuestionnaire);
        },
        moveQuestion: (bundleId, questionId, index, originBundleId) => {
          moveQuestionToIndex(bundleId, questionId, index, originBundleId).then((resp) => {
            if (resp.ok) {
              resp.json().then(setQuestionnaire);
            }
          });
        },
        moveQuestionBundle: (bundleId, index) => {
          moveQuestionBundleToIndexInTemplate(questionnaire.id, bundleId, index).then((resp) => {
            if (resp.ok) {
              resp.json().then((questionnaire) => {
                setQuestionnaire(questionnaire);
                /* setQuestionnaire((prevState) => (
                                questionnaire
                            )); */
              });
            }
          });
        },
        delQuestionBundle: (bundleId) => {
          deleteQuestionBundleInTemplate(questionnaire.id, bundleId).then((resp) => {
            if (resp.ok) {
              resp.json().then((questionnaire) => {
                setQuestionnaire((prevState) => questionnaire);
              });
            }
          });
        },
      }}
    >
      <Outlet />
    </QuestionnaireContext.Provider>
  );
};

export default withCookies(
  withI18n()((outerProps) => {
    const { templateName } = useParams();

    return (
      <ResourceAdapter
        serviceUrl={`${process.env.REACT_APP_QUESTIONNAIRE_SERVICE_URL}/questionnaires/templates/${templateName}`}
        component={(props) => {
          const tmp = props.data;
          return <QuestionnaireTemplateProvider template={tmp} {...outerProps} />;
        }}
      />
    );
  }),
);
