import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EditableProperty } from './EditableProperty';

export const EditableWebFormProperty = ({
  patchField,
  entity,
  handleChange,
  placeholder,
  editFormatter,
  labelFormatter,
  ...rest
}) => (
  <EditableProperty
    dto={entity}
    changeHandler={handleChange}
    patchField={patchField}
    placeholder={placeholder}
    targetUrl={`${process.env.REACT_APP_REQUEST_SERVICE_URL}/webforms/${entity.id}/formAttributes`}
    editFormatter={editFormatter}
    labelFormatter={labelFormatter}
    {...rest}
  />
);

EditableWebFormProperty.propTypes = {
  patchField: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
};
