// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Skeleton, Text, TextInput } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { IconTrashX } from '@tabler/icons';
import { openModal } from '@mantine/modals';
import { ContactRelation, ContactS, Contact } from '../../Service/ContactS';
import PanePaper from '../PanePaper';
import ContactPersonModal from '../../Organismns/Contact/ContactPersonModal';
import { ConstantS } from '../../Service/ConstantS';

interface Props {
  contactRelation: ContactRelation;
  updateRelation: (relationId: number, patch: Partial<ContactRelation>) => void;
  removeRelation: (relationId: number) => void;
}

const ContactRelationNew: FC<Props> = ({ contactRelation, updateRelation, removeRelation }) => {
  const { id, description, relatedContactId } = contactRelation;
  const [loading, setLoading] = useState(false);
  const [contact, setContact] = useState<Contact | null>();

  useEffect(() => {
    setLoading(true);
    ContactS.fetchById(relatedContactId)
      .then(setContact)
      .finally(() => setLoading(false));
  }, [relatedContactId]);

  const openEditContactModal = () => {
    if (contact) {
      openModal({
        modalId: ConstantS.Modals.ContactPerson,
        title: (
          <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
            Kontakt bearbeiten
          </Text>
        ),
        closeOnEscape: true,
        transition: 'slide-down',
        children: <ContactPersonModal contact={contact} onFinish={setContact} />,
      });
    }
  };

  if (loading) {
    return <Skeleton mt="sm" mb="sm" height={80} />;
  }
  if (!contact) {
    return null;
  }
  const { email, phoneNumber, personDescription } = contact;

  return (
    <PanePaper
      withShadow
      withoutBorder
      rightSection={
        <IconTrashX size={20} color="gray" onClick={() => removeRelation(id)} style={{ cursor: 'pointer' }} />
      }
    >
      <Text mb="xs" weight="bolder" sx={{ cursor: 'pointer' }} onClick={openEditContactModal}>
        👤 &nbsp; {personDescription}
      </Text>
      <TextInput
        label="Abteilung"
        defaultValue={description}
        onBlur={(e) => updateRelation(id, { description: e.currentTarget.value })}
      />
      <Group mt="xs" mb="xs" position="center">
        {email && <Text> ✉️ &nbsp; {email}</Text>}
        {phoneNumber && (
          <Text component="a" href={`tel:${phoneNumber}`}>
            📞 &nbsp; {phoneNumber}
          </Text>
        )}
      </Group>
    </PanePaper>
  );
};

export default ContactRelationNew;
