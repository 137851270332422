// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Paper, Text } from '@mantine/core';
import { FC, useEffect } from 'react';
import Confetti from 'react-confetti';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import { ConstantS } from '../../Service/ConstantS';
import { MixpanelS } from '../../Service/MixpanelS';

const RegistrationCompleted: FC = () => {
  useEffect(() => {
    document.title = 'Zugangsdaten versendet';
    // Execute with timeout to ensure that pixel is initialized
    setTimeout(() => ReactGA.event({ category: 'User', action: ConstantS.TrackingEvents.RegistrationCompleted }), 2000);
    setTimeout(() => MixpanelS.track(ConstantS.TrackingEvents.RegistrationCompleted), 2000);
    setTimeout(() => ReactPixel.track(ConstantS.TrackingEvents.RegistrationCompleted), 2000);
  }, []);

  return (
    <>
      <Confetti recycle={false} numberOfPieces={1000} />
      <Paper
        shadow="xs"
        p={40}
        sx={{
          maxWidth: 480,
          borderRadius: 10,
          position: 'relative',
          backgroundClip: 'padding-box, border-box',
          backgroundImage: 'linear-gradient(#fff,#fff),linear-gradient(88deg,#ffba5e,#ff6b69)',
          borderTop: '10px double transparent',
        }}
      >
        <Text size={28}>Nur noch ein Schritt!</Text>
        <Text mt={20}>
          Wir haben Dir eine E-Mail mit einem Link zur Bestätigung Deines Accounts geschickt.
        </Text>
        <Text weight="bolder" size="md" color={"red"} mt={20}>
          Achtung: Prüfe unbedingt Deinen Spam-Ordner!
        </Text>
      </Paper>
    </>
  );
};

export default RegistrationCompleted;
