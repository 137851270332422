import { AppShell, Group, Navbar, ScrollArea, ThemeIcon } from '@mantine/core';
import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import PageHeader from '../../Templates/Navigation/PageHeader';
import { WindowContext } from '../../Service/Context/WindowContext';
import { ResourcesContextProvider } from '../../Service/Context/ResourcesContext';
import ResourcesNavBar from './ResourcesNavBar';
import AddResourceIcon from './AddResourceIcon';
import { IconCalendar } from '@tabler/icons';
import { openAvailabilityModal } from './ResourceModals';
import BookitupTutorialIcon from "../../Atoms/BookitupTutorialIcon";

const ResourcesPage = () => {
  const { sm } = useContext(WindowContext);
  const [navBarOpen, setNavBarOpen] = useState<boolean>(false);

  useEffect(() => {
    document.title = 'Ressourcen';
  }, []);

  return (
    <ResourcesContextProvider>
      <AppShell
        header={
          <PageHeader
            title="Ressourcen"
            navBarOpen={navBarOpen}
            setNavBarOpen={setNavBarOpen}
            rightSection={
              <Group>
                <BookitupTutorialIcon videoUrl={"https://www.youtube.com/watch?v=q2MA2vaBHoo"} name={"CreateResource"} />
                <ThemeIcon
                  size={20}
                  style={{ cursor: 'pointer' }}
                  variant="gradient"
                  gradient={{ from: '#FEC170', to: '#FDA632', deg: 135 }}
                >
                  {/* TODO: Change icon later to clock-check */}
                  <IconCalendar onClick={openAvailabilityModal} />
                </ThemeIcon>
                <AddResourceIcon />
              </Group>
            }
          />
        }
        navbar={
          <div>
            {sm ? (
              <Navbar hiddenBreakpoint="sm" hidden={!navBarOpen} width={{ sm: 220, md: 250 }}>
                <ScrollArea>
                  <ResourcesNavBar />
                </ScrollArea>
              </Navbar>
            ) : (
              <DoubleNavbar>
                <ResourcesNavBar />
              </DoubleNavbar>
            )}
          </div>
        }
      >
        <Outlet />
      </AppShell>
    </ResourcesContextProvider>
  );
};

export default ResourcesPage;
