import { FC } from 'react';
import { WorkflowStepDTO } from '../Types/LogT';
import ActionTypeIcon from './ActionTypeIcon';
import { GradientIcon } from './ThemeIcons';

const ProcedureStatusIcon: FC<{ workflowStep: WorkflowStepDTO }> = ({ workflowStep }) => {
  const { procedureType, executionReport, completed } = workflowStep;
  const { executionStatus } = executionReport ?? {};

  const getColor = () => {
    if (completed) {
      return 'blue';
    }

    if (executionStatus === 'FAILED' || executionStatus === 'PRECONDITION_FAILED' || executionStatus === 'ABORTED') {
      return 'red';
    }
    if (executionStatus === 'WAITING') {
      return 'orange';
    }
    return 'gray';
  };

  return (
    <GradientIcon color={getColor()}>
      <ActionTypeIcon actionType={procedureType} />
    </GradientIcon>
  );
};

export default ProcedureStatusIcon;
