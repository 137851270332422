/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
import { Select, SelectProps, Skeleton } from '@mantine/core';
import { IconChevronDown, IconUser } from '@tabler/icons';
import { FC, useEffect, useState } from 'react';
import { DataS, KeyValuePair } from '../../Service/DataS';
import { fetchContacts, getContactById } from '../../Service/restapi/contactService';
import Contact from '../../Types/Contact';
import { TestS } from '../../TestS';

interface CustomerSelectProps extends SelectProps {
  data: KeyValuePair[];
  initialValue?: string;
}


export const GeneralCustomerSelect: FC<Partial<CustomerSelectProps>> = ({ initialValue, ...rest }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [contacts, setContacts] = useState<KeyValuePair[]>([]);

  useEffect(() => {
    setLoading(true);
    fetchContacts()
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((contactsFromResponse: Contact[]) => {
            const formContactds: any = DataS.getContactsOptions(contactsFromResponse);
            setContacts(formContactds);
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading ? (
        <Skeleton radius="sm" height={35} width="100%" />
      ) : (
        <CustomerSelect data={contacts} initialValue={initialValue} {...rest} />
      )}
    </>
  );
};

interface EventCustomerSelectProps extends Partial<CustomerSelectProps> {
  eventParticipationContext: any;
}

export const EventCustomerSelect: FC<EventCustomerSelectProps> = ({
  initialValue,
  eventParticipationContext,
  ...rest
}) => {
  const { participations } = eventParticipationContext;
  const [loading, setLoading] = useState<boolean>(false);
  const [eventParticipants, setEventParticipants] = useState<KeyValuePair[]>([]);

  useEffect(() => {
    setLoading(true);
    Promise.all(
      participations.map((p: any) => {
        const { contactId } = p;
        return getContactById(contactId).then((resp: any) => {
          if (resp.ok) {
            return resp.json().then(DataS.getContactOption);
          }
          return [];
        });
      }),
    ).then((participants) => {
      setLoading(false);
      setEventParticipants(participants);
    });
  }, [participations]);

  return (
    <>
      {loading ? (
        <Skeleton radius="sm" height={35} width="100%" />
      ) : (
        <CustomerSelect data={eventParticipants} initialValue={initialValue} {...rest} />
      )}
    </>
  );
};

const CustomerSelect: FC<CustomerSelectProps> = ({ initialValue, ...rest }) => (
  <Select
    searchable
    icon={<IconUser size={16} />}
    label="Kunde"
    defaultValue={initialValue ?? ''}
    data-testid={TestS.Common.SELECT_CUSTOMER}
    styles={{ rightSection: { pointerEvents: 'none' } }}
    rightSection={<IconChevronDown size={14} />}
    rightSectionWidth={25}
    {...rest}
  />
);

export default CustomerSelect;
