import {FC, useContext} from "react";
import {useModals} from "@mantine/modals";
import {ContactContext} from "../Service/Context/ContactContext";
import OnboardingCallModal from "../Templates/Onboarding/OnboardingCallModal";
import {MixpanelS} from "../Service/MixpanelS";
import {ConstantS} from "../Service/ConstantS";

const DemoLink: FC = () => {
  const { openModal, closeAll } = useModals();
  const { contact } = useContext<any>(ContactContext);

  return (
    <b>
      <u style={{cursor: 'pointer'}} onClick={() => {
        MixpanelS.track(ConstantS.TrackingEvents.OpenOnboardingModal);
        openModal({
          size: 800,
          children: (
            <OnboardingCallModal
              contact={contact}
              onFinish={() => {
                closeAll();
              }}
            />
          ),
          onClose() {
            MixpanelS.track(ConstantS.TrackingEvents.CloseOnboardingModal);
          },
          closeOnEscape: false,
          withCloseButton: true,
        });
      }
      }>
        Hilfe vom Support
      </u>
    </b>
  )
}

export default DemoLink;
