import React, { useContext, useEffect, useState } from 'react';
import { Pane } from '../../Molecules/Pane';
import { TransButtonDefault, TransButtonDefaultWithIcon, TransButtonDefaultWithSpinner } from '../../Atoms/Buttons';
import { file } from '../../Service/FileActions';
import { InputField } from '../../Atoms/InputFields';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { ModalDialog } from '../../Molecules/Dialogs';
import PDFViewerProxy from '../../Atoms/PDFViewer';
import PanePaper from '../../Molecules/PanePaper';
import { RoutingS } from '../../Service/RoutingS';

// eslint-disable-next-line import/prefer-default-export
export const GDPR = () => {
  const [isDownloadLoading, setDownloadLoading] = useState(false);
  const { profile, patchProfile } = useContext(ProfileContext);
  const [showPreview, setPreview] = useState(false);
  const gdprUrl = `${process.env.REACT_APP_GDPR_SERVICE_URL}/gdpr-contract`;

  useEffect(() => {
    RoutingS.changeTitle('Datenschutz');
  }, [])

  function toggleGdpr() {
    patchProfile({ gdprAccepted: !profile.gdprAccepted });
  }

  function toggleSupportAccess() {
    patchProfile({ supportAccess: !profile.supportAccess });
  }

  function downloadGDPR(empty) {
    setDownloadLoading(true);
    if (!empty && !profile.gdprAccepted) {
      toggleGdpr();
    }
    file
      .download(`${gdprUrl}?empty=${empty}`, 'AV-Vertrag')
      .then((resp) => {
        setDownloadLoading(false);
      })
      .catch(() => {
        setDownloadLoading(false);
      });
  }

  return (
    <div style={{ maxWidth: 500 }}>
      <PanePaper title="gdpr">
        <div>
          {/* <InputField toggle={toggleSupportAccess} name={"supportAccess"} checked={profile.supportAccess} text={<div>Ich erlaube dem Support Zugriff auf meinen Account.</div>} />
                <br/><br/> */}
          <p>
            Einen Vertrag zur Auftragsverarbeitung nach Art. 28 EU DSGVO kannst Du bei bookitup in elektronischer Form
            schließen:
          </p>
          <InputField
            toggle={toggleGdpr}
            name="gdprAccepted"
            checked={profile.gdprAccepted}
            text={<div>Ich habe den AV-Vertrg nach DSGVO gelesen und akzeptiere diesen.</div>}
          />
          <br />
          <br />
          <TransButtonDefaultWithIcon text="actions.preview" icon="EYE" onClickHandler={() => setPreview(true)} />{' '}
          <TransButtonDefaultWithSpinner
            text="AV nach DSGVO akzeptieren"
            isLoading={isDownloadLoading}
            icon="DOWNLOAD"
            onClick={() => downloadGDPR(false)}
          />
          <ModalDialog
            size="xl"
            centered
            header="Auftragsverarbeitungs-Vertrag"
            body={<PDFViewerProxy closeCallback={() => setPreview(!showPreview)} url={gdprUrl} />}
            footer={<TransButtonDefault onClickHandler={() => setPreview(!showPreview)} text="actions.close" />}
            show={showPreview}
            toggle={() => setPreview(!showPreview)}
          />
        </div>
      </PanePaper>
    </div>
  );
};
