// eslint-disable-next-line no-redeclare
import { useContext } from 'react';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import { openCreateContactModal } from '../../Organismns/Contact/EditContactModal';
import { ContactsContext } from '../../Service/Context/ContactsContext';

const ContactsHeadActions = () => {
  const { reload } = useContext(ContactsContext);
  return <BookitupAddIcon onClick={() => openCreateContactModal(reload)} />;
};

export default ContactsHeadActions;
