import { ContactGroup } from '../Types/AuthT';
import { ajaxActions } from './AjaxActions';
import { Contact } from './ContactS';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_AUTH_SERVICE_URL;

const fetchAll = async (recommendable = false): Promise<ContactGroup[]> => {
  const res = await ajaxActions.get(
    `${BASE_URL}/contactGroups${recommendable ? `?recommendable=${recommendable}` : ''}`,
  );
  if (res.ok) {
    return res.json();
  }
  return [];
};

const fetchGroupContacts = async (groupId: number): Promise<Contact[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/contactGroups/${groupId}/contacts`);
  if (res.ok) {
    return res.json();
  }
  return [];
};

const addContact = async (groupId: number, contactId: number): Promise<Contact[] | null> => {
  const res = await ajaxActions.put(`${BASE_URL}/contactGroups/${groupId}/contacts/${contactId}`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const create = async (name: string, recommendable: boolean): Promise<ContactGroup | null> => {
  const res = await ajaxActions.post(`${BASE_URL}/contactGroups/`, { name, recommendable });
  if (res.status === 201) {
    ToastS.success('foo', `Grouppe ${name} wurde erstellt`);
    return res.json();
  }
  ToastS.generalError();

  return null;
};

const update = async (id: number, patch: Partial<ContactGroup>): Promise<ContactGroup | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/contactGroups/${id}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

const remove = async (id: number): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/contactGroups/${id}`);
  if (res.ok) {
    ToastS.info('FOO', 'Gruppe wurde gelöscht');
    return true;
  }
  ToastS.generalError();
  return false;
};

// eslint-disable-next-line import/prefer-default-export
export const ContactGroupS = {
  create,
  fetchAll,
  fetchGroupContacts,
  addContact,
  update,
  remove,
};
