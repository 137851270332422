import { Anchor, Box, Breadcrumbs, Space } from '@mantine/core';
import {
  IconAlertCircle,
  IconChevronRight,
  IconClock,
  IconInbox,
  IconPencil,
  IconSend,
  IconTrash,
} from '@tabler/icons';
import { FC, useContext } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import NavigationLink from '../../Atoms/Navigation/NavigationLink';
import EmailSearchTextInputField from '../../Molecules/Postbox/EmailSearchTextInputField';
import { useNavigationItem } from '../../Organismns/Navigation/NavigationItemHook';
import { WindowContext } from '../../Service/Context/WindowContext';
import EmailBoxCounter from './EmailBoxCounter';
import { PostboxCountersContext } from '../../Service/Context/PostboxCountersContext';

const EmailInboxNavLinks: FC<{ closeNavBar: () => void }> = ({ closeNavBar }) => {
  const navigate: NavigateFunction = useNavigate();
  const { pathname } = useLocation();
  const { sm } = useContext(WindowContext);

  // eslint-disable-next-line no-underscore-dangle
  const _onClick = (path: string) => {
    navigate(path);
    closeNavBar();
  };

  const { unreadCount, unassignedCount, draftsCount, scheduledCount, failedCount, trashCount } = useContext(PostboxCountersContext);

  return (
    <>
      {!sm && (
        <Breadcrumbs separator={<IconChevronRight size={12} />} mt={5} mb={8}>
          <Anchor p="md" size="xl" variant="text" weight="bolder">
            Postfach
          </Anchor>
        </Breadcrumbs>
      )}
      <Box p="xs">
        <EmailSearchTextInputField />
      </Box>
      <NavigationLink
        opened
        label="Posteingang"
        icon={sm && <IconInbox size={16} />}
        childrenOffset={28}
        color="lightBlue"
      >
        <NavigationLink
          label="Alle"
          active={pathname === '/postbox/inbox'}
          onClick={() => _onClick('/postbox/inbox')}
          variant="filled"
          color="lightBlue"
        />
        <NavigationLink
          label="Unerledigt"
          active={useNavigationItem('/postbox/inbox/unread')}
          onClick={() => _onClick('/postbox/inbox/unread')}
          variant="filled"
          rightSection={<EmailBoxCounter count={unreadCount} />}
          color="lightBlue"
        />
        <NavigationLink
          label="ohne Eventbezug"
          active={useNavigationItem('/postbox/inbox/unassigned')}
          onClick={() => _onClick('/postbox/inbox/unassigned')}
          variant="filled"
          rightSection={<EmailBoxCounter count={unassignedCount} />}
          color="lightBlue"
        />
      </NavigationLink>
      <NavigationLink
        label="Gesendet"
        icon={sm && <IconSend size={16} />}
        active={useNavigationItem('/postbox/outbound')}
        onClick={() => _onClick('/postbox/outbound')}
        variant="filled"
        color="lightBlue"
      />
      <NavigationLink
        label="Entwürfe"
        icon={sm && <IconPencil size={16} />}
        active={useNavigationItem('/postbox/drafts')}
        onClick={() => _onClick('/postbox/drafts')}
        variant="filled"
        rightSection={<EmailBoxCounter count={draftsCount} />}
        color="lightBlue"
      />
      <NavigationLink
        label="Geplant"
        icon={sm && <IconClock size={16} />}
        active={useNavigationItem('/postbox/scheduled')}
        onClick={() => _onClick('/postbox/scheduled')}
        variant="filled"
        rightSection={<EmailBoxCounter count={scheduledCount} />}
        color="lightBlue"
      />
      <NavigationLink
        label="Fehlgeschlagen"
        icon={sm && <IconAlertCircle size={16} />}
        active={useNavigationItem('/postbox/failed')}
        onClick={() => _onClick('/postbox/failed')}
        variant="filled"
        rightSection={<EmailBoxCounter count={failedCount} />}
        color="lightBlue"
      />
      <NavigationLink
        label="Papierkorb"
        icon={sm && <IconTrash size={16} />}
        active={useNavigationItem('/postbox/trash')}
        onClick={() => _onClick('/postbox/trash')}
        variant="filled"
        rightSection={<EmailBoxCounter count={trashCount} />}
        color="lightBlue"
      />
    </>
  );
};

export default EmailInboxNavLinks;
