import { Menu } from '@mantine/core';
import { IconDotsVertical, IconSettings, IconTrashX } from '@tabler/icons';
import { FC } from 'react';
import { openDeleteGoogleAccountModal } from './DeleteGoogleAcountModal';

interface Props {
  accountId: number;
  manageGoogleAccount: () => void;
  removeAccount: () => void;
}

const GoogleAccountActions: FC<Props> = ({ accountId, manageGoogleAccount, removeAccount }) => (
  <div>
    <Menu withArrow shadow="md" width={150} transition="scale-y" position="bottom-end">
      <Menu.Target>
        <div>
          <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item icon={<IconSettings size={16} />} onClick={() => manageGoogleAccount()}>
          Einstellungen
        </Menu.Item>
        <Menu.Item
          color="red"
          icon={<IconTrashX size={16} />}
          onClick={() => openDeleteGoogleAccountModal(accountId, removeAccount)}
        >
          Löschen
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  </div>
);

export default GoogleAccountActions;
