import { FC } from 'react';
import { Badge } from '@mantine/core';
import Moment from 'react-moment';
import BookitupDocument from '../../../Types/BookitupDocument';

const DocumentExpirationBadge: FC<{ bookitupDocument: BookitupDocument }> = ({ bookitupDocument }) => {
  const { draft, documentType, validUntil, paid } = bookitupDocument;
  if (!validUntil) {
    return null;
  }
  const expired = new Date().getTime() > new Date(validUntil).getTime();

  const getColors = () => {
    if (documentType === 'OFFER') {
      if (!draft) {
        return ['white', '#83D27E'];
      }
      if (expired && draft) {
        return ['white', '#FF5C5A'];
      }
      return ['black', '#E2E2E2'];
    }
    if (paid) {
      return ['white', '#83D27E'];
    }
    if (expired && !paid) {
      return ['white', '#FF5C5A'];
    }
    return ['black', '#E2E2E2'];
  };

  const [fontColor, bgColor] = getColors();

  return (
    <Badge
      sx={{
        color: fontColor,
        fontSize: 10,
        textTransform: 'none',
        fontWeight: 'lighter',
        backgroundColor: bgColor,
      }}
    >
      Gültig bis{' '}
      <b>
        <Moment date={validUntil} format="DD.MM" />
      </b>
    </Badge>
  );
};

export default DocumentExpirationBadge;
