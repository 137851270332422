import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ProfileContext } from '../Service/Context/ProfileContext';
import { Icon } from '../Atoms/Icons';
import { TransButtonPrimary } from '../Atoms/Buttons';
import { ToastS } from '../Service/ToastS';

const FileUpload = ({ text, serverUrl, reloadCallback }) => {
  const { profile } = useContext(ProfileContext);

  function onDrop(files) {
    if (!serverUrl) {
      serverUrl = `${process.env.REACT_APP_FILE_SERVICE_URL}/files/profiles/${profile.id}`;
    }
    // This will upload the file after having read it
    files.forEach((file) => {
      ToastS.info(`file.upload.${file.filename}`, `Datei ${file.name} wird hochgeladen, bitte warten`);
      const data = new FormData();
      data.append('file', file);
      fetch(serverUrl, {
        // Your POST endpoint
        method: 'PUT',
        credentials: 'include',
        body: data, // This is your file object
      })
        .then((response) => {
          if (response.ok) {
            ToastS.success(`file.upload.${file.filename}`, `Datei ${file.name} erfolgreich hochgeladen`);
            reloadCallback(file.name);
          } else {
            console.error(`could not upload file ${file.name}`);
            ToastS.error(`file.upload.${file.filename}`, `Datei ${file.name} konnte nicht hochgeladen werden`);
          }
        })
        .catch((error) => {
          ToastS.error(`file.upload.${file.filename}`, `Datei ${file.name} konnte nicht hochgeladen werden`);
          console.error({ error });
          console.error(`could not upload file ${file.name}`);
        });
    });
  }

  const styles = {
    container: {
      marginBottom: 5,
      marginTop: 5,
      display: 'flex',
      justifyContent: 'center',
    },
    dropZone: {
      backgroundColor: '#f6f8f9', // "#f5f7f8"
      width: '100% !important',
      maxWidth: 550,
      height: '150px !important',
      maxHeight: 200,
      border: 'none !important',
      textAlign: 'center',
      padding: 20,
      cursor: 'pointer',
      borderRadius: 2,
    },
    icon: {
      width: 30,
      height: 30,
    },
    button: {
      marginTop: 10,
    },
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className="dropzone" style={styles.container} {...getRootProps()}>
      <input style={styles.dropZone} {...getInputProps()} />
      <center>
        <Icon value="IMAGES" style={styles.icon} />
        <div> {text || 'Hier kannst Du Dateien hineinziehen'} </div>
        <TransButtonPrimary onClickHandler={() => {}} style={styles.button} text="Datei auswählen" />
      </center>
    </div>
  );
};

export default FileUpload;
