import {CSSProperties, FC, ReactNode, useContext, useEffect, useState} from 'react';
import {Trans, useLingui} from '@lingui/react';
import {Alert, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import {Modal, Paper, Sx} from '@mantine/core';
import {TransButtonDefault, TransButtonPrimary, TransButtonPrimaryWithSpinner} from '../Atoms/Buttons';
import {TransTextInputField} from '../Atoms/InputFields';
import {Icon} from '../Atoms/Icons';
import TooltipButton from '../Atoms/buttons/TooltipButton';
import {InputHelp} from '../Atoms/inputs/InputHelp';
import CustomStepper, {CustomStepperContext, CustomStepperContextProvider} from '../Atoms/Stepper/Stepper';
import {ModalFlowStepItem} from '../Atoms/Stepper/MenuItem';
import {TestS} from "../TestS";

export const useModalState = (initialState?: boolean) => {
  const [show, setShow] = useState(initialState || false);
  const toggle = () => setShow(!show);
  return { show, toggle };
};

export const useScript = (url: string) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export const useConfirmModalState = (initialState?: boolean) => {
  const [showConfirm, setShow] = useState(initialState || false);
  const toggleConfirm = () => setShow(!showConfirm);
  return { showConfirm, toggleConfirm };
};

type CustomModalHeaderProps = { children: ReactNode; onClose?: () => void; help?: string };

export const CustomModalHeader: FC<CustomModalHeaderProps> = ({ children, onClose, help }) => {
  const styles = {
    headContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  };

  let helpId = help;
  if (help !== undefined) {
    helpId = helpId?.replace(/./g, '-');
  }
  return (
    <ModalHeader onClose={onClose} style={{ border: 'none' }}>
      <div style={styles.headContainer}>
        <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between' }}>{children}</div>
        {help && <TooltipButton icon="QUESTION" id={helpId} text={help} />}
      </div>
    </ModalHeader>
  );
};

type ModalFlowProps = {
  menuItems: ModalFlowStepItem[];
  stepBodyComponents: ReactNode[];
  error?: string;
  isLoading?: boolean;
  canBeFinished?: boolean;
  finish: () => void;
  finishButtonText: string;
  clickable?: boolean;
};

interface ModalFlowPropsWithInit extends ModalFlowProps {
  initialStep?: number;
}

export const ModalFlow: FC<ModalFlowPropsWithInit> = (props) => (
  <CustomStepperContextProvider initialStep={props.initialStep}>
    <ModalFlowContent {...props} />
  </CustomStepperContextProvider>
);

const ModalFlowContent: FC<ModalFlowProps> = ({
  menuItems,
  stepBodyComponents,
  error,
  isLoading,
  canBeFinished,
  finish,
  finishButtonText,
  clickable,
}) => {
  const { steps, currentStep, nextStep, prevStep, setAllItemsContent } = useContext(CustomStepperContext);

  useEffect(() => {
    if (menuItems.length === stepBodyComponents.length) {
      setAllItemsContent(
        menuItems.map((it, idx) => ({
          step: it,
          content: stepBodyComponents[idx],
        })),
      );
    } else {
      console.error('menuItems, stepBodyComponents must be the same length!');
    }
  }, [menuItems, setAllItemsContent, stepBodyComponents]);

  const styles = {
    masterDetail: {
      display: 'flex',
    },
    footer: {
      paddingBottom: 10,
      marginTop: 20,
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };

  return (
    <>
      <CustomStepper clickable={clickable} />

      <div style={styles.masterDetail}>
        <div
          style={{
            flex: 1,
            paddingTop: 10,
            paddingRight: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
        >
          {error ? <InputHelp errorDescription={error} /> : null}
          <div style={styles.footer}>
            {currentStep > 0 && (
              <TransButtonPrimary style={{ marginRight: 5 }} onClickHandler={prevStep} text="actions.prev" />
            )}
            {currentStep < steps.length - 1 && (
              <TransButtonPrimary style={{ marginRight: 5 }} onClickHandler={nextStep} text="actions.next" />
            )}
            <TransButtonPrimaryWithSpinner
              isLoading={isLoading}
              disabled={!canBeFinished}
              onClickHandler={finish}
              text={finishButtonText || 'common.finish'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

type ModalDialogProps = {
  show: boolean;
  size?: string;
  className?: string;
  toggle?: () => void;
  header?: ReactNode;
  help?: string;
  alert?: string;
  modalBodyStyle?: CSSProperties;
  body: ReactNode;
  footer?: ReactNode;
  closable?: boolean;
  sx?: Sx;
};

export const ModalDialog: FC<ModalDialogProps> = ({
  show,
  size,
  className,
  toggle,
  header,
  help,
  alert,
  modalBodyStyle,
  body,
  footer,
  closable = true,
  sx,
}) => (
  <Modal
    closeOnEscape
    title={header && <CustomModalHeader onClose={toggle}> {header} </CustomModalHeader>}
    centered
    opened={show}
    onClose={() => toggle?.()}
    closeOnClickOutside={false}
    withCloseButton={closable}
    className={className}
    size={size}
    lockScroll={false}
    transition="slide-down"
    sx={sx}
  >
    {}
    <Paper style={modalBodyStyle}>
      {alert !== undefined && (
        <Alert color="danger">
          <Icon value="CAUTION" /> <Trans id={alert} />
        </Alert>
      )}
      {body}
    </Paper>
    {footer && <ModalFooter>{footer}</ModalFooter>}
  </Modal>
);

export const TransModalDialogWithTextField: FC<ModalDialogWithTextFieldProps> = (props) => {
  const { i18n } = useLingui();

  return (
    <ModalDialogWithTextField
      {...props}
      subject={i18n._(props.subject || '')}
      text={i18n._(props.text || '')}
      placeholder={i18n._(props.placeholder || '')}
    />
  );
};

type ConfirmDialogProps = {
  subject: string;
  children: ReactNode[];
  isLoading: boolean;
  text: string;
  help?: string;
  handler: () => void;
  toggle?: () => void;
  open: boolean;
  confirmText: string;
  disableConfirm: boolean;
};

export const ConfirmDialog: FC<ConfirmDialogProps> = ({
  subject,
  children,
  isLoading,
  text,
  help,
  handler,
  toggle,
  open,
  confirmText,
  disableConfirm,
}) => {
  const styles = {
    container: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
    },
    rightActions: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
  };

  return (
    <ModalDialog
      header={<Trans id={subject} />}
      help={help}
      body={<Trans id={text} />}
      closable={false}
      footer={
        <div style={styles.container}>
          <div>{children}</div>
          <div style={styles.rightActions}>
            <TransButtonPrimaryWithSpinner
              isLoading={isLoading}
              disabled={disableConfirm}
              onClickHandler={() => {
                toggle?.();
                handler();
              }}
              text={confirmText || 'actions.yes'}
              testId={TestS.Common.ConfirmDialog.YES}
            />
            &nbsp;
            <TransButtonDefault onClickHandler={toggle} text="actions.cancel" testId={TestS.Common.ConfirmDialog.CANCEL} />
          </div>
        </div>
      }
      show={open}
      toggle={toggle}
    />
  );
};

type ModalDialogWithTextFieldProps = {
  takenIdentifier: any;
  value: any;
  primaryButton: any;
  show: boolean;
  toggle: any;
  className: string | undefined;
  subject: string;
  text: string;
  name: any;
  onChange: any;
  placeholder: any;
  isLoading: boolean;
  props: any;
  disabled: boolean;
  confirm: () => void;
};

const ModalDialogWithTextField: FC<ModalDialogWithTextFieldProps> = ({
  takenIdentifier,
  value,
  disabled,
  primaryButton,
  show,
  isLoading,
  toggle,
  className,
  placeholder,
  onChange,
  subject,
  text,
  confirm,
}) => {
  const opts = {};
  if (takenIdentifier !== undefined) {
    if (takenIdentifier.includes(value)) {
      disabled = true;
    }
  }
  let primaryButtonText = primaryButton;
  if (primaryButtonText === undefined) {
    primaryButtonText = 'actions.save';
  }
  return (
    <Modal
      closeOnEscape
      opened={show}
      onClose={() => toggle(false)}
      className={className}
      closeOnClickOutside={false}
      title={subject && <CustomModalHeader onClose={toggle}> {subject} </CustomModalHeader>}
    >
      <ModalBody>
        {text !== undefined ? text : ''}
        <TransTextInputField value={value} onChange={onChange} placeholder={placeholder} />
      </ModalBody>
      <ModalFooter>
        <TransButtonPrimary isLoading={isLoading} onClickHandler={confirm} {...opts} text={primaryButtonText}>
          Ja
        </TransButtonPrimary>{' '}
        <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />
      </ModalFooter>
    </Modal>
  );
};
