import { useContext, useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { Title } from '@mantine/core';
import { EventContext } from '../../Service/Context/EventContext';
import { ModalDialog } from '../../Molecules/Dialogs';
import { TransButtonDefault, TransButtonPrimary, TransButtonPrimaryWithSpinner } from '../../Atoms/Buttons';
import { InputHelp } from '../../Atoms/inputs/InputHelp';
import { Icon } from '../../Atoms/Icons';
import { FocusedNodeContext } from '../../Service/Context/FocusedNode';
import { EventParticipationsContext } from '../../Service/Context/EventParticipationContext';
import { EmailTemplateFlow2 } from './EmailTemplateFlow-2';
import { EmailTemplateFlow1 } from './EmailTemplateFlow-1';
import { LicenceS } from '../../Service/LicenceS';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import { useJwtClaims } from '../../Service/Context/AuthContext';
import { MessageContext } from '../../Service/Context/MessageContextV2';
import { MessageS } from '../../Service/MessageS';

export const ModalFlowParent = ({ show, toggle }) => {
  const { licence } = useJwtClaims();
  const [currentStep, setStep] = useState(1);
  const [mailTemplate, setMailTemplate] = useState(undefined);
  const [templateName, setTemplateName] = useState(undefined);
  const [stepCount] = useState(2);
  const [error, setError] = useState(undefined);

  // load email template
  const { event } = useContext(EventContext);
  const { mainCustomerId } = useContext(EventParticipationsContext);
  const { openLicenceModal } = useContext(ViolationContext);
  const { message, changeMessage } = useContext(MessageContext);
  const { initMessage } = useContext(MessageContext);
  const [isLoading, setLoading] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState(new Map());
  const [disableApply, setDisableApple] = useState(false);
  const { setFocusedNode } = useContext(FocusedNodeContext);

  const [overrideSubject, setOverrideSubject] = useState(true);
  const [overrideRecipient, setOverrideRecipient] = useState(true);

  // reset state if close and open modal
  useEffect(() => {
    setStep(1);
    setSelectedDocuments(new Map());
    setMailTemplate(undefined);
  }, [show]);

  function next() {
    // validate step
    if (!mailTemplate) {
      setError('Bitte wähle eine Vorlage aus.');
    } else if (mailTemplate === 'CUSTOM' && !LicenceS.hasSuitableLicence('FLOW', licence)) {
      openLicenceModal(LicenceS.Restrictions.MULTIPLE_EMAIL_TEMPLATE);
    } else {
      setError(undefined);
      setStep(currentStep + 1);
    }
  }

  function prev() {
    setStep(currentStep - 1);
  }

  const finish = async () => {
    if (message) {
      MessageS.deleteDraft(message.id);
      changeMessage(null);
    }
    setFocusedNode(undefined); // otherwise, if a old field (such as the message field) is focused, the value of the old focused field will be override actual on sending...
    // console.log(mailTemplate, selectedDocuments)
    // templateLoaded(mainCustomerId, save)
    const partialMsg = await MessageS.createMessageFromTemplate(mailTemplate, templateName, mainCustomerId, selectedDocuments);
    initMessage(partialMsg)
    toggle();
  }

  return (
    <ModalDialog
      header={
        <div>
          {currentStep === 1 && <Title order={4}>Vorlage auswählen</Title>}
          {currentStep === 2 && (
            <div>
              <Icon value="ATTACHMENTS" /> <Trans id="email.template.select.attachments.head" />
            </div>
          )}
        </div>
      }
      body={
        <>
          {currentStep === 1 && (
            <EmailTemplateFlow1
              setMailTemplate={setMailTemplate}
              mailTemplate={mailTemplate}
              templateName={templateName}
              setTemplateName={setTemplateName}
            />
          )}

          {currentStep === 2 && (
            <EmailTemplateFlow2
              setDisableApply={setDisableApple}
              selectedDocuments={selectedDocuments}
              mailTemplate={mailTemplate}
              templateName={templateName}
            />
          )}
          {error ? <InputHelp errorDescription={error} /> : null}
        </>
      }
      footer={
        <div>
          <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />{' '}
          {currentStep > 1 && <TransButtonPrimary onClickHandler={prev} text="actions.prev" />}{' '}
          {currentStep < stepCount && <TransButtonPrimary onClickHandler={next} text="actions.next" />}{' '}
          {currentStep === stepCount && (
            <TransButtonPrimaryWithSpinner
              isLoading={isLoading}
              disabled={disableApply}
              id="applyTemplate"
              onClickHandler={finish}
              text="actions.finish"
            />
          )}
        </div>
      }
      show={show}
      toggle={toggle}
    />
  );
};
