import React, { useContext, useEffect, useState } from 'react';
import { fetchBriefestEventParticipationByContactId, fetchEventById } from '../../Service/restapi/eventService';
import { fetchContactById, getCustomerId } from '../../Service/restapi/contactService';

export const EventCustomerLoadingWrapper = ({ message, children }) => {
  const [event, setEvent] = useState(undefined);
  const [customer, setCustomer] = useState(undefined);

  useEffect(() => {
    if (message) {
      // ! on selecting other email, delete event and customer of previous selected email !
      setEvent(undefined);
      setCustomer(undefined);

      if (message.eventId) {
        fetchEventById(message.eventId).then((resp) => {
          if (resp.ok) {
            resp.json().then((event) => {
              setEvent(event);
            });
          }
        });
      } else {
        const customerId = getCustomerId(message);
        if (customerId) {
          fetchEventParticipation(customerId);
        }
      }
    }
  }, [message]);

  function fetchEventParticipation(customerId) {
    // ask event service for participating in next or last event
    fetchBriefestEventParticipationByContactId(customerId, true)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((participations) => {
            if (participations.length > 0) {
              console.log(`got ${participations.length} participations for contact ${customerId}`);
              const { eventId } = participations[0];
              fetchEventById(eventId)
                .then((resp) => {
                  if (resp.ok) {
                    resp.json().then((event) => {
                      setEvent(event);
                    });
                  } else {
                    console.info(`could not load event ${document.eventId}`);
                  }
                })
                .catch(() => {
                  console.info(`could not load event ${document.eventId}`);
                });
            } else {
              console.log(`no event participations found for contact ${customerId}`);
              setEvent(undefined);
            }
          });
        } else {
          console.error(`could not load event participations of contact ${customerId}`);
          setEvent(undefined);
        }
      })
      .catch((error) => {
        console.error(`could not load event participations of contact ${customerId}`);
        console.error({ error });
        setEvent(undefined);
      });
  }

  useEffect(() => {
    if (event) {
      fetchContactById(event.customerId).then((mainCustomer) => {
        setCustomer(mainCustomer);
      });
    }
  }, [event]);

  return React.cloneElement(children, {
    event,
    customer,
  });
};
