import { fetchAllCustomAttributes } from '../restapi/customAttributeService';
import { PlaceholderDataSource } from './PlaceholderResover';

export type PlaceholderLabel = {
  text: string;
  placeholder: string;
};

export type PlaceholderLabelsGroup = {
  groupName: string;
  items: PlaceholderLabel[];
};

export const getPlaceholderLabelById = (i18n: any, placeholderId: string) => {
  const allPlaceholders = [
    ...eventPlaceholder,
    ...locationPlaceholder,
    ...documentPlaceholderWithNum,
    ...customerPlaceholder,
    ...sequentialNumberPlaceholder,
  ];

  const text = allPlaceholders.find((p) => p.placeholder === placeholderId)?.text;

  if (text == null) {
    const [, customAttributeName] = placeholderId.split(':');
    return customAttributeName;
  }

  return i18n._(text);
};

export const eventPlaceholder: PlaceholderLabel[] = [
  { text: 'Datum', placeholder: 'event_date' },
  { text: 'Wochentag', placeholder: 'event_weekday' },
  { text: 'Start-Uhrzeit', placeholder: 'event_timeFrom' },
  { text: 'End-Uhrzeit', placeholder: 'event_timeTo' },
  { text: 'Stunden-Anzahl', placeholder: 'event_hours' },
  { text: 'Art', placeholder: 'event_kind' },
  { text: 'Portal-Link', placeholder: 'event_portalLink' },
];

export const locationPlaceholder: PlaceholderLabel[] = [
  { text: 'Location Name', placeholder: 'location_name' },
  { text: 'Location Anschrift', placeholder: 'location_address' },
  { text: 'Entfernung (km)', placeholder: 'location_distance' },
];

export const documentPlaceholder: PlaceholderLabel[] = [
  { text: 'Gültig bis', placeholder: 'document_validUntil' },
  { text: 'Tage bis ungültig', placeholder: 'document_daysTilExpire' },
  { text: 'document.sum', placeholder: 'document_sum' },
  { text: 'Dokument-Nummer', placeholder: 'document_number' },
];

export const documentNumPlaceholder: PlaceholderLabel[] = [{ text: 'Vertrags-Nummer', placeholder: 'contract_number' }];

export const documentPlaceholderWithNum: PlaceholderLabel[] = [...documentPlaceholder, ...documentNumPlaceholder];

export const customerPlaceholder: PlaceholderLabel[] = [
  { text: 'customer.salutation', placeholder: 'customer_salutation' },
  { text: 'customer.partner.salutation', placeholder: 'customer_partner_salutation' },
  { text: 'customer.salutationOfficial', placeholder: 'customer_salutationOfficial' },
  { text: 'customer.partner.salutationOfficial', placeholder: 'customer_partner_salutationOfficial' },
  { text: 'customer.and.partner.names', placeholder: 'customer_and_partner_names' },
  { text: 'customer.and.partner.first.names', placeholder: 'customer_and_partner_first_names' },
  { text: 'customer.firstname', placeholder: 'customer_firstName' },
  { text: 'customer.partner.firstname', placeholder: 'customer_partner_firstName' },
  { text: 'customer.lastname', placeholder: 'customer_lastName' },
  { text: 'customer.partner.lastname', placeholder: 'customer_partner_lastName' },
  { text: 'E-Mail', placeholder: 'customer_email' },
  { text: 'customer.partner.emailAddress', placeholder: 'customer_partner_email' },
  { text: 'Telefonnummer', placeholder: 'customer_phoneNumber' },
  { text: 'customer.partner.phoneNumber', placeholder: 'customer_partner_phoneNumber' },
  { text: 'Firmennamen', placeholder: 'customer_companyName' },
  { text: 'Straße', placeholder: 'customer_addressStreetAndNo' },
  { text: 'PLZ', placeholder: 'customer_zipCode' },
  { text: 'Stadt', placeholder: 'customer_city' },
];

export const sequentialNumberPlaceholder: PlaceholderLabel[] = [
  { text: 'Aktuelles Jahr', placeholder: 'CURRENT_YEAR' },
  { text: 'Aktueller Monat', placeholder: 'CURRENT_MONTH' },
  { text: 'Aktueller Tag', placeholder: 'CURRENT_DAY' },
  { text: 'Fortlaufende Nummer', placeholder: 'seq_no' },
];

export const sequentialNumbersGroups = [{ groupName: 'Rechnungsnummer', items: sequentialNumberPlaceholder }];

export const placeholderGroups: PlaceholderLabelsGroup[] = [
  { groupName: 'Event', items: eventPlaceholder },
  { groupName: 'Location', items: locationPlaceholder },
  { groupName: 'Kunde', items: customerPlaceholder },
  { groupName: 'Dokument', items: documentPlaceholder },
];

export const placeholderGroupsWithDocNum: PlaceholderLabelsGroup[] = [
  { groupName: 'Event', items: eventPlaceholder },
  { groupName: 'Location', items: locationPlaceholder },
  { groupName: 'Kunde', items: customerPlaceholder },
  { groupName: 'Dokument', items: documentPlaceholderWithNum },
];

export const fetchCustomPlaceholders = async (): Promise<PlaceholderLabelsGroup[]> => {
  const groups: PlaceholderLabelsGroup[] = [];

  const resp = await fetchAllCustomAttributes();

  if (!resp.ok) return groups;

  const attributes = await resp.json();

  attributes.forEach((a: any) => {
    const groupName = a.groupName.replace(/ /g, '_');
    const name = a.name.replace(/ /g, '_');
    const existingGroup = groups.filter((g) => g.groupName === a.groupName);

    if (existingGroup.length > 0) {
      existingGroup[0].items.push({
        text: a.name,
        placeholder: `${groupName}:${name}`,
      });
    } else {
      groups.push({
        groupName: a.groupName,
        items: [
          {
            text: a.name,
            placeholder: `${groupName}:${name}`,
          },
        ],
      });
    }
  });

  return groups;
};


export const getPredefinedPlaceholders = (
  includeEvent = true, 
  includeLocation = true, 
  includeCustomer = true,
  includeDocument = false,
  includeContract = false
) => {
  const result = [];

  if (includeEvent) {
    result.push({ groupName: 'Event', items: eventPlaceholder });
  }

  if (includeLocation) {
    result.push({ groupName: 'Location', items: locationPlaceholder });
  }

  if (includeCustomer) {
    result.push({ groupName: 'Kunde', items: customerPlaceholder });
  }

  if (includeDocument) {
    result.push({ groupName: 'Dokument', items: includeContract ? documentNumPlaceholder : documentPlaceholder });
  }
  return result;
};



export const getAllPredefinedPlaceholders = (props: PlaceholderDataSource) => {
  const result = [];

  const { customer, _document, contract, event, eventLocation } = props;

  if (event) {
    result.push({ groupName: 'Event', items: eventPlaceholder });
  }

  if (eventLocation) {
    result.push({ groupName: 'Location', items: locationPlaceholder });
  }

  if (customer) {
    result.push({ groupName: 'Kunde', items: customerPlaceholder });
  }

  if (_document) {
    result.push({ groupName: 'Dokument', items: contract ? documentNumPlaceholder : documentPlaceholder });
  }
  return result;
};
