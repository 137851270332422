import { TransTextInputField } from '../../../Atoms/InputFields';
import EditableDateInput from '../../../Atoms/inputs/EditableDateInput';
import { Text } from '@mantine/core';
import { ValidationS } from '../../../Service/ValidationS';
import { i18n } from '@lingui/core';

export const ShareFlow1 = ({ name, setName, password, setPassword, sharedUntil, setSharedUntil }) => {
  return (
    <div>
      {/* <ContactSelect selectedContactId={entityId} contactSelected={setEntityId}
                            placeholder={"Kontakt auswählen"}/> */}

      <TransTextInputField
        value={name}
        saveValue={() => {}}
        onChange={(e) => {
          setName(e.target.value);
        }}
        placeholder="Name des Zugangs"
      />

      <TransTextInputField
        value={password}
        saveValue={() => {}}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        name="password"
        placeholder="Passwort (zur Weitergabe an Deinen Kunden)"
      />
      {!ValidationS.validatePortalPassword(password) && (
        <Text mt={-10} mb="xs" color="red" size="xs">
          {i18n._('error.input-field.invalid-portal-password-char')}
        </Text>
      )}
      <EditableDateInput
        finishCallback={(p) => {
          setSharedUntil(p.sharedUntil);
          return Promise.resolve();
        }}
        value={sharedUntil}
        patchField="sharedUntil"
        labelStyle={{ color: '#9ca6af', fontWeight: 'unset' }}
        placeholder="Freigabe beenden am (optional)"
      />
    </div>
  );
};
