enum BookingState {
  /* Vorgemerkt */
  EARMARKED = 'EARMARKED',

  /**
   * Neue Anfrage. Ggf. schon darauf reagiert, aber noch kein Angebot abgegeben.
   */
  OPEN = 'OPEN',

  /**
   * Angebot verschickt.
   */
  OFFERED = 'OFFERED',

  /**
   * Gebucht für dieses Event.
   */
  BOOKED = 'BOOKED',

  /**
   * Nicht mehr verfügbar.
   */
  UNAVAILABLE = 'UNAVAILABLE',

  /**
   * Stoniertes Event.
   */
  CANCELED = 'CANCELED',

  /* Weiterempfohlen */
  RECOMMENDED = 'RECOMMENDED',

  /* Bezahlen */
  PAYMENT = 'PAYMENT',

  /* Abgeschlossen */
  COMPLETED = 'COMPLETED',
}

export const BookingStatesAll = [
  BookingState.BOOKED,
  BookingState.CANCELED,
  BookingState.COMPLETED,
  BookingState.EARMARKED,
  BookingState.OFFERED,
  BookingState.OPEN,
  BookingState.PAYMENT,
  BookingState.RECOMMENDED,
  BookingState.UNAVAILABLE,
];

export const getMantineColor = (state: BookingState): string => {
  switch (state) {
    case BookingState.BOOKED:
      return 'green';
    case BookingState.CANCELED:
      return 'red';
    case BookingState.OFFERED:
      return 'orange';
    case BookingState.OPEN:
      return 'blue';
    case BookingState.UNAVAILABLE:
      return 'grey';
    default:
      return 'grey';
  }
};

export const getBookingStateColor = (state: BookingState) => {
  switch (state) {
    case BookingState.BOOKED:
      return '#4EBF46';
    case BookingState.CANCELED:
      return '#f44336';
    // case BookingState.COMPLETED:
    //   return 'green';
    // case BookingState.EARMARKED:
    //   return 'green';
    case BookingState.OFFERED:
      return '#FDA632';
    case BookingState.OPEN:
      return '#1D5191';
    // case BookingState.PAYMENT:
    //   return 'green';
    // case BookingState.RECOMMENDED:
    //   return 'green';
    case BookingState.UNAVAILABLE:
      return '#757575';
    default:
      return '#969699';
  }
};

export default BookingState;
