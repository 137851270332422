import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { FormGroup, Label } from 'reactstrap';
import { Trans } from '@lingui/react';
import { InputHelp } from './inputs/InputHelp';

export const LocationSearchInput = ({
  finishCallback,
  style,
  patchField,
  value: initialValue,
  placeholder,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  function findType(addressComponents, type) {
    let found;
    addressComponents.forEach((item) => {
      if (!found) {
        const optional = item.types.find((t) => t === type);
        if (optional !== undefined) {
          found = item.long_name;
        }
      }
    });
    return found;
  }

  function handleSelect(address) {
    setLoading(true);
    const addressComponents = address.split(',');
    const locationName = addressComponents[0];
    geocodeByAddress(address)
      .then((results) => results[0])
      .then((result) => {
        const street = findType(result.address_components, 'route');
        const streetNo = findType(result.address_components, 'street_number');
        const zipCode = findType(result.address_components, 'postal_code');
        const city = findType(result.address_components, 'locality');
        let streetAndNo = street || '';
        if (streetNo) {
          streetAndNo += ` ${streetNo}`;
        }
        const patch = {
          placeId: result.place_id,
          name: locationName,
          streetAndNo: streetAndNo ?? '',
          zipCode: zipCode ?? '',
          city: city ?? '',
        };
        const fakeElt = document.createElement('div');
        const service = new google.maps.places.PlacesService(fakeElt);

        service.getDetails(
          {
            placeId: result.place_id,
          },
          (place, status) => callback(place, status, patch),
        );
      })
      .catch((error) => {
        console.error('could not load location');
        console.error({ error });
      });
  }

  function callback(place, status, patch) {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      patch.phoneNumber = place.formatted_phone_number ?? '';
      patch.website = place.website ?? '';
      finishCallback(patch)
        .catch((error) => setError(error.message))
        .then(() => setLoading(false));
    }
  }

  // select suggestion
  function onClick(suggestion) {}

  const styles = {
    error: {
      borderColor: 'red',
    },
    input: {
      marginBottom: 0,
    },
    label: {
      color: '#8ca3b5',
      fontWeight: 800,
    },
    inputContainer: {
      marginBottom: 9,
    },
  };

  const classNames = loading ? 'location-search-input form-control loading' : 'location-search-input form-control';
  return (
    <div style={styles.inputContainer}>
      <Label sm={12} style={styles.label}>
        <Trans id={placeholder} />
      </Label>
      <PlacesAutocomplete
        name={patchField}
        value={value}
        onChange={setValue}
        onSelect={(val) => {
          handleSelect(val);
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <FormGroup>
            <input
              style={error ? { ...styles.error, ...styles.input } : styles.input}
              {...getInputProps({
                className: classNames,
                onBlur: () => {
                  setLoading(true);
                  finishCallback({ [patchField]: value })
                    .catch((error) => setError(error.message))
                    .then(() => setLoading(false));
                },
                onKeyUp: (e) => {
                  switch (e.key) {
                    case 'Enter':
                      finishCallback({ [patchField]: value })
                        .catch((error) => setError(error.message))
                        .then(() => setLoading(false));
                      break;
                    case 'Escape':
                      finishCallback({ [patchField]: initialValue })
                        .catch((error) => setError(error.message))
                        .then(() => setLoading(false));
                  }
                },
              })}
            />
            {loading && <div>Laden...</div>}

            {suggestions.length > 0 && (
              <ul className="suggestions">
                {suggestions.map((suggestion, index) => {
                  const className = suggestion.active ? 'suggestion-active' : '';

                  return (
                    <li
                      key={index}
                      onMouseDown={() => {
                        onClick(suggestion);
                      }}
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      {suggestion.description}
                    </li>
                  );
                })}
              </ul>
            )}
          </FormGroup>
        )}
      </PlacesAutocomplete>
      {error ? <InputHelp errorDescription={error} /> : null}
    </div>
  );
};
