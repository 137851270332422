import { FC, useEffect, useState } from 'react';
import { IconFileDescription, IconTemplate } from '@tabler/icons';
import { Checkbox, Loader, Select } from '@mantine/core';
import { CreateContractConfigDTO, WorkflowConfigProps } from '../../../../../Types/LogT';
import { fetchContractTemplates } from '../../../../../Service/restapi/contractService';
import { WorkflowS } from '../../../../../Service/WorkflowS';
import { DataS } from '../../../../../Service/DataS';

const CreateContractConfig: FC<WorkflowConfigProps> = ({ config, handleChange, availableEntities }) => {
  const { templateId, offerReference } = config as CreateContractConfigDTO;
  const [attachDocument, setAttachDocument] = useState(offerReference !== null);
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState<any[]>([]);
  const availableDocuments = WorkflowS.filterEntities(availableEntities, ['documents']);

  useEffect(() => {
    setLoading(true);
    fetchContractTemplates()
      .then((res) => {
        if (res.ok) {
          res.json().then(setTemplates);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <Select
        required
        value={templateId}
        icon={<IconTemplate size={20} />}
        rightSection={loading && <Loader size="xs" />}
        disabled={loading}
        label="Vertragsvorlage"
        data={DataS.getContractTemplatesOptions(templates)}
        onChange={(id) => handleChange({ templateId: id })}
      />
      <Checkbox
        size="xs"
        mt="xs"
        defaultChecked={attachDocument}
        label="Angebot beifügen"
        onChange={(e) => {
          const { checked } = e.currentTarget;
          setAttachDocument(checked);
          if (!checked && offerReference) {
            handleChange({ offerReferenceId: null });
          }
        }}
      />
      {attachDocument && (
        <Select
          mt="sm"
          defaultValue={offerReference ? offerReference.id : null}
          icon={<IconFileDescription size={20} />}
          rightSection={loading && <Loader size="xs" />}
          disabled={loading}
          label="Angebot"
          data={DataS.getEntityReferencesOptions(availableDocuments)}
          onChange={(val) => val && handleChange({ offerReferenceId: val })}
        />
      )}
    </>
  );
};

export default CreateContractConfig;
