// eslint-disable-next-line import/prefer-default-export
export const ajaxActions = {
  getResourceAsJson,
  getResponse,
  getBlobResponse,
  post,
  patch,
  del,
  exchange,
  get,
  put,
  putFile,
  uploadFile,
  uploadPutFile
};

async function getResourceAsJson(url: string | URL): Promise<unknown> {
  const resp = await getResponse(url);
  try {
    const value = await resp?.json();
    return value;
  } catch (e) {
    console.error('Failed to read json: ', e);
    throw e;
  }
}

function getJson(resp: Response): unknown {
  return resp
    .json()
    .then((value) => value)
    .catch((value) => console.error('Failed to read json: ', value));
}

async function getBlobResponse(url: string | URL): Promise<Response> {
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
  })
    .then((resp) => {
      if (resp.ok) {
        return resp;
      }
      console.error(`Response not OK (url: ${url})`);
      throw resp;
    })
    .catch((resp) => {
      console.error(`Failed to get data at ${url}`);
      throw resp;
    });
}

async function getResponse(url: string | URL): Promise<Response> {
  return fetch(url, {
    method: 'GET',
    headers: { Accept: 'application/json' },
    credentials: 'include',
  })
    .then((resp) => {
      if (resp.ok) {
        return resp;
      }
      console.error(`Response not OK (url: ${url})`);
      return Promise.reject();
    })
    .catch((reason) => {
      console.error(`Failed to get data at ${url} because of a network error`);
      throw reason;
    });
}

async function get(url: string | URL, reqConfig?: RequestInit): Promise<Response> {
  return fetch(url, {
    method: 'GET',
    headers: { Accept: 'application/json' },
    credentials: 'include',
    ...reqConfig,
  })
    .then((resp) => resp)
    .catch((resp) => {
      console.log(resp);
      throw resp;
    });
}

async function post(url: string | URL, data?: unknown, reqConfig?: RequestInit): Promise<Response> {
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(data),
    ...reqConfig,
  })
    .then((resp) => resp)
    .catch((resp) => {
      console.error(`Failed to sent data to ${url}`);
      throw resp;
    });
}

async function exchange(method: string, url: string | URL, data: unknown): Promise<Response> {
  return fetch(url, {
    method,
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((resp) => resp)
    .catch((resp) => {
      console.error(`Failed to sent data to ${url}`);
      throw resp;
    });
}

async function put(url: string | URL, data?: unknown): Promise<Response> {
  return fetch(url, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((resp) => resp)
    .catch((resp) => {
      console.error(`Failed to sent data to ${url}`);
      throw resp;
    });
}

async function uploadFile(url: string | URL, file: File): Promise<Response> {
  const data = new FormData();
  data.append('file', file);
  return fetch(url, {
    method: 'POST',
    credentials: 'include',
    body: data,
  })
    .then((resp) => resp)
    .catch((resp) => {
      console.error(`Failed to sent data to ${url}`);
      throw resp;
    });
}

async function uploadPutFile(url: string | URL, file: File): Promise<Response> {
  const data = new FormData();
  data.append('file', file);
  return fetch(url, {
    method: 'PUT',
    credentials: 'include',
    body: data,
  })
    .then((resp) => resp)
    .catch((resp) => {
      console.error(`Failed to sent data to ${url}`);
      throw resp;
    });
}

async function putFile(url: string, file: File) {
  let data = new FormData();
  data.append('file', file);

  return fetch(url, {
    method: 'PUT',
    credentials: 'include',
    body: data,
  })
    .then((resp) => resp)
    .catch((err) => {
      throw err;
    });
}

async function patch(url: string | URL, data: unknown): Promise<Response> {
  return fetch(url, {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    credentials: 'include',
    body: JSON.stringify(data),
  })
    .then((resp) => resp)
    .catch((resp) => {
      console.error(`Failed to sent data to ${url} because of a network error`);
      throw resp;
    });
}

async function del(url: string | URL, data?: unknown): Promise<Response> {
  return fetch(url, {
    method: 'DELETE',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    credentials: 'include',
    body: data ? JSON.stringify(data) : undefined,
  })
    .then((resp) => {
      if (!resp.ok) {
        console.error(`Response not OK (url: ${url})`);
      }
      return resp;
    })
    .catch((reason) => {
      console.error(`Failed to delete data at ${url} because of a network error`);
      throw reason;
    });
}
