/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { useElementSize } from '@mantine/hooks';
import { FC, useContext } from 'react';
import BookitupTable from '../../Atoms/BookitupTable';
import DocumentListItem from '../../Molecules/Event/Documents/DocumentListItem';
import PanePaper from '../../Molecules/PanePaper';
import { DocumentsContext } from '../../Service/Context/DocumentsContext';
import { TestS } from '../../TestS';
import BookitupDocument from '../../Types/BookitupDocument';
import SortDirectionIcon from '../../Atoms/SortDirectionIcon';

const DocumentList: FC = () => {
  const { ref, width } = useElementSize();
  const { documents, reload, filter, changeFilter } = useContext(DocumentsContext);
  const { sortBy, direction } = filter;
  const sortingByDate = sortBy === 'date';
  const sortingByNumber = sortBy === 'number';

  const toggleSort = (field: string) => {
    if (field === sortBy) {
      changeFilter({ direction: direction === 'ASC' ? 'DESC' : 'ASC' });
    } else {
      changeFilter({ sortBy: field, direction: 'DESC' });
    }
  };

  const showColumns = {
    showNumber: width > 750,
    showDatum: width > 820,
    showName: width > 1040,
    showDueDate: width > 670,
    showPortal: true,
    narrowEventStatus: false,
    narrowPortalStatus: width < 580,
    showSum: width > 520,
    hideEventDate: width < 410,
  };

  return (
    <div ref={ref} data-testid={TestS.Navigate.Documents.LIST}>
      <PanePaper>
        <BookitupTable>
          <thead>
            <tr>
              <th style={{ width: 30 }}>Status</th>
              {showColumns.showNumber && (
                <th
                  style={{ width: 100, fontWeight: sortingByNumber ? 'bolder' : 'normal', cursor: 'pointer' }}
                  onClick={() => toggleSort('number')}
                >
                  Nummer{'  '}
                  {sortingByNumber && <SortDirectionIcon direction={direction} />}
                </th>
              )}
              {showColumns.showName && <th>Name </th>}
              {showColumns.showDatum && (
                <th
                  style={{ width: 100, fontWeight: sortingByDate ? 'bolder' : 'normal', cursor: 'pointer' }}
                  onClick={() => toggleSort('date')}
                >
                  Datum{'  '}
                  {sortingByDate && <SortDirectionIcon direction={direction} />}
                </th>
              )}
              {showColumns.showSum && <th style={{ width: 100 }}>Betrag</th>}
              {showColumns.showDueDate && <th style={{ width: 100 }}>Frist</th>}
              <th style={{ width: showColumns.narrowEventStatus ? 50 : showColumns.hideEventDate ? 200 : 225 }}>
                Event
              </th>
              <th style={{ width: showColumns.narrowPortalStatus ? 50 : 120 }}>Portal</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc: BookitupDocument) => (
              <DocumentListItem withEventStatus key={doc.id} bookitupDocument={doc} reload={reload} {...showColumns} />
            ))}
          </tbody>
        </BookitupTable>
      </PanePaper>
    </div>
  );
};

export default DocumentList;
