import React, { useContext, useEffect, useState } from 'react';
import { getContactById } from '../../../Service/restapi/contactService';

const RecommendationPersonDescription = ({ contactId, alias, style }) => {
  const [contact, setContact] = useState(undefined);

  useEffect(() => {
    fetchContact();
  }, [contactId]);

  function fetchContact() {
    getContactById(contactId).then((resp) => {
      if (resp.ok) {
        resp.json().then((contact) => {
          setContact(contact);
        });
      }
    });
  }

  if (!contact) {
    return null;
  }

  return (
    <div style={{ marginRight: 10, flex: 1, ...style }}>
      <div style={{ maxWidth: 225, display: 'flex' }}>
        <div style={{ fontWeight: 500, fontSize: 11 }} className="overflow-ellipsis">
          {alias ||
            (contact.firstName || contact.lastName
              ? `${contact.firstName ? contact.firstName : ''} ${contact.lastName ? contact.lastName : ''}`
              : contact.email)}
        </div>
        <div style={{ marginLeft: 5, fontSize: 9, alignSelf: 'center' }} className="overflow-ellipsis">
          {contact.companyName}
        </div>
      </div>
    </div>
  );
};

export default RecommendationPersonDescription;
