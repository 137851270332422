const USER = {
    INTERACTIVE: {
        USERNAME: 'cypress@bookitup.de',
        PASSWORD: 'bookitUP123!'
    }
}

const Navigate = {
  TO_EVENTS: 'navigate-to-events',
  TO_TASKS: 'navigate-to-tasks',
  TO_MAILS: 'navigate-to-mails',
  TO_CONTACTS: 'navigate-to-contacts',
  TO_DOCUMENTS: 'navigate-to-documents',
  TO_STATISTICS: 'navigate-to-statistics',
  TO_SETTINGS: 'navigate-to-settings',
  TO_RESOURCES: 'navigate-to-resources',
  SETTINGS_TO_USER_DATA: 'settings-to-user-data',
  SETTINGS_TO_DOCUMENTS: 'settings-to-documents',
  SETTINGS_TO_DOCUMENT_LAYOUT: 'documents-to-document-layout',
  SETTINGS_TO_TAXES: 'documents-to-taxes',

  Add: {
    EVENT: "add-event"
  },

  Events: {
    LIST: "event-list",
  },

  Documents: {
    LIST: "document-list",
    TO_PREVIEW: "to-preview",
    TO_EDIT: "to-edit",
    TO_DOCUMENT_LIST: "to-document-list"
  }
};

const Login = {
  INPUT_FIELD_USER_MAIL: 'user-email',
  INPUT_FIELD_USER_PASSWORD: 'user-password',
  BUTTON_SUBMIT_LOGIN: 'login-submit',
};

const Taxes = {
  TOGGLE_SMALL_SCALE_BUSINESS: 'toggle-small-scale-business',
}

const DocumentLayout = {
  TOGGLE_SHOW_FOUNDER_PERSONAL_NAME: 'toggle-show-founder-personal-name',
  TOGGLE_HIDE_BANK_ACCOUNT: 'toggle-hide-bank-account',
  TOGGLE_SHOW_BORDERS: 'toggle-show-borders',
  TOGGLE_POSITION_NUMBERS: 'toggle-show-position-numbers',
  TOGGLE_TRANSPARENT_BACKGROUND: 'toggle-transparent-background',

  TOGGLE_LOGO_ONLY_FOR_ONLINE_PORTAL: 'toggle-logo-only-for-online-portal',
  CONTROL_LOGO_SIZE: 'control-logo-size',
  SLIDER_LOGO_POSITION: 'slider-logo-position',

  PICK_BACKGROUND_COLOR: 'pick-background-color',
  PICK_FONT_COLOR: 'pick-font-color',
  CONTROL_PAGE_NUMBER_POSITION: 'page-number-position',
  CONTROL_CONTACT_NAME_PREFERENCE: 'control-contact-name-preference',
  CONTROL_INFO_BOX_PLACEMENT: 'control-info-box-placement',
  SELECT_FONT: 'page-number-position',
}

const Common = {
  SELECT_CUSTOMER: "select-customer",

  ConfirmDialog: {
    YES: "confirm-dialog-yes",
    CANCEL: "confirm-dialog-cancel"
  }
}

const Documents = {
  CREATE_DOCUMENT: "create-document",
  SELECT_TEMPLATE: "select-document-template",

  ManagePayments : {
    ADD: "add-payment",
    AMOUNT_INPUT: "payment-amount-input",
    DATE_INPUT: "payment-date-input"
  },

  Create: {
    SAVE: "create-document-modal-save"
  },

  Edit: {
    SUM: "document-sum",
    FINAL_SUM: "document-final-sum",
    TAX: "document-tax",
    FINAL_TAX: "document-final-tax",
    PARTIAL_INVOICE_NET: "partial-invoice-net",
    PARTIAL_INVOICE_TAX: "partial-invoice-tax",
    PARTIAL_INVOICE_GROSS: "partial-invoice-gross",
    DISCOUNT_VALUE: "document-discount-value",
    DISCOUNT_TYPE: "document-discount-type",

    Position: {
      DISCOUNT_VALUE: "position-discount-value",
      DISCOUNT_TYPE: "position-discount-type"
    }
  },

  List: {
    Dropdown: {
      OPEN: "open-document-dropdown",
      NON_DRAFT: "non-draft-document",
      ACCEPT: "accept-document",
      MANAGE_PAYMENTS: "manage-payments",
      CREATE_CONFIRMATION: "create-confirmation",
      CREATE_PARTIAL_INVOICE: "create-partial-invoice",
      CREATE_INVOICE: "create-invoice",
      CREATE_FINAL_INVOICE: "create-final-invoice",
      CREATE_CANCEL: "create-cancel",
      DOWNLOAD: "download-document",
      DUPLICATE: "duplicate-document",
      RENAME: "rename-document",
      DELETE: "delete-document",
    }
  }
}

const Events = {
  Create: {
    DATE_PICKER: "event-date-picker",
    CONTACT_SELECT: "event-contact-select",
    SAVE: "create-event-modal-save",
    CANCEL: "create-event-modal-cancel"
  },

  Details: {
    DATE_FROM: "event-date-from",
    DATE_TO: "event-date-to",
  },

  Delete: {
    DELETE: "delete-event",
    CHECKBOX_LOSE_DOCUMENTS_EMAILS: "confirm-to-lose-documents-emails",
    CONFIRM: "confirm-delete-event"
  },

  Navigate: {
    TO_DOCUMENTS: "to-event-documents"
  },

  Add: {
    CREATE_DOCUMENT: "create-event-document"
  }
}

export const TestS = {
    USER,
    Taxes,
    Common,
    Documents,
    DocumentLayout,
    Navigate,
    Events,
    Login
}
