// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Checkbox, Group, NumberInput, Select, Text } from '@mantine/core';
import { FC } from 'react';
import { IconFileDescription } from '@tabler/icons';
import { CreateDocumentConfigDTO, WorkflowConfigProps } from '../../../../../Types/LogT';
import { DataS } from '../../../../../Service/DataS';
import DocumentTemplateSelect from '../../../../../Atoms/inputs/DocumentTemplateSelect';
import { WorkflowS } from '../../../../../Service/WorkflowS';
import BookitupSegmentedControl from '../../../../../Atoms/BookitupSegmentedControl';
import EntityInputTooltipIcon from '../../../../../Atoms/workflows/EntityInputTooltipIcon';

const CreateDocumentConfig: FC<WorkflowConfigProps> = ({ config, handleChange, availableEntities }) => {
  const { documentType, originDocument, partialAmount, partialAmountUnit, templateName, finalInvoice } =
    config as CreateDocumentConfigDTO;
  const availableDocuments = WorkflowS.filterEntities(availableEntities, ['documents']);
  const isPartialInvoice = documentType === 'PARTIAL_INVOICE';

  return (
    <>
      <Select
        required
        mb="xs"
        icon={<IconFileDescription size={20} />}
        label="Dokumentenart"
        data={DataS.documentTypeOptions}
        defaultValue={documentType}
        onChange={(val) => val && handleChange({ documentType: val })}
      />
      <DocumentTemplateSelect
        required
        defaultValue={templateName}
        documentType={documentType}
        onChange={(val) => val && handleChange({ templateName: val })}
      />
      <Select
        clearable
        mt="sm"
        defaultValue={originDocument ? originDocument.id : null}
        label={
          <Text>
            Ursprungsdokument{'  '}
            <EntityInputTooltipIcon />
          </Text>
        }
        data={DataS.getEntityReferencesOptions(availableDocuments)}
        onChange={(val) => handleChange({ originDocumentReferenceId: val })}
      />
      {isPartialInvoice && originDocument && (
        <Group position="apart" mt="sm">
          <Text mr="sm">Prozentsatz der Anzahlung: </Text>
          <Group spacing={5}>
            <NumberInput
              hideControls
              defaultValue={partialAmount}
              min={0}
              onBlur={(e) => handleChange({ partialAmount: e.currentTarget.value })}
              sx={{ width: 55 }}
            />
            <BookitupSegmentedControl
              data={DataS.paymentUnitOptions}
              defaultValue={partialAmountUnit}
              onChange={(val) => val && handleChange({ partialAmountUnit: val })}
            />
          </Group>
        </Group>
      )}
      {documentType === 'INVOICE' && originDocument && (
        <Checkbox
          mt="sm"
          size="xs"
          defaultChecked={finalInvoice}
          onChange={(e) => handleChange({ finalInvoice: e.currentTarget.checked })}
          label="Als Schlussrechnung erstellen"
        />
      )}
    </>
  );
};

export default CreateDocumentConfig;
