import React, { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { TransButtonLink } from '../../../Atoms/Buttons';
import { useModalState } from '../../../Molecules/Dialogs';
import { LoadingAnimation } from '../../../Service/ResourceAdapter';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import QuestionnaireTemplateNameModal from '../../../Organismns/Settings/Documents/QuestionnaireTemplates/QuestionnaireTemplateNameModal';
import {
  createQuestionnaireTemplateByName,
  fetchQuestionnaireTemplates,
} from '../../../Service/restapi/questionnaireService';
import { QuestionnaireTemplateListActions } from '../../../Molecules/Settings/QuestionnaireTemplate/QuestionnaireTemplateListActions';
import PanePaper from '../../../Molecules/PanePaper';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import { RoutingS } from '../../../Service/RoutingS';
import { Text } from '@mantine/core';
import { MixpanelS } from '../../../Service/MixpanelS';
import { ConstantS } from '../../../Service/ConstantS';

export default function () {
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const { profile } = useContext(ProfileContext);
  const { show, toggle } = useModalState();
  const [templates, setTemplates] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    RoutingS.changeTitle('Fragebogen-Vorlagen');
    loadQuestionnaireTemplates();
  }, []);

  function loadQuestionnaireTemplates() {
    fetchQuestionnaireTemplates()
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((templates) => {
            setTemplates(templates);
          });
        } else {
          console.error('could not load questionnaireTemplates');
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not load questionnaireTemplates');
      });
  }

  function create(name) {
    createQuestionnaireTemplateByName(name)
      .then((resp) => {
        if (resp.ok) {
          MixpanelS.track(ConstantS.TrackingEvents.QuestionnaireTemplateCreated);
          navigate(`/settings/template/questionnaire/${name}`);
        } else {
          console.error(`could not create questionnaire template ${name}`);
        }
      })
      .catch((error) => {
        console.error(`could not create questionnaire template ${name}`);
        console.error({ error });
      });
  }

  const styles = {
    actions: {
      marginBottom: 5,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    list: {
      backgroundColor: 'white',
      borderRadius: 5,
      paddingTop: 5,
      paddingBottom: 5,
    },
    itemContainer: {
      marginBottom: 5,
      display: 'flex',
    },
  };

  const items = [];
  if (!templates) {
    return <LoadingAnimation />;
  }

  templates.forEach((value, index) => {
    items.push(
      <div style={styles.itemContainer}>
        <div key={index} style={{ flex: 1, maxWidth: '100%' }}>
          <div style={{ display: 'flex' }}>
            <TransButtonLink
              className="overflow-ellipsis"
              style={{ fontSize: '1rem', color: '#000000', height: 25 }}
              onClick={() => navigate(`/settings/template/questionnaire/${value.name}`)}
              text={value.name}
            />
            <QuestionnaireTemplateListActions
              templateName={value.name}
              id={value.id}
              reloadDocuments={loadQuestionnaireTemplates}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {value.lastModified && (
              <span style={{ fontSize: '0.75rem' }}>
                Bearbeitet <Moment fromNow>{value.lastModified}</Moment>
              </span>
            )}
          </div>
        </div>
      </div>,
    );
  });
  return (
    <PanePaper
      title="questionnaire.templates"
      tooltip="help.settings.questionnaire.templates.pane"
      rightSection={
        <BookitupAddIcon onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.FRAGEBOGEN_USAGE, toggle)} />
      }
      sx={{ maxWidth: 500 }}
    >
      {templates.length === 0 && (
        <Text italic color="dimmed" size="xs" weight="lighter">
          Noch nichts hinzugefügt.
        </Text>
      )}
      <div style={styles.container}>
        <div style={styles.list}>{items}</div>
        <QuestionnaireTemplateNameModal show={show} toggle={toggle} finish={create} />
      </div>
    </PanePaper>
  );
}
