import React, { FC, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { useLingui } from '@lingui/react';
import { Placement } from 'react-bootstrap/esm/types';

type Props = {
  target: string | HTMLElement | React.RefObject<HTMLElement>;
  text: string;
  placement?: Placement;
};

export const ToolTip: FC<Props> = ({ target, text, placement }) => {
  const [show, setShow] = useState(false);

  const actualPlacement = placement ?? 'top';

  const toggle = () => {
    setShow(!show);
  };

  return (
    <Tooltip placement={actualPlacement} isOpen={show} target={target} toggle={toggle}>
      {text}
    </Tooltip>
  );
};

export const TransToolTip: FC<Props> = ({ target, text, placement }) => {
  const { i18n } = useLingui();

  return <ToolTip target={target} text={i18n._(text || '')} placement={placement} />;
};
