import React, { useContext, useEffect, useState } from 'react';
import { EditableDocumentProperty } from '../../../Atoms/EditableProperties/EditableDocumentProperty';
import { SimpleEditableText } from '../../../Atoms/inputs/SimpleEditableText';
import { StringFormatter } from '../../../Atoms/Formatter/StringFormatter';
import { getNextSequentialNumber, patchDocument } from '../../../Service/restapi/documentService';
import { LoadingAnimation } from '../../../Service/ResourceAdapter';
import { TransButtonLink } from '../../../Atoms/Buttons';
import { patchContract } from '../../../Service/restapi/contractService';
import { EditableContractProperty } from '../../../Atoms/EditableProperties/EditableContractProperty';

export default function ({ handleChange, documentType, number, entityId, lastModified }) {
  const [nextNumber, setNextNumber] = useState(undefined);
  useEffect(() => {
    if (entityId) {
      getNextSequentialNumber(`${documentType}_NUMBER`, entityId).then((resp) => {
        if (resp.ok) {
          resp.json().then((number) => {
            setNextNumber(number.nextNumber);
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    if ((documentType === 'INVOICE' || documentType === 'CANCEL') && !number) {
      saveNumber();
    }
  }, [number, documentType]);

  function saveNumber() {
    let patchMethod = patchDocument;
    if (documentType === 'CONTRACT') {
      patchMethod = patchContract;
    }
    patchMethod(entityId, { number: nextNumber }).then((resp) => {
      if (resp.ok) {
        handleChange({ number: nextNumber });
      }
    });
  }

  let EditableProperty = EditableDocumentProperty;
  if (documentType === 'CONTRACT') {
    EditableProperty = EditableContractProperty;
  }

  if (!nextNumber) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <EditableProperty
        patchField="number"
        changeHandler={handleChange}
        entity={{ number, id: entityId, lastModified }}
        placeholder="document.no"
        editFormatter={SimpleEditableText}
        labelFormatter={StringFormatter}
        style={{ height: 'unset' }}
      />
      {!number && <TransButtonLink text="Nummer erstellen" id="contactNew" onClick={saveNumber} />}
    </>
  );
}
