import { FC } from 'react';
import { ThemeIcon } from '@mantine/core';
import { IconCheck, IconX} from '@tabler/icons';
import { EventPlanningGroup } from '../Types/ResourceT';

const ResourceGroupPlanningIcon: FC<{ group: EventPlanningGroup }> = ({ group }) => {
  const { showResourcePlanningShortage, showWarehousePlanningShortage, planningsCount, warehousePlanningsCount } =
    group;

  if (warehousePlanningsCount > 0) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {showWarehousePlanningShortage ? (
            <ThemeIcon radius="xl" size="sm" color="#FF5C5A">
              <IconX size={14} />
            </ThemeIcon>
        ) : (
          <ThemeIcon radius="xl" size="sm" color="green">
            <IconCheck size={14} />
          </ThemeIcon>
        )}
      </>
    );
  }

  return (
    <ThemeIcon radius="xl" size="sm" color={showResourcePlanningShortage ? '#FFD600' : 'gray'}>
      {showResourcePlanningShortage && <IconX color={"#4b525b"} size={14}/>}
      {!showResourcePlanningShortage && <IconCheck color={"black"} size={14}/>}
    </ThemeIcon>
  );
};

export default ResourceGroupPlanningIcon;
