/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Box, Button, Center, Group, LoadingOverlay, Text, TextInput } from '@mantine/core';
import { FC, useState } from 'react';
import { useForm } from '@mantine/form';
import { i18n } from '@lingui/core';
import { closeModal, openModal } from '@mantine/modals';
import { IconAlertCircle, IconAt, IconPhone, IconPhonePlus } from '@tabler/icons';
import { isEmpty } from '../../Utils/utils';
import ContactGroupSelection from '../../Molecules/Contact/ContactGroupSelection';
import { ValidationS } from '../../Service/ValidationS';
import { ContactS, Contact } from '../../Service/ContactS';
import { ConstantS } from '../../Service/ConstantS';

export const openEditContactModal = (contact: Contact, onChange: (contact: Contact) => void) => {
  openModal({
    modalId: ConstantS.Modals.ContactPerson,
    title: (
      <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
        Kontakt bearbeiten
      </Text>
    ),
    closeOnEscape: true,
    transition: 'slide-down',
    children: <ContactPersonModal contact={contact} onFinish={onChange} />,
  });
};

interface Props {
  contact: Contact;
  onFinish?: (contact: Contact) => void;
}

const ContactPersonModal: FC<Props> = ({ contact, onFinish }) => {
  const form = useForm({
    initialValues: { ...contact, minimalInfoMissing: '' },
    validate: {
      firstName: (value) => checkAwsSpecialChars(value),
      lastName: (value) => checkAwsSpecialChars(value),
      minimalInfoMissing: (value, values) => {
        const { firstName, lastName, email } = values;
        return isEmpty(firstName) && isEmpty(lastName) && isEmpty(email);
      },
    },
  });
  const [loading, setLoading] = useState(false);
  const { minimalInfoMissing } = form.errors;

  const checkAwsSpecialChars = (value: string) =>
    ValidationS.validAwsObjectKeyName(value) ? null : i18n._('error.input-field.invalid-aws-char');

  const onSubmit = async () => {
    setLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-unused-vars
    const { minimalInfoMissing, ...rest } = form.values;
    const updatedContact = await ContactS.saveOrUpdate(rest);
    setLoading(false);
    if (onFinish) {
      onFinish(updatedContact);
    }
    close();
  };

  const close = () => closeModal(ConstantS.Modals.ContactPerson);

  return (
    <Box sx={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <form onSubmit={form.onSubmit(onSubmit)}>
        {minimalInfoMissing && (
          <Alert
            mt="sm"
            mb="sm"
            icon={<IconAlertCircle size="1rem" />}
            title="Fehlende Daten"
            color="red"
            variant="filled"
          >
            Bitte gib einen Namen oder E-Mail-Adresse an.
          </Alert>
        )}
        <Group grow spacing="xs" sx={{ alignItems: 'flex-start' }}>
          <TextInput label="Anrede" mt="sm" {...form.getInputProps('salutation')} />
          <TextInput mt="sm" label="Vorname" {...form.getInputProps('firstName')} />
          <TextInput mt="sm" label="Nachname" {...form.getInputProps('lastName')} />
        </Group>
        <Group grow mt="sm">
          <TextInput icon={<IconPhone size={16} />} label="Telefonnummer" {...form.getInputProps('phoneNumber')} />
          <TextInput icon={<IconAt size={16} />} label="E-Mail-Adresse" {...form.getInputProps('email')} />
        </Group>
        <TextInput
          mt="sm"
          mb="sm"
          icon={<IconPhonePlus size={16} />}
          label="Telefonnummer (alternativ)"
          {...form.getInputProps('phoneNumberAlt')}
        />
        <ContactGroupSelection
          selectedGroups={form.values.groups}
          saveValue={(p: []) => form.setValues((prev) => ({ ...prev, groups: p }))}
        />
        <Center>
          <Button fullWidth mt="xl" mb="md" type="submit" disabled={loading} sx={{ maxWidth: 250 }}>
            {i18n._('actions.save')}
          </Button>
        </Center>
      </form>
    </Box>
  );
};

export default ContactPersonModal;
