/* eslint-disable react/jsx-props-no-spreading */
import { SegmentedControl, createStyles, SegmentedControlProps } from '@mantine/core';
import { FC, useContext } from 'react';
import { WindowContext } from '../Service/Context/WindowContext';

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
  },
  label: {
    color: 'black',
    marginBottom: 0,
  },
  controlActive: {
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    padding: 0,
  },
}));

interface Props extends SegmentedControlProps {
  autoSwitch?: boolean;
  testId?: string;
}

const BookitupSegmentedControl: FC<Props> = ({ autoSwitch, testId, ...rest }) => {
  const { sm } = useContext(WindowContext);
  const { classes } = useStyles();
  return (
    <SegmentedControl
      orientation={autoSwitch && sm ? 'vertical' : 'horizontal'}
      color="blue"
      radius="md"
      classNames={classes}
      className={classes.root}
      {...rest}
      data-testid={testId}
    />
  );
};

export default BookitupSegmentedControl;
