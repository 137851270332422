import { useEffect, useState } from 'react';
import { EditableEmailProperty } from '../Atoms/EditableProperties/EditableEmailProperty';
import { EditableCheckbox } from '../Atoms/inputs/EditableCheckbox';
import { fetchDocumentById } from '../Service/restapi/documentService';
import { LoadingAnimation } from '../Service/ResourceAdapter';
import { TransButtonDefaultWithSpinner } from '../Atoms/Buttons';
import { fetchContractById } from '../Service/restapi/contractService';
import PdfPreview from './Document/PdfPreview';
import { fetchQuestionnaireById } from '../Service/restapi/questionnaireService';

const CheckDocumentPlaceholderWrapper = ({
  eventId,
  attachment,
  addAttachment,
  changeMessage,
  email,
  reload,
  index,
}) => {
  const [loading, setLoading] = useState(undefined);
  const [document, setDocument] = useState(undefined);

  useEffect(() => {
    fetchDocumentOrContract();
  }, [attachment]);

  function fetchDocumentOrContract() {
    let fctCall = fetchDocumentById;
    if (attachment.entityType === 'contracts') {
      fctCall = fetchContractById;
    }
    if (attachment.entityType === 'questionnaires') {
      fctCall = fetchQuestionnaireById;
    }
    fctCall(attachment.entityId).then((resp) => {
      if (resp.ok) {
        resp.json().then(setDocument);
      }
    });
  }

  if (!document) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        padding: '20px 0px',
        backgroundColor: index % 2 ? 'rgb(251, 251, 251)' : 'unset',
      }}
    >
      {loading && <LoadingAnimation style={{ width: 20, height: 20 }} />}

      <div style={{ flex: 1, marginLeft: 10, alignSelf: 'center' }}>
        <EditableEmailProperty
          patchField="selected"
          style={{ marginBottom: 0 }}
          preChange={(p) => addAttachment(attachment)}
          changeHandler={changeMessage}
          email={email}
          placeholder={attachment.fileName}
          editOnly
          editFormatter={EditableCheckbox}
        />
      </div>
      <PdfPreview eventId={eventId} file={attachment} button={TransButtonDefaultWithSpinner} />
    </div>
  );
};

export default CheckDocumentPlaceholderWrapper;
