import { useEffect, useState } from 'react';
import { fetchEmailsHead } from '../restapi/messageService';

export const usePostboxCounter = (box: string, trigger: number, filter?: string, contactIds?: number[]) => {
  const [count, setCount] = useState<number | null>();

  useEffect(() => {
    fetchEmailsHead(box, filter, null, contactIds)
      .then((resp) => {
        const total = resp.headers.get('X-Total-Count');
        if (total) {
          setCount(parseInt(total, 10));
        }
      })
      .catch(() => setCount(null));
  }, [trigger]);

  return count;
};
