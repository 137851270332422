// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Button, Text } from '@mantine/core';
import { IconCalendarTime, IconInfoCircle } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { RoutingS } from '../../../Service/RoutingS';

const BookitupFormsAlert = () => {
  const navigate = useNavigate();
  return (
    <Alert
      title="Kontaktformulare"
      sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })}
      icon={<IconInfoCircle />}
    >
      <Text mb="sm">
        Um ein Kontaktformular deiner Webseite mit bookitup zu verbinden, kannst du unter{' '}
        <Text
          span
          weight="bolder"
          color="blue"
          variant="link"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate('/settings/contact-form')}
        >
          Kontaktformulare
        </Text>{' '}
        mit wenigen klicks ein Formular erstellen und in Deine Webseite einbetten.
      </Text>
      <Text>
        Alternativ kannst Du hier auch das Kontaktformular anbinden, welches Du bereits auf Deiner Webseite hast. Dazu
        buche Dir einfach einen Einrichtungstermin.
      </Text>
      <Button
        size="xs"
        leftIcon={<IconCalendarTime size={16} />}
        mt="md"
        onClick={() => RoutingS.openInNewTab('https://hey.bookitup.de/meetings/sebastian-mahlke/technik-support ')}
      >
        Termin vereinbaren
      </Button>
    </Alert>
  );
};

export default BookitupFormsAlert;
