import { Stack } from '@mantine/core';
import { useEffect } from 'react';
import { RoutingS } from '../../../Service/RoutingS';
import RecruitmentCampaignList from './RecuitmentCampaignList';
import RecruitmentCampaignExplained from './RecruitmentCampaignExplained';

const RecruitmentSection = () => {
  useEffect(() => {
    RoutingS.changeTitle('Freunde werben');
  }, []);

  return (
    <Stack sx={{ maxWidth: 850 }}>
      <RecruitmentCampaignExplained />
      <RecruitmentCampaignList />
    </Stack>
  );
};

export default RecruitmentSection;
