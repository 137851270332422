import React, { useContext, useEffect } from 'react';
import { withI18n } from '@lingui/react';
import { useNavigate } from 'react-router-dom';
import HeadBodySkeleton from '../../../Templates/HeadBodySkeleton';
import { Pane } from '../../Pane';
import { preChange } from '../../../Utils/utils';
import { ContractContext } from '../../../Service/Context/ContractContext';
import ContractTemplateSkeleton from '../../../Organismns/Settings/Documents/ContractTemplates/ContractTemplateSkeleton';
import {
  addAboveTextBlockOnContractTemplate,
  addBelowTextBlockOnContractTemplate,
  patchContractTemplate,
} from '../../../Service/restapi/contractTemplateService';
import { EditableSelectField } from '../../../Atoms/inputs/EditableSelectField';
import { EditableContractTemplateProperty } from '../../../Atoms/EditableProperties/EditableContractTemplateProperty';
import { TransButtonLinkWithIcon } from '../../../Atoms/Buttons';
import ContractTemplateNameModal from '../../../Organismns/Settings/Documents/ContractTemplates/ContractTemplateNameModal';
import { useModalState } from '../../Dialogs';
import { WindowContext } from '../../../Service/Context/WindowContext';
import ContractTemplateHead from './ContractTemplateHead';
import { Box, Group, Stack } from '@mantine/core';
import ContractTemplateActions from './ContractTemplateActions';
import PanePaper from '../../PanePaper';
import { MixpanelS } from '../../../Service/MixpanelS';
import { ConstantS } from '../../../Service/ConstantS';

export default withI18n()(({ i18n, history }) => {
  const { contract, handleChange } = useContext(ContractContext);
  const { show, toggle } = useModalState();
  const { windowWidth } = useContext(WindowContext);
  const navigate = useNavigate();

  useEffect(() => {
    MixpanelS.track(ConstantS.TrackingEvents.ContractTemplateOpened);
  }, []);

  const styles = {
    container: {
      backgroundColor: 'white',
      paddingLeft: 5,
      paddingRight: 5,
    },
    name: {
      marginLeft: 10,
      fontSize: 20,
      marginBottom: 20,
      display: 'flex',
    },
    contractDesign: {
      flex: 1,
      display: 'flex',
      flexWrap: 'wrap',
      padding: 5,
    },
    contractHead: {
      flex: 1,
      maxWidth: 200,
      minWidth: 200,
      margin: 5,
    },
    contractFooter: {
      flex: 1,
      maxWidth: 200,
      minWidth: 200,
      margin: 5,
    },
    contractSignType: {
      flex: 1,
      maxWidth: 300,
      minWidth: 300,
      margin: 5,
    },
  };

  function addAboveTextBlock(textBlockId) {
    addAboveTextBlockOnContractTemplate(contract.name, textBlockId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((newContact) => {
            handleChange(newContact);
          });
        } else {
          console.error(`could not add textBlock to contractTemplate${contract.name}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not add textBlock to contractTemplate${contract.name}`);
      });
  }

  function addBelowTextBlock(textBlockId) {
    addBelowTextBlockOnContractTemplate(contract.name, textBlockId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((newContact) => {
            handleChange(newContact);
          });
        } else {
          console.error(`could not add textBlock to contractTemplate${contract.name}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not add textBlock to contractTemplate${contract.name}`);
      });
  }

  function changeName(newName) {
    patchContractTemplate(contract.id, { name: newName })
      .then((resp) => {
        if (resp.ok) {
          navigate(`/settings/template/contract/${newName}`);
        } else {
          console.error(`could not save template ${contract.name} under new name ${newName}`);
        }
      })
      .catch(() => {
        console.error(`could not save template ${contract.name} under new name ${newName}`);
      });
  }

  const contractHeadOptions = [
    { label: i18n._('contract.head.parties'), value: 'PARTIES' },
    { label: i18n._('contract.head.letterhead'), value: 'LETTERHEAD' },
    { label: i18n._('contract.head.none'), value: 'NONE' },
  ];

  const contractFooterOptions = [
    { label: i18n._('contract.footer.centered'), value: 'CENTERED' },
    { label: i18n._('contract.footer.blocks'), value: 'BLOCKS' },
    { label: i18n._('contract.footer.none'), value: 'NONE' },
  ];

  const contractSignOptions = [
    { label: i18n._('contract.sign.both'), value: 'BOTH' },
    { label: i18n._('contract.sign.none'), value: 'NONE' },
  ];

  const customerSignTypeOptions = [
    { label: i18n._('contract.sign.type.base64'), value: 'BASE64' },
    { label: i18n._('contract.sign.type.textField'), value: 'TEXT_FIELD' },
  ];
  return (
    <PanePaper
      rightSection={
        <Box mt={20}>
          <ContractTemplateActions />
        </Box>
      }
    >
      <div style={styles.name}>
        {contract.name}
        <div style={styles.child}>
          <TransButtonLinkWithIcon
            icon="EDIT"
            className="primary-link"
            onClick={toggle}
            text={windowWidth > 800 ? 'Namen ändern' : ''}
          />
        </div>
        <ContractTemplateNameModal show={show} toggle={toggle} finish={changeName} />
      </div>
      <Stack>
        <PanePaper title="Layout">
          <div style={styles.contractDesign}>
            <div style={styles.contractHead}>
              <EditableContractTemplateProperty
                patchField="contractHead"
                changeHandler={handleChange}
                options={contractHeadOptions}
                entity={contract}
                placeholder="contract.head"
                editFormatter={EditableSelectField}
                editOnly
              />
            </div>
            <div style={styles.contractFooter}>
              <EditableContractTemplateProperty
                patchField="contractFooter"
                changeHandler={handleChange}
                options={contractFooterOptions}
                entity={contract}
                placeholder="contract.footer"
                editFormatter={EditableSelectField}
                editOnly
              />
            </div>
            <div style={styles.contractFooter}>
              <EditableContractTemplateProperty
                patchField="contractSign"
                changeHandler={handleChange}
                options={contractSignOptions}
                entity={contract}
                placeholder="contract.sign"
                editFormatter={EditableSelectField}
                editOnly
              />
            </div>
            <div style={styles.contractSignType}>
              <EditableContractTemplateProperty
                patchField="customerSignType"
                changeHandler={handleChange}
                options={customerSignTypeOptions}
                entity={contract}
                placeholder="contract.sign.type"
                editFormatter={EditableSelectField}
                editOnly
              />
            </div>
          </div>
        </PanePaper>

        <PanePaper title="Inhalt">
          <div style={styles.container}>
            <ContractTemplateSkeleton
              addAboveTextBlock={addAboveTextBlock}
              addBelowTextBlock={addBelowTextBlock}
              preChange={(p) => preChange(p, i18n)}
            />
          </div>
        </PanePaper>
      </Stack>
    </PanePaper>
  );
});
