import { FC } from 'react';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { Center } from '@mantine/core';
import { useSearchParams } from 'react-router-dom';
import { AuthS } from '../../Service/restapi/authService';
import { AlertS } from '../../Service/AlertS';

const GoogleAuthButton: FC<{
  setAlert: (alert: string) => void;
  setLoading: (loading: boolean) => void;
  text?: 'signin_with' | 'signup_with' | 'continue_with' | 'signin';
  recruitmentCampaignId?: string;
  campaignId?: string;
}> = ({ setAlert, setLoading, text, recruitmentCampaignId, campaignId }) => {
  const [searchParams] = useSearchParams();
  if (!process.env.REACT_APP_GOOGLE_CLIENT_ID) {
    return null;
  }
  const signIn = (credentialResponse: CredentialResponse) => {
    const { credential } = credentialResponse;
    if (credential) {
      setLoading(true);
      const loxLaunch = searchParams.get('loxLaunch') !== null;
      AuthS.signupWithGoogle({ credential, recruitmentCampaignId, campaignId, expandedTrial: loxLaunch })
        .then((resp) => {
          if (!resp.ok) {
            setAlert(AlertS.AlertType.UNEXPECTED_ERROR);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <Center>
      <GoogleLogin text={text} onSuccess={signIn} onError={() => setAlert(AlertS.AlertType.UNEXPECTED_ERROR)} />
    </Center>
  );
};

export default GoogleAuthButton;
