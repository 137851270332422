import { useContext, useEffect, useState } from 'react';
import { EventContext } from '../../Service/Context/EventContext';
import { fetchDocumentsByEvent, getUnresolvablePlaceholders } from '../../Service/restapi/documentService';
import { UnresolvableInfoBox } from '../MailTemplates/UnresolvableInfoBox';
import { fetchContractsByEvent } from '../../Service/restapi/contractService';
import AttachmentListFileItem from './AttachmentListFileItem';
import { i18n } from '@lingui/core';
import { Space, Stack, Text } from '@mantine/core';
import { IconPaperclip } from '@tabler/icons';
import EmptyText from '../../Atoms/EmptyText';

export default ({ documentRequests, documentBlueprint, onDocumentRequestSelected, setDisableApply }) => {
  const { event } = useContext(EventContext);

  const [selectedDocumentRequest, setSelectedDocumentRequest] = useState(undefined);
  const [hasUnresolvedPlaceholders, setHasUnresolvedPlaceholders] = useState(false);
  const [selectedDocumentTemplate, setSelectedDocumentTemplate] = useState(undefined);

  const [newDocument, setNewDocument] = useState(false);

  const [existingDocuments, setExistingDocuments] = useState(undefined);

  useEffect(() => {
    if (documentBlueprint.documentType !== 'CONTRACT') {
      fetchDocumentsByEvent(event.id)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((documents) => {
              const tmpDocs = [];
              documents.forEach((d) => {
                if (d.documentType !== documentBlueprint.documentType) {
                  tmpDocs.push(d);
                }
              });
              setExistingDocuments(tmpDocs);
            });
          } else {
            console.error(`could not load documents of event ${event.id}`);
          }
        })
        .catch((error) => {
          console.error(`could not load documents of event ${event.id}`);
          console.error({ error });
        });
    } else {
      fetchContractsByEvent(event.id)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((documents) => {
              const tmpDocs = [];
              documents.forEach((d) => {
                if (d.documentType !== documentBlueprint.documentType) {
                  tmpDocs.push(d);
                }
              });
              setExistingDocuments(tmpDocs);
            });
          } else {
            console.error(`could not load contracts of event ${event.id}`);
          }
        })
        .catch((error) => {
          console.error(`could not load contracts of event ${event.id}`);
          console.error({ error });
        });
    }
  }, []);

  if (!documentRequests || !documentBlueprint) {
    console.error('documentRequests', documentRequests);
    console.error('documentBlueprint', documentBlueprint);
    throw new Error('documentRequests or documentBlueprint was null');
  }

  function unselectDocumentRequest() {
    setSelectedDocumentRequest(undefined);
    onDocumentRequestSelected(undefined);
    setHasUnresolvedPlaceholders(false);
  }

  function documentRequestSelected(dR) {
    setSelectedDocumentRequest(dR);
    onDocumentRequestSelected(dR);
    setHasUnresolvedPlaceholders(false);

    if (!dR || dR.documentId === undefined) {
      setDisableApply(false);
    } else {
      setDisableApply(true);

      if (dR.documentId) {
        getUnresolvablePlaceholders(dR.documentType === 'CONTRACT' ? 'contracts' : 'documents', dR.documentId)
          .then((unresolvablePlaceholder) => {
            if (unresolvablePlaceholder.length > 0) {
              setHasUnresolvedPlaceholders(true);
            } else {
              setDisableApply(false);
            }
          })
          .catch((error) => {
            console.error(`could not get unresolvable placeholders of document ${dR.documentId}`);
            console.error({ error });
          });
      }
    }
  }

  const filteredDR = documentRequests
    ? documentRequests.filter((dR) => dR.documentType === documentBlueprint.documentType && dR.documentId)
    : [];

  return (
    <>
      {!newDocument && (
        <>
          {documentRequests && documentRequests.length > 0 && (
            <Text color="blue" mb="sm" weight="bolder">
              <IconPaperclip size={16} style={{ marginRight: 5 }} />
              {i18n._(`document.${documentBlueprint.documentType.toLowerCase()}`)}
            </Text>
          )}
          {hasUnresolvedPlaceholders && (
            <>
              <UnresolvableInfoBox
                documentType={documentBlueprint.documentType}
                entityId={selectedDocumentRequest.documentId}
                selectedTemplate={selectedDocumentTemplate}
                eventId={event.id}
                customerId={event.customerId}
              />
              <Space h="xs" />
            </>
          )}
          <Stack spacing="xs">
            {filteredDR.length > 0 ? (
              filteredDR.map((dR, index) => (
                <AttachmentListFileItem
                  selected={selectedDocumentRequest === dR}
                  onClick={(item) => {
                    selectedDocumentRequest === dR ? unselectDocumentRequest() : documentRequestSelected(item);
                  }}
                  key={index}
                  item={dR}
                />
              ))
            ) : (
              <EmptyText />
            )}
          </Stack>
        </>
      )}
    </>
  );
};
