import React, { useState } from 'react';
import { ajaxActions } from '../AjaxActions';
import { fetchEventParticipations } from '../restapi/eventService';
import { CustomAttributesContextProvider } from './CustomAttributeContext';
import { ToastS } from '../ToastS';
import { PostboxCountersProvider } from './PostboxCountersContext';

export const EventParticipationsContext = React.createContext(undefined);

export const EventParticipationsContextProvider = ({ event, participations, children }) => {
  const [currentParticipations, setParticipations] = useState(participations);

  const mainCustomer = currentParticipations.find((p) => p.eventRole === 'MAIN_CUSTOMER');
  return (
    <EventParticipationsContext.Provider
      value={{
        participations: currentParticipations,
        mainCustomerId: mainCustomer ? mainCustomer.contactId : undefined,
        changeParticipation: (index, participationKey) => {
          const eventParticipations = Object.assign([], currentParticipations);
          eventParticipations[index] = {
            ...eventParticipations[index],
            ...participationKey,
          };
          setParticipations((prevState) => eventParticipations);
        },
        addParticipation: (eventParticipationKey) => {
          eventParticipationKey.eventId = event.id;
          if (!eventParticipationKey.contactId) {
            throw new Error('ContactId undefined!');
          }
          ajaxActions
            .getResourceAsJson(`${process.env.REACT_APP_AUTH_SERVICE_URL}/contacts/${eventParticipationKey.contactId}`)
            .then((contactDto) => {
              const eventParticipation = {
                contact: contactDto,
                eventRole: eventParticipationKey.eventRole,
                eventId: event.id,
              };
              let eventParticipations = Object.assign([], currentParticipations);

              if (!event.id) {
                eventParticipation.eventRole = 'MAIN_CUSTOMER';
                eventParticipations = [eventParticipation];
              } else {
                eventParticipations.push(eventParticipation);
              }
              setParticipations((prevState) => eventParticipations);
            });
        },
        removeParticipation: (index) => {
          const eventParticipations = Object.assign([], currentParticipations);
          eventParticipations.splice(index, 1);
          setParticipations((prevState) => eventParticipations);
        },
        saveParticipant: (eventParticipationKey) => {
          if (!eventParticipationKey.contactId) {
            throw new Error('Contact not saved!');
          }
          eventParticipationKey.eventId = event.id;
          return ajaxActions
            .exchange('POST', `${process.env.REACT_APP_EVENT_SERVICE_URL}/eventParticipations`, eventParticipationKey)
            .then((resp) => {
              if (resp.ok) {
                ToastS.success('persist.event.participation', 'Kontakt erfolgreich am Event gespeichert');
                fetchEventParticipations(event.id).then((resp) => {
                  resp.json().then((eventParticipations) => {
                    setParticipations((prevState) => eventParticipations);
                  });
                });
                return resp;
              }
              ToastS.error('persist.event.participation', 'Kontakt konnte nicht zum Event gespeichert werden');
              console.error('could not save participant by key ', eventParticipationKey);
              return resp;
            })
            .catch(() => {
              throw new Error('Contact could not be saved as participant');
            });
        },
        refreshParticipations: () => {
          fetchEventParticipations(event.id).then((resp) => {
            resp.json().then((eventParticipations) => {
              setParticipations((prevState) => eventParticipations);
            });
          });
        },
      }}
    >
      <CustomAttributesContextProvider entityType="event" entity={event}>
        <PostboxCountersProvider contactsIds={currentParticipations.map((p) => p.contactId)}>
          {children}
        </PostboxCountersProvider>
      </CustomAttributesContextProvider>
    </EventParticipationsContext.Provider>
  );
};
