import { i18n } from '@lingui/core';
import { Box, Button, Center, Loader, TextInput } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import { IconTag } from '@tabler/icons';
import { FC, useState } from 'react';
import { ResourceCalendar } from '../../../Types/CalDavT';
import { CalDavS } from '../../../Service/CalDavS';

interface Props {
  calendar: ResourceCalendar;
  editCalendar: (calendar: ResourceCalendar) => void;
}

const EditCalendarModal: FC<Props> = ({ calendar, editCalendar }) => {
  const { id, label: initialLabel } = calendar;
  const [label, setLabel] = useState(initialLabel);
  const [loading, setLoading] = useState(false);

  const onConfirm = () => {
    setLoading(true);
    CalDavS.editCalendar(id, { label })
      .then((_calendar) => {
        if (_calendar) {
          editCalendar(_calendar);
          closeAllModals();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <TextInput
        defaultValue={initialLabel}
        mt="xl"
        mb="sm"
        label="Beschriftung"
        icon={<IconTag size={16} />}
        disabled={loading}
        onChange={(e) => setLabel(e.currentTarget.value)}
      />

      <Center>
        <Box sx={{ width: '70%' }}>
          <Button
            fullWidth
            mt="xl"
            mb="xs"
            onClick={onConfirm}
            disabled={loading}
            leftIcon={loading && <Loader size="xs" />}
          >
            {i18n._('actions.save')}
          </Button>
          <Button fullWidth disabled={loading} color="gray" variant="subtle" onClick={() => closeAllModals()}>
            {i18n._('actions.cancel')}
          </Button>
        </Box>
      </Center>
    </>
  );
};

export default EditCalendarModal;
