// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Space, Stack, Text } from '@mantine/core';
import { FC } from 'react';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';

const ContactFormsNotFound: FC<{ message: string, onClick?: () => void; }> = ({ message, onClick }) => (
  <Stack>
    <Box sx={(theme) => ({ width: '100%', height: 50, backgroundColor: theme.colors.gray[0], borderRadius: 10 })} />
    <Box sx={(theme) => ({ width: '100%', height: 100, backgroundColor: theme.colors.gray[0], borderRadius: 10 })} />
    <Text sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
      {`${message} Klicke auf`}
      <Space w={5} />
      <BookitupAddIcon onClick={onClick} />
      <Space w={2} />, um zu beginnen.
    </Text>
  </Stack>
);

export default ContactFormsNotFound;
