/* eslint-disable no-nested-ternary */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import {Button, Center, Group, List, Space, Text} from '@mantine/core';
import { i18n } from '@lingui/core';
import { closeAllModals } from '@mantine/modals';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutingS } from '../Service/RoutingS';
import { LicenceRestriction } from '../Service/LicenceS';

const LicenceModal: FC<LicenceRestriction> = ({ title, description, requiredLicence, addons }) => {
  const navigate = useNavigate();
  const showPlans = () => {
    navigate(RoutingS.ROUTES.PLANS);
    closeAllModals();
  };
  return (
    <>
      <Text weight="bolder" mt="sm">
        {' '}
        {title}
      </Text>
      <Space h={5} />
      <Text size="xs">{description}</Text>
      <Space h="md" />
      <Text sx={{marginBottom: 10}}>
        {`Um diese Funktion nutzen zu können, benötigst Du:`}
      </Text>
        <List spacing="xs">
            <List.Item key={-1}>
                <Group position="apart">
                    {i18n._(`licence.${requiredLicence}`)}
                    <Text size="xs" color="dimmed">{`als Grundpaket`}</Text>
                </Group>
            </List.Item>

        {addons && addons.map((addon, index) => {
            return <List.Item key={index}>
                <Group position="apart">
                    {addon}
                    <Text size="xs" color="dimmed">{`als Addon`}</Text>
                </Group>
            </List.Item>
        })}
        </List>
        <Text sx={{marginTop: 10}}>
            {`Führe jetzt ein Upgrade durch - die Funktion wird sofort freigeschaltet.`}
        </Text>
      <Center>
        <Button mt="xl" onClick={showPlans} sx={{ width: 250 }}>
          Zu den Paketen
        </Button>
      </Center>
    </>
  );
};

export default LicenceModal;
