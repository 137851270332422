// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { List, Space, Text, ThemeIcon } from '@mantine/core';
import { IconCash, IconExchange, IconPhoneOutgoing, IconThumbUp } from '@tabler/icons';
import PanePaper from '../../../Molecules/PanePaper';

const BuyPlanContact = () => (
  <PanePaper withShadow withoutBorder>
    <List center spacing="xs" size="sm">
      <List.Item
        icon={
          <ThemeIcon size={24} radius="xl">
            <IconThumbUp size={16} />
          </ThemeIcon>
        }
      >
        30 Tage kostenfreie Testversion
      </List.Item>
      <List.Item
        sx={{ alignContent: 'center' }}
        icon={
          <ThemeIcon size={24} radius="xl">
            <IconExchange size={16} />
          </ThemeIcon>
        }
      >
        Plan jederzeit wechseln oder kündigen
      </List.Item>
      <List.Item
        icon={
          <ThemeIcon size={24} radius="xl">
            <IconCash size={16} />
          </ThemeIcon>
        }
      >
        100 Tage Geld-zurück-Garantie
      </List.Item>
    </List>
    <Space h="md" />
    <Text weight="bolder">Noch unschlüssig?</Text>
    <List center spacing="xs" size="sm" mt="xs" mb="xs">
      <List.Item
        icon={
          <ThemeIcon size={24} radius="xl">
            <IconPhoneOutgoing size={16} />
          </ThemeIcon>
        }
      >
        <a style={{ color: '#8CA3B5', textDecoration: 'underline' }} href="tel:+49 221 37050094">
          +49 221 37050094
        </a>
      </List.Item>
    </List>
    <Text size="xs" color="dimmed">
      Mo - Fr von 8:00 bis 17:00 Uhr
    </Text>
  </PanePaper>
);

export default BuyPlanContact;
