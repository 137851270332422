import React, { useContext, useEffect, useState } from 'react';
import { ajaxActions } from '../AjaxActions';
import ApplicationUserData from '../../Molecules/Settings';
import { AuthS } from '../restapi/authService';
import { AuthContext } from './AuthContext';

export const BankAccountContext = React.createContext(undefined);

export const BankAccountContextProvider = () => {
  const { jwtClaims } = useContext(AuthContext);
  const [bankAccount, setBankAccount] = useState();
  const { profileId } = jwtClaims;

  useEffect(() => {
    if (profileId) {
      AuthS.getBankAccount(profileId).then((resp) => {
        if (resp.ok) {
          resp.json().then(setBankAccount);
        }
      });
    }
  }, [profileId]);

  return (
    <BankAccountContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        bankAccount,
        handleBankAccountChange: (patch) => {
          setBankAccount((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
        saveBankAccountChange: (patch) => {
          ajaxActions
            .patch(`${process.env.REACT_APP_AUTH_SERVICE_URL}/profiles/${profileId}/bankAccount`, patch)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((bankAccount) => {
                  setBankAccount((prevState) => ({
                    ...prevState,
                    ...bankAccount,
                  }));
                });
              }
            });
        },
      }}
    >
      <ApplicationUserData />
    </BankAccountContext.Provider>
  );
};
