import React, { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { addAnswer, delAnswer, fetchAdditionalAnswer, fetchAnswers } from '../../Service/restapi/questionnaireService';
import { TransButtonLink } from '../../Atoms/Buttons';
import AnswerItem from './AnswerItem';
import { Stack } from '@mantine/core';
import { QuestionnaireS } from '../../Service/QuestionnaireS';

export default function ({ questionId, attributeType, event, eventLocation, customer }) {
  const [answers, setAnswers] = useState(undefined);
  const [additionalAnswer, setAdditionalAnswer] = useState(undefined);

  useEffect(() => {
    loadAnswers();
  }, [questionId, attributeType]);

  function loadAnswers() {
    fetchAnswers(questionId).then((resp) => {
      if (resp.ok) {
        resp.json().then((answers) => {
          setAnswers(answers);
        });
      }
    });
    fetchAdditionalAnswer(questionId).then((resp) => {
      if (resp.ok) {
        resp.json().then(setAdditionalAnswer);
      }
    });
  }

  function addAnswerOption(additionalAnswer) {
    const answerId = uuidv4();
    addAnswer(answerId, questionId, additionalAnswer).then((resp) => {
      if (resp.ok) {
        loadAnswers();
      }
    });
  }

  function delAnswerOption(answerId) {
    delAnswer(answerId, questionId)
      .then((resp) => {
        if (resp.ok) {
          const oldVal = answers.find((a) => a.id === answerId);
          if (oldVal) {
            const indexOfVal = answers.indexOf(oldVal);
            const newAnswers = Object.assign([], answers);
            newAnswers.splice(indexOfVal, 1);
            setAnswers(newAnswers);
          } else if (additionalAnswer.id === answerId) {
            setAdditionalAnswer(undefined);
          }
        } else {
          console.error(`could not delete answer ${answerId}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not delete answer ${answerId}`);
      });
  }

  function handleChange(patch) {
    const oldVal = answers.find((a) => a.id === patch.id);
    const indexOfVal = answers.indexOf(oldVal);
    const newAnswers = Object.assign([], answers);
    newAnswers.splice(indexOfVal, 1, patch);
    setAnswers(newAnswers);
  }

  const moveAnswer = (from, to) => {
    QuestionnaireS.moveAnswer(questionId, from, to).then((resJSON) => {
      if (resJSON) {
        setAnswers(resJSON);
      }
    });
  };

  if (!answers) {
    return null;
  }

  if (attributeType !== 'SINGLE_CHOICE' && attributeType !== 'MULTIPLE_CHOICE') {
    return null;
  }

  const options = [];
  answers.forEach((answer) => {
    options.push({
      label: '',
      checked: answer.enabled,
      disabled: true,
      onClick: () => {
        handleChange({ ...answer, enabled: true });
      },
    });
  });

  return (
    <div style={{ marginTop: 20 }}>
      <Stack spacing="xs">
        {answers.map((answer, index) => (
          <AnswerItem
            index={index}
            answer={answer}
            attributeType={attributeType}
            delAnswerOption={delAnswerOption}
            options={options}
            handleChange={handleChange}
            event={event}
            eventLocation={eventLocation}
            moveAnswer={moveAnswer}
            customer={customer}
          />
        ))}

        {additionalAnswer && (
          <AnswerItem
            withoutDrag
            answer={additionalAnswer}
            attributeType={attributeType}
            delAnswerOption={delAnswerOption}
            options={options}
            handleChange={handleChange}
            additionalAnswer
            event={event}
            eventLocation={eventLocation}
            customer={customer}
          />
        )}
      </Stack>
      <TransButtonLink onClick={() => addAnswerOption()} text="common.add.answer" />
      {!additionalAnswer && (
        <TransButtonLink
          style={{ marginLeft: 10 }}
          onClick={() => addAnswerOption(true)}
          text="common.add.answer.additional"
        />
      )}
    </div>
  );
}
