import React, { useContext, useEffect, useState } from 'react';
import { deleteCalendarAccess } from '../../../Service/restapi/calendarAccountService';
import { SimpleEditableText } from '../../../Atoms/inputs/SimpleEditableText';
import { StringFormatter } from '../../../Atoms/Formatter/StringFormatter';
import { EditableCalendarAccessProperty } from '../../../Atoms/EditableProperties/EditableCalendarAccessProperty';
import { getContactById } from '../../../Service/restapi/contactService';
import { LoadingAnimation } from '../../../Service/ResourceAdapter';
import TooltipButton from '../../../Atoms/buttons/TooltipButton';
import { ConfirmDialog, useConfirmModalState } from '../../../Molecules/Dialogs';
import { EditableCalendarShareProperty } from '../../../Atoms/EditableProperties/EditableCalendarShareProperty';
import { EditableCheckbox } from '../../../Atoms/inputs/EditableCheckbox';

export default function ({ calendarAccess: initialCalendarAccess, index, reloadCalendarAccesses }) {
  const [calendarAccess, setCalendarAccess] = useState(initialCalendarAccess);
  const [contact, setContact] = useState();
  const { showConfirm, toggleConfirm } = useConfirmModalState();

  useEffect(() => {
    if (calendarAccess.foreignContactId) {
      getContactById(calendarAccess.foreignContactId)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then(setContact);
          } else {
            console.error(`could not load contact ${calendarAccess.foreignContactId}`);
          }
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not load contact ${calendarAccess.foreignContactId}`);
        });
    }
  }, [calendarAccess.foreignContactId]);

  useEffect(() => {
    setCalendarAccess(initialCalendarAccess);
  }, [initialCalendarAccess]);

  function delCalendarAccess() {
    deleteCalendarAccess(calendarAccess.id)
      .then((resp) => {
        if (resp.ok) {
          reloadCalendarAccesses();
        } else {
          console.error(`could not delete calendarAccess ${calendarAccess.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not delete calendarAccess ${calendarAccess.id}`);
      });
  }

  if (!contact) {
    return <LoadingAnimation />;
  }

  const styles = {
    container: {
      flex: 1,
      display: 'flex',
      padding: 10,
      backgroundColor: index % 2 ? '#fbfbfb' : 'unset',
    },
  };

  console.log({ calendarAccess });
  return (
    <div style={styles.container}>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: 5, marginTop: 12, alignSelf: 'center' }}>
          <EditableCalendarAccessProperty
            entity={calendarAccess}
            patchField="enabled"
            changeHandler={(p) => {
              setCalendarAccess(p);
            }}
            placeholder=""
            editFormatter={EditableCheckbox}
            editOnly
          />
        </div>
        <div style={{ marginRight: 10, marginTop: 25, width: 300, alignSelf: 'center' }}>
          {contact.personDescription}
        </div>
        <div style={{ width: 300 }}>
          <EditableCalendarAccessProperty
            entity={calendarAccess}
            patchField="calendarShareId"
            changeHandler={(p) => {
              setCalendarAccess(p);
            }}
            placeholder="Schlüssel des Kollegen"
            editFormatter={SimpleEditableText}
            labelFormatter={StringFormatter}
          />
        </div>
      </div>
      <div style={{ marginLeft: 10, marginBottom: 6, alignSelf: 'flex-end' }}>
        <TooltipButton
          icon="TRASH"
          isLoading={false}
          id={`delete-calendarAccess-${calendarAccess.id}`}
          style={{ marginRight: 10 }}
          text="Freigabe entfernen"
          onClick={toggleConfirm}
        />

        <ConfirmDialog
          text="settings.calendarAccess.delete.confirm"
          subject="settings.calendarAccess.delete.confirm.head"
          open={showConfirm}
          toggle={toggleConfirm}
          handler={delCalendarAccess}
        />
      </div>
    </div>
  );
}
