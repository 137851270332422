import { FC } from 'react';
import { Group, Popover, Text } from '@mantine/core';
import { IconMoodWink2 } from '@tabler/icons';
import EmojiPicker, { IEmojiData } from 'emoji-picker-react';
import { useDisclosure } from '@mantine/hooks';

interface Props {
  value: string | null;
  onChange: (val: string) => void;
}

const PrimaryEmojiPicker: FC<Props> = ({ value, onChange }) => {
  const [opened, { toggle, close }] = useDisclosure(false);

  const onEmojiClick = (event: React.MouseEvent, data: IEmojiData) => {
    onChange(data.emoji);
    close();
  };

  return (
    <Group spacing="xl">
      <Text>Emoji wählen:</Text>
      <Popover opened={opened} onChange={toggle} returnFocus={true}>
        <Popover.Target>
          <Text
            onClick={toggle}
            size="xl"
            sx={{
              border: '1px solid #E2E2E2',
              borderRadius: 10,
              height: 40,
              width: 40,
              cursor: 'pointer',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {value && value}
            {!value && <IconMoodWink2 />}
          </Text>
        </Popover.Target>
        <Popover.Dropdown>
          <EmojiPicker onEmojiClick={onEmojiClick} />
        </Popover.Dropdown>
      </Popover>
    </Group>
  );
};

export default PrimaryEmojiPicker;
