import { Trans } from '@lingui/react';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalDialog } from '../../Dialogs';
import { patchDocument } from '../../../Service/restapi/documentService';
import { patchContract } from '../../../Service/restapi/contractService';
import { Button, Loader, Text } from '@mantine/core';

export default function ({ documentType, show, toggle, eventId, entityType, entityId, patchEntity }) {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function setAsDraftAndOpen() {
    let fctCall = patchDocument;
    if (entityType === 'contracts') {
      fctCall = patchContract;
    }
    setLoading(true);
    fctCall(entityId, { draft: true })
      .then((resp) => {
        if (resp.ok) {
          navigate(`${eventId ? `/events/${eventId}` : ''}/${entityType}/${entityId}?unlock=true`);
          if (patchEntity) {
            patchEntity({ draft: true });
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const documentSubject = 'Dokument bereits fertig';
  const documentText = (
    <Text color="dimmed">
      Achtung: Dieses Dokument wurde bereits als Fertig markiert. Bist du sicher, dass du dieses Dokument trotzdem
      bearbeiten möchtest?
    </Text>
  );
  const invoiceSubject = <Text color="dimmed">Rechnung nachträglich bearbeiten</Text>;
  const invoiceText = (
    <Text color="dimmed">
      Achtung: Falls dieser Beleg bereits an Ihren Kunden versandt oder weiterverarbeitet wurde, könnten Ihnen
      steuerrechtliche Nachteile entstehen, wenn Sie ihn nachträglich bearbeiten.
      <br />
      <br />
      Falls Sie ein Buchhaltungsprogramm angebunden haben, achten Sie darauf, dass die bereits übertragene Rechnung{' '}
      <u>nicht automatisch gelöscht</u> wird.
      <br />
      <br />
      Stornieren und duplizieren Sie stattdessen diesen Beleg (empfohlen).
    </Text>
  );

  return (
    <ModalDialog
      header={<Trans id={documentType && documentType === 'INVOICE' ? invoiceSubject : documentSubject} />}
      body={
        <>
          {(entityType !== 'documents' || documentType !== 'INVOICE') && documentText}
          {entityType === 'documents' && documentType === 'INVOICE' && invoiceText}
        </>
      }
      footer={
        <>
          <Button onClick={toggle} variant="default">
            {' '}
            Abbrechen
          </Button>
          <Button leftIcon={loading && <Loader size="xs" />} disabled={loading} onClick={setAsDraftAndOpen}>
            Trotzdem bearbeiten
          </Button>
        </>
      }
      show={show}
      toggle={toggle}
    />
  );
}
