/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Text } from '@mantine/core';
import { closeAllModals, closeModal, openModal } from '@mantine/modals';
import { createContext, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import LicenceModal from '../../Atoms/LicenceModal';
import { LicenceRestriction, LicenceS } from '../LicenceS';
import { Violation, ViolationS } from '../ViolationS';
import { openAddonMissingModal } from '../../Organismns/Resources/ResourceModals';
import { useJwtClaims } from './AuthContext';
import { ResourceAddonContext } from './ResourceAddonsContext';
import {ProductFruitsInit} from "./ProductFruits";

interface ViolationContextInterface {
  interceptViolation: (httpResponse: Response) => void;
  openErrorModal: (violation: Violation) => void;
  executeWithLicenceCheck: (licenceRestriction: LicenceRestriction, featureHandler: () => unknown) => void;
  openLicenceModal: (licenceRestriction: LicenceRestriction) => void;
}

export const ViolationContext = createContext<ViolationContextInterface>({} as ViolationContextInterface);

export const ViolationContextProvider = () => {
  const modalId = 'violation-modal';
  const navigate = useNavigate();
  const { isActive: addonActive, openTrialModal, trialAvailable } = useContext(ResourceAddonContext);
  const { licence } = useJwtClaims();

  const executeWithLicenceCheck = (licenceRestriction: LicenceRestriction, featureHandler: () => unknown) => {
    const { requiredLicence, trialForbidden, addons } = licenceRestriction;

    if (!LicenceS.hasSuitableLicence(requiredLicence, licence) || (trialForbidden && LicenceS.inTrialPeriod(licence))) {
      openLicenceModal(licenceRestriction);
      return;
    }
    if (addons && !addonActive) {
      if (trialAvailable) {
        openTrialModal();
        return;
      }

      openAddonMissingModal();
      return;
    }
    featureHandler();
  };

  const openErrorModal = (violation: Violation) => {
    const { title, description, buttonTitle, buttonHref, externalHref } = violation;

    openModal({
      title: (
        <Text size="md" weight="bolder">
          ⚠️&nbsp;&nbsp;{title}
        </Text>
      ),
      closeOnEscape: true,
      closeOnClickOutside: true,
      transition: 'slide-up',
      centered: true,
      children: (
        <>
          <Text> {description} </Text>
          <Button
            mt="md"
            onClick={() => {
              if (externalHref) {
                window.open(buttonHref, '_blank', 'noopener,noreferrer');
              }
              if (buttonHref) {
                navigate(buttonHref);
              }
              closeModal(modalId);
            }}
            sx={{ float: 'right' }}
          >
            {buttonTitle}
          </Button>
        </>
      ),
    });
  };

  const interceptViolation = (httpResponse: Response) => {
    if (httpResponse) {
      httpResponse.json().then((resJSON) => {
        if (resJSON && resJSON.violation) {
          const violation = ViolationS.getViolation(resJSON.violation);
          closeAllModals();
          openErrorModal(violation);
        }
      });
    }
  };
  return (
    <ViolationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ interceptViolation, openErrorModal, executeWithLicenceCheck, openLicenceModal }}
    >
      <ProductFruitsInit />
      <Outlet />
    </ViolationContext.Provider>
  );
};

export const openLicenceModal = (licenceRestriction: LicenceRestriction) => {
  openModal({
    closeOnEscape: true,
    transition: 'slide-down',
    title: (
      <Text weight="bolder" size="lg">
        {`Jetzt upgraden ⬆️`}
      </Text>
    ),
    children: <LicenceModal {...licenceRestriction} />,
  });
};
