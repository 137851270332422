import React, { useContext, useState } from 'react';
import { Trans } from '@lingui/react';
import { useNavigate } from 'react-router-dom';
import { Button, Group, Space } from '@mantine/core';
import TemplatePreview from '../Templates/TemplatePreview';
import { DocumentContext } from '../../../Service/Context/DocumentContext';
import { ModalDialog, useModalState } from '../../Dialogs';
import { savePatch } from '../../../Service/restapi/templateService';
import { TransButtonDefault, TransButtonPrimary } from '../../../Atoms/Buttons';
import GenericTemplateName from '../GenericTemplateName';
import { IconBallpen } from '@tabler/icons';
import { WindowContext } from '../../../Service/Context/WindowContext';

const DocumentTemplateActions = () => {
  const { document } = useContext(DocumentContext);
  const { show, toggle } = useModalState();
  const navigate = useNavigate();
  const [newName, setNewName] = useState(document.name);
  const [disableSave, setDisableSave] = useState(false);
  const { sm } = useContext(WindowContext);

  function changeName() {
    let templateName = newName.trim();
    savePatch(document.id, { name: templateName })
      .then((resp) => {
        if (resp.ok) {
          navigate('/settings/template/document/' + document.documentType.toLowerCase() + '/' + templateName);
        } else {
          console.error('could not save template ' + document.name + ' under new name ' + templateName);
        }
      })
      .catch(() => {
        console.error('could not save template ' + document.name + ' under new name ' + templateName);
      });
  }

  return (
    <>
      <Group position="right">
        {sm ? (
          <IconBallpen onClick={toggle} />
        ) : (
          <Button variant="outline" onClick={toggle}>
            Namen ändern
          </Button>
        )}
        <TemplatePreview name={document.name} documentType={document.documentType} />
      </Group>
      <Space h="md" />
      <ModalDialog
        header={<Trans id="Name der Vorlage ändern" />}
        body={
          <div>
            <div>Wähle einen Namen für diese Vorlage:</div>
            <GenericTemplateName
              disableSave={setDisableSave}
              documentType={document.documentType}
              name={document.name}
              newName={newName}
              setNewName={setNewName}
            />
          </div>
        }
        footer={
          <div>
            <TransButtonPrimary
              disabled={disableSave}
              onClickHandler={() => {
                changeName();
                toggle();
              }}
              text="actions.save"
            />
            <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />
          </div>
        }
        show={show}
        toggle={toggle}
      />
    </>
  );
};

export default DocumentTemplateActions;
