// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Group, Image, Text } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { FC } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Announcements from '../Molecules/Announcements/AnnouncementsCard';
import MaintenanceHeader from '../Atoms/MaintenanceHeader';

const BookitupTemplate: FC = () => {
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '';
  const isPhoneDevice = useMediaQuery('(max-width: 480px)');
  const showAnnouncements = useMediaQuery('(min-width: 1200px)');

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <MaintenanceHeader />
      <Box
        p="md"
        sx={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          background: 'transparent linear-gradient(53deg, #FFF6EB 0%, #FFEFEF 100%) 0% 0% no-repeat padding-box',
        }}
      >
        <Image width={120} height={30} mt={10} ml={10} mb="md" src="/img/bookitup-logo-with-text.png" />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, gap: 25 }}>
          <Outlet />
          {showAnnouncements && <Announcements />}
        </div>
        <Group spacing={5} mb={10} ml={10} mt="md" sx={{ fontSize: isPhoneDevice ? 11 : 14 }}>
          <Text>{`© ${new Date().getFullYear()} bookitup GmbH`}</Text>
          <Text>•</Text>
          <Text component="a" href="https://bookitup.de/impressum" target="_blank">
            Impressum
          </Text>
          <Text>•</Text>
          <Text component="a" href="https://bookitup.de/datenschutz" target="_blank">
            Datenschutz
          </Text>
          <Text>•</Text>
          <Text component="a" href="https://bookitup.de/wp-content/uploads/2023/05/bookitup-agb-1.pdf" target="_blank">
            AGB
          </Text>
          <Text>•</Text>
          <Text component="a" href="https://help.bookitup.de" target="_blank">
            Hilfe
          </Text>
        </Group>
      </Box>
    </GoogleOAuthProvider>
  );
};

export default BookitupTemplate;
