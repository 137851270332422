import { FC, useContext } from 'react';
import { openConfirmModal } from '@mantine/modals';
import { useLingui } from '@lingui/react';
import { Text } from '@mantine/core';
import { TransButtonLink } from '../../../Atoms/Buttons';
import { Icon } from '../../../Atoms/Icons';
import { ContactDeleteFlowContext } from './ContactDeleteFlowContext';

const ContactDeleteFlow0: FC = () => {
  const { contracts, isLoading, removeContracts } = useContext(ContactDeleteFlowContext);

  const { i18n } = useLingui();

  const confirmDeleteContracts = () => {
    openConfirmModal({
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          {i18n._('common.delete.contracts.confirm.head')}
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      labels: { confirm: 'Ja', cancel: 'Abbrechen' },
      children: <Text>{i18n._('common.delete.contracts.confirm')}</Text>,
      onConfirm: removeContracts,
    });
  };

  if (!contracts || contracts?.length === 0) {
    return (
      <div>
        <Icon value="CHECK" /> Keine verknüpften Verträge zu diesem Kontakt.
      </div>
    );
  }

  return (
    <div>
      <div>
        Um diesen Kontakt zu löschen, musst Du vorher {contracts?.length} an ihn addressierten Verträge löschen:
      </div>

      <TransButtonLink
        isLoading={isLoading}
        style={{ color: '#9499a7' }}
        icon="TRASH"
        className="primary-link"
        onClick={confirmDeleteContracts}
        text={`${contracts?.length ?? '0'} Verträge löschen`}
      />
    </div>
  );
};

export default ContactDeleteFlow0;
