import { Menu } from '@mantine/core';
import { IconDotsVertical, IconTrashX, IconCopy } from '@tabler/icons';
import { FC, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkflowS } from '../../../Service/WorkflowS';
import { WorkflowTemplateDTO } from '../../../Types/LogT';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { openDeleteTemplateModal } from './WorkflowTemplateModals';

const WorkflowTemplateActions: FC<{ template: WorkflowTemplateDTO; remove: () => void }> = ({ template, remove }) => {
  const { id } = template;
  const navigate = useNavigate();
  const { interceptViolation } = useContext(ViolationContext);

  const duplicate = async () => {
    const copy = await WorkflowS.copyTemplate(id);
    if (copy) {
      const { id: copyId } = copy;
      navigate(`templates/${copyId}`);
    }
  };

  return (
    <Menu radius="md" shadow="md" width={150} transition="slide-down" position="bottom-end">
      <Menu.Target>
        <div>
          <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item icon={<IconCopy size={16} />} onClick={duplicate}>
          Duplizieren
        </Menu.Item>
        <Menu.Item
          color="red"
          icon={<IconTrashX size={16} />}
          onClick={() => openDeleteTemplateModal({ template, onDelete: remove, interceptViolation })}
        >
          Löschen
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default WorkflowTemplateActions;
