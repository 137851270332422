/* eslint-disable camelcase */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Button, Space, Stack, Text } from '@mantine/core';
import { IconX } from '@tabler/icons';
import { FC, ReactNode, useContext } from 'react';
import { AnnoucementsContext } from '../../Service/Context/AnnouncementsContext';
import { RoutingS } from '../../Service/RoutingS';
import { Story } from '../../Service/StoryBlokS';

const AnnoucementsBox: FC = () => {
  const { stories, loading, markAsSeen } = useContext(AnnoucementsContext);

  if (!loading && (!stories || stories.length === 0)) {
    return null;
  }

  const mapStory = (story: Story): ReactNode => {
    const { uuid, content } = story;
    const { title, description, post_type, buttonTitle, call_to_action_btn } = content;
    const isAnnouncement = post_type === 'ANNOUNCEMENT';

    return (
      <Alert
        withCloseButton={isAnnouncement}
        key={uuid}
        p="md"
        radius="md"
        title={
          <Text weight="bolder" size="md" sx={{ color: isAnnouncement ? 'inherit' : 'white' }}>
            {title}
          </Text>
        }
        sx={(theme) => ({
          backgroundColor: isAnnouncement ? theme.colors.lightBlue[0] : theme.colors.red[4],
        })}
        onClose={() => {
          markAsSeen(uuid);
        }}
      >
        {!isAnnouncement && (
          <IconX
            color="white"
            size={16}
            style={{ position: 'absolute', right: 15, top: 15, cursor: 'pointer' }}
            onClick={() => markAsSeen(uuid)}
          />
        )}
        <Text color={isAnnouncement ? 'black' : 'white'}>{description}</Text>

        {buttonTitle && call_to_action_btn && (
          <>
            <Space h="md" />
            <Button onClick={() => RoutingS.openInNewTab(call_to_action_btn.url)}> {buttonTitle} </Button>
          </>
        )}
      </Alert>
    );
  };

  return <Stack mt="md">{stories.map(mapStory)}</Stack>;
};

export default AnnoucementsBox;
