// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Anchor, Breadcrumbs, Container, Divider, Space, Tabs, Text, Timeline } from '@mantine/core';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IconCalendar, IconInfoCircle, IconSettings, IconToggleRight } from '@tabler/icons';
import { i18n } from '@lingui/core';
import { EntityReferenceDTO, WorkflowTemplateDTO } from '../../../Types/LogT';
import { WorkflowS } from '../../../Service/WorkflowS';
import { GradientIcon } from '../../../Atoms/ThemeIcons';
import ActionTypeIcon from '../../../Atoms/ActionTypeIcon';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { openEditStepModal } from './WorkflowTemplateModals';
import WorkflowStepTemplate from './WorkflowStepTemplate';
import WorkflowTemplateSettings from './WorkflowTemplateSettings';
import EventWorkflowList from './EventWorkflowList';

interface WorkflowTemplateContextInterface {
  refresh: () => void;
  getAvailableEntities: (index: number, alternative?: boolean) => EntityReferenceDTO[];
  addStep: (newIndex?: number, alternativeFlow?: boolean, alternative?: boolean) => void;
  isAlternative: (index: number, primarySequence?: boolean) => boolean;
  patchTemplate: (patch: Partial<WorkflowTemplateDTO>) => void;
}

export const WorkflowTemplateContext = createContext<WorkflowTemplateContextInterface>(
  {} as WorkflowTemplateContextInterface,
);

const WorkflowTemplate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState<WorkflowTemplateDTO | null>(null);
  const { interceptViolation } = useContext(ViolationContext);

  useEffect(() => {
    fetchTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const patchTemplate = async (patch: Partial<WorkflowTemplateDTO>) => {
    if (id) {
      setLoading(true);
      WorkflowS.editTemplate(id, patch, interceptViolation)
        .then((resJSON) => {
          if (resJSON) {
            setTemplate(resJSON);
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const isAlternative = (index: number, primarySequence = true) =>
    WorkflowS.alternativeStepDefined(primarySequence ? steps : alternativeSteps, index);

  const fetchTemplate = () => {
    if (id) {
      setLoading(true);
      WorkflowS.fetchTemplate(id)
        .then(setTemplate)
        .finally(() => setLoading(false));
    }
  };

  const getAvailableEntities = (index: number, alternative = false) => {
    const entities = [];
    if (template && template.customerReference) {
      entities.push(template.customerReference);
    }
    if (index === 0) return entities;
    const stepsOfInterests = alternative ? alternativeSteps : steps;
    stepsOfInterests
      .slice(0, index)
      .filter((step) => step.entityReference !== null)
      .forEach((step) => entities.push(step.entityReference));
    return entities;
  };

  const addStep = (index?: number, alternativeFlow = false, alternative = false) => {
    if (template) {
      WorkflowS.addWorkflowStep(template.id, alternative, alternativeFlow, index).then(
        (resJSON: WorkflowTemplateDTO) => {
          if (resJSON) {
            setTemplate(resJSON);
            // eslint-disable-next-line no-underscore-dangle
            const _steps = alternativeFlow ? resJSON.alternativeSteps : resJSON.steps;
            openEditStepModal({
              workflowStep: _steps[index ?? _steps.length - 1],
              refresh: fetchTemplate,
              availableEntities: getAvailableEntities(index ? index + 1 : _steps.length - 1, alternativeFlow),
              interceptViolation,
            });
          }
        },
      );
    }
  };

  const value = useMemo(
    () => ({
      refresh: fetchTemplate,
      addStep,
      getAvailableEntities,
      patchTemplate,
      isAlternative,
      template,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [template],
  );

  if (!template) {
    return null;
  }
  const { name, steps, alternativeSteps, typeOfInterest, enabled } = template;

  return (
    <WorkflowTemplateContext.Provider value={value}>
      <Container p={0}>
        <Breadcrumbs>
          <Anchor onClick={() => navigate('/settings/workflow')}>Workflow-Vorlagen</Anchor>
          <Anchor sx={{ pointerEvents: 'none' }}>
            <b>{name}</b>
          </Anchor>
        </Breadcrumbs>
        <Divider my="sm" />
        <Alert
          mb="sm"
          icon={<IconInfoCircle />}
          title="Wichtig: Denke an alle Vorbedingungen"
          sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })}
        >
          <Text>{i18n._('workflow.tip.helper')}</Text>
        </Alert>

        {!enabled && (
          <Alert
            mb="sm"
            icon={<IconToggleRight color="white" />}
            title={<Text color="white">Workflow aktivieren</Text>}
            sx={(theme) => ({ backgroundColor: theme.colors.orange[3] })}
          >
            <Text color="white">Denke daran den Workflow zu aktivieren, wenn du ihn fertig bearbeitet hast.</Text>
          </Alert>
        )}

        <Tabs
          mt="sm"
          keepMounted={false}
          defaultValue="Einstellungen"
          styles={{
            tab: {
              '&[data-active]': {
                fontWeight: 'bolder',
              },
            },
          }}
        >
          <Tabs.List>
            <Tabs.Tab value="Einstellungen" icon={<IconSettings size={14} />}>
              Einstellungen
            </Tabs.Tab>
            <Tabs.Tab value="Verwendet in Events" icon={<IconCalendar size={14} />}>
              Verwendet in Events
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="Einstellungen" pt="xs">
            <Space h="xs" />
            {template && <WorkflowTemplateSettings template={template} />}
            <Tabs defaultValue="primary">
              <Tabs.List>
                <Tabs.Tab value="primary">
                  <Text size="md">{typeOfInterest === 'REQUEST_RECEIVED' ? 'Verfügbar' : 'Schritte'}</Text>
                </Tabs.Tab>
                {typeOfInterest === 'REQUEST_RECEIVED' && (
                  <Tabs.Tab value="secondary">
                    <Text size="md">Ausgebucht</Text>
                  </Tabs.Tab>
                )}
              </Tabs.List>
              <Tabs.Panel value="primary" pt="xs">
                <>
                  {!loading && steps.length === 0 ? (
                    <Text italic mb="xl" color="dimmed" size="xs" weight="lighter">
                      Noch nichts hinzugefügt.
                    </Text>
                  ) : (
                    <>
                      <Timeline mt="md" bulletSize={32} lineWidth={3}>
                        {steps.map((step, index) => (
                          <Timeline.Item
                            sx={{ marginLeft: step.alternativeStep ? 25 : 0, minHeight: 50 }}
                            key={step.id}
                            lineVariant={step.alternativeStep ? 'dashed' : 'solid'}
                            bullet={
                              <GradientIcon color={step.configId ? 'blue' : 'red'}>
                                <ActionTypeIcon actionType={step.procedureType} />
                              </GradientIcon>
                            }
                          >
                            <WorkflowStepTemplate index={index} step={step} />
                          </Timeline.Item>
                        ))}
                      </Timeline>
                      <Divider my="sm" />
                    </>
                  )}
                  <Text color="blue" variant="link" sx={{ cursor: 'pointer' }} onClick={() => addStep()}>
                    + Schritt hinzufügen
                  </Text>
                </>
              </Tabs.Panel>
              {alternativeSteps && (
                <Tabs.Panel value="secondary" pt="xs">
                  <>
                    {!loading && alternativeSteps.length === 0 ? (
                      <Text italic mb="xl" color="dimmed" size="xs" weight="lighter">
                        Noch nichts hinzugefügt.
                      </Text>
                    ) : (
                      <>
                        <Timeline mt="md" bulletSize={32} lineWidth={3}>
                          {alternativeSteps.map((step, index) => (
                            <Timeline.Item
                              sx={{ marginLeft: step.alternativeStep ? 25 : 0, minHeight: 50 }}
                              key={step.id}
                              lineVariant={step.alternativeStep ? 'dashed' : 'solid'}
                              bullet={
                                <GradientIcon color={step.configId ? 'blue' : 'red'}>
                                  <ActionTypeIcon actionType={step.procedureType} />
                                </GradientIcon>
                              }
                            >
                              <WorkflowStepTemplate alternativeFlow index={index} step={step} />
                            </Timeline.Item>
                          ))}
                        </Timeline>
                        <Divider my="sm" />
                      </>
                    )}
                    <Text
                      color="blue"
                      variant="link"
                      sx={{ cursor: 'pointer' }}
                      onClick={() => addStep(undefined, true)}
                    >
                      + Schritt hinzufügen
                    </Text>
                  </>
                </Tabs.Panel>
              )}
            </Tabs>
          </Tabs.Panel>
          <Tabs.Panel value="Verwendet in Events" pt="xs">
            {id && <EventWorkflowList workflowTemplateId={id} />}
          </Tabs.Panel>
        </Tabs>
      </Container>
    </WorkflowTemplateContext.Provider>
  );
};

export default WorkflowTemplate;
