import { Trans } from '@lingui/react';
import React from 'react';
import { ModalDialog, useModalState } from '../Dialogs';
import FileUpload from '../../Organismns/FileUpload';
import { TransButtonLinkWithIcon } from '../../Atoms/Buttons';

export const FileUploadModalDialog = ({ text, serverUrl, reloadCallback, show, toggle }) => {
  return (
    <div>
      {/* <TransButtonLinkWithIcon
        className="btn-link"
        style={{ color: '#7d7d7d' }}
        text="+ Datei hochladen"
        onClick={toggle}
      /> */}
      <ModalDialog
        header={<Trans id="Datei hochladen" />}
        body={
          <FileUpload
            serverUrl={serverUrl}
            reloadCallback={(fileName) => {
              reloadCallback(fileName);
              toggle();
            }}
            text={text}
          />
        }
        show={show}
        toggle={toggle}
      />
    </div>
  );
};
