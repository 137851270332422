// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text, Title } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { WistiaS } from '../../Service/WistiaS';
import { WistiaMedia } from '../../Types/WistiaT';
import WistiaVideoPlayer from './WistiaVideoPlayer';

const WistiaVideoWrapper: FC<{ mediaId: string; hideLabel?: boolean }> = ({ mediaId, hideLabel = false }) => {
  const [video, setVideo] = useState<WistiaMedia | null>(null);

  useEffect(() => {
    WistiaS.fetchMediaDetails(mediaId).then(setVideo);
  }, [mediaId]);

  if (!video) return null;

  const { name, description } = video;

  return (
    <>
      {!hideLabel && (
        <Title mb="sm" order={3}>
          {name}
        </Title>
      )}
      <WistiaVideoPlayer mediaId={mediaId} />
      {description && description.trim() !== '' && (
        <>
          <Title order={5} mt="xs">
            Videobeschreibung
          </Title>
          <Text mt="sm" className="space-preserve" dangerouslySetInnerHTML={{ __html: description }} />
        </>
      )}
    </>
  );
};

export default WistiaVideoWrapper;
