import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { Autocomplete, AutocompleteItem, Flex, InputVariant, Loader, Text } from '@mantine/core';
import { IconSearch } from '@tabler/icons';
import { Icon } from '../Atoms/Icons';

type Props<T> = {
  search: (text: string, signal: any) => Promise<any>;
  searchString?: string;
  defaultValue?: string;
  placeholder?: string;
  onSelect: (suggestion: T) => void;
  onChange?: (value: string) => void;
  autocompleteItem: FC<any>;
  inlinePlaceholder?: boolean;
  inlineNothingFound?: boolean;
  required?: boolean;
  clearable?: boolean;
  nothingFound?: string | ReactNode;
  icon?: ReactNode;
  variant?: InputVariant;
  sx?: any;
};

const SearchTextInputField: FC<Props<any>> = ({
  search,
  searchString,
  defaultValue,
  placeholder,
  onChange,
  onSelect,
  autocompleteItem,
  inlinePlaceholder,
  inlineNothingFound,
  required,
  clearable,
  nothingFound,
  icon,
  variant,
  sx,
}) => {
  const [timeoutId, setTimeoutId] = useState<any>(undefined);
  const [suggestions, setSuggestions] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [signal, setSignal] = useState(axios.CancelToken.source());
  const [previousSignal, setPreviousSignal] = useState<CancelTokenSource | undefined>(undefined);

  const onClear = () => {
    onChange?.('');
  };
  const onSearchTextChange = useCallback(
    (value: string) => {
      const doSearch = (val: string) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        const id = setTimeout(() => {
          if (previousSignal) {
            previousSignal.cancel('reset timer');
          }

          // Do the ajax stuff
          if (val !== '') {
            setLoading(true);

            if (signal) {
              setPreviousSignal(signal);
            }

            setSignal(axios.CancelToken.source());

            search(val, signal)
              .then((newSuggestions: any[]) => {
                setSuggestions(newSuggestions);
                setLoading(false);
              })
              .catch((error: Error) => {
                if (axios.isCancel(error)) {
                  console.log('Error: ', error.message);
                } else {
                  console.error(`could not search emails by search-string ${val}`);
                  setSuggestions([]);
                  setLoading(false);
                }
              });
          } else {
            setLoading(false);
            setSuggestions([]);
          }
        }, 200);
        setTimeoutId(id);
      };

      onChange?.(value);
      doSearch(value);
    },
    [onChange, previousSignal, search, signal, timeoutId],
  );

  useEffect(() => {
    if (defaultValue) {
      onSearchTextChange(defaultValue);
    }
  }, [defaultValue]);

  const Clear =
    searchString !== '' && clearable ? (
      <Icon value="CLEAR" onClick={onClear} style={{ color: '#e2e2e2' }} />
    ) : undefined;

  const showingNothingFound = !isLoading && suggestions.length === 0 && searchString !== '';

  const NothingFoundComponent = typeof nothingFound === 'string' ? <Text> {nothingFound} </Text> : nothingFound;

  return (
    <Flex direction="column" gap="md">
      <Autocomplete
        variant={variant ?? 'filled'}
        rightSection={isLoading ? <Loader size="sm" /> : Clear}
        icon={icon}
        defaultValue={defaultValue}
        placeholder={inlinePlaceholder ? placeholder : ''}
        label={!inlinePlaceholder ? placeholder : ''}
        itemComponent={autocompleteItem}
        nothingFound={showingNothingFound && inlineNothingFound && NothingFoundComponent}
        limit={8}
        data={suggestions}
        value={searchString}
        onItemSubmit={onSelect}
        onChange={onSearchTextChange}
        zIndex={9999}
        required={required}
        radius="sm"
        filter={(value, item) => item.value?.toLowerCase().trim().includes(value?.toLowerCase().trim())}
        sx={sx}
      />
      {showingNothingFound && !inlineNothingFound && NothingFoundComponent}
    </Flex>
  );
};
export default SearchTextInputField;
