import { PageableResponse } from '../../Types/PageableResponse';
import {
  CreateDefaultPlanning,
  CreatePlanning,
  CreatePlanningGroup,
  DefaultPlanning,
  Planning,
  PlanningGroup,
  EventPlanning,
  Shortage,
  WarehousePlanning,
  WarehousePlanningUpdate,
  PlanningCountReportDTO,
  ResourcePlanningDTO,
} from '../../Types/ResourceT';
import { DateUtils, toUTC } from '../../Utils/dateUtils';
import { ajaxActions } from '../AjaxActions';
import { ConstantS } from '../ConstantS';
import { MixpanelS } from '../MixpanelS';
import { ToastS } from '../ToastS';

const BASE_URL = process.env.REACT_APP_RESOURCE_SERVICE_URL;

/**
 * Create a new booking for a resource
 * @param resourceId id of the resource
 * @param create
 * @returns {Promise<Planning>}
 */
const createPlanning = async (create: CreatePlanning): Promise<Planning> => {
  const res = await ajaxActions.put(`${BASE_URL}/resourcePlanning`, create);
  return processResponse(res);
};

const createPlannings = async (create: CreatePlanning[]): Promise<Planning[]> => {
  const res = await ajaxActions.put(`${BASE_URL}/resourcePlannings`, create);
  return processResponse(res);
};

const fetchPlannings = async (groupId: string): Promise<Planning[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/resourcePlannings?planningGroupId=${groupId}`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return [];
};

/**
 * Create a new booking for a resource
 * @param create
 * @returns {Promise<DefaultPlanning>}
 */
const createDefaultPlanning = async (create: CreateDefaultPlanning): Promise<DefaultPlanning> => {
  const res = await ajaxActions.put(`${BASE_URL}/defaultPlanning`, create);
  return processResponse(res);
};

const createDefaultPlannings = async (create: CreateDefaultPlanning[]): Promise<DefaultPlanning[]> => {
  const res = await ajaxActions.put(`${BASE_URL}/defaultPlannings`, create);
  return processResponse(res);
};

/**
 * Get an individual booking
 * @param planningId id of the booking
 * @returns {Promise<Planning | null>}
 */
const getPlanning = async (planningId: string): Promise<Planning | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/resourcePlannings/${planningId}`);
  return processResponse(res);
};

const getResoucePlannings = async (
  resourceId: string,
  pageNumber: number,
): Promise<PageableResponse<ResourcePlanningDTO> | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/resources/${resourceId}/plannings?pageNumber=${pageNumber}`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};

/**
 * Get an individual booking
 * @param planningId id of the booking
 * @returns {Promise<DefaultPlanning | null>}
 */

const getDefaultPlannings = async (): Promise<DefaultPlanning[] | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/defaultPlannings`);
  return processResponse(res);
};

const getDefaultPlanning = async (planningId: string): Promise<DefaultPlanning | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/defaultPlannings/${planningId}`);
  return processResponse(res);
};

/**
 * Deletes an individual booking
 * @param planningId id of the booking
 * @returns {Promise<boolean>}
 */
const deletePlanning = async (planningId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/resourcePlannings/${planningId}`);
  if (res.ok) {
    ToastS.info('planning-deleted', 'Planung wurde gelöscht');
    return true;
  }
  return false;
};

/**
 * Deletes an individual booking
 * @param planningId id of the booking
 * @returns {Promise<boolean>}
 */
const deleteDefaultPlanning = async (planningId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/defaultPlannings/${planningId}`);
  return res.ok;
};

/**
 * List of all bookings that belong to an event
 * @param eventId id of the event
 * @returns {Promise<Planning[]>}
 */
const getPlanningsByEvent = async (eventId: string): Promise<Planning[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/events/${eventId}/resourcePlannings`);
  return processResponse(res);
};

/**
 * List of all bookings that belong to an event
 * @param eventId id of the event
 * @returns {Promise<Planning[]>}
 */
const getEventPlannings = async (eventId: string, from: Date, to: Date): Promise<EventPlanning[]> => {
  const params = [];
  if (from) {
    params.push(`begin=${from.toISOString()}`);
  }
  if (to) {
    params.push(`end=${to.toISOString()}`);
  }
  const res = await ajaxActions.get(`${BASE_URL}/events/${eventId}/eventPlannings?${params.join('&')}`);
  return processResponse(res);
};

/**
 * List of all bookings for a resource
 * @param resourceId id of the resource
 * TODO @param status OPEN | OFFERED | BOOKED | UNAVAILABLE
 * @param from e.g. 2016-12-23 15:39:15
 * @param to e.g. 2016-12-23 15:39:15
 * @returns {Promise<Planning[]>}
 */
const getBookings = async (
  resourceId: string,
  from: Date | null,
  to: Date | null,
): Promise<ResourcePlanningDTO[] | null> => {
  const params = [];
  // if (status) {
  //   params.push(`status=${status}`);
  // }
  if (from) {
    params.push(`begin=${from.toISOString()}`);
  }
  if (to) {
    params.push(`end=${to.toISOString()}`);
  }

  const res = await ajaxActions.get(`${BASE_URL}/resources/${resourceId}/resourcePlannings?${params.join('&')}`);

  return processResponse(res);
};

const getShortages = async (
  resourceId?: string,
  begin?: Date | null,
  end?: Date | null,
  entityType?: string,
  entityId?: string,
): Promise<Shortage[]> => {
  const params = [];
  if (resourceId) {
    params.push(`resourceId=${resourceId}`);
  }
  if (begin) {
    params.push(`begin=${begin}`);
  }
  if (end) {
    params.push(`end=${end}`);
  }
  if (entityType) {
    params.push(`entityType=${entityType}`);
  }
  if (entityId) {
    params.push(`entityId=${entityId}`);
  }

  const res = await ajaxActions.get(`${BASE_URL}/shortages?${params.join('&')}`);

  return processResponse(res);
};

const getVirtualPlanningShortages = async (
  resourceId: string,
  quantity: number,
  eventId?: number,
  from?: Date,
  to?: Date,
  abortSignal?: AbortSignal,
): Promise<Shortage[]> => {
  const params = [];
  if (resourceId) {
    params.push(`resourceId=${resourceId}`);
  }
  if (eventId) {
    params.push(`eventId=${eventId}`);
  }
  if (quantity) {
    params.push(`quantity=${quantity}`);
  }
  if (from) {
    params.push(`begin=${toUTC(from).toISOString()}`);
  }
  if (to) {
    params.push(`end=${toUTC(to).toISOString()}`);
  }

  const res = await ajaxActions.get(`${BASE_URL}/virtualPlanningShortages?${params.join('&')}`, {
    signal: abortSignal,
  });

  return processResponse(res);
};

const getShortagesByEvent = (eventId: string, begin?: Date | null, end?: Date | null) =>
  getShortages(undefined, begin, end, 'events', eventId);

const updatePlanning = async (planningId: string, patch: Partial<CreatePlanning>): Promise<Planning | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/resourcePlannings/${planningId}`, patch);
  return processResponse(res);
};

const updateDefaultPlanning = async (
  planningId: string,
  patch: Partial<CreateDefaultPlanning>,
): Promise<DefaultPlanning | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/defaultPlannings/${planningId}`, patch);
  return processResponse(res);
};

/**
 * Get an individual planninghGroup
 * @param planningGroupId id of the group
 * @returns {Promise<PlanningGroup | null>}
 */
const getPlanningGroup = async (planningGroupId: string): Promise<PlanningGroup | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/planningGroups/${planningGroupId}`);
  return processResponse(res);
};

/**
 * List of all planning groups that belong to an event
 * @param eventId id of the event
 * @returns {Promise<Planning[]>}
 */
const getPlanningGroupsByEvent = async (eventId: string): Promise<PlanningGroup[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/planningGroups/events/${eventId}`);
  return processResponse(res);
};

/**
 * Create a new planningGroup for a resourcePlanning
 * @returns {Promise<Planning>}
 */
const createPlanningGroup = async (
  create: CreatePlanningGroup,
  interceptViolation?: (httpResponse: Response) => void,
): Promise<PlanningGroup> => {
  const res = await ajaxActions.put(`${BASE_URL}/planningGroups`, create);
  if (res.ok) {
    MixpanelS.track(ConstantS.TrackingEvents.PlanningGroupCreated);
    ToastS.success('group-created', `Die Planungsgruppe ${create.description} wurde erstellt`);
  }
  return processResponse(res, interceptViolation);
};

const updatePlanningGroup = async (
  planningGroupId: string,
  patch: Partial<CreatePlanningGroup>,
): Promise<PlanningGroup | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/planningGroups/${planningGroupId}`, patch);
  return processResponse(res);
};

/**
 * Deletes an individual planningGroups
 * @param planningGroupId id of the group
 * @returns {Promise<boolean>}
 */
const deletePlanningGroup = async (planningGroupId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/planningGroups/${planningGroupId}`);
  return res.ok;
};

/**
 * Create a new WarehousePlanning for a resourcePlanning
 * @returns {Promise<WarehousePlanning>}
 */
const confirmResourcePlanning = async (resourcePlanningId: string): Promise<WarehousePlanning | null> => {
  const res = await ajaxActions.put(`${BASE_URL}/resourcePlannings/${resourcePlanningId}/warehousePlannings`);
  if (res.ok) {
    ToastS.success('reserved', 'Resource wurde reserviert.');
    return res.json();
  }
  ToastS.generalError();
  return null;
};

/**
 * Removes all WarehousePlannings for a resourcePlanning
 * @returns {Promise<boolean>}
 */
const rejectResourcePlanning = async (resourcePlanningId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/resourcePlannings/${resourcePlanningId}/warehousePlannings`);
  if (res.ok) {
    ToastS.info('res-cancelled', 'Reservierung wurde entfernt.');
    return true;
  }
  return false;
};

const updateWarehousePlanning = async (
  warehousePlanningId: string,
  patch: Partial<WarehousePlanningUpdate>,
): Promise<WarehousePlanning | null> => {
  const res = await ajaxActions.patch(`${BASE_URL}/warehousePlannings/${warehousePlanningId}`, patch);
  return processResponse(res);
};

/**
 * Deletes an individual WarehousePlanning
 * @param warehousePlanningId id of the planning
 * @returns {Promise<boolean>}
 */
const deleteWarehousePlanning = async (warehousePlanningId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/warehousePlannings/${warehousePlanningId}`);
  if (res.ok) {
    ToastS.info('res-cancelled', 'Reservierung wurde entfernt.');
    return true;
  }
  return false;
};

/**
 * Get an individual WarehousePlanning
 * @param warehousePlanningId id of the planning
 * @returns {Promise<WarehousePlanning | null>}
 */
const getWarehousePlanning = async (warehousePlanningId: string): Promise<WarehousePlanning | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/warehousePlannings/${warehousePlanningId}`);
  return processResponse(res);
};

/**
 * List of all planning groups that belong to an event
 * @returns {Promise<WarehousePlanning[]>}
 */
const getWarehousePlannings = async (): Promise<WarehousePlanning[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/warehousePlannings`);
  return processResponse(res);
};

const getPlanningsCountReport = async (resourceId: string): Promise<PlanningCountReportDTO | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/resourcePlannings/${resourceId}/count-report`);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const processResponse = (res: Response, interceptViolation?: (httpResponse: Response) => void) => {
  if (res.ok) {
    return res.json();
  }
  if (interceptViolation) {
    interceptViolation(res);
  } else {
    ToastS.interceptError(res);
  }
  return null;
};

const ResourcePlanningS = {
  createPlanning,
  createPlannings,
  createDefaultPlanning,
  createDefaultPlannings,
  getPlanning,
  getResoucePlannings,
  getDefaultPlanning,
  getDefaultPlannings,
  getShortages,
  getShortagesByEvent,
  getVirtualPlanningShortages,
  getEventPlannings,
  updatePlanning,
  updateDefaultPlanning,
  deletePlanning,
  deleteDefaultPlanning,
  getPlanningsByEvent,
  getBookings,
  getPlanningGroup,
  getPlanningGroupsByEvent,
  createPlanningGroup,
  updatePlanningGroup,
  deletePlanningGroup,
  confirmResourcePlanning,
  rejectResourcePlanning,
  updateWarehousePlanning,
  deleteWarehousePlanning,
  getWarehousePlanning,
  getWarehousePlannings,
  getPlanningsCountReport,
  fetchPlannings,
};

export default ResourcePlanningS;
