import { Group, Space } from '@mantine/core';
import React, { useState } from 'react';
import DocumentLayout from './DocumentLayoutSettings';
import { MediaUpload } from './MediaUpload';

const DocumentSettings = () => {
  const [logoAvailable, setLogoAvailable] = useState(false);
  const [lastModified, setLastModified] = useState(undefined);

  const styles = {
    logoAndNotePaper: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  };

  return (
    <div>
      <Group position="center">
        <MediaUpload
          kind="logo"
          title="Logo"
          text="Hier kannst Du Dein Logo hineinziehen"
          setLastModified={setLastModified}
          setLogoUploaded={setLogoAvailable}
        />
        <MediaUpload
          kind="notepaper"
          title="Hintergrund (nur Seite 1)"
          text="Hier kannst Du ein Hintergrund-Bild hineinziehen"
          setLastModified={setLastModified}
        />
        <MediaUpload
          kind="background-starting-page-2"
          title="Hintergrund (ab Seite 2)"
          text="Hier kannst Du ein Hintergrund-Bild hineinziehen"
          setLastModified={setLastModified}
          lastModified={lastModified}
        />
      </Group>
      <Space h="xl" />
      <DocumentLayout lastModified={lastModified} logoAvailable={logoAvailable} />
    </div>
  );
};

export default DocumentSettings;
