// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';

const EmptyText = () => (
  <Text italic color="dimmed" size="xs" weight="lighter">
    Noch nichts hinzugefügt.
  </Text>
);

export default EmptyText;
