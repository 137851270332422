// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Space, Text } from '@mantine/core';
import { FC, useContext } from 'react';
import { ProfileContext } from '../../../Service/Context/ProfileContext';

const PositionHeader: FC<{ documentVersion: boolean; desktopVersion: boolean; showDiscount: boolean }> = ({
  documentVersion,
  desktopVersion,
  showDiscount = true,
}) => {
  const { profile } = useContext(ProfileContext);

  return (
    <Group spacing="xs" p={6} sx={{ backgroundColor: '#4A4A4A', borderRadius: 6, color: 'white', fontSize: 12 }}>
      {desktopVersion ? (
        <>
          {documentVersion && <Space w={35} />}
          <Text ml={documentVersion ? 0 : 'xs'} sx={{ flexGrow: 1 }}>
            Produkt/Leistung
          </Text>
          <Text sx={{ width: 50 }}>Menge</Text>
          <Text sx={{ width: 60 }}>Einheit</Text>
          <Text sx={{ width: 70 }}>Preis (€)</Text>
          {profile && !profile.smallScaleBusiness && <Text sx={{ width: 60 }}>Steuer (%)</Text>}
          {showDiscount && <Text sx={{ width: 130 }}>Rabatt</Text>}
          <Text align="center" sx={{ width: 100 }}>
            Gesamt
          </Text>
          <div style={{ width: 50 }} />
        </>
      ) : (
        <Text ml="xs">Positionen</Text>
      )}
    </Group>
  );
};

export default PositionHeader;
