import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { NotificationsProvider } from '@mantine/notifications';
import moment from 'moment';
import { App } from './App';
import { messages as enMessages } from './locales/en/messages';
import { messages as deMessages } from './locales/de/messages';
import 'moment/locale/de';

const root = ReactDOM.createRoot(document.getElementById('root'));
i18n.load({
  en: enMessages,
  de: deMessages,
});
i18n.activate('de');

moment().locale('de');

root.render(
  <I18nProvider i18n={i18n}>
    <BrowserRouter>
      <NotificationsProvider position="bottom-center">
        <App />
      </NotificationsProvider>
    </BrowserRouter>
  </I18nProvider>,
);
