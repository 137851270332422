import React from 'react';
import PropTypes from 'prop-types';

/**
 * DecimalNumberFormatter is a formatter which simply passes decimal numbers trough, but
 * returns a dash for empty or null values.
 */
export const DecimalNumberFormatter = ({ value, style }) => {
  const styles = {
    span: {
      height: '100%',
      ...style,
    },
  };

  let stringValue = value.toString();
  if (stringValue.toString().includes('.')) {
    stringValue = stringValue.replace('.', ',');
  }

  return (
    <span className="form-control" style={styles.span}>
      {value === '' || value === null || value === undefined ? '-' : stringValue}
    </span>
  );
};

DecimalNumberFormatter.propTypes = {
  value: PropTypes.number,
};
