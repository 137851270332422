// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Center, Checkbox, ColorInput, Group, Loader, Select, Text, TextInput } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { closeAllModals, openModal } from '@mantine/modals';
import { i18n } from '@lingui/core';
import { EventKind } from '../../Types/EventT';
import { EventS } from '../../Service/EventS';
import { DataS } from '../../Service/DataS';
import {MixpanelS} from "../../Service/MixpanelS";
import {ConstantS} from "../../Service/ConstantS";

export const openDeleteEventKindModal = (kind: EventKind, onDelete: () => void, allKinds: EventKind[]) => {
  const otherKinds = allKinds.filter((k) => k.id !== kind.id);
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Auftragstyp löschen
      </Text>
    ),
    children: <DeleteEventKindModal kind={kind} onDelete={onDelete} otherKinds={otherKinds} />,
  });
};

interface DeleteModalProps {
  kind: EventKind;
  onDelete: () => void;
  otherKinds: EventKind[];
}

const DeleteEventKindModal: FC<DeleteModalProps> = ({ kind, onDelete, otherKinds }) => {
  const { id, name } = kind;
  const [loading, setLoading] = useState(false);
  const [replace, setReplace] = useState(false);
  const [replacement, setReplacement] = useState<string>();

  const onConfirm = async () => {
    setLoading(true);
    const deleted = await EventS.deleteEventKind(id, getReplacement());
    if (deleted) {
      MixpanelS.track(ConstantS.TrackingEvents.EventKindDeleted);
      onDelete();
      closeAllModals();
    }
    setLoading(false);
  };

  const getReplacement = () => {
    if (replace) {
      const replacementKind = otherKinds.find((k) => k.name === replacement);
      if (replacementKind) {
        return replacementKind.id;
      }
    }
    return undefined;
  };

  return (
    <>
      <Text>
        Möchtest du diesen Auftragstyp entfernen? Deine Events bleiben bestehen, aber der Auftragstyp wird von den
        Events entfernt.
      </Text>
      <Checkbox
        mt="xs"
        mb="xs"
        label="Der Auftragstyp soll durch einen anderen Auftragstyp ersetzt werden."
        checked={replace}
        onChange={(e) => setReplace(e.currentTarget.checked)}
      />
      {replace && (
        <Select
          value={replacement}
          onChange={(val) => val && setReplacement(val)}
          mb="md"
          label={`${name} ersetzen durch`}
          data={DataS.getEventKindsOptions(otherKinds)}
        />
      )}

      <Group position="right" spacing="xs" mt="xl">
        <Button variant="default" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          rightIcon={loading && <Loader size="xs" />}
          color="red"
          disabled={loading || (replace && !replacement)}
          onClick={onConfirm}
        >{`${i18n._('actions.delete')}${replace ? ' und ersetzen' : ''}`}</Button>
      </Group>
    </>
  );
};

export const openEditEventKindModal = (
  kind: EventKind,
  onChange: (kind: EventKind) => void,
  existingNames: string[],
) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Art bearbeiten
      </Text>
    ),
    children: <EditEventKindModal kind={kind} existingNames={existingNames} onChange={onChange} />,
  });
};

interface EditModalProps {
  onChange: (kind: EventKind) => void;
  kind: EventKind;
  existingNames: string[];
}

const EditEventKindModal: FC<EditModalProps> = ({ onChange, kind, existingNames }) => {
  const { id, name: initialName, color: initialColor } = kind;
  const [loading, setLoading] = useState(false);
  const [conflict, setConflict] = useState(false);
  const [name, setName] = useState(initialName);
  const [color, setColor] = useState<string | undefined>(initialColor ?? undefined);

  useEffect(() => {
    setConflict(existingNames.includes(name));
  }, [existingNames, name]);

  const updateKind = () => {
    setLoading(true);
    EventS.editEventKind(id, { name, color })
      .then((_kind) => {
        if (_kind) {
          onChange(_kind);
          closeAllModals();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <TextInput
        value={name}
        label="Name des Art"
        onChange={(e) => setName(e.currentTarget.value)}
        disabled={loading}
        error={conflict && 'Name ist bereits vergeben'}
      />
      <ColorInput
        mt="xs"
        placeholder="Farbe wählen"
        label="Farbe (optional)"
        value={color}
        onChange={setColor}
        autoComplete="off"
      />
      <Center mt="xl">
        <Button
          disabled={name === '' || (name === initialName && color === initialColor) || conflict || loading}
          onClick={updateKind}
          rightIcon={loading && <Loader size="xs" />}
          sx={{ minWidth: 250 }}
        >
          {i18n._('actions.edit')}
        </Button>
      </Center>
    </>
  );
};

// eslint-disable-next-line import/prefer-default-export
export const openCreateEventKindModal = (onCreate: (kind: EventKind) => void, existingNames: string[]) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Art erstellen
      </Text>
    ),
    children: <CreateEventKindModal onCreate={onCreate} existingNames={existingNames} />,
  });

interface CreateModalProps {
  onCreate: (kind: EventKind) => void;
  existingNames: string[];
}

const CreateEventKindModal: FC<CreateModalProps> = ({ onCreate, existingNames }) => {
  const [loading, setLoading] = useState(false);
  const [conflict, setConflict] = useState(false);
  const [name, setName] = useState('');
  const [color, setColor] = useState<string>();

  useEffect(() => {
    setConflict(existingNames.includes(name));
  }, [existingNames, name]);

  const createKind = () => {
    setLoading(true);
    EventS.createEventKind({ name, color })
      .then((kind) => {
        if (kind) {
          MixpanelS.track(ConstantS.TrackingEvents.EventKindCreated);
          onCreate(kind);
          closeAllModals();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <TextInput
        value={name}
        label="Name des Art"
        onChange={(e) => setName(e.currentTarget.value)}
        disabled={loading}
        error={conflict && 'Name ist bereits vergeben'}
      />
      {/*<ColorInput*/}
      {/*  mt="xs"*/}
      {/*  placeholder="Farbe wählen"*/}
      {/*  label="Farbe (optional)"*/}
      {/*  value={color}*/}
      {/*  onChange={setColor}*/}
      {/*  autoComplete="off"*/}
      {/*/>*/}
      <Center mt={30}>
        <Button
          disabled={name === '' || conflict || loading}
          onClick={createKind}
          rightIcon={loading && <Loader size="xs" />}
          sx={{ minWidth: 250 }}
        >
          {i18n._('actions.save')}
        </Button>
      </Center>
    </>
  );
};
