import { Skeleton, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateContractConfigDTO } from '../../../../../Types/LogT';
import { fetchContractTemplate } from '../../../../../Service/restapi/contractService';

const CreateContract: FC<{ config: unknown }> = ({ config }) => {
  const { templateId } = config as CreateContractConfigDTO;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState<any>();

  useEffect(() => {
    if (templateId) {
      setLoading(true);
      fetchContractTemplate(templateId)
        .then((resJSON) => {
          if (resJSON) {
            setTemplate(resJSON);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [templateId]);

  if (!templateId) {
    return <Text color="red">Die Vertragsvorlage wurde nicht ausgewählt</Text>;
  }
  return (
    <Text>
      Vertrag aus Vorlage{' '}
      {loading || !template ? (
        <Skeleton mb={-3} height={16} width={100} sx={{ display: 'inline-block' }} />
      ) : (
        <Text
          span
          weight="bolder"
          color="blue"
          variant="link"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(`/settings/template/contract/${template.name}`)}
        >
          {template.name}
        </Text>
      )}{' '}
      erstellen.
    </Text>
  );
};

export default CreateContract;
