// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { i18n } from '@lingui/core';
import moment from 'moment';
import { BookitupNotification } from '../../../../Types/LogT';
import { RoutingS } from '../../../../Service/RoutingS';

const RecommendationStateChanged: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { personDescription, eventId, value, eventDate } = notification;

  return (
    <Text>
      <Text span weight="bolder">
        {` ${personDescription} `}
      </Text>
      hat den Buchungsstatus für das Event am
      <Text
        span
        weight="bolder"
        variant="link"
        color="blue"
        onClick={() => RoutingS.openInNewTab(`/events/${eventId}/details`)}
        sx={{ cursor: 'pointer' }}
      >
        {eventDate && ` ${moment(eventDate).format('LL')}`}
      </Text>
      <Text span weight="bolder">
        {` auf ${i18n._(`recommendation.bookingState.${value?.toLowerCase()}`)} `}
      </Text>
      gesetzt.
    </Text>
  );
};

export default RecommendationStateChanged;
