import mixpanel, { Dict } from 'mixpanel-browser';

const track = (eventName: string, properties?: Dict) => {
  try {
    mixpanel.track(eventName, properties);
    // eslint-disable-next-line no-empty
  } catch {
    // eslint-disable-next-line no-console
    console.debug(
      `Event: ${eventName} [ props: ${properties ? JSON.stringify(properties) : '{}'} ] skipped for thiv env`,
    );
  }
};

// eslint-disable-next-line import/prefer-default-export
export const MixpanelS = {
  track,
};
