import { ajaxActions } from './AjaxActions';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_FILE_SERVICE_URL;

// Expend later as needed
export type EntityType = 'resources' | 'documents' | 'contracts' | 'questionnaires';

type FileDetails = {
  fileName: string;
  fileSize: number;
  lastModified: Date;
};

const put = async (file: File, entityType: EntityType, entityId = 'default'): Promise<boolean> => {
  const res = await ajaxActions.putFile(`${BASE_URL}/files/${entityType}/${entityId}`, file);
  if (res.ok) {
    return true;
  }
  ToastS.generalError();
  return false;
};

const getFirstEntityFile = async (entityType: EntityType, entityId: string): Promise<Blob | null> => {
  const files = await getEntityFiles(entityType, entityId);
  if (files.length > 0) {
    return get(entityType, entityId, files[0].fileName);
  }
  return null;
};

const get = async (entityType: EntityType, entityId: string, fileName: string): Promise<Blob | null> => {
  const res = await ajaxActions.get(`${BASE_URL}/files/${entityType}/${entityId}/${fileName}`);
  if (res.ok) {
    return res.blob();
  }
  ToastS.generalError();
  return null;
};

const getFirstEntityFileDetails = async (entityType: EntityType, entityId: string): Promise<FileDetails | null> => {
  const fileDetails = await getEntityFiles(entityType, entityId);
  return fileDetails ? fileDetails[0] : null;
};

const getEntityFiles = async (entityType: EntityType, entityId: string): Promise<FileDetails[]> => {
  const res = await ajaxActions.get(`${BASE_URL}/files/${entityType}/${entityId}`);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return [];
};

const removeEntityFiles = async (entityType: EntityType, entityId: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/files/${entityType}/${entityId}`);
  if (res.ok) {
    return true;
  }
  ToastS.generalError();
  return false;
};

const remove = async (entityType: EntityType, entityId: string, fileName: string): Promise<boolean> => {
  const res = await ajaxActions.del(`${BASE_URL}/files/${entityType}/${entityId}/${fileName}`);
  if (res.ok) {
    return true;
  }
  ToastS.generalError();
  return false;
};

const getFileSize = (fileSizeInBytes: number) => {
  if (fileSizeInBytes >= 1000000000) {
    return [Math.floor(fileSizeInBytes / 1000000000), 'GB'];
  }
  if (fileSizeInBytes >= 1000000) {
    return [Math.floor(fileSizeInBytes / 1000000), 'MB'];
  }
  if (fileSizeInBytes >= 1000) {
    return [Math.floor(fileSizeInBytes / 1000), 'KB'];
  }
  return [fileSizeInBytes, 'Bytes'];
};

const isFileExists = async (entityType: EntityType, entityId: string): Promise<boolean> => {
  const file = await getFirstEntityFileDetails(entityType, entityId);
  return file !== undefined;
}

// eslint-disable-next-line import/prefer-default-export
export const FileS = {
  get,
  put,
  remove,
  removeEntityFiles,
  getEntityFiles,
  getFirstEntityFileDetails,
  getFirstEntityFile,
  getFileSize,
  isFileExists,
};
