import { FC } from 'react';
import { Select } from '@mantine/core';
import { GeneralTaskConfigDTO, WorkflowConfigProps } from '../../../../../Types/LogT';
import { WorkflowS } from '../../../../../Service/WorkflowS';
import { DataS } from '../../../../../Service/DataS';

const GeneralTaskConfig: FC<WorkflowConfigProps> = ({ config, handleChange, availableEntities }) => {
  const { taskReference } = config as GeneralTaskConfigDTO;
  const availableTasks = WorkflowS.filterEntities(availableEntities, ['tasks']);

  return (
    <Select
      clearable
      required
      label="Aufgabe"
      defaultValue={taskReference ? taskReference.id : null}
      onChange={(val) => handleChange({ taskReferenceId: val })}
      data={DataS.getEntityReferencesOptions(availableTasks)}
    />
  );
};

export default GeneralTaskConfig;
