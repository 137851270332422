import { useEffect, useState } from 'react';
import { MixpanelS } from '../MixpanelS';
import { ConstantS } from '../ConstantS';

interface ProgressProps {
  playedSeconds: number;
  played: number;
}

// eslint-disable-next-line import/prefer-default-export
export const useVideoMonitoring = (mediaId: string, trackProps: Record<string, unknown>) => {
  // Define what part of video have been already watched by user
  const [thresholds, setThresholds] = useState({
    quarted: false,
    half: false,
    threeQuarters: false,
  });
  // Define whenever user used seek functionality over the video
  const [seekUsed, setSeekUsed] = useState(false);

  // Reset values to default whenever new video is opened
  useEffect(() => {
    setThresholds({ quarted: false, half: false, threeQuarters: false });
    setSeekUsed(false);
  }, [mediaId]);

  const onProgress = (props: ProgressProps) => {
    const { quarted, half, threeQuarters } = thresholds;
    const { played } = props;
    if (played > 0.25 && !quarted) {
      setThresholds((prev) => ({ ...prev, quarted: true }));
      MixpanelS.track(ConstantS.TrackingEvents.AcademyVideoProgress, { ...trackProps, progress: '25%' });
    }
    if (played > 0.5 && !half) {
      setThresholds((prev) => ({ ...prev, half: true }));
      MixpanelS.track(ConstantS.TrackingEvents.AcademyVideoProgress, { ...trackProps, progress: '50%' });
    }
    if (played > 0.75 && !threeQuarters) {
      setThresholds((prev) => ({ ...prev, threeQuarters: true }));
      MixpanelS.track(ConstantS.TrackingEvents.AcademyVideoProgress, { ...trackProps, progress: '75%' });
    }
  };

  const onSeek = () => {
    if (!seekUsed) {
      setSeekUsed(true);
      MixpanelS.track(ConstantS.TrackingEvents.AcademyVideoSeeked, trackProps);
    }
  };

  return { onProgress, onSeek };
};
