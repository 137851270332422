import React, { useContext, useEffect, useState } from 'react';
import { EditableEmailProperty } from '../../../Atoms/EditableProperties/EditableEmailProperty';
import { EditableCheckbox } from '../../../Atoms/inputs/EditableCheckbox';
import { FileUploadModalDialog } from '../../Dialogs/FileUploadModalDialog';
import { fetchFileListOfEvent } from '../../../Service/restapi/fileService';
import { Text } from '@mantine/core';
import { useModalState } from '../../Dialogs';

export default function ({ styles, eventId, addAttachment, changeMessage, getEditableItem, saveMessage }) {
  const [eventFiles, setEventFiles] = useState(undefined);
  const { show, toggle } = useModalState(false);

  useEffect(() => {
    if (eventId) {
      fetchFilesOfEvent();
    }
  }, [eventId]);

  if (!eventId) {
    return null;
  }

  function fetchFilesOfEvent() {
    fetchFileListOfEvent(eventId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((fileList) => {
            fileList.forEach((file) => {
              file.entityId = eventId;
              file.entityType = 'events';
            });
            setEventFiles(fileList);
          });
        } else {
          console.error('could not fetch event files');
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not fetch event files');
      });
  }

  return (
    <div style={styles.group}>
      <div style={styles.tableHead}>
        <div>Dateien aus diesem Event</div>
        <div />
      </div>
      <div style={styles.listAndUploadContainer}>
        <div>
          {!eventFiles && <div>Laden...</div>}
          {eventFiles
            ? eventFiles.map((item, index) => (
                <EditableEmailProperty
                  key={index}
                  patchField="selected"
                  preChange={(p) => addAttachment(item)}
                  changeHandler={changeMessage}
                  email={getEditableItem(item)}
                  placeholder={item.fileName}
                  editOnly
                  editFormatter={EditableCheckbox}
                />
              ))
            : null}
        </div>
        <FileUploadModalDialog
          show={show}
          toggle={toggle}
          text="Hier kannst Du Dateien zum Event hineinziehen"
          serverUrl={`${process.env.REACT_APP_FILE_SERVICE_URL}/files/events/${eventId}`}
          reloadCallback={(fileName) => {
            saveMessage(addAttachment({ entityType: 'events', entityId: eventId, fileName }));
            fetchFilesOfEvent();
          }}
        />
      </div>
      <Text mt="sm" onClick={toggle} color="blue" variant="link" sx={{ cursor: 'pointer' }}>
        + Datei hochladen
      </Text>
    </div>
  );
}
