import { ActionIcon, Box, Flex, NumberInput } from '@mantine/core';
import { IconMinus, IconTrash, IconPlus } from '@tabler/icons';
import { FC } from 'react';

interface Props {
  count: number;
  onChange: (count: number) => void;
  min?: number;
  deleteDisabled?: boolean;
  onLastRemoved?: () => void;
}

const NumberCounter: FC<Props> = ({ count, min = 0, deleteDisabled, onChange, onLastRemoved }) => {
  const showingTrashIcon = count <= min + 1;
  const decrement = () => onChange(count - 1);
  const increment = () => onChange(count + 1);

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
        textAlign: 'center',
        width: 100,
        maxWidth: 150,
        padding: 0,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
      })}
    >
      <Flex align="center" justify="center">
        <ActionIcon disabled={showingTrashIcon && deleteDisabled} variant="transparent" color="dark">
          {!showingTrashIcon && <IconMinus size={16} onClick={decrement} />}
          {showingTrashIcon && <IconTrash size={16} onClick={onLastRemoved} />}
        </ActionIcon>
        <NumberInput
          size="xs"
          hideControls
          value={count}
          onChange={onChange}
          sx={{ minWidth: 40, input: { textAlign: 'center', borderRadius: 0 }, justifyContent: 'center' }}
        />
        <ActionIcon
          variant="filled"
          color="blue"
          onClick={increment}
          sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        >
          <IconPlus size={16} />
        </ActionIcon>
      </Flex>
    </Box>
  );
};

export default NumberCounter;
