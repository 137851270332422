import { createContext, useEffect, useMemo, useState } from 'react';
import { addYears } from 'date-fns';
import { Outlet } from 'react-router-dom';
import { useLocalStorage } from '@mantine/hooks';
import BookingEvent, { EventsFilter, EventsView } from '../../Types/Event';
import { EventS } from '../EventS';
import { MixpanelS } from '../MixpanelS';
import { ConstantS } from '../ConstantS';
import { useJwtClaims } from './AuthContext';
import { CustomEventState } from '../../Types/EventT';

interface EventsContextInterface {
  bookingEvents: BookingEvent[];
  loading: boolean;
  filter: EventsFilter;
  totalCount: number | undefined;
  networkError: boolean;
  updateFilter: (patch: Partial<EventsFilter>) => unknown;
  view: EventsView;
  setView: (newView: EventsView) => unknown;
}

export const EventsContext = createContext<EventsContextInterface>({} as EventsContextInterface);

export const EventsContextProvider = () => {
  const { profileId, licence } = useJwtClaims();
  const [loading, setLoading] = useState(false);
  const [bookingEvents, setBookingEvents] = useState<BookingEvent[]>([]);
  const [totalCount, setTotalCount] = useState<number>();
  const [networkError, setNetworkError] = useState(false);
  const [view, setView] = useState<EventsView>('list');

  const [showEventsWithoutRecommendations, setShowAllEvents] = useLocalStorage({
    key: 'showEventsWithoutRecommendations',
    defaultValue: false,
  });

  const [filter, setFilter] = useState<EventsFilter>({
    from: new Date(),
    to: addYears(new Date(), 5),
    sorting: 'chronological',
    answersOfInterest: ['AVAILABLE'],
    profiles: [profileId],
    showAllEvents: showEventsWithoutRecommendations,
    agencyModeActive: false,
    filterCustomerContactShared: false,
    statesOfInterest: [],
    customStatesOfInterest: [],
  });

  useEffect(() => {
    EventS.getDefaultFilterStates(licence).then(updateFilter);
  }, []);

  const { agencyModeActive, showAllEvents, sorting } = filter;

  useEffect(() => {
    setLoading(true);
    const abortController = new AbortController();
    let fetchEvents: () => Promise<BookingEvent[]>;

    if (agencyModeActive) {
      fetchEvents = () => EventS.fetchRecommendations(filter, setTotalCount, abortController.signal);
    } else if (sorting === 'chronological') {
      fetchEvents = () => EventS.fetchEvents(filter, setTotalCount, abortController.signal);
    } else {
      fetchEvents = () => EventS.fetchEventsSorted(filter, abortController.signal);
    }
    fetchEvents()
      .then(setBookingEvents)
      .catch(() => {
        if (!abortController.signal.aborted) {
          setNetworkError(true);
        }
      })
      .finally(() => {
        if (!abortController.signal.aborted) {
          setLoading(false);
        }
      });

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  // Update localstorage setting after option is changed
  useEffect(() => {
    if (showAllEvents !== showEventsWithoutRecommendations) {
      setShowAllEvents(showAllEvents);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAllEvents]);

  useEffect(() => {
    if (agencyModeActive) {
      MixpanelS.track(ConstantS.TrackingEvents.AgencyModeActivated);
    }
  }, [agencyModeActive]);

  useEffect(() => {
    if (sorting === 'createdAt') {
      MixpanelS.track(ConstantS.TrackingEvents.EventsFilteredByCreationDate);
    }
  }, [sorting]);

  const updateFilter = (patch: Partial<EventsFilter>) => setFilter((prev) => ({ ...prev, ...patch }));

  const value = useMemo(
    () => ({
      bookingEvents,
      loading,
      filter,
      totalCount,
      networkError,
      view,
      setView,
      updateFilter,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bookingEvents, loading, filter, totalCount, networkError, view],
  );

  return (
    <EventsContext.Provider value={value}>
      <Outlet />
    </EventsContext.Provider>
  );
};
