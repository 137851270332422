import { useLingui } from '@lingui/react';
import { FC } from 'react';
import { InputField } from '../../Atoms/InputFields';
import { LoadingAnimation } from '../../Service/ResourceAdapter';

type Props = {
  items: { id: string; documentType: string }[];
  selectedItems: string[];
  toggleItem: (id: string) => void;
};

const MultipleItemSelector: FC<Props> = ({ items, selectedItems, toggleItem }) => {
  const { i18n } = useLingui();

  return (
    <div>
      {!items && <LoadingAnimation style={undefined} />}
      {items
        ? items.map((item) => {
            const checked = selectedItems.find((documentId) => documentId === item.id);
            return (
              <InputField
                key={item.id}
                checked={!!checked}
                toggle={() => {
                  toggleItem(item.id);
                }}
                text={i18n._(`document.${item.documentType.toLowerCase()}`)}
              />
            );
          })
        : null}
    </div>
  );
};

export default MultipleItemSelector;
