import { ajaxActions } from './AjaxActions';
import { fetchFile } from './restapi/fileService';
import { ToastS } from './ToastS';

export const file = {
  download,
  downloadIcal,
  setAsNonDraft,
};

function download(url, fileName) {
  let filename = '';
  if (fileName) {
    filename = fileName;
  }
  return ajaxActions.getBlobResponse(url).then((resp) => {
    resp
      .blob()
      .then((blob) => {
        blobToFile(blob, resp.headers, filename);
      })
      .catch((resp) => {
        console.error(`Could not load blob from response of url ${url}`);
        throw resp;
      })
      .finally(() => resp);
  });
}

export const downloadFile = (entityType, entityId, fileName) => {
  fetchFile(entityType, entityId, fileName)
    .then((resp) => {
      if (resp.ok) {
        resp.blob().then((blob) => {
          blobToFile(blob, resp.headers, fileName);
        });
        ToastS.success('download.file', 'Datei heruntergeladen');
      } else {
        ToastS.error('download.file', 'Datei konnte nicht heruntergeladen werden');
      }
    })
    .catch((error) => {
      console.error({ error });
      console.error(`file ${fileName} with entityId ${entityId} of type ${entityType} could not be loaded`);
    });
};

export const blobToFile = (blob, headers, filename) => {
  const disposition = headers.get('Content-Disposition');
  if (disposition) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }

  let mediaType = 'application/octet-stream';
  if (filename.includes('.pdf')) {
    mediaType = 'application/pdf';
  }
  const url = window.URL.createObjectURL(new Blob([blob], { type: mediaType }));
  const link = document.createElement('a');
  link.href = url;
  if (filename === '') {
    filename = 'Document.pdf';
  }
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

function setAsNonDraft(documentId) {
  return ajaxActions
    .exchange('PUT', `${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/transform/setAsNonDraft`, {
      documentIds: [documentId],
    })
    .then((resp) => resp)
    .catch(() => {
      throw new Error('server unavailable');
    });
}

function downloadIcal(url) {
  let filename = '';
  return fetch(url, {
    method: 'GET',
    credentials: 'include',
    responseType: 'text/calendar',
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob], { type: 'text/calendar' }));
      const link = document.createElement('a');
      link.href = url;
      if (filename === '') {
        filename = 'Event.ics';
      }
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
    })
    .catch((response) => {});
}
