import React from 'react';
import Batch from '../../Atoms/Batch';

const EventBookingStateSVG: React.FC<{ state: string }> = ({ state }) => {
  if (state === 'OPEN') {
    return <Batch classNames="unknown" text="Offen" />;
  }

  if (state === 'OFFERED') {
    return <Batch classNames="blocked" text="Option" />;
  }

  if (state === 'BOOKED') {
    return <Batch classNames="booked" text="Bestätigt" />;
  }

  if (state === 'CANCELED') {
    return <Batch classNames="canceled" text="Annulliert" />;
  }

  if (state === 'UNAVAILABLE') {
    return <Batch classNames="unavailable" text="Nicht verfügbar" />;
  }

  return null;
};

export default EventBookingStateSVG;
