import EmailFileAttachment from './Message/EmailFileAttachment';
import EmailDocumentAttachment from './Message/EmailDocumentAttachment';
import { Group } from '@mantine/core';

const Attachments = ({ eventId, attachments, documentBlueprints, deleteFileAttachment, deleteDocumentAttachment }) => {
  const generatedFilesTable = [];

  if (attachments) {
    attachments.map((attachment, index) => {
      generatedFilesTable.push(
        <EmailFileAttachment
          key={`file-${attachment}`}
          deleteAtIndex={deleteFileAttachment}
          index={index}
          attachment={attachment}
          eventId={eventId || undefined}
        />,
      );
    });
  }

  if (documentBlueprints) {
    documentBlueprints.map((attachment, index) => {
      generatedFilesTable.push(
        <EmailDocumentAttachment
          key={`doc-${attachment}`}
          deleteAtIndex={deleteDocumentAttachment}
          eventId={eventId || undefined}
          index={index}
          attachment={attachment}
        />,
      );
    });
  }
  
  return <Group spacing="xs">{generatedFilesTable}</Group>;
};

export default Attachments;
