import { useLingui } from '@lingui/react';
import { useLocation } from 'react-router-dom';
import { ButtonLinkWithIcon } from '../../Atoms/Buttons';
import PdfPreview from '../../Molecules/Document/PdfPreviewNew';
import PanePaper from '../../Molecules/PanePaper';
import { IconPaperclip, IconX } from '@tabler/icons';
import { Group, Text } from '@mantine/core';

const EmailDocumentAttachment = ({ eventId, attachment, deleteAtIndex, index }) => {
  const { i18n } = useLingui();
  const { pathname } = useLocation();

  if (!pathname.includes('emails')) {
    attachment.fileName = i18n._(`document.${attachment.documentType.toLowerCase()}`);
  }

  return (
    <Group p="xs" sx={(theme) => ({ borderRadius: theme.radius.sm, backgroundColor: theme.colors.gray[0] })}>
      {!pathname.includes('emails') && <PdfPreview file={attachment} eventId={eventId} />}

      {/* 
      <div style={styles.fileName}>{attachment.fileName}</div> */}

      {!pathname.includes('emails') && (
        <div>{attachment.templateName && <div>Vorlage: {attachment.templateName}</div>}</div>
      )}

      {deleteAtIndex && <IconX size={16} onClick={() => deleteAtIndex(index)} style={{ cursor: 'pointer' }} />}
    </Group>
  );
};

export default EmailDocumentAttachment;
