import { useEffect, useState } from 'react';
import { IconTrash } from '@tabler/icons';
import FileUpload from '../../../Organismns/FileUpload';
import { ConfirmDialog, useModalState } from '../../../Molecules/Dialogs';
import PanePaper from '../../../Molecules/PanePaper';
import { PdfS } from '../../../Service/PdfS';
import { Image } from '@mantine/core';
import { ConstantS } from '../../../Service/ConstantS';
import { MixpanelS } from '../../../Service/MixpanelS';

export const MediaUpload = ({ title, text, kind, lastModified, setLastModified, setLogoUploaded, showPreview }) => {
  const [available, setAvailable] = useState(false);
  const { show, toggle } = useModalState(false);
  const [blob, setBlob] = useState();

  useEffect(() => {
    reload();
  }, [lastModified]);

  useEffect(() => {
    if (showPreview && available) {
      PdfS.getLayoutMedia(kind).then((_blob) => {
        if (_blob) {
          setBlob(_blob);
        }
      });
    }
  }, [showPreview, available]);

  function deleteMedia() {
    if (kind !== null) {
      fetch(`${process.env.REACT_APP_PDF_SERVICE_URL}/layout/media/${kind}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: { Accept: 'application/json' },
      }).then((response) => {
        toggle();
        setBlob(undefined);
        reload();
        setLastModified(new Date().toString());
      });
    }
  }

  function lookUpAvailability() {
    fetch(`${process.env.REACT_APP_PDF_SERVICE_URL}/layout/media/${kind}`, {
      method: 'HEAD',
      credentials: 'include',
    })
      .then((response) => response.headers)
      .then((headers) => {
        const available = parseInt(headers.get('X-Total-Count')) === 1;
        setAvailable(available);
        if (kind === 'logo') {
          setLogoUploaded(available);
        }
      })
      .catch((err) => {
        console.error(`fetch error${err}`);
      });
  }

  function reload() {
    lookUpAvailability();
    setLastModified(new Date().toString());
  }

  return (
    <>
      <PanePaper title={title} rightSection={available && <IconTrash onClick={toggle} style={{ cursor: 'pointer' }} />}>
        {(!blob || !showPreview) &&
          <FileUpload
            kind="notepaper"
            reloadCallback={() => {
            MixpanelS.track(ConstantS.TrackingEvents.LogoUploaded);
            reload();
          }}
            serverUrl={`${process.env.REACT_APP_PDF_SERVICE_URL}/layout/media/${kind}`}
            text={text}
          />
        }
        {blob && <Image height={150} width="100%" fit="contain" src={URL.createObjectURL(blob)} />}
      </PanePaper>
      <ConfirmDialog
        text={`layout.${kind}.delete.confirm`}
        subject={`layout.${kind}.delete.confirm.head`}
        open={show}
        toggle={toggle}
        handler={deleteMedia}
      />
    </>
  );
};
