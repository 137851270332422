import { useContext, useEffect, useState } from 'react';
import { Box, LoadingOverlay, Space } from '@mantine/core';
import { useNavigate } from 'react-router-dom';
import { useLingui } from '@lingui/react';
import DocumentHeadData from '../../Molecules/DocumentForm';
import DocumentSkeleton from '../../Organismns/Document/DocumentSkeleton';
import { DocumentContext } from '../../Service/Context/DocumentContext';
import { getContactById } from '../../Service/restapi/contactService';
import PanePaper from '../../Molecules/PanePaper';
import DocumentActions from '../../Molecules/Document/DocumentActions';
import DocumentHeadTemplate from '../DocumentHeadTemplate';
import { GeneralCustomerSelect } from '../../Atoms/inputs/CustomerSelect';

const DocumentForm = () => {
  const { i18n } = useLingui();
  const { document, handleChange, addPosition, savePatch } = useContext(DocumentContext);
  const { id: documentId, eventId, customerId } = document;
  const [contact, setContact] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (documentId && eventId) {
      navigate(`/events/${eventId}/documents/${documentId}`);
    }
  }, [documentId, eventId]);

  useEffect(() => {
    getContactById(document.customerId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then(setContact);
        } else {
          console.error(`could not load contact ${document.customerId}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not load contact ${document.customerId}`);
      });
  }, [document.customerId]);

  const documentType = i18n._(`document.${document.documentType.toLowerCase()}`);

  return (
    <PanePaper>
      <DocumentHeadTemplate
        entityId={document.id}
        entityType="documents"
        title={`${documentType} erstellen`}
        previewUrl={
          eventId ? `/events/${eventId}/documents/${document.id}/preview` : `/documents/${document.id}/preview`
        }
        documentActions={<DocumentActions />}
      />
      <LoadingOverlay visible={!contact} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Space h="md" />
      <Box p="xl">
        <DocumentHeadData />
        <DocumentSkeleton
          _document={document}
          customer={contact}
          handleChange={handleChange}
          addPosition={addPosition}
          savePatch={savePatch}
        />
      </Box>
    </PanePaper>
  );
};

export default DocumentForm;
