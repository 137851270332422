import { FC, useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { ActionIcon, Checkbox, Chip, Badge, Text, Grid, Group, MantineColor, Space } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { useMediaQuery } from '@mantine/hooks';
import { useNavigate } from 'react-router-dom';
import { Trans } from '@lingui/react';
import { CustomModalHeader, useModalState } from '../../Molecules/Dialogs';
import { Icon } from '../Icons';
import { AgendaItem } from '../../Types/AgendaItem';
import BookingEvent from '../../Types/Event';
import Contact from '../../Types/Contact';
import { fetchEventById } from '../../Service/restapi/eventService';
import { fetchContactById } from '../../Service/restapi/contactService';
import { deleteTask } from '../../Service/restapi/taskService';
import { TaskListContext } from '../../Service/Context/TaskListContext';
import { isBeforeToday, isNDaysOrLessAfterToday, isToday, parseDate } from '../../Utils/dateUtils';
import useTaskConfirmDeleteModal from '../../Molecules/Event/Tasks/TaskConfirmDelete';
import { WindowContext } from '../../Service/Context/WindowContext';
import { getBookingStateColor } from '../../Types/Enums/BookingState';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import AutomaticLinkText from '../AutomaticLinkText';
import { InputHelp } from './InputHelp';

type Props = {
  task: AgendaItem;
  finishCallback: (patch: any) => Promise<any>;
  value: boolean;
  disabled: boolean;
  patchField: string;
};

const EditableTaskListItem: FC<Props> = ({
  finishCallback,
  value: checked,
  disabled,
  patchField,
  task: initialTask,
}) => {
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState<AgendaItem>(initialTask);

  const [event, setEvent] = useState<BookingEvent | undefined>();
  const [contact, setContact] = useState<Contact | undefined>();

  const { windowWidth } = useContext(WindowContext);

  const sm = useMediaQuery('(min-width: 768px)');

  const { refresh, taskListContent } = useContext(TaskListContext);

  const getGroupById = (groupId: string) => taskListContent.taskGroups.find((g) => g.id === groupId);

  const { openContextModal, closeAll } = useModals();

  const { openConfirmDeleteModal } = useTaskConfirmDeleteModal();

  const navigate = useNavigate();

  const { profile } = useContext<any>(ProfileContext);

  useEffect(() => {
    if (task.entityType === 'events') {
      fetchEventById(task.entityId)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((e) => {
              setEvent(e);
            });
          } else {
            console.info(`could not load event ${task.entityId}`);
          }
        })
        .catch(() => {
          console.info(`could not load event ${task.entityId}`);
        });
    } else {
      setEvent(undefined);
    }
  }, [task.entityId, task.entityType]);

  useEffect(() => {
    if (event?.customerId) {
      fetchContactById(event.customerId.toString()).then((contact: any) => {
        setContact(contact);
      });
    }
  }, [event]);

  if (!task) {
    return null;
  }

  const getDueDateAsDate = () => (task.dueDate !== undefined ? parseDate(task.dueDate) : null);

  const getDueDateColor = (): MantineColor => {
    const date = getDueDateAsDate();

    if (!date) return '#e2e2e2';

    if (isBeforeToday(date) || isToday(date)) return '#ff5c5a';
    if (isNDaysOrLessAfterToday(3, date)) return '#fda632';

    return 'blue';
  };

  const openTaskEditModal = () =>
    openContextModal('taskEditModal', {
      title: (
        <CustomModalHeader>
          <Trans id="task.edit" />
        </CustomModalHeader>
      ),
      innerProps: {
        profile,
        initialTask: task,
        entityType: 'events',
        group: task.groupId ? getGroupById(task.groupId) : undefined,
        finish: (updatedTask: AgendaItem) => {
          if (updatedTask) {
            setTask(updatedTask);
          } else {
            refresh();
          }
        },
        showEntityTypeSelection: true,
      },
      centered: true,
    });

  const toggleCompleted = () => {
    setLoading(true);
    finishCallback({ [patchField]: !checked })
      .catch((e: any) => setError(e.message))
      .then(() => setLoading(false));
  };

  const deleteThisTask = () => {
    closeAll();
    setLoading(true);
    deleteTask(task.id)
      .then((_) => {
        refresh();
      })
      .finally(() => setLoading(false));
  };

  const onDeleteClick = () => openConfirmDeleteModal(deleteThisTask, closeAll);

  const onEventChipClicked = () => navigate(`/events/${event?.id}/details`);

  return (
    <Grid align={sm ? 'center' : 'flex-start'} columns={24}>
      <Grid.Col xl={0.5} md={1} xs={2} span={2}>
        <Checkbox
          radius="xl"
          size="md"
          color="green"
          onChange={toggleCompleted}
          disabled={disabled}
          checked={checked}
        />
      </Grid.Col>
      <Grid.Col xl={23} md={22} xs={20} span={20}>
        <Grid align="center" sx={{ paddingLeft: 10 }}>
          <Grid.Col md={6} xs={9} onClick={openTaskEditModal} sx={{ cursor: 'pointer' }}>
            <Text
              size="md"
              className="headline"
              color={checked ? 'dimmed' : undefined}
              sx={{ textDecoration: checked ? 'line-through' : 'none' }}
            >
              {task.priority && <Icon className="important-badge" value="CAUTION" size="xs" />}
              {task.recurringTaskId && <Icon className="recurring-badge" value="REPEAT" size="xs" />}
              {task.name}
            </Text>
            {task.notes && (
              <>
                <Space h="xs" />
                <Text
                  size="xs"
                  className="notice-text"
                  color={checked ? 'dimmed' : '#838383'}
                  sx={{
                    textDecoration: checked ? 'line-through' : 'none',
                  }}
                >
                  <AutomaticLinkText text={task.notes} />
                </Text>
              </>
            )}
          </Grid.Col>
          <Grid.Col md={6} xs={10}>
            <Group spacing="xs" position={windowWidth > 768 ? 'right' : undefined}>
              {task && task.dueDate && (
                <Badge
                  sx={{
                    textTransform: 'none',
                    fontWeight: 200,
                    color: 'white',
                    borderColor: getDueDateColor(),
                    backgroundColor: getDueDateColor(),
                  }}
                  leftSection={<Icon value="OFFERED_CIRCLE" />}
                  size="md"
                  variant="filled"
                >
                  Erledigen bis <Moment format="DD.MM.YYYY">{task.dueDate}</Moment>
                </Badge>
              )}
              {event && event.dates && contact && (
                <Badge
                  sx={{
                    textTransform: 'none',
                    cursor: 'pointer',
                    fontWeight: 200,
                    color: 'black',

                    backgroundColor: '#e2e2e2',
                    borderColor: '#e2e2e2',
                  }}
                  size="md"
                  variant="filled"
                  onClick={onEventChipClicked}
                >
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <span className="dot" style={{ backgroundColor: getBookingStateColor(event.bookingState) }} />

                    <Text weight={700} sx={{ paddingRight: 5, paddingLeft: 5 }}>
                      {contact.personDescription}
                    </Text>
                    <Text> {event.kind} am </Text>
                    <Moment style={{ paddingLeft: 3 }} format="DD.MM.YYYY">
                      {event.dates?.at(0)?.date}
                    </Moment>
                  </div>
                </Badge>
              )}
              {task && task.groupId && (
                <Badge
                  sx={{
                    textTransform: 'none',
                    backgroundColor: '#e2e2e2',
                    borderColor: '#e2e2e2',
                    color: 'black',
                    fontWeight: 200,
                  }}
                  size="md"
                >
                  {getGroupById(task.groupId)?.name}
                </Badge>
              )}
            </Group>
          </Grid.Col>
        </Grid>
      </Grid.Col>
      <Grid.Col xl={0.5} md={1} xs={2} span={2}>
        <ActionIcon variant="transparent" color="gray" onClick={onDeleteClick}>
          <Icon value="TRASH" />
        </ActionIcon>
        {error && <InputHelp errorDescription={error} />}
      </Grid.Col>
    </Grid>
  );
};
export default EditableTaskListItem;
