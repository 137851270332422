import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { BankAccountContext } from '../../Service/Context/BankAccountContext';
import { EditableProperty } from './EditableProperty';
import { AuthContext } from '../../Service/Context/AuthContext';

export const EditableBankAccountProperty = ({
  bankAccount,
  patchField,
  placeholder,
  editFormatter,
  labelFormatter,
}) => {
  const { handleBankAccountChange } = useContext(BankAccountContext);
  const { jwtClaims } = useContext(AuthContext);
  const { profileId } = jwtClaims;

  return (
    <EditableProperty
      dto={bankAccount}
      changeHandler={handleBankAccountChange}
      patchField={patchField}
      placeholder={placeholder}
      targetUrl={`${process.env.REACT_APP_AUTH_SERVICE_URL}/profiles/${profileId}/bankAccount`}
      editFormatter={editFormatter}
      labelFormatter={labelFormatter}
    />
  );
};

EditableBankAccountProperty.propTypes = {
  patchField: PropTypes.string.isRequired,
  bankAccount: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lastModified: PropTypes.string.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
};
