import { useContext } from 'react';
import ContactList from '../ContactList';
import PanePaper from '../../Molecules/PanePaper';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import { openSelectCreateContactModal } from '../Contact/SelectCreateContactModal';
import { EventParticipationsContext } from '../../Service/Context/EventParticipationContext';
import { EventContext } from '../../Service/Context/EventContext';

const CustomerPane = () => {
  const { saveParticipant, addParticipation } = useContext(EventParticipationsContext) as any;
  const { event, handleChange } = useContext(EventContext) as any;
  const { id: eventId } = event;

  const persistEventParticipation = (contactId: number) => {
    const eventParticipantKey = { contactId };
    if (!eventId) {
      handleChange({ customerId: contactId });
      addParticipation(eventParticipantKey);
    } else {
      saveParticipant(eventParticipantKey);
    }
  };

  return (
    <PanePaper
      withShadow
      withoutBorder
      title="common.contacts"
      tooltip="help.event.contacts.pane"
      rightSection={<BookitupAddIcon onClick={() => openSelectCreateContactModal(persistEventParticipation)} />}
    >
      <ContactList />
    </PanePaper>
  );
};

export default CustomerPane;
