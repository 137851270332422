// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { ActionIcon, Alert, Box, Center, CopyButton, Group, Space, Text, Tooltip } from '@mantine/core';
import { IconCheck, IconCopy, IconInfoCircle } from '@tabler/icons';
import { FC, useMemo, useState } from 'react';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  TelegramIcon,
  EmailIcon,
} from 'react-share';
import BookitupSegmentedControl from '../../../Atoms/BookitupSegmentedControl';
import { DataS } from '../../../Service/DataS';

const ICON_SIZE = 40;

const ShareCampaignModal: FC<{ campaignId: string; userName: string }> = ({ campaignId, userName }) => {
  const campaignLink = `${process.env.REACT_APP_FRONTEND_URL}/register?campaign=${campaignId}`;
  const messageTitle = 'bookitup Eventverwaltung';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const sharedMessagesOptions = useMemo(() => DataS.getSharedMessageGroupCategoryOptions(userName), [campaignLink]);
  const [contentMessage, setMessage] = useState(sharedMessagesOptions[0].value);

  return (
    <>
      <Alert
        mb="xs"
        icon={<IconInfoCircle size={16} />}
        sx={(theme) => ({ backgroundColor: theme.colors.lightBlue[0] })}
      >
        Kopiere z.B. einen unserer Beispieltexte oder teile ihn direkt per Social Media.
      </Alert>
      <Center>
        <BookitupSegmentedControl data={sharedMessagesOptions} onChange={setMessage} />
      </Center>
      <Box
        mt="sm"
        mb="md"
        sx={(theme) => ({ backgroundColor: theme.colors.gray[0], borderRadius: 10, position: 'relative' })}
      >
        <Box sx={{ position: 'absolute', right: 5, top: 5 }}>
          <CopyButton value={`${contentMessage}${campaignLink}`} timeout={2000}>
            {({ copied, copy }) => (
              <Tooltip label={copied ? 'Kopiert' : 'Nachricht kopieren'} withArrow position="right">
                <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                  {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" color="black" />}
                </ActionIcon>
              </Tooltip>
            )}
          </CopyButton>
        </Box>
        <Text italic p="md" pr="xl" size="xs" sx={{ minHeight: 130, whiteSpace: 'pre-line' }}>
          {`${contentMessage}${campaignLink}`}
        </Text>
      </Box>
      <Space h="xl" />
      <Group position="center">
        <WhatsappShareButton url={campaignLink} title={contentMessage}>
          <WhatsappIcon round size={ICON_SIZE} />
        </WhatsappShareButton>
        <FacebookShareButton url={campaignLink} hashtag="#bookitup">
          <FacebookIcon round size={ICON_SIZE} />
        </FacebookShareButton>
        <TelegramShareButton url={campaignLink} title={contentMessage}>
          <TelegramIcon round size={ICON_SIZE} />
        </TelegramShareButton>
        <TwitterShareButton url={campaignLink} title={`${messageTitle}\n\n`} hashtags={['bookitup', 'eventverwaltung']}>
          <TwitterIcon round size={ICON_SIZE} />
        </TwitterShareButton>
        <EmailShareButton url={campaignLink} subject={messageTitle} body={contentMessage}>
          <EmailIcon round size={ICON_SIZE} />
        </EmailShareButton>
      </Group>
    </>
  );
};

export default ShareCampaignModal;
