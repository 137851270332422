import { Badge, Box, Button, Group, Image, Skeleton, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { DefaultPlanning, Planning } from '../../Types/ResourceT';
import { FileS } from '../../Service/FileS';
import IncDecCounter from '../../Atoms/inputs/IncDecCounter';
import ResourcePlanningS from '../../Service/restapi/resourcePlanningS';

interface Props {
  planning: Planning | DefaultPlanning;
  refresh: () => unknown;
  defaultPlannings: boolean;
}

// eslint-disable-next-line import/prefer-default-export
export const openPlanningDrawerModal = (props: Props) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl" align="center">
        Planung bearbeiten
      </Text>
    ),
    children: <PlanningDrawerModal {...props} />,
  });
};

const PlanningDrawerModal: FC<Props> = ({ planning, refresh, defaultPlannings }) => {
  const { id: planningId, resource, plannedQuantity } = planning;
  const { id: resourceId, name, description, category } = resource;
  const [quantity, setQuantity] = useState(plannedQuantity);
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState<Blob>();

  useEffect(() => {
    if (resourceId) {
      setLoading(true);
      FileS.getFirstEntityFile('resources', resourceId)
        .then((imgBlob) => imgBlob && setPhoto(imgBlob))
        .finally(() => setLoading(false));
    }
  }, [resourceId]);

  const onClick = async () => {
    let resJSON: Planning | DefaultPlanning | null;
    if (defaultPlannings) {
      resJSON = await ResourcePlanningS.updateDefaultPlanning(planningId, { plannedQuantity: quantity });
    } else {
      resJSON = await ResourcePlanningS.updatePlanning(planningId, { plannedQuantity: quantity });
    }
    if (resJSON) {
      refresh();
      closeAllModals();
    }
  };

  return (
    <>
      <Skeleton visible={loading} sx={{ borderTopLeftRadius: 25, borderTopRightRadius: 25 }}>
        <Image
          fit="revert"
          height={150}
          src={photo ? URL.createObjectURL(photo) : '/img/fallback-resource-image.jpg'}
          sx={{ borderBottom: '2px solid #e2e2e2' }}
        />
      </Skeleton>
      <Box p="sm">
        <Text size="xl" weight="bolder">
          {name}
        </Text>
        {category && (
          <Badge mt="sm" radius="sm" sx={{ backgroundColor: '#585858', color: 'white' }}>
            <Text weight="lighter" size={10}>
              {category.name}
            </Text>
          </Badge>
        )}
        {description && <Text mt="md">{description}</Text>}
        <Group spacing="xl" position="center" mt={50}>
          <IncDecCounter deleteDisabled initialCount={quantity} onChanged={setQuantity} />{' '}
          <Button disabled={quantity === plannedQuantity} onClick={onClick}>
            {i18n._('actions.save')}
          </Button>
        </Group>
      </Box>
    </>
  );
};
