import { useContext, useState } from 'react';
import EmailHistoryList from '../../Organismns/Message/EmailHistoryList';
import { ModalFlowParent } from '../../Organismns/ModalFlow/ModalFlowParent';
import PaginationNav from '../../Molecules/Navigation/PaginationNav';
import { EmailBoxContext } from '../../Service/Context/EmailBoxContext';
import { useParams, useSearchParams } from 'react-router-dom';
import EmailsListHeader from '../../Organismns/Message/EmailsListHeader';
import { Box } from '@mantine/core';

const EmailMasterDetail = () => {
  const { pageNumber, totalCount, pageCount, setPageNumber, messages } = useContext(EmailBoxContext);
  const [searchParams] = useSearchParams();
  const { id: eventId } = useParams();
  const [showMessageTemplateModal, setMessageTemplateModal] = useState(!!searchParams.get('message_template'));
  const emptyBox = messages.length === 0;

  return (
    <Box pos="relative" sx={{ minHeight: 5 * 95 + 120, height: messages.length * 95 + 150 }}>
      <EmailsListHeader desktop />
      <EmailHistoryList />
      {!emptyBox && (
        <Box pos="absolute" sx={{ bottom: 10, width: '100%' }}>
          <PaginationNav
            pageSize={10}
            pageNumber={pageNumber}
            totalCount={totalCount}
            totalPages={pageCount}
            onChange={(newPageNumber) => setPageNumber(newPageNumber - 1)}
            entityName="Nachricht"
          />
        </Box>
      )}
      {eventId && (
        <ModalFlowParent
          show={showMessageTemplateModal}
          toggle={() => {
            setMessageTemplateModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default EmailMasterDetail;
