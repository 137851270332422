import { FC } from 'react';
import { Text } from '@mantine/core';
import { CreateShareConfigDTO } from '../../../../../Types/LogT';

const CreateShare: FC<{ config: unknown }> = ({ config }) => {
  const { name, password, randomPassword } = config as CreateShareConfigDTO;

  if (!name || (!password && !randomPassword)) {
    return (
      <Text italic color="red">
        Name für das Portal fehlt
      </Text>
    );
  }
  return (
    <Text>
      Online-Portal{' '}
      <Text span weight="bolder">
        {name}
      </Text>{' '}
      erstellen.
    </Text>
  );
};

export default CreateShare;
