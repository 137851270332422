import { useEffect, useState } from 'react';
import {
  deleteCustomAttributeGroup,
  fetchCustomAttributesOfGroup,
} from '../../../Service/restapi/customAttributeService';
import { useConfirmModalState, useModalState } from '../../Dialogs';
import { Accordion, Stack, Text } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons';

const CustomAttributeGroup = ({
  index,
  group,
  entityId,
  entityType,
  customAttributeFormatter,
  refreshGroups,
  refreshValue,
  ...rest
}) => {
  const [attributes, setAttributes] = useState(undefined);
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);
  const { show, toggle } = useModalState(false);

  const { id, name, openAutomatically } = group;

  const [lastModified, setLastModified] = useState(new Date());

  useEffect(() => {
    loadCustomAttributes();
  }, [lastModified, group]);

  if (!attributes) {
    return null;
  }

  function handleChange(patch) {
    const tmoAttr = Object.assign([], attributes);
    const attr = tmoAttr.find((a) => a.id === patch.id);
    const indexOfAttr = tmoAttr.indexOf(attr);
    tmoAttr.splice(indexOfAttr, 1, patch);
    setAttributes(tmoAttr);
  }

  const styles = {
    group: {
      border: show ? '1px solid rgba(0, 0, 0, .125)' : 'unset',
      borderRadius: 4,
      padding: 5,
    },
    groupName: {
      marginTop: -14,
      backgroundColor: '#ffffff',
      paddingLeft: 2,
      paddingRight: 2,
      border: '1px solid rgba(0, 0, 0, .125)',
      borderRadius: 4,
      fontSize: 11,
      display: 'flex',
      justifyContent: 'space-between',
    },
    groupFields: {
      margin: 5,
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'column',
    },
  };

  function loadCustomAttributes() {
    fetchCustomAttributesOfGroup(id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((attributes) => {
            setAttributes(attributes);
          });
        } else {
          console.error(`could not load custom attributes of group ${name}`);
        }
      })
      .catch(() => {
        console.error(`could not load custom attributes of group ${name}`);
      });
  }

  function delGroup() {
    deleteCustomAttributeGroup(id)
      .then((resp) => {
        if (resp.ok) {
          refreshGroups();
        } else {
          console.error(`could not delete custom attribute group ${id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not delete custom attribute group ${id}`);
      });
  }

  const CustomAttributeFormatter = customAttributeFormatter;
  return (
    <Accordion
      sx={{ flexGrow: 1 }}
      chevron={<IconChevronRight size="1rem" />}
      styles={{
        chevron: {
          '&[data-rotate]': {
            transform: 'rotate(90deg)',
          },
        },
      }}
      variant="separated"
      defaultValue="foo"
      chevronPosition="left"
    >
      <Accordion.Item value={openAutomatically ? 'foo' : 'closed'}>
        <Accordion.Control>{name}</Accordion.Control>
        <Accordion.Panel>
          <div>
            <div style={entityType === 'contact' ? {} : styles.group}>
              {attributes.map((attr, index) => (
                <CustomAttributeFormatter
                  key={attr}
                  index={index}
                  attribute={attr}
                  entityId={entityId}
                  handleChange={handleChange}
                  reload={() => setLastModified(new Date())}
                  refreshGroups={refreshGroups}
                  refreshValue={refreshValue}
                  setAttributes={setAttributes}
                  {...rest}
                />
              ))}
            </div>
          </div>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};

export default CustomAttributeGroup;
