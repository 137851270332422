const changeItemInList = (list, setList, patch) => {
  const tmp = Object.assign([], list);
  const itemToChange = tmp.find((s) => s.id === patch.id);
  const indexOf = tmp.indexOf(itemToChange);
  tmp.splice(indexOf, 1, patch);
  setList(tmp);
};

const deleteItemInList = (list, setList, itemId) => {
  const tmp = Object.assign([], list);
  const itemToDelete = tmp.find((s) => s.id === itemId);
  const indexOf = tmp.indexOf(itemToDelete);
  tmp.splice(indexOf, 1);
  setList(tmp);
};

export const ChangeListS = {
  changeItemInList,
  deleteItemInList,
};
