import { useState } from 'react';
import { useLingui } from '@lingui/react';
import { openModal } from '@mantine/modals';
import { Text } from '@mantine/core';
import { Icon } from '../../../../Atoms/Icons';
import { TransButtonLink } from '../../../../Atoms/Buttons';
import ShortEventValueDescription from './ShortEventValueDescription';
import ConfirmDeleteValuesModal from './ConfirmDeleteValuesModal';

const CustomAttributeDeleteFlow1 = ({ attributeId, values, reload, setCanBeFinished }) => {
  const { i18n } = useLingui();
  const [isLoading, setLoading] = useState(false);

  const confirmDeleteValues = () => {
    openModal({
      modalId: 'confirmDeleteValues',
      size: 'md',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          {i18n._('customField.delete.values.confirm.head')}
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: <ConfirmDeleteValuesModal attributeId={attributeId} refresh={reload} />,
    });
  };

  if (values && values.length === 0) {
    return (
      <div>
        <Icon value="CHECK" /> Keine eingetragenen Werte zu diesem Feld.
      </div>
    );
  }

  return (
    <div>
      <div>
        Um dieses Feld zu löschen, musst Du vorher die Löschung der unten angezeigten Werte in den Events bestätigen.
      </div>
      {values.map((value, index) => (
        <ShortEventValueDescription key={value} index={index} eventId={value.entityId} value={value.value} />
      ))}
      <br />
      <TransButtonLink
        isLoading={isLoading}
        style={{ color: '#9499a7' }}
        icon="TRASH"
        className="primary-link"
        onClick={confirmDeleteValues}
        text="Ja, Werte löschen"
      />
    </div>
  );
};

export default CustomAttributeDeleteFlow1;
