import { useParams } from 'react-router-dom';
import ResourceAdapter from '../../ResourceAdapter';
import { EventContextProvider } from '../EventContext';
import EventLocationContextProviderProxy from './EventLocationContextProviderProxy';

const EventContextProviderProxy = () => {
  const { id } = useParams();

  if (id === 'new') {
    const eventInit = {
      id: '',
      descriptionOfLocation: '',
      addressOfLocation: '',
      phoneNumberOfLocation: '',
      placeId: '',
      provision: '',
      kind: '',
      userDefinedKind: '',
      confirmed: false,
      fee: '',
      tip: '',
      hotel: '',
      bookingState: 'OPEN',
      dates: [{ date: '', timeFrom: '', timeTo: '' }],
      notes: '',
      numberOfGuests: '',
      numberOfKids: '',
    };

    return (
      <EventContextProvider eventFromProvider={eventInit}>
        <EventLocationContextProviderProxy />
      </EventContextProvider>
    );
  }
  return (
    <ResourceAdapter
      serviceUrl={`${process.env.REACT_APP_EVENT_SERVICE_URL}/events/${id}`}
      component={(eventProps) => {
        if (eventProps.length !== 0) {
          eventProps.submitted = true;
        }
        if (eventProps.data.notes !== undefined && eventProps.data.notes !== null) {
          eventProps.data.notes = eventProps.data.notes.replace(/<br\/>/g, '\n');
        }
        return (
          <EventContextProvider eventFromProvider={eventProps.data}>
            <EventLocationContextProviderProxy />
          </EventContextProvider>
        );
      }}
    />
  );
};

export default EventContextProviderProxy;
