import { Trans } from '@lingui/react';
import React, { useState } from 'react';
import SelectBookingState from '../../Molecules/Eventlist/SelectBookingState';
import { TransButtonDefault, TransButtonPrimary } from '../../Atoms/Buttons';
import { ModalDialog } from '../../Molecules/Dialogs';

export const BookingStateModal = ({
  show,
  toggle,
  bookingState: initialValue,
  onBookingStateChange,
  finish,
  title,
  all,
}) => {
  const [bookingState, setBookingState] = useState(initialValue);

  function onChange(value) {
    setBookingState(value);
    if (typeof onBookingStateChange === 'function') {
      onBookingStateChange(value);
    }
  }

  const styles = {
    selectBookingState: {
      flex: 1,
      marginLeft: 10,
      maxWidth: 175,
    },
  };

  return (
    <ModalDialog
      className="modal-xs"
      header={<Trans id={title} />}
      body={
        <SelectBookingState
          style={styles.selectBookingState}
          bookingState={bookingState}
          onBookingStateChange={onChange}
          all={all}
        />
      }
      footer={
        <div>
          <TransButtonPrimary
            onClickHandler={() => {
              finish(bookingState);
              toggle();
            }}
            text="actions.apply"
          />{' '}
          <TransButtonDefault onClickHandler={toggle} text="actions.close" />
        </div>
      }
      show={show}
      toggle={toggle}
    />
  );
};
