// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Container, Grid, Image, Space, Text, Title } from '@mantine/core';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutingS } from '../../Service/RoutingS';
import { WistiaS } from '../../Service/WistiaS';
import { MixpanelS } from '../../Service/MixpanelS';
import { ConstantS } from '../../Service/ConstantS';
import WistiaVideoWrapper from './WistiaVideoWrapper';

const AcademyLandingPage = () => {
  useEffect(() => {
    RoutingS.changeTitle('Akademie');
    MixpanelS.track(ConstantS.TrackingEvents.AcademyOpened);
  }, []);

  const navigate = useNavigate();

  return (
    <Container p={0}>
      <Title mt="md" mb="md">
        Willkommen in unserer Video-Akademie 🎓
      </Title>
      <Text size="md" mb="xl">
        Hier bekommst du eine Schritt für Schritt Anleitung, damit du als Event-Dienstleister immer den perfekten
        Überblick hast und lästige Büroarbeit deutlich reduzierst.{' '}
      </Text>
      <WistiaVideoWrapper hideLabel mediaId="8i0f9vsicz" />
      <Space h="xl" />
      <Title mb="md" order={3}>
        Verfügbare Module
      </Title>
      <Text size="md" mb="xl">
        In diesen 6 Modulen zeigen wir Dir wie du bookitup für Dein Event-Business einrichten kannst. Klick jetzt
        einfach auf das erste Modul um zu starten.
      </Text>
      <Grid>
        {WistiaS.CHANNELS.map((channel) => (
          <Grid.Col
            key={channel.path}
            className="hooverable-tile"
            xs={12}
            sm={6}
            md={6}
            lg={4}
            sx={{ cursor: 'pointer', borderRadius: 5 }}
            onClick={() => navigate(`/academy/channels/${channel.path}`)}
          >
            <Image radius={5} src={channel.img} />
          </Grid.Col>
        ))}
      </Grid>
      <Space h="xl" />
    </Container>
  );
};

export default AcademyLandingPage;
