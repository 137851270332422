import { useLingui } from '@lingui/react';
import { Badge } from '@mantine/core';
import { FC } from 'react';

const SubscriptionStatusBadge: FC<{ status: string }> = ({ status }) => {
  const { i18n } = useLingui();

  const getBadgeColor = () => {
    if (status === 'ACTIVE' || status === 'FUTURE') {
      return 'green';
    }
    if (status === 'CANCELLED' || status === 'NON_RENEWING') {
      return 'red';
    }
    return 'gray';
  };

  return (
    <Badge radius="sm" color={getBadgeColor()} variant="filled">
      {i18n._(`subscription.status.${status}`)}
    </Badge>
  );
};

export default SubscriptionStatusBadge;
