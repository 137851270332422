import { useLingui } from '@lingui/react';
import { Box, Group, Stack, Text } from '@mantine/core';
import EventItem from './EventItem';

const EventsMonthPane = ({ month, year, eventSums, events }) => {
  const { i18n } = useLingui();
  const { length } = events;
  if (length === 0) {
    return null;
  }

  const eventsOfMonth = [];
  events
    .sort((e1, e2) => new Date(e1.dates[0].date) - new Date(e2.dates[0].date))
    .map((event) => {
      eventsOfMonth.push(<EventItem key={event.id} bookingEvent={event} />);
    });

  if (eventSums) {
    eventSums.forEach((e) => {
      monthSumOpen += e.sumOpen;
    });

    eventSums.forEach((e) => {
      monthSumConfirmed += e.sumConfirmed;
    });
  }

  return (
    <>
      <Box p="md" mt={15} sx={{ backgroundColor: '#F1F1F1', borderRadius: 10 }}>
        <Group>
          <Text size="lg" weight="bold">{`${i18n._(`month.${month}`)} ${year}`}</Text>
          <Text size="xs" color="#6E6E6E">{`${length} Events`}</Text>
        </Group>
      </Box>
      <Stack>{eventsOfMonth}</Stack>
    </>
  );
};

export default EventsMonthPane;
