import { ThemeIcon } from '@mantine/core';
import { IconPlus } from '@tabler/icons';
import { FC, useContext } from 'react';
import { useModalState } from '../Molecules/Dialogs';
import { UpgradeModal } from '../Organismns/Upgrade/UpgradeModal';
import { AuthContext, Licence } from '../Service/Context/AuthContext';
import { hasSuitableLicence } from '../Service/helper/LicenceService';

const BookitupAddIcon: FC<{
  onClick?: () => void;
  requiredLicence?: Licence;
  upgradeTitle?: string;
  upgradeText?: string;
  id?: string;
  testId?: string;
}> = ({ onClick, requiredLicence, upgradeTitle, upgradeText, id, testId }) => {
  const { jwtClaims } = useContext(AuthContext);
  const { show, toggle } = useModalState(false);
  const { licence } = jwtClaims;
  const valid = requiredLicence && licence ? hasSuitableLicence(requiredLicence, licence) : true;

  return (
    <ThemeIcon
      id={id}
      size={20}
      style={{ cursor: 'pointer' }}
      variant="gradient"
      gradient={{ from: '#1D5191', to: '#6185B2', deg: 135 }}
    >
      <IconPlus data-testid={testId} color="white" onClick={valid ? onClick : toggle} />
      <UpgradeModal show={show} toggle={toggle} title={upgradeTitle} text={upgradeText} licence={requiredLicence} />
    </ThemeIcon>
  );
};

export default BookitupAddIcon;
