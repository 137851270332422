import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_ATTRIBUTE_SERVICE_URL;

export const fetchCustomAttributeGroups = (entityType) =>
  ajaxActions.get(`${BASE_URL}/${entityType}/attributegroups`).then((resp) => resp);

export const fetchCustomAttributesOfGroup = (groupId) =>
  ajaxActions.get(`${BASE_URL}/attributegroups/${groupId}/attributes`).then((resp) => resp);

export const fetchAllCustomAttributes = () => ajaxActions.get(`${BASE_URL}/attributes`).then((resp) => resp);

export const fetchFlattenedCustomAttributeValues = (entityType, entityId) =>
  ajaxActions.get(`${BASE_URL}/flattened/attributes/${entityType}/${entityId}`).then((resp) => resp);

export const fetchCustomAttributeValueByEntityId = (attributeId, entityId) =>
  ajaxActions.get(`${BASE_URL}/attributes/${attributeId}/values/${entityId}`).then((resp) => resp);

export const fetchCustomAttributeValues = (attributeId) =>
  ajaxActions.get(`${BASE_URL}/attributes/${attributeId}/values`).then((resp) => resp);

export const deleteCustomAttribute = (attributeId) =>
  ajaxActions.del(`${BASE_URL}/attributes/${attributeId}`).then((resp) => resp);

export const deleteCustomAttributeGroup = (groupId) =>
  ajaxActions.del(`${BASE_URL}/attributegroups/${groupId}`).then((resp) => resp);

export const deleteCustomAttributeValues = (attributeId) =>
  ajaxActions.del(`${BASE_URL}/attributes/${attributeId}/values`).then((resp) => resp);

export const createCustomAttribute = (name, description, attributeType, groupId) =>
  ajaxActions
    .put(`${BASE_URL}/attributes`, {
      name,
      description,
      attributeType,
      groupId,
    })
    .then((resp) => resp);

export const createCustomAttributeInDefaultGroup = (name, description, attributeType, entityType) =>
  ajaxActions
    .put(`${BASE_URL}/${entityType}/attributes`, {
      name,
      description,
      attributeType,
    })
    .then((resp) => resp);

export const createCustomAttributeGroup = (entityType, name) =>
  ajaxActions.put(`${BASE_URL}/${entityType}/attributegroups`, { name }).then((resp) => resp);

export const patchCustomAttributeValue = (entityId, customAttributeId, value) =>
  ajaxActions.patch(`${BASE_URL}/attributes/${customAttributeId}/values/${entityId}`, { value }).then((resp) => resp);
