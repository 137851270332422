// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text, SelectItemProps } from '@mantine/core';
import { forwardRef } from 'react';

interface ResourceTypeItemProps extends SelectItemProps {
  label: string;
  description: string;
}
const AutocompleteResourceTypeItem = forwardRef<HTMLDivElement, ResourceTypeItemProps>(
  ({ label, description, ...rest }: ResourceTypeItemProps, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div ref={ref} {...rest}>
      <Text weight="bolder">{label}</Text>
      <Text size="xs" >
        {description}
      </Text>
    </div>
  ),
);

export default AutocompleteResourceTypeItem;
