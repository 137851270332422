import { useContext, useEffect, useState } from 'react';
import { getCalendarAccesses, saveCalendarAccess } from '../../../Service/restapi/calendarAccountService';
import CalendarAccessItem from './CalendarAccessItem';
import PanePaper from '../../../Molecules/PanePaper';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { Skeleton, Text } from '@mantine/core';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import SelectCreateContactModal from '../../../Organismns/Contact/SelectCreateContactModal';
import { openModal } from '@mantine/modals';

export default function () {
  const [calendarAccesses, setCalendarAccesses] = useState();

  useEffect(() => {
    fetchCalendarAccesses();
  }, []);

  function fetchCalendarAccesses() {
    getCalendarAccesses()
      .then((resp) => {
        if (resp.ok) {
          resp.json().then(setCalendarAccesses);
        } else {
          console.error('could not fetch calendarAccesses');
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not fetch calendarAccesses');
      });
  }

  function createAccess(contactId) {
    console.log({ contactId });
    saveCalendarAccess(contactId)
      .then((resp) => {
        if (resp.ok) {
          fetchCalendarAccesses();
        } else {
          console.error(`could not create access for contact ${contactId}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not create access for contact ${contactId}`);
      });
  }

  if (!calendarAccesses) {
    return <Skeleton width="100%" height={100} />;
  }

  const { executeWithLicenceCheck } = useContext(ViolationContext);


  const openAddTeamMember = () =>
  openModal({
    size: 'sm',
    title: (
      <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
        Kontakt hinzufügen
      </Text>
    ),
    closeOnEscape: true,
    transition: 'slide-down',
    children: <SelectCreateContactModal onSelect={createAccess} />,
  });

  return (
    <PanePaper
      title="Verfügbarkeit Deines Teams"
      rightSection={
        <BookitupAddIcon onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.NETWORK_PROVIDER, openAddTeamMember)} />
      }
    >
      <div className="description" style={{ marginBottom: 10 }}>
        Prüfe die Verfügbarkeit von anderen Dienstleistern, um Kunden-Anfragen schnell und einfach weiterzuleiten und
        ggf. eine Vermittlungs-Provision zu erhalten.
      </div>
      {calendarAccesses.map((calendarAccess, index) => (
        <CalendarAccessItem
          key={index}
          index={index}
          calendarAccess={calendarAccess}
          reloadCalendarAccesses={fetchCalendarAccesses}
        />
      ))}
    </PanePaper>
  );
}
