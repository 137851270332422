// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Paper, Stack, Text } from '@mantine/core';
import { DataGroup, Timeline, TimelineOptions } from 'vis-timeline/standalone';
import { renderToStaticMarkup } from 'react-dom/server';
import { FC } from 'react';
import { EventPlanningGroup, EventTimelineItem } from '../Types/ResourceT';
import { DateUtils } from '../Utils/dateUtils';
import ResourceGroupPlanningIcon from '../Atoms/ResourceGroupPlanningIcon';
import BookingEvent from '../Types/Event';
import { RoutingS } from './RoutingS';
import { ContactS } from './ContactS';
import ResourceS from './ResourceS';

const options: TimelineOptions = {
  locale: 'de',
  minHeight: window.innerWidth < 500 ? 450 : 600,
  min: DateUtils.BookitupStartOfResources,
  max: DateUtils.MaxFilterDate,
  zoomFriction: 5,
  zoomMin: 1000 * 60 * 60, // 1 hour
  zoomMax: 1000 * 60 * 60 * 24 * 365 * 2, // 2 year
  xss: {
    disabled: true, // Disable XSS to be able to provide custom styles in templates
  },
  tooltip: {
    template: (originalItemData: any, parsedItemData) => {
      if (originalItemData.planningGroup) {
        return renderToStaticMarkup(<PlanningGroupTooltipTemplate planningGroup={originalItemData.planningGroup} />);
      }
      return '';
    },
  },
};

const initialize = (container: HTMLElement, items: EventTimelineItem[], onRangeChange: (props: any) => unknown) => {
  const timeline = new Timeline(container, getItems(items), options);
  // Open event details in new tab on item click
  timeline.on('click', (props: any) => {
    if (!props.item) {
      return;
    }
    const itemStr = props.item as string;

    if (itemStr.startsWith('event')) {
      const parts = itemStr.split('-');
      if (parts.length === 2) {
        RoutingS.openInNewTab(`/events/${Number(parts[1])}/details`);
      }
    }
    if (itemStr.startsWith('group')) {
      const parts = itemStr.split(',');
      if (parts.length === 3) {
        RoutingS.openInNewTab(`/events/${Number(parts[2])}/resources`);
      }
    }
  });
  // Adjust filter on dateRange change
  timeline.on('rangechanged', onRangeChange);
  return timeline;
};

const update = (timeline: Timeline, items: EventTimelineItem[]) => {
  timeline.setData({ items: getItems(items), groups: getGroups(items) });
};

const getGroups = (items: EventTimelineItem[]) => {
  const groups: DataGroup[] = [];
  items.forEach((item) => {
    const { id, title, planningGroups } = item;
    const nestedGroups = planningGroups.length === 0 ? undefined : planningGroups.map((g) => `group-${g.id}`);
    groups.push({ id: `group-${id}`, content: title, nestedGroups });
    planningGroups.forEach((planningGroup) =>
      groups.push({ id: `group-${planningGroup.id}`, content: planningGroup.name }),
    );
  });
  return groups;
};

const getItems = (items: EventTimelineItem[]) => {
  const mapBookingEvent = (item: EventTimelineItem) => {
    const tmp = [];
    const { id, eventDates, bookingState, planningGroups } = item;
    const { start, end } = eventDates[0];

    tmp.push({
      id: `event-${id}`,
      group: `group-${id}`,
      content: renderToStaticMarkup(<EventItemTemplate item={item} />),
      start,
      end,
      className: bookingState,
      test: 'test',
    });
    planningGroups.forEach((planningGroup) => {
      const { interval, planningsCount, warehousePlanningsCount } = planningGroup;
      if (planningsCount + warehousePlanningsCount > 0) {
        tmp.push({
          id: `group,${planningGroup.id},${id}`,
          group: `group-${planningGroup.id}`,
          content: renderToStaticMarkup(<PlanningItemTemplate planningGroup={planningGroup} />),
          start: interval.start,
          end: interval.end,
          className: 'planningGroup',
          planningGroup,
        });
      }
    });
    return tmp;
  };
  return items.map(mapBookingEvent).flat();
};

const PlanningItemTemplate: FC<{ planningGroup: EventPlanningGroup }> = ({ planningGroup }) => {
  const { name } = planningGroup;

  return (
    <Text ml="xs" weight="bolder">
      <ResourceGroupPlanningIcon group={planningGroup} />
      <Text span ml={5} color="black" sx={{ fontFamily: 'Nunito Sans' }}>
        {name}
      </Text>
    </Text>
  );
};

const PlanningGroupTooltipTemplate: FC<{ planningGroup: EventPlanningGroup }> = ({ planningGroup }) => {
  const { warehousePlanningsCount, showResourcePlanningShortage, showWarehousePlanningShortage } = planningGroup;

  const getText = () => {
    if (warehousePlanningsCount > 0) {
      if (showWarehousePlanningShortage) {
        return 'Resource ist bestätigt, aber es gibt einen Mangel.';
      }
      return 'Resource ist eingeplant und ausreichend verfügbar.';
    }
    if (showResourcePlanningShortage) {
      return 'Resource ist nicht ausreichend verfügbar.';
    }
    return 'Resource ist verfügbar und muss noch bestätigt werden.';
  };
  return (
    <Paper>
      <Text ml="xs" weight="bolder" sx={{ fontFamily: 'Nunito Sans' }}>
        {' '}
        {getText()}
      </Text>
    </Paper>
  );
};

const EventItemTemplate: FC<{ item: EventTimelineItem }> = ({ item }) => {
  const { title, kind } = item;
  return (
    <Stack spacing={0} ml={3}>
      <Text weight="bolder" sx={{ fontFamily: 'Nunito Sans' }}>
        {title}
      </Text>
      {kind && <Text sx={{ fontFamily: 'Nunito Sans' }}>{kind}</Text>}
    </Stack>
  );
};

const resolveTimelineItems = async (events: BookingEvent[], signal: AbortSignal): Promise<EventTimelineItem[]> => {
  const eventIds = events.map((e) => e.id);
  const contactIds = events.map((e) => e.customerId);
  const items: EventTimelineItem[] = [];

  const values = await Promise.all([
    ContactS.fetchDescriptions(contactIds, signal),
    ResourceS.fetchTimelinePlannings(eventIds, signal),
  ]);
  if (values) {
    const [descriptions, plannings] = values;
    if (plannings !== null) {
      events.forEach((bookingEvent) => {
        const { id: eventId, customerId, bookingState, dates, kind } = bookingEvent;
        items.push({
          id: eventId,
          bookingState,
          title: descriptions[customerId],
          eventDates: dates,
          kind,
          planningGroups: plannings[eventId],
        });
      });
    }
  }
  return items;
};

// eslint-disable-next-line import/prefer-default-export
export const TimelineS = {
  initialize,
  update,
  resolveTimelineItems,
};
