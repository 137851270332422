/* eslint-disable max-classes-per-file */
import { useLingui } from '@lingui/react';
import { Button, DropdownItem, NavLink, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { cloneElement } from 'react';
import { Center } from '@mantine/core';
import { Icon } from './Icons';

export const TransNavLink = (props) => {
  const { i18n } = useLingui();

  return (
    <NavLink id={props.id} to={props.to} style={props.style} onClick={props.onClick} tag={Link}>
      {i18n._(props.text || '')}
    </NavLink>
  );
};

export const TransNavLinkWithIcon = (props) => {
  const { i18n } = useLingui();

  return (
    <NavLink to={props.to} onClick={props.onClick} tag={Link}>
      <Icon value={props.icon} />
      {i18n._(props.text || '')}
    </NavLink>
  );
};

export const TransButtonPrimary = (props) => {
  const { i18n } = useLingui();

  return <ButtonPrimary {...props} text={i18n._(props.text || '')} />;
};
export const TransButtonPrimaryWithIcon = (props) => {
  const { i18n } = useLingui();

  return <ButtonPrimaryWithIcon {...props} text={i18n._(props.text || '')} />;
};

export const TransButtonPrimaryWithSpinner = (props) => {
  const { i18n } = useLingui();

  return <ButtonPrimaryWithSpinner {...props} text={i18n._(props.text || '')} />;
};

export const TransButtonSecondary = (props) => {
  const { i18n } = useLingui();

  return <ButtonSecondary {...props} text={i18n._(props.text || '')} />;
};

export const TransButtonSecondaryWithIcon = (props) => {
  const { i18n } = useLingui();

  return <ButtonSecondaryWithIcon {...props} title={i18n._(props.title || '')} text={i18n._(props.text || '')} />;
};

export const TransButtonDefault = (props) => {
  const { i18n } = useLingui();

  const color = props.color !== undefined ? props.color : 'default';

  return <ButtonDefault {...props} color={color} text={i18n._(props.text || '')} />;
};

export const TransButtonDefaultWithIcon = (props) => {
  const { i18n } = useLingui();
  return <ButtonDefaultWithIcon {...props} text={i18n._(props.text || '')} />;
};

export const TransButtonDefaultWithSpinner = (props) => {
  const { i18n } = useLingui();

  return <ButtonDefaultWithSpinner {...props} text={i18n._(props.text || '')} />;
};

export const TransButtonLink = (props) => {
  const { i18n } = useLingui();

  return <ButtonLink {...props} text={i18n._(props.text || '')} />;
};

export const TransButtonLinkWithIcon = (props) => {
  const { i18n } = useLingui();

  return <ButtonLinkWithIcon {...props} title={i18n._(props.title || '')} text={i18n._(props.text || '')} />;
};
export const TransDropdownItem = (props) => {
  const { i18n } = useLingui();

  return <DropdownItem onClick={props.onClick}>{i18n._(props.text || '', props.values || {})}</DropdownItem>;
};

export const TransDropdownItemWithIcon = (props) => {
  const { i18n } = useLingui();

  return (
    <DropdownItem onClick={props.onClick} disabled={props.disabled} style={props.style}>
      {props.icon !== undefined ? <Icon value={props.icon} /> : ''}{' '}
      <span>{i18n._(props.text || '', props.values || {})}</span>
    </DropdownItem>
  );
};

export const ButtonPrimary = (props) => (
  <Button
    type="button"
    style={props.style}
    disabled={props.disabled}
    id={props.id}
    className={props.className}
    onClick={(e) => props.onClickHandler(e)}
    color="primary"
    data-testid={props.testId}
  >
    <ButtonContent icon={props.isLoading ? <Spinner size="sm" color="light" /> : null} text={props.text} />
  </Button>
);

const ButtonPrimaryWithIcon = (props) => (
  <Button
    type={props.type !== undefined ? props.type : 'button'}
    id={props.id}
    className={`icon-and-text ${props.className}`}
    disabled={props.disabled}
    style={props.style}
    onClick={props.onClickHandler}
    color="primary"
    data-testid={props.testId}
  >
    <ButtonContent icon={<Icon value={props.icon} />} text={props.text} />
  </Button>
);

const ButtonPrimaryWithSpinner = (props) => (
  <Button
    type={props.type !== undefined ? props.type : 'button'}
    id={props.id}
    className={`icon-and-text ${props.className}`}
    disabled={props.disabled}
    style={props.style}
    onClick={props.onClickHandler}
    color="primary"
    data-testid={props.testId}
  >
    <ButtonContent
      icon={props.isLoading ? <Spinner size="sm" color="light" /> : <Icon value={props.icon} />}
      text={props.text}
    />
  </Button>
);

export const ButtonSecondary = (props) => (
  <Button
    type="button"
    className={props.className}
    disabled={props.disabled}
    style={props.style}
    onClick={props.onClickHandler}
    color="secondary"
    data-testid={props.testId}
  >
    <ButtonContent
      icon={props.isLoading ? <Spinner size="sm" color="light" /> : <Icon value={props.icon} />}
      text={props.text}
    />
  </Button>
);

const ButtonSecondaryWithIcon = (props) => (
  <Button
    type="button"
    className={`icon-and-text ${props.className}`}
    title={props.title}
    style={props.style}
    onClick={props.onClickHandler}
    color="secondary"
    data-testid={props.testId}
  >
    <ButtonContent icon={<Icon value={props.icon} />} text={props.text} />
  </Button>
);

export const ButtonDefault = (props) => {
  let color = 'default';
  if (props.color !== undefined) {
    color = props.color;
  }

  return (
    <Button
      type="button"
      id={props.id}
      disabled={props.disabled}
      className={`icon-and-text ${props.className}`}
      onMouseDown={props.onMouseDown}
      style={props.style}
      onClick={props.onClickHandler}
      color={color}
      data-testid={props.testId}
    >
      {props.text}
    </Button>
  );
};

const ButtonDefaultWithIcon = (props) => {
  let color = 'default';
  if (props.color) {
    color = props.color;
  }
  return (
    <Button
      type="button"
      className={props.text !== undefined && props.className === undefined ? 'icon-and-text ' : `${props.className}`}
      id={props.id}
      style={props.style}
      onClick={props.onClickHandler}
      color={color}
      data-testid={props.testId}
    >
      <ButtonContent
        icon={props.isLoading ? <Spinner size="sm" color="dark" /> : <Icon value={props.icon} />}
        text={props.text}
      />
    </Button>
  );
};

const ButtonDefaultWithSpinner = (props) => (
  <Button
    type="button"
    disabled={props.disabled}
    className={props.text !== undefined ? 'icon-and-text ' : `${props.className}`}
    id={props.id}
    style={props.style}
    onClick={props.onClick}
    color="default"
    data-testid={props.testId}
  >
    <ButtonContent
      icon={props.isLoading ? <Spinner size="sm" color="dark" /> : <Icon value={props.icon} />}
      text={props.text}
    />
  </Button>
);

export const ButtonLink = ({ style, className, text, onClick, isLoading, icon, disabled, testId }) => (
  <Button
      color="link"
      style={style}
      className={className}
      disabled={disabled}
      onClick={onClick}
      data-testid={testId}
  >
    <ButtonContent
      icon={isLoading ? <Spinner size="sm" color="dark" /> : icon ? <Icon value={icon} /> : null}
      text={text}
    />
  </Button>
);

export const ButtonLinkWithIcon = (props) => (
  <Button
    className={props.className}
    data-tour={props.dataTour}
    title={props.title}
    color={props.color !== undefined ? props.color : 'default'}
    disabled={props.disabled}
    onClick={props.onClick}
    style={props.style}
    data-testid={props.testId}
  >
    <ButtonContent
      icon={props.isLoading ? <Spinner size="sm" color="dark" /> : <Icon value={props.icon} />}
      text={props.text}
    />
  </Button>
);

const ButtonContent = ({ text, icon }) => {
  if (text !== undefined && text !== null && text !== '' && icon !== undefined && icon !== null) {
    return (
      <Center>
        {cloneElement(icon, { style: { marginRight: 5 } })} {text}
      </Center>
    );
  }
  if (text !== undefined && text !== null && text !== '') {
    return <Center>{text}</Center>;
  }

  if (icon !== undefined && icon !== null) {
    return <Center>{cloneElement(icon, { style: { marginRight: 0 } })}</Center>;
  }

  return null;
};
