/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import {
  IconArrowBackUp,
  IconArrowRight,
  IconCheck,
  IconMinus,
  IconQuestionMark,
  IconX,
  TablerIconProps,
} from '@tabler/icons';
import { Skeleton } from '@mantine/core';
import BookingState from '../Types/Enums/BookingState';
import { RequestAnswer } from '../Types/RecommendationT';

interface BookingStateProps extends TablerIconProps {
  bookingState: BookingState;
}

export const BookingStateIcon: FC<BookingStateProps> = ({ bookingState, ...rest }) => {
  if (bookingState === BookingState.OPEN) {
    return <IconArrowRight {...rest} />;
  }
  if (bookingState === BookingState.OFFERED) {
    return <IconArrowBackUp {...rest} />;
  }
  if (bookingState === BookingState.BOOKED) {
    return <IconCheck {...rest} />;
  }
  if (bookingState === BookingState.UNAVAILABLE) {
    return <IconMinus {...rest} />;
  }
  if (bookingState === BookingState.CANCELED) {
    return <IconX {...rest} />;
  }
  return null;
};

interface RequestAnswerProps extends TablerIconProps {
  requestAnswer?: RequestAnswer;
}

export const RequestAnswerIcon: FC<RequestAnswerProps> = ({ requestAnswer, ...rest }) => {
  if (!requestAnswer) {
    return <Skeleton circle height={8} />;
  }
  if (requestAnswer === 'AVAILABLE') {
    return <IconCheck {...rest} />;
  }
  if (requestAnswer === 'UNAVAILABLE') {
    return <IconX {...rest} />;
  }
  if (requestAnswer === 'UNKNOWN') {
    return <IconQuestionMark {...rest} />;
  }
  return null;
};
