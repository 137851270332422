import React, { useContext, useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import {
  createCustomAttribute,
  createCustomAttributeGroup,
  createCustomAttributeInDefaultGroup,
  fetchCustomAttributeGroups,
} from '../../../Service/restapi/customAttributeService';
import { ModalDialog } from '../../Dialogs';
import { TransButtonDefault, TransButtonPrimaryWithSpinner } from '../../../Atoms/Buttons';
import { TransTextInputField } from '../../../Atoms/InputFields';
import Autocomplete from '../../../Atoms/Autocomplete';
import { InputHelp } from '../../../Atoms/inputs/InputHelp';
import { hasSpecialChar, replaceUmlaute } from '../../../Utils/utils';
import { ToastS } from '../../../Service/ToastS';
import { CustomAttributeType } from './CustomAttributeType';
import { ConstantS } from '../../../Service/ConstantS';
import { MixpanelS } from '../../../Service/MixpanelS';

export const CustomAttributeCreateModal = ({ entityType, show, toggle, finish }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [attributeType, setAttributeType] = useState(undefined);
  const [groups, setGroups] = useState(undefined);
  const [groupName, setGroupName] = useState(undefined);
  const [groupId, setGroupId] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    setName('');
    setDescription('');
    setAttributeType(undefined);
    setGroupName(undefined);
    setGroupId(undefined);
    fetchGroups();
  }, [show]);

  useEffect(() => {
    fetchGroups();
  }, [entityType]);

  function changeName(name) {
    setName(replaceUmlaute(name));
  }

  function changeGroupName(groupName) {
    setGroupName(replaceUmlaute(groupName));
  }

  function fetchGroups() {
    fetchCustomAttributeGroups(entityType)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((groups) => {
            setGroups(groups);
            const defaultGroup = groups.find((g) => g.name === 'Standard');
            if (defaultGroup && entityType !== 'contact') {
              setGroupName('Standard');
            }
          });
        } else {
          console.error(`could not get groups of entityType ${entityType}`);
        }
      })
      .catch(() => {
        console.error(`could not get groups of entityType ${entityType}`);
      });
  }

  function create() {
    if (!name || !attributeType) {
      ToastS.info('create-result', 'Bitte vergebe dem Feld einen Namen und wähle einen Feld-Typ aus.');
    } else if (!hasSpecialChar(name) && !hasSpecialChar(groupName)) {
      setLoading(true);
      if (!groupId) {
        if (groupName) {
          const groupExists = groups.find((g) => g.name === groupName);
          if (groupExists) {
            setGroupId(groupExists.id);
            createAttr(groupId);
          } else {
            createCustomAttributeGroup(entityType, groupName)
              .then((resp) => {
                if (resp.ok) {
                  resp.json().then(({ id, name }) => {
                    console.log(`created new group ${name} - ${id}`);
                    createAttr(id);
                  });
                } else {
                  console.error(`could not create new group ${groupName}`);
                  setLoading(false);
                }
              })
              .catch(() => {
                console.error(`could not create new group ${groupName}`);
                setLoading(false);
              });
          }
        } else {
          createAttr();
        }
      } else {
        createAttr(groupId);
      }
    } else {
      ToastS.info('create-result', 'Sonderzeichen sind im Namen nicht erlaubt.');
    }
  }

  function createAttr(tmpGroupId) {
    if (tmpGroupId) {
      createCustomAttribute(name, description, attributeType, tmpGroupId)
        .then((resp) => {
          if (resp.ok) {
            ToastS.success('field-created', 'Eigenes Feld erstellt');
            MixpanelS.track(ConstantS.TrackingEvents.CustomAttibuteCreated);
            finish();
            toggle();
          } else {
            console.error(`could not create new custom attribute ${name} of attributeType ${attributeType}`);
          }
        })
        .catch(() => {
          console.error(`could not create new custom attribute ${name} of attributeType ${attributeType}`);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createCustomAttributeInDefaultGroup(name, description, attributeType, entityType)
        .then((resp) => {
          if (resp.ok) {
            MixpanelS.track(ConstantS.TrackingEvents.CustomAttibuteCreated);
            finish();
            toggle();
          } else {
            console.error(`could not create new custom attribute ${name} of attributeType ${attributeType}`);
          }
        })
        .catch(() => {
          console.error(`could not create new custom attribute ${name} of attributeType ${attributeType}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const styles = {
    selectGroup: {
      marginBottom: 10,
    },
    description: {
      color: '#666666',
      fontSize: 12,
    },
  };

  return (
    <ModalDialog
      header={<Trans id="custom-attribute.create" />}
      body={
        <div>
          <div style={styles.description}>
            Vergib dem Feld einen Namen wie bspw. <i>Anzahl der Gäste</i> und wähle einen Typ aus. Optional kannst du
            eine detailliertere Beschreibung angeben und das Feld einer Gruppe zuordnen wie z.B. <i>Eckdaten</i>.
          </div>
          <div>
            <TransTextInputField
              onChange={(e) => {
                changeName(e.target.value);
              }}
              value={name}
              saveValue={() => {}}
              placeholder="custom-attribute.name"
            />
          </div>

          <div>
            <TransTextInputField
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              value={description}
              saveValue={() => {}}
              placeholder="custom-attribute.description"
            />
          </div>

          {entityType !== 'contact' && (
            <Autocomplete
              suggestions={groups}
              placeholder="Gruppe (optional):"
              patchField="name"
              value={groupName}
              onChange={(patch) => {
                setGroupId(undefined);
                changeGroupName(patch.name);
              }}
              saveValue={(patch) => {
                setGroupId(patch.id);
              }}
            />
          )}

          <CustomAttributeType attributeType={attributeType} onSelect={setAttributeType} />

          {error ? <InputHelp errorDescription={error} /> : null}
        </div>
      }
      footer={
        <div>
          <TransButtonPrimaryWithSpinner isLoading={loading} onClickHandler={create} text="actions.save" />
          &nbsp;
          <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />
        </div>
      }
      show={show}
      toggle={toggle}
    />
  );
};
