import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

// eslint-disable-next-line import/prefer-default-export
export const useGoogleTagManager = () => {
  const gtmId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID;

  useEffect(() => {
    if (gtmId) {
      TagManager.initialize({ gtmId });
    } else {
      // eslint-disable-next-line no-console
      console.warn('Google tag manager is disabled for this env');
    }
  }, [gtmId]);
};
