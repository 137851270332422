import { useEffect, useState, ReactNode, FC, CSSProperties } from 'react';
import { To, useNavigate } from 'react-router-dom';
import { useLingui } from '@lingui/react';
import { TransButtonDefaultWithIcon, TransNavLink } from '../Atoms/Buttons';

type Props = {
  description: ReactNode;
  content?: ReactNode;
  actions?: ReactNode;
  backTo: To;
  backDescription: string;
  backHandler?: () => void;
  contentStyle?: CSSProperties;
};

const HeadSkeleton: FC<Props> = ({
  description,
  content,
  actions,
  backTo,
  backDescription,
  backHandler,
  contentStyle,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const navigate = useNavigate();
  const { i18n } = useLingui();

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  });

  function updateDimensions() {
    setWindowHeight(window.innerHeight);
    setWindowWidth(window.innerWidth);
  }

  const styles = {
    container: {
      display: 'flex',
      flexWrap: 'wrap' as any,
      flex: 1,
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    description: {
      maxWidth: 300,
    },
    location: {
      fontSize: windowWidth < 800 ? 14 : 24,
    },
    goBack: {
      padding: 0,
      fontSize: windowWidth < 800 ? 12 : 14,
    },
    contentAndActions: {},
    content: {
      display: 'flex',
      marginRight: windowWidth > 800 ? 20 : 5,
      marginLeft: windowWidth > 800 ? 20 : 5,
      flex: 1,
      // maxWidth: 1200,
      justifyContent: 'flex-end',
      alignItems: 'center',
      ...contentStyle,
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      // alignItems: "center",
    },
  };

  return (
    <div style={styles.container}>
      {windowWidth < 800 && (
        <div>
          {backDescription && (
            <TransButtonDefaultWithIcon
              id={`to${backDescription.replace('.', '-')}`}
              onClickHandler={() => navigate(backTo)}
              icon="BACK"
            />
          )}
        </div>
      )}
      {windowWidth > 800 && (
        <div style={styles.description}>
          <div style={styles.location}>{description}</div>
          {backDescription && (
            <TransNavLink
              style={styles.goBack}
              onClick={() => {
                if (typeof backHandler === 'function') {
                  backHandler();
                }
              }}
              id={`to${backDescription.replace('.', '-')}`}
              to={backTo}
              text={`‹ zurück ${i18n._(backDescription)}`}
            />
          )}
        </div>
      )}

      <div style={styles.content}>{content}</div>
      <div style={styles.actions}>{actions}</div>
    </div>
  );
};

export default HeadSkeleton;
