import { createContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { PlanningGroup } from '../../Types/ResourceT';
import ResourcePlanningS from '../restapi/resourcePlanningS';

export interface EventResourcePlanningContextInterface {
  planningGroups: PlanningGroup[];
  loading: boolean;
  addGroup: (newGroup: PlanningGroup) => void;
  deleteGroup: (groupId: string) => void;
  editGroup: (editedGroup: PlanningGroup) => void;
}

export const EventResourcePlanningContext = createContext<EventResourcePlanningContextInterface>(
  {} as EventResourcePlanningContextInterface,
);

export const EventResourcePlanningContextProvider = () => {
  const { id: eventId } = useParams();
  const [planningGroups, setPlanningGroups] = useState<PlanningGroup[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (eventId) {
      setLoading(true);
      ResourcePlanningS.getPlanningGroupsByEvent(eventId)
        .then((resJSON) => {
          if (resJSON) {
            setPlanningGroups(resJSON);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [eventId]);

  const addGroup = (newGroup: PlanningGroup) => setPlanningGroups([...planningGroups, newGroup]);
  const editGroup = (editedGroup: PlanningGroup) =>
    setPlanningGroups(planningGroups.map((group) => (group.id === editedGroup.id ? editedGroup : group)));
  const deleteGroup = (groupId: string) => setPlanningGroups(planningGroups.filter((group) => group.id !== groupId));

  const value = useMemo(
    () => ({
      planningGroups,
      loading,
      addGroup,
      editGroup,
      deleteGroup,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, planningGroups],
  );

  return (
    <EventResourcePlanningContext.Provider value={value}>
      <Outlet />
    </EventResourcePlanningContext.Provider>
  );
};
