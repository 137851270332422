/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC, useContext } from 'react';
import BookitupTable from '../../../../Atoms/BookitupTable';
import { Part } from '../../../../Types/ResourceT';
import ResourceTableRow from '../general/ResourceTableRow';
import { ResourceDetailsContext } from '../../../../Service/Context/ResourceDetailsContext';
import IncDecCounter from '../../../../Atoms/inputs/IncDecCounter';
import { openDeleteResourcePlanning } from '../../../../Templates/Resource/EventPlanningsModals';

const ResourcePartsTable: FC<{ parts: Part[]; width: number }> = ({ parts, width }) => {
  const { resourceId, updateResourcePart, removePart } = useContext(ResourceDetailsContext);

  const showColumns = {
    showDescription: width > 650,
    showCategory: width > 450,
  };

  const mapRowUI = (part: Part) => {
    const { id: partId, quantity, resource } = part;
    const onLastRemoved = () => {
      openDeleteResourcePlanning(resource.name, () => removePart(partId));
    };

    return (
      <ResourceTableRow
        key={partId}
        resource={resource}
        counter={
          <IncDecCounter
            initialCount={quantity}
            onChanged={(count) => updateResourcePart(resourceId ?? '', partId, { quantity: count })}
            onLastRemoved={onLastRemoved}
          />
        }
        {...showColumns}
      />
    );
  };

  if (parts.length === 0) {
    return (
      <Text italic color="dimmed">
        Noch nichts hinzugefügt.
      </Text>
    );
  }

  return (
    <BookitupTable>
      <thead>
        <tr>
          <th> </th>
          <th style={{ width: 30 }}> Typ </th>
          <th style={{ width: 250 }}>Name</th>
          {showColumns.showCategory && <th>Kategorie</th>}
          {showColumns.showDescription && <th>Bezeichnung</th>}
          <th>Anzahl</th>
        </tr>
      </thead>
      <tbody>{parts.map(mapRowUI)}</tbody>
    </BookitupTable>
  );
};

export default ResourcePartsTable;
