import { useContext } from 'react';
import { EventsContext } from '../../Service/Context/EventsContext';
import EventsListView from '../Event/EventListView';
import EventsTimelineView from './EventsTimelineView';

const EventsPageContent = () => {
  const { view } = useContext(EventsContext);

  if (view === 'list') {
    return <EventsListView />;
  }
  return <EventsTimelineView />;
};

export default EventsPageContent;
