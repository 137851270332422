import React, { useState } from 'react';
import { Input, Label } from 'reactstrap';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/react';
import { InputHelp } from './InputHelp';

export const EditableDecimalNumber = ({
  finishCallback,
  value: initialValue,
  placeholder,
  patchField,
  style,
  ...rest
}) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const styles = {
    error: {
      borderColor: 'red',
    },
    input: {
      marginBottom: 0,
    },
    label: {
      color: '#8ca3b5',
      fontWeight: 800,
    },
    inputContainer: {
      marginBottom: 9,
      ...style,
    },
  };

  function formatDecimal() {
    let tmpValue = value.toString();
    if (tmpValue.includes(',')) {
      tmpValue = tmpValue.replace(',', '.');
    }
    setValue(tmpValue);

    setLoading(true);
    finishCallback({ [patchField]: tmpValue })
      .catch((error) => setError(error.message))
      .then(() => setLoading(false));
  }

  return (
    <div style={styles.inputContainer}>
      {placeholder && (
        <Label sm={12} style={styles.label}>
          <Trans id={placeholder} />
        </Label>
      )}
      <Input
        onBlur={() => {
          formatDecimal();
        }}
        className={loading ? 'loading' : ''}
        style={error ? { ...styles.error, ...styles.input } : styles.input}
        onChange={(e) => setValue(e.target.value)}
        onKeyUp={(e) => {
          switch (e.key) {
            case 'Enter':
              finishCallback({ [patchField]: value })
                .catch((error) => setError(error.message))
                .then(() => setLoading(false));
              break;
            case 'Escape':
              finishCallback({ [patchField]: initialValue })
                .catch((error) => setError(error.message))
                .then(() => setLoading(false));
          }
        }}
        value={value != null ? value : ''}
        {...rest}
      />
      {error ? <InputHelp errorDescription={error} /> : null}
    </div>
  );
};

EditableDecimalNumber.propTypes = {
  value: PropTypes.number,
  finishCallback: PropTypes.func,
  autoFocus: PropTypes.bool,
};

EditableDecimalNumber.defaultProps = {
  autoFocus: true,
};
