import Select from 'react-select';
// import '../unsupported_scss_libs/react-select.scss'
import { ButtonGroup, FormGroup, Input, Label } from 'reactstrap';
import { withI18n, useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { LocationSearchInput } from './PlacesInputField';
import { TransButtonLinkWithIcon } from './Buttons';
import { isMobile, isTablet } from 'react-device-detect';

export const TransTextInputField = (props) => {
  const { i18n } = useLingui();

  return <TextInputField {...props} placeholder={i18n._(props.placeholder || '')} />;
};

export const TransSelectField = withI18n()((props) => {
  if (props.options !== undefined) {
    props.options.forEach((option) => {
      option.label = props.i18n._(option.label || '');
    });
  }
  let noSelectionPlaceholder;
  if (props.noSelectionPlaceholder === undefined) {
    noSelectionPlaceholder = 'common.select';
  } else {
    noSelectionPlaceholder = props.noSelectionPlaceholder;
  }

  let value = null;
  if (props.value !== undefined && props.value !== null) {
    value = { label: props.i18n._(props.value.label || ''), value: props.value.value };
  }

  return (
    <SelectField
      {...props}
      value={value}
      noSelectionPlaceholder={props.i18n._(noSelectionPlaceholder || '')}
      placeholder={props.i18n._(props.placeholder || '')}
      testId={props.testId}
    />
  );
});

export const TransLocationSearchInput = withI18n()((props) => (
  <LocationSearchInput
    {...props}
    placeholder={props.i18n._(props.placeholder || '')}
    noSelectionPlaceholder={props.i18n._(props.noSelectionPlaceholder || '')}
  />
));

export const TransEmailInputField = withI18n()((props) => (
  <EmailInputField {...props} placeholder={props.i18n._(props.placeholder || '')} />
));

export const TransNumberInputField = withI18n()((props) => (
  <NumberInputField
    {...props}
    onBlur={props.onBlur ? props.onBlur : {}}
    placeholder={props.i18n._(props.placeholder || '')}
  />
));

export const TransPasswordInputField = withI18n()((props) => (
  <PasswordInputField {...props} placeholder={props.i18n._(props.placeholder || '')} />
));

export const TransTextArea = withI18n()((props) => (
  <TextArea {...props} placeholder={props.i18n._(props.placeholder || '')} />
));

export const TextInputField = (props) => {
  const opts = {};
  if (props.disabled !== undefined) {
    opts.disabled = true;
    opts.readOnly = true;
  }

  let type = 'text';
  if (props.type !== undefined) {
    type = props.type;
  }

  const styles = {
    input: {
      width: '100%',
      ...props.inputStyles,
    },
  };
  return (
    <FormGroup style={props.style}>
      {props.placeholder && (
        <Label for={props.name} sm={12}>
          {props.placeholder}
        </Label>
      )}
      <input
        autoComplete={props.autoComplete}
        pattern={props.pattern}
        className={`form-control${props.className !== undefined ? ` ${props.className}` : ''}`}
        style={styles.input}
        {...opts}
        type={type}
        onChange={props.onChange}
        maxLength={props.maxLength}
        onBlur={(e) => {
          if (props.saveValue === 'function') {
            props.saveValue(e.target.value, e.target.name);
          }
        }}
        name={props.name}
        placeholder={props.inlinePlaceholder ? props.inlinePlaceholder : ''}
        value={props.value !== null ? props.value : ''}
        data-testid={props.testId}
      />
    </FormGroup>
  );
};

export class PasswordInputField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let newValue = '';
    if (this.props.value !== null) {
      newValue = this.props.value;
    }
    const opts = {};
    if (this.props.disabled === 'disabled') {
      opts.disabled = true;
    }
    return (
      <FormGroup className="minheight">
        <Label for={this.props.name} sm={12}>
          {this.props.placeholder}
        </Label>
        <input
          className={`${this.props.className} form-control`}
          {...opts}
          type="password"
          onChange={this.props.onChange}
          name={this.props.name}
          value={newValue}
          data-testid={this.props.testId}
        />
      </FormGroup>
    );
  }
}

export class NumberInputField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let newValue = '';
    if (this.props.value !== null) {
      newValue = this.props.value;
    }
    return (
      <FormGroup className="minheight">
        <Label for={this.props.name} sm={12}>
          {this.props.placeholder}
        </Label>
        <input
          className="form-control"
          type="number"
          onBlur={this.props.onBlur}
          onChange={this.props.onChangeHandler}
          name={this.props.name}
          value={newValue}
        />
      </FormGroup>
    );
  }
}

export class EmailInputField extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let newValue = '';
    if (this.props.value !== null) {
      newValue = this.props.value;
    }
    return (
      <FormGroup className="minheight">
        <Label for={this.props.name} sm={12}>
          {this.props.placeholder}
        </Label>
        <input
          className="form-control"
          type="email"
          onChange={this.props.onChange}
          name={this.props.name}
          value={newValue}
        />
      </FormGroup>
    );
  }
}

export class SelectState extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cSelected: [] };
    this.onRadioBtnClick = this.onRadioBtnClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.handler(e);
  }

  onRadioBtnClick(rSelected) {
    if (rSelected == 1) {
      if (this.state.rSelected == 1) {
        rSelected = null;
        this.props.handler('');
      } else {
        this.props.handler('OPEN');
      }
    }
    if (rSelected == 2) {
      if (this.state.rSelected == 2) {
        rSelected = null;
        this.props.handler('');
      } else {
        this.props.handler('OFFERED');
      }
    }

    if (rSelected == 3) {
      if (this.state.rSelected == 3) {
        rSelected = null;
        this.props.handler('');
      } else {
        this.props.handler('BOOKED');
      }
    }

    if (rSelected == 4) {
      if (this.state.rSelected == 4) {
        rSelected = null;
        this.props.handler('');
      } else {
        this.props.handler('RECOMMENDED');
      }
    }

    if (rSelected == 5) {
      if (this.state.rSelected == 5) {
        rSelected = null;
        this.props.handler('');
      } else {
        this.props.handler('CANCELED');
      }
    }
    this.setState({ rSelected });
  }

  render() {
    return (
      <ButtonGroup>
        <TransButtonLinkWithIcon
          onClick={() => this.onRadioBtnClick(1)}
          color="primary"
          active={this.state.rSelected === 1}
          title="event.request.unanswered"
          icon="OPEN"
        />
        <TransButtonLinkWithIcon
          onClick={() => this.onRadioBtnClick(2)}
          color="primary"
          active={this.state.rSelected === 2}
          title="event.request.offered"
          icon="OFFERED"
        />
        <TransButtonLinkWithIcon
          onClick={() => this.onRadioBtnClick(3)}
          color="primary"
          active={this.state.rSelected === 3}
          title="event.request.booked"
          icon="BOOKED"
        />
        <TransButtonLinkWithIcon
          onClick={() => this.onRadioBtnClick(4)}
          color="primary"
          active={this.state.rSelected === 4}
          title="event.request.recommended"
          icon="RECOMMENDED"
        />
        <TransButtonLinkWithIcon
          onClick={() => this.onRadioBtnClick(5)}
          color="primary"
          active={this.state.rSelected === 5}
          title="event.request.canceled"
          icon="CANCELED"
        />
      </ButtonGroup>
    );
  }
}

export const InputField = (props) => {
  return (
    <label className="checkbox-checkbox" style={{ display: 'flex' }}>
      <Input
        type="checkbox"
        className={`checkbox-checkboxInput${props.isLoading ? ' loading' : ''}`}
        disabled={props.disabled}
        onClick={props.toggle}
        name={props.name}
        onChange={() => {}}
        checked={props.checked}
      />
      <span
        className={`ua-checkbox-checkboxLabel checkbox-checkboxLabel${props.isLoading ? ' loading' : ''}`}
        style={{ flex: 1, ...props.labelStyle }}
        data-testid={props.testId}
      >
        {props.text}
      </span>
    </label>
  );
};

export const CheckBox = (props) => {
  const [checked, setChecked] = useState(props.initialValue);

  function toggle() {
    props.checked(!checked);
    setChecked(!checked);
  }

  return <InputField toggle={toggle} checked={checked} text={props.text} name={props.name} testId={props.testId} />;
};

export class TextArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = { labelClass: '', empty: 'empty' };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  onFocus() {
    this.setState({ labelClass: 'focused' });
  }

  onBlur(e) {
    this.setState({ labelClass: '' });
    this.props.saveValue(e.target.value, e.target.name);
  }

  onChange(e) {
    if (e.target.value === '') {
      this.setState({ empty: 'empty' });
    } else {
      this.setState({ empty: '' });
    }
    this.props.onChange(e);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.value !== '') {
      this.setState({ empty: '' });
    }
  }

  render() {
    let { value } = this.props;
    if (value == null) {
      value = '';
    }

    value = value.replace(/<br\/>/g, '\n');
    let numberOfLineBreaks = 1;
    if (value !== undefined && value !== null) {
      numberOfLineBreaks = (value.match(/\n/g) || []).length;
    }
    const opts = {};
    if (this.props.disabled === 'disabled') {
      opts.disabled = true;
    }

    return (
      <FormGroup>
        {this.props.placeholder !== undefined && (
          <Label className={`textarea ${this.state.labelClass} ${this.state.empty}`} for={this.props.name} sm={12}>
            {this.props.placeholder}
          </Label>
        )}
        <textarea
          style={this.props.style}
          className="form-control"
          {...opts}
          onBlur={this.onBlur}
          rows={this.props.rows === undefined ? numberOfLineBreaks + 1 : this.props.rows}
          onChange={this.onChange}
          name={this.props.name}
          value={value}
        />
      </FormGroup>
    );
  }
}

export const SelectWrapper = ({
  onChangeHandler,
  isClearable,
  value,
  noSelectionPlaceholder,
  style,
  id,
  name,
  options,
  disabled,
  className,
  placeholder,
}) => {
  const [labelClass, setLabelClass] = useState('blurred');

  function changeStateHandler(value, name) {
    setLabelClass('blurred');
    if (value === null) {
      onChangeHandler(null, name);
    } else {
      onChangeHandler(value, name);
    }
  }

  function onFocus() {
    setLabelClass('focused');
  }

  function onBlur() {
    setLabelClass('blurred');
  }

  const indents = [];

  indents.push(
    <option key={-1} disabled={!isClearable} selected={!!(value == null || value === '')} value>
      {' '}
      {noSelectionPlaceholder}{' '}
    </option>,
  );
  options.map((value, index) => {
    const opts = {};

    if (value !== null && value !== undefined && value !== '' && value.value === value.value) {
      opts.selected = true;
    }
    indents.push(
      <option key={index} {...opts} value={value.value}>
        {value.label}
      </option>,
    );
  });

  return (
    <FormGroup style={style}>
      <Select
        id={id || ''}
        name={name}
        value={value}
        options={options}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        onChange={(item) => {
          if (item === null) {
            changeStateHandler(null, name);
          }
          if (item !== null) {
            changeStateHandler(item, name);
          }
        }}
        className={className}
        isClearable={isClearable}
        placeholder={noSelectionPlaceholder}
      />

      {placeholder && (
        <Label className={labelClass} for={name} sm={12}>
          {placeholder}
        </Label>
      )}
    </FormGroup>
  );
};

export class SelectField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value, labelClass: 'blurred' };
    this.changeStateHandler = this.changeStateHandler.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  changeStateHandler(value, name) {
    this.setState({ labelClass: 'blurred' });
    if (value === null) {
      this.props.onChangeHandler(null, name);
    } else {
      this.props.onChangeHandler(value, name);
    }
  }

  onFocus() {
    this.setState({ labelClass: 'focused' });
  }

  onBlur() {
    this.setState({ labelClass: 'blurred' });
  }

  render() {
    return (
      <FormGroup style={this.props.style} data-testid={this.props.testId}>
        {this.props.placeholder && (
          <Label className={this.state.labelClass} for={this.props.name} sm={12}>
            {this.props.placeholder}
          </Label>
        )}
        <Select
          id={this.props.id ? this.props.id : ''}
          name={this.props.name}
          value={this.props.value}
          options={this.props.options}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          disabled={this.props.disabled}
          onChange={(item) => {
            if (item === null) {
              this.changeStateHandler(null, this.props.name);
            }
            if (item !== null) {
              this.changeStateHandler(item.value, this.props.name);
            }
          }}
          className={this.props.className}
          isClearable={this.props.isClearable}
          placeholder={this.props.noSelectionPlaceholder}
        />
      </FormGroup>
    );
  }
}

export const TimeField = (props) => {
  const [parseError, setParseError] = useState(false);

  const opts = {};
  if (props.readonly !== undefined) {
    opts.readonly = true;
  }

  function formatTime(value) {
    if (value.includes('Uhr')) {
      value = value.replace('Uhr', '');
    }

    if (value.length > 0) {
      if (value.length === 1) {
        if (!isNaN(value)) {
          value = `0${value}:00`;
        } else {
          setParseError(true);
        }
      } else if (value.length === 2) {
        if (!isNaN(value)) {
          if (value <= 24) {
            value += ':00';
          } else {
            setParseError(true);
            console.error('keine gültige Zeitangabe');
          }
        } else if (value.indexOf(':') === 1) {
          // e.g.: 4:
          value = `0${value}00`;
        } else {
          setParseError(true);
        }
      } else if (value.length <= 5) {
        if (value.includes(':')) {
          if (value.indexOf(':') === 1) {
            if (value.length === 3) {
              // e.g.: 4:0
              value = `0${value}0`;
            }
            if (value.length === 4) {
              // e.g.: 6:00
              value = `0${value}`;
            }
          }
          if (value.indexOf(':') === 2) {
            if (value.length === 3) {
              // e.g.: 14:
              value += '00';
            }
            if (value.length === 4) {
              // e.g.: 10:0
              value += '0';
            }
          } else if (value.indexOf(':') === 1) {
            if (value.length === 3) {
              value = `0${value}`;
            } else {
              // hinter dem Doppelpunkt stehen mehr als 2 Zeichen => nicht erlaubt
              setParseError(true);
              console.log('keine gültige Zeitangabe');
            }
          } else {
          }
        } else if (!isNaN(value)) {
          if (value.length === 3) {
            value = `0${value.substring(0, 1)}:${value.substring(1, 3)}`;
          } else if (value.length === 4) {
            value = `${value.substring(0, 2)}:${value.substring(2, 4)}`;
          } else {
            // e.g.: 04:000
            setParseError(true);
            console.error('keine gültige Zeitangabe');
          }
        } else {
          setParseError(true);
          console.error('keine gültige Zeitangabe');
        }
      } else {
        // value.length > 5
      }

      if (value === '24:00') {
        value = '00:00';
      }

      const found = value.match(/([01]?[0-9]|2[0-3]):[0-5][0-9]/g);
      if (found) {
        setParseError(false);
        props.changeHandler(found[0], props.name);
        props.saveValue(found[0], props.name);
      } else {
        setParseError(true);
      }
    } else {
      props.changeHandler('', props.name);
      props.saveValue('', props.name);
    }
  }

  const { value } = props;

  const styles = {
    errorStyle: {
      borderColor: 'red',
    },
  };

  return (
    <TransTextInputField
      inputStyles={parseError ? styles.errorStyle : {}}
      {...opts}
      value={value}
      saveValue={(value, name) => {
        formatTime(value);
      }}
      onChange={(e) => {
        props.changeHandler(e.target.value, props.name);
      }}
      name={props.name}
      placeholder={props.placeholder}
    />
  );
};
