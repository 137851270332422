import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { Trans } from '@lingui/react';
import { translate } from '../Service/PlaceholderTranslationService';
import PlaceholderInputField from './inputs/PlaceholderInputField';
import { HtmlPlaceholderFormatter } from './Formatter/HtmlPlaceholderFormatter';

const Autocomplete = ({
  suggestions,
  value,
  reload,
  onChange,
  patchField,
  patchFieldAlternative,
  index,
  className,
  autoFocus,
  style,
  placeholder,
  saveValue,
  listTopComponent,
  inlinePlaceholder,
  listValueComponent,
  isLoading,
  previewOnNewSuggestions,
  filterSuggestions,
  event,
  eventLocation,
  customer,
  _document,
  settings,
  disableTextSelectionPopover,
  labelStyle,
}) => {
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState(value);
  const [selectedValue, setSelectedValue] = useState();
  let internValue;
  const [startMove, setStartMove] = useState(undefined);

  // new suggestions e.g. by ajax call
  useEffect(() => {
    if (previewOnNewSuggestions) {
      setShowSuggestions(true);
      setFilteredSuggestions(suggestions);
    }
  }, [previewOnNewSuggestions, suggestions]);

  const changeValue = (v) => {
    onChange({ [patchField]: v });
    internValue = v;
    loadSuggestions();
  };

  const loadSuggestions = (fastInternValue) => {
    let tmpValue = internValue;

    if (fastInternValue) {
      tmpValue = fastInternValue;
    }
    // Filter our suggestions that don't contain the user's input
    // and is not equal the suggested one userInput !== suggestion
    let newFilteredSuggestions = Object.assign([], suggestions);
    if (tmpValue !== null && tmpValue !== '') {
      if (typeof filterSuggestions === 'function') {
        newFilteredSuggestions = suggestions.filter((suggestion) => filterSuggestions(suggestion, tmpValue));
      } else {
        newFilteredSuggestions = suggestions.filter((suggestion) => {
          if (suggestion !== null && suggestion) {
            if (!tmpValue) return true;
            return (
              JSON.stringify(suggestion).toLowerCase().indexOf(tmpValue.toLowerCase()) > -1 &&
              suggestion[patchField] !== tmpValue
            );
          }
          return true;
        });
      }

      setShowSuggestions(newFilteredSuggestions.length > 0);
      setFilteredSuggestions(newFilteredSuggestions);
    } else {
      setShowSuggestions(true);
      setFilteredSuggestions(newFilteredSuggestions);
    }
  };

  const onInputChange = (e) => {
    let tmpValue = e.target.innerHTML;

    // remove line break, if user hits enter to apply selected value
    tmpValue = tmpValue.replace('<br>', '');

    internValue = tmpValue;

    loadSuggestions(tmpValue);
    changeValue(tmpValue);

    if (activeSuggestion > -1 && filteredSuggestions[activeSuggestion]) {
      setUserInput(filteredSuggestions[activeSuggestion][patchField]);
      changeValue(filteredSuggestions[activeSuggestion][patchField]);
    }
  };

  // select suggestion
  const onClick = (suggestion) => {
    if (typeof saveValue === 'function') {
      saveValue(suggestion);
    }

    // important e.g. for custom attribute group name
    setUserInput(suggestion[[patchField]]);

    // propagate selected value in PlaceholderInputField to block there the onBlur-Event
    // to prevent overriding selected value
    if (suggestion[[patchField]]) {
      setSelectedValue(suggestion[[patchField]]);
    } else {
      setSelectedValue(suggestion[patchFieldAlternative]);
    }
    // setUserInput(suggestion[[patchField]]);
    // changeValue(suggestion[patchField]);

    setShowSuggestions(false);
  };

  // navigate through suggestions and select
  const onKeyDown = (e) => {
    // User pressed the enter key
    if (e.key === 'Escape') {
      e.target.innerHTML = userInput;
      changeValue(userInput);
      setActiveSuggestion(-1);
      setShowSuggestions(false);
    }
    if (e.keyCode === 13) {
      if (filteredSuggestions.length > 0 && activeSuggestion !== -1 && filteredSuggestions[activeSuggestion]) {
        e.target.innerHTML = filteredSuggestions[activeSuggestion][patchField];
        changeValue(filteredSuggestions[activeSuggestion][patchField]);
        saveValue(filteredSuggestions[activeSuggestion]);
        setActiveSuggestion(-1);
        setShowSuggestions(false);
      } else {
        e.target.blur();
      }
      e.preventDefault();
    }
    // User pressed the up arrow
    else if (e.keyCode === 38 && showSuggestions) {
      if (activeSuggestion === -1) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40 && showSuggestions) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      if (filteredSuggestions.length > activeSuggestion + 1) {
        setActiveSuggestion(activeSuggestion + 1);
      }
    } else if (e.keyCode === 8) {
      loadSuggestions();
    }
  };

  let suggestionsListComponent;

  const styles = {
    input: {
      padding: '0.375rem 0.75rem',
      border: '1px solid rgba(0, 0, 0, 0.125)',
      borderRadius: '4px',
      height: 34,
    },
    label: {
      ...labelStyle,
    },
    suggestionsList: {
      position: 'absolute',
      zIndex: 1,
      backgroundColor: '#ffffff',
      borderRadius: 4,
      padding: 2,
    },
  };

  if (showSuggestions) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <div style={styles.suggestionsList}>
          <ul className="suggestions">
            {listTopComponent || undefined}
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'suggestion-active';
              }
              const ListValueComponent = listValueComponent;
              return (
                <li
                  className={className}
                  key={index}
                  onTouchMove={() => setStartMove(true)}
                  onTouchEnd={() => {
                    if (!startMove) onClick(suggestion);
                  }}
                  onMouseDown={() => {
                    onClick(suggestion);
                  }}
                >
                  {listValueComponent ? (
                    <ListValueComponent suggestion={suggestion} />
                  ) : (
                    <HtmlPlaceholderFormatter
                      value={suggestion[patchField]}
                      label
                      // event={event}
                      customer={customer}
                      _document={_document}
                      // eventLocation={eventLocation}
                      settings={settings}
                    />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else {
      suggestionsListComponent = null;
    }
  }

  return (
    <div>
      {placeholder !== undefined && (
        <Label style={styles.label} for={patchField} sm={12}>
          <Trans id={placeholder} />
        </Label>
      )}
      <PlaceholderInputField
        htmlString={userInput}
        selectedValue={selectedValue}
        index={index}
        name={patchField}
        autoFocus={autoFocus}
        disableTextSelectionPopover={disableTextSelectionPopover}
        onBlur={(e) => {
          setShowSuggestions(false);
          let v = e.target.innerHTML.replace('<br>', '');

          // nur wegen dem Tippy Eingabe-Feld wichtig,
          // wenn man im Label einer Position ein Datenfeld ändern möchte,
          // damit es sich nicht schließt, bzw. nur, wenn sich die Feld-Daten geändert haben

          // ansonsten könnte man hier einfach nur saveValue({[patchField]: v}); aufrufen
          v = translate.toEnglish(v);
          if (v !== userInput) {
            saveValue({ [patchField]: v });
          }
        }}
        onFocus={() => {
          if (typeof reload === 'function') {
            reload(userInput);
          }
          loadSuggestions();
        }}
        className={className}
        inlinePlaceholder={inlinePlaceholder}
        style={style}
        onInputChange={onInputChange}
        onKeyDown={onKeyDown}
        loading={isLoading}
        event={event}
        customer={customer}
        _document={_document}
        finish={(newValue) => {
          saveValue({ [patchField]: translate.toEnglish(newValue.replace('<br>', '')) });
        }}
        settings={settings}
        error={undefined}
      />

      {suggestionsListComponent}
    </div>
  );
};

Autocomplete.propTypes = {
  suggestions: PropTypes.instanceOf(Array),
};

export default Autocomplete;
