import { createContext, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useListState } from '@mantine/hooks';
import { BookitupNotification, DefaultConfig, NotificationConfig, NotificationFilter } from '../../Types/LogT';
import { LogS } from '../LogS';
import { useBrowserNotifications } from '../hooks/useBrowserNotifications';
import { useNotificationTab } from '../hooks/useNotificationTab';

interface NotificationContextInterface {
  notifications: BookitupNotification[];
  loading: boolean;
  unseenCount: number;
  markAsSeen: () => void;
  markAllAsSeen: () => void;
  deleteAll: () => void;
  hasMore: boolean;
  filter: NotificationFilter;
  config: NotificationConfig;
  updateConfig: (cfg: NotificationConfig) => void;
  updateFilter: (patch: Partial<NotificationFilter>) => void;
}

export const NotificationsContext = createContext<NotificationContextInterface>({} as NotificationContextInterface);

export const NotificationsContextProvider = () => {
  const [loading, setLoading] = useState(false);
  const [unseenCount, setUnseenCount] = useState<number>(0);
  const [filter, setFilter] = useState<NotificationFilter>({ includeAll: false, pageNumber: 0 });
  const [notifications, handlers] = useListState<BookitupNotification>([]);
  const [config, setConfig] = useState<NotificationConfig>(DefaultConfig);
  const [hasMore, setHasMore] = useState(false);
  const { includeAll, pageNumber } = filter;
  const { setState: setNotifications, applyWhere } = handlers;

  useBrowserNotifications(notifications);
  useNotificationTab(unseenCount);

  useEffect(() => {
    loadUnseenCount();
    loadConfig();
    // Refreshing notifications every minute
    const tick = setInterval(() => {
      fetchNext(true);
      loadUnseenCount();
    }, 1000 * 60);
    return () => clearInterval(tick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchNext(pageNumber === 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const updateFilter = (patch: Partial<NotificationFilter>) => {
    if (patch.includeAll !== undefined) {
      // eslint-disable-next-line no-param-reassign
      patch.pageNumber = 0;
    }
    setFilter((prevFilter) => ({ ...prevFilter, ...patch }));
  };

  const loadConfig = async () => {
    const cfg = await LogS.fetchNotificationConfig();
    if (cfg) {
      setConfig(cfg);
    }
  };

  const updateConfig = (cfg: NotificationConfig) => {
    setConfig(cfg);
    updateFilter({ pageNumber: 0 });
  };

  const loadUnseenCount = () => {
    LogS.fetchUnseenNotificationsCount().then((count) => {
      if (count !== null) {
        setUnseenCount(count);
      }
    });
  };

  const markAllAsSeen = async () => {
    const marked = await LogS.markAllAsSeen();
    if (marked) {
      applyWhere(
        (notification) => !notification.seen,
        (notification) => ({ ...notification, seen: new Date() }),
      );
      setUnseenCount(0);
    }
  };

  const deleteAll = async () => {
    const deleted = await LogS.deleteAllNotifications();
    if (deleted) {
      setNotifications([]);
      setUnseenCount(0);
    }
  };

  // Called on popover close
  const markAsSeen = () => {
    if (notifications.length > 0) {
      const unseenNotifications = notifications.filter((n) => !n.seen).map((n) => n.id);
      if (unseenNotifications.length > 0) {
        LogS.markAsSeen(unseenNotifications).then((success) => {
          if (success) {
            loadUnseenCount();
            updateFilter({ pageNumber: 0 });
          }
        });
      }
    }
  };

  const fetchNext = async (override = false) => {
    setLoading(true);
    const page = await LogS.fetchNotifications(pageNumber, includeAll);
    if (page) {
      const { content, last } = page;
      setHasMore(!last);
      if (override) {
        LogS.insertTimeIndents(content);
        setNotifications(content);
      } else {
        const tmp = [...notifications, ...content];
        LogS.insertTimeIndents(tmp);
        setNotifications(tmp);
      }
    }
    setLoading(false);
  };

  const value = useMemo(
    () => ({
      notifications,
      loading,
      filter,
      config,
      updateConfig,
      updateFilter,
      markAsSeen,
      markAllAsSeen,
      deleteAll,
      unseenCount,
      hasMore,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMore, config, loading, notifications, unseenCount, filter],
  );

  return (
    <NotificationsContext.Provider value={value}>
      <Outlet />
    </NotificationsContext.Provider>
  );
};
