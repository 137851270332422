// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Loader, Space, Text } from '@mantine/core';
import { useState } from 'react';
import ConfigOption from '../../../../Atoms/ConfigOption';
import FilePicker from '../../../../Atoms/FilePicker';
import { DocumentS } from '../../../Documents/DocumentS';
import { LexOfficeS } from '../../../../Service/LexOfficeS';
import PanePaper from '../../../../Molecules/PanePaper';
import { MixpanelS } from '../../../../Service/MixpanelS';
import { ConstantS } from '../../../../Service/ConstantS';

const LexOfficeImportPane = () => {
  const [importingProducts, setImportingProducts] = useState(false);
  const [importingContacts, setImportingContacts] = useState(false);

  const importContacts = async (csvFile: File) => {
    setImportingContacts(true);
    await LexOfficeS.importContacts(csvFile);
    MixpanelS.track(ConstantS.TrackingEvents.LexOfficeEntitiesImported, { entityType: 'contact' });
    setImportingContacts(false);
  };

  const importProducts = async (csvFile: File) => {
    setImportingProducts(true);
    await DocumentS.importProductsLOX(csvFile);
    MixpanelS.track(ConstantS.TrackingEvents.LexOfficeEntitiesImported, { entityType: 'product' });
    setImportingProducts(false);
  };

  return (
    <PanePaper withoutBorder sx={{ backgroundColor: '#F3F3F3' }}>
      <ConfigOption
        title="Kontakte importieren"
        description="Importiere deine Kontakte aus lexoffice, indem Du in lexoffice unter 'Kontakte', dann auf die drei Punkte oben rechts -> 'Alle Kunden exportieren (CSV)' klickst und diese Datei in bookitup hochlädst."
        rightSection={
          importingContacts ? (
            <Loader variant="dots" size="xl" />
          ) : (
            <FilePicker
              id="contacts"
              onSelectFile={importContacts}
              accept=".csv"
              label={
                <Text variant="link" color="blue" sx={{ cursor: 'pointer' }}>
                  Importieren
                </Text>
              }
            />
          )
        }
      />
      <Space h="md" />
      <ConfigOption
        title="Produkte/Service importieren"
        description="Importiere Deine Produkte/Services aus lexoffice, indem Du in lexoffice oben rechts auf das Zahnrad klickst -> 'Produkte/Services', dann auf die drei Punkte oben rechts -> 'Alle Produkte/Services exportieren (CSV)' klickst und diese Datei in bookitup hochlädst."
        rightSection={
          importingProducts ? (
            <Loader variant="dots" size="xl" />
          ) : (
            <FilePicker
              id="products"
              onSelectFile={importProducts}
              accept=".csv"
              label={
                <Text variant="link" color="blue" sx={{ cursor: 'pointer' }}>
                  Importieren
                </Text>
              }
            />
          )
        }
      />
    </PanePaper>
  );
};

export default LexOfficeImportPane;
