// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { i18n } from '@lingui/core';
import { BookitupNotification } from '../../../../Types/LogT';

const EventStateChanged: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { value } = notification;

  return (
    <Text>
      Ereignisstatusänderung zu{'  '}
      <Text span weight="bolder">
        {i18n._(`event.bookingstate.${value?.toLowerCase()}`)}
      </Text>
      .
    </Text>
  );
};

export default EventStateChanged;
