import { ajaxActions } from './AjaxActions';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_AUTH_SERVICE_URL;

const activateTrial = async (trialEndsAt: Date) => {
  const res = await ajaxActions.get(`${BASE_URL}/profiles/activate-trial?trialEndsAt=${trialEndsAt.toISOString()}`);
  if (res.ok) {
    ToastS.success('foo', 'Trial activated');
  } else {
    ToastS.generalError();
  }
};

export const AuthS = {
  activateTrial,
};
