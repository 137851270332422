// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Center, Skeleton, Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { useContext, useEffect, useState } from 'react';
import Moment from 'react-moment';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import BookitupTable from '../../Atoms/BookitupTable';
import { EventContext } from '../../Service/Context/EventContext';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import { LicenceS } from '../../Service/LicenceS';
import { fetchFileListOfEvent } from '../../Service/restapi/fileService';
import { useModalState } from '../Dialogs';
import { FileModalDialog } from '../Dialogs/FileModalDialog';
import { FileUploadModalDialog } from '../Dialogs/FileUploadModalDialog';
import PanePaper from '../PanePaper';
import EventFileActions from './EventFileActions';

type BookitupFile = {
  fileName: string;
  size: number;
  lastModified: Date;
  entityId?: string;
  entityType?: string;
};

const EventUploads = () => {
  const { event } = useContext<any>(EventContext);
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const [showFileUploadDialog, setShowFileUploadDialog] = useState<boolean>(false);
  const { show, toggle } = useModalState(false);
  const [file, setFile] = useState<BookitupFile>();
  const [files, setFiles] = useState<BookitupFile[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const toggleFileUpload = () => setShowFileUploadDialog(!showFileUploadDialog);
  const [lastUpdate, setLastUpdate] = useState<Date>();
  const { id: eventId } = event;
  const reload = () => setLastUpdate(new Date());
  const { ref, width } = useElementSize();

  useEffect(() => {
    fetchUploadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (lastUpdate) {
      fetchUploadFiles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdate]);

  const fetchUploadFiles = () => {
    setLoading(true);
    fetchFileListOfEvent(eventId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then(setFiles);
        }
      })
      .finally(() => setLoading(false));
  };

  const selectFile = (bookitupFile: BookitupFile) => {
    setFile({ ...bookitupFile, entityType: 'events', entityId: eventId });
    toggle();
  };

  const showColumns = {
    showDatum: width > 270,
  };

  return (
    <PanePaper
      title="Uploads"
      tooltip="help.event.files.pane"
      rightSection={
        <BookitupAddIcon
          onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.EVENT_UPLOAD_FILE, toggleFileUpload)}
        />
      }
    >
      <div ref={ref}>
        {loading ? (
          <div>
            {Array.from(Array(3)).map(() => (
              <Skeleton key={Math.random()} radius="md" height={25} width="100%" mb={10} />
            ))}
          </div>
        ) : (
          <div>
            {files.length === 0 ? (
              <Text italic color="dimmed" size="xs" weight="lighter">
                Noch nichts hinzugefügt.
              </Text>
            ) : (
              <div ref={ref}>
                <BookitupTable>
                  <thead>
                    <tr>
                      <th>Bezeichnung</th>
                      {showColumns.showDatum && <th style={{ width: 50 }}>Datum</th>}
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.map((file) => {
                      const { fileName, lastModified } = file;
                      const creationDate = new Date(lastModified);
                      return (
                        <tr key={fileName}>
                          <td onClick={() => selectFile(file)} style={{ cursor: 'pointer' }}>
                            {fileName}
                          </td>
                          {showColumns.showDatum && (
                            <td>
                              <Moment date={creationDate} format="DD.MM" />
                            </td>
                          )}
                          <td style={{ position: 'relative' }}>
                            <Center>
                              <div style={{ position: 'absolute', right: 0 }}>
                                <EventFileActions
                                  entityId={event.id}
                                  entityType="events"
                                  fileName={fileName}
                                  reload={reload}
                                />
                              </div>
                            </Center>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </BookitupTable>
              </div>
            )}
          </div>
        )}
      </div>

      <FileModalDialog
        show={show}
        toggle={toggle}
        allowDownload
        allowEmailAttachment={false}
        allowEditing={false}
        file={file}
        eventId={undefined}
      />
      <FileUploadModalDialog
        serverUrl={`${process.env.REACT_APP_FILE_SERVICE_URL}/files/events/${event.id}`}
        reloadCallback={() => reload()}
        text="Hier kannst Du Dateien zum Event hineinziehen"
        show={showFileUploadDialog}
        toggle={toggleFileUpload}
      />
    </PanePaper>
  );
};

export default EventUploads;
