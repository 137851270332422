// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Center, Group, Loader, Stack, Text } from '@mantine/core';
import { useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NotificationsContext } from '../../Service/Context/NotificationsContext';
import ToggleText from '../../Atoms/ToggleText';
import GeneralNotification from './GeneralNotification';
import GeneralNotificationsActions from './GeneralNotificationsActions';

const BookitupNotifications = () => {
  const { notifications, hasMore, filter, updateFilter, loading } = useContext(NotificationsContext);
  const { pageNumber, includeAll } = filter;
  const toggleFilter = () => updateFilter({ includeAll: !includeAll });
  const nextPage = () => updateFilter({ pageNumber: pageNumber + 1 });

  return (
    <>
      <Group position="apart" mt="xs" mb="xl">
        <Text size="xl" weight="bold">
          Benachrichtigungen
        </Text>
        {loading && <Loader variant="dots" />}
        {!loading && <GeneralNotificationsActions />}
      </Group>
      <Group spacing={5} mb="xs" pb="xs" sx={{ borderBottom: '2px solid #e2e2e2' }}>
        <ToggleText text="Ungelesen" active={!includeAll} toggle={toggleFilter} />
        <ToggleText text="Alle" active={includeAll} toggle={toggleFilter} />
      </Group>
      <InfiniteScroll
        scrollableTarget="scrollableDiv"
        dataLength={notifications.length}
        next={nextPage}
        hasMore={hasMore}
        loader={
          <Center mt="xl" mb="md">
            <Loader variant="dots" size="xl" />
          </Center>
        }
        endMessage={
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {notifications.length === 0 ? (
              <EmptyListSkeleton />
            ) : (
              <Text italic color="dimmed" align="center" mt="xl" mb="md">
                Du hast jetzt wirklich bis ans Ende dieser Liste gescrolled? 👀 Nice.
              </Text>
            )}
          </>
        }
      >
        <Stack spacing={5}>
          {notifications.map((notification) => (
            <div key={notification.id}>
              <GeneralNotification notification={notification} />
            </div>
          ))}
        </Stack>
      </InfiniteScroll>
    </>
  );
};

const EmptyListSkeleton = () => (
  <Stack>
    <Box sx={(theme) => ({ width: '100%', height: 50, backgroundColor: theme.colors.gray[0], borderRadius: 10 })} />
    <Box sx={(theme) => ({ width: '100%', height: 50, backgroundColor: theme.colors.gray[0], borderRadius: 10 })} />
    <Box sx={(theme) => ({ width: '100%', height: 100, backgroundColor: theme.colors.gray[0], borderRadius: 10 })} />
    <Text italic color="dimmed" align="center" mt="xl" mb="md">
      Sie haben keine Benachrichtigungen
    </Text>
  </Stack>
);

export default BookitupNotifications;
