import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withI18n } from '@lingui/react';
import { InputField } from '../InputFields';
import { InputHelp } from './InputHelp';

export const EditableCheckbox = withI18n()(
  ({ finishCallback, value, placeholder, patchField, disabled, style, i18n, labelStyle, testId, ...rest }) => {
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState(false);

    function toggle() {
      setLoading(true);
      finishCallback({ [patchField]: !value })
        .catch((error) => setError(error.message))
        .then(() => setLoading(false));
    }

    return (
      <div style={style}>
        <InputField
          isLoading={loading}
          checked={value}
          labelStyle={labelStyle}
          toggle={toggle}
          text={i18n._(placeholder || '')}
          name={patchField}
          disabled={disabled}
          testId={testId}
        />
        {error ? <InputHelp errorDescription={error} /> : null}
      </div>
    );
  },
);

EditableCheckbox.propTypes = {
  value: PropTypes.string,
  finishCallback: PropTypes.func,
  placeholder: PropTypes.string,
};
