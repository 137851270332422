import { translate } from '../Service/PlaceholderTranslationService';

const onBlurEditableContent = (e, selectedValue, onBlur, finish) => {
  if (e.target.nodeName === 'BUTTON') {
    return;
  }
  if (selectedValue) {
    if (onBlur) {
      e.target.innerHTML = selectedValue;
      onBlur(e);
    }
    return; // case: suggestion selected in Autocomplete.jsx
  }
  if (onBlur) {
    onBlur(e);
  }

  // Firefox, Chrome
  if (e.relatedTarget && e.relatedTarget.classList && e.relatedTarget.classList.contains('open-emoji-picker')) {
    return;
  }

  if (e.relatedTarget && e.relatedTarget.classList && e.relatedTarget.classList.contains('open-share-link')) {
    return;
  }

  // Safari
  if (
    e.relatedTarget &&
    e.relatedTarget.childNodes &&
    e.relatedTarget.childNodes[0] &&
    e.relatedTarget.childNodes[0].classList &&
    e.relatedTarget.childNodes[0].classList.contains('open-emoji-picker')
  ) {
    return;
  }
  if (
    e.relatedTarget &&
    e.relatedTarget.childNodes &&
    e.relatedTarget.childNodes[0] &&
    e.relatedTarget.childNodes[0].classList &&
    e.relatedTarget.childNodes[0].classList.contains('open-share-link')
  ) {
    return;
  }

  if (
    e.relatedTarget &&
    e.relatedTarget.childNodes &&
    e.relatedTarget.childNodes[0] &&
    e.relatedTarget.childNodes[0].classList &&
    e.relatedTarget.childNodes[0].classList.contains('emoji-img')
  ) {
    return;
  }
  if (
    e.relatedTarget &&
    e.relatedTarget.parentNode &&
    e.relatedTarget.parentNode.classList &&
    e.relatedTarget.parentNode.classList.contains('tippy-tooltip-content')
  ) {
    return;
  }

  // if relatedTarget is in nether land or the appropriate input
  if (
    !e.relatedTarget ||
    (!e.relatedTarget.getAttribute('data-placeholder-id') &&
      !e.relatedTarget.classList.contains('open-placeholder-menu') && // Firefox, Chrome
      !e.relatedTarget.classList.contains('show-placeholder-menu') && // Safari
      !e.relatedTarget.classList.contains('text-formatter-option-cell'))
  ) {
    const newHtmlString = translate.removeUnnecessary(e.target.innerHTML);
    finish(newHtmlString);
  } else {
    e.persist();
    setTimeout(() => {
      const elements = document.getElementsByClassName('show-placeholder-menu'); // why testing length === 0?
      if (
        elements.length === 0 &&
        (!e.relatedTarget || !e.relatedTarget.classList.contains('text-formatter-option-cell'))
      ) {
        const newHtmlString = translate.removeUnnecessary(e.target.innerHTML);
        finish(newHtmlString);
      }
    }, 100);
  }
};

export default onBlurEditableContent;
