import { Center } from '@mantine/core';
import { FC, useContext } from 'react';
import { Contact, ContactsColumnVisibility } from '../../Service/ContactS';
import { openEditContactModal } from '../../Organismns/Contact/EditContactModal';
import { ContactsContext } from '../../Service/Context/ContactsContext';
import ContactRowActions from './ContactRowActions';

interface Props extends ContactsColumnVisibility {
  key: string;
  contact: Contact;
}

const ContactRow: FC<Props> = ({ key, contact, showMail, showPhone, showGroups }) => {
  const { reload } = useContext(ContactsContext);
  const { personDescription, email, phoneNumber, groups } = contact;

  const edit = () => openEditContactModal(contact, reload);

  return (
    <tr key={key}>
      <td onClick={edit} style={{ cursor: 'pointer' }}>
        {personDescription ?? email}
      </td>
      {showMail && <td>{email}</td>}
      {showPhone && <td>{phoneNumber}</td>}
      {showGroups && <td>{groups.join(', ')}</td>}
      <td style={{ position: 'relative' }}>
        <Center>
          <div style={{ position: 'absolute', right: 0 }}>
            <ContactRowActions contact={contact} />
          </div>
        </Center>
      </td>
    </tr>
  );
};

export default ContactRow;
