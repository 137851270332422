/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Center, Checkbox, MultiSelect, Select, SimpleGrid, Space, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useModals } from '@mantine/modals';
import { FC, useContext, useEffect, useState } from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import mixpanel from 'mixpanel-browser';
import GoogleAutocomplete from '../../Atoms/Autocomplete/GoogleAutocomplete';
import { DataS } from '../../Service/DataS';
import { Profile } from '../../Service/ProfileS';
import Contact from '../../Types/Contact';
import { ConstantS } from '../../Service/ConstantS';
import { MixpanelS } from '../../Service/MixpanelS';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { ContactContext } from '../../Service/Context/ContactContext';
import { ApplicationUserContext } from '../../Service/Context/ApplicationUserContext';
import ExtendedOnboarding from "./ExtendedOnboarding";

const Onboarding = () => {
  const { profile, patchProfile } = useContext(ProfileContext);
  const { contact, saveContactChange } = useContext<any>(ContactContext);
  const { savePatch } = useContext<any>(ApplicationUserContext);
  const { openModal, closeAll } = useModals();
  const [onboardingCallModal, setOnboardingCallModal] = useState<boolean>(false);

  useEffect(() => {
    if (!profile?.dismissOnboarding) {
      openModal({
        size: 500,
        children: (
          <OnboardingModal
            contact={contact}
            saveProfileChange={patchProfile}
            saveContactChange={saveContactChange}
            saveAppUserChange={savePatch}
            onFinish={() => {
              closeAll();
              setOnboardingCallModal(true);
            }}
          />
        ),
        closeOnEscape: false,
        withCloseButton: false,
      });
    }
    return () => closeAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (onboardingCallModal) {
  //     openModal({
  //       size: 400,
  //       children: (
  //         <ExtendedOnboarding
  //           contact={contact}
  //         />
  //       ),
  //       closeOnEscape: false,
  //       withCloseButton: false,
  //     })
  //   }
  // }, [onboardingCallModal]);

  return null;
};

const OnboardingModal: FC<{
  contact: Contact;
  saveContactChange: (patch: Partial<Contact>) => void;
  saveProfileChange: (patch: Partial<Profile>) => void;
  saveAppUserChange: (patch: any) => void;
  onFinish: () => void;
}> = ({ contact, saveContactChange, saveProfileChange, saveAppUserChange, onFinish }) => {
  const { firstName: initialFirstName, lastName: initialLastName } = contact;

  const form = useForm({
    initialValues: {
      companyName: '',
      alias: '',
      firstName: initialFirstName,
      lastName: initialLastName,
      phoneNumber: '',
      addressOfWebsite: '',
      userOrigin: '',
      services: [],
      smallScaleBusiness: false,
      addressStreetAndNo: '',
      city: '',
      zipCode: '',
    },
    validate: {
      alias: (value) => (value.length > 0 ? null : 'Bitte geben Sie den Künstlername ein'),
      lastName: (value) => (value && value.length > 0 ? null : 'Bitte geben Sie den Nachnamen ein'),
      firstName: (value) => (value && value.length > 0 ? null : 'Bitte geben Sie den Vornamen ein'),
      services: (value) => (value.length > 0 ? null : 'Bitte wählen Sie mindestens einen Geschäftsbereich aus'),
      userOrigin: (value) => (value.length > 0 ? null : 'Bitte teilen Sie uns mit, wie Sie von uns erfahren haben'),
    },
  });

  const onSubmit = () => {
    const {
      companyName,
      alias,
      firstName,
      lastName,
      phoneNumber,
      addressOfWebsite,
      userOrigin,
      services,
      smallScaleBusiness,
      addressStreetAndNo,
      city,
      zipCode,
    } = form.values;
    saveContactChange({ companyName, firstName, lastName, phoneNumber, addressStreetAndNo, city, zipCode });
    saveAppUserChange({ userOrigin });
    setTimeout(() => {
      saveProfileChange({ alias, addressOfWebsite, services, smallScaleBusiness, dismissOnboarding: true });
    }, 1500);
    ReactPixel.trackCustom(ConstantS.TrackingEvents.OnboardingCompleted);
    ReactGA.event({ category: 'User', action: ConstantS.TrackingEvents.OnboardingCompleted });
    MixpanelS.track(ConstantS.TrackingEvents.OnboardingCompleted);
    if (mixpanel.people) {
      mixpanel.people.append({ services });
    }
    onFinish();
  };

  const onPlaceSelected = (place: any) => {
    for (const [key, value] of Object.entries(place)) {
      form.setFieldValue(key, value);
    }
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Text mt="md" weight="bolder" size={24} align="center" sx={{ lineHeight: 1.2 }}>
        &#129395; {` Willkommen, Du rockst${initialFirstName ? ` ${initialFirstName}` : ''}!`}
      </Text>
      <Space h="md" />
      <Text mb="xl" align="center">
        Danke, dass Du Dich für bookitup entschieden hast. Unser Team feiert Dich. Bitte hinterlege Deine wichtigsten
        Unternehmensdaten.
      </Text>
      <GoogleAutocomplete
        label="Marken-/Künstlername"
        onPlaceSelected={onPlaceSelected}
        {...form.getInputProps('alias')}
      />
      <Space h="sm" />
      <TextInput mb="sm" label="Unternehmensname" {...form.getInputProps('companyName')} />
      <SimpleGrid cols={2} mb="sm">
        <TextInput label="Vorname" {...form.getInputProps('firstName')} />
        <TextInput label="Nachname" {...form.getInputProps('lastName')} />
      </SimpleGrid>
      {form.values.addressStreetAndNo && (
        <>
          <TextInput mb="sm" label="Straße und Hausnummer" {...form.getInputProps('addressStreetAndNo')} />
          <SimpleGrid cols={2} mb="sm">
            <TextInput label="PLZ" {...form.getInputProps('zipCode')} />
            <TextInput label="Stadt" {...form.getInputProps('city')} />
          </SimpleGrid>
        </>
      )}
      <TextInput mb="sm" label="Telefonnummer" {...form.getInputProps('phoneNumber')} />
      <TextInput mb="sm" label="Website" {...form.getInputProps('addressOfWebsite')} />
      <MultiSelect searchable mb="sm" label="Geschäftsbereich" data={DataS.servicesOptions} {...form.getInputProps('services')} />
      <Select
        searchable
        mb="md"
        label="Wie bist Du auf bookitup aufmerksam geworden?"
        data={DataS.howYouKnowUsOptions}
        {...form.getInputProps('userOrigin')}
      />
      <Checkbox
        mb="sm"
        label="Ich bin Kleinunternehmer und weise keine Mehrwertsteuer aus."
        {...form.getInputProps('smallScaleBusiness')}
      />
      <Center>
        <Button id={"complete-onboarding"} mb="xl" mt="md" type="submit">
          Einrichtung abschließen
        </Button>
      </Center>
    </form>
  );
};

export default Onboarding;
