import { FC, ReactNode } from 'react';
import moment from 'moment';
import { IconCheck, IconDotsVertical, IconTrashX } from '@tabler/icons';
import { Center, Menu, Text, ThemeIcon } from '@mantine/core';
import { closeAllModals, openConfirmModal } from '@mantine/modals';
import { i18n } from '@lingui/core';
import { PlanningState, ResourcePlanningDTO } from '../../../Types/ResourceT';
import EventBadge from '../../../Atoms/EventBadge';
import ResourcePlanningS from '../../../Service/restapi/resourcePlanningS';

interface Props {
  key: string;
  resoucePlanning: ResourcePlanningDTO;
  refresh: () => void;
}

const ResourcePlanningItem: FC<Props> = ({ key, resoucePlanning, refresh }) => {
  const { id, begin, end, quantity, eventId, warehouseStatus } =
    resoucePlanning;

  const onDelete = () => {
    const onConfirm = async () => {
      const deleted = await ResourcePlanningS.deletePlanning(id);
      if (deleted) {
        refresh();
        closeAllModals();
      }
    };

    openConfirmModal({
      title: (
        <Text weight="bolder" size="xl">
          Planung löschen
        </Text>
      ),
      children: <Text size="sm">Möchten Sie diese Planung wirklich löschen?</Text>,
      labels: { confirm: i18n._('actions.delete'), cancel: i18n._('actions.cancel') },
      confirmProps: { color: 'red' },
      groupProps: { spacing: 5 },
      onCancel: closeAllModals,
      onConfirm,
    });
  };

  const getIcon = (): ReactNode => {
    if (warehouseStatus === PlanningState.CONFIRMED) {
      return (
        <ThemeIcon radius="xl" size="sm">
          <IconCheck size={14} />
        </ThemeIcon>
      );
    }
    return null;
  };

  return (
    <tr key={key}>
      <td> {getIcon()}</td>
      <td>{`${moment(begin).format('DD.MM.YYYY HH:mm')} - ${moment(end).format('DD.MM.YYYY HH:mm')}`}</td>
      <td>{quantity}</td>
      <td>
        <EventBadge
          eventId={eventId}
        />
      </td>
      <td style={{ position: 'relative' }}>
        <Center>
          <div style={{ position: 'absolute', right: 0 }}>
            <Menu shadow="md" width={130} transition="scale-y" position="bottom-end">
              <Menu.Target>
                <div>
                  <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
                </div>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item color="red" onClick={onDelete} icon={<IconTrashX size={16} />}>
                  Löschen
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </div>
        </Center>
      </td>
    </tr>
  );
};

export default ResourcePlanningItem;
