import React, { useContext, useEffect, useState } from 'react';
import { patchLocation } from '../restapi/locationService';
import { ToastS } from '../ToastS';
import { LocationS } from '../LocationS';
import { isEmpty } from '../../Utils/utils';
import { ContactContext } from './ContactContext';
import EventParticipationsContextProviderProxy from './proxy/EventParticipationContextProviderProxy';

export const EventLocationContext = React.createContext(undefined);

export const EventLocationContextProvider = ({ event }) => {
  const [location, setLocation] = useState();
  const { contact } = useContext(ContactContext);
  const { id: eventId } = event;
  const { id: contactId } = contact;

  const [loading, setLoading] = useState(false);
  const [distance, setDistance] = useState();

  useEffect(() => {
    if (location && isEmptyLocation(location) && distance) {
      setDistance(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setLoading(true);
    LocationS.fetchByEventId(eventId)
      .then((loc) => {
        if (loc) {
          const { id: locationId } = loc;
          if (isEmptyLocation(loc)) {
            setLocation(loc);
          } else {
            LocationS.calculateDistance(contactId, locationId).then((_distance) => {
              setDistance(_distance);
              setLocation({ ...loc, distance: _distance ? Math.round(_distance.distanceInMeters / 1000) : null });
            });
          }
        }
      })
      .finally(() => setLoading(false));
  }, [contactId, eventId]);

  const refreshDistance = (_location) => {
    const { id: locationId } = _location;
    setLoading(true);
    LocationS.calculateDistance(contactId, locationId)
      .then((_distance) => {
        if (_distance) {
          setDistance(_distance);
          // Update location property for placeholder values
          setLocation((prev) => ({ ...prev, distance: Math.round(_distance.distanceInMeters / 1000) }));
        }
      })
      .finally(() => setLoading(false));
  };

  // Check if distance recalculation is necessary after the patched is executed
  const dirtyDistance = (patch) => {
    if (location) {
      return (
        patch.streetAndNo !== location.streetAndNo || patch.city !== location.city || patch.zipCode !== location.zipCode
      );
    }
    return true;
  };

  // Check whenever calculation of the distance is possible
  const isEmptyLocation = (_location) => {
    const { city, zipCode, streetAndNo } = _location;
    return isEmpty(city) && isEmpty(zipCode) && isEmpty(streetAndNo);
  };

  return (
    <EventLocationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        location,
        distance,
        loading,
        handleLocationChange: (patch) => {
          if (dirtyDistance(patch)) {
            refreshDistance(patch);
          }
          setLocation((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
        saveLocation: (patch) => {
          setLocation((prevState) => ({
            ...prevState,
            ...patch,
          }));
          patchLocation(eventId, location.id, patch)
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((savedLocation) => {
                  setLocation(savedLocation);
                });
              } else {
                ToastS.error('patch.location', 'Fehler beim Speichern');
                console.error('Location could not be saved.');
              }
            })
            .catch(() => {
              ToastS.error('patch.location', 'Fehler beim Speichern');
            });
        },
      }}
    >
      <EventParticipationsContextProviderProxy />
    </EventLocationContext.Provider>
  );
};
