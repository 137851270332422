// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Button, Center, Loader, Text, Textarea } from '@mantine/core';
import { i18n } from '@lingui/core';
import { closeAllModals } from '@mantine/modals';
import { FC, useState } from 'react';
import { IconMessage, IconSend } from '@tabler/icons';
import { RecommendationS } from '../../../../Service/RecommendationS';

const RecommendationEmailModal: FC<{ recommendationId: string }> = ({ recommendationId }) => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const sendInfoEmail = () => {
    setLoading(true);
    RecommendationS.sendInfoMail(recommendationId, message).finally(() => {
      setLoading(false);
      closeAllModals();
    });
  };

  return (
    <>
      <Text mb="md"> Schreibe eine E-Mail mit weiteren Details zur Vermittlung an den Dienstleister.</Text>
      <Textarea
        icon={<IconMessage size={16} />}
        maxRows={10}
        variant="filled"
        placeholder="Nachricht an den Dienstleister"
        value={message}
        disabled={loading}
        onChange={(event) => setMessage(event.currentTarget.value)}
      />
      <Text mt="xs" size="xs" color="dimmed">
        Hinweis: Diese Nachricht wird zur Zeit noch nicht im System gespeichert - Du kannst Sie also im Nachhinein nicht
        noch einmal einsehen.
      </Text>
      <Center>
        <Box sx={{ width: '60%' }}>
          <Button
            fullWidth
            mt="xl"
            mb="xs"
            onClick={sendInfoEmail}
            disabled={loading}
            leftIcon={loading ? <Loader size="xs" /> : <IconSend size={16} />}
          >
            {i18n._('actions.send')}
          </Button>
          <Button disabled={loading} fullWidth color="gray" variant="subtle" onClick={() => closeAllModals()}>
            {i18n._('actions.cancel')}
          </Button>
        </Box>
      </Center>
    </>
  );
};

export default RecommendationEmailModal;
