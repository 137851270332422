// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { IconArrowRight } from '@tabler/icons';
import { FC, ReactNode } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { Profile } from '../../Service/ProfileS';

const SwitchProfile: FC<{
  currentProfile: Profile;
  profiles: Profile[];
  switchProfile: (profile: Profile) => void;
}> = ({ currentProfile, profiles, switchProfile }) => {
  const navigate: NavigateFunction = useNavigate();
  const { closeAll } = useModals();
  // eslint-disable-next-line no-underscore-dangle
  const _switchProfile = (profile: Profile) => {
    switchProfile(profile);
    closeAll();
    navigate('/');
  };

  const mapProfileUI = (profile: Profile): ReactNode => {
    const { id } = profile;
    const isCurrentProfile = id === currentProfile.id;
    return (
      <Group position="apart">
        <Text> {profile.alias} </Text>
        <ThemeIcon
          color={isCurrentProfile ? 'gray' : 'blue'}
          onClick={() => (isCurrentProfile ? () => null : _switchProfile(profile))}
          sx={{ opacity: isCurrentProfile ? 0.7 : 1, cursor: isCurrentProfile ? 'not-allowed' : 'pointer' }}
        >
          <IconArrowRight />
        </ThemeIcon>
      </Group>
    );
  };

  return <Stack mt="xl">{profiles.map(mapProfileUI)}</Stack>;
};

export default SwitchProfile;
