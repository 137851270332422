import React, { useEffect, useState, useContext } from 'react';
import { Trans, withI18n } from '@lingui/react';
import { TransButtonDefault, TransButtonPrimary } from '../../Atoms/Buttons';
import { ModalDialog, useModalState } from '../Dialogs';
import { TransPasswordInputField } from '../../Atoms/InputFields';
import { PasswordConstraintCheck } from '../../Templates/Settings/Account/PasswordConstraintCheck';
import { ApplicationUserContext } from '../../Service/Context/ApplicationUserContext';
import { ToastS } from '../../Service/ToastS';

export default withI18n()(({ open, i18n }) => {
  const { show, toggle } = useModalState();
  const { savePatch } = useContext(ApplicationUserContext);

  const [password, setPassword] = useState(undefined);
  const [passwordRepeat, setPasswordRepeat] = useState(undefined);
  const [validPassword, setValidPassword] = useState(false);

  useEffect(() => {
    if (open) {
      toggle();
    }
  }, [open]);

  function changePassword() {
    if (password !== passwordRepeat) {
      ToastS.warn('change.password', 'Die eingegebenen Passwörter stimmen nicht überein.');
    } else if (!password || password === '') {
      ToastS.warn('change.password', 'Es kann kein leeres Password gesetzt werden.');
    } else {
      savePatch({ password }).then((resp) => {
        if (resp.ok) {
          toggle();
          ToastS.success('change.password', 'Passwort erfolgreich geändert.');
        } else if (resp.status === 406) {
          ToastS.warn('change.password', 'Passwort entspricht nicht den Richtlinien.');
        }
      });
    }
  }

  const styles = {
    passwordPolicy: {
      marginBottom: 10,
    },
  };

  return (
    <div>
      <ModalDialog
        header={<Trans id="actions.change-password" />}
        body={
          <>
            <div style={styles.passwordPolicy}>
              <span>{i18n._('settings.password-change')}</span>
            </div>

            <TransPasswordInputField
              onChange={(e) => setPassword(e.target.value)}
              name="password"
              value={password}
              placeholder="applicationuser.password"
            />
            <TransPasswordInputField
              onChange={(e) => setPasswordRepeat(e.target.value)}
              name="passwordRepeat"
              value={passwordRepeat}
              placeholder="applicationuser.password.repeat"
            />

            <PasswordConstraintCheck
              password={password}
              passwordRepeat={passwordRepeat}
              setValidPassword={setValidPassword}
            />
          </>
        }
        footer={
          <div>
            <TransButtonPrimary
              id="changePassword"
              disabled={!validPassword}
              onClickHandler={changePassword}
              text="actions.change-password"
            />{' '}
            <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />
          </div>
        }
        show={show}
        toggle={toggle}
      />
    </div>
  );
});
