/* eslint-disable no-nested-ternary */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Skeleton, Text, Tooltip } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { PortalS, ResourceStatus } from '../../../Service/PortalS';
import { fetchFormSubmissionOfResource } from '../../../Service/restapi/shareService';
import { FormSubmission } from '../../../Types/ShareT';

const DocumentResourceBadge: FC<{ id: string; shortVersion?: boolean; entityType: string }> = ({
  id,
  shortVersion,
  entityType,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [formSubmission, setFormSubmission] = useState<FormSubmission>();
  const resourceStatus: ResourceStatus = !formSubmission
    ? 'PUBLISHED'
    : formSubmission && formSubmission.lastSubmitted
    ? 'CONFIRMED'
    : 'SEEN';

  useEffect(() => {
    fetchFormSubmissionOfResource(id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then(setFormSubmission);
        }
      })
      .finally(() => setLoading(false));
  }, [id]);

  if (shortVersion) {
    return (
      <div>
        {loading ? (
          <Skeleton circle width={10} height={10} />
        ) : (
          <ResourceStatusIcon status={resourceStatus} entityType={entityType} />
        )}
      </div>
    );
  }

  return (
    <Group
      spacing={0}
      sx={(theme) => ({
        textTransform: 'none',
        color: 'black',
        display: 'flex',
        backgroundColor: theme.colors.gray[1],
        borderRadius: 10,
      })}
    >
      {loading ? (
        <Skeleton mr={10} ml={10} circle width={10} height={10} />
      ) : (
        <ResourceStatusIcon status={resourceStatus} entityType={entityType} />
      )}
      <Text size={10} weight="bolder">
        Freigegeben
      </Text>
    </Group>
  );
};

const ResourceStatusIcon: FC<{ status: ResourceStatus; entityType: string }> = ({ status, entityType }) => (
  <Tooltip
    withArrow
    position="left-start"
    transition="pop"
    label={PortalS.getResourceTooltipMessage(entityType, status)}
  >
    <div
      style={{
        width: 10,
        height: 10,
        marginLeft: 10,
        marginRight: 10,
        borderRadius: '50%',
        backgroundColor: PortalS.getResouceStatusColor(status),
        boxShadow: `0px 0px 8px ${PortalS.getResouceStatusColor(status)}`,
      }}
    />
  </Tooltip>
);

export default DocumentResourceBadge;
