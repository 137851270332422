import { FC, useEffect, useState } from 'react';
import { Loader, Select } from '@mantine/core';
import { IconTemplate } from '@tabler/icons';
import { QuestionnaireS } from '../../../../../Service/QuestionnaireS';
import { WorkflowConfigProps, CreateQuestionnaireConfigDTO } from '../../../../../Types/LogT';
import { QuestionnaireTemplateDto } from '../../../../../Types/QuestionnaireT';
import { DataS } from '../../../../../Service/DataS';

const CreateQuestionnaireConfig: FC<WorkflowConfigProps> = ({ config, handleChange }) => {
  const { templateId } = config as CreateQuestionnaireConfigDTO;
  const [loadingTemplates, setLoadingTemplates] = useState(false);
  const [templates, setTemplates] = useState<QuestionnaireTemplateDto[]>([]);

  useEffect(() => {
    setLoadingTemplates(true);
    QuestionnaireS.fetchTemplates()
      .then(setTemplates)
      .finally(() => setLoadingTemplates(false));
  }, []);

  return (
    <Select
      required
      value={templateId}
      icon={<IconTemplate size={20} />}
      rightSection={loadingTemplates && <Loader size="xs" />}
      disabled={loadingTemplates}
      label="Fragebogenvorlage"
      data={DataS.getQuestionnaireTemplatesOptions(templates)}
      onChange={(id) => handleChange({ templateId: id })}
    />
  );
};

export default CreateQuestionnaireConfig;
