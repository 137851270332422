import { FC, useContext } from 'react';
import DocumentTemplatePreview from '../../Molecules/Document/DocumentTemplatePreview';
import { ContractContext } from '../../Service/Context/ContractContext';

const ContractPreview: FC = () => {
  const { contract, patch } = useContext<any>(ContractContext);
  const { id, draft } = contract;

  return (
    <DocumentTemplatePreview
      portalSupported
      url={`${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/contracts/${id}/pdf`}
      entityType="contracts"
      entity={contract}
      draft={draft}
      patchEntity={patch}
    />
  );
};

export default ContractPreview;
