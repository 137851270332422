import React, { useEffect, useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import ProgressBarItem from './ProgressBarItem';

export default function ({ values, sum }) {
  const [valuesInPercent, setValuesInPercent] = useState([]);

  useEffect(() => {
    /**
     * Beispiel-Werte: 10, 20, 40
     *
     * 1. Summe bilden (70 = 100%) oder Vorgeben (z.B. gekaufter Speicherplatz)
     * 2. 100% / 70 => 1 ^= 1,43
     * 3. Werte mal Faktor (1,43)
     */

    if (!sum) {
      // Summe bilden
      let valueSum = 0;
      values.forEach((val) => {
        valueSum += val.value;
      });
      sum = valueSum;
    }

    const factor = 100 / sum;

    // calc percent
    const newValues = Object.assign(values);
    values.map((val, index) => {
      newValues[index].procent = Math.round(val.value * factor);
    });

    setValuesInPercent(newValues);
  }, [values]);

  return (
    <ProgressBar>
      {valuesInPercent.map((item, index) => (
        <ProgressBarItem key={index} index={index} item={item} />
      ))}
    </ProgressBar>
  );
}
