import styled from 'styled-components';
import BookingState from '../Types/Enums/BookingState';

interface BoxProps {
  bookingState: BookingState;
}

const EventItemBox = styled.div<BoxProps>`
  border-bottom: 1px solid #e0e0e0;
  ${(props: BoxProps) => {
    switch (props.bookingState) {
      case 'OPEN':
        return `
          border-left: 5px solid #1D5191;
        `;
      case 'OFFERED':
        return `
          border-left: 5px solid #FDA632;
        `;
      case 'BOOKED':
        return `
          border-left: 5px solid #4EBF46;
        `;
      case 'UNAVAILABLE':
        return `
          border-left: 5px solid #e2e2e2;
        `;
      case 'CANCELED':
        return `
            border-left: 5px solid #ff6b69;
          `;
      default:
        return '';
    }
  }}
`;

export default EventItemBox;
