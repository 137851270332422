import Contact from '../../Types/Contact';
import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_AUTH_SERVICE_URL;

export const fetchContacts = () => ajaxActions.get(`${BASE_URL}/contacts`).then((resp) => resp);

export const deleteContacts = (contactIdList: string[]) =>
  ajaxActions.del(`${BASE_URL}/contacts`, contactIdList).then((resp) => resp);

export const fetchContactsById = (contactIds: string[]) =>
  ajaxActions.post(`${BASE_URL}/contacts/list`, contactIds).then((resp) => resp);

export const fetchContactById = (contactId: string) =>
  ajaxActions.getResourceAsJson(`${BASE_URL}/contacts/${contactId}`).then((contact) => contact);

export const fetchMultipleContacts = (contactIds: string[]) =>
  ajaxActions.post(`${BASE_URL}/contacts/multiple`, contactIds).then((contact) => contact);

export const getContactById = (contactId: string) =>
  ajaxActions.get(`${BASE_URL}/contacts/${contactId}`).then((resp) => resp);

export const saveContact = (contact: Partial<Contact>) =>
  ajaxActions
    .exchange('POST', `${BASE_URL}/contacts${contact.id ? `/${contact.id}` : ''}`, contact)
    .then((resp) => resp);

export const validateContact = (contact: Partial<Contact>) => {
  if (!contact) return false;
  if (contact.firstName || contact.lastName || contact.companyName || contact.email) {
    return true;
  }
  return false;
};

export const getCustomerId = (email: any) => {
  let customerId;
  let toContactId;
  if (email.to !== null) {
    toContactId = email.to[0].contactId;
  }
  if (email.sendByUser) {
    customerId = toContactId;
  } else {
    customerId = email.from.contactId;
  }
  return customerId;
};
