import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';
import { TransButtonDefault } from '../../Atoms/Buttons';
import PDFViewerProxy from '../../Atoms/PDFViewer';
import { ModalDialog } from '../Dialogs';
import DownloadSendDocument from './DownloadSendDocument';

export const FileModalDialog = ({
  show,
  toggle,
  file,
  eventId,
  allowDownload,
  allowEmailAttachment,
  allowEditing,
}) => {
  const [renderPdf, showPdf] = useState(false);
  const [url, setUrl] = useState(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    showPdf(false);
    loadFile();
  }, [file, show]);

  if (!file) {
    return null;
  }

  function loadFile() {
    setUrl(undefined);
    if (file && file.entityType !== 'videos') {
      if (
        file.entityType &&
        file.entityType !== 'documents' &&
        file.entityType !== 'contracts' &&
        file.entityType !== 'questionnaires'
      ) {
        const tmpUrl = `${process.env.REACT_APP_FILE_SERVICE_URL}/files/${file.entityType}/${
          file.entityId ? file.entityId : 'default'
        }/${file.fileName}`;
        setUrl(tmpUrl);

        let img = document.querySelector('#imgPreview');
        if (img !== null) {
          img.style.display = 'none';
        }

        const fileNameSplit = file.fileName.split('.');
        const fileNameExtension = fileNameSplit[fileNameSplit.length - 1];

        if (fileNameExtension.toUpperCase() === 'PDF') {
          showPdf(true);
          // Bild ausblenden
          let img = document.querySelector('#imgPreview');
          if (img !== null) {
            img.style.display = 'none';
          }
        } else {
          fetch(tmpUrl, {
            method: 'GET',
            credentials: 'include',
            headers: { Accept: 'image/*' },
          })
            .then((response) => response.blob())
            .then((blob) => {
              // Bild einblenden
              const imgSrc = window.URL.createObjectURL(blob);
              const img = document.querySelector('#imgPreview');
              if (img !== null) {
                img.style.display = 'block';
                img.src = imgSrc;
              }
              setUrl('');
            });
        }
      } else {
        let img = document.querySelector('#imgPreview');
        if (img !== null) {
          img.style.display = 'none';
        }

        if (eventId !== undefined) {
          file.eventId = eventId;
        }
        showPdf(true);
        if (file.entityType === 'contracts') {
          setUrl(`${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/contracts/${file.entityId}/pdf`);
        } else if (file.entityType === 'questionnaires') {
          setUrl(`${process.env.REACT_APP_QUESTIONNAIRE_SERVICE_URL}/questionnaires/${file.entityId}/pdf`);
        } else {
          setUrl(
            `${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/${file.entityId ? 'existing' : 'generate'}?${addUrlParams(
              file,
            )}`,
          );
        }
      }
    }
  }

  const styles = {
    img: {
      width: '100%',
    },
    pdf: {
      width: '100%',
    },
    actions: {
      display: 'flex',
      flex: allowEditing ? 1 : 'unset',
      justifyContent: 'space-between',
    },
  };
  const isSafari = /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);

  const config = {
    file: {
      forceHLS: false,
    },
  };

  return (
    <ModalDialog
      size='xl'
      header={file.fileName ? file.fileName : ' '}
      body={
        <div
          style={{
            maxHeight: 'calc(100vh - 210px)',
            overflowY: 'auto',
          }}
        >
          <DownloadSendDocument
            allowDownload={allowDownload}
            allowEmailAttachment={allowEmailAttachment}
            reloadFile={loadFile}
            fileName={file.fileName}
            eventId={eventId}
            entityType={file.entityType}
            entityId={file.entityId}
          />
          {!renderPdf && <img style={styles.img} id="imgPreview" alt="" />}
          {renderPdf && <PDFViewerProxy style={styles.pdf} closeCallback={toggle} kind={file.kind} url={url} />}

          {file.entityType === 'videos' && <ReactPlayer width="100%" url={file.fileName} controls />}
        </div>
      }
      footer={
        <div style={styles.actions}>
          {allowEditing &&
            file.documentId &&
            eventId &&
            !window.location.pathname.includes(`/events/${eventId}/documents/${file.documentId}`) && (
              <TransButtonDefault
                onClickHandler={() => navigate(`/events/${eventId}/documents/${file.documentId}`)}
                text="actions.document.edit"
              />
            )}
          {allowEditing &&
            file.entityId &&
            file.entityType &&
            file.entityType === 'documents' &&
            eventId &&
            !window.location.pathname.includes(`/events/${eventId}/documents/${file.entityId}`) && (
              <TransButtonDefault
                onClickHandler={() => navigate(`/events/${eventId}/documents/${file.entityId}`)}
                text="actions.document.edit"
              />
            )}
          <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />
        </div>
      }
      show={show}
      toggle={toggle}
    />
  );
};

export function addUrlParams(document) {
  let urlPart = '';
  if (document.templateName) {
    urlPart += `templatename=${document.templateName}&`;
  }
  if (document.documentNumber && document.documentNumber !== '') {
    urlPart += `documentnumber=${document.documentNumber}&`;
  }
  if (document.documentType) {
    urlPart += `documenttype=${document.documentType}&`;
  }
  if (document.documentId) {
    urlPart += `documentid=${document.documentId}&`;
  }
  if (document.entityId) {
    urlPart += `documentid=${document.entityId}&`;
  }
  if (document.eventId) {
    urlPart += `eventid=${document.eventId}&`;
  }
  if (document.fileName) {
    urlPart += `filename=${document.fileName}&`;
  }
  return urlPart;
}
