import { FC, useContext } from 'react';
import { MessageDTO } from '../../Types/MessageT';
import { Box, Divider, Group, Stack, Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { IconPaperclip } from '@tabler/icons';
import Moment from 'react-moment';
import { MessageS } from '../../Service/MessageS';
import { EmailBoxContext } from '../../Service/Context/EmailBoxContext';
import MessagePlaceholderText from '../../Atoms/MessagePlaceholderText';
import Dot from '../../Atoms/Dot';
import { useParams } from 'react-router-dom';

interface Props {
  email: MessageDTO;
}

const EmailRowPhone: FC<Props> = ({ email }) => {
  const { id: eventId } = useParams();
  const { message, attachments, readTimestamp, localDateTime, sendByUser } = email;
  const { ref, width } = useElementSize();
  const { selectMessage } = useContext(EmailBoxContext);
  const unread = localDateTime && !readTimestamp && !sendByUser;

  const contact = MessageS.getCustomerContact(email);

  return (
    <>
      <Box
        ref={ref}
        p="xs"
        onClick={() => selectMessage(email)}
        sx={{
          borderRadius: 10,
          height: 100,
          cursor: 'pointer',
          overflow: 'hidden',
        }}
      >
        <Box pos="relative">
          <Group spacing="xs">
            {unread && <Dot withoutShadow size={8} colour="#1D5191" />}
            <Text className="overflow-ellipsis" size="md" weight="bolder" sx={{ width: '75%' }}>
              {contact.personDescription ? contact.personDescription : contact.email}
            </Text>
          </Group>
          <Box pos="absolute" right={0} top={0}>
            {!email.scheduledTime && (
              <Text size="xs" align="right">
                {email.localDateTime && <Moment calendar={MessageS.calendarStrings}>{email.localDateTime}</Moment>}
                {!email.localDateTime && <Moment calendar={MessageS.calendarStrings}>{email.lastModified}</Moment>}
              </Text>
            )}
            {email.scheduledTime && (
              <Text size="xs" align="right">
                <Moment fromNow calendar={MessageS.calendarStrings}>
                  {email.scheduledTime}
                </Moment>
              </Text>
            )}
          </Box>
        </Box>

        <Box pos="relative" mt={5}>
          {email.subject && (
            <Text className="overflow-ellipsis" sx={{ width: '90%' }}>
              <MessagePlaceholderText settings={eventId === undefined} email={email} value={email.subject} />
            </Text>
          )}
          {!email.subject && (
            <Text italic color="dimmed" weight="bolder">
              Kein Betreff
            </Text>
          )}
          {attachments.length > 0 && (
            <Box pos="absolute" sx={{ top: 0, right: 5 }}>
              <IconPaperclip size={16} />
            </Box>
          )}
        </Box>

        <Text color="dimmed" pb={10} mt={8} sx={{ width: width * 0.9 }}>
          <MessagePlaceholderText email={email} value={message} />
        </Text>
      </Box>
      <Divider />
    </>
  );
};

export default EmailRowPhone;
