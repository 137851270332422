import { Checkbox, Group, Text } from '@mantine/core';
import { patchPosition } from '../../../Service/restapi/positionService';

const PositionOptionalCheckbox = ({ documentType, position, index, changePosition }) => {
  const { id, optional, autoSelect } = position;

  const handleChange = (patch) => {
    patchPosition(id, patch).then((resp) => {
      if (resp.ok) {
        resp.json().then((resJSON) => {
          changePosition(index, resJSON);
        });
      }
    });
  };

  return (
    <Group>
      {documentType === 'OFFER' && (
        <Checkbox
          size="xs"
          label={
            <Text color="dimmed" size="xs">
              optional
            </Text>
          }
          checked={optional}
          onChange={() => handleChange({ optional: !optional })}
        />
      )}
      {optional && (
        <Checkbox
          size="xs"
          label={
            <Text color="dimmed" size="xs">
              Option vorausgewählt
            </Text>
          }
          checked={autoSelect}
          onChange={() => handleChange({ autoSelect: !autoSelect })}
        />
      )}
    </Group>
  );
};

export default PositionOptionalCheckbox;
