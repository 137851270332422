import { createContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import SemanticForm, { FormCustomItem, FormStyleAttributes } from '../../Types/SemanticForm';
import { SemanticFormS } from '../restapi/semanticFormService';
import { v4 as uuidv4 } from 'uuid';

interface BookitupFormContextInterface {
  bookitupForm: SemanticForm | null;
  bookitupFormStyles: FormStyleAttributes;
  loading: boolean;
  handleChange: (patch: Partial<SemanticForm>) => unknown;
  updateForm: (patch: Partial<SemanticForm>) => unknown;
  updateFormStyles: (patch: Partial<FormStyleAttributes>) => unknown;
  addCustomItem: (item: FormCustomItem) => unknown;
  builderKey: string;
}

export const BookitupFormContext = createContext<BookitupFormContextInterface>({} as BookitupFormContextInterface);

export const BookitupFormContextProvider = () => {
  const { id: formId } = useParams();
  const [loading, setLoading] = useState(false);
  const [bookitupForm, setBookitupForm] = useState<SemanticForm | null>(null);
  const [bookitupFormStyles, setBookitupFormStyles] = useState<FormStyleAttributes>({} as FormStyleAttributes);
  const [builderKey, setBuilderKey] = useState(uuidv4());

  useEffect(() => {
    if (formId) {
      setLoading(true);
      SemanticFormS.getForm(formId)
        .then((resJSON) => {
          if (resJSON) {
            const { styleAttributes } = resJSON;
            setBookitupForm(resJSON);
            setBookitupFormStyles(styleAttributes);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [formId]);

  const updateForm = async (patch: Partial<SemanticForm>) => {
    if (formId) {
      const patchedForm = await SemanticFormS.patch(formId, patch);
      if (patchedForm) {
        setBookitupForm(patchedForm);
        if (patch.formStyle) {
          const { styleAttributes } = patchedForm;
          setBookitupFormStyles(styleAttributes);
        }
      }
    }
  };

  const handleChange = (patch: Partial<SemanticForm>) => {
    setBookitupForm((prev) => {
      if (prev) {
        return { ...prev, ...patch };
      }
      return null;
    });
  };

  const updateFormStyles = async (patch: Partial<FormStyleAttributes>) => {
    if (formId) {
      const success = await SemanticFormS.patchStyles(formId, patch);
      if (success) {
        setBookitupFormStyles((prev) => ({ ...prev, ...patch }));
        handleChange({ outOfSync: true });
      }
    }
  };

  const addCustomItem = async (item: FormCustomItem) => {
    if (formId) {
      const form = await SemanticFormS.addCustomItem(formId, item);
      if (form) {
        setBookitupForm(form);
        setBuilderKey(uuidv4())
      }
    }
  };

  const value = useMemo(
    () => ({
      bookitupForm,
      bookitupFormStyles,
      loading,
      builderKey,
      handleChange,
      updateForm,
      updateFormStyles,
      addCustomItem
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [bookitupForm, loading, bookitupFormStyles, builderKey],
  );

  return (
    <BookitupFormContext.Provider value={value}>
      <Outlet />
    </BookitupFormContext.Provider>
  );
};
