import {Container} from '@mantine/core';
import { TestS } from '../../TestS';
import EventListContent from '../EventList/EventListContent';
import ProductFruitsChecklist from "./ProductFruitsChecklist";

const EventsListView = () => (
  <Container px={0} size={800} data-testid={TestS.Navigate.Events.LIST}>
    <EventListContent />
    <ProductFruitsChecklist />
  </Container>
);

export default EventsListView;
