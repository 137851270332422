// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Text } from '@mantine/core';
import PanePaper from '../../../Molecules/PanePaper';

const DjTalkRecommendation = () => (
  <PanePaper withShadow withoutBorder>
    <Group spacing="xs">
      <div style={{ marginRight: 10 }}>
        <img style={{ width: 60 }} src="/img/djtalk.jpeg" alt="djtalk" />
      </div>
      <Text style={{ width: 200, fontSize: 12 }}>
        Der{' '}
        <Text span color="#EE5A26">
          DJ TALK
        </Text>{' '}
        Podcast empfiehlt bookitup für alle Eventdienstleister.
      </Text>
    </Group>
  </PanePaper>
);

export default DjTalkRecommendation;
