import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useLingui } from '@lingui/react';
import { useCookies } from 'react-cookie';
import { translate } from '../../Service/PlaceholderTranslationService';

/**
 * HtmlPlaceholderFormatter is a formatter which displays html content and placeholders, but
 * returns a dash for empty or null values.
 */
export const HtmlPlaceholderFormatter = ({
  value,
  style,
  dataTour,
  inlinePlaceholder,
  name,
  label,
  className,
  settings,
  event,
  customer,
  eventLocation,
  _document,
  contract,
  customAttributeValues,
  disabled,
  ...rest
}) => {
  const [items, setItems] = useState([]);

  const { i18n } = useLingui();
  const [locale, setLocale, removeLocale] = useCookies(['locale']);

  useEffect(() => {
    setItems(undefined);
    process(value);
  }, [value, event, customer, eventLocation, _document, contract, customAttributeValues]);

  function process(htmlString) {
    const htmlArray = translate.toLocale(htmlString, false, false, settings, i18n, locale, () => {}, {
      event,
      customer,
      eventLocation,
      _document,
      contract,
      customAttributeValues,
    });
    setItems(htmlArray);
  }

  const { big } = rest;

  const styles = {
    input: {
      height: '100%',
      minHeight: big ? 250 : label ? 'unset' : 34,
      backgroundColor: disabled ? '#f1f1f1' : 'white',
      cursor: disabled ? 'not-allowed' : 'initial',
      ...style,
    },
  };

  return (
    <div
      name={name}
      data-tour={dataTour}
      className={label ? className || '' : `form-control ${className || ''}`}
      style={styles.input}
    >
      {items.length > 0 ? (
        items
      ) : (
        <div style={{ color: '#9ca6af', textAlign: 'left' }}>
          <Trans id={inlinePlaceholder || ''} />
        </div>
      )}
    </div>
  );
};

HtmlPlaceholderFormatter.propTypes = {
  value: PropTypes.string,
};
