import { Flex, Space, Text } from '@mantine/core';
import { FC, ReactNode } from 'react';

interface Props {
  title: string;
  description?: string;
  rightSection: ReactNode;
}

const ConfigOption: FC<Props> = ({ title, description, rightSection }) => (
  <Flex align="center" mt="md">
    <div style={{ flexGrow: 1 }}>
      <Text weight="bolder">{title}</Text>
      {description && (
        <Text size="xs" color="dimmed">
          {description}
        </Text>
      )}
    </div>
    <Space w="xl" />
    {rightSection}
  </Flex>
);

export default ConfigOption;
