import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EditableProperty } from './EditableProperty';

export const EditableMailNotificationConfigProperty = ({
  config,
  patchField,
  handleChange,
  placeholder,
  editFormatter,
  labelFormatter,
  ...rest
}) => (
  <EditableProperty
    dto={config}
    changeHandler={handleChange}
    patchField={patchField}
    placeholder={placeholder}
    targetUrl={`${process.env.REACT_APP_SCHEDULE_SERVICE_URL}/notifications/config`}
    editFormatter={editFormatter}
    labelFormatter={labelFormatter}
    {...rest}
  />
);

EditableMailNotificationConfigProperty.propTypes = {
  config: PropTypes.shape({
    profileId: PropTypes.number.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
};
