import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_LEXOFFICE_SERVICE_URL;

export const fetchLexOfficeConfig = () => ajaxActions.get(`${BASE_URL}/lexOffice/config`).then((resp) => resp);

export const fetchLexOfficeInvoice = (documentId) =>
  ajaxActions.get(`${BASE_URL}/invoices/${documentId}`).then((resp) => resp);

export const deleteLexOfficeInvoice = (documentId) =>
  ajaxActions.del(`${BASE_URL}/invoices/${documentId}`).then((resp) => resp);
