/* eslint-disable react/jsx-props-no-spreading */
import { Select, SelectProps } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons';
import { FC } from 'react';

const BookitupSelect: FC<SelectProps> = ({ ...rest }) => (
  <Select
    variant="filled"
    styles={{ rightSection: { pointerEvents: 'none' } }}
    rightSection={<IconChevronDown size={16} />}
    rightSectionWidth={25}
    {...rest}
  />
);

export default BookitupSelect;