import { useModals } from '@mantine/modals';
import React, { useState, FC, ReactNode, useMemo, useEffect } from 'react';
import { ToastS } from '../../../Service/ToastS';
import { deleteContacts } from '../../../Service/restapi/contactService';
import { deleteContracts, fetchContractsByCustomerContactId } from '../../../Service/restapi/contractService';
import { deleteDocuments, fetchDocumentsByCustomerContactId } from '../../../Service/restapi/documentService';
import {
  deleteEvents,
  fetchBriefestEventParticipationByContactId,
  removeContactFromAllEvents,
} from '../../../Service/restapi/eventService';
import { deleteEmailsOfContact, fetchEmailIds } from '../../../Service/restapi/messageService';
import { deleteRecommendations, fetchRecommendationsByContact } from '../../../Service/restapi/recommendationService';

type Props = {
  contactId: string;
  reload?: () => void;
  lastModified?: Date;
  children: ReactNode;
};

export interface ContactDeleteFlowContextInterface {
  documents?: any[];
  contracts?: any[];
  mails?: any[];
  error?: string;
  eventParticipations?: any[];
  recommendations?: any[];
  documentIds: string[];
  mainCustomerParticipations: any[];
  otherParticipations: any[];
  isRemovingParticipants: boolean;
  isDeletingEvents: boolean;
  isLoading: boolean;
  deleteMails: () => Promise<void>;
  deleteSelectedDocs: () => Promise<void>;
  removeParticipations: () => Promise<void>;
  removeRecomendations: () => Promise<void>;
  removeContracts: () => Promise<void>;
  delEvents: () => Promise<void>;
  selectAllDocuments: () => void;
  toggleDocument: (documentId: string) => void;
  canBeFinished: () => boolean;
  finish: () => void;
  deleteDocumentsValidator: () => boolean;
  deleteContractsValidator: () => boolean;
  deleteMailsValidator: () => boolean;
  deleteEventParticipantsValidator: () => boolean;
  deleteRecommendationsValidator: () => boolean;
}

export const ContactDeleteFlowContext = React.createContext<ContactDeleteFlowContextInterface>(
  {} as ContactDeleteFlowContextInterface,
);

export const ContactDeleteFlowContextProvider: FC<Props> = ({
  children,
  contactId,
  reload,
  lastModified: modified,
}) => {
  const [error, setError] = useState<string | undefined>(undefined);

  const [lastModified, setLastModified] = useState(new Date());

  // load email template
  const [documents, setDocuments] = useState<any[] | undefined>(undefined);
  const [contracts, setContracts] = useState<any[] | undefined>(undefined);
  const [mails, setMails] = useState<any[] | undefined>(undefined);
  const [eventParticipations, setEventParticipations] = useState<any[] | undefined>(undefined);
  const [recommendations, setRecommendations] = useState<any[] | undefined>(undefined);

  const [isLoading, setLoading] = useState(false);

  const { closeAll } = useModals();

  const [documentIds, setDocumentIds] = useState<string[]>([]);

  const [isRemovingParticipants, setIsRemovingParticipants] = useState(false);
  const [isDeletingEvents, setDeletingEvents] = useState(false);

  // reset state if contact changed or reload requested
  useEffect(() => {
    const fetchAll = async () => {
      const contractResp = await fetchContracts();

      setContracts(await contractResp?.json());

      const docResp = await fetchDocs();
      setDocuments(await docResp?.json());

      const mailResp = await fetchMails();
      setMails(await mailResp?.json());

      const participationResp = await fetchParticipations();
      setEventParticipations(await participationResp?.json());

      const recomendationResp = await fetchRecomendations();
      setRecommendations(await recomendationResp?.json());
    };

    const fetchParticipations = () =>
      fetchBriefestEventParticipationByContactId(contactId)
        .then((resp) => {
          if (resp.ok) {
            return resp;
          }
          console.error(`could not load eventParticipations by contactId ${contactId}`);
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not load eventParticipations by contactId ${contactId}`);
        });

    const fetchMails = () =>
      fetchEmailIds([contactId])
        .then((resp) => {
          if (resp.ok) {
            return resp;
          }
          console.error(`could not load mails of contactId ${contactId}`);
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not load mails of contactId ${contactId}`);
        });

    const fetchRecomendations = () =>
      fetchRecommendationsByContact(contactId)
        .then((resp) => {
          if (resp.ok) {
            return resp;
          }
          console.error(`could not load recomendations of contactId ${contactId}`);
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not load recomendations of contactId ${contactId}`);
        });

    const fetchDocs = () =>
      fetchDocumentsByCustomerContactId(contactId)
        .then((resp) => {
          if (resp.ok) {
            return resp;
          }
          console.error(`could not load documents by customerContactId ${contactId}`);
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not load documents by customerContactId ${contactId}`);
        });

    const fetchContracts = () =>
      fetchContractsByCustomerContactId(contactId)
        .then((resp) => {
          if (resp.ok) {
            return resp;
          }
          console.error(`could not load contracts by customerContactId ${contactId}`);
        })
        .catch((error) => {
          console.error({ error });
          console.error(`could not load contracts by customerContactId ${contactId}`);
        });
    setError(undefined);
    fetchAll();
  }, [contactId, lastModified, modified]);

  const value = useMemo(() => {
    const canBeFinished = () => {
      if (!documents || documents.length > 0) {
        return false;
      }
      if (!mails || mails.length > 0) {
        return false;
      }
      if (!eventParticipations || eventParticipations.length > 0) {
        return false;
      }
      if (!recommendations || recommendations.length > 0) {
        return false;
      }
      return true;
    };

    const deleteDocumentsValidator = () => {
      const notValid = !documents || documents.length > 0;
      if (notValid) {
        setError('Bitte lösche zunächst alle Dokumente');
      } else {
        setError(undefined);
      }
      return !notValid;
    };

    const deleteContractsValidator = () => {
      const notValid = !contracts || contracts.length > 0;
      if (notValid) {
        setError('Bitte lösche zunächst alle Verträge');
      } else {
        setError(undefined);
      }
      return !notValid;
    };

    const deleteMailsValidator = () => {
      const notValid = !mails || mails.length > 0;
      if (notValid) {
        setError('Bitte lösche zunächst alle E-Mails');
      } else {
        setError(undefined);
      }
      return !notValid;
    };

    const deleteEventParticipantsValidator = () => {
      const notValid = !eventParticipations || eventParticipations.length > 0;
      if (notValid) {
        setError('Bitte lösche zunächst alle Event-Zuordnungen');
      } else {
        setError(undefined);
      }
      return !notValid;
    };
    const deleteRecommendationsValidator = () => {
      const notValid = !recommendations || recommendations.length > 0;
      if (notValid) {
        setError('Bitte lösche zunächst alle Vermittlungen');
      } else {
        setError(undefined);
      }
      return !notValid;
    };

    const reloadModal = () => setLastModified(new Date());

    const finish = async () => {
      if (!canBeFinished()) {
        console.error(`could not delete contact ${contactId}, docs, mails or parties still found`);
        return;
      }

      setLoading(true);
      const resp = await deleteContacts([contactId]);
      try {
        if (resp.ok) {
          ToastS.success('delete.contacts', 'Kontakt erfolgreich gelöscht');
        } else {
          console.error(`could not delete contact ${contactId}`);
        }
      } catch (error) {
        console.error({ error });
        console.error(`could not delete contact ${contactId}`);
      }
      setLoading(false);
      closeAll();
      reload?.();
    };

    const deleteMails = async () => {
      setLoading(true);

      try {
        const resp = await deleteEmailsOfContact(contactId);

        if (!resp.ok) {
          console.error(`could not delete emails of contact ${contactId}`);
        }
      } catch (error) {
        console.error({ error });
        console.error(`could not delete emails of contact ${contactId}`);
      }
      setLoading(false);
      reloadModal();
    };

    const removeContracts = async () => {
      const contractIds = contracts?.map((contract) => contract.id);

      if (!contractIds || contractIds.length < 1) return;
      setLoading(true);

      try {
        const resp = await deleteContracts(contractIds);

        if (!resp.ok) {
          console.error('could not delete contracts ', { documentIds });
        }
      } catch (error) {
        console.error('could not delete contracts ', { contractIds });
        console.error({ error });
      }
      setLoading(false);
      reloadModal();
    };

    const deleteSelectedDocs = async () => {
      if (!documentIds || documentIds.length < 1) return;
      setLoading(true);
      try {
        const resp = await deleteDocuments(documentIds);

        if (resp.status === 423) {
          ToastS.warn(
            'delete.document',
            'Kontakt kann nicht gelöscht werden, da mind. eine Rechnung existiert, die fertiggestellt wurde.',
          );
        }

        if (!resp.ok) {
          console.error('could not delete documents ', { documentIds });
        }
      } catch (error) {
        console.error('could not delete documents ', { documentIds });
        console.error({ error });
      }
      setLoading(false);
      reloadModal();
    };

    const toggleDocument = (documentId: string) => {
      const tmp = Object.assign([], documentIds);
      if (documentIds.includes(documentId)) {
        setDocumentIds(documentIds.filter((docId) => docId !== documentId));
      } else {
        tmp.push(documentId);
        setDocumentIds(tmp);
      }
    };

    const selectAllDocuments = () => {
      if (documents?.length === documentIds.length) {
        setDocumentIds([]);
      } else {
        setDocumentIds(documents?.map((document) => document.id) ?? []);
      }
    };

    const mainCustomerParticipations = eventParticipations?.filter((p) => p.eventRole === 'MAIN_CUSTOMER') ?? [];
    const otherParticipations = eventParticipations?.filter((p) => p.eventRole !== 'MAIN_CUSTOMER') ?? [];

    const delEvents = async () => {
      setDeletingEvents(true);

      try {
        const resp = await deleteEvents(mainCustomerParticipations.map((p) => p.eventId));

        if (resp.ok) {
          reloadModal();
        } else {
          console.error('could not delete events');
        }
      } catch (error) {
        console.error('could not delete events');
        console.error({ error });
      }

      setDeletingEvents(false);
      reloadModal();
    };

    const removeRecomendations = async () => {
      setLoading(true);

      try {
        const resp = await deleteRecommendations(
          recommendations?.map((r) => r.id),
          false,
        );

        if (!resp.ok) {
          console.error(`could not delete recomendations of contact ${contactId}`);
        }
      } catch (error) {
        console.error({ error });
        console.error(`could not delete recomendations of contact ${contactId}`);
      }
      setLoading(false);
      reloadModal();
    };

    const removeParticipations = async () => {
      setIsRemovingParticipants(true);

      try {
        const resp = await removeContactFromAllEvents(contactId);

        if (resp.ok) {
          reloadModal();
        } else {
          console.error(`could not delete contact ${contactId}`);
        }
      } catch (error) {
        console.error({ error });
        console.error(`could not delete contact ${contactId}`);
      }
      setIsRemovingParticipants(false);
      reloadModal();
    };

    return {
      documents,
      contracts,
      mails,
      eventParticipations,
      recommendations,
      error,
      canBeFinished,
      documentIds,
      mainCustomerParticipations,
      otherParticipations,
      isLoading,
      isDeletingEvents,
      isRemovingParticipants,
      finish,
      deleteMails,
      deleteSelectedDocs,
      delEvents,
      removeParticipations,
      removeRecomendations,
      removeContracts,
      toggleDocument,
      selectAllDocuments,
      deleteDocumentsValidator,
      deleteMailsValidator,
      deleteEventParticipantsValidator,
      deleteRecommendationsValidator,
      deleteContractsValidator,
    };
  }, [
    eventParticipations,
    documents,
    contracts,
    mails,
    recommendations,
    error,
    documentIds,
    isLoading,
    isDeletingEvents,
    isRemovingParticipants,
    contactId,
    closeAll,
    reload,
  ]);

  return <ContactDeleteFlowContext.Provider value={value}>{children}</ContactDeleteFlowContext.Provider>;
};
