/* eslint-disable react/jsx-props-no-spreading */
import { ThemeIcon, ThemeIconProps } from '@mantine/core';
import { FC, ReactNode } from 'react';

interface Props extends ThemeIconProps {
  children: ReactNode;
}

const GradientIcon: FC<Props> = ({ children, ...rest }) => (
  <ThemeIcon
    style={{ cursor: 'pointer' }}
    variant="gradient"
    gradient={{ from: '#1D5191', to: '#6185B2', deg: 135 }}
    {...rest}
  >
    {children}
  </ThemeIcon>
);

export default GradientIcon;
