/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { useContext } from 'react';
import BookitupTable from '../../Atoms/BookitupTable';
import ContractItem from '../../Molecules/Event/Contracts/ContractItem';
import PanePaper from '../../Molecules/PanePaper';
import { DocumentsContext } from '../../Service/Context/DocumentsContext';
import SortDirectionIcon from '../../Atoms/SortDirectionIcon';

const ContractsList = () => {
  const { ref, width } = useElementSize();
  const { contracts, reload, filter, changeFilter } = useContext(DocumentsContext);
  const { sortBy, direction } = filter;
  const sortingByDate = sortBy === 'date';
  const sortingByNumber = sortBy === 'number';

  const toggleSort = (field: string) => {
    if (field === sortBy) {
      changeFilter({ direction: direction === 'ASC' ? 'DESC' : 'ASC' });
    } else {
      changeFilter({ sortBy: field, direction: 'DESC' });
    }
  };

  const showColumns = {
    showNumber: width > 300,
    showDatum: width > 350,
    showName: width > 500,
    showLabel: width > 500,
    narrowPortalStatus: width < 200,
    showPortal: width >= 150,
  };

  return (
    <PanePaper>
      <div ref={ref}>
        <div>
          {!contracts || contracts.length === 0 ? (
            <Text italic color="dimmed" size="xs" weight="lighter">
              Noch nichts hinzugefügt.
            </Text>
          ) : (
            <BookitupTable>
              <thead>
                <tr>
                  <th style={{ width: 50 }}>Status</th>
                  {showColumns.showNumber && (
                    <th
                      style={{ width: 100, fontWeight: sortingByNumber ? 'bolder' : 'normal', cursor: 'pointer' }}
                      onClick={() => toggleSort('number')}
                    >
                      Nummer{'  '}
                      {sortingByNumber && <SortDirectionIcon direction={direction} />}
                    </th>
                  )}
                  {showColumns.showLabel && <th style={{ width: 100 }}>Name</th>}
                  {showColumns.showName && <th>Vertragspartner:in</th>}
                  {showColumns.showDatum && (
                    <th
                      style={{ width: 100, fontWeight: sortingByDate ? 'bolder' : 'normal', cursor: 'pointer' }}
                      onClick={() => toggleSort('date')}
                    >
                      Datum{'  '}
                      {sortingByDate && <SortDirectionIcon direction={direction} />}
                    </th>
                  )}
                  {showColumns.showPortal && (
                    <th style={{ width: showColumns.narrowPortalStatus ? 50 : 120 }}>Portal</th>
                  )}
                  <th> </th>
                </tr>
              </thead>
              <tbody>
                {contracts.map((contract) => (
                  <ContractItem key={contract.id} contract={contract} reload={reload} {...showColumns} />
                ))}
              </tbody>
            </BookitupTable>
          )}
        </div>
      </div>
    </PanePaper>
  );
};

export default ContractsList;
