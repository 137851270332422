// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Affix, Box, Center, Text } from '@mantine/core';
import { IconX } from '@tabler/icons';
import { useContext, useState } from 'react';
import { AnnoucementsContext } from '../Service/Context/AnnouncementsContext';
import { Story, StoryBlokS } from '../Service/StoryBlokS';

const MaintenanceHeader = () => {
  const { stories, markAsSeen } = useContext(AnnoucementsContext);
  const [hideStory, setHideStory] = useState<boolean>(false);
  const filteredStories = stories.filter((story) => story.content.post_type === 'MAINTENANCE');

  if (hideStory || filteredStories.length === 0) {
    return null;
  }
  const maintenanceStory: Story = filteredStories[0];
  const { uuid, content } = maintenanceStory;

  if (StoryBlokS.getSeenNotifications().includes(uuid)) {
    return null;
  }

  return (
    <Box p="sm" sx={(theme) => ({ position: 'relative', backgroundColor: theme.colors.red[4] })}>
      <Text align="center" color="white" mr="xl">
        {content.description}
      </Text>
      <IconX
        color="white"
        size={16}
        onClick={() => {
          markAsSeen(uuid);
          setHideStory(true);
        }}
        style={{ cursor: 'pointer', position: 'absolute', right: 15, top: 15 }}
      />
    </Box>
  );
};

export default MaintenanceHeader;
