import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EditableProperty } from './EditableProperty';

export const EditableEmailTemplateProperty = ({
  email,
  messageType,
  templateName,
  changeHandler,
  preChange,
  patchField,
  placeholder,
  editFormatter,
  labelFormatter,
  name,
  ...rest
}) => (
  <EditableProperty
    dto={email}
    changeHandler={changeHandler}
    preChange={preChange}
    patchField={patchField}
    placeholder={placeholder}
    targetUrl={`${process.env.REACT_APP_MESSAGE_SERVICE_URL}/email/template/${messageType}${
      templateName ? `?name=${templateName}` : ''
    }`}
    editFormatter={editFormatter}
    labelFormatter={labelFormatter}
    name={name}
    {...rest}
  />
);

EditableEmailTemplateProperty.propTypes = {
  patchField: PropTypes.string.isRequired,
  messageType: PropTypes.string.isRequired,
  email: PropTypes.shape({}).isRequired,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  preChange: PropTypes.func,
};
