import { useParams, useSearchParams } from 'react-router-dom';
import { WistiaS } from '../WistiaS';

// eslint-disable-next-line import/prefer-default-export
export const useWistiaChannel = () => {
  const { channelName } = useParams();
  const [searchParams] = useSearchParams();
  if (channelName) {
    const channel = WistiaS.CHANNELS.find((ch) => ch.path.endsWith(channelName));
    const episodeIdParam = searchParams.get('episodeId');
    const episodeId = episodeIdParam ?? channel?.episodes[0].id;
    const currentEpisode = channel?.episodes.find((e) => e.id === episodeId);
    return { channel, currentEpisode };
  }
  return {};
};
