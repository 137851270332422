import { useContext, useEffect, useState } from 'react';
import { useModals } from '@mantine/modals';
import { Skeleton } from '@mantine/core';
import { DndProvider } from 'react-dnd-latest';
import { HTML5Backend } from 'react-dnd-html5-backend-latest';
import { fetchResources } from '../../Service/restapi/shareService';
import { TransButtonLink } from '../../Atoms/Buttons';
import { CustomModalHeader } from '../../Molecules/Dialogs';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { EventPortalContext } from '../../Service/Context/EventPortalContext';
import ResourceItem from './ResourceItem';

const SharedResourceList = ({ shareId, eventId }) => {
  const { reload } = useContext(EventPortalContext);
  const [resources, setResources] = useState(undefined);
  const { profile } = useContext(ProfileContext);

  const { openContextModal } = useModals();

  const fetch = () => {
    fetchResources(shareId).then((resp) => {
      if (resp.ok) {
        resp.json().then((r) => {
          setResources(r);
        });
      } else {
        reload();
      }
    });
  };

  useEffect(() => {
    fetch();
  }, [shareId]);

  const openShareModalFlow = () =>
    openContextModal('shareModalFlow', {
      title: <CustomModalHeader>Portal-Zugang erstellen</CustomModalHeader>,
      innerProps: {
        eventId,
        shareId,
        reload,
        profile,
      },
      centered: true,
      size: 'xl',
    });

  if (!resources) {
    return (
      <div>
        {Array.from(Array(3)).map(() => (
          <Skeleton key={Math.random()} radius="md" height={25} width="100%" mb={10} />
        ))}
      </div>
    );
  }
  if (resources.length === 0) {
    return <div>keine Dateien geteilt</div>;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      {resources.map((resource, index) => (
        <ResourceItem key={resource.toString()} index={index} resource={resource} setResources={setResources} />
      ))}

      <div style={{ display: 'flex', marginTop: 10 }}>
        <TransButtonLink onClick={openShareModalFlow} text="+ Dokument hinzufügen" title="Dokument hinzufügen" />
      </div>
    </DndProvider>
  );
};

export default SharedResourceList;
