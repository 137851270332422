/* eslint-disable react/jsx-props-no-spreading */
import { useElementSize } from '@mantine/hooks';
import { FC } from 'react';
import { Text } from '@mantine/core';
import BookitupTable from '../../../../Atoms/BookitupTable';
import { AvailabilityFilter, Resource } from '../../../../Types/ResourceT';
import ResourceAvailabilityRow from './ResourceAvailabilityRow';

interface Props extends AvailabilityFilter {
  resources: Resource[];
}

const AvailabilityTable: FC<Props> = ({ resources, ...rest }) => {
  const { ref, width } = useElementSize();

  const showColumns = {
    showDescription: width > 550,
    showCategory: width > 550,
    showType: width > 450,
    showCounter: width > 400,
  };

  return (
    <div ref={ref}>
      {resources.length === 0 && (
        <Text italic color="dimmed">
          Keine Resourcen vorhanden.
        </Text>
      )}
      {resources.length > 0 && (
        <BookitupTable>
          <thead>
            <tr ref={ref}>
              <th style={{ width: 30 }}> </th>
              <th style={{ width: 30 }}> </th>
              {showColumns.showType && <th style={{ width: 70 }}> Typ </th>}
              <th style={{ width: 250 }}>Name</th>
              {showColumns.showCategory && <th>Kategorie</th>}
              {showColumns.showDescription && <th>Bezeichnung</th>}
              <th> </th>
            </tr>
          </thead>
          <tbody>
            {resources.map((resource) => (
              <ResourceAvailabilityRow key={resource.id} resource={resource} {...showColumns} {...rest} />
            ))}
          </tbody>
        </BookitupTable>
      )}
    </div>
  );
};

export default AvailabilityTable;
