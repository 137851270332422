/* eslint-disable react/jsx-no-useless-fragment */
import React, {FC, Key, ReactNode} from 'react';
import {useDisclosure} from '@mantine/hooks';
import {Center} from '@mantine/core';
import {IconChevronDown, IconChevronDownLeft, IconChevronRight} from '@tabler/icons';
import {useNavigate} from 'react-router-dom';
import {i18n} from '@lingui/core';
import {Part, Resource, ResourceType, TableResponsiveProps} from '../../../../Types/ResourceT';
import ResourceAvailabilityDot from "../../../Settings/Documents/Positions/ResourceAvailabilityDot";

interface Props extends TableResponsiveProps {
  key: Key;
  resource: Resource;
  getSelectionColumn?: (resourceId: string) => ReactNode;
  actions?: ReactNode;
  counter?: ReactNode;
  depth?: number;
  onClick?: (resource: Resource) => void;
  eventId?: number;
  quantity?: number;
  showPlanningIcon?: boolean
}

const ResourceTableRow: FC<Props> = ({
  resource,
  quantity,
  key,
  depth = 0,
  actions,
  counter,
  getSelectionColumn,
  onClick,
  showCategory = true,
  showType = true,
  showDescription = true,
  eventId,
  showPlanningIcon = true
}) => {
  const { id, type, name, content, category, description, archive } = resource;
  const [opened, { toggle }] = useDisclosure(false);
  const navigate = useNavigate();
  const isItem = type === 'ITEM';

  const mapResourcePartUI = (part: Part) => {
    const { id: partId, resource: resourcePart, quantity: partQuantity } = part;
    return (
      <ResourceTableRow
        key={partId}
        resource={resourcePart}
        depth={depth + 1}
        actions={actions}
        getSelectionColumn={getSelectionColumn}
        showCategory={showCategory}
        showType={showType}
        showDescription={showDescription}
        eventId={eventId}
        showPlanningIcon={showPlanningIcon && type !== 'CASE'}
        quantity={quantity ? (quantity * partQuantity) : undefined}
      />
    );
  };

  // eslint-disable-next-line no-underscore-dangle
  const _onClick = () => {
    if (onClick) {
      onClick(resource);
    } else {
      navigate(`/resources/${id}/details`);
    }
  };

  return (
    <>
      <tr key={key} style={{ color: archive ? 'gray' : 'inherit' }}>
        <td style={{ paddingLeft: depth * 10 }}>
          {!isItem && content.length > 0 && (
            <>
              {opened && <IconChevronDown size={20} onClick={toggle} style={{ cursor: 'pointer' }} />}
              {!opened && <IconChevronRight size={20} onClick={toggle} style={{ cursor: 'pointer' }} />}
            </>
          )}
          {isItem && depth > 0 && <IconChevronDownLeft size={20} />}
        </td>
        {getSelectionColumn && <td>{getSelectionColumn(id)}</td>}
        {(eventId) && <td onClick={_onClick} style={{ width: 40 }}>
          {showPlanningIcon &&
              <ResourceAvailabilityDot eventId={eventId} resourceId={resource.id} amount={quantity ? quantity + "" : "1"}/>}
        </td>}
        {showType && (
          <td onClick={_onClick} style={{ minWidth: 51, cursor: 'pointer' }}>
            {i18n._(`resource.type.${type}`)}
          </td>
        )}
        <td className="overflow-ellipsis" onClick={_onClick} style={{ maxWidth: 140, cursor: 'pointer' }}>
          {name}
        </td>
        {showCategory && (
          <td className="overflow-ellipsis" onClick={_onClick} style={{ maxWidth: 80, cursor: 'pointer' }}>
            {category?.name}
          </td>
        )}
        {showDescription && (
          <td className="overflow-ellipsis" style={{ maxWidth: 150, cursor: 'pointer' }} onClick={_onClick}>
            {description}
          </td>
        )}
        <td>{counter && counter}</td>
        {actions && (
          <td style={{ position: 'relative' }}>
            <Center>
              <div style={{ position: 'absolute', right: 0 }}>{actions}</div>
            </Center>
          </td>
        )}
      </tr>
      {content.length > 0 && opened && content.map(mapResourcePartUI)}
    </>
  );
};

export default ResourceTableRow;
