// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { BookitupNotification } from '../../../../Types/LogT';

const TaskCreated: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { value } = notification;
  return (
    <Text>
      Aufgabe{' '}
      <Text span weight="bolder">
        {value}
      </Text>{' '}
      wurde erstellt.
    </Text>
  );
};

export default TaskCreated;
