import { useState, useEffect } from 'react';
import {
  fetchLatestRequestOfWebForm,
  patchCustomAttributeRequestField,
  patchFormAttributeRequestField,
  postWebForm,
} from '../../Service/restapi/webformService';
import { fetchCustomAttributeGroups, fetchCustomAttributesOfGroup } from '../../Service/restapi/customAttributeService';
import AssignField from './AssignField';

const SelectFieldList = ({ webForm }) => {
  const [mapping, setMapping] = useState(webForm.formAttributesMap);
  const [customAttributes, setCustomAttributes] = useState(webForm.customAttributeMappings);
  const [lastRequest, setLastRequest] = useState(undefined);
  const webFormField = 'webform.field.';

  function addMapping(websiteField, option) {
    if (option.attribute) {
      patchCustomAttributeRequestField(webForm.id, websiteField.keyValue, option.value)
        .then((resp) => {
          if (resp.ok) {
            console.log(`patched field ${webFormField} to custom attribute ${option.attribute.name}`);
            resp.json().then((newWebForm) => {
              setCustomAttributes(newWebForm.customAttributeMappings);
              setMapping(newWebForm.formAttributesMap);
            });
          } else {
            console.error(`could not patch field ${webFormField} to custom attribute ${option.attribute.name}`);
          }
        })
        .catch(() => {
          console.error(`could not patch field ${webFormField} to custom attribute ${option.attribute.name}`);
        });
    } else {
      patchFormAttributeRequestField(webForm.id, websiteField.keyValue, option.value)
        .then((resp) => {
          if (resp.ok) {
            console.log(`patched field ${webFormField} to form attribute ${option.value}`);
            resp.json().then((newWebForm) => {
              setCustomAttributes(newWebForm.customAttributeMappings);
              setMapping(newWebForm.formAttributesMap);
            });
          } else {
            console.error(`could not patch field ${webFormField} to form attribute ${option.value}`);
          }
        })
        .catch(() => {
          console.error(`could not patch field ${webFormField} to form attribute ${option.value}`);
        });
    }
  }

  useEffect(() => {
    fetchLatestRequestOfWebForm(webForm.id)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((request) => {
            console.log({ request });
            if (request != null && request.originalFormData) {
              setLastRequest(request.originalFormData);
            }
          });
        } else {
          console.error(`could not load last request of webForm ${webForm.id}`);
        }
      })
      .catch(() => {
        console.error(`could not load last request of webForm ${webForm.id}`);
      });
  }, [webForm.id]);

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    descriptionContainer: {
      display: 'flex',
      marginBottom: 5,
    },
    assignment: {
      flex: 1,
      fontWeight: 800,
      maxWidth: 250,
    },
    exampleValue: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      fontWeight: 800,
    },
    subject: {
      fontWeight: 800,
    },
    description: {
      marginBottom: 20,
    },
  };

  const selectList = [];
  if (lastRequest) {
    lastRequest.map((field, index) => {
      let s;
      if (index % 2 === 0) {
        s = {
          backgroundColor: '#fbfbfb',
          borderRadius: 4,
        };
      }
      const fieldMapping = mapping.find((m) => m.keyValue === field.keyValue);
      const customAttributeFieldMapping = customAttributes.find((m) => m.keyValue === field.keyValue);
      selectList.push(
        <div key={index} style={s}>
          <AssignField
            targetFieldId={
              fieldMapping
                ? fieldMapping.value
                : customAttributeFieldMapping
                ? customAttributeFieldMapping.value
                : undefined
            }
            i18nPreLabel={webFormField}
            field={field.keyValue}
            exampleValue={lastRequest[index]}
            onChange={(val) => {
              addMapping(field, val);
            }}
          />
        </div>,
      );
    });
  }

  return (
    <div style={styles.container}>
      <div style={styles.subject}>Daten-Felder zuweisen</div>
      <div style={styles.description}>
        Auf der linken Seite siehst Du Deine eingegeben Daten auf Deiner Webseite. Finde dazu das passende Feld in
        bookitup, damit die Daten automatisch bei Anfragen eingepflegt werden. Es müssen nicht alle Felder zugewiesen
        werden.
      </div>

      <div style={styles.descriptionContainer}>
        <span style={styles.exampleValue}>Daten von Deiner Webseite</span>
        <span style={styles.assignment}>bookitup Feld</span>
      </div>
      {selectList}
    </div>
  );
};

export default SelectFieldList;