import { useContext } from 'react';
import { useLingui } from '@lingui/react';
import { useNavigate } from 'react-router-dom';
import { IconArrowLeft } from '@tabler/icons';
import { Box, Group, Text } from '@mantine/core';
import DocumentTemplateSkeleton from '../../../Organismns/Settings/Documents/DocumentTemplates/DocumentTemplateSkeleton';
import { preChange } from '../../../Utils/utils';
import { DocumentContext } from '../../../Service/Context/DocumentContext';
import PanePaper from '../../PanePaper';
import { WindowContext } from '../../../Service/Context/WindowContext';
import DocumentTemplateActions from './DocumentTemplateActions';

const Template = () => {
  const navigate = useNavigate();
  const { i18n } = useLingui();
  const { document } = useContext(DocumentContext);
  const { lg } = useContext(WindowContext);

  return (
    <PanePaper>
      <Group position="right" sx={{ position: 'relative' }}>
        <Box sx={{ position: 'absolute', left: 15, top: 5 }}>
          <Group>
            <IconArrowLeft color="gray" style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
            {lg && (
              <Text size="lg" weight="bolder">
                {`Dokument-Vorlage: ${document.name}`}
              </Text>
            )}
          </Group>
        </Box>
        <DocumentTemplateActions />
      </Group>

      <DocumentTemplateSkeleton preChange={(p) => preChange(p, i18n)} />
    </PanePaper>
  );
};

export default Template;
