/* eslint-disable no-else-return */
import React from 'react';
import DocumentSelectorModal from '../../Templates/Documents/DocumentSelectorModal';
import { TransButtonDefault } from '../../Atoms/Buttons';
import TextBlockAndButtons from './TextBlockAndButtons';

const TextBlockContainer = ({
  textBlocks,
  contract,
  handleChange,
  addTextBlock,
  event,
  customer,
  eventLocation,
  _document,
  customAttributeValues,
  settings,
}) => {
  const showingAddPositions = (textBlockId) => {
    const visibleForTextBlock = () =>
      // Non-empty array
      contract.aboveTextBlocks !== undefined &&
      contract.aboveTextBlocks.length > 0 &&
      // Is not the text block directly above the position/doc
      textBlockId !== contract.aboveTextBlocks[contract.aboveTextBlocks.length - 1].id;

    if (settings) {
      // For template check if positions is undefined
      return !contract.positions || visibleForTextBlock();
    } else {
      // Or for contracts check if documentId is unset
      return !contract.documentId || visibleForTextBlock();
    }
  };

  return (
    <>
      {textBlocks.map((textBlock, index) => {
        const lastTextBlock = textBlocks[textBlocks.length - 1];

        return (
          <>
            <TextBlockAndButtons
              key={index}
              index={index}
              contract={contract}
              handleChange={handleChange}
              textBlock={textBlock}
              event={event}
              customer={customer}
              eventLocation={eventLocation}
              _document={_document}
              customAttributeValues={customAttributeValues}
              settings={settings}
            />
            <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
              {showingAddPositions(textBlock.id) && (
                <DocumentSelectorModal
                  eventId={event ? event.id : undefined}
                  handleChange={handleChange}
                  contractId={contract.id}
                  contractTemplateName={contract.name}
                  textBlockId={textBlock.id}
                />
              )}
              <TransButtonDefault
                style={{ margin: 5 }}
                text="textBlocks.button.add"
                id="addTextBlock"
                onClickHandler={() => {
                  if (contract.aboveTextBlocks.length > 0) {
                    addTextBlock(textBlock.id);
                  } else {
                    addTextBlock(null);
                  }
                }}
              />
            </div>
          </>
        );
      })}
      <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
        <TransButtonDefault
          style={{ margin: 5 }}
          text="textBlocks.button.add"
          id="addTextBlock"
          onClickHandler={() => {
            addTextBlock(null);
          }}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
        <TransButtonDefault
          style={{ margin: 5 }}
          text="textBlocks.button.add"
          id={'addTextBlock'}
          onClickHandler={() => {
            addTextBlock(null);
          }}
        />
      </div>
    </>
  );
};

export default TextBlockContainer;
