import { Group, SelectItemProps, Text } from '@mantine/core';
import { forwardRef } from 'react';
import { i18n } from '@lingui/core';
import ActionTypeIcon from '../ActionTypeIcon';
import { ProcedureType } from '../../Types/LogT';

const WorkflowProcedureItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ value, ...rest }: SelectItemProps, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div ref={ref} {...rest}>
      <Group noWrap>
        <ActionTypeIcon actionType={value as ProcedureType} />
        <div>
          <Text>{i18n._(`actionType.${value}`)}</Text>
          <Text size="xs" color="dimmed">
            {i18n._(`actionType.${value}.description`)}
          </Text>
        </div>
      </Group>
    </div>
  ),
);
export default WorkflowProcedureItem;
