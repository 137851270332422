import { useContext } from 'react';
import { useLingui } from '@lingui/react';
import { Badge, Box, Space, Menu, Text } from '@mantine/core';
import { IconBallpen } from '@tabler/icons';
import { EventContext } from '../../Service/Context/EventContext';
import { EventParticipationsContext } from '../../Service/Context/EventParticipationContext';
import { ToastS } from '../../Service/ToastS';
import ContactListItemDescription from './ContactListItemDescription';

const ContactListItem = ({
  participation,
  lastModified,
  setDeleteContact,
  setEditContact,
  index,
  setEditingContact,
  editingContact,
}) => {
  const { i18n } = useLingui();
  const { event } = useContext(EventContext);
  const { participations, saveParticipant } = useContext(EventParticipationsContext);
  const { contactId, eventRole } = participation;

  function persistEventRole(selectedEventRole) {
    if (participations.length === 1) {
      ToastS.warn('persist.event.role', 'Es muss einen Kontakt als Hauptkunden geben.');
    } else {
      const eventParticipantKey = {
        contactId,
        eventRole: selectedEventRole,
      };
      saveParticipant(eventParticipantKey);
    }
  }

  const onRemove = () => {
    setDeleteContact({
      contactId,
      eventId: event.id,
      eventRole,
      index,
    });
  };

  const onEdit = () => {
    setEditContact({ index, contactId, eventRole });
    setEditingContact(true);
  };

  return (
    <Box p="md" sx={{ borderRadius: 10, backgroundColor: '#F1F1F1', position: 'relative' }}>
      <Menu withArrow shadow="md" width={200} position="bottom-end" transition="scale-y">
        <Menu.Target>
          <Box sx={{ position: 'absolute', right: 10, top: 15, cursor: 'pointer' }}>
            <IconBallpen size={18} />
          </Box>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={onEdit}>Bearbeiten</Menu.Item>
          <Menu.Item disabled={eventRole === 'MAIN_CUSTOMER'} onClick={onRemove}>
            Entfernen
          </Menu.Item>
          {(!eventRole || eventRole !== 'MAIN_CUSTOMER') && (
            <Menu.Item onClick={() => persistEventRole('MAIN_CUSTOMER')}>als Hauptkunden setzen</Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>
      {eventRole && participations.length > 1 && (
        <Badge radius="sm" sx={{ backgroundColor: '#585858', color: 'white' }}>
          <Text weight="lighter">{i18n._(`eventrole.${eventRole.toLowerCase()}`)}</Text>
        </Badge>
      )}
      <Space h="xs" />
      <ContactListItemDescription
        lastModified={lastModified}
        contactId={contactId}
        editingContact={editingContact}
        onEdit={() => {
          setEditContact({
            index,
            contactId,
            eventRole,
          });
          setEditingContact(true);
        }}
      />
    </Box>
  );
};

export default ContactListItem;
