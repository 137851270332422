import { Menu } from '@mantine/core';
import { IconBallpen, IconDotsVertical, IconTrashX } from '@tabler/icons';
import { FC, useContext } from 'react';
import { i18n } from '@lingui/core';
import { useModalState } from '../../Molecules/Dialogs';
import { Contact } from '../../Service/ContactS';
import ContactDeleteModalFlow from '../../Organismns/Contact/ContactDeleteModalFlowDialog';
import { ContactsContext } from '../../Service/Context/ContactsContext';
import { ContactContext } from '../../Service/Context/ContactContext';
import { openEditContactModal } from '../../Organismns/Contact/EditContactModal';

const ContactRowActions: FC<{ contact: Contact }> = ({ contact }) => {
  const { reload, updateContact } = useContext(ContactsContext);
  const { contact: owner } = useContext<any>(ContactContext);
  const { id, personDescription, email } = contact;
  const { show, toggle } = useModalState(false);
  const { id: ownerId } = owner ?? {};

  return (
    <Menu shadow="md" width={150} transition="scale-y" position="bottom-end">
      <Menu.Target>
        <div>
          <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item
          icon={<IconBallpen size={16} />}
          onClick={() => openEditContactModal(contact, reload, updateContact)}
        >
          {i18n._('actions.edit')}
        </Menu.Item>
        <Menu.Item color="red" icon={<IconTrashX size={16} />} onClick={toggle} disabled={ownerId === id}>
          {i18n._('actions.delete')}
        </Menu.Item>
      </Menu.Dropdown>
      {show && (
        <ContactDeleteModalFlow
          close={toggle}
          personDescription={personDescription || email || ''}
          contactId={id.toString()}
          reload={reload}
        />
      )}
    </Menu>
  );
};

export default ContactRowActions;
