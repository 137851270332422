import { Alert } from 'reactstrap';
import { Trans, useLingui, withI18n } from '@lingui/react';
import React, { useContext, useCallback } from 'react';
import { Icon } from '../Atoms/Icons';

import DivInputField from '../Atoms/inputs/DivInputField';
import { WindowContext } from '../Service/Context/WindowContext';

export const Pane = (props) => {
  const { i18n } = useLingui();

  const styles = {
    headContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'inherit',
    },
    iconAndTitle: {
      alignSelf: 'center',
      fontFamily: 'Nunito Sans',
    },
    headAddition: {
      alignSelf: 'center',
      display: 'flex',
      justifyContent: 'flex-end',
      flex: 1,
    },
  };

  const trans = useCallback((transId) => i18n._(transId), []);

  return (
    <FullHeadPane
      style={props.style}
      head={
        props.head ? (
          props.head
        ) : (
          <>
            {(props.headAddition || props.title) && (
              <div style={styles.headContainer}>
                <div style={styles.iconAndTitle}>
                  {props.icon !== undefined && <Icon value={props.icon} />}
                  {trans(props.title)}
                </div>
                <div style={styles.headAddition}>{props.headAddition}</div>
              </div>
            )}
          </>
        )
      }
      body={
        <div>
          {props.alert !== undefined && (
            <Alert color="danger">
              <Icon value="CAUTION" /> <Trans id={props.alert} />
            </Alert>
          )}
          {props.component}
        </div>
      }
      footer={props.footer}
    />
  );
};

export const FullHeadPane = withI18n()(({ style, body, head, footer, className }) => {
  const { windowWidth } = useContext(WindowContext);

  const styles = {
    headContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      paddingLeft: 10,
      marginTop: 0,
      marginBottom: 0,
      paddingTop: 5,
      paddingBottom: 5,
    },
    body: {
      paddingTop: windowWidth > 800 ? 10 : 4,
      paddingBottom: windowWidth > 800 ? 10 : 4,
      paddingLeft: windowWidth > 800 ? 10 : 4,
      paddingRight: windowWidth > 800 ? 10 : 4,
    },
  };

  return (
    <div className={className ?? 'pane'} style={style}>
      {head && (
        <div className="head" style={styles.headContainer}>
          {head}
        </div>
      )}
      <div className="body" style={styles.body}>
        {body}
      </div>
      {footer && (
        <div className="footer" style={styles.headContainer}>
          {footer}
        </div>
      )}
    </div>
  );
});

export const SimplePane = (props) => {
  const styles = {
    container: {
      marginTop: props.notop ? 0 : 20,
    },
    headContainer: {
      display: 'flex',
      marginBottom: 5,
      padding: 5,
      justifyContent: props.right ? 'flex-end' : 'flex-start',
    },
    title: {
      color: '#969696',
      fontVariant: 'petite-caps',
      fontFamily: 'RobotoLight',
      fontSize: 15,
      padding: '2px 10px',
      marginLeft: !props.right ? -25 : 0,
      marginRight: props.right ? -25 : 0,
      backgroundColor: 'rgba(0, 0, 0, .03)', // "antiquewhite", // ffcca5
    },
    iconAndTitle: {
      display: 'flex',
      flex: 1,
    },
    headAddition: {
      color: '#969696',
      fontVariant: 'petite-caps',
      fontFamily: 'RobotoLight',
      fontSize: 15,
      padding: '2px 10px',
      marginLeft: !props.right ? -25 : 0,
      marginRight: props.right ? -25 : 0,
      backgroundColor: 'rgba(0, 0, 0, .03)', // "antiquewhite", // ffcca5
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.headContainer}>
        <div style={styles.iconAndTitle}>
          {props.icon !== undefined && <Icon value={props.icon} />}
          <div style={styles.title}>
            <Trans id={props.title} />
          </div>
        </div>
        {props.headAddition && <div style={styles.headAddition}>{props.headAddition}</div>}
      </div>

      {props.alert !== undefined && (
        <Alert color="danger">
          <Icon value="CAUTION" /> <Trans id={props.alert} />
        </Alert>
      )}
      {props.component}
    </div>
  );
};

export const TextFieldPane = (props) => (
  <SimplePane
    title="document.headrow"
    component={<DivInputField value={props.value} name={props.name} onChange={props.onChange} />}
  />
);

export const EditorPane = (props) => (
  <DivInputField
    big
    value={props.value}
    onBlur={props.onBlur ? props.onBlur : {}}
    name={props.name}
    onChange={(value) => {
      props.changeHandler(value, props.name);
    }}
  />
);
