import { ajaxActions } from '../AjaxActions';
import { ToastS } from '../ToastS';

const BASE_URL = process.env.REACT_APP_CALDAV_SERVICE_URL;

export const createCalDAVUser = (alias, password, email) =>
  ajaxActions
    .put(`${BASE_URL}/calDAVConfigs`, {
      alias,
      password,
      email,
    })
    .then((resp) => resp);

export const getCalDAVUser = () => ajaxActions.get(`${BASE_URL}/calDAVConfigs`).then((resp) => resp);

export const createCalendarSubscription = () => ajaxActions.put(`${BASE_URL}/subscriptions`).then((resp) => resp);

export const publishEventsToCalDAV = () => ajaxActions.get(`${BASE_URL}/events/publish`).then((resp) => resp);

export const createExternalSubscription = (url) =>
  ajaxActions
    .put(`${BASE_URL}/externalSubscriptions`, {
      subscriptionUrl: url,
    })
    .then((resp) => resp);

export const getExternalSubscriptions = () => ajaxActions.get(`${BASE_URL}/externalSubscriptions`).then((resp) => resp);

export const deleteExternalSubscription = (id) =>
  ajaxActions.del(`${BASE_URL}/externalSubscriptions/${id}`).then((resp) => resp);

export const editCalendarSubscription = async (id, patch) => {
  const res = await ajaxActions.patch(`${BASE_URL}/subscriptions/${id}`, patch);
  if (res.ok) {
    return res.json();
  }
  ToastS.generalError();
  return null;
};