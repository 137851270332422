import React from 'react';
import Moment from 'react-moment';
import TooltipButton from '../../../../Atoms/buttons/TooltipButton';
import { ConfirmDialog, useConfirmModalState } from '../../../../Molecules/Dialogs';

export const ExternalSubscription = ({ subscription, onClick, index }) => {
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);

  const LIGHTGREY = 'rgb(251, 251, 251)';

  const styles = {
    container: {
      display: 'flex',
      backgroundColor: index % 2 ? LIGHTGREY : 'unset',
      padding: '1rem 0',
    },
    subscriptionUrl: {
      fontsize: '2rem',
      marginLeft: 10,
      flex: 1,
      alignSelf: 'center',
    },
    urlHead: {
      marginLeft: 10,
      alignSelf: 'center',
    },
    lastModified: {
      textAlign: 'right',
      alignSelf: 'center',
      marginLeft: 'auto',
      marginRight: '50px',
      width: 150,
    },
    trash: {
      width: 50,
      alignSelf: 'center',
    },
  };

  return (
    <section>
      {index === 0 && (
        <div style={{ ...styles.container, fontWeight: '600', backgroundColor: LIGHTGREY, height: 50 }}>
          <div style={styles.urlHead}>Kalender-URL</div>
          <div style={styles.lastModified}>Zuletzt bearbeitet</div>
          <div style={styles.trash}> </div>
        </div>
      )}
      <div style={styles.container}>
        <div style={styles.subscriptionUrl}>{subscription.subscriptionUrl}</div>
        <div style={styles.lastModified}>
          <Moment fromNow>{subscription.lastModified}</Moment>
        </div>
        <div style={styles.trash}>
          <TooltipButton
            icon="TRASH"
            id={`external-subscription-delete-${index}`}
            onClick={toggleConfirm}
            text="Externen Kalender löschen"
          />
        </div>
        <ConfirmDialog
          text="externalSubscription.delete.confirm"
          subject="externalSubscription.delete.confirm.head"
          open={showConfirm}
          toggle={toggleConfirm}
          handler={() => {
            onClick(subscription.id);
          }}
        />
      </div>
    </section>
  );
};
