/* eslint-disable react/jsx-key */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, List, Text, ThemeIcon } from '@mantine/core';
import { i18n } from '@lingui/core';
import { IconCheck } from '@tabler/icons';
import { useContext } from 'react';
import PanePaper from '../../../Molecules/PanePaper';
import { WindowContext } from '../../../Service/Context/WindowContext';

const RecruitmentCampaignExplained = () => {
  const { sm } = useContext(WindowContext);
  return (
    <PanePaper title="recruitment-campaign">
      <Text>Verdiene Geld mit bookitup und verhilf Deinen Kolleg:innen zu einer effizienteren Verwaltung.</Text>
      <Group mt={10} position="center">
        {[1, 2, 3].map((val) => (
          <PanePaper withShadow withoutBorder sx={{ maxWidth: sm ? 'none' : 250 }}>
            <Text weight="bolder">{i18n._(`recruitment-campaign.step${val}`)}</Text>
            <img width={100} height={100} src={`/img/recruitment-campaign/step${val}.svg`} alt="" />
            <Text size="xs" mt="sm">
              {i18n._(`recruitment-campaign.step${val}.description`)}
            </Text>
          </PanePaper>
        ))}
        <PanePaper title="Bedingungen">
          <List center spacing="xs" size="sm">
            {[1, 2, 3].map((val) => (
              <List.Item
                icon={
                  <ThemeIcon size={20} radius="xl">
                    <IconCheck size={16} />
                  </ThemeIcon>
                }
                sx={{ lineHeight: 1.3 }}
              >
                {i18n._(`recruitment-campaign.conditions.${val}`)}
              </List.Item>
            ))}
          </List>
        </PanePaper>
      </Group>
    </PanePaper>
  );
};

export default RecruitmentCampaignExplained;
