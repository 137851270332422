import React from 'react';
import { SimpleEditableText } from '../Atoms/inputs/SimpleEditableText';
import { StringFormatter } from '../Atoms/Formatter/StringFormatter';
import { EditableContactProperty } from '../Atoms/EditableProperties/EditableContactProperty';
import PanePaper from './PanePaper';

export const ContactForm = ({ hideEmail, contact, changeHandler }) => (
  <PanePaper title="common.settings.address">
    <div>
      <EditableContactProperty
        patchField="addressStreetAndNo"
        entity={contact}
        changeHandler={changeHandler}
        placeholder="contact.streetAndNo"
        editFormatter={SimpleEditableText}
        labelFormatter={StringFormatter}
      />
      <EditableContactProperty
        patchField="zipCode"
        entity={contact}
        changeHandler={changeHandler}
        placeholder="contact.zipCode"
        editFormatter={SimpleEditableText}
        labelFormatter={StringFormatter}
      />
      <EditableContactProperty
        patchField="city"
        entity={contact}
        changeHandler={changeHandler}
        placeholder="contact.city"
        editFormatter={SimpleEditableText}
        labelFormatter={StringFormatter}
      />
      <EditableContactProperty
        patchField="phoneNumber"
        entity={contact}
        changeHandler={changeHandler}
        placeholder="contact.phoneNumber"
        editFormatter={SimpleEditableText}
        labelFormatter={StringFormatter}
      />
      {!hideEmail && (
        <EditableContactProperty
          patchField="email"
          entity={contact}
          changeHandler={changeHandler}
          placeholder="contact.email"
          editFormatter={SimpleEditableText}
          labelFormatter={StringFormatter}
        />
      )}
    </div>
  </PanePaper>
);
