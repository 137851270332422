import React, { useContext, useState } from 'react';
import { ConfirmDialog } from '../../Dialogs';
import { Dropdown } from '../../../Atoms/Dropdowns';
import { copyQuestionnaireTemplate, deleteQuestionnaireTemplate } from '../../../Service/restapi/questionnaireService';

export const QuestionnaireTemplateListActions = ({ templateName, id, reloadDocuments }) => {
  const [modalDelete, setModalDelete] = useState(false);

  const items = [
    { text: 'Löschen', onClick: () => setModalDelete(!modalDelete) },
    { text: 'Duplizieren', onClick: () => copy(templateName) },
  ];

  function deleteHandler() {
    deleteQuestionnaireTemplate(id)
      .then((resp) => {
        if (resp.ok) {
        } else {
          console.error(`could not delete contract template ${templateName}`);
        }
        reloadDocuments();
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not delete contract template ${templateName}`);
      });
  }

  function copy(templateName) {
    copyQuestionnaireTemplate(templateName)
      .then((resp) => {
        if (resp.ok) {
          reloadDocuments();
        } else {
          console.error(`could not copy template ${templateName}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not copy template ${templateName}`);
      });
  }

  const styles = {
    dropdown: {
      padding: '0px 4px',
      borderRadius: '4px',
      textAlign: 'center',
      color: '#b5b6b7',
      fontWeight: 800,
      marginLeft: 2,
      height: 21,
      alignSelf: 'center',
    },
    action: {
      paddingLeft: 3,
      paddingRight: 3,
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Dropdown style={styles.dropdown} caret={false} icon="CARET_DOWN" left={false} options={{ items }} />

      <ConfirmDialog
        text="common.delete.questionnaire.confirm"
        subject="common.delete.questionnaire.confirm.head"
        open={modalDelete}
        toggle={() => setModalDelete(!modalDelete)}
        handler={deleteHandler}
      />
    </div>
  );
};
