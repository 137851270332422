import { Stack } from '@mantine/core';
import EventItem from './EventItem';

export default function ({ events }) {
  const eventsOfMonth = [];

  if (events) {
    events.map((event, index1) => {
      eventsOfMonth.push(<EventItem bookingEvent={event}/>);
    });
  }

  return <Stack data-testid="eventList">{eventsOfMonth}</Stack>;
}
