import React, { useContext, useEffect, useState } from 'react';
import { getCalendarShares, newCalendarShare } from '../../../Service/restapi/calendarAccountService';
import { LoadingAnimation } from '../../../Service/ResourceAdapter';
import { TransButtonLinkWithIcon } from '../../../Atoms/Buttons';
import CalendarShareItem from './CalendarShareItem';
import PanePaper from '../../../Molecules/PanePaper';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { Skeleton } from '@mantine/core';

export default function () {
  const [calendarShares, setCalendarShares] = useState();

  useEffect(() => {
    fetchCalendarShares();
  }, []);

  function fetchCalendarShares() {
    getCalendarShares().then((resp) => {
      if (resp.ok) {
        resp.json().then(setCalendarShares);
      }
    });
  }

  function createKey() {
    newCalendarShare().then((resp) => {
      fetchCalendarShares();
    });
  }

  if (!calendarShares) {
    return <Skeleton width="100%" height={100} />;
  }

  return (
    <PanePaper title="Eigene Verfügbarkeit im Team teilen" rightSection={<BookitupAddIcon onClick={createKey} />}>
      <div className="description" style={{ marginBottom: 10 }}>
        Teile Deine Verfügbarkeit mit Deinem Team / Pool, um Anfragen schnell und einfach an Dich weiterzuleiten. Es
        werden keine Details übermittelt - nur <i>verfügbar / nicht verfügbar</i>.<br />
        Schicke Deinem Kollegen den hier generierten Schlüssel, damit er ihn in seinem bookitup Konto unter{' '}
        <i>Verfügbarkeit anderer</i> hinzufügen kann.
        <br />
        <br />
        Du kannst einen Schlüssel an mehrere Kollegen verschicken, oder auch z.B. für jeden Kollegen einen eigenen
        Schlüssel generieren.
        <br />
        Denke daran, den Schlüssel hier manuell zu aktivieren.
      </div>
      {calendarShares.map((calendarShare, index) => (
        <CalendarShareItem
          key={index}
          calendarShare={calendarShare}
          index={index}
          reloadCalendarShares={fetchCalendarShares}
        />
      ))}
    </PanePaper>
  );
}
