import React from 'react';
import EventBookingStateSVG from './EventBookingStateSVG';

const EventBookingState: React.FC<{ state: string }> = ({ state }) => {
  if (!state) {
    return null;
  }
  return <EventBookingStateSVG state={state} />;
};

export default EventBookingState;
