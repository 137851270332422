import React, { useEffect, useState } from 'react';
import { Autocomplete, AutocompleteItem, InputVariant, Loader, Text } from '@mantine/core';
import { useLingui } from '@lingui/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { IconSearch } from '@tabler/icons';
import AutoCompleteEventItem from '../../Atoms/Autocomplete/AutoCompleteEventItem';
import { fetchEventById, getDescription, searchEvents } from '../../Service/restapi/eventService';
import BookingEvent from '../../Types/Event';
import { fetchContactById } from '../../Service/restapi/contactService';
import Contact from '../../Types/Contact';
import { Icon } from '../../Atoms/Icons';

const EventSearchAutocomplete: React.FC<{
  initialEventId?: string;
  selectEvent?: (bookingEvent: BookingEvent) => void;
  openOnSelect?: boolean;
  withoutTitle?: boolean;
  placeholder?: string;
  required?: boolean;
  clearable?: boolean;
  onQueryChanged?: (value: string) => void;
  withIcon?: boolean;
  inputVariant?: InputVariant;
}> = ({
  initialEventId,
  selectEvent,
  openOnSelect,
  withoutTitle,
  placeholder = '',
  required,
  clearable,
  onQueryChanged,
  withIcon,
  inputVariant,
}) => {
  const { i18n } = useLingui();
  const [loading, setLoading] = useState<boolean>(false);
  const [events, setEvents] = useState([]);
  const [timeoutId, setTimeoutId] = useState<any>();
  const [value, setValue] = useState<string>('');
  const navigate = useNavigate();
  const controller = new AbortController();

  const onSubmit = (item: AutocompleteItem) => {
    const selectedEvent = item as unknown as BookingEvent;
    if (openOnSelect) {
      navigate(`/events/${selectedEvent.id}/details`);
    } else if (selectEvent) {
      selectEvent(selectedEvent);
    }
  };

  const enrichEvents = (bookingEvents: BookingEvent[]) => {
    const enrichedEvents: any = bookingEvents.map((bookingEvent: BookingEvent) => ({
      ...bookingEvent,
      value: getDescription(bookingEvent, i18n),
    }));
    setEvents(enrichedEvents);
  };

  // Check if the actual value is selected event so we don't trigger search request again
  const isEventSelected = (): boolean => {
    if (value && events && events.length > 0) {
      const selectedEvent = events.find((bookingEvent: BookingEvent) => value === getDescription(bookingEvent, i18n));
      return selectedEvent !== undefined;
    }
    return false;
  };

  const onChange = (value: string) => {
    setValue(value);
    onQueryChanged?.(value);
  };

  useEffect(() => {
    if (initialEventId) {
      setLoading(true);
      fetchEventById(initialEventId)
        .then((resp: any) => {
          if (resp.ok) {
            resp.json().then((event: BookingEvent) => {
              fetchContactById(event.customerId.toString()).then((contact) => {
                onChange(getDescription({ ...event, customer: contact as Contact }, i18n) ?? '');
              });
            });
          } else {
            console.info(`could not load event ${initialEventId}`);
          }
        })
        .catch(() => {
          console.info(`could not load event ${initialEventId}`);
        })
        .finally(() => setLoading(false));
    }
  }, [i18n, initialEventId]);

  useEffect(() => {
    if (value !== '' && !isEventSelected()) {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setLoading(true);
      setTimeoutId(
        setTimeout(() => {
          controller.abort();
          searchEvents(value, controller.signal)
            .then((resp) => {
              if (resp.status === 200) {
                enrichEvents(resp.data);
              }
            })
            .catch((err) => {
              if (axios.isCancel(err)) {
                console.log(`Error: ${err.message}`);
              } else {
                console.error('Unable to fetch events');
              }
            })
            .finally(() => setLoading(false));
        }, 500),
      );
      // Delete all suggested events when customer clear input
    } else if (events.length !== 0) {
      setEvents([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onClear = () => {
    onQueryChanged?.('');
    setValue('');
  };

  const Clear =
    value !== '' && clearable ? <Icon value="CLEAR" onClick={onClear} style={{ color: '#e2e2e2' }} /> : undefined;

  return (
    <Autocomplete
      variant={inputVariant ?? 'default'}
      rightSection={loading ? <Loader size="sm" /> : Clear}
      placeholder={placeholder}
      description={withoutTitle ? '' : 'Event'}
      icon={withIcon ? <IconSearch size={16} /> : null}
      itemComponent={AutoCompleteEventItem}
      nothingFound={!loading && events.length === 0 && value !== '' && <Text> Keine Events </Text>}
      limit={8}
      data={events}
      value={value}
      onItemSubmit={onSubmit}
      onChange={onChange}
      zIndex={9999}
      required={required}
      radius="sm"
    />
  );
};

export default EventSearchAutocomplete;
