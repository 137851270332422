import React, { useContext, useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { TransButtonLink } from '../../Atoms/Buttons';
import { ModalDialog, useConfirmModalState, useModalState } from '../Dialogs';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { fetchPublicFileListOfProfile } from '../../Service/restapi/fileService';
import FileUpload from '../../Organismns/FileUpload';
import { getClosest } from '../../Utils/utils';
import { PlaceholderImage } from './PlaceholderImage';

export const PlaceholderImageCollection = ({ finish, style }) => {
  const { profile } = useContext(ProfileContext);
  const { show, toggle } = useModalState();
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);
  const [imageItems, setImageItems] = useState([]);

  useEffect(() => {
    fetchFiles();
  }, []);

  function fetchFiles() {
    fetchPublicFileListOfProfile(profile.id).then((resp) => {
      if (resp.ok) {
        resp.json().then((files) => {
          const fileNames = [];
          files.forEach((file) => {
            const { fileName } = file;
            if (fileName.includes('jpg') || fileName.includes('jpeg') || fileName.includes('png')) {
              fileNames.push(file.fileName);
            }
          });

          const items = [
            <TransButtonLink
              key={0}
              className="primary-link"
              style={{ fontWeight: 800, height: 60, marginBottom: 5 }}
              text="placeholder.image.upload"
              onClick={toggleConfirm}
            />,
          ];

          fileNames.map((imageName, index) => {
            items.push(
              <PlaceholderImage
                key={index + 1}
                onKeyUp={onKeyUp}
                onMouseDown={onMouseDown}
                imageName={imageName}
                onClick={() => onClickHandler(imageName)}
              />,
            );
          });
          setImageItems(items);
        });
      }
    });
  }

  function onClickHandler(fileName) {
    toggle();
    const selObj = window.getSelection();
    const selRange = selObj.getRangeAt(0);
    const newElement = window.document.createElement('img');
    newElement.title = fileName;
    newElement.src = `${process.env.REACT_APP_FILE_SERVICE_URL}/public/${profile.id}/${fileName}`;
    selRange.insertNode(newElement);
    selObj.removeAllRanges();
    const parentNode = getClosest(newElement, '.form-control');
    finish(parentNode.innerHTML);
  }

  function onKeyUp(e) {
    if (e.keyCode === 13 || e.keyCode === 32) {
      if (e) {
        e.preventDefault();
      }
    }
  }

  function onMouseDown(e) {
    if (e) {
      e.preventDefault();
    }
    return false;
  }

  const styles = {
    placeholderContainer: {
      marginLeft: 5,
      border: '1px solid rgba(0, 0, 0, .125)',
      borderRadius: 4,
      // position: "absolute",
      backgroundColor: '#ffffff',
      zIndex: 1,
      display: 'flex',
      flexDirection: 'column',
      maxHeight: 200,
      overflowY: 'scroll',
      ...style,
    },
    iconAndText: {
      paddingRight: 5,
    },
    groupName: {
      padding: 5,
      fontWeight: 800,
      color: '#96969A',
    },
    item: {
      textAlign: 'left',
      padding: 5,
      minHeight: 30,
    },
  };

  return (
    <div>
      <div tabIndex={0} className="placeholder show-placeholder-menu" style={styles.placeholderContainer}>
        {!show && (
          <TransButtonLink
            key={0}
            className="primary-link open-placeholder-menu"
            style={{ fontWeight: 800, marginTop: -1 }}
            text="placeholder.image.insertButton"
            onClick={toggle}
          />
        )}
        {show && imageItems}
      </div>

      <ModalDialog
        header={<Trans id="Datei hochladen" />}
        body={
          <FileUpload
            serverUrl={`${process.env.REACT_APP_FILE_SERVICE_URL}/files/public/${profile.id}`}
            reloadCallback={(fileName) => {
              toggle();
              toggleConfirm();
              fetchFiles();
              onClickHandler(fileName);
            }}
          />
        }
        show={showConfirm}
        toggle={toggleConfirm}
      />
    </div>
  );
};
