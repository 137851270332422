import React, { useContext, useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { createCalendarSubscription, editCalendarSubscription } from '../../../../Service/restapi/calDAVService';
import { LoadingAnimation } from '../../../../Service/ResourceAdapter';
import { Subscription } from './Subscription';
import { Checkbox } from '@mantine/core';

export const SubscriptionCalendar = () => {
  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState();

  useEffect(() => {
    getCalendar();
  }, []);

  const editCalendar = async (patch) => {
    const _subscription = await editCalendarSubscription(subscription.id, patch);
    if (_subscription) {
      setSubscription(_subscription);
    }
  };

  function getCalendar() {
    setLoading(true);
    createCalendarSubscription()
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((calendarSubscription) => {
            setSubscription(calendarSubscription);
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (loading) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <div className="description">
        <div style={{ marginBottom: 5 }}>
          <Trans id="settings.calendar.subscription.description" />
        </div>
        <div style={{ marginBottom: 5 }}>
          <Trans id="settings.calendar.subscription.manual.macos" />
        </div>
        <div style={{ marginBottom: 10 }}>
          <Trans id="settings.calendar.subscription.manual.ios" />
        </div>
      </div>

      <div className="heading" style={{ marginTop: 20 }}>
        Deine Buchungen:
      </div>
      {subscription && <Subscription calendarSubscriptionId={subscription.id} entityType="events" />}

      <div className="heading" style={{ marginTop: 20 }}>
        Deine Vermittlungen im Dienstleister-Netzwerk:
      </div>
      {subscription && (
        <>
          <Subscription calendarSubscriptionId={subscription.id} entityType="recommendations" />
          <Checkbox
            checked={subscription.showOnlySharedRecommendations}
            onChange={() =>
              editCalendar({ showOnlySharedRecommendations: !subscription.showOnlySharedRecommendations })
            }
            label="Nur Vermittlungen eintragen, deren Auftragsdaten geteilt wurden"
          />
          <Checkbox
            mt="xs"
            checked={subscription.useCustomStateLabels}
            onChange={() =>
              editCalendar({ useCustomStateLabels: !subscription.useCustomStateLabels })
            }
            label="Eigenen Buchungsstatus verwenden"
          />
        </>
      )}
    </>
  );
};
