import { i18n } from '@lingui/core';
import { Button, Group, Loader, TextInput } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import { FC, useState } from 'react';
import { RecruitmentCampaign, RecruitmentS } from '../../../Service/restapi/recruitmentService';

const CreateCampaignModal: FC<{ addCampaign: (campaign: RecruitmentCampaign) => void }> = ({ addCampaign }) => {
  const [campaignName, setCampaignName] = useState('');
  const [loading, setLoading] = useState(false);

  const createCampaign = () => {
    setLoading(true);
    RecruitmentS.createRecruitmentCampaign(campaignName)
      .then((campaign) => campaign && addCampaign(campaign))
      .finally(() => {
        closeAllModals();
        setLoading(false);
      });
  };

  return (
    <>
      <TextInput
        value={campaignName}
        onChange={(e) => setCampaignName(e.target.value)}
        label={i18n._('recruitment-campaign.name')}
      />
      <Group spacing="xs" mt="xl" position="right">
        <Button variant="default" onClick={() => closeAllModals()}>
          {i18n._('actions.cancel')}
        </Button>
        <Button
          disabled={loading || campaignName.length === 0}
          onClick={createCampaign}
          leftIcon={loading && <Loader size="xs" />}
        >
          {i18n._('actions.save')}
        </Button>
      </Group>
    </>
  );
};

export default CreateCampaignModal;
