// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Loader, Text } from '@mantine/core';
import { FC, useState } from 'react';
import { i18n } from '@lingui/core';
import { closeAllModals } from '@mantine/modals';
import BookitupDocument from '../../Types/BookitupDocument';
import { EventCustomerSelect, GeneralCustomerSelect } from '../../Atoms/inputs/CustomerSelect';

interface Props {
  patchDocument: (patch: Partial<BookitupDocument>, onSuccess?: () => void, onError?: () => void) => Promise<void>;
  initialCustomerId: string;
  eventParticipationContext?: unknown;
}

const DocumentChangeCustomerModal: FC<Props> = ({ patchDocument, initialCustomerId, eventParticipationContext }) => {
  const [loading, setLoading] = useState(false);
  const [customerId, setCustomerId] = useState(initialCustomerId);

  const onClick = async () => {
    setLoading(true);
    const onFinish = () => {
      setLoading(false);
      closeAllModals();
    };
    patchDocument({ customerId: Number(customerId) }, onFinish, onFinish);
  };

  return (
    <>
      <Text mb="md">Wähle den Kunden, der für dieses Dokument verwendet werden soll.</Text>
      {eventParticipationContext ? (
        <EventCustomerSelect
          disabled={loading}
          eventParticipationContext={eventParticipationContext}
          initialValue={initialCustomerId}
          onChange={(newCustomerId) => newCustomerId && setCustomerId(newCustomerId)}
        />
      ) : (
        <GeneralCustomerSelect
          disabled={loading}
          initialValue={initialCustomerId}
          onChange={(newCustomerId) => newCustomerId && setCustomerId(newCustomerId)}
        />
      )}
      <Button
        fullWidth
        mt="xl"
        mb="sm"
        type="submit"
        disabled={loading}
        onClick={onClick}
        leftIcon={loading && <Loader size="xs" />}
      >
        {i18n._('actions.save')}
      </Button>
      <Button fullWidth disabled={loading} variant="subtle" color="gray" onClick={() => closeAllModals()}>
        {i18n._('actions.cancel')}
      </Button>
    </>
  );
};

export default DocumentChangeCustomerModal;
