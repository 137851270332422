// eslint-disable-next-line no-redeclare
import { useContext } from 'react';
import { DocumentContext } from '../../Service/Context/DocumentContext';
import BookitupDocument from '../../Types/BookitupDocument';
import { addUrlParams } from '../Dialogs/FileModalDialog';
import DocumentTemplatePreview from './DocumentTemplatePreview';

const DocumentPreview = () => {
  const { document, savePatch, negativeSum } = useContext<any>(DocumentContext);
  const { eventId, id: documentId, draft } = document as BookitupDocument;

  return (
    <DocumentTemplatePreview
      portalSupported
      entityType="documents"
      entity={document}
      draft={draft}
      url={`${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/${documentId ? 'existing' : 'generate'}?${addUrlParams({
        eventId,
        documentId,
      })}`}
      patchEntity={savePatch}
      isPrintable={!negativeSum}
    />
  );
};

export default DocumentPreview;
