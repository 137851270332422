import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_SCHEDULE_SERVICE_URL;

export const fetchScheduledMailInfos = (scheduledMailId) =>
  ajaxActions
    .get(`${process.env.REACT_APP_MESSAGE_SERVICE_URL}/emails/schedule/${scheduledMailId}`)
    .then((resp) => resp);

export const fetchMailNotificationConfig = () =>
  ajaxActions.get(`${BASE_URL}/notifications/config`).then((resp) => resp);

export const scheduleMail = (emailId, dateTime) =>
  ajaxActions
    .post(`${BASE_URL}/schedule`, {
      emailId,
      scheduledTime: dateTime,
    })
    .then((resp) => resp);
