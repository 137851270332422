// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Stack, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { IconBallpen, IconTrashX } from '@tabler/icons';
import { EventKind } from '../../Types/EventT';
import { EventS } from '../../Service/EventS';
import PanePaper from '../PanePaper';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import SkeletonItems from '../../Atoms/SkeletonItems';
import Dot from '../../Atoms/Dot';
import { openCreateEventKindModal, openDeleteEventKindModal, openEditEventKindModal } from './EventKindModals';
import BookitupTable from '../../Atoms/BookitupTable';

const EventKindsPane = () => {
  const [loading, setLoading] = useState(true);
  const [kinds, setKinds] = useState<EventKind[]>([]);
  const kindNames = kinds.map((_kind) => _kind.name);
  const { length } = kinds;

  useEffect(() => {
    EventS.fetchEventKinds()
      .then(setKinds)
      .finally(() => setLoading(false));
  }, []);

  const appendKind = (kind: EventKind) => setKinds([...kinds, kind]);
  const updateKind = (kind: EventKind) => setKinds(kinds.map((_kind) => (_kind.id === kind.id ? kind : _kind)));
  const deleteKind = (id: string) => setKinds(kinds.filter((kind) => kind.id !== id));

  const mapKindUI = (kind: EventKind) => {
    const { id, name, color } = kind;

    return (
      <tr>
        <td>
          <div style={{ width: 25 }}>{color && <Dot withoutShadow colour={color} />}</div>
        </td>
        <td>{name}</td>
        <td>
          <Group spacing="xs" position="right">
            <IconBallpen
              size={20}
              color="gray"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                openEditEventKindModal(
                  kind,
                  updateKind,
                  kindNames.filter((_name) => _name !== name),
                )
              }
            />
            <IconTrashX
              size={20}
              color="gray"
              style={{ cursor: 'pointer' }}
              onClick={() => openDeleteEventKindModal(kind, () => deleteKind(id), kinds)}
            />
          </Group>
        </td>
      </tr>
    );
  };

  return (
    <PanePaper
      title="Auftragsarten"
      rightSection={<BookitupAddIcon onClick={() => openCreateEventKindModal(appendKind, kindNames)} />}
    >
      <Text mb="md">Hinterlege hier deine Auftragsarten wie Hochzeit, Firmenevent etc.</Text>
      {loading && <SkeletonItems />}
      {!loading && length === 0 && (
        <Text italic color="dimmed" size="xs" weight="lighter">
          Noch nichts hinzugefügt.
        </Text>
      )}
      {length > 0 && (
        <BookitupTable>
          <thead>
            <tr>
              <th style={{ width: 25 }}> </th>
              <th>Name</th>
              <th> </th>
            </tr>
          </thead>
          <tbody>{kinds.map(mapKindUI)}</tbody>
        </BookitupTable>
      )}
    </PanePaper>
  );
};

export default EventKindsPane;
