// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookitupNotification } from '../../../../Types/LogT';

const QuestionnaireFilled: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { entityLabel, entityId, eventId } = notification;
  const navigate = useNavigate();

  return (
    <Text>
      Fragebogen
      {entityLabel && (
        <Text
          span
          weight="bolder"
          variant="link"
          color="blue"
          onClick={() => navigate(`/events/${eventId}/questionnaires/${entityId}/preview`)}
          sx={{ cursor: 'pointer' }}
        >
          {` ${entityLabel} `}
        </Text>
      )}
      ausgefüllt.
    </Text>
  );
};

export default QuestionnaireFilled;
