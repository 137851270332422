import { FC, useContext, useEffect, useState } from 'react';
import { Group, MultiSelect, MultiSelectProps, Stack, Text } from '@mantine/core';
import { IconX } from '@tabler/icons';
import { MessageContext } from '../../Service/Context/MessageContextV2';
import { useParams } from 'react-router-dom';
import { Contact, ContactS } from '../../Service/ContactS';
import { IdPersonDescriptionEmailDto } from '../../Types/MessageT';
import AutoCompleteContactItem from '../../Atoms/Autocomplete/AutoCompleteContactItem';
import { DataS } from '../../Service/DataS';

const EmailReceiver = () => {
  const { id: eventId } = useParams();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const { message, patchMessage } = useContext(MessageContext);
  const [showCc, setShowCc] = useState(message && message.cc && message.cc.length > 0);
  const [showBcc, setShowBcc] = useState(message && message.bcc && message.bcc.length > 0);

  useEffect(() => {
    const loadContacts = async () => {
      let _contacts;
      if (eventId) {
        _contacts = await ContactS.getEventContacts(eventId);
      } else {
        _contacts = await ContactS.fetchAll();
      }
      setContacts(_contacts);
    };
    loadContacts();
  }, []);

  useEffect(() => {
    if (message && message.cc && message.cc.length > 0) {
      setShowCc(true);
    }
    if (message && message.bcc && message.bcc.length > 0) {
      setShowBcc(true);
    }
  }, [message]);

  if (!message) {
    return null;
  }
  const data = DataS.getRecipientOptions(contacts, message);

  const clearCC = () => {
    setShowCc(false);
    patchMessage({ cc: [] });
  };

  const clearBCC = () => {
    setShowBcc(false);
    patchMessage({ bcc: [] });
  };

  const updateRecipients = async (key: string, emails: string[]) => {
    const tmp = emails.map((email) => ({ email }));
    patchMessage({ [key]: tmp });
  };

  const onCreate = (newEmail: string) => {
    const newEntry: any = { email: newEmail, personDescription: newEmail, label: newEmail, value: newEmail };
    setContacts([...contacts, newEntry]);
    return newEntry;
  };

  return (
    <>
      <Stack spacing="xs">
        <RecipientInput
          fieldName="An"
          recipients={message.to}
          data={data as []}
          onChange={(val) => updateRecipients('to', val)}
          onCreate={onCreate}
          rightSectionWidth={50}
          rightSection={
            <Group spacing={5}>
              {!showCc && (
                <Text size="xs" color="dimmed" sx={{ cursor: 'pointer' }} onClick={() => setShowCc(true)}>
                  CC
                </Text>
              )}
              {!showBcc && (
                <Text size="xs" color="dimmed" sx={{ cursor: 'pointer' }} onClick={() => setShowBcc(true)}>
                  BCC
                </Text>
              )}
            </Group>
          }
        />
        {showCc && (
          <RecipientInput
            fieldName="Cc"
            recipients={message.cc}
            data={data as []}
            clear={clearCC}
            onChange={(val) => updateRecipients('cc', val)}
            onCreate={onCreate}
          />
        )}
        {showBcc && (
          <RecipientInput
            fieldName="Bcc"
            recipients={message.bcc}
            data={data as []}
            clear={clearBCC}
            onChange={(val) => updateRecipients('bcc', val)}
            onCreate={onCreate}
          />
        )}
      </Stack>
    </>
  );
};

interface Props extends MultiSelectProps {
  fieldName: string;
  recipients: IdPersonDescriptionEmailDto[] | null;
  clear?: () => unknown;
}

const RecipientInput: FC<Props> = ({ fieldName, recipients, clear, ...rest }) => (
  <MultiSelect
    creatable
    searchable
    itemComponent={AutoCompleteContactItem}
    variant="unstyled"
    value={recipients ? recipients.map((c) => c.email) : []}
    getCreateLabel={(query) => (
      <Text color="blue">
        + Hinzufügen
        <Text span color="black" ml={10} weight="bolder">
          {query}
        </Text>
      </Text>
    )}
    icon={
      <Text size="xs" weight="bolder">
        {fieldName}
      </Text>
    }
    rightSection={clear && <IconX size={16} style={{ cursor: 'pointer' }} onClick={clear} />}
    sx={(theme) => ({ borderBottom: `1px solid ${theme.colors.gray[4]}` })}
    filter={(value, selected, item) => {
      if (selected) {
        return false;
      }
      const { label, value: itemValue } = item;
      return (
        (label && label.toLowerCase().includes(value.toLowerCase().trim())) ||
        itemValue.toLowerCase().includes(value.toLowerCase().trim())
      );
    }}
    {...rest}
  />
);

export default EmailReceiver;
