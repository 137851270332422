import React, { FC } from 'react';
import { Box, Flex, Group, Stack } from '@mantine/core';
import { ProductPositionPreview } from '../../../../Molecules/Document/Position/PositionPreview';
import { ProductPositionPreviewActions } from '../../../../Molecules/Document/Position/PositionActions';
import { Position } from '../../../../Molecules/Document/Position/Position';
import { IconTrashX } from '@tabler/icons';
import ResourcePositionAssignment from './ResourcePositionAssignment';

type Props = {
  position: any;
  openDialog: (id: number) => void;
  index: number;
  updatePosition: (idx: number, position: any) => void;
  width: number;
};

const FavoritePosition: FC<Props> = ({ position, openDialog, index, updatePosition, width }) => {
  const previewVersion = width < 900;

  if (previewVersion) {
    return (
      <Flex sx={{ border: '1px solid #D4D4D4', padding: 10, borderRadius: 6, background: 'rgba(251, 251, 251, 0.9)' }}>
        <div style={{ flexGrow: 1 }}>
          <ProductPositionPreview position={position} favoritePosition={true} />
        </div>
        <div>
          <ProductPositionPreviewActions
            index={index}
            deletePosition={() => openDialog(position.id)}
            position={position}
            onChange={updatePosition}
          />
        </div>
      </Flex>
    );
  }
  return (
    <Group sx={{ border: '1px solid #D4D4D4', padding: 10, borderRadius: 6, background: 'rgba(251, 251, 251, 0.9)' }}>
      <Stack spacing="xs" sx={{ flexGrow: 1 }}>
        <Position
          position={position}
          isFavoritePosition
          favorites={[]}
          settings
          changePosition={updatePosition}
          index={undefined}
          _document={undefined}
          customer={undefined}
          event={undefined}
          eventLocation={undefined}
          distance={undefined}
          customAttributeValues={undefined}
          onPlaceholderClicked={undefined}
          fetchFavorites={undefined}
        />
        <Group position="left" sx={{paddingTop: 10, paddingLeft: 5}}>
          <ResourcePositionAssignment position={position} preview={false} favoritePosition={true} />
        </Group>
      </Stack>
      <Box ml="xs" mr="xs">
        <IconTrashX size={20} color="gray" onClick={() => openDialog(position.id)} style={{ cursor: 'pointer' }} />
      </Box>
    </Group>
  );
};

export default FavoritePosition;
