// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { IconCalendar } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import PanePaper from '../../../Molecules/PanePaper';
import { GoogleAccountDTO } from '../../../Types/GoogleT';
import GoogleScopeIcon from './GoogleScopeIcon';
import MissingScope from './MissingScope';

const GoogleCalendarPane: FC<{ googleAccount: GoogleAccountDTO }> = ({ googleAccount }) => {
  const navigate = useNavigate();
  const { hasCalendarScopes } = googleAccount;

  return (
    <PanePaper
      withoutBorder
      sx={{ backgroundColor: '#F3F3F3' }}
      rightSection={<GoogleScopeIcon accessGranted={hasCalendarScopes} innerIcon={<IconCalendar size={14} />} />}
    >
      <Text size="lg" mb="xl">
        Kalender
      </Text>
      {!hasCalendarScopes && <MissingScope />}
      {hasCalendarScopes && (
        <>
          <Text>Einzelne Kalender können im folgenden Abschnitt konfiguriert werden: </Text>
          <Text span variant="link" onClick={() => navigate('/settings/calendar')} sx={{ cursor: 'pointer' }}>
            Einstellungen &gt; Integrationen &gt; Kalender-Sync
          </Text>
        </>
      )}
    </PanePaper>
  );
};

export default GoogleCalendarPane;
