import { Label } from 'reactstrap';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from '@lingui/react';
import { v4 as uuidv4 } from 'uuid';
import TooltipButton from '../buttons/TooltipButton';
import { Text } from '@mantine/core';

export const SimpleLabelWrapper = ({ startEditCallback, children, placeholder, description, disabled, ...rest }) => (
  <div
    className="editable"
    onClick={(e) => {
      if (!disabled) {
        startEditCallback(e);
      }
    }}
    onFocus={(e) => {
      if (!disabled) {
        startEditCallback(e);
      }
    }}
    style={{}}
    {...rest}
  >
    {(placeholder || description) && (
      <Label sm={12}>
        <Text color="dimmed" size="xs">
          <Trans id={placeholder} />
        </Text>
        {description && <TooltipButton style={{ marginLeft: 5 }} icon="QUESTION" id={uuidv4()} text={description} />}
      </Label>
    )}
    {children}
  </div>
);

SimpleLabelWrapper.propTypes = {
  startEditCallback: PropTypes.func,
  children: PropTypes.node,
};
