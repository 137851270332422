import { Center, Divider, Pagination, Paper, Skeleton, Space, Stack } from '@mantine/core';
import { FC, useContext } from 'react';
import TaskListItem from '../../Molecules/Event/Tasks/TaskListItem';
import { TaskListContext } from '../../Service/Context/TaskListContext';
import { randomInteger } from '../../Utils/utils';

const AllTasksList: FC = () => {
  const { totalPages, taskListFilter, taskListContent, changeFilter, loading } = useContext(TaskListContext);

  const onChange = (page: number) => changeFilter({ page });

  return (
    <>
      <Paper shadow="xs" p="xl" radius="md" className="paper-wrapper-child">
        <Stack>
          <>
            {loading &&
              Array.from({ length: randomInteger(3, 5) }, (_, i) => i).map((i) => <PlaceholderItem key={i} />)}

            {!loading &&
              taskListContent.agendaItems.map((task) => (
                <>
                  <TaskListItem key={task.id} task={task} entityId={parseInt(task.entityId, 10)} />
                  <Divider my="xs" color="#f2f2f2" />
                </>
              ))}
          </>
        </Stack>
      </Paper>
      <Space h="xl" />
      <Center>
        <Pagination
          size="sm"
          total={totalPages}
          page={taskListFilter.page + 1}
          onChange={(pg) => onChange(pg - 1)}
          color="blue"
        />
      </Center>
      <Space h="xl" />
    </>
  );
};

const PlaceholderItem = () => (
  <>
    <Skeleton height={8} radius="xl" />
    <Skeleton height={8} mt={6} radius="xl" />

    <Skeleton height={8} mt={6} width="70%" radius="xl" />
  </>
);

export default AllTasksList;
