import { FC } from 'react';
import { CreateShareConfigDTO, ProcedureReportProps } from '../../../../Types/LogT';
import { Text } from '@mantine/core';

const CreateShareReport: FC<ProcedureReportProps> = ({ report, config }) => {
  const cfg = config as CreateShareConfigDTO;
  const { name } = cfg;

  return (
    <Text>
      Freigabe{' '}
      <Text span weight="bolder">
        {name}
      </Text>{' '}
      erfolgreich erstellt.
    </Text>
  );
};

export default CreateShareReport;
