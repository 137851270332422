import { useEffect } from 'react';
import { notificationCountPattern } from '../../Utils/utils';

const DEFAULT_FAVICON = '/favicon.png';
const NOTIFICATION_FAVICON = '/favicon/notification-favicon-32.png';

// eslint-disable-next-line import/prefer-default-export
export const useNotificationTab = (unseenCount: number) => {
  useEffect(() => {
    const { title } = document;
    const prevTitle = title.replace(notificationCountPattern, '');
    if (unseenCount > 0) {
      document.title = `(${unseenCount}) ${prevTitle}`;
    } else if (notificationCountPattern.test(title)) {
      document.title = prevTitle;
    }
    updateFavicon(unseenCount > 0);
  }, [unseenCount]);

  const updateFavicon = (active: boolean) => {
    const favicon: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
    if (favicon) {
      if (favicon.href.endsWith(DEFAULT_FAVICON) && active) {
        favicon.href = NOTIFICATION_FAVICON;
      } else if (favicon.href.endsWith(NOTIFICATION_FAVICON) && !active) {
        favicon.href = DEFAULT_FAVICON;
      }
    }
  };
};
