import { Space, Text } from '@mantine/core';
import { addYears } from 'date-fns';
import { useContext } from 'react';
import { useDateRangeItem } from '../../../Service/hooks/useDateRangeItem';
import BookingStateItem from '../BookingStateItem';
import { EventsContext } from '../../../Service/Context/EventsContext';
import { EventS } from '../../../Service/EventS';

const DateIntervalSelection = () => {
  const { filter, updateFilter } = useContext(EventsContext);
  const { from, to } = filter;
  const selectedRange: Date[] = [from, to];

  const {
    dateRangePickerMinimum,
    dateRangePickerMaximum,
    startOfYear,
    startOfPreviousYear,
    startOfLastMonth,
    endOfYear,
    startOfMonth,
    startOfNextMonth,
    today,
    monthEnd,
    endOfNextMonth,
    endOfPreviousYear,
    endOfTheLastMonth,
  } = EventS.getDateIntervals();

  return (
    <>
      <Space h="xl" />
      <Text pl={10}>Datumsbereich</Text>
      <Space h="xs" />
      <BookingStateItem
        label="Alle Events"
        onClick={() => updateFilter({ from: dateRangePickerMinimum, to: dateRangePickerMaximum })}
        active={useDateRangeItem(dateRangePickerMinimum, dateRangePickerMaximum, selectedRange)}
      />
      <BookingStateItem
        label="Letztes Jahr"
        onClick={() => updateFilter({ from: startOfPreviousYear, to: endOfPreviousYear })}
        active={useDateRangeItem(startOfPreviousYear, endOfPreviousYear, selectedRange)}
      />
      <BookingStateItem
        label="Dieses Jahr"
        onClick={() => updateFilter({ from: startOfYear, to: endOfYear })}
        active={useDateRangeItem(startOfYear, endOfYear, selectedRange)}
      />
      <BookingStateItem
        label="Letzter Monat"
        onClick={() => updateFilter({ from: startOfLastMonth, to: endOfTheLastMonth })}
        active={useDateRangeItem(startOfLastMonth, endOfTheLastMonth, selectedRange)}
      />
      <BookingStateItem
        label="Dieser Monat"
        onClick={() => updateFilter({ from: startOfMonth, to: monthEnd })}
        active={useDateRangeItem(startOfMonth, monthEnd, selectedRange)}
      />
      <BookingStateItem
        label="Nächste Events"
        onClick={() => updateFilter({ from: today, to: addYears(today, 5) })}
        active={useDateRangeItem(today, addYears(today, 5), selectedRange)}
      />
      <BookingStateItem
        label="Nächster Monat"
        onClick={() => updateFilter({ from: startOfNextMonth, to: endOfNextMonth })}
        active={useDateRangeItem(startOfNextMonth, endOfNextMonth, selectedRange)}
      />
    </>
  );
};

export default DateIntervalSelection;
