// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { BookitupNotification } from '../../../../Types/LogT';

const MailOpened: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { mailSubject } = notification;
  return (
    <Text>
      E-Mail{'  '}
      <Text span weight="bolder">
        {mailSubject}
      </Text>
      {'  '}
      geöffnet.
    </Text>
  );
};

export default MailOpened;
