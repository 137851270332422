import { FC, useEffect, useState } from 'react';
import { Skeleton, Text } from '@mantine/core';
import { QuestionDto } from '../../Types/QuestionnaireT';
import { CustomAttributeS } from '../../Service/Context/CustomAttributeS';
import { CustomAttributeDTO } from '../../Types/CustomAttributeT';
import { openQuestionAttributeModal } from './QuestionCustomAttributeModal';

interface Props {
  question: QuestionDto;
  handleChange: (patch: Partial<QuestionDto>) => void;
}

const QuestionCustomAttribute: FC<Props> = ({ question, handleChange }) => {
  const { targetAttributeId, attributeType } = question;
  const [loading, setLoading] = useState(false);
  const [customAttribute, setAttribute] = useState<CustomAttributeDTO | null>(null);

  useEffect(() => {
    if (targetAttributeId) {
      setLoading(true);
      CustomAttributeS.fetchAttribute(targetAttributeId)
        .then((attribute) => {
          if (attribute) {
            setAttribute(attribute);
          }
        })
        .finally(() => setLoading(false));
    } else if (customAttribute) {
      setAttribute(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetAttributeId]);

  // Not supported
  if (attributeType === 'FILE_UPLOAD') {
    return null;
  }

  if (loading) {
    return <Skeleton height={32} width={150} />;
  }

  return (
    <div>
      <Text
        variant="link"
        color="blue"
        sx={{ cursor: 'pointer' }}
        onClick={() => openQuestionAttributeModal({ currentAttribute: customAttribute, handleChange, question })}
      >
        {targetAttributeId ? `Eigenes Feld: ${customAttribute?.name}` : 'Eigenes Feld zuweisen'}
      </Text>
    </div>
  );
};

export default QuestionCustomAttribute;
