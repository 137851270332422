// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { i18n } from '@lingui/core';
import { useNavigate } from 'react-router-dom';
import { CreateDocumentConfigDTO } from '../../../../../Types/LogT';

const CreateDocument: FC<{ config: unknown }> = ({ config }) => {
  const { documentType, templateName, originDocument, partialAmount, finalInvoice } = config as CreateDocumentConfigDTO;
  const navigate = useNavigate();

  const isValid = () => {
    if (documentType === 'PARTIAL_INVOICE' && (!partialAmount || !originDocument)) {
      return false;
    }
    if (finalInvoice && !originDocument) {
      return false;
    }
    return documentType !== null && templateName !== null;
  }

  if (!isValid()) {
    return (
      <Text italic color="red">
        {i18n._('step.config.general-error')}
      </Text>
    );
  }

  if (originDocument) {
    const { label } = originDocument;
    return (
      <Text>
        Erstellen Sie{'  '}
        <Text span weight="bolder">
          {i18n._(`document.${documentType.toLowerCase()}`)}
        </Text>
        {'  '}aus dem Dokument{'  '}
        <Text span weight="bolder">
          {label}
        </Text>
        .
      </Text>
    );
  }

  if (templateName) {
    return (
      <Text>
        Erstellen Sie{'  '}
        <Text span weight="bolder">
          {i18n._(`document.${documentType.toLowerCase()}`)}
        </Text>
        {'  '}aus der Vorlage{'  '}
        <Text
          span
          weight="bolder"
          color="blue"
          variant="link"
          onClick={() => navigate(`/settings/template/document/${documentType.toLowerCase()}/${templateName}`)}
          sx={{ cursor: 'pointer' }}
        >
          {templateName}
        </Text>
        .
      </Text>
    );
  }

  return null;
};

export default CreateDocument;
