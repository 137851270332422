// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Loader, Text, Accordion, TextInput, Center, Slider } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { closeAllModals } from '@mantine/modals';
import { IconCar, IconChevronRight, IconSearch, IconX } from '@tabler/icons';
import { ContactGroup, ServiceProviderFilter } from '../../../../Types/AuthT';
import { ContactGroupS } from '../../../../Service/ContactGroupS';
import { RecommendationS } from '../../../../Service/RecommendationS';
import { RecommendationDto } from '../../../../Types/RecommendationT';
import { LocationDto } from '../../../../Types/LocationT';
import { LocationS } from '../../../../Service/LocationS';
import RecommendationGroup from '../RecommendationGroup';

interface Props {
  eventId: number;
  appendRecommendations: (recommendations: RecommendationDto[]) => void;
  eventLocation?: LocationDto;
  recommendations: RecommendationDto[];
  redGreenWeakness: boolean;
}

const AddRecommendationModal: FC<Props> = ({ appendRecommendations, eventId, eventLocation, recommendations, redGreenWeakness }) => {
  const [loading, setLoading] = useState(false);
  const [contactGroups, setContactGroups] = useState<ContactGroup[]>([]);
  const [selectedContacts, selectContacts] = useState<number[]>([]);
  const [filter, setFilter] = useState<ServiceProviderFilter>({ searchQuery: '', radius: 300 });
  const { searchQuery, radius } = filter;

  useEffect(() => {
    setLoading(true);
    ContactGroupS.fetchAll(true)
      .then(setContactGroups)
      .finally(() => setLoading(false));
  }, []);

  const toggleContact = (contactId: number) => {
    const remove = (id: number) => selectContacts(selectedContacts.filter((_id) => _id !== id));
    const append = (id: number) => selectContacts([...selectedContacts, id]);
    if (selectedContacts.includes(contactId)) {
      remove(contactId);
    } else {
      append(contactId);
    }
  };

  const toggleMultiple = (contactIds: number[], remove: boolean) => {
    if (remove) {
      selectContacts(selectedContacts.filter((id) => !contactIds.includes(id)));
    } else {
      const filteredIds = contactIds.filter((id) => !selectedContacts.includes(id));
      selectContacts([...selectedContacts, ...filteredIds]);
    }
  };

  const createRecommnedations = () => {
    if (eventId) {
      setLoading(true);
      RecommendationS.create(eventId, selectedContacts)
        .then(appendRecommendations)
        .finally(() => {
          setLoading(false);
          closeAllModals();
        });
    }
  };

  const updateFilter = (patch: Partial<ServiceProviderFilter>) =>
    setFilter((prevFilter) => ({ ...prevFilter, ...patch }));

  return (
    <>
      <Text mb="xs">Wähle alle Dienstleister aus, die Du für das Event anfragen möchtest.</Text>
      <TextInput
        variant="filled"
        placeholder="Dienstleister finden…"
        icon={<IconSearch size={16} color="black" />}
        onChange={(e) => updateFilter({ searchQuery: e.currentTarget.value })}
        value={searchQuery}
        rightSection={
          searchQuery.length > 0 && (
            <IconX size={16} onClick={() => updateFilter({ searchQuery: '' })} style={{ cursor: 'pointer' }} />
          )
        }
      />
      {eventLocation && LocationS.isFilled(eventLocation) && (
        <>
          <Text mt="md">Entfernung für Dienstleister</Text>
          <Slider
            mt="md"
            mb={40}
            thumbChildren={<IconCar size="1rem" />}
            thumbSize={26}
            max={300}
            value={radius}
            onChangeEnd={(val) => updateFilter({ radius: val })}
            label={(value) => `${value} km`}
            marks={[
              { value: 50, label: '50 km' },
              { value: 100, label: '100 km' },
              { value: 150, label: '150 km' },
              { value: 200, label: '200 km' },
              { value: 250, label: '250 km' },
              { value: 300, label: '> 300 km' },
            ]}
            step={50}
            styles={{
              thumb: { borderWidth: 2, padding: 3 },
              markLabel: { fontSize: 10, marginTop: 10, color: 'black' },
            }}
          />
        </>
      )}
      {contactGroups.length > 0 && (
        <Accordion
          mt="md"
          variant="filled"
          chevronPosition="left"
          defaultValue={contactGroups[0].name}
          chevron={<IconChevronRight size="1rem" />}
          styles={{
            chevron: {
              '&[data-rotate]': {
                transform: 'rotate(90deg)',
              },
            },
          }}
        >
          {contactGroups.map((group) => (
            <RecommendationGroup
              key={group.id}
              recommendations={recommendations}
              filter={filter}
              eventId={eventId}
              group={group}
              eventLocation={eventLocation}
              toggleContact={toggleContact}
              selectedContacts={selectedContacts}
              toggleMultiple={toggleMultiple}
              redGreenWeakness={redGreenWeakness}
            />
          ))}
        </Accordion>
      )}
      <Center>
        <Button
          mt="xl"
          mb="xs"
          onClick={createRecommnedations}
          disabled={loading || selectedContacts.length === 0}
          leftIcon={loading && <Loader size="xs" />}
        >
          Zum Event hinzufügen
        </Button>
      </Center>
      <Text size="xs" color="dimmed" align="center">
        Es werden noch keine Anfragen an Dienstleister gesendet.
      </Text>
    </>
  );
};

export default AddRecommendationModal;
