// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Skeleton, Text, ThemeIcon } from '@mantine/core';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useModals } from '@mantine/modals';
import { IconRefresh } from '@tabler/icons';
import { CustomModalHeader } from '../../Molecules/Dialogs';
import PanePaper from '../../Molecules/PanePaper';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { EventPortalContext } from '../../Service/Context/EventPortalContext';
import ShareItem from './ShareItem';

const SharePane = () => {
  const { shares, loading, reload } = useContext(EventPortalContext);
  const { id: entityId } = useParams();
  const { profile } = useContext<any>(ProfileContext);
  const { openContextModal } = useModals();

  const openShareModalFlow = () =>
    openContextModal('shareModalFlow', {
      title: <CustomModalHeader>Portal-Zugang erstellen</CustomModalHeader>,
      innerProps: {
        profile,
        eventId: entityId,
        reload,
      },
      centered: true,
      size: 'xl',
    });

  return (
    <PanePaper
      title="common.shares"
      tooltip="help.event.share.pane"
      rightSection={
        <Group spacing={10}>
          <ThemeIcon size="sm">
            <IconRefresh onClick={reload} style={{ cursor: 'pointer' }} />
          </ThemeIcon>

          <BookitupAddIcon
            onClick={openShareModalFlow}
            requiredLicence="INTERACTIVE"
            upgradeTitle="Lass den Papierkram hinter Dir"
            upgradeText={
              'Mit dem Interactive-Modul kannst Du Deinem Kunden Fragebögen erstellen und freigeben, \nVerträge mit digitaler Unterschrift bereitstellen und Dein Angebot mit Bildern und Videos zu jeder Position besser präsentieren.\n'
            }
          />
        </Group>
      }
    >
      <div>
        {loading ? (
          <div>
            {Array.from(Array(3)).map(() => (
              <Skeleton key={Math.random()} radius="md" height={25} width="100%" mb={10} />
            ))}
          </div>
        ) : (
          <>
            {shares.map((share) => (
              <ShareItem key={share.toString()} share={share} eventId={entityId} />
            ))}

            <Text italic color="dimmed" size="xs" weight="lighter">
              Hinweis: Es werden keine automatisierten E-Mails an Deinen Kunden verschickt.
              <br />
              Wenn du den Portal-Zugang erstellt hast, kannst Du den Link und das Passwort z.B. per E-Mail an Deinen
              Kunden weiterleiten.
            </Text>
          </>
        )}
      </div>
    </PanePaper>
  );
};

export default SharePane;
