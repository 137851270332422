import { IconUpload } from '@tabler/icons';
import { FC } from 'react';

const FilePickerIcon: FC<{ onSelectFile: (file: File) => unknown }> = ({ onSelectFile }) => (
  <>
    <label htmlFor="file-input" style={{ display: 'flex' }}>
      <IconUpload color="white" size={40} style={{ cursor: 'pointer' }} />
    </label>
    <input
      id="file-input"
      type="file"
      name="file"
      accept="image/png, image/jpeg"
      style={{ display: 'none' }}
      onChange={(event) => event.target.files && onSelectFile(event.target.files[0])}
    />
  </>
);

export default FilePickerIcon;
