import React from 'react';
import { TransSelectField } from '../../Atoms/InputFields';

export default function ({ style, bookingState, onBookingStateChange, all }) {
  function onChange(value) {
    if (value === 'ALL') {
      onBookingStateChange('');
    } else {
      onBookingStateChange(value);
    }
  }

  const stateItems = [
    { value: 'OPEN', label: 'event.bookingstate.open' },
    { value: 'OFFERED', label: 'event.bookingstate.offered' },
    { value: 'BOOKED', label: 'event.bookingstate.booked' },
    { value: 'UNAVAILABLE', label: 'event.bookingstate.unavailable' },
    { value: 'RECOMMENDED', label: 'event.bookingstate.recommended' },
    { value: 'CANCELED', label: 'event.bookingstate.canceled' },
  ];

  if (all) {
    stateItems.unshift({ value: 'ALL', label: 'event.bookingstate.all' });
  }

  let val;
  if (!bookingState) {
    val = { value: 'ALL', label: 'event.bookingstate.all' };
  } else {
    val = { value: bookingState, label: `event.bookingstate.${bookingState.toString().toLowerCase()}` };
  }

  return (
    <TransSelectField
      style={style}
      value={val}
      options={stateItems}
      placeholder="Buchungsstatus"
      onChangeHandler={onChange}
      name="bookingState"
    />
  );
}
