import React, { useContext, useEffect, useState } from 'react';
import { RadioBox } from '../../Atoms/inputs/RadioBox';
import { useQuery } from '../../Helpers/UrlQuery';
import { fetchMessageTemplatesByType } from '../../Service/restapi/messageService';
import { Checkbox } from '@mantine/core';

export const EmailTemplateFlow1 = ({ mailTemplate, setMailTemplate, templateName, setTemplateName }) => {
  const query = useQuery();
  const [templates, setTemplates] = useState();

  useEffect(() => {
    const messageTemplate = query.get('message_template');
    if (messageTemplate) {
      setMailTemplate(messageTemplate);
    }
    fetchMessageTemplatesByType('CUSTOM').then((resp) => {
      if (resp.ok) {
        resp.json().then(setTemplates);
      }
    });
  }, []);

  const options = [
    {
      label: 'message.type.available',
      dataTour: 'email-template-available',
      checked: mailTemplate === 'AVAILABLE',
      onClick: () => setMailTemplate('AVAILABLE'),
    },
    {
      label: 'message.type.unavailable',
      dataTour: 'email-template-unavailable',
      checked: mailTemplate === 'UNAVAILABLE',
      onClick: () => setMailTemplate('UNAVAILABLE'),
    },
    {
      label: 'message.type.offer',
      dataTour: 'email-template-offer',
      checked: mailTemplate === 'OFFER',
      onClick: () => setMailTemplate('OFFER'),
    },
    {
      label: 'message.type.follow_up',
      dataTour: 'email-template-follow-up',
      checked: mailTemplate === 'FOLLOW_UP',
      onClick: () => setMailTemplate('FOLLOW_UP'),
    },
    {
      label: 'message.type.confirm',
      dataTour: 'email-template-confirm',
      checked: mailTemplate === 'CONFIRM',
      onClick: () => setMailTemplate('CONFIRM'),
    },
    {
      label: 'message.type.send_contract',
      dataTour: 'email-template-send-contract',
      checked: mailTemplate === 'SEND_CONTRACT',
      onClick: () => setMailTemplate('SEND_CONTRACT'),
    },
    {
      label: 'message.type.prepayment_received',
      dataTour: 'email-template-prepayment_received',
      checked: mailTemplate === 'PREPAYMENT_RECEIVED',
      onClick: () => setMailTemplate('PREPAYMENT_RECEIVED'),
    },
    {
      label: 'message.type.lastcheck',
      dataTour: 'email-template-lastcheck',
      checked: mailTemplate === 'LASTCHECK',
      onClick: () => setMailTemplate('LASTCHECK'),
    },
    {
      label: 'message.type.invoice',
      dataTour: 'email-template-invoice',
      checked: mailTemplate === 'INVOICE',
      onClick: () => setMailTemplate('INVOICE'),
    },
  ];

  if (templates) {
    templates.forEach((t) => {
      options.push({
        label: t.name,
        checked: mailTemplate === 'CUSTOM' && templateName === t.name,
        onClick: () => {
          setMailTemplate('CUSTOM');
          setTemplateName(t.name);
        },
      });
    });
  }

  const styles = {
    instruction: {
      marginBottom: 10,
      color: '#9499a7',
    },
  };

  return (
    <div>
      <div style={styles.instruction}>Wähle eine der folgenden Vorlagen:</div>
      <RadioBox options={options} />
      <Checkbox label="Betreff überschreiben" />
      <Checkbox label="Empfänger überschreiben" />
    </div>
  );
};
