import { Menu } from '@mantine/core';
import { IconDotsVertical, IconTrash } from '@tabler/icons';
import { useContext, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import SaveContractTemplateName from '../../Molecules/Contract/SaveContractTemplateName';
import { ConfirmDialog, useModalState } from '../../Molecules/Dialogs';
import ConfigPDFCoverDialog from '../../Molecules/Dialogs/ConfigPDFCoverDialog';
import { ContractContext } from '../../Service/Context/ContractContext';
import { deleteContract, patchContract } from '../../Service/restapi/contractService';
import { checkSubmissionsAndDelete } from '../../Service/restapi/shareService';

const EventContractHeaderActions = () => {
  const navigate: NavigateFunction = useNavigate();
  const { contract, handleChange } = useContext<any>(ContractContext);
  const [modalDelete, setModalDelete] = useState(false);
  const [openSaveTemplateNameDialog, setOpenSaveTemplateNameDialog] = useState(false);
  const { show, toggle } = useModalState(false);

  function deleteHandler() {
    checkSubmissionsAndDelete(
      'contracts',
      contract.id,
      deleteContract,
      () => {
        if (contract.eventId) {
          navigate(`/events/${contract.eventId}/documents`);
        } else {
          navigate('/documents');
        }
      },
      () => null,
    );
  }
  function patch(patch: any) {
    patchContract(contract.id, patch)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((contract) => {
            handleChange(contract);
          });
        } else {
          console.error('could not patch contract ' + contract.id);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not patch contract ' + contract.id);
      });
  }

  return (
    <div>
      <Menu withArrow shadow="md" width={200} transition="scale-y" position="bottom-end">
        <Menu.Target>
          <div>
            <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item onClick={() => setOpenSaveTemplateNameDialog(true)}>Vorlage speichern</Menu.Item>
          <Menu.Item onClick={toggle}>PDFs beifügen</Menu.Item>
          <Menu.Item onClick={() => setModalDelete(!modalDelete)} icon={<IconTrash size={16} />}>
            Löschen
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>

      <SaveContractTemplateName
        contractId={contract.id}
        open={openSaveTemplateNameDialog}
        close={() => {
          setOpenSaveTemplateNameDialog(false);
        }}
      />

      <ConfigPDFCoverDialog show={show} toggle={toggle} patch={patch} />

      <ConfirmDialog
        text="common.delete.document.confirm"
        subject="common.delete.document.confirm.head"
        open={modalDelete}
        toggle={() => setModalDelete(!modalDelete)}
        handler={() => deleteHandler()}
        // eslint-disable-next-line react/no-children-prop
        children={[]}
        isLoading={false}
        confirmText=""
        disableConfirm={false}
      />
    </div>
  );
};

export default EventContractHeaderActions;
