/* eslint-disable react/jsx-props-no-spreading */
import { FC, useState } from 'react';
import { useForm } from '@mantine/form';
import { Box, Button, Center, Group, Loader, NumberInput, Textarea } from '@mantine/core';
import { DatePicker, TimeInput } from '@mantine/dates';
import { IconCalendar } from '@tabler/icons';
import { i18n } from '@lingui/core';
import { closeAllModals } from '@mantine/modals';
import { StockIntervalDto } from '../../../Types/ResourceT';
import ResourceS from '../../../Service/ResourceS';

interface Props {
  resourceId: string;
  onCreate: (stockInterval: StockIntervalDto) => void;
}

const CreateStockIntervalModal: FC<Props> = ({ resourceId, onCreate }) => {
  const form = useForm({
    initialValues: {
      quantityChange: 0,
      beginDate: new Date(),
      beginTime: new Date(),
      description: '',
    },
    validate: {
      quantityChange: (value) => (value !== 0 ? null : 'Menge darf nicht 0 sein'),
    },
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    const { beginDate, beginTime } = form.values;
    beginDate.setHours(beginTime.getHours());
    beginDate.setMinutes(beginTime.getMinutes());
    setLoading(true);
    ResourceS.createStockInterval({ ...form.values, resourceId, begin: beginDate })
      .then((stockInterval) => {
        if (stockInterval) {
          closeAllModals();
          onCreate(stockInterval);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Group position="center" mt="xl" mb="sm">
        <NumberInput
          label="Menge"
          defaultValue={18}
          disabled={loading}
          step={1}
          {...form.getInputProps('quantityChange')}
          sx={{ maxWidth: 100 }}
        />
        <Group spacing="xs">
          <DatePicker
            icon={<IconCalendar size={16} />}
            clearable={false}
            disabled={loading}
            label="Date"
            {...form.getInputProps('beginDate')}
            sx={{ width: 130 }}
          />
          <TimeInput label="Time" {...form.getInputProps('beginTime')} />
        </Group>
      </Group>
      <Textarea label="Beschreibung" disabled={loading} minRows={2} {...form.getInputProps('description')} />
      <Center>
        <Box sx={{ width: '70%' }}>
          <Button fullWidth mt="xl" mb="xs" type="submit" disabled={loading} leftIcon={loading && <Loader size="xs" />}>
            {i18n._('actions.save')}
          </Button>
          <Button fullWidth disabled={loading} color="gray" variant="subtle" onClick={() => closeAllModals()}>
            {i18n._('actions.cancel')}
          </Button>
        </Box>
      </Center>
    </form>
  );
};

export default CreateStockIntervalModal;
