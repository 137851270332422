import { i18n } from '@lingui/core';
import { MessageDTO } from '../Types/MessageT';
import { ajaxActions } from './AjaxActions';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_PLACEHOLDER_SERVICE_URL;

const replaceMessage = async (email: MessageDTO): Promise<MessageDTO | null> => {
  const { from, to, cc, bcc, ...rest } = email;
  const res = await ajaxActions.post(`${BASE_URL}/replace/email`, { ...rest });
  if (res.ok) {
    const resJSON = await res.json();
    const { subject, message } = resJSON;
    return { ...email, subject, message };
  }
  if (res.status === 412) {
    ToastS.cleanAll();
    ToastS.error('find.unresolved.placeholders', 'E-Mail enthält nicht ersetzbare Platzhalter');
    const unresolvedPlaceholders = await res.json();
    unresolvedPlaceholders.forEach((placeholder: string) => {
      const translation = i18n._(placeholder, { 0: 1 });
      ToastS.error(`placeholder.unsatisfied-${translation}`, i18n._(`${translation} nicht gesetzt`));
    });
  }
  return null;
};

export const PlaceholderS = {
  replaceMessage,
};
