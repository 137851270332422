import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { fetchShares } from '../restapi/shareService';
import { PortalShare } from '../../Types/ShareT';
import { ShareS } from '../ShareS';
import { EventContext } from './EventContext';

interface EventPortalContextInterface {
  shares: PortalShare[];
  loading: boolean;
  reload: () => void;
}

export const EventPortalContext = createContext<EventPortalContextInterface>({} as EventPortalContextInterface);

export const EventPortalContextProvider: FC = () => {
  const { id: eventId } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [shares, setShares] = useState<PortalShare[]>([]);
  const { handleChange, portalReloadTrigger } = useContext<any>(EventContext);

  useEffect(() => {
    if (shares.length > 0) {
      const portalLink = ShareS.getPortalLink(shares[0]);
      handleChange({ portalLink });
    } else {
      handleChange({ portalLink: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shares]);

  const loadShares = () => {
    setLoading(true);
    fetchShares('events', eventId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then(setShares);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadShares();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, portalReloadTrigger]);

  const value = useMemo(
    () => ({
      loading,
      shares,
      reload: loadShares,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, shares],
  );

  return (
    <EventPortalContext.Provider value={value}>
      <Outlet />
    </EventPortalContext.Provider>
  );
};
