import {
  IconAffiliate,
  IconCircleCheck,
  IconClockHour5,
  IconFilePlus,
  IconFileText,
  IconListCheck,
  IconPlaylistX,
  IconSend,
  IconShare,
  IconSignature,
  IconStatusChange,
  IconTrash,
  IconX,
} from '@tabler/icons';
import { FC } from 'react';
import { ProcedureType } from '../Types/LogT';

const ActionTypeIcon: FC<{ actionType: ProcedureType; size?: number }> = ({ actionType, size = 20 }) => {
  if (actionType === 'SEND_MAIL') {
    return <IconSend size={size} />;
  }
  if (actionType === 'CREATE_QUESTIONNAIRE') {
    return <IconFileText size={size} />;
  }
  if (actionType === 'CREATE_DOCUMENT') {
    return <IconFilePlus size={size} />;
  }
  if (actionType === 'SHARE_ENTITY') {
    return <IconShare size={size} />;
  }
  if (actionType === 'WAIT_UNTIL') {
    return <IconClockHour5 size={size} />;
  }
  if (actionType === 'CREATE_TASK') {
    return <IconListCheck size={size} />;
  }
  if (actionType === 'CREATE_SHARE') {
    return <IconAffiliate size={size} />;
  }
  if (actionType === 'CREATE_CONTRACT') {
    return <IconSignature size={size} />;
  }
  if (actionType === 'CHANGE_EVENT_STATUS') {
    return <IconStatusChange size={size} />;
  }
  if (actionType === 'DELETE_TASK') {
    return <IconTrash size={size} />;
  }
  if (actionType === 'DELETE_TASKS') {
    return <IconPlaylistX size={size} />;
  }
  if (actionType === 'COMPLETE_TASK') {
    return <IconCircleCheck size={size} />;
  }
  return <IconX size={size} />;
};

export default ActionTypeIcon;
