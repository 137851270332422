/* eslint-disable no-undef */
interface NotificationStatus {
  supported: boolean;
  granted: boolean;
}

/**
 * Verify whenever Notification is supported in browser.
 * Prevents blank page for iPhone/iPad devices
 */
const getNotificationStatus = (): NotificationStatus => {
  if ('Notification' in window) {
    return { supported: true, granted: Notification.permission === 'granted' };
  }
  return { supported: false, granted: false };
};

// eslint-disable-next-line import/prefer-default-export
export const BrowserS = {
  getNotificationStatus,
};
