import { TransSelectField, TransTextInputField } from '../../../Atoms/InputFields';

const RecurringTaskFlow2 = ({ days, setDays, timeState, setTimeState }) => {
  const timeStates = [
    { value: 'PAST_OPEN', label: 'recurringTasks.timeState.past_open' },
    { value: 'PAST_OFFERED', label: 'recurringTasks.timeState.past_offered' },
    { value: 'AFTER_BOOKED_DAY', label: 'recurringTasks.timeState.after_booked_day' },
    { value: 'BOOKED_PRE_EVENT', label: 'recurringTasks.timeState.booked_pre_event' },
    { value: 'BOOKED_PAST_EVENT', label: 'recurringTasks.timeState.booked_past_event' },
  ];

  const timeStateValue = timeStates.find((type) => type.value === timeState);

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <div style={{ marginRight: 5, alignSelf: 'center' }}>Fällig</div>
        <div style={{ maxWidth: 80 }}>
          <TransTextInputField
            className="clickable"
            type="number"
            inputStyles={{ border: 'none', color: '#000000', textAlign: 'center' }}
            saveValue={() => {}}
            onChange={(e) => {
              setDays(e.target.value);
            }}
            value={days}
            inlinePlaceholder="X"
          />
        </div>
        <div style={{ marginLeft: 5, alignSelf: 'center' }}>Tage</div>
        <div style={{ marginLeft: 10, width: 300 }}>
          <TransSelectField
            value={timeStateValue}
            options={timeStates}
            onChangeHandler={(val) => {
              setTimeState(val);
            }}
            noSelectionPlaceholder=""
          />
        </div>
      </div>
    </div>
  );
};

export default RecurringTaskFlow2;
