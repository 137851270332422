import { ajaxActions } from '../AjaxActions';

export const fetchEventKindStatistic = (year, leadOrigin, abortSignal) => {
  const requestParams = [];
  if (year) {
    requestParams.push(`year=${year}`);
  }
  if (leadOrigin) {
    requestParams.push(`leadOrigin=${leadOrigin}`);
  }
  return ajaxActions.get(`${process.env.REACT_APP_EVENT_SERVICE_URL}/statistics/event-kinds?${requestParams.join('&')}`, { signal: abortSignal }).then((resp) => resp);
}

export const fetchBookingStateStatistic = (year, leadOrigin, eventKind, abortSignal) => {
  const requestParams = [];
  if (year) {
    requestParams.push(`year=${year}`);
  }
  if (leadOrigin) {
    requestParams.push(`leadOrigin=${leadOrigin}`);
  }
  if (eventKind) {
    requestParams.push(`eventKind=${eventKind}`);
  }
  return ajaxActions.get(`${process.env.REACT_APP_EVENT_SERVICE_URL}/statistics/booking-states?${requestParams.join('&')}`, { signal: abortSignal }).then((resp) => resp);
}

export const fetchLeadOriginStatistic = (year, eventKind, abortSignal) => {
  const requestParams = [];
  if (year) {
    requestParams.push(`year=${year}`);
  }
  if (eventKind) {
    requestParams.push(`eventKind=${eventKind}`);
  }
  return ajaxActions.get(`${process.env.REACT_APP_EVENT_SERVICE_URL}/statistics/lead-origins?${requestParams.join('&')}`, { signal: abortSignal }).then((resp) => resp);
}

export const fetchEventsCreatedStatistic = (year, leadOrigin, eventKind, abortSignal) => {
  const requestParams = [];
  if (year) {
    requestParams.push(`year=${year}`);
  }
  if (leadOrigin) {
    requestParams.push(`leadOrigin=${leadOrigin}`);
  }
  if (eventKind) {
    requestParams.push(`eventKind=${eventKind}`);
  }
  return ajaxActions.get(`${process.env.REACT_APP_EVENT_SERVICE_URL}/statistics/events-created?${requestParams.join('&')}`, { signal: abortSignal }).then((resp) => resp);
}

export const fetchDocumentsStatistic = (year) => {
  const requestParams = [];
  if (year) {
    requestParams.push(`year=${year}`);
  }
  return ajaxActions.get(`${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/statistics/documents?${requestParams.join('&')}`).then((resp) => resp);
}

export const fetchPaymentDocumentsStatistic = (year, leadOrigin, eventKind, abortSignal) => {
  const requestParams = [];
  if (year) {
    requestParams.push(`year=${year}`);
  }
  if (leadOrigin) {
    requestParams.push(`leadOrigin=${leadOrigin}`);
  }
  if (eventKind) {
    requestParams.push(`eventKind=${eventKind}`);
  }
  return ajaxActions.get(`${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/statistics/document-payments?${requestParams.join('&')}`, { signal: abortSignal }).then((resp) => resp);
}

export const fetchEventDocumentsStatistic = (year, leadOrigin, eventKind, abortSignal) => {
  const requestParams = [];
  if (year) {
    requestParams.push(`year=${year}`);
  }
  if (leadOrigin) {
    requestParams.push(`leadOrigin=${leadOrigin}`);
  }
  if (eventKind) {
    requestParams.push(`eventKind=${eventKind}`);
  }
  return ajaxActions.get(`${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/statistics/expected-revenue?${requestParams.join('&')}`, { signal: abortSignal }).then((resp) => resp);
}
