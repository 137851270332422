import React, { useContext } from 'react';
import { withI18n } from '@lingui/react';
import TemplatePreview from '../Templates/TemplatePreview';
import { QuestionnaireContext } from '../../../Service/Context/QuestionnaireContext';

export default withI18n()(() => {
  const { questionnaire } = useContext(QuestionnaireContext);

  const styles = {
    container: {
      display: 'flex',
    },
    button: {
      marginLeft: 5,
      alignSelf: 'center',
    },
  };

  return (
    <div style={styles.container}>
      <TemplatePreview style={styles.button} name={questionnaire.id} documentType="QUESTIONNAIRE" />
    </div>
  );
});
