import React, { useContext, useState } from 'react';
import { TransButtonLink } from '../../Atoms/Buttons';
import { QuestionnaireContext } from '../../Service/Context/QuestionnaireContext';
import { ConfirmDialog } from '../../Molecules/Dialogs';
import { Question } from './Question';

export default function ({
  bundleId,
  questions,
  setQuestions,
  event,
  eventLocation,
  customer,
  customAttributeValues,
  settings,
}) {
  const { addQuestion, delQuestion, moveQuestion } = useContext(QuestionnaireContext);
  const [questionInDelete, setQuestionInDelete] = useState(undefined);

  function handleChange(patch) {
    const oldVal = questions.find((q) => q.id === patch.id);
    const indexOfVal = questions.indexOf(oldVal);
    const newQuestions = Object.assign([], questions);
    newQuestions.splice(indexOfVal, 1, patch);
    setQuestions(newQuestions);
  }

  const styles = {
    addQuestion: {
      marginTop: 10,
      marginLeft: 15,
    },
  };

  return (
    <div>
      {questions.map((val, index) => (
        <Question
          key={index}
          handleChange={handleChange}
          moveQuestion={moveQuestion}
          bundleId={bundleId}
          setQuestionInDelete={setQuestionInDelete}
          questionsLength={questions.length}
          question={val}
          index={index}
          event={event}
          eventLocation={eventLocation}
          customer={customer}
          customAttributeValues={customAttributeValues}
          settings={settings}
          setQuestions={setQuestions}
        />
      ))}
      <ConfirmDialog
        text="questionnaire.question.delete.confirm"
        subject="questionnaire.question.delete.confirm.head"
        open={questionInDelete}
        toggle={() => {
          setQuestionInDelete(undefined);
        }}
        handler={() => delQuestion(questionInDelete, bundleId)}
      />
      <div style={styles.addQuestion}>
        <TransButtonLink onClick={() => addQuestion(bundleId)} text="common.add.question" />
      </div>
    </div>
  );
}
