import React, { useContext } from 'react';
import { withI18n } from '@lingui/react';
import TemplatePreview from '../Templates/TemplatePreview';
import { ContractContext } from '../../../Service/Context/ContractContext';
import { Dropdown } from '../../../Atoms/Dropdowns';
import ConfigPDFCoverDialog from '../../Dialogs/ConfigPDFCoverDialog';
import { useModalState } from '../../Dialogs';
import { patchContractTemplate } from '../../../Service/restapi/contractTemplateService';

export default withI18n()(() => {
  const { contract, handleChange } = useContext(ContractContext);
  const { show, toggle } = useModalState(false);

  const styles = {
    container: {
      display: 'flex',
    },
    button: {
      marginLeft: 5,
      alignSelf: 'center',
    },
    dropdown: {
      color: '#96969A',
      padding: '0.2rem 0.6rem',
      backgroundColor: '#ffffff',
      border: '1px solid #e5e5e5',
      borderRadius: '4px',
      textAlign: 'center',
      fontWeight: 800,
    },
  };

  function patch(patch) {
    patchContractTemplate(contract.id, patch)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((contract) => {
            handleChange(contract);
          });
        } else {
          console.error(`could not patch contract ${contract.id}`);
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error(`could not patch contract ${contract.id}`);
      });
  }

  const items = [{ text: 'PDFs beifügen', onClick: () => toggle() }];

  return (
    <div style={styles.container}>
      <div style={{ alignSelf: 'center' }}>
        <Dropdown style={styles.dropdown} caret left value="Mehr" options={{ items }} />
      </div>
      <ConfigPDFCoverDialog show={show} toggle={toggle} patch={patch} />

      <TemplatePreview style={styles.button} name={contract.name} documentType="CONTRACT" />
    </div>
  );
});
