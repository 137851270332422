import { useContext } from 'react';
import { CustomerContextProvider } from '../CustomerContext';
import { EventParticipationsContext } from '../EventParticipationContext';

const CustomerContextProviderProxy = () => {
  const { mainCustomerId } = useContext(EventParticipationsContext);
  return <CustomerContextProvider mainCustomerId={mainCustomerId} />;
};

export default CustomerContextProviderProxy;
