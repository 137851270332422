// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Group, Menu, Text } from '@mantine/core';
import { FC, useContext } from 'react';
import { openModal } from '@mantine/modals';
import { IconChevronDown, IconSend } from '@tabler/icons';
import moment from 'moment';
import Dot from '../../../Atoms/Dot';
import { RecommendationDto } from '../../../Types/RecommendationT';
import ExportEventInfoModal from './Modals/ExportEventInfoModal';
import { RecommendationContext } from './RecommendationsPane';

const ExportEventInfoStatus: FC<{ recommendation: RecommendationDto }> = ({ recommendation }) => {
  const { lastContactShare } = recommendation;
  const { patchRecommendation } = useContext(RecommendationContext);

  const openExportEventInfoModal = () =>
    openModal({
      title: (
        <Text weight="bolder" size="xl" sx={{ lineHeight: 1.2 }}>
          Auftragsdaten übermitteln
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: <ExportEventInfoModal recommendation={recommendation} patchRecommendation={patchRecommendation} />,
    });

  return (
    <Box
      p={5}
      pr="xs"
      pl="md"
      sx={(theme) => ({ borderRadius: theme.spacing.xs, backgroundColor: 'white', fontSize: theme.fontSizes.xs })}
    >
      <Group position="apart">
        <Group>
          <Dot colour={lastContactShare ? '#4EBF46' : '#f44336'} />
          <Text weight="bolder">{`Daten ${lastContactShare ? 'übermittelt' : 'noch nicht übermittelt'}`}</Text>
        </Group>
        <Menu shadow="md" radius="md" width={230} position="bottom-end" transition="scale-y">
          <Menu.Target>
            <div>
              <IconChevronDown size={16} style={{ cursor: 'pointer' }} />
            </div>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Item icon={<IconSend size={16} />} onClick={openExportEventInfoModal}>
              {`Daten ${lastContactShare ? '' : 'erneut'} übermitteln`}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      </Group>
      {lastContactShare && (
        <Text italic color="dimmed">{`am ${moment(lastContactShare).format('DD.MM.YY HH:mm')}`}</Text>
      )}
    </Box>
  );
};

export default ExportEventInfoStatus;
