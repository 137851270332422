import { EditableContactProperty } from '../../Atoms/EditableProperties/EditableContactProperty';
import { SimpleEditableText } from '../../Atoms/inputs/SimpleEditableText';
import { StringFormatter } from '../../Atoms/Formatter/StringFormatter';
import { EditableTimeInput } from '../../Atoms/inputs/EditableTimeInput';
import { TimeFormatter } from '../../Atoms/Formatter/TimeFormatter';
import { EditableEventDateProperty } from '../../Atoms/EditableProperties/EditableEventDateProperty';
import { EditableEventLocationProperty } from '../../Atoms/EditableProperties/EditableEventLocationProperty';
import { EditableEventProperty } from '../../Atoms/EditableProperties/EditableEventProperty';
import EditableDateInput from '../../Atoms/inputs/EditableDateInput';
import { DateFormatter } from '../../Atoms/Formatter/DateFormatter';
import { CustomerContextInjection } from './CustomerContextInjection';

export const EditablePlaceholderDispatcher = ({ placeholderId, refreshValue }) => {
  switch (placeholderId) {
    case '{event_date}':
      return (
        <EditableEventDateProperty
          patchField="date"
          preChange={(patch) => {
            refreshValue('event', patch);
            return patch;
          }}
          placeholder="Event-Datum"
          editFormatter={EditableDateInput}
          labelFormatter={DateFormatter}
        />
      );
    case '{event_timeFrom}':
      return (
        <EditableEventDateProperty
          patchField="timeFrom"
          preChange={(patch) => {
            refreshValue('event', patch);
            return patch;
          }}
          placeholder="Start-Uhrzeit"
          editFormatter={EditableTimeInput}
          labelFormatter={TimeFormatter}
        />
      );
    case '{event_timeTo}':
      return (
        <EditableEventDateProperty
          patchField="timeTo"
          preChange={(patch) => {
            refreshValue('event', patch);
            return patch;
          }}
          placeholder="End-Uhrzeit"
          editFormatter={EditableTimeInput}
          labelFormatter={TimeFormatter}
        />
      );
    case '{event_kind}':
      return (
        <EditableEventProperty
          patchField="kind"
          preChange={(patch) => {
            refreshValue('event', patch);
            return patch;
          }}
          placeholder="Art der Veranstaltung"
          editFormatter={SimpleEditableText}
          labelFormatter={StringFormatter}
        />
      );

    case '{customer_firstName}':
      return (
        <CustomerContextInjection>
          <EditableContactProperty
            patchField="firstName"
            preChange={(patch) => {
              refreshValue('customer', patch);
              return patch;
            }}
            placeholder="Vorname"
            editFormatter={SimpleEditableText}
            labelFormatter={StringFormatter}
          />
        </CustomerContextInjection>
      );

    case '{customer_lastName}':
      return (
        <CustomerContextInjection>
          <EditableContactProperty
            patchField="lastName"
            preChange={(patch) => {
              refreshValue('customer', patch);
              return patch;
            }}
            placeholder="Nachname"
            editFormatter={SimpleEditableText}
            labelFormatter={StringFormatter}
          />
        </CustomerContextInjection>
      );

    case '{customer_companyName}':
      return (
        <CustomerContextInjection>
          <EditableContactProperty
            patchField="companyName"
            preChange={(patch) => {
              refreshValue('customer', patch);
              return patch;
            }}
            placeholder="Firmenname"
            editFormatter={SimpleEditableText}
            labelFormatter={StringFormatter}
          />
        </CustomerContextInjection>
      );
    case '{location_name}':
      return (
        <EditableEventLocationProperty
          patchField="name"
          preChange={(patch) => {
            refreshValue('location', patch);
            return patch;
          }}
          placeholder="Name der Location"
          editFormatter={SimpleEditableText}
          labelFormatter={StringFormatter}
        />
      );
  }
  return null;
};
