import { Col, InputGroup, Row } from 'reactstrap';
import { Trans, withI18n } from '@lingui/react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Space } from '@mantine/core';
import { fetchWebForms } from '../../../Service/restapi/webformService';
import { TransButtonLink } from '../../../Atoms/Buttons';
import { EditableProfileProperty } from '../../../Atoms/EditableProperties/EditableProfileProperty';
import { EditableSelectField } from '../../../Atoms/inputs/EditableSelectField';
import ClipboardButton from '../../../Atoms/buttons/ClipboardButton';
import { LoadingAnimation } from '../../../Service/ResourceAdapter';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import PanePaper from '../../../Molecules/PanePaper';
import { RoutingS } from '../../../Service/RoutingS';
import WebForms from './WebForms';
import BookitupFormsAlert from './BookitupFormsAlert';

export const ConnectWebsite = withI18n()(({ i18n }) => {
  const { profile } = useContext(ProfileContext);
  const [webForms, setReceivedFormData] = useState([]);
  const [javaScriptCode] = useState(
    `<script type="text/javascript" src="https://connect.bookitup.de/js/bookitup-${profile.id}-min.js"/>`,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    RoutingS.changeTitle('Webseite verbinden');
    loadWebForms();
  }, []);

  function loadWebForms() {
    setIsLoading(true);
    fetchWebForms()
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((webForms) => {
            setReceivedFormData(webForms);
          });
        } else {
          console.error('could not load webForms');
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not load webForms');
        setIsLoading(false);
      });
  }

  const styles = {
    codeField: {
      height: 34,
      borderRadius: 5,
      marginRight: 4,
    },
    textField: {
      height: 200,
      borderRadius: 5,
      marginRight: 4,
    },
    description: {
      marginBottom: 10,
      display: 'flex',
      flexDirection: 'column',
    },
    dropdown: {
      maxWidth: 200,
      marginTop: 10,
      alignSelf: 'center',
    },
  };

  const websiteOptions = [
    { label: i18n._('webform.website.wordpress'), value: 'WordPress' },
    { label: i18n._('webform.website.wix'), value: 'WiX' },
    { label: i18n._('webform.website.jimdo'), value: 'Jimdo' },
    { label: i18n._('webform.website.1&1'), value: '1&1 Baukasten' },
    { label: i18n._('webform.website.other'), value: 'Other' },
  ];

  const dataPrivacyText =
    'Zur einfachen und schnellen Auftragsverarbeitung, sind die Kontaktformulare dieser Webseite mit dem Dienst https://bookitup.de der bookitup GmbH verknüpft. Alle eingetragenen Daten (einschließlich personenbezogene Daten) werden zu https://bookitup.de übertragen. Diese Kundendaten werden von der bookitup GmbH ausschließlich auf Servern im EU-Raum gehostet.\n' +
    'Mit Hilfe des Dienstes https://app.bookitup.de verarbeite ich automatisch übermittelte, sowie manuell erfasste Daten, um Ihren Auftrag zu bearbeiten. Zur Bearbeitung des Auftrags bietet der Dienst https://bookitup.de der bookitup GmbH u.a. eine Option zur Kommunikation per Postfach, das Erstellen diverser Dokumente wie Angebote, Rechnungen, Fragebögen und Verträgen, sowie eine Kalender und Aufgaben-Funktionen an. Der Webseitenverantwortliche dieser Webseite hat mit der bookitup GmbH einen Nutzungsvertrag, sowie Auftragsverarbeitungsvertrag abgeschlossen.';

  return (
    <PanePaper title="connectwebsite.title">
      <Row>
        <Col xs="12">
          <div style={styles.description}>
            <BookitupFormsAlert />
            <Space h="xs" />
            <Trans id="connectwebsite.description" />

            <div style={styles.dropdown}>
              Was hast Du für eine Webseite?
              <EditableProfileProperty
                patchField="kindOfWebsite"
                options={websiteOptions}
                profile={profile}
                editFormatter={EditableSelectField}
                editOnly
              />
            </div>
          </div>
          {profile.kindOfWebsite && (
            <>
              {profile.kindOfWebsite !== 'WiX' && (
                <div>
                  <div className="step">
                    <div>
                      <div>
                        <Trans id="connectwebsite.setup.explanation.step1"/>
                      </div>
                      <InputGroup>
                        <input
                          readOnly
                          className="code form-control"
                          style={styles.codeField}
                          value={javaScriptCode}
                        />
                        <ClipboardButton text={javaScriptCode}/>
                      </InputGroup>
                    </div>

                    {profile.kindOfWebsite === 'Jimdo' && (
                      <>
                        <br/>
                        <img
                          style={{
                            width: '100%',
                            maxWidth: 1000,
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            display: 'block',
                          }}
                          src="/img/webhooks/webhook-jimdo.png"
                        />
                      </>
                    )}
                  </div>
                  <br/>
                  <div className="step haspredecessor">
                    <Trans id="connectwebsite.setup.explanation.step2"/>
                  </div>
                  <br/>
                  <div className="step haspredecessor">
                    Klick anschließend hier auf
                    <TransButtonLink
                      isLoading={isLoading}
                      icon="REFRESH"
                      className="primary-link"
                      onClick={loadWebForms}
                      text="common.refresh"
                    />{' '}
                    und verknüpfe die unten angezeigten Felder.
                  </div>
                  <br />
                  <div className="step haspredecessor">
                    Füge folgenden Text in Deine Datenschutz-Erklärung ein:
                    <InputGroup style={{ marginTop: 5 }}>
                      <textarea
                        readOnly
                        className="code form-control"
                        style={styles.textField}
                        value={dataPrivacyText}
                      />
                      <ClipboardButton text={dataPrivacyText} />
                    </InputGroup>
                    <TransButtonLink onClick={() => navigate('/settings/gdpr')} text="Jetzt AV-Vertrag abschließen" />
                  </div>
                </div>
              )}
            </>
          )}
          <br />
          {isLoading && <LoadingAnimation />}
          <WebForms forms={webForms} reloadCallback={loadWebForms} />
        </Col>
      </Row>
    </PanePaper>
  );
});

export const ConnectWebsiteProxy = ConnectWebsite;
