import { useEffect, useState } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useDateRangeItem = (from: Date, to: Date, selectedDateRange: Date[]): boolean => {
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    if (selectedDateRange[0] && selectedDateRange[1]) {
      const match =
        from.getFullYear() === selectedDateRange[0].getFullYear() &&
        from.getMonth() === selectedDateRange[0].getMonth() &&
        from.getDate() === selectedDateRange[0].getDate() &&
        to.getFullYear() === selectedDateRange[1].getFullYear() &&
        to.getMonth() === selectedDateRange[1].getMonth() &&
        to.getDate() === selectedDateRange[1].getDate();
      if (match !== active) {
        setActive(match);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDateRange]);

  return active;
};
