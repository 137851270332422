// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Button, Center, Loader, Text } from '@mantine/core';
import { i18n } from '@lingui/core';
import { closeAllModals } from '@mantine/modals';
import { FC, useState } from 'react';
import ResourceCategoryS from '../../../Service/restapi/resourceCategoryService';

const DeleteResourceModal: FC<{ categoryId: string; removeCategory: () => void }> = ({
  categoryId,
  removeCategory,
}) => {
  const [loading, setLoading] = useState(false);

  const onConfirm = () => {
    setLoading(true);
    ResourceCategoryS.deleteCategory(categoryId)
      .then((deleted) => {
        if (deleted) {
          removeCategory();
          closeAllModals();
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <Text mt="xl">Möchten Sie die Kategorie unwiderruflich löschen?</Text>
      <Center>
        <Box sx={{ width: '70%' }}>
          <Button
            fullWidth
            mt="xl"
            mb="xs"
            onClick={onConfirm}
            disabled={loading}
            leftIcon={loading && <Loader size="xs" />}
          >
            {i18n._('actions.yes')}
          </Button>
          <Button fullWidth disabled={loading} color="gray" variant="subtle" onClick={() => closeAllModals()}>
            {i18n._('actions.cancel')}
          </Button>
        </Box>
      </Center>
    </>
  );
};

export default DeleteResourceModal;
