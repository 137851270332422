import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Editor } from '@tiptap/react';
import { MessageAction, MessageDTO } from '../../Types/MessageT';
import { EntityType, FileS } from '../FileS';
import { MessageS } from '../MessageS';
import { openScheduleMessageModal } from '../../Organismns/Message/MessageModals';
import { LicenceS } from '../LicenceS';
import { replacePlaceholdersHtmlWithPlaceholdersId } from '../Placeholder/PlaceholderResover';
import { ViolationContext } from './ViolationContext';
import { PostboxCountersContext } from './PostboxCountersContext';

interface MessageContextInterface {
  message: MessageDTO | null;
  sending: boolean;
  loading: boolean;
  action: MessageAction | null;
  setAction: (action: MessageAction | null) => void;
  changeByAction: (messageId: number, action: MessageAction) => unknown;
  initMessage: (props?: Partial<MessageDTO>) => unknown;
  changeMessage: (patch: Partial<MessageDTO> | null) => unknown;
  sendMessage: (editor?: Editor) => unknown;
  scheduleMessage: () => unknown;
  patchMessage: (patch: Partial<MessageDTO>) => unknown;
}

export const MessageContext = createContext<MessageContextInterface>({} as MessageContextInterface);

export const MessageContextProvider = () => {
  const [searchParams] = useSearchParams();
  const { refreshCount } = useContext(PostboxCountersContext);
  const navigate = useNavigate();
  const { id: eventId } = useParams();
  const [message, setMessage] = useState<MessageDTO | null>(null);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState<MessageAction | null>(null);
  const { executeWithLicenceCheck } = useContext(ViolationContext);

  useEffect(() => {
    checkAttachmentsParams();
    checkMessageParams();
  }, []);

  useEffect(() => {
    if (message === null) {
      setAction(null);
    }
    if (message) {
      refreshCount('drafts');
    }
  }, [message]);

  const checkAttachmentsParams = async () => {
    const entityId = searchParams.get('entityId');
    const entityType = searchParams.get('entityType');
    if (entityId && entityType) {
      const fileDetails = await FileS.getFirstEntityFileDetails(entityType as EntityType, entityId);
      if (fileDetails) {
        initMessage({
          attachments: [
            {
              entityId: Number(entityId),
              entityType,
              ...fileDetails,
            },
          ],
        });
      }
    }
  };

  const checkMessageParams = async () => {
    const messageId = searchParams.get('messageId');
    const action = searchParams.get('action');
    if (messageId && action) {
      changeByAction(Number(messageId), action as MessageAction);
    }
  };

  const sendMessage = async (editor?: Editor) => {
    if (message) {
      try {
        setSending(true);
        if (editor) {
          message.message = replacePlaceholdersHtmlWithPlaceholdersId(editor.getHTML());
        }
        const plainMessage = await MessageS.validateMessage(message);
        if (plainMessage) {
          const msg = await MessageS.sendMail(plainMessage);
          setSending(false);
          if (msg) {
            setMessage(null);
            if (eventId) {
              if (action === 'REPLY') {
                refreshCount('inbox');
              }
              navigate(`/events/${eventId}/emails/outbound`);
            } else {
              navigate('/postbox/outbound');
            }
          }
        }
      } finally {
        setSending(false);
      }
    }
  };

  const scheduleMessage = async () => executeWithLicenceCheck(LicenceS.Restrictions.SCHEDULE_MAIL, schedule);

  const schedule = async () => {
    if (message) {
      const plainMessage = await MessageS.validateMessage(message);
      if (plainMessage) {
        const onCreate = () => {
          refreshCount('drafts');
          refreshCount('scheduled');
          if (eventId) {
            navigate(`/events/${eventId}/emails/scheduled`);
          } else {
            navigate('/postbox/scheduled');
          }
          MessageS.patchDraftMessage({ subject: plainMessage.subject, message: plainMessage.message }, message.id);
          setMessage(null);
        };
        const onDelete = () => {
          refreshCount('drafts');
          refreshCount('scheduled');
          if (eventId) {
            navigate(`/events/${eventId}/emails/drafts`);
          } else {
            navigate('/postbox/drafts');
          }
        };
        openScheduleMessageModal({ message, onCreate, onDelete });
      }
    }
  };

  const initMessage = async (props = {} as Partial<MessageDTO>) => {
    setLoading(true);
    const draft = await MessageS.patchNewDraft(props, eventId);
    setLoading(false);
    if (draft) {
      setMessage(draft);
      setAction('NEW');
    }
  };

  const changeMessage = (patch: Partial<MessageDTO> | null) => {
    if (patch === null) {
      setMessage(null);
    } else {
      setMessage((prev) => {
        if (prev) {
          return { ...prev, ...patch };
        }
        return null;
      });
    }
  };

  const patchMessage = async (patch: Partial<MessageDTO>) => {
    if (message && !sending) {
      const msg = await MessageS.patchDraftMessage(patch, message.id);
      if (msg) {
        setMessage(msg);
      }
    }
  };

  const changeByAction = async (messageId: number, action: MessageAction) => {
    const preparedMessage = await MessageS.getEmailByAction(messageId, action);
    if (preparedMessage) {
      setMessage(preparedMessage);
      setAction(action);
    }
  };

  const value = useMemo(
    () => ({
      message,
      loading,
      sending,
      action,
      initMessage,
      changeMessage,
      scheduleMessage,
      patchMessage,
      sendMessage,
      changeByAction,
      setAction,
    }),
    [message, loading, sending, action],
  );

  return (
    <MessageContext.Provider value={value}>
      <Outlet />
    </MessageContext.Provider>
  );
};
