import { Col } from 'reactstrap';
import { ButtonLink } from '../Atoms/Buttons';
import { Dropdown } from '../Atoms/Dropdowns';
import TooltipButton from '../Atoms/buttons/TooltipButton';

const React = require('react');

export class DynamicFieldWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.childChanged = this.childChanged.bind(this);
    this.state = { total: [0], actual: [0], values: this.props.values };
  }

  childChanged(input, value, name) {
    this.props.changeHandler(input, value, name);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ...this.state.values, values: nextProps.values });
  }

  renderChildren(input) {
    return React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, {
        input,
        name: child.props.name,
        changeHandler: this.childChanged,
        value: this.state.values[input],
      }),
    );
  }

  render() {
    let className = '';
    if (this.props.pane !== undefined) {
      className = 'pane';
    }
    return this.state.values.map((input, index) => (
      <div className={`dynamic-list ${className}`} key={index}>
        {this.renderChildren(index)}
      </div>
    ));
  }
}

export const DeleteAction = (props) => {
  const opts = {};
  /* if (props.count === 1) {
        opts['disabled'] = true;
    } */

  return (
    <TooltipButton
      isLoading={props.isLoading}
      id={`delete${props.index}`}
      text="actions.delete"
      {...opts}
      onClick={props.deletePosition}
      icon="TRASH"
    />
  );
};

export class FavoritAction extends React.Component {
  constructor(props) {
    super(props);
    this.markAsFavorit = this.markAsFavorit.bind(this);
    this.unmarkAsFavorit = this.unmarkAsFavorit.bind(this);
  }

  markAsFavorit(index) {
    const position = this.props.values[index];
    position.favorite = true;
    const { values } = this.props;
    values[index] = position;
    this.props.refreshHandler(values);
  }

  unmarkAsFavorit(index) {
    const position = this.props.values[index];
    position.favorite = false;
    const { values } = this.props;
    values[index] = position;
    this.props.refreshHandler(values);
  }

  render() {
    const position = this.props.values[this.props.input];
    const { favorite } = position;
    return (
      <Col xs="2" sm="1">
        {favorite && (
          <TooltipButton
            id={`pos${this.props.input}`}
            text="actions.document.position.favorite.remove"
            onClick={() => this.unmarkAsFavorit(this.props.input)}
            icon="STAR_FILLED"
          />
        )}
        {!favorite && (
          <TooltipButton
            id={`pos${this.props.input}`}
            text="actions.document.position.favorite.add"
            onClick={() => this.markAsFavorit(this.props.input)}
            icon="STAR"
          />
        )}
      </Col>
    );
  }
}

export const PositionDropdown = ({ count, index, saveAsFavorite, addPosition, insertAfter, movePosition }) => {
  const items = [];

  if (typeof movePosition === 'function' && index !== 0) {
    items.push({ text: 'nach oben schieben', icon: 'UP', onClick: pushUp });
  }

  if (typeof movePosition === 'function' && index !== count - 1) {
    items.push({ text: 'nach unten schieben', icon: 'DOWN', onClick: pushDown });
  }
  // items.push({text: "nach unten schieben", icon:"DOWN", onClick: () => {}});
  if (typeof saveAsFavorite === 'function') {
    items.push({ text: 'unter Favoriten speichern', icon: 'STAR_FILLED', onClick: saveAsFavorite });
  }
  if (typeof addPosition === 'function') {
    items.push({ text: 'Oberhalb hinzufügen', icon: 'PLUS', onClick: () => insertAfterPosition(index) });
    items.push({ text: 'Unterhalb hinzufügen', icon: 'PLUS', onClick: () => insertAfterPosition(index + 1) });
  }
  if (typeof insertAfter === 'function') {
    items.push({ text: 'Unterhalb hinzufügen', icon: 'PLUS', onClick: () => insertAfter() });
  }

  // items.push({text: "Menge/Einheit einstellen", icon:"DICE_THREE", onClick: () => {}});
  // items.push({text: "USt. individuell einstellen", icon:"PERCENT", onClick: () => {}});
  // items.push({text: "Duplizieren", icon:"DUPLICATE", onClick: () => {}});

  function pushUp() {
    if (index > 0) {
      movePosition(index, index - 1);
    }
  }

  function pushDown() {
    movePosition(index, index + 1);
  }

  function insertAfterPosition(index) {
    addPosition(index);
  }

  return (
    <Dropdown
      caret={false}
      icon="DOTS"
      style={{
        backgroundColor: 'rgb(240, 240, 240)',
        color: '#96969A',
        padding: 0,
        width: 24,
        borderRadius: 8,
        height: 17,
        textAlign: 'center',
      }}
      value=""
      left
      options={{ items }}
    />
  );
};

export class PushUpAction extends React.Component {
  constructor(props) {
    super(props);
    this.pushUp = this.pushUp.bind(this);
  }

  pushUp(index) {
    const val = this.props.values[index];
    const valAbove = this.props.values[index - 1];
    const { values } = this.props;
    values[index - 1] = val;
    values[index] = valAbove;

    this.props.refreshHandler(values);
  }

  render() {
    const opts = {};
    if (this.props.input === 0) {
      opts.disabled = true;
    }
    return (
      <div style={{ position: 'absolute', marginLeft: '-55px' }}>
        <TooltipButton
          {...opts}
          id={`pushUp${this.props.input}`}
          text="actions.document.position.pushup"
          onClick={() => this.pushUp(this.props.input)}
          icon="UP"
        />
      </div>
    );
  }
}

export class InsertAction extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Col sm="12" xs="12">
        <ButtonLink
          className="drag-in"
          text="+"
          title="Position einschieben"
          onClick={() => this.props.onClick(this.props.input)}
          value=""
        />
      </Col>
    );
  }
}
