import { useContext, useEffect, useState } from 'react';
import {Group, Select, Skeleton, Stack, Textarea, TextInput} from '@mantine/core';
import PanePaper from '../../Molecules/PanePaper';
import { ResourceType } from '../../Types/ResourceT';
import { DataS } from '../../Service/DataS';
import { ResourceDetailsContext } from '../../Service/Context/ResourceDetailsContext';
import ResourceS from '../../Service/ResourceS';
import CategoryInputField from './CategoryInputField';
import { openChangeResourceTypeModal } from './ResourceModals';
import AutocompleteResourceTypeItem from "./table/AutocompleteResourceTypeItem";
import BookitupSelect from "../../Atoms/BookitupSelect";

const ResourceInfoPane = () => {
  const { loading, resource, updateResource, patchResource } = useContext(ResourceDetailsContext);
  const { id: resourceId, name, description, type, category, code } = resource ?? {};

  const [_name, setName] = useState(name);
  const [_description, setDescription] = useState(description);
  const [_code, setCode] = useState(code);

  useEffect(() => {
    if (_name !== name) {
      setName(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (_description !== description) {
      setDescription(description);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description]);

  useEffect(() => {
    if (_code !== code) {
      setCode(code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  if (loading) {
    return <Skeleton height={150} width="100%" />;
  }
  if (!resource) {
    return null;
  }

  const updateType = (newType: ResourceType | null) => {
    if (resourceId && newType !== null) {
      const patch = () => updateResource({ type: newType });
      if (newType === 'ITEM' && (type === 'KIT' || type === 'CASE')) {
        openChangeResourceTypeModal(() => {
          patch();
          ResourceS.removeAllParts(resourceId);
        });
      } else {
        patch();
      }
    }
  };

  return (
    <PanePaper title="Stammdaten" sx={{ flexGrow: 2 }}>
      <Stack>
        <Group grow>
          <TextInput
            label="Name"
            value={_name}
            onChange={(e) => setName(e.target.value)}
            onBlur={() => updateResource({ name: _name })}
          />
          <BookitupSelect
              mt="md"
              label="Typ"
              sx={{marginTop: "0px !important"}}
              variant={"default"}
              data={DataS.resourceTypeOptions}
              value={type}
              onChange={updateType}
              itemComponent={AutocompleteResourceTypeItem}
          />
        </Group>

        <Textarea
          label="Beschreibung"
          value={_description}
          onChange={(e) => setDescription(e.target.value)}
          onBlur={() => updateResource({ description: _description })}
        />
        <Group grow>
          {/*<TextInput*/}
          {/*  label="Code"*/}
          {/*  value={_code}*/}
          {/*  onChange={(e) => setCode(e.target.value)}*/}
          {/*  onBlur={() => updateResource({ code: _code })}*/}
          {/*/>*/}
          <CategoryInputField
            initialName={category?.name}
            onSelected={(categoryName) => patchResource({ categoryName })}
          />
        </Group>
      </Stack>
    </PanePaper>
  );
};

export default ResourceInfoPane;
