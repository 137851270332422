import { FC, useContext, useEffect, useState } from 'react';
import { AppShell, Navbar, ScrollArea } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { DocumentsContextProvider } from '../../Service/Context/DocumentsContext';
import DocumentHeadActions from '../../Templates/Documents/DocumentHeadActions';
import { WindowContext } from '../../Service/Context/WindowContext';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import PageHeader from '../../Templates/Navigation/PageHeader';
import { RoutingS } from '../../Service/RoutingS';
import DocumentListNavLinks from './DocumentListNavLinks';

const DocumentsPage: FC = () => {
  const [navBarOpen, setNavBarOpen] = useState<boolean>(false);
  const { sm } = useContext(WindowContext);

  useEffect(() => {
    RoutingS.changeTitle('Dokumente');
  }, []);

  return (
    <DocumentsContextProvider>
      <AppShell
        header={
          <PageHeader
            title="Dokumente"
            navBarOpen={navBarOpen}
            setNavBarOpen={setNavBarOpen}
            rightSection={<DocumentHeadActions />}
          />
        }
        navbar={
          <div>
            {sm ? (
              <Navbar hiddenBreakpoint="sm" hidden={!navBarOpen} width={{ sm: 220, md: 250 }}>
                <ScrollArea scrollbarSize={10}>
                  <DocumentListNavLinks />
                </ScrollArea>
              </Navbar>
            ) : (
              <DoubleNavbar>
                <DocumentListNavLinks />
              </DoubleNavbar>
            )}
          </div>
        }
      >
        <Outlet />
      </AppShell>
    </DocumentsContextProvider>
  );
};

export default DocumentsPage;
