import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EditableProperty } from './EditableProperty';

export const EditableTaskProperty = ({
  entityId,
  task,
  handleChange,
  value,
  placeholder,
  editFormatter,
  labelFormatter,
  ...rest
}) => (
  <EditableProperty
    dto={value}
    changeHandler={handleChange}
    patchField="completed"
    placeholder={placeholder}
    targetUrl={`${process.env.REACT_APP_TASK_SERVICE_URL}/agendaItems/${task.id}`}
    editFormatter={editFormatter}
    labelFormatter={labelFormatter}
    task={task}
    {...rest}
  />
);

EditableTaskProperty.propTypes = {
  entityId: PropTypes.number.isRequired,
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.shape({
    value: PropTypes.any,
  }).isRequired,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
};
