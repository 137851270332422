import React from 'react';
import PropTypes from 'prop-types';

/**
 * PercentageFormatter is a formatter which simply passes strings trough and adds ' %', but
 * returns a dash for empty or null values.
 */
export const PercentageFormatter = ({ value, style }) => {
  const styles = {
    span: {
      height: '100%',
      ...style,
    },
  };

  return (
    <span disabled className="form-control" style={styles.span}>
      {value === '' || value === null || value === undefined ? '-' : value} %
    </span>
  );
};

PercentageFormatter.propTypes = {
  value: PropTypes.number,
};
