// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import {Box, Skeleton, Stack, Text} from '@mantine/core';
import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import PanePaper from '../../../Molecules/PanePaper';
import {ResourcePosition} from "../../Settings/Documents/Positions/ResourcePositionAssignment";
import {DocumentS} from "../../../Templates/Documents/DocumentS";
import ResourcePositionItem from "./ResourcePositionItem";
import AddPositionResourceItem from "./AddPositionResourceItem";

const ResourceProductsPane = () => {
  const { id: resourceId } = useParams();
  const [loading, setLoading] = useState(false);
  const [lastModified, setLastModified] = useState(new Date());
  const [resourcePositions, setResourcePositions] = useState<ResourcePosition[] | null>();
  const [resourcePositionFavorites, setResourcePositionFavorites] = useState<ResourcePosition[] | null>();

  const lengthResourcePositions = resourcePositions?.length ?? 0;
  const lengthResourcePositionFavorites = resourcePositionFavorites?.length ?? 0;

  useEffect(() => {
    if (resourceId) {
      setLoading(true);
      Promise.all([DocumentS.fetchResourcePositions(resourceId), DocumentS.fetchResourcePositionFavorites(resourceId)]).then(([positions, positionFavorites]) => {
        setResourcePositions(positions);
        setResourcePositionFavorites(positionFavorites);
      }).finally(() => setLoading(false));
    }
  }, [resourceId, lastModified]);

  return (
    <PanePaper
      withShadow
      withoutBorder
      title="Verknüpfte Produkte"
      rightSection={<AddPositionResourceItem onSave={() => setLastModified(new Date())} />}
    >
      {loading ? (
        Array.from(Array(3)).map(() => <Skeleton key={Math.random()} radius="md" height={20} width="100%" mb="xs" />)
      ) : (
        <div>
          {(lengthResourcePositions > 0 || lengthResourcePositionFavorites > 0) ? (
            <Box
              p="md"
              sx={{
                borderRadius: 10,
                backgroundColor: '#F4F2F2',
              }}
            >
              <Stack spacing="xs">
                {resourcePositions?.map((resourcePosition, index) => {
                  return (
                      <ResourcePositionItem key={index} resourcePosition={resourcePosition} favoritePosition={false} />
                  );
                })}
                {resourcePositionFavorites?.map((resourcePosition, index) => {
                  return (
                      <ResourcePositionItem key={index} resourcePosition={resourcePosition} favoritePosition={true} />
                  );
                })}
              </Stack>
            </Box>
          ) : (
            <Text italic color="dimmed" size="xs" weight="lighter">
              Noch nichts hinzugefügt.
            </Text>
          )}
        </div>
      )}
    </PanePaper>
  );
};

export default ResourceProductsPane;
