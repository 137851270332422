import React, { useContext, useEffect, useState } from 'react';
import { LoadingAnimation } from '../../../Service/ResourceAdapter';
import { SimpleEditableText } from '../../../Atoms/inputs/SimpleEditableText';
import { StringFormatter } from '../../../Atoms/Formatter/StringFormatter';
import { fetchCandisConfig, fetchCandisInvoices } from '../../../Service/restapi/candisService';
import { EditableCandisConfigProperty } from '../../../Atoms/EditableProperties/EditableCandisConfigProperty';
import CandisInvoiceItem from './CandisInvoiceItem';

export default function () {
  const [candisConfig, setCandisConfig] = useState(undefined);
  const [candisInvoices, setCandisInvoices] = useState([]);

  useEffect(() => {
    fetchCandisConfig().then((resp) => {
      if (resp.ok) {
        resp.json().then(setCandisConfig);
      }
    });
    fetchInvoices();
  }, []);

  function fetchInvoices() {
    fetchCandisInvoices()
      .then((resp) => {
        if (resp.ok) {
          resp.json().then(setCandisInvoices);
        } else {
          console.error('could not load candis invoices');
        }
      })
      .catch((error) => {
        console.error({ error });
        console.error('could not load candis invoices');
      });
  }

  if (!candisConfig) {
    return <LoadingAnimation />;
  }

  return (
    <div>
      <div className="step">
        <div className="description" style={{ marginBottom: 10 }}>
          Gib hier die E-Mail-Adresse von Candis ein, an die Deine Rechnungen geschickt werden sollen:
        </div>
        <EditableCandisConfigProperty
          entity={candisConfig}
          patchField="candisEndpointMailAddress"
          changeHandler={(p) => {
            setCandisConfig(p);
          }}
          placeholder="Candis E-Mail-Adresse"
          editFormatter={SimpleEditableText}
          labelFormatter={StringFormatter}
        />
      </div>
      <div style={{ marginTop: 10, padding: 15 }} className="box">
        <h6>Übertragene Rechnungen zu Candis</h6>
        {candisInvoices.map((invoice, index) => (
          <CandisInvoiceItem key={index} index={index} invoice={invoice} refreshInvoices={fetchInvoices} />
        ))}
        {candisInvoices.length === 0 && <div className="description">Bisher keine Rechnungen übertragen</div>}
      </div>
    </div>
  );
}
