// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Center, Image, Skeleton } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { IconTrash } from '@tabler/icons';
import PanePaper from '../../Molecules/PanePaper';
import { FileS } from '../../Service/FileS';
import FilePickerIcon from '../../Atoms/FilePickerIcon';
import { ResourceDetailsContext } from '../../Service/Context/ResourceDetailsContext';
import { MixpanelS } from '../../Service/MixpanelS';
import { ConstantS } from '../../Service/ConstantS';

const ResourceImagePane = () => {
  const { setLoading, loading, resourceId } = useContext(ResourceDetailsContext);

  const [photo, setPhoto] = useState<Blob | null>();

  useEffect(() => {
    if (resourceId) {
      setLoading(true);
      FileS.getFirstEntityFile('resources', resourceId)
        .then(setPhoto)
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceId]);

  const onSelectFile = (file: File) => {
    setLoading(true);
    FileS.put(file, 'resources', resourceId)
      .then((ok) => {
        if (ok) {
          setPhoto(file);
          MixpanelS.track(ConstantS.TrackingEvents.ResourcePhotoUploaded);
        }
      })
      .finally(() => setLoading(false));
  };

  const deletePhoto = () => {
    if (resourceId) {
      setLoading(true);
      FileS.removeEntityFiles('resources', resourceId)
        .then((ok) => ok && setPhoto(undefined))
        .finally(() => setLoading(false));
    }
  };

  return (
    <PanePaper
      title="Abbildung"
      sx={{ maxWidth: 350 }}
      rightSection={photo && <IconTrash onClick={deletePhoto} style={{ cursor: 'pointer' }} />}
    >
      <Skeleton radius="md" visible={loading}>
        <Box
          sx={(theme) => ({
            borderRadius: theme.radius.md,
            backgroundColor: '#DEDEDE',
            width: 275,
            height: 275,
          })}
        >
          {photo ? (
            <Image height={275} src={URL.createObjectURL(photo)} />
          ) : (
            <Center h={275}>
              <FilePickerIcon onSelectFile={onSelectFile} />
            </Center>
          )}
        </Box>
      </Skeleton>
    </PanePaper>
  );
};

export default ResourceImagePane;
