// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { i18n } from '@lingui/core';
import { BookitupNotification } from '../../../../Types/LogT';

const RecommendationStateChanged: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { personDescription, value } = notification;

  return (
    <Text>
      <Text span weight="bolder">
        {` ${personDescription} `}
      </Text>
      hat den Buchungsstatus
      <Text span weight="bolder">
        {` auf ${i18n._(`recommendation.bookingState.${value?.toLowerCase()}`)} `}
      </Text>
      gesetzt.
    </Text>
  );
};

export default RecommendationStateChanged;
