import React, { useEffect, useState } from 'react';
import { withI18n } from '@lingui/react';
import { EditableEmailTemplateProperty } from '../../../Atoms/EditableProperties/EditableEmailTemplateProperty';
import { EditableCheckbox } from '../../../Atoms/inputs/EditableCheckbox';

export default withI18n()(({ i18n, styles, message, itemIsSelected, emailTemplate, templateName, changeMessage }) => {
  const [documentBlueprints, setDocumentBlueprints] = useState(undefined);

  useEffect(() => {
    if (emailTemplate) {
      setDocumentBlueprints([
        { documentType: 'OFFER' },
        { documentType: 'CONFIRMATION' },
        { documentType: 'PARTIAL_INVOICE' },
        { documentType: 'INVOICE' },
        { documentType: 'CONTRACT' },
        { documentType: 'CANCEL' },
      ]);
    }
  }, [emailTemplate]);

  if (!documentBlueprints) {
    return null;
  }

  function getEditableBlueprint(item) {
    let foundItem;
    // console.log("check " + item.documentType, message.documentBlueprints);
    if (message.documentBlueprints) {
      foundItem = message.documentBlueprints.find((a) => a.documentType === item.documentType);
    }
    // console.log({foundItem})
    return {
      id: message.id,
      selected: !!foundItem,
      lastModified: item.lastModified,
    };
  }

  function addDocumentBlueprint(attachment) {
    const newAttachments = Object.assign([], message.documentBlueprints);

    if (itemIsSelected(attachment)) {
      const index = message.documentBlueprints.map((b) => b.documentType).indexOf(attachment.documentType);
      newAttachments.splice(index, 1);
    } else {
      newAttachments.push(attachment);
    }
    return { documentBlueprints: newAttachments };
  }

  return (
    <div style={styles.group}>
      <div style={styles.tableHead}>
        <div>Mögliche Dokument-Anhänge</div>
        <div />
      </div>

      {documentBlueprints.map((item, index) => (
        <EditableEmailTemplateProperty
          messageType={emailTemplate}
          templateName={templateName}
          key={index}
          patchField="selected"
          preChange={(p) => addDocumentBlueprint(item)}
          changeHandler={changeMessage}
          email={getEditableBlueprint(item)}
          placeholder={i18n._(`document.${item.documentType.toLowerCase()}`)}
          editOnly
          editFormatter={EditableCheckbox}
        />
      ))}
    </div>
  );
});
