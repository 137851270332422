const taxInPercentage = (tax) => 1 + tax / 100;

const calculateNetGrossPrice = (taxType, value, tax) => {
  switch (taxType) {
    case 'net':
      return calculateNetPrice(value, tax);

    default:
      return value;
  }
};

const roundPrice = (price) => Math.round(price * 100.0) / 100.0;

const calculateNetPrice = (grossPrice, tax) => roundPrice(grossPrice / taxInPercentage(tax));

const calculateGrossPrice = (netPrice, tax, withoutRound) => {
  const price = netPrice * taxInPercentage(tax);
  return withoutRound ? price : roundPrice(price);
};

const calculateTaxes = (grossPrice, tax) => {
  const netPrice = calculateNetPrice(grossPrice, tax);
  const vat = grossPrice - netPrice;
  return roundPrice(vat);
};

const getDiscountAmount = (discountType, discountValue, grossTotal) => {
  if (discountType === 'ABSOLUTE') {
    return discountValue;
  }
  return grossTotal * (discountValue / 100);
};

const getDiscountPercentageValue = (discountType, discountValue, grossTotal) => {
  if (discountType === 'ABSOLUTE') {
    return discountValue / grossTotal;
  }
  return discountValue / 100;
};

const getVatTaxTotal = (positions, discountPercentage) => {
  const taxVatTotal = {};
  const documentPercentDiscount = 1 - discountPercentage;

  positions.forEach((p) => {
    if (!p.optional) {
      let sumTax = 0;
      let sumTotal = 0;

      if (taxVatTotal[p.tax]) {
        sumTax = taxVatTotal[p.tax][0];
        sumTotal = taxVatTotal[p.tax][1];
      }
      const tax = sumTax + calculateTaxes(roundPrice(p.price * documentPercentDiscount), p.tax);
      const total = sumTotal + roundPrice(p.price * documentPercentDiscount);
      taxVatTotal[p.tax] = [tax, total];
    }
  });
  return taxVatTotal;
};

const getOverallNetPrice = (vats) => {
  let sum = 0;
  vats.forEach((vat) => {
    sum += vat[1] - vat[0];
  });
  return sum;
};

// eslint-disable-next-line import/prefer-default-export
export const DocumentService = {
  calculateNetGrossPrice,
  roundPrice,
  calculateTaxes,
  calculateNetPrice,
  calculateGrossPrice,
  getDiscountAmount,
  getDiscountPercentageValue,
  getVatTaxTotal,
  getOverallNetPrice,
};
