/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, LoadingOverlay, Paper, PasswordInput, Space, Text } from '@mantine/core';
import { IconLock } from '@tabler/icons';
import { FC, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { AuthS } from '../../Service/restapi/authService';
import { RoutingS } from '../../Service/RoutingS';
import { AlertS } from '../../Service/AlertS';
import PasswordChecker from './PasswordChecker';
import AlertBox from './AlertBox';

const PasswordResetPage: FC = () => {
  const isPhoneDevice = useMediaQuery('(max-width: 480px)');
  const [searchParams] = useSearchParams();
  const navigate: NavigateFunction = useNavigate();
  const [alert, setAlert] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [validPassword, setValidPassword] = useState<boolean>(false);
  const token = searchParams.get('token');

  const form = useForm({
    initialValues: {
      password: '',
      repeatedPassword: '',
    },
    validate: {
      password: () => (validPassword ? null : 'Bitte geben Sie ein gültiges Passwort ein'),
      repeatedPassword: (value, values) => (value === values.password ? null : 'Passwörter stimmen nicht überein'),
    },
  });

  const resetPassword = () => {
    setLoading(true);
    const { password } = form.values;
    AuthS.resetPassword(token, { password })
      .then((resp) => {
        if (resp.ok) {
          navigate(`${RoutingS.ROUTES.LOGIN}?alert=${AlertS.AlertType.PASSWORD_CHANGED}`);
        }
      })
      .catch(() => setAlert(AlertS.AlertType.SERVER_UNAVAILABLE))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    document.title = 'Passwort zurücksetzen';
    if (token) {
      setLoading(true);
      AuthS.verifyToken(AuthS.TOKEN_TYPE.RESET_PASSWORD, token)
        .then((resp) => {
          if (!resp.ok) {
            navigate(`${RoutingS.ROUTES.PASSWORD_FORGOTTEN}?alert=linkexpired`);
          }
        })
        .catch(() => setAlert(AlertS.AlertType.SERVER_UNAVAILABLE))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      shadow="xs"
      p={40}
      sx={{
        maxWidth: 480,
        borderRadius: 10,
        position: 'relative',
        backgroundClip: 'padding-box, border-box',
        backgroundImage: 'linear-gradient(#fff,#fff),linear-gradient(88deg,#ffba5e,#ff6b69)',
        borderTop: '10px double transparent',
      }}
    >
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      <Space h="md" />
      <Text size={isPhoneDevice ? 22 : 28} sx={{ lineHeight: 1.2 }}>
        Lege ein neues Passwort fest.
      </Text>
      <Text color="dimmed" mt={10} size={isPhoneDevice ? 'sm' : 'md'}>
        Danach kannst Du Dich direkt einloggen und loslegen.
      </Text>
      {alert && <AlertBox alert={alert} />}
      <Space h="xl" />
      <form onSubmit={form.onSubmit(resetPassword)}>
        <PasswordInput
          label={<Text size="xs">Passwort</Text>}
          icon={<IconLock size={16} />}
          {...form.getInputProps('password')}
        />
        <Space h="xs" />
        <PasswordChecker
          password={form.values.password}
          showErrors={Object.keys(form.errors).length > 0}
          setValidPassword={setValidPassword}
        />
        <Space h="xs" />
        <PasswordInput
          label={<Text size="xs">Passwort wiederholen</Text>}
          icon={<IconLock size={16} />}
          {...form.getInputProps('repeatedPassword')}
        />
        <Space h="xl" />
        <Button fullWidth type="submit">
          Passwort festlegen
        </Button>
      </form>
    </Paper>
  );
};

export default PasswordResetPage;
