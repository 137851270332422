import { Skeleton, ThemeIcon } from '@mantine/core';
import { IconBallpen, IconCheck } from '@tabler/icons';
import { FC, useEffect, useState } from 'react';
import Moment from 'react-moment';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { fetchContactById } from '../../../Service/restapi/contactService';
import { DocumentS } from '../../../Templates/Documents/DocumentS';
import { BookitupContract } from '../../../Types/BookitupDocument';
import Contact from '../../../Types/Contact';
import DocumentPortalStatus from '../DocumentPortalStatus';
import ContractItemActions from './ContractItemActions';

interface ContractItemInterface {
  contract: BookitupContract;
  reload: () => void;
  showNumber: boolean;
  showDatum: boolean;
  showName: boolean;
  showLabel: boolean;
  showPortal: boolean;
  narrowPortalStatus: boolean;
}

const ContractItem: FC<ContractItemInterface> = ({
  contract: initialContract,
  reload,
  showDatum,
  showName,
  showLabel,
  showPortal,
  showNumber,
  narrowPortalStatus,
}) => {
  const navigate: NavigateFunction = useNavigate();
  const { id: eventIdParam } = useParams();
  const [customer, setCustomer] = useState<Contact>();
  const [loading, setLoading] = useState<boolean>(true);
  const [contract, setContract] = useState<BookitupContract>(initialContract);
  const { id, customerId, date, number, label, draft } = contract;
  const contractDate = new Date(date);
  const eventId = eventIdParam ?? contract.eventId;

  useEffect(() => {
    if (customerId) {
      fetchContactById(customerId.toString())
        .then((contact: any) => setCustomer(contact))
        .finally(() => setLoading(false));
    }
  }, [customerId]);

  const setDraft = (_draft: boolean) => {
    setContract((prevState) => ({
      ...prevState,
      draft: _draft,
    }));
  };

  const patchContract = (patch: Partial<BookitupContract>) => {
    DocumentS.patchContract(id, patch).then((patchedContract) => {
      if (patchedContract) {
        setContract(patchedContract);
      }
    });
  };

  // eslint-disable-next-line no-underscore-dangle
  const _onClick = () => {
    const targetPath = `/events/${eventId}/contracts/${id}${draft ? '' : '/preview'}`;
    navigate(targetPath);
  };

  return (
    <tr>
      <td>
        <ThemeIcon radius="xl" size="sm" color={draft ? 'gray' : 'blue'}>
          {draft ? <IconBallpen size={14} /> : <IconCheck size={14} />}
        </ThemeIcon>
      </td>
      {showNumber && (
        <td onClick={_onClick} style={{ textAlign: 'center' }}>
          {number}
        </td>
      )}
      {showLabel && (
        <td onClick={_onClick} style={{cursor: 'pointer'}}>
          {loading ? <Skeleton width="100%" height={30}/> : (contract.label ? contract.label : "Vertrag")}
        </td>
      )}
      {showName && (
        <td onClick={_onClick} style={{ cursor: 'pointer' }}>
          {loading ? <Skeleton width="100%" height={30} /> : customer?.personDescription}
        </td>
      )}
      {showDatum && (
        <td onClick={_onClick} style={{ cursor: 'pointer' }}>
          <Moment date={contractDate} format="DD.MM" />
        </td>
      )}
      {showPortal && (
        <td>
          <DocumentPortalStatus
            setDraft={setDraft}
            entityType="contracts"
            entityId={id.toString()}
            shortVersion={narrowPortalStatus}
          />
        </td>
      )}
      <td style={{position: 'relative'}}>
        <div style={{position: 'absolute', right: 0, bottom: 5}}>
          <ContractItemActions
            contract={contract}
            initialLabel={label || 'Verträge'}
            handleChange={setContract}
            reload={reload}
            patchContract={patchContract}
          />
        </div>
      </td>
    </tr>
  );
};

export default ContractItem;
