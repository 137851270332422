import { notificationCountPattern } from '../Utils/utils';

const UNPROTECTED_ROUTES: string[] = [
  '/verify-email',
  '/login',
  '/register',
  '/registerSuccess',
  '/reset-password',
  '/setpassword',
  '/confirm-invitation',
  '/login-new',
  '/registration-completed',
  '/password-forgotten',
];

const ROUTES = {
  REGISTER: '/register',
  LOGIN: '/login',
  REGISTRATION_COMPLETED: '/registration-completed',
  PASSWORD_FORGOTTEN: '/password-forgotten',
  HOMEPAGE: '/',
  PLANS: '/settings/plans',
  HELP_CENTER: 'https://bookitup.de/hilfe',
  NEW_CONTACT_FORM: '/settings/contact-form/create-new',
  FORMS_LIST_PAGE: '/settings/contact-form',
  RESET_PASSWORD: '/reset-password',
};

const isUnprotectedRoute = (routePath: string): boolean => UNPROTECTED_ROUTES.includes(routePath);

const openInNewTab = (link: string) => window.open(link, '_blank', 'noopener,noreferrer');

const changeTitle = (newTitle: string) => {
  const { title } = document;
  if (notificationCountPattern.test(title)) {
    // Preserve notifications count if exists
    const notificationCount = title.split(' ')[0];
    document.title = `${notificationCount} ${newTitle}`;
  } else {
    document.title = newTitle;
  }
};

const isResourcePage = (pathname: string) => pathname.includes('resources');

// eslint-disable-next-line import/prefer-default-export
export const RoutingS = {
  ROUTES,
  isUnprotectedRoute,
  openInNewTab,
  changeTitle,
  isResourcePage,
};
