// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Text } from '@mantine/core';
import { FC } from 'react';

interface Props {
  name: string;
  value?: string | number;
}

const FieldValueRow: FC<Props> = ({ name, value }) => (
  <Group position="apart">
    <Text>{name}:</Text>
    <Text weight="bold"> {value}</Text>
  </Group>
);

export default FieldValueRow;
