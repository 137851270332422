// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import {AppShell, Navbar, SimpleGrid} from '@mantine/core';
import {useContext, useEffect, useState} from 'react';
import {WindowContext} from '../../Service/Context/WindowContext';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import PageHeader from '../Navigation/PageHeader';
import {RoutingS} from '../../Service/RoutingS';
import EventKindStatistic from "../../Organismns/Chart/EventKindStatistic";
import ExpectedRevenueStatistic from "../../Organismns/Chart/ExpectedRevenueStatistic";
import DocumentPaymentsStatistic from "../../Organismns/Chart/DocumentPaymentsStatistic";
import StatisticsNavBar from "./StatisticsNavBar";
import BookingStateStatistic from "../../Organismns/Chart/BookingStateStatistic";
import LeadOriginStatistic from "../../Organismns/Chart/LeadOriginStatistic";
import {LicenceS} from "../../Service/LicenceS";
import {useJwtClaims} from "../../Service/Context/AuthContext";
import {openLicenceModal} from "../../Service/Context/ViolationContext";
import {ProfileContext} from "../../Service/Context/ProfileContext";

const Statistics = () => {
  const { sm } = useContext(WindowContext);
  const [navBarOpen, setNavBarOpen] = useState<boolean>(false);
  const [year, setYear] = useState<string | null>(new Date().getFullYear().toString());
  const [eventKind, setEventKind] = useState<string | null>(null);
  const [leadOrigin, setLeadOrigin] = useState<string | null>(null);
  const { licence } = useJwtClaims();
  const [hasProperLicence, setHasProperLicence] = useState<boolean>(false);
  const { profile } = useContext(ProfileContext);

  useEffect(() => {
    RoutingS.changeTitle('Statistiken');
    // show statistics for user, who was unsatisfied because statistics were moved from free to paid licence
    if ((profile && profile.id === 20222) || LicenceS.hasSuitableLicence(LicenceS.Restrictions.STATISTICS.requiredLicence, licence) || (LicenceS.inTrialPeriod(licence))) {
      setHasProperLicence(true);
    } else {
      openLicenceModal(LicenceS.Restrictions.STATISTICS)
    }
  }, []);

  return (
    <AppShell
      header={<PageHeader title="Statistiken" navBarOpen={navBarOpen} setNavBarOpen={setNavBarOpen} />}
      navbar={
        <div>
          {sm ? (
            <Navbar hiddenBreakpoint="sm" hidden={!navBarOpen} width={{ sm: 220, md: 250 }}>
              <StatisticsNavBar year={year} setYear={setYear} eventKind={eventKind} setEventKind={setEventKind} leadOrigin={leadOrigin} setLeadOrigin={setLeadOrigin}/>
            </Navbar>
          ) : (
            <DoubleNavbar>
              <StatisticsNavBar year={year} setYear={setYear} eventKind={eventKind} setEventKind={setEventKind} leadOrigin={leadOrigin} setLeadOrigin={setLeadOrigin} />
            </DoubleNavbar>
          )}
        </div>
      }
    >
      <>
        <SimpleGrid
          cols={2}
          breakpoints={[
            { maxWidth: 'xl', cols: 2 },
            { maxWidth: 'md', cols: 1 },
            { maxWidth: 'sm', cols: 1 },
            { maxWidth: 'xs', cols: 1 },
          ]}
        >
          <div style={{maxWidth: 800, margin: 10}}>
              <ExpectedRevenueStatistic year={year} leadOrigin={leadOrigin} eventKind={eventKind} hasProperLicence={hasProperLicence} />
          </div>
          <div style={{maxWidth: 800, margin: 10}}>
              <DocumentPaymentsStatistic year={year} leadOrigin={leadOrigin} eventKind={eventKind} hasProperLicence={hasProperLicence} />
          </div>

        </SimpleGrid>
        <SimpleGrid
          cols={4}
          breakpoints={[
            { maxWidth: 'xl', cols: 4 },
            { maxWidth: 'md', cols: 2 },
            { maxWidth: 'sm', cols: 2 },
            { maxWidth: 'xs', cols: 1 },
          ]}
        >
          <div style={{maxWidth: 300, margin: 10}}>
            <EventKindStatistic year={year} leadOrigin={leadOrigin} hasProperLicence={hasProperLicence} />
          </div>
          <div style={{maxWidth: 300, margin: 10}}>
            <BookingStateStatistic year={year} leadOrigin={leadOrigin} eventKind={eventKind} hasProperLicence={hasProperLicence} />
          </div>
          <div style={{maxWidth: 300, margin: 10}}>
            <LeadOriginStatistic year={year} eventKind={eventKind} hasProperLicence={hasProperLicence} />
          </div>

        </SimpleGrid>
      </>

    </AppShell>
  );
};

export default Statistics;
