import { useContext } from 'react';
import { EmailBoxContext } from '../../Service/Context/EmailBoxContext';
import EmailsListHeader from './EmailsListHeader';
import SkeletonItems, { EmptyListSkeleton } from '../../Atoms/SkeletonItems';
import { Box, Flex, Paper, Space } from '@mantine/core';
import EmailRowPhone from './EmailRowPhone';
import PaginationNav from '../../Molecules/Navigation/PaginationNav';
import EmailMasterDetail from '../../Templates/Emails/EmailMasterDetail';
import NewEmail from './NewEmail';
import { useElementSize } from '@mantine/hooks';
import ChatMessage from '../../Molecules/Message/ChatMessage';
import ChatMessagePreview from './ChatMessagePreview';

const EmailsSection = () => {
  const { ref, width } = useElementSize();
  const { loading, messages, pageNumber, pageCount, totalCount, setPageNumber, selectedMessage } =
    useContext(EmailBoxContext);
  const emptyBox = messages.length === 0;

  return (
    <div ref={ref}>
      {width > 700 ? (
        <DesktopSection />
      ) : (
        <>
          <EmailsListHeader phoneVersion />
          <Space h="sm" />
          {!selectedMessage && (
            <>
              {loading && <SkeletonItems count={8} height={80} />}
              <Paper>{!loading && messages.map((email) => <EmailRowPhone email={email} />)}</Paper>
              {emptyBox && <EmptyListSkeleton />}
            </>
          )}
          {selectedMessage && (
            <Paper radius="md">
              <ChatMessage phoneVersion />
            </Paper>
          )}
          {!selectedMessage && !emptyBox && (
            <PaginationNav
              pageSize={10}
              pageNumber={pageNumber}
              totalCount={totalCount}
              totalPages={pageCount}
              onChange={(newPageNumber) => setPageNumber(newPageNumber - 1)}
              entityName="Nachricht"
            />
          )}
        </>
      )}
    </div>
  );
};

const DesktopSection = () => (
  <Flex gap="xs" sx={{ alignItems: 'flex-start' }}>
    <Box
      sx={(theme) => ({
        backgroundColor: 'white',
        minWidth: 300,
        maxWidth: 325,
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: 10,
      })}
    >
      <EmailMasterDetail />
    </Box>
    <Box
      sx={(theme) => ({
        backgroundColor: 'white',
        border: `1px solid ${theme.colors.gray[1]}`,
        borderRadius: 10,
        flexGrow: 1,
        minHeight: 500,
      })}
      pos="relative"
    >
      <NewEmail />
      <ChatMessagePreview />
    </Box>
  </Flex>
);

export default EmailsSection;
