import React, { useContext, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
import { ajaxActions } from '../AjaxActions';
import { AuthS } from '../restapi/authService';
import { useJwtClaims } from './AuthContext';

export const ApplicationUserContext = React.createContext(undefined);

export const ApplicationUserContextProvider = () => {
  const jwtClaims = useJwtClaims();
  const { email } = jwtClaims;
  const [applicationUser, setApplicationUser] = useState();

  useEffect(() => {
    if (email) {
      AuthS.getOwnAppUser().then((appUser) => {
        if (appUser) {
          setApplicationUser(appUser);
        }
      });
    }
  }, [email]);

  useEffect(() => {
    if (applicationUser && mixpanel.people) {
      const { origin, webSessions } = applicationUser;
      if (origin && webSessions) {
        mixpanel.people.set({ origin, webSessions });
      }
    }
  }, [applicationUser]);

  return (
    <ApplicationUserContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        applicationUser,
        handleApplicationUserChange: (patch) => {
          setApplicationUser((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
        savePatch: (patch) =>
          ajaxActions
            .patch(`${process.env.REACT_APP_AUTH_SERVICE_URL}/applicationUsers/own`, patch)
            .then((resp) => {
              if (resp.ok) {
                setApplicationUser((prevState) => ({
                  ...prevState,
                  ...patch,
                }));
              }
              return resp;
            })
            .catch((resp) => {
              throw resp;
            }),
      }}
    >
      <Outlet />
    </ApplicationUserContext.Provider>
  );
};

export const useAppUser = () => {
  const { applicationUser } = useContext(ApplicationUserContext);
  return applicationUser ?? {};
};
