import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_DOCUMENT_SERVICE_URL;

export const fetchTextBlockById = (textBlockId) =>
  ajaxActions.get(`${BASE_URL}/textBlocks/${textBlockId}`).then((resp) => resp);

export const deleteTextBlockFromContract = (textBlockId, contractId) =>
  ajaxActions.del(`${BASE_URL}/textBlocks/${textBlockId}/contract/${contractId}`).then((resp) => resp);

export const deleteTextBlockFromContractTemplate = (textBlockId, contractTemplateName) =>
  ajaxActions
    .del(`${BASE_URL}/textBlocks/${textBlockId}/contractTemplate/${contractTemplateName}`)
    .then((resp) => resp);

export const patchTextBlockById = (textBlockId, patch) =>
  ajaxActions.patch(`${BASE_URL}/textBlocks/${textBlockId}`, patch).then((resp) => resp);

export const insertPositionsAfterTextBlock = (textBlockId, contractId, documentId) =>
  ajaxActions
    .put(`${BASE_URL}/textBlocks/${textBlockId}/contract/${contractId}/insertPositions/${documentId}`)
    .then((resp) => resp);

export const insertPositionsAfterTextBlockInTemplate = (textBlockId, templateName) =>
  ajaxActions
    .put(`${BASE_URL}/textBlocks/${textBlockId}/contractTemplate/${templateName}/insertPositions`)
    .then((resp) => resp);
