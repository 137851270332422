// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Loader, Text, Textarea } from '@mantine/core';
import { i18n } from '@lingui/core';
import { closeAllModals } from '@mantine/modals';
import { FC, useState } from 'react';
import { BookingState } from '../../Types/RecommendationT';
import { RecommendationS } from '../../Service/RecommendationS';

interface Props {
  newBookingState: BookingState;
  recommendationId: string;
  onFinish: () => void;
}

const ChangeRecommendedEventStateModal: FC<Props> = ({ newBookingState, recommendationId, onFinish }) => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const clickHandler = () => {
    setLoading(true);
    RecommendationS.editAndInform(recommendationId, newBookingState, message, true).finally(() => {
      setLoading(false);
      onFinish();
      closeAllModals();
    });
  };

  return (
    <>
      <Text>
        Der Dienstleister der Dich für dieses Event vermittelt hat wird automatisch per E-Mail über die Status-Änderung
        informiert.
      </Text>
      <Textarea
        maxRows={10}
        variant="filled"
        mt="xs"
        placeholder="Optionale Nachricht an den Dienstleister"
        value={message}
        onChange={(e) => setMessage(e.currentTarget.value)}
      />
      <Button
        fullWidth
        mt="xl"
        mb="xs"
        disabled={loading}
        onClick={clickHandler}
        leftIcon={loading && <Loader size="xs" />}
      >
        {i18n._('recommendation.bookingState.changed')}
      </Button>
      <Button disabled={loading} fullWidth color="gray" variant="subtle" onClick={() => closeAllModals()}>
        {i18n._('actions.cancel')}
      </Button>
    </>
  );
};

export default ChangeRecommendedEventStateModal;
