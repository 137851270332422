import { IconChevronDown, IconChevronUp } from '@tabler/icons';
import { FC } from 'react';

const SortDirectionIcon: FC<{ direction: 'DESC' | 'ASC' }> = ({ direction }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>
    {direction === 'ASC' ? (
      <IconChevronUp size={16} style={{ paddingBottom: 2 }} />
    ) : (
      <IconChevronDown size={16} style={{ paddingBottom: 2 }} />
    )}
  </>
);

export default SortDirectionIcon;
