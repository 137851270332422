import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';

interface WindowContextInterface {
  windowWidth: number;
  windowHeight: number;
  sm: boolean;
  md: boolean;
  xs: boolean;
  lg: boolean;
}

export const WindowContext = React.createContext({} as WindowContextInterface);

export const WindowContextProvider: React.FC<{ children: React.ReactNode }> = React.memo(
  ({ children }) => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);
    const xs = useMediaQuery('(max-width: 500px)');
    const sm = useMediaQuery('(max-width: 768px)');
    const md = useMediaQuery('(max-width: 992px)');
    const lg = useMediaQuery('(min-width: 1264px)');

    useEffect(() => {
      window.addEventListener('resize', updateDimensions);
      return () => {
        window.removeEventListener('resize', updateDimensions);
      };
    });

    function updateDimensions() {
      setWindowHeight(window.innerHeight);
      setWindowWidth(window.innerWidth);
    }

    return (
      <WindowContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          windowWidth,
          windowHeight,
          sm,
          md,
          xs,
          lg,
        }}
      >
        {children}
      </WindowContext.Provider>
    );
  },
  () => true,
);
