/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Center, Loader, Text, TextInput } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { IconTemplate } from '@tabler/icons';
import { i18n } from '@lingui/core';
import { useNavigate } from 'react-router-dom';
import { closeAllModals, openModal } from '@mantine/modals';
import { MessageTemplateDto } from '../../../Types/MessageT';
import { MessageS } from '../../../Service/MessageS';

interface CopyTemplateProps {
  origin: MessageTemplateDto;
  existingNames: string[] | null;
}

// eslint-disable-next-line import/prefer-default-export
export const openCopyTemplateModal = (props: CopyTemplateProps) =>
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        E-Mail-Vorlage duplizieren
      </Text>
    ),
    children: <CopyTemplateModal {...props} />,
  });

const CopyTemplateModal: FC<CopyTemplateProps> = ({ origin, existingNames: initialExistingNames }) => {
  const { id: templateId, name } = origin;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [existingNames, setExistingNames] = useState<string[] | null>(initialExistingNames);
  const [copyName, setCopyName] = useState(`${name} Kopie`);
  const isNameTaken = existingNames !== null && existingNames.includes(copyName);

  useEffect(() => {
    if (existingNames === null) {
      MessageS.fetchTemplatesNames().then(setExistingNames);
    }
  }, [existingNames]);

  const copyHandler = async () => {
    setLoading(true);
    const copyJSON = await MessageS.copyTemplate(templateId, copyName);
    setLoading(false);
    if (copyJSON) {
      closeAllModals();
      navigate(`/settings/template/message/custom/${copyName}`);
    }
  };

  return (
    <>
      <TextInput
        label="Name der Vorlage"
        value={copyName}
        onChange={(e) => setCopyName(e.currentTarget.value)}
        icon={<IconTemplate size={20} />}
        error={isNameTaken && 'Dieser Name ist bereits vergeben.'}
      />
      <Center mt={40}>
        <Button
          disabled={loading || isNameTaken}
          onClick={copyHandler}
          rightIcon={loading && <Loader size="xs" />}
          sx={{ minWidth: 230 }}
        >
          {i18n._('actions.save')}
        </Button>
      </Center>
    </>
  );
};
