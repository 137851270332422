import { AppShell, Navbar, ScrollArea } from '@mantine/core';
import { useContext, useState, useEffect } from 'react';
import { i18n } from '@lingui/core';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import { WindowContext } from '../../Service/Context/WindowContext';
import PageHeader from '../Navigation/PageHeader';
import { ContactsContextProvider } from '../../Service/Context/ContactsContext';
import ContactsHeadActions from './ContactsHeadActions';
import ContactsPageNavBar from './ContactsPageNavbar';
import ContactsTable from './ContactsTable';

const ContactsPage = () => {
  const { sm } = useContext(WindowContext);
  const [navBarOpen, setNavBarOpen] = useState<boolean>(false);

  useEffect(() => {
    document.title = i18n._('contacts');
  }, []);

  return (
    <ContactsContextProvider>
      <AppShell
        header={
          <PageHeader
            title={i18n._('contacts')}
            navBarOpen={navBarOpen}
            setNavBarOpen={setNavBarOpen}
            rightSection={<ContactsHeadActions />}
          />
        }
        navbar={
          <div>
            {sm ? (
              <Navbar hiddenBreakpoint="sm" hidden={!navBarOpen} width={{ sm: 220, md: 250 }}>
                <ScrollArea>
                  <ContactsPageNavBar />
                </ScrollArea>
              </Navbar>
            ) : (
              <DoubleNavbar>
                <ContactsPageNavBar />
              </DoubleNavbar>
            )}
          </div>
        }
      >
        <ContactsTable />
      </AppShell>
    </ContactsContextProvider>
  );
};

export default ContactsPage;
