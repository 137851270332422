import React, { useContext, useEffect, useState } from 'react';
import { fetchFile } from '../../Service/restapi/fileService';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { LoadingAnimation } from '../../Service/ResourceAdapter';

export const PlaceholderImage = ({ imageName, onClick, ...rest }) => {
  const { profile } = useContext(ProfileContext);
  const [data, setData] = useState(undefined);

  useEffect(() => {
    fetchFile('public', profile.id, imageName).then((resp) => {
      if (resp.ok) {
        resp.blob().then((blob) => {
          setData(window.URL.createObjectURL(blob));
        });
      }
    });
  }, []);

  if (!data) {
    return <LoadingAnimation />;
  }

  const styles = {
    image: {
      width: 100,
      cursor: 'pointer',
      marginBottom: 5,
    },
  };

  return (
    <img
      style={styles.image}
      onClick={() => {
        onClick(imageName);
      }}
      src={data}
      {...rest}
    />
  );
};
