import { Trans } from '@lingui/react';
import { useModals } from '@mantine/modals';
import { FC, useContext } from 'react';
import { CustomModalHeader } from '../../Molecules/Dialogs';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { TaskListContext } from '../../Service/Context/TaskListContext';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import { LicenceS } from '../../Service/LicenceS';
import CheckLicenceActionIcon from '../Upgrade/CheckLicenceActionIcon';

const AddTaskIcon: FC = () => {
  const { openContextModal, closeAll } = useModals();
  const { refresh, taskListContent } = useContext(TaskListContext);
  const { profile } = useContext<any>(ProfileContext);
  const { executeWithLicenceCheck } = useContext(ViolationContext);

  return (
    <CheckLicenceActionIcon
      size={38}
      radius="xl"
      variant="filled"
      color="blue"
      alternativeCondition={taskListContent.agendaItems.length < 2}
      onClick={() =>
        executeWithLicenceCheck(LicenceS.Restrictions.SAVE_TASK, () => {
          openContextModal('taskCreateModal', {
            title: (
              <CustomModalHeader>
                <Trans id="task.create" />
              </CustomModalHeader>
            ),
            innerProps: {
              profile,
              entityType: 'events',
              finish: () => {
                closeAll();
                refresh();
              },
              showEntityTypeSelection: true,
              centered: true,
            },
          });
        })
      }
      icon="PLUS"
      licence="PRO"
      upgradeTitle="Aufgaben & Erinnerungen"
      upgradeText="Mit unserem Aufgaben-System kannst Du zum Einen selbst Aufgaben anlegen, so wie Du es kennst - zum Anderen legt bookitup für Dich aber auch Aufgaben automatisiert an und erinnert Dich damit an wichtige Handlungen, die erfahrungsgemäß zu einer höheren Buchungsquote führen."
    />
  );
};

export default AddTaskIcon;
