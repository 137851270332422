/* eslint-disable react/jsx-no-useless-fragment */
import { FC, useEffect, useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Center, Skeleton, ThemeIcon, Tooltip } from '@mantine/core';
import {
  IconCheck,
  IconChevronDown,
  IconChevronDownLeft,
  IconChevronRight,
  IconX,
} from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { i18n } from '@lingui/core';
import { useDebouncedCallback } from 'use-debounce';
import { Planning, ResourceType, Shortage, TableResponsiveProps } from '../../../../Types/ResourceT';
import IncDecCounter from '../../../../Atoms/inputs/IncDecCounter';
import ResourcePlanningS from '../../../../Service/restapi/resourcePlanningS';
import { openDeleteResourcePlanning } from '../../../../Templates/Resource/EventPlanningsModals';
import PlanningActions from './PlanningActions';

interface Props extends TableResponsiveProps {
  planning: Planning;
  depth?: number;
  refresh: () => unknown;
  showPlanningIcon?: boolean;
  parentType?: ResourceType;
}

const PlanningTableRow: FC<Props> = ({
  planning: initialPlanning,
  depth = 0,
  refresh,
  showCategory = true,
  showType = true,
  showCounter = true,
  showPlanningIcon = true,
  parentType,
}) => {
  const [planning, setPlanning] = useState(initialPlanning);
  const { id: planningId, resource, plannedQuantity, children } = planning;
  const { id, type, name, content, category } = resource;
  const [opened, { toggle }] = useDisclosure(true);
  const navigate = useNavigate();
  const isItem = type === 'ITEM';

  const updateQuantity = useDebouncedCallback((quantity: number) => {
    ResourcePlanningS.updatePlanning(planningId, { plannedQuantity: quantity }).then((resJSON) => {
      if (resJSON) {
        setPlanning(resJSON);
        refresh();
      }
    });
  }, 1000);

  const onClick = () => navigate(`/resources/${id}/details`);

  const removePlanning = () => {
    const onConfirm = async () => {
      const deleted = await ResourcePlanningS.deletePlanning(planningId);
      if (deleted) {
        refresh?.();
      }
    };
    openDeleteResourcePlanning(name, onConfirm);
  };

  const mapSubRowUI = (_planning: Planning) => (
    <PlanningTableRow
      key={_planning.id}
      planning={_planning}
      depth={depth + 1}
      showCategory={showCategory}
      showType={showType}
      showCounter={showCounter}
      showPlanningIcon={showPlanningIcon && type !== 'CASE'}
      refresh={refresh}
      parentType={type}
    />
  );

  return (
    <>
      <tr>
        <td style={{ paddingLeft: depth * 10 }}>
          {!isItem && content.length > 0 && (
            <>
              {opened && <IconChevronDown size={20} onClick={toggle} style={{ cursor: 'pointer' }} />}
              {!opened && <IconChevronRight size={20} onClick={toggle} style={{ cursor: 'pointer' }} />}
            </>
          )}
          {isItem && depth > 0 && <IconChevronDownLeft size={20} />}
        </td>
        <td>
          {showPlanningIcon && (
            <Center>
              <PlanningStatusIcon planning={planning} />
            </Center>
          )}
        </td>
        <td className="overflow-ellipsis" onClick={onClick} style={{ cursor: 'pointer' }}>
          {name}
        </td>
        {showCategory && (
          <td onClick={onClick} style={{ cursor: 'pointer', maxWidth: 100 }} className="overflow-ellipsis">
            {category?.name}
          </td>
        )}
        {showType && (
          <td onClick={onClick} style={{ cursor: 'pointer' }}>
            {i18n._(`resource.type.${type}`)}
          </td>
        )}
        {showCounter && (
          <td align="center">
            {depth === 0 && (
              <IncDecCounter initialCount={plannedQuantity} onChanged={updateQuantity} onLastRemoved={removePlanning} />
            )}
            {depth > 0 && initialPlanning.plannedQuantity}
          </td>
        )}
        <td style={{ position: 'relative' }}>
          {(depth === 0 || (showPlanningIcon && type !== 'CASE')) && (
            <Center>
              <div style={{ position: 'absolute', right: 0 }}>
                <PlanningActions planning={planning} refresh={refresh} removePlanning={removePlanning} />
              </div>
            </Center>
          )}
        </td>
      </tr>
      {children && children.length > 0 && opened && children.map(mapSubRowUI)}
    </>
  );
};

const PlanningStatusIcon: FC<{ planning: Planning }> = ({ planning }) => {
  const { planningGroup, warehousePlanning, resource } = planning;
  const { entityId, entityType, begin, end } = planningGroup ?? {};
  const { id: resourceId } = resource;
  const [shortages, setShortages] = useState<Shortage[]>([]);
  const [loading, setLoading] = useState(false);

  const fetchShortages = () => {
    setLoading(true);
    ResourcePlanningS.getShortages(resourceId, begin, end, entityType, entityId)
      .then(setShortages)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchShortages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planning]);

  if (loading) {
    return <Skeleton circle height={20} />;
  }

  if (warehousePlanning) {
    const shortage = shortages.reduce(
      (accumulator, currentShortage) =>
        accumulator ||
        (currentShortage.warehouseShortage < 0 && currentShortage.resourceType !== ResourceType.KIT) ||
        currentShortage.showWarehousePlanningShortage,
      false,
    );

    return (
      <Tooltip
        label={
          shortage
            ? 'Resource ist bestätigt, aber es gibt einen Mangel.'
            : 'Resource ist eingeplant und ausreichend verfügbar'
        }
        position="bottom-start"
        transition="slide-down"
      >
        <div>
          {shortage ? (
              <ThemeIcon radius="xl" size="sm" color="#FF5C5A">
                <IconX size={14} />
              </ThemeIcon>
          ) : (
            <ThemeIcon radius="xl" size="sm" color="green">
              <IconCheck size={14} />
            </ThemeIcon>
          )}
        </div>
      </Tooltip>
    );
  }

  const shortage = shortages.reduce(
    (accumulator, currentShortage) =>
      accumulator ||
      (currentShortage.shortage < 0 && currentShortage.resourceType !== ResourceType.KIT) ||
      currentShortage.showResourcePlanningShortage,
    false,
  );

  return (
    <Tooltip
      label={
        shortage
          ? 'Resource ist nicht ausreichend verfügbar.'
          : 'Resource ist verfügbar und muss noch bestätigt werden.'
      }
      position="bottom-start"
      transition="slide-down"
    >
      <ThemeIcon radius="xl" size="sm" color={shortage ? '#FFD600' : 'gray'}>
        {shortage && <IconX color={"#4b525b"} size={14}/>}
        {!shortage && <IconCheck color={"black"} size={14}/>}
      </ThemeIcon>
    </Tooltip>
  );
};

export default PlanningTableRow;
