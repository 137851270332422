// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Button, Center, Text } from '@mantine/core';
import { closeAllModals, openModal } from '@mantine/modals';
import { TokenResponse, useGoogleLogin } from '@react-oauth/google';
import { IconBrandGoogle } from '@tabler/icons';
import { FC } from 'react';
import { GoogleS } from '../../../Service/GoogleS';
import { ToastS } from '../../../Service/ToastS';

// eslint-disable-next-line import/prefer-default-export
export const openGoogleOAuthModal = (onSuccess: (tokenResponse: Partial<TokenResponse>) => void) => {
  openModal({
    title: (
      <Text weight="bolder" size="xl">
        Google Pop-Up-Fenster
      </Text>
    ),
    closeOnEscape: true,
    transition: 'slide-down',
    children: <GoogleOAuthModal onSuccess={onSuccess} />,
  });
};

const GoogleOAuthModal: FC<{ onSuccess: (tokenResponse: Partial<TokenResponse>) => void }> = ({ onSuccess }) => {
  const sync = useGoogleLogin({
    onSuccess,
    onError: ToastS.generalError,
    flow: 'auth-code',
    scope: GoogleS.SCOPES,
  });

  return (
    <>
      <Text>
        Im nächsten Schritt wird ein Pop-Up-Fenster von Google erscheinen. Falls nicht, prüfe bitte, ob Dein Browser
        dieses Fenster blockiert.
      </Text>
      <Center mt="xl">
        <Button
          leftIcon={<IconBrandGoogle size={16} />}
          onClick={() => {
            sync();
            closeAllModals();
          }}
        >
          Jetzt synchronisieren
        </Button>
      </Center>
    </>
  );
};
