import { FormSubmission, PortalShare } from '../Types/ShareT';
import { ajaxActions } from './AjaxActions';
import { ToastS } from './ToastS';

const BASE_URL = process.env.REACT_APP_SHARE_SERVICE_URL;

const patchFormSubmission = async (id: string, patch: Partial<FormSubmission>): Promise<FormSubmission | null> => {
  const resp = await ajaxActions.patch(`${BASE_URL}/formSubmissions/${id}`, patch);
  if (resp.ok) {
    return resp.json();
  }
  ToastS.generalError();
  return null;
};

const changeResourceIndex = async (shareId: string, oldIndex: number, newIndex: number) => {
  const resp = await ajaxActions.patch(
    `${BASE_URL}/shares/${shareId}/resources?fromIndex=${oldIndex}&newIndex=${newIndex}`,
    {},
  );
  if (resp.ok) {
    return resp.json();
  }
  ToastS.generalError();
  return null;
};

const getPortalLink = (share: PortalShare): string =>
  `${process.env.REACT_APP_PORTAL_SERVICE_URL}/login?shareId=${share.id}&key=${share.password}`;

// eslint-disable-next-line import/prefer-default-export
export const ShareS = {
  patchFormSubmission,
  changeResourceIndex,
  getPortalLink,
};
