// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Space, Stack, Text } from '@mantine/core';
import { i18n } from '@lingui/core';
import { FC } from 'react';
import DocumentHeadActions from '../../Templates/Documents/DocumentHeadActions';
import { BookitupDocumentType } from '../../Types/BookitupDocument';

interface Props {
  emptyDocuments: boolean;
  documentType?: BookitupDocumentType;
}

const DocumentsNotFound: FC<Props> = ({ emptyDocuments, documentType }) => {
  const message = documentType
    ? i18n._(`documents.empty.${documentType.toLowerCase()}`)
    : 'Du hast noch keine Dokumente.';

  return (
    <Stack>
      <Box sx={(theme) => ({ width: '100%', height: 50, backgroundColor: theme.colors.gray[0], borderRadius: 10 })} />
      <Box sx={(theme) => ({ width: '100%', height: 100, backgroundColor: theme.colors.gray[0], borderRadius: 10 })} />
      {emptyDocuments ? (
        <Text sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap' }}>
          {message}
          {documentType !== 'CONTRACT' && documentType !== 'QUESTIONNAIRE' && (
            <>
              {` Klicke auf`}
              <Space w={5} />
              <DocumentHeadActions />
              <Space w={2} />, um zu beginnen.
            </>
          )}
        </Text>
      ) : (
        <Text align="center">
          Die Kombination der ausgewählten <b>Filter</b> erzielt keine Ergebnisse.
        </Text>
      )}
    </Stack>
  );
};

export default DocumentsNotFound;
