// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { DeleteTasksConfigDTO, ProcedureReportProps } from '../../../../Types/LogT';

const DeleteTasksReport: FC<ProcedureReportProps> = ({ report, config }) => {
  const { removeOnlyUnfinished } = config as DeleteTasksConfigDTO;

  return <Text>Alle {removeOnlyUnfinished ? 'nicht erledigten' : ''} Aufgaben wurden gelöscht.</Text>;
};

export default DeleteTasksReport;
