import { CustomAttributeS } from '../../../../Service/Context/CustomAttributeS';
import { EventS } from '../../../../Service/EventS';
import { CustomAttributeDTO } from '../../../../Types/CustomAttributeT';

// Registry.register('GdprNotice', GdprNotice);

const customItems = [
  {
    group_name: 'Formular',
    key: 'GdprNotice',
    element: 'GDPR',
    field_name: 'gdpr_notice',
    name: 'Datenschutz',
    icon: 'fa fa-shield',
    label: 'Datenschutz',
    validationMessageOverride: 'Bitte akzeptiere den Datenschutz!',
    required: true,
    gdprLink: '',
  },
  {
    group_name: 'Formular',
    key: 'Recaptcha',
    field_name: 'recaptcha',
    sitekey: '',
  },
];

const mappedItems = [
  {
    key: 'DatePicker',
    element: 'DatePicker',
    group_name: 'Event',
    field_name: 'date',
    name: 'Datum',
    icon: 'fa fa-calendar',
    label: 'Veranstaltungsdatum',
    showTimeSelect: false,
    showTimeSelectOnly: false,
    showYearDropdown: true,
    showTimeInput: false,
    dateFormat: 'dd.MM.yyyy',
  },
  {
    group_name: 'Event',
    key: 'DatePicker',
    element: 'DatePicker',
    label: 'Begin der Veranstaltung',
    name: 'Start',
    icon: 'fa fa-clock',
    field_name: 'time.from',
    timeFormat: 'HH:mm',
    showTimeSelect: true,
    showTimeSelectOnly: true,
    showTimeInput: false,
    datePickerProps: {
      timeCaption: 'Uhrzeit',
    },
  },
  {
    group_name: 'Event',
    key: 'DatePicker',
    element: 'DatePicker',
    label: 'Ende der Veranstaltung',
    name: 'Ende',
    icon: 'fa fa-clock',
    field_name: 'time.to',
    timeFormat: 'HH:mm',
    showTimeSelect: true,
    showTimeSelectOnly: true,
    showTimeInput: false,
    datePickerProps: {
      timeCaption: 'Uhrzeit',
    },
  },

  {
    group_name: 'Event',
    key: 'EventKind',
    element: 'TextInput',
    name: 'Art des Events',
    label: 'Art des Events',
    field_name: 'kind',
  },

  {
    group_name: 'Event',
    key: 'EventRequestText',
    name: 'Nachricht',
    label: 'Nachricht',
    field_name: 'requestText',
    element: 'TextArea',
  },
  {
    group_name: 'Location',
    key: 'LocationName',
    name: 'Name der Location',
    label: 'Name der Location',
    field_name: 'location.name',
    element: 'TextInput',
  },
  {
    group_name: 'Location',
    key: 'LocationAddress',
    name: 'Anschrift der Location',
    label: 'Anschrift der Location',
    field_name: 'location.address',
    element: 'TextInput',
  },

  {
    group_name: 'Kontakt',
    key: 'SalutationDropdown',
    element: 'Dropdown',
    canPopulateFromApi: false,
    canHaveOptionCorrect: false,
    name: 'Anrede',
    label: 'Anrede',
    field_name: 'customer.salutation',
    options: [
      {
        key: 1,
        value: 'Herr',
        text: 'Herr',
      },
      {
        key: 2,
        value: 'Frau',
        text: 'Frau',
      },
    ],
  },
  {
    group_name: 'Kontakt',
    key: 'PartnerSalutationDropdown',
    element: 'Dropdown',
    canPopulateFromApi: false,
    canHaveOptionCorrect: false,
    name: 'Partner Anrede',
    label: 'Partner Anrede',
    field_name: 'customer.partner.salutation',
    options: [
      {
        key: 1,
        value: 'Herr',
        text: 'Herr',
      },
      {
        key: 2,
        value: 'Frau',
        text: 'Frau',
      },
    ],
  },
  {
    group_name: 'Kontakt',
    key: 'FirstName',
    label: 'Vorname',
    name: 'Vorname',
    field_name: 'customer.firstname',
    element: 'TextInput',
  },
  {
    group_name: 'Kontakt',
    key: 'LastName',
    label: 'Nachname',
    name: 'Nachname',
    field_name: 'customer.lastname',
    element: 'TextInput',
  },
  {
    group_name: 'Kontakt',
    key: 'PartnerFirstName',
    label: 'Partner Vorname',
    name: 'Partner Vorname',
    field_name: 'customer.partner.firstname',
    element: 'TextInput',
  },
  {
    group_name: 'Kontakt',
    key: 'PartnerLastName',
    label: 'Partner Nachname',
    name: 'Partner Nachname',
    field_name: 'customer.partner.lastname',
    element: 'TextInput',
  },
  {
    group_name: 'Kontakt',
    key: 'Email',
    label: 'E-Mail',
    name: 'E-Mail',
    field_name: 'customer.emailAddress',
    element: 'EmailInput',
  },
  {
    group_name: 'Kontakt',
    key: 'PartnerEmail',
    label: 'Partner E-Mail',
    name: 'Partner E-Mail',
    field_name: 'customer.partner.emailAddress',
    element: 'EmailInput',
  },

  {
    group_name: 'Kontakt',
    key: 'KontaktAddress',
    name: 'Straße',
    label: 'Straße',
    field_name: 'customer.addressStreetAndNo',
    element: 'TextInput',
  },

  {
    group_name: 'Kontakt',
    key: 'KontaktPlz',
    name: 'PLZ',
    label: 'PLZ',
    field_name: 'customer.postalCode',
    element: 'TextInput',
  },

  {
    group_name: 'Kontakt',
    key: 'KontaktCity',
    name: 'Stadt',
    label: 'Stadt',
    field_name: 'customer.city',
    element: 'TextInput',
  },

  {
    group_name: 'Kontakt',
    key: 'Phone',
    label: 'Telefonnummer',
    name: 'Telefonnummer',
    field_name: 'phone',
    element: 'PhoneNumber',
    skipValidation: true,
  },
  {
    group_name: 'Kontakt',
    key: 'PartnerPhone',
    label: 'Partner Telefonnummer',
    name: 'Partner Telefonnummer',
    field_name: 'partner.phone',
    element: 'PhoneNumber',
    skipValidation: true,
  },
  {
    group_name: 'Kontakt',
    key: 'CompanyName',
    label: 'Firma',
    name: 'Firma',
    field_name: 'customer.companyName',
    element: 'TextInput',
  },
];

const defaultItems = [
  {
    group_name: 'Sonstiges',
    key: 'Header',
  },
  {
    group_name: 'Sonstiges',
    key: 'Label',
  },
  {
    group_name: 'Sonstiges',
    key: 'Paragraph',
  },
  {
    group_name: 'Sonstiges',
    key: 'LineBreak',
  },
  {
    group_name: 'Sonstiges',
    key: 'TwoColumnRow',
  },
  {
    group_name: 'Sonstiges',
    key: 'ThreeColumnRow',
  },
  {
    group_name: 'Sonstiges',
    key: 'FourColumnRow',
    element: 'MultiColumnRow',
    col_count: 4,
  },
];

const fetchLeadOriginOptionsAndCreateItem = async () => {
  const leadOrigins = await EventS.fetchLeadOrigins();
  return {
    group_name: 'Event',
    key: 'LeadOriginDropdown',
    element: 'Dropdown',
    canPopulateFromApi: false,
    canHaveOptionCorrect: false,
    name: 'Aufmerksam geworden durch',
    label: 'Aufmerksam geworden durch',
    field_name: 'leadOrigin',
    options:
      leadOrigins?.map((lo: string) => ({
        key: lo,
        value: lo,
        text: lo,
      })) ?? [],
  };
};

const fetcEventKindOptionsAndCreateItem = async () => {
  const eventKinds = await EventS.fetchEventKinds();
  return {
    group_name: 'Event',
    key: 'EventKindDropdown',
    element: 'Dropdown',
    canPopulateFromApi: false,
    canHaveOptionCorrect: false,
    name: 'Art des Events',
    label: 'Art des Events',
    field_name: 'kind',
    options:
      eventKinds
        ?.map((k) => k.name)
        .filter((ek?: string) => ek && ek !== null)
        .map((ek: string) => ({
          key: ek,
          value: ek,
          text: ek,
        })) ?? [],
  };
};

const fetchCustomAttributtesAndCreateItems = async () => {
  const groups = await CustomAttributeS.fetchGroups('event');

  const results = [] as any;

  for await (const group of groups) {
    const attributes = await CustomAttributeS.fetchGroupAttributes(group.id);
    attributes.forEach(attr => createFormItemsFromCustomAttributes(attr, group.name, results));
  }

  return results;
};

const createFormItemsFromCustomAttributes = (attr: CustomAttributeDTO, groupName: string, results: any[]) => {
  const fieldName = `custom-${attr.id}`;

  switch (attr.attributeType) {
    case 'TEXT':
      results.push({
        group_name: groupName,
        key: fieldName,
        name: attr.name,
        label: attr.name,
        field_name: fieldName,
        element: 'TextArea',
      });
      break;
    case 'DATE':
      results.push({
        key: 'DatePicker',
        element: 'DatePicker',
        icon: 'fa fa-calendar',
        showTimeSelect: false,
        showTimeSelectOnly: false,
        showTimeInput: false,
        dateFormat: 'dd.MM.yyyy',
        group_name: groupName,
        name: attr.name,
        label: attr.name,
        field_name: fieldName,
      });
      break;
    case 'TIME':
      results.push({
        key: 'DatePicker',
        element: 'DatePicker',
        icon: 'fa fa-clock',
        timeFormat: 'HH:mm',
        showTimeSelect: true,
        showTimeSelectOnly: true,
        showTimeInput: false,
        datePickerProps: {
          timeCaption: 'Uhrzeit',
        },
        group_name: groupName,
        name: attr.name,
        label: attr.name,
        field_name: fieldName,
      });
      break;
    case 'BOOLEAN':
      results.push({
        group_name: groupName,
        key: fieldName,
        element: 'RadioButtons',
        field_name: fieldName,
        canPopulateFromApi: false,
        canHaveOptionCorrect: false,
        name: attr.name,
        label: attr.name,
        options: [
          {
            key: `${fieldName}_accepted`,
            value: true,
            text: 'Ja',
          },
          {
            key: `${fieldName}_rejected`,
            value: false,
            text: 'Nein',
          },
        ],
      });
      break;

    case 'STRING':
      results.push({
        group_name: groupName,
        key: fieldName,
        name: attr.name,
        label: attr.name,
        field_name: fieldName,
        element: 'Dropdown',
        canPopulateFromApi: false,
        canHaveOptionCorrect: false,
        options: getFieldOptions(attr.suggestions),
      });

      results.push({
        group_name: groupName,
        key: fieldName,
        name: attr.name,
        label: attr.name,
        field_name: fieldName,
        element: 'TextInput',
      });

      break;
    case 'MULTIPLE_CHOICE':
      results.push({
        group_name: groupName,
        key: fieldName,
        field_name: fieldName,
        element: 'Checkboxes',
        canPopulateFromApi: false,
        canHaveOptionCorrect: false,
        name: attr.name,
        label: attr.name,
        options: getFieldOptions(attr.suggestions),
      });
      break;
    case 'NUMBER':
    case 'DECIMAL':
    default:
      results.push({
        group_name: groupName,
        key: fieldName,
        name: attr.name,
        label: attr.name,
        field_name: fieldName,
        element: 'TextInput',
      });
      break;
  }
};

const getFieldOptions = (suggestions: string[]) => {
  if (suggestions.length === 0) {
    return [
      {
        key: 1,
        value: '',
        text: 'Option 1',
      },
    ];
  }
  return suggestions.map((suggestion, index) => ({
    key: index,
    value: suggestion,
    text: suggestion,
  }));
};

const FormElementToolbarItems = [...customItems, ...mappedItems, ...defaultItems];

export const enrichFormElementsWithCustomAttributes = async () => [
  ...FormElementToolbarItems,
  ...(await fetchCustomAttributtesAndCreateItems()),
  await fetchLeadOriginOptionsAndCreateItem(),
  await fetcEventKindOptionsAndCreateItem(),
];

export default FormElementToolbarItems;
