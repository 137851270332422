/* eslint-disable react/jsx-props-no-spreading */
import { Select, SelectProps, Skeleton } from '@mantine/core';
import { IconChevronDown, IconTemplate } from '@tabler/icons';
import { FC, useEffect, useState } from 'react';
import { DocumentS } from '../../Templates/Documents/DocumentS';
import { BookitupDocumentType } from '../../Types/BookitupDocument';
import { TestS } from '../../TestS';

interface Props extends Partial<SelectProps> {
  documentType: BookitupDocumentType;
  initialValue?: string;
}

const DocumentTemplateSelect: FC<Props> = ({ documentType, initialValue, ...rest }) => {
  const [loadingTemplates, setLoadingTemplates] = useState<boolean>(true);
  const [availableTemplates, setAvailableTemplates] = useState<string[]>([]);

  useEffect(() => {
    DocumentS.loadTemplates(documentType)
      .then((templates) => {
        if (templates.length > 0) {
          setAvailableTemplates(templates);
        }
      })
      .finally(() => setLoadingTemplates(false));
  }, [documentType]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loadingTemplates ? (
        <Skeleton radius="sm" height={35} width="100%" />
      ) : (
        <Select
          clearable
          icon={<IconTemplate size={16} />}
          data={availableTemplates}
          label="Vorlage"
          defaultValue={initialValue}
          data-testid={TestS.Documents.SELECT_TEMPLATE}
          styles={{ rightSection: { pointerEvents: 'none' } }}
          rightSection={<IconChevronDown size={14} />}
          rightSectionWidth={25}
          {...rest}
        />
      )}
    </>
  );
};

export default DocumentTemplateSelect;
