/* eslint-disable no-return-assign */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Skeleton, Text } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { openModal } from '@mantine/modals';
import { StockIntervalDto } from '../../../Types/ResourceT';
import ResourceS from '../../../Service/ResourceS';
import PanePaper from '../../../Molecules/PanePaper';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import BookitupTable from '../../../Atoms/BookitupTable';
import CreateStockIntervalModal from './CreateStockIntervalModal';
import StockIntervalItem from './StockIntervalItem';
import EditStockIntervalModal from './EditStockIntervalModal';
import DeleteStockIntervalModal from './DeleteStockIntervalModal';

const ResourceStockIntervals = () => {
  const { id: resourceId } = useParams();
  const [loading, setLoading] = useState(false);
  const [stockIntervals, setStockIntervals] = useState<StockIntervalDto[]>([]);

  const pastIntervals = stockIntervals.filter((interval) => new Date(interval.begin) < new Date());
  const futureIntervals = stockIntervals.filter((interval) => !pastIntervals.includes(interval));

  const currentStock = pastIntervals.reduce((acc, interval) => acc + interval.quantityChange, 0);
  const expecting = futureIntervals.reduce((acc, interval) => acc + interval.quantityChange, 0);

  useEffect(() => {
    if (resourceId) {
      setLoading(true);
      ResourceS.getStockIntervals(resourceId)
        .then(setStockIntervals)
        .finally(() => setLoading(false));
    }
  }, [resourceId]);

  const appendInterval = (stockInterval: StockIntervalDto) => setStockIntervals([stockInterval, ...stockIntervals]);

  const editInterval = (patch: StockIntervalDto) =>
    setStockIntervals(stockIntervals.map((interval) => (patch.id === interval.id ? patch : interval)));

  const deleteInterval = (deletedInterval: StockIntervalDto) =>
    setStockIntervals(stockIntervals.filter((interval) => interval.id !== deletedInterval.id));

  const openCreateModal = () => {
    if (resourceId) {
      openModal({
        title: (
          <Text weight="bolder" size="xl" align="center">
            Lagerbewegungen planen
          </Text>
        ),
        children: <CreateStockIntervalModal resourceId={resourceId} onCreate={appendInterval} />,
      });
    }
  };

  const openEditModal = (stockInterval: StockIntervalDto) =>
    openModal({
      title: (
        <Text weight="bolder" size="xl" align="center">
          Lagerbewegungen bearbeiten
        </Text>
      ),
      children: <EditStockIntervalModal stockInterval={stockInterval} onFinish={editInterval} />,
    });

  const openDeleteModal = (stockInterval: StockIntervalDto) => {
    openModal({
      title: (
        <Text weight="bolder" size="xl" align="center">
          Lagerbewegungen löschen
        </Text>
      ),
      children: (
        <DeleteStockIntervalModal stockInterval={stockInterval} deleteInterval={() => deleteInterval(stockInterval)} />
      ),
    });
  };

  const mapItemUI = (stockInterval: StockIntervalDto) => (
    <StockIntervalItem
      stockInterval={stockInterval}
      editInterval={() => openEditModal(stockInterval)}
      deleteInterval={() => openDeleteModal(stockInterval)}
    />
  );

  return (
    <PanePaper title="Bestand" rightSection={<BookitupAddIcon onClick={openCreateModal} />}>
      {loading ? (
        <LoadingSkeleton />
      ) : (
        <div>
          {stockIntervals.length === 0 ? (
            <Text italic color="dimmed" size="xs" weight="lighter">
              Noch nichts hinzugefügt.
            </Text>
          ) : (
            <>
              <Group mb="md">
                <Text weight="lighter">
                  Lagerbestand:{'  '}
                  <Text span weight="bolder">
                    {currentStock}
                  </Text>
                </Text>
                <Text weight="lighter">
                  Erwartet:{'  '}
                  <Text span weight="bolder">
                    {expecting}
                  </Text>
                </Text>
              </Group>
              <BookitupTable>
                <thead>
                  <tr>
                    <th style={{ width: 50 }}> </th>
                    <th style={{ width: 100 }}>Menge</th>
                    <th style={{ width: 150 }}>Datum</th>
                    <th>Beschreibung</th>
                    <th style={{ width: 50 }}> </th>
                  </tr>
                </thead>
                <tbody>
                  {futureIntervals.length > 0 && (
                    <>
                      <tr className="notHover">
                        <td colSpan={6}>
                          <Text weight="bolder">Zukünftig</Text>
                        </td>
                      </tr>
                      {futureIntervals.map(mapItemUI)}
                    </>
                  )}
                  {pastIntervals.length > 0 && (
                    <>
                      <tr className="notHover">
                        <td colSpan={6}>
                          <Text weight="bolder">Vergangenheit</Text>
                        </td>
                      </tr>
                      {pastIntervals.map(mapItemUI)}
                    </>
                  )}
                </tbody>
              </BookitupTable>
            </>
          )}
        </div>
      )}
    </PanePaper>
  );
};

const LoadingSkeleton = () => (
  <>
    {Array.from(Array(3)).map(() => (
      <Skeleton key={Math.random()} radius="md" height={25} width="100%" mb={10} />
    ))}
  </>
);

export default ResourceStockIntervals;
