// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Menu, Text } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { useModals } from '@mantine/modals';
import { IconCopy, IconDotsVertical, IconTrash } from '@tabler/icons';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SemanticForm from '../../../Types/SemanticForm';
import { SemanticFormS } from '../../../Service/restapi/semanticFormService';
import { RoutingS } from '../../../Service/RoutingS';
import BookitupTutorialIcon from '../../../Atoms/BookitupTutorialIcon';

interface Props {
  form: SemanticForm;
  reload: () => unknown;
  showTutorialIcon?: boolean;
}

const ContactFormActions: FC<Props> = ({ form, reload, showTutorialIcon = false }) => {
  const { id, name } = form;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { openConfirmModal } = useModals();
  const { i18n } = useLingui();

  const deleteFormById = async () => {
    const deleted = await SemanticFormS.deleteForm(id);
    if (deleted) {
      // Form table page
      if (pathname === RoutingS.ROUTES.FORMS_LIST_PAGE) {
        reload();
      } else {
        // Form details page
        navigate('/settings/contact-form');
      }
    }
  };

  const copyForm = async () => {
    const copy = await SemanticFormS.copy(id);
    if (copy) {
      navigate(`/settings/contact-form/${copy.id}`);
    }
  };

  const openDeleteModal = () =>
    openConfirmModal({
      title: (
        <Text weight="bolder" size="xl">
          {i18n._('settings.contact-form.delete-form')}
        </Text>
      ),
      centered: true,
      closeOnConfirm: true,
      children: (
        <Text size="sm">
          Möchtest Du das Formular{' '}
          <Text span weight="bolder">
            {name}
          </Text>{' '}
          wirklich löschen? Daten gehen verloren.
        </Text>
      ),
      labels: { confirm: i18n._('actions.delete'), cancel: i18n._('actions.cancel') },
      confirmProps: { color: 'red' },
      groupProps: { spacing: 'xs', mt: 'xl' },
      onConfirm: deleteFormById,
    });

  return (
    <Group>
      {showTutorialIcon && (
        <BookitupTutorialIcon videoUrl={'https://www.youtube.com/watch?v=gVUX6-EGbdM'} name={'CreateContactForm'} />
      )}
      <Menu radius="sm" shadow="md" width={130} transition="scale-y" position="bottom-end">
        <Menu.Target>
          <div>
            <IconDotsVertical color="gray" style={{ cursor: 'pointer' }} />
          </div>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item icon={<IconCopy size={16} />} onClick={copyForm}>
            {i18n._('actions.copy')}
          </Menu.Item>
          <Menu.Item color="red" icon={<IconTrash size={16} />} onClick={openDeleteModal}>
            {i18n._('actions.delete')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};

export default ContactFormActions;
