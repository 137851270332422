/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import {Button, Group, Space, Text} from '@mantine/core';
import React, {FC} from 'react';
import HubSpotOnboardingFrame from "../../Atoms/HubSpotOnboardingFrame";
import Contact from "../../Types/Contact";
import {MixpanelS} from "../../Service/MixpanelS";
import {ConstantS} from "../../Service/ConstantS";
import {closeModal, openModal} from "@mantine/modals";
import SkipOnboardingModal from "./SkipOnboardingModal";

const OnboardingCallModal: FC<{
  contact: Contact;
  showDemoSkipButton?: boolean,
  onFinish: () => void;
}> = ({ contact, showDemoSkipButton, onFinish }) => {

  const openSkipOnboardingModal = () =>
    openModal({
      modalId: "skip-onboarding",
      size: 'xl',
      closeOnEscape: false,
      withCloseButton: true,
      transition: 'slide-down',
      children: <SkipOnboardingModal onFinish={onFinish} closeModal={() => {
        closeModal("skip-onboarding");
      }} />,
    });

  return (
    <>
      <Text mt="md" weight="bolder" size={24} align="center" sx={{ lineHeight: 1.2 }}>
        {`bookitup talk` }
      </Text>
      <Text mb="xs" color="dimmed" align="center">
        Erste Steps mit bookitup
      </Text>
      <Space h="md" />
      <Text mb="sm" align="left">
        Wir freuen uns auf das Gespräch. 😍
      </Text>
      <Space h="sm" />
      <Text size="sm" align="left">
        Bitte nimm dir Zeit für das Gespräch und leg dir Blatt und Stift zurecht, damit du dir wichtige Dinge auch aufschreiben kannst. ✍️ (Niemand kann sich alles merken 😀)
      </Text>
      <Space h="sm" />
      <Text size="sm" align="left">
        Nach Terminvereinbarung erhältst Du eine E-Mail mit einem Link zu einem Google Meet Termin. Zur verabredeten Zeit klickst Du einfach auf den Link und es kann los gehen.
      </Text>
      <Space h="sm" />
      <Text size="sm" align="left">
        Such Dir für den Call einen ruhigen Ort. Es geht um dich und dein Business.
      </Text>
      <Space h="sm" />
      <Text size="sm" align="left">

        Let's rock.
      </Text>
      <Text size="sm" align="left">
        Team bookitup 🙋‍♂️
      </Text>

      <Space h="sm" />
      <HubSpotOnboardingFrame contact={contact} />
    </>
  );
};

export default OnboardingCallModal;
