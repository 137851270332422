import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import ResourceAdapter from '../ResourceAdapter';

export const DocumentLayoutContext = React.createContext(undefined);

const DocumentLayoutContextProvider = (props) => {
  const [documentLayout, setDocumentLayout] = useState(props.documentLayout);

  return (
    <DocumentLayoutContext.Provider
      value={{
        documentLayout,
        handleDocumentLayoutChange: (patch) => {
          setDocumentLayout((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
      }}
    >
      <Outlet />
    </DocumentLayoutContext.Provider>
  );
};

export const DocumentLayoutContextProviderProxy = (outerProps) => (
  <ResourceAdapter
    serviceUrl={`${process.env.REACT_APP_PDF_SERVICE_URL}/documentLayouts`}
    component={(props) => {
      const documentLayout = props.data;
      return <DocumentLayoutContextProvider documentLayout={documentLayout} />;
    }}
  />
);
