import { Menu } from '@mantine/core';
import { IconDots, IconEye, IconRefresh, IconTrash } from '@tabler/icons';
import { useContext } from 'react';
import { NotificationsContext } from '../../Service/Context/NotificationsContext';

const GeneralNotificationsActions = () => {
  const { notifications, updateFilter, markAllAsSeen, deleteAll } = useContext(NotificationsContext);
  const isEmpty = notifications.length === 0;

  return (
    <Menu radius="sm" shadow="md" transition="scale-y" position="bottom-end">
      <Menu.Target>
        <div>
          <IconDots color="gray" style={{ cursor: 'pointer' }} />
        </div>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item icon={<IconRefresh size={16} />} onClick={() => updateFilter({ pageNumber: 0 })}>
          Aktualisieren
        </Menu.Item>
        <Menu.Item disabled={isEmpty} icon={<IconEye size={16} />} onClick={markAllAsSeen}>
          Alles als gelesen markieren
        </Menu.Item>
        <Menu.Item disabled={isEmpty} color="red" icon={<IconTrash size={16} />} onClick={deleteAll}>
          Alle Benachrichtigungen löschen
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default GeneralNotificationsActions;
