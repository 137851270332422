import { Center, LoadingOverlay } from '@mantine/core';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LexOfficeS } from '../../Service/LexOfficeS';
import { ToastS } from '../../Service/ToastS';

const OAuthDispatcher = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const code = searchParams.get('code');
  const error = searchParams.get('error');

  useEffect(() => {
    if (code) {
      LexOfficeS.completeOAuthProcess(code)
        .then((resp) => {
          if (resp.ok) {
            ToastS.success('lo-setup', '🎉 lexoffice erfolgreich eingerichtet');
          } else {
            ToastS.error('lo-setup', 'lexoffice kann momentan nicht eingerichtet werden');
          }
        })
        .finally(() => navigate('/settings/bookkeeping'));
    } else {
      if (error === 'access_denied') {
        ToastS.error('lo-setup', 'lexoffice konnte nicht verbunden werden, da der Zugriff verweigert wurde.');
      } else {
        ToastS.generalError();
      }
      navigate('/settings/bookkeeping');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Center sx={{ position: 'relative', height: '100vh', width: '100vw' }}>
      <LoadingOverlay visible overlayBlur={2} loaderProps={{ size: 'xl' }} />
    </Center>
  );
};

export default OAuthDispatcher;
