// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { CustomEventState } from '../../Types/EventT';
import { EventS } from '../../Service/EventS';
import PanePaper from '../PanePaper';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import SkeletonItems from '../../Atoms/SkeletonItems';
import { openCreateEventStatusModal } from './EventStatusesModals';
import BookitupTable from '../../Atoms/BookitupTable';
import EventStateItems from './EventStateItems';
import BookingState from '../../Types/Enums/BookingState';
import { DndProvider } from 'react-dnd-latest';
import { HTML5Backend } from 'react-dnd-html5-backend-latest';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import { LicenceS } from '../../Service/LicenceS';
import { WindowContext } from '../../Service/Context/WindowContext';
import BookitupTutorialIcon from "../../Atoms/BookitupTutorialIcon";

const EventStatesPane = () => {
  const [loading, setLoading] = useState(true);
  const { xs } = useContext(WindowContext);
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const [eventStates, setEventStates] = useState<CustomEventState[]>([]);
  const stateNames = eventStates.map((_kind) => _kind.label);
  const { length } = eventStates;

  useEffect(() => {
    fetchStates();
  }, []);

  const fetchStates = async () => {
    setLoading(true);
    const _eventStates = await EventS.fetchEventStates();
    setEventStates(_eventStates);
    setLoading(false);
  };

  return (
    <PanePaper
      title="Eigene Event-Status"
      rightSection={
        <>
          <BookitupTutorialIcon videoUrl={"https://youtu.be/kG5E99ytc2o"} name={"CreateEventState"} />
          <BookitupAddIcon onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.CUSTOM_EVENT_STATE, () =>
            openCreateEventStatusModal(fetchStates, stateNames),
          )} />
        </>
      }
    >
      <Text mb="md">Lege Dir eigene Event-Status an wie "Warte auf Vertrag", "in Vorbereitung" oder "Abgeschlossen". Dabei kannst du Emojis verwenden, die in der Eventliste bei jedem Event angezeigt werden.</Text>
      {loading && <SkeletonItems />}
      {!loading && length === 0 && (
        <Text italic color="dimmed" size="xs" weight="lighter">
          Noch nichts hinzugefügt.
        </Text>
      )}
      {length > 0 && (
        <DndProvider backend={HTML5Backend}>
          <BookitupTable>
            <thead>
              <tr>
                <th style={{ width: 25 }}> </th>
                <th style={{ width: 25 }}> </th>
                <th>Name</th>
                {!xs && <th style={{ width: 200 }}>Basis-Buchungsstatus</th>}
                <th> </th>
              </tr>
            </thead>
            <tbody>
              <EventStateItems refresh={fetchStates} bookingState={BookingState.OPEN} states={eventStates} />
              <EventStateItems refresh={fetchStates} bookingState={BookingState.OFFERED} states={eventStates} />
              <EventStateItems refresh={fetchStates} bookingState={BookingState.BOOKED} states={eventStates} />
              <EventStateItems refresh={fetchStates} bookingState={BookingState.UNAVAILABLE} states={eventStates} />
              <EventStateItems refresh={fetchStates} bookingState={BookingState.CANCELED} states={eventStates} />
            </tbody>
          </BookitupTable>
        </DndProvider>
      )}
    </PanePaper>
  );
};

export default EventStatesPane;
