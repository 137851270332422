// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Label } from 'reactstrap';
import { CustomAttributeDTO } from '../../../Types/CustomAttributeT';
import TooltipButton from '../../../Atoms/buttons/TooltipButton';

const LabelWithDescription: FC<{ attribute: CustomAttributeDTO }> = ({ attribute }) => {
  const { name, description } = attribute;
  return (
    <Label sm={12}>
      <Text size="xs" color="dimmed">
        {name}
      </Text>
      {description && <TooltipButton style={{ marginLeft: 5 }} icon="QUESTION" id={uuidv4()} text={description} />}
    </Label>
  );
};

export default LabelWithDescription;
