import { useEffect, useState } from 'react';
import { AttributeType, CustomAttributeWithGroupNameDTO } from '../../Types/CustomAttributeT';
import { CustomAttributeS } from '../Context/CustomAttributeS';

// eslint-disable-next-line import/prefer-default-export
export const useCustomAttribute = (
  attributeId: string | null,
  entityId: string | undefined,
  customAttributes: CustomAttributeWithGroupNameDTO[],
  questionAttributeType: AttributeType,
) => {
  const [loading, setLoading] = useState(false);
  const [attributeValue, setAttributeValue] = useState<unknown>();
  const attribute = attributeId ? customAttributes.find((attr) => attr.id === attributeId) : null;
  const alternatives = CustomAttributeS.filterSuitableAttributesByType(customAttributes, questionAttributeType);

  useEffect(() => {
    if (attributeId && entityId) {
      setLoading(true);
      CustomAttributeS.fetchAttributeValue(attributeId, entityId)
        .then((resJSON) => {
          if (resJSON) {
            const { value } = resJSON;
            setAttributeValue(value);
          } else {
            setAttributeValue(undefined);
          }
        })
        .finally(() => setLoading(false));
    } else if (!attributeId && attributeValue) {
      setAttributeValue(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributeId, entityId]);

  return { loading, attribute, alternatives, attributeValue };
};
