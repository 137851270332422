import React, { useContext } from 'react';
import { ContractContext } from '../../../../Service/Context/ContractContext';
import ContractSubject from '../../../Contract/ContractSubject';
import TextBlockContainer from '../../../Contract/TextBlockContainer';
import ContractAddPositions from '../../../Contract/ContractAddPositions';
import { TransButtonDefault } from '../../../../Atoms/Buttons';

export default function ({ addAboveTextBlock, addBelowTextBlock, preChange, style }) {
  const { contract, handleChange } = useContext(ContractContext);

  if (!contract) {
    return null;
  }

  return (
    <div style={style}>
      <ContractSubject contract={contract} handleChange={handleChange} preChange={preChange} settings />
      <TextBlockContainer
        contract={contract}
        handleChange={handleChange}
        textBlocks={contract.aboveTextBlocks}
        addTextBlock={addAboveTextBlock}
        settings
      />
      {(!contract.aboveTextBlocks || contract.aboveTextBlocks.length < 1) && (
        <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
          <TransButtonDefault
            style={{ margin: 5 }}
            text="textBlocks.button.add"
            id={'addTextBlock'}
            onClickHandler={() => {
              addAboveTextBlock(null);
            }}
          />
        </div>
      )}

      <ContractAddPositions contract={contract} handleChange={handleChange} />

      <TextBlockContainer
        contract={contract}
        handleChange={handleChange}
        textBlocks={contract.belowTextBlocks}
        addTextBlock={addBelowTextBlock}
        settings
      />

      {
        // if positions should get inserted and
        // no below text blocks are present (thus no addTextBlock button is shown in above component)
        contract.positions && (!contract.belowTextBlocks || contract.belowTextBlocks.length < 1) && (
          <div style={{ display: 'flex', justifyContent: 'center', margin: 10 }}>
            <TransButtonDefault
              style={{ margin: 5 }}
              text="textBlocks.button.add"
              id={'addTextBlock'}
              onClickHandler={() => {
                addBelowTextBlock(null);
              }}
            />
          </div>
        )
      }
    </div>
  );
}
