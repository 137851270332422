import { useContext, useEffect, useState } from 'react';
import { useModals } from '@mantine/modals';
import { Stack } from '@mantine/core';
import { CustomModalHeader } from '../../../Molecules/Dialogs';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import PanePaper from '../../../Molecules/PanePaper';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import { RoutingS } from '../../../Service/RoutingS';
import WorkflowTemplates from '../Workflow/WorkflowTemplates';
import RecurringTaskList from './RecurringTaskList';
import Reminder from './Reminder';
import MailNotificationConfig from './MailNotificationConfig';
import BookitupNotificationsConfigPane from './BookitupNotificationsConfigPane';
import WorkflowDepricationAlert from './WorkflowDepricationAlert';

const Workflow = () => {
  const [lastModified, setLastModified] = useState<Date | undefined>(undefined);
  const { openContextModal, closeAll } = useModals();
  const { executeWithLicenceCheck } = useContext(ViolationContext);

  const openRecurringTaskModalFlow = () =>
    openContextModal('recurringTaskModalFlow', {
      title: <CustomModalHeader>Wiederkehrende Aufgaben erstellen</CustomModalHeader>,
      innerProps: {
        entityType: 'events',
        finish: () => {
          closeAll();
          setLastModified(new Date());
        },
        showEntityTypeSelection: true,
      },
      centered: true,
      size: 'xl',
    });

  useEffect(() => {
    RoutingS.changeTitle('Workflow');
  }, []);

  return (
    <Stack mb={70} sx={{ maxWidth: 650 }}>
      <WorkflowTemplates />
      <BookitupNotificationsConfigPane />
      <PanePaper
        title="Workflow"
        tooltip="help.settings.workflow.pane"
        rightSection={
          <BookitupAddIcon
            onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.WORKFLOW, openRecurringTaskModalFlow)}
          />
        }
      >
        <WorkflowDepricationAlert />
        <div style={{ marginBottom: 20 }}>
          <div className="description">Hier kannst Du Aufgaben verwalten, die automatisch erstellt werden sollen.</div>
        </div>
        <Reminder />
        <div className="heading">Wiederkehrende Aufgaben</div>
        <div className="description">
          Hinterlege hier Deinen persönlichen Workflow - Deine Aufgaben, die immer wieder anfallen.
        </div>
        <RecurringTaskList lastModified={lastModified} />
        <MailNotificationConfig />
      </PanePaper>
    </Stack>
  );
};

export default Workflow;
