import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/react';
import { Skeleton, Text } from '@mantine/core';
import { fetchDocumentTemplatesByType } from '../../../../Service/restapi/templateService';
import DocumentTemplateItem from './DocumentTemplateItem';

const DocumentTemplateByDocumentType = ({ documentType }) => {
  const [documentTemplates, setDocumentTemplates] = useState();

  useEffect(() => {
    loadTemplates();
  }, []);

  function loadTemplates() {
    fetchDocumentTemplatesByType(documentType).then((resp) => {
      if (resp.ok) {
        resp.json().then(setDocumentTemplates);
      }
    });
  }

  if (!documentTemplates) {
    return <Skeleton width="100%" height={70} />;
  }

  const items = [];
  documentTemplates.map((documentTemplate, index) => {
    items.push(
      <DocumentTemplateItem
        key={index}
        index={index}
        template={documentTemplate}
        documentType={documentType}
        reloadCallback={loadTemplates}
      />,
    );
  });

  return (
    <div>
      <Text size="md" weight="bolder">
        <Trans id={`document.templates.${documentType.toLowerCase()}`} />
      </Text>
      {items}
    </div>
  );
}

export default DocumentTemplateByDocumentType;
