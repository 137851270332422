// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Flex, Group, Space, Stack, Text, ThemeIcon } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { IconCurrencyEuro, IconTrashX } from '@tabler/icons';
import { useContext } from 'react';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';
import { DocumentContext } from '../../../../Service/Context/DocumentContext';
import { FormatS } from '../../../../Service/FormatS';
import { ToastS } from '../../../../Service/ToastS';
import { DocumentS } from '../../../../Templates/Documents/DocumentS';
import { PartialInvoice } from '../../../../Types/BookitupDocument';
import PartialInvoiceHeader from './PartialInvoiceHeader';
import { TestS } from '../../../../TestS';

const DocumentPartialInvoices = () => {
  const { document: bookitupDocument, refresh, negativeSum } = useContext<any>(DocumentContext);
  const navigate = useNavigate();
  const { ref, width } = useElementSize();
  const { partialInvoices, eventId, finalSum, finalTaxSummary } = bookitupDocument;
  const previewVersion = width < 700;

  if (!partialInvoices || partialInvoices.length === 0) {
    return null;
  }

  const confirmRemovePartialInvoice = (invoiceId: number) => {
    openConfirmModal({
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Wirklich löschen
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      labels: { confirm: 'Ja', cancel: 'Abbrechen' },
      children: <Text>Möchten Sie diese Abschlagsrechnung wirklich entfernen?</Text>,
      onConfirm: () => removePartialInvoice(invoiceId),
    });
  };

  const removePartialInvoice = (invoiceId: number) => {
    DocumentS.patchDocument(invoiceId, { finalDocument: null }).then((ok) => {
      if (ok) {
        refresh();
      } else {
        ToastS.generalError();
      }
    });
  };

  const mapInvoiceUI = (invoice: PartialInvoice) => {
    const { id, gross, vat, net, documentNumber, date, taxRate } = invoice;
    const creationDate = new Date(date);

    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {previewVersion ? (
          <>
            <Flex>
              <Text sx={{ flexGrow: 1 }}>
                <ThemeIcon radius="xl" size="sm" mr="md" color="green">
                  <IconCurrencyEuro size={14} />
                </ThemeIcon>
                <Text
                  span
                  color="blue"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/events/${eventId}/documents/${id}/preview`)}
                >
                  {` ${documentNumber} `}
                </Text>
                vom{'  '}
                <Moment date={creationDate} format="DD.MM.YYYY" />
              </Text>
              <div style={{ width: 65 }}>
                <IconTrashX
                  size={20}
                  color="gray"
                  onClick={() => confirmRemovePartialInvoice(id)}
                  style={{ cursor: 'pointer', float: 'right' }}
                />
              </div>
            </Flex>
            <Flex direction="row" wrap="wrap" gap="md" rowGap="xs">
              <Text size="xs" weight="bolder">
                Brutto:
                <Text span weight="normal">
                  {` ${FormatS.numberToCurrency(gross)}`}
                </Text>
              </Text>
              <Text size="xs" weight="bolder">
                Steuer:
                <Text span weight="normal">
                  {` ${taxRate || '____'} %`}
                </Text>
              </Text>
              <Text size="xs" weight="bolder">
                USt:
                <Text span weight="normal">
                  {` ${FormatS.numberToCurrency(vat)}`}
                </Text>
              </Text>
            </Flex>
          </>
        ) : (
          <Flex>
            <Text sx={{ flexGrow: 1 }}>
              <ThemeIcon radius="xl" size="sm" mr="md" color="green">
                <IconCurrencyEuro size={14} />
              </ThemeIcon>
              Abschlagsrechnung
              <Text
                span
                color="blue"
                sx={{ cursor: 'pointer' }}
                onClick={() => navigate(`/events/${eventId}/documents/${id}/preview`)}
              >
                {` ${documentNumber} `}
              </Text>
              vom{'  '}
              <Moment date={creationDate} format="DD.MM.YYYY" />
              {`  (USt: ${taxRate}%) `}
            </Text>
            <Text sx={{ width: 100 }} data-testid={TestS.Documents.Edit.PARTIAL_INVOICE_NET}>
              {FormatS.formatNumberCurrencyWithUnitSign(net)}
            </Text>
            <Text sx={{ width: 100 }} data-testid={TestS.Documents.Edit.PARTIAL_INVOICE_TAX}>
              {FormatS.formatNumberCurrencyWithUnitSign(vat)}
            </Text>
            <Text sx={{ width: 100 }} data-testid={TestS.Documents.Edit.PARTIAL_INVOICE_GROSS}>
              - {FormatS.formatNumberCurrencyWithUnitSign(gross)}
            </Text>
            <div style={{ width: 95 }}>
              <IconTrashX
                size={20}
                color="gray"
                onClick={() => confirmRemovePartialInvoice(id)}
                style={{ cursor: 'pointer', float: 'right' }}
              />
            </div>
          </Flex>
        )}
      </>
    );
  };

  return (
    <div ref={ref}>
      <Space h="md" />
      <PartialInvoiceHeader desktopVersion={!previewVersion} />
      <Space h="xs" />
      <Stack
        sx={{
          border: '1px solid #D4D4D4',
          padding: 15,
          borderRadius: 6,
          background: 'rgba(251, 251, 251, 0.9)',
        }}
      >
        {partialInvoices.map(mapInvoiceUI)}
      </Stack>
      <div>
        <Box
          p="xs"
          mt="xs"
          sx={{
            backgroundColor: negativeSum ? '#FFEFEF' : '#F1F1F1',
            borderRadius: 10,
            width: '100%',
            maxWidth: 375,
            float: 'right',
            border: negativeSum ? 'none' : '1px solid #D4D4D4',
            color: negativeSum ? 'red' : 'inherit',
          }}
        >
          <Group position="apart">
            <Text weight="bolder">Forderungsbetrag</Text>
            <Text weight="bolder" color={negativeSum ? 'red' : 'inherit'} data-testid={TestS.Documents.Edit.FINAL_SUM}>
              {FormatS.formatNumberCurrencyWithUnitSign(finalSum)}
            </Text>
          </Group>
          {finalTaxSummary &&
            finalTaxSummary.taxEntries.map((taxEntry: any) =>
              taxEntry.taxRatePercent === '0' ? null : (
                <Text size="xs" key={taxEntry} data-testid={TestS.Documents.Edit.FINAL_TAX}>
                  {`enthält USt. ${taxEntry.taxRatePercent} % (${FormatS.formatCurrencyWithUnitSign(
                    taxEntry.taxAmount,
                  )})`}
                </Text>
              ),
            )}
        </Box>
      </div>
    </div>
  );
};

export default DocumentPartialInvoices;
