import React, { useContext, useEffect, useState } from 'react';
import { useLingui } from '@lingui/react';
import {
  createExternalSubscription,
  deleteExternalSubscription,
  getExternalSubscriptions,
} from '../../../../Service/restapi/calDAVService';
import { ExternalSubscriptionsList } from './ExternalSubscriptionsList';
import { ToastS } from '../../../../Service/ToastS';
import PanePaper from '../../../../Molecules/PanePaper';
import { TransModalDialogWithTextField, useModalState } from '../../../../Molecules/Dialogs';
import BookitupAddIcon from '../../../../Atoms/BookitupAddIcon';
import { ViolationContext } from '../../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../../Service/LicenceS';
import { ConstantS } from '../../../../Service/ConstantS';
import { MixpanelS } from '../../../../Service/MixpanelS';

const ExternalSubscriptions = () => {
  const { i18n } = useLingui();
  const [subscriptions, setSubscriptions] = useState([]);
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const { show, toggle } = useModalState(false);
  const [url, setUrl] = useState('');

  function handleConfirm() {
    addUrl();
    setUrl('');
    toggle();
  }

  useEffect(() => {
    getExternalSubs();
  }, []);

  function getExternalSubs() {
    getExternalSubscriptions().then((resp) => {
      if (!resp.ok) return;

      resp.json().then(setSubscriptions);
    });
  }

  function addUrl() {
    createExternalSubscription(url).then((resp) => {
      if (resp.ok) {
        ToastS.success('add.external.subscription', i18n._('external-subscription.add-success'));
        MixpanelS.track(ConstantS.TrackingEvents.ExternalCalendarAdded);
        getExternalSubs();
      } else {
        ToastS.error('add.external.subscription', i18n._('external-subscription.add-error'));
      }
    });
  }

  function deleteSubscription(id) {
    deleteExternalSubscription(id).then((resp) => {
      if (!resp.ok) {
        ToastS.error('delete.external.subscription', i18n._('external-subscription.delete-error'));
      }
      ToastS.success('delete.external.subscription', i18n._('external-subscription.delete-success'));
      getExternalSubs();
    });
  }

  const styles = {
    table: {
      width: '50vw',
      margin: '2rem 0',
    },
  };

  const addPrivateCalendar = () => {
    const { length } = subscriptions;
    if (length > 0) {
      executeWithLicenceCheck(LicenceS.Restrictions.PRIVATE_CALENDAR, toggle);
    } else {
      toggle();
    }
  };

  return (
    <PanePaper
      title="settings.calendar.external-subscriptions"
      tooltip="help-settings-external-subscriptions-pane"
      rightSection={<BookitupAddIcon onClick={addPrivateCalendar} />}
    >
      <div className="description">{i18n._('external-subscription.headline')}</div>

      <div className="description">{i18n._('external-subscription.description')}</div>

      <section style={styles.center}>
        <div style={styles.table}>
          <ExternalSubscriptionsList subscriptions={subscriptions} deleteSubscription={deleteSubscription} />
        </div>
      </section>
      <TransModalDialogWithTextField
        subject="Kalender hinzufügen"
        value={url}
        name="template"
        onChange={(e) => setUrl(e.target.value)}
        placeholder="https://some.calendar.ics"
        show={show}
        confirm={() => handleConfirm()}
        toggle={toggle}
      />
    </PanePaper>
  );
};

export default ExternalSubscriptions;
