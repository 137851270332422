import { FC } from 'react';
import BookingState from '../../Types/Enums/BookingState';
import { CustomEventState } from '../../Types/EventT';
import EventStateItem from './EventStateItem';
import _ from 'lodash';

interface Props {
  bookingState: BookingState;
  states: CustomEventState[];
  refresh: () => void;
}

const EventStateItems: FC<Props> = ({ bookingState, states, refresh }) => {
  const eventStates = _.orderBy(
    states.filter((s) => s.originState === bookingState),
    ['idx'],
  );
  return (
    <>
      {eventStates.map((state) => (
        <EventStateItem key={state.label} state={state} refresh={refresh} states={states} />
      ))}
    </>
  );
};

export default EventStateItems;
