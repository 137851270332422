import { withI18n } from '@lingui/react';
import { withCookies } from 'react-cookie';
import React, { useContext, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import ResourceAdapter from '../../../Service/ResourceAdapter';
import { ajaxActions } from '../../../Service/AjaxActions';
import { MessageContext } from '../../../Service/Context/MessageContextV2';

const MessageTemplateProvider = ({ message: initialMessage }) => {
  const { type: messageType, name } = useParams();
  const [message, setMessage] = useState(initialMessage);

  return (
    <MessageContext.Provider
      value={{
        message,
        changeMessage: (patch) => {
          setMessage((prevState) => ({
            ...prevState,
            ...patch,
          }));
        },
        saveMessage: (patch) =>
          ajaxActions
            .patch(
              `${process.env.REACT_APP_MESSAGE_SERVICE_URL}/email/template/${messageType.toUpperCase()}${
                name ? `?name=${name}` : ''
              }`,
              patch,
            )
            .then((resp) => {
              if (resp.ok) {
                setMessage((prevState) => ({
                  ...prevState,
                  ...patch,
                }));
              }
              return resp;
            })
            .catch((resp) => {
              throw resp;
            }),
      }}
    >
      <Outlet />
    </MessageContext.Provider>
  );
};

export default withCookies(
  withI18n()((outerProps) => {
    const { type, name } = useParams();

    return (
      <ResourceAdapter
        serviceUrl={`${process.env.REACT_APP_MESSAGE_SERVICE_URL}/email/template?emailTemplate=${type.toUpperCase()}${
          name ? `&name=${name}` : ''
        }`}
        component={(props) => <MessageTemplateProvider message={props.data} {...outerProps} />}
      />
    );
  }),
);
