import { Trans } from '@lingui/react';
import React from 'react';
import PropTypes from 'prop-types';

export const InputHelp = ({ errorDescription }) => (
  <div className="help-block">
    <Trans id={errorDescription} />
  </div>
);

InputHelp.propTypes = {
  errorDescription: PropTypes.string.isRequired,
};
