import { Checkbox, TextInput } from '@mantine/core';
import TaskGroupSelection from '../../../Molecules/Event/Tasks/TaskGroupSelection';

const RecurringTaskFlow1 = ({
  description,
  setDescription,
  notes,
  setNotes,
  groupName,
  setGroupName,
  priority,
  setPriority,
}) => (
  <div>
    <TaskGroupSelection
      style={{ color: '#000000' }}
      entityType="events"
      groupName={groupName}
      onGroupNameChanged={setGroupName}
    />
    <TextInput
      onChange={(e) => {
        setDescription(e.target.value);
      }}
      value={description}
      description="Aufgabe"
      sx={{ marginTop: 10 }}
    />
    <TextInput
      sx={{ marginTop: 10 }}
      onChange={(e) => {
        setNotes(e.target.value);
      }}
      value={notes}
      description="Notizen"
      placeholder="Diese Aufgabe hat keine Notizen."
    />

    <div style={{ flex: 1, marginTop: 20 }}>
      <Checkbox
        checked={priority}
        onChange={(e) => {
          setPriority(e.currentTarget.checked);
        }}
        label="Wichtige Aufgabe"
      />
    </div>
  </div>
);

export default RecurringTaskFlow1;
