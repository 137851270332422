import { FC, useEffect, useState } from 'react';
import { Skeleton, Text } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { CreateContractConfigDTO, ProcedureReportProps } from '../../../../Types/LogT';
import { fetchContractTemplate } from '../../../../Service/restapi/contractService';

const CreateContractReport: FC<ProcedureReportProps> = ({ report, config }) => {
  const { templateId } = config as CreateContractConfigDTO;
  const { entityId, executionStatus } = report ?? {};
  const navigate = useNavigate();
  const { id: eventId } = useParams();
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState<any>();
  const contractCreated = executionStatus === 'COMPLETED';

  const onClick = () => {
    if (eventId && contractCreated) {
      navigate(`/events/${eventId}/contracts/${entityId}`);
    }
  };

  useEffect(() => {
    if (templateId) {
      setLoading(true);
      fetchContractTemplate(templateId)
        .then((resJSON) => {
          if (resJSON) {
            setTemplate(resJSON);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [templateId]);

  return (
    <Text>
      <Text
        span
        weight="bolder"
        onClick={onClick}
        variant={contractCreated ? 'link' : 'text'}
        color={contractCreated ? 'blue' : 'inherit'}
        sx={{ cursor: contractCreated ? 'pointer' : 'default' }}
      >
        Vertrag
      </Text>{' '}
      wurde aus Vorlage{' '}
      {loading || !template ? (
        <Skeleton mb={-3} height={16} width={100} sx={{ display: 'inline-block' }} />
      ) : (
        <Text
          span
          weight="bolder"
          color="blue"
          variant="link"
          sx={{ cursor: 'pointer' }}
          onClick={() => navigate(`/settings/template/contract/${template.name}`)}
        >
          {template.name}
        </Text>
      )}{' '}
      erstellen.
    </Text>
  );
};

export default CreateContractReport;
