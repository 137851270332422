import { Affix, Box, Button, Center, Space, Stack, Text, Tooltip } from '@mantine/core';
import { FC, useContext, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router-dom';
import {
  IconAddressBook,
  IconCalendarEvent,
  IconChartBar,
  IconCircles,
  IconFileDescription,
  IconListCheck,
  IconLogout,
  IconMailbox,
  IconSchool,
  IconSettings,
  IconSwitchHorizontal,
  TablerIcon,
} from '@tabler/icons';
import { closeModal, openModal } from '@mantine/modals';
import { AuthContext } from '../Service/Context/AuthContext';
import { ProfileContext } from '../Service/Context/ProfileContext';
import { RoutingS } from '../Service/RoutingS';
import { TestS } from '../TestS';
import { ConstantS } from '../Service/ConstantS';
import { MixpanelS } from '../Service/MixpanelS';
import { LicenceS } from '../Service/LicenceS';
import { EventsContext } from '../Service/Context/EventsContext';
import { ReactComponent as IconBookitupCircle } from './bookitup-signet.svg';
import NotificationNavIcon from './Notifications/NotificationNavIcon';

type NavigationLink = {
  icon: TablerIcon;
  disabled?: boolean;
  label: string;
  internalLabel?: string;
  path: string;
  hoverColor?: string;
  onClick?: () => void;
  hidden?: boolean;
  testId?: string;
};

const SecondaryNavLink: FC<{ link: NavigationLink }> = ({ link }) => {
  const { hoverColor, path, onClick, hidden, testId, disabled, label } = link;
  const { pathname } = useLocation();
  const [hover, setHover] = useState<boolean>(false);
  const navigate: NavigateFunction = useNavigate();
  const active = pathname.includes(path);
  // eslint-disable-next-line no-nested-ternary
  const itemColor = hover ? hoverColor : active ? '#1D5191' : 'gray';

  if (hidden) {
    return null;
  }

  return (
    <Tooltip label={label} position="right" withArrow transition="slide-right" key={label}>
      <Box
        data-testid={testId}
        onClick={() => {
          if (!disabled) {
            if (onClick) {
              onClick();
            } else {
              navigate(link.path);
            }
          } else {
            openForceEventCreationModal();
          }
        }}
        sx={{
          width: 40,
          height: 40,
          cursor: disabled ? 'not-allowed' : 'pointer',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <link.icon
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{ color: itemColor }}
        />
      </Box>
    </Tooltip>
  );
};

const PrimaryNavbar: FC = () => {
  const { logout } = useContext(AuthContext);
  const { pathname } = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const { hasSwitchableProfiles, switchProfile, loadingProfiles } = useContext(ProfileContext);
  const { profile } = useContext<any>(ProfileContext);
  const { totalCount } = useContext(EventsContext);

  const forceEventView = false; // totalCount === 0 && profile && profile.id % 2 === 1;

  const primaryNavigationLinks = [
    {
      icon: IconCalendarEvent,
      label: 'Events',
      internalLabel: 'Events',
      path: '/',
      testId: TestS.Navigate.TO_EVENTS,
      disabled: false,
    },
  ];

  if (profile && LicenceS.hasAccessToResourceModule(profile.id)) {
    primaryNavigationLinks.push({
      icon: IconCircles,
      label: 'Ressourcen',
      internalLabel: 'Resources',
      path: '/resources/all',
      testId: TestS.Navigate.TO_RESOURCES,
      disabled: forceEventView,
    });
  }

  primaryNavigationLinks.push(
    {
      icon: IconListCheck,
      label: 'Aufgaben',
      internalLabel: 'Tasks',
      path: '/tasks',
      testId: TestS.Navigate.TO_TASKS,
      disabled: forceEventView,
    },
    {
      icon: IconMailbox,
      label: 'Postfach',
      internalLabel: 'Inbox',
      path: '/postbox/inbox',
      testId: TestS.Navigate.TO_MAILS,
      disabled: forceEventView,
    },
    {
      icon: IconAddressBook,
      label: 'Kontakte',
      internalLabel: 'Contacts',
      path: '/contacts',
      testId: TestS.Navigate.TO_CONTACTS,
      disabled: forceEventView,
    },
    {
      icon: IconFileDescription,
      label: 'Dokumente',
      internalLabel: 'Documents',
      path: '/documents',
      testId: TestS.Navigate.TO_DOCUMENTS,
      disabled: forceEventView,
    },
    {
      icon: IconChartBar,
      label: 'Statistiken',
      internalLabel: 'Statistics',
      path: '/statistics?year=2023',
      testId: TestS.Navigate.TO_STATISTICS,
      disabled: forceEventView,
    },
  );

  const secondaryNavigationLinks = [
    {
      icon: IconSwitchHorizontal,
      label: 'switch profile',
      path: 'foo',
      hoverColor: '#212121',
      onClick: switchProfile,
      hidden: loadingProfiles || !hasSwitchableProfiles,
    },
    {
      icon: IconSettings,
      label: 'Einstellungen',
      path: '/settings',
      hoverColor: '#212121',
      onClick: () => {
        navigate('/settings/userdata');
        MixpanelS.track(ConstantS.TrackingEvents.ViewContent, { page: 'Settings' });
      },
      testId: TestS.Navigate.TO_SETTINGS,
      disabled: forceEventView,
    },
    {
      icon: IconSchool,
      label: 'Akademie',
      path: 'foo',
      hoverColor: '#212121',
      onClick: () => navigate('/academy/welcome'),
    },
    {
      icon: IconLogout,
      label: 'Abmelden',
      path: '/logout',
      hoverColor: 'red',
      onClick: () => {
        MixpanelS.track(ConstantS.TrackingEvents.Logout);
        logout();
      },
    },
  ];

  const navLinkActive = (linkPath: string) =>
    linkPath === '/' ? linkPath === pathname || pathname.startsWith('/events') : pathname.startsWith(linkPath);

  const mapPrimaryNavLink = (link: NavigationLink) => {
    const { path, label, internalLabel, testId, disabled } = link;
    const active = navLinkActive(path);

    // eslint-disable-next-line no-underscore-dangle
    const _onClick = () => {
      navigate(path);
      if (internalLabel) {
        MixpanelS.track(ConstantS.TrackingEvents.ViewContent, { page: internalLabel });
      }
    };

    return (
      <Tooltip label={label} position="right" withArrow transition="slide-right" key={label}>
        <Box
          onClick={() => {
            if (!disabled) {
              _onClick();
            } else {
              openForceEventCreationModal();
            }
          }}
          sx={(theme) => ({
            backgroundColor: active && theme.colors.lightBlue ? theme.colors.lightBlue[0] : 'transparent',
            width: 40,
            height: 40,
            cursor: disabled ? 'not-allowed' : 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 10,
            '&:hover': {
              backgroundColor: theme.colors.gray[0],
            },
          })}
          data-testid={testId}
        >
          <link.icon style={{ color: active ? '#1D5191' : disabled ? 'grey' : '#212121' }} />
        </Box>
      </Tooltip>
    );
  };

  return (
    <>
      <Stack spacing={10} p="sm" sx={{ width: 60 }}>
        <Center>
          <IconBookitupCircle
            height={33}
            width={33}
            onClick={() => navigate(RoutingS.ROUTES.HOMEPAGE)}
            style={{ cursor: 'pointer' }}
          />
        </Center>
        <Space h={2} />
        <NotificationNavIcon />
        {primaryNavigationLinks.map(mapPrimaryNavLink)}
      </Stack>
      <Affix position={{ bottom: 30, left: 12 }}>
        <Stack>
          {secondaryNavigationLinks.map((link) => (
            <SecondaryNavLink key={link.label} link={link} />
          ))}
        </Stack>
      </Affix>
    </>
  );
};

export const openForceEventCreationModal = () =>
  openModal({
    modalId: 'force-event-creation-modal',
    title: (
      <Text weight="bolder" size="xl" align="center">
        ⚠️ Noch kein Event erstellt
      </Text>
    ),
    children: (
      <Text size="sm">
        Um diesen Bereich freizuschalten, erstelle bitte zuerst ein Event.
        <Text size="xs" color="dimmed" sx={{ marginTop: 10 }}>
          Klicke dafür auf das blaue Plus, wähle ein Event-Datum aus und gib einen Kundennamen an.
        </Text>
        <Center>
          <Button mt="xl" onClick={() => closeModal('force-event-creation-modal')} sx={{ width: 250 }}>
            Alles klar!
          </Button>
        </Center>
      </Text>
    ),
  });

export default PrimaryNavbar;
