import { FC, Key, useState } from 'react';
import { i18n } from '@lingui/core';
import { useDisclosure } from '@mantine/hooks';
import { IconChevronDown, IconChevronRight, IconChevronDownLeft } from '@tabler/icons';
import { useNavigate } from 'react-router-dom';
import { closeAllModals } from '@mantine/modals';
import { Part, Resource, TableResponsiveProps } from '../../../../Types/ResourceT';
import NumberCounter from '../../../../Atoms/inputs/NumberCounter';
import ResourceAvailabilityDot from '../../../Settings/Documents/Positions/ResourceAvailabilityDot';

interface Props extends TableResponsiveProps {
  key: Key;
  resource: Resource;
  depth?: number;
  from?: Date;
  to?: Date;
}

const ResourceAvailabilityRow: FC<Props> = ({
  key,
  resource,
  depth = 0,
  showCategory = true,
  showType = true,
  showDescription = true,
  from,
  to,
}) => {
  const { id, type, name, content, category, description } = resource;
  const navigate = useNavigate();
  const [opened, { toggle }] = useDisclosure(false);
  const [quantity, setQuantity] = useState(1);
  const isItem = type === 'ITEM';

  const mapResourcePartUI = (part: Part) => {
    const { resource: resourcePart } = part;
    return (
      <ResourceAvailabilityRow
        key={key}
        resource={resourcePart}
        depth={depth + 1}
        showCategory={showCategory}
        showType={showType}
        showDescription={showDescription}
      />
    );
  };

  const onClick = () => {
    navigate(`/resources/${id}/details`);
    closeAllModals();
  };

  return (
    <>
      <tr key={key}>
        <td style={{ paddingLeft: depth * 10 }}>
          {!isItem && content.length > 0 && (
            <>
              {opened && <IconChevronDown size={20} onClick={toggle} style={{ cursor: 'pointer' }} />}
              {!opened && <IconChevronRight size={20} onClick={toggle} style={{ cursor: 'pointer' }} />}
            </>
          )}
          {isItem && depth > 0 && <IconChevronDownLeft size={20} />}
        </td>
        <td style={{ width: 40 }}>
          {depth === 0 && (
            <ResourceAvailabilityDot resourceId={resource.id} amount={quantity.toString()} from={from} to={to} />
          )}
        </td>
        {showType && <td style={{ minWidth: 51, cursor: 'pointer' }}>{i18n._(`resource.type.${type}`)}</td>}
        <td onClick={onClick} className="overflow-ellipsis" style={{ maxWidth: 140, cursor: 'pointer' }}>
          {name}
        </td>
        {showCategory && (
          <td onClick={onClick} className="overflow-ellipsis" style={{ maxWidth: 80, cursor: 'pointer' }}>
            {category?.name}
          </td>
        )}
        {showDescription && (
          <td onClick={onClick} className="overflow-ellipsis" style={{ maxWidth: 150, cursor: 'pointer' }}>
            {description}
          </td>
        )}
        <td>
          <NumberCounter deleteDisabled count={quantity} onChange={setQuantity} />
        </td>
      </tr>
      {content.length > 0 && opened && content.map(mapResourcePartUI)}
    </>
  );
};

export default ResourceAvailabilityRow;
