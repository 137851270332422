import React, { useState, useContext } from 'react';
import { Trans } from '@lingui/react';
import axios from 'axios';
import { TransButtonDefault, TransButtonPrimary } from '../../Atoms/Buttons';
import { ModalDialog } from '../Dialogs';
import { TransTextInputField } from '../../Atoms/InputFields';
import { ApplicationUserContext } from '../../Service/Context/ApplicationUserContext';
import { AuthContext } from '../../Service/Context/AuthContext';
import {ToastS} from "../../Service/ToastS";

export default function ({ open, toggle }) {
  const { handleApplicationUserChange } = useContext(ApplicationUserContext);
  const { jwtClaims } = useContext(AuthContext);
  const [email, setEmail] = useState(jwtClaims.email);

  function save() {
    handleApplicationUserChange({ email });
    const serviceUrl = `${process.env.REACT_APP_AUTH_SERVICE_URL}/applicationUsers/own`;
    axios
      .patch(
        serviceUrl,
        { email },
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            // "If-Unmodified-Since": lastModified
          },
          withCredentials: true,
        },
      )
      .then((resp) => {
        ToastS.success('email-changed', 'E-Mail erfolgreich geändert.');
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          ToastS.error('email-not-changed', 'E-Mail konnte nicht geändert werden.');
          if (error.response) {
            console.error(error.response);
          } else {
            console.error(`Failed to load count for ${serviceUrl} because of a network error`);
            console.error(error.message);
          }
        }
      });
  }

  return (
    <div>
      <ModalDialog
        header={<Trans id="actions.change-email" />}
        body={
          <div>
            <TransTextInputField
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              name="email"
              value={email}
              placeholder="contact.email"
            />
          </div>
        }
        footer={
          <div>
            <TransButtonPrimary
              id="changeEmail"
              onClickHandler={() => {
                save();
                toggle();
              }}
              text="actions.change-email"
            />{' '}
            <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />
          </div>
        }
        show={open}
        toggle={toggle}
      />
    </div>
  );
}
