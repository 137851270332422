import React, { Fragment, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { Trans } from '@lingui/react';
import { FocusedNodeContext } from '../Service/Context/FocusedNode';

const MultiAutocomplete = ({
  suggestions,
  value,
  formatter,
  reload,
  patchField,
  subPatchField,
  index,
  placeholder,
  className,
  saveValue,
  style,
  listTopComponent,
  listValueComponent,
  autoFocus,
  inlinePlaceholder,
  isLoading,
  previewOnNewSuggestions,
  filterSuggestions,
  remove,
}) => {
  const [activeSuggestion, setActiveSuggestion] = useState(-1);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [userInput, setUserInput] = useState(value);
  const [inputRef] = useState(React.createRef());
  const { setFocusedNode, setListPosition } = useContext(FocusedNodeContext);
  let internValue;
  const [startMove, setStartMove] = useState(undefined);

  useEffect(() => {
    setUserInput(value);
  }, [value]);

  useEffect(() => {
    setUserInput(value);
  }, [patchField]);

  // new suggestions e.g. by ajax call
  useEffect(() => {
    if (previewOnNewSuggestions) {
      setShowSuggestions(true);
      setFilteredSuggestions(suggestions);
    }
  }, [suggestions]);

  useEffect(() => {
    if (autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  function loadSuggestions(fastInternValue) {
    let tmpValue = internValue;

    if (fastInternValue) {
      tmpValue = fastInternValue;
    }
    // Filter our suggestions that don't contain the user's input
    // and is not equal the suggested one userInput !== suggestion
    let newFilteredSuggestions = Object.assign([], suggestions);
    if (tmpValue !== null && tmpValue !== '') {
      if (typeof filterSuggestions === 'function') {
        newFilteredSuggestions = suggestions.filter((suggestion) => filterSuggestions(suggestion, tmpValue));
      } else {
        newFilteredSuggestions = suggestions.filter((suggestion) => {
          if (suggestion !== null && suggestion[patchField] && suggestion[patchField] !== null && suggestion) {
            if (!tmpValue) return true;
            return (
              suggestion[patchField].toLowerCase().indexOf(tmpValue.toLowerCase()) > -1 &&
              suggestion[patchField] !== tmpValue
            );
          }
          return true;
        });
      }

      setShowSuggestions(newFilteredSuggestions.length > 0);
      setFilteredSuggestions(newFilteredSuggestions);
    } else {
      setShowSuggestions(true);
      setFilteredSuggestions(newFilteredSuggestions);
    }
  }

  function onInputChange(e) {
    let tmpValue = e.target.innerHTML;

    // remove line break, if user hits enter to apply selected value
    tmpValue = tmpValue.replace('<br>', '');

    internValue = tmpValue;
    loadSuggestions(tmpValue);

    if (activeSuggestion > -1 && filteredSuggestions[activeSuggestion]) {
      setUserInput(filteredSuggestions[activeSuggestion][patchField]);
    }
  }

  // select suggestion
  function onClick(suggestion) {
    const newUserInput = Object.assign([], userInput);
    newUserInput.push(suggestion);
    inputRef.current.innerHTML = '';
    if (typeof saveValue === 'function') {
      saveValue({ [patchField]: newUserInput });
    }
    setUserInput(newUserInput);
    internValue = null;

    setShowSuggestions(false);
  }

  // navigate through suggestions and select
  function onKeyDown(e) {
    // User pressed the enter key
    if (e.key === 'Escape') {
      inputRef.current.innerHTML = '';
      setActiveSuggestion(-1);
      setShowSuggestions(false);
    }
    if (e.keyCode === 13) {
      if (filteredSuggestions.length > 0 && activeSuggestion !== -1 && filteredSuggestions[activeSuggestion]) {
        if (activeSuggestion !== -1 && filteredSuggestions[activeSuggestion]) {
          const newUserInput = Object.assign([], userInput);
          newUserInput.push(filteredSuggestions[activeSuggestion]);
          setUserInput(newUserInput);
          inputRef.current.innerHTML = '';

          saveValue({ [patchField]: newUserInput });
          setActiveSuggestion(-1);
          setShowSuggestions(false);
        }
      } else {
        inputToNewContact();
      }
      e.preventDefault();
    }
    // User pressed the up arrow
    else if (e.keyCode === 38 && showSuggestions) {
      if (activeSuggestion === -1) {
        return;
      }

      setActiveSuggestion(activeSuggestion - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40 && showSuggestions) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }
      if (filteredSuggestions.length > activeSuggestion + 1) {
        setActiveSuggestion(activeSuggestion + 1);
      }
    } else if (e.keyCode === 8) {
      loadSuggestions();
    }
  }

  function inputToNewContact() {
    // TODO: validate email
    let input = inputRef.current.innerHTML;
    input = input.replace(/&nbsp;/g, '');
    input = input.replace(/<br>/g, '');
    input = input.trim();
    if (input !== '') {
      const newUserInput = Object.assign([], userInput);
      if (subPatchField) {
        newUserInput.push({ [subPatchField]: input });
      } else {
        newUserInput.push({ [patchField]: input });
      }
      setUserInput(newUserInput);
      saveValue({ [patchField]: newUserInput });
      inputRef.current.innerHTML = '';
    }
  }

  let suggestionsListComponent;

  const styles = {
    input: {
      padding: '0.375rem 0.75rem',
      border: '1px solid #B7B7B7',
      borderRadius: '4px',
      // height: 34,
      display: 'flex',
      flexWrap: 'wrap',
    },
    label: {
      color: '#6E6E6E',
    },
    suggestionsList: {
      position: 'absolute',
      zIndex: 1,
      backgroundColor: '#ffffff',
      borderRadius: 4,
      padding: 2,
    },
  };

  if (showSuggestions) {
    if (filteredSuggestions.length) {
      suggestionsListComponent = (
        <div style={styles.suggestionsList}>
          <ul className="suggestions">
            {listTopComponent || undefined}
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = 'suggestion-active';
              }
              const ListValueComponent = listValueComponent;
              return (
                <li
                  className={className}
                  key={index}
                  onTouchMove={() => setStartMove(true)}
                  onTouchEnd={() => {
                    if (!startMove) onClick(suggestion);
                  }}
                  onMouseDown={() => {
                    onClick(suggestion);
                  }}
                >
                  {listValueComponent ? (
                    <ListValueComponent suggestion={suggestion} />
                  ) : (
                    <div dangerouslySetInnerHTML={{ __html: suggestion[patchField] }} />
                  )}
                </li>
              );
            })}
          </ul>
        </div>
      );
    } else {
      suggestionsListComponent = null;
    }
  }
  const Formatter = formatter;
  return (
    <>
      {placeholder !== undefined && (
        <Label style={styles.label} for={patchField} sm={12}>
          <Trans id={placeholder} />
        </Label>
      )}
      <div className={isLoading ? `loading ${className}` : className} style={{ ...styles.input, ...style }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {userInput != null
            ? userInput.map((val, index) => (
                <Formatter key={index} value={val} patchField={subPatchField || patchField} remove={remove} />
              ))
            : null}
        </div>
        <div
          ref={inputRef}
          style={{ width: '100%', marginLeft: 5, minHeight: 35 }}
          contentEditable
          onInput={onInputChange}
          onKeyDown={onKeyDown}
          spellCheck
          onBlur={(e) => {
            inputToNewContact();
            setShowSuggestions(false);
          }}
          placeholder={inlinePlaceholder}
          onClick={() => loadSuggestions()}
          onFocus={(e) => {
            if (typeof reload === 'function') {
              reload(userInput);
            }
            loadSuggestions();
            setListPosition(index);
            setFocusedNode(e.target);
          }}
        />
      </div>
      {suggestionsListComponent}
    </>
  );
};

MultiAutocomplete.propTypes = {
  suggestions: PropTypes.instanceOf(Array),
};

export default MultiAutocomplete;
