// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { BookitupNotification } from '../../../../Types/LogT';

const OfferConfirmed: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { eventId, entityId } = notification;
  const navigate = useNavigate();

  return (
    <Text>
      <Text
        span
        weight="bolder"
        variant="link"
        color="blue"
        sx={{ cursor: 'pointer' }}
        onClick={() => navigate(`/events/${eventId}/documents/${entityId}/preview`)}
      >
        Angebot
      </Text>
      {'  '}
      wurde angenommen.
    </Text>
  );
};

export default OfferConfirmed;
