import { ajaxActions } from '../AjaxActions';

const BASE_URL = process.env.REACT_APP_DOCUMENT_SERVICE_URL;

export const saveTemplate = (templateName, documentId) =>
  ajaxActions.put(`${BASE_URL}/templates/${templateName}?documentId=${documentId}`).then((resp) => resp);

export const savePatch = (templateId, patch) =>
  ajaxActions.patch(`${BASE_URL}/templates/${templateId}`, patch).then((resp) => resp);

export const addTemplatePositionAtIndex = (templateId, positionIndex) =>
  ajaxActions.put(`${BASE_URL}/templates/${templateId}/positions?index=${positionIndex}`).then((resp) => resp);

export const moveTemplatePosition = (templateId, fromIndex, newIndex) =>
  ajaxActions
    .patch(`${BASE_URL}/templates/${templateId}/positions?fromIndex=${fromIndex}&newIndex=${newIndex}`)
    .then((resp) => resp);

export const deleteTemplatePositionAtIndex = (templateId, positionIndex) =>
  ajaxActions.del(`${BASE_URL}/templates/${templateId}/positions?index=${positionIndex}`).then((resp) => resp);

export const fetchDocumentTemplatesByType = (documentType) =>
  ajaxActions.get(`${BASE_URL}/documentTemplates/${documentType.toUpperCase()}`).then((resp) => resp);

export const getDocumentTemplateNames = (documentType) =>
  ajaxActions.get(`${BASE_URL}/report/template/${documentType}/names`).then((resp) => resp);

export const newDocumentOfDocumentAndTemplate = (documentType, templateName, documentId) =>
  ajaxActions
    .put(`${BASE_URL}/report/template/${documentType}/${templateName}?documentId=${documentId}`)
    .then((resp) => resp);

export const createTemplateByDocumentTypeAndName = (documentType, templateName) =>
  ajaxActions.put(`${BASE_URL}/templates/${documentType}/${templateName}`).then((resp) => resp);
