import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { createLog, getLogs } from '../../Service/restapi/logService';
import { ChangeListS } from '../../Service/helper/ChangeListS';
import NoteItem from './NoteItem';
import { Skeleton, Text } from '@mantine/core';
import PanePaper from '../PanePaper';
import BookitupAddIcon from '../../Atoms/BookitupAddIcon';
import { EventContext } from '../../Service/Context/EventContext';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import { LicenceS } from '../../Service/LicenceS';

export default function ({ asideBar }) {
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const { event } = useContext(EventContext);
  const { id: eventId } = event;
  const [notes, setNotes] = useState(undefined);

  useEffect(() => {
    getLogs('notes', 'events', eventId).then((resp) => {
      if (resp.ok) {
        resp.json().then((logs) => {
          setNotes(logs);
        });
      }
    });
  }, [eventId]);

  function handleChange(patch) {
    ChangeListS.changeItemInList(notes, setNotes, patch);
  }

  function deleteNote(noteId) {
    ChangeListS.deleteItemInList(notes, setNotes, noteId);
  }

  function newLog() {
    const id = uuidv4();
    createLog(id, 'notes', 'events', eventId).then((resp) => {
      if (resp.ok) {
        resp.json().then((note) => {
          const tmpNotes = Object.assign([], notes);
          tmpNotes.push(note);
          setNotes(tmpNotes);
        });
      }
    });
  }

  return (
    <PanePaper
      withShadow={asideBar}
      withoutBorder={asideBar}
      title="common.notes"
      tooltip="help.event.notes.pane"
      rightSection={
        notes && notes.length > 0 ? (
          <BookitupAddIcon
            onClick={() => executeWithLicenceCheck(LicenceS.Restrictions.MULTIPLE_EVENT_NOTES, newLog)}
          />
        ) : (
          <BookitupAddIcon onClick={newLog} />
        )
      }
    >
      {!notes ? (
        <Skeleton width={'100%'} height={50} />
      ) : (
        <>
          {notes.length === 0 ? (
            <Text italic color="dimmed" size="xs" weight="lighter">
              Noch nichts hinzugefügt.
            </Text>
          ) : (
            <>
              {notes.map((note, index) => (
                <NoteItem key={index} note={note} handleChange={handleChange} deleteNote={deleteNote} />
              ))}
            </>
          )}
        </>
      )}
    </PanePaper>
  );
}
