import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EditableProperty } from './EditableProperty';

export const EditableCustomAttributeValueProperty = ({
  entityId,
  attribute,
  handleChange,
  value,
  placeholder,
  editFormatter,
  labelFormatter,
  ...rest
}) => (
  <EditableProperty
    dto={value}
    changeHandler={handleChange}
    patchField="value"
    placeholder={placeholder}
    targetUrl={`${process.env.REACT_APP_ATTRIBUTE_SERVICE_URL}/attributes/${attribute.id}/values/${entityId}`}
    editFormatter={editFormatter}
    labelFormatter={labelFormatter}
    {...rest}
  />
);

EditableCustomAttributeValueProperty.propTypes = {
  entityId: PropTypes.number.isRequired,
  attribute: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  value: PropTypes.shape({
    value: PropTypes.any,
    lastModified: PropTypes.string.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
};
