import { useEffect, useState } from 'react';
import { fetchTasksForGroup } from '../../../Service/restapi/taskService';
import { TaskField } from './TaskField';
import { Accordion, Stack } from '@mantine/core';
import { IconChevronRight } from '@tabler/icons';

export const TaskGroup = ({ group, entityId, lastModified }) => {
  const [tasks, setTasks] = useState();

  const { id, name } = group;

  useEffect(() => {
    loadTasks();
  }, [lastModified, group]);

  if (!tasks || tasks.length === 0) {
    return null;
  }

  function loadTasks() {
    fetchTasksForGroup(id, entityId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((tasks) => {
            setTasks(tasks);
          });
        } else {
          console.error(`could not load tasks of list ${name}`);
        }
      })
      .catch(() => {
        console.error(`could not load tasks of list ${name}`);
      });
  }

  return (
    <Accordion
      sx={{ flexGrow: 1 }}
      chevron={<IconChevronRight size="1rem" />}
      styles={{
        chevron: {
          '&[data-rotate]': {
            transform: 'rotate(90deg)',
          },
        },
      }}
      variant="separated"
      defaultValue="foo"
      chevronPosition="left"
    >
      <Accordion.Item value="foo">
        <Accordion.Control>{name}</Accordion.Control>
        <Accordion.Panel>
          <Stack>
            {tasks.map((task, index) => (
              <TaskField key={index} task={task} entityId={entityId} />
            ))}
          </Stack>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
};
