import { FC, ReactNode } from 'react';

interface Props {
  id: string;
  onSelectFile: (file: File) => unknown;
  label: ReactNode;
  accept?: string;
}

const FilePicker: FC<Props> = ({ id, onSelectFile, label, accept = 'image/png, image/jpeg' }) => (
  <>
    <label htmlFor={`file-input-${id}`} style={{ display: 'flex' }}>
      {label}
    </label>
    <input
      id={`file-input-${id}`}
      type="file"
      name="file"
      accept={accept}
      style={{ display: 'none' }}
      onChange={(event) => event.target.files && onSelectFile(event.target.files[0])}
    />
  </>
);

export default FilePicker;
