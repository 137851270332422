import { DefaultMantineColor, MantineNumberSize, NavLink } from '@mantine/core';
import { FC, ReactNode } from 'react';

type Props = {
  opened?: boolean;
  defaultOpened?: boolean;
  active?: boolean;
  label?: ReactNode;
  icon?: ReactNode;
  childrenOffset?: MantineNumberSize;
  children?: ReactNode;
  onClick?: () => void;
  variant?: 'filled' | 'light' | 'subtle' | undefined;
  color?: DefaultMantineColor | undefined;
  rightSection?: ReactNode;
  testId?: string
};

const NavigationLink: FC<Props> = (props) => {
  const {
    opened,
    defaultOpened,
    active,
    label,
    icon,
    childrenOffset,
    children,
    onClick,
    variant,
    color,
    rightSection,
    testId
  } = props;

  return (
    <NavLink
      defaultOpened={defaultOpened}
      opened={opened}
      active={active}
      label={label}
      icon={icon}
      childrenOffset={childrenOffset}
      color={color ?? 'lightBlue'}
      styles={{
        body: {
          color: active ? '#1D5191' : '#282828',
          fontWeight: active ? 'bold' : 'normal',
        },
        icon: {
          color: active ? '#1D5191' : '#282828',
        },
      }}
      onClick={onClick}
      variant={variant}
      rightSection={rightSection}
      data-testid={testId}
    >
      {children}
    </NavLink>
  );
};

export default NavigationLink;
