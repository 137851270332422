import { Box, Code, CopyButton, ActionIcon, Tooltip } from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons';
import { FC } from 'react';

const CodeWithCopy: FC<{ content: string }> = ({ content }) => (
  <Box pos="relative">
    <Code block>
      {content}
      <Box sx={{ position: 'absolute', right: 5, top: 5 }}>
        <CopyButton value={content} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip label={copied ? 'Kopiert' : 'Code kopieren'} withArrow position="right">
              <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" color="black" />}
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      </Box>
    </Code>
  </Box>
);

export default CodeWithCopy;
