import { useElementSize } from '@mantine/hooks';
import { FC, useContext, useEffect, useState } from 'react';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import BookitupTable from '../../../Atoms/BookitupTable';
import PanePaper from '../../../Molecules/PanePaper';
import ContactFormsNotFound from '../../../Organismns/Tables/ContactFormsNotFound';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import SemanticForm from '../../../Types/SemanticForm';
import SkeletonItems from '../../../Atoms/SkeletonItems';
import { SemanticFormS } from '../../../Service/restapi/semanticFormService';
import { RoutingS } from '../../../Service/RoutingS';
import ContactFormListItem from './ContactFormListItem';
import { openCreateContactFormModal } from './CreateContactFormModal';
import {Col, Row} from "reactstrap";
import {Button, Space} from "@mantine/core";
import {IconCalendarTime} from "@tabler/icons";
import BookitupTutorialIcon from "../../../Atoms/BookitupTutorialIcon";

const ContactFormList: FC = () => {
  const { ref, width } = useElementSize();
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const [forms, setForms] = useState<SemanticForm[]>([]);
  const [loading, setLoading] = useState(false);
  const { length } = forms;

  useEffect(() => {
    fetchForms();
    RoutingS.changeTitle('Kontaktformulare');
  }, []);

  const fetchForms = () => {
    setLoading(true);
    SemanticFormS.getForms()
      .then(setForms)
      .finally(() => setLoading(false));
  };

  const showColumns = {
    showDatum: width > 400,
    showName: width > 150,
    showCount: width > 300,
    narrowActions: true,
  };

  const onClick = () => {
    if (length > 0) {
      executeWithLicenceCheck(LicenceS.Restrictions.CONTACT_FORM, openCreateContactFormModal);
    } else {
      openCreateContactFormModal();
    }
  };

  return (
    <>
      <PanePaper title="Kontaktformulare"  rightSection={
        <>
          <BookitupTutorialIcon videoUrl={"https://www.youtube.com/watch?v=gVUX6-EGbdM"} name={"CreateContactForm"} />
          <BookitupAddIcon onClick={onClick} />
        </>
      } sx={{ maxWidth: 900 }}>
        Erstelle Dir mit wenigen Klicks ein Kontaktformular und füge es auf Deiner Webseite als HTML-Baustein hinzu.
        <Space h="xl" />

        <div ref={ref}>
          {loading && <SkeletonItems height={40} />}
          {!loading && length === 0 && <ContactFormsNotFound message="Keine Formulare gefunden." onClick={onClick} />}
          {!loading && length > 0 && (
            <BookitupTable>
              <thead>
              <tr>
                {showColumns.showName && <th>Bezeichnung </th>}
                {showColumns.showDatum && <th>Erstellungsdatum</th>}
                {showColumns.showCount && <th>Einsendungen</th>}
                <th> </th>
              </tr>
              </thead>
              <tbody>
              {forms.map((form: SemanticForm) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <ContactFormListItem key={form.id} form={form} reload={fetchForms} {...showColumns} />
              ))}
              </tbody>
            </BookitupTable>
          )}
        </div>
      </PanePaper>
      <Space h="sm" />

      <PanePaper title="Eigenes Kontaktformular verbinden lassen" sx={{ maxWidth: 900 }}>
        <Row>
          <Col xs="12">
            Neben der Möglichkeit ein Kontaktformular in bookitup zu erstellen und auf Deiner Webseite einzubinden, gibt es auch die Möglichtkeit Dein bereits vorhandenes Kontaktformular mit bookitup zu verbinden.
            Das bieten wir insbesondere dann an, wenn Du ein Kontaktformular hast, dass sich nicht mit unserem Kontaktformular-Editor nachbauen lässt; beispielsweise Dein Kontaktformular auch Bilder enthalten soll oder mehrschrittig ist.
            <Space h="sm" />

            Die Anbindung erfolgt dann meistens über einen kleinen Code den man im Head-Bereich Deiner Webseite hinzufügt - oder über Tools wie Zapier (eher selten der Fall).
            <Space h="sm" />

            Buche Dir für die persönliche Einrichtung einfach einen Technik-Termin:
            <Space h="sm" />

            <Button
              size="xs"
              leftIcon={<IconCalendarTime size={16} />}
              mt="md"
              onClick={() => RoutingS.openInNewTab('https://hey.bookitup.de/meetings/sebastian-mahlke/technik-support ')}
            >
              Termin vereinbaren
            </Button>
          </Col>
        </Row>
      </PanePaper>
    </>

  );
};

export default ContactFormList;
