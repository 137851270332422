import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {Alert, Box, Group, Space, Text, Title} from '@mantine/core';
import { TransButtonLinkWithIcon } from '../../../Atoms/Buttons';
import { useModalState } from '../../Dialogs';
import { WindowContext } from '../../../Service/Context/WindowContext';
import QuestionnaireTemplateNameModal from '../../../Organismns/Settings/Documents/QuestionnaireTemplates/QuestionnaireTemplateNameModal';
import { QuestionnaireContext } from '../../../Service/Context/QuestionnaireContext';
import { patchQuestionnaireTemplate } from '../../../Service/restapi/questionnaireService';
import QuestionnaireSkeleton from '../../../Organismns/Questionnaire/QuestionnaireSkeleton';
import PanePaper from '../../PanePaper';
import QuestionnaireTemplateActions from './QuestionnaireTemplateActions';
import { MixpanelS } from '../../../Service/MixpanelS';
import { ConstantS } from '../../../Service/ConstantS';
import {IconInfoCircle} from "@tabler/icons";
import { i18n } from '@lingui/core';

const QuestionnaireTemplate = () => {
  const { questionnaire } = useContext(QuestionnaireContext);
  const { show, toggle } = useModalState();
  const { windowWidth } = useContext(WindowContext);
  const navigate = useNavigate();

  useEffect(() => {
    MixpanelS.track(ConstantS.TrackingEvents.QuestionnaireTemplateOpened);
  }, []);

  function changeName(newName) {
    patchQuestionnaireTemplate(questionnaire.id, { name: newName })
      .then((resp) => {
        if (resp.ok) {
          navigate(`/settings/template/questionnaire/${newName}`);
        } else {
          console.error(`could not save template ${questionnaire.name} under new name ${newName}`);
        }
      })
      .catch(() => {
        console.error(`could not save template ${questionnaire.name} under new name ${newName}`);
      });
  }

  return (
    <PanePaper
      rightSection={
        <Box>
          <QuestionnaireTemplateActions />
        </Box>
      }
    >
      <Group>
        <Title order={5}>{questionnaire.name}</Title>
        <TransButtonLinkWithIcon
          icon="EDIT"
          className="primary-link"
          onClick={toggle}
          text={windowWidth > 800 ? 'Namen ändern' : ''}
        />
      </Group>
      <QuestionnaireTemplateNameModal show={show} toggle={toggle} finish={changeName} />
      <Space h="xl" />
      <Alert
        mb="sm"
        icon={<IconInfoCircle />}
        title="Sind im PDF große Bereiche weiß? Dieser Tipp hilft Dir!"
        sx={(theme) => ({ maxWidth: 800, backgroundColor: theme.colors.lightBlue[0] })}
      >
        <Text>{i18n._('questionnaire.tip.helper')}</Text>
      </Alert>
      <PanePaper title="Inhalt">
        <QuestionnaireSkeleton settings />
      </PanePaper>
    </PanePaper>
  );
};

export default QuestionnaireTemplate;
