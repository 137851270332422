/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { LoadingOverlay, Center, Text, Pagination, Stack, Box } from '@mantine/core';
import { useContext } from 'react';
import { useElementSize } from '@mantine/hooks';
import PanePaper from '../../Molecules/PanePaper';
import { ContactsContext } from '../../Service/Context/ContactsContext';
import BookitupTable from '../../Atoms/BookitupTable';
import ContactRow from './ContactRow';

const ContactsTable = () => {
  const PAGE_SIZE = 30;
  const { ref, width } = useElementSize();
  const { filter, totalPages, count, loading, contacts, updateFilter } = useContext(ContactsContext);
  const { pageNumber } = filter;

  const showColumns = {
    showMail: width > 500,
    showPhone: width > 600,
    showGroups: width > 700,
  };

  return (
    <div ref={ref} style={{ position: 'relative' }}>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'xl' }} />
      {!loading && contacts && contacts.length === 0 && <EmptyList />}
      {contacts && contacts.length > 0 && (
        <PanePaper>
          <BookitupTable>
            <thead>
              <tr>
                <th>Name</th>
                {showColumns.showMail && <th>E-Mail</th>}
                {showColumns.showPhone && <th>Telefonnummer</th>}
                {showColumns.showGroups && <th>Gruppen</th>}
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {contacts.map((contact) => (
                <ContactRow key={contact.id.toString()} contact={contact} {...showColumns} />
              ))}
            </tbody>
          </BookitupTable>
        </PanePaper>
      )}
      <Center mt="xl" mb="sm">
        <Pagination
          noWrap
          siblings={1}
          total={totalPages}
          page={pageNumber + 1}
          onChange={(newPageNumber) => updateFilter({ pageNumber: newPageNumber - 1 })}
        />
      </Center>
      {count > 0 && (
        <Center>
          <Text weight="lighter">{`${PAGE_SIZE * pageNumber + 1} - ${
            PAGE_SIZE * (pageNumber + 1) < count ? PAGE_SIZE * (pageNumber + 1) : count
          } von ${count} Kontakte`}</Text>
        </Center>
      )}
    </div>
  );
};

const EmptyList = () => (
  <Stack>
    <Box sx={(theme) => ({ width: '100%', height: 50, backgroundColor: theme.colors.gray[0], borderRadius: 10 })} />
    <Box sx={(theme) => ({ width: '100%', height: 100, backgroundColor: theme.colors.gray[0], borderRadius: 10 })} />
    <Text align="center">
      Die Kombination der ausgewählten <b>Filter</b> erzielt keine Ergebnisse.
    </Text>
  </Stack>
);

export default ContactsTable;
