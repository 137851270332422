import React, {FC} from 'react';
import Contact from "../Types/Contact";

const HubSpotOnboardingFrame: FC<{
    contact: Contact;
  }> = ({ contact }) => (

  <iframe
    title={'hubspot'}
    style={{
      width: '100%',
      height: '690px'
    }}
    src={`https://hey.bookitup.de/meetings/sebastian-mahlke/onboarding?embed=true&firstname=${contact.firstName}&lastname=${contact.lastName}&email=${encodeURIComponent(contact.email)}`}
  />
);

export default HubSpotOnboardingFrame;
