import React, { useContext, useEffect, useState } from 'react';
import MultiAutocomplete from '../../Atoms/MultiAutocomplete';
import { RemovableTextFormatter } from '../../Atoms/Formatter/RemovableTextFormatter';
import { fetchContactGroups } from '../../Service/restapi/contactGroupService';

export default function ({ selectedGroups, saveValue }) {
  const [groups, setGroups] = useState(undefined);

  useEffect(() => {
    fetchContactGroups().then((resp) => {
      if (resp.ok) {
        resp.json().then((groups) => {
          setGroups(groups);
        });
      }
    });
  }, []);

  let groupSelection = [];
  if (groups) {
    groupSelection = groups.map((g) => ({ groups: g.name }));
  }

  let names = [];
  if (selectedGroups) {
    names = selectedGroups.map((groupName) => ({ groups: groupName }));
  }

  return (
    <MultiAutocomplete
      formatter={RemovableTextFormatter}
      remove={(p) => {
        saveValue(selectedGroups.filter((g) => g !== p.groups));
      }}
      saveValue={(value) => {
        saveValue(value.groups.map((g) => g.groups));
      }}
      patchField="groups"
      suggestions={groupSelection}
      value={names} // array of objects
      placeholder="Gruppen"
    />
  );
}
