// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Text } from '@mantine/core';
import { FC } from 'react';
import SemanticForm from '../../../Types/SemanticForm';
import { SemanticFormS } from '../../../Service/restapi/semanticFormService';

const RequiredFieldsAlert: FC<{ form: SemanticForm }> = ({ form }) => {
  const { task_data } = form;
  const emailPresent = SemanticFormS.emailPresent(task_data);
  const datePresent = SemanticFormS.datePresent(task_data);

  console.log(emailPresent, datePresent);

  return (
    <>
      <Alert hidden={emailPresent} sx={(theme) => ({ backgroundColor: theme.colors.red[4] })}>
        <Text color="white">
          Im Kontaktformular fehlt ein Feld für die Kunden E-Mail abzufragen. Dieses ist jedoch unbedingt notwendig.
          Füge es über die Seitenleiste hinzu.
        </Text>
      </Alert>
      <Alert hidden={datePresent} sx={(theme) => ({ backgroundColor: theme.colors.red[4] })} mt={!emailPresent ? 'sm' : 0}>
        <Text color="white">
          Im Kontaktformular wird das Veranstaltungsdatum nicht abgefragt. Dieses ist jedoch unbedingt notwendig. Füge
          es über die Seitenleiste hinzu.
        </Text>
      </Alert>
    </>
  );
};

export default RequiredFieldsAlert;
