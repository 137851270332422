import React from 'react';
import { withI18n } from '@lingui/react';
import { EditableQuestionBundleProperty } from '../../Atoms/EditableProperties/EditableQuestionBundleProperty';
import { preChange } from '../../Utils/utils';
import { EditableDivInput } from '../../Atoms/inputs/EditableDivInput';
import { HtmlPlaceholderFormatter } from '../../Atoms/Formatter/HtmlPlaceholderFormatter';

export default withI18n()(
  ({ i18n, index, bundle, handleChange, event, eventLocation, settings, customer, customAttributeValues }) => {
    const styles = {
      questionContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 5,
        padding: '0px 5px',
        backgroundColor: 'rgb(251, 251, 251)',
        border: '1px solid rgba(0, 0, 0, .125)',
        borderRadius: '0px 0px 0px 4px',
      },
      detailComponent: {
        flex: 1,
        padding: 5,
      },
      firstLineContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      subtextComponent: {
        flex: 1,
        minWidth: 400,
      },
      secondLineComponent: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 5,
      },
      questionComponent: {
        flex: 1,
        marginRight: 15,
        minWidth: 400,
      },
    };

    return (
      <div style={styles.questionContainer}>
        <div style={styles.detailComponent}>
          <div style={styles.firstLineContainer}>
            <div style={styles.questionComponent}>
              <EditableQuestionBundleProperty
                index={index}
                style={{ minHeight: 40, fontSize: 24 }}
                name="title"
                patchField="title"
                inlinePlaceholder="Überschrift"
                preChange={(p) => preChange(p, i18n)}
                changeHandler={handleChange}
                entity={bundle}
                editFormatter={EditableDivInput}
                labelFormatter={HtmlPlaceholderFormatter}
                settings={settings}
                event={event}
                eventLocation={eventLocation}
                customer={customer}
                customAttributeValues={customAttributeValues}
              />
            </div>
          </div>
          <div style={styles.secondLineComponent}>
            <div style={styles.subtextComponent}>
              <EditableQuestionBundleProperty
                index={index}
                inlinePlaceholder="Beschreibung (optional)"
                name="description"
                patchField="description"
                preChange={(p) => preChange(p, i18n)}
                changeHandler={handleChange}
                entity={bundle}
                editFormatter={EditableDivInput}
                labelFormatter={HtmlPlaceholderFormatter}
                settings={settings}
                event={event}
                eventLocation={eventLocation}
                customer={customer}
                customAttributeValues={customAttributeValues}
              />
            </div>
          </div>
        </div>
      </div>
    );
  },
);
