/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Text } from '@mantine/core';
import { useContext } from 'react';
import BookingEvent from '../../Types/Event';
import { EventContext } from '../../Service/Context/EventContext';
import { EventS } from '../../Service/EventS';
import BookitupDateInput from '../../Atoms/inputs/BookitupDateInput';
import BookitupTimeInput from '../../Atoms/inputs/BookitupTimeInput';

const EventDates = () => {
  const { event, handleChange } = useContext<any>(EventContext);
  const { id, dates } = event as BookingEvent;
  const eventDate = dates[0];
  const { date, timeFrom, timeTo, hours } = eventDate;

  const patchProp = async (prop: string, val: unknown) => {
    const patch = await EventS.editEventDate(id, { [prop]: val });
    if (patch) {
      handleChange({ dates: [{ ...event.dates[0], ...patch }] });
      return true;
    }
    return false;
  };

  return (
    <Group spacing={5} mb="xs">
      <BookitupDateInput patchCallback={(val) => patchProp('date', val)} label="Datum" value={new Date(date)} />
      <Group spacing={5}>
        <BookitupTimeInput label="von" patchCallback={(val) => patchProp('timeFrom', val)} initialValue={timeFrom} />
        <BookitupTimeInput label="bis" patchCallback={(val) => patchProp('timeTo', val)} initialValue={timeTo} />
        {hours && <Text ml="xs" mt="lg" weight="bolder" size="xs">{`${hours} h`}</Text>}
      </Group>
    </Group>
  );
};

export default EventDates;
