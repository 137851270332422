import { MantineThemeOverride } from '@mantine/core';
import 'dayjs/locale/de';

const CustomMantineTheme: MantineThemeOverride = {
  // Defines color scheme for all components, defaults to "light"
  colorScheme: 'light',

  // Controls focus ring styles:
  // auto – display focus ring only when user navigates with keyboard (default)
  // always – display focus ring when user navigates with keyboard and mouse
  // never – focus ring is always hidden (not recommended)
  focusRing: 'auto',

  // Determines whether motion based animations should be disabled for
  // users who prefer to reduce motion in their OS settings
  respectReducedMotion: true,

  // Determines whether elements that do not have pointer cursor by default
  // (checkboxes, radio, native select) should have it
  cursorType: 'pointer',

  // Default border-radius used for most elements
  defaultRadius: 'sm',

  // White and black colors, defaults to '#fff' and '#000'
  white: '#FFFFFF',
  black: '#282828',

  // Object of arrays with 10 colors
  colors: {
    orange: [
      '#FEC170',
      '#FDB85B',
      '#FDAF47',
      '#FDA632',
      '#E4952D',
      '#CA8528',
      '#B17423',
      '#98641E',
      '#7F5319',
      '#654214',
    ],
    // eslint-disable-next-line prettier/prettier
    red: [
      '#FF8D8C', 
      '#FF7D7B', 
      '#FF6C6B', 
      '#FF5C5A', 
      '#E65351', 
      '#CC4A48', 
      '#B3403F', 
      '#993736', 
      '#802E2D', 
      '#662524'
    ],

    lightBlue: [
      '#E8EEF4',
      '#E8EEF4',
      '#E8EEF4',
      '#E8EEF4',
      '#E8EEF4',
      '#E8EEF4',
      '#E8EEF4',
      '#E8EEF4',
      '#E8EEF4',
      '#E8EEF4',
    ],

    secondaryBlue: [
      '#DFEBFB',
      '#DFEBFB',
      '#DFEBFB',
      '#DFEBFB',
      '#DFEBFB',
      '#DFEBFB',
      '#DFEBFB',
      '#DFEBFB',
      '#DFEBFB',
      '#DFEBFB',
    ],

    blue: [
      '#6185B2',
      '#4A74A7',
      '#34629C',
      '#1D5191',
      '#1A4983',
      '#174174',
      '#143966',
      '#113157',
      '#0F2949',
      '#0C203A',
    ],
    green: [
      '#a0eca5',
      '#8ce893',
      '#79e481',
      '#66e06f',
      '#53dc5d',
      '#40d84b',
      '#3ac244',
      '#33ad3c',
      '#2d9735',
      '#26822d',
    ],
    silver: [
      '#c1c1c1',
      '#b5b5b5',
      '#a8a8a8',
      '#9c9c9c',
      '#8f8f8f',
      '#838383',
      '#767676',
      '#696969',
      '#5c5c5c',
      '#4f4f4f',
    ],
    gray: [
      '#F1F1F1',
      '#E2E2E2',
      '#D4D4D4',
      '#C5C5C5',
      '#B7B7B7',
      '#A8A8A8',
      '#9A9A9A',
      '#8B8B8B',
      '#7D7D7D',
      '#6E6E6E',
    ],
  },

  // Key of theme.colors
  primaryColor: 'blue',

  // Index of color from theme.colors that is considered primary, Shade type is 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  primaryShade: { light: 3, dark: 8 },

  // Default gradient used in components that support `variant="gradient"` (Button, ThemeIcon, etc.)
  defaultGradient: { deg: 90, from: 'orange', to: 'red' },

  // font-family and line-height used in most components
  fontFamily: 'Nunito Sans',
  lineHeight: '18px',

  // Timing function used for animations, defaults to 'ease'
  transitionTimingFunction: 'ease',

  // Monospace font-family, used in Code, Kbd and Prism components
  // fontFamilyMonospace: "Nunito Sans",

  // Sizes for corresponding properties
  // fontSizes: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>;

  // radius: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>;

  // spacing: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>;

  // Values used for box-shadow
  // shadows: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', string>;

  // Breakpoints used in some components to add responsive styles
  // breakpoints: Record<'xs' | 'sm' | 'md' | 'lg' | 'xl', number>;

  // Styles added to buttons with `:active` pseudo-class
  // activeStyles: CSSObject;

  // h1-h6 styles, used in Title and TypographyStylesProvider components
  // headings: {
  //   fontFamily: CSSProperties['fontFamily'];
  //   fontWeight: CSSProperties['fontWeight'];
  //   sizes: {
  //     // See heading options below
  //     h1: Heading;
  //     h2: Heading;
  //     h3: Heading;
  //     h4: Heading;
  //     h5: Heading;
  //     h6: Heading;
  //   };
  // };

  // theme functions, see in theme functions guide
  // fn: MantineThemeFunctions;

  // Left to right or right to left direction, see RTL Support guide to learn more
  dir: 'ltr',

  // Default loader used in Loader and LoadingOverlay components
  loader: 'oval',

  // Default date format used in DatePicker and DateRangePicker components
  dateFormat: 'DD.MM.YYYY',

  // Default dates formatting locale used in every @mantine/dates component
  datesLocale: 'de',

  // defaultProps, styles and classNames for components
  // components: ComponentsOverride;

  // Add your own custom properties on Mantine theme
  // other: Record<string, any>;
};

export default CustomMantineTheme;
