// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { AppShell, Navbar, ScrollArea } from '@mantine/core';
import { Outlet } from 'react-router-dom';
import { FC, useContext, useEffect, useState } from 'react';
import { WindowContext } from '../../Service/Context/WindowContext';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import PageHeader from '../Navigation/PageHeader';
import { RoutingS } from '../../Service/RoutingS';
import SettingsNavBar from './SettingsNavBar';

const Settings: FC = () => {
  const { sm } = useContext(WindowContext);
  const [navBarOpen, setNavBarOpen] = useState<boolean>(false);

  useEffect(() => {
    RoutingS.changeTitle('Einstellungen');
  }, []);

  return (
    <AppShell
      header={<PageHeader title="Einstellungen" navBarOpen={navBarOpen} setNavBarOpen={setNavBarOpen} />}
      navbar={
        <div>
          {sm ? (
            <Navbar hiddenBreakpoint="sm" hidden={!navBarOpen} width={{ sm: 220, md: 250 }}>
              <ScrollArea>
                <SettingsNavBar closeNavBar={() => setNavBarOpen(false)} />
              </ScrollArea>
            </Navbar>
          ) : (
            <DoubleNavbar>
              <SettingsNavBar closeNavBar={() => setNavBarOpen(false)} />
            </DoubleNavbar>
          )}
        </div>
      }
    >
      <Outlet />
    </AppShell>
  );
};

export default Settings;
