import React, { useContext, useState } from 'react';
import { TransButtonLink } from '../../Atoms/Buttons';
import { QuestionnaireContext } from '../../Service/Context/QuestionnaireContext';
import { ConfirmDialog } from '../../Molecules/Dialogs';
import { QuestionBundleItem } from './QuestionBundleItem';

export default function ({ event, eventLocation, customer, customAttributeValues, settings }) {
  const { questionnaire, setQuestionnaire, addQuestionBundle, delQuestion, moveQuestionBundle, delQuestionBundle } =
    useContext(QuestionnaireContext);
  const [bundleInDelete, setBundleInDelete] = useState(undefined);

  function handleChange(patch) {
    const oldVal = questionnaire.questionBundles.find((q) => q.id === patch.id);
    const indexOfVal = questionnaire.questionBundles.indexOf(oldVal);
    const newQuestionBundles = Object.assign([], questionnaire.questionBundles);
    newQuestionBundles.splice(indexOfVal, 1, patch);
    questionnaire.questionBundles = newQuestionBundles;
    setQuestionnaire(questionnaire);
  }

  function setQuestions(bundleId, questions) {
    const oldVal = questionnaire.questionBundles.find((q) => q.id === bundleId);
    oldVal.questions = Object.assign([], questions);
    setQuestionnaire(questionnaire);
  }

  const styles = {
    addQuestion: {
      marginTop: 10,
      marginLeft: 15,
    },
  };

  return (
    <div>
      {questionnaire.questionBundles.map((val, index) => (
        <QuestionBundleItem
          key={`question-bundle-${val.id}`}
          bundle={val}
          index={index}
          moveQuestionBundle={moveQuestionBundle}
          event={event}
          eventLocation={eventLocation}
          setQuestions={setQuestions}
          settings={settings}
          customer={customer}
          customAttributeValues={customAttributeValues}
          setBundleInDelete={setBundleInDelete}
          addQuestionBundle={addQuestionBundle}
          handleChange={handleChange}
          bundlesLength={questionnaire.questionBundles.length}
        />
      ))}
      <ConfirmDialog
        text="questionnaire.questionBundle.delete.confirm"
        subject="questionnaire.questionBundle.delete.confirm.head"
        open={bundleInDelete}
        toggle={() => {
          setBundleInDelete(undefined);
        }}
        handler={() => delQuestionBundle(bundleInDelete)}
      />
      <div style={styles.addQuestion}>
        <TransButtonLink onClick={() => addQuestionBundle()} text="common.add.questionBundle" />
      </div>
    </div>
  );
}
