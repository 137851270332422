// eslint-disable-next-line no-redeclare
import { Center, Text } from '@mantine/core';
import { useContext } from 'react';
import PanePaper from '../PanePaper';
import { SimpleEditableText } from '../../Atoms/inputs/SimpleEditableText';
import { StringFormatter } from '../../Atoms/Formatter/StringFormatter';
import { EditableEventLocationProperty } from '../../Atoms/EditableProperties/EditableEventLocationProperty';
import { LocationSearchInput } from '../../Atoms/PlacesInputField';
import { EventLocationContext } from '../../Service/Context/EventLocationContext';
import { OwnDistance } from './LocationDistance';

const LocationPane = () => {
  const { location } = useContext(EventLocationContext);

  return (
    <PanePaper withShadow withoutBorder title="common.location" tooltip="help.event.location.pane">
      <EditableEventLocationProperty
        patchField="name"
        placeholder="location.name"
        editFormatter={LocationSearchInput}
        labelFormatter={StringFormatter}
      />
      <EditableEventLocationProperty
        patchField="streetAndNo"
        placeholder="location.streetAndNo"
        editFormatter={SimpleEditableText}
        labelFormatter={StringFormatter}
      />
      <div style={{ display: 'flex' }}>
        <EditableEventLocationProperty
          style={{ maxWidth: 80, height: 'unset' }}
          patchField="zipCode"
          placeholder="location.zipCode"
          editFormatter={SimpleEditableText}
          labelFormatter={StringFormatter}
        />
        <div style={{ flex: 1, marginLeft: 5 }}>
          <EditableEventLocationProperty
            patchField="city"
            placeholder="location.city"
            editFormatter={SimpleEditableText}
            labelFormatter={StringFormatter}
          />
        </div>
      </div>
      <EditableEventLocationProperty
        patchField="phoneNumber"
        placeholder="location.phoneNumber"
        editFormatter={SimpleEditableText}
        labelFormatter={StringFormatter}
      />
      <Center mt="sm">
        <OwnDistance />
      </Center>
      {location && location.website && (
        <Center>
          <Text component="a" href={location.website} target="_blank" color="blue" className="overflow-ellipsis">
            {location.website}
          </Text>
        </Center>
      )}
    </PanePaper>
  );
};

export default LocationPane;
