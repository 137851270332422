import { useContext, useEffect } from 'react';
import { getHubSpotIdTokenAPI } from '../restapi/subscriptionService';
import { HubSpotS } from '../HubSpotS';
import { AuthContext } from '../Context/AuthContext';

declare global {
  interface Window {
    hsConversationsSettings: any;
  }
}

const useHubspot = () => {
  const { jwtClaims } = useContext(AuthContext);
  const { supportLogin } = jwtClaims;

  useEffect(() => {
    if (!supportLogin) {
      const script = addHubSpotScript();
      if (jwtClaims) {
        const { email } = jwtClaims;
        console.log('get hubspot token for application user', { email });
        if (email) {
          getHubSpotIdTokenAPI()
            .then((resp: any) => {
              if (resp.ok) {
                resp.json().then((data: any) => {
                  const { token } = data;
                  console.log('response from BE: ', { data });
                  HubSpotS.isConversationApiReady(email, token);
                });
              } else {
                console.error('could not load hubspot visitor token');
                HubSpotS.isConversationApiReady('', '');
              }
            })
            .catch((error) => {
              console.error('could not load hubspot visitor token', error);
              HubSpotS.isConversationApiReady('', '');
            });
        } else {
          console.error('hubSpot could not be loaded with user, because contact has no email assigned', { email });
          HubSpotS.isConversationApiReady('', '');
        }
      } else {
        console.log('initialize hubspot widget anonymously');
        HubSpotS.isConversationApiReady('', '');
      }

      return () => {
        document.body.removeChild(script);
      };
    }
    console.log('not init hubSpot, because supportLogin is true');
  }, [jwtClaims]);

  const addHubSpotScript = () => {
    window.hsConversationsSettings = {
      loadImmediately: false,
    };
    const script = document.createElement('script');

    script.id = "hs-script-loader"
    script.src = 'https://js-eu1.hs-scripts.com/25589471.js';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
    return script;
  };
};

export default useHubspot;
