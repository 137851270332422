// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Alert, Text } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { FC } from 'react';
import { IconAlertCircle, IconCircleCheck } from '@tabler/icons';
import { AlertS } from '../../Service/AlertS';

const AlertBox: FC<{ alert: string }> = ({ alert }) => {
  const { i18n } = useLingui();
  const { title, color } = AlertS.Alerts[alert];

  return (
    <Alert
      mb={20}
      icon={color === 'green' ? <IconCircleCheck /> : <IconAlertCircle />}
      color={color}
      title={title}
      variant="filled"
    >
      <Text color="white"> {i18n._(`alert.${alert}`)}</Text>
    </Alert>
  );
};

export default AlertBox;
