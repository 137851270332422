// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconExternalLink } from '@tabler/icons';
import { BookitupNotification } from '../../../../Types/LogT';

const RequestReceived: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { formDomain, formName, value } = notification;
  const navigate = useNavigate();

  if (!formName || !formDomain) {
    return <Text>Veranstaltung über Kontaktformular erstellt.</Text>;
  }

  return (
    <>
      <Text>
        Ereignis über Kontaktformular erstellt{'  '}
        <Text
          span
          weight="bolder"
          variant="link"
          color="blue"
          onClick={() => navigate(`/settings/contact-form/${value}`)}
          sx={{ cursor: 'pointer'}}
        >
          {formName}
        </Text>
        .{'  '}
      </Text>
      <Text>
        {formDomain} <IconExternalLink size={16} style={{ marginBottom: 3 }} />
      </Text>
    </>
  );
};

export default RequestReceived;
