import React, { useContext } from 'react';
import { useDrag, useDrop } from 'react-dnd-latest';
import { withI18n } from '@lingui/react';
import DragButton from '../../Atoms/DragDrop/DragButton';
import QuestionList from './QuestionList';
import QuestionBundleMenu from './QuestionBundleMenu';
import QuestionBundleDetail from './QuestionBundleDetail';
import { QuestionnaireContext } from '../../Service/Context/QuestionnaireContext';

export const QuestionBundleItem = withI18n()(
  ({
    bundle,
    index,
    moveQuestionBundle,
    event,
    eventLocation,
    setQuestions,
    settings,
    customer,
    customAttributeValues,
    setBundleInDelete,
    addQuestionBundle,
    handleChange,
    bundlesLength,
  }) => {
    const { moveQuestion } = useContext(QuestionnaireContext);
    const [collected, drag] = useDrag(
      () => ({
        type: 'questionBundleItem',
        item: { bundle, index },
      }),
      [bundle.id, index],
    );

    const [, drop] = useDrop(
      () => ({
        accept: ['questionBundleItem', 'questionItem'],
        collect(monitor) {
          return { handlerId: monitor.getHandlerId() };
        },
        drop(item, monitor) {
          // Question type
          console.log(item);
          if (item.bundleId) {
            if (bundle.id !== item.bundleId) {
              moveQuestion(bundle.id, item.question.id, 0, item.bundleId);
            }
          } else {
            moveQuestionBundle(item.bundle.id, index);
          }
        },
      }),
      [bundle.id, index],
    );

    const opacity = collected.isDragging ? 0.6 : 1;

    return (
      <div ref={drop}>
        <div key={index} style={{ marginTop: 30, opacity }}>
          <div className="section-header" style={{ display: 'flex', flexDirection: 'row' }}>
            <DragButton dragRef={drag} entity="question-bundle" />
            <div style={{ width: '100%', alignSelf: 'center' }}>
              Abschnitt {index + 1} von {bundlesLength}
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <QuestionBundleDetail
              index={index}
              bundle={bundle}
              handleChange={handleChange}
              settings={settings}
              event={event}
              eventLocation={eventLocation}
              customer={customer}
              customAttributeValues={customAttributeValues}
            />
            <QuestionBundleMenu
              bundleId={bundle.id}
              index={index}
              addQuestionBundle={addQuestionBundle}
              bundlesLength={bundlesLength}
              moveQuestionBundle={moveQuestionBundle}
              setBundleInDelete={setBundleInDelete}
            />
          </div>
          <QuestionList
            questions={bundle.questions}
            bundleId={bundle.id}
            setQuestions={(questions) => setQuestions(bundle.id, questions)}
            settings={settings}
            event={event}
            eventLocation={eventLocation}
            customer={customer}
            customAttributeValues={customAttributeValues}
          />
        </div>
      </div>
    );
  },
);
