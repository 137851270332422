/* eslint-disable react/jsx-no-useless-fragment */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Skeleton, Stack, Text } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { FC, useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { EventPortalContext } from '../../Service/Context/EventPortalContext';
import { fetchResourceByEntityTypeAndEntityId, newShare, share } from '../../Service/restapi/shareService';
import { ModalDialog, useConfirmModalState } from '../Dialogs';
import { TransButtonDefault, TransButtonPrimary } from '../../Atoms/Buttons';
import { ShareFlow1 } from '../../Organismns/Share/ShareFlow/ShareFlow-1';
import { getUnresolvablePlaceholders } from '../../Service/restapi/documentService';
import { ToastS } from '../../Service/ToastS';
import { ViolationContext } from '../../Service/Context/ViolationContext';
import { LicenceS } from '../../Service/LicenceS';
import { PortalResouce } from '../../Types/ShareT';
import DocumentResourceBadge from './Documents/DocumentResourceBadge';
import { ValidationS } from '../../Service/ValidationS';

const DocumentPortalStatus: FC<{
  entityType: string;
  entityId: string;
  shortVersion?: boolean;
  setDraft: (val: boolean) => void;
  label?: string;
}> = ({ entityType, entityId, shortVersion, setDraft, label }) => {
  const { pathname } = useLocation();
  const { i18n } = useLingui();
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const { shares, reload } = useContext(EventPortalContext);
  const [name, setName] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [sharedUntil, setSharedUntil] = useState<Date>();
  const [loading, setLoading] = useState<boolean>(true);
  const [portalResources, setPortalResources] = useState<PortalResouce[]>([]);
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);
  const { length } = portalResources;
  const [unresolvablePlaceholders, setUnresolvablePlaceholders] = useState<[]>();
  const { id: eventId } = useParams();

  useEffect(() => {
    if (entityType && entityId) {
      fetchUnresolvablePlaceholders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityType, entityId]);

  const publish = () => {
    executeWithLicenceCheck(LicenceS.Restrictions.PORTAL, () => {
      if (!unresolvablePlaceholders || unresolvablePlaceholders.length > 0) {
        fetchUnresolvablePlaceholders(true);
      } else if (shares.length > 0) {
        createShare();
      } else {
        toggleConfirm();
      }
    });
  };

  const fetchUnresolvablePlaceholders = (notification?: boolean) => {
    setLoading(true);
    getUnresolvablePlaceholders(entityType, entityId)
      .then((unresolvablePlaceholders) => {
        setUnresolvablePlaceholders(unresolvablePlaceholders);
        if (notification && unresolvablePlaceholders.length > 0) {
          ToastS.warn('placeholder.unsatisfied', i18n._('placeholder.unsatisfied'));
          unresolvablePlaceholders.forEach((placeholder: any) => {
            const translation = i18n._(placeholder, { 0: 1 });
            ToastS.warn(`unresolvable.placeholder-${translation}`, i18n._(`${translation} nicht gesetzt`));
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const createShare = () => {
    setLoading(true);
    const requestBody = [
      { entityType, entityId, label: label ?? i18n._(`resource.entityType.${entityType.toLowerCase()}`) },
    ];

    if (shares.length > 0) {
      share(shares[0].id, { entityTypeAndId: requestBody })
        .then((resp) => {
          if (resp.ok) {
            fetchPortalResource();
            setDraft(false);
          }
        })
        .finally(() => setLoading(false));
    } else {
      newShare(name, password, sharedUntil, eventId).then((resp) => {
        if (resp.ok) {
          resp.json().then((s) => {
            share(s.id, { entityTypeAndId: requestBody })
              .then((resp) => {
                if (resp.ok) {
                  fetchPortalResource();
                  setDraft(false);
                }
              })
              .finally(() => {
                reload();
                toggleConfirm();
                setLoading(false);
              });
          });
        }
      });
    }
  };

  const fetchPortalResource = () => {
    fetchResourceByEntityTypeAndEntityId(entityType, entityId)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then(setPortalResources);
        }
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPortalResource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading ? (
        <Skeleton width={100} height={20} radius="md" />
      ) : (
        <>
          {length === 0 ? (
            <>
              {pathname.includes('events') && (
                <Text
                  align="center"
                  size="xs"
                  weight="lighter"
                  color="blue"
                  sx={{ cursor: 'pointer' }}
                  onClick={publish}
                >
                  <u>Jetzt freigeben</u>
                </Text>
              )}
            </>
          ) : (
            <Stack spacing={5}>
              {portalResources.map((_resource) => {
                const { id } = _resource;
                return <DocumentResourceBadge key={id} id={id} shortVersion={shortVersion} entityType={entityType} />;
              })}
            </Stack>
          )}
        </>
      )}
      <ModalDialog
        header="Portal-Zugang erstellen"
        body={
          <>
            <ShareFlow1
              name={name}
              setName={setName}
              password={password}
              setPassword={setPassword}
              sharedUntil={sharedUntil}
              setSharedUntil={setSharedUntil}
            />
          </>
        }
        footer={
          <>
            <TransButtonDefault onClickHandler={toggleConfirm} text="actions.cancel" />
            <TransButtonPrimary
              disabled={!ValidationS.validatePortalPassword(password ?? '')}
              onClickHandler={createShare}
              text="actions.save"
            />
          </>
        }
        show={showConfirm}
        toggle={toggleConfirm}
      />
    </>
  );
};

export default DocumentPortalStatus;
