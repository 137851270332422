import { ThemeIcon } from '@mantine/core';
import { FC, ReactNode } from 'react';

interface GradientIconProps {
  color: string;
  children: ReactNode;
}

export const GradientIcon: FC<GradientIconProps> = ({ color, children }) => {
  if (color === 'green') {
    return <GreenGradientIcon>{children} </GreenGradientIcon>;
  }
  if (color === 'red') {
    return <RedGradientIcon>{children} </RedGradientIcon>;
  }
  if (color === 'orange') {
    return <OrangeGradientIcon>{children} </OrangeGradientIcon>;
  }
  if (color === 'gray') {
    return <GrayGradientIcon>{children} </GrayGradientIcon>;
  }
  return <BlueGradientIcon>{children} </BlueGradientIcon>;
};

export const GrayGradientIcon: FC<{ children: ReactNode }> = ({ children }) => (
  <ThemeIcon size="xl" radius="xl" variant="gradient" gradient={{ from: '#C5C5C5', to: '#E2E2E2', deg: 135 }}>
    {children}
  </ThemeIcon>
);

export const OrangeGradientIcon: FC<{ children: ReactNode }> = ({ children }) => (
  <ThemeIcon size="xl" radius="xl" variant="gradient" gradient={{ from: '#FDA632', to: '#FEC170', deg: 135 }}>
    {children}
  </ThemeIcon>
);

export const BlueGradientIcon: FC<{ children: ReactNode }> = ({ children }) => (
  <ThemeIcon size="xl" radius="xl" variant="gradient" gradient={{ from: '#1D5191', to: '#6185B2', deg: 135 }}>
    {children}
  </ThemeIcon>
);

export const RedGradientIcon: FC<{ children: ReactNode }> = ({ children }) => (
  <ThemeIcon size="xl" radius="xl" variant="gradient" gradient={{ from: '#FF5C5A', to: '#FF8D8C', deg: 135 }}>
    {children}
  </ThemeIcon>
);

export const GreenGradientIcon: FC<{ children: ReactNode }> = ({ children }) => (
  <ThemeIcon size="xl" radius="xl" variant="gradient" gradient={{ from: '#4EBF46', to: '#83D27E', deg: 135 }}>
    {children}
  </ThemeIcon>
);
