/* eslint-disable consistent-return */
/* eslint-disable default-case */
export type ResourceStatus = 'PUBLISHED' | 'SEEN' | 'CONFIRMED';

const getResouceStatusColor = (status: ResourceStatus) => {
  switch (status) {
    case 'PUBLISHED':
      return '#686868';
    case 'SEEN':
      return '#FDA632';
    case 'CONFIRMED':
      return '#4EBF46';
  }
};

const getResourceTooltipMessage = (entityType: string, status: ResourceStatus) => {
  if (entityType === 'documents') {
    switch (status) {
      case 'PUBLISHED':
        return 'Dokument wurde noch nicht angesehen';
      case 'SEEN':
        return 'Dokument wurde wahrscheinlich angesehen';
      case 'CONFIRMED':
        return 'Dokument wurde bestätigt';
    }
  } else if (entityType === 'contracts') {
    switch (status) {
      case 'PUBLISHED':
        return 'Vertrag wurde noch nicht angesehen';
      case 'SEEN':
        return 'Vertrag wurde wahrscheinlich angesehen';
      case 'CONFIRMED':
        return 'Vertrag wurde bestätigt';
    }
  } else {
    switch (status) {
      case 'PUBLISHED':
        return 'Fragebogen wurde noch nicht angesehen';
      case 'SEEN':
        return 'Fragebogen wurde wahrscheinlich angesehen';
      case 'CONFIRMED':
        return 'Fragebogen wurde ausgefüllt';
    }
  }
};

export const PortalS = {
  getResouceStatusColor,
  getResourceTooltipMessage,
};
