import { ActionIcon, Box, Flex, NumberInput } from '@mantine/core';
import { useCounter } from '@mantine/hooks';
import { IconMinus, IconPlus, IconTrash } from '@tabler/icons';
import { FC, useEffect } from 'react';

type Props = {
  initialCount?: number;
  min?: number;
  onChanged?: (count: number) => void;
  deleteDisabled?: boolean;
  onLastRemoved?: () => void;
};

const IncDecCounter: FC<Props> = ({ initialCount = 0, min = 0, deleteDisabled, onChanged, onLastRemoved }) => {
  const [count, { increment, decrement, set }] = useCounter(initialCount ?? 0, { min });

  useEffect(() => {
    if (count !== initialCount) {
      onChanged?.(count);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count]);

  const showingTrashIcon = count <= min + 1;

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
        textAlign: 'center',
        width: 100,
        maxWidth: 150,
        padding: 0,
        borderRadius: theme.radius.md,
        cursor: 'pointer',
      })}
    >
      <Flex align="center" justify="center">
        <ActionIcon disabled={showingTrashIcon && deleteDisabled} variant="transparent" color="dark">
          {!showingTrashIcon && <IconMinus size={16} onClick={decrement} />}
          {showingTrashIcon && <IconTrash size={16} onClick={onLastRemoved} />}
        </ActionIcon>
        <NumberInput
          size="xs"
          hideControls
          value={count}
          onChange={set}
          sx={{ minWidth: 40, input: { textAlign: 'center', borderRadius: 0 }, justifyContent: 'center' }}
        />
        <ActionIcon
          variant="filled"
          color="blue"
          onClick={increment}
          sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
        >
          <IconPlus size={16} />
        </ActionIcon>
      </Flex>
    </Box>
  );
};

export default IncDecCounter;
