import React, { useContext, useState } from 'react';
import { TransModalDialogWithTextField } from '../Dialogs';
import ResourceAdapter from '../../Service/ResourceAdapter';
import { createContractTemplateFromContract } from '../../Service/restapi/contractService';
import { ToastS } from '../../Service/ToastS';

const TemplateNameTextField = ({ templates, contractId, close, open }) => {
  const [template, setTemplate] = useState(undefined);

  function save() {
    createContractTemplateFromContract(contractId, template)
      .then((resp) => {
        if (resp.ok) {
          ToastS.success('create-contract-result', 'Erfolgreich gespeichert');
        } else {
          ToastS.error('create-contract-result', 'Vorlage konnte nicht gespeichert werden');
        }
        close();
      })
      .catch(() => {
        ToastS.error('create-contract-result', 'Vorlage konnte nicht gespeichert werden');
      });
  }

  function changeTemplateInput(e) {
    setTemplate(e.target.value);
  }

  return (
    <TransModalDialogWithTextField
      subject="document.template.save"
      value={template}
      name="template"
      onChange={changeTemplateInput}
      placeholder="document.template"
      takenIdentifier={templates}
      show={open}
      confirm={save}
      toggle={close}
    />
  );
};

export default function (outerProps) {
  return (
    <ResourceAdapter
      serviceUrl={`${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/contracts/templates/names`}
      component={({ data }) => <TemplateNameTextField templates={data} {...outerProps} />}
    />
  );
}
