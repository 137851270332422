import React, { useContext, useState } from 'react';
import { TransButtonLink } from '../../../Atoms/Buttons';
import { createRecurringEventTasks } from '../../../Service/restapi/taskService';
import { ConfirmDialog, useConfirmModalState } from '../../../Molecules/Dialogs';

export default function ({ task }) {
  const { showConfirm, toggleConfirm } = useConfirmModalState(false);
  const [loading, setLoading] = useState(false);

  function createEventTasks() {
    setLoading(true);
    createRecurringEventTasks(task.id)
      .then((resp) => {})
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
  }

  if (task.timeState === 'BOOKED_PAST_EVENT' || task.timeState === 'BOOKED_PRE_EVENT') {
    return (
      <>
        <TransButtonLink
          isLoading={loading}
          className="link-style-blue"
          onClick={toggleConfirm}
          text="recurringTasks.apply.eventTasks"
        />
        <ConfirmDialog
          text="recurringTasks.apply.eventTasks.confirm"
          subject="recurringTasks.apply.eventTasks.confirm.head"
          open={showConfirm}
          toggle={toggleConfirm}
          handler={createEventTasks}
        />
      </>
    );
  }

  return null;
}
