/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { useElementSize } from '@mantine/hooks';
import BookitupTable from '../../../../Atoms/BookitupTable';
import { Resource } from '../../../../Types/ResourceT';
import PanePaper from '../../../../Molecules/PanePaper';
import ResourceTableRow from './ResourceTableRow';
import GeneralResourceActions from './GeneralResourceActions';

const ResourcesTable: FC<{ resources: Resource[] }> = ({ resources }) => {
  const { ref, width } = useElementSize();

  const showColumns = {
    showDescription: width > 550,
    showCategory: width > 450,
  };

  return (
    <PanePaper>
      <BookitupTable>
        <thead>
          <tr ref={ref}>
            <th style={{ width: 30 }}> </th>
            <th style={{ width: 70 }}> Typ </th>
            <th style={{ width: 250 }}>Name</th>
            {showColumns.showCategory && <th>Kategorie</th>}
            {showColumns.showDescription && <th>Bezeichnung</th>}
            <th> </th>
            <th> </th>
          </tr>
        </thead>
        <tbody>
          {resources.map((resource) => (
            <ResourceTableRow
              key={resource.id}
              resource={resource}
              actions={<GeneralResourceActions resource={resource} />}
              {...showColumns}
            />
          ))}
        </tbody>
      </BookitupTable>
    </PanePaper>
  );
};

export default ResourcesTable;
