import React, { useContext, useState } from 'react';
import { Dropdown } from '../../../Atoms/Dropdowns';
import { deleteUserOfProfile } from '../../../Service/restapi/authService';
import { ConfirmDialog } from '../../../Molecules/Dialogs';

export const ProfileShareActions = ({ email, reloadProfileShares }) => {
  const [modalDelete, setModalDelete] = useState(false);

  const items = [{ text: 'Freigabe entfernen', onClick: () => setModalDelete(!modalDelete) }];

  function deleteHandler() {
    deleteUserOfProfile(email)
      .then((resp) => {})
      .finally(() => {
        reloadProfileShares();
      });
  }

  const styles = {
    dropdown: {
      padding: '0px 4px',
      borderRadius: '4px',
      textAlign: 'center',
      color: '#b5b6b7',
      fontWeight: 800,
      marginLeft: 2,
      height: 21,
      alignSelf: 'center',
    },
    action: {
      paddingLeft: 3,
      paddingRight: 3,
    },
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Dropdown style={styles.dropdown} caret={false} icon="CARET_DOWN" left={false} options={{ items }} />

      <ConfirmDialog
        text="common.delete.profile.share.confirm"
        subject="common.delete.profile.share.head"
        open={modalDelete}
        toggle={() => setModalDelete(!modalDelete)}
        handler={deleteHandler}
      />
    </div>
  );
};
