// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Badge, Skeleton, Space, Stack, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { openModal } from '@mantine/modals';
import { ContactRelation, ContactS } from '../../Service/ContactS';
import SelectCreateContactModal from '../../Organismns/Contact/SelectCreateContactModal';
import { ConstantS } from '../../Service/ConstantS';
import ContactRelationNew from './ContactRelationNew';

const ContactRelations: FC<{ contactId: number }> = ({ contactId }) => {
  const [loading, setLoading] = useState(false);
  const [contactRelations, setContactRelations] = useState<ContactRelation[]>([]);

  useEffect(() => {
    setLoading(true);
    ContactS.fetchRelatedContacts(contactId)
      .then(setContactRelations)
      .finally(() => setLoading(false));
  }, [contactId]);

  const onContactSelect = (relatedContactId: number) => {
    setLoading(true);
    ContactS.addRelatedContact(contactId, relatedContactId)
      .then((newRelation) => {
        if (newRelation) {
          setContactRelations([...contactRelations, newRelation]);
        }
      })
      .finally(() => setLoading(false));
  };

  const openAddRelationModal = () =>
    openModal({
      modalId: ConstantS.Modals.SelectCreateContact,
      size: 'sm',
      title: (
        <Text mt="md" weight="bolder" size="xl" align="center" sx={{ lineHeight: 1.2 }}>
          Kontakt hinzufügen
        </Text>
      ),
      closeOnEscape: true,
      transition: 'slide-down',
      children: <SelectCreateContactModal onSelect={onContactSelect} />,
    });

  const updateRelation = (relationId: number, patch: Partial<ContactRelation>) => {
    ContactS.patchContactRelation(relationId, patch).then((patchedRelation) => {
      if (patchedRelation) {
        setContactRelations(
          contactRelations.map((_relation) => (_relation.id === relationId ? patchedRelation : _relation)),
        );
      }
    });
  };

  const removeRelation = (relationId: number) => {
    ContactS.deleteContactRelation(relationId).then((deleted) => {
      if (deleted) {
        setContactRelations(contactRelations.filter((relation) => relation.id !== relationId));
      }
    });
  };

  return (
    <>
      <Badge mt="xl" radius="sm" sx={{ backgroundColor: '#585858', color: 'white' }}>
        <Text weight="lighter">Ansprechpartner</Text>
      </Badge>
      <Space h="md" />
      {loading && <Skeleton mt="xs" mb="xs" height={120} />}
      <Stack>
        {contactRelations.map((contactRelation) => (
          <ContactRelationNew
            key={contactRelation.id}
            contactRelation={contactRelation}
            updateRelation={updateRelation}
            removeRelation={removeRelation}
          />
        ))}
      </Stack>
      <Text variant="link" mt="sm" mb="sm" sx={{ cursor: 'pointer' }} onClick={openAddRelationModal}>
        {i18n._('contact-relation.company.button.add')}
      </Text>
    </>
  );
};

export default ContactRelations;
