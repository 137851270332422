import { Stack } from '@mantine/core';
import DocumentPane from '../../Molecules/Event/Documents/DocumentPane';
import ContractPane from '../../Molecules/Event/Contracts/ContractPane';
import QuestionnairePane from '../../Molecules/Event/Questionnaires/QuestionnairePane';
import EventUploads from '../../Molecules/Event/EventUploads';
import MessageFilesPane from '../../Molecules/Event/MessageFilesPane';

const EventDocuments = () => (
  <Stack>
    <DocumentPane />
    <ContractPane />
    <QuestionnairePane />
    <EventUploads />
    <MessageFilesPane />
  </Stack>
);

export default EventDocuments;
