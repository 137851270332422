import axios from 'axios';
import { ajaxActions } from '../AjaxActions';
import { fetchContactById } from './contactService';
import { saveDocumentFromTemplate } from './documentService';

const BASE_URL = process.env.REACT_APP_MESSAGE_SERVICE_URL;

export const sendMail = (message) => ajaxActions.post(`${BASE_URL}/emails`, message).then((resp) => resp);

export const saveDraft = (message) => ajaxActions.post(`${BASE_URL}/emails/draft`, message).then((resp) => resp);

export const fetchStandardMessageTemplate = (documentType) =>
  ajaxActions
    .getResourceAsJson(`${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/templates/${documentType}/default`)
    .then((template) => template);

export const fetchMessageTemplates = () => ajaxActions.get(`${BASE_URL}/email/template`).then((resp) => resp);

export const fetchMessageTemplatesByType = (messageType) =>
  ajaxActions.get(`${BASE_URL}/email/template?emailTemplate=${messageType.toUpperCase()}`).then((resp) => resp);

export const deleteMessageTemplate = (messageType, name) =>
  ajaxActions.del(`${BASE_URL}/email/template/${messageType}?name=${name}`).then((resp) => resp);

export const createMessageTemplate = (messageType, name) =>
  ajaxActions.get(`${BASE_URL}/email/template/${messageType}?name=${name}`).then((resp) => resp);

export const fetchMessageTemplateNames = (messageType) =>
  ajaxActions.get(`${BASE_URL}/email/template/${messageType.toUpperCase()}/names`).then((resp) => resp);

export const patchEmailTemplate = (messageType, name, patch) =>
  ajaxActions
    .patch(`${BASE_URL}/email/template/${messageType}${name ? `?name=${name}` : ''}`, patch)
    .then((resp) => resp);

export const fetchEmailById = (emailId) =>
  ajaxActions.getResourceAsJson(`${BASE_URL}/emails/${emailId}`).then((email) => email);

export const fetchEmailsByEventId = (eventId) =>
  ajaxActions.getResourceAsJson(`${BASE_URL}/event/${eventId}/messages`).then((emails) => emails);

export const fetchEmails = (postbox, filter, page, contacts) =>
  ajaxActions
    .post(`${BASE_URL}/emails/postbox/${postbox}?${filter ? `filter=${filter}` : ''}&page=${page}`, contacts)
    .then((resp) => resp);

export const fetchEmailIds = (contactIds) => ajaxActions.post(`${BASE_URL}/emails/id`, contactIds).then((resp) => resp);

export const fetchEmailsHead = (postbox, filter, page, contacts) =>
  ajaxActions
    .post(
      `${BASE_URL}/emails/postbox/${postbox}/counts?${filter ? `filter=${filter}` : ''}${page ? `&page=${page}` : ''}`,
      contacts,
    )
    .then((resp) => resp);

export const patchEmail = (emailId, patch) =>
  ajaxActions
    .patch(`${BASE_URL}/emails/${emailId}`, patch)
    .then((resp) => resp)
    .catch(() => {
      throw new Error(`could not patch email ${emailId}`);
    });

export const patchDraft = (emailId, patch) =>
  ajaxActions
    .patch(`${BASE_URL}/emails/draft${emailId ? `?id=${emailId}` : ''}`, patch)
    .then((resp) => resp)
    .catch(() => {
      throw new Error(`could not patch draft email ${emailId}`);
    });

export const patchNewDraft = (eventId, patch) =>
  ajaxActions.patch(`${BASE_URL}/emails/draft${eventId ? `?eventId=${eventId}` : ''}`, patch).then((resp) => resp);

export const deleteEmail = (emailId) =>
  ajaxActions
    .del(`${BASE_URL}/emails/${emailId}`, null)
    .then((resp) => resp)
    .catch(() => {
      throw new Error(`could not delete email ${emailId}`);
    });

export const deleteEmailsOfContact = (contactId) =>
  ajaxActions
    .del(`${BASE_URL}/emails?contactId=${contactId}`)
    .then((resp) => resp)
    .catch(() => {
      throw new Error(`could not delete emails of contact ${contactId}`);
    });

export const emailByAction = (emailId, emailAction) =>
  ajaxActions.get(`${BASE_URL}/emails/${emailId}/${emailAction.toLowerCase()}`).then((resp) => resp);

export const searchEmailsCancelable = async (searchText, signal) =>
  await axios.get(`${BASE_URL}/search?query=${searchText}`, {
    cancelToken: signal.token,
    withCredentials: true
  });

export const searchEmails = (searchText) =>
  ajaxActions.get(`${BASE_URL}/search?query=${searchText}`).then((resp) => resp);

export const loadMessageTemplate = (setDocumentRequests, setDocumentBlueprints, kind, templateName, eventId) => {
  // let toastId;
  // toastManager.add('Vorlage wird geladen...', {appearance: 'info' }, (id) => {toastId = id});

  ajaxActions
    .get(`${BASE_URL}/email/template/${kind.toUpperCase()}${templateName ? `?name=${templateName}` : ''}`)
    .then((resp) => {
      if (!resp.ok) {
        // toastManager.add('Nachrichten-Vorlage konnte nicht geladen werden', { autoDismiss: true, appearance: 'error' });
      }

      if (resp.ok) {
        resp.json().then((emailTemplate) => {
          const exchangeDocumentDTO = {
            eventId,
            documentRequests: emailTemplate.documentBlueprints,
          };

          ajaxActions
            .post(
              `${process.env.REACT_APP_DOCUMENT_SERVICE_URL}/process/exchangeToExistingDocumentsIfPossible`,
              exchangeDocumentDTO,
            )
            .then((resp) => {
              if (resp.ok) {
                resp.json().then((documentRequests) => {
                  const tmpDRs = [];
                  documentRequests.forEach((dR) => {
                    if (dR.documentId) {
                      tmpDRs.push(dR);
                    } else {
                      console.log('no existing document for document request', dR);
                    }
                  });
                  setDocumentRequests(tmpDRs);
                  setDocumentBlueprints(emailTemplate.documentBlueprints);
                });
              } else {
                // toastManager.add('Dokument-Anhänge konnte nicht geladen werden.', { autoDismiss: true, appearance: 'error' });
              }
            })
            .catch(() => {
              // toastManager.add('Keine Verbindung zum Server', { autoDismiss: true, appearance: 'error' });
            });
        });
      }
    })
    .catch(() => {
      // toastManager.add('Keine Verbindung zum Server', { autoDismiss: true, appearance: 'error' });
    })
    .then(() => {
      // toastManager.remove(toastId);
    });
};

// ----------------------------------
// Load E-Mail Template

export const prepareEmailTemplate = (
  setLoading,
  selectedMessageTemplate,
  templateName,
  saveMessage,
  selectedDocuments,
  mainCustomerId,
  eventId,
  toggle,
) => {
  if (selectedMessageTemplate) {
    setLoading(true);
    // toastManager.add('Vorlage wird geladen, bitte warten', {appearance: 'info' }, (toastId) => {
    // get document requests, for creating documents from template
    const documentTemplates = [];
    for (const dR of selectedDocuments.values()) {
      if (dR.kind === 'GENERATE') {
        documentTemplates.push(dR);
      }
    }
    let documents = [];
    if (documentTemplates.length > 0) {
      console.log('Generate documents from following templates:');
      documentTemplates.forEach((dT) => {
        console.log(dT);
      });

      // generate and save documents
      const documentTemplateSetDTO = {
        customerContactId: mainCustomerId,
        eventId,
        documentTemplates,
      };

      saveDocumentFromTemplate(documentTemplateSetDTO)
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((documentRequests) => {
              documents = merge(documentRequests, selectedDocuments);
              templateLoaded(mainCustomerId, documents, saveMessage, setLoading, selectedMessageTemplate, templateName);
              toggle();
            });
          } else if (resp.status === 412) {
            // should be prevented and therefore never occur
            // toastManager.add('Vorlage enthält nicht ersetzbare Platzhalter', {autoDismiss: true, appearance: 'error'});
          }
        })
        .catch((resp) => {
          console.error('Failed to sent data');
        })
        .then(() => {
          // toastManager.remove(toastId);
        });
    } else {
      for (const dR of selectedDocuments.values()) {
        documents.push(dR);
      }
      console.log('No documents to generate, attach those ', documents);
      toggle();
      templateLoaded(mainCustomerId, documents, saveMessage, setLoading, selectedMessageTemplate, templateName);
      // toastManager.remove(toastId);
    }
  } else {
    // close dialog, if no template was selected to load
    toggle();
  }
};

function templateLoaded(mainCustomerId, documents, saveMessage, setLoading, selectedMessageTemplate, templateName) {
  const documentsAsFiles = [];
  documents.forEach((d) => {
    documentsAsFiles.push({
      fileName: d.fileName,
      entityType: d.documentType === 'CONTRACT' ? 'contracts' : 'documents',
      entityId: d.documentId,
    });
  });
  ajaxActions
    .getResourceAsJson(
      `${BASE_URL}/email/template/${selectedMessageTemplate}${templateName ? `?name=${templateName}` : ''}`,
    )
    .then((messageTemplate) => {
      // toastManager.add('Vorlage wurde geladen', {autoDismiss: true, appearance: 'success'});
      fetchContactById(mainCustomerId).then((mainCustomer) => {
        // override existing values of message
        const attachments = messageTemplate.attachments.concat(documentsAsFiles);
        const newMessage = {
          to: [{ email: mainCustomer.email !== null ? mainCustomer.email : undefined }],
          message: messageTemplate.message,
          subject: messageTemplate.subject,
        };
        if (attachments && attachments.length > 0) {
          newMessage.attachments = attachments;
        }
        saveMessage(newMessage);
      });
    })
    .then(() => {
      setLoading(false);
    });
}

function merge(documentRequests, selectedDocuments) {
  const documents = [];
  for (const dR of selectedDocuments.values()) {
    if (dR.kind !== 'GENERATE') {
      documents.push(dR);
    }
  }
  return documents.concat(documentRequests);
}

// export mails

export const exportEvent = (eventId, receiverContactId, embedCustomerDetails, recommendationId, message) =>
  ajaxActions
    .put(
      `${BASE_URL}/export/events/${eventId}?receiverContactId=${receiverContactId}&embedCustomerDetails=${embedCustomerDetails}`,
      {
        id: recommendationId,
        message,
      },
    )
    .then((resp) => resp);
