// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Group, Space, Stack, Text } from '@mantine/core';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { i18n } from '@lingui/core';
import Moment from 'react-moment';
import PanePaper from '../../../Molecules/PanePaper';
import BookitupAddIcon from '../../../Atoms/BookitupAddIcon';
import { WorkflowTemplateDTO } from '../../../Types/LogT';
import { WorkflowS } from '../../../Service/WorkflowS';
import SkeletonItems from '../../../Atoms/SkeletonItems';
import { ViolationContext } from '../../../Service/Context/ViolationContext';
import { LicenceS } from '../../../Service/LicenceS';
import WorkflowTemplateActions from './WorkflowTemplateActions';
import { openCreateTemplateModal } from './WorkflowTemplateModals';

const WorkflowTemplates = () => {
  const [templates, setTemplates] = useState<WorkflowTemplateDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { executeWithLicenceCheck } = useContext(ViolationContext);
  const existingNames = templates.map((template) => template.name);

  useEffect(() => {
    setLoading(true);
    WorkflowS.fetchTemplates()
      .then(setTemplates)
      .finally(() => setLoading(false));
  }, []);

  const createTemplate = () => {
    executeWithLicenceCheck(LicenceS.Restrictions.AUTOMATIONS, () =>
      openCreateTemplateModal({ onCreate: append, existingNames }),
    );
  };

  const append = (template: WorkflowTemplateDTO) => setTemplates([...templates, template]);

  const remove = (templateId: string) => setTemplates(templates.filter((template) => template.id !== templateId));

  const mapItemUI = (template: WorkflowTemplateDTO) => {
    const { id, name, lastModified } = template;
    return (
      <div key={id}>
        <Group position="apart">
          <Stack spacing={0}>
            <Text
              size="md"
              sx={{ cursor: 'pointer', color: 'black' }}
              variant="link"
              onClick={() => navigate(`/settings/workflow/templates/${id}`)}
            >
              {name}
            </Text>
            <Text size="xs" color="dimmed">
              Bearbeitet{'  '}
              <Moment fromNow date={lastModified} />
            </Text>
          </Stack>
          <div>
            <WorkflowTemplateActions template={template} remove={() => remove(id)} />
          </div>
        </Group>
      </div>
    );
  };

  return (
    <PanePaper
      title="Workflow-Vorlagen"
      tooltip="help.settings.workflow.pane"
      rightSection={<BookitupAddIcon onClick={createTemplate} />}
    >
      <Text>{i18n._('workflow.templates.description')}</Text>
      <Space h="xl" />
      {loading && <SkeletonItems height={50} />}
      {!loading && templates.length === 0 && (
        <Text italic size="xs" color="dimmed">
          {i18n._('workflow.templates.add')}
        </Text>
      )}
      <Stack>{templates.map(mapItemUI)}</Stack>
    </PanePaper>
  );
};

export default WorkflowTemplates;
