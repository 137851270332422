// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Select, Button, Center, Loader, LoadingOverlay, Text } from '@mantine/core';
import { FC, useEffect, useState } from 'react';
import { i18n } from '@lingui/core';
import { closeModal, openModal } from '@mantine/modals';
import { IconUser } from '@tabler/icons';
import { ContactS, Contact } from '../../Service/ContactS';
import { DataS } from '../../Service/DataS';
import { ConstantS } from '../../Service/ConstantS';
import { openCreateContactModal } from './EditContactModal';

export const openSelectCreateContactModal = (
  onContactSelect: (id: number) => void,
  omittedContacts?: number[],
  omitContactsWithPartner?: boolean,
) =>
  openModal({
    modalId: ConstantS.Modals.SelectCreateContact,
    title: (
      <Text weight="bolder" size="xl" sx={{ lineHeight: 1.2 }}>
        Kontakt hinzufügen
      </Text>
    ),
    children: (
      <SelectCreateContactModal
        onSelect={onContactSelect}
        omittedContacts={omittedContacts}
        omitContactsWithPartner={omitContactsWithPartner}
      />
    ),
  });

interface Props {
  onSelect: (contactId: number) => void;
  omittedContacts?: number[];
  omitContactsWithPartner?: boolean;
}

const SelectCreateContactModal: FC<Props> = ({ onSelect, omittedContacts = [], omitContactsWithPartner = false }) => {
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [contactId, setContactId] = useState<string>();

  useEffect(() => {
    setLoading(true);
    ContactS.fetchAll()
      .then((resJSON) => {
        const tmpContacts = resJSON.filter((_contact) => {
          const include = !omittedContacts.includes(_contact.id);
          if (omitContactsWithPartner) {
            return include && !_contact.partnerId;
          }
          return include;
        });
        setContacts(tmpContacts);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreate = (_contact: Contact) => {
    onSelect(_contact.id);
    close();
  };

  const onSubmit = () => {
    if (contactId) {
      onSelect(Number(contactId));
      close();
    }
  };

  const close = () => {
    closeModal(ConstantS.Modals.SelectCreateContact);
  };

  return (
    <>
      <LoadingOverlay visible={loading} overlayBlur={2} loaderProps={{ size: 'lg' }} />
      <Select
        searchable
        mt="sm"
        icon={<IconUser size={16} />}
        rightSection={loading && <Loader size="xs" />}
        label="Kontakt suchen"
        data={DataS.getContactsOptions(contacts) as any}
        value={contactId}
        onChange={(val) => val && setContactId(val)}
        limit={16}
      />
      <Text variant="link" mt="sm" mb="sm" onClick={() => openCreateContactModal(onCreate)} sx={{ cursor: 'pointer' }}>
        + Kontakt erstellen
      </Text>
      <Center>
        <Button fullWidth mt="xl" disabled={loading || !contactId} sx={{ maxWidth: 250 }} onClick={onSubmit}>
          {i18n._('actions.add')}
        </Button>
      </Center>
    </>
  );
};

export default SelectCreateContactModal;
