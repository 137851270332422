// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { BookitupNotification } from '../../../../Types/LogT';

const MailReceived: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { personDescription, mailSubject } = notification;
  return (
    <Text>
      Du hast eine E-Mail{'  '}
      <Text span weight="bolder">
        {mailSubject}
      </Text>
      {'  '}
      von{'  '}
      <Text span weight="bolder">
        {personDescription}
      </Text>
      {'  '}
      erhalten.
    </Text>
  );
};

export default MailReceived;
