import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { EditableProperty } from './EditableProperty';

export const EditableContactRelationProperty = ({
  patchField,
  contactRelation,
  changeHandler,
  placeholder,
  editFormatter,
  labelFormatter,
  ...rest
}) => (
  <EditableProperty
    dto={contactRelation}
    changeHandler={changeHandler}
    patchField={patchField}
    placeholder={placeholder}
    targetUrl={`${process.env.REACT_APP_AUTH_SERVICE_URL}/contactRelations/${contactRelation.id}`}
    editFormatter={editFormatter}
    labelFormatter={labelFormatter}
    {...rest}
  />
);

EditableContactRelationProperty.propTypes = {
  patchField: PropTypes.string.isRequired,
  contactRelation: PropTypes.shape({
    id: PropTypes.number.isRequired,
    lastModified: PropTypes.string.isRequired,
  }).isRequired,
  changeHandler: PropTypes.func,
  placeholder: PropTypes.string,
  editFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
  labelFormatter: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.func]).isRequired,
};
