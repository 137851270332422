import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// eslint-disable-next-line import/prefer-default-export
export const useNavigationItem = (itemPath: string) => {
  const { pathname } = useLocation();
  const [isActive, setActive] = useState<boolean>(pathname.startsWith(itemPath));

  useEffect(() => {
    if (pathname) {
      const itemSelected: boolean = pathname.startsWith(itemPath);
      if (isActive !== itemSelected) {
        setActive(itemSelected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return isActive;
};
