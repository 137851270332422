import { useState, useEffect } from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga4';
import SubscriptionS from '../restapi/subscriptionService';
import { ConstantS } from '../ConstantS';
import { MixpanelS } from '../MixpanelS';

// eslint-disable-next-line import/prefer-default-export
export const useUpdateBillingPlans = (freeLicence: boolean) => {
  const [seconds, setSeconds] = useState(10);
  const [countdown, setCountdown] = useState<NodeJS.Timer>();

  const cleanup = () => {
    if (countdown) {
      clearInterval(countdown);
    }
  };

  useEffect(() => {
    if (freeLicence) {
      setCountdown(
        setInterval(() => {
          setSeconds((prev) => prev - 1);
        }, 1000),
      );
    }
    return cleanup;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [freeLicence]);

  useEffect(() => {
    if (seconds === 0) {
      cleanup();
      SubscriptionS.updateBillingPlansViewed();
      ReactPixel.track(ConstantS.TrackingEvents.Lead);
      ReactGA.event({ category: 'Billing', action: ConstantS.TrackingEvents.Lead });
      MixpanelS.track(ConstantS.TrackingEvents.Lead);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);
};
