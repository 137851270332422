// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Text } from '@mantine/core';
import { FC } from 'react';
import { BookitupNotification } from '../../../../Types/LogT';
import { RoutingS } from '../../../../Service/RoutingS';

const TaskOverdue: FC<{ notification: BookitupNotification }> = ({ notification }) => {
  const { entityLabel, entityType, eventId } = notification;

  const navigateToTask = () => {
    if (entityType === 'events') {
      RoutingS.openInNewTab(`/events/${eventId}/details`);
    } else {
      RoutingS.openInNewTab(`/tasks`);
    }
  };

  return (
    <Text>
      Aufgabe
      <Text span weight="bolder" variant="link" color="blue" sx={{ cursor: 'pointer' }} onClick={navigateToTask}>
        {` ${entityLabel} `}
      </Text>
      ist überfällig.
    </Text>
  );
};

export default TaskOverdue;
