import { Popover, Group, Space, Tooltip, Loader, ActionIcon } from '@mantine/core';
import {
  IconBold,
  IconCalendarTime,
  IconClearFormatting,
  IconHash,
  IconItalic,
  IconLink,
  IconList,
  IconListNumbers,
  IconMoodWink2,
  IconPaperclip,
  IconSend,
  IconShare,
  IconTemplate,
  IconTrashX,
  IconUnderline,
} from '@tabler/icons';
import { useCurrentEditor } from '@tiptap/react';
import EmojiPicker, { IEmojiData } from 'emoji-picker-react';
import { FC, useCallback, useContext, useState } from 'react';
import { WindowContext } from '../../../Service/Context/WindowContext';
import { MessageContext } from '../../../Service/Context/MessageContextV2';
import { openDeleteDraftModal } from '../../Message/MessageModals';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import { CustomModalHeader } from '../../../Molecules/Dialogs';
import { useParams } from 'react-router';
import { useModals } from '@mantine/modals';
import ActionIconWithoutFocus from '../../../Atoms/ActionIconWithoutFocus';
import { EmailBoxContext } from '../../../Service/Context/EmailBoxContext';
import { PostboxCountersContext } from '../../../Service/Context/PostboxCountersContext';

interface Props {
  addAttachment: () => unknown;
  loadTemplate?: () => void;
}

const MessageToolbar: FC<Props> = ({ addAttachment, loadTemplate }) => {
  const { editor } = useCurrentEditor();
  const { id: eventId } = useParams();
  const { openContextModal } = useModals();
  const { profile } = useContext(ProfileContext);
  const [opened, setOpened] = useState(false);
  const { xs, md } = useContext(WindowContext);
  const { message, sendMessage, changeMessage, sending, scheduleMessage } = useContext(MessageContext);
  const { refresh } = useContext(EmailBoxContext);
  const { refreshCount } = useContext(PostboxCountersContext);

  const onDeleteDraft = () => {
    changeMessage(null);
    refresh();
    refreshCount('drafts');
  };

  const setLink = useCallback(() => {
    if (editor) {
      const previousUrl = editor.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);
      // cancelled
      if (url === null) {
        return;
      }
      // empty
      if (url === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }
      // update link
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    }
  }, [editor]);

  const onEmojiClick = (event: React.MouseEvent, data: IEmojiData) => {
    if (editor) {
      editor.commands.insertContent(data.emoji);
      setOpened(false);
    }
  };

  const onHashClicked = () => {
    if (editor) {
      editor.commands.insertContent('#');
    }
  };

  const openInsertPortalLink = () =>
    openContextModal('insertPortalLink', {
      title: <CustomModalHeader>Portal-Zugang erstellen</CustomModalHeader>,
      innerProps: {
        profile,
        eventId,
        editor,
      },
      centered: true,
      size: 'xl',
    });

  if (!editor) {
    return null;
  }

  return (
    <Group spacing={0} position="apart" p="xs">
      <Group spacing={0}>
        <Tooltip hidden={sending} openDelay={300} withArrow transition="slide-up" label="Senden">
          <ActionIcon
            onMouseDown={(e: any) => e.preventDefault()}
            disabled={sending}
            onClick={() => sendMessage(editor)}
            size="lg"
            variant="filled"
            color="blue"
          >
            {!sending && <IconSend size={20} />}
            {sending && <Loader size={20} />}
          </ActionIcon>
        </Tooltip>
        <Space w="xs" />
        {loadTemplate && (
          <ActionIcon onClick={loadTemplate}>
            <IconTemplate size={20} />
          </ActionIcon>
        )}
        <Tooltip openDelay={300} withArrow transition="slide-up" label="E-Mail planen">
          <ActionIcon onClick={scheduleMessage}>
            <IconCalendarTime size={20} />
          </ActionIcon>
        </Tooltip>
        <Tooltip openDelay={300} withArrow transition="slide-up" label="Fett">
          <div>
            <ActionIconWithoutFocus
              variant={editor.isActive('bold') ? 'filled' : 'subtle'}
              onClick={() => editor.chain().focus().toggleBold().run()}
              disabled={!editor.can().chain().focus().toggleBold().run()}
            >
              <IconBold size={20} />
            </ActionIconWithoutFocus>
          </div>
        </Tooltip>
        <Tooltip openDelay={300} withArrow transition="slide-up" label="Kursiv">
          <div>
            <ActionIconWithoutFocus
              variant={editor.isActive('italic') ? 'filled' : 'subtle'}
              onClick={() => editor.chain().focus().toggleItalic().run()}
              disabled={!editor.can().chain().focus().toggleItalic().run()}
            >
              <IconItalic size={20} />
            </ActionIconWithoutFocus>
          </div>
        </Tooltip>
        <Tooltip openDelay={300} withArrow transition="slide-up" label="Unterstrichen">
          <div>
            <ActionIconWithoutFocus
              variant={editor.isActive('underline') ? 'filled' : 'subtle'}
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              disabled={!editor.can().chain().focus().toggleUnderline().run()}
            >
              <IconUnderline size={20} />
            </ActionIconWithoutFocus>
          </div>
        </Tooltip>
        <Tooltip openDelay={300} withArrow transition="slide-up" label="Formatierung löschen">
          <div>
            <ActionIconWithoutFocus
              variant="subtle"
              onClick={() => editor.chain().focus().clearNodes().unsetAllMarks().run()}
              disabled={!editor.can().chain().focus().clearNodes().unsetAllMarks().run()}
            >
              <IconClearFormatting size={20} />
            </ActionIconWithoutFocus>
          </div>
        </Tooltip>
        {!xs && (
          <>
            <Tooltip openDelay={300} withArrow transition="slide-up" label="Liste">
              <div>
                <ActionIconWithoutFocus
                  variant={editor.isActive('bulletList') ? 'filled' : 'subtle'}
                  onClick={() => editor.chain().focus().toggleBulletList().run()}
                  disabled={!editor.can().chain().focus().toggleBulletList().run()}
                >
                  <IconList size={20} />
                </ActionIconWithoutFocus>
              </div>
            </Tooltip>
            <Tooltip openDelay={300} withArrow transition="slide-up" label="Nummerierte Liste">
              <div>
                <ActionIconWithoutFocus
                  variant={editor.isActive('orderedList') ? 'filled' : 'subtle'}
                  onClick={() => editor.chain().focus().toggleOrderedList().run()}
                  disabled={!editor.can().chain().focus().toggleOrderedList().run()}
                >
                  <IconListNumbers size={20} />
                </ActionIconWithoutFocus>
              </div>
            </Tooltip>
          </>
        )}
        <Tooltip openDelay={300} withArrow transition="slide-up" label="Verlinken">
          <div>
            <ActionIconWithoutFocus variant={editor.isActive('link') ? 'filled' : 'subtle'} onClick={setLink}>
              <IconLink size={20} />
            </ActionIconWithoutFocus>
          </div>
        </Tooltip>
        <Tooltip openDelay={300} withArrow transition="slide-up" label="Anhang hinzufügen">
          <div>
            <ActionIconWithoutFocus onClick={addAttachment}>
              <IconPaperclip size={20} />
            </ActionIconWithoutFocus>
          </div>
        </Tooltip>
        {!md && (
          <Popover opened={opened} onChange={setOpened} position="top" returnFocus={true}>
            <Popover.Target>
              <Tooltip openDelay={300} withArrow transition="slide-up" label="Emoji">
                <div>
                  <ActionIconWithoutFocus variant="subtle" onClick={() => setOpened((o) => !o)}>
                    <IconMoodWink2 size={20} />
                  </ActionIconWithoutFocus>
                </div>
              </Tooltip>
            </Popover.Target>
            <Popover.Dropdown>
              <EmojiPicker onEmojiClick={onEmojiClick} />
            </Popover.Dropdown>
          </Popover>
        )}
        {!xs && eventId && (
          <Tooltip openDelay={300} withArrow transition="slide-up" label="Platzhalter einfügen ">
            <div>
              <ActionIconWithoutFocus onClick={onHashClicked}>
                <IconHash size={20} />
              </ActionIconWithoutFocus>
            </div>
          </Tooltip>
        )}
        {eventId && (
          <Tooltip openDelay={300} withArrow transition="slide-up" label="Portal-Link einfügen">
            <div>
              <ActionIconWithoutFocus onClick={openInsertPortalLink}>
                <IconShare size={20} />
              </ActionIconWithoutFocus>
            </div>
          </Tooltip>
        )}
      </Group>
      <Group spacing={5}>
        <Tooltip openDelay={300} withArrow transition="slide-up" label="Entwurf löschen">
          <ActionIcon onClick={() => openDeleteDraftModal({ draftId: message?.id ?? 4, onDelete: onDeleteDraft })}>
            <IconTrashX size={20} />
          </ActionIcon>
        </Tooltip>
      </Group>
    </Group>
  );
};

export default MessageToolbar;
