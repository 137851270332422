import { ajaxActions } from '../AjaxActions';
import { ToastS } from '../ToastS';
import { fetchResourceByEntityTypeAndEntityId, fetchSubmittedFormSubmission } from './shareService';

const BASE_URL = process.env.REACT_APP_DOCUMENT_SERVICE_URL;

export const fetchDocumentsByEvent = (eventId) =>
  ajaxActions.get(`${BASE_URL}/documents/event/${eventId}`).then((resp) => resp);

export const fetchDocumentById = (documentId) =>
  ajaxActions.get(`${BASE_URL}/documents/${documentId}`).then((resp) => resp);

export const patchDocument = (documentId, patch) =>
  ajaxActions.patch(`${BASE_URL}/documents/${documentId}`, patch).then((resp) => resp);

export const transmitToBookkeepingTool = (documentId) =>
  ajaxActions.put(`${BASE_URL}/documents/${documentId}/transmitToBookkeepingTool`).then((resp) => resp);
export const fetchDocumentsByCustomerContactId = (contactId) =>
  ajaxActions.get(`${BASE_URL}/documents?customerContactId=${contactId}`).then((resp) => resp);

export const fetchDocumentsByType = (documentType, fromDate, toDate) => {
  const requestParams = [];
  if (documentType) {
    requestParams.push(`documentType=${documentType}`);
  }
  if (fromDate) {
    requestParams.push(`fromDate=${fromDate}`);
  }
  if (toDate) {
    requestParams.push(`toDate=${toDate}`);
  }

  return ajaxActions.get(`${BASE_URL}/documents?${requestParams.join('&')}`).then((resp) => resp);
};

export const copyDocument = (documentId, documentType, templateName, customerId, finalInvoice) => {
  let requestParam = documentType ? `?documentType=${documentType}` : '';
  requestParam += templateName ? `&templateName=${templateName}` : '';
  requestParam += customerId ? `&customerId=${customerId}` : '';
  requestParam += finalInvoice ? `&finalInvoice=true` : '';
  return ajaxActions.put(`${BASE_URL}/documents/${documentId}${requestParam}`).then((resp) => resp);
};

export const fetchDocumentsAndContractsByEvent = (eventId) =>
  ajaxActions.get(`${BASE_URL}/existing/${eventId}/documentsAndContracts`).then((resp) => resp);

export const generateDocumentById = (documentId) =>
  ajaxActions.get(`${BASE_URL}/existing?documentid=${documentId}`).then((resp) => resp);

export const addPositionAtIndex = (documentId, positionIndex) =>
  ajaxActions.put(`${BASE_URL}/documents/${documentId}/positions?index=${positionIndex}`).then((resp) => resp);

export const movePosition = (documentId, fromIndex, newIndex) =>
  ajaxActions
    .patch(`${BASE_URL}/documents/${documentId}/positions?fromIndex=${fromIndex}&newIndex=${newIndex}`)
    .then((resp) => resp);

export const deletePositionAtIndex = (documentId, positionIndex) =>
  ajaxActions.del(`${BASE_URL}/documents/${documentId}/positions?index=${positionIndex}`).then((resp) => resp);

export const deleteDocument = (documentId) =>
  ajaxActions.del(`${BASE_URL}/documents/${documentId}`).then((resp) => resp);

export const countDocumentsOfEvent = (eventId) =>
  ajaxActions.exchange('HEAD', `${BASE_URL}/document/all/event/${eventId}`).then((resp) => resp);

export const sumDocumentsOfEvents = (eventIds) => ajaxActions.post(`${BASE_URL}/document/sum`, { eventIds });

export const deleteDocumentTemplate = (documentType, templateName) =>
  ajaxActions.del(`${BASE_URL}/documentTemplates/${documentType}/${templateName}`).then((resp) => resp);

export const copyDocumentTemplate = (documentType, templateName) =>
  ajaxActions.put(`${BASE_URL}/documentTemplates/${documentType}/${templateName}/copy`).then((resp) => resp);

export const getResourcePosition = (positionId) =>
    ajaxActions.get(`${BASE_URL}/resourcePosition?positionId=${positionId}`).then((resp) => resp);

export const getResourcePositionFavorite = (positionId) =>
    ajaxActions.get(`${BASE_URL}/resourcePositionFavorite?positionId=${positionId}`).then((resp) => resp);

export const createResourcePositionFavorite = (resourcePosition) =>
    ajaxActions.put(`${BASE_URL}/resourcePositionFavorites`, resourcePosition).then((resp) => resp);

export const createResourcePosition = (resourcePosition) =>
    ajaxActions.put(`${BASE_URL}/resourcePositions`, resourcePosition).then((resp) => resp);

export const deleteResourcePositionFavorite = (resourcePositionId) =>
    ajaxActions.del(`${BASE_URL}/resourcePositionFavorites/${resourcePositionId}`).then((resp) => resp);

export const deleteResourcePosition = (resourcePositionId) =>
    ajaxActions.del(`${BASE_URL}/resourcePositions/${resourcePositionId}`).then((resp) => resp);

export const deleteDocumentHelper = (documentId, onSuccess) => {
  fetchResourceByEntityTypeAndEntityId('documents', documentId).then((resp) => {
    if (resp.ok) {
      resp.json().then((resources) => {
        if (resources.length === 0) {
          deleteDocument(documentId)
            .then((resp) => {
              if (resp.ok) {
                onSuccess();
                ToastS.info('delete.document', 'Dokument wurde gelöscht');
              } else if (resp.status === 226) {
                ToastS.error('delete.document', 'Dokument wird in Vertrag verwendet');
              } else {
                ToastS.error('delete.document', 'Dokument konnte nicht gelöscht werden');
              }
            })
            .catch(() => {
              ToastS.error('delete.document', 'Dokument konnte nicht gelöscht werden');
            });
        } else {
          ToastS.error('id', 'Dokument kann nicht gelöscht werden, da es im Online-Portal freigegeben wurde.');
        }
      });
    }
  });
};

export const deleteDocuments = (documentIds) => ajaxActions.del(`${BASE_URL}/documents`, documentIds);

export const createDocument = (customerId, template, eventId, documentType) => {
  let url = `${BASE_URL}/documents?customerId=${customerId}&documentType=${documentType}`;
  if (template) {
    url += `&template=${template}`;
  }
  if (eventId) {
    url += `&eventId=${eventId}`;
  }
  return ajaxActions.put(url).then((resp) => resp);
};

export const getUnresolvablePlaceholders = (entityType, entityId) =>
  ajaxActions
    .get(`${process.env.REACT_APP_PLACEHOLDER_SERVICE_URL}/unresolvedPlaceholders/${entityType}/${entityId}`)
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      }
      console.error(`could not get unresolvablePlaceholders of ${entityType} ${entityId}`);
    });

export const getTemplateUnresolvedPlaceholders = (documentType, templateName, eventId) => {
  let entityType = 'documentTemplates';
  if (documentType === 'CONTRACT') {
    entityType = 'contractTemplates';
  } else if (documentType === 'QUESTIONNAIRE') {
    entityType = 'questionnaireTemplates';
  }
  return ajaxActions
    .get(
      `${process.env.REACT_APP_PLACEHOLDER_SERVICE_URL}/templatePlaceholders/${entityType}/${templateName}?eventId=${eventId}`,
    )
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      }
      console.error(`could not check if template ${templateName} has unresolvablePlaceholders in event ${eventId}`);
    })
    .catch((error) => {
      console.error(`could not check if template ${templateName} has unresolvablePlaceholders in event ${eventId}`);
      console.error({ error });
    });
};

export const saveDocumentFromTemplate = (documentTemplateSet) =>
  ajaxActions.post(`${BASE_URL}/process/saveDocumentsFromTemplate`, documentTemplateSet).then((resp) => resp);

export const getSequentialNumber = (sequentialNumberType) =>
  ajaxActions.get(`${BASE_URL}/sequentialNumbers/${sequentialNumberType}`).then((resp) => resp);

export const getNextSequentialNumber = (sequentialNumberType, documentId) =>
  ajaxActions
    .put(`${BASE_URL}/sequentialNumbers/${sequentialNumberType}${documentId ? `?documentId=${documentId}` : ''}`)
    .then((resp) => resp);
