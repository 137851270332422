import React, {forwardRef, useState} from 'react';
import {Input, Label} from 'reactstrap';
import {Trans} from '@lingui/react';
import DatePicker, {registerLocale, setDefaultLocale} from 'react-datepicker';
import de from 'date-fns/locale/de';
import PropTypes from 'prop-types';
import {dateFormatService} from '../../Service/DateFormatService';
import {InputHelp} from './InputHelp';

registerLocale('de', de);

Date.prototype.isValid = function () {
  // If the date object is invalid it
  // will return 'NaN' on getTime()
  // and NaN is never equal to itself.
  return this.getTime() === this.getTime();
};

Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.addHours = function (hours) {
  const date = new Date(this.valueOf());
  date.setHours(date.getHours() + hours);
  return date;
};

const EditableDateInput = ({
  finishCallback,
  value: initialValue,
  placeholder = '',
  patchField,
  left,
  dataTour,
  labelStyle,
  disabled,
  testId,
  ...rest
}) => {
  // TODO: nasty initialValue
  const [value, setValue] = useState(
    initialValue ? (initialValue.date ? initialValue.date : new Date(initialValue)) : undefined,
  );
  const [error, setError] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const ref = React.createRef();

  const styles = {
    error: {
      borderColor: 'red',
    },
    input: {
      marginBottom: 0,
    },
    label: {
      color: '#8ca3b5',
      fontWeight: 800,
      ...labelStyle,
    },
    inputContainer: {
      marginBottom: 9,
    },
  };

  function save(val) {
    let dateVal;
    if (val == null) {
      dateVal = null;
    } else {
      dateVal = new Date(val);
      if (!rest.showTimeSelect) {
        dateVal.setHours(2); // if date was deleted, hours is set to 00:00, that result in a day before on saving
      }
    }

    setLoading(true);

    finishCallback({ [patchField]: dateVal })
      .catch((error) => {
        setError(error.message);
      })
      .then(() => {
        setLoading(false);
      });
  }
  setDefaultLocale('de');

  const handleOnBlur = ({ target: { value } }) => {
    // TODO: handle e.g. 4.5.20 instead of 04.05.2020
    if (value === '' || !isValidDate(value)) {
      setValue(null);
      save(null);
    } else {
      setValue(dateFormatService.getDateOfDeLocale(value));
      save(dateFormatService.getDateOfDeLocale(value));
    }
  };

  // Validates that the input string is a valid date formatted as "dd.mm.yyyy"
  function isValidDate(dateString) {
    if (!/^\d{1,2}.\d{1,2}.\d{4}$/.test(dateString) && !/^\d{1,2}.\d{1,2}.\d{2}$/.test(dateString)) return false;
    return true;
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const CustomInput = forwardRef(({ value, onClick }, ref) => {
    const [tmpValue, setTmpValue] = useState(value);

    return (
      <Input
        data-testid="customInput"
        ref={ref}
        disabled={disabled}
        onKeyUp={(e) => {
          switch (e.key) {
            case 'Enter':
              handleOnBlur(e);
              break;
            case 'Escape':
              finishCallback({ [patchField]: initialValue })
                .catch((error) => setError(error.message))
                .then(() => setLoading(false));
              break;
            default:
          }
        }}
        onBlur={tmpValue !== value ? (e) => handleOnBlur(e) : null}
        onChange={(e) => {
          setTmpValue(e.target.value);
        }}
        value={tmpValue}
        className={loading ? 'form-control loading' : 'form-control'}
        onClick={onClick}
      />
    );
  });

  return (
    <div style={styles.inputContainer} data-testid={testId}>
      <Label sm={12} style={styles.label}>
        <Trans id={placeholder} />
      </Label>
      <DatePicker
        dateFormat={rest.showTimeSelect ? 'Pp' : 'P'}
        isClearable
        selected={value}
        onChange={(val) => {
          setValue(val);
          save(val);
        }}
        onBlur={(e) => {}}
        style={error ? { ...styles.error, ...styles.input } : styles.input}
        // onChangeRaw={event => console.log(event.target.value)}
        popperPlacement={left ? 'bottom-end' : 'bottom-start'}
        showYearDropdown
        yearDropdownItemNumber={1}
        showWeekNumbers
        // onCalendarClose={handleCalendarClose}
        // onCalendarOpen={handleCalendarOpen}
        // strictParsing
        customInput={<CustomInput ref={ref} />}
        openToDate={value}
        scrollableYearDropdown
        disabledKeyboardNavigation
        {...rest}
      />
      {error ? <InputHelp errorDescription={error} /> : null}
    </div>
  );
};

export default EditableDateInput;

EditableDateInput.propTypes = {
  value: PropTypes.string,
  finishCallback: PropTypes.func,
  autoFocus: PropTypes.bool,
};

EditableDateInput.defaultProps = {
  autoFocus: true,
};

/*
<UserAgent mobile tablet>
                <input className={"form-control"} type={"date"} onChange={(e) => {this.handleDayClick(e.target.value, this.props.name)}} value={value} placeholder={""} />
            </UserAgent>
            <UserAgent computer>
*/
