import { ajaxActions } from '../AjaxActions';
import { ConstantS } from '../ConstantS';
import { MixpanelS } from '../MixpanelS';
import { ToastS } from '../ToastS';

const BASE_URL = process.env.REACT_APP_AUTH_SERVICE_URL;

const TOKEN_TYPE = {
  VERIFY_USER: 'USER_VERIFICATION',
  RESET_PASSWORD: 'RESET_PASSWORD',
  SHARE_PROFILE: 'SHARE_PROFILE',
};

const signup = (user) => ajaxActions.post(`${BASE_URL}/applicationUsers`, user).then((resp) => resp);

const signupWithGoogle = (user) => ajaxActions.post(`${BASE_URL}/applicationUsers/google`, user).then((resp) => resp);

const resetPassword = (token, body) => {
  const tokenParam = token ? `?token=${token}` : '';
  return ajaxActions.post(`${BASE_URL}/applicationUsers/reset-password${tokenParam}`, body).then((resp) => resp);
};

export const getProfileShares = () => ajaxActions.get(`${BASE_URL}/profiles/shares`).then((resp) => resp);

export const getOwnProfiles = () => ajaxActions.get(`${BASE_URL}/profiles/own`).then((resp) => resp);

export const switchProfile = (profileId) =>
  ajaxActions.post(`${BASE_URL}/profiles/switchProfile/${profileId}`).then((resp) => resp);

const refreshToken = (email) =>
  ajaxActions
    .get(`${BASE_URL}/applicationUsers/${email}/token`)
    .then((resp) => {
      if (!resp.ok) {
        throw resp;
      }
      return resp.text();
    })
    .catch(() => {
      console.error('Token konnte nicht geholt werden');
    });

export const getUsersOfProfile = () =>
  ajaxActions.get(`${BASE_URL}/profiles/shares/applicationUsers`).then((resp) => resp);

export const isOwnProfile = (profileId) =>
  ajaxActions.get(`${BASE_URL}/profiles/${profileId}/isOwn`).then((resp) => resp);

export const deleteUserOfProfile = (email) =>
  ajaxActions.del(`${BASE_URL}/profiles/shares/applicationUsers`, { email }).then((resp) => resp);

export const admitUserOnProfile = (email) =>
  ajaxActions.put(`${BASE_URL}/profiles/shares/applicationUsers`, { email }).then((resp) => resp);

export const patchProfile = (profileId, patch) =>
  ajaxActions.patch(`${BASE_URL}/profiles/${profileId}`, patch).then((resp) => resp);

export const deleteAccount = () => ajaxActions.del(`${BASE_URL}/applicationUsers`).then((resp) => resp);

const resendVerificationEmailAPI = (email) =>
  ajaxActions.get(`${BASE_URL}/applicationUsers/verification/${email}/resend`).then((resp) => resp);

const confirmRegistrationAPI = (token) =>
  ajaxActions.get(`${BASE_URL}/applicationUsers/confirm-registration?token=${token}`).then((resp) => resp);

const confirmInvitation = (token, password) =>
  ajaxActions.post(`${BASE_URL}/applicationUsers/confirm-invitation?token=${token}`, { password }).then((resp) => resp);

const verifyToken = (tokenType, token) =>
  ajaxActions.get(`${BASE_URL}/applicationUsers/verify-token/${tokenType}/${token}`).then((resp) => resp);

const fetchProfile = (profileId) => ajaxActions.get(`${BASE_URL}/profiles/${profileId}`).then((resp) => resp);

const getBankAccount = (profileId) =>
  ajaxActions.get(`${process.env.REACT_APP_AUTH_SERVICE_URL}/profiles/${profileId}/bankAccount`).then((resp) => resp);

const logout = () =>
  ajaxActions.get(`${process.env.REACT_APP_AUTH_SERVICE_URL}/applicationUsers/logout`).then((resp) => resp);

const getOwnAppUser = async () => {
  const res = await ajaxActions
    .get(`${process.env.REACT_APP_AUTH_SERVICE_URL}/applicationUsers/own`)
    .then((resp) => resp);
  if (res.ok) {
    return res.json();
  }
  return null;
};

const redeemTrialLicence = async () => {
  const res = await ajaxActions.get(`${BASE_URL}/applicationUsers/redeem-trial`);
  if (res.ok) {
    MixpanelS.track(ConstantS.TrackingEvents.TrialLicenceRedeemed);
    ToastS.success('foo', '🚀 Testphase erfolgreich gestartet');
    return true;
  }
  ToastS.generalError();
  return false;
};

export const AuthS = {
  TOKEN_TYPE,
  deleteAccount,
  signup,
  signupWithGoogle,
  refreshToken,
  confirmRegistrationAPI,
  resendVerificationEmailAPI,
  resetPassword,
  confirmInvitation,
  verifyToken,
  fetchProfile,
  getBankAccount,
  logout,
  getOwnAppUser,
  redeemTrialLicence,
};
