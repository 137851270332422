// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Center, Stack, Text } from '@mantine/core';
import { useLingui } from '@lingui/react';
import { useContext, useEffect } from 'react';
import { ProfileContext } from '../../../Service/Context/ProfileContext';
import PanePaper from '../../../Molecules/PanePaper';
import BookitupSelect from '../../../Atoms/BookitupSelect';
import { DataS } from '../../../Service/DataS';
import { RoutingS } from '../../../Service/RoutingS';
import LexOfficeConfig from './lox/LexOfficeConfig';
import CandisConfig from './CandisConfig';

const Accounting = () => {
  const { i18n } = useLingui();
  const { profile, patchProfile } = useContext(ProfileContext);

  useEffect(() => {
    RoutingS.changeTitle('Buchhaltung');
  }, []);

  if (!profile) {
    return null;
  }
  const { accountingTool } = profile;

  return (
    <PanePaper sx={{ maxWidth: 1000 }}>
      <Stack mb="xl">
        <Text size="lg" weight="bold">
          {i18n._('accounting.title')}
        </Text>
        <Center>
          <BookitupSelect
            value={accountingTool}
            onChange={(_accountingTool) => _accountingTool && patchProfile({ accountingTool: _accountingTool })}
            data={DataS.getAccountingWebsiteOptions(i18n)}
            sx={{ maxWidth: 200 }}
          />
        </Center>
      </Stack>

      {accountingTool === 'LexOffice' && <LexOfficeConfig />}
      {accountingTool === 'Candis' && <CandisConfig />}
      {accountingTool && accountingTool !== 'LexOffice' && accountingTool !== 'Candis' && accountingTool !== 'Other' && (
        <PanePaper title="Wir unterstützen Dein Buchhaltungstool aktuell noch nicht." sx={{ maxWidth: 850 }}>
          <Text>
            Unser Entwickler-Team richtet sich bei der Umsetzung von neuen Funktionen nach der Häufigkeit der gesendeten
            Funktionswünsche. Sende uns gerne einen Funktions-Wunsch über den Live-Chat unten rechts, um Dein Interesse
            zu bekunden.
          </Text>
        </PanePaper>
      )}
      {accountingTool === 'Other' && (
        <Text>Schreib uns gerne, welches Tool du benutzt und wir prüfen, ob wir es an bookitup anbinden können.</Text>
      )}
    </PanePaper>
  );
};

export default Accounting;
