import { useContext, useEffect, useState } from 'react';
import { useModals } from '@mantine/modals';
import { ModalFooter } from 'reactstrap';
import { CustomModalHeader } from '../../Molecules/Dialogs';
import { TransButtonDefault, TransButtonPrimary } from '../../Atoms/Buttons';
import { FocusedNodeContext } from '../../Service/Context/FocusedNode';
import { TransTextInputField } from '../../Atoms/InputFields';
import { fetchShares } from '../../Service/restapi/shareService';
import { RadioBox } from '../../Atoms/inputs/RadioBox';
import { CheckLicence } from '../Upgrade/CheckLicence';
import { ProfileContext } from '../../Service/Context/ProfileContext';
import { useCurrentEditor } from '@tiptap/react';

function insertAtCursor(myField, myValue) {
  // IE support
  if (document.selection) {
    console.log('IE');
    myField.focus();
    const sel = document.selection.createRange();
    sel.text = myValue;
  }
  // MOZILLA and others
  else if (myField.selectionStart || myField.selectionStart === '0') {
    const startPos = myField.selectionStart;
    const endPos = myField.selectionEnd;
    myField.value =
      myField.value.substring(0, startPos) + myValue + myField.value.substring(endPos, myField.value.length);
  } else {
    myField.innerHTML += myValue;
  }
}

const InsertPortalLink = ({ context, id, innerProps }) => {
  const { eventId, profile, editor } = innerProps;
  const { focusedNode } = useContext(FocusedNodeContext);
  const [name, setName] = useState();
  const [shares, setShares] = useState();
  const [selectedShare, setSelectedShare] = useState(undefined);
  const [useCustomQuickLink, setUseCustomQuickLink] = useState(false);

  console.log(editor)

  const { openContextModal, closeModal } = useModals();

  const [lastModified, setLastModified] = useState(new Date());
  const reload = () => setLastModified(new Date());

  useEffect(() => {
    setSelectedShare(undefined);
    setName(undefined);
  }, []);

  useEffect(() => {
    const reloadShares = () => {
      if (eventId) {
        fetchShares('events', eventId).then((resp) => {
          if (resp.ok) {
            resp.json().then(setShares);
          }
        });
      }
    };
    reloadShares();
  }, [eventId, lastModified]);

  if (!eventId || !shares) {
    return null;
  }

  const openShareModalFlow = () =>
    openContextModal('shareModalFlow', {
      title: <CustomModalHeader>Portal-Zugang erstellen</CustomModalHeader>,
      innerProps: {
        profile,
        eventId,
        reload,
        profile,
      },
      centered: true,
      size: 'xl',
    });

  const onClick = () => {
    const shareLink = `https://portal.bookitup.de/login?shareId=${selectedShare.id}&key=${selectedShare.password}`;
    const text = `<a title="${shareLink}" href="${shareLink}" target="_blank">${name}</a><br/><br/>`;
    if (editor) {
      editor.commands.insertContent(text);
      closeThisModal();
    }
  };

  const closeThisModal = () => closeModal('insertPortalLink');

  function setQuickLinkName(link) {
    setName(link);
    setUseCustomQuickLink(false);
  }

  const options = [];

  if (shares) {
    shares.forEach((share) => {
      options.push({
        label: share.name ? share.name : 'Portal-Zugang',
        checked: selectedShare ? share.id === selectedShare.id : false,
        onClick: () => setSelectedShare(share),
        onDeselect: () => setSelectedShare(undefined),
      });
    });
  }

  const quickLinkOptions = [
    {
      label: 'Angebot öffnen',
      checked: name ? name === 'Angebot öffnen' : false,
      onClick: () => setQuickLinkName('Angebot öffnen'),
    },
    {
      label: 'Angebot öffnen und bestätigen',
      checked: name ? name === 'Angebot öffnen und bestätigen' : false,
      onClick: () => setQuickLinkName('Angebot öffnen und bestätigen'),
    },
    {
      label: 'Vertrag öffnen',
      checked: name ? name === 'Vertrag öffnen' : false,
      onClick: () => setQuickLinkName('Vertrag öffnen'),
    },
    {
      label: 'Vertrag öffnen und unterschreiben',
      checked: name ? name === 'Vertrag öffnen und unterschreiben' : false,
      onClick: () => setQuickLinkName('Vertrag öffnen und unterschreiben'),
    },
    {
      label: 'Angebot und Vertrag öffnen',
      checked: name ? name === 'Angebot und Vertrag öffnen' : false,
      onClick: () => setQuickLinkName('Angebot und Vertrag öffnen'),
    },
    {
        label: 'Auftragsbestätigung öffnen',
        checked: name ? name === 'Auftragsbestätigung öffnen' : false,
        onClick: () => setQuickLinkName('Auftragsbestätigung öffnen'),
    },
    {
      label: 'Checkliste öffnen',
      checked: name ? name === 'Checkliste öffnen' : false,
      onClick: () => setQuickLinkName('Checkliste öffnen'),
    },
    {
      label: 'Fragebogen öffnen',
      checked: name ? name === 'Fragebogen öffnen' : false,
      onClick: () => setQuickLinkName('Fragebogen öffnen'),
    },
    {
        label: 'Kunden-Portal öffnen',
        checked: name ? name === 'Kunden-Portal öffnen' : false,
        onClick: () => setQuickLinkName('Kunden-Portal öffnen'),
    },
    {
      label: 'Anderes',
      checked: useCustomQuickLink,
      onDeselect: () => setUseCustomQuickLink(false),
      onClick: () => {
        setName('');
        setUseCustomQuickLink(true);
      },
    },
  ];

  return (
    <div style={{ paddingLeft: 15 }}>
      <div className="description">Wähle den Portal-Zugang aus:</div>
      {shares.length > 0 && <RadioBox options={options} />}

      <CheckLicence
        buttonText="+ Portal-Zugang erstellen"
        onClick={openShareModalFlow}
        licence="INTERACTIVE"
        upgradeTitle="Lass den Papierkram hinter Dir"
        upgradeText={
          'Mit dem Interactive-Modul kannst Du Deinem Kunden Fragebögen erstellen und freigeben, \nVerträge mit digitaler Unterschrift bereitstellen und Dein Angebot zur Bestätigung bereitstellen.\n'
        }
      />

      {selectedShare && (
        <>
          <div className="description" style={{ marginTop: 10 }}>
            Wähle eine Vorlage als Beschreibung, oder erstelle einen eigenen Text
          </div>
          <RadioBox options={quickLinkOptions} />
        </>
      )}
      {useCustomQuickLink && (
        <TransTextInputField
          style={{ marginTop: 10 }}
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
          saveValue={() => {}}
          placeholder="Link-Beschreibung (z.B. Angebot öffnen)"
        />
      )}
      <ModalFooter>
        <TransButtonDefault onClickHandler={closeThisModal} text="actions.cancel" />
        <TransButtonPrimary disabled={!name || name === ''} onClickHandler={onClick} text="actions.insert.link" />
      </ModalFooter>
    </div>
  );
};

export default InsertPortalLink;
