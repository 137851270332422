import { FC, useEffect, useState } from 'react';
import { Autocomplete, AutocompleteItem, Loader, Text } from '@mantine/core';
import { fetchTaskGroups } from '../../../Service/restapi/taskService';
import AgendaGroup from '../../../Types/AgendaGroup';
import { Icon } from '../../../Atoms/Icons';

type Props = {
  entityType?: string;
  initialGroupName?: string;
  onGroupNameChanged?: (name: string) => void;
  onGroupIdChanged?: (groupId: string) => void;
  placeholder?: string;
  withoutTitle?: boolean;
  required?: boolean;
  clearable?: boolean;
};

const TaskGroupSelection: FC<Props> = ({
  entityType,
  initialGroupName,
  onGroupNameChanged,
  onGroupIdChanged,
  placeholder,
  withoutTitle,
  required,
  clearable,
}) => {
  const [groups, setGroups] = useState<AgendaGroup[]>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const updateFromInput = (val: string) => {
    setValue(val);
    onGroupNameChanged?.(val);
  };

  useEffect(() => {
    setLoading(true);
    fetchTaskGroups(entityType)
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((resGroups: AgendaGroup[]) => {
            setGroups(resGroups);

            const groupName = initialGroupName ?? 'Standard';

            const defaultGroup =
              resGroups.find((g) => g.name === groupName) || resGroups.find((g) => g.name === 'Standard');
            if (defaultGroup) {
              onGroupNameChanged?.(defaultGroup.name);
              onGroupIdChanged?.(defaultGroup.id);
              setValue(defaultGroup.name);
            }
          });
        } else {
          console.error(`could not get lists of entityType ${entityType}`);
        }
      })
      .catch(() => {
        console.error(`could not get lists of entityType ${entityType}`);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [entityType, initialGroupName, onGroupIdChanged, onGroupNameChanged]);

  const onSubmit = (item: AutocompleteItem) => {
    const selected = item as unknown as AgendaGroup;

    onGroupNameChanged?.(selected.name);
    onGroupIdChanged?.(selected.id);
  };

  const onClear = () => {
    onGroupNameChanged?.('');
    setValue('');
  };

  const Clear =
    value !== '' && clearable ? <Icon value="CLEAR" onClick={onClear} style={{ color: '#e2e2e2' }} /> : undefined;

  return (
    <Autocomplete
      rightSection={loading ? <Loader size="sm" /> : Clear}
      placeholder={placeholder}
      description={withoutTitle ? '' : 'Gruppe'}
      nothingFound={!loading && groups.length === 0 && value !== '' && <Text> Keine Grouppen </Text>}
      limit={8}
      data={groups.map((g) => ({ value: g.name, ...g }))}
      value={value}
      onItemSubmit={onSubmit}
      onChange={updateFromInput}
      zIndex={9999}
      required={required}
      radius="sm"
    />
  );
};

export default TaskGroupSelection;
