import React from 'react';
import PropTypes from 'prop-types';

/**
 * DurationMinutesFormatter is a formatter which simply passes strings trough, but
 * returns a dash for empty or null values.
 */
export const DurationMinutesFormatter = ({ value, style }) => {
  const styles = {
    span: {
      height: '100%',
      ...style,
    },
  };
  return (
    <span className="form-control" style={styles.span}>
      {value === '' || value === null || value === undefined ? '0 Minuten' : `${value / 60} Minuten`}
    </span>
  );
};

DurationMinutesFormatter.propTypes = {
  value: PropTypes.string,
};
