/* eslint-disable react/jsx-no-useless-fragment */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Box, Text } from '@mantine/core';
import { FC, useContext } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AuthContext, Licence } from '../Service/Context/AuthContext';
import { WindowContext } from '../Service/Context/WindowContext';
import { RoutingS } from '../Service/RoutingS';
import { LicenceS } from '../Service/LicenceS';
import { AuthS } from '../Service/restapi/authService';
import DemoLink from "./DemoLink";

const LicenceHeader: FC<{ licence: Licence }> = ({ licence }) => {
  const navigate: NavigateFunction = useNavigate();
  const { sm } = useContext(WindowContext);
  const { jwtClaims } = useContext(AuthContext);
  const { trialEndsAt } = jwtClaims;
  const remainingTrialDays = LicenceS.getRemainingTrialDays(new Date(trialEndsAt));

  return (
    <Box p="xs" sx={(theme) => ({ backgroundColor: theme.colors.blue[4], height: 40 })}>
      {licence === 'BASIC' ? (
        <Text align="center" color="white">
          {sm ? (
            <>
              Starter-Plan aktiv.{'  '}
              <>
                {LicenceS.trialRedeemable(jwtClaims) ? (
                  <>
                    {'  '}
                    <u style={{ cursor: 'pointer' }} onClick={() => AuthS.redeemTrialLicence()}>
                      Testphase erneut starten
                    </u>
                  </>
                ) : (
                  <>
                    <b>
                      <u style={{ cursor: 'pointer' }} onClick={() => navigate(RoutingS.ROUTES.PLANS)}>
                        Paket wählen
                      </u>
                    </b>
                  </>
                )}
              </>
            </>
          ) : (
            <>
              <b>Kostenfreier Starter-Plan aktiv.</b>
              <>
                {LicenceS.trialRedeemable(jwtClaims) ? (
                  <>
                    {'  '}
                    <u style={{ cursor: 'pointer' }} onClick={() => AuthS.redeemTrialLicence()}>
                      Testphase erneut starten
                    </u>
                  </>
                ) : (
                  <>
                    {'  '}
                    Upgrade Deine Verwaltung zum passenden Pro-Paket und hole Dir Deine Zeit zurück.{'  '}
                    <b>
                      <u style={{ cursor: 'pointer' }} onClick={() => navigate(RoutingS.ROUTES.PLANS)}>
                        Paket wählen
                      </u>
                    </b>
                  </>
                )}
              </>
            </>
          )}
        </Text>
      ) : (
        <Text align="center" color="white">
          {sm ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {remainingTrialDays > 0 ? (
                <>
                  {`${remainingTrialDays} Tage verbleibend. `}
                  <DemoLink />
                </>
              ) : (
                <>
                  Testphase endet heute.{'  '}
                  <u style={{ cursor: 'pointer' }} onClick={() => navigate(RoutingS.ROUTES.PLANS)}>
                    Paket wählen
                  </u>
                </>
              )}
            </>
          ) : (
            <>
              {remainingTrialDays > 0 ? (
                <>
                  {`Du testest das Standard Paket. ${remainingTrialDays} Tage verbleibend. `}
                  <DemoLink />
                </>
              ) : (
                <>
                  Deine Testphase endet heute.{'  '}
                  <u style={{cursor: 'pointer'}} onClick={() => navigate(RoutingS.ROUTES.PLANS)}>
                    Paket wählen
                  </u>
                </>
              )}
            </>
          )}
        </Text>
      )}
    </Box>
  );
};

export default LicenceHeader;
