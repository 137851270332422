import { FC } from 'react';
import { ModalDialog } from '../../Molecules/Dialogs';
import ContactModalSteps from './ContactDeleteFlow/ContactDeleteModalFlowSteps';
import { ContactDeleteFlowContextProvider } from './ContactDeleteFlow/ContactDeleteFlowContext';

type Props = {
  personDescription?: string;
  contactId: string;
  lastModified?: Date;
  reload?: () => void;
  close: () => void;
};

const ContactDeleteModalFlow: FC<Props> = ({ personDescription, contactId, lastModified, reload, close }) => (
  <ContactDeleteFlowContextProvider contactId={contactId} reload={reload} lastModified={lastModified}>
    <ModalDialog
      size="xl"
      header={`Kontakt löschen | ${personDescription}`}
      body={<ContactModalSteps />}
      show
      toggle={close}
    />
  </ContactDeleteFlowContextProvider>
);

export default ContactDeleteModalFlow;
