import { ActionIcon, Divider, Group, Loader, Modal, Text, Tooltip } from '@mantine/core';
import {
  IconArrowBackUp,
  IconArrowLeft,
  IconBallpen,
  IconEyeCheck,
  IconEyeOff,
  IconRefresh,
  IconSend,
  IconTemplate,
  IconTrash,
} from '@tabler/icons';
import { FC, useContext, useEffect, useState } from 'react';
import { EmailBoxContext } from '../../Service/Context/EmailBoxContext';
import { MessageContext } from '../../Service/Context/MessageContextV2';
import { useDisclosure } from '@mantine/hooks';
import MessageModal from './MessageModal';
import { i18n } from '@lingui/core';
import { useParams, useSearchParams } from 'react-router-dom';
import { LoadTemplateModal, openDeleteEmailsModal } from './MessageModals';
import { MessageS } from '../../Service/MessageS';
import { PostboxCountersContext } from '../../Service/Context/PostboxCountersContext';

const EmailsListHeader: FC<{ phoneVersion: boolean }> = ({ phoneVersion }) => {
  const { id: eventId, box } = useParams();
  const [searchParams] = useSearchParams();
  const { refreshCount } = useContext(PostboxCountersContext);
  const {
    refresh,
    loading,
    messages,
    selectedMessage,
    selectMessage,
    prepareMessage,
    pageNumber,
    setPageNumber,
    changeEmail,
  } = useContext(EmailBoxContext);
  const { initMessage, loading: loadingMessage, action, setAction } = useContext(MessageContext);
  const [opened, { open, close }] = useDisclosure(false);
  const [showMessageTemplateModal, setMessageTemplateModal] = useState(!!searchParams.get('message_template'));
  const empty = messages.length === 0;

  useEffect(() => {
    if (!action && opened) {
      close();
    }
    if (phoneVersion && action && !opened) {
      open();
    }
  }, [action]);

  const deleteHandler = async () => {
    if (selectedMessage && phoneVersion) {
      if (box === 'trash') {
        const deleted = await MessageS.deleteEmail(selectedMessage.id);
        if (deleted) {
          refresh();
        }
      } else {
        const email = await MessageS.patchEmail(selectedMessage.id, { trash: true });
        if (email) {
          refresh();
        }
      }
      refreshCount('trash');
    } else {
      removeEmailsHandler();
    }
  };

  const markAsReadHandler = async (read: boolean) => {
    if (selectedMessage) {
      const patchedEmail = await MessageS.patchEmail(selectedMessage.id, { read });
      if (patchedEmail) {
        changeEmail(patchedEmail);
        selectMessage(patchedEmail);
      }
    }
  };

  const removeEmailsHandler = () =>
    openDeleteEmailsModal({
      ids: messages.map((m) => m.id),
      onDelete: () => {
        refreshCount('trash');
        if (box && box !== 'trash') {
          refreshCount(box ?? '');
        }
        if (pageNumber === 0) {
          refresh();
        } else {
          setPageNumber(0);
        }
      },
    });

  const hideGeneralIcons = phoneVersion && selectedMessage;

  return (
    <>
      <Group p={phoneVersion ? 0 : 'xs'} position="apart">
        <div>
          {phoneVersion && selectedMessage && (
            <ActionIcon onClick={() => selectMessage(undefined)}>
              <IconArrowLeft size={20} />
            </ActionIcon>
          )}
          {phoneVersion && !selectedMessage && (
            <Text size="lg" weight="bolder">
              {i18n._(`messaging.box.${box}`)}
            </Text>
          )}
        </div>
        <Group>
          {!empty && (
            <Tooltip openDelay={300} withArrow transition="slide-down" label="E-Mails löschen">
              <ActionIcon onClick={deleteHandler}>
                <IconTrash size={20} />
              </ActionIcon>
            </Tooltip>
          )}
          {(!selectedMessage || !phoneVersion) && (
            <>
              {loading && <Loader size="xs" />}
              {!loading && (
                <Tooltip openDelay={300} withArrow transition="slide-down" label="Aktualisierung">
                  <ActionIcon onClick={refresh}>
                    <IconRefresh size={20} />
                  </ActionIcon>
                </Tooltip>
              )}
            </>
          )}
          {!hideGeneralIcons && (
            <>
              {eventId && (
                <Tooltip openDelay={300} withArrow transition="slide-down" label="Vorlage laden">
                  <ActionIcon onClick={() => setMessageTemplateModal(true)}>
                    <IconTemplate size={20} />
                  </ActionIcon>
                </Tooltip>
              )}
              {action !== 'NEW' && (
                <>
                  {loadingMessage && <Loader size="xs" />}
                  {!loadingMessage && (
                    <Tooltip openDelay={300} withArrow transition="slide-down" label="Neue E-Mail schreiben">
                      <ActionIcon onClick={() => initMessage()} color="blue" variant="filled">
                        <IconSend size={20} />
                      </ActionIcon>
                    </Tooltip>
                  )}
                </>
              )}
            </>
          )}
          {selectedMessage && selectedMessage.readTimestamp && phoneVersion && box === 'inbox' && (
            <ActionIcon onClick={() => markAsReadHandler(false)}>
              <IconEyeOff size={20} />
            </ActionIcon>
          )}
          {selectedMessage && !selectedMessage.readTimestamp && phoneVersion && box === 'inbox' && (
            <ActionIcon onClick={() => markAsReadHandler(true)}>
              <IconEyeCheck size={20} />
            </ActionIcon>
          )}
          {selectedMessage && phoneVersion && box === 'inbox' && (
            <ActionIcon onClick={() => prepareMessage('REPLY')} color="blue" variant="filled">
              <IconArrowBackUp size={20} />
            </ActionIcon>
          )}
          {selectedMessage && phoneVersion && box === 'drafts' && (
            <ActionIcon onClick={() => prepareMessage('EDIT')} color="blue" variant="filled">
              <IconBallpen size={20} />
            </ActionIcon>
          )}
        </Group>
      </Group>
      {eventId && (
        <Modal
          closeOnClickOutside={false}
          transition="slide-down"
          opened={showMessageTemplateModal}
          onClose={() => setMessageTemplateModal(false)}
          title={
            <Text size="xl" weight="bolder">
              Vorlage auswählen
            </Text>
          }
          zIndex={201}
        >
          <LoadTemplateModal onClose={() => setMessageTemplateModal(false)} />
        </Modal>
      )}
      {phoneVersion && (
        <Modal
          closeOnClickOutside={false}
          size={550}
          transition="slide-down"
          opened={opened}
          onClose={() => {
            close();
            setAction(null);
          }}
          title={
            <Text size="lg" weight="bolder">
              {i18n._(`messaging.action.${action}`)}
            </Text>
          }
        >
          <MessageModal loadTemplate={() => setMessageTemplateModal(true)} />
        </Modal>
      )}
      {!phoneVersion && <Divider />}
    </>
  );
};

export default EmailsListHeader;
