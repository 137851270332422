import {AppShell, Navbar} from '@mantine/core';
import {useLingui} from '@lingui/react';
import {FC, useContext, useEffect, useState} from 'react';
import { TaskListContextProvider } from '../../Service/Context/TaskListContext';
import TasksNavFilter from '../../Templates/Tasks/TasksNavFilter';
import {WindowContext} from '../../Service/Context/WindowContext';
import PageHeader from '../../Templates/Navigation/PageHeader';
import DoubleNavbar from '../../Molecules/DoubleNavbar';
import AllTasksList from './AllTasksList';
import AddTaskIcon from './AddTaskIcon';
import { RoutingS } from '../../Service/RoutingS';

const Tasks: FC = () => {
  const [navBarOpen, setNavBarOpen] = useState<boolean>(false);
  const { sm } = useContext(WindowContext);
  const { i18n } = useLingui();

  useEffect(() => {
    RoutingS.changeTitle('Aufgaben');
  }, []);

  return (
    <TaskListContextProvider>
      <AppShell
        navbar={
          <div>
            {sm ? (
              <Navbar hiddenBreakpoint="sm" hidden={!navBarOpen} width={{ sm: 220, md: 250 }}>
                <TasksNavFilter closeNavBar={() => setNavBarOpen(false)} />
              </Navbar>
            ) : (
              <DoubleNavbar>
                <TasksNavFilter closeNavBar={() => setNavBarOpen(false)} />
              </DoubleNavbar>
            )}
          </div>
        }
        header={
          <PageHeader
            title={i18n._('common.tasks')}
            navBarOpen={navBarOpen}
            setNavBarOpen={setNavBarOpen}
            rightSection={<AddTaskIcon />}
          />
        }
      >
        <AllTasksList />
      </AppShell>
    </TaskListContextProvider>
  );
};

export default Tasks;
