import EmailHistoryListItem from './EmailHistoryListItem';
import SkeletonItems, { EmptyListSkeleton } from '../../Atoms/SkeletonItems';
import { useCallback, useContext } from 'react';
import { EmailBoxContext } from '../../Service/Context/EmailBoxContext';

const EmailHistoryList = () => {
  const { messages, loading, selectedMessage, selectMessage } = useContext(EmailBoxContext);

  const isActive = useCallback(
    (emailId) => {
      return selectedMessage && selectedMessage.id === emailId;
    },
    [selectedMessage],
  );

  if (loading) {
    return (
      <div style={{ padding: 10 }}>
        <SkeletonItems count={5} height={80} />
      </div>
    );
  }

  if (messages.length === 0) {
    return <EmptyListSkeleton />;
  }

  return (
    <>
      {messages.map((email) => {
        return (
          <div key={email.id}>
            <EmailHistoryListItem active={isActive(email.id)} onClick={() => selectMessage(email)} email={email} />
          </div>
        );
      })}
    </>
  );
};

export default EmailHistoryList;
