/* eslint-disable react/jsx-props-no-spreading */
// eslint-disable-next-line no-redeclare, @typescript-eslint/no-redeclare
import { Badge, BadgeProps, Text } from '@mantine/core';
import { FC } from 'react';

interface Props extends BadgeProps {
  title: string;
}

const BookitupBadge: FC<Props> = ({ title, ...rest }) => (
  <Badge radius="sm" sx={{ backgroundColor: '#585858', color: 'white' }} {...rest}>
    <Text weight="lighter"> {title} </Text>
  </Badge>
);

export default BookitupBadge;
