import React, { useState } from 'react';
import { Trans } from '@lingui/react';
import { ModalDialog } from '../../../../Molecules/Dialogs';
import { TransButtonDefault, TransButtonPrimary } from '../../../../Atoms/Buttons';
import GenericTemplateName from '../../../../Molecules/Settings/GenericTemplateName';

export default function ({ show, toggle, finish }) {
  const [disableSave, setDisableSave] = useState(false);
  const [newName, setNewName] = useState('');

  return (
    <ModalDialog
      header={<Trans id="contract.name.dialog-title" />}
      help="help.contract.name"
      body={
        <GenericTemplateName
          documentType="CONTRACT"
          disableSave={setDisableSave}
          newName={newName}
          setNewName={setNewName}
        />
      }
      footer={
        <div>
          <TransButtonDefault onClickHandler={toggle} text="actions.cancel" />{' '}
          <TransButtonPrimary
            disabled={disableSave}
            onClickHandler={() => {
              finish(newName.trim());
              toggle();
            }}
            text="common.apply"
          />
        </div>
      }
      show={show}
      toggle={toggle}
    />
  );
}
